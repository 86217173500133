import React from "react"

export const SvgIconShieldProtected = ({ width = 54, height = 64 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 59 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.45 68C27.45 68 54.65 55.2 54.65 36V13.6L27.45 4L0.25 13.6V36C0.25 55.2 27.45 68 27.45 68Z"
        fill="#FAA300"
      />
      <path d="M27.45 68C27.45 68 54.6499 55.2 54.6499 36V13.6L27.45 4V68Z" fill="#ED872B" />
      <path
        d="M20.9873 28.5V44.5H18.0888V28.5H20.9873ZM29.4478 44.5H24.0259V28.5H29.5572C31.1457 28.5 32.5103 28.8203 33.6509 29.4609C34.7968 30.0964 35.677 31.0104 36.2916 32.2031C36.9061 33.3958 37.2134 34.8229 37.2134 36.4844C37.2134 38.151 36.9035 39.5833 36.2837 40.7812C35.6692 41.9792 34.7811 42.8984 33.6197 43.5391C32.4634 44.1797 31.0728 44.5 29.4478 44.5ZM26.9244 41.9922H29.3072C30.4218 41.9922 31.3514 41.7891 32.0962 41.3828C32.841 40.9714 33.4009 40.3594 33.7759 39.5469C34.1509 38.7292 34.3384 37.7083 34.3384 36.4844C34.3384 35.2604 34.1509 34.2448 33.7759 33.4375C33.4009 32.625 32.8462 32.0182 32.1119 31.6172C31.3827 31.2109 30.4764 31.0078 29.3931 31.0078H26.9244V41.9922Z"
        fill="white"
      />
      <rect x="30.75" width="28" height="28" rx="14" fill="#FEDF88" />
      <g clipPath="url(#clip0_8809_68986)">
        <path
          d="M51.4165 9.15234V12.7889H47.7802"
          stroke="#B22B00"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.0831 18.8475V15.2109H41.7195"
          stroke="#B22B00"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.6046 12.182C39.912 11.3133 40.4344 10.5367 41.1231 9.92454C41.8117 9.31241 42.6443 8.88471 43.5429 8.68136C44.4416 8.47801 45.3772 8.50565 46.2623 8.76168C47.1474 9.01771 47.9532 9.4938 48.6046 10.1455L51.4167 12.7881M38.0834 15.2124L40.8955 17.855C41.5469 18.5067 42.3527 18.9828 43.2378 19.2388C44.1229 19.4948 45.0585 19.5225 45.9571 19.3191C46.8558 19.1158 47.6883 18.6881 48.377 18.0759C49.0657 17.4638 49.5881 16.6872 49.8955 15.8185"
          stroke="#B22B00"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8809_68986">
          <rect width="16" height="16" fill="white" transform="translate(36.75 6)" />
        </clipPath>
      </defs>
    </svg>
  )
}
