import React from "react"

interface ISvgXCircle {
  width: number;
  height: number;
  color: string;
}

export const SvgXCircle = ({ width, height, color }: ISvgXCircle) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0013 18.9167C14.6037 18.9167 18.3346 15.1857 18.3346 10.5833C18.3346 5.98096 14.6037 2.25 10.0013 2.25C5.39893 2.25 1.66797 5.98096 1.66797 10.5833C1.66797 15.1857 5.39893 18.9167 10.0013 18.9167Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 8.08594L7.5 13.0859"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 8.08594L12.5 13.0859"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
