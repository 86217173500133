import React from "react"

export const SvgInsuredDocs = ({ width = 25, height = 25, color = "#6E6E6E" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1523_96790)">
        <path
          d="M12.5 23.5C12.5 23.5 22 19.1 22 12.5V4.8L12.5 1.5L3 4.8V12.5C3 19.1 12.5 23.5 12.5 23.5Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.69922 12.6719L10.4716 14.4182C10.6677 14.6114 10.983 14.6098 11.1771 14.4146L16.3125 9.25"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1523_96790">
          <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
