import React, { FC } from "react"
import "./AuthHeader.scss"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"

type GAuthHeaderProps = {
  icon?: string,
  title: string,
  description?: string,
  renderDescriptionHtml?: boolean,
}

const GAuthHeader: FC<GAuthHeaderProps> = ({ icon, title, description, renderDescriptionHtml = false }) => {
  return (
    <div className={`GAuthHeader`}>
      {icon && <div className={`GAuthHeader__Container`}>
        <GIconsSvgType
          type={icon}
          width={64}
          height={64}
          color={COLORS.ROYAL_BLUE_600}
          backgroundColor={COLORS.ROYAL_BLUE_50}
        />
      </div>}
      <div className={`GAuthHeader__Container GAuthHeader__Container--Title`}>
          <h1>{title} </h1>
          {renderDescriptionHtml && (
            <GText text={``} size={SIZE.PARAGRAPH_BOLD_18} color={COLORS_NEW.BLACK_500} innerHtml={description} />
          )}
          {!renderDescriptionHtml && description && (
            <GText text={description} size={SIZE.PARAGRAPH_BOLD_18} color={COLORS_NEW.BLACK_500}/>
          )}
      </div>
    </div>
  )
}

export default GAuthHeader
