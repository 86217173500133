import React, {useEffect} from "react";

type NortonProps = {
    sealId: string
}

/**
 * Norton Seal is a component that will render Norton Seal from Digicert based on sealId. SealId can be obtained from Digicert. We just need to create a script tag
 * as a child of body tag with specific settings.
 * @see https://docs.digicert.com/en/certcentral/manage-certificates/digicert-site-seals/install-your-digicert-site-seal.html
 * @param sealId
 * @constructor
 */
const Norton: React.FunctionComponent<NortonProps> = ({sealId}) => {
    const addSeal = () => {
        /**
         * Create a script tag with specific settings and using sealId as a parameter
         */
        const scriptSettings = document.createElement("script");
        const codeSettings = 'var __dcid = __dcid || [];__dcid.push({"cid":"DigiCertClickID_' + sealId + '","tag":"' + sealId + '"});(function(){var cid=document.createElement("script");cid.async=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = document.getElementsByTagName("script");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());';
        scriptSettings.type = 'text/javascript';
        try {
            scriptSettings.appendChild(document.createTextNode(codeSettings));
        } catch (e) {
            scriptSettings.text = codeSettings;
        }
        document.body.appendChild(scriptSettings);
    }

    useEffect(() => {
        addSeal()
    }, [])

    /**
     * We just need to create a div with specific id. Digicert will replace this div with Norton Seal
     */
    return <div id={`DigiCertClickID_${sealId}`} />
}

export default Norton