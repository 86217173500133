export const STEP_NAME_PAYMENT_PAGE = "billing_page";
export const ADDITIONAL_STEP_GOVERNMENT_FEE = "government_fees";
export const STEP_TYPE_PAYMENT = "payment";
export const STEP_TYPE_SCRAP_CARD = "scrap_card";
export const STEP_PAYMENT_TYPE_SUBSCRIPTION = "subscription";
export const STEP_PAYMENT_TYPE_COMBINED = "combined";
export const STEP_PAYMENT_TYPE_APPLICATION = "application";
export const SUBSCRIPTION_PERIOD_MONTHLY = "monthly";
export const SUBSCRIPTION_PERIOD_YEARLY = "yearly";

export const COMBINED_PAYMENT_METHOD_APPLICATION_ONLY = "application_only";


