import React from "react"

interface ISvgLockOpen {
  width: number;
  height: number;
  color: string;
}

export const SvgLockOpen = ({ width, height, color }: ISvgLockOpen) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6667 7.83331H3.33333C2.59695 7.83331 2 8.43027 2 9.16665V13.8333C2 14.5697 2.59695 15.1666 3.33333 15.1666H12.6667C13.403 15.1666 14 14.5697 14 13.8333V9.16665C14 8.43027 13.403 7.83331 12.6667 7.83331Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66602 7.83331V5.16665C4.66519 4.34001 4.97154 3.54256 5.5256 2.92909C6.07966 2.31563 6.8419 1.92992 7.66435 1.84684C8.4868 1.76376 9.31078 1.98925 9.97633 2.47952C10.6419 2.9698 11.1015 3.68988 11.266 4.49998"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
