import React, {FC} from "react"
import {Link} from "react-router-dom"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes"
import "./Logo.scss"

interface ILogoSettings {
    logoIcon: string;
}

const LOGO_THEME: ILogoSettings = {
    logoIcon: ICONS_SVG_TYPE.LOGO,
}

type GLogoProps = {
    url?: string,
    width?: number,
    height?: number,
}

const GLogo: FC<GLogoProps> = ({url, width = 84, height = 22}) => {
    const logoUrl = url ?? "/";
    return (
        logoUrl ?
            <div className={`GLogo`}>
                <Link className={`GLogo__Link`} to={logoUrl}>
                    <GIconsSvgType type={LOGO_THEME.logoIcon} width={width} height={height}/>
                </Link>
            </div> :
            <div className={`GLogo`}>
                <GIconsSvgType type={LOGO_THEME.logoIcon} width={width} height={height}/>
            </div>
    )
}

export default GLogo