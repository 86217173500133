import React, { FC } from "react"
import GBoxImage, { GBoxImageProps } from "../../molecules/BoxImage/GBoxImage"
import "./GUploadPassportPhotoStep.scss"
import { BOX_TYPE, SIZE } from "../../types/types"
import Step1_1Image from "../../../../assets/image/photo_step/Step1_1.webp"
import Step1_2Image from "../../../../assets/image/photo_step/Step1_2.webp"
import Step2Image from "../../../../assets/image/photo_step/Step2.webp"
import Step3_1Image from "../../../../assets/image/photo_step/step_good.webp"
import Step3_2Image from "../../../../assets/image/photo_step/Step3_2.webp"
import Step4_1Image from "../../../../assets/image/photo_step/step_good.webp"
import Step4_2Image from "../../../../assets/image/photo_step/Step4_2.webp"
import Step5_1Image from "../../../../assets/image/photo_step/step_good.webp"
import Step5_2Image from "../../../../assets/image/photo_step/Step5_2.webp"
import Step6_1Image from "../../../../assets/image/photo_step/step_good.webp"
import Step6_2Image from "../../../../assets/image/photo_step/Step6_2.webp"
import Step7_1Image from "../../../../assets/image/photo_step/step_good.webp"
import Step7_2Image from "../../../../assets/image/photo_step/Step7_2.webp"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
import GText from "../../atoms/Text/Text"
import { COLORS_NEW } from "../../types/Colors"
import { sentPhotoToBack } from "../../../../Redux/store/upload/upload.actions"
import { useDispatch } from "react-redux"
import GUploadPhoto from "../GUploadPhoto/UploadPhoto"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import { WarningsText } from "../../../../shared/text/Text"
import GVideoPlayer from "../../molecules/VideoPlayer/VideoPlayer"
import { env } from "../../../../env"

const GUploadPassportPhotoStep: FC<any> = () => {
  const breakpoint = useBreakpoint()
  const dispatch = useDispatch()

  const instructionsVideoUrl = `${env.REACT_APP_S3_VIDEOS}/photo-woman+746_.mp4`

  let boxImages: GBoxImageProps[] = [
    {
      type: BOX_TYPE.VERTICAL,
      numberTitle: "1",
      title: "Take a new photo",
      subtitle:
        "<span>Take a brand new photo with your phone. </span> Taking a photo of an existing photo will produce a low-quality and unusable image.",
      images: [{ image: Step1_1Image }, { image: Step1_2Image }],
      imageWidth: breakpoint.xs ? "100%" : 132,
      imageHeight: "auto",
    },
    {
      type: BOX_TYPE.VERTICAL,
      numberTitle: "2",
      title: "Check your Distance",
      subtitle:
        "<span>The distance between your face and the camera should be about 15 inches. </span> Ideally, you can have another person take the photo.",
      images: [{ image: Step2Image }],
      imageWidth: breakpoint.xs ? "100%" : 276,
      imageHeight: "auto",
    },
    {
      type: BOX_TYPE.VERTICAL,
      numberTitle: "3",
      title: "Look straight ahead",
      subtitle:
        "<span>Face the camera with your head centered in the frame and both eyes open. </span> Be careful not to tilt your head up, down, or to one side.",
      images: [{ image: Step3_1Image }, { image: Step3_2Image }],
      imageWidth: breakpoint.xs ? "100%" : 132,
      imageHeight: "auto",
    },
    {
      type: BOX_TYPE.VERTICAL,
      numberTitle: "4",
      title: "Use good lighting",
      subtitle:
        "<span>Stand facing a natural light source, like a window. </span> We'll remove the background, so you don't have to worry about your setting.",
      images: [{ image: Step4_1Image }, { image: Step4_2Image }],
      imageWidth: breakpoint.xs ? "100%" : 132,
      imageHeight: "auto",
    },
    {
      type: BOX_TYPE.VERTICAL,
      numberTitle: "5",
      title: "Do not show your teeth",
      subtitle:
        "A small, natural smile is acceptable, but it's best to maintain a neutral facial expression. Either way, <span>make sure your mouth is completely closed. </span>",
      images: [{ image: Step5_1Image }, { image: Step5_2Image }],
      imageWidth: breakpoint.xs ? "100%" : 132,
      imageHeight: "auto",
    },
    {
      type: BOX_TYPE.VERTICAL,
      numberTitle: "6",
      title: "Remove your glasses",
      subtitle:
        "<span>Glasses are not allowed in your passport photo. </span> Even if you wear glasses every day, you must remove them for the photo.",
      images: [{ image: Step6_1Image }, { image: Step6_2Image }],
      imageWidth: breakpoint.xs ? "100%" : 132,
      imageHeight: "auto",
    },
    {
      type: BOX_TYPE.VERTICAL,
      numberTitle: "7",
      title: "Keep clothing simple",
      subtitle:
        "Wear everyday clothing, but <span>avoid sleeveless tops, camouflage, uniforms, excessive jewelry, and hats. </span>",
      images: [{ image: Step7_1Image }, { image: Step7_2Image }],
      imageWidth: breakpoint.xs ? "100%" : 132,
      imageHeight: "auto",
    },
  ]

  const handlerChangeUploadPhoto = (file: any) => {
    return file && dispatch(sentPhotoToBack(file))
  }

  return (
    <div className={`GUploadPassportPhotoStep`}>
      <div className={"UploadPhotoVideo"}>
        <GVideoPlayer url={instructionsVideoUrl} loop={true} controls={false} />
      </div>

      <div className={"UploadDocuments_Warning"} >
          <GIconsSvgType type={ICONS_SVG_TYPE.ALERT_TRIANGLE} color={COLORS_NEW.RED_600} width={breakpoint.md ? 40 : 20} height={breakpoint.md ? 24 : 20} />
          <GText 
            text={WarningsText.PassportPhotoWarning} 
            size={breakpoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14} 
            color={COLORS_NEW.RED_600} 
            innerHtml={WarningsText.PassportPhotoWarning} 
            maxWidth={breakpoint.md? "" : "271px"} 
          />
      </div>
      {boxImages.map((box, index) => (
        <div key={index}>
          <GBoxImage
            type={box.type}
            title={box.title}
            numberTitle={box.numberTitle}
            subtitle={box.subtitle}
            images={box.images}
            imageWidth={box.imageWidth}
            imageHeight={box.imageHeight}
            information={box.information}
          />
          {breakpoint.xs && !box.numberTitle && (
            <div className={`GUploadPassportPhotoStep__Instructions`}>
              <GText
                text={`Instructions on photo to be uploaded`}
                size={SIZE.PARAGRAPH_BOLD_16}
                color={COLORS_NEW.BLACK_800}
              />
            </div>
          )}
        </div>
      ))}

      <GUploadPhoto
        text={{ title: "Upload photo", subtitle: "Drag and drop files here or" }}
        action={handlerChangeUploadPhoto}
      />
    </div>
  )
}
export default GUploadPassportPhotoStep
