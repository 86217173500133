import {CREATE_TAX_LINK_ERROR, CREATE_TAX_LINK_START, CREATE_TAX_LINK_SUCCESS} from "../../constants/ActionTypes";

const INIT_STATE = {
    taxLink: null,
    loading: false,
    error: null,
};

const Tax = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_TAX_LINK_START: {
            return {
                ...state,
                loading: true,
            };
        }

        case CREATE_TAX_LINK_SUCCESS: {
            return {
                ...state,
                taxLink: action.payload,
                loading: false,
                error: null,
            };
        }

        case CREATE_TAX_LINK_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        default:
            return state;
    }
}

export default Tax;