import React, { FC } from "react"
import "./Footer.scss"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import {COLORS, COLORS_NEW} from "../../types/Colors"
import { Layout } from "antd"
import { env } from "../../../../env"

const { Footer } = Layout

const currentYear = new Date().getFullYear();

const GOVPLUS_TERMS1 = `© ${currentYear} EIN Plus LLC. All rights reserved. GovPlus is a registered trademark of EIN Plus LLC.`
const GOVPLUS_TERMS2 =
  "EIN Plus LLC is a private online software technology company not affiliated nor endorsed by any Government or " +
    "State agency. We do not charge for any forms, however, we charge for use of our software in assisting you with " +
    "completing the form. We are not a financial, accounting or law firm and do not provide legal or financial advice."


interface GFooterProps {}

const GFooter: FC<GFooterProps> = () => {
  const { REACT_APP_GOV_HOME_PAGE_URL } = env
  return (
    <Footer className={"GFooter"}>
      <div className={"GFooter__Content"}>
        <div className={"GFooter__Content__GovplusTerms"}>
          <div className={`GFooter__Content__GovplusTerms__Text1`}>
            <GText text={GOVPLUS_TERMS1} size={SIZE.PARAGRAPH_REGULAR_14} color={COLORS_NEW.BLACK_600} />
          </div>
          <GText text={GOVPLUS_TERMS2} size={SIZE.PARAGRAPH_REGULAR_14} color={COLORS_NEW.BLACK_600} />
        </div>
        <div className={"GFooter__Content__TermsAndPrivacy"}>
          <GText
            text={"Terms and Conditions"}
            size={SIZE.LINK_REGULAR_14}
            href={`${REACT_APP_GOV_HOME_PAGE_URL}/terms-conditions`}
            color={COLORS_NEW.BLACK_600}
          />
          <div>&middot;</div>
          <GText
            text={"Privacy Policy"}
            size={SIZE.LINK_REGULAR_14}
            href={`${REACT_APP_GOV_HOME_PAGE_URL}/privacy-policy`}
            color={COLORS_NEW.BLACK_600}
          />
        </div>
      </div>
    </Footer>
  )
}

export default GFooter
