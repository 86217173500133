import React, {useState} from 'react';
import {Form, Radio} from 'antd';
import SubscriptionPaymentItem, {SubscriptionPaymentItemProps} from "./SubscriptionPaymentItem";
import GText from "../../atomicDesign/atoms/Text/Text";
import {SIZE} from "../../atomicDesign/types/types";
import {COLORS} from "../../atomicDesign/types/Colors";
import './style.scss';

interface SubscriptionPriceInformationProps extends SubscriptionPaymentItemProps {
  id: number;
}

interface SubscriptionPaymentProps {
  subscriptionPriceInformation: SubscriptionPriceInformationProps[];
  headerText?: string;
  setSelectedPlan: any;
}

const SubscriptionPaymentForm: React.FC<SubscriptionPaymentProps> = ({
  subscriptionPriceInformation,
  headerText,
  setSelectedPlan
}) => {
  const [activeId, setActiveId] = useState(1)

  const onRadioChange = (selectedData: string) => {
    const selectedRow = subscriptionPriceInformation.find((priceInfo) => priceInfo.method === selectedData);
    const price = selectedRow?.yearPrice ? selectedRow?.yearPrice : selectedRow?.price;
    setSelectedPlan({method: selectedRow?.method, price: price as number});
  };

  return (
    <div className={'subscription-payment-form'}>
      {headerText && (
        <div className={'payment-header'}>
          <GText
            text={headerText}
            size={SIZE.PARAGRAPH_BOLD_18}
            color={COLORS.SLATE_GRAY_600}
          />
        </div>
      )}
      <Form.Item
        name="period"
        rules={[
          {required: true, message: "Please select your subscription plan"}
        ]}
        initialValue={"yearly"}
      >
        <Radio.Group
          onChange={(e) => onRadioChange(e.target.value)}
          className={"payment-method-information"}
          ref={(el) => {
              el?.style?.setProperty('display', 'flex', 'important')
          }}
        >
          {subscriptionPriceInformation.map(({id, price, method, saleLabel, salePrice, yearPrice}) => (
            <li
              key={id}
              onClick={() => setActiveId(id)}
              className={'payment-method-information__item'}
            >
              <SubscriptionPaymentItem
                price={price}
                method={method}
                saleLabel={saleLabel}
                salePrice={salePrice}
                yearPrice={yearPrice}
                showBestPriceLabel={method === 'yearly'}
              />
            </li>
          ))}
        </Radio.Group>
      </Form.Item>
      <div className={'payment-footer'}>
          <GText
              text={`Government fees not included`}
              size={SIZE.PARAGRAPH_REGULAR_16}
              color={COLORS.SLATE_GRAY_500}
          />
      </div>
    </div>
  );
};

export default SubscriptionPaymentForm;
