import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { Form } from "antd"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"

type CheckBoxGroupComponentsProps = {
  field: FieldInterface,
}

const CheckBoxGroupComponents: React.FunctionComponent<CheckBoxGroupComponentsProps> = ({ field }) => {
  const dispatch = useDispatch()

  const {
    name,
    label,
    type,
    // checkBoxGroup: options,
    priority: { nameVariable = "", value = "" } = {},
    defaultValue: { optionName = "", price: defaultPrice = "", currency = "", descriptionOption = "" } = {},
    // eslint-disable-next-line no-empty-pattern
  } = ({} = field)

  useEffect(() => {
    dispatch(setValueCurrentField(nameVariable, value))
    dispatch(setValueCurrentField(`${name}`, defaultPrice))
  }, [dispatch, nameVariable, value, defaultPrice, name])

  return (
    <Form.Item
      label={label}
      name={name}
      required={true}
      preserve={false}
      className={`check-box-group__container ${type} ${name}`}
    >
      <div className={`check-box-group__default-option`}>
        <div className={`default-option__header_container`}>
          <p className={`default-option__header-text`}>
            {optionName}
            <span className={`default-option__price-text`}>{currency + defaultPrice}</span>
          </p>
          <div className={`default-option__description_container`}>
            <p className={`default-option__description_text`}>{descriptionOption}</p>
          </div>
        </div>
      </div>
      {/*{checkBoxGroup(options)}*/}
    </Form.Item>
  )
}

export default CheckBoxGroupComponents
