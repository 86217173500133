import React from "react"
import { connect } from "react-redux"
import { Form, Input } from "antd"
import { ValidationItemInterface } from "../../../../Redux/InterfacesEntity/validation.interface"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import { moveInNextInput } from "./moveInputs"
import {getLabel} from "../../../../services/service-function/field";

type InputGroupComponentProps = {
  blockObject: { headerFields: string, fields: [FieldInterface] } | any,
  submitObject: {} | any,
  validationRule: ValidationItemInterface,
  dispatch: any,
}

// eslint-disable-next-line max-len
const InputGroupComponent: React.FunctionComponent<InputGroupComponentProps> = ({
  blockObject,
  submitObject,
  validationRule,
  dispatch,
}) => {
  const parentForFocus = "parent__for-focus"

  return (
    <Form.Item label={getLabel(blockObject.label)} name={blockObject.name} data-parent-elements={"focus"}>
      <Input.Group size="small" className={parentForFocus}>
        {blockObject.modalDescription && <p>{blockObject.modalDescription}</p>}
        {blockObject.fields.length &&
          blockObject.fields.map((item: FieldInterface | any, index: number) => {
            return (
              <React.Fragment key={index}>
                <Form.Item name={item.name} className={"social-group__input"}>
                  <Input
                    maxLength={item.maxLength}
                    onKeyDown={(e) => {
                      moveInNextInput(e, item, parentForFocus)
                    }}
                    onChange={(e: any) => dispatch(setValueCurrentField(item.name, e.target.value))}
                    style={{
                      width: "110px",
                      height: "50px",
                      padding: "0 15px",
                    }}
                  />
                </Form.Item>
                {index < blockObject.fields.length - 1 && "-"}
              </React.Fragment>
            )
          })}
      </Input.Group>
    </Form.Item>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
  observers: state.form.observers,
})

export default connect(mapStateToProps)(InputGroupComponent)
