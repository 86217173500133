export const initialSubmitObject = {
  namechange_reason: "",
  fname: "",
  mname: "",
  lname: "",
  old_fname: "",
  old_mname: "",
  old_lname: "",
  ssn_applicant1: "",
  ssn_applicant2: "",
  ssn_applicant3: "",
  diff_name_birth: "",
  city_birth: "",
  address1: "",
  city_name: "",
  zipcode: "",
  agreement_terms: "",
  phone: "",
  email: "",
  confirm_email: "",
  f_name_pay: "",
  l_name_pay: "",
  adress_pay: "",
  city_pay: "",
  zip_pay: "",
  "expiration_date-month": "",
  "expiration_date-year": "",
  card_no: "",
  ssc: "",
  gender: "",
  fname_birth: "",
  mname_birth: "",
  lname_birth: "",
  state_code: "",
  address2: "",
  state_pay: "",
  different_dob: "",
  uscitizen: "",
  worklegally: "",
  name_other: "",
  diff_name_prev_option: "",
  socialSecurityNumber: "",
  ssn_applicant: "",
  ssn_applicant_hidden: "",
  "date_birth-year": "",
  ssn_mother_unknown: "",
  ssn_father_unknown: "",
  fname_other: "",
  lname_other: "",
  diff_name_prev: "",
  relationship: "",
  "different_dob_date-year": "",
  enterlegally: "",
  "date_birth-month": "",
  "date_birth-day": "",
  "different_dob_date-month": "",
  "different_dob_date-day": "",
  fname_mother: "",
  lname_mother: "",
  ssn_mother_num: "",
  ssn_mother_num_hidden: "",
  fname_father: "",
  ssn_father_num: "",
  ssn_father_num_hidden: "",
  relationship_fname: "",
  relationship_lname: "",
  form_id: "",
  ssn_mother_num1: "",
  ssn_mother_num2: "",
  ssn_mother_num3: "",
  ssn_father_num2: "",
  ssn_father_num3: "",
  action: "new",
  relationship_specify: "",
  formtype: "",
  "form-type": "",
  "form-name": "",
  pages: [],
  ssn: ""
}
