/* eslint-disable indent */
import React from "react"
import { connect } from "react-redux"
import FormBlock from "../../FormComponents/FormBlock/FormBlock"
import FormHelper from "../FormHelper/FormHelper"
import GovFees from "../../../assets/image/government_fees.svg"
type ListFormsStepProps = {
  blockOnFormPage: {} | any,
  currentStep: {} | any,
}
const getHeaderIcon = (icon: string): string | undefined => {
  if(icon === 'government_fees'){
    return GovFees
  }
  return undefined
}
const ListFormsStep: React.FunctionComponent<ListFormsStepProps> = ({ blockOnFormPage, currentStep }) => {
  const headerBlockIcon = blockOnFormPage.header_block?.icon? getHeaderIcon(blockOnFormPage.header_block.icon) : false

  const formsInBlockOnFormPage = Array.isArray(blockOnFormPage?.formsInBlockOnFormPage) ? blockOnFormPage?.formsInBlockOnFormPage : Object?.values(blockOnFormPage?.formsInBlockOnFormPage ?? {})

  return (
    <>
      {!blockOnFormPage.header_block && (
        <div className={"form-block__header-container"}>
          <h2 className={"form-block__header-container-header"}>{blockOnFormPage.label}</h2>
          {blockOnFormPage.subLabel && <h4 className={"form-block__header-container-sub-header"}>{blockOnFormPage.subLabel}</h4>}
          {blockOnFormPage.description && typeof blockOnFormPage.description !== "object" && (
            <p
              className={`form-block__header-container-main ${blockOnFormPage.name} ${blockOnFormPage.type} ${currentStep["form-name"]}`}
            >
              {blockOnFormPage.description}
            </p>
          )}
        </div>
      )}

      {blockOnFormPage.header_block && (
        <div className={"form-block__header-container-block"}>
          {headerBlockIcon && (
            <img className={"form-block__header-container-block__icon"} src={headerBlockIcon} />
          )}
          <h2 className={"form-block__header-container-block__label"}>{blockOnFormPage.header_block.label}</h2>
          {blockOnFormPage.header_block.additional_texts && blockOnFormPage.header_block.additional_texts.length && (
           <ul className={"form-block__header-container-block__additional-texts"}>
             {blockOnFormPage.header_block.additional_texts.map((item: string, index: number) => <p key={index}>{item}</p>)}
           </ul>
          )}
        </div>
      )}

      {/* for package pages */}
      {blockOnFormPage.type === "package-page" && (
        <div className={"form-block__header-container form-block__header-container_package"}>
          <h2 className={"form-block__header-container-header form-block__header-container-header_package"}>
            {blockOnFormPage.label}
          </h2>
          {blockOnFormPage.description && typeof blockOnFormPage.description !== "object" && (
            <p className={"form-block__header-container-main form-block__header-container-main_package"}>
              {blockOnFormPage.description}
            </p>
          )}
        </div>
      )}

      <ul className={`list-forms ${currentStep["form-name"]} ${blockOnFormPage.name}`}>
        {formsInBlockOnFormPage?.length &&
            formsInBlockOnFormPage?.map((item: {} | any, index: number) => (
            <li
              // eslint-disable-next-line max-len
              className={`form-or-helper-block__container current-step_${currentStep.name}`}
              key={index}
            >
              {item.form ? <FormBlock formContainer={item.form} /> : <FormHelper item={item} />}
            </li>
          ))}
      </ul>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  currentStep: state.step.currentStep,
})

export default connect(mapStateToProps)(ListFormsStep)
