import React from 'react';

export const SvgIconGreenCheck = ({width = 24, height = 24}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#13BA68"/>
      <path d="M17 8.25L10.125 15.125L7 12" stroke="white" strokeWidth="1.25" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>

  );
}

