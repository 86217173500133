import React, {FC} from 'react';
import './MediumTrackingCard.scss';
import {GMediumTrackingCardProps} from "./MediumTrackingCard.props";
import GText from "../../../../../atoms/Text/Text";
import {PROGRESS_BAR_ORIENTATION, SIZE} from "../../../../../types/types";
import GProgressBar from "../../../../../molecules/ProgressBar/ProgressBar";
import {COLORS_NEW} from "../../../../../types/Colors";
import packageSmall from "../../../../../../../assets/image/upgrade/package_small.webp";
import {ICONS_SVG_TYPE} from "../../../../../atoms/IconsSvg/IconsSvgTypes";
import IconsSvg from "../../../../../atoms/IconsSvg/IconsSvg";
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const GMediumTrackingCard: FC<GMediumTrackingCardProps> = ({solution, estimatedWaitTime}) => {
  const breakPoint = useBreakpoint()
  return (
    <div className={'GMediumTrackingCard'}>
      <div className={'GMediumTrackingCard__Image'}>
        <img src={packageSmall} alt="Tracking card"/>
      </div>
      <div className={'GMediumTrackingCard__Details'}>
        <GText
          text={solution}
          size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_22 : SIZE.PARAGRAPH_BOLD_18}
          color={COLORS_NEW.BLACK_1000}
        />
        <GProgressBar
          showIcon={false}
          value={75}
          orientation={PROGRESS_BAR_ORIENTATION.LINEAR}
          customText={'In progress'}
          color={COLORS_NEW.GREEN_600}
        />
        <div className={'GMediumTrackingCard__Details__Processing'}>
          <IconsSvg type={ICONS_SVG_TYPE.CLOCK_ICON} width={25} height={25}/>
          <GText
            text={`Estimated wait time: ${estimatedWaitTime}`}
            size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14}
            color={COLORS_NEW.BLACK_800}
          />
        </div>
      </div>
    </div>
  )
}

export default GMediumTrackingCard;