import {Form, Select} from "antd"
import React, {useState} from "react"
import {DateDropDownProps} from "../types"
import {useSelector} from "react-redux"
import {RootState} from "../../../../../App"
import {formSelector} from "../../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import {paymentErrorInterface} from "../../InputComponent/serviceInput"
import './DataDropDownComponent.less'
import GSelect from "../../../../atomicDesign/molecules/Select/Select";
import {ICONS_SVG_TYPE} from "../../../../atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import {COLORS} from "../../../../atomicDesign/types/Colors";
import GIconsSvgType from "../../../../atomicDesign/atoms/IconsSvg/IconsSvg";

const DataDropDownComponent: React.FC<DateDropDownProps> = ({children, ...rest}) => {
    // @ts-ignore
    const {fieldData, optionElements, onSelectChange, value, validation, onSearch, submitError, ageValidate} = rest
    const {name, label, nameBlock, category} = fieldData
    const [searchValue, setSearchValue] = useState<string>("")
  const submitErrorMessage = submitError?.error?.data

  const classForError = () => {
    if (Object.keys(submitErrorMessage).includes('parent1_date_birth')) {
      if (nameBlock === 'parent1_date_birth') {
        return true
      } else {
        return false
      }
    }
    if (Object.keys(submitErrorMessage).includes('parent2_date_birth')) {
      if (nameBlock === 'parent2_date_birth') {
        return true
      } else {
        return false
      }
    }
    if (Object.keys(submitErrorMessage).includes('date_lost')) {
      if (nameBlock === 'date_lost') {
        return true
      } else {
        return false
      }
    }
    if (Object.keys(submitErrorMessage).includes('fdate_w_mm')) {
      if (nameBlock === 'fdate_w_mm') {
        return true
      } else {
        return false
      }
    }
  }

    const paymentError = useSelector<RootState, { paymentError: paymentErrorInterface | null }>(formSelector)
        ?.paymentError ?? {nameField: [""], errorDescription: ""}

    const getPaymentError = (currentFieldName: string, paymentError: paymentErrorInterface) =>
        paymentError?.nameField?.includes(currentFieldName || "empty") ? "error-payment" : ""

    return (
        <Form.Item
            key={name}
            className={`date-group__fieldData-picker ${getPaymentError(name || "", paymentError)}`}
            name={name}
            preserve={false}
            rules={[
                {
                    validator: (rule: any, value: any, callback: (error?: string) => void) =>
                        validation(rule, value, callback, fieldData),
                },
            ]}
            initialValue={value}
            //validateStatus={invalidText ? "error" : ""}
            // extra={
            //     invalidText ? (
            //         <div className="ant-form-item-explain ant-form-item-explain-error">
            //             <div role="alert">{invalidText}</div>
            //         </div>
            //     ) : (
            //         ""
            //     )
            // }
        >
            <GSelect
                icon={<GIconsSvgType type={ICONS_SVG_TYPE.DROPDOWN} color={COLORS.SLATE_GRAY_500} width={13} height={12} />}
                onChange={(value: string) => onSelectChange(value + "", name, nameBlock, category)}
                value={value ?? ""}
                showSearch
                searchValue={searchValue}
                onSearch={(value) => onSearch(value, setSearchValue)}
                filterOption={(input: string, option: { children: { toString: () => string } }) => option?.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeHolder={label}
                options ={optionElements}
            />

        </Form.Item>
    )
}

export default DataDropDownComponent
