import IBuilder from "../IBuilder";
import SignedUp from "./SignedUp";
import IEvent from "../IEvent";
import {IAddress} from "../EventProperties";
import BaseBuilder from "../BaseBuilder";

/**
 * Event for when a user signs up
 * @class SignedUpBuilder
 * @extends {BaseBuilder}
 * @description Event for when a user signs up
 */
export default class SignedUpBuilder extends BaseBuilder {
    private _createdAt: string | undefined;
    private _email: string | undefined;
    private _phone: string | undefined;
    private _firstName: string | undefined;
    private _lastName: string | undefined;
    private _address: IAddress | undefined;
    private _birthDay: string | undefined;
    private _utmMedium: string | undefined;
    private _utmSource: string | undefined;
    private _utmCampaign: string | undefined;
    private _utmContent: string | undefined;
    private _utmTerms: string | undefined;
    private _solution: string | undefined;
    private _service: string | undefined;
    private _category: string | undefined;
    private _lastUtmCampaign: string | undefined;
    private _lastUtmContent: string | undefined;
    private _lastUtmMedium: string | undefined;
    private _lastUtmSource: string | undefined;
    private _lastUtmTerm: string | undefined;

    /**
     * Set created at
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set created at
     */
    setCreatedAt(value: string | undefined) {
        this._createdAt = value;
        return this;
    }

    /**
     * Set email
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set email
     */
    setEmail(value: string | undefined) {
        this._email = value;
        return this;
    }

    /**
     * Set phone
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set phone
     */
    setPhone(value: string | undefined) {
        this._phone = value;
        return this;
    }

    /**
     * Set first name
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set first name
     */
    setFirstName(value: string | undefined) {
        this._firstName = value;
        return this;
    }

    /**
     * Set last name
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set last name
     */
    setLastName(value: string | undefined) {
        this._lastName = value;
        return this;
    }

    /**
     * Set address
     * @param address
     * @returns {IBuilder}
     * @description This method is used to set address
     */
    setAddress(address: IAddress | undefined) {
        this._address = address;
        return this;
    }

    /**
     * Set birthday
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set birthday
     */
    setBirthDay(value: string | undefined) {
        this._birthDay = value;
        return this;
    }

    /**
     * Set utm medium
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set utm medium
     */
    setUtmMedium(value: string | undefined) {
        this._utmMedium = value;
        return this;
    }

    /**
     * Set utm source
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set utm source
     */
    setUtmSource(value: string | undefined) {
        this._utmSource = value;
        return this;
    }

    /**
     * Set utm campaign
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set utm campaign
     */
    setUtmCampaign(value: string | undefined) {
        this._utmCampaign = value;
        return this;
    }

    /**
     * Set utm content
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set utm content
     */
    setUtmContent(value: string | undefined) {
        this._utmContent = value;
        return this;
    }

    /**
     * Set utm terms
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set utm terms
     */
    setUtmTerms(value: string | undefined) {
        this._utmTerms = value;
        return this;
    }

    /**
     * Set solution
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set solution
     */
    setSolution(value: string | undefined) {
        this._solution = value;
        return this;
    }

    /**
     * Set service
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set service
     */
    setService(value: string | undefined) {
        this._service = value;
        return this;
    }

    /**
     * Set category
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set category
     */
    setCategory(value: string | undefined) {
        this._category = value;
        return this;
    }

    /**
     * Set ip address
     * @param ipAddress
     * @returns {IBuilder}
     * @description This method is used to set ip address
     */
    setIpAddress(ipAddress: string | undefined) {
        this._ipAddress = ipAddress;
        return this;
    }

    setLastUtmCampaign(value: string) {
        this._lastUtmCampaign = value;
        return this;
    }

    setLastUtmContent(value: string) {
        this._lastUtmContent = value;
        return this;
    }

    setLastUtmMedium(value: string) {
        this._lastUtmMedium = value;
        return this;
    }

    setLastUtmSource(value: string) {
        this._lastUtmSource = value;
        return this;
    }

    setLastUtmTerm(value: string) {
        this._lastUtmTerm = value;
        return this;
    }

    /**
     * Build event
     * @returns {IEvent}
     * @description This method is used to build event
     */
    build(): IEvent {
        return new SignedUp(
            this._createdAt,
            this._email,
            this._phone,
            this._firstName,
            this._lastName,
            this._address,
            this._birthDay,
            this._utmMedium,
            this._utmSource,
            this._utmCampaign,
            this._utmContent,
            this._utmTerms,
            this._solution,
            this._service,
            this._category,
            this._ipAddress,
            this._lastUtmMedium,
            this._lastUtmSource,
            this._lastUtmCampaign,
            this._lastUtmContent,
            this._lastUtmTerm,
        );
    }
}
