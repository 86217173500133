import TemplateOne from "./TemplateOne";
import React from "react";

export default class TemplateOneBuilder {
    private _imageHeader: string | undefined;
    private _componentHeader: React.ReactElement | undefined;
    private _title: string;
    private _subTitle: string | undefined;
    private _subTitleSize: string | undefined;
    private _footerText: string | undefined;
    private _checkListItems: string[] | undefined;
    private _checkListSubTitle: string | undefined;
    private _previousPrice: string | undefined;
    private _price: string | undefined;
    private _tagInformation: string | undefined;
    private _acceptButton: string;
    private _cancelButton: string;
    private _testMode: boolean;
    private _testModeMessage: string | undefined;
    private _linkToOpen: string | undefined;
    private _imageHeaderSubTitle: string | undefined;
    private _service: string | undefined;
    private _serviceName: string | undefined;
    private _pageType: string | undefined;
    private _crossSellProductOffer: string | undefined;
    private _iconAcceptButton: string | undefined;

    constructor() {
        this._title = "";
        this._price = "0";
        this._acceptButton = "";
        this._cancelButton = "";
        this._testMode = false;
        this._service = undefined;
        this._serviceName = undefined;
        this._pageType = undefined;
        this._crossSellProductOffer = undefined;
    }

    setImageHeader(imageHeader: string | undefined) {
        this._imageHeader = imageHeader;
        return this;
    }

    setComponentHeader(componentHeader: React.ReactElement | undefined) {
        this._componentHeader = componentHeader;
        return this;
    }

    setTitle(title: string) {
        this._title = title;
        return this;
    }

    setSubtitle(subTitle: string | undefined) {
        this._subTitle = subTitle;
        return this;
    }

    setSubtitleSize(subTitleSize: string | undefined) {
        this._subTitleSize = subTitleSize;
        return this;
    }

    setFooterText(footerText: string | undefined) {
        this._footerText = footerText;
        return this;
    }

    setCheckListItems(checkListItems: string[] | undefined = []) {
        this._checkListItems = checkListItems;
        return this;
    }

    setCheckListSubTitle(checkListSubTitle: string | undefined) {
        this._checkListSubTitle = checkListSubTitle;
        return this;
    }

    setPreviousPrice(previousPrice: string | undefined) {
        this._previousPrice = previousPrice;
        return this;
    }

    setPrice(price: string | undefined) {
        this._price = price;
        return this;
    }

    setTagInformation(tagInformation: string | undefined) {
        this._tagInformation = tagInformation;
        return this;
    }

    setAcceptButton(acceptButton: string) {
        this._acceptButton = acceptButton;
        return this;
    }

    setCancelButton(cancelButton: string) {
        this._cancelButton = cancelButton;
        return this;
    }

    setTestMode(testMode: boolean) {
        this._testMode = testMode;
        return this;
    }

    setTestModeMessage(testModeMessage: string | undefined) {
        this._testModeMessage = testModeMessage;
        return this;
    }
    setLinkToOpen(linkToOpen: string | undefined) {
        this._linkToOpen = linkToOpen;
        return this;
    }

    setImageHeaderSubtitle(value: string | undefined) {
        this._imageHeaderSubTitle = value;
        return this;
    }

    setService(value: string | undefined) {
        this._service = value;
        return this;
    }

    setServiceName(serviceName: string | undefined) {
        this._serviceName = serviceName;
        return this;
    }

    setPageType(pageType: string | undefined) {
        this._pageType = pageType;
        return this;
    }

    setCrossSellProductOffer(crossSellProductOffer: string | undefined) {
        this._crossSellProductOffer = crossSellProductOffer;
        return this;
    }

    setIconAcceptButton(iconAcceptButton: string | undefined) {
        this._iconAcceptButton = iconAcceptButton;
        return this;
    }

    build() {
        return new TemplateOne(
            this._imageHeader,
            this._componentHeader,
            this._title,
            this._subTitle,
            this._subTitleSize,
            this._footerText,
            this._checkListItems,
            this._checkListSubTitle,
            this._previousPrice,
            this._price,
            this._tagInformation,
            this._acceptButton,
            this._cancelButton,
            this._testMode,
            this._testModeMessage,
            this._linkToOpen,
            this._imageHeaderSubTitle,
            this._service,
            this._serviceName,
            this._pageType,
            this._crossSellProductOffer,
            this._iconAcceptButton
        );
    }
}