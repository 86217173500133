import React from "react"
import {insuranceForm} from "./insuranceForm";
import {helper} from "./helper";
import {Form} from "antd";

const InsuranceDocuments = () => {
  const {typeSwitcher} = helper;

  return (
    <div>
      <h1 style={{color: "blue"}}>{insuranceForm.headerText.label}</h1>
      <Form>
        {insuranceForm.formBlock.insuranceItems.map((item, id) => {
          return typeSwitcher(
            item.type,
            item.label,
            item.items,
            item.defaultValue,
            item.name,
          )
        })}
      </Form>
    </div>
  );
}

export default InsuranceDocuments;
