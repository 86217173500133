import React, { FC } from "react"
import { Route } from "antd/lib/breadcrumb/Breadcrumb"
import { Breadcrumb } from "antd"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import GText from "../../atoms/Text/Text"
import "./BreadCrumbs.scss"
import { BREAD_CRUMBS_THEME } from "./BreadCrumbsTheme"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"

type GBreadCrumbsProps = {
  routesAttr: Route[],
}

const GBreadCrumbs: FC<GBreadCrumbsProps> = ({ routesAttr }) => {
  const breakpoint = useBreakpoint()

  const itemRender = (route: Route, params: any, routes: Array<Route>, paths: Array<string>) => {
    const last = routesAttr.indexOf(route) === routes.length - 1
    if (last) {
      return (
        <GText
          text={route.breadcrumbName}
          size={BREAD_CRUMBS_THEME.textSize}
          weight={BREAD_CRUMBS_THEME.textWeight}
          color={BREAD_CRUMBS_THEME.textColor}
        />
      )
    } else {
      return (
        <GText
          text={route.breadcrumbName}
          size={BREAD_CRUMBS_THEME.linkSize}
          linkTo={"/formsApp"}
          color={BREAD_CRUMBS_THEME.linkColor}
        />
      )
    }
  }

  const getSeparatorSvg = () => {
    return (
      <GIconsSvgType
        type={BREAD_CRUMBS_THEME.separatorIcon}
        color={BREAD_CRUMBS_THEME.separatorColor}
        width={BREAD_CRUMBS_THEME.separatorWidth}
        height={BREAD_CRUMBS_THEME.separatorHeight}
      />
    )
  }

  return (
    <>
      {breakpoint.lg && (
        <Breadcrumb className="GBreadcrumb" itemRender={itemRender} routes={routesAttr} separator={getSeparatorSvg()} />
      )}
    </>
  )
}

export default GBreadCrumbs
