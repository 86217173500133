import React, {useState} from "react"
import { connect } from "react-redux"
import {
  setAnswersQuestionary,
  setStatusQuestionary,
  setStatusQuestionnaireFront,
  submitAdditionalForm,
} from "../../../../Redux/store/additionalForm/additional.actions"
import GButton, {BUTTON_TYPE} from "../../../atomicDesign/molecules/Button/GButton";
import "./styles.scss"
import GText from "../../../atomicDesign/atoms/Text/Text";
import {SIZE} from "../../../atomicDesign/types/types";
import {COLORS, COLORS_NEW} from "../../../atomicDesign/types/Colors";
import UserIpRepository from "../../../../persistence/session/UserIpRepository";
import {
  ClickedButton,
  QuestionnaireButtonSize,
  QuestionnaireButtonText
} from "../QuestionaryTSA/QuestionnaireConstant";
import {LoadingState} from "../../../../shared/constans/user-from-view-mode.enum";
import { env } from "../../../../env";

const rootLink = env.REACT_APP_QUESTIONARY_IMG ?? "https://api-files.govplus.dev/static/img/ssn_documents/"

type QuestionaryComponentProps = {
  blocksStep: any,
  successPageSubmit: {} | any,
  submitObject: any,
  questionary: [] | any,
  appId: string,
  formId: string,
  dispatch: any,
  loadingButtonState: string,
}

// eslint-disable-next-line max-len
const QuestionaryComponent: React.FunctionComponent<QuestionaryComponentProps> = ({
  blocksStep,
  successPageSubmit,
  submitObject,
  questionary,
  appId,
  formId,
  dispatch,
  loadingButtonState,
}) => {
  const [index, setIndex] = useState(0)
  const [indexDocuments, setIndexDocuments] = useState(0)
  const [indexDocumentsQuestions, setIndexDocumentsQuestions] = useState(0)

  const ini: any[] = []
  const [documentsYes, setDocumentsYes] = useState(ini)
  const [documentsNo, setDocumentsNo] = useState(ini)

  const [loadingYesBtn, setLoadingYesBtn] = useState(false)
  const [loadingNoBtn, setLoadingNoBtn] = useState(false)
  const [disableBtn, setDisabledBtn] = useState(false)

  const initialObj = {
    applicant_documents: "",
    responsible_documents: "",
    applicant_orientations: "",
    responsible_orientation: "",
  }
  const [obj, setObj]: any = useState(initialObj)

  const getStringDocument = (oldString: string, newDocument: string) => {
    if (oldString && newDocument) {
      return `${oldString} | ${newDocument}`
    }
    return newDocument
  }

  const setObjFunc = () => {
    if (index === 0) {
      setObj({
        ...obj,
        applicant_documents: questionary[index].documents[indexDocuments].name,
      })
    }
    if (index === 1)
      setObj({
        ...obj,
        applicant_documents: getStringDocument(
          obj.applicant_documents,
          questionary[index].documents[indexDocuments].name
        ),
      })
    if (index === 2)
      setObj({
        ...obj,
        applicant_documents: getStringDocument(
          obj.applicant_documents,
          questionary[index].documents[indexDocuments].name
        ),
      })
    if (index > 2)
      setObj({
        ...obj,
        applicant_documents: getStringDocument(
          obj.applicant_documents,
          questionary[index].documents[indexDocuments].name
        ),
      })
  }

  const getObjFunc = () => {
    if (index === 0)
      return {
        applicant_documents: questionary[index].documents[indexDocuments].name,
      }
    if (index === 1)
      return {
        applicant_documents: getStringDocument(
          obj.applicant_documents,
          questionary[index].documents[indexDocuments].name
        ),
      }
    if (index === 2)
      return {
        applicant_documents: getStringDocument(
          obj.applicant_documents,
          questionary[index].documents[indexDocuments].name
        ),
      }
    if (index > 2)
      return {
        applicant_documents: getStringDocument(
          obj.applicant_documents,
          questionary[index].documents[indexDocuments].name
        ),
      }
  }

  const getNextCategory = (index: number) => {
    let next = -1;
    for(let i = index+1; i < questionary.length; i++) {
      const existDocument = questionary[i].documents.find((f: any) => f.name === questionary[index].documents[indexDocuments].name)
      if(!existDocument)
        return i;
    }
    return next;
  }

  const getNextDocument = (index: number, indexDocument: number = 0): {index:number, indexDocument:number} | false => {
    let next = false;
    for(let i = index; i < questionary.length; i++) {
      let setIndexDocument = 0
      if(indexDocument > 0  && i === index){
        setIndexDocument = indexDocument
      }
      for (let j = setIndexDocument; j < questionary[i].documents.length; j++) {
        if( documentsNo.indexOf(questionary[i].documents[j].name) === -1 && documentsYes.indexOf(questionary[i].documents[j].name) === -1){
          return {
            index: i,
            indexDocument: j
          };
        }
      }
    }
    return next;
  }

  /**
   * handler answer button click. Return undefined if is not the last question.
   * This function save the document answered yes by customer in array documentsYes
   * Every time customer click in button yes or not, we search the next question to show it
   *
   * @param value Y or N
   * @returns undefined if is not the last question else return {isCallGetObject: true}
   */
  const handlerQuestionnaireAnswerClick = (value: string): {isCallGetObject:boolean} | undefined => {
    if (value === "Y") {
      let newYesArray = [...documentsYes, questionary[index].documents[indexDocuments].name];
      setDocumentsYes(newYesArray)
      setObjFunc()
      const newSubmit = { ...obj, ...getObjFunc() }
      dispatch(setAnswersQuestionary(newSubmit))
      // if isn't last elements in array questionary
      if (index < questionary.length - 1) {
        const next = getNextCategory(index);
        if(next === -1) {
          setIndex(0)
          return {isCallGetObject: true}
        } else {
          const nextOther = getNextDocument(index+1);
          if(!nextOther) {
            setIndex(0)
            return {isCallGetObject: true}
          } else {
            setIndex(next)
            setIndexDocuments(nextOther.indexDocument)
          }
        }
      } else {
        setIndex(0)
        return {isCallGetObject: true}
      }
    }
    if (value === "N") {
      let newNoArray = [...documentsNo, questionary[index].documents[indexDocuments].name];
      setDocumentsNo(newNoArray)
      // if isn't last elements in array questionary.documents
      if (indexDocuments < questionary[index].documents.length - 1) {
        const nextDocument = getNextDocument(index, indexDocuments + 1)
        if(!nextDocument) {
          setIndex(0)
          return {isCallGetObject: false}
        } else {
          setIndex(nextDocument.index)
          setIndexDocuments(nextDocument.indexDocument)
        }
      } else if (indexDocuments === questionary[index].documents.length - 1) {
        // if isn't last elements in array questionary
        if (index < questionary.length - 1) {
          const nextDocument = getNextDocument(index + 1)
          if(!nextDocument) {
            setIndex(0)
            return {isCallGetObject: false}
          } else {
            setIndex(nextDocument.index)
            setIndexDocuments(nextDocument.indexDocument)
          }
        } else {
          setIndex(0)
          return {isCallGetObject: false}
        }
      }
    }
    return undefined
  }

  const handlerClickButton = (e: string) => {
    if(e === ClickedButton.YES) {
      setLoadingYesBtn(true);
      setLoadingNoBtn(false);
      setDisabledBtn(true);
    }
    if(e === ClickedButton.NO) {
      setLoadingYesBtn(false);
      setLoadingNoBtn(true);
      setDisabledBtn(true);
    }

    setTimeout(()=>{
      const question = handlerQuestionnaireAnswerClick(e)

      if (question) {
        let newSubmit = undefined
        if(question.isCallGetObject) {
          newSubmit = {
            ...successPageSubmit,
            answersQuestionary: { ...obj, ...getObjFunc() },
          }
        } else {
          newSubmit = {
            ...successPageSubmit,
            answersQuestionary: { ...obj },
          }
        }
        dispatch(setStatusQuestionnaireFront(""))
        dispatch(
            submitAdditionalForm({
              ...newSubmit,
              dataPricing: submitObject["data-pricing"],
              client_token: appId,
              form_id: formId,
              userIp: UserIpRepository.getInstance().getValue()
            })
        )

        dispatch(setStatusQuestionary("completed"))

      }
      resetBtnStatuses();
    }, 500)
  }

  const resetBtnStatuses = () => {
    setLoadingYesBtn(false);
    setLoadingNoBtn(false);
    setDisabledBtn(false);
  }

  return (
    <>
      <div>
        {questionary && questionary.length && questionary[index] && (
          <React.Fragment key={index}>
            <div key={index} className={`step-questionnaire__main-container`}>
              <div className={'step-questionnaire__main-header'}>
                <GText text={questionary[index].documents[indexDocuments] &&
                    questionary[index].documents[indexDocuments].questions[indexDocumentsQuestions].question.replace(
                      `$NAME`,
                      `your`
                    ).replace(
                      `$SEX`,
                      ``
                    )} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000}/>
              </div>
              <img
                // eslint-disable-next-line max-len
                src={`${rootLink}${
                  questionary[index].documents[indexDocuments] && questionary[index].documents[indexDocuments].picture
                }`}
                alt={questionary[index].documents[indexDocuments] && questionary[index].documents[indexDocuments].name}
                className={`step-questionnaire__main-img`}
              />
            </div>
            <div className={'options-container'}>
              <GButton
                  textSize={SIZE.PARAGRAPH_BOLD_16}
                  text={QuestionnaireButtonText.NO}
                  size={QuestionnaireButtonSize.SMALL}
                  isSubmit={false}
                  showIcon={false}
                  type={BUTTON_TYPE.SECONDARY}
                  click={()=>handlerClickButton(ClickedButton.NO)}
                  disabled={disableBtn || loadingButtonState === LoadingState.Loading}
                  loading={loadingNoBtn || loadingButtonState === LoadingState.Loading}
              />
              <GButton
                  textSize={SIZE.PARAGRAPH_BOLD_16}
                  text={QuestionnaireButtonText.YES}
                  size={QuestionnaireButtonSize.SMALL}
                  showIcon={false}
                  isSubmit={false}
                  type={BUTTON_TYPE.SECONDARY}
                  click={()=>handlerClickButton(ClickedButton.YES)}
                  disabled={disableBtn || loadingButtonState === LoadingState.Loading}
                  loading={loadingYesBtn || loadingButtonState === LoadingState.Loading}
              />
            </div>
            <p className={`step-questionnaire__hint`}>{blocksStep.content.hint}</p>
          </React.Fragment>
        )}

      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  successPageSubmit: state.additional.successPageSubmit,
  questionary: state.form.questionary,
  submitObject: state.form.submitObject,
  appId: state.form.appId,
  formId: state.form.formId,
  loadingButtonState: state.loading.loadingButtonState,
})

export default connect(mapStateToProps)(QuestionaryComponent)
