import {all, fork, put, call, takeEvery} from "redux-saga/effects";
import {
  GET_FOOTER_DATA,
  GET_RETARGETING_DATA,
  SET_GENERATED_RETARGETING_LINK
} from "../../constants/ActionTypes";
import {userService} from "../../api/api";
import {
  clearMessage,
  getFooterDataSuccess,
  getRetargetingLinkDataSuccess,
  setUserToken,
  showAuthMessage,
  showGrowlMessage, userSignInSuccess
} from "../actions";
import {notifyError} from "@dash/gov-plus__front-end__form/src/util/helper";
import {getIntercomUserHash,getUserDataFromDash, getUserFlatternData, isUserSubscribedData, getUserApplications} from "./Auth";
import UtmRepository from "@dash/gov-plus__front-end__form/src/persistence/cookies/UtmRepository";
import {EventsHelper} from "@dash/gov-plus__front-end__form/src/util/EventsHelper";
import FormIdRepository from "@dash/gov-plus__front-end__form/src/persistence/session/FormIdRepository";
import {AdditionalSagaHelper} from "@dash/gov-plus__front-end__form/src/Redux/store/additionalForm/AdditionalSagaHelper";
import AuthUserBearerTokenRepository from "@dash/gov-plus__front-end__form/src/persistence/session/AuthUserBearerTokenRepository";
import {SubscriptionStatuses} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import { env } from "../../env";

function* getFooterNotes() {
  try {
    const response = yield userService.getFooterData();
    yield put(getFooterDataSuccess(response.data.data.items[0]));
  }catch (err) {
    notifyError(err);
  }
}

function* generateRetargetingLink({form_id}) {
  try {
    const retargetingLinkResponse = yield userService.retargetingLinkGenerator({
      state:{
        app_id: null,
        state: {form_id},
      }
    });
    localStorage.setItem(`state_${form_id}`, `${env.REACT_APP_GOV_HOME_DASHBOARD}/resolve/?retargetingToken=${retargetingLinkResponse.data.data}`);
  }catch (err) {
    notifyError(err);
  }
}

async function triggerIdentifyEvent(email, clientToken) {
  const utmVarsJson = UtmRepository.getInstance().getUtm();

  /**
   * Promise.allSettled is used to make sure that all promises are resolved, even if some of them fail. This return an array of objects with the following structure:
   * {
   *  status: "fulfilled" | "rejected", // depending on the promise status
   *  value: any // the value returned by the promise or the error
   * }
   */
  const data = await Promise.allSettled([getUserDataFromDash(), getUserFlatternData(), isUserSubscribedData(), getIntercomUserHash({client_token: clientToken}), getUserApplications(clientToken)]);
  const userDataResponse = data[0].status === "fulfilled" ? data[0].value : null;
  const flattenResponse = data[1].status === "fulfilled" ? data[1].value : null;
  const isUserSubscribed = data[2].status === "fulfilled" ? data[2].value : null;
  const subscriptionStatus = isUserSubscribed?.data?.status === SubscriptionStatuses.ACTIVE;
  const intercomUserHash = data[3].status === "fulfilled" ? data[3].value?.data?.user_hash : "";
  const applications = data[4].status === "fulfilled" && data[4]?.value?.data?.success ? data[4].value.data.success.applications : [];

  const {outerId, address, firstName, lastName, phoneNumber, dateOfBirth, authorized, createdAt} = EventsHelper.getDataToIdentify(userDataResponse, flattenResponse);
  await EventsHelper.triggerIdentify(outerId, address, email, firstName, lastName, phoneNumber, dateOfBirth, applications.length > 0 || subscriptionStatus, isUserSubscribed?.data.status === SubscriptionStatuses.ACTIVE ||  isUserSubscribed?.data.status === SubscriptionStatuses.CANCELLED || isUserSubscribed?.data.status === SubscriptionStatuses.IN_TRIAL, utmVarsJson, authorized === 1, intercomUserHash, createdAt);
}

function* getRetargetingData({retargetingToken, outerId}) {
  try {
    const {data} = yield userService.getDataFromRetargetingLink(retargetingToken);

    FormIdRepository.getInstance().setValue(data?.data?.state?.form_id);

    localStorage.setItem(`state_${data.data.state.form_id}`, `${env.REACT_APP_GOV_HOME_DASHBOARD}/resolve/?retargetingToken=${retargetingToken}`);

    if (data.data.authToken) {
      sessionStorage.setItem("isThisUserPreAuth", "true")
      yield call(AdditionalSagaHelper.setAuthBearerToken, data.data.authToken);

      yield triggerIdentifyEvent(data?.data?.email, data.data.authToken);

      localStorage.setItem('user', JSON.stringify({
        'email': data?.data?.email,
        'outer_id': data.data.outer_id,
        'name': data?.data?.firstname,
        'last_name': data?.data?.lastname,
      }));

      yield all([
        put(setUserToken({
          userToken: data.data.authToken,
          outerId: data.data.outer_id,
        })),
        put(getRetargetingLinkDataSuccess({
          formId: data.data.state.form_id,
          appId: data.data.app_id,
          userName: data.data.firstname,
          lastName: data.data.lastname,
        })),
        put(userSignInSuccess({
          email: data?.data?.email, firstname: data?.data?.firstname, lastname: data?.data?.lastname, outer_id: data.data.outer_id,
          authUser: AuthUserBearerTokenRepository.getInstance().getValue(),
        }))
      ])
    } else {
      yield put(showAuthMessage(data));
      yield put(getRetargetingLinkDataSuccess({
        formId: data.data.state.form_id,
        appId: data.data.app_id,
      }))
      yield put(showGrowlMessage({
        messageInfo: "Please login to finish your application.",
        growlStatus: false,
        code: 403,
      }))
      yield put(clearMessage())
    }
  } catch (err) {
    yield put(showAuthMessage(err.response?.data));
    yield put(showGrowlMessage({
      messageInfo: err.response.data.messageInfo,
      growlStatus: false,
      code: 400,
    }))
    notifyError(err);
  }
}



function* getFooterNotesFork() {
  yield takeEvery(GET_FOOTER_DATA, getFooterNotes);
}
function* setGenerateRetargetingLinkFork() {
  yield takeEvery(SET_GENERATED_RETARGETING_LINK, generateRetargetingLink);
}

function* getRetargetingDataFork() {
  yield takeEvery(GET_RETARGETING_DATA, getRetargetingData);
}

export default function* rootSaga() {
  yield all([
    fork(getFooterNotesFork),
    fork(setGenerateRetargetingLinkFork),
    fork(getRetargetingDataFork),
  ]);
}
