import React, {FC} from "react";
import InsuranceDocumentCard from "../../organisms/InsuranceDocumentCard/InsuranceDocumentCard";
import "./InsuranceDocumentsList.scss"
import {IInsuredDocument} from "../../types/Models";

type InsuranceDocumentsListProps = {
    documents: IInsuredDocument[],
    showCardsFooter?: boolean,
    fromTeaser?: boolean,
    onSubscribe?: () => void
}
const InsuranceDocumentsList: FC<InsuranceDocumentsListProps> = (
    {
        documents, 
        showCardsFooter = true,
        onSubscribe

    }) => {

    return <div className={"GInsuranceDocumentsList"}>
        {documents.map(document => <InsuranceDocumentCard 
                                        key={document.name} 
                                        document={document} 
                                        showFooter={showCardsFooter}
                                        onSubscribe ={onSubscribe}
                                    /> )}
    </div>
}

export default InsuranceDocumentsList