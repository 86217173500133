import React, {Children, FC} from "react";
import GText from "../../atoms/Text/Text";
import {COLORS_NEW} from "../../types/Colors";
import "./StepApplicationCard.scss"
import ApplicationCardTemplate from "../../templates/ApplicationCardTemplate/ApplicationCardTemplate";
import { ICONS_SVG_TYPE } from '../../atoms/IconsSvg/IconsSvgTypes';
import ApplicationCardTemplateMobile from "../../templates/ApplicationCardTemplate/ApplicationCardTemplateMobile";
import { SIZE } from "../../types/types";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

export enum StepApplicationCardContentAlign {
    Center = "center", Left = "left"
}

export type StepApplicationCardProps = {
    title: string,
    subtitle: string,
    productImage: string,
    contentAlign: StepApplicationCardContentAlign,
    iconType: string,
    children?: React.ReactNode
}

const GStepApplicationCard: FC<StepApplicationCardProps> =
    ({
         contentAlign,
         title,
         productImage,
         subtitle,
         iconType,
         children
     }) => {
        const bodyStyles: React.CSSProperties = {
            textAlign: contentAlign
        }
        const breakPoint = useBreakpoint()
        return(
            <div className="GStepApplicationCard">
                {breakPoint.md ?
                    <div className="GStepApplicationCard__Large">
                        <ApplicationCardTemplate
                            image={productImage}
                            productName={title}
                            iconType={iconType ? iconType : ICONS_SVG_TYPE.SEARCH_STEP}
                            iconBackground={ "white" }
                            iconColor={COLORS_NEW.GREEN_700}
                        >
                            <GText text={subtitle} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.GREEN_600} />
                            {children && <div>{children}</div>}
                        </ApplicationCardTemplate>
                    </div>
                    :
                    <div className="GStepApplicationCard__Small">
                        <ApplicationCardTemplateMobile
                            image={productImage}
                            productName={title}
                            iconType={iconType ? iconType : ICONS_SVG_TYPE.SEARCH_STEP}
                            iconBackground={ "white" }
                            iconColor={COLORS_NEW.GREEN_700}
                            iconSize={32}
                            HeaderContent={() => <GText text={subtitle} size={SIZE.PARAGRAPH_BOLD_14} color={COLORS_NEW.GREEN_600} />}
                        >
                            {children && <div>{children}</div>}
                        </ApplicationCardTemplateMobile>
                    </div>

                }

            </div>
        )

    }


export default GStepApplicationCard


