import {IHeader} from "../Header";
import {FC} from "react";
import React from "react";
import "./GHeaderSubscriptionPayment.scss"
import GImageTitle from "../../../molecules/ImageTitle/GImageTitle";
import productsGroupImage from "../../../../../assets/image/product-group-gradient-carousel.webp";
import productsGroupImageMobile from "../../../../../assets/image/product-group-gradient-carousel-mobile.webp";
import {getPropertiesForEvents} from "../../../../../services/gtm/backEndEvent";
import GSubscriptionPaymentImageDescription
  from "./SubscriptionPaymentImageDescription/GSubscriptionPaymentImageDescription";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {SIZE} from "../../../types/types";
import {COLORS_NEW} from "../../../types/Colors";
import GProgressBar from "../../../molecules/ProgressBar/ProgressBar";
import { SolutionNames } from "../../../../../util/SolutionNames";


type GHeaderPaymentProps = {
    props: IHeader,
}

const GHeaderSubscriptionPayment: FC<GHeaderPaymentProps> = (content) => {
    const breakPoint = useBreakpoint();
    const headerContent: IHeader = content.props;
    const {form_id: formId} = getPropertiesForEvents()
    const webFlowServicesData = JSON.parse(localStorage.getItem("webFlowServicesData") ?? "{}") ?? {}
    let linkPhotoProcessingSteps = ""
    let shadow = true
    const {solution, formType} = getPropertiesForEvents()

    linkPhotoProcessingSteps = formId
      ? webFlowServicesData[formId]?.illustration_l
      : webFlowServicesData[formType.toUpperCase()]?.illustration_l
   
    let image: React.CSSProperties = {
        maxHeight: breakPoint.md ? solution === SolutionNames.PAS || solution === "PAS" ? "190px" : "200px" : "160px",
        width: "auto",
    }

    const productsGroupImgCarousel = !breakPoint.xs ? productsGroupImage : productsGroupImageMobile;

    return (
      <div className="GHeaderPayment">
        <GImageTitle
            title={headerContent.title}
            imageStyle={image}
            image={linkPhotoProcessingSteps}
            titleSize={SIZE.HEADING_LG}
        />
        <GProgressBar value={90} customText={"Ready to file"} showIcon={false} color={COLORS_NEW.GREEN_600} showIconRight />
        <GSubscriptionPaymentImageDescription title={headerContent.title2} titleSize={SIZE.HEADING_MDLG} image={productsGroupImgCarousel}></GSubscriptionPaymentImageDescription>
      </div>
    )
}

export default GHeaderSubscriptionPayment