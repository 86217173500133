import React, { FC, useState } from "react"
import "./PhoneNumberDetailsForm.scss"
import { Form } from "antd"
import { FormInput, validatePhoneNumber } from "../../../../../util/IdentityProtectionHelper"
import GText from "../../../atoms/Text/Text"
import GButton from "../../../molecules/Button/GButton"
import GInputText from "../../../molecules/InputText/InputText"
import { COLORS_NEW } from "../../../types/Colors"
import { SIZE, BUTTON_TYPE } from "../../../types/types"
import ReactInputMask from "react-input-mask"

const PhoneNumberDetailsForm: FC<FormInput> = ({ recordType, handleFormActions }) => {
  const [phoneNumberForm] = Form.useForm()
  const [phoneNumberError, setPhoneNumberError] = useState("")

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[-\s()\\/]/g, "")
    phoneNumberForm.setFieldValue("phoneNumber", event.target.value)

    if (value.length === 10) {
      setPhoneNumberError("")
    }
  }

  const handleFormSubmit = async () => {
    try {
      const valid = await phoneNumberForm.validateFields()
      if (valid) {
        const submittedData = {
          recordType,
          value: phoneNumberForm.getFieldValue("phoneNumber").replace(/[-\s()\\/]/g, ""),
        }
        handleFormActions(submittedData)
        setPhoneNumberError("")
      }
    } catch (error) {
      console.error(error)
      setPhoneNumberError("Please enter a valid phone number")
    }
  }

  const getPhoneNumberFormLabel = () => {
    return (
      <div className={"PhoneNumberDetailsForm__Item__Label"}>
        <GText text={"Phone number"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
      </div>
    )
  }

  return (
    <Form
      className={"PhoneNumberDetailsForm"}
      layout={"vertical"}
      form={phoneNumberForm}
      initialValues={{ remember: true }}
      name={"basic"}
      onFinish={handleFormSubmit}
    >
      <Form.Item
        className={"PhoneNumberDetailsForm__Item"}
        label={getPhoneNumberFormLabel()}
        name={"phoneNumber"}
        initialValue={""}
        rules={[{ required: true, validator: validatePhoneNumber }]}
        hasFeedback={false}
        help={phoneNumberError}
        validateStatus={phoneNumberError ? "error" : ""}
      >
        <ReactInputMask
          inputMode={`tel`}
          type={`text`}
          onChange={handleInputChange}
          mask={"(999) 999-9999"}
          maskPlaceholder={"(___) ___-____"}
        >
          <GInputText inputMode={"numeric"} />
        </ReactInputMask>
      </Form.Item>
      <div className={"PhoneNumberDetailsForm__Actions"}>
        <GButton
          type={BUTTON_TYPE.SECONDARY}
          showIcon={false}
          style={{ height: "44px" }}
          text={"Add"}
          click={handleFormSubmit}
        />
      </div>
    </Form>
  )
}

export default PhoneNumberDetailsForm
