import Event from "../Event"

import { EventOptions, EventProps } from "../EventProperties"

import IEvent from "../IEvent"

const EVENT_NAME = "Identity Protection Verification Started"

/**
 * Identity Protection Verification Started
 * @class IdentityProtectionCreditScoreStarted
 * @extends {Event}
 * @implements {IEvent}
 * @description Identity Protection Verification Started
 */

export default class IdentityProtectionCreditScoreStarted extends Event implements IEvent {

  /**
   * Creates an instance of IdentityProtectionCreditScoreStarted.
   * @memberof IdentityProtectionCreditScoreStarted
   * @description Identity Protection Verification Started event
   */

  constructor(ipAddress: string | undefined) {
    super(EVENT_NAME, ipAddress)
  }

  getEventData(): EventProps {
    return {
      ...super.baseEventData(),
    }
  }

  getEventName(): string {
    return this.eventName
  }

  pageName(): string {
    return ""
  }

  getPreviousId(): string {
    return ""
  }

  getUserId(): string {
    return ""
  }

  getEventOptions(): EventOptions | undefined {
    return undefined
  }
}
