/* eslint-disable react-redux/useSelector-prefer-selectors */
import React, { FC, Suspense, useCallback, useEffect, useMemo } from "react"
import "./Documents.scss"
import IconsSvg from "../../Components/atomicDesign/atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../Components/atomicDesign/atoms/IconsSvg/IconsSvgTypes"
import { COLORS, COLORS_NEW } from "../../Components/atomicDesign/types/Colors"
import GText from "../../Components/atomicDesign/atoms/Text/Text"
import { SIZE } from "../../Components/atomicDesign/types/types"
import { useSubscription } from "../../../../dashboard-govplus-front/src/hooks/useSubscription"
import creditMonitoringLogo from "../../assets/image/credit-monitoring-logo.png"
import identityRestorationLogo from "../../assets/image/identity-restoration-logo.png"
import oneMillionLogo from "../../assets/image/one-million-logo.png"
import heroPromoImg from "../../assets/image/apps-icons-hero.webp"
import GButton from "../../Components/atomicDesign/molecules/Button/GButton"
import {
  getIdentities,
  setScrollTarget,
  setSelectedFormId,
  showModalsType,
} from "../../../../dashboard-govplus-front/src/appRedux/actions"
import { BUNDLE_TYPES, getRecordTypeName, mockTeaserRecords, parseJSON, RecordType, ScrollTargets } from "../../util/IdentityProtectionHelper"
import { useDispatch, useSelector } from "react-redux"
import DocumentsTemplateCard from "../../Components/atomicDesign/templates/DocumentsTemplateCard/DocumentsTemplateCard"
import { useUser } from "../../../../dashboard-govplus-front/src/hooks/useUser"
import { useHistory } from "react-router-dom"
import { capitalizeFirstLetter } from "../../util/SolutionNames"
import Spinner from "../../Components/atomicDesign/atoms/Spinner/Spinner"
import { SvgStartApplicationCard } from "../../Components/atomicDesign/atoms/IconsSvg/svg/SvgStartApplicationCard"
import { useApplications } from "../../../../dashboard-govplus-front/src/hooks/useApplications"
import DocumentSection from "../../Components/atomicDesign/organisms/DocumentSection/DocumentSection"
import { DocumentBlockProps } from "../../util/DocumentsPageHelper"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
import { ProductsIds } from "../../util/ProductsIds"
import { DocumentsPageText, GeneralText } from "../../shared/text/Text"
import GProgressBar from "../../Components/atomicDesign/molecules/ProgressBar/ProgressBar"
import { calculateArithmeticMean } from "../../util/helper"

const SSNMockedCard = [
  {
    recordType: RecordType.SSN,
    isPresent: true,
  },
  {
    recordType: RecordType.ADDRESS,
    isPresent: true,
  }
]

const HeroBlock = () => {
  // @ts-ignore
  const { productsPresent } = useSelector(({ allProducts }) => allProducts);
  const breakpoint = useBreakpoint()
  const history = useHistory()

  const keys = ["SSN", "EIN", "COA", "CER", "PAS", "TSA", "ITIN", "TAX", "NAM"];

  return (
    <div className={"GDocuments__Hero"}>
      <div className={"GDocuments__Hero__Content"}>
        <div className={"GDocuments__Hero__Content__Left"}>
          <GText
            size={breakpoint.xs ? SIZE.PARAGRAPH_BOLD_16 : SIZE.PARAGRAPH_BOLD_18}
            color={COLORS_NEW.BLACK_500}
            text={DocumentsPageText.DocumentsPageHeader}
            innerHtml={DocumentsPageText.DocumentsPageHeader}
          />
          <GProgressBar
            showIcon
            filledText={GeneralText.Prefilled}
            value={calculateArithmeticMean(productsPresent, keys)}
          />
          <div className={"GDocuments__Hero__Content__Left__Actions"}>
            <GButton text={" Start a new application "} showIcon click={() => history.push('/formsApp')} />
          </div>
        </div>
        <div className={"GDocuments__Hero__Content__Right"}>
          <img src={heroPromoImg} alt={"Promo card"} />
        </div>
      </div>
    </div>
  )
}

const MyApplicationsSection: FC<DocumentBlockProps> = ({ content }) => {
  return (
    <DocumentSection
      title={"My applications"}
      hasTitleIcon
      titleIcon={<IconsSvg type={ICONS_SVG_TYPE.MY_APPLICATIONS} width={24} height={24} color={COLORS_NEW.BLACK_1000} />}
    >
      <div className={"GDocuments__Content"}>{content}</div>
    </DocumentSection>
  )
}

const MyAutoRenewalsSection: FC<DocumentBlockProps> = ({ content }) => {
  return (
    <DocumentSection
      title={"My automatic renewals"}
      hasTitleIcon
      titleIcon={<IconsSvg type={ICONS_SVG_TYPE.RENEWAL} width={24} height={24} color={COLORS_NEW.BLACK_1000} />}
    >
      <div className={"GDocuments__Content"}>{content}</div>
    </DocumentSection>
  )
}

const MyInsuredDocumentsSection: FC<DocumentBlockProps> = ({ content }) => {
  return (
    <DocumentSection
      title={"My insured documents"}
      hasTitleIcon
      titleIcon={
        <IconsSvg type={ICONS_SVG_TYPE.INSURANCE_DOCUMENTS} width={24} height={24} color={COLORS_NEW.BLACK_1000} />
      }
    >
      <div className={"GDocuments__Content"}>{content}</div>
    </DocumentSection>
  )
}

const IdentityProtectionSection: FC<DocumentBlockProps> = ({ content, additionalContent }) => {
  const breakpoint = useBreakpoint()
  return (
    <DocumentSection
      title={"My identity protection"}
      hasTitleIcon
      titleIcon={<IconsSvg type={ICONS_SVG_TYPE.MY_IDENTITY} width={24} height={24} color={COLORS_NEW.BLACK_1000} />}
    >
      <div>
        <GText
          text={DocumentsPageText.IdentityProtectionCoverage}
          size={breakpoint.xs ? SIZE.PARAGRAPH_BOLD_16 : SIZE.PARAGRAPH_BOLD_18}
          color={COLORS_NEW.BLACK_1000}
        />
      </div>
      <div className={"GDocuments__Content"}>{content}</div>
      {additionalContent}
    </DocumentSection>
  )
}

const Documents = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const breakpoint = useBreakpoint()

  const { renewedDocuments, insuredDocuments } = useSubscription()
  const { subscriptionIsActive, subscriptionIsCancelled } = useSubscription()
  const { userFlattenData } = useUser()
  const { filteredProducts } = useApplications()

  // @ts-ignore
  const { identities } = useSelector((state) => state.identityProtectionData)
  // @ts-ignore
  const { allProducts, allProductsLoader, anotherGovProducts } = useSelector(({ allProducts }) => allProducts);

  const subscriptionIsValid = subscriptionIsActive || subscriptionIsCancelled
  const firstName = userFlattenData?.data?.personal?.identity?.firstName
  const greeting = firstName ? `Welcome back, ${capitalizeFirstLetter(firstName)}!` : 'Welcome';

  const isIdentityProtectionActive = Boolean(identities?.records?.length)
  const isCreditMonitoringActive = Boolean(identities?.isAuthenticated)
  
  const getApplicationsData = Object.keys(filteredProducts)?.length > 0 ? filteredProducts : allProducts
  const bundleId = useMemo(() => parseJSON(identities)?.bundleId, [identities])

  const getImageBgColor = (isActive: boolean) => {
    return {
      background: isActive ? COLORS_NEW.ROYAL_BLUE_200 : COLORS_NEW.BLACK_100,
      opacity: isActive ? 1 : 0.6,
    }
  }

  const ctaFooter = (ctaText: string, showIcon = false) => {
    return (
      <div className={"GDocuments__Content__Card__CTA"}>
        <GButton text={ctaText} showIcon={showIcon} textSize={SIZE.PARAGRAPH_BOLD_14} />
      </div>
    )
  }

  const statusFooter = (status: string) => {
    return (
      <div className={"GDocuments__Content__Card__Status"}>
        <GText
          text={status}
          size={SIZE.PARAGRAPH_BOLD_14}
          color={COLORS_NEW.GREEN_700}
        />
        <IconsSvg type={ICONS_SVG_TYPE.CONFIRM} width={16} height={16} color={COLORS_NEW.GREEN_700} />
      </div>
    )
  }

  const iconSize = breakpoint.xs ? 24 : 28

  const getAutoRenewalIcon = (isActive: boolean) => {
    return (
      <IconsSvg
        type={ICONS_SVG_TYPE.REFRESH_NEW}
        width={iconSize}
        height={iconSize}
        color={isActive ? COLORS.WHITE : COLORS_NEW.BLACK_300}
        backgroundColor={isActive ? COLORS_NEW.GREEN_600 : COLORS_NEW.SLAYE_100} />
    )
  }

  const getApplicationIcon = (isActive: boolean) => {
    return isActive ? (
      <IconsSvg
        type={ICONS_SVG_TYPE.CHECK_CIRCLE}
        width={iconSize}
        height={iconSize}
        color={COLORS.WHITE}
        backgroundColor={COLORS_NEW.GREEN_600}
      />
    ) : null
  }

  const getInsuredDocsIcon = (isActive: boolean) => {
    return (
      <IconsSvg
        type={ICONS_SVG_TYPE.GUARD}
        width={iconSize}
        height={iconSize}
        color={isActive ? COLORS.WHITE : COLORS_NEW.BLACK_300}
        backgroundColor={isActive ? COLORS_NEW.GREEN_600 : COLORS_NEW.SLAYE_100}
      />
    )
  }

  const renderIdentityProtectionCards = () => {
    const cards = [
      {
        productName: "Credit monitoring",
        productImage: creditMonitoringLogo,
        isActive: isCreditMonitoringActive,
        scrollTarget: ScrollTargets.creditMonitoring,
        url: "/myIdentity",
        footer: isCreditMonitoringActive ? statusFooter("Active") : ctaFooter("Activate", true),
      },
      {
        productName: "Identity restoration",
        productImage: identityRestorationLogo,
        isActive: isIdentityProtectionActive,
        scrollTarget: ScrollTargets.identityInsurance,
        url: "/myIdentity",
        footer: isIdentityProtectionActive ? statusFooter("Active") : ctaFooter("Activate", true),
      },
      {
        productName: "Up to $1M coverage",
        productImage: oneMillionLogo,
        isActive: isIdentityProtectionActive,
        scrollTarget: ScrollTargets.identityInsurance,
        url: "/myIdentity",
        footer: isIdentityProtectionActive ? statusFooter("Active") : ctaFooter("Activate", true),
      },
    ];

    return (
      <>
        {cards.map((card, index) => (
          <div className="GDocuments__Content__Card" key={index}>
            <DocumentsTemplateCard
              productName={card.productName}
              productImage={card.productImage}
              imageBgColor={getImageBgColor(card.isActive)}
              isActive={card.isActive}
              footer={card.footer}
              click={() => {
                dispatch(setScrollTarget(card.scrollTarget));
                history.push(card.url);
              }}
            />
          </div>
        ))}
      </>
    );
  };

  const renderCards = useMemo(() => {
    if (!subscriptionIsValid || bundleId === BUNDLE_TYPES.INITIAL) {
      return mockTeaserRecords
    }
    else if (bundleId === BUNDLE_TYPES.MEDIUM || bundleId === BUNDLE_TYPES.ADVANCED) {
      return SSNMockedCard.concat(identities?.records)
    }
    else {
      return []
    }
  }, [bundleId, identities?.records, subscriptionIsValid])

  const renderIdentityProtectionRecords = () => {

    return (
      <>
        <GText
          text={DocumentsPageText.MonitoredInformation}
          size={breakpoint.xs ? SIZE.PARAGRAPH_BOLD_16 : SIZE.PARAGRAPH_BOLD_18}
          color={COLORS_NEW.BLACK_1000}
          style={{ marginTop: "8px" }}
        />

        <div className={"GDocuments__Content"}>
          {renderCards?.length > 0 && renderCards?.map((chip: { recordType: string, isPresent: boolean }, index: number) => {
            const recordType = chip?.recordType
            const isPresent = chip?.isPresent
            return (
              <div className={"GDocuments__Content__Chip"} key={index}>
                <DocumentsTemplateCard
                  productName={getRecordTypeName(recordType).replace("Social Security Number", "Social security Number")}
                  isActive={isPresent}
                  click={() => {
                    dispatch(setScrollTarget(ScrollTargets.personalInfo))
                    history.push("/myIdentity")
                  }}
                  footer={
                    isPresent
                      ? statusFooter("Monitored")
                      : ctaFooter("Monitor", false)}
                />
              </div>
            )
          })
          }
        </div>
      </>
    )
  }

  const renderAutoRenewalCards = () => {
    return renewedDocuments?.length
      ? renewedDocuments?.map((doc, index) => {
        const isActive = doc.activatedSince !== null;

        return (
          <div className={"GDocuments__Content__Card"} key={index}>
            <DocumentsTemplateCard
              productName={capitalizeFirstLetter(doc.solutionName!)}
              icon={getAutoRenewalIcon(isActive)}
              productImage={doc.image as string}
              isActive={isActive}
              imageBgColor={getImageBgColor(isActive)}
              click={() => history.push("/myAutomaticRenewals")}
              footer={isActive ? statusFooter("Active") : ctaFooter("Activate", true)}
            />
          </div>
        );
      })
      : (
        <div onClick={() => history.push('/myAutomaticRenewals')} className={"GDocuments__Content__Card"}>
          <SvgStartApplicationCard />
        </div>
      )
  };

  const renderApplicationCards = () => {
    return Object.keys(getApplicationsData).length > 0
      ? Object.keys(getApplicationsData).map((id) => {
        const product = allProducts[id];
        const isActive = product.completion === 100;
        const productImage = anotherGovProducts?.find((item: { id: any }) => item.id === allProducts[id]?.product_name)?.illustration

        const checkProductStatus = (productId: { toString: () => any }) => {
          const productName = allProducts[productId?.toString()]?.product_name;
          if (productName === ProductsIds.TsaId ||
            productName === ProductsIds.NAMId ||
            (
              allProducts[productId?.toString()]?.app_error !== null &&
              !allProducts[productId?.toString()]?.status.includes('error')
            )
          ) {
            history.push(`products/productInfo/${productId?.toString()}`)
          } else {
            history.push({
              pathname: `/forms/?formId=${allProducts[productId?.toString()]?.form_id}&&applicationId=${allProducts[productId?.toString()]?.application_id}`,
            })
          }
          dispatch(showModalsType(''))
          dispatch(setSelectedFormId(''))
        }

        return (
          <div className={"GDocuments__Content__Card"} key={id}>
            <DocumentsTemplateCard
              productName={product.form_name}
              productImage={productImage}
              icon={getApplicationIcon(isActive)}
              isActive={isActive}
              click={() => checkProductStatus(id)}
              completedPercentage={product.completion}
              hasProgressBar={true}
              footer={
                isActive
                  ? statusFooter("Application completed")
                  : ctaFooter("Continue", true)}
            />
          </div>
        );
      })
      : (
        <div onClick={() => history.push('/formsApp')} className={"GDocuments__Content__Card"}>
          <SvgStartApplicationCard />
        </div>
      )
  };

  const renderInsuredDocuments = () => {
    return insuredDocuments?.length
      ? insuredDocuments?.map((doc, index) => {
        const isActive = doc.insured;

        return (
          <div className={"GDocuments__Content__Card"} key={index}>
            <DocumentsTemplateCard
              productName={capitalizeFirstLetter(doc.solutionName).replace("Social Security card", "Social Security Card")}
              productImage={doc.image as string}
              icon={getInsuredDocsIcon(isActive)}
              isActive={isActive}
              imageBgColor={getImageBgColor(isActive)}
              click={() => history.push("/myInsuredDocuments")}
              footer={isActive ? statusFooter("Insured") : ctaFooter("Activate", true)}
            />
          </div>
        );
      })
      : (
        <div onClick={() => history.push('/formsApp')} className={"GDocuments__Content__Card"}>
          <SvgStartApplicationCard />
        </div>
      )
  };

  const onLoad = useCallback(() => {
    dispatch(getIdentities())
  }, [dispatch])

  useEffect(() => {
    if (subscriptionIsValid) {
      onLoad()
    }
  }, [onLoad, subscriptionIsValid])

  return allProductsLoader ? <Spinner /> : (
    <Suspense>
      <div className={"GDocuments"}>
        <div className={"GDocuments__Header"}>
          <GText size={breakpoint.xs ? SIZE.PARAGRAPH_BOLD_28 : SIZE.PARAGRAPH_BOLD_36} color={COLORS_NEW.BLACK_1000} style={{ letterSpacing: "-1px" }} text={greeting} />
        </div>
        <HeroBlock />
        <MyApplicationsSection content={renderApplicationCards()} />
        <MyAutoRenewalsSection content={renderAutoRenewalCards()} />
        <MyInsuredDocumentsSection content={renderInsuredDocuments()} />
        <IdentityProtectionSection content={renderIdentityProtectionCards()} additionalContent={renderIdentityProtectionRecords()} />
      </div>
    </Suspense>
  )
}

export default Documents
