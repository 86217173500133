import React, { FC } from "react"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import { Checkbox } from "antd"
import "./CheckBox.scss"

type GCheckBoxProps = {
  onChange?: any,
  label: string,
  checked?: boolean,
  innerHtml?: string
}

const GCheckBox: FC<GCheckBoxProps> = ({ label, onChange, checked ,innerHtml}) => {
  return (
    <Checkbox className={"GCheckbox"} checked={checked} onChange={onChange}>
      <GText text={label} innerHtml={innerHtml} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
    </Checkbox>
  )
}

export default GCheckBox
