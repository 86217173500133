import React, {useEffect} from "react";
import {setFirstUserSelectedProduct} from "../../appRedux/actions";
import {useDispatch, useSelector} from "react-redux";
import {getParams} from "../../assets/helpers/getParams";

export const useGetParamsAndSaveData = () => {
  const {anotherGovProducts} = useSelector(({allProducts}) => allProducts);
  const dispatch = useDispatch();
  const params = getParams(window.location.search, ["form_id", "service"]);

  useEffect(() => {
    let userSelectedCategory

    const webFlowServiceData = JSON.parse(localStorage.getItem("webFlowServicesData"))

    if (params?.form_id || params?.service) {
      anotherGovProducts.forEach(product => {
        if (product.collapseInfo.find(item => item.formId === params.form_id)) {
          userSelectedCategory = product.id
        }
      })

      sessionStorage.setItem("userSelectedFirstForm", JSON.stringify({
        service: params.service,
        formId: params.form_id,
        category: userSelectedCategory || ""
      }))

      if (webFlowServiceData) {
        localStorage.setItem("forBreadcrumbs", JSON.stringify({
          headerText: `${webFlowServiceData[params.form_id]?.title}`,
          value: webFlowServiceData[params.form_id]?.text
        }))
      }
      dispatch(setFirstUserSelectedProduct({
        userSelectedCategory,
        service: params.service,
        form_id: params.form_id,
      }))
    }
  }, [anotherGovProducts, params])
};
