import React, { FC } from "react"
import { BasicBlockContainerProps } from "../UploadPhotoService/interfaces/interfaces"
import ButtonComponent from "../ButtonComponent/ButtonComponent"
import loading from "../../../../assets/image/ico-loading-24px.svg"

const UploadPhotoLoadingButton: FC<BasicBlockContainerProps> = ({ item: { className, text } }) => (
  <ButtonComponent className={className} text={text} disabled={true} icon={loading} />
)

export default UploadPhotoLoadingButton
