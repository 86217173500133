import "./PurchasedOfferModal.scss"
import {PurchasedOfferModalProps} from "./PurchasedOfferModalProps";
import {FC} from "react";
import React from "react";
import GModal from "../GModal/GModal";
import {COLORS_NEW} from "../../types/Colors";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import GButton from "../../molecules/Button/GButton";

const PurchasedOfferModal: FC<PurchasedOfferModalProps> =
    ({
         offerName,
         isOpen,
         offerImage,
         currentSolution,
         onCancel,
         description
     }) => {
        const isSubscription = offerName?.includes("Unlimited") || offerName?.includes("Subscription") || offerName?.includes("Premium")
        return (
            <GModal open={isOpen} onCancel={onCancel} destroyOnClose={true} width={560}>
                <div className={'PurchasedOfferModal'}>
                    <div className={'title'}>
                        <GText text={`You successfully purchased ${offerName}!`} size={SIZE.HEADING_MDLG}
                               color={COLORS_NEW.BLACK_1000}/>
                    </div>
                    <div className={'offer-image'} style={{border: !isSubscription ? "1px solid #CACACA" : ""}}>
                        <img src={offerImage} width={isSubscription ? 410 : 317.25}/>
                    </div>
                    {description && (
                        <div className={'offer-description'}>
                            {description}
                        </div>
                    )}
                    <div className={"continue-button"}>
                        <GButton text={`Continue`} textSize={SIZE.PARAGRAPH_BOLD_16} showIcon={true} click={onCancel}/>
                    </div>
                    
                </div>
            </GModal>
        )
    }

export default PurchasedOfferModal;