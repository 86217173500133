import GAnalyticEvents from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/AnalyticEvents";
import OrderCompletedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/OrderCompleted/OrderCompletedBuilder";
import {getClientTokenForResponse} from "@dash/gov-plus__front-end__form/src/Redux/store/step/service.step.saga";
import UserIpRepository from "@dash/gov-plus__front-end__form/src/persistence/session/UserIpRepository";
import DocumentInsuranceStartedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/DocumentInsuranceStarted/DocumentInsuranceStartedBuilder";
import DocumentRenewalStartedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/DocumentRenewalStarted/DocumentRenewalStartedBuilder";
import DocumentInsuranceCompletedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/DocumentInsuranceCompleted/DocumentInsuranceCompletedBuilder";
import DocumentRenewalCompletedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/DocumentRenewalCompleted/DocumentRenewalCompletedBuilder";
import SubscriptionCancellationStartedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/SubscriptionCancellationStarted/SubscriptionCancellationStartedBuilder";
import SubscriptionCancellationConfirmedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/SubscriptionCancellationConfirmed/SubscriptionCancellationConfirmedBuilder";
import SubscriptionCancelledBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/SubscriptionCancelled/SubscriptionCancelledBuilder";
import IdentityProtectionCoverageStartedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/IdentityProtectionCoverageStarted/IdentityProtectionCoverageStartedBuilder";
import IdentityProtectionCoverageCompletedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/IdentityProtectionCoverageCompleted/IdentityProtectionCoverageCompletedBuilder";
import IdentityProtectionCreditScoreStartedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/IdentityProtectionCreditScoreStarted/IdentityProtectionCreditScoreStartedBuilder";
import IdentityProtectionRecordMonitoringUpdatedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/IdentityProtectionRecordMonitoringUpdated/IdentityProtectionRecordMonitoringUpdatedBuilder";
import IdentityProtectionAlertConfirmedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/IdentityProtectionAlertConfirmed/IdentityProtectionAlertConfirmedBuilder";
import IdentityProtectionAlertDismissedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/IdentityProtectionAlertDismissed/IdentityProtectionAlertDismissedBuilder";
import IdentityProtectionCreditScoreCompletedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/IdentityProtectionCreditScoreCompleted/IdentityProtectionCreditScoreCompletedBuilder";
import ServiceHostDashBoard from "@dash/gov-plus__front-end__form/src/services/requests/userService";
import {EventsHelper} from "@dash/gov-plus__front-end__form/src/util/EventsHelper";
import {IAddress} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/EventProperties";
import {userService} from "../api/api";
import UtmRepository from "@dash/gov-plus__front-end__form/src/persistence/cookies/UtmRepository";


export const useHelperSubscription = () => {
    
    async function triggerOrderCompletedEvent(response: any, eventType: string) {
        try {
            const data = response?.data?.event_order_completed
            const properties = data?.properties
            const product = properties?.products[0]
            const subscription = properties?.subscription[0]
            const orderCompletedBuilder = new OrderCompletedBuilder();
            orderCompletedBuilder.setIpAddress(data?.ip ?? "");
            orderCompletedBuilder.setEmail(data?.options?.traits?.email ?? "");
            orderCompletedBuilder.setEventType(eventType);
            orderCompletedBuilder
                .setGclid(properties?.gclid ?? "")
                .setMsclkid(properties?.msclkid ?? "")
                .setFbc(properties?.fbc ?? "")
                .setFbp(properties?.fbp ?? "")
                .setUtmMedium(properties?.utm_medium ?? "")
                .setUtmSource(properties?.utm_source ?? "")
                .setUtmCampaign(properties?.utm_campaign ?? "")
                .setUtmContent(properties?.utm_content ?? "")
                .setUtmTerm(properties?.utm_term ?? "")
                .setLastUtmMedium(properties?.utm_medium_last ?? "")
                .setLastUtmSource(properties?.utm_source_last ?? "")
                .setLastUtmCampaign(properties?.utm_campaign_last ?? "")
                .setLastUtmContent(properties?.utm_content_last ?? "")
                .setLastUtmTerm(properties?.utm_term_last ?? "")
            orderCompletedBuilder
                .setOrderId(properties?.order_id ?? "")
                .setValue(properties?.value ?? "")
                .setRevenue(properties?.revenue ?? "")
                .setCurrency(properties?.currency ?? "")
                .setCoupon(properties?.coupon ?? "")
            orderCompletedBuilder
                .setProductId(product?.product_id ?? "")
                .setSolution(product?.solution ?? "")
                .setService(product?.service ?? "")
                .setSubdivision(product?.subdivision ?? "")
                .setProductCategory(product?.category ?? "")
                .setProductQuantity(product?.quantity ?? "")
                .setProductName(product?.name ?? "")
                .setProductPrice(product?.price ?? "");
            orderCompletedBuilder
                .setSubscriptionId(subscription?.subscription_id ?? "")
                .setSubscriptionIdExternal(subscription?.subscription_external_id ?? "")
                .setSubscriptionCreationDate(subscription?.subscription_start_date ?? "")
                .setSubscriptionPaymentFrequency(subscription?.subscription_payment_frequency ?? "")
                .setSubscriptionPlan(subscription?.subscription_plan ?? "")
                .setSubscriptionPrice(subscription?.subscription_price ?? "")
                .setSubscriptionStatus(subscription?.subscription_status ?? "");
            await GAnalyticEvents.track(orderCompletedBuilder.build());
        } catch (error) {
            console.log(error)
        }
    }

    const triggerIdentifyEvent = async (
        customer: any,
        hasTransactions: boolean = false,
        isSubscribed: boolean = false
    ) => {
        const address: IAddress = {
            street: customer?.address1,
            city: customer?.city,
            state: customer?.state,
            postalCode: customer?.zip,
        }
        const userData = await ServiceHostDashBoard.getUsersData();
        const userProfile = await ServiceHostDashBoard.getUserProfile();

        const clientToken = getClientTokenForResponse()

        const response = await userService.getIntercomUserHash(clientToken)

        EventsHelper.triggerIdentify(
            userProfile.outerId,
            address,
            customer.email,
            customer.firstName,
            customer.lastName,
            customer.phone,
            customer.dateOfBirth,
            hasTransactions,
            isSubscribed,
            UtmRepository.getInstance().getUtm(),
            userProfile.authorise === 1, // isActivated
            response?.data?.user_hash,
            userData?.created_at
        )
    }

    function triggerDocumentInsuranceStartedAnalyticEvent(nameSolution: string | undefined) {
        let eventBuilder = new DocumentInsuranceStartedBuilder();
        if (eventBuilder) {
            eventBuilder
                .setDocumentSolution(nameSolution ?? "")
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    function triggerDocumentRenewalStartedAnalyticEvent(nameSolution: string | undefined) {
        let eventBuilder = new DocumentRenewalStartedBuilder();
        if (eventBuilder) {
            eventBuilder
                .setDocumentSolution(nameSolution ?? "")
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    function triggerDocumentInsuranceCompletedAnalyticEvent(nameSolution: string | undefined) {
        let eventBuilder = new DocumentInsuranceCompletedBuilder();
        if (eventBuilder) {
            eventBuilder
                .setDocumentSolution(nameSolution ?? "")
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    function triggerDocumentRenewalCompletedAnalyticEvent(nameSolution: string | undefined) {
        let eventBuilder = new DocumentRenewalCompletedBuilder();
        if (eventBuilder) {
            eventBuilder
                .setDocumentSolution(nameSolution ?? "")
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    function triggerSubscriptionCancellationStartedAnalyticEvent(subscriptionId: string | undefined) {
        let eventBuilder = new SubscriptionCancellationStartedBuilder();
        if (eventBuilder) {
            eventBuilder
                .setSubscriptionId(subscriptionId ?? "")
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    function triggerSubscriptionCancellationConfirmedAnalyticEvent(subscriptionId: string | undefined) {
        let eventBuilder = new SubscriptionCancellationConfirmedBuilder();
        if (eventBuilder) {
            eventBuilder
                .setSubscriptionId(subscriptionId ?? "")
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    function triggerSubscriptionCancelledAnalyticEvent(
        subscriptionId: string | undefined,
        subscriptionExternalId: string | undefined,
        subscriptionPlan: string | undefined,
        subscriptionFrequency: string | undefined,
        subscriptionPrice: string | undefined,
        subscriptionStatus: string | undefined,
        subscriptionStartDate: string | undefined,
        subscriptionCancellationReason: string | undefined,
        subscriptionCancellationChannel: string | undefined
    ) {
        let eventBuilder = new SubscriptionCancelledBuilder();
        if (eventBuilder) {
            eventBuilder
                .setSubscriptionId(subscriptionId ?? "")
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "")
                .setSubscriptionExternalId(subscriptionExternalId ?? "")
                .setSubscriptionPlan(subscriptionPlan ?? "")
                .setSubscriptionFrequency(subscriptionFrequency ?? "")
                .setSubscriptionPrice(subscriptionPrice ?? "")
                .setSubscriptionStatus(subscriptionStatus ?? "")
                .setSubscriptionStartDate(subscriptionStartDate ?? "")
                .setSubscriptionCancellationReason(subscriptionCancellationReason ?? "")
                .setSubscriptionCancellationChannel(subscriptionCancellationChannel ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    function triggerIdentityProtectionMonitoringStarted(bundle_id: string | undefined, package_name: string | undefined) {
        let eventBuilder = new IdentityProtectionCoverageStartedBuilder();
        if (eventBuilder) {
            eventBuilder
                .setBundleId(bundle_id ?? "")
                .setPackageName(package_name ?? "")
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    function triggerIdentityProtectionMonitoringCompleted(bundle_id: string | undefined, package_name: string | undefined) {
        let eventBuilder = new IdentityProtectionCoverageCompletedBuilder();
        if (eventBuilder) {
            eventBuilder
                .setBundleId(bundle_id ?? "")
                .setPackageName(package_name ?? "")
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    function triggerIdentityProtectionVerificationStarted(){
        let eventBuilder = new IdentityProtectionCreditScoreStartedBuilder();
        if (eventBuilder) {
            eventBuilder
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    function triggerIdentityProtectionVerificationCompleted(){
        let eventBuilder = new IdentityProtectionCreditScoreCompletedBuilder();
        if (eventBuilder) {
            eventBuilder
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    function triggerIdentityProtectionUpdated(recordType: string | undefined){
        let eventBuilder = new IdentityProtectionRecordMonitoringUpdatedBuilder();
        if (eventBuilder) {
            eventBuilder
                .setRecordType(recordType ?? "")
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    function triggerIdentityProtectionAlertConfirmed(alertClass: string | undefined, alertId: string | undefined, firstName: string | undefined, lastName: string | undefined, email: string | undefined, phone: string | undefined) {

        let eventBuilder = new IdentityProtectionAlertConfirmedBuilder();

        if (eventBuilder) {
            eventBuilder
                .setAlertClass(alertClass ?? "")
                .setAlertId(alertId ?? "")
                .setFirstName(firstName ?? "")
                .setLastName(lastName ?? "")
                .setEmail(email ?? "")
                .setPhone(phone ?? "")
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    function triggerIdentityProtectionAlertDismissed(alertClass: string | undefined, alertId: string | undefined, firstName: string | undefined, lastName: string | undefined, email: string | undefined, phone: string | undefined) {

        let eventBuilder = new IdentityProtectionAlertDismissedBuilder();

        if (eventBuilder) {
            eventBuilder
                .setAlertClass(alertClass ?? "")
                .setAlertId(alertId ?? "")
                .setFirstName(firstName ?? "")
                .setLastName(lastName ?? "")
                .setEmail(email ?? "")
                .setPhone(phone ?? "")
                .setIpAddress(UserIpRepository.getInstance().getValue() ?? "");
            GAnalyticEvents.track(eventBuilder.build());
        }
    }

    return {
        triggerOrderCompletedEvent,
        triggerDocumentInsuranceStartedAnalyticEvent,
        triggerDocumentRenewalStartedAnalyticEvent,
        triggerDocumentInsuranceCompletedAnalyticEvent,
        triggerDocumentRenewalCompletedAnalyticEvent,
        triggerIdentifyEvent,
        triggerSubscriptionCancellationStartedAnalyticEvent,
        triggerSubscriptionCancellationConfirmedAnalyticEvent,
        triggerSubscriptionCancelledAnalyticEvent,
        triggerIdentityProtectionMonitoringStarted,
        triggerIdentityProtectionMonitoringCompleted,
        triggerIdentityProtectionVerificationStarted,
        triggerIdentityProtectionVerificationCompleted,
        triggerIdentityProtectionUpdated,
        triggerIdentityProtectionAlertConfirmed,
        triggerIdentityProtectionAlertDismissed
    }
}