import TemplateOneBuilder from "../TemplateOneBuilder";
import {ITemplateOneBuilder} from "./ITemplateOneBuilder";
import {IUpsellProcessingData} from "../../../StepUpsell";
import TemplateOne from "../TemplateOne";
import renewalPasImage from "../../../../../../../../assets/image/upsells/renewal_pas.png";
import renewalTsaImage from "../../../../../../../../assets/image/upsells/renewal_tsa.png";
import {SolutionNames} from "../../../../../../../../util/SolutionNames";

export class AutomaticRenewalUpgrade implements ITemplateOneBuilder {
    upgradeAutomaticBenefits: string[] = [
        'Expedited application processing',
        'Expert application review to prevent mistakes and delays',
        'Protection against lost, stolen, and damaged documents',
        'Applications for your family members all in one place'
    ];

    buildTemplate(builder: TemplateOneBuilder, data: IUpsellProcessingData, solutionName: string): TemplateOne {
        let pageBuilder = builder
            .setImageHeader(this.getRenewalImage(solutionName))
            .setCheckListItems(this.upgradeAutomaticBenefits)
            .setPrice(`${data.price}`)
            .setTagInformation('Cancel anytime')
            .setImageHeaderSubtitle("With GOV+ Subscription, you also get:")
        return pageBuilder.build();
    }

    private getRenewalImage(solutionName: string): string | undefined {
        switch (solutionName) {
            case SolutionNames.PAS:
                return renewalPasImage;
            case SolutionNames.TSA:
                return renewalTsaImage;
            default:
                return undefined;
        }
    }

}