import {all, fork, put, takeEvery} from "redux-saga/effects";
import {billingApis} from "../../api/api";
import {CONFIRM_SUBSCRIPTION, GET_BILLING_INFORMATION} from "../../constants/ActionTypes";
import {getBillingInformationSuccess, setPaymentError} from "../actions/Billings";
import {notifyError} from "@dash/gov-plus__front-end__form/src/util/helper";

function* getBillingInformationSaga({payload}) {
  try {
    const response = yield billingApis.getCustomerBilling(payload);
    if (response.data.error)
      throw response.error.message
    yield put(getBillingInformationSuccess(response.data.success))
  } catch (err) {
    notifyError(err);
  }
}

function* confirmSubscriptionSaga({payload}) {
  try {
    const response = yield billingApis.confirmSubscription(payload);
    if (response.data.error) {
      yield put(setPaymentError(response.data.error))
    } else {
      // redirect to home or product page
    }
  } catch (err) {
    notifyError(err)
  }
}

export function* getBillingInformationSagaFork() {
  yield takeEvery(GET_BILLING_INFORMATION, getBillingInformationSaga);
}

export function* confirmSubscriptionSagaFork() {
  yield takeEvery(CONFIRM_SUBSCRIPTION, confirmSubscriptionSaga);
}

export default function* rootSaga() {
  yield all([
    fork(getBillingInformationSagaFork),
    fork(confirmSubscriptionSagaFork)
  ]);
}
