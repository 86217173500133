import React from "react"
import { Form, Input } from "antd"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import { connect } from "react-redux"

type InputComponentProps = {
  field: FieldInterface,
  value: string,
  dispatch: any,
}

const InputComponent2: React.FunctionComponent<InputComponentProps> = ({ field, value, dispatch }) => {
  return (
    <>
      <Form.Item
        label={field.label}
        name={field.label}
        initialValue={value || ""}
        rules={[{ required: true, message: "Required" }]}
      >
        <Input value={value} onChange={(e: any) => dispatch(setValueCurrentField(field.name, e.target.value))} />
      </Form.Item>
    </>
  )
}

const mapStateToProps = (state: any) => ({})

export default connect(mapStateToProps)(InputComponent2)
