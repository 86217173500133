import React from "react"

interface ISvgMagicPencil {
    width: number;
    height: number;
    color: string;
}

export const SvgMagicPencil = ({ width = 19, height = 19, color = "#3585FF" }: ISvgMagicPencil) => {
    return (
        <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3187_27205)">
                <path d="M11.2803 5.54165L13.6553 7.91665M4.15532 4.74998V7.91665M15.2387 11.0833V14.25M8.11365 1.58331V3.16665M5.73865 6.33331H2.57199M16.822 12.6666H13.6553M8.90532 2.37498H7.32199M17.3287 2.88165L16.3153 1.86831C16.2262 1.77831 16.1202 1.70687 16.0033 1.65811C15.8865 1.60935 15.7611 1.58424 15.6345 1.58424C15.5079 1.58424 15.3825 1.60935 15.2656 1.65811C15.1488 1.70687 15.0427 1.77831 14.9537 1.86831L2.06532 14.7566C1.97532 14.8457 1.90387 14.9518 1.85512 15.0686C1.80636 15.1855 1.78125 15.3109 1.78125 15.4375C1.78125 15.5641 1.80636 15.6895 1.85512 15.8063C1.90387 15.9232 1.97532 16.0292 2.06532 16.1183L3.07865 17.1316C3.16718 17.2226 3.27303 17.2949 3.38997 17.3443C3.50691 17.3937 3.63255 17.4191 3.75949 17.4191C3.88642 17.4191 4.01206 17.3937 4.129 17.3443C4.24594 17.2949 4.35179 17.2226 4.44032 17.1316L17.3287 4.24331C17.4196 4.15479 17.4919 4.04893 17.5413 3.93199C17.5907 3.81506 17.6161 3.68941 17.6161 3.56248C17.6161 3.43555 17.5907 3.3099 17.5413 3.19296C17.4919 3.07603 17.4196 2.97017 17.3287 2.88165Z" stroke={color} strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_3187_27205">
                    <rect width="19" height="19" fill={"white"}/>
                </clipPath>
            </defs>
        </svg>

    )
}
