import GModal from "../GModal/GModal";
import ActivateRenewal from "../ActivateRenewal/ActivateRenewal";
import React, {FC} from "react";
import { IProductRenewalForm } from "../../types/Models";


interface ActivateRenewalModalProps {
    isOpen: boolean,
    onClose: () => void,
    onSubmit: (data: any) => void,
    product:  IProductRenewalForm
}

const ActivateRenewalModal: FC<ActivateRenewalModalProps> = ({
     isOpen,
     onClose,
     onSubmit,
     product
 }) => {
    return (
        <GModal open={isOpen} onCancel={onClose} width={527}>
            <ActivateRenewal onSubmit={onSubmit} product={product}/>
        </GModal>
    )
}

export default ActivateRenewalModal