/* eslint-disable indent */
import React from "react"
import { connect } from "react-redux"
import AdditionalCoa from "./AdditionalPageByType/coa/AdditionalCoa"
import StatusCheckEinComponent from "./AdditionalPageByType/StatusCheckEinComponent/StatusCheckEinComponent"

type AdditionalPageComponentProps = {
  additionalPage: {} | any,
  dispatch: any,
}

const switchAdditionalPage = (additionalPage: {} | any) => {
  switch (additionalPage?.type) {
    case "status-check-ein":
      return <StatusCheckEinComponent additionalPage={additionalPage} />
    case "additional-сoa":
      return <AdditionalCoa additionalPage={additionalPage} />
    default:
      return <div>Влад, ты неправильно прописал тип</div>
  }
}
// eslint-disable-next-line max-len
const AdditionalPageComponent: React.FunctionComponent<AdditionalPageComponentProps> = ({ additionalPage }) => {
  return (
    <div
      // eslint-disable-next-line max-len
      className={`additional-page__container ${additionalPage?.type} ${additionalPage?.name}`}
    >
      {switchAdditionalPage(additionalPage)}
    </div>
  )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(AdditionalPageComponent)
