import React from "react"

interface ISvgGift {
  width: number;
  height: number;
  color: string;
}

export const SvgGift = ({ width, height, color }: ISvgGift) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5508 12.2109V22.2109H4.55078V12.2109"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5508 7.21094H2.55078V12.2109H22.5508V7.21094Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.5508 22.2109V7.21094" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.5508 7.21094H8.05078C7.38774 7.21094 6.75186 6.94755 6.28301 6.4787C5.81417 6.00986 5.55078 5.37398 5.55078 4.71094C5.55078 4.0479 5.81417 3.41201 6.28301 2.94317C6.75186 2.47433 7.38774 2.21094 8.05078 2.21094C11.5508 2.21094 12.5508 7.21094 12.5508 7.21094Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5508 7.21094H17.0508C17.7138 7.21094 18.3497 6.94755 18.8185 6.4787C19.2874 6.00986 19.5508 5.37398 19.5508 4.71094C19.5508 4.0479 19.2874 3.41201 18.8185 2.94317C18.3497 2.47433 17.7138 2.21094 17.0508 2.21094C13.5508 2.21094 12.5508 7.21094 12.5508 7.21094Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
