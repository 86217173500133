import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Form, Checkbox } from "antd"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { setObservers, setValueCurrentField } from "../../../../Redux/store/form/form.actions"

type CheckBoxComponentProps = {
  field: FieldInterface,
  value: string,
  billingAddress: {} | any,
  dispatch: any,
}

// eslint-disable-next-line max-len
const CheckBoxComponent: React.FunctionComponent<CheckBoxComponentProps> = ({
  field,
  value,
  billingAddress,
  dispatch,
}) => {
  const [checked, setChecked] = useState(true)

  useEffect(() => {
    dispatch(setValueCurrentField(field.name, "" + checked))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCheckboxChange = async (e: any) => {
    await setChecked(e.target.checked)
    dispatch(setValueCurrentField(field.name, "" + e.target.checked))
    if (field.observable) {
      dispatch(setObservers(field.observable, e.target.checked))
    }
  }

  return (
    <Form.Item name={field.name} className={`check-box ${field.type} ${field.name}`}>
      <Checkbox
        checked={checked}
        onChange={onCheckboxChange}
        className={`check-box__content-container`}
        onClick={(e: any) => {
          e.stopPropagation()
        }}
      >
        <p className={`check-box__label`}>{field.label}</p>
        <p className={`check-box__content-text`}>
          {billingAddress &&
            // eslint-disable-next-line max-len
            `${billingAddress.address1}, ${billingAddress.city_name}, ${
              billingAddress.state_code || billingAddress.province
            }, ${billingAddress.zipcode}`}
        </p>
      </Checkbox>
    </Form.Item>
  )
}

const mapStateToProps = (state: any) => ({
  billingAddress: state.form.billingAddress,
})

export default connect(mapStateToProps)(CheckBoxComponent)
