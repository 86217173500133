import React, { useEffect } from "react"
import { connect, useSelector } from "react-redux"
import { Form } from "antd"
import FormTools from "../FormTools/FormTools"
import {
  confirmSubscription,
  setPaymentError,
  setValueCurrentField,
  submitForm,
  setStripeLoaded,
  payGovernmentFees,
  payGovernmentFeesFromScrap,
  clearSubmitByEmptyPropStore, payApplicationFee, setPaymentMethod, showStripeForm, showAddPaymentButton,
} from "../../../Redux/store/form/form.actions"
import { setCurrentStep, setEventPageName, setIndexCurrentStep } from "../../../Redux/store/step/step.actions"
import { setInitialHelper } from "../../../Redux/store/form/form.actions"
import ListBlocksFormWithFields from "../ListBlocksFormWithFields/ListBlocksFormWithFields"
import { StepInterface } from "../../../Redux/InterfacesEntity/step.interface"
import {
  approveAdress,
  setFields,
  setStatusSignature,
  submitSignature,
} from "../../../Redux/store/additionalForm/additional.actions"
import AdditionalTabletHelperComponent from "../PostSubmitComponents/AdditionalTabletHelperComponent/AdditionalTabletHelperComponent"
import { setFirstMistakeName } from "../../../Redux/store/validation/validation.actions"
import { useHistory } from "react-router-dom"
import { makeCustomerSupport } from "../../../Redux/store/email/email.action"
import { compareSubmit, crutSubmit } from "./crutsSubmit/CrutSubmit"
import checkGetParamInUrl from "./checkGetParamInUrl"
import { handlerClickButtonPassportDetails } from "../PostSubmitComponents/PassportDetailsComponent/PassportDetailsComponent"
import { RootState } from "../../../App"
import {
  additionalSelector,
  arrayStepSelector,
  formSelector,
} from "../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { ItemInterface } from "../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import DescriptionPageComponent from "../ListFormsStep/DespcriptionPageComponent"
import {PaymentRequestTokenEvent, Stripe, StripeElement} from "@stripe/stripe-js"
import { clearSubmitByEmptyProp } from "../../../Redux/store/form/servicePayment"
import { setLoadingButtonState } from "../../../Redux/store/loading/loading.action"
import { LoadingState } from "../../../shared/constans/user-from-view-mode.enum"
import {BillingInformation, Charge} from "../BlockFormWithFields/BillingInformation/BlockBillingInformation"
import {submitGLO} from "../../../Redux/store/user/user.actions";
import {
  ADDITIONAL_STEP_GOVERNMENT_FEE, COMBINED_PAYMENT_METHOD_APPLICATION_ONLY,
  STEP_NAME_PAYMENT_PAGE, STEP_PAYMENT_TYPE_APPLICATION, STEP_PAYMENT_TYPE_COMBINED,
  STEP_PAYMENT_TYPE_SUBSCRIPTION,
  STEP_TYPE_PAYMENT,
  STEP_TYPE_SCRAP_CARD, SUBSCRIPTION_PERIOD_MONTHLY, SUBSCRIPTION_PERIOD_YEARLY
} from "../../../shared/constans/payment";
import {ProductsIds} from "../../../util/ProductsIds";
import {EntryPointFlows} from "../../../util/EntryPointFlows";
import {EventsHelper, EventsProductCategory} from "../../../util/EventsHelper";
import {CurrencyKey} from "../../../util/CurrencyHelper";
import {getPropertiesForEvents, getServiceAndSolutionNameForFormsHeaders} from "../../../services/gtm/backEndEvent";
import {FormsSteps} from "../Steps/Steps";
import {PaymentValues} from "../../../domain/Payment/PaymentValues";
import ToApplyFormIdRepository from "../../../persistence/session/ToApplyFormIdRepository";
import ToApplyServiceIdRepository from "../../../persistence/session/ToApplyServiceIdRepository";
import {PaymentError} from "../../../dto/PaymentError";
import {FormError} from "../../../dto/FormError";
import GNotification, {NOTIFICATION_TYPE} from "../../atomicDesign/organisms/Notification/Notification";
import {ICONS_SVG_TYPE} from "../../atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import "./FormBlock.scss"
import {CreditCardToken, PaymentRequestEvent, PaymentRequestToken} from "../../atomicDesign/types/types";
import {NEW_PAYMENT_METHOD_VALUE} from "../../../util/PaymentMethodsHelper";
import {FEE_ITEM_KEY} from "../../../util/FeeItemKey";
import {setUserCouponState, submitGetUserCoupon} from "../../../Redux/store/billing/billing.action";
import OfferCouponRepository from "../../../persistence/session/OfferCouponRepository";
import { Path } from "../../../util/PageHelper"
// @ts-ignore
import { useNavigationHistory } from "../../../../../dashboard-govplus-front/src/hooks/useNavigation"
// @ts-ignore
import { useABTestingPayment } from "../../../../../dashboard-govplus-front/src/hooks/useABTestingPayment";
// @ts-ignore
import { useUrlParams } from "../../../../../dashboard-govplus-front/src/hooks/useUrlParams";
import {ServicesIds} from "../../../util/ServicesIds";

export const getTopScrollPosition = () => {
  document
      .querySelector(".ant-layout-header")
      ?.scrollIntoView({ block: "start", inline: "start" })

}

export const getScrollElementById = ({ id }: { id: string }) => {
  if (id.includes("phone")) {
    document
      .getElementById("phone_wrapper_scrollLabelById")
      ?.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
    return
  }
  if (id.includes("date")) {
    document
        .getElementById("date-picker")
        ?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    return
  }
  if (id.includes("height")) {
    document
        .getElementById("height_scrollLabelById")
        ?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    return
  }

  document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
}

export const getScrollElementByClassName = ({ className }: { className: string }) => {
  if (className.includes("phone")) {
    document
      .getElementById("phone_wrapper_scrollLabelById")
      ?.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
    return
  }
  document.getElementsByClassName(className)[0]?.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
}

type FormBlockProps = {
  formContainer: {} | any,
  arraySteps: [],
  indexCurrentStep: number,
  submitObject: any,
  currentStep: StepInterface | any,
  statusSignature: string,
  currentResponse: {} | any,
  appId: string,
  formId: string,
  billingAddress: {} | any,
  eventPageName: string,
  dispatch: any,
  stripeLoaded: boolean | null,
  tagStoreWithFields: { [key: string]: string },
  tagFields: { [key: string]: string },
  billingInformation: BillingInformation,
  paymentError: PaymentError,
  formError: FormError,
  userCoupon?: any,
  charges?: Charge[],
  loadingState?: string
}

export const setCurrentIndexInURL = (index: number, arraySteps: [], history: any) => {
  let checkIndex
  if (index === arraySteps.length || index < 0) {
    checkIndex = 0
  } else {
    checkIndex = index
  }

  let params = new URLSearchParams(window.location.search).toString()
  // cut and concat parts without &indexCurrentStep=""
  params =
    params.split("&indexCurrentStep")[0] +
    ((params.split("&indexCurrentStep")[1] &&
      "&" + params.split("&indexCurrentStep")[1].split("&").slice(1).join("&")) ||
      "")
  history.push(`?${params}&indexCurrentStep=${checkIndex}`)
}

const FormBlock: React.FunctionComponent<FormBlockProps> = ({
  formContainer,
  arraySteps,
  indexCurrentStep,
  submitObject,
  currentStep,
  statusSignature,
  currentResponse,
  appId,
  formId,
  billingAddress,
  eventPageName,
  dispatch,
  stripeLoaded,
  tagStoreWithFields,
  tagFields,
  billingInformation,
  paymentError,
  formError,
  userCoupon,
  charges,
  loadingState
}) => {

  let history = useHistory()
  const header = formContainer?.header?.header

  const description = formContainer?.description

  const displayListFieldsForm = arraySteps ? indexCurrentStep < arraySteps.length : false

  const {navigate} = useNavigationHistory()
  const {page} = useUrlParams()

  let stripe: Stripe | null = null
  let elements: any = null

  const currentOfferCoupon = OfferCouponRepository.getInstance().getValue();

  const {getOneTimeFeePaymentOptions} = useABTestingPayment();

  useEffect(() => {
    if(billingInformation?.payment_methods?.length > 0){
      const id = billingInformation.default_payment_methods ? billingInformation.default_payment_methods: billingInformation?.payment_methods[0].id
      dispatch(setPaymentMethod(id))
      dispatch(showStripeForm(false))
      dispatch(showAddPaymentButton(true))
    }
  }, []);

  const handlerAnyClickButton = (nextIndex: number) => {
    dispatch(setCurrentStep(nextIndex))
    dispatch(setInitialHelper())
  }

  const handleDecrementClick = () => {
    if (indexCurrentStep >= 0) {
      dispatch(setIndexCurrentStep(indexCurrentStep - 1))
      handlerAnyClickButton(indexCurrentStep - 1)
      setCurrentIndexInURL(indexCurrentStep - 1, arraySteps, history)
    }
    if (currentStep.name === "sss-after4") {
      dispatch(setStatusSignature(""))
    }
    setTimeout(() => getTopScrollPosition(), 100)
  }

  const setStripeElements = (stripeParam: Stripe, elementParam: StripeElement) => {
    stripe = stripeParam
    elements = elementParam
  }

  const payWithGatewayPaymentToken = async (event: PaymentRequestTokenEvent, coupon: any, subscriptionPlanMethod?: string | null) => {
    const creditCardToken: CreditCardToken = {tokenSourceName: event.walletName, tokenId: event.token.id};

    const requestEvent: PaymentRequestToken = {
      event: event,
      creditCardToken: creditCardToken,
      requestCompletedCallback: completePaymentRequestEvent
    };

    let values: any = {
      payment_method: NEW_PAYMENT_METHOD_VALUE,
      paymentRequestEvent: requestEvent,
      period: subscriptionPlanMethod,
      itemPriceChargeId: subscriptionPlanMethod
    };

    if (blocksStep?.type === ADDITIONAL_STEP_GOVERNMENT_FEE) {
      values[FEE_ITEM_KEY] = submitObject[FEE_ITEM_KEY];
    }

    await chargebeePay(values, coupon);
  }

  /**
   * Complete the payment request event, and close the apple/google pay window
   */
  const completePaymentRequestEvent = (event: PaymentRequestEvent) => {
    event.session?.completePayment(event.status) || event.complete?.("success");
  }

  const chargebeePay = async (values: {} | any, coupon: any = null) => {
    dispatch(setLoadingButtonState(LoadingState.Loading))

    let newSubmitObject = { ...compareSubmit(submitObject), form_id: formId }
    if (currentStep.name === STEP_NAME_PAYMENT_PAGE &&
        (
            currentStep.paymentType === STEP_PAYMENT_TYPE_SUBSCRIPTION ||
            (
                currentStep.paymentType === STEP_PAYMENT_TYPE_COMBINED &&
                [SUBSCRIPTION_PERIOD_MONTHLY, SUBSCRIPTION_PERIOD_YEARLY].includes(values?.period)
            )
        )
    ) {
      dispatch(confirmSubscription({ newSubmitObject, values }))
    } else if (currentStep.name === STEP_NAME_PAYMENT_PAGE &&
        (
            currentStep.paymentType === STEP_PAYMENT_TYPE_APPLICATION ||
            currentStep.paymentType === STEP_PAYMENT_TYPE_COMBINED
        )
    ) {
      const currentCoupon = coupon ?? userCoupon;
      const abTestOneTimeFee = getOneTimeFeePaymentOptions()
      newSubmitObject = { ...newSubmitObject, ...abTestOneTimeFee }
      dispatch(payApplicationFee({ newSubmitObject, values, arraySteps, indexCurrentStep, userCoupon: currentCoupon }))
    } else if (blocksStep.type === ADDITIONAL_STEP_GOVERNMENT_FEE) {
      dispatch(payGovernmentFees({ newSubmitObject, values, arraySteps, indexCurrentStep }))
    }


    dispatch(setPaymentError(null))
  }

  const handleIncrementClick = () => {
    if (
      currentStep.name !== "get_new_social_security_card" &&
      currentStep.type !== "submit_payment" &&
      currentStep.type !== "customer_support" &&
      currentStep.type !== "submit_glo" &&
      currentStep.type !== "submit_form"
    ) {
      setTimeout(() => getTopScrollPosition(), 150)
    }
    if (
      statusSignature !== "process" &&
      currentStep.name !== "sss-after2" &&
      arraySteps &&
      indexCurrentStep < arraySteps.length - 1
    ) {
      dispatch(setIndexCurrentStep(indexCurrentStep + 1))
      handlerAnyClickButton(indexCurrentStep + 1)
    }
    if (
      statusSignature !== "process" &&
      currentStep.name !== "sss-after2" &&
      arraySteps &&
      indexCurrentStep === arraySteps.length - 1
    ) {
      dispatch(setIndexCurrentStep(arraySteps.length))
      dispatch(setCurrentStep(arraySteps.length - 1))
    }
    if (statusSignature === "process" && currentStep.name !== "sss-after2") {
      dispatch(setIndexCurrentStep(indexCurrentStep - 1))
      dispatch(setCurrentStep(indexCurrentStep - 1))

      dispatch(setStatusSignature(""))
      dispatch(setFields(submitObject))
    }
    if (currentStep.name === "sss-after2") {
      const adress = {
        address1: submitObject.address1,
        address2: submitObject.address2,
        city_name: submitObject.city_name,
        state_code: submitObject.state_code,
        zipcode: submitObject.zipcode,
        name: currentResponse.verifiedAddress.original.name,
        client_token: appId || "",
        form_id: formId,
      }
      sessionStorage.setItem("address_validation_event", "")
      dispatch(approveAdress(adress))
    }
  }

  interface ArraySsnInterface {
    ssnName: string;
    ssnNameHidden: string;
    typePageSubmit: string;
  }

  interface valuesSubmitInterface {
    ssn_descendent: string;
    ssn_descendent_hidden: string;
    ssn_applicant: string;
    ssn_applicant_hidden: string;
    ssn_mother_num: string;
    ssn_mother_num_hidden: string;
    ssn_father_num: string;
    ssn_father_num_hidden: string;
    ssn: string;
    ssn_hidden: string;
  }

  const getArraySSN = () => [
    {
      ssnName: "ssn_descendent",
      ssnNameHidden: "ssn_descendent_hidden",
      typePageSubmit: "submit_form",
    },
    {
      ssnName: "ssn_applicant",
      ssnNameHidden: "ssn_applicant_hidden",
      typePageSubmit: "submit_form",
    },
    {
      ssnName: "ssn_mother_num",
      ssnNameHidden: "ssn_mother_num_hidden",
      typePageSubmit: "submit_form",
    },
    {
      ssnName: "ssn_father_num",
      ssnNameHidden: "ssn_father_num_hidden",
      typePageSubmit: "submit_form",
    },
    {
      ssnName: "ssn",
      ssnNameHidden: "ssn_hidden",
      typePageSubmit: "submit_form",
    },
  ]

  const mapArraySSN = (
    arraySSN: ArraySsnInterface[],
    submitObject: valuesSubmitInterface,
    mutateSubmitObject: boolean = false
  ) => arraySSN.forEach((ssnItem: ArraySsnInterface) => setValueSSN(ssnItem, submitObject, mutateSubmitObject))

  const setValueSSN = (
    { ssnName, ssnNameHidden, typePageSubmit }: ArraySsnInterface,
    submitObject: valuesSubmitInterface | any,
    mutateSubmitObject: boolean = false
  ) => {
    if (submitObject[ssnName] !== submitObject[ssnNameHidden] && submitObject[ssnName]) {
      if (mutateSubmitObject) {
        submitObject[ssnName] = submitObject[ssnNameHidden]
      }
      return dispatch(setValueCurrentField(ssnName, submitObject[ssnNameHidden]))
    } else {
      return undefined
    }
  }

  const successPageSubmit = useSelector<RootState, { successPageSubmit: { fields: {} | any } } | any>(
    additionalSelector
  ).successPageSubmit

  const blocksStep = useSelector<RootState, { currentBlock: any }>(arrayStepSelector).currentBlock
  const submitError = useSelector<RootState, { submitError: {} | any }>(formSelector).submitError

  useEffect(() => {
    if (submitError?.error?.data && document.getElementsByClassName("submitError")[0]) {
      document
        .getElementsByClassName("submitError")[0]
        .scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }
  }, [submitError])

  const triggerCheckoutStartedEvent = async () => {
    let price: string = "";

    /**
     * Searching by the block name subscription_plans. This block is who have the price information
     */
    const blockFormSubscription = formContainer?.blocksWithFieldsOrNestBlocks?.filter((obj: any) => {
      return obj.name === "subscription_plans";
    });

    /**
     * If we found the block subscription_plans we get the price
     */
    if(blockFormSubscription && blockFormSubscription.length > 0){

      /**
       * The price is in the content object in priceInformation object in price property.
       * Remove the $ symbol from price to send to analytics
       */
      price = blockFormSubscription[0]?.priceInformation?.content?.price.replace("$", "");
    }

    let productCategory = undefined
    if (currentStep.name === STEP_NAME_PAYMENT_PAGE) {
      switch (currentStep.paymentType) {
        case STEP_PAYMENT_TYPE_SUBSCRIPTION:
          productCategory = EventsProductCategory.PRODUCT_CATEGORY_SUBSCRIPTION
          break
        case STEP_PAYMENT_TYPE_APPLICATION:
          productCategory = EventsProductCategory.PRODUCT_CATEGORY_ONE_TIME_FEE
          break
      }
    }

    const {form_id} = getPropertiesForEvents();
    const {service, solutionName, subCategory} = getServiceAndSolutionNameForFormsHeaders();

    if (charges?.length) {
      price = String(charges[0].price?.toString() || price);
    }

    await EventsHelper.triggerCheckoutStarted(
        price,
        price,
        CurrencyKey.USD,
        form_id,
        service,
        solutionName,
        price,
        PaymentValues.PURCHASE_DEFAULTS_QUANTITY,
        productCategory,
        subCategory
    )
  }

  useEffect(() => {
    /**
     * Current step is the JSON that come from backend and if type is submit_payment that mean we arrive to payment page
     */
    if (page === "payment") {
      if (currentOfferCoupon && !userCoupon) {
        dispatch(submitGetUserCoupon());
      }
    } else if(currentOfferCoupon) {
      dispatch(setUserCouponState(null))
    }
  }, [page])

  useEffect(() => {
    if (page === 'payment' && loadingState === LoadingState.Loaded) {
      triggerCheckoutStartedEvent();
    }
  }, [page, loadingState])

  const onFinish = async (values: {} | any) => {
    if (currentStep.formType === STEP_TYPE_PAYMENT || blocksStep.formType === STEP_TYPE_PAYMENT) {
      await chargebeePay(values)
    } else if (currentStep.formType === STEP_TYPE_SCRAP_CARD || blocksStep.formType === STEP_TYPE_SCRAP_CARD) {
      let newSubmit = Object.fromEntries(
        Object.entries(submitObject).filter(([key]) => submitObject[key] !== "" && submitObject[key] !== undefined)
      )
      newSubmit = { ...newSubmit, ...values }
      dispatch(payGovernmentFeesFromScrap(newSubmit))
    } else if (
      blocksStep?.["type"] === "invalid_old_address" ||
      blocksStep?.["type"] === "invalid_new_address" ||
      blocksStep?.["type"] === "invalid_address"
    ) {
      sessionStorage.setItem("address_validation_event", "")
      const newSubmit = Object.fromEntries(
        Object.entries(submitObject).filter(([key]) => submitObject[key] !== "" && submitObject[key] !== undefined)
      )
      dispatch(approveAdress({ ...newSubmit, name: currentResponse?.verifiedAddress?.original?.name }))
    } else {
      //for send event only one time on payment-page
      if (currentStep.type === "submit_form" || currentStep.name === "mailing_address") {
        sessionStorage.setItem("backEndEvent", "payment")
        dispatch(setEventPageName("payment"))
      }

      if (values["passport_type"] || blocksStep?.["type"] === "additional_information_step") {
        // for render or don't blocks
        mapArraySSN(getArraySSN(), submitObject, true)
        const setUploadPermission = !blocksStep.statusDependencies?.values.includes(
          submitObject[blocksStep.statusDependencies?.variable]
        )
        const clearedSubmit = clearSubmitByEmptyProp({ ...submitObject })
        const copySubmitObject = { ...clearedSubmit }
        delete copySubmitObject.form_type
        handlerClickButtonPassportDetails(
          setUploadPermission,
          copySubmitObject,
          dispatch,
          successPageSubmit,
          appId,
          formId
        )
        return
      }

      mapArraySSN(getArraySSN(), submitObject, true)

      mapArraySSN(getArraySSN(), submitObject)
      if (!new URLSearchParams(window.location.search).get("formId")) {
        checkGetParamInUrl(history)
      }
      setCurrentIndexInURL(indexCurrentStep + 1, arraySteps, history)

      dispatch(setFirstMistakeName(""))
      handleIncrementClick()

      if (
        (statusSignature !== "process" && currentStep.name === "mailing_address") ||
        currentStep.type === "submit_form"
      ) {
        // for fix submitObj before submit
        let newSubmitObject = { ...crutSubmit(submitObject), flattenData: { ...tagStoreWithFields, ...tagFields } }
        const getOnlyNumberFormatPhone = (phoneNumber: string) => phoneNumber?.replaceAll(/[^0-9]/g, "")
        if (submitObject["phone"]) {
          newSubmitObject = {
            ...newSubmitObject,
            phone: getOnlyNumberFormatPhone(submitObject["phone"]),
            phone_mask: submitObject["phone"],
          }
        }
        const clearedSubmit = clearSubmitByEmptyProp(newSubmitObject)
        dispatch(clearSubmitByEmptyPropStore(clearedSubmit))
        dispatch(submitForm({ newSubmitObject: clearedSubmit, values, arraySteps, indexCurrentStep }))
      }

      if (currentStep.name === "customer-support") {
        dispatch(makeCustomerSupport(submitObject))
      }

      if (currentStep.type === "submit_additional_form") {
        dispatch(
          submitSignature({
            fields: submitObject,
            client_token: appId,
            form_id: formId,
          })
        )
      }

      if (currentStep.type === "submit_glo") {
        /**
         * In the submit GLO we need to send the fields that are in the tagStoreWithFields
         * tagStoreWithFields - is the object that contains the fields that are filled with autofilled
         * tagFields - is the object that contains the fields we send to backend in submitGlo action
         */
        const tagFieldsFixed = includeFieldsForGlo(tagFields, tagStoreWithFields)
        
        dispatch(submitGLO({submitObject, tagFields: tagFieldsFixed, redirectCallBack: () => {
          getTopScrollPosition()
          navigate(Path.NEW_APPLICATION)
        }}))
      }

      if(formId !== ProductsIds.GloFormId) {
        ToApplyFormIdRepository.getInstance().removeItem();
        ToApplyServiceIdRepository.getInstance().removeItem();
        sessionStorage.removeItem(EntryPointFlows.EntryPoint)
      }
    }
  }

  /**
   * Include fields that are in tagStoreWithFields but not in fields
   *
   * @param fields
   * @param tagStoreWithFields
   */
  const includeFieldsForGlo = (fields: any, tagStoreWithFields: any) => {
    for (let key in tagStoreWithFields) {
      if(!fields.hasOwnProperty(key)) {
        fields[key] = tagStoreWithFields[key]
      }
    }
    return fields;
  }

  const onFinishFailed = (errorInfo: {} | any) => {
    getScrollElementById({ id: `${errorInfo?.errorFields[0]?.name}_scrollLabelById` })
  }

  const [form] = Form.useForm()

  const parseDescription = (description: ItemInterface | string) =>
    typeof description === "string" ? description : <DescriptionPageComponent description={description} />

  if (currentStep.formType === "payment" && stripeLoaded === undefined) {
    dispatch(setStripeLoaded(false))
  }

  const getFormError = (): string | undefined => {
    if (currentStep.formType !== STEP_TYPE_PAYMENT) {
      return undefined;
    }

    return formError?.error ?? paymentError?.errorDescription;
  }

  const hasFormError = getFormError()

  return (
    <>
      <Form
        className={`form-block__main form-block__main_${formContainer.type || "base"}`}
        form={form}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {formContainer.type === "package-page" && <h2 className={"package-page__form-header"}>{header}</h2>}
        {description && <p className={"package-page__form-description "}> {parseDescription(description)}</p>}
        {displayListFieldsForm && (
          <div className={'g-form-block-errors-wrapper'}>
            <ListBlocksFormWithFields
                onGatewayPaymentStartedWithToken={payWithGatewayPaymentToken}
                setStripeElements={setStripeElements}
                arrayBlocksFields={formContainer.blocksWithFieldsOrNestBlocks}
                form={form}
            />
            {hasFormError &&
                <GNotification text={hasFormError} type={NOTIFICATION_TYPE.ERROR} icon={ICONS_SVG_TYPE.INFORMATION}/>}
          </div>
        )}
        {currentStep.additionalStep && currentStep.name === "sss-after2" && <AdditionalTabletHelperComponent />}
        {(formContainer.type === "Form" || formContainer.buttonNext) && (
          <FormTools
            arraySteps={arraySteps}
            indexCurrentStep={indexCurrentStep}
            handleDecrementClick={handleDecrementClick}
            type={formContainer.type}
            reCaptcha={formContainer.reCaptcha}
            buttonNext={formContainer.buttonNext}
            buttonPrev={formContainer.buttonPrev}
            nameStep={currentStep.name}
            labelButton={currentStep.button_label}
            isPaymentPage={currentStep.formType === STEP_TYPE_PAYMENT}
          />
        )}
      </Form>
    </>
  )
}



const mapStateToProps = (state: any) => ({
  arraySteps: state.step.arraySteps,
  indexCurrentStep: state.step.indexCurrentStep,
  submitObject: state.form.submitObject,
  currentStep: state.step.currentStep,
  statusSignature: state.additional.statusSignature,
  currentResponse: state.step.currentResponse,
  appId: state.form.appId,
  formId: state.form.formId,
  billingAddress: state.form.billingAddress,
  eventPageName: state.step.eventPageName,
  stripeLoaded: state.form.stripeLoaded,
  tagStoreWithFields: state.form.tagStoreWithFields,
  tagFields: state.form.tagFields,
  billingInformation: state.step.billingInformation,
  paymentError: state.form.paymentError,
  formError: state.form.error,
  userCoupon: state.billing.userCoupon,
  charges: state.billing.itemPriceCharges,
  loadingState: state.loading.loadingButtonState
})

export default connect(mapStateToProps)(FormBlock)
