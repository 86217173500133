import IPersistenceHandler from "./IPersistenceHandler";
import { env } from "../env";

class CookiesHandler implements IPersistenceHandler{
    EXPIRATION_TIME = 3;

    get(key: string): string | null {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + key.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : null;
    }

    has(key: string): boolean {
        return this.get(key) !== null;
    }

    set(key: string, value: string): void {
        let expires = "";
        if (this.EXPIRATION_TIME) {
            let date = new Date();
            date.setTime(date.getTime() + (this.EXPIRATION_TIME*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = key + "=" + (value || "")  + expires + "; path=/";
    }

    remove(key: string): void {
        let domain = '';
        const url = env.REACT_APP_CURRENT_DOMAIN;
        if(url) {
            domain = "domain=." + new URL(url).hostname;
        }
        document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;' + domain;
    }
}

export default CookiesHandler