/* eslint-disable indent */
import React, { useEffect, useState } from "react"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import { connect } from "react-redux"
import { ValidationItemInterface } from "../../../../Redux/InterfacesEntity/validation.interface"
import NumberInputRenderComponent from "./NumberInputRenderComponent/NumberInputRenderComponent"

type NumberInputComponentProps = {
  field: FieldInterface,
  value: string,
  validationRule: ValidationItemInterface | any,
  submitObject: {} | any,
  dispatch: any,
}

// eslint-disable-next-line max-len
const NumberInputComponent: React.FunctionComponent<NumberInputComponentProps> = ({
  field,
  value,
  validationRule,
  submitObject,
  dispatch,
}) => {
  const specialInput = field.className === "special-input" ? "special-input" : "usual-input"

  const iconsInput = field.icons ? "icons" : "no-icons"

  const handlerOnChangeInput = (
    fieldValue: number | any,
    fieldName: string,
    accumulatorTotal: string | undefined,
    submitObject: {} | any
  ) => {
    if (accumulatorTotal) {
      const newArray = (submitObject["accumulatorTotal"] || []).filter((item: {} | any) => item.name !== fieldName)

      dispatch(setValueCurrentField("accumulatorTotal", [...newArray, { name: fieldName, value: +fieldValue }]))
    }

    dispatch(setValueCurrentField(field.name, "" + fieldValue))
  }

  // for validation
  const [render, setRender] = useState(true)
  const [timeId, setTimeId]: any = useState(null)

  const getAccumulatorArrayTotal = (accumulatorTotal: string | undefined, submitObject: {} | any) => {
    return (submitObject["accumulatorTotal"] || []).reduce((acc: number, field: {} | any) => acc + field.value, 0) < 0.5
  }

  useEffect(() => {
    if (render !== getAccumulatorArrayTotal(field.accumulator_total, submitObject)) {
      setRender(false)
      setTimeId(setTimeout(() => setRender(true), 0))
    }
    return () => clearTimeout(timeId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitObject["accumulatorTotal"]])

  return (
    <>
      {render && (
        <NumberInputRenderComponent
          field={field}
          value={value}
          validationRule={validationRule}
          submitObject={submitObject}
          specialInput={specialInput}
          iconsInput={iconsInput}
          getAccumulatorArrayTotal={getAccumulatorArrayTotal}
          handlerOnChangeInput={handlerOnChangeInput}
        />
      )}
      {!render && (
        <NumberInputRenderComponent
          field={field}
          value={value}
          validationRule={validationRule}
          submitObject={submitObject}
          specialInput={specialInput}
          iconsInput={iconsInput}
          getAccumulatorArrayTotal={getAccumulatorArrayTotal}
          handlerOnChangeInput={handlerOnChangeInput}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
})

export default connect(mapStateToProps)(NumberInputComponent)
