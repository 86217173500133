import React from "react"
import { connect } from "react-redux"
import liveChat from "../../../../assets/image/live_chat.svg"

type LiveChatComponentProps = {
  dispatch: any,
}

const LiveChatComponent: React.FunctionComponent<LiveChatComponentProps> = ({ dispatch }) => {
  const handlerClickImg = () => {}
  return (
    <div className={"live-chat__container"}>
      <img src={liveChat} alt="live-chat__img" className={"live-chat__img"} onClick={handlerClickImg} />
    </div>
  )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(LiveChatComponent)
