import React, { FC } from "react"
import "./Spinner.scss"

type GSpinnerProps = {
  width?: number,
  height?: number,
}

const GSpinner: FC<GSpinnerProps> = ({ width = 42, height = 42 }) => {
  const style: React.CSSProperties = {
    width: width,
    height: height,
  }
  return <div className={`GSpinner`} style={style} />
}

export default GSpinner
