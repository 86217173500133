import {PaymentConfirmationModalProps} from "./PaymentConfirmationModalProps";
import React, {FC, useEffect, useState} from "react";
import GModal from "../GModal/GModal";
import "./ConfirmPaymentModal.scss"
import GText from "../../atoms/Text/Text";
import {CreditCard, SIZE} from "../../types/types";
import {COLORS_NEW} from "../../types/Colors";
import GPaymentMethodsForm from "../PaymentMethodsForm/PaymentMethodsForm";
import {Form} from "antd";
import GButton from "../../molecules/Button/GButton";
import {isNewPaymentMethod, NEW_PAYMENT_METHOD_VALUE} from "../../../../util/PaymentMethodsHelper";
import GNotification, {NOTIFICATION_TYPE} from "../../organisms/Notification/Notification";
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes";
import {PaymentRequestTokenEvent} from "@stripe/stripe-js";
import GSubscriptionsMethodsForm from "../SubscriptionMethodsForm/SubscriptionMethodsForm";
import subscriptionMethods from '../SubscriptionMethodsForm/subscriptionMethods.json'
import {SUBSCRIPTION_PERIOD_YEARLY} from "../../../../shared/constans/payment";
import {connect} from "react-redux";
import {PaymentButtonsFactory} from "../PaymentButtons/PaymentButtonsFactory";
import {PaymentError} from "../../../../dto/PaymentError";
// @ts-ignore
import {SubscriptionTrialOptions} from "../../../../../../dashboard-govplus-front/src/constants/SubscriptionTrialOptions"

const ConfirmPaymentModal: FC<PaymentConfirmationModalProps> =
    ({
         isOpen,
         submitObject,
         submitPaymentCallback,
         paymentDescription,
         paymentAuthorizationDescription,
         setSubscriptionSelectedPrice,
         paymentMethods,
         defaultPaymentMethod,
         close,
         isLoading = false,
         errorMessage,
         title,
         priceInfo,
         showSubscriptionOptions = false,
         mode = "normal",
         selectedSubscription,
         selectedTrial
     }) => {
        const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(defaultPaymentMethod || NEW_PAYMENT_METHOD_VALUE);
        const [selectedSubscriptionMethod, setSelectedSubscriptionMethod] = useState(selectedSubscription || SUBSCRIPTION_PERIOD_YEARLY);
        const [form] = Form.useForm();
        const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | undefined>(undefined);

        useEffect(() => {
          const selectedSubscriptionObject = subscriptionMethods?.find((row) => row.method === selectedSubscriptionMethod)
          setSubscriptionSelectedPrice?.(selectedSubscriptionObject?.yearPrice || selectedSubscriptionObject?.price || 0)
        }, [selectedSubscriptionMethod])

        const handleSubmit = async (): Promise<void> => {
            setPaymentErrorMessage(undefined);

            if (!isNewPaymentMethod(selectedPaymentMethod)) {
                submitPaymentCallback({
                    isNewPaymentMethod: false,
                    paymentMethod: selectedPaymentMethod,
                    period: selectedSubscriptionMethod,
                    trial: selectedTrial || SubscriptionTrialOptions.MINIMUM
                });
                return;
            }

            const creditCard: CreditCard | undefined = await getCreditCardFormValue();
            if (!creditCard) {
                return;
            }

            submitPaymentCallback({
                isNewPaymentMethod: true,
                paymentMethod: selectedPaymentMethod,
                creditCard: creditCard,
                period: selectedSubscriptionMethod,
                trial: selectedTrial || SubscriptionTrialOptions.MINIMUM
            });
        };

        const submitPaymentWithToken = async (event: PaymentRequestTokenEvent) => {
            submitPaymentCallback({
                isNewPaymentMethod: true,
                paymentMethod: NEW_PAYMENT_METHOD_VALUE,
                period: selectedSubscriptionMethod,
                trial: selectedTrial || SubscriptionTrialOptions.MINIMUM,
                requestToken: {
                    event: event,
                    creditCardToken: {tokenSourceName: event.walletName, tokenId: event.token.id},
                    requestCompletedCallback: (event) => { event.complete?.("success") }
                }
            });
        }

        const getCreditCardFormValue = async (): Promise<CreditCard | undefined> => {
            try {
                return await form.validateFields()
            } catch (ex) {
                return undefined
            }
        }

        useEffect(() => {
            if (defaultPaymentMethod) {
                setSelectedPaymentMethod(defaultPaymentMethod)
            }

        }, [defaultPaymentMethod])

        useEffect(() => {
            if (selectedSubscription) {
                setSelectedSubscriptionMethod(selectedSubscription)
            }
        }, [selectedSubscription])

        const setPaymentError = (paymentError: PaymentError | null) => {
            if(paymentError) {
                setPaymentErrorMessage(paymentError.message)
            } else {
                setPaymentErrorMessage(undefined)
            }
        }

        const finalErrorMessage = errorMessage || paymentErrorMessage

        const normalMode = mode === "normal"
        const paymentErrorMode = mode === "payment_error"

        return (
            <GModal open={isOpen} onCancel={close} width={600}>
                <Form form={form} layout={`vertical`}>
                    <div className={'ConfirmPaymentModal'}>
                        <div className={"Content"}>
                            <div className={'header'}>
                                <GText text={title} size={SIZE.HEADING_MDLG} color={COLORS_NEW.BLACK_1000} maxWidth={paymentErrorMode ? "512px" : ""}/>
                            </div>
                            {finalErrorMessage && paymentErrorMode && (
                                <GNotification text={finalErrorMessage} type={NOTIFICATION_TYPE.ERROR}
                                            icon={ICONS_SVG_TYPE.INFORMATION}/>
                            )}
                            <div className={'payment-form'}>
                                {paymentDescription && <GText text={paymentDescription} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_500} style={{textAlign: "center"}}/>}
                                {priceInfo && selectedPaymentMethod === NEW_PAYMENT_METHOD_VALUE && PaymentButtonsFactory.getButton({
                                    priceInfo: priceInfo,
                                    onTokenCreated: submitPaymentWithToken,
                                    submitObject: submitObject,
                                    setError: setPaymentError
                                })}
                                <GPaymentMethodsForm
                                    paymentMethods={paymentMethods}
                                    defaultPaymentMethod={defaultPaymentMethod}
                                    selectionPaymentMethodChange={setSelectedPaymentMethod}
                                    isPaymentMethodFormOpen={undefined}
                                />

                                {showSubscriptionOptions && <div className="subscription_options">
                                    <GSubscriptionsMethodsForm
                                        subscriptionMethods={subscriptionMethods}
                                        defaultSubscriptionMethod={SUBSCRIPTION_PERIOD_YEARLY}
                                        selectionSubscriptionMethodChange={setSelectedSubscriptionMethod}
                                        hasTotalPriceBlock={false}
                                    />
                                </div>}

                            </div>
                            {finalErrorMessage && normalMode && (
                                <GNotification text={finalErrorMessage} type={NOTIFICATION_TYPE.ERROR}
                                            icon={ICONS_SVG_TYPE.INFORMATION}/>
                            )}
                            {paymentAuthorizationDescription && paymentAuthorizationDescription.length > 0 && 
                            <div className={'bottom-agreement'}>
                                {paymentAuthorizationDescription.map(text =>
                                    <GText key={text} text={text} size={SIZE.PARAGRAPH_REGULAR_14} color={COLORS_NEW.BLACK_600}/>
                                )}
                            </div>}
                        </div>
                        
                        <div className={"Footer"}>
                            {/* @ts-ignore */}
                            <GButton text={(normalMode && 'Submit') || (paymentErrorMode && 'Submit payment')} 
                                textSize={SIZE.PARAGRAPH_BOLD_16} 
                                loading={isLoading}
                                disabled={isLoading} showIcon={true}
                                click={handleSubmit}
                            />
                        </div>
                        

                    </div>

                </Form>
            </GModal>
        )
    }

const mapStateToProps = (state: any) => ({
    submitObject: state?.form?.submitObject
})
export default connect(mapStateToProps)(ConfirmPaymentModal)