import React, { FC, useState } from "react"
import "./StepServiceProviderSelection.scss"
import GCategorySelection from "./CategorySelection"
import { connect, useDispatch } from "react-redux"
import GButton from "../../../molecules/Button/GButton"
import { submitAdditionalCoa } from "../../../../../Redux/store/additionalForm/additional.actions"
import { SIZE } from "../../../types/types"

interface IServicesCategory {
  name: string;
  type: string;
  placeholder: string;
  maxSelections: number;
  categoryName: string;
}

type GStepServiceProviderSelectionProps = {
  categories: IServicesCategory[],
  loadingButtonState: string,
  submitObject: any,
}

const SAVE_COMPANY = "saveCompanies"
const CTA_TEXT = "Submit"

const GStepServiceProviderSelection: FC<GStepServiceProviderSelectionProps> = ({
  categories,
  loadingButtonState,
  submitObject,
}) => {
  const [actionLoading, setActionLoading] = useState("")
  const dispatch = useDispatch()

  const handlerButtonSubmit = () => {
    setActionLoading(SAVE_COMPANY)
    dispatch(submitAdditionalCoa({ ...submitObject, companies_later: false }))
  }

  return (
    <div className={`StepServiceProviderSelection`}>
      {categories &&
        categories.map((category: IServicesCategory) => {
          return <GCategorySelection key={category.categoryName} category={category} />
        })}

      <div className={`StepServiceProviderSelection__CtaContainer`}>
        <GButton
          disabled={loadingButtonState === "loading"}
          loading={loadingButtonState === "loading" && actionLoading === "saveCompanies"}
          text={CTA_TEXT}
          textSize={SIZE.PARAGRAPH_BOLD_16}
          size={"large"}
          click={handlerButtonSubmit}
          showIcon={false}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  loadingButtonState: state.loading.loadingButtonState,
  submitObject: state.form.submitObject,
})

export default connect(mapStateToProps)(GStepServiceProviderSelection)
