import React, {useEffect} from "react"
import { ItemInterface } from "../../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import {connect, useSelector} from "react-redux"
import { RootState } from "../../../../App"
import { formSelector } from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import "./index.scss"
type PaymentErrorContentProps = {
  item: ItemInterface,
  paymentError: any,
}

const PaymentErrorContent: React.FunctionComponent<PaymentErrorContentProps> = ({item: { text, className }, paymentError }) => {
const error = (useSelector<RootState, { error: { error: string } }>(formSelector)?.error?.error) ?? paymentError?.errorDescription ??
    "some payment error"

  useEffect(() => {
    window.scrollTo(0, 490)
  }, [])

  return (
    <>
      {" "}
      <ul className={`${className}__list-error g-payment-errors`}>
        {[error].map((error: string, index: number) => (
          <li className={`${className}__text-error ${className}__text-error_${index}`} key={index}>
            {error}
          </li>
        ))}
      </ul>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  paymentError: state.form.paymentError,
})

export default connect(mapStateToProps)(PaymentErrorContent)
