import React from "react"

interface ISvgUser {
    width: number;
    height: number;
    color: string;
}

export const SvgUser = ({ width, height, color }: ISvgUser) => {
    return (
        <svg width={width} height={height} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.3327 13V11.6667C11.3327 10.9594 11.0517 10.2811 10.5516 9.78105C10.0515 9.28095 9.37326 9 8.66601 9H3.33268C2.62544 9 1.94716 9.28095 1.44706 9.78105C0.946967 10.2811 0.666016 10.9594 0.666016 11.6667V13"
                stroke="#666666" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M6.00065 6.33333C7.47341 6.33333 8.66732 5.13943 8.66732 3.66667C8.66732 2.19391 7.47341 1 6.00065 1C4.52789 1 3.33398 2.19391 3.33398 3.66667C3.33398 5.13943 4.52789 6.33333 6.00065 6.33333Z"
                stroke="#666666" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
