import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

const APPLICATION_ID = "application_id"

class ApplicationIdRepository extends BaseSessionStorageRepository {
    private static _instance: ApplicationIdRepository;

    private constructor() {
        super();
    }

    public static getInstance():ApplicationIdRepository {
        if (!ApplicationIdRepository._instance) {
            ApplicationIdRepository._instance = new ApplicationIdRepository();
        }
        return ApplicationIdRepository._instance;
    }

    getKeyName(): string {
        return APPLICATION_ID;
    }
}

export default ApplicationIdRepository;
