import React from "react"
import { Form, Input } from "antd"

const LoginUserModalContent: React.FunctionComponent = () => (
  <>
    <label className={`login_user_modal__login_label check-status__email-container`}>
      <Form.Item
        label="Login"
        name="login"
        rules={[
          { required: true, message: "Required" },
          // { pattern: new RegExp("^[0-9]+$"), message: "Number required" },
        ]}
      >
        <Input className={`login_user_modal__login_input`} />
      </Form.Item>
    </label>
    <label className={`login_user_modal__password_label check-status__id-container`}>
      <Form.Item label="Password" name="password" rules={[{ required: true, message: "Required" }]}>
        <Input className={`login_user_modal__password_input check-status__id-input`} />
      </Form.Item>
    </label>
  </>
)

export default LoginUserModalContent
