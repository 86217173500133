import React from "react";
import { useSubscription } from "../../../../dashboard-govplus-front/src/hooks/useSubscription";
import MyAutomaticRenewals from "./MyAutomaticRenewal/MyAutomaticRenewals";
import TeaserPageWrapper from "../Subscription/TeaserPageWrapper";

const RenewalMainPageWrapper = () => {
    const {renewedDocuments} = useSubscription()

    return (
        <TeaserPageWrapper DocumentsPage={MyAutomaticRenewals}  documents={renewedDocuments} />
    )

}

export default RenewalMainPageWrapper