import React from "react"
import { connect } from "react-redux"

type ClientDataComponentProps = {
  item: any,
  clientId: any,
  clientEmail: any,
  currentResponse: any,
  packageOnly?: boolean,
  customerSupport?: boolean,
  customer: {} | any,
  billingAddress: {} | any,
}

// eslint-disable-next-line max-len
const ClientDataComponent: React.FunctionComponent<ClientDataComponentProps> = ({
  item,
  clientId,
  clientEmail,
  currentResponse,
  billingAddress,
  customer,
}) => {
  const className = item.blockOnFormPage.type || ""

  // const originData =
  //   (currentResponse &&
  //     currentResponse.verifiedAddress &&
  //     currentResponse.verifiedAddress.original &&
  //     currentResponse.verifiedAddress.original) ||
  //   ""
  const { personalData, customerSupport } = item.blockOnFormPage

  const customerName = customer ? `${customer.first_name} ${customer.middle_name} ${customer.last_name}` : ""

  const getName = () => {
    if (personalData) {
      return {
        first: "Client ID",
        second: "Email Address",
        third: "Address",
      }
    }
    if (customerSupport) {
      return {
        first: "Phone Number",
        second: "Mailing Address",
        third: "Our Offices",
      }
    } else {
      return {
        first: "Client ID",
        second: "Email Address",
        third: "Address",
      }
    }
  }

  const getValue = (helper: {} | any) => {
    if (personalData) {
      return helper.personalData
    }
    if (customerSupport) {
      return helper.customerSupport
    }
    return helper.personalData || {}
  }

  return (
    // eslint-disable-next-line max-len
    <div
      // eslint-disable-next-line max-len
      className={`your-data-helper__container your-data-helper__container_${className}`}
    >
      <div className={`your-data-helper__sub-block`}>
        <h3 className={`your-data-helper__header`}>{getName().first || "Client ID"}</h3>
        <p className={`your-data-helper__text`}>{clientId || getValue(item.blockOnFormPage).clientId}</p>
      </div>
      <div className={`your-data-helper__sub-block`}>
        <h3 className={`your-data-helper__header`}>{getName().second || "Email Address"}</h3>
        <p className={`your-data-helper__text`}>{clientEmail || getValue(item.blockOnFormPage).emailAddress}</p>
      </div>
      <div className={`your-data-helper__sub-block`}>
        <h3 className={`your-data-helper__header`}>{getName().third || "Address"}</h3>

        <p
          // eslint-disable-next-line max-len
          className={`your-data-helper__text your-data-helper__text_low-first-letter`}
        >
          {customerName || getValue(item.blockOnFormPage).address.name}
        </p>
        <p className={`your-data-helper__text`}>
          {(billingAddress && billingAddress.address1) || getValue(item.blockOnFormPage).address.content}
        </p>
        <p className={`your-data-helper__text`}>
          {billingAddress &&
            // eslint-disable-next-line max-len
            `${billingAddress.city_name}, ${billingAddress.state_code} ${billingAddress.zipcode}`}
        </p>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  currentResponse: state.step.currentResponse,
  billingAddress: state.form.billingAddress,
  customer: state.form.customer,
  clientEmail: state.form.clientEmail,
})

export default connect(mapStateToProps)(ClientDataComponent)
