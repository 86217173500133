import React from "react"
import { connect } from "react-redux"
import CheckStatusFormComponent from "./CheckStatusFormComponent/CheckStatusFormComponent"

type CheckStatusComponentProps = {}

// eslint-disable-next-line max-len
const CheckStatusComponent: React.FunctionComponent<CheckStatusComponentProps> = () => {
  return (
    <div className={"check-status__container"}>
      <h2 className={"check-status__header"}>Check Status</h2>
      <p className={"check-status__description"}>
        Please enter your email address and the Transaction ID received in your confirmation email to check the status
        of your Social Security Card application:
      </p>
      <CheckStatusFormComponent />
    </div>
  )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(CheckStatusComponent)
