import Event from "../Event"
import {EventOptions, EventProps} from "../EventProperties"
import IEvent from "../IEvent";

const EVENT_NAME = "Signup Started";

export default class SignupStarted extends Event implements IEvent {

    constructor(
        ipAddress: string | undefined
    ) {
        super(EVENT_NAME, ipAddress);
    }

    getEventData(): EventProps {
        return {
            ...super.baseEventData(),
        }
    }

    getEventName(): string {
        return this.eventName;
    }

    pageName(): string {
        return "";
    }

    getPreviousId(): string {
        return "";
    }

    getUserId(): string {
        return "";
    }

    getEventOptions(): EventOptions | undefined {
        return undefined;
    }
}
