import React from "react"

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
}

export const SvgDocument = ({ width, height, color }: ISvgCheck) => {
  return (
      <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.65625" width="64" height="64" rx="32" fill="#EEF4FB"/>
          <path d="M40.5 29.6562L33.5 22.6562H26.5C25.9696 22.6562 25.4609 22.867 25.0858 23.242C24.7107 23.6171 24.5 24.1258 24.5 24.6562V40.6562C24.5 41.1867 24.7107 41.6954 25.0858 42.0705C25.4609 42.4455 25.9696 42.6562 26.5 42.6562H27M40.5 38.1562V40.6562C40.5 41.1867 40.2893 41.6954 39.9142 42.0705C39.5391 42.4455 39.0304 42.6562 38.5 42.6562H35.5" stroke="#185EC8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M33.5 22.6562V29.6562H40.5" stroke="#185EC8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M30.3125 43.3999L42 32.1562" stroke="#185EC8" strokeWidth="2" strokeLinecap="round"/>
      </svg>
  )
}
