import React, { FC } from "react"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS_NEW } from "../../types/Colors"
import IconsSvg from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GButton from "../../molecules/Button/GButton"
import { BenefitsModalProps } from "./BenefitsModal.props"
import GModal from "../GModal/GModal"

const BenefitsModal: FC<BenefitsModalProps> = ({
  width = 560,
  open,
  onCancel,
  benefits,
  agreementText,
  hasAgreement,
  headline,
}) => {
  return (
    <GModal width={width} open={open} onCancel={onCancel}>
      <div className={"GBenefitsModal"}>
        <div className={"GBenefitsModal__Header"}>
          <GText text={headline} innerHtml={headline} size={SIZE.PARAGRAPH_BOLD_32} color={COLORS_NEW.BLACK_1000} />
        </div>
        {benefits.map((benefit, index) => (
          <div key={`${benefit}-${index}`} className={"GBenefitsModal__Section"}>
            {benefit.headline && (
              <GText text={benefit.headline} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_500} />
            )}
            <ul className={"GBenefitsModal__Section__List"}>
              {benefit.options.map((option: any, optionIndex: any) => (
                <li key={`${option}-${optionIndex}`}>
                  <IconsSvg type={ICONS_SVG_TYPE.ICON_GREEN_CHECK} width={22} height={22} />
                  <GText text={option.label} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
                </li>
              ))}
            </ul>
          </div>
        ))}
        {hasAgreement && agreementText}
        <div className={"GBenefitsModal__Actions"}>
          <GButton text={"OK"} showIcon={false} click={onCancel} />
        </div>
      </div>
    </GModal>
  )
}

export default BenefitsModal
