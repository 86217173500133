import {ServicesNames} from "./ServicesNames";

const ServicesHelper = {
    servicesWithGovernmentFee: (): string[] => {
        return [
            ServicesNames.NewTSAPreCheck,
            ServicesNames.TsaPreCheckRenewal,
            ServicesNames.PassportRenewal,
            ServicesNames.PassportFirst,
            ServicesNames.PassportStolen,
            ServicesNames.PassportDamage,
            ServicesNames.PassportLost,
            ServicesNames.BirthCertificateReplacement,
            ServicesNames.FirstBirthCertificate,
            ServicesNames.BirthCertificateCertifiedCopy,
        ]
    },
    involvesGovernmentFee: (service?: string | null): boolean => {
        if (!service) {
            return false
        }
        const result = ServicesHelper.servicesWithGovernmentFee()
            .map((serviceF: string) => {
              return serviceF.toLowerCase()
            });
        return result.includes(service.toLowerCase());
    }
}
export default ServicesHelper;