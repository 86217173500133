import Norton from "./Norton";
import React from "react";
import nortonImage from "../../../../assets/image/logo-norton-secure-seal.png"
import Environment from "../../../../Constants/Environments";
import { env } from "../../../../env";

export const SEAL_NORTON_ID = "KUoxS-69";

export const DEFAULT_NORTON_IMAGE = nortonImage;

export enum SealType {
    NORTON = "NORTON"
}

type SealHandlerProps = {
    type: SealType,
    id: string,
    defaultImage?: string
}

const SealHandler: React.FunctionComponent<SealHandlerProps> = ({type, id, defaultImage}) => {
    if((env.NODE_ENV !== Environment.PRODUCTION || env.REACT_APP_ENV !== Environment.PRODUCTION) && defaultImage){
        return <img src={defaultImage} alt=""/>
    }
    switch (type) {
        case SealType.NORTON:
            return <Norton sealId={id}/>;
        default:
            return <></>;
    }
}

export default SealHandler;