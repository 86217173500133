import React, { useEffect } from "react"
import { connect } from "react-redux"
import { ValidationItemInterface } from "../../../../Redux/InterfacesEntity/validation.interface"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { Checkbox, Form } from "antd"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import {getLabel} from "../../../../services/service-function/field";

type CheckBoxGroupComponentsProps = {
  field: FieldInterface | any,
  value: ValidationItemInterface,
  submitObject: {} | any,
  dispatch: any,
  pricing: {} | any
}

// eslint-disable-next-line max-len
const CheckBoxGroupComponents: React.FunctionComponent<CheckBoxGroupComponentsProps> = ({
  field,
  submitObject,
  value,
  dispatch,
  pricing
}) => {
  const options = field?.options
  const defaultValue = field?.defaultValue
  pricing = pricing??(JSON.parse(localStorage.getItem("pricing") || "[]"))
  const defaultValuePrice = pricing?.[field?.defaultValue?.backendValue]

  useEffect(() => {
    if (field.priority) {
      dispatch(setValueCurrentField("priority", field.priority))
    }
    if (defaultValue && defaultValuePrice) {
      dispatch(setValueCurrentField(`${field.name}`, defaultValuePrice))
    }
    // eslint-disable-next-line
  }, [dispatch, field.priority, defaultValue])

  function onChange(
    arrayValues: [] | any,
    defaultValue: {} | any,
    field: FieldInterface
  ) {
    const arrayOptions =
      field &&
      field?.options?.reduce(
        (acc: [] | any, item: {} | any) => {
          if (arrayValues.includes(item.name)) {
            dispatch(setValueCurrentField(item.name, pricing[item.backendValue]))
            return acc.concat(item)
          } else {
            dispatch(setValueCurrentField(item.name, ""))
            return acc
          }
        },
        [defaultValue]
      )
    const issueCost = arrayOptions.reduce((acc: any, item: any) => {
      return acc + +pricing[item.backendValue]
    }, 0)
    dispatch(setValueCurrentField(field.name, "" + issueCost))
    dispatch(setValueCurrentField(`defaultValue${field.name}`, arrayValues))
  }

  interface backendPriceInterface {
    FIRST: number
    // eslint-disable-next-line prettier/prettier
    ADD:number
    RUSH_SERVICE:number
    RUSH_SERVICE_FEE:number
    NOTARIZE:number
  }

  const filterOptions = (optionsJSON: [], backendPrice: backendPriceInterface | any) => 
    optionsJSON.filter((option: {backendValue: string}) => backendPrice?.[option.backendValue])

  return (
    <Form.Item
      label={getLabel(field.label)}
      name={field.name}
      required={true}
      preserve={false}
      initialValue={submitObject[`defaultValue${field.name}`]}
      className={`check-box-group__container ${field.type} ${field.name}`}
    >
      <div className={`check-box-group__default-option`}>
        <div className={`default-option__header_container`}>
          <p
            // eslint-disable-next-line max-len
            className={`default-option__header-text`}
          >
            {defaultValue?.optionName}
            <span className={`default-option__price-text`}>
              {defaultValue?.currency + defaultValuePrice}
            </span>
          </p>
        </div>
        <div className={`default-option__description_container`}>
          <p
            // eslint-disable-next-line max-len
            className={`default-option__description_text`}
          >
            {defaultValue?.descriptionOption}
          </p>
        </div>
      </div>
      <Checkbox.Group
        onChange={(arrayValues) => onChange(arrayValues, defaultValue, field)}
        className={`check-box-group__dynamic-options`}
      >
        {filterOptions(options, pricing).map((item: any) => {
          return (
            <Checkbox
              value={item.name}
              key={item.name}
              className={`check-box__container ${item.type} ${item.name}`}
            >
              <p className={`check-box__name-container`}>
                <span className={`check-box__name-text`}>{item.optionName}</span> -
                <span className={`check-box__price-text`}>{` ${item.currency}${pricing?.[item.backendValue]}`}</span>
              </p>
              <p className={`check-box__container ${item.type} ${item.name}`}>{item.descriptionOption}</p>
            </Checkbox>
          )
        })}
      </Checkbox.Group>
      <div className={`check-box-group__total`}>
        <div className={`total__header_container`}>
          <p
            // eslint-disable-next-line max-len
            className={`total__header-text`}
          >
            {field.total}
            <span className={`total__price-text`}>
              {defaultValue?.currency + submitObject[field.name]}
            </span>
          </p>
        </div>
        <div className={`total__description_container`}>
          <p
            // eslint-disable-next-line max-len
            className={`total__description_text`}
          >
            {field.totalDescription}
          </p>
        </div>
      </div>
    </Form.Item>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
  pricing: state.form.pricing
})

export default connect(mapStateToProps)(CheckBoxGroupComponents)
