import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCSSES,
} from "../../constants/ActionTypes";

export const getNotifications = (notifications) => {
  return {
    type: GET_NOTIFICATIONS,
    payload: notifications
  };
};

export const getNotificationsSuccsses = (notifications) => {
  return {
    type: GET_NOTIFICATIONS_SUCCSSES,
    payload: notifications
  };
};
