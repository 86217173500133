import React from "react"

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
}

export const SvgInsuranceShield = ({
  width = 65,
  height = 65,
  color = "#113469"
}: ISvgCheck) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.66276 15.1615C8.66276 15.1615 14.3294 12.4948 14.3294 8.49479V3.82812L8.66276 1.82812L2.99609 3.82812V8.49479C2.99609 12.4948 8.66276 15.1615 8.66276 15.1615Z"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.45703 8.9375L7.63864 10.1017C7.76935 10.2305 7.97958 10.2295 8.10896 10.0993L11.5326 6.65625"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
    </svg>
  )
}