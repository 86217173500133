import React, { FC } from "react"
import {
  BasicBlockContainerProps,
  ItemInterface,
} from "../../../../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { useSelector } from "react-redux"
import { RootState } from "../../../../../../App"
import {
  renderBasicBlockContainer,
  uploadSelector,
} from "../../../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import LayoutBaseContainer from "../../../../UploadPhoto/Layouts/LayoutBaseContainer/LayoutBaseContainer"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"

export interface currentStateDocumentsUploadInterface {
  [key: string]: any;
}

const UploadDocumentActionBlock: FC<BasicBlockContainerProps> = ({
  item: { className, text, children: childrenItem = [], state },
  arrayComponents,
  ...props
}) => {
  const { dynamicProps = { name_field: "" } } = props
  const { name_field } = dynamicProps
  const currentStateDocumentsUpload = useSelector<
    RootState,
    { currentStateDocumentsUpload: currentStateDocumentsUploadInterface }
  >(uploadSelector).currentStateDocumentsUpload

  const findCurrentChildren = childrenItem.find(
    (child: ItemInterface) => child?.state === currentStateDocumentsUpload?.[name_field]
  )
    ? [childrenItem?.find((child: ItemInterface) => child?.state === currentStateDocumentsUpload?.[name_field])]
    : childrenItem?.slice(0, 1)

  return (
      <DndProvider
          backend={HTML5Backend}
          key={Math.random()}
          context={window}
      >
      {renderBasicBlockContainer(
        { className, text, childrenItem: findCurrentChildren, arrayComponents },
        LayoutBaseContainer,
        props
      )}
    </DndProvider>
  )
}

export default UploadDocumentActionBlock
