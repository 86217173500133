import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

export const TO_APPLY_SERVICE_ID = "to_apply_service_id"

class ToApplyServiceIdRepository extends BaseSessionStorageRepository {
    private static _instance: ToApplyServiceIdRepository;

    private constructor() {
        super();
    }

    public static getInstance(): ToApplyServiceIdRepository {
        if (!ToApplyServiceIdRepository._instance) {
            ToApplyServiceIdRepository._instance = new ToApplyServiceIdRepository();
        }
        return ToApplyServiceIdRepository._instance;
    }

    getKeyName(): string {
        return TO_APPLY_SERVICE_ID;
    }

    getValue(): string | null {
        return super.getValue();
    }
}

export default ToApplyServiceIdRepository;