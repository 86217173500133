import {SolutionNames} from "../../../../util/SolutionNames";

export interface SignatureCanvasConfig {
    minDistance: number;
    throttle: number;
    velocityFilterWeight: number;
    minWidth: number;
    maxWidth: number;
    clearOnResize: boolean
    penColor: string;
}

// blue color for pen signature canvas
const SIGNATURE_CANVAS_PEN_DEFAULT_COLOR = '#000F55';


export function getSignatureCanvasConfiguration(solution: SolutionNames): SignatureCanvasConfig {
    const defaultConfig: SignatureCanvasConfig = {
        maxWidth: 1.25,
        minWidth: 0.5,
        minDistance: 5,
        throttle: 16,
        velocityFilterWeight: 0.7,
        penColor: SIGNATURE_CANVAS_PEN_DEFAULT_COLOR,
        clearOnResize: false
    }
    if (solution === SolutionNames.PAS) {
        defaultConfig.penColor = '#0f0101'
    }
    return defaultConfig;
}