import axios from "axios"
import { env } from "../../env"

const hostWebFlow = env.REACT_APP_GOV_DASHBOARD ?? "https://auth.govplus.dev"

/**
 * We use webflow as out content manager. The collection ids can be seen in the settings under
 * CMS collection section in webflow.
 */
enum WebFlowCollectionsIds {
  SERVICES = "63fa70698bac0a648d4f2cf8",
  SOLUTIONS = "63fa70698bac0a02eb4f2cf7",
}

/**
 * Expose endpoints for fetching info from web flow CMS.
 */
export default class ServiceWebFlow {
  static getHeaderData = async () =>
    (await axios.get(`${hostWebFlow}/webflow/getCollection/${WebFlowCollectionsIds.SERVICES}`)).data.data
  static getSolutionsData = async () =>
    (await axios.get(`${hostWebFlow}/webflow/getCollection/${WebFlowCollectionsIds.SOLUTIONS}`)).data.data
}
