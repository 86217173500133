import React, {useEffect, useState} from 'react'
import './style.less'
import {Link} from "react-router-dom";
import {Input, Form, message} from 'antd'
import {ArrowLeftOutlined, LockOutlined, UnlockOutlined} from '@ant-design/icons'
import SettingsButton from "../../../components/SettingsButton";
import ValidationBlock from "../../../components/Validation";
import {useDispatch, useSelector} from "react-redux";
import {changePassword, hideMessage} from "../../../appRedux/actions/profileSettings";

const EditPasswordPage = () => {
  // current password get from back
  const dispatch = useDispatch();
  const {changePasswordMessage} = useSelector(({accountSettingsReducer})=>accountSettingsReducer);
  const [passUpperStatus, setPassUpperStatus] = useState(false);
  const [passNumberStatus, setPassNumberStatus] = useState(false);
  const [passSymbolStatus, setPassSymbolStatus] = useState(false);
  const [passLengthsStatus, setPassLengthsStatus] = useState(false);
  const [confirmPasswordValidationStatus, setConfirmPasswordValidationStatus] = useState(false)
  const [userStartWorkWithPasswords, setUserStartWorkWithPasswords] = useState(false)
  const [formValMessage, setFormValMessage] = useState('')

  const onChange = (e, allE) => {
    dispatch(hideMessage())
    setConfirmPasswordValidationStatus(false);
    setPassLengthsStatus(allE[1]?.value && allE[1]?.value.length > 0);
    allE[1]?.value.length >= 8 ? setPassLengthsStatus(true) : setPassLengthsStatus(false);
    // setConfirmPasswordValidationStatus(allE[1]?.value === allE[2]?.value);
    new RegExp("[A-Z]").test(allE[1]?.value) ? setPassUpperStatus(true) : setPassUpperStatus(false);
    new RegExp("[0-9]").test(allE[1]?.value) ? setPassNumberStatus(true) : setPassNumberStatus(false);
    new RegExp(/[-±ˆ!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/).test(allE[1].value) ? setPassSymbolStatus(true) : setPassSymbolStatus(false);
  }

  const sendNewPassword = (event) => {
    const {confirmNewPassword, currentPassword, newPassword} = event;
    if (newPassword !== confirmNewPassword) {
      setConfirmPasswordValidationStatus(true);
      setFormValMessage("Passwords is not equal! Please check your data");
    }

    if (confirmNewPassword === "" || currentPassword === "" || newPassword === "") {
      setConfirmPasswordValidationStatus(true);
      setFormValMessage("Please fell data");
    }else if (newPassword === confirmNewPassword) {
      dispatch(changePassword({
        currentPassword,
        newPassword,
      }));
    }else{
      setConfirmPasswordValidationStatus(true);
      setFormValMessage("Password is not equal");
    }
  }

  const content = <div>
    <div>
      <h2>Password Updated</h2>
    </div>
    <p>Your password was successfully updated.</p>
  </div>

  const info = () => {
    message.open(
      {
        type: "info",
        content: content,
        duration: 5
      }
    );
  };

  useEffect(()=>{
    if (changePasswordMessage === "change-password.WRONG_CURRENT_PASSWORD"){
      setConfirmPasswordValidationStatus(true);
      setFormValMessage("Wrong current password. Please try again.");
    }
    if(changePasswordMessage === "change-password.PASSWORD_SUCCESSFULLY_UPDATED"){
      info();
    }
  }, [changePasswordMessage])

  return <div className='EditPasswordPage'>
    <Link to='/account-settings' className='EditPasswordPage-link'>
      <ArrowLeftOutlined className='EditPasswordPage-link-icon'/>Account Settings
    </Link>
    <h1 className='EditPasswordPage-title'>Edit Password</h1>
    <Form
      className='EditPasswordPage-form'
      layout="vertical"
      name={"basic"}
      onFieldsChange={onChange}
      onFinish={sendNewPassword}
    >
      <Form.Item
        initialValue={""}
        name={"currentPassword"}
        label={"Old Password"}
        className='EditPasswordPage-form-box-item'>
        <Input.Password
          type={"password"}
          iconRender={visible => (visible ? <UnlockOutlined/> : <LockOutlined/>)}
          className='EditPasswordPage-form-box-item-input currentPassword'
        />
      </Form.Item>

      <Form.Item
        name={"newPassword"}
        initialValue={""}
        label={"New Password"}
        className='EditPasswordPage-form-box-item'
      >
        <Input.Password
          onFocus={() => setUserStartWorkWithPasswords(true)}
          type="password"
          className='EditPasswordPage-form-box-item-input'
          iconRender={visible => (visible ? <UnlockOutlined/> : <LockOutlined/>)}
        />
      </Form.Item>

      <div className={userStartWorkWithPasswords ? "validationBlockRed" : "validationBlock"}>
        <ValidationBlock
          showIcon={true}
          iconColor={userStartWorkWithPasswords ? "red" : "#666666"}
          validationStatus={passLengthsStatus ? 'notValid' : ''}
          validationMessage={<span className={passLengthsStatus ? "greenText" : null}>Use 8 or more characters</span>}
        />
        <ValidationBlock
          showIcon={true}
          validationStatus={passUpperStatus ? 'notValid' : ''}
          validationMessage={<span className={passUpperStatus ? "greenText" : null}>Use upper and lower case letters (e.g. Aa)</span>}
          iconColor={userStartWorkWithPasswords ? "red" : "#666666"}
        />
        <ValidationBlock
          showIcon={true}
          validationStatus={passNumberStatus ? 'notValid' : ''}
          validationMessage={<span className={passNumberStatus ? "greenText" : null}>Use a number (e.g. 1234)</span>}
          iconColor={userStartWorkWithPasswords ? "red" : "#666666"}
        />
        <ValidationBlock
          showIcon={true}
          validationStatus={passSymbolStatus ? 'notValid' : ''}
          validationMessage={<span className={passSymbolStatus ? "greenText" : null}>Use a symbol (e.g. !@#$)</span>}
          iconColor={userStartWorkWithPasswords ? "red" : "#666666"}
        />
      </div>


      <Form.Item
        name={"confirmNewPassword"}
        label={"Confirm New Password"}
        className='EditPasswordPage-form-box-item'
        initialValue={""}
      >
        <Input.Password
          type={"password"}
          iconRender={visible => (visible ? <UnlockOutlined/> : <LockOutlined/>)}
          className='EditPasswordPage-form-box-item-input'
        />
      </Form.Item>
      {confirmPasswordValidationStatus && (
        <div className={"validationBlockConfirm"}>
          <ValidationBlock
            showIcon={true}
            iconColor={"red"}
            validationStatus={false}
            validationMessage={formValMessage}
          />
        </div>
      )}
      <SettingsButton buttonText={"Save Password"}/>
    </Form>
  </div>
}

export default EditPasswordPage