/* eslint-disable indent */
import React from "react"
import { setActiveInput } from "../../../Redux/store/form/form.actions"
import { connect } from "react-redux"
import { FieldInterface } from "../../../Redux/InterfacesEntity/field.interface"
import { ValidationInterface } from "../../../Redux/InterfacesEntity/validation.interface"
import { switchType } from "./switchType"

type SwitchBlockProps = {
  field: any,
  validationRulers?: ValidationInterface | any,
  required: [string] | any,
  value: string | number | any,
  observer: boolean,
  currentStep: any,
  errorBar: any,
  dispatch: any,
}

const SwitchBlock: React.FunctionComponent<SwitchBlockProps> = ({
  field,
  validationRulers,
  required,
  value,
  observer,
  currentStep,
  errorBar,
  dispatch,
}) => {
  const handlerClickInput = (currentInput: FieldInterface) => {
    if (currentInput.helper) {
      dispatch(setActiveInput(currentInput))
    }
  }

  const { type } = field

  const position = field.modal && field.modal.prevText ? "field-in-block__main-relative" : ""

  const getRequiredStatus = (fieldName: string, arrFields: [string]) => {
    if (arrFields) return arrFields.includes(fieldName)
  }

  return (
    <>
      {type !== "application-package" && (
        <div className={`field-in-block__main ${position} ${field.name}`} onMouseEnter={() => handlerClickInput(field)}>
          {switchType(
            type,
            field,
            value,
            observer,
            validationRulers,
            required && getRequiredStatus(field.name, required)
          )}
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  validationRulers: state.step.validation.properties,
  required: state.step.validation.required,
  currentStep: state.step.currentStep,
  errorBar: state.additional.errorBar,
})

export default connect(mapStateToProps)(SwitchBlock)
