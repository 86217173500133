import UpsellSubmit from "./UpsellSubmit";
import {PaymentInformation, PaymentRequestToken} from "../../../../types/types";

export default class UpsellSubmitBuilder {
    private _formId: string;
    private _upsellFunnelId: number | undefined;
    private _upsellPageId: number | undefined;
    private _action: string | undefined;
    private _iterator: number | undefined;
    private _handler: string | undefined;
    private _uniqSession: string | undefined;
    private _paymentMethod: string | undefined;
    private _expirationDate: string | undefined;
    private _cardNo: string | undefined;
    private _ssc: string | undefined;
    private _zipPay: string | undefined;
    private _period: string | undefined;
    private  _paymentRequestEvent: PaymentRequestToken | undefined;

    constructor() {
        this._formId = "";
    }

    setFormId(formId: string): this {
        this._formId = formId;
        return this;
    }

    setUpsellFunnelId(upsellFunnelId: number | undefined): this {
        this._upsellFunnelId = upsellFunnelId;
        return this;
    }

    setUpsellPageId(upsellPageId: number | undefined): this {
        this._upsellPageId = upsellPageId;
        return this;
    }

    setAction(action: string | undefined): this {
        this._action = action;
        return this;
    }

    setIterator(iterator: number | undefined): this {
        this._iterator = iterator;
        return this;
    }

    setHandler(handler: string | undefined): this {
        this._handler = handler;
        return this;
    }

    setUniqSession(uniqSession: string | undefined): this {
        this._uniqSession = uniqSession;
        return this;
    }

    setPaymentInformation(payment: PaymentInformation): this {
        this._paymentMethod = payment.paymentMethod;
        if (payment.isNewPaymentMethod) {
            this._cardNo = payment.creditCard?.number;
            this._ssc = payment.creditCard?.cvv;
            this._expirationDate = payment.creditCard?.expirationDate;
            this._zipPay = payment.creditCard?.billingZipCode;
        }
        this._period = payment.period;
        this._paymentRequestEvent = payment.requestToken;
        return this;
    }

    build(): UpsellSubmit {
        return new UpsellSubmit(
            this._formId,
            this._upsellFunnelId,
            this._upsellPageId,
            this._action,
            this._iterator,
            this._handler,
            this._uniqSession,
            this._paymentMethod,
            this._expirationDate,
            this._cardNo,
            this._ssc,
            this._zipPay,
            this._period,
            this._paymentRequestEvent
        );
    }
}