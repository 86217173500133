import React from "react"

export const SvgIconEdit = ({ width = 20, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9163 4.16801L16.2497 7.50134M14.583 2.50134C14.7952 2.25054 15.0578 2.04639 15.3539 1.90191C15.6499 1.75743 15.9731 1.67579 16.3027 1.66219C16.6323 1.64859 16.9612 1.70333 17.2683 1.82292C17.5755 1.94251 17.8541 2.12432 18.0866 2.35678C18.3191 2.58923 18.5002 2.86723 18.6185 3.17305C18.7367 3.47886 18.7895 3.80579 18.7735 4.13299C18.7575 4.4602 18.673 4.7805 18.5254 5.07348C18.3778 5.36647 18.1704 5.62571 17.9163 5.83468L6.66634 17.0847L2.08301 18.3347L3.33301 13.7513L14.583 2.50134Z"
        stroke="#9B9B9B"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
