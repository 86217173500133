import React from 'react';

export const SvgReviewRedirectIcon = ({width = 29, height = 28}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="0.5" width="27" height="27" rx="13.5" stroke="#216DE3"/>
      <g clipPath="url(#clip0_4879_96193)">
        <path d="M9.39567 14.0039L19.604 14.0039" stroke="#216DE3" strokeWidth="1.45833" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M14.4998 8.89714L19.604 14.0013L14.4998 19.1055" stroke="#216DE3" strokeWidth="1.45833"
              strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_4879_96193">
          <rect width="17.5" height="17.5" fill="white" transform="translate(5.75 5.25)"/>
        </clipPath>
      </defs>
    </svg>

  );
}

export default SvgReviewRedirectIcon;