import React from 'react';
import {SvgType} from "../SvgType";

export const SvgIconInfo = ({width, height}: SvgType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5013 18.7858C15.1037 18.7858 18.8346 15.0548 18.8346 10.4525C18.8346 5.8501 15.1037 2.11914 10.5013 2.11914C5.89893 2.11914 2.16797 5.8501 2.16797 10.4525C2.16797 15.0548 5.89893 18.7858 10.5013 18.7858Z"
        stroke="#191919" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.5 13.7865V10.4531" stroke="#191919" strokeWidth="1.66667" strokeLinecap="round"
            strokeLinejoin="round"/>
      <path d="M10.5 7.11914H10.5083" stroke="#191919" strokeWidth="1.66667" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
  );
}