import React, {useState} from "react"
import AdditionalQuestionBoolean from "./AdditionalQuestionBoolean";
import AdditionalQuestionSelect from "./AdditionalQuestionSelect";
import AdditionalQuestionSelectBtn from "./AdditionalQuestionSelectBtn";
import {QuestionnaireButtonText} from "./QuestionnaireConstant";

type QuestionnaireAdditionalQuestionProps = {
  question: any,
  setAdditionalQuestions: any,
  dispatchFinish: any,
  searchNextAdditionalQuestion: any,

  disableBtn: boolean,
  loadingNoBtn: boolean,
  loadingYesBtn: boolean,
  buttonLoading: string | undefined,
  setDisabledBtn: any,
  setLoadingYesBtn: any,
  setLoadingNoBtn: any,
  setButtonLoading: any,
}

// eslint-disable-next-line max-len
const QuestionnaireAdditionalQuestion: React.FunctionComponent<QuestionnaireAdditionalQuestionProps> = ({
  question,
  setAdditionalQuestions,
  dispatchFinish,
  searchNextAdditionalQuestion,
  disableBtn,
  loadingNoBtn,
  loadingYesBtn,
  buttonLoading,
  setDisabledBtn,
  setLoadingYesBtn,
  setLoadingNoBtn,
  setButtonLoading,
}) => {
  const handlerAdditionalQuestionClickButton = (value: any, nextQuestion: any) => {
    if(value === QuestionnaireButtonText.YES) {
      setLoadingYesBtn(true);
      setLoadingNoBtn(false);
      setDisabledBtn(true);
    }

    if(value === QuestionnaireButtonText.NO) {
      setLoadingYesBtn(false);
      setLoadingNoBtn(true);
      setDisabledBtn(true);
    }

    if(Number.isInteger(value)) {
      setDisabledBtn(true);
      setButtonLoading(value);
    }

    setTimeout(()=>{
      setAdditionalQuestions(`${question.valueName}=${value}`)
      if(nextQuestion === "complete") {
        dispatchFinish(`${question.valueName}=${value}`)
      } else {
        searchNextAdditionalQuestion(nextQuestion)
      }
    }, 500);
  }

  if (question.valueType === "boolean") {
    return (
      <AdditionalQuestionBoolean
        handlerAdditionalQuestionClickButton={handlerAdditionalQuestionClickButton}
        question={question}
        loadingYesBtn={loadingYesBtn}
        loadingNoBtn={loadingNoBtn}
        disableBtn={disableBtn}
      />
    )
  } else if (question.valueType === "select") {
    return (
      <AdditionalQuestionSelect
        handlerAdditionalQuestionClickButton={handlerAdditionalQuestionClickButton}
        question={question}
      />
    )
  }else if (question.valueType === "selectBtn") {
    return (
      <AdditionalQuestionSelectBtn
        handlerAdditionalQuestionClickButton={handlerAdditionalQuestionClickButton}
        question={question}
        disableBtn={disableBtn}
        buttonLoading={buttonLoading}
      />
    )
  } else {
    return <div>Question Not Supported</div>
  }
}
export default QuestionnaireAdditionalQuestion
