import React from 'react';
import {SvgType} from "../SvgType";

export const SvgClockIcon = ({width = 25, height = 25}: SvgType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" fill="none">
      <path
        d="M12.5 22.4531C18.0228 22.4531 22.5 17.976 22.5 12.4531C22.5 6.93028 18.0228 2.45312 12.5 2.45312C6.97715 2.45312 2.5 6.93028 2.5 12.4531C2.5 17.976 6.97715 22.4531 12.5 22.4531Z"
        stroke="#3E3E3E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 6.45312V12.4531L16.5 14.4531" stroke="#3E3E3E" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
  );
}