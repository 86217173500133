import React, {useEffect, useState} from "react"
import {connect, useSelector} from "react-redux"
import {
    setStatusGovernmentFeeOpen,
    setStatusQuestionnaireFront,
    setStatusTSADetailsToOpen,
} from "../../../../Redux/store/additionalForm/additional.actions"
import yesIcon from "../../../../assets/image/bigYes.svg"
import PassportDetailsComponent from "../PassportDetailsComponent/PassportDetailsComponent"
import QuestionaryComponentTSA from "../QuestionaryTSA/QuestionaryComponentTSA"
import GovernmentFeesFormFields
    from "../../AdditionalPageComponent/AdditionalPageByType/GovermentFees/GovernmentFeesFormFields"
import {BillingInformation} from "../../BlockFormWithFields/BillingInformation/BlockBillingInformation"
import {RootState} from "../../../../App"
import {additionalSelector} from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import {delayTimer} from "../../../../Pages/FormPage/FormPage"
import {getScrollElementById} from "../../FormBlock/FormBlock"
import GAdditionalStep, {StepVisualStatus} from "../../../atomicDesign/templates/AdditionalStep/AdditionalStep"
import GAdditionalStepUpsell from "../../../atomicDesign/templates/AdditionalStep/AdditionalStepUpsell";
import {LoadingState} from "../../../../shared/constans/user-from-view-mode.enum";
import {PaymentRequestTokenEvent} from "@stripe/stripe-js";
import {getIdNameFromStatuses} from "../../../../util/BlockStep";
import { BlockStepTypes } from "../../../../Constants/BlockStepTypes"

type BlocksStepsTsaProps = {
    blocksStep: any,
    index: number,
    showStatus: string,
    statusDetails: string,
    statusDetailsToOpen: string,
    statusQuestionary: string,
    statusQuestionnaireFront: string,
    statusGovernmentFeeOpen: string,
    submitObject: {} | any,
    currentResponse: any,
    loadingButtonState: string,
    appId: string,
    formId: string,
    statusDocuments: string,
    customer: {} | any,
    dispatch: any,
    billingInformation: BillingInformation,
    upsellProcessingInformation: any,
    onGatewayPaymentStartedWithToken?: (event: PaymentRequestTokenEvent, userCoupon: any) => Promise<void>
}

const BlocksStepsTsa: React.FunctionComponent<BlocksStepsTsaProps> = ({
                                                                          blocksStep,
                                                                          index,
                                                                          showStatus,
                                                                          statusDetails,
                                                                          statusDetailsToOpen,
                                                                          statusQuestionary,
                                                                          statusQuestionnaireFront,
                                                                          statusGovernmentFeeOpen,
                                                                          submitObject,
                                                                          currentResponse,
                                                                          loadingButtonState,
                                                                          appId,
                                                                          formId,
                                                                          statusDocuments,
                                                                          customer,
                                                                          dispatch,
                                                                          billingInformation,
                                                                          upsellProcessingInformation,
                                                                          onGatewayPaymentStartedWithToken

                                                                      }) => {
    const uniqSession = submitObject["unique-section-id"]
    const handlerGovernmentFeeOpen = (status: string) => {
        dispatch(setStatusGovernmentFeeOpen(status))
    }
    const handlerDetailsToOpen = (status: string) => {
        dispatch(setStatusTSADetailsToOpen(status))
    }

    const handlerPreQuestionnaireClick = () => {
        dispatch(setStatusQuestionnaireFront("process"))
        sessionStorage.setItem("statusQuestionnaireFront", "process")
    }

    const setStripeElements = () => {
    }

    const {statusSignaturePage} = useSelector<RootState, { statusSignaturePage: string } | undefined>(
        additionalSelector
    ) ?? {statusSignaturePage: ""}

    useEffect(() => {
        delayTimer(300).then(() => getScrollElementById({id: `${statusSignaturePage}_scrollById`}))
    }, [statusSignaturePage])

    let idNameFromStatuses = getIdNameFromStatuses(blocksStep.status)

    return (
        <div className={`steps-signature-container`} id={`${idNameFromStatuses}${index !== 1 ? "_scrollById" : ""}`}>
            {blocksStep.type === BlockStepTypes.CONTENT && (
                <div className={`status_complete`}>
                    <div className={`icon-container_complete`}>
                        <img src={yesIcon} alt="approve"/>
                    </div>
                    <div className={`div-bg`}>
                        <p>{blocksStep?.content?.complete}</p>
                    </div>
                </div>
            )}

            {blocksStep.type === BlockStepTypes.UPSELL && upsellProcessingInformation && upsellProcessingInformation[blocksStep.status] && (
                <GAdditionalStepUpsell
                    title={blocksStep.content.title}
                    titleDone={(upsellProcessingInformation[blocksStep.status]?.action === 'cancelled' && blocksStep.content?.titleSkip) ? blocksStep.content.titleSkip : blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    formId={formId}
                    uniqSession={uniqSession}
                    blockStatus={blocksStep.status}
                />
            )}

            {blocksStep.type === BlockStepTypes.ADDITIONAL_INFORMATION_STEP && (
                <GAdditionalStep
                    index={index.toString()}
                    title={blocksStep.content?.title}
                    titleDone={blocksStep.content?.title}
                    information={blocksStep.content?.information}
                    ctaText={blocksStep.content?.button}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusDetailsToOpen}
                    ctaForm={() => handlerDetailsToOpen(StepVisualStatus.STATUS_PROCESS)}
                >
                    <PassportDetailsComponent forceLoadingAndDisabled={true} blocksStep={blocksStep}
                                              statusSignaturePage={""}/>
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.QUESTIONNAIRE && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusQuestionnaireFront}
                    index={index.toString()}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    ctaForm={handlerPreQuestionnaireClick}
                    loadingButton={loadingButtonState === LoadingState.Loading}
                >
                    <QuestionaryComponentTSA blocksStep={blocksStep}/>
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.GOVERNMENT_FEES && billingInformation?.charges?.length > 0 && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusGovernmentFeeOpen}
                    index={index.toString()}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    ctaForm={() => handlerGovernmentFeeOpen(StepVisualStatus.STATUS_PROCESS)}
                >
                    <GovernmentFeesFormFields onGatewayPaymentStartedWithToken={onGatewayPaymentStartedWithToken}
                                              blocksStep={blocksStep} setStripeElements={setStripeElements}
                                              index={index}/>
                </GAdditionalStep>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    statusDetails: state.additional.statusDetails,
    statusDetailsToOpen: state.additional.statusDetailsToOpen,
    statusQuestionary: state.additional.statusQuestionary,
    statusQuestionnaireFront: state.additional.statusQuestionnaireFront,
    submitObject: state.form.submitObject,
    currentResponse: state.step.currentResponse,
    loadingButtonState: state.loading.loadingButtonState,
    appId: state.form.appId,
    formId: state.form.formId,
    missingInformationStatus: state.form.missingInformationStatus,
    statusDocuments: state.additional.statusDocuments,
    customer: state.form.customer,
    statusGovernmentFeeOpen: state.additional.statusGovernmentFeeOpen,
    billingInformation: state.step.billingInformation,
    upsellProcessingInformation: state.additional.upsellProcessingInformation
})

export default connect(mapStateToProps)(BlocksStepsTsa)
