/* eslint-disable react-redux/useSelector-prefer-selectors */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from "react"
import "./AlertMessagesPage.scss"
import { Card, Collapse } from "antd"
import GText from "../../../Components/atomicDesign/atoms/Text/Text"
import { SIZE } from "../../../Components/atomicDesign/types/types"
import { COLORS_NEW } from "../../../Components/atomicDesign/types/Colors"
import {
  AlertBackgroundTypes,
  AlertDismissStatuses,
  bottomConfirmModalText,
  DismissAlertStatuses,
  formatDateToCustomString,
  headerConfirmModalText,
  IdentityProtectionType,
  isAlert,
  isDismissed,
  isRedAlert,
  parseJSON,
  renderNotificationIcon,
  sortByDate,
  topConfirmModalText,
} from "../../../util/IdentityProtectionHelper"
import CustomTabs from "../../../Components/atomicDesign/templates/CustomTabs/CustomTabs"
import CollapseTopPart from "../../../Components/atomicDesign/templates/CollapseTop/CollapseTop"
import CollapseBottomPart from "../../../Components/atomicDesign/templates/CollapseBottom/CollapseBottom"
import { AlertMessageProps, AlertMessagesPageProps } from "./AlertMessagesPageProps"
import { useIdentityProtection } from "../../../../../dashboard-govplus-front/src/hooks/useIdentityProtection"
import IDPConfirmModal from "../../../Components/atomicDesign/templates/IDPConfirmModal/IDPConfirmModal"
import useIsMobile from "../../../../../dashboard-govplus-front/src/hooks/useIsMobile"
import GAlertBackgroundMessage from "../../../Components/atomicDesign/templates/AlertBackgroundMessage/AlertBackgroundMessage"
import GSpinner from "../../../Components/atomicDesign/atoms/Spinner/Spinner"
import { useHelperSubscription } from "../../../../../dashboard-govplus-front/src/hooks/useHelperSubscription"
import { useDispatch, useSelector } from "react-redux"
import { getAlerts } from "../../../../../dashboard-govplus-front/src/appRedux/actions/IDPActions"

const AlertMessagesCollapse: FC<AlertMessagesPageProps> = ({ alerts, actionsBlock }) => {
  const { dismissAlertAction, dismissAlertResult, getAlerts, loadingAlerts } = useIdentityProtection()
  const { triggerIdentityProtectionAlertConfirmed, triggerIdentityProtectionAlertDismissed } = useHelperSubscription()

  // @ts-ignore
  const { name, lastName, eMail, phone } = useSelector((state) => state.auth.userData)

  const isMobile = useIsMobile(768)

  const [expandedKey, setExpandedKey] = useState("")
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const handlePanelClick = (key: any) => {
    setExpandedKey(key === expandedKey ? "" : key)
  }

  const handleNotificationDismiss = useCallback(
    async (event: MouseEvent, item: AlertMessageProps) => {
      event.preventDefault()

      if (item.CanRedAlert === "0" && !isDismissed(item)) {
        await dismissAlertAction(item.AlertID, {
          status: AlertDismissStatuses.DISMISS,
        })
      }
    },
    [dismissAlertAction, getAlerts]
  )

  return (
    <>
      {loadingAlerts && (
        <div className={"AlertMessagesCollapse__Overlay"}>
          <div className={"AlertMessagesCollapse__Spinner"}>
            <GSpinner />
          </div>
        </div>
      )}
      <Collapse
        accordion
        onChange={handlePanelClick}
        className={loadingAlerts ? "AlertMessagesCollapse__Loading" : "AlertMessagesCollapse"}
      >
        {alerts?.map((alert: AlertMessageProps) => {
          const { ID, AlertClass, DateTime, AlertCenterMsg, AlertID } = alert
          return (
            <Collapse.Panel
              key={ID}
              header={
                <CollapseTopPart
                  key={ID}
                  topLeftMessage={AlertClass}
                  showIcon={true}
                  bold={isAlert(alert)}
                  timestamp={formatDateToCustomString(DateTime, isMobile)}
                  click={(e: MouseEvent) => handleNotificationDismiss(e, alert)}
                  style={{ maxWidth: "100%", width: "100%" }}
                />
              }
            >
              <CollapseBottomPart
                key={ID}
                description={AlertCenterMsg}
                actionsBlock={actionsBlock}
                showActionButtons={
                  // show only in alerts tab and can be handled
                  isAlert(alert) && alert.CanRedAlert === "1"
                }
                alert={alert}
                confirmButtonAction={async () => {
                  await dismissAlertAction(AlertID, {
                    status: AlertDismissStatuses.DISMISS,
                  })
                  triggerIdentityProtectionAlertDismissed(AlertClass, AlertID, name, lastName, eMail, phone)
                }}
                declineButtonAction={async () => {
                  await dismissAlertAction(AlertID, { status: AlertDismissStatuses.RED_ALERT })
                  triggerIdentityProtectionAlertConfirmed(AlertClass, AlertID, name, lastName, eMail, phone)
                  if (dismissAlertResult === DismissAlertStatuses.SUCCESS) {
                    setOpenConfirmModal(true)
                  }
                }}
              />
            </Collapse.Panel>
          )
        })}
      </Collapse>
      <IDPConfirmModal
        topText={topConfirmModalText}
        headerText={headerConfirmModalText}
        open={openConfirmModal}
        bottomText={bottomConfirmModalText}
        width={560}
        onCancel={() => setOpenConfirmModal(false)}
        click={() => setOpenConfirmModal(false)}
      />
    </>
  )
}

const AlertMessagePage = () => {
  const dispatch = useDispatch()
  const { dismissAlertResponse } = useIdentityProtection()

  // @ts-ignore
  const { alerts, alertsError } = useSelector((state) => state.identityProtectionData)

  const onLoad = useCallback(() => {
    dispatch(getAlerts(IdentityProtectionType.ALL))
  }, [dispatch, dismissAlertResponse])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const filteredAlerts = parseJSON(alerts.getAllAlertsResult)?.filter(
    (alert: any) =>
      (isAlert(alert) && alert.CanRedAlert === "1") ||
      isRedAlert(alert) ||
      (isDismissed(alert) && alert.CanRedAlert === "1")
  )

  const filteredNotifications = parseJSON(alerts.getAllAlertsResult)?.filter(
    (alert: any) => (isAlert(alert) && alert.CanRedAlert === "0") || (isDismissed(alert) && alert.CanRedAlert === "0")
  )

  const notificationStyle = {
    minWidth: "22px",
    height: "22px",
    background: COLORS_NEW.RED_700,
  }

  const alertsCount = filteredAlerts?.filter((alert: any) => isAlert(alert)).length

  const notificationsCount = filteredNotifications?.filter((alert: any) => isAlert(alert)).length

  const getAlertTabsContent = () => {
    if (alertsError) {
      return (
        <GAlertBackgroundMessage
          status={AlertBackgroundTypes.ERROR}
          message={"Error fetching user data. Please try again later."}
        />
      )
    }
    if (filteredAlerts?.length) {
      return (
        <>
          <div className={"AlertMessagePage__Note"}>
            <GText
              text={
                "Below, you’ll find alerts for when your personal information has been used. Let us know whether each activity was you or not, so we can take the appropriate next steps."
              }
              size={SIZE.PARAGRAPH_REGULAR_16}
              color={COLORS_NEW.BLACK_800}
            />
          </div>
          <AlertMessagesCollapse actionsBlock={true} alerts={sortByDate(filteredAlerts)} />
        </>
      )
    }

    return (
      <GText
        text={"Good news! There is no suspicious activity that needs your attention."}
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
    )
  }

  const getNotificationTabsContent = () => {
    if (alertsError) {
      return (
        <GAlertBackgroundMessage
          status={AlertBackgroundTypes.ERROR}
          message={"Error fetching user data. Please try again later."}
        />
      )
    }
    if (filteredNotifications?.length) {
      return <AlertMessagesCollapse actionsBlock={true} alerts={sortByDate(filteredNotifications)} />
    }

    return []
  }

  const tabsData = [
    {
      key: "1",
      tab: "Alerts",
      icon: renderNotificationIcon(alertsCount, notificationStyle, SIZE.PARAGRAPH_BOLD_12),
      content: getAlertTabsContent(),
    },
    {
      key: "2",
      tab: "Notifications",
      icon: renderNotificationIcon(notificationsCount, notificationStyle, SIZE.PARAGRAPH_BOLD_12),
      content: getNotificationTabsContent(),
    },
  ]

  return (
    <div className={"AlertMessagePage"}>
      <div className={"AlertMessagePage__Header"}>
        <GText text={"Alert messages"} size={SIZE.PARAGRAPH_BOLD_40} color={COLORS_NEW.BLACK_1000} />
      </div>
      <div className={"AlertMessagePage__Content"}>
        <Card className={"AlertMessagePage__Content__Card"}>
          <CustomTabs tabsData={tabsData} />
        </Card>
      </div>
    </div>
  )
}

export default AlertMessagePage
