/* eslint-disable indent */
import {ActionTypes} from "./additional.actions"
import { Action } from "../../interfacesAction/action.interface"
import { DOCUMENT_NAMES, DOCUMENT_TYPES } from "../upload/upload.saga"
import {IUpsellStepVisualStatus} from "../../InterfacesEntity/upsell.step.interfaces";

const getlocalStorage = (nameItem: string) => sessionStorage.getItem(nameItem) || ""

const getSuccessPageSubmit = () => JSON.parse(localStorage.getItem("submitAdditional") || "{}")

export interface State {
  indexSignature: number;
  statusSignature: string;
  statusQuestionary: string;
  statusQuestionnaireFront: string;
  statusDocuments: string;
  statusAdditionalDocuments: string;
  statusPhotoUpload: string;
  successPageSubmit: {} | any;
  errorBar: boolean;
  documents: [];
  additionalDocuments: [];
  statusSignaturePage: string;
  statusDetails: string;
  statusArrayUpsellStep: IUpsellStepVisualStatus[],
  taxReturn: any;
  openColumnsTaxesModalWithUrl: string | null;
}

const initialState: State = {
  indexSignature: 1,
  statusSignature: getlocalStorage("statusSignature") || "",
  statusQuestionary: getlocalStorage("statusQuestionary") || "",
  statusQuestionnaireFront: getlocalStorage("statusQuestionnaireFront") || "",
  statusDocuments: getlocalStorage("statusDocuments") || "",
  statusAdditionalDocuments: getlocalStorage("additionalDocuments") || "",
  statusPhotoUpload: getlocalStorage("statusPhotoUpload") || "",
  successPageSubmit: getSuccessPageSubmit() || "",
  errorBar: false,
  documents: [],
  additionalDocuments: [],
  statusSignaturePage: "",
  statusDetails: getlocalStorage("statusDetails") || "",
  statusArrayUpsellStep: [],
  taxReturn: null,
  openColumnsTaxesModalWithUrl: null,
}

const getDocumentsArray = (documents: any, payload: any) => {
  let arrDocuments = documents.filter((document: {} | any) => document.name_field !== payload.name_field)
  if (payload.file) {
    arrDocuments = [...arrDocuments.concat(payload)]
  }
  return arrDocuments
}

export const additionalReducer = (state: State = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_STEP_SIGNATURE:
      return {
        ...state,
        indexSignature: action.payload,
      }
    case ActionTypes.SET_STATUS_QUESTIONARY:
      return {
        ...state,
        statusQuestionary: action.payload,
      }
    case ActionTypes.SET_ADDRESS_VALIDATION:
      return {
        ...state,
        statusAddress: action.payload,
      }
    case ActionTypes.SET_UPSELL_STEP:
      return {
        ...state,
        statusUpsellStep: action.payload,
      }
    case ActionTypes.SET_ARRAY_UPSELL_STEP:
      return {
        ...state,
        statusArrayUpsellStep: action.payload,
      }
    case ActionTypes.SET_NEW_ADDRESS_VALIDATION:
      return {
        ...state,
        statusNewAddress: action.payload,
      }
    case ActionTypes.SET_STATUS_QUESTIONNAIRE_FRONT:
      return {
        ...state,
        statusQuestionnaireFront: action.payload,
      }
    case ActionTypes.SET_STATUS_SIGNATURE:
      return {
        ...state,
        statusSignature: action.payload,
      }
    case ActionTypes.SET_STATUS_SIGNATURE_OPEN:
      return {
        ...state,
        statusSignatureBlockOpen: action.payload,
      }
    case ActionTypes.SET_STATUS_UPLOAD_DOCUMENT_OPEN:
      return {
        ...state,
        statusDocumentsBlockOpen: action.payload,
      }
    case ActionTypes.SET_STATUS_UPLOAD_ADDITIONAL_DOCUMENT_OPEN:
      return {
        ...state,
        statusAdditionalDocumentsBlockOpen: action.payload,
      }
    case ActionTypes.SET_CAN_NOTARIZE:
      return {
        ...state,
        canNotarize: action.payload,
      }
    case ActionTypes.SET_NOTARIZATION_LINK:
    return {
      ...state,
      notarizationLink: action.payload,
    }
    case ActionTypes.SET_EIN_NUMBER:
      return {
        ...state,
        einNumber: action.payload,
      }
    case ActionTypes.SET_UPSELL_INFORMATION:
      return {
        ...state,
        upsellInformation: action.payload,
      }
    case ActionTypes.SET_UPSELL_PROCESSING_INFORMATION:
      return {
        ...state,
        upsellProcessingInformation: action.payload,
      }
    case ActionTypes.SET_STATUS_PASSPORT_DETAILS:
      return {
        ...state,
        statusDetails: action.payload,
      }
    case ActionTypes.SET_STATUS_TSA_TO_OPEN_DETAILS:
      return {
        ...state,
        statusDetailsToOpen: action.payload,
      }
    case ActionTypes.SET_STATUS_GOVERNMENT_FEE_OPEN_DETAILS:
      return {
        ...state,
        statusGovernmentFeeOpen: action.payload,
      }
    case ActionTypes.SET_STATUS_TSA_DETAILS:
      return {
        ...state,
        statusDetails: action.payload,
      }
    case ActionTypes.SET_STATUS_UPLOAD_DOCUMENTS:
      return {
        ...state,
        statusDocuments: action.payload,
      }
    case ActionTypes.SET_STATUS_PHOTO_UPLOAD:
      return {
        ...state,
        statusPhotoUpload: action.payload,
      }
    case ActionTypes.SET_STATUS_UPLOAD_ADDITIONAL_DOCUMENTS:
      return {
        ...state,
        statusAdditionalDocuments: action.payload,
      }
    case ActionTypes.SET_SIGNATURE:
      return {
        ...state,
        successPageSubmit: {
          ...state.successPageSubmit,
          signature: action.payload,
        },
      }
    case ActionTypes.SET_FIELDS:
      return {
        ...state,
        successPageSubmit: {
          ...state.successPageSubmit,
          fields: action.payload,
        },
      }
    case ActionTypes.SET_ANSWERS_QUESTIONARY:
      return {
        ...state,
        successPageSubmit: {
          ...state.successPageSubmit,
          answersQuestionary: action.payload,
        },
      }
    case ActionTypes.SET_DOCUMENTS:
      let arrDocuments: [] | any = getDocumentsArray(state.documents, action.payload)
      return {
        ...state,
        documents: arrDocuments,
      }
    case ActionTypes.SET_ADDITIONAL_DOCUMENTS:
      let arrAdditionalDocuments: [] | any = getDocumentsArray(state.additionalDocuments, action.payload)
      return {
        ...state,
        additionalDocuments: arrAdditionalDocuments,
      }
    case ActionTypes.REMOVE_DOCUMENT:
      const { name_field, typeDocument } = action.payload
      const filterArrayDocuments = (nameField: string, arrayDocuments: { name_field: string }[]) =>
        arrayDocuments.filter(({ name_field }) => name_field !== nameField)

      const returnCurrentArrayDocuments = (state: { documents: [], additionalDocuments: [] }, typeDocument: string) =>
        [
          {
            arrayDocuments: state.documents,
            type: DOCUMENT_TYPES.UPLOADING_DOCUMENTS,
            name: DOCUMENT_NAMES.UPLOADING_DOCUMENTS,
          },
          {
            arrayDocuments: state.additionalDocuments,
            type: DOCUMENT_TYPES.UPLOAD_ADDITIONAL_DOCUMENTS,
            name: DOCUMENT_NAMES.UPLOAD_ADDITIONAL_DOCUMENTS,
          },
        ].find(({ type }) => type === typeDocument)
      return {
        ...state,
        [returnCurrentArrayDocuments(state, typeDocument)?.name ?? DOCUMENT_NAMES.UPLOADING_DOCUMENTS]:
          filterArrayDocuments(
            name_field,
            returnCurrentArrayDocuments(state, typeDocument)?.arrayDocuments ?? state.documents
          ),
      }
    case ActionTypes.CLEAR_ARRAYS_DOCUMENTS:
      return {
        ...state,
        documents: [],
        additionalDocuments: [],
      }
    case ActionTypes.SET_CLEAR_TEMP_DOCUMENT:
      let emptyDocuments: [] | any = []
      return {
        ...state,
        documents: emptyDocuments,
        additionalDocuments: emptyDocuments,
      }
    case ActionTypes.SET_ERROR_BAR_VALUE:
      return {
        ...state,
        errorBar: action.payload,
      }
    case ActionTypes.SET_LINK_VALUE:
      return {
        ...state,
        link: action.payload,
      }
    case ActionTypes.SET_STATUS_SIGNATURE_PAGE:
      return {
        ...state,
        statusSignaturePage: action.payload,
      }
    case ActionTypes.GET_FAILURE:
      return {
        ...state,
        getFailure: action.payload,
      }
    case ActionTypes.APPROVE_ADDRESS_FIELD:
      return {
        ...state,
        approveAddressField: action.payload,
      }
    case ActionTypes.CLEAR_ADDITIONAL_IN_START_APP:
      return { ...initialState }
    case ActionTypes.SET_TAX_RETURN_INFORMATION:
      return {
        ...state,
        taxReturn: action.payload,
      }
    default:
      return state
  }
}
