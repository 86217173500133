import {all, fork, put, takeLatest} from "redux-saga/effects";
import {createTaxLinkFailure, createTaxLinkSuccess,} from "../actions/Tax";
import {CREATE_TAX_LINK_START} from "../../constants/ActionTypes";
import {taxApis} from "../../api/api";

function* createTaxLinkSaga(){
    try {
        // todo need to pass customerId and applicationId
        const response = yield taxApis.postTaxPageLink({
            customerId: 20486,
            applicationId: 20486,
        });
        yield put(createTaxLinkSuccess(response));
    } catch (error) {
        yield put(createTaxLinkFailure(error));
    }
}

export function* createTaxLinkSagaFork() {
    yield takeLatest(CREATE_TAX_LINK_START, createTaxLinkSaga);
}


export default function* rootSaga() {
    yield all([
        fork(createTaxLinkSagaFork),
    ]);
}