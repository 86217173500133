export interface BlockStep {
    type: string;
    status: string;
}

export const getIdNameFromStatuses = (status: string) => {
    if(Array.isArray(status)){
        return status.join("_")
    } else {
        return status
    }
}