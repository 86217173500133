import {useSubscription} from "../../../../../dashboard-govplus-front/src/hooks/useSubscription";
import {useNavigation} from "../../../../../dashboard-govplus-front/src/hooks/useNavigation";
import React from "react";
import RenewalDocumentsList from "../../../Components/atomicDesign/templates/RenewalDocumentsList/RenewalDocumentsList";
import GButton, {BUTTON_TYPE} from "../../../Components/atomicDesign/molecules/Button/GButton";
import {GeneralText, InsuredDocumentsText, RenewalDocumentsText, SubscriptionText} from "../../../shared/text/Text";
import ProductPage from "../../../Components/atomicDesign/templates/ProductPage/ProductPage";
import {ICONS_SVG_TYPE} from "../../../Components/atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import {COLORS, COLORS_NEW} from "../../../Components/atomicDesign/types/Colors";
import "./RenewalDocumentsCancel.scss"
import GText from "../../../Components/atomicDesign/atoms/Text/Text";
import {SIZE} from "../../../Components/atomicDesign/types/types";
import {useHelperSubscription} from "../../../../../dashboard-govplus-front/src/hooks/useHelperSubscription";
import {useIdentityProtection} from "../../../../../dashboard-govplus-front/src/hooks/useIdentityProtection";


const RenewalDocumentsCancel = () => {
    const {renewedDocuments, subscription} = useSubscription()
    const {identities} = useIdentityProtection()
    const {navigate} = useNavigation()
    const {triggerSubscriptionCancellationConfirmedAnalyticEvent} = useHelperSubscription()

    const proceedCancellation = () => {
        // @ts-ignore
        triggerSubscriptionCancellationConfirmedAnalyticEvent(subscription?.id);
        if (identities?.records?.filter((record: { isPresent: boolean }) => record.isPresent).length > 0) {
            navigate("/SubscriptionCancelIdentityProtection")
        } else {
            navigate("/SubscriptionCancelReason")
        }
    }
    const keepSubscription = () => {
        navigate("./billing-settings")
    }

    const letterSpacingText = {
        letterSpacing: "-0.1px",
        cursor: "pointer"
    }

    const footer = () => {
        return (
            <div className={"GSubscriptionCancel__Footer"}>
                <GButton
                    text={GeneralText.KeepPremium}
                    click={keepSubscription}
                    showIcon={false}
                />
                <GText
                    text={SubscriptionText.CancelSubscriptionRenewal}
                    innerHtml={SubscriptionText.CancelSubscriptionRenewal}
                    size={SIZE.PARAGRAPH_REGULAR_16}
                    color={COLORS_NEW.ROYAL_BLUE_700}
                    style={letterSpacingText}
                    onClick={proceedCancellation}
                    maxWidth={"359px"}
                />
            </div>
        )
    }

    const listComponent = () => {
        // @ts-ignore
        return <div className={"GSubscriptionCancel__Content"}>
            {/* @ts-ignore */}
            <RenewalDocumentsList documents={renewedDocuments.filter(document => document.automaticRenewalDate)} />
        </div>
    }

    return (
        <ProductPage
            List={listComponent}
            headerText={RenewalDocumentsText.RenewalCancelHeader}
            subHeaderText={RenewalDocumentsText.RenewalCancelExplanation}
            Footer={footer}
            headerWidth="540px"
            subHeaderWidth="810px"
        />
    )
}

export default RenewalDocumentsCancel