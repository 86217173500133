import React from "react"

interface ISvgCheckCircle {
  width: number;
  height: number;
  color: string;
}

export const SvgCheckCircleOutline = ({ width = 20, height = 20, color = "#00A660" }: ISvgCheckCircle) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_417_69454)">
        <path d="M10.0003 18.3307C14.6027 18.3307 18.3337 14.5998 18.3337 9.9974C18.3337 5.39502 14.6027 1.66406 10.0003 1.66406C5.39795 1.66406 1.66699 5.39502 1.66699 9.9974C1.66699 14.5998 5.39795 18.3307 10.0003 18.3307Z" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.30176 10.342L8.29734 12.2922C8.46117 12.4523 8.72352 12.4501 8.88468 12.2873L13.6976 7.42529" stroke={color} strokeWidth="1.66667" strokeLinecap="round"/>
      </g>
      <defs>
        <clipPath id="clip0_417_69454">
          <rect width={width} height={height} fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
}
