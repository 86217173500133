import React, { useState } from "react"
import { Form, Input } from "antd"
import { setValueCurrentField, setValueModal } from "../../../Redux/store/form/form.actions"
import { useDispatch, useSelector } from "react-redux"
import {
  arrayStepSelector,
  formSelector,
} from "../../FormComponents/UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { RootState } from "../../../App"

const ReviewPageModal: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const submitObject = useSelector<RootState, { submitObject: {} | any }>(formSelector).submitObject
  const nameActualInput = useSelector<RootState, { nameActualInput: string }>(arrayStepSelector).nameActualInput
  const nameActualLabel = useSelector<RootState, { nameActualLabel: string }>(arrayStepSelector).nameActualLabel
  const [vaLueInput, setValueInput] = useState(submitObject[nameActualInput])
  const handlerSubmit = () => {
    dispatch(setValueCurrentField(nameActualInput, vaLueInput))
    dispatch(setValueModal(""))
  }

  return (
    <div className={"review-page-modal__container"}>
      <div className={"review-page-modal__header"}>
        <h3 className={"review-page-modal__header-text"}>Edit information</h3>
      </div>
      <div className={"review-page-modal__main"}>
        <label className={`review-page-modal__label`}>
          <Form.Item
            label={nameActualLabel}
            name={nameActualInput}
            rules={[
              { required: true, message: "Required" },
              // { pattern: new RegExp("^[0-9]+$"), message: "Number required" },
            ]}
          >
            <Input
              className={`review-page-modal__container`}
              value={vaLueInput}
              onChange={(e) => setValueInput(e?.target?.value)}
            />
          </Form.Item>
        </label>
      </div>
      <div className={"review-page-modal__footer"}>
        <button className={"review-page-modal__footer-btn"} onClick={() => dispatch(setValueModal(""))}>
          Cancel
        </button>
        <button className={"review-page-modal__footer-btn"} onClick={handlerSubmit}>
          Save
        </button>
      </div>
    </div>
  )
}

export default ReviewPageModal
