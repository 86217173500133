import React, { FC } from "react"
import { useDispatch } from "react-redux"
import { BasicBlockContainerProps } from "../UploadPhotoService/interfaces/interfaces"
import { removeStatusUploadPhoto } from "../../../../Redux/store/upload/upload.actions"
import ButtonComponent from "../ButtonComponent/ButtonComponent"
import remove from "../../../../assets/image/remove.svg"

const UploadPhotoRemoveButton: FC<BasicBlockContainerProps> = ({ item: { className, text } }) => {
  const dispatch = useDispatch()
  const handlerOnClickRemove = () => dispatch(removeStatusUploadPhoto())

  return <ButtonComponent className={className} icon={remove} text={text} handlerOnClick={handlerOnClickRemove} />
}

export default UploadPhotoRemoveButton
