export enum HeaderInformation {
    DASHBOARD_HEADER_TITLE= "Complete any application in minutes.",
    DASHBOARD_HEADER_DESCRIPTION= "Now you can submit any government application in minutes. Try it out!",
    DASHBOARD_FROM_GLO_GENERAL_HEADER_TITLE= "GOV+ has prefilled your applications for you",
    DASHBOARD_FROM_GLO_GENERAL_HEADER_DESCRIPTION= "Now select a service to submit an application in seconds.",
    DASHBOARD_FROM_PRODUCT_SERVICE_HEADER_DESCRIPTION= "Now you can submit any government applications in minutes. Try it out with your {service} application!",
    GLO_GENERAL_HEADER_TITLE = "The last government form you’ll ever fill out",
    GLO_GENERAL_HEADER_DESCRIPTION = "Provide your basic information just once and GOV+ will autofill all your government applications for you.",
    FORM_GENERAL_HEADER_TITLE = "The last government form you’ll ever fill out.",
    FORM_GENERAL_HEADER_DESCRIPTION = "Provide your basic information just once and GOV+ will auto-complete your {service} application and any other government applications you may need in the future.",
    DASHBOARD_FROM_GLO_GENERAL_HEADER_TITLE_WITH_OUT_SAVE_GLO = "GOV+ has prefilled your applications for you",
    DASHBOARD_FROM_GLO_GENERAL_HEADER_DESCRIPTION_WITH_OUT_SAVE_GLO = "Now select a service to submit an application in seconds.",
    FORM_HEADER_WITHOUT_REMAINING_QUESTIONS = "Complete your {service} application in seconds",
    FORM_HEADER_DESCRIPTION_WITHOUT_REMAINING_QUESTIONS = "Just answer a few simple questions. GOV+ will fill out your application for you to help you avoid any errors or delays."
}