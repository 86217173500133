import {
  GET_OFFERS, GET_OFFERS_SUCCSSES,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  allOffers:[]
};

const OffersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OFFERS: {
      return {
        ...state,
        loader: false,
      }
    }
    case GET_OFFERS_SUCCSSES: {
      return {
        ...state,
        loader: false,
        allOffers: [...action.payload]
      }
    }
    default:
      return state;
  }
}

export default OffersReducer;
