import React, {useEffect, useRef, useState} from "react";
import "./index.less"
import {Table} from "antd";
import {useHistory} from "react-router-dom";
import {IconSvg} from "../../../../../../components/svgImages/SvgImages";
import SettingsButton from "../../../../../../components/SettingsButton";
import {useSelector} from "react-redux";
import {variablesForTable} from "./variablesForTable";

const PendingStatusComponent = ({productId, status}) => {
  const tableRef = useRef()
  const {allProducts, allProductsLoader} = useSelector(({allProducts}) => allProducts);
  const {name, lastName} = useSelector(({auth}) => auth.userData);
  const {form_id,} = allProducts[productId];
  const {services, governmentAgencies} = allProducts[productId].add;
  const {old_address_verification, new_address_verification} = allProducts[productId].add.billingAddress;
  const history = useHistory();
  const [headerText, setHeaderText] = useState("");
  const {service, agency} = variablesForTable.columns;

  const headerTextChecker = ()=>{
    switch (status){
      case "processing": {
        return "Check the status of your address change below";
      }
      case status !== "processing": {
        return "Your Address Change application is being processed";
      }
      case "completed":{
        return "Check the status of your address change below";
      }
      default: return "Your Address Change application is being processed";
    }
  }

  const addKeyFlagToArrForTable = (arr)=> arr.map((item, id)=>({...item, key: item.id ? item.id : id}))

  useEffect(() => {
    setHeaderText(headerTextChecker())
  }, [status]);

  const statusesWhenUser_Cant_AddOtherProviders = [
    "processing_notifications_services",
    "completed",
    "processing_validation",
    "processing",
    "processing_notifications_agencies",
  ]

  const toGovPage = async () => {
    const webFlowServiceData = JSON.parse(localStorage.getItem("webFlowServicesData"))
    history.push({
      pathname: `/forms/?formId=${form_id}&&applicationId=${productId}`,
    })
    localStorage.setItem("forBreadcrumbs", JSON.stringify({
      headerText: `${webFlowServiceData[allProducts[productId]?.form_id]?.title}`,
      value: webFlowServiceData[allProducts[productId]?.form_id]?.text
    }));
  }

  useEffect(()=>{
    tableRef.current.querySelectorAll(".ant-table-cell").forEach((item)=>{
      if (item.innerText === "pending_shipment"){
        item.innerText = "Pending shipment";
        item.style.color = "#EB9B00";
        item.style.fontWeight = 600;
      }
      if(item.innerText === "pending"){
        item.innerText = "Pending";
        item.style.color = "#EB9B00";
        item.style.fontWeight = 600;
      }
      if(item.innerText === "notified"){
        item.innerText = "Notified";
        item.style.color = "#0FB968";
        item.style.fontWeight = 600;
      }
      if(item.innerText === "shipping"){
        item.innerText = "Shipping";
        item.style.color = "#0FB968";
        item.style.fontWeight = 600;
      }
    })
  },[allProducts[productId]])

  return (
    <div className={"COA_pendingStatusComponent-container"}>
      <div className={"COA_pendingStatusComponent-container_header"}>
        <IconSvg type={status}/>
        <div className={"COA_pendingStatusComponent-container_header_text"}>
          <h1>{headerText}</h1>
        </div>
      </div>

      <div className={"COA_pendingStatusComponent-container_userApplicationInfo"}>
        <div className={"COA_pendingStatusComponent-container_userApplicationInfo_iconsBlock"}>
          <div className={"COA_pendingStatusComponent-container_userApplicationInfo_iconsBlock-oldAddress"}>
            <IconSvg type={"oldAddress"}/>
            <p>Old Address</p>
          </div>
          <span className={"COA_pendingStatusComponent-container_userApplicationInfo_iconsBlock_arrowBetwenOldAndNew"}>
            <IconSvg type={"arrowBetwenOldAndNew"}/>
          </span>
          <div className={"COA_pendingStatusComponent-container_userApplicationInfo_iconsBlock-newAddress"}>
            <IconSvg type={"newAddress"}/>
            <p>New Address</p>
          </div>
        </div>
        <div className={"COA_pendingStatusComponent-container_userApplicationInfo_oldAddressNewAddress"}>
          <div
            className={"COA_pendingStatusComponent-container_userApplicationInfo_oldAddressNewAddress-oldAddressInfo"}>
            <div>
              <p>Address 1:</p>
              <p>{old_address_verification.address_verified.address1}</p>
            </div>

            <div>
              <p>Address 2:</p>
              <p>{old_address_verification.address_verified.address2}</p>
            </div>

            <div>
              <p>Zip Code:</p>
              <p>{old_address_verification.address_verified.zipcode}</p>
            </div>

            <div>
              <p>City:</p>
              <p>{old_address_verification.address_verified.city_name}</p>
            </div>

            <div>
              <p>State:</p>
              <p>{old_address_verification.address_verified.state_code}</p>
            </div>
          </div>

          <div className={"COA_pendingStatusComponent-container_userApplicationInfo_oldAddressNewAddress-newAddressInfo"}>
            <div>
              <p>Address 1:</p>
              <p>{new_address_verification.address_verified.address1}</p>
            </div>

            <div>
              <p>Address 2:</p>
              <p>{new_address_verification.address_verified.address2}</p>
            </div>

            <div>
              <p>Zip Code:</p>
              <p>{new_address_verification.address_verified.zipcode}</p>
            </div>

            <div>
              <p>City:</p>
              <p>{new_address_verification.address_verified.city_name}</p>
            </div>

            <div>
              <p>State:</p>
              <p>{new_address_verification.address_verified.state_code}</p>
            </div>
          </div>
        </div>

        <div
          className={"COA_pendingStatusComponent-container_userApplicationInfo-tableBlock"}
          ref={tableRef}
        >

          <p>Government Agencies - Address Change Notification Status</p>
          <div>
            <Table
              pagination={false}
              columns={agency}
              dataSource={addKeyFlagToArrForTable(governmentAgencies)}
            />
          </div>

          <p>Service Providers - Address Change Notification Status</p>
          <div>
            <Table
              pagination={false}
              columns={service}
              dataSource={addKeyFlagToArrForTable(services)}
            />
          </div>
        </div>

        {
          !statusesWhenUser_Cant_AddOtherProviders.includes(status)
            && <div className={"COA_pendingStatusComponent-container_userApplicationInfo_submitButton"}>
            <SettingsButton buttonText={"Add Service Providers"} onClickFoo={toGovPage}/>
            </div>
        }
      </div>
    </div>
  )
};

export default PendingStatusComponent;
