import React from "react";
import './ProductTicket.less'
import {useSelector} from "react-redux";
import GText from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Text/Text";
import {SIZE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import {COLORS_NEW} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";
const ProductTicket = ({ticketImage, ticketText}) => {
    return (
        <div className={'ticket-container'}>
            <div className={'ticket-container-imageBox'}>
                <img src={ticketImage} alt={ticketText} className={'ticket-container-imageBox_image'}/>
            </div>
            <div className={'ticket-container-textBox'}>
                <GText text={ticketText} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} />
            </div>
        </div>
    )
}

export default ProductTicket;