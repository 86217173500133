import ErrorLog from "../services/ErrorLog/ErrorLog";
import { env } from "../env";

export function notifyError(error) {
  if(env.NODE_ENV !== "production") {
    console.error(error);
  }
  ErrorLog.getInstance(env.REACT_APP_SENTRY_DSN, env.NODE_ENV).notifyError(error);
}

export const getISOFormat = (dateStr) => {
  if (!dateStr) {
    return ""
  }
  const [month, date, year] = dateStr.split("/")

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0")
  }

  return `${year}-${padTo2Digits(month)}-${padTo2Digits(date)}T00:00:00`
}
export function formatToDate(dateString) {

  const date = new Date(dateString);

  const day = date.getDate();

  const month = date.getMonth() + 1;

  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const calculateArithmeticMean = (data, keys) => {
  const values = keys?.map((key) => data[key])?.filter((value) => typeof value === "number")
  const sum = values?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const mean = sum / values?.length
  return Math.round(parseFloat(mean.toFixed(mean)))
}
