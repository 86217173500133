import React, { FC } from "react"
import { Tooltip } from "antd"
import { COLORS } from "../../types/Colors"
import "./Tooltip.scss"
import { TooltipPlacement } from "antd/es/tooltip"
import { PLACEMENT } from "./TooltipTheme"

type GTooltipProps = {
  text: string,
  color?: string,
  children?: React.ReactNode,
  placement?: TooltipPlacement,
}

const GTooltip: FC<GTooltipProps> = ({ children, text, color = COLORS.SLATE_GRAY_800, placement = PLACEMENT.TOP }) => {
  return (
    <Tooltip className="GTooltip" title={text} color={color} placement={placement}>
      {children}
    </Tooltip>
  )
}

export default GTooltip
