import successValidation from "../../../assets/image/successValidation.svg"
import { setValueModal } from "../../../Redux/store/form/form.actions"
import { DispatchInterface } from "../TermsOfUseComponent/TermsOfUseComponent"
import { FC } from "react"

interface AnimationDownLoadPrefilledProps {
  dispatch: DispatchInterface;
}

const AnimationDownLoadPrefilled: FC<AnimationDownLoadPrefilledProps> = ({ dispatch }) => (
  <div className={"animation-prefilled__container"}>
    <div className={"animation-prefilled__header"}>
      <h3 className={"animation-prefilled__header-text"}>New birth certificate</h3>
    </div>
    <div className={"animation-prefilled__main"}>
      <p className={"animation-prefilled__main-text"}>
        You just saved 36 minutes, with GOV<span className={"animation-prefilled__main-text-plus"}>+</span>
      </p>
      <div className={"animation-prefilled__main-animation-svg"}>
        <div className={"animation-prefilled__svg-wrapper"}>
          {/* {<img src={successValidation} alt={""} className={"animation-prefilled__icon-svg"} />} */}
        </div>
      </div>
    </div>
    <div className={"animation-prefilled__footer"}>
      <button className={"animation-prefilled__footer-btn"} onClick={() => dispatch(setValueModal(""))}>
        Continue
      </button>
    </div>
  </div>
)

export default AnimationDownLoadPrefilled
