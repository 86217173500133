import {hideEmptyBlocksEIN} from "./service.preffiled.ein";
import FlattenDataDto from "../../../dto/FLattenDataDto/FlattenDataDto";

interface blocksWithFieldsInterface {
    name: string;
    fields?: [];
    tag?: string
    type: string;
}

const arrayTypesBlock = ["observer", "nestBlock", "dateCoaGroup", "nestBlockPrefilled"]

const arraySimpleFields = ["radio", "select", "input", "input_number", "dateGroupDropDown", "datePicker", "dateCoaGroup","address_autofill", "city_birth_autofill"]

interface hideEmptyBlocksProps {
    arrayBlocks: blocksWithFieldsInterface[];
    flattenData: { [key: string]: string };
    prefilledStore: { [key: string]: string }
}

interface checkPrefilledField {
    field: blocksWithFieldsInterface;
    flattenData: { [key: string]: string };
    prefilledStore: { [key: string]: string };
}

interface findCaseForBlockOrField {
    arrayBlocks: blocksWithFieldsInterface;
    flattenData: { [key: string]: string };
    prefilledStore: { [key: string]: string };
}

interface getToolsForFormsInterface {
    arraySteps: [];
    flattenData: { [key: string]: string };
    prefilledStore: { [key: string]: string } | {};
    product: string;
}

const SPLITTER = "/"

const mapDatesForStore =
    ({valueTag, arraySubFields, SPLITTER, prefilledStore}: any) =>
      valueTag
        ?.split(SPLITTER)
        ?.forEach((splitterDate: any, index: number) => prefilledStore[arraySubFields?.[index] ?? ""] = splitterDate)

const getSpecialCases = ({field, flattenData, prefilledStore}: any) => {
  if (field?.type === "dateGroupDropDown") {
    const arraySubFields = field?.fields?.map(({name}: any) => name)

    mapDatesForStore({valueTag: flattenData[field?.tag ?? ""], arraySubFields, SPLITTER, prefilledStore})
  }
  if (field?.name?.includes("ssn")) {
    prefilledStore[`${field?.name}_hidden`] = flattenData[field?.tag]
  }
}

const checkPrefilledField = ({field, flattenData, prefilledStore}: checkPrefilledField) => {
  if (field?.tag && flattenData?.[field?.tag ?? ""]) {
    getSpecialCases({field, flattenData, prefilledStore})
    prefilledStore[field?.name] = flattenData[field?.tag]
    return null
  }
  return field
}

const getPhoneNumber = ({field, flattenData, prefilledStore}: any) => {
  const flattenDataHandler = new FlattenDataDto(flattenData);
  if (flattenDataHandler.phoneNumber?.length === 14) {
    prefilledStore["phone_number"] = flattenDataHandler.phoneNumber
    prefilledStore["phone"] = flattenDataHandler.phoneNumber
    return null
  }
  return field
}

const showCitizenshipBlock = ({block, flattenData, prefilledStore}: any) => {
  const arrayCitizenshipBlock = ["personal.qualifyingQuestion.isUScitizen"]
  if (block?.fields?.some?.((field: any) => arrayCitizenshipBlock.some?.((tag: any) =>
    tag === field?.tag && flattenData?.[field?.tag ?? ""] !== undefined ))) {
    block?.fields?.some?.((field: any) => prefilledStore[field?.name] = flattenData[field?.tag])
    return null
  }
  return block
}

const showNonImmigrantBlock = ({block, flattenData, prefilledStore}: any) => {
  const flattenDataHandler = new FlattenDataDto(flattenData);
  if (flattenDataHandler.countryOfCitizenShip) {
    return null
  }
  return block
}

const hideAddressAutofill = ({block, flattenData, prefilledStore}:any) => {
  if(flattenData[block.tag]) {
    const residentialAddress = {
      fieldTag: FlattenDataDto.VAR_ADDRESS_1,
      fieldValue: "residential_address1",
    };

    const residentialZipCode = {
      fieldTag: FlattenDataDto.VAR_ZIP_CODE,
      fieldValue: "residential_zipcode",
    };

    const residentialCityName = {
      fieldTag: FlattenDataDto.VAR_CITY,
      fieldValue: "residential_city_name",
    };

    const residentialStateCode = {
      fieldTag: FlattenDataDto.VAR_CITY,
      fieldValue: "residential_city_name",
    };

    const addressArray = [
      residentialAddress,
      residentialZipCode,
      residentialCityName,
      residentialStateCode,
    ];

    addressArray.forEach(({ fieldTag, fieldValue }) => prefilledStore[fieldValue] = flattenData[fieldTag]);
    return null
  }
  return block
}

const hideCityBirthAutofill = ({block, flattenData, prefilledStore}:any) => {
  if(flattenData[block.tag]) {
    const countryBirthObj = {
      fieldTag: FlattenDataDto.VAR_COUNTRY_OF_BIRTH,
      fieldValue: "country_birth",
    }

    const stateBirthObj = {
      fieldTag: FlattenDataDto.VAR_STATE_OF_BIRTH,
      fieldValue: "state_birth",
    }

    const cityBirthObj = {
      fieldTag: FlattenDataDto.VAR_CITY_OF_BIRTH,
      fieldValue: "city_birth",
    }

    const demographicsArray = [
      countryBirthObj,
      stateBirthObj,
      cityBirthObj,
    ];

    demographicsArray.forEach(({ fieldTag, fieldValue }) => prefilledStore[fieldValue] = flattenData[fieldTag]);
    return null
  }
  return block
}


const findCaseForBlockOrField = ({blockOrField, flattenData, prefilledStore}: any) => {
  return [
    {
      condition: blockOrField.name === "block_relatives",
      action: () => {
        return null
      }
    },
    {
      condition: blockOrField.name === "block_non-immigrant_visa",
      action: () => {
        return showNonImmigrantBlock({block: blockOrField, flattenData, prefilledStore})
      }
    },
    {
      condition: blockOrField.name === "Citizenship",
      action: () => {
        return showCitizenshipBlock({block: blockOrField, flattenData, prefilledStore})
      }
    },
    {
      condition:  blockOrField.type === "address_autofill",
      action: () => hideAddressAutofill({block: blockOrField, flattenData, prefilledStore})
    },
    {
      condition: blockOrField.type === "city_birth_autofill",
      action: () => hideCityBirthAutofill({block: blockOrField, flattenData, prefilledStore})
    },
    {
      condition: blockOrField.type === "description" && blockOrField.nest_input,
      action: () => blockOrField
    },
    {
      condition: arrayTypesBlock.some((typeBlock: string) => (typeBlock === blockOrField.type) || !blockOrField?.type),
      action: () => {
        if(hideEmptyBlocks({arrayBlocks: blockOrField.fields ?? [], flattenData, prefilledStore})) {
          return  {
            ...blockOrField,
            fields: hideEmptyBlocks({arrayBlocks: blockOrField.fields ?? [], flattenData, prefilledStore})
          }
        }
        return null
      }
    },
    {
      condition: arraySimpleFields.some((field: string) => field === blockOrField.type),
      action: () => {
        return checkPrefilledField({field: blockOrField, flattenData, prefilledStore})
      }
    },
    {
      condition: ["phone_wrapper"].some((field: string) => field === blockOrField.type),
      action: () => {
        return getPhoneNumber({field: blockOrField, flattenData, prefilledStore})
      }
    },
  ].find(({condition}) => condition)?.action()
}

const hideEmptyBlocks = ({arrayBlocks, flattenData, prefilledStore}: any) => {
  const filteredArray: any = arrayBlocks.reduce((acc: any, blockOrField: any) => {
    if (findCaseForBlockOrField({blockOrField, flattenData, prefilledStore})) {
      return [...acc,
        findCaseForBlockOrField({blockOrField, flattenData, prefilledStore})
      ]
    }
    return [...acc]
  }, [])

  return filteredArray.length ? filteredArray : null
}

export const getToolsForForms = ({arraySteps, flattenData, prefilledStore, product}: getToolsForFormsInterface) => {
  const getArrayBlocks = (step: any) => {
    return step?.stepContent?.blocksOnFormPage?.[0]?.blockOnFormPage
      ?.formsInBlockOnFormPage?.[0]?.form?.blocksWithFieldsOrNestBlocks
  }

  const arrayStepsAfterPrefilled = arraySteps.reduce((acc: any, step: any) => {
    let newArrayBlocks
    if(product !== "EIN") {
      newArrayBlocks = hideEmptyBlocks({arrayBlocks: getArrayBlocks(step), flattenData, prefilledStore})
    }
    if(product === "EIN") {
      newArrayBlocks = hideEmptyBlocksEIN({arrayBlocks: getArrayBlocks(step), flattenData, prefilledStore})
    }
    let newStep =
            {
              ...step, stepContent: {
                ...step?.stepContent, blocksOnFormPage: {
                  ...step?.stepContent?.blocksOnFormPage, 0:
                            {
                              ...step?.stepContent?.blocksOnFormPage[0], blockOnFormPage:
                                    {
                                      ...step?.stepContent?.blocksOnFormPage?.[0]?.blockOnFormPage,
                                      formsInBlockOnFormPage:
                                            {
                                              ...step?.stepContent?.blocksOnFormPage?.[0]?.blockOnFormPage
                                                ?.formsInBlockOnFormPage, 0:
                                                    {
                                                      ...step?.stepContent?.blocksOnFormPage?.[0]?.blockOnFormPage
                                                        ?.formsInBlockOnFormPage?.[0], form: {
                                                        ...step?.stepContent?.blocksOnFormPage?.[0]?.blockOnFormPage
                                                          ?.formsInBlockOnFormPage?.[0]?.form,
                                                        blocksWithFieldsOrNestBlocks:
                                                            newArrayBlocks
                                                      }
                                                    }
                                            }
                                    }
                            }
                }
              }
            }

    if (newArrayBlocks) {
      return [...acc, newStep]
    }
    return [...acc]
  }, [])

  return {arrayStepsAfterPrefilled, flattenData, prefilledStore}
}


