import React, {FC} from "react";

import GAdditionalStepCard, {CARD_STATUS} from "../../organisms/AdditionalStepCard/AdditionalStepCard";
import "./AdditionalStep.scss"
import { BlockStepTypes } from "../../../../Constants/BlockStepTypes";

export type AdditionalStepProps = {
    index?: string,
    title: string,
    titleDone: string,
    information?: string,
    ctaText?: string,
    ctaForm?: any,
    stepVisualStatus?: StepVisualStatus | string
    stepShowStatus: StepShowStatus | string
    children?: React.ReactNode,
    loadingButton?: boolean,
    blockStepType?: string

}

/**
 * This enum is to identify the step is active or completed in additional steps
 *
 * DEFAULT: No active and no completed
 * PROCESS: Is Active
 * COMPLETED: Is Completed
 */
export enum StepShowStatus {
    DEFAULT = "", PROCESS = "process", COMPLETED = "completed"
}

/**
 * This enum is to manage the step card status
 *
 * DEFAULT: No active and no opened
 * STATUS_INIT: When step is enabled
 * STATUS_PROCESS: When step is opened
 */
export enum StepVisualStatus {
    DEFAULT = "", STATUS_INIT = "init", STATUS_PROCESS = "process"
}

/**
 * This function is to identify current step is not arrived to this step
 * @param stepShowStatus
 * @param stepVisualStatus
 */
function isNotArrivedToStep(stepShowStatus: StepShowStatus | string, stepVisualStatus: StepVisualStatus | string | undefined | StepVisualStatus.DEFAULT | StepVisualStatus.STATUS_INIT | StepVisualStatus.STATUS_PROCESS) {
    return stepShowStatus === StepShowStatus.DEFAULT &&
        (!stepVisualStatus || stepVisualStatus === StepVisualStatus.STATUS_INIT || stepVisualStatus === StepVisualStatus.DEFAULT);
}

/**
 * This function is to identify current step is active and has CTA
 * @param stepShowStatus
 * @param stepVisualStatus
 */
function isActiveWithCTA(stepShowStatus: StepShowStatus | string, stepVisualStatus: StepVisualStatus | string | undefined | StepVisualStatus.DEFAULT | StepVisualStatus.STATUS_INIT | StepVisualStatus.STATUS_PROCESS) {
    return stepShowStatus === StepShowStatus.PROCESS &&
        (!stepVisualStatus || stepVisualStatus === StepVisualStatus.STATUS_INIT || stepVisualStatus === StepVisualStatus.DEFAULT);
}

/**
 * This function is to identify current step is active and has children components inside opened
 * @param stepShowStatus
 * @param stepVisualStatus
 */
function isActiveWithChildrenComponents(stepShowStatus: StepShowStatus | string, stepVisualStatus: StepVisualStatus | string | undefined) {
    return stepShowStatus === StepShowStatus.PROCESS &&
        stepVisualStatus === StepVisualStatus.STATUS_PROCESS;
}

/**
 * This function is to identify current step is completed
 * @param stepShowStatus
 */
function isCompleted(stepShowStatus: StepShowStatus | string) {
    return stepShowStatus === StepShowStatus.COMPLETED;
}

const GAdditionalStep: FC<AdditionalStepProps> =
    ({
         index,
         title,
         titleDone,
         information,
         ctaText,
         ctaForm,
         stepVisualStatus,
         stepShowStatus,
         children,
         loadingButton = false,
         blockStepType
     }) => {
        const isCompletedWithContent = blockStepType === BlockStepTypes.COMPLETED && isActiveWithCTA(stepShowStatus, stepVisualStatus)
        return (
            <div className="AdditionalSteps">
                {isNotArrivedToStep(stepShowStatus, stepVisualStatus) && (
                    <GAdditionalStepCard
                        index={index}
                        title={title}
                        cardStatus={CARD_STATUS.DISABLED}
                    />
                )}

                {isActiveWithCTA(stepShowStatus, stepVisualStatus) && (
                    <GAdditionalStepCard
                        index={index}
                        title={title}
                        information={information}
                        ctaText={ctaText}
                        cardStatus={CARD_STATUS.PROCESS}
                        blockStepType={blockStepType}
                    >
                        {children}
                    </GAdditionalStepCard>
                )}

                {(isCompleted(stepShowStatus) || isCompletedWithContent) && (
                    <GAdditionalStepCard title={titleDone} cardStatus={CARD_STATUS.DONE}/>
                )}
            </div>
        )
    }
export default GAdditionalStep