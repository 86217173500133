import React from "react"
import { connect } from "react-redux"
import BlockFormWithFields from "../BlockFormWithFields/BlockFormWithFields"
import { checkObserverOn, getArrayDynamicBlock } from "../BlockFormWithFields/dynamicBlock"
import { BillingInformation } from "../BlockFormWithFields/BillingInformation/BlockBillingInformation"
import { checkShowOn } from "../BlockFormWithFields/checkShowOn"
import {FormInstance} from "antd";
import {PaymentRequestTokenEvent} from "@stripe/stripe-js";

type ListBlocksFormWithFieldsProps = {
  arrayBlocksFields: [] | any,
  submitObject: {} | any,
  dynamicBlocks: [{}] | any,
  observers: {} | any,
  setStripeElements: any,
  billingInformation: BillingInformation,
  showStripe: boolean,
  onGatewayPaymentStartedWithToken?: (event: PaymentRequestTokenEvent, userCoupon: any, subscriptionPlanMethod?: string | null) => Promise<void>
  form?: FormInstance
}

// eslint-disable-next-line max-len
const ListBlocksFormWithFields: React.FunctionComponent<ListBlocksFormWithFieldsProps> = ({
  arrayBlocksFields,
  submitObject,
  dynamicBlocks,
  observers,
  setStripeElements,
  billingInformation,
  showStripe,
  onGatewayPaymentStartedWithToken,
  form
}) => {
  const showBlock = (item: any, arrayFields: [], observerOn: any) => {
    const itemsSowedWithoutFields = ['Mandatory_fees', "subscription_plans", "billing_information", "subscription_information_sections"]
    const hasArrayFields = (arrayFields!== undefined && arrayFields.length > 0 && observerOn !== undefined);
    const hasSteps = (item?.blocksSteps!==undefined && item?.blocksSteps?.length > 0);
    const hasFields = hasArrayFields || hasSteps
    return (hasFields || itemsSowedWithoutFields.includes(item.name)) && observerOn
  }

  const arrayNamePrefilledBlock = ["permanent_address_block"]

  const getAlwaysPrefilledBlock = (arrayBlocksForRender: [], arrayNamePrefilledBlock: string[]) =>
    arrayBlocksForRender.filter(({ name }) => !arrayNamePrefilledBlock.includes(name))
  return (
    <ul className={`list-blocks-with-fields`}>
      {arrayBlocksFields.length !== 0 &&
        getAlwaysPrefilledBlock(arrayBlocksFields, arrayNamePrefilledBlock).map((item: {} | any, index: number) => {
          const arrayFields: [] = getArrayDynamicBlock(item, dynamicBlocks, submitObject)
          const observerOn: boolean = checkObserverOn(item, observers)
          const showBlockWithFields = showBlock(item, arrayFields, observerOn)
          const isPayment = item.name === "billing_address"
          const showPaymentForm = !isPayment  || (isPayment && showStripe || (showStripe === undefined && billingInformation?.payment_methods?.length === 0))
          // console.log("item", item)
          return (
            <React.Fragment key={index}>
              {showBlockWithFields && showPaymentForm && (
                // {parseDescription(item?.description)}
                <li
                  // eslint-disable-next-line max-len
                  className={`list-blocks-with-fields__container list-blocks-with-fields__container_${
                    item.name && item.name.replace(" ", "-")
                  } list-blocks-with-fields__container_${index + 1}-${item.type}`}
                  key={index}
                >
                  <BlockFormWithFields
                    onGatewayPaymentStartedWithToken={onGatewayPaymentStartedWithToken}
                    setStripeElements={setStripeElements}
                    blockObject={item}
                    indexBlock={index}
                    form={form}
                  />
                </li>
              )}
            </React.Fragment>
          )
        })}
    </ul>
  )
}

const mapStateToProps = (state: any) => ({
  billingInformation: state.step.billingInformation,
  submitObject: state.form.submitObject,
  observers: state.form.observers,
  dynamicBlocks: state.step.dynamicBlocks,
  showStripe: state.form.showStripe,
})

export default connect(mapStateToProps)(ListBlocksFormWithFields)
