import Event from "../Event"
import {EventOptions, EventProps, IAddress} from "../EventProperties"
import IEvent from "../IEvent";

const EVENT_NAME = "Signed Up";

/**
 * Event for when a user signs up
 * @class SignedUp
 * @description Event for user signed up
 */
export default class SignedUp extends Event implements IEvent {
    private readonly _createdAt: string | undefined;
    private readonly _email: string | undefined;
    private readonly _phone: string | undefined;
    private readonly _firstName: string | undefined;
    private readonly _lastName: string | undefined;
    private readonly _address: IAddress | undefined;
    private readonly _birthDay: string | undefined;
    private readonly _utmMedium: string | undefined;
    private readonly _utmSource: string | undefined;
    private readonly _utmCampaign: string | undefined;
    private readonly _utmContent: string | undefined;
    private readonly _utmTerms: string | undefined;
    private readonly _solution: string | undefined;
    private readonly _service: string | undefined;
    private readonly _category: string | undefined;
    private readonly _lastUtmCampaign: string | undefined;
    private readonly _lastUtmContent: string | undefined;
    private readonly _lastUtmMedium: string | undefined;
    private readonly _lastUtmSource: string | undefined;
    private readonly _lastUtmTerm: string | undefined;

    /**
     *
     * @param createdAt
     * @param email
     * @param phone
     * @param firstName
     * @param lastName
     * @param address
     * @param birthDay
     * @param utmMedium
     * @param utmSource
     * @param utmCampaign
     * @param utmContent
     * @param utmTerms
     * @param solution
     * @param service
     * @param category
     * @param ipAddress
     * @param lastUtmMedium
     * @param lastUtmSource
     * @param lastUtmCampaign
     * @param lastUtmContent
     * @param lastUtmTerm
     */
    constructor(
        createdAt: string | undefined,
        email: string | undefined,
        phone: string | undefined,
        firstName: string | undefined,
        lastName: string | undefined,
        address: IAddress | undefined,
        birthDay: string | undefined,
        utmMedium: string | undefined,
        utmSource: string | undefined,
        utmCampaign: string | undefined,
        utmContent: string | undefined,
        utmTerms: string | undefined,
        solution: string | undefined,
        service: string | undefined,
        category: string | undefined,
        ipAddress: string | undefined,
        lastUtmMedium: string | undefined,
        lastUtmSource: string | undefined,
        lastUtmCampaign: string | undefined,
        lastUtmContent: string | undefined,
        lastUtmTerm: string | undefined,
    ) {
        super(EVENT_NAME, ipAddress);
        this._createdAt = createdAt;
        this._email = email;
        this._phone = phone;
        this._firstName = firstName;
        this._lastName = lastName;
        this._address = address;
        this._birthDay = birthDay;
        this._utmMedium = utmMedium;
        this._utmSource = utmSource;
        this._utmCampaign = utmCampaign;
        this._utmContent = utmContent;
        this._utmTerms = utmTerms;
        this._solution = solution;
        this._service = service;
        this._category = category;
        this._lastUtmMedium = lastUtmMedium;
        this._lastUtmSource = lastUtmSource;
        this._lastUtmCampaign = lastUtmCampaign;
        this._lastUtmContent = lastUtmContent;
        this._lastUtmTerm = lastUtmTerm;
    }

    /**
     * Get event data
     * @returns {EventProps}
     * @description Get event data
     */
    getEventData(): EventProps {
        return {
            ...this.baseEventData(),
            createdAt: this._createdAt,
            email: this._email,
            phone: this._phone,
            firstName: this._firstName,
            lastName: this._lastName,
            address: this._address,
            birthday: this._birthDay,
            utm_medium: this._utmMedium,
            utm_source: this._utmSource,
            utm_campaign: this._utmCampaign,
            utm_content: this._utmContent,
            utm_term: this._utmTerms,
            utm_medium_last: this._lastUtmMedium,
            utm_source_last: this._lastUtmSource,
            utm_campaign_last: this._lastUtmCampaign,
            utm_content_last: this._lastUtmContent,
            utm_term_last: this._lastUtmTerm,
            solution: this._solution,
            service: this._service,
            subdivision: this._category,
        }
    }

    /**
     * Get event name
     * @returns {string}
     * @description Get event name
     */
    getEventName(): string {
        return this.eventName;
    }

    /**
     * Get event page name
     * @returns {string}
     * @description Get event page name
     */
    pageName(): string {
        return "";
    }

    /**
     * Get previous id
     * @returns {string}
     * @description Get previous id
     */
    getPreviousId(): string {
        return "";
    }

    /**
     * Get user id
     * @returns {string}
     * @description Get user id
     */
    getUserId(): string {
        return "";
    }

    getEventOptions(): EventOptions | undefined {
        return undefined;
    }
}
