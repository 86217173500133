import { useEffect } from "react"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { arrayEmptyOptions, getOptionsName, getValidationWithEmptyOption, trimOption } from "./createOptions"
import { savePrefilled } from "../../../../Redux/store/user/user.actions"
export type Dispatch<A> = (value: A) => void

const getDispatch = (submitValue: string, optionName: string, name: string, dispatch: Dispatch<any>) => {
  if (submitValue && optionName && name) {
    dispatch(setValueCurrentField(`${name}__full_name`, optionName))
    dispatch(setValueCurrentField(`${name}`, submitValue))
  }
}

const useOldEffects = (
  field: FieldInterface,
  submitObject: {} | any,
  dispatch: Dispatch<any>,
  defaultsDocumentOption: {} | undefined,
  statusTagFields: string
) => {
  const wrapperDefaultValue = getOptionsName(field.defaultValue)

  useEffect(() => {
    if (getValidationWithEmptyOption(field, arrayEmptyOptions) && !defaultsDocumentOption) {
      //if it is first launch app
      dispatch(setValueCurrentField(`${field.name}__full_name`, ""))
      dispatch(setValueCurrentField(`${field.name}`, ""))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, field])

  useEffect(() => {
    if (
      !submitObject[`${field.name}__full_name`] &&
      submitObject[`${field.name}`] &&
      getValidationWithEmptyOption(field, arrayEmptyOptions) &&
      !defaultsDocumentOption
    ) {
      //if was refresh with chosen option
      dispatch(setValueCurrentField(`${field.name}`, ""))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, field])

  useEffect(() => {
    if (
      !submitObject[`${field.name}__full_name`] &&
      !submitObject[`${field.name}`] &&
      !field.emptyOption &&
      field.defaultAdditionalValue &&
      !defaultsDocumentOption &&
      !statusTagFields
    ) {
      dispatch(setValueCurrentField(`${field.name}__full_name`, field.defaultValue))
      dispatch(setValueCurrentField(`${field.name}`, field.defaultAdditionalValue))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      !submitObject[`${field.name}__full_name`] &&
      !submitObject[`${field.name}`] &&
      !field.emptyOption &&
      !field.defaultAdditionalValue &&
      !defaultsDocumentOption
    ) {
      if (field.tag && wrapperDefaultValue.submitValue) {
        dispatch(savePrefilled({ [field.tag]: wrapperDefaultValue.submitValue }))
      }
      dispatch(setValueCurrentField(`${field.name}`, wrapperDefaultValue.submitValue))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      !submitObject[`${field.name}__full_name`] &&
      !submitObject[`${field.name}`] &&
      !field.emptyOption &&
      field.submitAdditionalOption &&
      !defaultsDocumentOption
    ) {
      //if need to make something with option
      const defaultValue = trimOption(field.defaultValue, field.submitAdditionalOption, dispatch, field.name)
      dispatch(setValueCurrentField(field.name, defaultValue))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, field])
}

export const useDefaultOption = (
  select: FieldInterface,
  submitObject: {} | any,
  dispatch: Dispatch<any>,
  statusTagFields: string
) => {
  const { defaultsDocumentOption: { submitValue = "", optionName = "" } = {}, name, defaultsDocumentOption } = select
  useEffect(() => {
    if (defaultsDocumentOption && !submitObject[name]) {
      getDispatch(submitValue, optionName, name, dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, submitValue, optionName, name, defaultsDocumentOption])
  useOldEffects(select, submitObject, dispatch, defaultsDocumentOption, statusTagFields)
}
