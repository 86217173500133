import * as React from "react"
import FormPage from "./Pages/FormPage/FormPage"
import {Provider} from "react-redux"
import {createStore, applyMiddleware} from "redux"
import {composeWithDevTools} from "redux-devtools-extension"
import rootReducer from "./Redux/index"
import createSagaMiddleware from "redux-saga"
import stepSaga from "./Redux/store/step/step.saga"
import formSaga from "./Redux/store/form/form.saga"
import ModalWindows from "./Components/ModalWindows/ModalWindows"
import additionalSaga from "./Redux/store/additionalForm/additional.saga"
import cashSaga from "./Redux/store/cash/cash.saga"
import emailSaga from "./Redux/store/email/email.saga"
import {BrowserRouter, Switch, Route} from "react-router-dom"
import initialUrlPath from "./services/initial-data/initialUrlPath"
import uploadSaga from "./Redux/store/upload/upload.saga"
import userSaga from "./Redux/store/user/user.saga"
import billingSaga from "./Redux/store/billing/billing.saga";

export type RootState = ReturnType<typeof storeForm.getState>

const sagaMiddleware = createSagaMiddleware()

const storeForm = createStore(
  rootReducer,
  composeWithDevTools({trace: true, traceLimit: 25})(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(stepSaga)
sagaMiddleware.run(formSaga)
sagaMiddleware.run(additionalSaga)
sagaMiddleware.run(cashSaga)
sagaMiddleware.run(emailSaga)
sagaMiddleware.run(uploadSaga)
sagaMiddleware.run(userSaga)
sagaMiddleware.run(billingSaga)

const App: React.FC = () => (
  <Provider store={storeForm}>
    <BrowserRouter>
      <div className={"wrapper-react"}>
        <ModalWindows/>
        <main className={"main"}>
          <Switch>
            <Route component={FormPage} path={initialUrlPath()} exact/>
          </Switch>
        </main>
      </div>
    </BrowserRouter>
  </Provider>
)

export default App
