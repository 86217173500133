import React, {FC} from "react";
import "./GCanceledSubscription.scss";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import IntercomHelper from "../../../../util/IntercomHelper";
import {SubscriptionText} from "../../../../shared/text/Text";
// @ts-ignore
import {useSubscription} from "../../../../../../dashboard-govplus-front/src/hooks/useSubscription";
import moment from "moment";


const GCanceledSubscription: FC = () => {
    const {subscription} = useSubscription()
    const contactUsClick = (e:any) => {
        if (e.target.id === "GCanceledSubscriptionContactUs") {
            IntercomHelper.showBubbleChat();
        }
    }

    const finalDescription = SubscriptionText.SubscriptionCancelledExplanation
        .replaceAll('contact us', `<a href="https://govplus.com/contact-us" target="_blank" id="GCanceledSubscriptionContactUs">contact us</a>`)
        .replaceAll("{date}", moment(subscription?.expirationDate).format("LL")  || 'your subscription expires')

    return (
        <div className={"GCanceledSubscription"}>
            <div className={"GCanceledSubscription__Title"}>
                <GText text={SubscriptionText.SubscriptionCancelled} size={SIZE.HEADING_XL} innerHtml={SubscriptionText.SubscriptionCancelled}/>
            </div>
            <div className={"GCanceledSubscription__Content"}>
                <GText onClick={contactUsClick} text={finalDescription} size={SIZE.PARAGRAPH_REGULAR_18} color={COLORS_NEW.BLACk_800}
                        innerHtml={finalDescription}/>
            </div>
        </div>
    )

}

export default GCanceledSubscription