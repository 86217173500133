import React from "react"
import {COLORS} from "../../../types/Colors";

interface ISvgCheck {
    width?: number;
    height?: number;
    color?: string;
}

export const SvgCheckTax = ({width = 20, height = 20, color = COLORS.SLATE_GRAY_500}: ISvgCheck) => {
    const viewBox = `0 0 ${width + 5} ${height + 4}`;
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5857 1.99609L12.5057 1.99609C14.6621 1.99732 16.7604 2.69559 18.4875 3.98676C20.2147 5.27793 21.4782 7.09281 22.0896 9.16074C22.701 11.2287 22.6276 13.4388 21.8803 15.4616C21.133 17.4844 19.7518 19.2114 17.9428 20.3851C16.1338 21.5588 13.9938 22.1163 11.842 21.9744C9.69029 21.8325 7.64205 20.9989 6.0028 19.5978C4.36354 18.1967 3.22111 16.3033 2.74587 14.1999C2.27063 12.0965 2.48806 9.89583 3.36572 7.92609"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="8" cy="3" r="1" fill={color}/>
            <circle cx="5.25" cy="5" r="1" fill={color}/>
            <path d="M8.91406 12.3283L10.7876 14.159C10.9807 14.3477 11.29 14.3451 11.48 14.1532L16.0869 9.5"
                  stroke={color} strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}
