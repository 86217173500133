import React, {FC} from "react";
import {Modal} from "antd";
import "./GModal.scss"
import {GModalProps} from "./GModalProps";
import {SvgCloseModal} from "../../atoms/IconsSvg/svg/SvgCloseModal";

/**
 * This is a wrapper of the Ant Modal component here you can see the description of its properties
 * https://ant.design/components/modal
 */
const GModal: FC<GModalProps> =
    ({
         width,
         centered = true,
         closable = true,
         destroyOnClose = false,
         closeIcon = <SvgCloseModal/>,
         maskClosable = false,
         open,
         onOk,
         onCancel,
         children,
         extraClassName,
         forceRender = false
     }) => {

        return (
            <Modal
                width={width}
                footer={null}
                wrapClassName={'GModal ' + extraClassName}
                centered={centered}
                maskClosable={maskClosable}
                closable={closable}
                closeIcon={closeIcon}
                destroyOnClose={destroyOnClose}
                open={open}
                onOk={onOk}
                onCancel={onCancel}
                children={children}
                forceRender={forceRender}

            />
        )
    }

export default GModal