/* eslint-disable indent */
import { ActionTypes } from "./email.action"
import { Action } from "../../interfacesAction/action.interface"
export interface State {
  answer: {} | any;
  emailUserStatus: {} | null;
  arrayCountryCodes: [];
  statusAuthModal: string;
}

const initialState: State = {
  answer: {},
  emailUserStatus: null,
  arrayCountryCodes: [],
  statusAuthModal: "redirect",
}

export const emailReducer = (state: State = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case ActionTypes.SET_ANSWER_EMAIL:
      return {
        ...state,
        answer: payload,
      }
    case ActionTypes.SET_CHECK_CUSTOMER_BY_EMAIL:
      return {
        ...state,
        emailUserStatus: payload,
      }
    case ActionTypes.SET_STATUS_AUTH_MODAL:
      return {
        ...state,
        statusAuthModal: payload,
      }
    case ActionTypes.SET_ARRAY_COUNTRY_CODES:
      return {
        ...state,
        arrayCountryCodes: payload,
      }
    default:
      return state
  }
}
