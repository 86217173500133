import Event from "../Event"
import {EventOptions, EventProps} from "../EventProperties"
import IEvent from "../IEvent";

const EVENT_NAME = "Password Reset Requested";

/**
 * Event for when a password reset is requested
 * @class PasswordResetRequested
 * @extends {Event}
 * @implements {IEvent}
 * @description Event for when a password reset is requested
 */
export default class PasswordResetRequested extends Event implements IEvent {
    private readonly _email: string | undefined;

    /**
     * Creates an instance of PasswordResetRequested.
     * @param email
     * @param ipAddress
     */
    constructor(
        email: string | undefined,
        ipAddress: string | undefined
    ) {
        super(EVENT_NAME, ipAddress);
        this._email = email;
    }

    /**
     * Get event data
     * @returns {EventProps}
     * @description This method is used to get event data
     */
    getEventData(): EventProps {
        return {
            ...super.baseEventData(),
            email: this._email,
        }
    }

    /**
     * Get event name
     * @returns {string}
     * @description This method is used to get event name
     */
    getEventName(): string {
        return this.eventName;
    }

    /**
     * Get page name
     * @returns {string}
     * @description This method is used to get page name
     */
    pageName(): string {
        return "";
    }

    /**
     * Get previous id
     * @returns {string}
     * @description This method is used to get previous id
     */
    getPreviousId(): string {
        return "";
    }

    /**
     * Get user id
     * @returns {string}
     * @description This method is used to get user id
     */
    getUserId(): string {
        return "";
    }

    getEventOptions(): EventOptions | undefined {
        return undefined;
    }
}
