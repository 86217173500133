/* eslint-disable indent */
import { ActionTypes } from "./billing.action"
import { Action } from "../../interfacesAction/action.interface"

export interface State {
  userCoupon: any;
  itemPriceCharges: any;
}

const initialState: State = {
  userCoupon: null,
  itemPriceCharges: [],
}

export const billingReducer = (state: State = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case ActionTypes.SET_USER_COUPON:
      return {
        ...state,
        userCoupon: payload,
      }
    case ActionTypes.SET_ITEM_PRICE_CHARGES:
      return {
        ...state,
        itemPriceCharges: payload,
      }
    default:
      return state
  }
}
