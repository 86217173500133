// it function made check route for refresh browser

import FormIdRepository from "../../persistence/session/FormIdRepository";

const comparingUrlForRefresh = (url: string, urlPath: string, localStorageFormId: string | null) => {
  //for simple refresh, route can change after click button next
  if (localStorageFormId && url.indexOf(`/${urlPath}/${localStorageFormId}/`) !== -1) {
    return `/${urlPath}/${localStorageFormId}/`
  }
  // for copy big link with get for example:
  // http://localhost:3000/forms/ssn-new/?formId=ssn-new&indexCurrentStep=1
  else if (!localStorageFormId || (localStorageFormId && url.indexOf(`/${urlPath}/${localStorageFormId}/`) === -1)) {
    return `/${url.split("/").slice(3, -1).join("/")}/`
  }
}

//case when we have utm get params or "/" after formId in url
const getFormIdWithUtm = (url: string) => {
  if (
    url.split("/")[window.location.href.split("/").length - 1].indexOf("?") !== -1 ||
    url.split("/")[window.location.href.split("/").length - 1] === ""
  ) {
    return url.split("/")[window.location.href.split("/").length - 2]
  }
  return url.split("/")[window.location.href.split("/").length - 1]
}

// it function made path in route
// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const url = window.location.href
  let formId = new URLSearchParams(window.location.search).get("formId") || ""
  const checkStatusFormId = new URLSearchParams(window.location.search).get("form_id")
  const localStorageFormId = FormIdRepository.getInstance().getValue()
  let urlPath = `/forms/`

  // for short link for example:
  // http://localhost:3000/forms/ssn-new
  if (!formId && !checkStatusFormId) {
    formId = getFormIdWithUtm(url)
    FormIdRepository.getInstance().setValue(formId);
    return `${urlPath}${formId}`
  }
  // for first type link or link for check-status for example:
  // http://localhost:3000/forms/?formId=ssn-new&indexCurrentStep=0
  // http://localhost:3000/forms/?form_id=check_status&email=fdfsd@ffg.gg&app_id=1349
  else if (formId || checkStatusFormId) {
    if (checkStatusFormId) {
      formId = checkStatusFormId || ""
    }
    FormIdRepository.getInstance().setValue(formId);
    // wrapper for case if user refresh browser with new route
    const checkedUrlForRefresh = comparingUrlForRefresh(url, urlPath, localStorageFormId)
    return `${checkedUrlForRefresh}`
  }
}
