import {getSolutionNameByProductId, SolutionNames} from "./SolutionNames";

import expediteSsnImage from "../assets/image/upsells/expedite_ssn.webp";
import expeditePasImage from "../assets/image/upsells/expedite_pas.webp";
import expediteCerImage from "../assets/image/upsells/expedite_cer.webp";
import expeditedProcessingEinImage from "../assets/image/upsells/expedited_processing_ein.webp";
import expeditedProcessingCoaImage from "../assets/image/upsells/expedited_processing_coa.webp";
import expeditedProcessingTsaImage from "../assets/image/upsells/expedited_processing_tsa.webp";
import auditProtectionImage from "../assets/image/upsells/audit-protection.webp"
import expressProcessingPasImage from "../assets/image/upsells/express_processing_pas.webp";

import prepaidTsaImage from "../assets/image/upsells/tsa-prepaid.png";
import prepaidPasImage from "../assets/image/upsells/pas-prepaid.png";
import subscriptionImage from "../assets/image/upgrade/successfull_subscription.webp";
import {ProductsIds} from "./ProductsIds";
import {IUpsellProcessingData} from "../Components/atomicDesign/templates/AdditionalStep/StepUpsell/StepUpsell";
import {OfferType} from "../Components/atomicDesign/types/types";
import {
    OffersPageNames,
    TemplateOneFactory
} from "../Components/atomicDesign/templates/AdditionalStep/StepUpsell/templates/templateOne/Factory/TemplateOneFactory";


export enum UpsellCategories {
    UPGRADE = "upgrade",
    UPSELL = "upsell",
    CROSS_SALE = "cross-sell",
    Subscription = "subscription"
}

export function getExpeditedShippingImage(solutionName: string): string | undefined {
    switch (solutionName) {
        case SolutionNames.PAS:
            return expeditePasImage;
        case SolutionNames.SSN:
            return expediteSsnImage;
        case SolutionNames.CER:
            return expediteCerImage;
        default:
            return undefined;
    }
}

export function getAuditProtectionImage() {
    return auditProtectionImage
}

export function getPrepaidImage(productCode?: string): string | undefined {
    switch (productCode) {
        case ProductsIds.TsaId:
            return prepaidTsaImage;
        case ProductsIds.PassportId:
            return prepaidPasImage;
        default:
            return undefined;
    }
}

export function getPurchasedConfirmationOfferImage(offerData: IUpsellProcessingData, currentSolution: string): string | undefined {
    if(offerData.page_type === OfferType.CrossSell){
        return getPrepaidImage(offerData.cross_sell_product_offer);
    }
    if (offerData.page_type === OfferType.Upgrade) {
        return subscriptionImage
    }

    if (offerData.page_name === OffersPageNames.EXPEDITED_SHIPPING) {
        return getExpeditedShippingImage(currentSolution);
    }

    if (offerData.page_name === OffersPageNames.EXPEDITED_PROCESSING) {
        return getExpeditedProcessingImage(currentSolution);
    }

    if (offerData.page_name === OffersPageNames.AUDIT_PROTECTION) {
        return getAuditProtectionImage();
    }

    if (offerData.page_name === OffersPageNames.EXPRESS_PROCESSING_UPSELL) {
        return getExpressProcessingImage(currentSolution);
    }

    return undefined;
}

export function computeOfferName(isCrossSell: boolean, serviceName?: string, crossSellProductOffer?: string) {
    if (isCrossSell) {
        return getSolutionNameByProductId(crossSellProductOffer) + " application"
    }
    if (serviceName?.includes("Subscription") || serviceName?.includes("Premium")) {
        return "GOV+ Unlimited"
    }
    return serviceName;
}

export function getExpeditedProcessingImage(solutionName: string): string | undefined {
    switch (solutionName) {
        case SolutionNames.EIN:
            return expeditedProcessingEinImage;
        case SolutionNames.COA:
            return expeditedProcessingCoaImage;
         case SolutionNames.TSA:
            return expeditedProcessingTsaImage;
        default:
            return undefined;
    }
}

export function getExpressProcessingImage(solutionName: string): string | undefined {
    switch (solutionName) {
        case SolutionNames.PAS:
            return expressProcessingPasImage;
        default:
            return undefined;
    }
}

export const SolutionsToNormalCase = [SolutionNames.TSA, SolutionNames.SSN];