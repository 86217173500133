import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import GButton, {BUTTON_TYPE} from "../../../atomicDesign/molecules/Button/GButton";
import "./QuestionaryNAM.scss"
import GText from "../../../atomicDesign/atoms/Text/Text";
import {SIZE} from "../../../atomicDesign/types/types";
import {COLORS, COLORS_NEW} from "../../../atomicDesign/types/Colors";
import {
  ClickedButton,
  QuestionnaireButtonSize,
  QuestionnaireButtonText
} from "../QuestionaryTSA/QuestionnaireConstant";
import {LoadingState} from "../../../../shared/constans/user-from-view-mode.enum";
import {
  setStatusQuestionary,
  setStatusQuestionnaireFront,
  submitAdditionalForm
} from "../../../../Redux/store/additionalForm/additional.actions";
import UserIpRepository from "../../../../persistence/session/UserIpRepository";
import {setValueCurrentField} from "../../../../Redux/store/form/form.actions";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { env } from "../../../../env";

const rootLink = env.REACT_APP_QUESTIONARY_IMG ?? "https://api-files.govplus.dev/static/img/ssn_documents/"

type Question = {
  name: string,
  header: string,
  urlImg: string,
  variable: string,
  filterQuestion: {
    variable: string,
    values: [any]
  }
}

type Answer = {
  [p: string]: number
}

type QuestionaryNAMProps = {
  blocksStep: any,
  submitObject: any,
  appId: string,
  formId: string,
  dispatch: any,
  loadingButtonState: string,
}

const QuestionsHeaderVariables = {
  OLD_FIRST_NAME: '{previousFirstName}',
  OLD_LAST_NAME: '{previousLastName}'
}

// eslint-disable-next-line max-len
const QuestionaryNAM: React.FunctionComponent<QuestionaryNAMProps> = ({
                                                                        blocksStep,
                                                                        submitObject,
                                                                        appId,
                                                                        formId,
                                                                        dispatch,
                                                                        loadingButtonState,
                                                                      }) => {
  const [slide, setSlide] = useState(0)
  const [sliderCompleted, setSliderCompleted] = useState(false)
  const [loadingYesBtn, setLoadingYesBtn] = useState(false)
  const [loadingNoBtn, setLoadingNoBtn] = useState(false)
  const [disableBtn, setDisabledBtn] = useState(false)
  const [answer, setAnswer] = useState<Answer | null>(null);
  const breakPoint = useBreakpoint()

  const {
    content: {
      questionnaire
    }
  } = blocksStep || {}

  const getFilteredQuestionnaire = () => {
    return questionnaire?.length ? questionnaire.filter((question: Question) => {
      if(question.hasOwnProperty('filterQuestion')) {
        const {variable, values} = question.filterQuestion

        return submitObject.hasOwnProperty(variable) && values.includes(submitObject[variable])
      }
      return true
    }) : []
  }

  useEffect(() => {
    const answersCompleted = (answer && Object.keys(answer).length) === getFilteredQuestionnaire().length

    if (sliderCompleted && answersCompleted) {
      dispatch(setStatusQuestionnaireFront(""))
      dispatch(
        submitAdditionalForm({
          fields: {...submitObject, ...answer},
          ...answer,
          dataPricing: submitObject["data-pricing"],
          client_token: appId,
          form_id: formId,
          userIp: UserIpRepository.getInstance().getValue()
        })
      )
      dispatch(setStatusQuestionary("completed"))
    }
  }, [answer, sliderCompleted]);


  const handlerClickButton = (answer: string, variable: string) => {
    const isYes: boolean = answer === ClickedButton.YES

    startLoading(isYes)

    setAnswer((prev) => ({...prev, [variable]: Number(isYes)}))

    dispatch(setValueCurrentField(variable, Number(isYes)))

    if (getFilteredQuestionnaire().length - 1 !== slide) {
      setSlide(slide + 1)
    } else {
      setSliderCompleted(true)
    }

    resetBtnStatuses()
  }

  const getQuestionHeader = (header: string) => {
    const oldFirstName = submitObject?.old_fname || submitObject?.fname
    return header
      .replaceAll(QuestionsHeaderVariables.OLD_FIRST_NAME, oldFirstName)
      .replaceAll(QuestionsHeaderVariables.OLD_LAST_NAME, submitObject?.old_lname)
  }

  const startLoading = (isYes: boolean) => {
    setLoadingYesBtn(isYes)
    setLoadingNoBtn(!isYes)
    setDisabledBtn(true)
  }

  const resetBtnStatuses = () => {
    setLoadingYesBtn(false);
    setLoadingNoBtn(false);
    setDisabledBtn(false);
  }

  return  getFilteredQuestionnaire().map((question: Question, index: number) => (
    <section key={index} className={slide != index ? 'slide-hidden' : 'slide-shown'}>
      <div className={`step-questionnaire__main-container`}>
        <div className={'step-questionnaire__main-header'}>
          <GText text={getQuestionHeader(question.header)} size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_20 : SIZE.PARAGRAPH_BOLD_16}
                 color={COLORS_NEW.BLACK_1000}/>
        </div>
        <img
          src={`${rootLink}${question.urlImg}`}
          alt={question.name}
          className={`step-questionnaire__main-img`}
        />
      </div>
      <div className={'options-container'}>
        <GButton
          textSize={SIZE.PARAGRAPH_BOLD_16}
          text={QuestionnaireButtonText.NO}
          size={QuestionnaireButtonSize.SMALL}
          isSubmit={false}
          showIcon={false}
          type={BUTTON_TYPE.SECONDARY}
          click={() => handlerClickButton(ClickedButton.NO, question.variable)}
          disabled={disableBtn || loadingButtonState === LoadingState.Loading}
          loading={loadingNoBtn || loadingButtonState === LoadingState.Loading}
        />
        <GButton
          textSize={SIZE.PARAGRAPH_BOLD_16}
          text={QuestionnaireButtonText.YES}
          size={QuestionnaireButtonSize.SMALL}
          showIcon={false}
          isSubmit={false}
          type={BUTTON_TYPE.SECONDARY}
          click={() => handlerClickButton(ClickedButton.YES, question.variable)}
          disabled={disableBtn || loadingButtonState === LoadingState.Loading}
          loading={loadingYesBtn || loadingButtonState === LoadingState.Loading}
        />
      </div>
      <p className={`step-questionnaire__hint`}>{blocksStep.content.hint}</p>
    </section>))
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
  appId: state.form.appId,
  formId: state.form.formId,
  loadingButtonState: state.loading.loadingButtonState,
})

export default connect(mapStateToProps)(QuestionaryNAM)
