import { FC } from "react"
import { renderBasicBlockContainer } from "../UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { BasicBlockContainerProps } from "../UploadPhotoService/interfaces/interfaces"
import LayoutH3ContentContainer from "../Layouts/LayoutH3ContentContainer/LayoutH3ContentContainer"

const HeaderH3ContentComponent: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text },
  arrayComponents,
}) => renderBasicBlockContainer({ className, text, childrenItem, arrayComponents }, LayoutH3ContentContainer)

export default HeaderH3ContentComponent
