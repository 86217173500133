import React from "react"

interface ISvgLockClose {
  width: number;
  height: number;
  color: string;
}

export const SvgLockClose = ({ width, height, color }: ISvgLockClose) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8333 9.75H4.16667C3.24619 9.75 2.5 10.4962 2.5 11.4167V17.25C2.5 18.1705 3.24619 18.9167 4.16667 18.9167H15.8333C16.7538 18.9167 17.5 18.1705 17.5 17.25V11.4167C17.5 10.4962 16.7538 9.75 15.8333 9.75Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83203 9.75V6.41667C5.83203 5.3116 6.27102 4.25179 7.05242 3.47039C7.83382 2.68899 8.89363 2.25 9.9987 2.25C11.1038 2.25 12.1636 2.68899 12.945 3.47039C13.7264 4.25179 14.1654 5.3116 14.1654 6.41667V9.75"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
