import React, {FC} from "react";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import "./ApplicationCardTemplate.scss";
import IconsSvg from "../../atoms/IconsSvg/IconsSvg";
import GTooltip from "../../atoms/Tooltip/Tooltip";
import GTag from "../../molecules/Tag/GTag";
import {PREPAID_TEXT, PREPAID_TOOLTIP, PrepaidProduct} from "../../../../util/ProductHelper";
import {SolutionNames} from "../../../../util/SolutionNames";

type ApplicationCardProps = {
    image: string,
    productName: string,
    children: React.ReactNode,
    iconType?: string,
    iconColor?: string,
    iconBackground?: string,
    disabled?: boolean,
    disableMessage?: string,
    prepaid?: PrepaidProduct | false
}

const GApplicationCard = ({
    image,
    productName,
    iconType,
    children,
    iconColor,
    iconBackground,
    disabled= false,
    disableMessage="",
    prepaid = false
}: ApplicationCardProps) => {

    const bgDisabled:React.CSSProperties = disabled ? {
        backgroundColor: COLORS_NEW.BLACK_100
    } : {};

    const fontColor = disabled ? COLORS_NEW.BLACK_400 : COLORS_NEW.BLACK_1000

    const getTitleAndProgressBar = () => {
        let prepaidTooltip = "";
        if(prepaid) {
            const key = prepaid?.productId;
            prepaidTooltip = prepaid ? PREPAID_TOOLTIP
                .replace("{solution}", SolutionNames[key as keyof typeof SolutionNames])
                .replace("{date}", prepaid.datePaid) : "";
        }

        return (
            <>
                <GText text={productName} size={SIZE.PARAGRAPH_BOLD_20} color={fontColor} />

                {prepaid && (
                    <GTooltip text={prepaidTooltip}>
                        <div>
                            <GTag
                                text={PREPAID_TEXT}
                                sizeText={SIZE.PARAGRAPH_BOLD_14}
                                colorBackground={COLORS_NEW.YELLOW_200}
                                colorText={COLORS_NEW.YELLOW_700}
                                tooltip={true}
                            />
                        </div>
                    </GTooltip>
                )}
            </>
        )
    }

    return (
        <div className={"GApplicationCard"}>
            <section className={"GApplicationCard__ImageHeader"} style={bgDisabled}>
                {iconType && <IconsSvg type={iconType} width={38} height={38} color={iconColor} backgroundColor={iconBackground}/>}
                <img src={image} />
            </section>
            <div className={"GApplicationCard__Content"}>
                <section className={"GApplicationCard__Content__Title"}>
                    {
                        getTitleAndProgressBar()
                    }
                    {
                        disabled && (
                            <GTooltip text={disableMessage}>
                                <div className={`NewApplicationCard__BodyCard__Select__TooltipContainer__DisableIconBtn`}>
                                    <GTag tooltip={true} text={"Unavailable"} colorBackground={COLORS_NEW.BLACK_100} colorText={fontColor} />
                                </div>
                            </GTooltip>
                        )
                    }
                </section>
                <section className={"GApplicationCard__Content__Options"}>
                    {children}
                </section>
            </div>
        </div>
    )
}

export default GApplicationCard