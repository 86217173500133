import React from 'react';
import {SvgType} from "../SvgType";

export const SvgIconConfirmButton = ({width = 21, height = 20}: SvgType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.1663 5L7.99967 14.1667L3.83301 10" stroke="white" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>

  );
}

