import { ActionTypes } from "./user.actions"
import { Action } from "../../interfacesAction/action.interface"

export interface State {
  percents: { oneProduct: string | number } | null | any;
  webFlowData: { [key: string]: any } | null;
  // cmsImageData: { [key: string]: any } | null;
}

const initialState: State = {
  percents: null,
  webFlowData: null,
  // cmsImageData: null
}

export const userReducer = (state: State = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.SET_PERCENTS:
      return {
        ...state,
        percents: action.payload,
      }
    case ActionTypes.SET_WEB_FLOW_DATA:
      return {
        ...state,
        webFlowData: action.payload,
      }
    // case ActionTypes.SET_CMS_SOLUTIONS_DATA:
    //   return {
    //     ...state,
    //     cmsImageData: action.payload,
    //   }
    default:
      return state
  }
}
