import { Action } from "../../interfacesAction/action.interface"

export const ActionTypes = {
  SET_USER_COUPON: "[BILLING] set user coupon",
  SUBMIT_GET_USER_COUPON: "[BILLING] submit get user coupon",
  SET_ITEM_PRICE_CHARGES: "[BILLING] set item price charges"
}

export const setUserCouponState = (userCoupon: any): Action<any> => ({
  type: ActionTypes.SET_USER_COUPON,
  payload: userCoupon,
})

export const submitGetUserCoupon = (): Action<any> => ({
  type: ActionTypes.SUBMIT_GET_USER_COUPON,
})

export const setItemPriceCharges = (itemPriceCharges: any): Action<any> => ({
    type: ActionTypes.SET_ITEM_PRICE_CHARGES,
    payload: itemPriceCharges,
})
