import React from "react";
import GText from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Text/Text";
import {SIZE, INPUT_STATUS} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import {COLORS} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";
import GBadge from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Badge/Badge"
import {ICONS_SVG_TYPE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/IconsSvg/IconsSvgTypes"
import moment from "moment";
import "./index.scss"

export const SHIPPING_STATUSES = {
  PENDING: 'Pending',
  COMPLETED: 'Completed'
}

const SHIPPING_BADGE_PROPERTIES = {
  [SHIPPING_STATUSES.PENDING]: {
    text: 'Pending',
    iconType: ICONS_SVG_TYPE.CLOCK,
    type: INPUT_STATUS.WARNING,
    textColor: COLORS.YELLOW_500,
    backgroundColor: COLORS.YELLOW_50,
    iconColor: COLORS.YELLOW_500
  },
  [SHIPPING_STATUSES.COMPLETED]: {
    text: 'Completed',
    iconType: ICONS_SVG_TYPE.CHECK,
    type: INPUT_STATUS.SUCCESS,
    textColor: COLORS.GREEN_600,
    backgroundColor: COLORS.GREEN_50,
    iconColor: COLORS.GREEN_600
  }
}

const ShippingInfoCard =
  ({
     headerText,
     image,
     submissionDate,
     status,
     children
   }) => {
    return (
      <div className={`GShippingInfoCard`}>
        <div className={`GShippingInfoCard__Body`}>
          <div>
            <div className={`GShippingInfoCard__Body__ProductName`}>
              <GText
                text={headerText}
                size={SIZE.PARAGRAPH_BOLD_18}
                color={COLORS.DARK_BLUE_600}
              />
            </div>
          </div>
        </div>
        { children ||
          <React.Fragment>
            <div className={`GShippingInfoCard__Header`}>
              <div className={`GShippingInfoCard__Header__Image`}>
                <img src={image} alt={'solution'}/>
              </div>
            </div>
            <div className={`GShippingInfoCard__Body`}>
              <div className={`GShippingInfoCard__Body__SubmissionDate`}>
                <GText
                  text={"Submission date: " + (submissionDate && `${moment(submissionDate).format("D MMM YYYY")}`)}
                  size={SIZE.PARAGRAPH_REGULAR_16}
                  color={COLORS.SLATE_GRAY_400}
                />
              </div>
              <GBadge
                showIcon
                {...SHIPPING_BADGE_PROPERTIES[status]}
              />
            </div>
          </React.Fragment>
        }
      </div>
    )
  }


export default ShippingInfoCard


