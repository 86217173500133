import React from "react";
import "./index.less";
import {IconSvg} from "../../components/svgImages/SvgImages";
import SettingsButton from "../../components/SettingsButton";
import {ProductsImages} from "../../components/ProductsImages";

const PostalStatusPage = () => {

  const activeBlock = "activeBlock"
  return (
    <div className={"PostalStatusPage"}>
      <div className={"PostalStatusPageContainer"}>
        <div className={"PostalStatusPageContainer-postalInfo"}>
          <div className={"PostalStatusPageContainer-postalInfo_header"}>
            <h1>Check Status</h1>
            <p>Your application was sent successfully.</p>
          </div>

          <div className={"PostalStatusPageContainer-postalInfo_step"}>
            <div className={`PostalStatusPageContainer-postalInfo_step-progressStatus_${activeBlock}`}>
              <span><IconSvg type={"shipped"}/></span>
              <p>Shipped</p>
            </div>
            <div className={`PostalStatusPageContainer-postalInfo_step-progressStatus`}>
              <span><IconSvg type={"inTransit"}/></span>
              <p>In Transit</p>
            </div>
            <div className={`PostalStatusPageContainer-postalInfo_step-progressStatus`}>
              <span><IconSvg type={"delivered"}/></span>
              <p>Delivered</p>
            </div>
          </div>
        </div>

        <div className={"PostalStatusPageContainer-userPackageInfo"}>
          <div className={"CheckStatusInfoBody-container_product-info_block-postalInfo"}>
            <div className={"CheckStatusInfoBody-container_product-info_block-postalInfo_address"}>
              <p>Your package will be delivered to:</p>
              <p>{"Idaho Vital Records Po Box 83720, Boise-Id 83720-0036, US"}</p>
            </div>
            <div className={"CheckStatusInfoBody-container_product-info_block-postalInfo_trackNum"}>
              <p>Your USPS Tracking number</p>
              <p>{"9205 5000 0000 0000 0000 00"}</p>
            </div>
          </div>
          <div className={"PostalStatusPageContainer-userPackageInfo_trackButton"}>
            <SettingsButton buttonText={"Track on USPS"}/>
          </div>
          <div className={"PostalStatusPageContainer-userPackageInfo_postalImage"}>
            <ProductsImages type={"usPostalService"} imageWidth={150}/>
          </div>
        </div>
      </div>
    </div>
  )

}

export default PostalStatusPage;
