import React from "react"

interface ISvgCheck {
    width: number;
    height: number;
    color: string;
}

export const SvgPlusSeparator = ({width = 19, height = 19, color = "#185EC8"}: ISvgCheck) => {
    const viewBox = `0 0 ${width} ${height}`;
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.428 11.3312H11.813V18.1212H7.19301V11.3312H0.578008V7.06125H7.19301V0.27125H11.813V7.06125H18.428V11.3312Z"
                fill={color} />
        </svg>

    )
}
