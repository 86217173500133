import React, { useEffect } from "react"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { Form, Select } from "antd"
import { useDispatch } from "react-redux"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
const { Option } = Select

interface optionsInterface {
  name: string;
  dial_code: string;
  code: string;
}

type SelectComponentProps = {
  field: FieldInterface,
  options: optionsInterface[],
  dependsField: string,
}

const getDefaultOption = (defaultValueName: string, options: optionsInterface[]) =>
  options.find(({ name }) => defaultValueName === name) ?? { dial_code: "+63" }

const SelectPhoneComponent: React.FunctionComponent<SelectComponentProps> = ({ field, options, dependsField }) => {
  const { defaultValue, name, label, inputOption } = field

  const dispatch = useDispatch()

  const getSelectionOption = (value: string) => dispatch(setValueCurrentField(dependsField, value))

  useEffect(() => {
    dispatch(setValueCurrentField(dependsField, getDefaultOption(defaultValue.name, options)?.dial_code))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form.Item
      label={label}
      name={name}
      initialValue={`${defaultValue.name} (${defaultValue.dial_code})`}
      rules={[
        {
          pattern: new RegExp(`^(?!${defaultValue}$).*`),
          message: "Required",
        },
      ]}
      preserve={false}
    >
      <Select
        showSearch={!!inputOption}
        placeholder=""
        filterOption={
          inputOption
            ? (input, option) => option?.props?.children?.toLowerCase()?.indexOf(input.toLowerCase()) === 0
            : false
        }
        onChange={(value: string) => getSelectionOption(value)}
        style={{ width: 320 }}
      >
        {options?.map(({ name, dial_code }) => {
          return <Option value={dial_code} key={name}>{`${name} (${dial_code})`}</Option>
        })}
      </Select>
    </Form.Item>
  )
}

export default SelectPhoneComponent
