import React, { FC } from "react"
import GText from "../../atoms/Text/Text"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import { SIZE } from "../../types/types"
import "./GUpsellCard.scss"
import GCheckListItem from "../../molecules/CheckList/CheckListItem"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GTag from "../../molecules/Tag/GTag"
import GButton, { BUTTON_TYPE } from "../../molecules/Button/GButton"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"

export enum CARD_THEME {
  ONE = "one",
  TWO = "two",
}

type IGUpsellCard = {
  theme?: CARD_THEME,
  title: string,
  subtitle?: string,
  subtitleSize?: string,
  imageHeader?: string,
  componentHeader?: React.ReactElement
  logo?: string,
  logoSubText?: string,
  logoSubTitle?: string,
  checkList: string[] | undefined,
  checkListSubTitle?: string,
  prevPriceInformation?: string,
  priceInformation?: string,
  tagInformation?: string,
  textButton: string,
  iconButton?: string,
  footerText?:string,
  ctaClick: any,
  ctaDisable?: boolean,
  ctaLoading?: boolean,
  className?: string
}

const GUpsellCard: FC<IGUpsellCard> = ({
  theme = CARD_THEME.ONE,
  title,
  subtitle,
  subtitleSize,
  imageHeader,
  componentHeader,
  logo,
  logoSubText,
  logoSubTitle,
  checkList,
  checkListSubTitle,
  prevPriceInformation,
  priceInformation,
  tagInformation,
  textButton,
  iconButton,
  footerText,
  ctaClick,
  ctaDisable = false,
  ctaLoading = false,
  className
}) => {
  const letterSpacingText = {
    letterSpacing: "-0.1px",
    cursor: "pointer"
  }
  const breakPoint = useBreakpoint()
  const upsellImageStyles: React.CSSProperties = {
    border: `solid 1px ${COLORS_NEW.BLACK_200}`,
    borderRadius: 24,
    boxShadow: `0px 8px 24px 4px rgba(0, 0, 0, 0.04)`
  }
  return (
    <div className={`GUpsellCard ${className}`}>
      {theme === CARD_THEME.ONE && (
        <div className="GUpsellCard__Header">
          {title && <div className="GUpsellCard__Header__Title">
            <GText text={title} size={breakPoint.md ? SIZE.HEADING_MDLG : SIZE.PARAGRAPH_BOLD_18} color={COLORS_NEW.BLACK_1000} />
          </div>}
          {subtitle && (
            <div className="GUpsellCard__Header__SubTitle">
              <GText 
                text={subtitle} 
                size={breakPoint.md ? subtitleSize || SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16} 
                color={COLORS_NEW.ROYAL_BLUE_700} 
                innerHtml={breakPoint.md ? subtitle : subtitle.replaceAll("<br>", "")} 
                maxWidth="550px" 
              />
            </div>
          )}
        </div>
      )}
      {theme === CARD_THEME.TWO && (
        <div className="GUpsellCard__Foreground">
          <div className="GUpsellCard__Foreground__Title">
            <GText text={title} size={SIZE.HEADING_SM} color={COLORS_NEW.BLACK_1000} />
          </div>
        </div>
      )}
      {componentHeader}
      {(imageHeader || logo || logoSubText || logoSubTitle) && (
        <div className="GUpsellCard__Logo">
          {imageHeader && <img style={!className?.includes("upgrade") ? upsellImageStyles : undefined} src={imageHeader} alt="header_upsell" />}
          {logo && <GIconsSvgType type={logo} width={112} height={29} />}
          {logoSubText && (
            <div className="GUpsellCard__Logo__SubLogo">
              <GText text={logoSubText} size={SIZE.HEADING_XS} color={COLORS_NEW.BLACK_1000} />
            </div>
          )}
          {logoSubTitle && (
            <div className="GUpsellCard__Logo__SubTitle">
              <GText text={logoSubTitle} size={SIZE.PARAGRAPH_BOLD_22} color={COLORS_NEW.BLACK_800} />
            </div>
          )}
        </div>
      )}
      {checkList && checkList.length > 0 && (
          <div className="GUpsellCard__CheckList">
            {checkList.map((text, key) => {
              return (
                  <div key={key}>
                    <GCheckListItem
                        text={text}
                        textSize={SIZE.PARAGRAPH_REGULAR_16}
                        textColor={COLORS_NEW.BLACK_800}
                        bubbleColor={COLORS_NEW.GREEN_600}
                        bubbleWidth={24}
                        bubbleHeight={24}
                        iconsType={ICONS_SVG_TYPE.CHECK}
                        iconsWidth={15}
                        iconsHeight={15}
                        iconsColor={COLORS.WHITE}
                    />
                  </div>
              )
            })}
          </div>
      )}
      {checkListSubTitle && (
          <div className="GUpsellCard__Header">
            <div className="GUpsellCard__Header__SubTitle">
              <GText text={''} innerHtml={checkListSubTitle} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
            </div>
          </div>
      )}
      <div className={"GUpsellCard__Content"}>
        {priceInformation && (
          <div className="GUpsellCard__PriceContainer">
            <div className="GUpsellCard__PriceContainer__Price">
              {prevPriceInformation &&
                  <div className="GUpsellCard__PriceContainer__Price__PrevPrice">
                    <GText text={prevPriceInformation} size={SIZE.PARAGRAPH_BOLD_24} color={COLORS_NEW.BLACK_300} />
                  </div>
              }
              <GText text={priceInformation} size={SIZE.PARAGRAPH_BOLD_24} color={COLORS_NEW.BLACK_1000} innerHtml={priceInformation}/>
            </div>
            {tagInformation && (
              <div className="GUpsellCard__PriceContainer__Time">
                <GTag
                  text={tagInformation}
                  sizeText={SIZE.PARAGRAPH_BOLD_14}
                  colorBackground={COLORS_NEW.YELLOW_200}
                  colorText={COLORS_NEW.YELLOW_700}
                />
              </div>
            )}
          </div>
        )}
        <div className="GUpsellCard__CTA">
          <GButton
            text={textButton}
            textSize={SIZE.PARAGRAPH_BOLD_16}
            click={ctaClick}
            showIcon={true}
            iconType={iconButton}
            type={theme === CARD_THEME.ONE ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.SECONDARY}
            loading={ctaLoading}
            disabled={ctaDisable}
          />
        </div>
      </div>
      
      {footerText && (
            <div className="GUpsellCard__FooterText">
              <GText 
                text={footerText} 
                size={SIZE.PARAGRAPH_REGULAR_14} 
                color={COLORS_NEW.BLACK_500} 
                style={letterSpacingText}/>
            </div>
          )}
    </div>
  )
}

export default GUpsellCard
