import React from "react"
import GSmallTrackingCard from "../shared/SmallTrackingCard/SmallTrackingCard"
import GFollowInstructionsCard from "../shared/FollowInstructionsCard/FollowInstructionsCard"
import GMediumTrackingCard from "../shared/MediumTrackingCard/MediumTrackingCard"
import {getImageFromLocalStorage, ProcessingStatuses} from "../../../../../../util/ProcessingTrackingHelper"
import {GPassportStatusTrackingProps} from "./PassportStatusTracking.props"
import handWithPassport from "../../../../../../assets/image/hand-with-pas.webp"
import ProcessingValidationComponent
    from "../../../../../FormComponents/PostSubmitComponents/ProcessingValidationComponent/ProcessingValidationComponent";
import PendingReceivingConfirmationComponent
    from "../../../../../FormComponents/PostSubmitComponents/PendingReceivingConfirmationComponent/PendingReceivingConfirmationComponent";
import {useDispatch} from "react-redux"
import {submitAdditionalData} from "../../../../../../Redux/store/additionalForm/additional.actions"
import {videoLinkFollowInstruction} from "../../../../../../../../dashboard-govplus-front/src/api/apiHelper"
import {SolutionNamesForAdditionalSteps} from "../../../../../../util/SolutionNames";

const GPassportStatusTracking: React.FC<GPassportStatusTrackingProps> = ({
                                                                             status,
                                                                             title,
                                                                             subtitle,
                                                                             nameProduct,
                                                                             showStatus,
                                                                             applicationId,
                                                                             loadingBtnState
                                                                         }) => {

    const dispatch = useDispatch()
    const sendConfirmationOfReceipt = () => {
        dispatch(submitAdditionalData({
            confirm_received_document: true,
        }))
    }
    const getContent = (status: string) => {
        switch (status) {
            case ProcessingStatuses.processing:
                return <GSmallTrackingCard
                    showGovernmentIcon={false}
                    imgSrc={getImageFromLocalStorage(nameProduct)}
                    solution={title}
                    width={50}
                    height={70}
                />
            case ProcessingStatuses.processing_validation:
                return <ProcessingValidationComponent
                    title={title}
                    subtitle={subtitle}
                    nameProduct={nameProduct}
                />
            case ProcessingStatuses.pending_shipment:
                return <GMediumTrackingCard
                    estimatedWaitTime={"1 business day"}
                    solution={title}
                />
            case ProcessingStatuses.processing_follow_instructions:
                return <GFollowInstructionsCard videoLink={videoLinkFollowInstruction}/>
                
            case ProcessingStatuses.pending_receiving_document_confirmation:
                return <PendingReceivingConfirmationComponent
                    title={title}
                    subtitle={"Estimated delay: 8 weeks"}
                    nameProduct={nameProduct}
                    textButton={`I’ve received my ${SolutionNamesForAdditionalSteps.PAS}`}
                    onClickBtn={sendConfirmationOfReceipt}
                    loadingBtnState={loadingBtnState}
                    imageLeft={handWithPassport}
                />
            default:
                return null
        }
    }

    return getContent(status)
}

export default GPassportStatusTracking