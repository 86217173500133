import { connect } from "react-redux"
import React, { useEffect, useState } from "react"
import { Button } from "antd"
import SelectMultiple from "../../../FieldsComponents/SelectMultiple/SelectMultiple"
import ImportantBlockComponent from "./сomponentsCOA/ImportantBlockComponent"
import AddressBlockComponent from "./сomponentsCOA/AddressBlockComponent"
import StepsCoaComponent from "./сomponentsCOA/StepsCoaComponent"
import SelectProvidersComponent from "./сomponentsCOA/SelectProvidersComponent"
import StatusComponent from "./сomponentsCOA/StatusComponent"
import TableDataComponent from "./сomponentsCOA/TableDataComponent"
import { submitAdditionalCoa } from "../../../../../Redux/store/additionalForm/additional.actions"

type AdditionalCoaProps = {
  additionalPage: {} | any,
  submitObject: any,
  dispatch: any,
  loadingButtonState: string,
}

const getElement = (
  item?: {} | any,
  className?: string | any,
  handlerButtonMoveNextPage?: any,
  currentStep?: any,
  clientId?: string | any,
  customer?: {} | any
) => {
  if (item && item.tag === "h1") {
    if (currentStep.name === "сoa_required_container3") {
      return (
        <h1 className={`${className} ${"name-" + item.name} ${"type-" + item.type}`} key={item.name}>
          Hi,{customer && customer.first_name}
        </h1>
      )
    } else {
      return (
        <h1 className={`${className} ${"name-" + item.name} ${"type-" + item.type}`} key={item.name}>
          {item.text}
        </h1>
      )
    }
  }
  if (item && item.tag === "div") {
    return (
      <div className={`${className} ${"name-" + item.name} ${"type-" + item.type}`} key={item.name}>
        {item.content}
      </div>
    )
  }
  if (item && item.tag === "h3div") {
    return (
      <div className={`${className} ${"name-" + item.name} ${"type-" + item.type}`} key={item.name}>
        <h3 className={`${className} ${"name-" + item.name} ${"type-" + item.type}`}>{item.text}</h3>
      </div>
    )
  }
  if (item && item.tag === "h3") {
    return (
      <h3 className={`${className} ${"name-" + item.name} ${"type-" + item.type}`} key={item.name}>
        {item.text}
      </h3>
    )
  }

  if (item && item.tag === "p") {
    return (
      <p className={`${className} ${"name-" + item.name} ${"type-" + item.type}`} key={item.name}>
        {item.text}
      </p>
    )
  }
  if (item && item.tag === "checkStatus") {
    return (
      <p
        dangerouslySetInnerHTML={{ __html: item.text }}
        className={`additional-page__container-main-text name-addCompaniens type-textsteps2`}
      ></p>
    )
  }
  if (item && item.tag === "clientID") {
    return (
      <p className={`${className} ${"name-" + item.name} ${"type-" + item.type}`} key={item.name}>
        {item.text}
        {clientId}
      </p>
    )
  }
  if (item && item.tag === "button") {
    return (
      <Button
        className={`${className} ${"name-" + item.name} ${"type-" + item.type}`}
        key={item.name}
        onClick={handlerButtonMoveNextPage}
      >
        {item.text}
      </Button>
    )
  }
  if (item && item.tag === "selectMultiple") {
    return (
      <React.Fragment key={item.name}>
        <SelectMultiple field={item} />
      </React.Fragment>
    )
  }
  if (item && item.tag === "importantBlock") {
    return (
      <React.Fragment key={item.name}>
        <ImportantBlockComponent item={item.content} />
      </React.Fragment>
    )
  }
  if (item && item.tag === "addressBlock") {
    return (
      <div className={"address-change_testBlock"}>
        <React.Fragment key={item.name}>
          <AddressBlockComponent item={item.content} item2={item.content} />
        </React.Fragment>
      </div>
    )
  }
  if (item.tag === "stepsCoaBlock") {
    return (
      <React.Fragment key={item.name}>
        <div className="address__change_coa_steps-component">
          <StepsCoaComponent item={item.content} />
        </div>
      </React.Fragment>
    )
  }

  if (item.tag === "providers") {
    return (
      <React.Fragment key={item.name}>
        <div className="address__change_coa_providers-component">
          <SelectProvidersComponent item={item.content} />
        </div>
      </React.Fragment>
    )
  }
  if (item.tag === "statusComponent") {
    return (
      <React.Fragment key={item.name}>
        <StatusComponent item={item.content} />
      </React.Fragment>
    )
  }
  if (item.tag === "tableData") {
    return (
      <React.Fragment key={item.name}>
        <TableDataComponent item={item.content} />
      </React.Fragment>
    )
  }
  if (item && item.tag === "nestBlock") {
    return item.nestBlock.map((itemMap: any) => {
      return <React.Fragment key={item.name}>{getElement(itemMap)}</React.Fragment>
    })
  }
}

const checkIncludes = (
  item: {} | any,
  handlerButtonMoveNextPage?: any,
  currentStep?: any,
  clientId?: any,
  customer?: any
) => {
  const arrayTag = [
    "h1",
    "h3",
    "p",
    "button",
    "selectMultiple",
    "importantBlock",
    "nestBlock",
    "stepsCoaBlock",
    "providers",
    "statusComponent",
    "tableData",
    "h3div",
    "clientID",
    "checkStatus",
  ]

  if (arrayTag.includes(item.tag)) {
    return getElement(
      item,
      "additional-page__container-main-text",
      handlerButtonMoveNextPage,
      currentStep,
      clientId,
      customer
    )
  }
}

// eslint-disable-next-line max-len
const AdditionalCoa: React.FunctionComponent<AdditionalCoaProps> = ({
  additionalPage,
  submitObject,
  dispatch,
  loadingButtonState,
}) => {
  const { main } = additionalPage || []
  const [actionLoading, setActionLoading] = useState("")

  useEffect(() => {
    /* Event Processing Step */
  }, [])

  const handlerButtonSubmit = () => {
    setActionLoading("saveCompanies")
    dispatch(submitAdditionalCoa({ ...submitObject, companies_later: false }))
  }

  return (
    <div className={`div-bg`}>
      <main className={"additional-page_coa__container-main"}>
        <div className={`additional-page_coa__container-main-data-${additionalPage.name}`}>
          {main && main.map((item: {} | any) => checkIncludes(item))}
        </div>
        <div className={"additional-page_coa__container-main-img"}></div>
      </main>
      <div className={"additional-page_coa__container-actions"}>
        <Button
          className="ant-btn ant-btn-primary name-saveCompanies"
          key="saveCompanies"
          disabled={loadingButtonState === "loading"}
          loading={loadingButtonState === "loading" && actionLoading === "saveCompanies"}
          onClick={handlerButtonSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  arraySteps: state.step.arraySteps,
  indexCurrentStep: state.step.indexCurrentStep,
  submitObject: state.form.submitObject,
  appId: state.form.appId,
  formId: state.form.formId,
  customer: state.form.customer,
  currentStep: state.step.currentStep,
  clientId: state.form.clientId,
  clientEmail: state.form.clientEmail,
  loadingButtonState: state.loading.loadingButtonState,
})

export default connect(mapStateToProps)(AdditionalCoa)
