import IPersistenceHandler from "./IPersistenceHandler";

class SessionStorageHandler implements IPersistenceHandler {
    get(key: string): string | null {
        return sessionStorage.getItem(key);
    }

    has(key: string): boolean {
        return this.get(key) !== null;
    }

    set(key: string, value: string): void {
        sessionStorage.setItem(key, value)
    }

    static clearALl(): void {
        sessionStorage.clear()
    }

    static clearIgnoringKey(keysToKeep: string[]): void {
        for (let key in sessionStorage) {
            if (!keysToKeep.includes(key)) {
                sessionStorage.removeItem(key);
            }
        }
    }

    remove(key: string): void {
        sessionStorage.removeItem(key)
    }
}

export default SessionStorageHandler