import React from "react"

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
}

export const SvgDropDown = ({ width, height, color }: ISvgCheck) => {
  return (
    <svg width={width} height={height} viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.32812 1.5L6.32812 6.5L11.3281 1.5"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
