import React, { FC, useState } from "react"
import { Tabs } from "antd"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS_NEW } from "../../types/Colors"
import { CustomTabsProps } from "./CustomTabs.props"

const CustomTabs: FC<CustomTabsProps> = ({ tabsData }) => {
  const [activeKey, setActiveKey] = useState("1")

  const handleTabChange = (key: any) => {
    setActiveKey(key)
  }

  return (
    <Tabs activeKey={activeKey} onChange={handleTabChange}>
      {tabsData.map((tab: any) => (
        <Tabs.TabPane
          key={tab.key}
          tab={
            <>
              {tab.icon}
              <GText
                text={tab.tab}
                size={SIZE.PARAGRAPH_BOLD_18}
                color={activeKey === tab.key ? COLORS_NEW.BLACK_1000 : COLORS_NEW.BLACK_500}
              />
            </>
          }
        >
          {tab.content}
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}

export default CustomTabs
