import React, {FC, useEffect, useState} from "react"

import "./StepTaxPendingTracking.scss";
import {connect, useDispatch} from "react-redux";
import GText from "../../../atoms/Text/Text";
import {SIZE} from "../../../types/types";
import {COLORS} from "../../../types/Colors";
import {TaxConstant} from "../../../../../util/TaxConstant";
import {ICONS_SVG_TYPE} from "../../../atoms/IconsSvg/IconsSvgTypes";
import GIconsSvgType from "../../../atoms/IconsSvg/IconsSvg";
import StringHelper from "../../../../../util/StringHelper";
import GButton, {BUTTON_TYPE} from "../../../molecules/Button/GButton";
import GAdditionalStep, {StepShowStatus, StepVisualStatus} from "../AdditionalStep";
import AdditionalStepCardFail from "../../../organisms/AdditionalStepCard/AdditionalStepCardFail";
import IntercomHelper from "../../../../../util/IntercomHelper";
import {
    launchTaxAssistanceAction
} from "../../../../../Redux/store/additionalForm/additional.actions";

export interface ITaxReturn {
    tax_year: number,
    status: string,
    submission_status: string,
    refund_or_amount_owed_cents_total: number,

}

export type StepTaxPendingTrackingProps = {
    index?: string,
    title: string,
    titleDone: string,
    information?: string,
    ctaText?: string,
    ctaForm?: any,
    stepVisualStatus?: StepVisualStatus | string
    stepShowStatus: StepShowStatus | string
    children?: React.ReactNode,
    ctaLoading?: boolean,
    ctaDisabled?: boolean,
    failContent: any,
    taxReturn: ITaxReturn,
    launchTaxAssistance: any
}

const StepTaxPendingTracking: FC<StepTaxPendingTrackingProps> = ({
                                                                     index,
                                                                     title,
                                                                     titleDone,
                                                                     information,
                                                                     ctaText,
                                                                     ctaForm,
                                                                     stepVisualStatus,
                                                                     stepShowStatus,
                                                                     children,
                                                                     ctaLoading = false,
                                                                     ctaDisabled = false,
                                                                     failContent,
                                                                     taxReturn,
                                                                     launchTaxAssistance
                                                                 }) => {

    const dispatch = useDispatch();

    const enum ColumnTaxStatus {
        NOT_STARTED = "NOT_STARTED",
        STARTED = "STARTED",
        SUBMITTED = "SUBMITTED",
    }

    const enum ColumnTaxSubmissionStatus {
        NOT_SUBMITTED = "NOT_SUBMITTED",
        SUBMITTED = "SUBMITTED",
        RETRYABLE = "RETRYABLE",
        REJECTED = "REJECTED",
        ACCEPTED = "ACCEPTED"
    }

    const [taxStatus, setTaxStatus] = useState<string>(taxReturn?.status);
    const [taxSubmissionStatus, setTaxSubmissionStatus] = useState<string>(taxReturn?.submission_status);
    const [refundValue, setRefundValue] = useState<number>(taxReturn?.refund_or_amount_owed_cents_total/100);

    useEffect(() => {
        if (taxReturn) {
            setTaxStatus(taxReturn?.status);
            setTaxSubmissionStatus(taxReturn?.submission_status);
            setRefundValue(taxReturn?.refund_or_amount_owed_cents_total/100);
        }
    }, [taxReturn?.status, taxReturn?.submission_status, taxReturn?.refund_or_amount_owed_cents_total]);

    const handleCtaClick = () => {
        dispatch(launchTaxAssistanceAction())
    }

    return (
        <>
            {
                (!taxReturn && (
                    <GAdditionalStep
                        index={index}
                        title={title}
                        titleDone={titleDone}
                        information={information}
                        ctaText={ctaText}
                        stepShowStatus={stepShowStatus}
                        stepVisualStatus={stepVisualStatus}
                    />
                ))
            }
            {
                ((taxStatus === ColumnTaxStatus.SUBMITTED && taxSubmissionStatus === ColumnTaxSubmissionStatus.SUBMITTED) ||
                    (taxSubmissionStatus === ColumnTaxSubmissionStatus.ACCEPTED)) && (
                    <GAdditionalStep
                        index={index}
                        title={title}
                        titleDone={titleDone}
                        information={information}
                        ctaText={ctaText}
                        stepShowStatus={stepShowStatus}
                        stepVisualStatus={stepVisualStatus}
                    >
                        <div className={`StepTaxPendingTracking`}>
                            <div className={`StepTaxPendingTracking__RefundContainer`}>
                                <div className={`StepTaxPendingTracking__RefundContainer__TitleContainer`}>
                                    <GIconsSvgType type={ICONS_SVG_TYPE.TAX_REFUND_ICON} color={COLORS.BLACK_1000} width={24} height={24} />
                                    <GText text={TaxConstant.ESTIMATED_REFUND} size={SIZE.HEADING_XXS} color={COLORS.BLACK_1000} />
                                </div>
                                <div className={`StepTaxPendingTracking__RefundContainer__AmountContainer`}>
                                    <GText text={`$${refundValue}`} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS.GREEN_700_TAX} />
                                </div>
                            </div>
                            <div className={`StepTaxPendingTracking__InformationContainer`}>
                                <div className={`StepTaxPendingTracking__InformationContainer__Card`}>
                                    <GText text={TaxConstant.FEDERAL_TAX_RETURN} size={SIZE.HEADING_XXS} color={COLORS.BLACK_1000}/>
                                    <GText text={StringHelper.capitalizeFirstLetter(taxStatus)} size={SIZE.PARAGRAPH_BOLD_18} color={COLORS.GREEN_700_TAX} />
                                </div>
                                <div className={`StepTaxPendingTracking__InformationContainer__Card`}>
                                    <GText text={TaxConstant.STATE_RETURN_STATUS} size={SIZE.HEADING_XXS} color={COLORS.BLACK_1000}/>
                                    <GText text={StringHelper.capitalizeFirstLetter(taxSubmissionStatus)} size={SIZE.PARAGRAPH_BOLD_18} color={COLORS.GREEN_700_TAX} />
                                </div>
                            </div>
                            <div className={`StepTaxPendingTracking__CtaContainer`}>
                                <GButton
                                    type={BUTTON_TYPE.SECONDARY}
                                    textSize={SIZE.PARAGRAPH_BOLD_16}
                                    text={TaxConstant.CTA_SEE_MORE_DETAIL}
                                    isSubmit={false}
                                    showIcon={false}
                                    disabled={ctaDisabled}
                                    loading={ctaLoading}
                                    click={handleCtaClick}
                                />
                            </div>
                        </div>
                    </GAdditionalStep>
                )
            }
            {
                (taxSubmissionStatus === ColumnTaxSubmissionStatus.RETRYABLE || taxSubmissionStatus === ColumnTaxSubmissionStatus.REJECTED) && (
                    <AdditionalStepCardFail
                        title={failContent.retryable.message}
                        ctaText={failContent.retryable.button}
                        ctaLoading={ctaLoading}
                        ctaDisabled={ctaDisabled}
                        ctaOnClick={()=>{
                            launchTaxAssistance();
                        }}
                    />
                )
            }
        </>
    );
}

const mapStateToProps = (state: any) => ({
    loadingButtonState: state.loading.loadingButtonState,
    taxReturn: state.additional.taxReturn
})

export default connect(mapStateToProps)(StepTaxPendingTracking)