import { ScrollTargets } from '@dash/gov-plus__front-end__form/src/util/IdentityProtectionHelper';
import { useEffect, RefObject } from 'react';
import { useDispatch } from 'react-redux';
import { setScrollTarget } from '../appRedux/actions';

interface RefMap {
  [key: string]: RefObject<HTMLDivElement>;
}

const useScrollToTarget = (
  scrollTarget: ScrollTargets | null,
  refs: RefMap
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (scrollTarget && refs[scrollTarget]?.current) {
      const timeOut = setTimeout(() => {
        refs[scrollTarget].current?.scrollIntoView({
          behavior: "smooth", 
          block: "center", 
          inline: "nearest"
        });
        dispatch(setScrollTarget(null));
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [scrollTarget, refs, dispatch]);
};

export default useScrollToTarget;