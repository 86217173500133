import React from "react";
import DeliveredInfoBox from "../../../../../../components/DeliveredInfoBox";
import SimpleStatusesForProducts from "../../../../simpleStatusesForProducts";
import {useSelector} from "react-redux";

const Completed = ({status, productId}) =>{
  const {
    allProducts,
    allProductsLoader,
    anotherGovProducts,
  } = useSelector(({allProducts}) => allProducts);
  const {name, lastName} = useSelector(({auth}) => auth.userData);
  const headerText = "Your application is on its way";
  const productImage = anotherGovProducts?.find(item => item.id === allProducts[productId]?.product_name)?.image;
  const productName = anotherGovProducts?.find(item => item.id === allProducts[productId]?.product_name)?.title;
  const {
    trackingNum,
    trackingUri,
    provider,
    address: {address1, city_name, zipcode, country_code}
  } = allProducts[productId].add;

  return(
    <div>
      <SimpleStatusesForProducts
        iconStatus={status}
        userName={name}
        headerText={headerText}
        description={""}
        buttonText={""}
        productImage={productImage}
        productName={productName}
        productId={productId}
      />

      <DeliveredInfoBox
        trackNumber={trackingNum}
        deliverAddress={`${address1}, ${city_name} ${zipcode}, ${country_code}`}
        redirectLink={trackingUri}
        provider={provider}
      />
    </div>
  )
}

export default Completed;
