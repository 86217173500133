import {
  CHECK_DUPLICATE_PRODUCT,
  CHECK_DUPLICATE_PRODUCT_CLEAR,
  CHECK_DUPLICATE_PRODUCT_SUCCESS,
  FIRST_SELECTED_PRODUCT,
  GET_FELLING_PRESENT,
  GET_FELLING_PRESENT_SUCCSSES,
  GET_GOVERNMENT_PRODUCTS,
  GET_GOVERNMENT_PRODUCTS_SUCCSSES,
  GET_PRODUCTS,
  GET_PRODUCTS_ADDITIONAL_INFO,
  GET_PRODUCTS_SUCCSSES,
  SEND_APPOINTMENT,
  SET_BREADCRUMBS_VALUE,
  SET_CHECK_STATUS_EMAIL_PRODUCT_ID, SET_SELECTED_FORM_ID,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  allProductsLoader: false,
  allProducts:[],
  userProductsName: [],
  anotherGovProducts: [],
  govProductsLoader: false,
  getPresentStatus: false,
  productsPresent: {},
  breadcrumbsObj: {},
  userSelectedProducts: "",
  productIdFromEmailCheckStatus: "",
  selectedFormId: "",
  duplicateApplicationLoader: null,
  duplicateApplications: {},
  userSelectedFormId: "",
  userSelectedService: "",
  webFlowData: JSON.parse(localStorage.getItem("webFlowServicesData")) || {},
};

const ProductsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS: {
      return {
        ...state,
        allProductsLoader: true,
      }
    }
    case GET_GOVERNMENT_PRODUCTS: {
      return {
        ...state,
        govProductsLoader: true,
      }
    }
    case GET_PRODUCTS_ADDITIONAL_INFO: {
      return {
        ...state,
        allProductsLoader: true,
      }
    }
    case GET_PRODUCTS_SUCCSSES: {
      return {
        ...state,
        allProductsLoader: false,
        allProducts: action.payload.applications,
        userProductsName: action.payload.userProductsName,
        productLoaded: action.payload.productLoaded,
      }
    }
    case GET_GOVERNMENT_PRODUCTS_SUCCSSES: {
      return {
        ...state,
        govProductsLoader: false,
        anotherGovProducts: action.payload.anotherGovProducts,
        webFlowData: action.payload.webFlowData,
      }
    }
    case GET_FELLING_PRESENT: {
      return {
        ...state,
        getPresentStatus: false,
      }
    }
    case GET_FELLING_PRESENT_SUCCSSES: {
      return {
        ...state,
        productsPresent: {...action.payload.productsPercentArr},
        getPresentStatus: action.payload.gettingPercentStatus,
      }
    }
    case SEND_APPOINTMENT: {
      return {
        ...state,
        allProductsLoader: true,
      }
    }
    case SET_BREADCRUMBS_VALUE: {
      return {
        ...state,
        breadcrumbsObj: action.payload
      }
    }
    case FIRST_SELECTED_PRODUCT: {
      return {
        ...state,
        userSelectedProducts: action.payload.userSelectedCategory,
        userSelectedFormId: action.payload.form_id,
        userSelectedService: action.payload.service,
      }
    }
    case SET_CHECK_STATUS_EMAIL_PRODUCT_ID: {
      return {
        ...state,
        productIdFromEmailCheckStatus: action.payload.productIdFromEmailCheckStatus,
      }
    }

    case CHECK_DUPLICATE_PRODUCT: {
      return {
        ...state,
        duplicateApplicationLoader: true
      }
    }

    case CHECK_DUPLICATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        duplicateApplicationLoader: false,
        duplicateApplications: action.payload
      }
    }
    case CHECK_DUPLICATE_PRODUCT_CLEAR: {
      return {
        ...state,
        duplicateApplications: {}
      }
    }
    case SET_SELECTED_FORM_ID: {
      return {
        ...state,
        selectedFormId: action.payload
      }
    }
    default:
      return state;
  }
}

export default ProductsReducer;
