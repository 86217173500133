import IBuilder from "./IBuilder";
import IEvent from "./IEvent";

/**
 * Base Builder
 * @class BaseBuilder
 * @description This is the base class builder
 */
abstract class BaseBuilder implements IBuilder{
    protected _ipAddress: string | undefined;

    /**
     * Set ip address
     * @param ipAddress
     * @returns {IBuilder}
     * @description This method is used to set ip address
     */
    abstract setIpAddress(ipAddress: string | undefined): IBuilder;

    /**
     * Build
     * @returns {IEvent}
     * @description This method is used to build
     */
    abstract build(): IEvent;
}

export default BaseBuilder;