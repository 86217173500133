import React from "react"

interface ISvgDashArrowLeft {
  width: number;
  height: number;
  color: string;
}

export const SvgDashArrowLeft = ({ width, height, color }: ISvgDashArrowLeft) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.1654 8.08594H3.83203" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.4987 12.7552L3.83203 8.08854L8.4987 3.42188"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
