import { FC, useEffect, useState } from "react"
import { BasicBlockContainerProps } from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/interfaces/interfaces"
import {
  renderBasicBlockContainer,
  userSelector,
} from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import LayoutBaseContainer from "../../Components/FormComponents/UploadPhoto/Layouts/LayoutBaseContainer/LayoutBaseContainer"
import { getServiceAndSolutionNameForFormsHeaders } from "../../services/gtm/backEndEvent"
import { useSelector } from "react-redux"
import { RootState } from "../../App"

const DynamicProductHeaderLayout: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text, initial_text, state, replace_text },
  arrayComponents,
}) => {
  const { service, solutionName } = getServiceAndSolutionNameForFormsHeaders()
  const percents = useSelector<RootState, { percents: any }>(userSelector).percents ?? ""

  const getReplace = (text: string) => text.replace("{product}", service)?.replace("{solution}", solutionName)

  const [statusInitialText, setStatusInitialText] = useState(false)
  const [textRender, setTextRender] = useState(getReplace(text))

  useEffect(() => {
    if (percents && Number(!percents?.oneProduct) && initial_text && !statusInitialText) {
      setStatusInitialText(true)
      setTextRender(getReplace(initial_text))
    }
  }, [getReplace, initial_text, percents, percents?.oneProduct, service, solutionName, statusInitialText, text])

  text = getReplace(text)

  return renderBasicBlockContainer(
    { className, text: initial_text ? textRender : text, childrenItem, arrayComponents, state },
    LayoutBaseContainer
  )
}

export default DynamicProductHeaderLayout
