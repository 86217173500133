import {apiAxiosInstance} from "../../../../dashboard-govplus-front/src/api/apiHelper";
import {SubscriptionStatuses} from "../../Components/atomicDesign/types/types";

export default class SubscriptionService {

    static async getUserSubscriptionStatus() {
        return await apiAxiosInstance.subscriptionBackInstance("get", "subscriptions/my-status")
    }

    static async isUserSubscribed() {
        try {
            const response = await this.getUserSubscriptionStatus();

            return response?.data?.status === SubscriptionStatuses.ACTIVE
        } catch (e) {
            return false;
        }
    }
}
