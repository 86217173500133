import Event from "../Event"
import {EventOptions, EventProps} from "../EventProperties"
import IEvent from "../IEvent"

const EVENT_NAME = "Identity Protection Activation Started"

/**
 * Identity Protection Activation Started
 * @class IdentityProtectionCoverageStarted
 * @extends {Event}
 * @implements {IEvent}
 * @description Identity Protection Activation Started
 */

export default class IdentityProtectionCoverageStarted extends Event implements IEvent {
    private readonly _bundle_id: string | undefined;
    private readonly _package_name: string | undefined;

    /**
     * Creates an instance of IdentityProtectionCoverageStarted.
     * @memberof IdentityProtectionCoverageStarted
     * @description Identity Protection Activation Started
     */

    constructor(bundle_id: string | undefined, package_name: string | undefined, ipAddress: string | undefined) {
        super(EVENT_NAME, ipAddress)
        this._bundle_id = bundle_id;
        this._package_name = package_name;
    }

    getEventData(): EventProps {
        return {
            ...super.baseEventData(),
            bundle_id: this._bundle_id,
            package_name: this._package_name,
        }
    }

    getEventName(): string {
        return this.eventName
    }

    pageName(): string {
        return ""
    }

    getPreviousId(): string {
        return ""
    }

    getUserId(): string {
        return ""
    }

    getEventOptions(): EventOptions | undefined {
        return undefined
    }
}
