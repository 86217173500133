import React, {FC} from "react"
import "./GAdditionalStepCard.scss"
import GText from "../../atoms/Text/Text"
import {SIZE} from "../../types/types"
import {COLORS} from "../../types/Colors"
import GButton, {BUTTON_TYPE} from "../../molecules/Button/GButton"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes"
import iconError from "./image/icon-oval-x.png"

type AdditionalStepCardFailProps = {
    title: string,
    ctaText?: string,
    ctaOnClick?: any,
    ctaLoading?: boolean,
    ctaDisabled?: boolean,
}

const AdditionalStepCardFail: FC<AdditionalStepCardFailProps> = ({
                                                                     title,
                                                                     ctaText,
                                                                     ctaOnClick,
                                                                     ctaLoading = false,
                                                                     ctaDisabled = false,
                                                                 }) => {
    return (
        <div className={`GAdditionalStepCardFail`}>
            <div className={`GAdditionalStepCardFail__Information`}>
                <img alt="" src={iconError} className={`GAdditionalStepCardFail__Information__Image`}/>
                <GText text={title} size={SIZE.HEADING_XS} color={COLORS.RED_600}/>
            </div>
            {
                ctaText && <GButton
                    textSize={SIZE.PARAGRAPH_BOLD_16}
                    text={ctaText}
                    isSubmit={false}
                    showIcon={false}
                    disabled={ctaLoading}
                    loading={ctaDisabled}
                    click={ctaOnClick}
                    type={BUTTON_TYPE.SECONDARY}
                />
            }
        </div>
    )
}

export default AdditionalStepCardFail
