import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

export const CHANGED_FORM_TYPE = "changedFormType"

class ChangedFormTypeRepository extends BaseSessionStorageRepository {
    private static _instance: ChangedFormTypeRepository;

    private constructor() {
        super();
    }

    public static getInstance(): ChangedFormTypeRepository {
        if (!ChangedFormTypeRepository._instance) {
            ChangedFormTypeRepository._instance = new ChangedFormTypeRepository();
        }
        return ChangedFormTypeRepository._instance;
    }

    getKeyName(): string {
        return CHANGED_FORM_TYPE;
    }

    getValue(): string | null {
        return super.getValue() ?? null;
    }
}

export default ChangedFormTypeRepository;