import moment, { Moment } from "moment"

export const customValidation = (
  selectedMomentString: string,
  expirationMoment: Moment | undefined,
  thisMoment: Moment,
  minAgeMoment: Moment | undefined,
  maxAgeMoment: Moment | undefined,
  yearValue: number | string | undefined
) => {
  let isValid = true
  const selectedMoment = moment(selectedMomentString)
  if (yearValue) {
    if (
      expirationMoment &&
      (expirationMoment.isBefore(selectedMoment, "year") ||
        selectedMoment.isBefore(thisMoment, "year") ||
        expirationMoment.isBefore(selectedMoment, "month") ||
        selectedMoment.isBefore(thisMoment, "month") ||
        expirationMoment.isBefore(selectedMoment, "day") ||
        selectedMoment.isBefore(thisMoment, "day"))
    ) {
      isValid = false
    }

    if (
      minAgeMoment &&
      ((selectedMoment.year() !== minAgeMoment.year() && minAgeMoment.isBefore(selectedMoment, "year")) ||
        minAgeMoment.isBefore(selectedMoment, "month") ||
        minAgeMoment.isBefore(selectedMoment, "day"))
    ) {
      isValid = false
    }

    if (
      maxAgeMoment &&
      (maxAgeMoment.isAfter(selectedMoment, "year") ||
        selectedMoment.isAfter(thisMoment, "year") ||
        maxAgeMoment.isAfter(selectedMoment, "month") ||
        selectedMoment.isAfter(thisMoment, "month") ||
        maxAgeMoment.isAfter(selectedMoment, "day") ||
        selectedMoment.isAfter(thisMoment, "day"))
    ) {
      isValid = false
    }
  }
  return isValid
}
