import { SIZE, WEIGHT } from "../../types/types"
import {COLORS, COLORS_NEW} from "../../types/Colors"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"

export interface IBreadCrumbsSettings {
  textSize: string;
  textWeight: string;
  textColor: string;
  linkSize: string;
  linkColor: string;
  separatorIcon: string;
  separatorWidth: number;
  separatorHeight: number;
  separatorColor: string;
}

export const BREAD_CRUMBS_THEME: IBreadCrumbsSettings = {
  textSize: SIZE.PARAGRAPH_BOLD_14,
  textWeight: WEIGHT.WEIGHT_400,
  textColor: COLORS_NEW.BLACK_700,
  linkSize: SIZE.LINK_REGULAR_14,
  linkColor: COLORS_NEW.BLACK_700,
  separatorIcon: ICONS_SVG_TYPE.HEADER_SEPARATOR,
  separatorWidth: 20,
  separatorHeight: 20,
  separatorColor: COLORS_NEW.BLACK_400,
}
