import React from "react"
import ButtonToggle from "../../../Hocs/ButtonToggle/ButtonToggle"
import { connect } from "react-redux"
import { Form } from "antd"
import ButtonNextComponent from "./ButtonNextComponent/ButtonNextComponent"
import ReCaptchaComponent from "../../ReCaptchaComponent/ReCaptchaComponent"
import { getPropertiesForEvents, getServiceAndSolutionNameForFormsHeaders } from "../../../services/gtm/backEndEvent"
import StringHelper from "../../../util/StringHelper"
import { ServicesNames } from "../../../util/ServicesNames"

type FormToolsProps = {
  arraySteps: [],
  indexCurrentStep: number,
  handleDecrementClick: any,
  content?: string,
  type: string,
  reCaptcha: {} | any,
  buttonNext?: string | boolean | undefined,
  buttonPrev?: string | undefined,
  additionalStep?: boolean,
  nameStep: string,
  navigation?: {} | any,
  loadingButtonState: string,
  error: {} | any,
  dispatch: any,
  billingInformation: any,
  labelButton?: string,
  isPaymentPage?: boolean
}

const FormTools: React.FunctionComponent<FormToolsProps> = ({
  arraySteps,
  indexCurrentStep,
  handleDecrementClick,
  content,
  type,
  reCaptcha,
  buttonNext,
  buttonPrev,
  additionalStep,
  nameStep,
  navigation,
  loadingButtonState,
  error,
  dispatch,
  billingInformation,
  labelButton,
  isPaymentPage= false
}) => {
  // for hidden nextButton
  const arrayHiddenTypes = ["status-check-ein"]
  const visibleNext = !arrayHiddenTypes.includes(type)
  const typeClassName = type

  const text = navigation && navigation.text

  const disabledDecrement = indexCurrentStep === 0

  const additional = additionalStep ? "additional" : "base"

  const BUTTON_LABELS = {
    CTA: "{CTA}",
    GLO: "Next",
  }

  const getNextButtonName = (type: string, buttonNext: string | boolean | undefined) => {
    if(isPaymentPage) return "Submit";
    if (type === `Form`) return getNextButtonAction()
    if (type === `additional-form`) return `Update`
    if (type === `checkStatus`) return `${nameStep}`
    return buttonNext || `Next`
  }

  const getNextButtonAction = () => {
    // @ts-ignore
    const {service}: string = getServiceAndSolutionNameForFormsHeaders()
    // @ts-ignore
    const solution: string = arraySteps?.length && arraySteps["0"].Solution
    
    let actionName = service
    switch (service?.toLowerCase()) {
      case ServicesNames.PassportRenewal.toLowerCase():
        actionName = "Renew passport"
        break;
      case ServicesNames.PassportFirst.toLowerCase():
        actionName = "Get first passport"
        break
      case ServicesNames.PassportDamage.toLowerCase():
        actionName = "Replace damaged passport"
        break
      case ServicesNames.PassportLost.toLowerCase():
        actionName = "Replace lost passport"
        break
      case ServicesNames.PassportStolen.toLowerCase():
        actionName = "Replace stolen passport"
        break
      case ServicesNames.SocialSecurityCardReplacement.toLowerCase():
      case ServicesNames.SSCardReplacemnt.toLowerCase():
        actionName = "Replace S.S. card"
        break
      case ServicesNames.SocialSecurityCardNameChange.toLowerCase():
      case ServicesNames.SocialSecurityCardUpdates.toLowerCase():
      case ServicesNames.SSCardNameChange.toLowerCase():
      case ServicesNames.SSCardUpdates.toLowerCase():
        actionName = "Update S.S. card"
        break
      case ServicesNames.FirstSocialSecurityCard.toLowerCase():
      case ServicesNames.FirstSSCard.toLowerCase():
        actionName = "Get first S.S. card"
        break
      case ServicesNames.TAXES.toLowerCase():
      case ServicesNames.TAXES_V1_1.toLowerCase():
      case ServicesNames.TAXES_WITHOUT_YEAR.toLowerCase():
        actionName = "File taxes"
        break
      case ServicesNames.BirthCertificateReplacement.toLowerCase():
        actionName = "Replace birth certificate"
        break
      case ServicesNames.NewTSAPreCheck.toLowerCase():
        actionName = "Apply for TSA Precheck®"
        break
      case ServicesNames.TsaPreCheckRenewal.toLowerCase():
        actionName = "Renew TSA Precheck®"
        break
      case ServicesNames.IndividualAddressChange.toLowerCase():
        actionName = "Change individual address"
        break
      case ServicesNames.BusinessAddressChange.toLowerCase():
        actionName = "Change business address"
        break
      case ServicesNames.NewEin.toLowerCase():
        actionName = "Get EIN number"
        break
      case ServicesNames.EinRetrieval.toLowerCase():
        actionName = "Retrieve EIN number"
        break
      case ServicesNames.IRSEinConfirmationLetter.toLowerCase():
        actionName = "Get IRS confirmation letter"
        break
      case ServicesNames.EinsUpdate.toLowerCase():
        actionName = "Update EIN number"
        break
      default:
        break;
    }
    
    return solution ? `${actionName}` : "Next"

  }

  const getPrevButtonName = (type: string, buttonPrev: string | undefined) => {
    if ((type === `additional-form` || type === `status-check-ein`) && buttonPrev) {
      return buttonPrev
    }
    return "Previous"
  }

  const checkStatusClass = type === `checkStatus` ? "check-status" : ""

  return (
    <Form.Item>
      <div
        // eslint-disable-next-line max-len
        className={`form-block__tools form-block__tools_${additional} form-block__tools_${checkStatusClass} ${typeClassName}`}
      >
        {text && <p className={`form-block__tools-text`}>{text}</p>}
        <ButtonToggle
          handleDecrementClick={handleDecrementClick}
          disabled={disabledDecrement}
          iconTemplate={"arrow-prev"}
          buttonNext={buttonNext}
          buttonPrev={buttonPrev}
          nameStep={nameStep}
          indexCurrentStep={indexCurrentStep}
        >
          <p className={"button-toggle-step__prev-content"}>{getPrevButtonName(type, buttonPrev)}</p>
        </ButtonToggle>

        {visibleNext && !reCaptcha && (
          <ButtonNextComponent
            type={type}
            buttonNext={buttonNext}
            loadingButtonState={loadingButtonState}
            checkStatusClass={checkStatusClass}
            getNextButtonName={getNextButtonName}
          />
        )}

        {reCaptcha && (
          <div
            className={
              // eslint-disable-next-line max-len
              `button-toggle-step__next-content_wrapper button-toggle-step__next-content_${checkStatusClass}_wrapper`
            }
          >
            <ReCaptchaComponent />
            <ButtonNextComponent
              type={type}
              buttonNext={buttonNext}
              loadingButtonState={loadingButtonState}
              checkStatusClass={checkStatusClass}
              getNextButtonName={getNextButtonName}
            />
          </div>
        )}

        {/*{error && error.error && error.error.message && (*/}
        {/*  <>*/}
        {/*    <div></div>*/}
        {/*    <p className={"button-toggle-step__next_error"}>{`${error.error.message}`}</p>*/}
        {/*  </>*/}
        {/*)}*/}
      </div>
    </Form.Item>
  )
}

const mapStateToProps = (state: any) => ({
  loadingButtonState: state.loading.loadingButtonState,
  error: state.form.error,
  billingInformation: state.step.billingInformation,
})

export default connect(mapStateToProps)(FormTools)
