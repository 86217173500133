import React, {FC} from "react";
import {PaymentRequestTokenEvent} from "@stripe/stripe-js";
import {PriceInfo} from "../../types/types";
import StripePaymentButton from "../Stripe/StripePaymentButton";
import BraintreePaymentButton from "../Braintree/BraintreePaymentButton";
import {PaymentError} from "../../../../dto/PaymentError";
import { env } from "../../../../env";

type PaymentButtonsFactoryProps = {
    onTokenCreated?:  (event: PaymentRequestTokenEvent, userCoupon: any) => Promise<void>;
    priceInfo: PriceInfo,
    marginButton?: number;
    submitObject?:any;
    setError?: (paymentError: PaymentError | null) => void;
}

const PAYMENT_BUTTON_BRAINTREE = "braintree";
const PAYMENT_BUTTON_STRIPE = "stripe";

const enableButtonType = env.REACT_APP_PAYMENT_BUTTON;
export const PaymentButtonsFactory = {
    getButton:(data: PaymentButtonsFactoryProps) => {
        if(enableButtonType === PAYMENT_BUTTON_BRAINTREE) {
            return <BraintreePaymentButton
                priceInfo={data.priceInfo}
                onTokenCreated={data.onTokenCreated}
                marginButton={data.marginButton}
                submitObject={data.submitObject}
                setError={data.setError}
            />
        }

        return <StripePaymentButton
            marginButton={data.marginButton}
            priceInfo={data.priceInfo}
            onTokenCreated={data.onTokenCreated}
            submitObject={data.submitObject}
        />
    }
}