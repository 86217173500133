import CheckoutStarted from "./CheckoutStarted";
import IEvent from "../IEvent";
import BaseBuilder from "../BaseBuilder";

export default class CheckoutStartedBuilder extends BaseBuilder {
    private _value: string | undefined;
    private _revenue: string | undefined;
    private _currency: string | undefined;
    private _productId: string | undefined;
    private _productName: string | undefined;
    private _service: string | undefined;
    private _solution: string | undefined;
    private _productPrice: string | undefined;
    private _productQuantity: string | undefined;
    private _productCategory: string | undefined;
    private _subdivision: string | undefined;

    setIpAddress(ipAddress: string | undefined) {
        this._ipAddress = ipAddress;
        return this;
    }

    setValue(value: string | undefined) {
        this._value = value;
        return this;
    }

    setRevenue(value: string | undefined) {
        this._revenue = value;
        return this;
    }

    setCurrency(value: string | undefined) {
        this._currency = value;
        return this;
    }

    setProductId(value: string | undefined) {
        this._productId = value;
        return this;
    }

    setProductName(value: string | undefined) {
        this._productName = value;
        return this;
    }

    setService(value: string | undefined) {
        this._service = value;
        return this;
    }

    setSolution(value: string | undefined) {
        this._solution = value;
        return this;
    }

    setProductPrice(value: string | undefined) {
        this._productPrice = value;
        return this;
    }

    setProductQuantity(value: string | undefined) {
        this._productQuantity = value;
        return this;
    }

    setProductCategory(value: string | undefined) {
        this._productCategory = value;
        return this;
    }

    setSubdivision(value: string) {
        this._subdivision = value;
        return this;
    }

    build(): IEvent {
        return new CheckoutStarted(
            this._ipAddress,
            this._value,
            this._revenue,
            this._currency,
            this._productId,
            this._productName,
            this._service,
            this._solution,
            this._productPrice,
            this._productQuantity,
            this._productCategory,
            this._subdivision
        );
    }
}
