import BaseCookiesRepository from "./BaseCookiesRepository";

const CLIENT_TOKEN_VARS_KEY = "client_token";

class ClientTokenRepository extends BaseCookiesRepository {

    private static _instance: ClientTokenRepository;

    private constructor() {
        super()
    }

    public static getInstance(): ClientTokenRepository {
        if (!ClientTokenRepository._instance) {
            ClientTokenRepository._instance = new ClientTokenRepository();
        }
        return ClientTokenRepository._instance;
    }

    getKeyName(): string {
        return CLIENT_TOKEN_VARS_KEY;
    }
}

export default ClientTokenRepository;
