import AuthUserBearerTokenRepository from "../../persistence/session/AuthUserBearerTokenRepository";
import { env } from "../../env";

const axios = require("axios")
const hostDashBoard = env.REACT_APP_GOV_DASHBOARD ?? "https://auth.govplus.dev"
const host = env.REACT_APP_HOST ?? "https://api.govplus.dev"

const autofillMicroserviceUri = env.REACT_APP_AUTOFILL_MICROSERVICE_URI ?? "";

export const interceptorFunc = () =>
  axios.interceptors.request.use(
    (config: any) => {
      const token = AuthUserBearerTokenRepository.getInstance().getValue();
      config.withCredentials = true
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      config.headers["Content-Type"] = "application/json"
      return config
    },
    (error: string) => Promise.reject(error)
  )

export default class ServiceHostDashBoard {
  static getUsersData = async () => (await axios.get(`${hostDashBoard}/usersData/data`)).data.data

  static getUserProfile = async () => (await axios.get(`${hostDashBoard}/profile`)).data.data
   
  static savePrefilled = async (props: {}) => (await axios.put(`${autofillMicroserviceUri}/usersData/data`, props)).data.data

  static setUserData = async (props: {}) => (await axios.put(`${hostDashBoard}/usersState/data`, props)).data.data
}

export class ServiceUser {
  static getFormFillPercents = async (props: {}) =>
    (await axios.post(`${host}/api_gov/form/getFormFillPercent`, props)).data

  static submitGLO = async (props: {}) => (await axios.post(`${host}/api_gov/form/submitGlo`, props, {withCredentials: true})).data
}
