import React from "react";
import {productsAndStatusesConstants} from "./productsAndStatusesConstants";
import Passport from "./productsStatuses/PasportStatuses/Passport";
import Ein from "./productsStatuses/EINStatuses/Ein";
import AddressChange from "./productsStatuses/CoaStatuses/AddressChange";
import Ssn from "./productsStatuses/SsnStatuses/Ssn";
import Cer from "./productsStatuses/CerStatuses/Cer";
import Tsa from "./productsStatuses/TsaStatuses/Tsa";
import Nam from "./productsStatuses/NamStatuses/Nam";
import NoSuchProductOrStatus from "../NoSuchProductOrStatus";

const ProductSwicher = ({productName, statusName, productId}) => {
  const RenderComponent = () =>{
    switch (productName){
      case productsAndStatusesConstants.products.PAS:{
        return <Passport
          statusName={statusName}
          productId={productId}
          productName={productName}
        />
      }
      case productsAndStatusesConstants.products.EIN:{
        return <Ein
          statusName={statusName}
          productId={productId}
          productName={productName}
        />
      }
      case productsAndStatusesConstants.products.COA:{
        return <AddressChange
          statusName={statusName}
          productId={productId}
          productName={productName}
        />
      }
      case productsAndStatusesConstants.products.SSN:{
        return <Ssn
          statusName={statusName}
          productId={productId}
          productName={productName}
        />
      }
      case productsAndStatusesConstants.products.CER:{
        return <Cer
          statusName={statusName}
          productId={productId}
          productName={productName}
        />
      }
      case productsAndStatusesConstants.products.NAM:{
        return <Nam
          statusName={statusName}
          productId={productId}
          productName={productName}
        />
      }
      case productsAndStatusesConstants.products.TSA:{
        return <Tsa
          statusName={statusName}
          productId={productId}
          productName={productName}
        />
      }
      default: return(
        <NoSuchProductOrStatus
          description={"Sorry page for this product is not ready yet."}
          appId={productId}
          productName={productName}
          productStatus={statusName}
        />
      )
    }
  }

  return (
    <div>
      {RenderComponent()}
    </div>

  )
}

export default ProductSwicher;
