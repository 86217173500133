export enum ProductsIds {
    GloFormId = "glo-sub",

    PassportId = "PAS",
    BirthCertificateId = "CER",
    ChangeAddressId = "COA",
    EinId = "EIN",
    SSNId = "SSN",
    TsaId = "TSA",
    TAXId = "TAX",
    NAMId = "NAM"
}
