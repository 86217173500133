import React, { useState, useEffect, useRef, FC } from "react"
import "../InputText/InputText.scss"
import { Input } from "antd"
import { FULL_HISTORY_MASK_CLASS_NAME } from "../../../../util/FullHistoryMaskedFields"
import { SSNInputProps } from "./SSNInput.props"

const SSNInput: FC<SSNInputProps> = ({ onChange }) => {
  const [displayValue, setDisplayValue] = useState("")
  const [internalValue, setInternalValue] = useState("")
  const inputRef = useRef(null)

  const transformDisplay = (val: string) => {
    let displayVal = val.replace(/[^0-9|\\*]/g, "").substr(0, 9)

    if (displayVal.length >= 4) {
      displayVal = displayVal.slice(0, 3) + "-" + displayVal.slice(3)
    }

    if (displayVal.length >= 7) {
      displayVal = displayVal.slice(0, 6) + "-" + displayVal.slice(6)
    }

    if (displayVal.length > 5) {
      const maskedPart = displayVal.slice(0, 6).replace(/[0-9]/g, "*")
      const unmaskedPart = displayVal.slice(6)
      displayVal = maskedPart + unmaskedPart
    } else {
      displayVal = displayVal.replace(/[0-9]/g, "*")
    }

    return displayVal
  }

  const transformValue = (val: string) => {
    if (!val) {
      setInternalValue("")
      return ""
    }

    const cleanVal = val.replace(/[^0-9|\\*]/g, "").substr(0, 9)
    let newInternalValue = internalValue

    for (let i = 0; i < cleanVal.length; i++) {
      if (/[0-9]/.test(cleanVal[i])) {
        newInternalValue = newInternalValue.substr(0, i) + cleanVal[i] + newInternalValue.substr(i + 1)
      }
    }

    const updatedInternalValue = newInternalValue.substr(0, cleanVal.length)
    setInternalValue(updatedInternalValue)
    return updatedInternalValue
  }

  const handleInputChange = (e: { target: { value: any } }) => {
    const val = e.target.value
    const displayVal = transformDisplay(val)
    const updatedInternalValue = transformValue(val)
    setDisplayValue(displayVal)
    setInternalValue(updatedInternalValue)
    onChange(updatedInternalValue)
  }

  useEffect(() => {
    if (inputRef.current) {
      const length = displayValue.length
      // @ts-ignore
      inputRef.current.setSelectionRange(length, length)
    }
  }, [displayValue])

  return (
    <Input
      className={`GInputText__Input GInputText__Input ${FULL_HISTORY_MASK_CLASS_NAME}`}
      id="ssn"
      type="text"
      value={displayValue}
      onChange={handleInputChange}
      ref={inputRef}
    />
  )
}

export default SSNInput
