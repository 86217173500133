import React, { FC, useEffect } from "react"
import { arrayUploadPhotoComponents } from "../../../../UploadPhoto/UploadPhotoService/datas/arrayUploadPhotoComponents"
import { ItemInterface } from "../../../../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import UploadDocumentActionBlock from "./UploadDocumentActionBlock"
import "./styles.scss"
import GText from "../../../../../atomicDesign/atoms/Text/Text"
import { SIZE } from "../../../../../atomicDesign/types/types"
import { COLORS } from "../../../../../atomicDesign/types/Colors"
import { BlockStepTypes } from "../../../../../../Constants/BlockStepTypes"

type DNDUploadDocumentContainerProps = {
  field: { backendValue: string },
  blocksStep: { type: string },
  nameDocument: string,
  documentKey: string,
  documentParentKey: string,
  showFileInputContainer: (name: string) => boolean,
  item: ItemInterface,
  formId: string,
}

export interface dynamicPropsInterface {
  name_field: string;
  name_document: string;
  isFinalSubmit: boolean;
  typeDocument: string;
  form_id: string;
}

export interface reviewPagePropsInterface {
  sub_name?: string;
  field_type?: string;
  field_name?: string;
  label?: string;
}

const DNDUploadDocumentContainer: FC<DNDUploadDocumentContainerProps> = ({
  item,
  showFileInputContainer,
  documentParentKey,
  documentKey,
  field,
  blocksStep,
  nameDocument,
  formId,
}) => {
  const submitDocumentParams = {
    name_field: documentKey,
    name_document: field?.backendValue,
    isFinalSubmit: false,
    typeDocument: blocksStep?.type,
    form_id: formId,
  }

  useEffect(() => {
    const typeDocument =
      blocksStep.type === BlockStepTypes.UPLOADING_FILE ? "upload_document_event" : "upload_additional_document_event"

    if (!sessionStorage.getItem(typeDocument)) {
      sessionStorage.setItem(typeDocument, "true")
      /* Event Processing Step */
    }
  })

  nameDocument =
    nameDocument?.includes("Front") || nameDocument?.includes("Passport")
      ? "Front of the document"
      : "Back of the document"

  return (
    <>
      {showFileInputContainer(documentParentKey) && (
        <div className={"step-upload__input-file-container"} key={documentKey}>
          <>
            <div className={"upload-document-side"}>
              <GText text={nameDocument} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS.SLATE_GRAY_500} />
            </div>
            <UploadDocumentActionBlock
              item={item}
              arrayComponents={arrayUploadPhotoComponents}
              dynamicProps={submitDocumentParams}
            />
          </>
        </div>
      )}
    </>
  )
}

export default DNDUploadDocumentContainer
