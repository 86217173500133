import React, { ChangeEvent } from "react"
import { Form, Input } from "antd"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../App"
import { formSelector } from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import InputMask from "react-input-mask"

type InputComponentProps = {
  field: FieldInterface,
  dependsField?: string,
}

const InputPhoneComponent: React.FunctionComponent<InputComponentProps> = ({ field }) => {
  const submitObject = useSelector<RootState, { submitObject: { [key: string]: string } }>(formSelector).submitObject

  const {
    label,
    name,
    dependencies,
    specialInput,
    iconsInput,
    phoneMobile,
    whyDoWeNeed,
    placeholder,
    maxLength,
    minLength,
  } = field
  const value = submitObject[name]
  const dispatch = useDispatch()

  const handlerOnChange = (e: ChangeEvent<HTMLInputElement>, name: string) =>
    dispatch(setValueCurrentField(name, e.target.value?.replaceAll("_", "")))

  const getPatternValidator = (value: string, messageError: string) =>
    value.trim().length > 1 ? Promise.resolve() : Promise.reject(messageError)

  return (
    <>
      <Form.Item
        label={label}
        className={`form-item__${specialInput}-container form-item__${iconsInput}-container ${phoneMobile}-container ${whyDoWeNeed}`}
      >
        <Form.Item
          name={name}
          initialValue={submitObject[name] ?? ""}
          preserve={false}
          rules={[{ validator: () => getPatternValidator(value, "Required") }]}
          required={true}
          className={"form-item__input"}
          hasFeedback
          dependencies={[dependencies]}
        >
          <div className={`form-item__${specialInput} form-item__${iconsInput} ${phoneMobile}`}>
            <InputMask mask={field.mask || "+99999999999999"} value={value} onChange={(e) => handlerOnChange(e, name)}>
              <Input
                className={`form-item__value-${specialInput}`}
                placeholder={placeholder || ""}
                maxLength={maxLength || 50}
                minLength={minLength || 0}
              />
            </InputMask>
          </div>
        </Form.Item>
      </Form.Item>
    </>
  )
}

export default InputPhoneComponent
