import OfferCouponRepository from "../../../persistence/session/OfferCouponRepository";
import ApplicationIdRepository from "../../../persistence/session/ApplicationIdRepository";
import {apiAxiosInstance} from "../../../services/form-service_instace";
export interface UserCouponResponse {
    error?: string;
    success?: {
        couponId: string;
        discount: number;
        available: boolean;
        hasChargeItems: boolean;
    };
}
export const BillingSagaHelper = {
    getOfferCouponFromRepository: async () : Promise<string | null> => {
        return OfferCouponRepository.getInstance().getValue();
    },
    getApplicationIdFromRepository: async () : Promise<string | null> => {
        return ApplicationIdRepository.getInstance().getValue();
    },
    getUserCoupon: async (couponId: string, token: string, applicationId: string): Promise<UserCouponResponse> => {
        const response = await apiAxiosInstance.formsBackInstancePost('api_gov/user/coupon', {
            coupon_id: couponId,
            client_token: token,
            application_id: applicationId,
        });
        return response.data;
    }
}