import React, {FC} from "react";
import "./GCancelSubscription.scss";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS} from "../../types/Colors";
import {Card} from 'antd';
import GButton, {BUTTON_SIZE, BUTTON_TYPE} from "../../molecules/Button/GButton";

interface GCancelSubscriptionCard {
    title: string,
    description: string,
    ctaButtonText: string
}

interface GCancelSubscription {
    title: string,
    subTitle: string,
    pauseSubscriptionCard: GCancelSubscriptionCard,
    cancelSubscriptionCard: GCancelSubscriptionCard,
}

const GCancelSubscription: FC<GCancelSubscription> = ({
                                                          title,
                                                          subTitle,
                                                          pauseSubscriptionCard,
                                                          cancelSubscriptionCard
                                                      }) => {

    const letterSpacingText: React.CSSProperties = {
        letterSpacing: "-0.1px"
    }

    return (
        <div className={"GCancelSubscription"}>
            <div className={"GCancelSubscription__Title"}>
                <GText text={title} size={SIZE.HEADING_LG}/>
            </div>
            <div className={"GCancelSubscription__Subtitle"}>
                <GText text={subTitle} size={SIZE.PARAGRAPH_REGULAR_18} color={COLORS.SLATE_GRAY_500}
                       style={letterSpacingText}/>
            </div>

            <div className={"GCancelSubscription__Card1"}>
                <div className={"GCancelSubscription__Card1__Title"}>
                    <GText text={pauseSubscriptionCard.title} size={SIZE.HEADING_XS}/>
                </div>
                <div className={"GCancelSubscription__Card1__Content"}>
                    <GText text={pauseSubscriptionCard.description} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.SLATE_GRAY_500}/>
                </div>
                <div className={"GCancelSubscription__Card1__Button"}>
                    <GButton text={pauseSubscriptionCard.ctaButtonText} size={BUTTON_SIZE.SMALL} textSize={SIZE.PARAGRAPH_BOLD_16}
                             showIcon={false}/>
                </div>
            </div>

            <Card className={"GCancelSubscription__Card2"}>
                <div className={"GCancelSubscription__Card1__Title"}>
                    <GText text={cancelSubscriptionCard.title} size={SIZE.HEADING_XS}/>
                </div>
                <div className={"GCancelSubscription__Card1__Content"}>
                    <GText text={cancelSubscriptionCard.description} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.SLATE_GRAY_500}/>
                </div>
                <div className={"GCancelSubscription__Card1__Button"}>
                    <GButton type={BUTTON_TYPE.DANGER} text={cancelSubscriptionCard.ctaButtonText} size={BUTTON_SIZE.SMALL}
                             textSize={SIZE.PARAGRAPH_BOLD_16} showIcon={false}/>
                </div>
            </Card>

        </div>
    )

}

export default GCancelSubscription