/* eslint-disable indent */
import React, {useEffect} from "react"
import { connect } from "react-redux"
import AdditionalPageComponent from "../../../Components/FormComponents/AdditionalPageComponent/AdditionalPageConponent"
import ListFormsStep from "../../../Components/FormComponents/ListFormsStep/ListFormsStep"
import { StepInterface } from "../../../Redux/InterfacesEntity/step.interface"
import UpsellContent from "./UpsellContent"
import ReviewPageContainer from "../../../Components/FormComponents/ReviewPage/ReviewPageContainer"
import {useUser} from "../../../../../dashboard-govplus-front/src/hooks/useUser";

type FormPageContentProps = {
  currentStep: StepInterface | any,
  submitObject: {} | any,
}

export const TYPES = {
  REVIEW_PAGE: "review_page",
}

const FormPageContent: React.FunctionComponent<FormPageContentProps> = ({ currentStep, submitObject }) => {
  const orgId = "e0j3u9ji"
  const pageId = 1
  const uniqSession = submitObject["unique-section-id"]
  const {getUserSubmittedData} = useUser()



  const srcFraudSight = `https://h.online-metrix.net/fp/tags.js?org_id=${orgId}&session_id=${uniqSession}&pageid=${pageId}`
  const styleFraudSight = {
    width: "100px",
    height: "100px",
    border: 0,
    Position: "absolute",
    top: "-5000px",
  }

  const { type = "", hideMain = "" } = currentStep !== null ? currentStep : {}
  const additionalPage = type?.indexOf("additional_page") !== -1

  const isUpsell = currentStep?.name === "upsell"

  const IsAllowShowReview = currentStep?.type === TYPES.REVIEW_PAGE && currentStep?.contentPage

  const blocksOnFormPage = Array.isArray(currentStep?.stepContent?.blocksOnFormPage) ? currentStep?.stepContent?.blocksOnFormPage : Object?.values(currentStep?.stepContent?.blocksOnFormPage ?? {})

  useEffect(getUserSubmittedData, [currentStep])

  return (
    <>
      {!additionalPage && !hideMain && isUpsell && <UpsellContent uniqSession={uniqSession} />}

      {!additionalPage && !hideMain && !isUpsell && (
        <ul
          className={`form-page__content form-page__content_${currentStep?.name || ""} ${
            currentStep && currentStep["form-name"]
          } ${currentStep && currentStep["name"]}`}
        >
          {blocksOnFormPage?.map?.((item: {} | any, index: number) => (
            <li className="form-page__blocks-with-forms" key={index}>
              <ListFormsStep blockOnFormPage={item.blockOnFormPage} />
            </li>
          ))}
        </ul>
      )}
      {additionalPage && <AdditionalPageComponent additionalPage={currentStep?.additionalPage} />}
      {IsAllowShowReview && <ReviewPageContainer {...{ item: currentStep?.contentPage }} />}
      {/* Begin ThreatMetric profiling tags below */}
      {(currentStep?.name === "get_new_social_security_card" ||
        currentStep?.type === "submit_payment" ||
        currentStep?.name === "upsell") && (
        <>
          <script type="text/javascript" src={`${srcFraudSight}`}></script>
          <noscript>
            <iframe title={`${uniqSession}`} style={styleFraudSight} src={`${srcFraudSight}`}></iframe>
          </noscript>
        </>
      )}
      {/* End profiling tags */}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    currentStep: state.step.currentStep,
    submitObject: state.form.submitObject,
  }
}

export default connect(mapStateToProps)(FormPageContent)
