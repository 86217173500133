import React, { useMemo } from "react"
import "./AnimatedStatusBar.scss"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS } from "../../types/Colors"
import { AnimatedStatusBarProps } from "./AnimatedStatusBar.props"
import { ShipmentStatuses } from "../../../../util/ProcessingTrackingHelper"

const GAnimatedStatusBar: React.FC<AnimatedStatusBarProps> = ({status, text}) => {
  const getNumGreenBars = (status: ShipmentStatuses) => {
    switch (status) {
      case ShipmentStatuses.DELIVERED:
        return 3;
      case ShipmentStatuses.RETURN_TO_SENDER:
        return 3;
      case ShipmentStatuses.OUT_FOR_DELIVERY:
        return 2;
      default:
        return 1;
    }
  };

  const numGreenBars = useMemo(() => getNumGreenBars(status), [status]);

  return <div className={'GAnimatedStatusBar'}>
    {[...Array(numGreenBars)].map((_, i) => (
        <div key={i} className={'GAnimatedStatusBar__Bar GAnimatedStatusBar__Bar--Green'}/>
    ))}

    {numGreenBars < 3 && (
        <div className={'GAnimatedStatusBar__Bar GAnimatedStatusBar__Bar--Animated'}/>
    )}

    {numGreenBars < 2 && (
        <div className={'GAnimatedStatusBar__Bar GAnimatedStatusBar__Bar--White'}/>
    )}

    <span className={'GAnimatedStatusBar__Text'}>
            <GText
                text={text}
                size={SIZE.PARAGRAPH_REGULAR_12}
                color={COLORS.GREEN_400}
            />
      </span>
  </div>
};

export default GAnimatedStatusBar
