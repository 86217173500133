import React, { FC } from "react"
import "./IDPConfirmModal.scss"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS_NEW } from "../../types/Colors"
import GButton from "../../molecules/Button/GButton"
import { IdentityProtectionConfirmModalProps } from "./IDPConfirmModal.props"
import GModal from "../GModal/GModal"

const IDPConfirmModal: FC<IdentityProtectionConfirmModalProps> = ({
  width = 560,
  headerText,
  topText,
  bottomText,
  click,
  open,
  onCancel,
}) => {
  return (
    <GModal width={width} open={open} onCancel={onCancel}>
      <div className={"GIdentityProtectionConfirmModal"}>
        <div className={"GIdentityProtectionConfirmModal__Header"}>
          <GText text={headerText} size={SIZE.PARAGRAPH_BOLD_32} color={COLORS_NEW.BLACK_1000} />
        </div>
        <div className={"GIdentityProtectionConfirmModal__Content"}>
          <GText text={topText} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
          <GText text={bottomText || ""} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
        </div>
        <div className={"GIdentityProtectionConfirmModal__Actions"}>
          <GButton text={"OK"} click={click} style={{ width: "100%" }} />
        </div>
      </div>
    </GModal>
  )
}

export default IDPConfirmModal
