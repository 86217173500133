import React from "react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"
import { mapCenter } from "../../../../../../Redux/InterfacesEntity/mapCenter.interface"

const containerStyle = {
  width: "100%",
  height: "100%",
}

const zoom = 15

type MapComponentProps = {
  mapCenter: mapCenter,
  googleMapsApiKey: string,
}

const MapComponent: React.FunctionComponent<MapComponentProps> = ({ mapCenter, googleMapsApiKey }) => {
  const googleMapsApiKey2 = "AIzaSyBZOSO1KYolAh_fJ4fAoY8fv94nhmGUw6o"
  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey || googleMapsApiKey2}>
      <GoogleMap mapContainerStyle={containerStyle} center={mapCenter} zoom={zoom}>
        <Marker position={mapCenter} />
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(MapComponent)
