import { Dispatch } from "redux"

const findNestBlock = (
  name_nest_block: string,
  arrayBlocksByOptions: [{}] | any,
  submitObject: {} | any,
  name_render_var: string
) => {
  return (
    ((arrayBlocksByOptions &&
      arrayBlocksByOptions.find((item: {} | any) => item.name === submitObject[name_render_var])) ||
      {})[name_nest_block] || {}
  ) // find block by name
}

const concatArray = (
  name_nest_block: string,
  oldFields: [] | any,
  arrayBlocksByOptions: [{}] | any,
  submitObject: {} | any,
  name_render_var: string
) => {
  const nestBlock = findNestBlock(name_nest_block, arrayBlocksByOptions, submitObject, name_render_var)
  const arrayForConcat = (nestBlock && nestBlock.fields) || []
  if (nestBlock && nestBlock.name_nest_block) {
    const arr: [] | any = concatArray(
      nestBlock.name_nest_block, // name nest block
      (oldFields ?? []).concat(arrayForConcat),
      arrayBlocksByOptions, // array blocks from store
      submitObject,
      nestBlock.name_render_var // var for render different nest block
    )
    return arr
  } else {
    return (oldFields ?? []).concat(arrayForConcat)
  }
}

const getExcludeType = (nameType: string) => {
  return nameType !== "showOnForListRadio"
}

export const checkObserverOn = (blockObject: {} | any, observers: {} | any) => {
  return !(
    blockObject &&
    blockObject.type === "observer" &&
    observers &&
    (!observers[blockObject.name] ||
      (getExcludeType(observers[blockObject.name].type) &&
        observers[blockObject.name].value === blockObject.visibility))
  )
}

export const getArrayDynamicBlock = (blockObject: {} | any, dynamicBlocks: [{}] | any, submitObject: {} | any) => {
  if (blockObject && blockObject.name_nest_block) {
    // console.log("blockObject.fields",blockObject)
    return concatArray(
      blockObject.name_nest_block, // name nest block
      blockObject.fields,
      dynamicBlocks, // array blocks from store
      submitObject,
      blockObject.name_render_var // var for render different nest block
    )
  } else {
    return blockObject.fields
  }
}

export const getArrayBlockSteps = (blockSteps: any[], upsellProcessingInformation: {} | any, upsellsInformation: any[], formId: string) => {
  const resultBlockSteps = blockSteps?.slice()
  upsellsInformation?.forEach((upsell) => {
    const upsellInformation = upsell.hasOwnProperty('filterFormIds') ?
      upsell['filterFormIds'].includes(formId) && upsellProcessingInformation?.[upsell.status] :
      upsellProcessingInformation?.[upsell.status]

    if(upsellInformation){
      const blockIndex = resultBlockSteps?.findIndex((block: any) => block.status === upsellInformation.application_status)
      blockIndex > -1 && resultBlockSteps?.splice(blockIndex, 0, upsell)
    }
  })

  return resultBlockSteps
}

export const getLabelDynamicBlock = (blockObject: any, dynamicBlocks: [{}] | any, submitObject: {} | any) => {
  if (blockObject?.name_nest_block && !blockObject.label) {
    return findNestBlock(blockObject.name_nest_block, dynamicBlocks, submitObject, blockObject.name_render_var).label
  } else {
    return blockObject.label
  }
}

interface clearChildrenHiddenBlockInterface {
  fields: [] | undefined;
  setValueCurrentField: (name: string, value: string) => any;
  dispatch: Dispatch;
  submitObject: { [key: string]: string };
}

export const clearChildrenHiddenBlock = ({
  fields,
  setValueCurrentField,
  dispatch,
  submitObject,
}: clearChildrenHiddenBlockInterface) => {
  if (fields?.length) {
    fields.forEach(({ name }) => {
      if (submitObject[name]) {
        dispatch(setValueCurrentField(name, ""))
      }
      if (submitObject[`${name}__full_name`]) {
        dispatch(setValueCurrentField(`${name}__full_name`, ""))
      }
    })
  }
}
