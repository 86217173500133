import React, { FC } from "react"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GAuthHeader from "../../molecules/AuthHeader/AuthHeader"
import "./ResetNotification.scss"
import GAuthFooter from "../../molecules/AuthFooter/AuthFooter"

type GResetNotificationProps = {
  email: string,
}

const TITLE = "Check your email"

const GResetNotification: FC<GResetNotificationProps> = ({ email }) => {
  const DESCRIPTION = `We just sent you an email at ${email} so you can reset your password.`
  return (
    <div className={`GResetNotification`}>
      <GAuthHeader title={TITLE} description={DESCRIPTION} />
      <GAuthFooter firstLinkText={`Sign in`} firstLinkShowIcon={true} firstLinkUrl={`/signin`} />
    </div>
  )
}
export default GResetNotification
