import React, {FC, useState} from "react"
import "./DropDownMenu.scss"
import GText from "../../atoms/Text/Text"
import { SIZE, WEIGHT } from "../../types/types"
import {COLORS, COLORS_NEW} from "../../types/Colors"
import {Badge} from "antd"
import { Popover, Avatar } from "antd"
import GDropdownMenuContent, { IUserData } from "./DropDownMenuContent/DropDownMenuContent"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

type GDropDownMenuProps = {
  userMenuItems: any,
  userSignOut: any,
  checkProductsForDuplicate: any,
  email: string,
  name: string,
  lastName: string,
  userId: string,
  outerId: string,
}

const GDropDownMenu: FC<GDropDownMenuProps> = ({
  userMenuItems,
  userSignOut,
  checkProductsForDuplicate,
  email,
  name,
  lastName,
  userId,
  outerId,
}) => {
  const userData: IUserData = {
    email: email,
    name: name,
    lastName: lastName,
    userId: userId,
  }

  const [popOverOpen, setPopOverOpen] = useState(false)

  const togglePopOver = () => {
    setPopOverOpen(!popOverOpen)
  }

  const getUserInitials = () => userData.name[0].toUpperCase() + userData.lastName[0].toUpperCase()

  const breakPoint = useBreakpoint();

  return (
    <Popover
      className={`GDropDownMenu`}
      showArrow={false}
      placement="bottomRight"
      trigger={"click"}
      onOpenChange={togglePopOver}
      open={popOverOpen}
      content={
        <GDropdownMenuContent
          userMenuItems={userMenuItems}
          userSignOut={userSignOut}
          checkProductsForDuplicate={checkProductsForDuplicate}
          userData={userData}
          outerId={outerId}
          hideMenu={() => setPopOverOpen(false)}
        />
      }
    >
      <Badge dot color={"green"} offset={[-5, 5]}>
        <Avatar className={`GDropDownMenu__Avatar`} style={{
          border: "1px solid " + COLORS_NEW.ROYAL_BLUE_100,
          borderColor: popOverOpen ? COLORS_NEW.ROYAL_BLUE_700 : COLORS_NEW.ROYAL_BLUE_100,
        }}>
          <GText text={getUserInitials()} size={!breakPoint.xs ? SIZE.PARAGRAPH_BOLD_14 : SIZE.PARAGRAPH_BOLD_12} color={COLORS_NEW.ROYAL_BLUE_700} />
        </Avatar>
      </Badge>
    </Popover>
  )
}
export default GDropDownMenu
