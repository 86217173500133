import React from "react"

interface ISvgMagic {
  width: number;
  height: number;
  color: string;
}

export const SvgMagic = ({ width, height, color }: ISvgMagic) => {
  return (
    <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.341 7.10606C10.3344 5.95448 8.5975 4.21754 7.44592 0.210938C6.29434 4.21754 4.55738 5.95448 0.550781 7.10607C4.55738 8.25765 6.29434 9.99458 7.44592 14.0012C8.5975 9.99458 10.3344 8.25764 14.341 7.10606Z"
        fill={color}
      />
      <path
        d="M14.341 7.10606C10.3344 5.95448 8.5975 4.21754 7.44592 0.210938C6.29434 4.21754 4.55738 5.95448 0.550781 7.10607C4.55738 8.25765 6.29434 9.99458 7.44592 14.0012C8.5975 9.99458 10.3344 8.25764 14.341 7.10606Z"
        stroke={color}
      />
      <path
        d="M18.5891 17.1403C15.6431 16.3059 14.3659 15.0472 13.5192 12.1438C12.6724 15.0472 11.3953 16.3059 8.44922 17.1403C11.3953 17.9748 12.6724 19.2335 13.5192 22.1369C14.3659 19.2335 15.6431 17.9748 18.5891 17.1403Z"
        fill={color}
      />
      <path
        d="M18.5891 17.1403C15.6431 16.3059 14.3659 15.0472 13.5192 12.1438C12.6724 15.0472 11.3953 16.3059 8.44922 17.1403C11.3953 17.9748 12.6724 19.2335 13.5192 22.1369C14.3659 19.2335 15.6431 17.9748 18.5891 17.1403Z"
        stroke={color}
      />
      <path
        d="M22.4505 10.0651C20.565 9.53106 19.7476 8.7255 19.2057 6.86733C18.6638 8.7255 17.8464 9.53106 15.9609 10.0651C17.8464 10.5992 18.6638 11.4048 19.2057 13.2629C19.7476 11.4048 20.565 10.5992 22.4505 10.0651Z"
        fill={color}
      />
      <path
        d="M22.4505 10.0651C20.565 9.53106 19.7476 8.7255 19.2057 6.86733C18.6638 8.7255 17.8464 9.53106 15.9609 10.0651C17.8464 10.5992 18.6638 11.4048 19.2057 13.2629C19.7476 11.4048 20.565 10.5992 22.4505 10.0651Z"
        stroke={color}
      />
    </svg>
  )
}
