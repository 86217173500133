import { ProductsIds } from "../../util/ProductsIds"
import { ServicesIds } from "../../util/ServicesIds"
import {ServicesNames} from "../../util/ServicesNames"
import {SolutionNames} from "../../util/SolutionNames";
import OuterIdRepository from "../../persistence/session/OuterIdRepository";
import ToApplyFormIdRepository from "../../persistence/session/ToApplyFormIdRepository";
import ToApplyServiceIdRepository from "../../persistence/session/ToApplyServiceIdRepository";
import FormIdRepository from "../../persistence/session/FormIdRepository";
import ApplicationIdRepository from "../../persistence/session/ApplicationIdRepository";
import ServiceRepository from "../../persistence/session/ServiceRepository";
import CategoryRepository from "../../persistence/session/CategoryRepository";
import FormNameForEventRepository from "../../persistence/session/FormNameForEventRepository";
import ChangedFormTypeRepository from "../../persistence/session/ChangedFormTypeRepository";
export const getPropertiesForEvents = () => {
  const formType = sessionStorage.getItem("formNameForEvent") ?? ""
  const form_id = FormIdRepository.getInstance().getValue() ?? ""
  const userFlattenData = JSON.parse(sessionStorage.getItem("userFlattenData") ?? "{}") ?? { form_id: "" }
  const userId = userFlattenData["userId"]
  const webFlowServicesData = JSON.parse(localStorage.getItem("webFlowServicesData") ?? "{}") ?? {}
  const product = webFlowServicesData?.[form_id ?? ""]
  const solution = product?.["formId"]
  const service = product?.text ?? ServiceRepository.getInstance().getValue() ?? ""
  const solutionName = product?.title ?? sessionStorage.getItem("Solution") ?? ""
  const subCategory = formType === "EIN" ? sessionStorage.getItem("SubCategory") ?? "" : ""
  const applicationId = ApplicationIdRepository.getInstance().getValue() ?? ""
  const CTA = product?.CTA
  const webFlowData = JSON.parse(localStorage.getItem("webFlowData") ?? "{}") ?? {}
  const retargetingLink = localStorage.getItem(`state_${form_id}`) ?? ""
  const outer_id = OuterIdRepository.getInstance().getValue() ?? "outer_id"

  let analyticsAnonymousId = null;
  let analyticsUserId = null;
  // @ts-ignore
  if (typeof window?.analytics?.user !== "undefined") {
    // @ts-ignore
    analyticsAnonymousId = window?.analytics?.user()?.anonymousId() ?? null;
    // @ts-ignore
    analyticsUserId = window?.analytics?.user()?.id() ?? null;
  }

  return {
    formType,
    solution,
    service,
    subCategory,
    applicationId,
    form_id,
    userId,
    CTA,
    solutionName,
    webFlowData,
    retargetingLink,
    outer_id,
    analyticsAnonymousId,
    analyticsUserId
  }
}

export const handleFormTypeSwitch = (newFormType: string) => {
  switch (newFormType) {
    case 'passport-new' : {
      FormIdRepository.getInstance().setValue('passport-new')
      ServiceRepository.getInstance().setValue(ServicesNames.PassportFirst)
      CategoryRepository.getInstance().setValue('passport-new')
      FormNameForEventRepository.getInstance().setValue('passport_form')
      ChangedFormTypeRepository.getInstance().setValue('passport-new')
    }
  }
}

export const getServiceAndSolutionNameForFormsHeaders = () => {
  let service;
  let solutionName;
  let subCategory = ""
  const applicationId = ApplicationIdRepository.getInstance().getValue() ?? "";
  if (ToApplyFormIdRepository.getInstance().getValue() == null) {
    const formType = sessionStorage.getItem("formNameForEvent") ?? "";
    const form_id = FormIdRepository.getInstance().getValue() ?? "";
    const webFlowServicesData = JSON.parse(localStorage.getItem("webFlowServicesData") ?? "{}") ?? {};
    const product = webFlowServicesData?.[form_id ?? ""];
    service = product?.text ?? ServiceRepository.getInstance().getValue() ?? "";
    solutionName = product?.title ?? sessionStorage.getItem("Solution") ?? "";
    subCategory = formType === "EIN" ? sessionStorage.getItem("SubCategory") ?? "" : "";
  } else {
    service = getServiceNameById(ToApplyServiceIdRepository.getInstance().getValue() ?? "");
    solutionName = getProductNameById(ToApplyFormIdRepository.getInstance().getValue() ?? "");
  }

  return {
    service,
    solutionName,
    subCategory,
    applicationId
  }
}

export const getServiceNameById = (service: String) => {
  let serviceName;
  switch (service) {
    case ServicesIds.FirstBirthCertificate:
      serviceName = ServicesNames.FirstBirthCertificate
    break
    case ServicesIds.BirthCertificateCertifiedCopy:
      serviceName = ServicesNames.BirthCertificateCertifiedCopy
    break
    case ServicesIds.FirstSocialSecurityCard:
      serviceName = ServicesNames.FirstSocialSecurityCard
    break
    case ServicesIds.SocialSecurityCardUpdates:
      serviceName = ServicesNames.SocialSecurityCardUpdates
      break
    case ServicesIds.SocialSecurityCardNameChange:
      serviceName = ServicesNames.SocialSecurityCardNameChange
      break
    case ServicesIds.SocialSecurityCardReplacement:
      serviceName = ServicesNames.SocialSecurityCardReplacement
      break
    case ServicesIds.NewEin:
      serviceName = ServicesNames.NewEin
      break
    case ServicesIds.IRSEinConfirmationLetter:
      serviceName = ServicesNames.IRSEinConfirmationLetter
      break
    case ServicesIds.EinRetrieval:
      serviceName = ServicesNames.EinRetrieval
      break
    case ServicesIds.EinUpdates:
      serviceName = ServicesNames.EinsUpdate
      break
    case ServicesIds.BusinessAddressChange:
      serviceName = ServicesNames.BusinessAddressChange
      break
    case ServicesIds.IndividualAddressChange:
      serviceName = ServicesNames.IndividualAddressChange
      break
    case ServicesIds.NewTSAPreCheck:
      serviceName = ServicesNames.NewTSAPreCheck
      break
    case ServicesIds.TSAprecheckRenewal:
      serviceName = ServicesNames.TsaPreCheckRenewal
      break
    case ServicesIds.PassportRenewal:
      serviceName = ServicesNames.PassportRenewal
      break
    case ServicesIds.PassportStolen:
      serviceName = ServicesNames.PassportStolen
    break
    case ServicesIds.PassportDamage:
      serviceName = ServicesNames.PassportDamage
      break
    case ServicesIds.PassportLost:
      serviceName = ServicesNames.PassportLost
      break
    default:
      serviceName = "Service"
  }

  return serviceName
}

const getProductNameById = (product: String) => {
  let productName;
  switch (product) {
    case ProductsIds.PassportId:
      productName = SolutionNames.PAS
      break
    case ProductsIds.BirthCertificateId:
      productName = SolutionNames.CER
      break
    case ProductsIds.EinId:
      productName = SolutionNames.EIN
    break
    case ProductsIds.SSNId:
      productName = SolutionNames.SSN
    break
    case ProductsIds.ChangeAddressId:
      productName = SolutionNames.COA
    break
    case ProductsIds.TsaId:
      productName = SolutionNames.TSA
    break
    default:
      productName = SolutionNames.UNDEFINED
  }

  return productName
}
