import React from "react"

export const SvgIconErrorCircle = ({ width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 21.9961C18.0228 21.9961 22.5 17.5189 22.5 11.9961C22.5 6.47325 18.0228 1.99609 12.5 1.99609C6.97715 1.99609 2.5 6.47325 2.5 11.9961C2.5 17.5189 6.97715 21.9961 12.5 21.9961Z"
        stroke="#D1172B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.5 7.99609V11.9961" stroke="#D1172B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5 16.0039H12.51" stroke="#D1172B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
