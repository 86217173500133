import {userService} from "../../api/api";
import {all, fork, put, takeEvery} from "redux-saga/effects";
import {CHANGE_PASSWORD} from "../../constants/ActionTypes";
import {changePasswordShowMessage} from "../actions/profileSettings";
import {notifyError} from "@dash/gov-plus__front-end__form/src/util/helper";


function* changePasswordSaga(payload){
  const {changePasswordData} = payload;
  try {
    const response = yield userService.changeUserPassword(
      changePasswordData.currentPassword, changePasswordData.newPassword
    );
    if(response.data.messages === "change-password.PASSWORD_SUCCESSFULLY_UPDATED"){
      yield put(changePasswordShowMessage(response.data.messages));
    }

  }catch (error){
    const {response} = error;
    yield put(changePasswordShowMessage(response.data.messages));
    notifyError(error);
  }
}

function* changePasswordFork(){
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
}

export default function* rootSaga(){
  yield all([
    fork(changePasswordFork),
  ]);
};
