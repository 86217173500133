/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react"
import "./EmailExposuresPage.scss"
import { EmailExposuresPageProps } from "./EmailExposuresPage.props"
import { Collapse } from "antd"
import {
  AlertBackgroundTypes,
  checkDataLength,
  formatDateToCustomString,
  parseJSON,
} from "../../../util/IdentityProtectionHelper"
import GCard from "../../../Components/atomicDesign/templates/GCard/GCard"
import GAlertBackgroundMessage from "../../../Components/atomicDesign/templates/AlertBackgroundMessage/AlertBackgroundMessage"
import CollapseTopPart from "../../../Components/atomicDesign/templates/CollapseTop/CollapseTop"
import CollapseBottomPart from "../../../Components/atomicDesign/templates/CollapseBottom/CollapseBottom"
import { useIdentityProtection } from "../../../../../dashboard-govplus-front/src/hooks/useIdentityProtection"
import GSpinner from "../../../Components/atomicDesign/atoms/Spinner/Spinner"
import GText from "../../../Components/atomicDesign/atoms/Text/Text"
import { SIZE } from "../../../Components/atomicDesign/types/types"
import { COLORS_NEW } from "../../../Components/atomicDesign/types/Colors"
import useIsMobile from "../../../../../dashboard-govplus-front/src/hooks/useIsMobile"

const EmailExposureCollapse: FC<EmailExposuresPageProps> = ({ emailBreaches }) => {
  const [expandedKey, setExpandedKey] = useState("")
  const isMobile = useIsMobile(768)

  const handlePanelClick = (key: any) => {
    setExpandedKey(key === expandedKey ? "" : key)
  }

  return (
    <Collapse accordion onChange={handlePanelClick}>
      {emailBreaches?.map((item: any) => (
        <Collapse.Panel
          key={item.ID}
          header={
            <CollapseTopPart
              bold={true}
              showIcon={true}
              centerMessage={item.Title}
              topLeftMessage={item.Name}
              bottomLeftMessage={item.Domain}
              timestamp={formatDateToCustomString(item.BreachDate, isMobile)}
            />
          }
        >
          <CollapseBottomPart key={item.ID} exposedData={item.DataClasses} description={item.Description} />
        </Collapse.Panel>
      ))}
    </Collapse>
  )
}

const EmailExposuresPage: FC<EmailExposuresPageProps> = () => {
  const { emailBreaches, getEmailBreaches, loadingEmailBreaches, errorEmailBreaches } = useIdentityProtection()

  useEffect(() => {
    getEmailBreaches()
  }, [])

  const emailBreachPageContent = () => {
    return (
      <div className={loadingEmailBreaches ? "EmailExposuresPage__Loading" : "EmailExposuresPage"}>
        {checkDataLength(parseJSON(emailBreaches)) ? (
          <EmailExposureCollapse emailBreaches={parseJSON(emailBreaches)} />
        ) : (
          <GAlertBackgroundMessage
            status={AlertBackgroundTypes.SUCCESS}
            message={"Great news! Your email address has not been found in any data breaches at this time."}
          />
        )}
      </div>
    )
  }

  return (
    <div className={loadingEmailBreaches ? "EmailExposuresPage__Loading" : "EmailExposuresPage"}>
      <div className={"EmailExposuresPage__Header"}>
        <GText text={"My email exposures"} size={SIZE.PARAGRAPH_BOLD_40} color={COLORS_NEW.BLACK_1000} />
      </div>
      <GCard title={"My breaches"}>
        {loadingEmailBreaches && (
          <div className={"EmailExposuresPage__Overlay"}>
            <div className={"EmailExposuresPage__Spinner"}>
              <GSpinner />
            </div>
          </div>
        )}
        {errorEmailBreaches && (
          <GAlertBackgroundMessage
            status={AlertBackgroundTypes.ERROR}
            message={"Error fetching user data. Please try again later."}
          />
        )}
        {!loadingEmailBreaches && !errorEmailBreaches && emailBreachPageContent()}
      </GCard>
    </div>
  )
}

export default EmailExposuresPage
