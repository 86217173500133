import React, { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { Form } from "antd"
import { setCurrentBlock } from "../../../../../Redux/store/step/step.actions"
import ListBlocksFormWithFields from "../../../ListBlocksFormWithFields/ListBlocksFormWithFields"
import GButton from "../../../../atomicDesign/molecules/Button/GButton"
import "./GovernmentFeesFormFields.scss"
import { SIZE } from "../../../../atomicDesign/types/types"
import {PaymentRequestTokenEvent} from "@stripe/stripe-js";

type GovernmentFeesFormFieldsProps = {
  blocksStep: { statusBlockStep: string, button: string, status: string, content: any, name: number },
  setStripeElements: any,
  index: number,
  loadingButtonState: string,
  onGatewayPaymentStartedWithToken?: (event: PaymentRequestTokenEvent, userCoupon: any) => Promise<void>
}

const GovernmentFeesFormFields: React.FunctionComponent<GovernmentFeesFormFieldsProps> = ({
  blocksStep,
  setStripeElements,
  index,
  loadingButtonState,
  onGatewayPaymentStartedWithToken
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    /* Event Processing Step */
  }, [])

  useEffect(() => {
    dispatch(setCurrentBlock(blocksStep))
  }, [])

  return (
    <>
      <div>
        <div className={`additional-container-content-${blocksStep.content.name}`}>
          <ListBlocksFormWithFields
            arrayBlocksFields={blocksStep.content.blocksWithFieldsOrNestBlocks}
            setStripeElements={setStripeElements} onGatewayPaymentStartedWithToken={onGatewayPaymentStartedWithToken}
          />
          <Form.Item>
            <div className={"pay-btn"}>
              <GButton
                textSize={SIZE.PARAGRAPH_BOLD_16}
                text={blocksStep.content.button}
                showIcon={false}
                isSubmit={true}
                loading={loadingButtonState === "loading"}
                disabled={loadingButtonState === "loading"}
              />
            </div>
          </Form.Item>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  loadingButtonState: state.loading.loadingButtonState,
})

export default connect(mapStateToProps)(GovernmentFeesFormFields)
