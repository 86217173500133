import {
  GET_FOOTER_DATA,
  GET_FOOTER_DATA_SUCCESS,
  GET_RETARGETING_DATA, GET_RETARGETING_DATA_SUCCESS,
  SET_GENERATED_RETARGETING_LINK,
} from "../../constants/ActionTypes.js";

export const getFooterData = () => {
  return {
    type: GET_FOOTER_DATA,
  }
};

export const getFooterDataSuccess = (payload) => {
  return {
    type: GET_FOOTER_DATA_SUCCESS,
    payload
  }
};

export const setRetargetingLink = (form_id) => {
  return {
    type: SET_GENERATED_RETARGETING_LINK,
    form_id
  }
};

export const getRetargetingLinkData = (retargetingToken, outerId= undefined) => {
  return {
    type: GET_RETARGETING_DATA,
    retargetingToken,
    outerId
  }
};


export const getRetargetingLinkDataSuccess = (payload) => {
  return {
    type: GET_RETARGETING_DATA_SUCCESS,
    payload
  }
};

