import React from 'react';
import {SvgType} from "../SvgType";

export const SvgIconCheck = ({width = 21, height = 20, color = "fff"}: SvgType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.1668 5L8.00016 14.1667L3.8335 10" stroke={color} strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>

  );
}
