/* eslint-disable react-redux/useSelector-prefer-selectors */
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import "./MyIdentityPage.scss"
import { IdentityProtectionText } from "../../../shared/text/Text"
import { SIZE } from "../../../Components/atomicDesign/types/types"
import { MyIdentityPageProps } from "./MyIdentityPage.props"
import GText from "../../../Components/atomicDesign/atoms/Text/Text"
import AddPersonalInfoCard from "../../../Components/atomicDesign/templates/AddPersonalInfoCard/AddPersonalInfoCard"
import IdentityInsuranceCard from "../../../Components/atomicDesign/templates/IdentityInsuranceCard/IdentityInsuranceCard"
import { COLORS_NEW } from "../../../Components/atomicDesign/types/Colors"
import {
  BUNDLE_NAMES,
  BUNDLE_TYPES,
  IdentityProtectionType,
  RecordType,
  ScrollTargets,
  bottomConfirmModalText,
  headerConfirmModalText,
  identityRestorationModalText,
  identityTheftInsuranceModalText,
  isAlert,
  mockTeaserRecords,
  parseJSON,
  sortByDate,
  topConfirmModalText,
} from "../../../util/IdentityProtectionHelper"
import { useHelperSubscription } from "../../../../../dashboard-govplus-front/src/hooks/useHelperSubscription"
import MissingFieldsModal from "../../../Components/atomicDesign/templates/MissingFieldsModal/MissingFieldsModal"
import { useDispatch, useSelector } from "react-redux"
import AuthUserBearerTokenRepository from "../../../persistence/session/AuthUserBearerTokenRepository"
import { useSubscription } from "../../../../../dashboard-govplus-front/src/hooks/useSubscription"
import {
  updateCustomer,
  patchUserBundle,
  getAlerts,
  getEmailBreaches,
  getIdentities,
  getRecords,
  checkVerificationStatus,
} from "../../../../../dashboard-govplus-front/src/appRedux/actions"
import { useUser } from "../../../../../dashboard-govplus-front/src/hooks/useUser"
import AddRecordModal from "../../../Components/atomicDesign/templates/AddRecordModal/AddRecordModal"
import AgreementModalBlock from "../../../Components/atomicDesign/templates/AgreementModalBlock/AgreementModalBlock"
import AlertMessagesCard from "../../../Components/atomicDesign/templates/AlertMessagesCard/AlertMessagesCard"
import BenefitsModal from "../../../Components/atomicDesign/templates/BenefitsModal/BenefitsModal"
import EmailBreachCard from "../../../Components/atomicDesign/templates/EmailBreachCard/EmailBreachCard"
import IDPConfirmModal from "../../../Components/atomicDesign/templates/IDPConfirmModal/IDPConfirmModal"
import CreditMonitoringCard from "../../../Components/atomicDesign/templates/CreditMonitoringCard/CreditMonitoringCard"
import useIsMobile from "../../../../../dashboard-govplus-front/src/hooks/useIsMobile"
// @ts-ignore
import usePrevValue from "../../../../../dashboard-govplus-front/src/hooks/usePrevValue"
// @ts-ignore
import useScrollToTarget from "../../../../../dashboard-govplus-front/src/hooks/useScrollToTarget"

const MyIdentityPage: FC<MyIdentityPageProps> = ({ onGetSubscription }) => {
  const dispatch = useDispatch()
  const { userFlattenData, getUserFlattenData } = useUser()
  const { triggerIdentityProtectionMonitoringStarted, triggerIdentityProtectionMonitoringCompleted } =
    useHelperSubscription()
  const isMobile = useIsMobile(768)

  const {
    missingFields,
    customerUpdated,
    customerUpdateError,
    identities,
    alerts,
    emailBreaches,
    records,
    identitiesLoader,
    identitiesError,
    creditScoresLoader,
    creditScoresError,
    alertsLoader,
    alertsError,
    emailBreachesLoader,
    emailBreachError,
    scrollTarget,
    // @ts-ignore
  } = useSelector((state) => state.identityProtectionData)

  const { subscriptionIsActive, subscriptionIsCancelled } = useSubscription()

  const client_token = AuthUserBearerTokenRepository.getInstance().getValue()
  const isTeaserRecordsStatus = parseJSON(identities)?.bundleId === BUNDLE_TYPES.INITIAL
  const isTeaserCreditScoreStatus = !identities?.isAuthenticated
  const locationData = userFlattenData?.data?.personal?.location?.permanent || {}
  const subscriptionIsValid = usePrevValue(subscriptionIsActive || subscriptionIsCancelled)
  const firstName = userFlattenData?.data?.personal?.identity?.firstName || ""
  const lastName = userFlattenData?.data?.personal?.identity?.lastName || ""
  const oldEmail = userFlattenData?.data?.personal?.communication?.email || ""

  const [recordType, setRecordType] = useState("")
  const [openAddRecordModal, setOpenAddRecordModal] = useState(false)
  const [openIdentityRestorationModal, setOpenIdentityRestorationModal] = useState(false)
  const [openIdentityTheftInsuranceModal, setOpenIdentityTheftInsuranceModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openMissingFieldsModal, setOpenMissingFieldsModal] = useState(false)
  const [bundleId, setBundleId] = useState(localStorage.getItem("bundleId") || "")
  const [packageName, setPackageName] = useState(localStorage.getItem("packageName") || "")
  const [monitoringCompleted, setMonitoringCompleted] = useState(false)
  const [infoCardIsClicked, setInfoCardIsClicked] = useState(
    localStorage.getItem("infoCardIsClicked") === "true" || false
  )

  const creditMonitoringRef = useRef<HTMLDivElement>(null)
  const personalInfoRef = useRef<HTMLDivElement>(null)
  const identityInsuranceRef = useRef<HTMLDivElement>(null)

  const filteredAlerts = parseJSON(alerts.getAllAlertsResult)?.filter(
    (alert: { CanRedAlert: string }) => alert.CanRedAlert === "1"
  )

  const clearLocalStorageData = () => {
    localStorage.removeItem("infoCardIsClicked")
    localStorage.removeItem("bundleId")
    localStorage.removeItem("packageName")
  }

  const resetState = () => {
    setBundleId("")
    setPackageName("")
    setInfoCardIsClicked(false)
  }

  const handleCloseModal = useCallback(() => {
    setOpenMissingFieldsModal(false)
    resetState()
    clearLocalStorageData()
  }, [])

  const handleClickEntryCard = async () => {
    setBundleId(BUNDLE_TYPES.INITIAL)
    setPackageName(BUNDLE_NAMES.INITIAL)
    localStorage.setItem("bundleId", BUNDLE_TYPES.INITIAL)
    localStorage.setItem("packageName", BUNDLE_NAMES.INITIAL)
    onGetSubscription?.()
  }

  const handleClickInfoCard = async (bundleId: string, packageName: string) => {
    if (!subscriptionIsValid) {
      onGetSubscription?.()
    }

    setBundleId(bundleId)
    setPackageName(packageName)
    setInfoCardIsClicked(true)
    localStorage.setItem("bundleId", bundleId)
    localStorage.setItem("infoCardIsClicked", "true")
    localStorage.setItem("packageName", packageName)
  }

  // method for missing fields modal
  const handleUpdateUser = async (data: any) => {
    const submittedData = {
      missingFieldsData: {
        // data from missing fields modal inputs that is allowed by backend
        ...data,
        oldEmail,
        client_token,
      },
      bundleId,
    }

    dispatch(updateCustomer(submittedData))

    if (!monitoringCompleted) {
      triggerIdentityProtectionMonitoringCompleted(bundleId, packageName)
      setMonitoringCompleted(true)
    }
  }

  const getAlertsCardData = useMemo(() => {
    const alerts = filteredAlerts?.filter((alert: any) => isAlert(alert))
    return sortByDate(alerts)
  }, [filteredAlerts])

  const getSubHeaderText = useMemo(() => {
    if (subscriptionIsValid) {
      return isMobile ? IdentityProtectionText.IDPLandingSubheaderMobile : IdentityProtectionText.IDPLandingSubheader
    }
    return isMobile ? IdentityProtectionText.TeaserHeaderMobile : IdentityProtectionText.TeaserHeader
  }, [isMobile, subscriptionIsValid])

  useEffect(() => {
    if (missingFields?.length && infoCardIsClicked) {
      setOpenMissingFieldsModal(true)
      triggerIdentityProtectionMonitoringStarted(bundleId, packageName)
      setInfoCardIsClicked(false)
      localStorage.removeItem("infoCardIsClicked")
    }
  }, [bundleId, infoCardIsClicked, missingFields?.length, packageName])

  useEffect(() => {
    // close modal after successful update or error
    if (customerUpdated || customerUpdateError) {
      handleCloseModal()
    }
  }, [customerUpdateError, customerUpdated, handleCloseModal])

  useEffect(() => {
    // re-render page if user successfully updated his credit monitoring status
    if (identities.isAuthenticated) {
      dispatch(getIdentities())
    }
  }, [dispatch, identities.isAuthenticated])

  useEffect(() => {
    if (recordType && recordType !== RecordType.ADDRESS) {
      dispatch(getRecords(recordType))
    }
  }, [dispatch, recordType])

  useEffect(() => {
    getUserFlattenData()
  }, [customerUpdated])

  const onLoad = useCallback(() => {
    dispatch(getIdentities())
    dispatch(getAlerts(IdentityProtectionType.ALL))
    dispatch(getEmailBreaches())
  }, [dispatch])

  useEffect(() => {
    if (subscriptionIsValid) {
      onLoad()
    }
  }, [onLoad, subscriptionIsValid])

  useEffect(() => {
    if (subscriptionIsValid && (bundleId === BUNDLE_TYPES.MEDIUM || bundleId === BUNDLE_TYPES.ADVANCED)) {
      dispatch(patchUserBundle({ bundleId }))
      localStorage.removeItem("bundleId")
    }
  }, [bundleId, dispatch, subscriptionIsValid])

  useEffect(() => {
    if (identities.bundleId === BUNDLE_TYPES.ADVANCED && !identities.isAuthenticated) {
      dispatch(checkVerificationStatus())
    }
  }, [dispatch, identities.bundleId, identities.isAuthenticated])

  useEffect(() => {
    return () => {
      resetState()
    }
  }, [])

  const refs = {
    [ScrollTargets.identityInsurance]: identityInsuranceRef,
    [ScrollTargets.creditMonitoring]: creditMonitoringRef,
    [ScrollTargets.personalInfo]: personalInfoRef,
  }

  useScrollToTarget(scrollTarget, refs)

  return (
    <div className="MyIdentityPage">
      <div className="MyIdentityPage__Header">
        <GText
          text={IdentityProtectionText.MyIdentityProtection}
          innerHtml={IdentityProtectionText.MyIdentityProtection}
          size={isMobile ? SIZE.PARAGRAPH_BOLD_32 : SIZE.PARAGRAPH_SEMI_BOLD_40}
          color={COLORS_NEW.BLACK_1000}
          style={{ letterSpacing: "-1px" }}
        />

        <div className="MyIdentityPage__Header__Subheader">
          <GText
            text={getSubHeaderText}
            innerHtml={getSubHeaderText}
            size={isMobile ? SIZE.PARAGRAPH_BOLD_16 : SIZE.PARAGRAPH_BOLD_18}
            color={COLORS_NEW.BLACK_500}
            style={{ letterSpacing: "-0.1px" }}
          />
        </div>
      </div>

      <div className={"MyIdentityPage__Body"}>
        {subscriptionIsValid && identities.bundleId !== BUNDLE_TYPES.INITIAL && (
          <>
            <div className={"MyIdentityPage__Body__Header"}>
              <GText
                text={"Alerts"}
                size={isMobile ? SIZE.PARAGRAPH_BOLD_28 : SIZE.PARAGRAPH_BOLD_32}
                color={COLORS_NEW.BLACK_1000}
                style={{ letterSpacing: "-1px" }}
              />
            </div>

            <AlertMessagesCard
              alerts={getAlertsCardData}
              hasReviewLink={true}
              redirectLink={"/alertMessages"}
              alertsLoader={alertsLoader}
              alertsError={alertsError}
            />

            <EmailBreachCard
              emailBreaches={parseJSON(emailBreaches?.getEmailBreachDataResult)}
              hasReviewLink={true}
              redirectLink={"/myEmailExposures"}
              emailBreachesLoader={emailBreachesLoader}
              emailBreachError={emailBreachError}
            />
          </>
        )}
        <div ref={identityInsuranceRef}>
          <IdentityInsuranceCard
            dynamic={true}
            subscriptionTeaserStatus={!subscriptionIsValid || !identities.bundleId}
            subscriptionTeaserAction={!subscriptionIsValid ? handleClickEntryCard : undefined}
            openIdentityRestorationModal={subscriptionIsValid ? () => setOpenIdentityRestorationModal(true) : undefined}
            userBundleID={subscriptionIsValid ? parseJSON(identities)?.bundleId : undefined}
            openIdentityTheftInsuranceModal={
              subscriptionIsValid ? () => setOpenIdentityTheftInsuranceModal(true) : undefined
            }
          />
        </div>

        <div ref={creditMonitoringRef}>
          <CreditMonitoringCard
            dynamic={true}
            isTeaserSubscriptionStatus={!subscriptionIsValid}
            isTeaserCreditScoreStatus={subscriptionIsValid ? isTeaserCreditScoreStatus : true}
            subscriptionTeaserAction={
              !subscriptionIsValid ? () => handleClickInfoCard(BUNDLE_TYPES.ADVANCED, BUNDLE_NAMES.ADVANCED) : undefined
            }
            userBundleID={subscriptionIsValid ? parseJSON(identities)?.bundleId : undefined}
            handleClickInfoCard={subscriptionIsValid ? handleClickInfoCard : undefined}
            creditScoresLoader={subscriptionIsValid ? creditScoresLoader : undefined}
            creditScoresError={subscriptionIsValid ? creditScoresError : undefined}
          />
        </div>

        <div ref={personalInfoRef}>
          <AddPersonalInfoCard
            dynamic={true}
            isTeaserSubscriptionStatus={!subscriptionIsValid}
            isTeaserRecordsStatus={subscriptionIsValid ? isTeaserRecordsStatus : false}
            subscriptionTeaserAction={
              !subscriptionIsValid ? () => handleClickInfoCard(BUNDLE_TYPES.MEDIUM, BUNDLE_NAMES.MEDIUM) : undefined
            }
            identities={subscriptionIsValid ? parseJSON(identities)?.records : mockTeaserRecords}
            recordType={subscriptionIsValid ? recordType : undefined}
            setRecordType={subscriptionIsValid ? setRecordType : undefined}
            setOpenAddRecordModal={subscriptionIsValid ? setOpenAddRecordModal : undefined}
            handleClickInfoCard={subscriptionIsValid ? handleClickInfoCard : undefined}
            identitiesLoader={subscriptionIsValid ? identitiesLoader : undefined}
            identitiesError={subscriptionIsValid ? identitiesError : undefined}
          />
        </div>

        {openMissingFieldsModal && (
          <MissingFieldsModal
            open={openMissingFieldsModal}
            onCancel={handleCloseModal}
            onSubmitSuccess={handleCloseModal}
            missingFields={missingFields}
            handleUpdateUser={handleUpdateUser}
          />
        )}
      </div>

      {subscriptionIsValid && (
        <>
          <AddRecordModal
            width={640}
            recordType={recordType}
            open={openAddRecordModal}
            records={parseJSON(records?.getBkCcDlMdPpEmTMDetailsResult)}
            currentUserName={{ firstName, lastName }}
            click={() => setOpenAddRecordModal(false)}
            onCancel={() => setOpenAddRecordModal(false)}
            state={locationData?.state}
            address1={locationData?.address1}
            address2={locationData?.address2}
            zipCode={locationData?.zipCode}
            city={locationData?.city}
          />

          <IDPConfirmModal
            topText={topConfirmModalText}
            headerText={headerConfirmModalText}
            open={openConfirmModal}
            bottomText={bottomConfirmModalText}
            width={560}
            onCancel={() => setOpenConfirmModal(false)}
            click={() => setOpenConfirmModal(false)}
          />

          <BenefitsModal
            headline={"Identity restoration"}
            benefits={identityRestorationModalText}
            width={560}
            open={openIdentityRestorationModal}
            onCancel={() => setOpenIdentityRestorationModal(false)}
          />

          <BenefitsModal
            headline={IdentityProtectionText.IdentityTheftProtectionModalHeader}
            benefits={identityTheftInsuranceModalText}
            width={560}
            open={openIdentityTheftInsuranceModal}
            hasAgreement={true}
            agreementText={
              <AgreementModalBlock
                textBeforeLink={
                  "GOV+ identity theft insurance is underwritten by insurance company subsidiaries or affiliates of American International Group, Inc. (AIG). Please refer to the actual policies for terms, conditions, and exclusions of coverage. Coverage may not be available in all jurisdictions." +
                  " "
                }
                linkText={"Link to the policy."}
                textAfterLink={" " + "No one can prevent all identity theft."}
              />
            }
            onCancel={() => setOpenIdentityTheftInsuranceModal(false)}
          />
        </>
      )}
    </div>
  )
}

export default MyIdentityPage
