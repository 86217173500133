import { FC } from "react"
import { renderBasicBlockContainer } from "../UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { BasicBlockContainerProps } from "../UploadPhotoService/interfaces/interfaces"
import LayoutBaseContainer from "../Layouts/LayoutBaseContainer/LayoutBaseContainer"

const DynamicErrorAnswer: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text, state },
  arrayComponents,
}) => {
  return renderBasicBlockContainer({ className, text, childrenItem, arrayComponents, state }, LayoutBaseContainer)
}

export default DynamicErrorAnswer
