import React, { useState } from "react"
import { Button, Select } from "antd"

type AdditionalQuestionSelectProps = {
  question: any,
  handlerAdditionalQuestionClickButton: any,
}

// eslint-disable-next-line max-len
const AdditionalQuestionSelect: React.FunctionComponent<AdditionalQuestionSelectProps> = ({
  question,
  handlerAdditionalQuestionClickButton,
}) => {
  const { Option } = Select
  const [selectValue, setSelectValue] = useState("")
  const acceptClick = () => {
    handlerAdditionalQuestionClickButton(selectValue, question.next)
  }
  return (
    <>
      <Select
        className={`additional-question-select`}
        defaultValue=""
        onChange={(value: string) => setSelectValue(value)}
      >
        <React.Fragment key={-1}>
          <Option value="">--Select--</Option>
        </React.Fragment>
        {question.options?.map((option: string, index: number) => (
          <React.Fragment key={index}>
            <Option value={option}>{option}</Option>
          </React.Fragment>
        ))}
      </Select>

      <div>
        <Button type="primary" className={`additional-question-btn`} onClick={acceptClick}>
          <span className="">Accept</span>
        </Button>
      </div>
    </>
  )
}
export default AdditionalQuestionSelect
