import React, {FC} from "react";
import "./FamilyMemberCard.scss"
import GText from "../../atoms/Text/Text";
import ServiceCardContentBadge from "../../atoms/ServiceCardContentBadge/ServiceCardContentBadge";
import {SIZE} from "../../types/types";
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes";
import GButton, {BUTTON_TYPE} from "../../molecules/Button/GButton";
import {COLORS} from "../../types/Colors";
import FamilyMemberHeader from "../../molecules/FamilyMemberHeader/FamilyMemberHeader";

interface FamilyMemberCardProps {

    firstName: string,
    middleName?: string,
    lastName: string,
    relationship: string
    insuredDocuments: boolean;
    renewal: boolean;
    expeditedProcessing: boolean;
}
const FamilyMemberCard: FC<FamilyMemberCardProps> = ({
    firstName,
    lastName,
    middleName,
    relationship,
    insuredDocuments = false,
    renewal = false,
    expeditedProcessing = false
}) => {

    const letterSpacingText: React.CSSProperties = {
        letterSpacing: "-0.1px"
    }

    return <div className={"GFamilyMemberCard"}>
        <FamilyMemberHeader
            firstName={firstName}
            lastName={lastName}
            middleName={middleName}
            relationship={relationship}
        />
        <div className={"GFamilyMemberCard__Content"}>
            <div className={"GFamilyMemberCard__Content__Item"} >
                <ServiceCardContentBadge text={'Document insurance'} iconType={ICONS_SVG_TYPE.INSURANCE_SHIELD_CIRCLE} active={insuredDocuments} />
                {!insuredDocuments && firstName && <GText text={"Activate"} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.ROYAL_BLUE_600} style={letterSpacingText}/>}
            </div>
            <div className={"GFamilyMemberCard__Content__Item"} >
                <ServiceCardContentBadge text={'Auto-renewal'} iconType={ICONS_SVG_TYPE.RENEWAL_CIRCLE_BODY} active={renewal} />
                {!renewal && firstName && <GText text={"Activate"} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.ROYAL_BLUE_600} style={letterSpacingText}/>}
            </div>
            <div className={"GFamilyMemberCard__Content__Item"} >
                <ServiceCardContentBadge text={'Expedited processing'} iconType={ICONS_SVG_TYPE.EXPEDITE_DOCUMENT} active={expeditedProcessing} />
                {!expeditedProcessing && firstName && <GText text={"Activate"} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.ROYAL_BLUE_600} style={letterSpacingText}/>}
            </div>
        </div>
        {firstName && <div className={"GFamilyMemberCard__Buttons"}>
            <GButton text={`${firstName}'s applications`}
                     textSize={SIZE.PARAGRAPH_BOLD_16}
                     type={BUTTON_TYPE.SECONDARY}
                     showIcon={false}
            />
            <GButton text={"New application"} textSize={SIZE.PARAGRAPH_BOLD_16}/>
        </div>}
        {!firstName && <div className={"GFamilyMemberCard__Buttons"}>
            <GButton text={"Complete profile"} textSize={SIZE.PARAGRAPH_BOLD_16} type={BUTTON_TYPE.SECONDARY} showIcon={false}/>
        </div>}
    </div>

}
export default FamilyMemberCard