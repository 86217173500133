import React from "react"
import {COLORS} from "../../../types/Colors";

interface ISvgCheck {
    width?: number;
    height?: number;
    color?: string;
}

export const SvgTaxRefundIcon = ({width = 25, height = 24, color = COLORS.BLACK_1000}: ISvgCheck) => {
    const viewBox = `0 0 ${width} ${height}`;
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1574_69233)">
                <path d="M1.5 18.9037V14.9987H5.40433" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.5 14.9998L6.13962 19.36C7.21428 20.4353 8.5438 21.2208 10.0041 21.6433C11.4644 22.0657 13.008 22.1113 14.4907 21.7758C15.9733 21.4403 17.3469 20.7346 18.4831 19.7246C19.6194 18.7145 20.4813 17.4331 20.9884 15.9998" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M10.7305 3.19883C12.0484 2.90059 13.4204 2.94112 14.7184 3.31662C16.0164 3.69213 17.1982 4.39038 18.1534 5.34623C18.1608 5.35362 18.1683 5.3609 18.176 5.36805L20.9758 7.99925H19.5979C19.0456 7.99925 18.5979 8.44696 18.5979 8.99925C18.5979 9.55153 19.0456 9.99925 19.5979 9.99925H23.4756C23.5242 10.0004 23.573 9.9981 23.6213 9.99223C24.1175 9.93337 24.5023 9.51125 24.5023 8.99925V5.09421C24.5023 4.54192 24.0545 4.09421 23.5023 4.09421C22.95 4.09421 22.5023 4.54192 22.5023 5.09421V6.68923L19.5567 3.9211C18.3647 2.73211 16.8916 1.86331 15.2742 1.3954C13.6516 0.925999 11.9365 0.87534 10.289 1.24815C8.64156 1.62096 7.11537 2.40509 5.85287 3.52735C4.59038 4.64961 3.63273 6.07343 3.06926 7.66594C2.88504 8.1866 3.15778 8.75801 3.67843 8.94223C4.19909 9.12645 4.7705 8.85371 4.95472 8.33306C5.40551 7.05901 6.17164 5.91994 7.18163 5.02214C8.19162 4.12434 9.41254 3.49706 10.7305 3.19883Z" fill={color}/>
                <path d="M15.5 9H11C10.6022 9 10.2206 9.15804 9.93934 9.43934C9.65804 9.72064 9.5 10.1022 9.5 10.5C9.5 10.8978 9.65804 11.2794 9.93934 11.5607C10.2206 11.842 10.6022 12 11 12H14C14.3978 12 14.7794 12.158 15.0607 12.4393C15.342 12.7206 15.5 13.1022 15.5 13.5C15.5 13.8978 15.342 14.2794 15.0607 14.5607C14.7794 14.842 14.3978 15 14 15H9.5M12.5 16.5V7.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_1574_69233">
                    <rect width={width} height={height} fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
