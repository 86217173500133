import { Action } from "../../interfacesAction/action.interface"
import { ValidationInterface } from "../../InterfacesEntity/validation.interface"

export const ActionTypes = {
  GET_ARRAY_STEPS: "[STEP] Get array steps",
  SET_ARRAY_STEPS: "[STEP] Set array steps",
  SET_FORM_ARRAY_STEPS: "[STEP] Set form array steps",
  SET_INITIAL_ARRAY_STEPS: "[STEP] set initial array steps",

  GET_ADDITIONAL_ARRAY_STEPS: "[STEP] get additional array steps",

  SET_CURRENT_STEP: "[STEP] Set current step",
  SET_INDEX_CURRENT_STEP: "[STEP] Set index current step",

  SET_LABEL_FORM: "[STEP] Set label form",

  SET_CURRENT_BLOCK: "[BLOCK] Set current block",

  SET_VALIDATION_RULERS: "[VALIDATION] Set validation rulers",

  SET_OPTIONS_FOR_SELECT: "[STEP] Set options for select",
  SET_ADDITIONAL_STEPS: "[STEP] Set additional steps",

  SET_CURRENT_RESPONSE: "[STEP] Set current response",

  SET_TYPE_STEP: "[STEP] Set type page",

  SET_SHOW_VALUE_MODAL_INPUT: "[MODAL] Set show value modal input",
  SET_NAME_ACTUAL_INPUT: "[MODAL] Set name actual input",
  SET_NAME_ACTUAL_LABEL: "[MODAL] Set name actual label",
  SET_NAME_ACTUAL_TYPE: "[MODAL] Set name actual type",
  SET_MODAL_CONTENT: "[MODAL] Set modal content",

  SET_DYNAMIC_BLOCKS: "[DYNAMIC_BLOCKS] set dynamic blocks",
  SET_BILLING_INFORMATION: "[BILLING_INFORMATION] set billing information",
  SET_DYNAMIC_FIELDS: "[DYNAMIC_FIELDS] set dynamic fields",

  SET_EVENT_PAGE_NAME: "[EVENT] set event page name",

  GET_STORE_FROM_EDITOR: "[STORE] get store from editor",

  GET_FAILURE: "[getFailureAction] get failure action",
}

export const getArraySteps = (): Action<string> => ({
  type: ActionTypes.GET_ARRAY_STEPS,
})

export const setArraySteps = (arraySteps: any[] | null): Action<any[] | null> => ({
  type: ActionTypes.SET_ARRAY_STEPS,
  payload: arraySteps,
})

export const setFormArraySteps = (arraySteps: any[]): Action<any[]> => ({
  type: ActionTypes.SET_FORM_ARRAY_STEPS,
  payload: arraySteps,
})

export const setInitialArraySteps = (arraySteps: any[]): Action<any[]> => ({
  type: ActionTypes.SET_INITIAL_ARRAY_STEPS,
  payload: arraySteps,
})

export const getAdditionalArraySteps = (nameAdditionalSteps: string): Action<string> => ({
  type: ActionTypes.GET_ADDITIONAL_ARRAY_STEPS,
  payload: nameAdditionalSteps,
})

export const setCurrentStep = (indexCurrentStep: number): Action<number> => ({
  type: ActionTypes.SET_CURRENT_STEP,
  payload: indexCurrentStep,
})

export const setCurrentBlock = (currentBlock: {}): Action<{}> => ({
  type: ActionTypes.SET_CURRENT_BLOCK,
  payload: currentBlock,
})

export const setIndexCurrentStep = (indexCurrentStep: number): Action<number> => ({
  type: ActionTypes.SET_INDEX_CURRENT_STEP,
  payload: indexCurrentStep,
})

export const setValidationRulers = (objectRulers: ValidationInterface): Action<ValidationInterface> => ({
  type: ActionTypes.SET_VALIDATION_RULERS,
  payload: objectRulers,
})

export const setOptionsForSelect = (object: any): Action<any> => ({
  type: ActionTypes.SET_OPTIONS_FOR_SELECT,
  payload: object,
})

export const setAdditionalSteps = (arraySteps: any[]): Action<any[]> => ({
  type: ActionTypes.SET_ADDITIONAL_STEPS,
  payload: arraySteps,
})

export const setCurrentResponse = (arraySteps: {}): Action<{}> => ({
  type: ActionTypes.SET_CURRENT_RESPONSE,
  payload: arraySteps,
})

export const setTypeStep = (type: string): Action<string> => ({
  type: ActionTypes.SET_TYPE_STEP,
  payload: type,
})

export const setNameActualInput = (name: string): Action<string> => ({
  type: ActionTypes.SET_NAME_ACTUAL_INPUT,
  payload: name,
})

export const setNameActualLabel = (name: string): Action<string> => ({
  type: ActionTypes.SET_NAME_ACTUAL_LABEL,
  payload: name,
})

export const setNameActualType = (type: string): Action<string> => ({
  type: ActionTypes.SET_NAME_ACTUAL_TYPE,
  payload: type,
})

export const setShowValueModalInput = (value: string): Action<string> => ({
  type: ActionTypes.SET_SHOW_VALUE_MODAL_INPUT,
  payload: value,
})

export const setContentModal = (content: {}): Action<{}> => ({
  type: ActionTypes.SET_MODAL_CONTENT,
  payload: content,
})

export const setDynamicBlocks = (array: [{}] | any): Action<[{}]> => ({
  type: ActionTypes.SET_DYNAMIC_BLOCKS,
  payload: array,
})

export const setBillingInformation = (payload: {}): Action<{}> => ({
  type: ActionTypes.SET_BILLING_INFORMATION,
  payload: payload,
})

export const setDynamicFields = (array: [{}] | any): Action<[{}]> => ({
  type: ActionTypes.SET_DYNAMIC_FIELDS,
  payload: array,
})

export const setEventPageName = (value: string): Action<string> => ({
  type: ActionTypes.SET_EVENT_PAGE_NAME,
  payload: value,
})

export const getStoreFromEditor = (indexCurrentStep: any): Action<any> => ({
  type: ActionTypes.GET_STORE_FROM_EDITOR,
  payload: indexCurrentStep,
})

export const setLabelForm = (label: string): Action<string> => ({
  type: ActionTypes.SET_LABEL_FORM,
  payload: label,
})

export const getFailureAction = (error?: {}): Action<{}> => ({
  type: ActionTypes.GET_FAILURE,
  payload: error,
})
