import { Action } from "../../interfacesAction/action.interface"

export const ActionTypes = {
  SET_VALUE_FOR_SELECT: "[SELECT] set value for select",

  GET_FAILURE: "[getFailureAction] get failure action",
}

export const setValueForSelect = (
  nameCurrentBlock: string,
  nameCurrentField: string,
  valueCurrentField: string | number
): Action<{}> => ({
  type: ActionTypes.SET_VALUE_FOR_SELECT,
  payload: { nameCurrentBlock, nameCurrentField, valueCurrentField },
})

export const getFailureAction = (error?: {}): Action<{}> => ({
  type: ActionTypes.GET_FAILURE,
  payload: error,
})
