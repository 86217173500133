export const dynamicOptionsSelects = {
  block_diff_name_prev: {
    block_current_name: {
      fname: "",
      mname: "",
      lname: "",
    },
    block_previous_name: {
      old_fname: "",
      old_mname: "",
      old_lname: "",
    },
    block_name_birth: {
      fname_birth: "",
      mname_birth: "",
      lname_birth: "",
    },
    block_name_other: {
      fname_other: "",
      mname_other: "",
      lname_other: "",
    },
  },
}
