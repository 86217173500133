import {all, fork, put, call, takeEvery} from "redux-saga/effects";
import {
    checkUserActiveLinkForExpiredResult,
    checkUserExistFailed,
    checkUserExistSuccess,
    checkUserTokenActiveFailed,
    checkUserTokenActiveSuccess, preAuthUserFailed, preAuthUserSuccess, setUserToken,
    showAuthMessage, showGrowlMessage, showModalsType, userLogInDataSave,
    userSignInSuccess,
    userSignOutSuccess, userTokenExpaired, setIsAuthFinished
} from "../actions";
import {userService, billingApis, productsApis, subscriptionService} from "../../api/api";
import {
    CHECK_USER_ACTIVATE_LINK,
    CHECK_USER_EMAIL_EXIST,
    CHECK_USER_TOKEN, CHECK_USER_TOKEN_FROM_COOKIES,
    CONFIRM_USER_SMS_SUCCESS, RESET_INSTRUCTIONS, RESET_PASSWORD,
    SIGNIN_USER, SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SIGNUP_USER, USER_PRE_AUTH
} from "../../constants/ActionTypes";
import {notifyError} from "@dash/gov-plus__front-end__form/src/util/helper";

import UtmRepository from "@dash/gov-plus__front-end__form/src/persistence/cookies/UtmRepository";
import SignedOutBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/SignedOut/SignedOutBuilder";
import PasswordResetConfirmedBuilder
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/PasswordResetConfirmed/PasswordResetConfirmedBuilder";
import GAnalyticEvents from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/AnalyticEvents";
import UserIpRepository, {USER_IP} from "@dash/gov-plus__front-end__form/src/persistence/session/UserIpRepository";
import OuterIdRepository from "@dash/gov-plus__front-end__form/src/persistence/session/OuterIdRepository";
import {EventsHelper} from "@dash/gov-plus__front-end__form/src/util/EventsHelper";
import SessionStorageHandler from "@dash/gov-plus__front-end__form/src/persistence/SessionStorageHandler";
import {getClientTokenForResponse} from "@dash/gov-plus__front-end__form/src/Redux/store/step/service.step.saga";
import {getUrlAnalyticQueryParams} from "@dash/gov-plus__front-end__form/src/util/UrlQueryParams";
import {AdditionalSagaHelper} from "@dash/gov-plus__front-end__form/src/Redux/store/additionalForm/AdditionalSagaHelper";
import AuthUserBearerTokenRepository from "@dash/gov-plus__front-end__form/src/persistence/session/AuthUserBearerTokenRepository";
import {SubscriptionStatuses} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";

export async function getUserDataFromDash() {
    return await userService.userDataFromDash();
}

export async function getUserFlatternData() {
    return await userService.userFlatternData();
}

export async function isUserSubscribedData() {
    return await subscriptionService.getUserSubscriptionStatus();
}

export async function getIntercomUserHash(token) {
    return await userService.getIntercomUserHash(token);
}

export async function getUserApplications(token) {
    return await productsApis.getAllProductsApi(token);
}

async function triggerIdentifyEvent(email, activated = false) {
    const clientToken = getClientTokenForResponse()
    const utmVarsJson = UtmRepository.getInstance().getUtm();

    /**
     * Promise.allSettled is used to make sure that all promises are resolved, even if some of them fail. This return an array of objects
     * with the following structure:
     * {
     *  status: "fulfilled" | "rejected", // depending on the promise status
     *  value: any // the value returned by the promise or the error
     * }
     */
    const data = await Promise.allSettled([getUserDataFromDash(), getUserFlatternData(), isUserSubscribedData(), getIntercomUserHash(clientToken), getUserApplications(clientToken?.client_token)]);
    const userDataResponse = data[0].status === "fulfilled" ? data[0].value : null;
    const flattenResponse = data[1].status === "fulfilled" ? data[1].value : null;
    const isUserSubscribed = data[2].status === "fulfilled" ? data[2].value : null;
    const subscriptionStatus = isUserSubscribed?.data?.status === SubscriptionStatuses.ACTIVE;
    const intercomUserHash = data[3].status === "fulfilled" ? data[3].value?.data?.user_hash : "";
    const applications = data[4].status === "fulfilled" && data[4]?.value?.data?.success ? data[4].value.data.success.applications : [];
    const {outerId, address, firstName, lastName, phoneNumber, dateOfBirth, authorized, createdAt} = EventsHelper.getDataToIdentify(userDataResponse, flattenResponse);
    let isActivated = activated ? activated : authorized === 1
    return await EventsHelper.triggerIdentify(outerId, address, email, firstName, lastName, phoneNumber, dateOfBirth, applications.length > 0 || subscriptionStatus, isUserSubscribed?.data.status === SubscriptionStatuses.ACTIVE ||  isUserSubscribed?.data.status === SubscriptionStatuses.CANCELLED || isUserSubscribed?.data.status === SubscriptionStatuses.IN_TRIAL, utmVarsJson, isActivated, intercomUserHash, createdAt);
}

function* signInUserWithEmailPassword({payload}) {
    const {email, password, rememberMe, smsCode} = payload;
    const otpToken = localStorage.getItem(`otpToken/${email}`);
    yield put(userLogInDataSave({email, password, rememberMe}));

    const {
        utmVars,
        analytic_fbc,
        analytic_fbp,
        analytic_gclid,
        analytic_msclkid,
        analytic_ever_flow_id,
        analytic_impact_radius_id
    } = getUrlAnalyticQueryParams();

    userService.saveUtmVars(
        email,
        utmVars,
        analytic_fbc,
        analytic_fbp,
        analytic_gclid,
        analytic_msclkid,
        analytic_ever_flow_id,
        analytic_impact_radius_id
    ).catch(() => {});

    try {
        yield put(setIsAuthFinished(false));
        const signInUser = yield userService.authenticate({
            login: email,
            password,
            smsCode,
            otp_token: otpToken || null,
            smsToken: null
        });

        if (signInUser.data.data.token) {
            yield call(AdditionalSagaHelper.setAuthBearerToken, signInUser.data.data.token)
            const userData = yield userService.userDataFromDash();
            localStorage.setItem("phoneSlice", signInUser.data.phone)
            const {
                address,
                birthday,
                city,
                country,
                email,
                firstname,
                id,
                lastname,
                phone,
                outer_id
            } = userData.data.data;
            localStorage.setItem('user', JSON.stringify({
                'email': email,
                'phone': phone,
                'outer_id': outer_id,
                'name': firstname,
                'last_name': lastname,
                'user_id': id
            }));

            yield put(checkUserTokenActiveSuccess(true));
            yield call(triggerIdentifyEvent, email, true);
            yield call(EventsHelper.triggerLogin, email, UserIpRepository.getInstance().getValue());

            yield put(userSignInSuccess({
                address, birthday, city, country, email, firstname, id, lastname, phone, outer_id,
                authUser: AuthUserBearerTokenRepository.getInstance().getValue(),
            }));

        }
        yield put(setIsAuthFinished(true));
    } catch (error) {
        yield put(setIsAuthFinished(true));
        const {response} = error;
        yield put(checkUserTokenActiveSuccess(false));
        localStorage.setItem("phoneSlice", error?.response?.data?.data?.phone)
        localStorage.setItem("signInOuterId", error?.response?.data?.data?.outer_id)
        yield put(showAuthMessage(response?.data));
        notifyError(error);
    }
}

function* createUserWithEmailPassword({payload}) {
    const {email, password, phone, smsToken, sms, rememberMe} = payload;
    try {
        yield put(setIsAuthFinished(false));
        if (localStorage.getItem("userDataFromGov")) {
            const signUp = yield userService.createNewUser({
                email,
                username: email,
                firstname: JSON.parse(localStorage.getItem("userDataFromGov")).name,
                lastname: JSON.parse(localStorage.getItem("userDataFromGov")).last_name,
                phone,
                mobile: phone,
                new_password: password,
                smsToken,
                sms,
                preauth_token: JSON.parse(localStorage.getItem("userDataFromGov")).client_token,
                userIp: UserIpRepository.getInstance().getValue()
            });
            yield call(AdditionalSagaHelper.setAuthBearerToken, signUp.data.data.current_token);
            sessionStorage.removeItem("isThisUserPreAuth")

            yield call(triggerIdentifyEvent, email, true);

            /* Event Verification */

            if (rememberMe) {
                localStorage.setItem(`otpToken/${email}`, signUp.data.data.otp_token)
            }
            if (signUp.data.data.current_token) {
                const userData = yield userService.userDataFromDash();
                yield put(showAuthMessage(signUp?.data));
                const {
                    address,
                    birthday,
                    city,
                    country,
                    email,
                    firstname,
                    id,
                    lastname,
                    phone,
                    outer_id
                } = userData.data.data;
                localStorage.setItem('user', JSON.stringify({
                    'email': email,
                    'phone': phone,
                    'outer_id': outer_id,
                    'name': firstname,
                    'last_name': lastname,
                    'user_id': id
                }));

                yield put(userSignInSuccess({
                    address, birthday, city, country, email, firstname, id, lastname, phone, outer_id,
                    authUser: AuthUserBearerTokenRepository.getInstance().getValue(),
                }));
            }
            yield put(userSignInSuccess({
                authUser: AuthUserBearerTokenRepository.getInstance().getValue(),
            }));
            yield put(showAuthMessage(signUp.data));
            yield put(checkUserTokenActiveSuccess(true));

        }
        yield put(setIsAuthFinished(true));
    } catch (err) {
        yield put(setIsAuthFinished(true));
        yield put(checkUserTokenActiveSuccess(false));
        const {response} = err;
        yield put(showAuthMessage(response?.data));
        notifyError(err);
    }
}

function* confirmUserSMSSaga({payload}) {
    const {email, password, smsCode, rememberMe, smsToken} = payload;
    try {
        yield put(setIsAuthFinished(false));
        const otpToken = localStorage.getItem(`otpToken/${email}`);
        if (!otpToken && !OuterIdRepository.getInstance().getValue()) {

            const signInUser = yield call(userService.authenticate, {
                login: email,
                password,
                sms: smsCode,
                smsToken,
                otp_token: null,
            });

            yield put(showAuthMessage(signInUser?.data));
            yield put(checkUserTokenActiveSuccess(true));
            if (rememberMe) {
                yield call(AdditionalSagaHelper.setAuthBearerToken, signInUser.data.data.token);
                localStorage.setItem(`otpToken/${email}`, signInUser.data.data.otp);
                // when user success auth

                yield call(triggerIdentifyEvent, email, true);
                yield call(EventsHelper.triggerLogin, email, UserIpRepository.getInstance().getValue());

                /* Event Verification */

                if (signInUser.data.data.token) {
                    const userData = yield userService.userDataFromDash();
                    const {
                        address,
                        birthday,
                        city,
                        country,
                        email,
                        firstname,
                        id,
                        lastname,
                        phone,
                        outer_id
                    } = userData.data.data;
                    localStorage.setItem('user', JSON.stringify({
                        'email': email,
                        'phone': phone,
                        'outer_id': outer_id,
                        'name': firstname,
                        'last_name': lastname,
                        'user_id': id
                    }));

                    yield put(userSignInSuccess({
                        address, birthday, city, country, email, firstname, id, lastname, phone, outer_id,
                        authToken: signInUser.data.data.token,
                    }));
                }

                if (signInUser.data.data.token) {

                    const userData = yield userService.userDataFromDash();

                    const {
                        address,
                        birthday,
                        city,
                        country,
                        email,
                        firstname,
                        id,
                        lastname,
                        phone,
                        outer_id
                    } = userData.data.data;
                    localStorage.setItem('user', JSON.stringify({
                        'email': email,
                        'phone': phone,
                        'outer_id': outer_id,
                        'name': firstname,
                        'last_name': lastname,
                        'user_id': id
                    }));

                    yield put(userSignInSuccess({
                        address, birthday, city, country, email, firstname, id, lastname, phone, outer_id,
                        authToken: signInUser.data.data.token,
                    }));
                }

            } else {
                yield call(AdditionalSagaHelper.setAuthBearerToken, signInUser.data.data.token);
                sessionStorage.setItem(`otpToken/${email}`, signInUser.data.data.otp);

                yield call(triggerIdentifyEvent, email, true);
                yield call(EventsHelper.triggerLogin, email, UserIpRepository.getInstance().getValue());

                /* Event Verification */
                if (signInUser.data.data.token) {
                    const userData = yield userService.userDataFromDash();
                    const {
                        address,
                        birthday,
                        city,
                        country,
                        email,
                        firstname,
                        id,
                        lastname,
                        phone,
                        outer_id
                    } = userData.data.data;
                    sessionStorage.setItem('user', JSON.stringify({
                        'email': email,
                        'phone': phone,
                        'outer_id': outer_id,
                        'name': firstname,
                        'last_name': lastname,
                        'user_id': id
                    }));

                    if (signInUser.data.data.token) {
                        const userData = yield userService.userDataFromDash();

                        const {
                            address,
                            birthday,
                            city,
                            country,
                            email,
                            firstname,
                            id,
                            lastname,
                            phone,
                            outer_id
                        } = userData.data.data;
                        localStorage.setItem('user', JSON.stringify({
                            'email': email,
                            'phone': phone,
                            'outer_id': outer_id,
                            'name': firstname,
                            'last_name': lastname,
                            'user_id': id
                        }));

                        yield put(userSignInSuccess({
                            address, birthday, city, country, email, firstname, id, lastname, phone, outer_id,
                            authToken: signInUser.data.data.token,
                        }));
                    }

                    yield put(userSignInSuccess({
                        address, birthday, city, country, email, firstname, id, lastname, phone, outer_id,
                        authToken: signInUser.data.data.token,
                    }));
                }
            }

        }

        if (localStorage.getItem("userDataFromGov")) {
            const authSMSData = yield userService.authUserSMS({
                sms: payload.smsCode,
                outer_id: JSON.parse(sessionStorage.getItem("userDataFromGov")).outer_id
            });

            if (authSMSData.data.messages === "common.SUCCESSFUL_REQUEST") {

                yield call(AdditionalSagaHelper.setAuthBearerToken, authSMSData.data.data.token);
                const userData = yield userService.userDataFromDash();

                const {
                    address,
                    birthday,
                    city,
                    country,
                    email,
                    firstname,
                    id,
                    lastname,
                    phone,
                    outer_id
                } = userData.data.data;
                localStorage.setItem('user', JSON.stringify({
                    'email': email,
                    'phone': phone,
                    'outer_id': outer_id,
                    'name': firstname,
                    'last_name': lastname,
                    'user_id': id
                }));

                yield put(userSignInSuccess({
                    address, birthday, city, country, email, firstname, id, lastname, phone, outer_id,
                    authUser: AuthUserBearerTokenRepository.getInstance().getValue(),
                }));

            } else {
                yield put(showAuthMessage(authSMSData));
            }
        }

        if (OuterIdRepository.getInstance().getValue()) {
            const authSMSData = yield userService.authUserSMS({
                    "sms": payload.smsCode,
                    "outer_id": OuterIdRepository.getInstance().getValue()
                }
            );

            yield call(AdditionalSagaHelper.setAuthBearerToken, authSMSData.data.token);

            if (authSMSData.data.token) {
                const userData = yield userService.userDataFromDash();
                const {
                    address,
                    birthday,
                    city,
                    country,
                    email,
                    firstname,
                    id,
                    lastname,
                    phone,
                    outer_id
                } = userData.data.data;
                localStorage.setItem('user', JSON.stringify({
                    'email': email,
                    'phone': phone,
                    'outer_id': outer_id,
                    'name': firstname,
                    'last_name': lastname,
                    'user_id': id
                }));
                yield put(userSignInSuccess({
                    address, birthday, city, country, email, firstname, id, lastname, phone, outer_id,
                    authUser: AuthUserBearerTokenRepository.getInstance().getValue(),
                }));
                yield put(checkUserTokenActiveSuccess(true));
            }
        }
        yield put(setIsAuthFinished(true));
    } catch (err) {
        const {response} = err;
        yield put(setIsAuthFinished(true));
        yield put(showAuthMessage(response?.data));
        yield put(checkUserTokenActiveSuccess(false));
        notifyError(err);
    }
}


function* signOut({payload}) {
    try {
        const userLogOut = yield userService.userLogOut();
        if (userLogOut.data.status === "OK") {

            const ipAddress = UserIpRepository.getInstance().getValue()
            const email = userLogOut?.data?.data?.email ?? ''

            // Don't trigger the "Signed out" event if the user is anonymous
            if(!email.includes("govplus")) {
                const eventSignedOutBuilder = new SignedOutBuilder()
                    .setEmail(email)
                    .setIpAddress(ipAddress);
                GAnalyticEvents.track(eventSignedOutBuilder.build());
                GAnalyticEvents.reset();
            }

            SessionStorageHandler.clearIgnoringKey([USER_IP])

            for (let key in localStorage) {
                if (key.split('/')[0] !== "otpToken") {
                    localStorage.removeItem(key);
                }
            }
            yield put(userSignOutSuccess({logOutStatus: true}))
        }
    } catch (error) {
        yield put(showAuthMessage(error));
        notifyError(error);
    }
}

function* sendResetInstruction({email}) {
    try {
        const resetData = yield userService.sendResetInstruction(email)

        yield call(EventsHelper.triggerPasswordResetRequested, email, UserIpRepository.getInstance().getValue());
        yield call(triggerIdentifyEvent, email, true);

        if (resetData.data.messages === "common.EMAIL_RESET_SENT") {
            yield put(showAuthMessage(resetData?.data));
        }

    } catch (error) {
        const {response} = error;
        yield put(showAuthMessage(response.data));
        notifyError(error);
    }
}

function* resetPassword({payload}) {
    const {password, resetToken} = payload
    try {
        const resetPassword = yield userService.ResetForGotPassword(password, resetToken);
        if (resetPassword.data.status === "OK") {

            const passwordResetConfirmedBuilder = new PasswordResetConfirmedBuilder()
                .setEmail(resetPassword.data.data.email)
                .setIpAddress(UserIpRepository.getInstance().getValue());
            GAnalyticEvents.track(passwordResetConfirmedBuilder.build());

            yield put(showAuthMessage(resetPassword.data));
        }

    } catch (error) {
        const {response} = error;
        yield put(showAuthMessage(response.data));
        notifyError(error);
    }
}

function* checkUserTokenForExpaired({payload}) {
    try {
        const {data: {data: {userActive}}} = yield userService.checkIsTokenActive(payload);
        if (userActive) {
            yield put(checkUserTokenActiveSuccess(true))
        }
        yield put(userTokenExpaired({errorMessage: "user_token_valid"}))
    } catch (err) {
        yield put(checkUserTokenActiveFailed(false))
        yield put(userTokenExpaired({errorMessage: err.response.data.messages}))
        if (err.response.data.messageInfo === "common.USER_TOKEN_EXPIRED") {
            yield put(showGrowlMessage({
                messageInfo: err.response.data.messageInfo,
                growlStatus: false,
                code: 400,
            }))
        }
        notifyError(err);
    }
}

function* preAuthUser({userData}) {
    sessionStorage.setItem("isThisUserPreAuth", "true")
    try {
        yield put(setIsAuthFinished(false));
        const responce = yield userService.userPreAuth(userData);
        const {token, outer_id, user_id} = responce.data.data;

        if (responce.data.messages === "common.CREATED_SUCCESSFULLY") {
            yield call(AdditionalSagaHelper.setAuthBearerToken, responce.data.data.token);

            yield put(preAuthUserSuccess({token, outer_id, user_id}));

            /* Event Alias */

            yield put(showModalsType(""))
        }
        yield put(setIsAuthFinished(true));
    } catch (err) {
        yield put(setIsAuthFinished(true));
        yield put(preAuthUserFailed({errorMessage: err.response?.data.messages}))
        notifyError(err);
    }
}

function* checkUserTokenActive({userTokenFromCookies}) {
    try {
        yield put(setIsAuthFinished(false));
        const {data: {data: {userActive}}} = yield userService.checkIsTokenActive(userTokenFromCookies);
        if (userActive) {
            yield put(checkUserTokenActiveSuccess(true))
            if (userTokenFromCookies) {
                yield call(AdditionalSagaHelper.setAuthBearerToken, userTokenFromCookies);
                yield put(setUserToken({userToken: userTokenFromCookies}))
            }
        }
        yield put(setIsAuthFinished(true));
    } catch (err) {
        yield put(setIsAuthFinished(true));
        yield put(checkUserTokenActiveFailed(false))

        if (userTokenFromCookies && err.response.data.messages !== "common.USER_TOKEN_EXPIRED") {
            yield call(AdditionalSagaHelper.setAuthBearerToken, userTokenFromCookies);
            yield put(setUserToken({userToken: userTokenFromCookies}))
        }
        if (err.response.data.messages === "common.USER_TOKEN_EXPIRED") {
            SessionStorageHandler.clearIgnoringKey([USER_IP])
            yield put(showGrowlMessage({
                messageInfo: "Please login to continue.",
                growlStatus: false,
                code: 400,
                showMessage: true,
            }))
            notifyError(err);
        }
    }
}

function* checkUserActiveLinkForExpired({tokenFromActivateLink}) {
    try {
        const {data: {data: {active}}} = yield userService.checkUserLink({token: tokenFromActivateLink});
        yield put(checkUserActiveLinkForExpiredResult({isUserLinkActive: active}))
    } catch (err) {
        yield put(checkUserActiveLinkForExpiredResult({isUserLinkActive: false}))
        notifyError(err);
    }
}

function* checkUserExist({payload: {userEmail}}) {
    try {
        yield call(userService.checkUserExist, userEmail);
        yield put(checkUserExistSuccess({
            checkUserEmailExistStatus: true
        }))
    } catch (err) {
        yield put(checkUserExistFailed({
            checkUserEmailExistStatus: false
        }))
        notifyError(err);
    }
}

function* checkUserActiveLinkForExpiredFork() {
    yield takeEvery(CHECK_USER_ACTIVATE_LINK, checkUserActiveLinkForExpired);
}

function* checkUserExistFork() {
    yield takeEvery(CHECK_USER_EMAIL_EXIST, checkUserExist);
}

function* checkUserTokenActiveFork() {
    yield takeEvery(CHECK_USER_TOKEN_FROM_COOKIES, checkUserTokenActive);
}

function* preAuthUserFork() {
    yield takeEvery(USER_PRE_AUTH, preAuthUser);
}

function* createUserAccountFork() {
    yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}


function* checkUserTokenForExpairedFork() {
    yield takeEvery(CHECK_USER_TOKEN, checkUserTokenForExpaired);
}

export function* resetUserPassFork() {
    yield takeEvery(RESET_INSTRUCTIONS, sendResetInstruction);
}

export function* resetPasswordFork() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* confirmUserSMSSagaFork() {
    yield takeEvery(CONFIRM_USER_SMS_SUCCESS, confirmUserSMSSaga);
    if (OuterIdRepository.getInstance().getValue()) {
        yield takeEvery(SIGNIN_USER_SUCCESS, confirmUserSMSSaga);
    }
}

export function* signInUserFork() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUserFork() {
    yield takeEvery(SIGNOUT_USER, signOut);
}


export default function* rootSaga() {
    yield all([
        fork(signInUserFork),
        fork(signOutUserFork),
        fork(createUserAccountFork),
        fork(confirmUserSMSSagaFork),
        fork(resetUserPassFork),
        fork(resetPasswordFork),
        fork(checkUserTokenForExpairedFork),
        fork(preAuthUserFork),
        fork(checkUserTokenActiveFork),
        fork(checkUserExistFork),
        fork(checkUserActiveLinkForExpiredFork),
    ]);
}
