import {EventOptions, EventProps} from "../EventProperties";
import IEvent from "../IEvent";

export default class Page implements IEvent {
    private readonly _pageName: string;
    private readonly _userIp: string;
    constructor(pageName: string, userIp: string) {
        this._userIp = userIp;
        this._pageName = pageName;
    }

    getEventData(): EventProps {
        return {
            ip: this._userIp,
        }
    }

    getEventName(): string {
        return "";
    }

    pageName(): string {
        return this._pageName;
    }

    getPreviousId(): string {
        return "";
    }

    getUserId(): string {
        return "";
    }

    getEventOptions(): EventOptions | undefined {
        return undefined;
    }
}
