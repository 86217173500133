import Event from "../Event"
import {EventOptions, EventProps} from "../EventProperties"
import IEvent from "../IEvent";

const EVENT_NAME = "Email Verification Succeeded";

export default class EmailVerificationSucceeded extends Event implements IEvent {
    private readonly _emailVerified: boolean | undefined;
    private readonly _emailData: any | undefined;

    constructor(
        emailVerified: boolean | undefined,
        emailData: any | undefined,
        ipAddress: string | undefined
    ) {
        super(EVENT_NAME, ipAddress);
        this._emailVerified = emailVerified;
        this._emailData = emailData;
    }

    getEventData(): EventProps {
        return {
            ...super.baseEventData(),
            emailVerified: this._emailVerified,
            email_data: this._emailData,
        }
    }

    getEventName(): string {
        return this.eventName;
    }

    pageName(): string {
        return "";
    }

    getPreviousId(): string {
        return "";
    }

    getUserId(): string {
        return "";
    }

    getEventOptions(): EventOptions | undefined {
        return undefined;
    }
}
