import React from "react"
import { Form } from "antd"
import { connect } from "react-redux"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import {getLabel} from "../../../../services/service-function/field";

type TextAreaComponentProps = {
  field: FieldInterface | any,
  value: string,
  dispatch: any,
}

// eslint-disable-next-line max-len
const TextAreaComponent: React.FunctionComponent<TextAreaComponentProps> = ({ field, value, dispatch }) => {
  const handlerOnChangeInput = (e: any, field: FieldInterface) => {
    dispatch(setValueCurrentField(field.name, e.target.value))
  }

  return (
    <>
      <Form.Item
        label={getLabel(field.label)}
        // eslint-disable-next-line max-len
        className={`form-item__${"text_area"}-container form-item__${field.name}-container`}
      >
        <Form.Item
          name={field.name}
          initialValue={value || ""}
          preserve={false}
          // eslint-disable-next-line max-len
          className={`form-item__input form-item__input_validation-${""}`}
          hasFeedback
          dependencies={[field.dependencies]}
        >
          <textarea
            className={`text-area__input text-area__input${field.name}`}
            rows={5}
            onChange={(e: any) => handlerOnChangeInput(e, field)}
            value={value}
          />
        </Form.Item>
      </Form.Item>
    </>
  )
}

const mapStateToProps = (state: any) => ({})

export default connect(mapStateToProps)(TextAreaComponent)
