import {useEffect, useState} from "react";

export const useDate = (isBefore = true, totalYearsShow = 101) => {
    const [selectedDay, setSelectedDay] = useState("")
    const [selectedMonth, setSelectedMonth] = useState("")
    const [selectedYear, setSelectedYear] = useState("")

    const [days, setDays] = useState([])
    const [years, setYears] = useState([])

    const months = [
        {
            value: 1,
            label: 'January'
        },
        {
            value: 2,
            label: 'February'
        },
        {
            value: 3,
            label: 'March'
        },
        {
            value: 4,
            label: 'April'
        },
        {
            value: 5,
            label: 'May'
        },
        {
            value: 6,
            label: 'June'
        },
        {
            value: 7,
            label: 'July'
        },
        {
            value: 8,
            label: 'August'
        },
        {
            value: 9,
            label: 'September'
        },
        {
            value: 10,
            label: 'October'
        },
        {
            value: 11,
            label: 'November'
        },
        {
            value: 12,
            label: 'December'
        },
    ]
    const populateYears = (isBefore, totalYearsShow) => {
        const currentYear = new Date().getFullYear();
        const yearsNodes = []
        if(isBefore === true){

            for (let i = 0; i <= totalYearsShow; i++) {
                yearsNodes.push({value: currentYear - i, label: currentYear - i})
            }
        }
        else {
            for (let i = 0; i <= totalYearsShow; i++) {
                yearsNodes.push({value: currentYear + i, label: currentYear + i})
            }
        }
        setYears(yearsNodes)
    }

    useEffect(() => {
        const dayNum = () => {
            if (selectedMonth) {
                if (selectedMonth === 2) {
                    return 29
                } else if ([1, 3, 5, 7, 8, 10, 12]
                    .includes(selectedMonth))
                {
                    return 31
                } else {
                    return 30
                }
            } else {
                return 31
            }
        }
        const daysNodes = []
        for (let i = 1; i <= dayNum(); i++) {
            daysNodes.push({value: i, label: i})
        }
        setDays(daysNodes)
    }, [selectedMonth])

    useEffect(() => {
        populateYears(isBefore, totalYearsShow)
    }, [isBefore, totalYearsShow])

    return {
        selectedDay,
        selectedMonth,
        selectedYear,
        setSelectedDay,
        setSelectedMonth,
        setSelectedYear,
        days,
        months,
        years
    }
}