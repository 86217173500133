import { Dispatch } from "../SelectComponent/useDefaultOption"
import FlattenDataDto from "../../../../dto/FLattenDataDto/FlattenDataDto";

export interface SubmitObjectInterface {
  [key: string]: string;
}

export type CaseDenyValidationActionType = (submitObject: SubmitObjectInterface, dependsField: string) => boolean

export interface CaseDenyValidationInterface {
  nameField: string;
  dependsField: string;
  action: CaseDenyValidationActionType;
}

export type ArrayCasesDenyValidationType = CaseDenyValidationInterface[]

export const getPhoneDenyValidation = (submitObject: SubmitObjectInterface, dependsField: string) =>
  submitObject[dependsField]?.split("@")?.[1] === "govplus.com"

export const arrayCasesDenyValidation = [
  {
    nameField: "phone",
    dependsField: "email",
    action: getPhoneDenyValidation,
  },
]

export interface paymentErrorInterface {
  nameField: string[];
  errorDescription: string;
}

export const hiddenField = (tag : string | undefined = "", tagStoreWithFields: tagStoreWithFieldsInterface) =>{
  if (tag === FlattenDataDto.VAR_PHONE_NUMBER && tagStoreWithFields[tag]?.length !== 14) {
    return ""
  }
  return tagStoreWithFields?.[tag] ? "prefilled_hidden" : ""
}

interface tagStoreWithFieldsInterface {
  [key: string]: string;
}

export const setPrefilledValueInSubmitObject = (
  name: string,
  tag: string | undefined,
  setValueCurrentField: (key: string, value: string) => any,
  tagStoreWithFields: tagStoreWithFieldsInterface,
  dispatch: Dispatch<any>
) => {
  if(tag && tagStoreWithFields[tag]) {
    dispatch(setValueCurrentField(name, tagStoreWithFields[tag]))
  }
}

const arrayCitesAutofillValidation = ["residential_city_name2", "bus_city", "mailing_city_name"]

export const checkCitesAutofillValidation = (name: string) => arrayCitesAutofillValidation.includes(name)

export const VALIDATION_STATUSES = {
  ON: "on",
  OFF: "off"
}