/* eslint-disable max-len */
import React from "react"
import successIcon from "../../../assets/image/status-check_completed.svg"
import closeModal from "../../../assets/image/closeModal.svg"

type ThankYouPageComponentProps = {
  setValueModal: any,
  dispatch: any,
}

const ThankYouPageComponent: React.FunctionComponent<ThankYouPageComponentProps> = ({ setValueModal, dispatch }) => {
  return (
    <div className="modal-bl">
      <div className={"modal-header__container"}>
        <img
          className={"modal-header__icon right"}
          src={closeModal}
          alt={"close-modal"}
          onClick={() => dispatch(setValueModal(""))}
        />
      </div>
      <div className={"modal-main__container"}>
        <div className={"modal-main__icon-block"}>
          <img className={"modal-main__icon center"} src={successIcon} alt={"icon-status"} />
        </div>
        <div className={"modal-main__text-block"}>
          <p className={"icon-block__text center"}>{"Thank you! We have received your documents"}</p>
        </div>
      </div>
    </div>
  )
}

export default ThankYouPageComponent
