import BaseCookiesRepository from "./BaseCookiesRepository";

const GCLID_VARS_KEY = "gclid";

class GclidRepository extends BaseCookiesRepository {

    static GCLID_DEFAULT_VALUE = "";

    private static _instance: GclidRepository;

    private constructor() {
        super()
    }

    public static getInstance(): GclidRepository {
        if (!GclidRepository._instance) {
            GclidRepository._instance = new GclidRepository();
        }
        return GclidRepository._instance;
    }

    getKeyName(): string {
        return GCLID_VARS_KEY;
    }
}

export default GclidRepository;
