import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  CONFIRM_USER_SMS_SUCCESS,
  USER_LOG_IN_DATA_SAVE,
  USER_LOG_IN_DATA_CLEAR,
  RESET_INSTRUCTIONS,
  RESET_PASSWORD,
  SAVE_USER_DATA,
  CLEAR_MESSAGE,
  SHOW_GROWL_MESSAGE,
  SET_USER_TOKEN,
  CHECK_USER_TOKEN,
  USER_TOKEN_EXPAIRED,
  USER_PRE_AUTH,
  USER_PRE_AUTH_SUCCESS,
  USER_PRE_AUTH_FAILED,
  CHECK_USER_TOKEN_FROM_COOKIES,
  CHECK_USER_TOKEN_FROM_COOKIES_SUCCESS,
  CHECK_USER_TOKEN_FROM_COOKIES_FAILED,
  CHECK_USER_EMAIL_EXIST,
  CHECK_USER_EMAIL_EXIST_SUCCESS,
  CHECK_USER_EMAIL_EXIST_FAILED,
  CHECK_USER_ACTIVATE_LINK,
  CHECK_USER_ACTIVATE_LINK_RESULT, CLEAR_STORAGE, SET_IS_AUTH_FINISHED
} from "../../constants/ActionTypes";

export const userSignUp = (newUserData) => {
  return {
    type: SIGNUP_USER,
    payload: newUserData
  };
};

export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};

export const showGrowlMessage = (payload) => {
  return {
    type: SHOW_GROWL_MESSAGE,
    payload
  };
};

export const setUserToken = (payload) => {
  return {
    type: SET_USER_TOKEN,
    payload
  };
};

export const resetInstructions = (email) => {
  return {
    type: RESET_INSTRUCTIONS,
    email
  };
};

export const resetPassword = (payload) => {
  return {
    type: RESET_PASSWORD,
    payload
  };
};

export const authUserSMSSuccess = (payload) => {
  return {
    type: CONFIRM_USER_SMS_SUCCESS,
    payload
  };
};

export const userSignUpSuccess = (authUserData) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUserData
  };
};

export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};

export const saveUserData = (payload) => {
  return {
    type: SAVE_USER_DATA,
    payload
  };
};

export const userLogInDataSave = (userLogInData) => {
  return {
    type: USER_LOG_IN_DATA_SAVE,
    payload: userLogInData
  };
};

export const userLogInDataClear = () => {
  return {
    type: USER_LOG_IN_DATA_CLEAR,
    payload: ""
  };
};

export const userSignInSuccess = (authUser) => {
  localStorage.setItem("userSignedIn", true)
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  }
};

export const userSignOut = (payload) => {
  return {
    type: SIGNOUT_USER,
    payload
  };
};

export const userSignOutSuccess = (payload) => {
  // window.location.reload();
  localStorage.removeItem("userSignedIn")
  return {
    type: SIGNOUT_USER_SUCCESS,
    payload
  }
};

export const showAuthMessage = (payload) => {
  return {
    type: SHOW_MESSAGE,
    payload
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const checkUserToken = (payload) => {
  return {
    type: CHECK_USER_TOKEN,
    payload
  };
};

export const userTokenExpaired = (payload) => {
  return {
    type: USER_TOKEN_EXPAIRED,
    payload
  };
};

export const preAuthUser = (userData) => {
  return {
    type: USER_PRE_AUTH,
    userData
  };
};

export const preAuthUserSuccess = (userData) => {
  return {
    type: USER_PRE_AUTH_SUCCESS,
    userData
  };
};

export const preAuthUserFailed = (payload) => {
  return {
    type: USER_PRE_AUTH_FAILED,
    payload
  };
};

/**
 * Check if user has activated his account from email verification.
 *
 * @param userTokenFromCookies
 * @returns {{userTokenFromCookies, type: string}}
 */
export const checkUserTokenActive = (userTokenFromCookies) => {
  return {
    type: CHECK_USER_TOKEN_FROM_COOKIES,
    userTokenFromCookies
  };
};

export const checkUserTokenActiveSuccess = (tokenStatus) => {
  return {
    type: CHECK_USER_TOKEN_FROM_COOKIES_SUCCESS,
    tokenStatus
  };
};

export const checkUserTokenActiveFailed = (tokenStatus) => {
  return {
    type: CHECK_USER_TOKEN_FROM_COOKIES_FAILED,
    tokenStatus
  };
};

export const checkUserExist = (payload) => {
  return {
    type: CHECK_USER_EMAIL_EXIST,
    payload
  };
};

export const checkUserExistSuccess = (payload) => {
  return {
    type: CHECK_USER_EMAIL_EXIST_SUCCESS,
    payload
  };
};

export const checkUserExistFailed = (payload) => {
  return {
    type: CHECK_USER_EMAIL_EXIST_FAILED,
    payload
  };
};

export const checkUserActiveLinkForExpired = (payload) => {
  return {
    type: CHECK_USER_ACTIVATE_LINK,
    tokenFromActivateLink: payload.token
  };
};

export const checkUserActiveLinkForExpiredResult = (payload) => {
  return {
    type: CHECK_USER_ACTIVATE_LINK_RESULT,
    payload
  };
};

export const clearStorage = () => {
  return {
    type: CLEAR_STORAGE
  };
};

export const setIsAuthFinished = (isFinished) => {
  return {
    type: SET_IS_AUTH_FINISHED,
    payload: isFinished
  };
};
