import {all, fork, put, takeEvery} from "redux-saga/effects";
import {CHECK_STATUS} from "../../constants/ActionTypes";
import {getCheckStatusFailed, getCheckStatusSuccsses} from "../actions";

export function* getCheckUserStatus({payload}) {
  try {
    const {clientEmail, clientID} = payload;

  } catch (err) {
    // console.error(err)
  }
}

export function* getCheckUserStatusFailed(message){
  yield put(getCheckStatusFailed(message))
}

export function* getCheckUserStatusFork() {
  yield takeEvery(CHECK_STATUS, getCheckUserStatus)
}

export default function* rootSaga() {
  yield all([
    fork(getCheckUserStatusFork),
  ]);
}
