import React, { FC } from "react";
import ProductPage from "../../../Components/atomicDesign/templates/ProductPage/ProductPage";
import {RenewalDocumentsText} from "../../../shared/text/Text";
import RenewalDocumentsList from "../../../Components/atomicDesign/templates/RenewalDocumentsList/RenewalDocumentsList";
import { IRenewalDocument } from "../../../Components/atomicDesign/types/Models";
export interface MyAutomaticRenewalsProps {
    documents: IRenewalDocument[],
    onGetSubscription?: () => void
}
const MyAutomaticRenewals: FC<MyAutomaticRenewalsProps> = ({documents, onGetSubscription }) => {

    const listComponent = () => {
        return <RenewalDocumentsList documents={documents} onSubscribe={onGetSubscription}/>
    }

    return (
        <ProductPage
            List={listComponent}
            headerText={RenewalDocumentsText.MyAutomaticRenewals}
            subHeaderText={RenewalDocumentsText.AutomaticRenewalSubHeader}
            subHeaderWidth="520px"
        />
    )
}

export default MyAutomaticRenewals