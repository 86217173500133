import React, { FC } from "react"
import "./GBlock.scss"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS_NEW } from "../../types/Colors"
import { BlockProps, TitleProps } from "./GBlock.props"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"

const Title: FC<TitleProps> = ({ title, hasTitleIcon = false, titleIcon }) => {
  const breakpoint = useBreakpoint()

  return (
    <div className={"GBlock__Review"}>
      <div className={"GBlock__Review__Alert"}>
        <div className={"GBlock__Review__Alert__Counter"}>
          {hasTitleIcon && <div className={"GBlock__Review__Alert__Counter__Icon"}>{titleIcon}</div>}
          <GText
            text={title as string}
            size={breakpoint.xs ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_22}
            color={COLORS_NEW.BLACK_1000}
          />
        </div>
      </div>
    </div>
  )
}

const GBlock: FC<BlockProps> = ({ title, children, hasTitleIcon = false, titleIcon }) => {
  return (
    <div className={"GBlock"}>
      <div className={"GBlock__Column"}>
        {title && (
          <div className={"GBlock__Column__Title"}>
            <Title title={title} hasTitleIcon={hasTitleIcon} titleIcon={titleIcon} />
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

export default GBlock
