import React, {FC} from "react";
import "./FamilyMembersTeaser.scss"
import {ICONS_SVG_TYPE} from "../../../Components/atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import GButton from "../../../Components/atomicDesign/molecules/Button/GButton";
import {FamilyMembersText} from "../../../shared/text/Text";
import ProductPage from "../../../Components/atomicDesign/templates/ProductPage/ProductPage";
import FamilyMembersTeaserImage from '../../../assets/image/FamilyMembersSubscriptionTeaser.webp'
import {SIZE} from "../../../Components/atomicDesign/types/types";
const FamilyMembersTeaser: FC = () => {
    const listComponent = () => {
        return (
            <div className={"GFamilyMembersTeaser"}>
                <img src={FamilyMembersTeaserImage} alt={"Family members cards photo"} />
            </div>
        )
    }

    const footer = () => {
        return (
            <div className={"GFamilyMembersTeaser__Footer"}>
                <GButton text={FamilyMembersText.TeaserButton} textSize={SIZE.PARAGRAPH_BOLD_16} />
            </div>
        )
    }

    return (
        <ProductPage
            List={listComponent}
            headerText={FamilyMembersText.TeaserHeader}
            subHeaderText={FamilyMembersText.TeaserSubHeader}
            Footer={footer}
        />
    )
}

export default FamilyMembersTeaser