import React from 'react';
import GText from "../../atomicDesign/atoms/Text/Text";
import {SIZE} from "../../atomicDesign/types/types";
import {COLORS_NEW} from "../../atomicDesign/types/Colors";
import SubscriptionAboutIcon from "./SunbscriptionAboutLogo";
import './style.scss';
import {getPropertiesForEvents} from "../../../services/gtm/backEndEvent";
import StringHelper from "../../../util/StringHelper";

interface ContentSectionInterface {
    sectionId: string;
    sectionHeadline: string;
    options: {label: string, id: string}[];
    icon: boolean;
}
const SubscriptionAbout: React.FC<{ contentSections: ContentSectionInterface[] }> = ({ contentSections }) => {
    const { service, solutionName, solution } = getPropertiesForEvents()
    const capitalizedService = StringHelper.capitalizeServiceName(service)

    const getSectionHeader = ((header: string) => {
        return header.replace("{solution}", solutionName).replace("{service}", capitalizedService)
    })
    return (
        <>
            <div className="content-sections">
                {contentSections.slice(0, 2).map((section: ContentSectionInterface, index) => (
                    <section key={section.sectionId} className={`section ${section.sectionId}`}>

                        <div className="section-header">
                            {section.icon && <SubscriptionAboutIcon/>}
                            <GText
                                text={`${index > 0 ? "+ " : ''} ${getSectionHeader(section.sectionHeadline)}`}
                                size={SIZE.PARAGRAPH_BOLD_20}
                                color={COLORS_NEW.BLACK_1000}
                            />
                        </div>

                        {section.options && (
                            <ul className="chip-list">
                                {section.options.map(({label, id}) => (
                                    id !== solution &&
                                    <li key={id}>
                                        <SubscriptionAboutIcon />
                                        <GText
                                            text={label}
                                            size={SIZE.PARAGRAPH_BOLD_16}
                                            color={COLORS_NEW.BLACK_800}
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </section>
                ))}

                <section className="section">
                    <div className="section-header">
                        <GText
                            text={"+ " + contentSections[2].sectionHeadline}
                            size={SIZE.PARAGRAPH_BOLD_20}
                            color={COLORS_NEW.BLACK_1000}
                        />
                    </div>

                    <ul className="chip-list">
                        <li>
                            <SubscriptionAboutIcon/>
                            <div className="chip-text">
                                <GText
                                    text={'Automatic renewal'}
                                    size={SIZE.PARAGRAPH_BOLD_16}
                                    color={COLORS_NEW.BLACK_800}
                                />
                                <b>&nbsp;</b>
                                <GText
                                    text={'of your documents before they expire'}
                                    size={SIZE.PARAGRAPH_REGULAR_16}
                                    color={COLORS_NEW.BLACK_800}
                                />
                            </div>
                        </li>
                        <li>
                            <SubscriptionAboutIcon/>
                            <div className="chip-text">
                                <GText
                                    text={'Insurance replacement'}
                                    size={SIZE.PARAGRAPH_BOLD_16}
                                    color={COLORS_NEW.BLACK_800}
                                />
                                <b>&nbsp;</b>
                                <GText
                                    text={'against lost, stolen, or damaged documents'}
                                    size={SIZE.PARAGRAPH_REGULAR_16}
                                    color={COLORS_NEW.BLACK_800}
                                />
                            </div>
                        </li>
                        <li>
                            <SubscriptionAboutIcon/>
                            <div className="chip-text">
                                <GText
                                    text={'Expedited shipping and processing'}
                                    size={SIZE.PARAGRAPH_BOLD_16}
                                    color={COLORS_NEW.BLACK_800}
                                />
                                <b>&nbsp;</b>
                                <GText
                                    text={'available on-demand'}
                                    size={SIZE.PARAGRAPH_REGULAR_16}
                                    color={COLORS_NEW.BLACK_800}
                                />
                            </div>
                        </li>
                        <li>
                            <SubscriptionAboutIcon/>
                            <div className="chip-text">
                                <GText
                                    text={'Expert review'}
                                    size={SIZE.PARAGRAPH_BOLD_16}
                                    color={COLORS_NEW.BLACK_800}
                                />
                                <b>&nbsp;</b>
                                <GText
                                    text={'to avoid mistakes and prevent delays'}
                                    size={SIZE.PARAGRAPH_REGULAR_16}
                                    color={COLORS_NEW.BLACK_800}
                                />
                            </div>
                        </li>
                        <li>
                            <SubscriptionAboutIcon/>
                            <GText
                                text={'24/7 support in seconds'}
                                size={SIZE.PARAGRAPH_BOLD_16}
                                color={COLORS_NEW.BLACK_800}
                            />
                        </li>
                    </ul>
                </section>
            </div>
        </>
    );
};

export default SubscriptionAbout;