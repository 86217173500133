import {HEADER_PRODUCT_IMAGE_TYPE, IHeader} from "../Header";
import {FC} from "react";
import React from "react";
import "./GHeaderPayment.scss"
import GImageTitle from "../../../molecules/ImageTitle/GImageTitle";
import productsGroupImage from "../../../../../assets/image/product-group-gradient-carousel.webp"
import productsGroupImageMobile from "../../../../../assets/image/product-group-gradient-carousel-mobile.webp"
import {getPropertiesForEvents} from "../../../../../services/gtm/backEndEvent";
import {COLORS, COLORS_NEW} from "../../../types/Colors";
import GImageDescription from "../../../molecules/ImageDescription/GImageDescription";
import GBenefitsList from "../../../organisms/BenefitsList/GBenefitsList";
import GProgressBar from "../../../molecules/ProgressBar/ProgressBar";
import GText from "../../../atoms/Text/Text";
import { SIZE } from "../../../types/types";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import ToApplyFormIdRepository from "../../../../../persistence/session/ToApplyFormIdRepository";

type GHeaderPaymentProps = {
    props: IHeader,
}

const GHeaderPayment: FC<GHeaderPaymentProps> = (content) => {
    const breakPoint = useBreakpoint();
    const headerContent: IHeader = content.props;
    const webFlowServicesData = JSON.parse(localStorage.getItem("webFlowSolutionData") ?? "{}") ?? {}

    let linkPhotoProcessingSteps = ""
    let shadow = true
    const {solution, formType} = getPropertiesForEvents()

    if (ToApplyFormIdRepository.getInstance().getValue()) {
        linkPhotoProcessingSteps =
            webFlowServicesData[ToApplyFormIdRepository.getInstance().getValue() ?? ""]?.illustrationM.url
    } else {
        linkPhotoProcessingSteps = solution
            ? webFlowServicesData[solution]?.illustrationM.url
            : webFlowServicesData[formType.toUpperCase()]?.illustrationM.url

        shadow = solution ? true : formType.toUpperCase() !== "GLO"
    }
    let image: React.CSSProperties = {
        maxHeight: "150px",
        width: "auto",
        boxShadow: shadow ? "0px 8px 32px 8px rgba(0, 0, 0, 0.08)" : "none"
    }

    const productsGroupImgCarousel = !breakPoint.xs ? productsGroupImage : productsGroupImageMobile;

    return (
        <div className="GHeaderPayment">
            <GImageTitle
                title={headerContent.title}
                imageStyle={image}
                image={linkPhotoProcessingSteps}
                titleSize={SIZE.HEADING_LG}
            />
            <GProgressBar value={90} customText={"Ready to file"} showIcon={false} color={COLORS_NEW.GREEN_600} showIconRight />
            <GText text={"+"} size={SIZE.HEADING_MDLG} color={COLORS_NEW.BLACK_1000} />
            <GImageDescription titleSize={SIZE.HEADING_MDLG} title={headerContent.title2} image={productsGroupImgCarousel} description={headerContent.description} />
            <GText text={"+"} size={SIZE.HEADING_MDLG} color={COLORS_NEW.BLACK_1000} />
            <GBenefitsList props={headerContent.benefits}></GBenefitsList>
        </div>
    )
}

export default GHeaderPayment