/* eslint-disable */
// fos separateStaticListOption from json
import { setValueCurrentField } from "../../../../../Redux/store/form/form.actions"
import { setCashState } from "../../../../../Redux/store/cash/cash.action"

export const getCheckStaticOptions = (staticOptions: {} | [], name: string, getWrapperStaticOptions: any) =>
  getWrapperStaticOptions(staticOptions, name)?.[name] ?? getWrapperStaticOptions(staticOptions, name)?.fields

const getCurrentArrayOptionsData = (staticOptions: {} | [] | any, fieldName: string, getWrapperStaticOptions: any) =>
  Array.isArray(staticOptions) ? getWrapperStaticOptions(staticOptions, fieldName)?.data : staticOptions[fieldName].data

const getCurrentOptionData = (currentValue: string, currentArrayOptionsData: []) =>
  currentArrayOptionsData.find((option: { name: string }) => option.name === currentValue)

const getCurrentSendField = (
  currentValue: string,
  staticOptions: {} | [] | any,
  fieldName: string,
  getWrapperStaticOptions: any
) =>
  Array.isArray(staticOptions)
    ? getWrapperStaticOptions(staticOptions, fieldName)?.fields.find(({ name }: { name: string }) => name === fieldName)
        .sendField
    : staticOptions[fieldName]?.sendField

const getShortValue = (currentDataOption: {} | any, currentSendField: string) => currentDataOption[currentSendField]

export const dispatchCurrentOption = (
  currentValue: string,
  staticOptions: {} | [],
  fieldName: string,
  getWrapperStaticOptions: any,
  submitObject: {} | any,
  submitAdditionalOption: string | undefined,
  trimOption: any,
  dispatch: any
) => {
  const currentArrayOptionsData = getCurrentArrayOptionsData(staticOptions, fieldName, getWrapperStaticOptions)
  const currentDataOption = getCurrentOptionData(currentValue, currentArrayOptionsData)
  const currentSendField = getCurrentSendField(currentValue, staticOptions, fieldName, getWrapperStaticOptions)
  const shortValue = getShortValue(currentDataOption, currentSendField)

  dispatch(setValueCurrentField(`${fieldName}__full_name`, `${currentValue}`))
  dispatch(
    setCashState({
      ...submitObject,
      [fieldName]: trimOption(`${shortValue}`, submitAdditionalOption, dispatch),
      [`${fieldName}__full_name`]: `${currentValue}`,
    })
  )

  return shortValue
}
