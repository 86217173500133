import Event from "../Event"
import {EventOptions, EventProps} from "../EventProperties"
import IEvent from "../IEvent";

const EVENT_NAME = "Subscription Cancelled";

/**
 * Account Activated Event
 * @class SubscriptionCancelled
 */
export default class SubscriptionCancelled extends Event implements IEvent {
    private readonly _subscriptionId: string | undefined;
    private readonly _subscriptionExternalId: string | undefined;
    private readonly _subscriptionPlan: string | undefined;
    private readonly _subscriptionFrequency: string | undefined;
    private readonly _subscriptionPrice: string | undefined;
    private readonly _subscriptionStatus: string | undefined;
    private readonly _subscriptionStartDate: string | undefined;
    private readonly _subscriptionCancellationReason: string | undefined;
    private readonly _subscriptionCancellationChannel: string | undefined;


    constructor(
        subscriptionId: string | undefined,
        ipAddress: string | undefined,
        subscriptionExternalId: string | undefined,
        subscriptionPlan: string | undefined,
        subscriptionFrequency: string | undefined,
        subscriptionPrice: string | undefined,
        subscriptionStatus: string | undefined,
        subscriptionStartDate: string | undefined,
        subscriptionCancellationReason: string | undefined,
        subscriptionCancellationChannel: string | undefined
    ) {
        super(EVENT_NAME, ipAddress);
        this._subscriptionId = subscriptionId;
        this._subscriptionExternalId = subscriptionExternalId;
        this._subscriptionPlan = subscriptionPlan;
        this._subscriptionFrequency = subscriptionFrequency;
        this._subscriptionPrice = subscriptionPrice;
        this._subscriptionStatus = subscriptionStatus;
        this._subscriptionStartDate = subscriptionStartDate;
        this._subscriptionCancellationReason = subscriptionCancellationReason;
        this._subscriptionCancellationChannel = subscriptionCancellationChannel;
    }

    /**
     * Get event data
     * @returns {EventProps}
     * @description This method is used to get event data
     */
    getEventData(): EventProps {
        return {
            ...super.baseEventData(),
            subscription_id: this._subscriptionId,
            subscription_external_id: this._subscriptionExternalId,
            subscription_plan: this._subscriptionPlan,
            subscription_frequency: this._subscriptionFrequency,
            subscription_price: this._subscriptionPrice,
            subscription_status: this._subscriptionStatus,
            subscription_start_date: this._subscriptionStartDate,
            subscription_cancellation_reason: this._subscriptionCancellationReason,
            subscription_cancellation_channel: this._subscriptionCancellationChannel
        }
    }

    /**
     * Get event name
     * @returns {string}
     * @description This method is used to get event name
     */
    getEventName(): string {
        return this.eventName;
    }

    /**
     * Get page name
     * @returns {string}
     * @description This method is used to get page name
     */
    pageName(): string {
        return "";
    }

    /**
     * Get previous id
     * @returns {string}
     * @description This method is used to get previous id
     */
    getPreviousId(): string {
        return "";
    }

    /**
     * Get user id
     * @returns {string}
     * @description This method is used to get user id
     */
    getUserId(): string {
        return "";
    }

    getEventOptions(): EventOptions | undefined {
        return undefined;
    }
}
