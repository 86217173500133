import React from "react";
import ShippingInfoCard, {SHIPPING_STATUSES} from "./ShippingInfoCard";
import DeliveredInfoBox from "../DeliveredInfoBox";
import './index.less'
import VA_Benefits from '../../assets/images/agencies/va_benefits.png'
import TAX_RETURN from '../../assets/images/agencies//tax_return.png'

const ShippingInfoBox = ({productInformation}) => {

  const {
    applicationNamStatus,
    applicationNamDateApply,
    applicationNamHasVa,
    applicationNamHasIrs,
    trackingNum,
    trackingUri,
    address: {address1, city_name, zipcode, country_code},
    provider
  } = productInformation || {}

  return (
    <div className={'GShippingInfoBox__Container'}>
      {applicationNamHasVa && <ShippingInfoCard
        headerText={'Name change status with the Department of Veteran Affairs'}
        image={VA_Benefits}
        submissionDate={applicationNamDateApply}
        status={SHIPPING_STATUSES.COMPLETED}
      />}
      {applicationNamHasIrs && <ShippingInfoCard
        headerText={'Name change status with the Internal Revenue Service'}
        image={TAX_RETURN}
        submissionDate={applicationNamDateApply}
        status={SHIPPING_STATUSES.COMPLETED}
      />}
      <ShippingInfoCard
        headerText={'Social Security Card and Passport Name change Document Box Tracking'}
      >
        <DeliveredInfoBox
          trackNumber={trackingNum}
          deliverAddress={`${address1}, ${city_name} ${zipcode}, ${country_code}`}
          redirectLink={trackingUri}
          provider={provider}
        />
      </ShippingInfoCard>
    </div>
  );
}

export default ShippingInfoBox;