import React from 'react';
import { SvgType } from '../SvgType';

export const SvgRejectedStatus = ({width, height}: SvgType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9985 36C15.9985 36 31.2985 28.8 31.2985 18V5.4L15.9985 0L0.698486 5.4V18C0.698486 28.8 15.9985 36 15.9985 36Z" fill="#EDF2F5"/>
      <path d="M20.4988 13.5L11.4988 22.5" stroke="#B2B2B2" strokeWidth="1.88" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.4988 13.5L20.4988 22.5" stroke="#B2B2B2" strokeWidth="1.88" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

