import React from "react";
import { SvgType } from "../SvgType";

export const SvgAlertTriangle = ({width = 25, height = 24, color = "#F54254"} : SvgType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.7888 3.8602L2.3188 18.0002C2.14417 18.3026 2.05177 18.6455 2.05079 18.9947C2.04981 19.3439 2.14029 19.6873 2.31323 19.9907C2.48616 20.2941 2.73553 20.547 3.03651 20.7241C3.33749 20.9012 3.6796 20.9964 4.0288 21.0002H20.9688C21.318 20.9964 21.6601 20.9012 21.9611 20.7241C22.2621 20.547 22.5114 20.2941 22.6844 19.9907C22.8573 19.6873 22.9478 19.3439 22.9468 18.9947C22.9458 18.6455 22.8534 18.3026 22.6788 18.0002L14.2088 3.8602C14.0305 3.56631 13.7795 3.32332 13.48 3.15469C13.1805 2.98605 12.8425 2.89746 12.4988 2.89746C12.1551 2.89746 11.8171 2.98605 11.5176 3.15469C11.2181 3.32332 10.9671 3.56631 10.7888 3.8602Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 9V13" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 17H12.51" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}