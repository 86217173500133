import { ArrayBlockType, BlockInterface } from "./statusesInterfaces"

// get current state block

const getCompareIndex = (currentIndexPage: number, currentIndexBlock: number) =>
  currentIndexPage < currentIndexBlock ? "" : currentIndexPage > currentIndexBlock ? "completed" : "process"

const arrayIncludes = (arrayA: string | string[], arrayB: string[]) => {
  if (Array.isArray(arrayA) && Array.isArray(arrayB))
    return arrayA.filter(element => arrayB.includes(element)).length > 0
  return false
}

export const getStatusBlock = (
  arrayBlocks: ArrayBlockType,
  statusCurrentBlock: string | string[],
  statusSignaturePage: string
) => {
  if (!statusCurrentBlock) {
    return "completed"
  }
  return getCompareIndex(
    arrayBlocks.findIndex(({ status }: BlockInterface) => typeof status === "string" ? status === statusSignaturePage : status.includes(statusSignaturePage)) + 1,
    arrayBlocks.findIndex(({ status }: BlockInterface) => typeof statusCurrentBlock === "string" ? status === statusCurrentBlock : arrayIncludes(status, statusCurrentBlock)) + 1
  )
}
