/* eslint-disable indent */
import React, {FC} from "react";
import Select from "../../molecules/Select/Select";
import {useDate} from "./useDate";
import "./DateSelectorDropdownGroup.scss"
import {Form} from "antd";

type DataSelectorDropdownGroupProps = {
    fieldName: string,
    isBefore?: boolean,
    totalYearsShow?: number,
    onChangeValue?: any
}
const formStyles : React.CSSProperties = {
    width: '100%'
}
const DateSelectorDropdownGroup: FC<DataSelectorDropdownGroupProps> = ({fieldName, isBefore, totalYearsShow, onChangeValue}) => {
    const {setSelectedMonth, days, months, years} = useDate(isBefore, totalYearsShow)
    function onChangeAnyField(value: string, component: string){
        if(component == 'month'){
            setSelectedMonth(value)
        }
        onChangeValue && onChangeValue()
    }
   
    return <div className={"GDateSelectorDropdownGroup"}>
        <Form.Item name={[fieldName, 'month']} style={formStyles} rules={[{ required: true, message: "Required" }]}>
            <Select placeHolder={"Month"} options={months} onChange={(e: string)=>onChangeAnyField(e, 'month')}/>
        </Form.Item>
        <Form.Item name={[fieldName, 'day']} style={formStyles} rules={[{ required: true, message: "Required" }]}>
            <Select placeHolder={"Day"}  options={days} onChange={(e: string)=>onChangeAnyField(e, 'day')}/>
        </Form.Item>
        <Form.Item name={[fieldName, 'year']} style={formStyles} rules={[{ required: true, message: "Required" }]}>
            <Select placeHolder={"Year"} options={years} onChange={(e: string)=>onChangeAnyField(e, 'year')} />
        </Form.Item>
    </div>
}

export default DateSelectorDropdownGroup;