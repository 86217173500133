import React from 'react';
import {Radio} from 'antd';
import GText from "../../../atomicDesign/atoms/Text/Text";
import {COLORS, COLORS_NEW} from "../../../atomicDesign/types/Colors";
import {SIZE} from "../../../atomicDesign/types/types";
import GLabelIcon from "../../../atomicDesign/molecules/LabelIcon/LabelIcon";
import './style.scss';
import GTag from "../../../atomicDesign/molecules/Tag/GTag";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

export interface SubscriptionPaymentItemProps {
    method: string;
    price: number;
    saleLabel?: string | null;
    salePrice?: number | null;
    saleName?: string | null;
    yearPrice?: number | null;
    showBestPriceLabel?: boolean | null;
    showPeriodSuffix?: boolean;
    isMostPopular?: boolean;
}

const SubscriptionPaymentItem: React.FC<SubscriptionPaymentItemProps> = ({
                                                                             method,
                                                                             price,
                                                                             yearPrice,
                                                                             salePrice,
                                                                             saleLabel,
                                                                             saleName,
                                                                             showBestPriceLabel,
                                                                             showPeriodSuffix = true,
                                                                             isMostPopular
                                                                         }) => {
    const breakPoint = useBreakpoint();

    const period = showPeriodSuffix ? !breakPoint.xs ? '/month' : '/mo' : '';

    const saleNameProcessing = saleName?.split('-');

    return (
        <Radio value={method} className={'GSubscriptionMethodRadioButton'} style={{background: '#EEF4FB'}}>
            {isMostPopular &&
                <div className="most-popular-tag">
                    <GLabelIcon>
                        <GText
                            text={'Most Popular'}
                            size={SIZE.PARAGRAPH_BOLD_16}
                            color={COLORS.WHITE}
                        />
                    </GLabelIcon>
                </div>
            }
            {showBestPriceLabel &&
                <div className="best-value-badge">
                    <GLabelIcon>
                        <GText text={'Best value'} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS.WHITE}/>
                    </GLabelIcon>
                </div>}
            <div
              className="content"
              style={{
                paddingTop: breakPoint.xs && !showBestPriceLabel && saleLabel ? '20px' : '32px'
              }}
            >
                <div style={{display: "flex", gap: 12, justifyContent: "space-between"}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                        alignItems: "flex-start"
                    }}>
                        {saleNameProcessing && saleNameProcessing.length == 2 && (
                            <div style={{
                                display: "flex",
                                flexDirection: breakPoint.md ? "row" : "column",
                                justifyContent: "center",
                            }}>
                                <GText
                                    text={saleNameProcessing[0]}
                                    size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16}
                                    color={COLORS_NEW.BLACK_800}
                                    style={{paddingTop: breakPoint.md ? 0 : 10}}

                                />
                                {breakPoint.md && (
                                    <GText
                                        text={'\u00A0-\u00A0'}
                                        size={SIZE.PARAGRAPH_REGULAR_16}
                                    />
                                )}
                                <GText
                                    text={saleNameProcessing[1]}
                                    size={SIZE.PARAGRAPH_REGULAR_16}
                                    color={COLORS_NEW.BLACK_}
                                    style={{
                                        textDecoration: "underline"
                                    }}
                                />
                            </div>
                        )}
                        {saleName && (!saleNameProcessing || saleNameProcessing.length == 1) && (
                            <GText
                                text={saleName || `Paid ${method}`}
                                size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16}
                                color={COLORS_NEW.BLACK_800}
                                style={{paddingTop: breakPoint.md ? 0 : 10}}
                            />
                        )}
                        {
                            saleLabel && (
                                <GTag
                                    text={saleLabel}
                                    sizeText={SIZE.PARAGRAPH_BOLD_14}
                                    colorBackground={COLORS_NEW.GREEN_600}
                                    colorText={COLORS.WHITE}
                                />
                            )
                        }
                    </div>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      minWidth: 50
                    }}>
                        <div className={"prices"}>
                            {
                                salePrice ? (
                                <>
                                    <GText
                                        text={`$${salePrice}${!breakPoint.xs ? '' : `${period}`}`}
                                        size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16}
                                        color={COLORS_NEW.BLACK_800}
                                    />
                                    <GText
                                        text={`$${price}${period}`}
                                        size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16}
                                        color={COLORS_NEW.BLACK_800}/>
                                </>
                                ) : (
                                    <GText
                                        text={`$${price}${period}`}
                                        size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16}
                                        color={COLORS_NEW.BLACK_800} style={{textDecoration: "none"}}
                                    />
                                )
                            }
                        </div>
                        {
                          yearPrice && !breakPoint.xs && (
                                <div style={{textAlign: "right", marginTop: "4px"}}>
                                    {
                                      <GText
                                          text={`$${yearPrice} billed yearly`}
                                          size={SIZE.PARAGRAPH_REGULAR_14}
                                          color={COLORS_NEW.BLACK_600}
                                      />
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>

                {
                  yearPrice && breakPoint.xs && (
                        <div style={{textAlign: "right"}}>
                            {
                              <GText
                                  text={`$${yearPrice} billed yearly`}
                                  size={SIZE.PARAGRAPH_REGULAR_14}
                                  color={COLORS_NEW.BLACK_600}
                              />
                            }
                        </div>
                    )
                }
            </div>
        </Radio>
    );
};

export default SubscriptionPaymentItem;

