import React from "react"
import { connect } from "react-redux"
import { Radio } from "antd"
import { FieldInterface } from "../../../../../../Redux/InterfacesEntity/field.interface"
import RadioCircleBillingAddressComponent from "./RadioCircleBillingAddressComponent"
import GRadioButton from "../../../../../atomicDesign/molecules/RadioButton/RadioButton";

type RadioCircleComponentProps = {
  field: FieldInterface,
  option: {} | any,
  handlerMouseEnterOnOption: any,
  dynamicHelper: boolean,
}

// eslint-disable-next-line max-len
const RadioCircleComponent: React.FunctionComponent<RadioCircleComponentProps> = ({
  field,
  option,
  handlerMouseEnterOnOption,
  dynamicHelper,
}) => {
  const billingAdress = !!(field && field.name && field.name.indexOf("billing_address") !== -1)
  return (
    <>
      {!billingAdress && (
        <GRadioButton
          value={option.optionName}
          label={option.optionName}
          onMouseEnter={() => handlerMouseEnterOnOption(option, dynamicHelper)}
        />
      )}
      {billingAdress && <RadioCircleBillingAddressComponent option={option} />}
    </>
  )
}

const mapStateToProps = (state: any) => ({})

export default connect(mapStateToProps)(RadioCircleComponent)
