import React, {useEffect, useState} from "react"
import "./index.less";
import {useHistory} from "react-router-dom";
import Header from "../Header";
import {useDispatch, useSelector} from "react-redux";
import {
  authUserSMSSuccess,
  clearMessage,
  setCheckStatusEmailProductId,
  userSignUp
} from "../../appRedux/actions";
import "../../../src/containers/SignIn/index.less"
import {userService} from "../../api/api";
import GVerificationCode
  from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/VerificationCode/VerificationCode";
import {Form} from "antd";
import {verificationHelper} from "./helper";
import {notifyError} from "@dash/gov-plus__front-end__form/src/util/helper";
import OuterIdRepository from "@dash/gov-plus__front-end__form/src/persistence/session/OuterIdRepository";
import {USER_IP} from "@dash/gov-plus__front-end__form/src/persistence/session/UserIpRepository";
import SessionStorageHandler from "@dash/gov-plus__front-end__form/src/persistence/SessionStorageHandler";
import GLogo from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Logo/Logo";

const VerificationCode = () => {
  const {loader, userData: {password, eMail, phone, smsToken}, alertMessage} = useSelector(({auth}) => auth);
  const [showValidation, setShowValidation] = useState(false);
  const [resendMessageShowStatus, setResendMessageShowStatus] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");
  const [resendSmsToken, setResendSmsToken] = useState(null);
  const [resendSmsTimer, setResendSmsTimer] = useState(null);
  const [resendSmsTimerStatus, setResendSmsTimerStatus] = useState(false);
  const [formLoader, setFormLoader] = useState(false)
  const history = useHistory();
  const {location} = history
  const dispatch = useDispatch();
  const [formVerificationCode] = Form.useForm()

  const resendTimerCount = 10;

  const resendVerificationCode = async () => {
    try {
      const userTokenWithoutOtpToken = localStorage.getItem("signInOuterId");
      const userPhoneNumber = localStorage.getItem("phone");
      if (userTokenWithoutOtpToken) {
        const regenerateSMS = await userService.generateSms(null, userTokenWithoutOtpToken);
        setResendSmsToken(regenerateSMS.data.data.smsToken)
        setResendMessageShowStatus(true)
        setResendSmsTimerStatus(true);
        setResendSmsTimer(resendTimerCount);
      }
      if (userPhoneNumber) {
        const regenerateSMS = await userService.generateSms(userPhoneNumber, null);
        setResendSmsToken(regenerateSMS.data.data.smsToken)
        setResendMessageShowStatus(true)
        setResendSmsTimerStatus(true);
        setResendSmsTimer(resendTimerCount);
      }
    } catch (err) {
      notifyError(err);
    }
  }

  useEffect(() => {
    const timerStart = setTimeout(() => {
      setResendSmsTimer(resendSmsTimer - 1);
    }, 1000);

    if (resendSmsTimer < 1) {
      setResendSmsTimerStatus(false);
      setResendMessageShowStatus(false);
      setResendSmsTimer(resendTimerCount);
    }
    return () => {
      clearTimeout(timerStart);
    }
  }, [resendSmsTimerStatus, resendSmsTimer]);

  const onFinish = ({verification_code, rememberMe}) => {
    setFormLoader(true)
    if (!verification_code || verification_code.length < 6) {
      setShowValidation(true);
      setValidationMsg("Please fill data.");
      setFormLoader(false)
    } else {
      if (localStorage.getItem("phoneSlice")) {
        dispatch(authUserSMSSuccess({
          smsCode: verification_code,
          outerId: OuterIdRepository.getInstance().getValue(),
          email: eMail,
          password: password,
          rememberMe: rememberMe,
          smsToken: resendSmsToken,
        }))
        setFormLoader(false)
      } else {
        dispatch(userSignUp({
          email: eMail,
          password,
          phone,
          smsToken: resendSmsToken ? resendSmsToken : smsToken,
          sms: verification_code,
          rememberMe: rememberMe !== undefined,
        }));
        setFormLoader(false)
      }
    }
  };

  useEffect(() => {
    if (password === "" && eMail === "") {
      history.push('/signin');
      for (let key in localStorage) {
        if (key.split('/')[0] !== "otpToken") {
          localStorage.removeItem(key);
        }
      }
      SessionStorageHandler.clearIgnoringKey([USER_IP]);
    }
  }, [password, eMail])

  useEffect(() => {
    if (alertMessage === "common.WRONG_SMS_PROVIDED" || alertMessage === "common.SMS_OR_TOKEN_INVALID") {
      setShowValidation(true);
      setValidationMsg("Incorrect code. Please try again.");
      dispatch(clearMessage())
    }
    if (alertMessage === "common.CREATED_SUCCESSFULLY" ){
      history.push('/welcomepage');
    }
    if (alertMessage === "common.SUCCESSFUL_REQUEST"){
      history.push('/welcomepage');
    }
  }, [alertMessage])

  const dispatchClearMessage = () => {
    dispatch(clearMessage())
    if(location.state === "confirmPhone") {
      dispatch(setCheckStatusEmailProductId({productIdFromEmailCheckStatus: ""}))
    }
    history.push("/signin")
  }

  return (
    <div className="gx-app-login-wrap">
      <div className={'gx-app-login-logo'}>
        <GLogo width={110} height={28} />
      </div>
      <GVerificationCode
          formOnFieldsChange={()=> {
            setShowValidation(false);
          }}
          formOnFinish={onFinish}
          form={formVerificationCode}
          locationState={location.state}
          checkPhoneExist={verificationHelper.checkPhoneExist()}
          resendVerificationCode={resendVerificationCode}
          resendSmsTimerStatus={resendSmsTimerStatus}
          resendSmsTimer={resendSmsTimer}
          onFooterFirstLinkClick={dispatchClearMessage}
          validationMessage={validationMsg}
          showInputValidation={showValidation}
          loader={formLoader}
      />
    </div>
  );
};

export default VerificationCode;
