import React, {FC} from "react";
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import "./ProductPage.scss"
import {Divider} from "antd";
import {COLORS, COLORS_NEW} from "../../types/Colors";

type GProductPageProps = {
    List: FC<any>,
    Footer?: FC<any>,
    headerText: string,
    headerWidth?: string,
    subHeaderText: string,
    subHeaderWidth?: string,
    showDivider?: boolean

}

const ProductPage: FC<GProductPageProps> = (
    {
        List,
        Footer,
        headerText,
        headerWidth = "700px",
        subHeaderText,
        subHeaderWidth = "740px",
        showDivider = false
    }
) => {

    return <div className={"GProductPage"}>
        <div className={"GProductPage__Header"}>
            <GText text={headerText} innerHtml={headerText} size={SIZE.HEADING_XL} color={COLORS_NEW.BLACK_1000} maxWidth={headerWidth} />
            <GText text={subHeaderText} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_500} innerHtml={subHeaderText} maxWidth={subHeaderWidth} />
        </div>
        <div className={"GProductPage__Content"}>
            <List/>
        </div>
        {Footer &&
            <div className={"GProductPage__Footer"}>
                {showDivider && <Divider/>}
                {Footer && <Footer/>}
            </div>
        }
    </div>
}

export default ProductPage;