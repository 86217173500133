import React from "react"

interface ISvgArrow {
    width?: number;
    height?: number;
    color?: string;
}
export const SvgArrowForward = ({
    width = 20,
    height = 20,
    color = "white"
}: ISvgArrow) => {
    return (
        <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3316_40024)">
                <path d="M4.29183 10.0037L15.9585 10.0037" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.1252 4.17031L15.9585 10.0036L10.1252 15.837" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_3316_40024">
                    <rect width={width} height={width} fill={color} transform="translate(0.125)"/>
                </clipPath>
            </defs>
        </svg>

    )
}
