import React, { useEffect } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../../App"
import { loadingSelector } from "../../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { setCurrentBlock } from "../../../../../Redux/store/step/step.actions"
import BlockFormWithFields from "../../../BlockFormWithFields/BlockFormWithFields"
import "./AddressVerificationFormFields.scss"
import GNotification, { NOTIFICATION_TYPE } from "../../../../atomicDesign/organisms/Notification/Notification"
import { ICONS_SVG_TYPE } from "../../../../atomicDesign/atoms/IconsSvg/IconsSvgTypes"
import GButton from "../../../../atomicDesign/molecules/Button/GButton"
import { SIZE } from "../../../../atomicDesign/types/types"

type AddressVerificationFormFieldsProps = {
  blocksStep: { statusBlockStep: string, button: string, status: string, content: any, name: number },
  forceLoadingAndDisabled?: boolean,
  statusSignaturePage: string,
  errorMessage: string,
  currentResponse: any,
}

const AddressVerificationFormFields: React.FunctionComponent<AddressVerificationFormFieldsProps> = ({
  blocksStep,
  forceLoadingAndDisabled = false,
  statusSignaturePage,
  errorMessage,
  currentResponse,
}) => {
  const loadingButtonState = useSelector<RootState, { loadingButtonState: string }>(loadingSelector).loadingButtonState
  const { status: statusBlockStep } = blocksStep

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentBlock(blocksStep))
  }, [])

  useEffect(() => {
    /* Event Processing Step */
  }, [])

  const loadingStatus =
    loadingButtonState === "loading" && (statusBlockStep === statusSignaturePage || forceLoadingAndDisabled)
  const loadingText = loadingStatus ? "Loading" : blocksStep.content.button

  return (
    <div className={`AddressVerificationFormFields`}>
      {blocksStep?.content?.form.formInputs.map((item: any, key: number) => {
        const error = item.text
          .replace("{mailingAddress1}", currentResponse?.verifiedAddress?.original.address1)
          .replace(
            "{mailingAddress2},",
            currentResponse?.verifiedAddress?.original.address2
              ? currentResponse?.verifiedAddress?.original.address2 + ","
              : ""
          )
          .replace("{mailingCity}", currentResponse?.verifiedAddress?.original.city_name)
          .replace("{mailingState}", currentResponse?.verifiedAddress?.original.state_code)
          .replace("{mailingZip}", currentResponse?.verifiedAddress?.original.zipcode)

        return (
          <div key={key}>
            {(!errorMessage || errorMessage === "Unable to verify address.") && (
              <div className={`AddressVerificationFormFields__Information`}>
                <GNotification text={error} type={NOTIFICATION_TYPE.ERROR} icon={ICONS_SVG_TYPE.INFORMATION} />
              </div>
            )}
            {errorMessage && errorMessage !== "Unable to verify address." && (
              <div className={`AddressVerificationFormFields__Information--Error`}>
                <GNotification text={errorMessage} type={NOTIFICATION_TYPE.ERROR} icon={ICONS_SVG_TYPE.INFORMATION} />
              </div>
            )}
            <div className={`AddressVerificationFormFields__Form`}>
              <div className={``} key={item.name}>
                <BlockFormWithFields  blockObject={item} />
              </div>

              <div className={`AddressVerificationFormFields__Form__CtaBtnContainer`}>
                <GButton
                  isSubmit={true}
                  disabled={loadingStatus}
                  text={loadingText}
                  textSize={SIZE.PARAGRAPH_BOLD_16}
                  size={"large"}
                  loading={loadingStatus}
                  showIcon={false}
                />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  errorMessage: state.additional.getFailure,
  currentResponse: state.step.currentResponse,
})

export default connect(mapStateToProps)(AddressVerificationFormFields)
