import {useDispatch, useSelector} from "react-redux";
import {useNavigation} from "../hooks/useNavigation";
import {
    setRetargetingLink,
    setSelectedFormId,
} from "../appRedux/actions";
import {ApplicationStatus} from "@dash/gov-plus__front-end__form/src/util/ApplicationStatus";
import {IInsuranceClaim} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Models";
import {useShouldShowApplications} from "../hooks/useShouldShowApplications";
import { productsApis } from "../api/api";

export const useApplicationServices = () => {

  const dispatch = useDispatch()
  const navigation = useNavigation()
  // @ts-ignore
  const {duplicateApplications, allProducts} = useSelector(({allProducts}) => allProducts);
  const { shouldShowApplication } = useShouldShowApplications();

  const startApplication = (selectedFormId: string) => {
    if (selectedFormId) {
        navigation.navigate(`forms/?formId=${selectedFormId}&&indexCurrentStep=0`)
        dispatch(setRetargetingLink(selectedFormId));
        dispatch(setSelectedFormId(''))
    }
  }
  
  const getApplicationCompletion = (application_id: string, product_id: string, form_id: string) => {
    return productsApis.getApplicationCompletion(application_id, product_id, form_id)
  }

  const filterApplications = () => {

    const productsPage = window.location.pathname.includes('product') || window.location.pathname.includes('forms');
    /**
     * In cancel subscription flow don't show cancelled or completed applications and exclude application made
     * through one time fee payment flow.
     *
     * is_from_subscription attribute represent if application was made through one time fee payment flow. If it's null then
     * application was made through cancel subscription flow.
     */
    let restKeys = Object.keys(allProducts).filter((item) => shouldShowApplication({
        appData: allProducts[item],
        statusesToExclude: productsPage ? [
            ApplicationStatus.STATUS_CANCELED
        ] : [
            ApplicationStatus.STATUS_CANCELED,
            ApplicationStatus.STATUS_COMPLETED,
            ApplicationStatus.STATUS_COMPLETED_TSA_NEW,
            ApplicationStatus.STATUS_COMPLETED_TSA_RENEW,
            ApplicationStatus.STATUS_COMPLETED_SSN
        ]
    }) && (productsPage || allProducts[item]?.is_from_subscription));
    let filteredProducts = {};
    for (let z in restKeys) {
        // @ts-ignore
        filteredProducts[restKeys[z]] = allProducts[restKeys[z]];
    }
    return filteredProducts
}

  return {
    startApplication,
    getApplicationCompletion,
    filterApplications
  }

}

