import React from "react";
import "./index.less"
import SimpleStatusesForProducts from "../../../../simpleStatusesForProducts";
import {useSelector} from "react-redux";
import ShippingInfoBox from "../../../../../../components/ShippingInfoBox";

const ProcessTracking = ({productId}) =>{
  const {
    allProducts,
    allProductsLoader,
    anotherGovProducts,
  } = useSelector(({allProducts}) => allProducts);
  const headerText = "Your application is on its way";
  const descriptionText = "";
  const buttonText = "";

  const productInformation = allProducts[productId].add;

  const productImage = anotherGovProducts?.find(item => item.id === allProducts[productId]?.product_name)?.image;
  const productName = anotherGovProducts?.find(item => item.id === allProducts[productId]?.product_name)?.title;

  return(
    <div className={'GProcessTracking__Container'}>
      <SimpleStatusesForProducts
        iconStatus={allProducts[productId]?.status}
        headerText={headerText}
        description={descriptionText}
        buttonText={buttonText}
        productImage={productImage}
        productName={productName}
        productId={productId}
      />

      <ShippingInfoBox productInformation={productInformation} />

    </div>
  )
}

export default ProcessTracking;
