import React from "react"
import {SIZE} from "../../../atomicDesign/types/types"
import { COLORS, COLORS_NEW } from '../../../atomicDesign/types/Colors';
import GIconsSvgType from "../../../atomicDesign/atoms/IconsSvg/IconsSvg"
import {ICONS_SVG_TYPE} from "../../../atomicDesign/atoms/IconsSvg/IconsSvgTypes"
import "./ProcessingNotarizationComponent.scss"
import GStepApplicationCard, { StepApplicationCardContentAlign } from "../../../atomicDesign/molecules/StepApplicationCard/StepApplicationCard"
import GButton from "../../../atomicDesign/molecules/Button/GButton"

type ProcessingNotarizationComponentProps = {
  nameProduct: string,
  title: string,
  subtitle: string,
  textButton: string,
  onClickBtn: () => void
}

const ProcessingNotarizationComponent: React.FunctionComponent<ProcessingNotarizationComponentProps> = ({nameProduct, title, subtitle, textButton, onClickBtn}) => {
  const getImage = (name: string) => {
    // @ts-ignore
    let solutions = JSON.parse(localStorage.getItem("webFlowSolutionData"))
    // @ts-ignore
    return solutions[name]?.illustration?.url 
}
  return (
    <div className={`GProcessingNotarizationComponent`}>
      <GStepApplicationCard
        title={title}
        subtitle={subtitle}
        productImage={getImage(nameProduct)}
        contentAlign={StepApplicationCardContentAlign.Center}
        iconType= {ICONS_SVG_TYPE.OVAL_HEADER_STEP}
    >
      <div className={`GProcessingNotarizationComponent__CheckStatusButton`}>
          <GButton
              text={textButton}
              textSize={SIZE.PARAGRAPH_BOLD_16}
              size={"large"}
              click={onClickBtn}
              style={{
                  backgroundColor: COLORS_NEW.ROYAL_BLUE_600,
                  height: 56
              }}
              showIcon={false}
          />
        </div>
      </GStepApplicationCard>
     
    </div>
  )
}
export default ProcessingNotarizationComponent
