import IBuilder from "../IBuilder";
import IEvent from "../IEvent";
import Identify from "./Identify";
import {IAddress} from "../EventProperties";

export default class IdentifyBuilder implements IBuilder {
    private _userId: string;
    private _address: IAddress | undefined;
    private _birthday: string;
    private _createdAt: string;
    private _email: string;
    private _firstName: string;
    private _hasTransaction: boolean | undefined;
    private _lastName: string;
    private _phone: string;
    private _utmCampaign: string;
    private _utmContent: string;
    private _utmMedium: string;
    private _utmSource: string;
    private _utmTerm: string;
    private _isSubscribed: boolean | undefined;
    private _isActivated: boolean | undefined;
    private _lastUtmCampaign: string;
    private _lastUtmContent: string;
    private _lastUtmMedium: string;
    private _lastUtmSource: string;
    private _lastUtmTerm: string;
    private _retargetingLink: string;
    private _intercomUserHash: string | undefined;

    constructor() {
        this._userId = "";
        this._address = undefined;
        this._birthday = "";
        this._createdAt = "";
        this._email = "";
        this._firstName = "";
        this._hasTransaction = undefined;
        this._lastName = "";
        this._phone = "";
        this._utmCampaign = "";
        this._utmContent = "";
        this._utmMedium = "";
        this._utmSource = "";
        this._utmTerm = "";
        this._isSubscribed = undefined;
        this._isActivated = undefined;
        this._lastUtmCampaign = "";
        this._lastUtmContent = "";
        this._lastUtmMedium = "";
        this._lastUtmSource = "";
        this._lastUtmTerm = "";
        this._retargetingLink = "";
        this._intercomUserHash = undefined;
    }

    setUserId(value: string) {
        this._userId = value;
        return this;
    }

    setAddress(value: IAddress) {
        this._address = value;
        return this;
    }

    setBirthDay(value: string) {
        this._birthday = value;
        return this;
    }

    setCreatedAt(value?: string) {
        if(value){
            try {
                const dateString = (value).replace(/-/g, '/').replace('T', ' ')
                const dateStringArray = dateString.split('.')
                const dateUTC = new Date(dateStringArray[0]);
                this._createdAt = dateUTC.toISOString();
            } catch (error) {
                this._createdAt = value;
            }
        }
        return this;
    }

    setEmail(value: string) {
        this._email = value;
        return this;
    }

    setFirstName(value: string) {
        this._firstName = value;
        return this;
    }

    setHasTransaction(value: boolean) {
        this._hasTransaction = value;
        return this;
    }

    setLastName(value: string) {
        this._lastName = value;
        return this;
    }

    setPhone(value: string) {
        this._phone = value;
        return this;
    }

    setUtmCampaign(value: string) {
        this._utmCampaign = value;
        return this;
    }

    setUtmContent(value: string) {
        this._utmContent = value;
        return this;
    }

    setUtmMedium(value: string) {
        this._utmMedium = value;
        return this;
    }

    setUtmSource(value: string) {
        this._utmSource = value;
        return this;
    }

    setUtmTerm(value: string) {
        this._utmTerm = value;
        return this;
    }

    setIsSubscribed(value: boolean) {
        this._isSubscribed = value;
        return this;
    }

    setIsActivated(value: boolean) {
        this._isActivated = value;
        return this;
    }

    setLastUtmCampaign(value: string) {
        this._lastUtmCampaign = value;
        return this;
    }

    setLastUtmContent(value: string) {
        this._lastUtmContent = value;
        return this;
    }

    setLastUtmMedium(value: string) {
        this._lastUtmMedium = value;
        return this;
    }

    setLastUtmSource(value: string) {
        this._lastUtmSource = value;
        return this;
    }

    setLastUtmTerm(value: string) {
        this._lastUtmTerm = value;
        return this;
    }

    setRetargetingLink(value: string) {
        this._retargetingLink = value;
        return this;
    }

    setIntercomUserHash(value: string) {
        this._intercomUserHash = value;
        return this;
    }

    build(): IEvent {
        return new Identify(
            this._userId,
            this._address,
            this._birthday,
            this._createdAt,
            this._email,
            this._firstName,
            this._hasTransaction,
            this._lastName,
            this._phone,
            this._utmCampaign,
            this._utmContent,
            this._utmMedium,
            this._utmSource,
            this._utmTerm,
            this._isSubscribed,
            this._isActivated,
            this._lastUtmMedium,
            this._lastUtmSource,
            this._lastUtmCampaign,
            this._lastUtmContent,
            this._lastUtmTerm,
            this._retargetingLink,
            this._intercomUserHash
        );
    }
}
