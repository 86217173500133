import {all, fork, put, takeEvery} from "redux-saga/effects";
import {userService} from "../../api/api";
import {saveUserDataFlattenFailed, saveUserDataFlattenSuccesses,} from "../actions";
import {GET_USER_DATA_FLATERN} from "../../constants/ActionTypes";
import {notifyError} from "@dash/gov-plus__front-end__form/src/util/helper";

function* getUserFlatterDataSaga({authToken}) {
    try {
        const response = yield userService.userFlatternData();
        if (response.data.code === 200) {
            yield put(saveUserDataFlattenSuccesses({
                state_of_birth: response.data?.data?.data?.personal?.demographics?.stateOfBirth,
                country_of_birth: response.data?.data?.data?.personal?.demographics?.countryOfBirth
            }))
        }
    } catch (err) {
        yield put(saveUserDataFlattenFailed(err))
        notifyError(err)
    }
}

function* getUserFlatterDataSagaFork() {
    yield takeEvery(GET_USER_DATA_FLATERN, getUserFlatterDataSaga);
}

export default function* rootSaga() {
    yield all([
        fork(getUserFlatterDataSagaFork)
    ]);
}
