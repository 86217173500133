/* eslint-disable indent */
import React, {useEffect, useState} from "react"
import {connect, useSelector} from "react-redux"
import {Button} from "antd"
import {
    setStatusGovernmentFeeOpen,
    setStatusSignature,
    setStatusSignatureOpen,
    setStatusUploadDocuments,
    setUpsellStep,
    submitAdditionalData,
} from "../../../../Redux/store/additionalForm/additional.actions"
import SignatureComponent from "../SignatureComponent/SignatureComponent"
import yesIcon from "../../../../assets/image/bigYes.svg"
import UploadComponent from "../UploadComponent/UploadComponent"
import GovernmentFeesFormFields
    from "../../AdditionalPageComponent/AdditionalPageByType/GovermentFees/GovernmentFeesFormFields"
import {getScrollElementById} from "../../FormBlock/FormBlock"
import {RootState} from "../../../../App"
import {additionalSelector} from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import {delayTimer} from "../../../../Pages/FormPage/FormPage"
import GAdditionalStep, {StepVisualStatus} from "../../../atomicDesign/templates/AdditionalStep/AdditionalStep"
import GAdditionalStepUpsell from "../../../atomicDesign/templates/AdditionalStep/AdditionalStepUpsell";
import {PaymentRequestTokenEvent} from "@stripe/stripe-js";
import ProcessingValidationComponent from "../ProcessingValidationComponent/ProcessingValidationComponent"
import {SolutionIds, SolutionNames, SolutionNamesForAdditionalSteps} from "../../../../util/SolutionNames"
import {replaceSolution} from "../../../../util/ProcessingTrackingHelper"
import ProcessingShipmentComponent from "../ProcessingShipmentComponet/ProcessingShipmentComponent"
import ProcessingNotarizationComponent from "../ProcessingNotarizationComponent/ProcessingNotarizationComponent"
import TrackingCardStepComponent from "../TrackingCardStepComponent/TrackingCardStepComponent"
import ProcessingByGovernmentComponent from "../ProcessingByGovernmentComponent/ProcessingByGovernmentComponent"
import PendingReceivingConfirmationComponent
    from "../PendingReceivingConfirmationComponent/PendingReceivingConfirmationComponent"
import handWithCER from "../../../../assets/image/hand-with-cer.webp"
import {getIdNameFromStatuses} from "../../../../util/BlockStep";
import { BlockStepTypes } from "../../../../Constants/BlockStepTypes"

type BlocksStepsCerProps = {
    blocksStep: any,
    index: number,
    showStatus: string,
    statusSignature: string,
    canNotarize: boolean,
    notarizationLink: string,
    statusQuestionary: string,
    submitObject: {} | any,
    currentResponse: any,
    loadingButtonState: string,
    appId: string,
    formId: string,
    statusDocuments: string,
    customer: {} | any,
    statusSignatureBlockOpen: string,
    statusGovernmentFeeOpen: string,
    setStripeElements: any,
    dispatch: any,
    upsellProcessingInformation: any,
    statusUpsellStep: string,
    onGatewayPaymentStartedWithToken?: (event: PaymentRequestTokenEvent, userCoupon: any) => Promise<void>
    application_id: string
}

const BlocksStepsCer: React.FunctionComponent<BlocksStepsCerProps> = ({
                                                                          blocksStep,
                                                                          index,
                                                                          showStatus,
                                                                          statusSignature,
                                                                          canNotarize,
                                                                          notarizationLink,
                                                                          statusQuestionary,
                                                                          submitObject,
                                                                          currentResponse,
                                                                          loadingButtonState,
                                                                          appId,
                                                                          formId,
                                                                          statusDocuments,
                                                                          customer,
                                                                          statusSignatureBlockOpen,
                                                                          statusGovernmentFeeOpen,
                                                                          setStripeElements,
                                                                          dispatch,
                                                                          upsellProcessingInformation,
                                                                          statusUpsellStep,
                                                                          onGatewayPaymentStartedWithToken,
                                                                          application_id


                                                                      }) => {
    const name = submitObject?.["fname"]

    const handlerSignature = (status: string) => {
        dispatch(setStatusSignature(status))
        dispatch(setStatusSignatureOpen(status))
    }

    const handleNotarize = () => {
        if (notarizationLink)
            window.open(notarizationLink, "_blank")
    }

    const handlerGovernmentFeeOpen = (status: string) => {
        dispatch(setStatusGovernmentFeeOpen(status))
    }

    const [firstLoadFlag, setFirstLoadFlag] = useState(true)

    if (canNotarize && firstLoadFlag) {
        dispatch(setStatusSignature("completed"))
        dispatch(setStatusUploadDocuments("process"))
        setFirstLoadFlag(false)
    }

    const {statusSignaturePage} = useSelector<RootState, { statusSignaturePage: string } | undefined>(
        additionalSelector
    ) ?? {statusSignaturePage: ""}

    useEffect(() => {
        delayTimer(300).then(() => getScrollElementById({id: `${statusSignaturePage}_scrollById`}))
    }, [statusSignaturePage])
    const uniqSession = submitObject["unique-section-id"]

    function sendConfirmationOfReceipt(): void {
        dispatch(submitAdditionalData({
            confirm_received_document: true,
        }))
    }

    let idNameFromStatuses = getIdNameFromStatuses(blocksStep.status)

    return (
        <div className={`steps-signature-container`} id={`${idNameFromStatuses}${index !== 1 ? "_scrollById" : ""}`}>
            {blocksStep.type === BlockStepTypes.CONTENT && (
                <>
                    <div className={`icon-container_complete`}>
                        <img src={yesIcon} alt="approve"/>
                    </div>
                    <div className={`div-bg`}>
                        <p>{blocksStep.content.complete}</p>
                    </div>
                </>
            )}

            {blocksStep.type === BlockStepTypes.UPSELL && upsellProcessingInformation && upsellProcessingInformation[blocksStep.status] && (
                <GAdditionalStepUpsell
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    formId={formId}
                    uniqSession={uniqSession}
                    blockStatus={blocksStep.status}
                />
            )}

            {!canNotarize && blocksStep.type === BlockStepTypes.SIGNATURE && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusSignatureBlockOpen}
                    ctaForm={() => handlerSignature(StepVisualStatus.STATUS_PROCESS)}
                >
                    <SignatureComponent nestBlock={blocksStep.content.nestBlock}/>
                </GAdditionalStep>
            )}

            {(blocksStep.type === BlockStepTypes.UPLOADING_FILE || blocksStep.type === BlockStepTypes.UPLOADING_ADDITIONAL_FILE) && (
                <UploadComponent
                    blocksStep={blocksStep}
                    index={canNotarize ? index - 1 : index}
                    showStatus={showStatus}
                    loadingButtonState={loadingButtonState}
                    statusDocuments={statusDocuments}
                    relationship={submitObject["relationship"]}
                    proofOnFlag={submitObject["relationship"] !== "myself"}
                />
            )}

            {blocksStep.type === BlockStepTypes.GOVERNMENT_FEES && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusGovernmentFeeOpen}
                    index={index.toString()}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    ctaForm={() => handlerGovernmentFeeOpen(StepVisualStatus.STATUS_PROCESS)}
                >
                    <GovernmentFeesFormFields onGatewayPaymentStartedWithToken={onGatewayPaymentStartedWithToken}
                                              blocksStep={blocksStep} setStripeElements={setStripeElements}
                                              index={index}/>
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.PROCESSING_VALIDATION && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                >
                    <ProcessingValidationComponent
                        nameProduct={SolutionIds.CER}
                        title={SolutionNames.CER}
                        subtitle={blocksStep.content?.nestBlock?.subheader ? blocksStep.content?.nestBlock?.subheader : " "}
                    />
                </GAdditionalStep>
            )}
            
            {blocksStep.type === BlockStepTypes.SENT_TO_NOTARIZE && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                >
                    <ProcessingNotarizationComponent
                        nameProduct={SolutionIds.CER}
                        title={SolutionNames.CER}
                        subtitle={blocksStep.content?.nestBlock?.subheader ? blocksStep.content?.nestBlock?.subheader : " "}
                        textButton={blocksStep.content?.nestBlock?.titleButton ? blocksStep.content?.nestBlock?.titleButton : " "}
                        onClickBtn={() => handleNotarize()}
                    />
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.PROCESSING_VALIDATION_NOTARIZATION && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                >
                    <ProcessingValidationComponent
                        nameProduct={SolutionIds.CER}
                        title={SolutionNames.CER}
                        subtitle={blocksStep.content?.nestBlock?.subheader ? blocksStep.content?.nestBlock?.subheader : " "}
                    />
                </GAdditionalStep>
            )}
            {blocksStep.type === BlockStepTypes.SHIPMENT && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={replaceSolution(blocksStep.content.information, SolutionNamesForAdditionalSteps.CER)}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                >
                    <ProcessingShipmentComponent
                        estimatedWaitTime={blocksStep.content?.nestBlock?.estimatedWaitTime ? blocksStep.content?.nestBlock?.estimatedWaitTime : " "}
                        solution={SolutionNames.CER}
                    />
                </GAdditionalStep>
            )}
            {blocksStep.type === BlockStepTypes.TRACKING && (
                <TrackingCardStepComponent
                    blocksStep={blocksStep}
                    solutionName={SolutionNamesForAdditionalSteps.CER}
                    index={index}
                    showStatus={showStatus}
                    applicationId={application_id}
                    replaceInformation={true}
                    toCustomer={blocksStep.status === "tracking_to_customer" ? false : true }
                />
            )}
            {blocksStep.type === BlockStepTypes.PROCESSING_BY_GOVERNMENT && (
                <ProcessingByGovernmentComponent
                    blocksStep={blocksStep}
                    solutionName={SolutionNamesForAdditionalSteps.CER}
                    solutionNameToAdditionalStepsCard={SolutionNames.CER}
                    solutionId={SolutionIds.CER}
                    index={index}
                    showStatus={showStatus}
                    applicationId={application_id}
                    toCustomer={true}
                    showEstimateWaitTime={false}
                />
            )}
            {blocksStep.type === BlockStepTypes.PENDING_RECEIVING_DOCUMENT_CONFIRMATION && (
                <GAdditionalStep
                    title={replaceSolution(blocksStep.content.title, SolutionNamesForAdditionalSteps.CER)}
                    titleDone={replaceSolution(blocksStep.content.titleDone, SolutionNamesForAdditionalSteps.CER)}
                    information={replaceSolution(blocksStep.content.information, SolutionNamesForAdditionalSteps.CER)}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                >
                    <PendingReceivingConfirmationComponent
                        title={SolutionNames.CER}
                        subtitle={"Estimated delay: 8 weeks"}
                        nameProduct={SolutionIds.CER}
                        textButton={`I’ve received my ${SolutionNamesForAdditionalSteps.CER}`}
                        onClickBtn={sendConfirmationOfReceipt}
                        loadingBtnState={loadingButtonState}
                        imageLeft={handWithCER}
                    />
                </GAdditionalStep>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    statusSignature: state.additional.statusSignature,
    canNotarize: state.additional.canNotarize,
    notarizationLink: state.additional.notarizationLink,
    application_id: state.form.application_id,
    statusQuestionary: state.additional.statusQuestionary,
    submitObject: state.form.submitObject,
    currentResponse: state.step.currentResponse,
    loadingButtonState: state.loading.loadingButtonState,
    appId: state.form.appId,
    formId: state.form.formId,
    missingInformationStatus: state.form.missingInformationStatus,
    statusDocuments: state.additional.statusDocuments,
    statusSignatureBlockOpen: state.additional.statusSignatureBlockOpen,
    statusGovernmentFeeOpen: state.additional.statusGovernmentFeeOpen,
    statusProcessingValidationOpen: state.additional.statusProcessingValidationOpen,
    customer: state.form.customer,
    upsellProcessingInformation: state.additional.upsellProcessingInformation,
    statusUpsellStep: state.additional.statusUpsellStep,
})

export default connect(mapStateToProps)(BlocksStepsCer)
