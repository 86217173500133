/* eslint-disable indent */
import { Button } from "antd"
import React from "react"
import ClientDataComponent from "../../PostSubmitComponents/ClientDataComponent/ClientDataComponent"

type FormHelperAdditionalStepsProps = {
  clientId: any,
  statusSignature: string,
  indexCurrentStep: number,
  handleIncrementClick: any,
  helperContent: {} | any,
  arrAdditionalText: [] | any,
}

const FormHelperAdditionalSteps: React.FunctionComponent<FormHelperAdditionalStepsProps> = ({
  clientId,
  statusSignature,
  indexCurrentStep,
  handleIncrementClick,
  helperContent,
  arrAdditionalText,
}) => {
  const { blockOnFormPage: { correctMyInformation = "" } = {} } = helperContent

  return (
    <>
      <div className={"additional-helper__container"}>
        <div className={`additional-helper form-helper ${window.pageYOffset > 300 ? "form-helper_top" : false}`}>
          <h2 className={"additional-helper form-helper__header"}>{helperContent.blockOnFormPage.content.header} </h2>
          <ol className={"additional-helper form-helper__main"}>
            {arrAdditionalText.map((item: any, index: number) => (
              <li key={index} className={`additional-helper__text`}>
                {item}
              </li>
            ))}
          </ol>
        </div>
        {helperContent.blockOnFormPage.personalData && statusSignature !== "process" && (
          <ClientDataComponent item={helperContent} clientId={clientId} />
        )}

        {statusSignature === "process" && indexCurrentStep === 0 && (
          <div className={`signature-process__container`}>
            {helperContent.blockOnFormPage.personalData && (
              <ClientDataComponent item={helperContent} clientId={clientId} />
            )}
            {correctMyInformation !== "hide" && false && (
              <div className={`correct-block`}>
                <h3 className={`correct-block__header`}>Mistakes on your form?</h3>
                <Button type="primary" className={`correct-block__button`} onClick={handleIncrementClick}>
                  <span className="">{`Correct my information`}</span>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default FormHelperAdditionalSteps
