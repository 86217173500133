import { useEffect, useState } from 'react';
import Form, { FormInstance } from 'antd/lib/form';

const useSubmittableForm = (form: FormInstance, fieldsToWatch: string[]) => {
  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch(fieldsToWatch, form);

  useEffect(() => {
    const fieldsAreTouched = fieldsToWatch.every((field) =>
      form.isFieldTouched(field)
    );

    if (fieldsAreTouched) {
      form
        .validateFields()
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false));
    } else {
      setSubmittable(false);
    }
  }, [form, values, fieldsToWatch]);

  return submittable;
};

export default useSubmittableForm;