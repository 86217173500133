import React, {useState} from "react"
import "./ConfirmPhone.less";
import {Form} from "antd"
import Validation from "../../components/Validation"
import MaskedInput from "antd-mask-input";
import {useHistory} from "react-router-dom";
import SettingsButton from "../../components/SettingsButton";
import Header from "../Header";
import {IconSvg} from "../../components/svgImages/SvgImages";

const ConfirmPhone = () => {
  const [phoneNumber] = useState("555-555-1125")
  const [showValidation, setShowValidation] = useState(false)
  const history = useHistory();


  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {

    if (phoneNumber !== values.phone_number.slice(1)) {
      setShowValidation(true)
    } else {

      history.push("/verification_code")

    }

  };

  return (
    <div className="gx-app-login-wrap">
      <Header/>
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content">
            <div className={"gx-app-login-header"}>
              <div className={"gx-app-login-header_logo"}>
                <IconSvg type={"phoneIcon"}/>
                <h1> Confirm Phone </h1>
              </div>
              <span>
              Confirm the phone number you provided in your profile: <span
                className='phone-number'>XXX-XXX-{phoneNumber.slice(-4)}</span>
              </span>
            </div>
            <Form
              initialValues={{remember: true}}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
              layout="vertical"
            >

              <Form.Item
                rules={[{required: false, message: 'The input is not valid E-mail!'}]}
                name="phone_number"
                className='gx-mb-4'
              >
                <MaskedInput
                  mask={"+111-111-1111"}
                  name={"phone"}
                  placeholder={"Phone"}
                  className={showValidation ? "phoneNumberMasked" : null}
                  onChange={(e)=>{
                    setShowValidation(false)
                  }}
                />
              </Form.Item>
              {showValidation && <Validation validationStatus={!showValidation}
                                             validationMessage={"Phone number mismatch, please try again"}/>}

              <p className={"formDescription"}>
                By entering a mobile phone number, you consent to receive automated security notification via text
                message from GOV+. Message and data rates may apply. Message frequency varies.
              </p>
              <div className={"buttonContainer"}>
                <SettingsButton buttonText={"Send me the auth code"}/>
              </div>

            </Form>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ConfirmPhone;
