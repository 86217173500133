import "./PaymentMethodsForm.scss"
import {PaymentMethodsFormProps} from "./PaymentMethodsFormProps";
import React, {FC, useEffect, useState} from "react";
import GPaymentMethod from "../../organisms/PaymentMethod/PaymentMethod";
import plusIcon from "../../../../assets/image/ico-plus-24.svg"
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import {isNewPaymentMethod, NEW_PAYMENT_METHOD_VALUE} from "../../../../util/PaymentMethodsHelper";
import GCreditCardForm from "../CreditCardForm/CreditCardForm";

const GPaymentMethodsForm: FC<PaymentMethodsFormProps> =
    ({
         paymentMethods,
         defaultPaymentMethod,
         selectionPaymentMethodChange,
         isPaymentMethodFormOpen,
     }) => {
        const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(defaultPaymentMethod || NEW_PAYMENT_METHOD_VALUE)
        const onSelectedPaymentMethod = (paymentMethod: string) => {
            setSelectedPaymentMethod(paymentMethod);
            selectionPaymentMethodChange(paymentMethod);
        }
        const setSelectedNewPaymentMethod = () => {
            setSelectedPaymentMethod(NEW_PAYMENT_METHOD_VALUE);
            selectionPaymentMethodChange(NEW_PAYMENT_METHOD_VALUE);
            isPaymentMethodFormOpen? isPaymentMethodFormOpen(true): null;

        }
       if(isNewPaymentMethod(selectedPaymentMethod)){
           isPaymentMethodFormOpen? isPaymentMethodFormOpen(true): null;
       }
       else{
              isPaymentMethodFormOpen? isPaymentMethodFormOpen(false): null;
       }

       useEffect(() => {
           if (defaultPaymentMethod) {
               setSelectedPaymentMethod(defaultPaymentMethod)
           }
       }, [defaultPaymentMethod])

        return (
            <div className={'GPaymentMethodForm'}>
                {paymentMethods.length > 0 && <div className={'payment-methods'}>
                    {paymentMethods.map((pm) => {
                        return <GPaymentMethod
                                    key={pm.id}
                                    paymentMethodData={pm}
                                    isSelected={pm.id === selectedPaymentMethod}
                                    onSelect={onSelectedPaymentMethod}
                                    isDefault={pm.id === defaultPaymentMethod}
                                />
                    })}
                </div>}
                {!isNewPaymentMethod(selectedPaymentMethod) && (
                    <div onClick={setSelectedNewPaymentMethod} className={'new-payment-method'}>
                        <img src={plusIcon} alt={`Add payment method`}/>
                        <GText text={'Add new payment method'} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_500}/>
                    </div>
                )}

                {isNewPaymentMethod(selectedPaymentMethod) && (
                    <div className={'new-payment-method-form'}>
                        <GCreditCardForm title={'Add new payment method'}/>
                    </div>
                )}
            </div>

        )
    }
export default GPaymentMethodsForm