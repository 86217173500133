import React from 'react'
import lockIcon from "../../../../assets/image/icon-lock.png";
import "./PaymentSecurityTag.scss"
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";

const PaymentSecurityTag = () => {

    return (
        <div className={"GPaymentSecurityTag"}>
            <img src={lockIcon} className={"list-fields-in-block__main-security-icon"} alt={"security-icon"} />
            <GText text={"All transactions are secure and encrypted"} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
        </div>
    )
}

export default PaymentSecurityTag