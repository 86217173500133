import axios, {AxiosInstance} from "axios";
import AuthUserBearerTokenRepository from "../persistence/session/AuthUserBearerTokenRepository";
import { env } from "../env";

const host = env.REACT_APP_HOST ?? "https://api.govplus.dev"
const hostUploadPhoto = env.REACT_APP_UTM_API_KEY ?? ""
const subscription_back = env.REACT_APP_SUBSCRIPTION;

const defaultHeaders = {
  "Content-Type": "application/json"
}

const apiInstanceHeaders = (bearerToken: string | null) =>
    bearerToken ? {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${bearerToken}`
    } : defaultHeaders

const formInstance: AxiosInstance | any = axios.create({
  baseURL: host,
  withCredentials: true,
  headers: defaultHeaders
});

const utmInstance: AxiosInstance | any = axios.create({
  baseURL: host,
  withCredentials: true,
  headers: {...defaultHeaders, "Authorization": `Bearer ${hostUploadPhoto}`}
});

const subscriptionInstance: AxiosInstance | any = axios.create({
  baseURL: subscription_back,
  headers: apiInstanceHeaders(AuthUserBearerTokenRepository.getInstance().getValue())
});

export const apiAxiosInstance = {
  formsBackInstance: (method: string, urlPoint: string, params: {}) => {
    return formInstance[method](urlPoint, JSON.stringify(params))
  },
  utmBackInstance: (method: string, urlPoint: string, params: {}) => {
    return utmInstance[method](urlPoint, JSON.stringify(params))
  },
  formsBackInstancePost: (urlPoint: string, params: {}) => {
    return formInstance['post'](urlPoint, JSON.stringify(params))
  },
  subscriptionBackInstance: (method: string, urlPoint: string, params: {}) => {
    return subscriptionInstance()[method](urlPoint, JSON.stringify(params))
  },
}
