import React from "react"

interface ISvgCheck {
    width: number;
    height: number;
    color: string;
    backgroundColor?: string;
    fillCircle?: boolean;
}

export const SgvRenewalCircleBody = ({ width = 65, height = 65, color = "#185EC8", backgroundColor = "#EEF4FB", fillCircle = true }: ISvgCheck) => {
    return (
        <svg width={width} height={height} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            {fillCircle && <rect x="0.5" width="30" height="30" rx="15" fill={backgroundColor}/>}
            <path
                d="M22.1679 10.1484V13.7853H18.5312"
                stroke={color}
                strokeWidth="1.33366"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.83203 19.8478V16.2109H12.4687"
                stroke={color}
                strokeWidth="1.33366"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.3534 13.1823C10.6608 12.3135 11.1832 11.5368 11.872 10.9247C12.5607 10.3125 13.3933 9.88474 14.292 9.68137C15.1908 9.47801 16.1264 9.50564 17.0116 9.76169C17.8968 10.0177 18.7027 10.4939 19.3541 11.1456L22.1664 13.7884M8.83203 16.213L11.6444 18.8557C12.2958 19.5075 13.1017 19.9836 13.9869 20.2397C14.8721 20.4957 15.8077 20.5233 16.7064 20.32C17.6052 20.1166 18.4378 19.6889 19.1265 19.0767C19.8153 18.4645 20.3377 17.6878 20.6451 16.8191"
                stroke={color}
                strokeWidth="1.33366"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
