import React from "react"

interface ISvgChevronRight {
    width?: number;
    height?: number;
    color?: string;
}

export const SvgChevronRight = ({width = 20, height = 20, color = "#216DE3"}: ISvgChevronRight) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 5.5L13 10.5L8 15.5"
                stroke={color}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
