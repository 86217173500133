import {CardSecurityCodeProps} from "./CardSecurityCodeProps";
import React, {FC} from "react";
import {Rule} from "rc-field-form/lib/interface";
import GFormItem from "../../organisms/FormItem/FormItem";
import {CreditCardFormFields, LabelsColor, LabelsSize} from "../../templates/CreditCardForm/CreditCardFormFields";
import GText from "../../atoms/Text/Text";
import InputMask from "react-input-mask";
import GInputText from "../InputText/InputText";
import "./CardSecurityCode.scss"
import card from "../../../../assets/image/card.svg"

const arrayCvvIcons = [card]
const GCardSecurityCode: FC<CardSecurityCodeProps> = () => {
    const validationRules: Rule[] = [{required: true, message: 'Required'}]
    return (
        <div className={'GCardSecurityCode'}>
            <GFormItem
                name={CreditCardFormFields.CVV}
                label={<GText text={'Security code (CVV)'} size={LabelsSize}
                              color={LabelsColor}/>}
                rules={validationRules}>
                <InputMask
                    mask={"9999"}
                    maskPlaceholder={null}
                >
                    <GInputText inputMode={'numeric'} maskedForPrivacy />
                </InputMask>
            </GFormItem>
            <div className={`form-item__icon-container`}>
                {arrayCvvIcons.map((item: string) => <img key={item} src={item} alt={item}/>)}
            </div>
        </div>

    )
}

export default GCardSecurityCode;