import React from "react";
import "./index.less";
import {toggleCollapsedSideNav, userSignOut, userSignOutSuccess} from "../../appRedux/actions";
import {useDispatch, useSelector} from "react-redux";
import GButton from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Button/GButton";
import {BUTTON_TYPE, SIZE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const PopoverMenuLogin = () => {
    const breakPoint = useBreakpoint()
    const dispatch = useDispatch();
    const {outerId} = useSelector(({auth}) => auth);
    const {userFlatternDate} = useSelector(({Users}) => Users);

    const toSignIn = () => {
        dispatch(userSignOutSuccess({logOutStatus: true}))
        dispatch(userSignOut({
            outer_id: outerId,
            userId: userFlatternDate?.userId,
        }))
        dispatch(toggleCollapsedSideNav(false))
    }



    return (
        <div className={`PopoverMenuLogin`}>
            <GButton 
                textSize={!breakPoint.xs ? SIZE.PARAGRAPH_BOLD_16 : SIZE.PARAGRAPH_BOLD_14}
                text={`Sign in`} isSubmit={false} 
                click={toSignIn} 
                showIcon={false} 
                type={BUTTON_TYPE.SECONDARY} 
                style={{
                    height: !breakPoint.xs ? 44 : 34,
                    borderRadius: 12,
                    background: "none"
                }}
            />
        </div>
    )
}
export default PopoverMenuLogin;
