import React, {useState} from "react"
import { connect } from "react-redux"
import {
  setAnswersQuestionary,
  setStatusQuestionary,
  setStatusQuestionnaireFront,
  submitAdditionalForm,
} from "../../../../Redux/store/additionalForm/additional.actions"
import QuestionnaireAdditionalQuestion from "./QuestionaryAdditionalQuestion";
import GButton, {BUTTON_TYPE} from "../../../atomicDesign/molecules/Button/GButton";
import "./styles.scss"
import {SIZE} from "../../../atomicDesign/types/types";
import {COLORS, COLORS_NEW} from "../../../atomicDesign/types/Colors";
import GText from "../../../atomicDesign/atoms/Text/Text";
import UserIpRepository from "../../../../persistence/session/UserIpRepository";
import {ClickedButton, QuestionnaireButtonSize, QuestionnaireButtonText} from "./QuestionnaireConstant";
import { env } from "../../../../env";

const rootLink = env.REACT_APP_QUESTIONARY_IMG ?? "https://api-files.govplus.dev/static/img/ssn_documents/"

type QuestionaryComponentTSAProps = {
  blocksStep: any,
  successPageSubmit: {} | any,
  submitObject: any,
  questionary: [] | any,
  appId: string,
  formId: string,
  dispatch: any,
}

// eslint-disable-next-line max-len
const QuestionaryComponentTSA: React.FunctionComponent<QuestionaryComponentTSAProps> = ({
  blocksStep,
  successPageSubmit,
  submitObject,
  questionary,
  appId,
  formId,
  dispatch,
}) => {
  const [questionnaireStatus, setQuestionnaireStatus] = useState("main")
  const [indexAdditionalQuestions, setIndexAdditionalQuestions] = useState(0)

  const [index, setIndex] = useState(0)
  const [indexDocuments, setIndexDocuments] = useState(0)

  const ini: any[] = []
  const [documentsYes, setDocumentsYes] = useState(ini)
  const [documentsNo, setDocumentsNo] = useState(ini)

  const [loadingYesBtn, setLoadingYesBtn] = useState(false)
  const [loadingNoBtn, setLoadingNoBtn] = useState(false)
  const [disableBtn, setDisabledBtn] = useState(false)
  const [buttonLoading, setButtonLoading] = useState()

  const initialObj = {
    applicant_documents: "",
    responsible_documents: "",
    applicant_orientations: "",
    responsible_orientation: "",
    additional_question: "",
  }
  const [obj, setObj]: any = useState(initialObj)

  const getStringDocument = (oldString: string, newDocument: string) => {
    if (oldString && newDocument) {
      return `${oldString} | ${newDocument}`
    }
    return newDocument
  }

  const setObjFunc = () => {
    if (index === 0) {
      setObj({
        ...obj,
        applicant_documents: questionary[index].documents[indexDocuments].name,
      })
    }
    if (index === 1)
      setObj({
        ...obj,
        applicant_documents: getStringDocument(
          obj.applicant_documents,
          questionary[index].documents[indexDocuments].name
        ),
      })
    if (index === 2)
      setObj({
        ...obj,
        applicant_documents: getStringDocument(
          obj.applicant_documents,
          questionary[index].documents[indexDocuments].name
        ),
      })
    if (index > 2)
      setObj({
        ...obj,
        applicant_documents: getStringDocument(
          obj.applicant_documents,
          questionary[index].documents[indexDocuments].name
        ),
      })
  }

  const getObjFunc = () => {
    if (index === 0)
      return {
        applicant_documents: questionary[index].documents[indexDocuments].name,
      }
    if (index === 1)
      return {
        applicant_documents: getStringDocument(
          obj.applicant_documents,
          questionary[index].documents[indexDocuments].name
        ),
      }
    if (index === 2)
      return {
        applicant_documents: getStringDocument(
          obj.applicant_documents,
          questionary[index].documents[indexDocuments].name
        ),
      }
    if (index > 2)
      return {
        applicant_documents: getStringDocument(
          obj.applicant_documents,
          questionary[index].documents[indexDocuments].name
        ),
      }
  }

  const setAdditionalQuestion = (value:any) => {
    setObj({
      ...obj,
      additional_question: getStringDocument(
        obj.additional_question,
        value
      ),
    })
  }

  const getNextCategory = (index: number) => {
    let next = -1;
    for(let i = index+1; i < questionary.length; i++) {
      const existDocument = questionary[i].documents.find((f: any) => f.name === questionary[index].documents[indexDocuments].name)
      if(!existDocument)
        return i;
    }
    return next;
  }

  const getNextDocument = (
    index: number,
    indexDocument: number = 0,
    updatedDocumentsNo = documentsNo,
    updatedDocumentsYes = documentsYes
  ): {index:number, indexDocument:number} | false => {
    let next = false;
    for(let i = index; i < questionary.length; i++) {
      let setIndexDocument = 0
      if(indexDocument > 0  && i === index){
        setIndexDocument = indexDocument
      }
      for (let j = 0; j < questionary[i].documents.length; j++) {
        /**
         * Use updated versions of "yes" and "no" documents (via parameters) instead of directly checking the state
         * to avoid checking a version of the array/state that hasn't been updated by React
         */
        if( updatedDocumentsNo.indexOf(questionary[i].documents[j].name) === -1
            && updatedDocumentsYes.indexOf(questionary[i].documents[j].name) === -1){
          return {
            index: i,
            indexDocument: j
          };
        }
      }
    }
    return next;
  }

  const indexCounter = (value: string) => {
    if (value === "Y") {
      let newYesArray = [...documentsYes, questionary[index].documents[indexDocuments].name];
      setDocumentsYes(newYesArray)
      setObjFunc()
      const newSubmit = { ...obj, ...getObjFunc() }
      dispatch(setAnswersQuestionary(newSubmit))
      // if isn't last elements in array questionary
      if (index < questionary.length - 1) {
        const next = getNextCategory(index);
        if(next === -1) {
          setIndex(0)
          // dispatch(setStatusQuestionary("complete"))
          return true
        } else {
          const nextOther
              = getNextDocument(index+1,undefined, undefined, newYesArray);
          if(!nextOther) {
            setIndex(0)
            // dispatch(setStatusQuestionary("complete"))
            return true
          } else {
            setIndex(next)
            setIndexDocuments(nextOther.indexDocument)
          }
        }
      } else {
        setIndex(0)
        // dispatch(setStatusQuestionary("complete"))
        return true
      }
    }
    if (value === "N") {
      let newNoArray = [...documentsNo, questionary[index].documents[indexDocuments].name];
      setDocumentsNo(newNoArray)
      // if isn't last elements in array questionary.documents
      if (indexDocuments < questionary[index].documents.length - 1) {
        const nextDocument = getNextDocument(index, indexDocuments + 1, newNoArray)
        if(!nextDocument) {
          setIndex(0)
          return {isCallGetObject: false}
        } else {
          setIndex(nextDocument.index)
          setIndexDocuments(nextDocument.indexDocument)
        }
      } else if (indexDocuments === questionary[index].documents.length - 1) {
        // if isn't last elements in array questionary
        if (index < questionary.length - 1) {
          const nextDocument = getNextDocument(index + 1, undefined, newNoArray)
          if(!nextDocument) {
            setIndex(0)
            // dispatch(setStatusQuestionary("complete"))
            return true
          } else {
            setIndex(nextDocument.index)
            setIndexDocuments(nextDocument.indexDocument)
          }
        } else {
          setIndex(0)
          return true
        }
      }
    }
    return false
  }

  const handlerClickButton = (e: string) => {
    if(e === ClickedButton.YES) {
      setLoadingYesBtn(true);
      setLoadingNoBtn(false);
      setDisabledBtn(true);
    }
    if(e === ClickedButton.NO) {
      setLoadingYesBtn(false);
      setLoadingNoBtn(true);
      setDisabledBtn(true);
    }

    setTimeout(()=>{
      const dispatchOn = indexCounter(e)
      if(dispatchOn) {
        if(!blocksStep.content.additionalQuestions) {
          dispatchFinish()
        } else {
          if(questionnaireStatus==="main"){
            setQuestionnaireStatus("additionalQuestions")
          }
          resetBtnStatuses();
        }
      } else {
        resetBtnStatuses();
      }
    }, 500);
  }

  const resetBtnStatuses = () => {
    setLoadingYesBtn(false);
    setLoadingNoBtn(false);
    setDisabledBtn(false);
    setButtonLoading(undefined);
  }

  const dispatchFinish = () => {
    dispatch(setStatusQuestionary("complete"))

    const newSubmit = {
      ...successPageSubmit,
      answersQuestionary: { ...obj, ...getObjFunc()},
    }

    dispatch(
      submitAdditionalForm({
        ...newSubmit,
        dataPricing: submitObject["data-pricing"],
        client_token: appId,
        form_id: formId,
        userIp: UserIpRepository.getInstance().getValue()
      })
    )

    dispatch(setStatusQuestionary("completed"))
    // crut for additional statusQuestionnaireFront
    setTimeout(() => {
      dispatch(setStatusQuestionnaireFront(""))
      sessionStorage.setItem("statusQuestionnaireFront", "")
      resetBtnStatuses();
    }, 4500)
  }

  const dispatchFinishAdditionalComponents = (value:any) => {
    dispatch(setStatusQuestionary("complete"))

    const newSubmit = {
      ...successPageSubmit,
      answersQuestionary: { ...obj, ...{additional_question: getStringDocument(obj.additional_question, value)}},
    }

    dispatch(
      submitAdditionalForm({
        ...newSubmit,
        dataPricing: submitObject["data-pricing"],
        client_token: appId,
        form_id: formId,
        userIp: UserIpRepository.getInstance().getValue()
      })
    )

    dispatch(setStatusQuestionary("completed"))
    // crut for additional statusQuestionnaireFront
    setTimeout(() => {
      dispatch(setStatusQuestionnaireFront(""))
      sessionStorage.setItem("statusQuestionnaireFront", "")
      resetBtnStatuses();
    }, 4500)
  }

  const searchNextAdditionalQuestion = (id:number) => {
    let index = -1;
    blocksStep.content.additionalQuestions.forEach((question: any, i: number) => {
      if(question.id === id) {
        index = i;
      }
    })
    if(index === -1) {
      dispatchFinish()
    } else {
      setIndexAdditionalQuestions(index)
      resetBtnStatuses();
    }
  }

  let questionnaireDescription = <p 
    className={`step-questionnaire__description`} 
    dangerouslySetInnerHTML={{__html: blocksStep.content.description}} 
  />

  return (
    <>
      <div className={`tsa-block`}>
        {questionnaireDescription}
        {questionary && questionary.length && questionary[index] && questionnaireStatus === "main" && (
          <React.Fragment key={index}>
            <div key={index} className={`step-questionnaire__main-container`}>
              <div className={'step-questionnaire__main-header'}>
                <GText text={questionary[index].documents[indexDocuments] && questionary[index].documents[indexDocuments].question}
                  size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000}/>
              </div>
              <img
                // eslint-disable-next-line max-len
                src={`${rootLink}${
                  questionary[index].documents[indexDocuments] && questionary[index].documents[indexDocuments].picture
                }`}
                alt={questionary[index].documents[indexDocuments] && questionary[index].documents[indexDocuments].name}
                className={`step-questionnaire__main-img`}
              />
            </div>
            <div className={'options-container'}>
              <GButton
                  textSize={SIZE.PARAGRAPH_BOLD_16}
                  text={QuestionnaireButtonText.YES}
                  size={QuestionnaireButtonSize.SMALL}
                  isSubmit={false}
                  showIcon={false}
                  type={BUTTON_TYPE.SECONDARY}
                  click={()=>handlerClickButton(ClickedButton.YES)}
                  disabled={disableBtn}
                  loading={loadingYesBtn}
              />
              <GButton
                  textSize={SIZE.PARAGRAPH_BOLD_16}
                  text={QuestionnaireButtonText.NO}
                  size={QuestionnaireButtonSize.SMALL}
                  showIcon={false}
                  isSubmit={false}
                  type={BUTTON_TYPE.SECONDARY}
                  click={()=>handlerClickButton(ClickedButton.NO)}
                  disabled={disableBtn}
                  loading={loadingNoBtn}
              />
            </div>
          </React.Fragment>
        )}

        {blocksStep.content.additionalQuestions && questionnaireStatus === "additionalQuestions" && (
          <React.Fragment key={index}>
            <div key={index} className={`step-questionnaire__main-container`}>
              <h4 className={`step-questionnaire__main-header`}>
                {blocksStep.content.additionalQuestions && 
                    blocksStep.content.additionalQuestions[indexAdditionalQuestions] && 
                    blocksStep.content.additionalQuestions[indexAdditionalQuestions].question}
              </h4>
            </div>
            {blocksStep.content.additionalQuestions && 
                blocksStep.content.additionalQuestions[indexAdditionalQuestions] && (
              <QuestionnaireAdditionalQuestion 
                question={blocksStep.content.additionalQuestions[indexAdditionalQuestions]} 
                setAdditionalQuestions={setAdditionalQuestion} 
                dispatchFinish={dispatchFinishAdditionalComponents} 
                searchNextAdditionalQuestion={searchNextAdditionalQuestion}
                disableBtn={disableBtn}
                loadingNoBtn={loadingNoBtn}
                loadingYesBtn={loadingYesBtn}
                setDisabledBtn={setDisabledBtn}
                setLoadingNoBtn={setLoadingNoBtn}
                setLoadingYesBtn={setLoadingYesBtn}
                buttonLoading={buttonLoading}
                setButtonLoading={setButtonLoading}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  successPageSubmit: state.additional.successPageSubmit,
  questionary: state.form.questionary,
  submitObject: state.form.submitObject,
  appId: state.form.appId,
  formId: state.form.formId
})

export default connect(mapStateToProps)(QuestionaryComponentTSA)
