import IEvent from "../IEvent";
import {EventOptions, EventProps, IAddress} from "../EventProperties";
import UserTimezoneRepository from "../../../../../persistence/session/UserTimezoneRepository";

export default class Identify implements IEvent {

    private _userId: string;
    private _address: IAddress | undefined;
    private _birthday: string;
    private _createdAt: string;
    private _email: string;
    private _firstName: string;
    private _hasTransaction: boolean | undefined;
    private _lastName: string;
    private _phone: string;
    private _utmCampaign: string;
    private _utmContent: string;
    private _utmMedium: string;
    private _utmSource: string;
    private _utmTerm: string;
    private _isSubscribed: boolean | undefined;
    private _isActivated: boolean | undefined;
    private _lastUtmCampaign: string;
    private _lastUtmContent: string;
    private _lastUtmMedium: string;
    private _lastUtmSource: string;
    private _lastUtmTerm: string;
    private _retargetingLink: string;

    private _intercomUserHash: string | undefined;

    constructor(
        userId: string,
        address: IAddress | undefined,
        birthday: string,
        createdAt: string,
        email: string,
        firstName: string,
        hasTransaction: boolean | undefined,
        lastName: string,
        phone: string,
        utmCampaign: string,
        utmContent: string,
        utmMedium: string,
        utmSource: string,
        utmTerm: string,
        isSubscribed: boolean | undefined,
        isActivated: boolean | undefined,
        lastUtmMedium: string,
        lastUtmSource: string,
        lastUtmCampaign: string,
        lastUtmContent: string,
        lastUtmTerm: string,
        retargetingLink: string,
        intercomUserHash: string | undefined
    ) {
        this._userId = userId;
        this._address = address;
        this._birthday = birthday;
        this._createdAt = createdAt;
        this._email = email;
        this._firstName = firstName;
        this._hasTransaction = hasTransaction;
        this._lastName = lastName;
        this._phone = phone;
        this._utmCampaign = utmCampaign;
        this._utmContent = utmContent;
        this._utmMedium = utmMedium;
        this._utmSource = utmSource;
        this._utmTerm = utmTerm;
        this._isSubscribed = isSubscribed;
        this._isActivated = isActivated;
        this._lastUtmMedium = lastUtmMedium;
        this._lastUtmSource = lastUtmSource;
        this._lastUtmCampaign = lastUtmCampaign;
        this._lastUtmContent = lastUtmContent;
        this._lastUtmTerm = lastUtmTerm;
        this._retargetingLink = retargetingLink;
        this._intercomUserHash = intercomUserHash;
    }

    getPreviousId(): string {
        return "";
    }

    getUserId(): string {
        return this._userId;
    }

    getEventData(): EventProps {
        let props: EventProps = {
            address: this._address,
            birthday: this._birthday,
            createdAt: this._createdAt,
            email: this._email,
            firstName: this._firstName,
            has_transactions: this._hasTransaction,
            lastName: this._lastName,
            phone: this._phone,
            utm_campaign: this._utmCampaign,
            utm_content: this._utmContent,
            utm_medium: this._utmMedium,
            utm_source: this._utmSource,
            utm_term: this._utmTerm,
            utm_medium_last: this._lastUtmMedium,
            utm_source_last: this._lastUtmSource,
            utm_campaign_last: this._lastUtmCampaign,
            utm_content_last: this._lastUtmContent,
            utm_term_last: this._lastUtmTerm,
            is_subscribed: this._isSubscribed,
            name: this._firstName + " " + this._lastName,
            is_activated: this._isActivated
        }
        const userTimezone = UserTimezoneRepository.getInstance().getValue();
        if(!userTimezone) {
            const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
            props.timezone = timezone;
            UserTimezoneRepository.getInstance().setValue(timezone);
        }

        if(this._retargetingLink && this._retargetingLink.trim() !== "")
            props.retargeting_link = this._retargetingLink;
        return props;
    }

    getEventName(): string {
        return "";
    }

    pageName(): string {
        return "";
    }

    getEventOptions(): EventOptions | undefined {
        if (this._intercomUserHash) {
            return {
                integrations: {
                    Intercom: {
                        user_hash: this._intercomUserHash
                    }
                }
            }
        }
        return undefined;
    }
}
