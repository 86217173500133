import React from "react"

interface ISvgSearchStepIcon {
    width: number;
    height: number;
    color: string;
    backgroundColor?: string;
}

export const SvgSearchStepIcon = ({ width = 48, height = 48, color, backgroundColor }: ISvgSearchStepIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 49 48" fill="none">
            <rect x="0.125" width="48" height="48" rx="24" fill="#13BA68"/>
            <path d="M23.375 29.25C26.6887 29.25 29.375 26.5637 29.375 23.25C29.375 19.9363 26.6887 17.25 23.375 17.25C20.0613 17.25 17.375 19.9363 17.375 23.25C17.375 26.5637 20.0613 29.25 23.375 29.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M30.8719 30.7508L27.6094 27.4883" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
