export const GBillingSettingsMockStaticTexts = {
    DEFAULT_TITLE: "Billing settings",
    DEFAULT_PLAN_BLOCK_TITLE: "Manage subscription",
    DEFAULT_PLANS: [
        {
            planName: "Individual Plan",
            planPrice: "14.99 $ / per month",
            planDescription: "You plan renews on August 22, 2023",
            actionName: "Cancel Plan",
        }
    ],
    DEFAULT_CTA_ADD_PAYMENT_METHOD: "Add payment method",
    DEFAULT_CTA_ADD_PAYMENT_METHOD_DESCRIPTION: "Your credit card will not be charged at this time",
    DEFAULT_TRANSACTION_HISTORY_BLOCK_TITLE: "Transaction History",
    DEFAULT_PAYMENTS_METHODS: [
        {
            id: "1234",
            cardBrand: "mastercard",
            cardExpiryMonth: 12,
            cardExpiryYear: 24,
            cardLast4: "1234",
            addressZip: "33137"
        }
    ] ,
    DEFAULT_TRANSACTIONS_HISTORY: [
        {
            date: "July 22, 2022",
            name: "Passport replacement governmental fee",
            price: "$9.99"
        },
        {
            date: "August 25, 2022",
            name: "Passport replacement governmental fee",
            price: "$9.99"
        }
    ]
}