
import {SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";

export enum CreditCardFormFields {
    CardNumber = "number",
    CVV = "cvv",
    ExpirationDate = "expirationDate",
    BillingZipCode = "billingZipCode"
}

export const LabelsSize = SIZE.PARAGRAPH_BOLD_16;
export const LabelsColor = COLORS_NEW.BLACK_900;



