import React from "react"
import GButton, {BUTTON_TYPE} from "../../../atomicDesign/molecules/Button/GButton";
import {SIZE} from "../../../atomicDesign/types/types";
import {QuestionnaireButtonSize, QuestionnaireButtonText} from "./QuestionnaireConstant";

type AdditionalQuestionBooleanProps = {
  question: any,
  handlerAdditionalQuestionClickButton: any,
  loadingYesBtn: boolean,
  loadingNoBtn: boolean,
  disableBtn: boolean,
}

// eslint-disable-next-line max-len
const AdditionalQuestionBoolean: React.FunctionComponent<AdditionalQuestionBooleanProps> = ({
  question,
  handlerAdditionalQuestionClickButton,
  loadingYesBtn,
  loadingNoBtn,
  disableBtn,
}) => {
  return (
    <>
      <div className={'options-container'}>
        <GButton
            textSize={SIZE.PARAGRAPH_BOLD_16}
            text={QuestionnaireButtonText.YES}
            size={QuestionnaireButtonSize.SMALL}
            showIcon={false}
            type={BUTTON_TYPE.SECONDARY}
            click={()=> handlerAdditionalQuestionClickButton(QuestionnaireButtonText.YES, question.nextYes)}
            loading={loadingYesBtn}
            disabled={disableBtn}
        />
        <GButton
            textSize={SIZE.PARAGRAPH_BOLD_16}
            text={QuestionnaireButtonText.NO}
            size={QuestionnaireButtonSize.SMALL}
            showIcon={false}
            type={BUTTON_TYPE.SECONDARY}
            click={()=> handlerAdditionalQuestionClickButton(QuestionnaireButtonText.NO, question.nextNo)}
            loading={loadingNoBtn}
            disabled={disableBtn}
        />
      </div>
    </>
  )
}
export default AdditionalQuestionBoolean
