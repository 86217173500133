/* eslint-disable indent */
import React from "react"
import { Form, InputNumber } from "antd"
import { FieldInterface } from "../../../../../Redux/InterfacesEntity/field.interface"
import { connect } from "react-redux"
import { ValidationItemInterface } from "../../../../../Redux/InterfacesEntity/validation.interface"
import { getPatternRules } from "../../InputComponent/validation"
import { getLabelWrapper } from "../../../../../services/service-function/field"

type NumberInputRenderComponentProps = {
  field: FieldInterface,
  value: string,
  validationRule: ValidationItemInterface | any,
  submitObject: {} | any,
  specialInput: string,
  iconsInput: string,
  getAccumulatorArrayTotal: any,
  handlerOnChangeInput: any,
  currentStep: { additionalStep: boolean },
  dispatch: any,
}

// eslint-disable-next-line max-len
const NumberInputRenderComponent: React.FunctionComponent<NumberInputRenderComponentProps> = ({
  field,
  value,
  validationRule,
  submitObject,
  specialInput,
  iconsInput,
  getAccumulatorArrayTotal,
  handlerOnChangeInput,
  currentStep,
  dispatch,
}) => {
  const { additionalStep } = currentStep
  return (
    <>
      <Form.Item
        label={getLabelWrapper(field, dispatch, additionalStep)}
        // eslint-disable-next-line max-len
        className={`form-item__number-input-container form-item__${specialInput}-container form-item__${iconsInput}-container`}
      >
        <Form.Item
          name={field.name}
          initialValue={value}
          rules={
            validationRule && validationRule.pattern && getAccumulatorArrayTotal(field.accumulator_total, submitObject)
              ? +value > -1
                ? [() => getPatternRules(validationRule.pattern, field), { required: true, message: "Required digits" }]
                : [
                    () => ({
                      validator() {
                        return Promise.reject("Num Arg The number must positive or 0")
                      },
                    }),
                  ]
              : [
                  () => ({
                    validator() {
                      return Promise.resolve("")
                    },
                  }),
                ]
          }
        >
          <InputNumber
            value={+value}
            min={-9999}
            maxLength={4}
            max={9999}
            onChange={(fieldValue) =>
              handlerOnChangeInput(fieldValue, field.name, field.accumulator_total, submitObject)
            }
          />
        </Form.Item>
      </Form.Item>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  currentStep: state.step.currentStep,
})

export default connect(mapStateToProps)(NumberInputRenderComponent)
