import React from "react";
import "./Completed.less"
import SimpleStatusesForProducts from "../../../../simpleStatusesForProducts";
import {useSelector} from "react-redux";
import {IconSvg} from "../../../../../../components/svgImages/SvgImages";

const Completed = ({status, productId}) =>{
  const {
    allProducts,
    allProductsLoader,
    anotherGovProducts
  } = useSelector(({allProducts}) => allProducts);
  const {name, lastName} = useSelector(({auth}) => auth.userData);
  const headerText = "Your EIN Number is ready!";
  const descriptionText = "You will receive your official confirmation letter by mail within 4 weeks. However, you can start using your EIN Number below immediately.";
  const productImage = anotherGovProducts?.find(item => item.id === allProducts[productId]?.product_name)?.image;
  const productName = anotherGovProducts?.find(item => item.id === allProducts[productId]?.product_name)?.title;
  const {download_link, einNumber} = allProducts[productId].add;

  const sendCopy = ()=>{
    window.open(download_link, "_blank")
  }

  return(
    <div className={"einCompleted_container"}>
      <SimpleStatusesForProducts
        userName={name}
        productName={productName}
        headerText={headerText}
        buttonText={""}
        productImage={productImage}
        iconStatus={status}
        description={descriptionText}
        productId={productId}
      />

      <div className={"EIN_completedFooter"}>
        <h2>Your EIN Number is:</h2>
        <div className={"EIN_completedFooter_einNumberContainer"}>
          <span>{einNumber || "No data"}</span>
        </div>
        <div className={"EIN_completedFooter_sendLinkContainer"}>
          <IconSvg type={"mailIcon"}/>
          <span
            onClick={sendCopy}
          >Download a copy of my SS-4</span>
        </div>
      </div>
    </div>
  )
}

export default Completed;
