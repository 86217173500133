import IEvent from "../IEvent";
import BaseBuilder from "../BaseBuilder";

import IdentityProtectionAlertConfirmed from "./IdentityProtectionAlertConfirmed";

/**
 * Identity Protection Alert Confirmed Builder
 * @class IdentityProtectionAlertConfirmedBuilder
 * @extends {BaseBuilder}
 * @implements {IBuilder}
 */
export default class IdentityProtectionAlertConfirmedBuilder extends BaseBuilder {
  private _alertClass: string | undefined;
  private _alertId: string | undefined;
  private _firstName: string | undefined;
  private _lastName: string | undefined;
  private _email: string | undefined;
  private _phone: string | undefined;

  /**
   * Set alert class
   * @param solution
   * @returns {IBuilder}
   * @description This method is used to set solution
   */

  setAlertClass(alertClass: string | undefined) {
    this._alertClass = alertClass;
    return this;
  }

  /**
   * Set alert id
   * @param alertId
   * @returns {IBuilder}
   * @description This method is used to set alert id
   */

  setAlertId(alertId: string | undefined) {
    this._alertId = alertId;
    return this;
  }

  /**
   * Set first name
   * @param firstName
   * @returns {IBuilder}
   * @description This method is used to set first name
   */

  setFirstName(firstName: string | undefined) {
    this._firstName = firstName;
    return this;
  }

  /**
   * Set last name
   * @param lastName
   * @returns {IBuilder}
   * @description This method is used to set last name
   */

  setLastName(lastName: string | undefined) {
    this._lastName = lastName;
    return this;
  }

  /**
   * Set email
   * @param email
   * @returns {IBuilder}
   * @description This method is used to set email
   */

  setEmail(email: string | undefined) {
    this._email = email;
    return this;
  }

  /**
   * Set phone
   * @param phone
   * @returns {IBuilder}
   * @description This method is used to set email
   */

  setPhone(phone: string | undefined) {
    this._phone = phone;
    return this;
  }

  /**
   * Set ip address
   * @param ipAddress
   * @returns {IBuilder}
   * @description This method is used to set ip address
   */

  setIpAddress(ipAddress: string | undefined) {
    this._ipAddress = ipAddress;
    return this;
  }

  /**
   * Build event
     * @returns {IEvent}
     * @description This method is used to build event
     */
    
  build(): IEvent {
    return new IdentityProtectionAlertConfirmed(
      this._alertClass,
      this._alertId,
      this._firstName,
      this._lastName,
      this._email,
      this._phone,
      this._ipAddress
    );
  }
}
