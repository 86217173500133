import TemplateOneBuilder from "../TemplateOneBuilder";
import {ITemplateOneBuilder} from "./ITemplateOneBuilder";
import {IUpsellProcessingData} from "../../../StepUpsell";
import TemplateOne from "../TemplateOne";
import {getExpressProcessingImage} from "../../../../../../../../util/UpsellHelper";
import {ICONS_SVG_TYPE} from "../../../../../../atoms/IconsSvg/IconsSvgTypes";

export class ExpressProcessingUpsell implements ITemplateOneBuilder {
    buildTemplate(builder: TemplateOneBuilder, data: IUpsellProcessingData, solutionName: string): TemplateOne {
        builder
            .setImageHeader(getExpressProcessingImage(solutionName))
            .setPreviousPrice('597')
            .setTagInformation('Last chance upgrade')
            .setFooterText('This timeline depends on you submitting the required documents as instructed within 24 hours. We’ll take it from there.')
            .setIconAcceptButton(ICONS_SVG_TYPE.CHEVRON_RIGHT)
        return builder.build();
    }
}