export const FONT_FAMILY = {
    INTER_BLACK_900: "InterBlack, sans-serif",
    INTER_EXTRA_BOLD_800: "InterExtraBold, sans-serif",
    INTER_BOLD_700: "InterBold, sans-serif",
    INTER_SEMI_BOLD_600: "InterSemiBold, sans-serif",
    INTER_MEDIUM_500: "InterMedium, sans-serif",
    INTER_REGULAR_400: "InterRegular, sans-serif",
    INTER_LIGHT_300: "InterLight, sans-serif",
    INTER_EXTRA_LIGHT_200: "InterExtraLight, sans-serif",
    INTER_THIN_100: "InterThin, sans-serif",

    POPPINS_THIN_100: "PoppinsThin, sans-serif",
    POPPINS_THIN_ITALIC_100: "PoppinsThinItalic, sans-serif",
    POPPINS_EXTRA_LIGHT_200: "PoppinsExtraLight, sans-serif",
    POPPINS_EXTRA_LIGHT_ITALIC_200: "PoppinsExtraLIghtItalic, sans-serif",
    POPPINS_LIGHT_300: "PoppinsLight, sans-serif",
    POPPINS_LIGHT_ITALIC_300: "PoppinsLightItalic, sans-serif",
    POPPINS_REGULAR_400: "PoppinsRegular, sans-serif",
    POPPINS_MEDIUM_500: "PoppinsMedium, sans-serif",
    POPPINS_MEDIUM_ITALIC_500: "PoppinsMediumItalic, sans-serif",
    POPPINS_SEMI_BOLD_600: "PoppinsSemiBold, sans-serif",
    POPPINS_SEMI_BOLD_ITALIC_600: "PoppinsSemiBoldItalic, sans-serif",
    POPPINS_BOLD_700: "PoppinsBold, sans-serif",
    POPPINS_BOLD_ITALIC_700: "PoppinsBoldItalic, sans-serif",
    POPPINS_EXTRA_BOLD_800: "PoppinsExtraBold, sans-serif",
    POPPINS_EXTRA_BOLD_ITALIC_800: "PoppinsExtraBoldItalic, sans-serif",
    POPPINS_BLACK_900: "PoppinsBlack, sans-serif",
    POPPINS_BLACK_ITALIC_900: "PoppinsBlackItalic, sans-serif",

    SILKA_SEMI_BOLD_600: "SilkaSemibold, sans-serif",
}

type SizeOptions = {
    [key: string]: string,
}

export const SIZE: SizeOptions = {
    HEADING_XXL: "h_xxl",
    HEADING_XL: "h_xl",
    HEADING_LG: "h_lg",
    HEADING_MDLG: "h_mdlg",
    HEADING_MD: "h_md",
    HEADING_SM: "h_sm",
    HEADING_XS: "h_xs",
    HEADING_XXS: "h_xxs",
    HEADING_XXXS: "h_xxxs",
    PARAGRAPH_BOLD_44: "p_b_44",
    PARAGRAPH_BOLD_40: "p_b_40",
    PARAGRAPH_SEMI_BOLD_40: "p_s_b_40",
    PARAGRAPH_REGULAR_35: "p_r_35",
    PARAGRAPH_BOLD_36: "p_b_36",
    PARAGRAPH_BOLD_32: "p_b_32",
    PARAGRAPH_BOLD_28: "p_b_28",
    PARAGRAPH_REGULAR_20: "p_r_20",
    PARAGRAPH_BOLD_24: "p_b_24",
    PARAGRAPH_BOLD_22: "p_b_22",
    PARAGRAPH_BOLD_20: "p_b_20",
    PARAGRAPH_REGULAR_18: "p_r_18",
    PARAGRAPH_BOLD_18: "p_b_18",
    PARAGRAPH_REGULAR_16: "p_r_16",
    PARAGRAPH_BOLD_16: "p_b_16",
    PARAGRAPH_REGULAR_14: "p_r_14",
    PARAGRAPH_BOLD_14: "p_b_14",
    PARAGRAPH_REGULAR_12: "p_r_12",
    PARAGRAPH_BOLD_12: "p_b_12",
    PARAGRAPH_REGULAR_11: "p_r_11",
    PARAGRAPH_BOLD_11: "p_b_11",
    LINK_REGULAR_14: "link_r_14",
    LINK_REGULAR_16: "link_r_16",

    SILKA_SEMI_BOLD_19: "s_s_b_19",
}

export const WEIGHT = {
    WEIGHT_400: "400",
    WEIGHT_500: "500",
    WEIGHT_600: "600",
    WEIGHT_700: "700",
}

export const BUTTON_TYPE = {
    PRIMARY: "primary",
    SECONDARY: "secondary",
}

export const BOX_TYPE = {
    HORIZONTAL: "HORIZONTAL",
    VERTICAL: "VERTICAL",
}

export interface TitleModel {
    text: string;
    size: string;
    weight?: string;
    color?: string;
    href?: string;
    linkTo?: string;
    innerHtml?: string;
    onClick?: any;
    style?: React.CSSProperties;
    maxWidth?: string;
}

export interface ISvgIcons {
    type: string;
    color?: string;
    width?: number;
    height?: number;
    backgroundColor?: string;
    fillCircle?: boolean;
}

export interface IButton {
    type?: string;
    click?: React.MouseEventHandler<HTMLElement>;
    size?: "large" | "middle" | "small";
    disabled?: boolean;
    name: string;
    nameSize?: string;
    icon?: string;
    isSubmit?: boolean;
    className?: string;
}

export enum PROGRESS_BAR_ORIENTATION {
    LINEAR = "lineal",
    VERTICAL = "vertical",
}

export interface ITag {
    text: string;
    colorBackground: string;
    colorText: string;
    click?: any;
    sizeText?: string;
    tooltip?: boolean;
}

export interface IUpsellPageContent {
    name: string;
    title: string;
    price: number;
    upsellInformation: any;
}

export interface PaymentMethod {
    id: string;
    cardBrand: string;
    cardExpiryMonth: number;
    cardExpiryYear: number;
    cardLast4: string;
    addressZip: string;
}

export type PaymentInformation = {
    isNewPaymentMethod: boolean;
    paymentMethod?: string;
    creditCard?: CreditCard;
    requestToken?: PaymentRequestToken;
    period?: string;
    trial?: string
}

export interface CreditCard {
    number: string;
    expirationDate: string;
    cvv: string;
    billingZipCode: string;
}

export enum INPUT_STATUS {
    DEFAULT = "Default",
    ERROR = "Error",
    WARNING = "Warning",
    SUCCESS = "Success",
}

export enum OfferType {
    Upsell = "upsell", Upgrade = "upgrade", CrossSell = "cross-sell"
}

export enum OfferAction {
    Accept = "accept", Cancel = "cancel"
}

export interface PaymentError {
    message: string;
}

export enum SubscriptionStatuses {
    ACTIVE = "ACTIVE",
    CANCELLED = "CANCELLED",
    EXPIRED = "EXPIRED",
    IN_TRIAL = "IN_TRIAL"
}

export enum InsuranceTypes {
    Loss = "Loss",
    Damage = "Damage",
    Theft = "Theft"
}

export enum DocumentStatusTypes {
    Processing = "Processing",
    Completed = "Completed",
    Blocked = "Blocked",
}

export enum FamilyMemberRelationships {
    Mother = "Mother",
    Father = "Father",
    Son = "Son",
    Daughter = "Daughter",
    Spouse = "Spouse",
    Grandfather = "Grandfather",
    Grandmother = "Grandmother",
    Granddaughter = "Granddaughter",
    Grandson = "Grandson",
    Brother = "Brother",
    Sister = "Sister",
    Cousin = "Cousin",
    Uncle = "Uncle",
    Aunt = "Aunt"
}

export enum InsuranceClaims {
    Lost = "Lost",
    Stolen = "Stolen",
    Damaged = "Damaged"
}
export interface CreditCardToken {
    tokenSourceName: string;
    tokenId: string;
}

export interface PaymentRequestToken {
    event: PaymentRequestEvent;
    creditCardToken: CreditCardToken;
    requestCompletedCallback?: (event: any) => void;
}

export interface PaymentRequestEvent {
    complete: (status: PaymentRequestCompleteStatus) => void;
    session?: any;
    status?: any;
}

export type PaymentRequestCompleteStatus = "success" | "fail";

export interface PriceInfo{
    productName: string;
    totalPay: number;
}

export interface SubscriptionData {
    id: number;
    method: string;
    price: number;
    saleLabel: string | null;
    salePrice: number | null;
    yearPrice: number | null;
}
export interface SubscriptionSubmitData {
    client_token: string,
    userId: string,
    anonymousId: string,
    userIp: string,
    payment_method?: string,
    card_no?: string,
    expiration_date?: string,
    ssc?: string,
    zip_pay?: string,
    period?: string,
    trial?: string,
    creditCardToken?: any,
    subscriptionCouponEnabled?: boolean,
    subscriptionCouponId?: string | null,
    subscribed_from: string
}
export const PATH_SUBSCRIPTION:{ [key: string]: string }  = {
    '/myInsuredDocuments': 'My Insured Documents',
    '/myAutomaticRenewals' : 'My Automatic Renewals',
    '/myIdentity': 'My Identity Protection',
}
