import {
  CONFIRM_SUBSCRIPTION,
  GET_BILLING_INFORMATION,
  GET_BILLING_INFORMATION_SUCCESS,
  SET_PAYMENT_ERROR
} from "../../constants/ActionTypes";

export const getBillingInformation = (authToken) => {
  return {
    type: GET_BILLING_INFORMATION,
    payload: authToken
  };
};

export const getBillingInformationSuccess = (information) => {
  return {
    type: GET_BILLING_INFORMATION_SUCCESS,
    payload: information
  };
};

export const confirmSubscription = (dataObject) => {
  return {
    type: CONFIRM_SUBSCRIPTION,
    payload: dataObject,
  }
}

export const setPaymentError = (paymentError) => {
  console.log('Billing Action: ', paymentError)
  return {
    type: SET_PAYMENT_ERROR,
    payload: paymentError,
  }
}
