import {notifyError} from "@dash/gov-plus__front-end__form/src/util/helper";
export const signUpHelper = {
  isDataFromLinkValid: (codedData)=>{
    try {
      return JSON.parse(atob(codedData))
    }catch (err){
      notifyError(err);
      return false
    }
  }
};
