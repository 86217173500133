import animationCloud from "../../../assets/image/animation_prefilled_cloud.png"

const AnimationDownLoadPrefilled = () => {
  return (
    <div className={"animation-prefilled__container"}>
      <div className={"animation-prefilled__header"}>
        <h3 className={"animation-prefilled__header-text"}>New birth certificate</h3>
      </div>
      <div className={"animation-prefilled__main"}>
        <p className={"animation-prefilled__main-text"}>
          Your birth certificate application is being completed automatically
        </p>
        <div className={"animation-prefilled__main-animation"}>
          <div className={"animation-prefilled__icon-wrapper"}>
            <img src={animationCloud} alt={""} className={"animation-prefilled__icon-item"} />
          </div>
        </div>
      </div>
      <div className={"animation-prefilled__footer"}>
        <div className={"animation-prefilled__downloader"}></div>
        <p className={"animation-prefilled__footer-text"}>Analyzing your situation...</p>
      </div>
    </div>
  )
}

export default AnimationDownLoadPrefilled
