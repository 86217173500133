import React from "react"

interface ISvgCheck {
    width: number;
    height: number;
    color: string;
    backgroundColor?: string;
    fillCircle?: boolean;
}

export const SvgRenewalCircle = ({
                                     width = 64,
                                     height = 64,
                                     color = "#113469",
                                     backgroundColor = "#EEF4FB",
                                     fillCircle = true
                                 }: ISvgCheck) => {

    const viewBox = `0 0 ${width} ${height}`;
    return (

        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.1734 0.349637C31.385 0.117186 33.615 0.117186 35.8266 0.349637C38.0382 0.582089 40.2194 1.04572 42.3344 1.73291C44.4493 2.42011 46.4865 3.32709 48.4123 4.439C50.3382 5.55091 52.1423 6.86162 53.7949 8.34964C55.4475 9.83766 56.9396 11.4948 58.2467 13.2939C59.5538 15.093 60.6688 17.0242 61.5733 19.0557C62.4778 21.0873 63.1669 23.2081 63.6293 25.3833C64.0916 27.5585 64.3247 29.7762 64.3247 32C64.3247 34.2238 64.0916 36.4415 63.6293 38.6167C63.1669 40.7919 62.4778 42.9127 61.5733 44.9443C60.6688 46.9758 59.5538 48.907 58.2467 50.7061C56.9396 52.5052 55.4475 54.1623 53.7949 55.6504C52.1423 57.1384 50.3382 58.4491 48.4123 59.561C46.4865 60.6729 44.4493 61.5799 42.3344 62.2671C40.2194 62.9543 38.0382 63.4179 35.8266 63.6504C33.615 63.8828 31.385 63.8828 29.1734 63.6504C26.9618 63.4179 24.7806 62.9543 22.6656 62.2671C20.5507 61.5799 18.5135 60.6729 16.5877 59.561C14.6618 58.4491 12.8577 57.1384 11.2051 55.6504C9.5525 54.1623 8.0604 52.5052 6.75328 50.7061C5.44615 48.907 4.3312 46.9758 3.42669 44.9443C2.52218 42.9127 1.8331 40.7919 1.37074 38.6167C0.908388 36.4415 0.675299 34.2238 0.675299 32C0.675299 29.7762 0.908388 27.5585 1.37074 25.3833C1.8331 23.2081 2.52218 21.0873 3.42669 19.0557C4.3312 17.0242 5.44615 15.093 6.75328 13.2939C8.0604 11.4948 9.5525 9.83766 11.2051 8.34964C12.8577 6.86162 14.6618 5.55091 16.5877 4.439C18.5135 3.32709 20.5507 2.42011 22.6656 1.73291C24.7806 1.04571 26.9618 0.58209 29.1734 0.349637Z"
                fill="#EEF4FB"/>
            <path d="M42.5014 24.7266V30.1814H37.0469" stroke="#113469" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M22.5 39.2751V33.8203H27.9545" stroke="#113469" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M24.7818 29.273C25.2429 27.97 26.0265 26.805 27.0595 25.8868C28.0925 24.9686 29.3413 24.3271 30.6894 24.022C32.0374 23.717 33.4407 23.7585 34.7684 24.1425C36.096 24.5266 37.3048 25.2407 38.2818 26.2183L42.5 30.1821M22.5 33.8186L26.7182 37.7824C27.6952 38.76 28.904 39.4741 30.2316 39.8582C31.5593 40.2422 32.9626 40.2837 34.3106 39.9787C35.6587 39.6736 36.9075 39.0321 37.9405 38.1139C38.9735 37.1957 39.7571 36.0307 40.2182 34.7277"
                stroke="#113469" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}


