import React, {FC, useState} from "react"
import "./CategorySelection.scss"
import { connect, useDispatch } from "react-redux"
import GText from "../../../atoms/Text/Text"
import { SIZE } from "../../../types/types"
import { COLORS } from "../../../types/Colors"
import GSelect from "../../../molecules/Select/Select"
import GIconsSvgType from "../../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../../atoms/IconsSvg/IconsSvgTypes"
import { Form } from "antd"
import { setValueCurrentField } from "../../../../../Redux/store/form/form.actions"
import GRenderServicesSelected from "./RenderServicesSelected";

const MAX_SELECTION_SERVICES = 3

export interface IBackendService {
  available: string;
  category_id: string;
  company_name: string;
  email: string;
  id: string;
  name: string;
  phone: string;
}

export interface IServicesCategory {
  name: string;
  type: string;
  placeholder: string;
  maxSelections: number;
  categoryName: string;
}

type GCategorySelectionProps = {
  key: string,
  category: IServicesCategory,
  submitObject: {} | any,
  servicesCustomer: {} | any,
}

const GCategorySelection: FC<GCategorySelectionProps> = ({ key, category, submitObject, servicesCustomer }) => {
  const dispatch = useDispatch()
  const allServices = (servicesCustomer && servicesCustomer[category.categoryName]) || []
  const selectionCap =
    category.maxSelections && Number.isInteger(category.maxSelections) ? category.maxSelections : MAX_SELECTION_SERVICES

  const servicesSelectedCount =
    submitObject["services"] === undefined || submitObject["services"][category.categoryName] === undefined
      ? 0
      : submitObject["services"][category.categoryName].length

  const wasSelected = (service: IBackendService) => {
    const serviceSelected =
      submitObject["services"] === undefined || submitObject["services"][category.categoryName] === undefined
        ? []
        : submitObject["services"][category.categoryName]
    return serviceSelected.some((item: IBackendService) => item.id === service.id)
  }

  const getOptionsServices = () => {
    const options: any[] = []
    allServices.forEach((service: IBackendService) => {
      if (!wasSelected(service)) {
        options.push({
          value: service.company_name,
          label: service.company_name,
        })
      }
    })
    return options
  }

  const handlerOnChange = (value: [string] | any) => {
    if (
      submitObject["services"] === undefined ||
      submitObject["services"][category.categoryName] === undefined ||
      submitObject["services"][category.categoryName].length < selectionCap
    ) {
      allServices.forEach(function (item: IBackendService) {
        if (value.includes(item.company_name)) {
          let services
          if (submitObject["services"] === undefined) {
            services = { [category.categoryName]: [item] }
          } else if (submitObject["services"][category.categoryName] === undefined) {
            services = {
              ...submitObject["services"],
              [category.categoryName]: [item],
            }
          } else {
            services = {
              ...submitObject["services"],
              [category.categoryName]: [...submitObject["services"][category.categoryName], item],
            }
          }
          dispatch(setValueCurrentField("services", services))
        }
      })
    }
  }

  const getServicesSelected = () => {
    if (submitObject["services"] === undefined) return []
    if (submitObject["services"][category.categoryName] === undefined) return []
    return submitObject["services"][category.categoryName]
  }

  const onDeleteItem = (service: IBackendService) => {
    if (submitObject["services"] !== undefined && submitObject["services"][category.categoryName] !== undefined) {
      let services = submitObject["services"][category.categoryName]
      const servicesResult = services.filter((item: IBackendService) => {
        return service.id !== item.id
      })
      services = { ...submitObject["services"], [category.categoryName]: servicesResult }
      dispatch(setValueCurrentField("services", services))
    }
  }

  return (
    <div key={key} className={`GCategorySelection`}>
      <Form.Item
        key={`Form--${key}`}
        label={
          <GText
            text={`${category.categoryName} (${servicesSelectedCount} out of ${selectionCap})`}
            size={SIZE.PARAGRAPH_BOLD_16}
            color={COLORS.SLATE_GRAY_500}
          />
        }
        className={`GCategorySelection__FormItem`}
        name={category.name}
      >
        <GSelect
          showSearch={true}
          placeHolder={"Select"}
          filterOption={(input: any, option: any) => {
            return (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
          }}
          icon={<GIconsSvgType type={ICONS_SVG_TYPE.DROPDOWN} color={COLORS.SLATE_GRAY_500} width={13} height={12} />}
          onChange={handlerOnChange}
          options={getOptionsServices()}
          showArrow={true}
        />
      </Form.Item>
      <GRenderServicesSelected servicesSelected={getServicesSelected()} onDeleteService={onDeleteItem} />
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
  servicesCustomer: state.form.servicesCustomer,
})

export default connect(mapStateToProps)(GCategorySelection)
