import { FC } from "react"
import { BasicBlockContainerProps } from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/interfaces/interfaces"
import PageMainLayout from "./PageMainLayout"
import FormPageContent from "../../Pages/FormPage/FormPageContent/FormPageContent"

const FormBaseBlock: FC<BasicBlockContainerProps> = ({ item: { className } }) => {
  return (
    <PageMainLayout {...{ className }}>
      <FormPageContent />
    </PageMainLayout>
  )
}

export default FormBaseBlock
