import React from "react"

interface ISvgHeaderSeparator {
  width: number;
  height: number;
  color: string;
}

export const SvgHeaderSeparator = ({ width = 20, height = 20, color = "#9B9B9B" }: ISvgHeaderSeparator) => {
  const viewBox = `0 0 ${width} ${height}`
  return (
      <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 15L12.5 10L7.5 5" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
}
