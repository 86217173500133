import React, { FC } from "react"
import GIconsSvgType from "../../../atoms/IconsSvg/IconsSvg"
import { Link } from "react-router-dom"
import GText from "../../../atoms/Text/Text"
import { SIZE } from "../../../types/types"
import { COLORS, COLORS_NEW } from "../../../types/Colors"
import "./DropDownMenuItem.scss"

const DROPDOWN_ICON_WIDTH = 20
const DROPDOWN_ICON_HEIGHT = 20

type GDropDownMenuItemProps = {
  linkTo?: string,
  icon: string,
  text: string,
  onClick?: any,
}

const GDropDownMenuItem: FC<GDropDownMenuItemProps> = ({ linkTo, icon, text, onClick }) => {
  return (
    <>
      {linkTo && (
        <Link className={"GDropDownMenuItem"} to={linkTo}>
          <GIconsSvgType height={DROPDOWN_ICON_WIDTH} width={DROPDOWN_ICON_HEIGHT} type={icon} color={COLORS_NEW.BLACK_600} />
          <GText text={text} size={SIZE.PARAGRAPH_BOLD_14} color={COLORS_NEW.BLACK_600} />
        </Link>
      )}
      {onClick && (
        <div className={"GDropDownMenuItem--Footer"} onClick={onClick}>
          <GIconsSvgType height={DROPDOWN_ICON_WIDTH} width={DROPDOWN_ICON_HEIGHT} type={icon} color={COLORS_NEW.BLACK_600} />
          <GText text={text} size={SIZE.PARAGRAPH_BOLD_14} color={COLORS_NEW.BLACK_600} />
        </div>
      )}
    </>
  )
}
export default GDropDownMenuItem
