export const productsAndStatusesConstants = {
  products: {
    PAS: "PAS",
    EIN: "EIN",
    COA: "COA",
    SSN: "SSN",
    CER: "CER",
    TSA: "TSA",
    NAM: "NAM",
  }
}
export const specialStatusConstants = {
  PAS: {
    completed: "completed"
  },
  EIN: {
    completed: "completed"
  },
  COA: {
    processing_notifications_services: "processing_notifications_services",
    processing: "processing",
    processing_error: "processing_error",
    processing_duplicate: "processing_duplicate",
    processing_notifications_agencies: "processing_notifications_agencies",
    completed: "completed",
    processing_validation: "processing_validation",
  },
  SSN: {
    tracking_to_customer: "tracking_to_customer",
  },
  NAM: {
    completed: "completed",
  },
  CER: {
    completed: "completed"
  },
  TSA: {
    appointment_selection: "appointment_selection",
    completed_new: "completed_new",
    completed_renew_expired: "completed_renew_expired",
    completed_renew: "completed_renew",
  }
}
