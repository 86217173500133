import { Action } from "../../interfacesAction/action.interface"

export const ActionTypes = {
  SAVE_PREFILLED: "[PERCENT] Save percents",
  GET_FORM_FILL_PERCENTS: "[PERCENT] Get form fill percent",
  SET_PERCENTS: "[PERCENT] Set percent",
  SET_USERS_DATA: "[STATE USERS] setUsersData",
  SUBMIT_GLO: "[GLO] Submit GLO",
  GET_WEBFLOW_BY_ID_KEY: "[WEBFLOW] get webflow by",
  SET_WEB_FLOW_DATA: "[WEBFLOW] set web flow data",
  GET_WEBFLOW_SOLUTIONS_DATA: "[WEBFLOW] get web flow solutions data",
  SET_WEBFLOW_SOLUTIONS_DATA: "[WEBFLOW] set web flow solutions data"
}

export const savePrefilled = (props: {}): Action<any> => ({
  type: ActionTypes.SAVE_PREFILLED,
  payload: props,
})

export const getFormFillPercents = (props: {}): Action<any> => ({
  type: ActionTypes.GET_FORM_FILL_PERCENTS,
  payload: props,
})

export const setPercents = (percents: number): Action<number> => ({
  type: ActionTypes.SET_PERCENTS,
  payload: percents,
})

export const setUsersData = (usersData: {}): Action<{}> => ({
  type: ActionTypes.SET_USERS_DATA,
  payload: usersData,
})

export const submitGLO = (submitObject: {}): Action<{}> => ({
  type: ActionTypes.SUBMIT_GLO,
  payload: submitObject,
})

export const getWebFlowByIdKey = () => ({
  type: ActionTypes.GET_WEBFLOW_BY_ID_KEY,
})

export const getWebFlowSolutions = () => ({
  type: ActionTypes.GET_WEBFLOW_SOLUTIONS_DATA,
})

export const setWebFlowSolutions = (webFlowSolutionsData: {}): Action<{}> => ({
  type: ActionTypes.SET_WEBFLOW_SOLUTIONS_DATA,
  payload: webFlowSolutionsData,
})

export const setWebFlowData = (webFlowData: {}): Action<{}> => ({
  type: ActionTypes.SET_WEB_FLOW_DATA,
  payload: webFlowData,
})