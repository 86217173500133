import React from "react"

interface ISvgGovOffice {
  width: number;
  height: number;
  color: string;
}

export const SvgGovOffice = ({ width = 25, height = 24, color = "#3E3E3E" }: ISvgGovOffice) => {
    const viewBox = `0 0 ${width} ${height}`;
  return (
      <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2043_33943)">
              <path d="M18.5 22V13H12.6184H6.5V22" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M13.5 5L16.5 5L16.5 3.52961L16.5 2L13.5 2" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M5.5 16H1.5V21" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M14.5 17V21" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10.5 17V21" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M19.5 16H23.5V21" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12.5 1.5L12.5 8.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M16.5 12.5075C16.405 11.5124 15.9426 10.5885 15.2029 9.91606C14.4633 9.24367 13.4996 8.87109 12.5 8.87109C11.5004 8.87109 10.5367 9.24367 9.79708 9.91606C9.05744 10.5885 8.59498 11.5124 8.5 12.5075" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
              <clipPath id="clip0_2043_33943">
                  <rect width={width} height={height} fill="white" transform="translate(0.5)"/>
              </clipPath>
          </defs>
      </svg>
  )
}
