import React from "react";
import {ICONS_SVG_TYPE} from "../../../Components/atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import InsuranceDocumentsList
    from "../../../Components/atomicDesign/templates/InsuranceDocumentsList/InsuranceDocumentsList";
import "./InsuranceDocumentsCancel.scss"
import GButton, {BUTTON_TYPE} from "../../../Components/atomicDesign/molecules/Button/GButton";
import {GeneralText, InsuredDocumentsText, SubscriptionText} from "../../../shared/text/Text";
import {COLORS, COLORS_NEW} from "../../../Components/atomicDesign/types/Colors";
import {useSubscription} from "../../../../../dashboard-govplus-front/src/hooks/useSubscription";
import ProductPage from "../../../Components/atomicDesign/templates/ProductPage/ProductPage";
import {useNavigation} from "../../../../../dashboard-govplus-front/src/hooks/useNavigation";
import GText from "../../../Components/atomicDesign/atoms/Text/Text";
import {SIZE} from "../../../Components/atomicDesign/types/types";
import {useHelperSubscription} from "../../../../../dashboard-govplus-front/src/hooks/useHelperSubscription";
import {useIdentityProtection} from "../../../../../dashboard-govplus-front/src/hooks/useIdentityProtection";

const InsuranceDocumentsCancel = () => {

    const {insuredDocuments, renewedDocuments, subscription} = useSubscription()
    const {identities} = useIdentityProtection()
    const {navigate} = useNavigation()
    const {triggerSubscriptionCancellationConfirmedAnalyticEvent} = useHelperSubscription()

    const proceedCancellation = () => {

        // @ts-ignore
        triggerSubscriptionCancellationConfirmedAnalyticEvent(subscription?.id);

        // @ts-ignore
        if (renewedDocuments.filter(document => document.automaticRenewalDate).length > 0) {
            navigate("/SubscriptionCancelRenewal")
        } else if (identities && identities?.records?.filter((record: { isPresent: boolean }) => record.isPresent).length > 0) {
            navigate("/SubscriptionCancelIdentityProtection")
        } else {
            navigate("/SubscriptionCancelReason")
        }
    }
    const keepSubscription = () => {
        navigate("./billing-settings")
    }

    const listComponent = () => {

        return <div className={"GSubscriptionCancel__Content"}>
            {/* @ts-ignore */}
            <InsuranceDocumentsList documents={insuredDocuments.filter(document => document.insured)}
                                    showCardsFooter={false}/>
        </div>


    }
    const footer = () => {
        return (
            <div className={"GSubscriptionCancel__Footer"}>
                <GButton
                    text={GeneralText.KeepPremium}
                    click={keepSubscription}
                    showIcon={false}
                />
                <GText
                    text={SubscriptionText.CancelSubscriptionDocumentsProtected}
                    innerHtml={SubscriptionText.CancelSubscriptionDocumentsProtected}
                    size={SIZE.PARAGRAPH_REGULAR_16}
                    color={COLORS_NEW.ROYAL_BLUE_700}
                    onClick={proceedCancellation}
                    maxWidth={"315px"}
                />
            </div>
        )
    }

    return (
        <ProductPage
            List={listComponent}
            headerText={InsuredDocumentsText.InsuranceCancelHeader}
            subHeaderText={InsuredDocumentsText.InsuranceCancelExplanation}
            Footer={footer}
            headerWidth="540px"
            subHeaderWidth="810px"
        />
    )
}

export default InsuranceDocumentsCancel