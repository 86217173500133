import {
  FORMS_SAVE_USER_DATA,
  FORMS_SAVE_USER_DATA_SUCCESSES,
  FORMS_SAVE_USER_DATA_UNSUCCESSES, GET_USER_DATA_FLATERN,
  SAVE_USER_DATA_FLATERN,
  SAVE_USER_DATA_FLATERN_FAILED,
  SAVE_USER_DATA_FLATERN_SUCCESSES,
  USER_PRE_AUTH
} from "../../constants/ActionTypes.js";

const userFlatternData = JSON.parse(sessionStorage.getItem("userFlattenData"))

const INIT_STATE = {
  loader: false,
  userSavedData: null,
  userFlattenData: {
    userId: userFlatternData?.userId,
    city_of_birth: userFlatternData?.address,
    country_of_birth: userFlatternData?.city,
    date_of_birth: userFlatternData?.city_of_birth,
    isUSA_citizen: userFlatternData?.country_of_birth,
    state_of_birth: userFlatternData?.date_of_birth,
    email: userFlatternData?.email,
    firstName: userFlatternData?.firstName,
    lastName: userFlatternData?.isUSA_citizen,
    address: userFlatternData?.lastName,
    city: userFlatternData?.state,
    state: userFlatternData?.state_of_birth,
    zipCode: userFlatternData?.zipCode,
  },
  error: "",
};


const UsersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FORMS_SAVE_USER_DATA: {
      return {
        ...state,
        loader: true,
      }
    }
    case FORMS_SAVE_USER_DATA_SUCCESSES: {
      return {
        ...state,
        loader: false,
        userSavedData: action.saveUserDataStatus
      }
    }
    case FORMS_SAVE_USER_DATA_UNSUCCESSES: {
      return {
        ...state,
        loader: false,
        userSavedData: action.saveuserDataStatus
      }
    }
    case SAVE_USER_DATA_FLATERN: {
      return {
        ...state,
        loader: true,
      }
    }
    case SAVE_USER_DATA_FLATERN_SUCCESSES: {
      return {
        ...state,
        loader: false,
        userFlattenData:{
          userId: action.payload?.userId,
          city_of_birth: action.payload.city_of_birth,
          country_of_birth: action.payload.country_of_birth,
          date_of_birth: action.payload.date_of_birth,
          isUSA_citizen: action.payload.isUSA_citizen,
          state_of_birth: action.payload.state_of_birth,
          email: action.payload.email,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          address: action.payload.address,
          city: action.payload.city,
          state: action.payload.state,
          zipCode: action.payload.zipCode,
        }
      }
    }
    case SAVE_USER_DATA_FLATERN_FAILED: {
      return {
        ...state,
        loader: false,
      }
    }
    case GET_USER_DATA_FLATERN: {
      return {
        ...state,
        loader: true
      }
    }
    default:
      return state;
  }
}

export default UsersReducer;
