export const criterionTypes = {
  AGE: "Age",
  EXPIRATION: "Expiration",
  YEAR: "Year",
  DATE: "Date",
  MONTH: "Month",
}
export const categories = {
  YEAR: "Year",
  DATE: "Date",
  MONTH: "Month",
  MONTH_NUMBER: "MonthNumber",
}
