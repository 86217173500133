import TemplateOneBuilder from "../TemplateOneBuilder";
import {ITemplateOneBuilder} from "./ITemplateOneBuilder";
import {IUpsellProcessingData} from "../../../StepUpsell";
import TemplateOne from "../TemplateOne";
import {getAuditProtectionImage} from "../../../../../../../../util/UpsellHelper";

export class AuditProtectionUpsell implements ITemplateOneBuilder{
    buildTemplate(builder: TemplateOneBuilder, data: IUpsellProcessingData, solutionName: string): TemplateOne {
        builder
            .setImageHeader(getAuditProtectionImage())
            .setPreviousPrice('77')
            .setCheckListItems([])
            .setTagInformation('Last chance upgrade')
        return builder.build();
    }
}