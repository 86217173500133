import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import productsSaga from "./Products";
import offersSaga from "./Offers";
import CheckForms from "./CheckForms";
import CheckUserStatus from "./CheckUserStatus";
import ProfileSettings from "./profileSettings";
import User from "./Users";
import billingsSaga from "./Billings";
import Notes from "./Notes";
import Tax from "./Tax";
import IDP from "./IDP";


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    productsSaga(),
    offersSaga(),
    CheckForms(),
    CheckUserStatus(),
    ProfileSettings(),
    User(),
    billingsSaga(),
    Notes(),
    Tax(),
    IDP()
  ]);
}
