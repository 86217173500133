import React, { useEffect, useMemo } from "react"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../App"
import {
  arrayStepSelector,
  formSelector,
} from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import InputComponent from "../InputComponent/InputComponent"
import NonUSAPhoneInputComponent from "./NonUSAPhoneInputComponent"
import { ValidationInterface } from "../../../../Redux/InterfacesEntity/validation.interface"
import { removeVariableCurrentField, setValueCurrentField } from "../../../../Redux/store/form/form.actions"

interface dependenceFieldInterface {
  name: string;
  splitter: string;
}

type dependenceFieldsType = dependenceFieldInterface[]

interface PhoneInputComponentInterface {
  field: fieldPropInterface;
}

interface fieldPropInterface {
  dependenceFields: dependenceFieldsType;
  phoneUSA: FieldInterface;
  phoneNonUSA: any;
}

const nonUSADomain = "govplus.com"

// this function should be extent in future
const getDependenceValue = (dependenceFields: dependenceFieldsType) => dependenceFields?.[0]

const PhoneInputContainer: React.FC<PhoneInputComponentInterface> = ({ field }) => {
  const { dependenceFields, phoneUSA, phoneNonUSA } = field
  const dispatch = useDispatch()
  const submitObject = useSelector<RootState, { submitObject: { [key: string]: string } }>(formSelector)?.submitObject

  // this function should be extent in future
  const { name, splitter } = getDependenceValue(dependenceFields)
  const statusNonUSAPhoneDomain = submitObject?.[name]?.split(splitter)?.[1]?.trim() === nonUSADomain

  // @ts-ignore
  const { properties } = useSelector<RootState, { validation: {} | ValidationInterface }>(arrayStepSelector)?.validation
  let pattern = useMemo(() => properties?.[phoneUSA?.name], [phoneUSA?.name, properties])

  const phoneValue = useMemo(() => submitObject?.[phoneUSA?.name], [phoneUSA?.name, submitObject])

  const standardValue = useMemo(() => submitObject[`${phoneUSA?.name}_mask`], [phoneUSA?.name, submitObject])

  useEffect(() => {
    if (standardValue && phoneValue !== standardValue) {
      dispatch(setValueCurrentField(phoneUSA?.name, standardValue))
      dispatch(removeVariableCurrentField(`${phoneUSA?.name}_mask`))
    }
  }, [dispatch, phoneUSA?.name, phoneValue, standardValue, submitObject])

  return (
    <>
      {statusNonUSAPhoneDomain && <NonUSAPhoneInputComponent phoneNonUSA={phoneNonUSA} />}
      {!statusNonUSAPhoneDomain && (
        <InputComponent
          field={phoneUSA}
          validationRule={pattern}
          required={true}
          value={submitObject?.[phoneUSA?.name]}
          observer={false}
        />
      )}
    </>
  )
}

export default PhoneInputContainer
