import React from 'react';
export type SvgGovernmentProp = {
    backgroundColor?: string;
    width?: number;
    height?: number;
    color?: string;
}

export const SvgGovernment = ({color="F7F9FA",  backgroundColor="white", width = 25, height = 25}: SvgGovernmentProp) => {
    return (
        <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={width} height={height} fill="#1E1E1E"/>
            <g clipPath="url(#clip0_0_1)">
            <rect width="1446" height="34227.5" transform="translate(-530 -25510.5)" fill={backgroundColor}/>
            <rect width="1445" height="34227.5" transform="translate(-529 -25510.5)" fill={color}/>
            <g filter="url(#filter0_d_0_1)">
            <g clipPath="url(#clip1_0_1)">
            <rect x="-211.5" y="-319.047" width="810" height="414" rx="24" fill={backgroundColor}/>
            <g filter="url(#filter1_d_0_1)">
            <rect x="-179.5" y="-117.047" width="746" height="180" rx="20" fill={backgroundColor}/>
            <rect x="-180" y="-117.547" width="747" height="181" rx="20.5" stroke="#CACACA"/>
            <g clipPath="url(#clip2_0_1)">
            <g clipPath="url(#clip3_0_1)">
            <path d="M18.5 22.9531V13.9531H12.6184H6.5V22.9531" stroke="#3E3E3E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.5 5.95312L16.5 5.95312L16.5 4.48273L16.5 2.95312L13.5 2.95312" stroke="#3E3E3E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.5 16.9531H1.5V21.9531" stroke="#3E3E3E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.5 17.9531V21.9531" stroke="#3E3E3E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.5 17.9531V21.9531" stroke="#3E3E3E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.5 16.9531H23.5V21.9531" stroke="#3E3E3E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.5 2.45312L12.5 9.45312" stroke="#3E3E3E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.5 13.4606C16.405 12.4655 15.9426 11.5416 15.2029 10.8692C14.4633 10.1968 13.4996 9.82422 12.5 9.82422C11.5004 9.82422 10.5367 10.1968 9.79708 10.8692C9.05744 11.5416 8.59498 12.4655 8.5 13.4606" stroke="#3E3E3E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            </g>
            </g>
            </g>
            </g>
            </g>
            <defs>
                <filter id="filter0_d_0_1" x="-251.5" y="-355.047" width="890" height="494" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="20"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
                </filter>
                <filter id="filter1_d_0_1" x="-214.5" y="-144.047" width="816" height="250" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feMorphology radius="6" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_0_1"/>
                    <feOffset dy="8"/>
                    <feGaussianBlur stdDeviation="14"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
                </filter>
                <clipPath id="clip0_0_1">
                    <rect x="-812" y="-25510.5" width="1728" height="34227.5" rx="20" fill={backgroundColor}/>
                </clipPath>
                <clipPath id="clip1_0_1">
                    <rect x="-211.5" y="-319.047" width="810" height="414" rx="24" fill={backgroundColor}/>
                </clipPath>
                <clipPath id="clip2_0_1">
                    <rect width="546" height="128" fill={backgroundColor} transform="translate(0.5 -91.0469)"/>
                </clipPath>
                <clipPath id="clip3_0_1">
                    <rect width={width} height={height} fill={backgroundColor} transform="translate(0.5 0.953125)"/>
                </clipPath>
            </defs>
        </svg>
    );
}