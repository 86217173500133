import React from "react"

interface ISvgPassport {
  width: number;
  height: number;
  color: string;
}

export const SvgPassport = ({ width = 24, height = 24, color = "6E6E6E" }: ISvgPassport) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.99976" y="2.00027" width="16" height="20" rx="2" stroke={color} strokeWidth="2" />
      <path
        d="M14.5 10C14.5 8.61929 13.3807 7.5 12 7.5C10.6193 7.5 9.5 8.61929 9.5 10C9.5 11.3807 10.6193 12.5 12 12.5C13.3807 12.5 14.5 11.3807 14.5 10Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 15.5H14" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
