import React, { FC } from "react"
import "./CollapseBottom.scss"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import { CollapseBottomPartProps } from "./CollapseBottom.props"
import { AlertBackgroundTypes, removeHTMLEntities, splitHandler } from "../../../../util/IdentityProtectionHelper"
import { COLORS_NEW } from "../../types/Colors"
import { SIZE } from "../../types/types"
import GButton, { BUTTON_TYPE } from "../../molecules/Button/GButton"
import GText from "../../atoms/Text/Text"
import DismissAlertStatus from "../DismissAlertStatus/DismissAlertStatus"
import GAlertBackgroundMessage from "../AlertBackgroundMessage/AlertBackgroundMessage"
import { useIdentityProtection } from "../../../../../../dashboard-govplus-front/src/hooks/useIdentityProtection"

const CollapseBottomPart: FC<CollapseBottomPartProps> = ({
  description,
  exposedData,
  actionsBlock,
  showActionButtons,
  showStatusLabel,
  confirmButtonAction,
  declineButtonAction,
  error,
  alert,
}) => {
  const updatedExposedData = splitHandler(exposedData, "<br />")
  const { loadingDismissAlert, loadingRedAlert } = useIdentityProtection()

  return (
    <div className={"CollapseBottomPart"}>
      {exposedData?.length && (
        <div className={"CollapseBottomPart__ExposedData"}>
          <div className={"CollapseBottomPart__Description"}>
            <GText text={"Exposed data:"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_800} />
            <ul className={"CollapseBottomPart__Description__List"}>
              {updatedExposedData?.map((data: string, index) => {
                return (
                  <li key={`${data}_${index}`}>
                    <GText text={data} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      )}
      {description && (
        <div className={"CollapseBottomPart__Content"}>
          <GText text={removeHTMLEntities(description)} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
        </div>
      )}
      {!error && actionsBlock && showActionButtons && (
        <div className={"CollapseBottomPart__Actions"}>
          <GButton
            text={"This was me"}
            iconType={ICONS_SVG_TYPE.ICON_APPROVED_BUTTON}
            type={BUTTON_TYPE.GREEN_BG}
            click={confirmButtonAction}
            loading={loadingDismissAlert}
          />
          <GButton
            text={`This wasn't me`}
            iconType={ICONS_SVG_TYPE.ICON_DECLINE_BUTTON}
            type={BUTTON_TYPE.RED_BG}
            click={declineButtonAction}
            loading={loadingRedAlert}
          />
        </div>
      )}

      {error && (
        <GAlertBackgroundMessage
          status={AlertBackgroundTypes.ERROR}
          message={"Error fetching user data. Please try again later."}
        />
      )}

      <div className={"CollapseBottomPart__Label"}>
        <DismissAlertStatus alert={alert} />
      </div>
    </div>
  )
}

export default CollapseBottomPart