/* eslint-disable indent */
import { ActionTypes } from "./upload.actions"
import { Action } from "../../interfacesAction/action.interface"
import { LoadingStateUploadDocument, LoadingStateUploadPhoto } from "../../../shared/constans/user-from-view-mode.enum"
import { currentStateDocumentsUploadInterface } from "../../../Components/FormComponents/PostSubmitComponents/UploadComponent/UploadSelectsRenderComponent/DNDUploadDocument/UploadDocumentActionBlock"

export interface State {
  linkPhoto: string;
  stateLoadingUploadPhoto: string;
  stateLoadingUploadDocument: string;
  uploadsDocuments: {} | null;
  uploadPhotoNameFile: null;
  submitDocumentParams: {} | null;
  currentStateDocumentsUpload: currentStateDocumentsUploadInterface;
  blobDocumentsUpload: currentStateDocumentsUploadInterface;
  documentsNameByFieldName: {};
}

const initialState: State = {
  linkPhoto: "",
  stateLoadingUploadPhoto: LoadingStateUploadPhoto.PreLoad,
  stateLoadingUploadDocument: LoadingStateUploadDocument.PreLoad,
  uploadsDocuments: null,
  uploadPhotoNameFile: null,
  submitDocumentParams: null,
  currentStateDocumentsUpload: {},
  blobDocumentsUpload: {},
  documentsNameByFieldName: {},
}

export const uploadReducer = (state: State = initialState, action: Action<{}>) => {
  switch (action.type) {
    case ActionTypes.SET_LINK_PHOTO:
      return {
        ...state,
        linkPhoto: action.payload,
      }
    case ActionTypes.SET_LINK_ORIGINAL_PHOTO:
      return {
        ...state,
        linkOriginalPhoto: action.payload,
      }
    case ActionTypes.SET_STATUS_UPLOAD_PHOTO:
      return {
        ...state,
        stateLoadingUploadPhoto: action.payload,
      }
    case ActionTypes.SET_DYNAMIC_UPLOAD_PHOTO_ERROR_MESSAGE:
      return {
        ...state,
        dynamicUploadPhotoErrorMessage: action.payload,
      }
    case ActionTypes.SET_STATUS_UPLOAD_DOCUMENT:
      return {
        ...state,
        stateLoadingUploadDocument: action.payload,
      }
    case ActionTypes.SET_UPLOADS_DOCUMENTS:
      return {
        ...state,
        uploadsDocuments: action.payload,
      }
    case ActionTypes.SET_CURRENT_PARAMS_UPLOAD_DOCUMENT:
      return {
        ...state,
        submitDocumentParams: action.payload,
      }
    case ActionTypes.SET_CURRENT_STATES_UPLOAD_DOCUMENTS:
      return {
        ...state,
        currentStateDocumentsUpload: { ...state.currentStateDocumentsUpload, ...action.payload },
      }
    case ActionTypes.SET_BLOB_UPLOAD_DOCUMENTS:
      return {
        ...state,
        blobDocumentsUpload: { ...state.blobDocumentsUpload, ...action.payload },
      }
    case ActionTypes.SET_DOCUMENTS_NAME_BY_FIELD_NAME:
      return {
        ...state,
        documentsNameByFieldName: { ...state.documentsNameByFieldName, ...action.payload },
      }
    case ActionTypes.SET_CURRENT_FILE_NAME:
      return {
        ...state,
        uploadPhotoNameFile: action.payload,
      }
    case ActionTypes.CLEAR_UPLOAD_IN_START_APP:
      return { ...initialState }
    case ActionTypes.CLEAR_BLOB_DOCUMENTS_UPLOAD:
      return {
        ...state,
        blobDocumentsUpload: {},
        currentStateDocumentsUpload: {},
        documentsNameByFieldName: {},
      }
    default:
      return state
  }
}
