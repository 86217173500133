import { PATH_SUBSCRIPTION, PaymentInformation, SubscriptionSubmitData } from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import { getClientTokenForResponse } from "@dash/gov-plus__front-end__form/src/Redux/store/step/service.step.saga";
import UserIpRepository from "@dash/gov-plus__front-end__form/src/persistence/session/UserIpRepository";
import { getPropertiesForEvents } from "@dash/gov-plus__front-end__form/src/services/gtm/backEndEvent";
import { useLocations } from "./useLocations";

export const useBuildDataForSubscription = () => {
    const {pathname} = useLocations()
    function buildDataForSubscription(paymentInformation: PaymentInformation, abTestingUpgradeOptions?: {subscriptionCouponEnabled?: boolean, subscriptionCouponId?: string | null} | null) {
        const {analyticsUserId, analyticsAnonymousId} = getPropertiesForEvents();
        const creditCardToken = paymentInformation?.requestToken?.creditCardToken;
        const dataForSubscription: SubscriptionSubmitData = {
            client_token: getClientTokenForResponse().client_token,
            userId: analyticsUserId,
            anonymousId: analyticsAnonymousId,
            userIp: UserIpRepository.getInstance().getValue(),
            payment_method: paymentInformation?.paymentMethod,
            card_no: paymentInformation.creditCard?.number,
            expiration_date: paymentInformation?.creditCard?.expirationDate,
            ssc: paymentInformation.creditCard?.cvv,
            zip_pay: paymentInformation.creditCard?.billingZipCode,
            period: paymentInformation.period,
            trial: paymentInformation.trial,
            subscribed_from: PATH_SUBSCRIPTION[pathname],
            ...abTestingUpgradeOptions
        }
        creditCardToken && (dataForSubscription['creditCardToken'] = creditCardToken)
        return dataForSubscription
    }
    return {
        buildDataForSubscription
    }
}