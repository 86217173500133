import React, {FC, useState} from "react";
import GSubscriptionPeriod from "../../organisms/SubscriptionPeriodItem/SubscriptionPeriodItem";
import GTotalPrice from "../../organisms/TotalPrice/TotalPrice";
import {SubscriptionData} from "../../types/types";
import {SubscriptionMethodFormProps} from "./SubscriptionMethodsForm.props";

const GSubscriptionsMethodsForm: FC<SubscriptionMethodFormProps> = ({
    subscriptionMethods,
    selectionSubscriptionMethodChange,
    hasTotalPriceBlock,
    defaultSubscriptionMethod
}) => {

    const [selectedSubscriptionMethod, setSelectedSubscriptionMethod] = useState<string>(defaultSubscriptionMethod)

    const onSelectedSubscriptionMethod = (subscription: string) => {
        setSelectedSubscriptionMethod(subscription);
        selectionSubscriptionMethodChange(subscription);
    }

    const getSubscriptionMethodPrice = (subscriptionMethod: string) => {
        const selectedRow = subscriptionMethods.find((sm: SubscriptionData) => sm.method === subscriptionMethod);
        return selectedRow?.yearPrice ? selectedRow.yearPrice : selectedRow?.price as number;
    }

    return (
        <>
            {subscriptionMethods.map(sm => (
                <GSubscriptionPeriod
                    key={sm.id}
                    id={sm.id}
                    price={sm.price}
                    method={sm.method}
                    saleLabel={sm.saleLabel}
                    salePrice={sm.salePrice}
                    yearPrice={sm.yearPrice}
                    isSelected={sm.method === selectedSubscriptionMethod}
                    onSelect={() => onSelectedSubscriptionMethod(sm.method)}
                />
            ))}
            {hasTotalPriceBlock && <GTotalPrice selectedPrice={getSubscriptionMethodPrice(selectedSubscriptionMethod)}/>}
        </>
    )
}
export default GSubscriptionsMethodsForm