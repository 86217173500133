import React, { FC, useEffect } from "react"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GAuthHeader from "../../molecules/AuthHeader/AuthHeader"
import "./SignIn.scss"
import { Form, Input } from "antd"
import GText from "../../atoms/Text/Text"
import {INPUT_STATUS, SIZE} from "../../types/types"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import GButton from "../../molecules/Button/GButton"
import GAuthFooter from "../../molecules/AuthFooter/AuthFooter"
import GFormItem, { FORM_ITEM_STATUSES } from "../../organisms/FormItem/FormItem"
import { INPUT_PASSWORD_STATUS } from "../../molecules/InputPassword/InputPassword"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import GInputText from "../../molecules/InputText/InputText";
import GNotification, { NOTIFICATION_TYPE } from "../../organisms/Notification/Notification"

type GSignInProps = {
  formOnFinish: any,
  form: any,
  onEmailChange: any,
  onEmailBlur: any,
  emailSuffixErrorIcon: any,
  emailErrorMessage: any,
  onPasswordChange: any,
  onPasswordBlur: any,
  passwordErrorMessage: any,
  loader: boolean,
  onFooterSecondLinkClick: any,
  signInErrorMessage: any,
}

const TITLE = "Sign in"
const DESCRIPTION = "Log in to your GOV+ account to access your dashboard and all our products."

const GSignIn: FC<GSignInProps> = ({
  formOnFinish,
  form,
  onEmailChange,
  onEmailBlur,
  emailSuffixErrorIcon,
  emailErrorMessage,
  onPasswordChange,
  onPasswordBlur,
  passwordErrorMessage,
  loader,
  onFooterSecondLinkClick,
  signInErrorMessage
}) => {
  useEffect(() => {
    localStorage.removeItem("userSignedIn")
  }, [])
  return (
    <div className={`GSignIn`}>
      <GAuthHeader title={TITLE} description={DESCRIPTION} />
      <Form
        initialValues={{ remember: true }}
        name={`basic`}
        onFinish={formOnFinish}
        className={`GSignIn__Form`}
        layout={`vertical`}
        form={form}
      >
        <GFormItem
          label={
            <div className={`GSignIn__Form__Item__Label`}>
              <GText text={`Email`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
            </div>
          }
          name="email"
          initialValue=""
          status={`${emailErrorMessage !== "" ? FORM_ITEM_STATUSES.ERROR : FORM_ITEM_STATUSES.DEFAULT}`}
          message={emailErrorMessage}
        >
          <GInputText
            onChange={onEmailChange}
            onBlur={onEmailBlur}
            value={""}
            suffixError={emailSuffixErrorIcon}
            inputStatus={`${emailErrorMessage !== "" ? INPUT_STATUS.ERROR : INPUT_STATUS.DEFAULT}`}
          />
        </GFormItem>
        <GFormItem
          label={
            <div className={`GSignIn__Form__Item__Label`}>
              <GText text={`Password`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
            </div>
          }
          name="password"
          status={`${passwordErrorMessage !== "" ? FORM_ITEM_STATUSES.ERROR : FORM_ITEM_STATUSES.DEFAULT}`}
          message={passwordErrorMessage}
          initialValue={``}
        >
          <Input.Password
            onChange={onPasswordChange}
            onBlur={onPasswordBlur}
            type={`password`}
            iconRender={(visible) =>
              visible ? (
                <GIconsSvgType
                  type={ICONS_SVG_TYPE.LOCK_OPEN}
                  width={20}
                  height={20}
                  color={passwordErrorMessage !== "" ? COLORS.RED_500 : COLORS_NEW.BLACK_700}
                />
              ) : (
                <GIconsSvgType
                  type={ICONS_SVG_TYPE.LOCK_CLOSE}
                  width={20}
                  height={20}
                  color={passwordErrorMessage !== "" ? COLORS.RED_500 : COLORS_NEW.BLACK_700}
                />
              )
            }
            className={`GInputPassword__Input GInputPassword__Input--${
              passwordErrorMessage !== "" ? INPUT_PASSWORD_STATUS.ERROR : INPUT_PASSWORD_STATUS.DEFAULT
            }`}
          />
        </GFormItem>

        <div className={`GSignIn__Form__ErrorMessage`}>
          {signInErrorMessage && <GNotification type={NOTIFICATION_TYPE.ERROR} text={signInErrorMessage} icon={ICONS_SVG_TYPE.INFORMATION} />}
        </div>

        <div className={`GSignIn__Form__BtnContainer`}>
          <GButton
            loading={loader}
            text={`Sign in securely`}
            size={"large"}
            isSubmit={true}
          />
        </div>
        <GAuthFooter
          firstLinkText={`Forgot password?`}
          firstLinkUrl={`/reset`}
          description={`Don’t have an account?`}
          secondLinkText={`Sign up.`}
          secondLinkUrl={`/signup?service=GLO&form_id=glo-sub`}
          secondLinkClick={onFooterSecondLinkClick}
        />
      </Form>
    </div>
  )
}
export default GSignIn
