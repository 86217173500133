import React from "react"

interface ISvgX {
  width: number;
  height: number;
  color: string;
}

export const SvgCloseModal = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18" stroke="#627086" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M6 6L18 18" stroke="#627086" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
}
