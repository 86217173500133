import React, { FC } from "react"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GAuthHeader from "../../molecules/AuthHeader/AuthHeader"
import "./ChangePassword.scss"
import { Form, Input } from "antd"
import { SIZE } from "../../types/types"
import {COLORS, COLORS_NEW} from "../../types/Colors"
import GText from "../../atoms/Text/Text"
import { INPUT_PASSWORD_STATUS } from "../../molecules/InputPassword/InputPassword"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import GValidationBlock from "../../molecules/ValidationBlock/ValidationBlock"
import GFormItem, { FORM_ITEM_STATUSES } from "../../organisms/FormItem/FormItem"
import GButton from "../../molecules/Button/GButton"
import { Link } from "react-router-dom"

type GChangePasswordProps = {
  formOnFinish: any,
  form: any,
  formOnChange: any,
  passwordErrorMessage: any,
  onFocusPassword: any,
  onBlurPassword: any,
  onChangePassword: any,
  validationRequirements: any,
  requirements: any,
  onChangeConfirm: any,
  confirmValidationMessage: any,
  loader: any,
}

const TITLE = "Reset Password"
const DESCRIPTION = ""

const GChangePassword: FC<GChangePasswordProps> = ({
  formOnFinish,
  form,
  loader,
  formOnChange,
  passwordErrorMessage,
  onFocusPassword,
  onBlurPassword,
  onChangePassword,
  validationRequirements,
  requirements,
  onChangeConfirm,
  confirmValidationMessage,
}) => {
  return (
    <div className={`GChangePassword`}>
      <GAuthHeader title={TITLE} description={DESCRIPTION} />
      <Form
        initialValues={{ remember: true }}
        name={`basic`}
        onFinish={formOnFinish}
        className={`GChangePassword__Form`}
        layout={`vertical`}
        form={form}
        onChange={formOnChange}
      >
        <Form.Item
          name="password"
          initialValue=""
          label={
            <div className={`GChangePassword__Form__Item__Label`}>
              <GText text={`New Password`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
            </div>
          }
          rules={[{ required: false }]}
          style={{ marginBottom: validationRequirements ? "9px" : "20px" }}
        >
          <Input.Password
            type="password"
            className={`GInputPassword__Input GInputPassword__Input--${
              passwordErrorMessage && passwordErrorMessage !== ""
                ? INPUT_PASSWORD_STATUS.ERROR
                : INPUT_PASSWORD_STATUS.DEFAULT
            }`}
            iconRender={(visible) => null}
            onFocus={onFocusPassword}
            onBlur={onBlurPassword}
            onChange={onChangePassword}
          />
        </Form.Item>
        {validationRequirements && (
          <div className={`GChangePassword__Form__GValidationBlock`}>
            {Object.values(requirements).map((rule: any, index) => {
              return (
                <div key={index}>
                  <GValidationBlock validationStatus={rule.status} validationMessage={rule.message} />
                </div>
              )
            })}
          </div>
        )}

        <GFormItem
          label={
            <div className={`GInputPassword__Form__Item__Label`}>
              <GText text={`Confirm Password`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
            </div>
          }
          name="confirm_password"
          status={`${
            confirmValidationMessage !== "" || passwordErrorMessage
              ? FORM_ITEM_STATUSES.ERROR
              : FORM_ITEM_STATUSES.DEFAULT
          }`}
          message={confirmValidationMessage}
          initialValue={``}
          rules={[{ required: false }]}
        >
          <Input.Password
            onChange={onChangeConfirm}
            type={`password`}
            iconRender={(visible) => null}
            className={`GInputPassword__Input GInputPassword__Input--${
              confirmValidationMessage !== "" || passwordErrorMessage
                ? INPUT_PASSWORD_STATUS.ERROR
                : INPUT_PASSWORD_STATUS.DEFAULT
            }`}
          />
        </GFormItem>
        <div className={`GChangePassword__Form__BtnContainer`}>
          <GButton disabled={loader} text={`Save`} textSize={SIZE.PARAGRAPH_REGULAR_16} size={"large"} isSubmit={true} />
        </div>
        <div className={`GAuthFooter`}>
          <div className={`GAuthFooter__Container`}>
            <Link to={`/signup`}>
              <div className={`GAuthFooter__Container__Link__Container`}>
                <GText text={`Cancel`} size={SIZE.PARAGRAPH_REGULAR_14} color={COLORS.ROYAL_BLUE_600} />
              </div>
            </Link>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default GChangePassword
