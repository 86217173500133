import React, {FC, useEffect, useState} from "react";
import "./InsuranceDocumentCardContent.scss"
import GText from "../../atoms/Text/Text";
import {InsuranceClaims, InsuranceTypes, SIZE} from "../../types/types";
import GServiceCardContentBadge from "../../atoms/ServiceCardContentBadge/ServiceCardContentBadge";
import GSelect from "../../molecules/Select/Select";
import GButton, {BUTTON_TYPE} from "../../molecules/Button/GButton";
import {Link} from "react-router-dom";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import {GeneralText} from "../../../../shared/text/Text";
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes";
import ActivateInsuranceModal from "../../templates/ActivateInsuranceModal/ActivateInsuranceModal";
// @ts-ignore
import {useSubscription} from "../../../../../../dashboard-govplus-front/src/hooks/useSubscription";
import {IInsuranceClaim, IInsuredDocument} from "../../types/Models";
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg";
import {Path} from "../../../../util/PageHelper";
import { useHelperSubscription } from "../../../../../../dashboard-govplus-front/src/hooks/useHelperSubscription";
import {capitalizeFirstLetter, getNewFormIdFromSolutionId} from "../../../../util/SolutionNames";
import ApplicationIdRepository from "../../../../persistence/session/ApplicationIdRepository";
import ToApplyFormIdRepository from "../../../../persistence/session/ToApplyFormIdRepository";
import ToApplyServiceIdRepository from "../../../../persistence/session/ToApplyServiceIdRepository";
import { EntryPointFlows } from "../../../../util/EntryPointFlows";
import { useApplications } from "../../../../../../dashboard-govplus-front/src/hooks/useApplications";

type InsuranceDocumentCardContentProps = {
    document: IInsuredDocument,
    showFooter?: boolean,
    onSubscribe? : () => void
}

const InsuranceDocumentCardContent: FC<InsuranceDocumentCardContentProps> = (
    {
        document,
        showFooter = true,
        onSubscribe
    }) => {
    const [insureModal, setInsureModal] = useState(false)
    const [loadingButton, setLoadingButton] = useState(false)
    const {insureDocument, makeClaim, subscriptionIsValid } = useSubscription()
    const {checkSameApplication} = useApplications()
    const {
        triggerDocumentInsuranceStartedAnalyticEvent, 
        triggerDocumentInsuranceCompletedAnalyticEvent, 
        } = useHelperSubscription()
    const insuredClaims = [
        {
            label: `${InsuranceClaims.Lost} ${document.solutionName}`,
            value: InsuranceClaims.Lost,
        },
        {
            label: `${InsuranceClaims.Stolen} ${document.solutionName}`,
            value: InsuranceClaims.Stolen,
        },
        {
            label: `${InsuranceClaims.Damaged} ${document.solutionName}`,
            value: InsuranceClaims.Damaged,
        },
    ]
    const insuranceClaimsForEIN = insuredClaims.slice(0, 2)

    const [selectedClaim, setSelectedClaim] = useState<InsuranceClaims>()

    const onStartClaim = () => {
        const claim: IInsuranceClaim = {
            name: document.name,
            solution: document.name,
            // @ts-ignore
            type: selectedClaim,
        }
        makeClaim(claim)
    }
    const onActivatedRenewal = () => {
        triggerDocumentInsuranceStartedAnalyticEvent(document.name)
        setInsureModal(true)
    }

    const buttonStyles: React.CSSProperties = {
        height: 44
    }

    const handleInsureDocument = (data: IInsuredDocument) => {
        triggerDocumentInsuranceCompletedAnalyticEvent(document.name)
        data.insured = true
        data.name = document.name
        insureDocument(data, () => {
            setInsureModal(false)
        })
    }

    const getNewDocumentWithGovPlus = () => {
        clearStorage()
        setLoadingButton(true)
        const formId = getNewFormIdFromSolutionId(document.name)
        if (formId) {
            const application = {
                name: document.name,
                solution: document.name,
                formId
            }
            checkSameApplication(application, true, () => {
                setLoadingButton(false)
            })
        }
    }

    const clearStorage = () => {
        sessionStorage.removeItem("currentStep")
        sessionStorage.removeItem("formNameForEvent")
        sessionStorage.removeItem("tagPrefilledFields")
        sessionStorage.removeItem("passport_details_event")
        sessionStorage.removeItem("signature_event")
        sessionStorage.removeItem("address_validation_event")
        sessionStorage.removeItem("upload_document_event")
        sessionStorage.removeItem("upload_additional_document_event")
        sessionStorage.removeItem("signature_event")
        ApplicationIdRepository.getInstance().removeItem();
        sessionStorage.removeItem("paymentGov")
    
        ToApplyFormIdRepository.getInstance().removeItem();
        ToApplyServiceIdRepository.getInstance().removeItem();
    
        sessionStorage.removeItem(EntryPointFlows.EntryPoint)
    }

    useEffect(() => {
        clearStorage()
    }, [selectedClaim])

    return (
        <>
            <ActivateInsuranceModal
                isOpen={insureModal}
                onClose={() => setInsureModal(false)}
                onSubmit={handleInsureDocument}
                product={document}
            />
        {document.insured &&
            <div className={"GInsuranceDocumentCardContent"} >
                <div className={"GInsuranceDocumentCardContent__Header"}>
                    <GText text={capitalizeFirstLetter(document.solutionName)} size={SIZE.PARAGRAPH_BOLD_20}/>
                </div>
                <div className={"GInsuranceDocumentCardContent__InsuranceList"}>
                    <GServiceCardContentBadge text={`${InsuranceTypes.Loss} protection`} iconType={ICONS_SVG_TYPE.CHECK_CIRCLE} />
                    {!document.name.includes('EIN') && <GServiceCardContentBadge text={`${InsuranceTypes.Damage} protection`}
                                               iconType={ICONS_SVG_TYPE.CHECK_CIRCLE}/>}
                    <GServiceCardContentBadge text={`${InsuranceTypes.Theft} protection`} iconType={ICONS_SVG_TYPE.CHECK_CIRCLE} />
                </div>
                {showFooter &&
                    <div className={"GInsuranceDocumentCardContent__Menu"}>
                        <GSelect
                            placeHolder={"Make a claim"}
                            icon={
                                <GIconsSvgType type={ICONS_SVG_TYPE.DROPDOWN} color={COLORS.SLATE_GRAY_500} width={13} height={12} />
                            }
                            width={"80%"}
                            options={document.name.includes("EIN") ? insuranceClaimsForEIN : insuredClaims}
                            onSelect={(value: any) => setSelectedClaim(value)}
                        />
                        <GButton
                            text={"Start"}
                            textSize={SIZE.PARAGRAPH_BOLD_16}
                            showIcon={false} style={{...buttonStyles, width: 83}}
                            click={onStartClaim}
                            disabled={!selectedClaim}
                        />
                    </div>
                }
            </div>
        }
        {!document.insured &&
            <div className={"GInsuranceDocumentCardContent InactiveContent"} >
                <div className={"GInsuranceDocumentCardContent__InactiveHeader"}>
                    <GText text={capitalizeFirstLetter(document.solutionName)} size={SIZE.PARAGRAPH_BOLD_20}/>
                    <GButton
                        text={`Insure ${document.solutionName}`}
                        showIcon={false}
                        textSize={SIZE.PARAGRAPH_BOLD_16}
                        style={buttonStyles}
                        click={subscriptionIsValid() ? onActivatedRenewal : onSubscribe}
                        loading={loadingButton}
                    />
                </div>
                <div className={"GInsuranceDocumentCardContent__InactiveText"}>
                    <GText text={`No ${document.solutionName} to insure?`} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800}/>
                    <GText 
                        text={GeneralText.LetGovPlusGetIt} 
                        size={SIZE.PARAGRAPH_REGULAR_16} 
                        color={COLORS_NEW.ROYAL_BLUE_700} 
                        onClick={getNewDocumentWithGovPlus} 
                        style={{textDecoration: "underline", cursor: "pointer"}} 
                    />
                </div>
            </div>
        }
        </>)
}

export default InsuranceDocumentCardContent