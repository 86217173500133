/**
 * Hold user information to prefill form autocomplete
 */
class FlattenDataDto {
  static VAR_EMAIL = "personal.communication.email";
  static VAR_PHONE_NUMBER = "personal.communication.phoneNumber";
  static VAR_CITY_OF_BIRTH = "personal.demographics.cityOfBirth";
  static VAR_STATE_OF_BIRTH = "personal.demographics.stateOfBirth";
  static VAR_FIRST_NAME = "personal.identity.firstName";
  static VAR_LAST_NAME = "personal.identity.lastName";
  static VAR_SSN = "personal.identity.socialSecurityNumber";
  static VAR_ADDRESS_1 = "personal.location.permanent.address1";
  static VAR_CITY = "personal.location.permanent.city";
  static VAR_STATE = "personal.location.permanent.state";
  static VAR_ZIP_CODE = "personal.location.permanent.zipCode";
  static VAR_DATE_OF_BIRTH = "personal.demographics.dateOfBirth";
  static VAR_COUNTRY_OF_CITIZENSHIP = "personal.demographics.countryOfCitizenship";
  static VAR_COUNTRY_OF_BIRTH = "personal.demographics.countryOfBirth";

  private readonly _email: string | undefined;
  private readonly _phoneNumber: string | undefined;
  private readonly _cityOfBirth: string | undefined;
  private readonly _stateOfBirth: string | undefined;
  private readonly _firstName: string | undefined;
  private readonly _lastName: string | undefined;
  private readonly _socialSecurityNumber: string | undefined;
  private readonly _address1: string | undefined;
  private readonly _city: string | undefined;
  private readonly _state: string | undefined;
  private readonly _zipCode: string | undefined;
  private readonly _dateOfBirth: string | undefined;
  private readonly _countryOfCitizenShip: string | undefined;
  private readonly _countryOfBirth: string | undefined;

  constructor(data: { [key: string]: string }) {
    this._email = data[FlattenDataDto.VAR_EMAIL];
    this._phoneNumber = data[FlattenDataDto.VAR_PHONE_NUMBER];
    this._cityOfBirth = data[FlattenDataDto.VAR_CITY_OF_BIRTH];
    this._stateOfBirth = data[FlattenDataDto.VAR_STATE_OF_BIRTH];
    this._firstName = data[FlattenDataDto.VAR_FIRST_NAME];
    this._lastName = data[FlattenDataDto.VAR_LAST_NAME];
    this._socialSecurityNumber = data[FlattenDataDto.VAR_SSN];
    this._address1 = data[FlattenDataDto.VAR_ADDRESS_1];
    this._city = data[FlattenDataDto.VAR_CITY];
    this._state = data[FlattenDataDto.VAR_STATE];
    this._zipCode = data[FlattenDataDto.VAR_ZIP_CODE];
    this._dateOfBirth = data[FlattenDataDto.VAR_DATE_OF_BIRTH];
    this._countryOfCitizenShip = data[FlattenDataDto.VAR_COUNTRY_OF_CITIZENSHIP];
    this._countryOfBirth = data[FlattenDataDto.VAR_COUNTRY_OF_BIRTH];
  }

  get email(): string | undefined {
    return this._email;
  }

  get phoneNumber(): string | undefined {
    return this._phoneNumber;
  }

  get cityOfBirth(): string | undefined {
    return this._cityOfBirth;
  }

  get stateOfBirth(): string | undefined {
    return this._stateOfBirth;
  }

  get firstName(): string | undefined {
    return this._firstName;
  }

  get lastName(): string | undefined {
    return this._lastName;
  }

  get socialSecurityNumber(): string | undefined {
    return this._socialSecurityNumber;
  }

  get address1(): string | undefined {
    return this._address1;
  }

  get city(): string | undefined {
    return this._city;
  }

  get state(): string | undefined {
    return this._state;
  }

  get zipCode(): string | undefined {
    return this._zipCode;
  }

  get dateOfBirth(): string | undefined {
    return this._dateOfBirth;
  }

  get countryOfCitizenShip(): string | undefined {
    return this._countryOfCitizenShip;
  }

  get countryOfBirth(): string | undefined {
    return this._countryOfBirth;
  }
}

export default FlattenDataDto;
