import {ApplicationStatus} from "@dash/gov-plus__front-end__form/src/util/ApplicationStatus";
import {ProductsIds} from "@dash/gov-plus__front-end__form/src/util/ProductsIds";

export interface ShouldShowApplicationParams {
    appData: any;
    statusesToExclude?: string[];
}

export const useShouldShowApplications = () => {
    const isStatusSelectedService = (status: string) => {
        return status === ApplicationStatus.STATUS_SELECT_SERVICE
    }

    const isStatusCancelledAndCrossSell = (status: string, isCrossSell: boolean) => {
        return status === ApplicationStatus.STATUS_CANCELED && isCrossSell;
    }

    const auxVerificationsShow = (showedByStatus: boolean, status: string, isCrossSell: boolean) => {
        if(!showedByStatus){
            return false
        }
        return !isStatusCancelledAndCrossSell(status, isCrossSell);
    }

    const applyStatusesFilter = (status: string, showedByStatus: boolean, statusesToExclude: string[] | undefined) => {
        if(!statusesToExclude || statusesToExclude.length === 0) {
            return showedByStatus;
        }
        statusesToExclude.forEach((statusToInclude) => {
            showedByStatus = showedByStatus && status !== statusToInclude;
        });
        return showedByStatus;
    }

    const shouldShowApplication = (params: ShouldShowApplicationParams) => {
        let showedByStatus;
        if(params.appData?.product_name !== ProductsIds.TAXId) {
            showedByStatus = params.appData?.status !== ApplicationStatus.STATUS_PENDING && !isStatusSelectedService(params.appData?.status);
        } else {
            showedByStatus = !isStatusSelectedService(params.appData?.status);
        }

        showedByStatus = applyStatusesFilter(
            params.appData?.status,
            showedByStatus,
            params.statusesToExclude
        );
        return auxVerificationsShow(showedByStatus, params.appData?.status, params.appData?.is_cross_sell);
    };

    return {
        shouldShowApplication
    };
}