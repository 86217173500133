import { AdditionalStepsInterface, ArrayPagesNameType } from "./statusesInterfaces"
import { getStatusBlock } from "./getStatesBlocks"
import { arrayActions } from "./dateByStatuses"
import { all, call, put } from "redux-saga/effects"

// check launch this function by name page

export const checkLaunchFunctionByPageName = (arrayPagesName: ArrayPagesNameType, currentPageName: string) =>
  arrayPagesName.find((page) => currentPageName === page)

// get array blocks

const getArrayBlocksSaga = (additionalSteps: AdditionalStepsInterface, pageApplication: string) =>
  additionalSteps?.[pageApplication]?.[0]?.stepContent?.blocksOnFormPage?.[0]?.blockOnFormPage
    ?.formsInBlockOnFormPage?.[0]?.form?.blocksWithFieldsOrNestBlocks?.[0]?.blocksSteps

// main saga function

function* iterationExecute(arrayBlocks: [], block: { status: string }, statusApplication: string): any {
  const stateBlock = yield getStatusBlock(arrayBlocks, block.status, statusApplication)
  const action = yield arrayActions.find(({ status: statusCurrentBlock }) => statusCurrentBlock === block.status)
    ?.action
  if (action && stateBlock) {
    yield put(action(stateBlock))
  }
}

export function* setStatusesCommonProducts(
  additionalSteps: AdditionalStepsInterface,
  statusApplication: string,
  pageApplication: string
): any {
  const arrayBlocks = yield call(getArrayBlocksSaga, additionalSteps, pageApplication)
  if(arrayBlocks) {
    yield all(
      arrayBlocks.map((block: { status: string }) => call(iterationExecute, arrayBlocks, block, statusApplication))
    )
  }
}
