import { FC } from "react"
import { BasicBlockContainerProps } from "../../../../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import {
  renderBasicBlockContainer,
  uploadSelector,
} from "../../../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import LayoutBaseContainer from "../../../../UploadPhoto/Layouts/LayoutBaseContainer/LayoutBaseContainer"
import { useSelector } from "react-redux"
import { RootState } from "../../../../../../App"
import { currentStateDocumentsUploadInterface } from "./UploadDocumentActionBlock"

const DynamicNameByFieldName: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text, state, type },
  arrayComponents,
  ...props
}) => {
  const { dynamicProps = { name_field: "" } } = props
  const { name_field } = dynamicProps
  const nameFileByFieldName =
    useSelector<RootState, { documentsNameByFieldName: currentStateDocumentsUploadInterface }>(uploadSelector)
      .documentsNameByFieldName?.[name_field] ?? ""
  return renderBasicBlockContainer(
    { className, text: nameFileByFieldName, childrenItem, arrayComponents, state },
    LayoutBaseContainer
  )
}

export default DynamicNameByFieldName
