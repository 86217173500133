import React, { FC, useState } from "react"
import "./PassportDetailsForm.scss"
import { Form } from "antd"
import { FormInput, validatePassportNumber } from "../../../../../util/IdentityProtectionHelper"
import GText from "../../../atoms/Text/Text"
import GButton from "../../../molecules/Button/GButton"
import GInputText from "../../../molecules/InputText/InputText"
import { COLORS_NEW } from "../../../types/Colors"
import { SIZE, BUTTON_TYPE } from "../../../types/types"

const PassportDetailsForm: FC<FormInput> = ({ recordType, handleFormActions }) => {
  const [passportNumberForm] = Form.useForm()
  const [passportNumberError, setPassportNumberError] = useState("")

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase()
    passportNumberForm.setFieldValue("passportNumber", event.target.value.toUpperCase())

    if (value.length === 9) {
      setPassportNumberError("")
    }
  }

  const handleFormSubmit = async () => {
    try {
      const valid = await passportNumberForm.validateFields()
      if (valid) {
        const submittedData = {
          recordType,
          value: passportNumberForm.getFieldValue("passportNumber"),
        }
        handleFormActions(submittedData)
        setPassportNumberError("")
      }
    } catch (error) {
      console.error(error)
      setPassportNumberError("Please enter a valid passport number")
    }
  }

  const getPassportFormLabel = () => {
    return (
      <div className={"PassportDetailsForm__Item__Label"}>
        <GText text={"Passport number"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
      </div>
    )
  }

  return (
    <Form
      className={"PassportDetailsForm"}
      layout={"vertical"}
      form={passportNumberForm}
      initialValues={{ remember: true }}
      name={"basic"}
    >
      <Form.Item
        className={"PassportDetailsForm__Item"}
        label={getPassportFormLabel()}
        name={"passportNumber"}
        initialValue={""}
        rules={[{ required: true, validator: validatePassportNumber }]}
        hasFeedback={false}
        help={passportNumberError}
        validateStatus={passportNumberError ? "error" : ""}
      >
        <GInputText onChange={handleInputChange} value={""} minLength={9} maxLength={9} />
      </Form.Item>

      <div className={"PassportDetailsForm__Actions"}>
        <GButton
          type={BUTTON_TYPE.SECONDARY}
          showIcon={false}
          style={{ height: "44px" }}
          text={"Add"}
          click={handleFormSubmit}
        />
      </div>
    </Form>
  )
}

export default PassportDetailsForm
