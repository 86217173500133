import React, {FC} from "react";
import RenewalDocumentCard from "../../organisms/RenewalDocumentCard/RenewalDocumentCard";
import "./RenewalDocumentsList.scss"
import { IRenewalDocument } from "../../types/Models";

type RenewalDocumentsListProps = {
    documents: IRenewalDocument[],
    onSubscribe?: () => void
}
const RenewalDocumentsList: FC<RenewalDocumentsListProps> = (
    {
        documents,
        onSubscribe
    }) => {

    return <div className={"GRenewalDocumentsList"}>
        {documents.map(document => <RenewalDocumentCard 
                                        key={document.name} 
                                        document={document}
                                        onSubscribe={onSubscribe}
                                    /> )}
    </div>
}

export default RenewalDocumentsList