import React from 'react';
import './SmallTrackingCard.scss';
import {SmallTrackingCardProps} from "./SmallTrackingCard.props";
import {PROGRESS_BAR_ORIENTATION, SIZE} from "../../../../../types/types";
import GText from "../../../../../atoms/Text/Text";
import GProgressBar from "../../../../../molecules/ProgressBar/ProgressBar";
import {COLORS, COLORS_NEW} from "../../../../../types/Colors";
import {ICONS_SVG_TYPE} from "../../../../../atoms/IconsSvg/IconsSvgTypes";
import IconsSvg from "../../../../../atoms/IconsSvg/IconsSvg";
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const GSmallTrackingCard: React.FC<SmallTrackingCardProps> = ({
                                                                imgSrc,
                                                                solution,
                                                                showGovernmentIcon,
                                                                governmentAgency,
                                                                numberOfWeeks,
                                                                textProgressBar,
                                                                width,
                                                                height,
                                                                showEstimateWaitTime = true
                                                              }) => {

  const imgSize = {width: `${width}px`, height: `${height}px`}
  const breakpoint = useBreakpoint()

  return (
    <div className={'GSmallTrackingCard'}>
      <div className={'GSmallTrackingCard__Image'}>
        <div className={'GSmallTrackingCard__Image__Container'}>
          <img src={imgSrc} alt="Tracking card" style={imgSize}/>
        </div>
      </div>
      <div className={'GSmallTrackingCard__Details'}>
        {showGovernmentIcon
          ? <>
            <GText
              text={`${solution}`}
              size={breakpoint.md ? SIZE.PARAGRAPH_BOLD_22 : SIZE.PARAGRAPH_BOLD_18}
              color={COLORS_NEW.BLACK_1000}
            />
            <GProgressBar
              showIcon={false}
              value={75}
              orientation={PROGRESS_BAR_ORIENTATION.LINEAR}
              customText={ textProgressBar || "In progress" }
              color={COLORS_NEW.GREEN_600}
            />
            <div className={'GSmallTrackingCard__Details__IconWrap'}>
               {breakpoint.md && <IconsSvg 
                  type={ICONS_SVG_TYPE.GOVERNMENT_ICON} 
                  width={25} 
                  height={25} 
                  backgroundColor={COLORS.WHITE}
                  color={COLORS_NEW.SLAYE_100} 
                  />}
              <div className={'GSmallTrackingCard__Details__IconWrap__Label'}>
                <GText
                  text={`${governmentAgency}`}
                  size={breakpoint.md ? SIZE.PARAGRAPH_BOLD_16 : SIZE.PARAGRAPH_BOLD_14}
                  color={COLORS_NEW.BLACK_800}
                />
                {
                  showEstimateWaitTime &&
                      <GText
                      text={`Estimated wait time: ${numberOfWeeks}`}
                      size={breakpoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14}
                      color={COLORS_NEW.BLACK_900}
                      innerHtml={breakpoint.md ? `Estimated wait time: ${numberOfWeeks}` : `Estimated wait time: <br> ${numberOfWeeks}`}
                  />
                }
              </div>
            </div>
          </>
          : <>
            <GText
              text={solution}
              size={breakpoint.md ? SIZE.PARAGRAPH_BOLD_22 : SIZE.PARAGRAPH_BOLD_18}
              color={COLORS_NEW.BLACK_1000}
            />
            <div className={'GSmallTrackingCard__Details__Progress'}>
              <GProgressBar
                showIcon={false}
                value={75}
                orientation={PROGRESS_BAR_ORIENTATION.LINEAR}
                customText= {textProgressBar || "In progress" }
                color={COLORS_NEW.GREEN_600}
              />
            </div>
            <div className={'GSmallTrackingCard__Details__Processing'}>
              <IconsSvg type={ICONS_SVG_TYPE.CLOCK_ICON} width={breakpoint.md ? 25 : 16} height={breakpoint.md ? 25 : 16}/>
              {
                showEstimateWaitTime &&
                    <GText
                      text={`Estimated wait time: ${numberOfWeeks || "few minutes" }`}
                      size={breakpoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14}
                      color={COLORS_NEW.BLACK_900}
                  />
              }
              
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default GSmallTrackingCard;
