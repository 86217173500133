export const changeFormatEIN = (submitObj: {} | any) => {
  let newSubmitObj: {} | any = {}
  // eslint-disable-next-line semi-style
  ;["ssn_hidden"].forEach((item: string) => {
    if (submitObj[item]) {
      newSubmitObj = dividerSSN(submitObj, item)
    }
  })
  if (newSubmitObj["form_id"]) {
    return newSubmitObj
  }
  return submitObj
}

const dividerSSN = (submitObj: any, name: string) => {
  let startArray = submitObj[name] && submitObj[name].split("-")

  const [ssn1, ssn2, ssn3] = [startArray[0], startArray[1], startArray[2]]
  const ssn = submitObj[name] && submitObj[name]

  return { ...submitObj, ssn, ssn1, ssn2, ssn3 }
}
