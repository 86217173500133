import React from "react";
import './index.less'

import {useSelector} from "react-redux";
import defaultImage from "../../../assets/images/defaultProductImage.png";
import ProductTicket from "../../../components/ProductTicket/ProductTicket";
import GText from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Text/Text";
import {SIZE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import {COLORS_NEW} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";
import {useApplications} from "../../../hooks/useApplications";

const NoSuchProductOrStatus = ({productName, productStatus, appId, description}) => {
    const {name} = useSelector(({auth}) => auth.userData);
    const {anotherGovProducts, allProducts} = useSelector(({allProducts}) => allProducts);
    const productImage = anotherGovProducts?.find(item => item.id === productName)?.illustration;
    const productTicketName = anotherGovProducts?.find(item => item.id === productName)?.title;
    const productFormIdName = anotherGovProducts?.find(item => item.id === productName)?.collapseInfo?.find(item => item.id === allProducts[appId].form_id).text;
    const defaultProductTicketName = "GOV+ Product";

    const title = name ? `Hello ${name},` : "Hello,";
    return (
        <div className={"noSuchProduct-container"}>
            <div className={`noSuchProduct-container__title-box`}>
                <GText text={title} size={SIZE.HEADING_XL} color={COLORS_NEW.BLACK_1000}/>
                <GText text={description} size={SIZE.PARAGRAPH_BOLD_18} color={COLORS_NEW.BLACK_500}/>

                <div className={"noSuchProduct-container-productInfo"}>
                    <GText text={`Your product name is ${productTicketName}`} size={SIZE.PARAGRAPH_REGULAR_16 } color={COLORS_NEW.BLACK_800}/>
                    <GText text={`Your product type is ${productFormIdName}`} size={SIZE.PARAGRAPH_REGULAR_16 } color={COLORS_NEW.BLACK_800}/>
                    <GText text={`Your product id is ${appId}`} size={SIZE.PARAGRAPH_REGULAR_16 } color={COLORS_NEW.BLACK_800}/>
                    <GText text={`Your product status is ${productStatus}`} size={SIZE.PARAGRAPH_REGULAR_16 } color={COLORS_NEW.BLACK_800}/>
                </div>
            </div>

            <ProductTicket
                ticketImage={productImage || defaultImage}
                ticketText={productTicketName || defaultProductTicketName}
            />
        </div>
    );
};

export default NoSuchProductOrStatus;

