import React, { FC } from "react"
import "./DropDownMenuContent.scss"
import { useDispatch } from "react-redux"
import GText from "../../../atoms/Text/Text"
import { SIZE } from "../../../types/types"
import { COLORS, COLORS_NEW } from "../../../types/Colors"
import { Divider, Avatar, Badge } from "antd"
import GDropDownMenuItem from "../DropDownMenuItem/DropDownMenuItem"
import {useHistory} from "react-router-dom";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

export interface IUserData {
  email: string;
  name: string;
  lastName: string;
  userId: string;
}

type GDropdownMenuContentProps = {
  userMenuItems: any,
  userSignOut: any,
  checkProductsForDuplicate: any,
  userData: IUserData,
  outerId: string,
  hideMenu: () => void
}

const GDropdownMenuContent: FC<GDropdownMenuContentProps> = ({
  userMenuItems,
  userSignOut,
  checkProductsForDuplicate,
  userData,
  outerId,
  hideMenu
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const avatar_text = () => {
    let first_name_initial = userData.name.charAt(0)
    let last_name_initial = userData.lastName.charAt(0)
    return first_name_initial + last_name_initial
  }

  const onClickLink = () => {
    hideMenu()
    dispatch(
      userSignOut({
        outerId: outerId,
        userId: userData.userId,
      })
    )
    dispatch(checkProductsForDuplicate())
  }

  const linkToBillingSettings = () => {
    hideMenu()
    history.push('/billing-settings')
  }

  return (
    <div className={`GDropdownMenuContent`}>
      <GDropDownMenuItem icon={userMenuItems[1].icon} text={userMenuItems[1].menuType} onClick={linkToBillingSettings} />
      <Divider className={`GDropdownMenuContent__Divider`} />
      <GDropDownMenuItem icon={userMenuItems[2].icon} text={userMenuItems[2].menuType} onClick={onClickLink} />
    </div>
  )
}
export default GDropdownMenuContent
