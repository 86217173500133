import React from "react";
import "./index.less";
import {Button, DatePicker, Form} from "antd";
import MaskedInput from "antd-mask-input";
import moment from "moment";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const ChangePayment = () => {
  const formInfo = ({CVC, Card_Number, day_month, year}) => {
    // console.log({
    //   CVC,
    //   Card_Number,
    //   day_month: moment(day_month).format('MM/DD'),
    //   year: moment(year).format('YYYY'),
    // })
  }

  return (
    <div className={"Change_Payment_Method"}>
      <Link to="/billing-settings" className="backLink">
        <ArrowLeftOutlined className="EditNamePage-link-icon"/>Billing settings
      </Link>
      <div>
        <Form
          className={"changePaymentSettingsForm"}
          layout={"vertical"}
          onFinish={formInfo}
        >
          <h1>Change Payment Method</h1>
          <span className={"changePaymentSettingsForm_description"}>
        To change your payment method please update your payment settings below.
      </span>
          <Form.Item
            className={"changePaymentSettingsForm_cardNumber"}
            initialValue=""
            rules={[{required: false, message: 'The input is not valid E-mail!'}]}
            name="Card_Number"
            label={"Card Number"}
          >
            <MaskedInput
              mask="1111-1111-1111-1111"
              placeholder={"xxxx-xxxx-xxxx-xxxx"}
            />
          </Form.Item>

          <div className={"changePaymentSettingsForm_cardExpirationInfo"}>
            <Form.Item
              className={"changePaymentSettingsForm_cardExpirationInfo_dayMonth"}
              initialValue=""
              name="day_month"
              label={"Expiration Date"}
            >
              <DatePicker format={"MM/DD"}/>
            </Form.Item>

            <Form.Item
              className={"changePaymentSettingsForm_cardExpirationInfo_year"}
              initialValue=""
              name="year"
            >
              <DatePicker picker="year" value={"year"} />
            </Form.Item>

            <Form.Item
              name={"CVC"}
              label={"CVC"}
              className={"changePaymentSettingsForm_cardExpirationInfo_CVC"}
            >
              <MaskedInput mask="111" name="ccv"/>
            </Form.Item>
          </div>

          <Button type="primary" className="changePaymentButton" htmlType="submit">
            Update
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ChangePayment;
