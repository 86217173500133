import React from 'react';
import './style.scss';

const SubscriptionAboutIcon: React.FC = () =>{
    return (
        <div className="icon">
            <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="23.9961" height="23.9961" rx="11.998" fill="#10BF6B"/>
                <path
                    d="M16.5693 8.85156L10.2831 15.1377L7.42578 12.2804"
                    stroke="white"
                    strokeWidth="1.13981"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
}

export default SubscriptionAboutIcon;