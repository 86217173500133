import React from "react"

interface ISvgMyInsuredDocuments {
  width: number;
  height: number;
  color: string;
}

export const SvgMyInsuredDocuments = ({ width, height, color }: ISvgMyInsuredDocuments) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22.7109C12 22.7109 20.5 18.7109 20.5 12.7109V5.71094L12 2.71094L3.5 5.71094V12.7109C3.5 18.7109 12 22.7109 12 22.7109Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.5 12.2109H9.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.5 16.2109H9.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.5 8.21094H10.5H9.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
