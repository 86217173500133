import React, { FC } from "react"
import { Button } from "antd"
import { ButtonComponentInterface } from "../UploadPhotoService/interfaces/interfaces"

const attachFile = ({ icon, className }: { icon: string | undefined, className: string }) =>
  icon ? <img className={`${className}-icon`} src={icon} alt={"icon"}></img> : null

const ButtonComponent: FC<ButtonComponentInterface> = ({ className, text, icon, handlerOnClick, disabled }) => (
  <Button className={className} onClick={handlerOnClick} disabled={disabled} icon={attachFile({ icon, className })}>
    {text}
  </Button>
)

export default ButtonComponent
