import { FC } from "react"
import { BasicBlockContainerProps } from "../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import {
  formSelector,
  renderBasicBlockContainer,
} from "../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import LayoutBaseContainer from "../UploadPhoto/Layouts/LayoutBaseContainer/LayoutBaseContainer"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../App"

const ReviewPageFieldValue: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text, state, field_name },
  arrayComponents,
  reviewPageProps = {},
  ...props
}) => {
  reviewPageProps = { ...reviewPageProps, field_name }
  const dispatch = useDispatch()
  const submitObject = useSelector<RootState, { submitObject: {} | any }>(formSelector).submitObject
  text = submitObject[field_name ?? ""] ?? "none"

  return renderBasicBlockContainer({ className, text, childrenItem, arrayComponents, state }, LayoutBaseContainer, {
    props: { ...props, reviewPageProps },
  })
}

export default ReviewPageFieldValue
