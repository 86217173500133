/* eslint-disable max-len */
/* eslint-disable indent */
import React from "react"
import { Button } from "antd"
import { connect } from "react-redux"
import priorityMail from "../../../../assets/image/priority-mail.png"
import security from "../../../../assets/image/security-mail.svg"
import PackageAdditionalNewComponent from "./PackageAdditionalNewComponent/PackageAdditionalNewComponent"
import { useEffect } from "react"
import { getAdditionalArraySteps } from "../../../../Redux/store/step/step.actions"

type PackageStepsComponentProps = {
  blocksStep: any,
  numberStep: number,
  packageResponse: {} | any,
  linkMove: string,
  dispatch: any,
}

// eslint-disable-next-line max-len
const PackageStepsComponent: React.FunctionComponent<PackageStepsComponentProps> = ({
  blocksStep,
  numberStep,
  packageResponse,
  linkMove,
  dispatch,
}) => {
  const { name } = blocksStep.content
  const { header } = blocksStep.content
  const { text } = blocksStep.content
  const arrDocHead = blocksStep.content.arrayDocuments && blocksStep.content.arrayDocuments.header

  const headerMail = blocksStep && blocksStep.content.header && blocksStep.content.header.header
  const textHeaderMail = blocksStep && blocksStep.content.header && blocksStep.content.header.headerDescription
  const mainMail = blocksStep && blocksStep.content.main
  const footerMail = blocksStep && blocksStep.content.footer

  const trackingNumber =
    packageResponse && ((packageResponse.success && packageResponse.success.trackingNum) || packageResponse.trackingNum)

  const success = packageResponse && (packageResponse.success || packageResponse)

  const dividerNumber = (str: string | any) => {
    if (!str) return
    return str.split("").reduce((acc: any, item: any, index: number) => {
      if ((index + 1) % 4 !== 0) return acc + item
      else return acc + item + " "
    }, "")
  }

  const cutRepeatItem = (arr: any) => {
    return (
      arr &&
      arr.reduce((acc: any, item: any) => {
        if (acc.length && acc.includes(item.trim())) {
          return acc
        }
        return acc.concat(item.trim())
      }, [])
    )
  }

  let arrayDocuments = cutRepeatItem(success && success.applicantDocuments) || []

  //for move to sendMessage(customer_support) page
  const getSubscribe = (linkMove: string) => {
    dispatch(getAdditionalArraySteps("customer_support"))
  }

  useEffect(() => {
    const wrapperLink = document.querySelector(".content-step__description")
    if (wrapperLink) {
      const link: any = wrapperLink.querySelector("a")
      link &&
        link.addEventListener("click", () => {
          getSubscribe(linkMove)
        })
      return () =>
        link &&
        link.removeEventListener("click", () => {
          getSubscribe(linkMove)
        })
    }
  })

  return (
    <div className={`steps-package-container`}>
      {name === "print" && (
        <>
          <div className={`icon-container_process icon-container_process-print`}>
            <p className={`icon-number`}>{numberStep}</p>
          </div>
          <div className={`div-bg steps-package__content-step`}>
            <h4 className={`content-step__header`}>{header}</h4>
            <p
              className={`content-step__description`}
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            ></p>
          </div>
        </>
      )}

      {name === "documents" && (
        <>
          <div
            // eslint-disable-next-line max-len
            className={`icon-container_process icon-container_process-documents`}
          >
            <p className={`icon-number`}>{numberStep}</p>
          </div>
          <div className={`div-bg steps-package__content-step`}>
            <h4 className={`content-step__header`}>{header}</h4>
            <ul className={`content-step__list-documents`}>
              {arrayDocuments.map((item: any, index: number) => {
                return (
                  <p className={`content-step__document`} key={index}>
                    {item}
                  </p>
                )
              })}
            </ul>
          </div>
        </>
      )}

      {name === "envelope" && (
        <>
          <div className={`icon-container_process icon-container_process-envelope`}>
            <p className={`icon-number`}>{numberStep}</p>
          </div>
          <div className={`div-bg steps-package__content-step`}>
            <h4 className={`content-step__header`}>{header}</h4>
            {text && <p className={`content-step__description`}>{text}</p>}
            <div className={`content-step__list-documents-container`}>
              <h6 className={`content-step__list-documents-header`}>{arrDocHead}</h6>
              {arrayDocuments.map((item: any, index: number) => {
                return (
                  <p className={`content-step__document`} key={index}>
                    {item}
                  </p>
                )
              })}
            </div>
          </div>
        </>
      )}

      {name === "simpleMail" && (
        <>
          <div
            // eslint-disable-next-line max-len
            className={`icon-container_process icon-container_process-simple-mail`}
          >
            <p className={`icon-number`}>{numberStep}</p>
          </div>
          <div className={`div-bg steps-package__content-step`}>
            <h4 className={`content-step__header`}>{headerMail}</h4>
            <p className={`content-step__description`} dangerouslySetInnerHTML={{ __html: textHeaderMail.first }}></p>
            <div className={`content-step__main-mail-container`}>
              <div className={`content-step__main-mail-header`}>
                <img className={`content-step__main-mail-header-icon`} src={security} alt="security-mail" />
                <h5 className={`content-step__main-mail-header-text`}>{mainMail.header}</h5>
              </div>
              <p className={`content-step__main-mail-text`}>{mainMail.description}</p>
            </div>
            <div className={"content-step__main-footer-container tracking-container"}>
              <h4 className={"tracking-header"}>{footerMail.header}</h4>
              <p className={"tracking-number"}>{dividerNumber(trackingNumber) || "9205 5000 0000 0000 0000 00"}</p>
              <div className={"tracking__button-container"}>
                <a
                  href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`}
                  className={"tracking__link"}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Button className={"tracking__button"}>{footerMail.button}</Button>
                </a>
                <img className={"tracking__img"} src={priorityMail} alt="tracking-img" />
              </div>
            </div>
          </div>
        </>
      )}

      {name === "additionalMail" && (
        <>
          <div
            // eslint-disable-next-line max-len
            className={`icon-container_process icon-container_process-additional-mail`}
          >
            <p className={`icon-number`}>{numberStep}</p>
          </div>
          <div className={`div-bg steps-package__content-step`}>
            <h4 className={`content-step__header`}>{headerMail}</h4>
            <p className={`content-step__description`}>{textHeaderMail.first}</p>
            <p className={`content-step__description`}>{textHeaderMail.second}</p>
            <p className={`content-step__description content-step__address`}>
              {`${success && success.address} ${success && success.city_state_zip}`}
            </p>
            <p className={`content-step__description`}>{textHeaderMail.fourth}</p>
            <div className={`content-step__main-mail-container`}>
              <div className={`content-step__main-mail-header`}>
                <img className={`content-step__main-mail-header-icon`} src={security} alt="security-mail" />
                <h5 className={`content-step__main-mail-header-text`}>{mainMail.header}</h5>
              </div>
              <p className={`content-step__main-mail-text`}>{mainMail.description}</p>
            </div>
            <div className={"content-step__main-footer-container tracking-container"}>
              <h4 className={"tracking-header"}>{footerMail.header}</h4>
              <p className={"tracking-number"}>{dividerNumber(trackingNumber) || "9205 5000 0000 0000 0000 00"}</p>
              <div className={"tracking__button-container"}>
                <a
                  href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={"tracking__link"}
                >
                  <Button className={"tracking__button"}>{footerMail.button}</Button>
                </a>
                <img className={"tracking__img"} src={priorityMail} alt="tracking-img" />
              </div>
            </div>
          </div>
        </>
      )}

      {name === "additionalMail-new" && (
        <PackageAdditionalNewComponent
          blocksStep={blocksStep}
          numberStep={numberStep}
          packageResponse={packageResponse}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  packageResponse: state.package.packageResponse,
  linkMove: state.additional.link,
})

export default connect(mapStateToProps)(PackageStepsComponent)
