import React, {FC} from "react";

import GServiceCardActive from "../../templates/GServiceCard/GServiceCardActive";
import GServiceCardInactive from "../../templates/GServiceCard/GServiceCardInactive";
import InsuranceDocumentCardContent from "../InsuranceDocumentCardContent/InsuranceDocumentCardContent";
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes";
import {IInsuredDocument} from "../../types/Models";

type InsuranceDocumentCardProps = {
    document: IInsuredDocument,
    className?: string,
    showFooter?: boolean,
    onSubscribe?: () => void
}

const InsuranceDocumentCard: FC<InsuranceDocumentCardProps> = (
    {
        document,
        className,
        showFooter = true,
        onSubscribe
    }) => {

        const content = () => {
            return <InsuranceDocumentCardContent 
                        document={document} 
                        showFooter={showFooter} 
                        onSubscribe={onSubscribe} 
                    />
        }

        return(
            <>
                {document.insured && <GServiceCardActive
                        badgeIcon={ICONS_SVG_TYPE.INSURANCE_SHIELD_CIRCLE}
                        imageURL={document.image}
                        Content={content}
                        className={className}
                    />
                }
                {!document.insured && <GServiceCardInactive
                        badgeText="Protected"
                        badgeIcon={ICONS_SVG_TYPE.INSURANCE_SHIELD_CIRCLE}
                        imageURL={document.image}
                        Content={content}
                        className={className}
                    />
                }
            </>
        ) 
            
}

export default InsuranceDocumentCard