import React from "react"

export const SvgSideBarExpandIcon = ({ width = 21, height = 20 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.75 12.5L10.75 7.5L5.75 12.5"
        stroke="#6E6E6E"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="rotate(180 10.25 10)"
      />
    </svg>
  )
}
