// @ts-nocheck

import {useState} from "react";
import {BraintreeConstants} from "./BraintreeConstants";
import {PriceInfo} from "../../types/types";
import {buildPaymentError, PaymentError} from "../../../../dto/PaymentError";
import {arrayPaymentError, getCorrespondFieldByErrorPayment} from "../../../../Redux/store/form/servicePayment";

export const useSetupBraintreeApplePay = (
    createClient: any,
    submitPaymentCallback: any,
    dispatchPaymentError: (paymentError: PaymentError | null) => void,
    setError?: (paymentError: PaymentError | null) => void,
) => {
    const applePayment = require('braintree-web/apple-pay');
    // @ts-ignore
    const {ApplePaySession} = window;

    const setupBraintreeApplePay = async (authorization: any) => {
        return await createClient({
            authorization
        }).then(function (clientInstance: any) {
            return applePayment.create({
                client: clientInstance
            });
        }).then(function (applePayInstance: any) {
            return applePayInstance;
        }).catch(function (error: any) {
            console.error(BraintreeConstants.APPLE_PAYMENT_ERROR, error);
            return false;
        });
    }

    const onClickApplePayBtn = (priceInfo: PriceInfo, applePayInstance: any) => {
        setError?.(null);
        dispatchPaymentError(null)
        console.log("applePayInstance", applePayInstance)
        console.log("ApplePaySession", ApplePaySession)
        if (applePayInstance && ApplePaySession) {
            try {
                const paymentRequest = applePayInstance.createPaymentRequest({
                    total: {
                        label: priceInfo.productName,
                        amount: `${priceInfo.totalPay / 100}`
                    },
                    requiredBillingContactFields: ["postalAddress"]
                });
                console.log('paymentRequest:', paymentRequest);
                const session = new ApplePaySession(3, paymentRequest);
                session.onvalidatemerchant = function (event: any) {
                    applePayInstance.performValidation({
                        validationURL: event.validationURL,
                        displayName: priceInfo.productName
                    }).then(function (merchantSession: any) {
                        console.log('merchantSession:', merchantSession);
                        session.completeMerchantValidation(merchantSession);
                    }).catch(function (validationErr: any) {
                        console.log('Error validating merchant:', validationErr);
                        const paymentError = buildPaymentError(
                            BraintreeConstants.APPLE_LOAD_PAYMENT_ERROR,
                            getCorrespondFieldByErrorPayment(
                                BraintreeConstants.APPLE_LOAD_PAYMENT_ERROR,
                                arrayPaymentError
                            )
                        )
                        dispatchPaymentError(paymentError)
                        setError?.(paymentError);
                        session.abort();
                        return;
                    });
                };
                session.onpaymentauthorized = function (event: any) {
                    applePayInstance.tokenize({
                        token: event.payment.token
                    }).then(function (payload: any) {

                        submitPaymentCallback(
                          payload?.nonce,
                          "apple",
                          session,
                          ApplePaySession.STATUS_SUCCESS
                        )

                    }).catch(function (tokenizeErr: any) {
                        console.error('Error tokenizing Apple Pay:', tokenizeErr);
                        // @ts-ignore
                        session.completePayment(ApplePaySession.STATUS_FAILURE);
                        return;
                    });
                };
                session.begin();
            } catch (err) {
                console.log(BraintreeConstants.APPLE_CREATE_PAYMENT_ERROR, err)
                const paymentError = buildPaymentError(
                    BraintreeConstants.APPLE_CREATE_PAYMENT_ERROR,
                    getCorrespondFieldByErrorPayment(
                        BraintreeConstants.APPLE_CREATE_PAYMENT_ERROR,
                        arrayPaymentError
                    )
                )

                dispatchPaymentError(paymentError)
                setError?.(paymentError);
            }
        }
    }

    const getApplePayButton = (priceInfo: PriceInfo, applePayInstance: any) => {
        const divContainer = document.createElement('div');
        divContainer.classList.add('apple-pay-button-with-text', 'apple-pay-button-white-with-text');
        divContainer.addEventListener('click', () => onClickApplePayBtn(priceInfo, applePayInstance));

        const spanBuyWithText = document.createElement('span');
        spanBuyWithText.classList.add('text');
        spanBuyWithText.textContent = 'Buy with';

        const spanLogo = document.createElement('span');
        spanLogo.classList.add('logo');

        divContainer.appendChild(spanBuyWithText);
        divContainer.appendChild(spanLogo);

        return divContainer;
    }

    return {
        setupBraintreeApplePay,
        getApplePayButton
    }
}