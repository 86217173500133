import "./PaymentMethod.scss"
import {PaymentMethodProps} from "./PaymentMethodProps";
import React, {FC, useState} from "react";
import {getCardImage, getCardName} from "../../../../util/CreditCardHelper";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import GRadio from "../../molecules/Radio/Radio";
import {GeneralText} from "../../../../shared/text/Text";

const GPaymentMethod: FC<PaymentMethodProps> =
    ({
         paymentMethodData,
         isSelected,
         onSelect,
         isDefault = false
     }) => {
        const triggerOnSelect = () => onSelect(paymentMethodData.id);
        const cardName = getCardName(paymentMethodData.cardBrand);
        const cardImage = getCardImage(paymentMethodData.cardBrand);
        const [isRadioHighlighted, setIsRadioHighlighted] = useState<boolean>(false);
        const expireYear = paymentMethodData.cardExpiryYear.toString().slice(-2);
        return (
            <div 
                key={paymentMethodData.id} onClick={triggerOnSelect} onMouseEnter={() => setIsRadioHighlighted(true)}
                onMouseLeave={() => setIsRadioHighlighted(false)}
                className={`GPaymentMethod-atomic ${isSelected ? 'selected' : ''}`}
            >
                <div className="radio">
                    <GRadio selected={isSelected} isHighlighted={isRadioHighlighted}/>
                </div>
                <div className="card-info">
                    <div className={'brand'}>
                        <div className={'name'}>
                            {cardImage && (<img src={cardImage} alt={cardName} width={32} height={21}/>)}
                            <GText text={cardName} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_800}/>
                            {isDefault && <GText text={GeneralText.Default} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.GREEN_600} />}
                        </div>
                        <GText 
                            text={`Ending in ${paymentMethodData.cardLast4}`} 
                            size={SIZE.PARAGRAPH_BOLD_16}
                            color={COLORS_NEW.BLACK_500}
                        />
                    </div>
                    <div className={'expiration'}>
                        <GText 
                            text={`Expires ${paymentMethodData.cardExpiryMonth}/${expireYear}`}
                            size={SIZE.PARAGRAPH_REGULAR_16} 
                            color={COLORS_NEW.BLACK_800}
                        />
                    </div>
                </div>
                
            </div>
        )
    }
export default GPaymentMethod;