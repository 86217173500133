import React, { FC } from "react"
import "./AddressRecord.scss"
import { RecordContentProps } from "../../../../../util/IdentityProtectionHelper"
import GText from "../../../atoms/Text/Text"
import { COLORS_NEW } from "../../../types/Colors"
import { SIZE } from "../../../types/types"
import GSpinner from "../../../atoms/Spinner/Spinner"

const AddressRecord: FC<RecordContentProps> = ({ addressTop, addressBottom, updateCustomerLoader }) => {
  return (
    <div className={updateCustomerLoader ? "AddressRecord__Loading" : "AddressRecord"}>
      {updateCustomerLoader && (
        <div className={"AddressRecord__Overlay"}>
          <div className={"AddressRecord__Spinner"}>
            <GSpinner />
          </div>
        </div>
      )}
      {(
        <>
          <GText text={addressTop as string} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} />
          <GText text={addressBottom as string} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
        </>
      )}
    </div>
  )
}

export default AddressRecord
