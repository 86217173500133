import { FC } from "react"
import {
  renderBasicBlockContainer,
  uploadSelector,
} from "../UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { RootState } from "../../../../App"
import { useSelector } from "react-redux"
import { BasicBlockContainerProps, ItemInterface } from "../UploadPhotoService/interfaces/interfaces"
import LayoutBaseContainer from "../Layouts/LayoutBaseContainer/LayoutBaseContainer"

const UploadPhotoActionBlock: FC<BasicBlockContainerProps> = ({
  item: { className, text, children: childrenItem = [], state },
  arrayComponents,
}) => {
  const currentStateUpload = useSelector<RootState, { stateLoadingUploadPhoto: string }>(
    uploadSelector
  ).stateLoadingUploadPhoto

  const findCurrentChildren = childrenItem.find((child: ItemInterface) => child.state === currentStateUpload)
    ? [childrenItem?.find((child: ItemInterface) => child.state === currentStateUpload)]
    : childrenItem

  return renderBasicBlockContainer(
    { className, text, childrenItem: findCurrentChildren, arrayComponents },
    LayoutBaseContainer
  )
}

export default UploadPhotoActionBlock
