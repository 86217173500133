import React from "react"

export const SvgAlertMessagesIcon = ({ width = 20, height = 22 }) => {
  return (
    <svg width={20} height={22} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.3 20C8.46738 20.3044 8.71345 20.5583 9.01249 20.7352C9.31153 20.912 9.65258 21.0053 10 21.0053C10.3474 21.0053 10.6885 20.912 10.9875 20.7352C11.2866 20.5583 11.5326 20.3044 11.7 20M4 7C4 5.4087 4.63214 3.88258 5.75736 2.75736C6.88258 1.63214 8.4087 1 10 1C11.5913 1 13.1174 1.63214 14.2426 2.75736C15.3679 3.88258 16 5.4087 16 7C16 14 19 16 19 16H1C1 16 4 14 4 7Z"
        stroke="#6E6E6E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
