/* eslint-disable indent */
import { ActionTypes } from "./cash.action"
import { Action } from "../../interfacesAction/action.interface"

export interface State {
  answerError: string;
}

const initialState: State = {
  answerError: "",
}

export const cashReducer = (state: State = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.SET_ANSWER_CHECK_STATUS:
      return {
        ...state,
        answerError: action.payload,
      }
    default:
      return state
  }
}
