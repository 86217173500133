import {Form, Input, Select} from "antd"
import moment from "moment"
import React, {FC, useCallback, useEffect, useMemo, useState} from "react"
import {connect, useDispatch, useSelector} from "react-redux"
import {RootState} from "../../../../App"
import {setCashState} from "../../../../Redux/store/cash/cash.action"
import {setObservers, setSubmitFormError, setValueCurrentField} from "../../../../Redux/store/form/form.actions"
import {getLabelWrapper} from "../../../../services/service-function/field"
import {
    formSelector
} from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import {categories} from "./constants"
import DataDropDownComponent from "./DataDropDownComponent/DataDropDownComponent"
import DateController from "./DateController"
import {defaultMonths} from "./defaultMonths.json"
import {
    capitalizeFirstLetter,
    checkPatternValidity,
    getMonthDate,
    optionsArrayFromMinMax,
    setStartMinMax,
    updateMaxDay
} from "./helpers"
import {customValidation} from "./services"

import {
    DateControllerReturnType,
    FieldsType,
    FieldType,
    OnSearchType,
    OnSelectChangeType,
    ValidationType,
    ValueType,
} from "./types"
import {hiddenField, setPrefilledValueInSubmitObject} from "../InputComponent/serviceInput"
import {savePrefilled} from "../../../../Redux/store/user/user.actions"

interface DataGroupDropDownComponentProps {
    field: FieldsType;
    currentStep: {
        additionalStep: boolean,
    };
    tagStoreWithFields: { [key: string]: string };
}

const {Option} = Select

const DataGroupDropDownComponent: FC<DataGroupDropDownComponentProps> = ({
                                                                             field: fieldGroupData,
                                                                             currentStep,
                                                                             tagStoreWithFields,
                                                                         }) => {
    const {additionalStep} = currentStep
    const dispatch = useDispatch()
    const submitObject = useSelector<RootState, { submitObject: {} | any }>(formSelector).submitObject
    // const Solution = useSelector<RootState>(state => state.step.currentStep.Solution)
    const submitError = useSelector<RootState, { submitError: {} | any}>(formSelector).submitError
    const {YEAR, DATE, MONTH, MONTH_NUMBER} = categories
    const {fields, criterions} = fieldGroupData
    const thisMoment = moment()
    const thisYear = useMemo(() => thisMoment.year(), [thisMoment])
    const thisMonth = useMemo(() => thisMoment.month() + 1, [thisMoment])
    const thisDate = useMemo(() => thisMoment.date(), [thisMoment])
    const {
        minStartYear,
        maxStartYear,
        minStartDate,
        maxStartDate,
        minStartMonth,
        maxStartMonth,
        expirationMoment,
        maxAgeMoment,
        minAgeMoment,
    } = setStartMinMax(criterions, moment())
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [minYear, setMinYear] = useState(minStartYear)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [maxYear, setMaxYear] = useState(maxStartYear)
    const [minMonth, setMinMonth] = useState(minStartMonth)
    const [maxMonth, setMaxMonth] = useState(maxStartMonth)
    const [isYearValid, setIsYearValid] = useState<boolean>(true)
    const [isMonthValid, setIsMonthValid] = useState<boolean>(true)
    const [isDateValid, setIsDateValid] = useState<boolean>(true)
    let defaultDate: number | undefined
    let defaultMonth: number | undefined
    let defaultYear: number | undefined
    const formatDateNumber = (value: string | number, isReverse?: true) => {
        const valueString = value.toString()
        let res
        if (isReverse && valueString.length === 2 && valueString.charAt(0) === "0") {
            res = valueString.charAt(1)
        } else {
            res = valueString.length === 1 ? `0${valueString}` : valueString
        }
        return res
    }

    fields.forEach(({name, defaultValue, category}: FieldType) => {
        let prevValue = name && submitObject[name] && formatDateNumber(submitObject[name], true)

        const setDefaultValue = (min: number | undefined, max: number | undefined) => {
            if (min && max) {
                let res
                if (!prevValue && defaultValue && defaultValue >= min && defaultValue <= max) {
                    res = defaultValue
                } else {
                    res = prevValue
                }
                return res
            }
        }
        if (category) {
            switch (category) {
                case MONTH:
                    prevValue = prevValue && defaultMonths.find(({index}) => index === +prevValue)?.name.substring(0, 3)
                    defaultMonth = setDefaultValue(minMonth, maxMonth)
                    break
                case MONTH_NUMBER:
                    const aux = prevValue && defaultMonths.find(({index}) => index === +prevValue)?.index
                    prevValue = aux < 10 ? "0" + aux : aux
                    defaultMonth = setDefaultValue(minMonth, maxMonth)
                    break
                case YEAR:
                    defaultYear = setDefaultValue(minYear, maxYear)
                    break
                case DATE:
                    defaultDate = setDefaultValue(minStartDate, maxStartDate)
                    break
            }
        }
    })

    const date: DateControllerReturnType = DateController(minStartDate, maxStartDate, defaultDate)
    const minDate: number | undefined = date && date.minDate
    const maxDate: number | undefined = date && date.maxDate
    const [monthValue, setMonthValue] = useState(defaultMonth)
    const [yearValue, setYearValue] = useState(defaultYear)
    const [ageValidate, setAgeValidate] = useState(false)
    const [uiMessage, setUiMessage] = useState('')
    let now = new Date();
    let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let dateOfBirth = new Date(Number(yearValue), Number(monthValue) - 1, Number(date?.dateValue));
    let dateOfBirthNow = new Date(today.getFullYear(), dateOfBirth.getMonth(), dateOfBirth.getDate());
    let age = today.getFullYear() - dateOfBirth.getFullYear();
    let startMonthsData = useMemo(
        () => getMonthDate(defaultMonths, minMonth, maxMonth, minDate, maxDate),
        [minMonth, maxMonth, minDate, maxDate]
    )
    const [dispatchString, setDispatchingString] = useState('')
    const [allDate, setAllDate] = useState(false)

    const setValid = () => {
        setIsDateValid(true)
        setIsMonthValid(true)
        setIsYearValid(true)
    }

    useEffect(() => {
        if (fieldGroupData.name === 'date_birth') {
            if (today < dateOfBirthNow) {
                age = age - 1
            }
            if (age < 18) {
                setAgeValidate(true)
                setUiMessage('You must be at least 18 years old')
            } else {
                setAgeValidate(false)
                setUiMessage('')
            }
        }
    }, [date?.dateValue, monthValue, yearValue])

    const startYears = useMemo(() => optionsArrayFromMinMax(minYear, maxYear, true), [minYear, maxYear])
    const [monthsData, setMonthsData] = useState(startMonthsData)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [years, setYears] = useState(startYears)
    const setStoreField = (name: string, value: string | undefined) => {
        dispatch(setValueCurrentField(name, value))
    }
    const setCashStore = (propObj: { [name: string]: string | undefined }) => {
        dispatch(setCashState({...submitObject, ...propObj}))
    }
    const onSelectChange: OnSelectChangeType = (value, name, nameBlock, category) => {
        if (category && value && name && nameBlock) {
            setStoreField(name, formatDateNumber(value))
            const submitNameBlock = submitObject[nameBlock] || '00/00/0000'
            switch (category) {
                case MONTH: {
                    setMonthValue(+value)
                    customValidation(dispatchString, expirationMoment, thisMoment, minAgeMoment, maxAgeMoment, yearValue)
                        ? setValid()
                        : setIsMonthValid(false)
                    break
                }
                case MONTH_NUMBER: {
                    setMonthValue(+value)
                    customValidation(dispatchString, expirationMoment, thisMoment, minAgeMoment, maxAgeMoment, yearValue)
                        ? setValid()
                        : setIsMonthValid(false)
                    break
                }
                case DATE: {
                    date?.setDateValue(+value)
                    customValidation(dispatchString, expirationMoment, thisMoment, minAgeMoment, maxAgeMoment, yearValue)
                        ? setValid()
                        : setIsDateValid(false)
                    break
                }
                case YEAR: {
                    setYearValue(+value)
                    if (fieldGroupData.observable) {
                        // check on year data picker
                        const ageOfClient = +value + "/" + +thisMoment.format("YYYY")
                        dispatch(setObservers(fieldGroupData.observable, ageOfClient + ""))
                    }
                    customValidation(dispatchString, expirationMoment, thisMoment, minAgeMoment, maxAgeMoment, yearValue)
                        ? setValid()
                        : setIsYearValid(false)
                    break
                }
            }
            if (submitError?.error?.data && (fieldGroupData.name === 'date_lost'
                                                || fieldGroupData.name === 'marriage_date'
                                                || fieldGroupData.name === 'fdate_w_mm'
                                                || fieldGroupData.name === 'parent1_date_birth'
                                                || fieldGroupData.name === 'parent2_date_birth')) {
                dispatch(setSubmitFormError({}))
            }
            setCashStore({
                [name]: formatDateNumber(value),
            })
        }
    }

    useEffect(() => {
        if (fieldGroupData.name === 'fdate_w_mm') {
           date?.setDateValue(1)
        }
        const monthString = monthValue && ((monthValue + "").length === 1 ? "0" + monthValue : monthValue)
        const dayString = date?.dateValue && ((date?.dateValue + "").length === 1 ? "0" + date?.dateValue : date?.dateValue)
        if (monthValue && date?.dateValue && yearValue) {
            setDispatchingString(`${monthString}/${dayString}/${yearValue}`)
        }
    }, [date?.dateValue, monthValue, yearValue])

    useEffect(() => {
        if (monthValue && date?.dateValue && yearValue && !ageValidate) {
            if (checkPatternValidity(dispatchString)) {
                setStoreField(fieldGroupData.name, dispatchString)
                setAllDate(allDate => !allDate)
            }
        }
    }, [dispatchString])

    useEffect(() => {
        if (monthValue && date?.dateValue && yearValue && fieldGroupData.tag && !ageValidate) {
            dispatch(savePrefilled({[fieldGroupData.tag ?? ""]: submitObject[fieldGroupData.name]}))
        }
        if (monthValue && date?.dateValue && yearValue && checkPatternValidity(dispatchString)) {
            setCashStore({
                [fieldGroupData.name]: dispatchString,
            })
        }
    }, [allDate])

    const setDefaultMonthDateData = useCallback(() => {
        date?.setMaxDate(31)
        date?.setMinDate(1)
        setMaxMonth(12)
        setMinMonth(1)
        setMonthsData(startMonthsData)
        date?.setDates(optionsArrayFromMinMax(date.minDate, date.maxDate))
    }, [startMonthsData, date])

    useEffect(() => {
        const isThisYear = yearValue === thisYear
        let newMaxDate, newMinDate

        if (monthValue) {
            const isThisMonth = monthValue === thisMonth

            if (isThisYear) {
                let newMonthsData
                if (expirationMoment) {
                    newMonthsData = startMonthsData
                    const monthData =
                        newMonthsData &&
                        newMonthsData.find(({index, max}) => index === monthValue && (!date?.dateValue || max >= date?.dateValue))
                    if (monthData) {
                        newMinDate = 1
                        newMaxDate = monthData.max
                    }
                } else {
                    newMonthsData = startMonthsData.filter(
                        ({index, max}) => index <= thisMonth && (!date?.dateValue || max >= date?.dateValue)
                    )
                    if (isThisMonth) {
                        newMaxDate = thisDate
                    } else {
                        newMaxDate = updateMaxDay(newMonthsData)
                    }
                }
                if (newMonthsData) {
                    setMonthsData(newMonthsData)
                }
            } else {
                const monthData = monthsData.find(({index}) => index === monthValue)
                if (monthData) {
                    const {max, min} = monthData
                    if (!date?.dateValue || (date?.dateValue && date?.dateValue <= max)) {
                        if (monthValue === thisMonth) {
                            if (
                                (maxAgeMoment && yearValue === minStartYear) ||
                                (minAgeMoment && yearValue === minStartYear) ||
                                (expirationMoment && yearValue === maxStartYear)
                            ) {
                                newMaxDate = thisDate
                            } else if (minAgeMoment && yearValue === maxStartYear) {
                                newMinDate = thisDate
                                newMaxDate = max
                            } else {
                                newMinDate = min
                                newMaxDate = max
                                setMonthsData(startMonthsData)
                            }
                        } else {
                            newMinDate = min
                            newMaxDate = max
                            setMonthsData(startMonthsData)
                        }
                    }
                }
            }
            // check leap year for February
            if (yearValue && monthValue === 2 && new Date(yearValue, 1, 29).getMonth() === 1) {
                newMaxDate = 29
            }
        } else {
            let newMonthsData

            if (date?.dateValue) {
                newMonthsData = startMonthsData.filter(
                    ({index, max}) => (!isThisYear || index <= thisMonth) && date?.dateValue && max >= date?.dateValue
                )
            } else if ((minAgeMoment && yearValue === maxStartYear) || (expirationMoment && yearValue === thisYear)) {
                newMonthsData = startMonthsData.filter(({index, max}) => index >= thisMonth)
            } else if (
                isThisYear ||
                (maxAgeMoment && yearValue === minStartYear) ||
                (expirationMoment && yearValue === maxStartYear)
            ) {
                newMonthsData = startMonthsData.filter(({index, max}) => index <= thisMonth)
            } else {
                setDefaultMonthDateData()
            }
            if (newMonthsData) {
                newMaxDate = updateMaxDay(newMonthsData)
                setMonthsData(newMonthsData)
            }
        }
        if (date) {
            const {setMinDate, setMaxDate, setMinMaxDates, minDate, maxDate} = date
            let min = minDate
            let max = maxDate
            if (newMinDate) {
                setMinDate(newMinDate)
                min = newMinDate
            }
            if (newMaxDate) {
                setMaxDate(newMaxDate)
                max = newMaxDate
            }
            setMinMaxDates(min, max)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthValue, yearValue, date?.dateValue])

    useEffect(() => {
        const dateField = submitObject[fieldGroupData.name] && formatDateNumber(submitObject[fieldGroupData.name], true)

        if (!dateField) {
            const month = defaultMonths.find(month => String(monthValue)?.slice(0,2) === month.name.slice(0,2))
            const monthString = month?.index && ((month?.index + "").length === 1 ? "0" + month?.index : month?.index)
            const dayString =
                date?.dateValue && ((date?.dateValue + "").length === 1 ? "0" + date?.dateValue : date?.dateValue)
            if (monthString) {
                setMonthValue(+monthString)
            }
            if (dayString) {
                date?.setDateValue(+dayString)
            }
            if (yearValue) {
                setYearValue(+yearValue)
            }
            const dateFieldString = `${monthString}/${dayString}/${yearValue}`
            if (monthString && dayString && yearValue) {
                if (checkPatternValidity(dateFieldString)) {
                    setStoreField(fieldGroupData.name, dateFieldString)
                    setCashStore({
                        [fieldGroupData.name]: dispatchString,
                    })
                }
            }
        } else {
            setMonthValue(+dateField.split('/')[0])
            date?.setDateValue(+dateField.split('/')[1])
            setYearValue(+dateField.split('/')[2])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const components = fields.map((fieldData: FieldType) => {
        const {category} = fieldData
        const validation: ValidationType = (rule, value, callback, {optionalField}) => {
            if (submitObject[rule.field] || optionalField) {
                if (fieldGroupData.name === 'date_birth') {
                    if (ageValidate) {
                        return callback('')
                    }
                }
                return callback()
            }
            // return callback(`${label} is required`)
            return callback("Required")
        }
        let value: ValueType
        let optionElements: JSX.Element[] | null | undefined = null
        let onSearch: OnSearchType = (value, setCurrentValue) => setCurrentValue(value)
        let invalidText = ""

        switch (category) {
            case MONTH:
                value = monthValue ? monthValue + "" : null
                optionElements = monthsData.map(({name, min}, i) => (
                    <Option key={name} value={min + i}>
                        {name.substring(0, 3)}
                    </Option>
                ))
                onSearch = (value, setCurrentValue) => {
                    if (!value || (value && isNaN(+value))) {
                        setCurrentValue(value.length > 3 ? value.substring(0, 3) : value)
                    }
                }
                invalidText = isMonthValid ? "" : "Invalid"
                break
            case MONTH_NUMBER:
                value = monthValue ? monthValue + "" : null
                optionElements = monthsData.map(({name, min, index}, i) => (
                    <Option key={name} value={min + i}>
                        {index < 10 ? "0"+index : index}
                    </Option>
                ))
                onSearch = (value, setCurrentValue) => {
                    if (!value || (value && !isNaN(+value))) {
                        setCurrentValue(value.length > 2 ? value.substring(0, 2) : value)
                    }
                }
                invalidText = isMonthValid ? "" : "Invalid"
                break
            case YEAR:
                value = yearValue ? yearValue + "" : null
                optionElements = years
                onSearch = (value, setCurrentValue) => {
                    if (!value) {
                        setCurrentValue(value)
                    } else if (value && !isNaN(+value)) {
                        const intValue = parseInt(value.length > 4 ? value.substring(0, 4) : value, 10)
                        if (maxYear && intValue <= maxYear) {
                            setCurrentValue(intValue + "")
                        }
                    }
                }
                invalidText = isYearValid ? "" : "Invalid"
                break
            case DATE:
                value = date?.dateValue ? date?.dateValue + "" : undefined
                optionElements = date?.dates
                onSearch = (value, setCurrentValue) => {
                    if (!value) {
                        setCurrentValue(value)
                    } else if (value && !isNaN(+value)) {
                        const intValue = parseInt(value.length > 2 ? value.substring(0, 2) : value, 10)
                        if (date?.maxDate && intValue <= date?.maxDate) {
                            setCurrentValue(intValue + "")
                        }
                    }
                }
                invalidText = isDateValid ? "" : "Invalid"
                break
            default:
                break
        }

        return (
            category && (
                <DataDropDownComponent
                    {...{
                        fieldData,
                        onSelectChange,
                        value,
                        optionElements,
                        onSearch,
                        validation,
                        invalidText,
                        ageValidate,
                        submitError
                    }}
                    key={fieldData.label}
                />
            )
        )
    })

    const statusTagFields = useMemo(
        () => hiddenField(fieldGroupData?.tag, tagStoreWithFields),
        [fieldGroupData?.tag, tagStoreWithFields]
    )
    useEffect(() => {
        setPrefilledValueInSubmitObject(
            fieldGroupData.name,
            fieldGroupData.tag,
            setValueCurrentField,
            tagStoreWithFields,
            dispatch
        )
    }, [dispatch, fieldGroupData.name, fieldGroupData.tag, tagStoreWithFields])

    const SPLITTER = "/"

    const mapDatesForStore = useCallback(
        (valueTag: string | undefined, arraySubFields: string[], SPLITTER: string) =>
            valueTag
                ?.split(SPLITTER)
                .forEach((splitterDate, index) => dispatch(setValueCurrentField(arraySubFields[index], splitterDate))),
        [dispatch]
    )

    useEffect(() => {
        const arraySubFields = fieldGroupData?.fields?.map(({name}) => name).reverse()

        mapDatesForStore(tagStoreWithFields[fieldGroupData?.tag ?? ""], arraySubFields, SPLITTER)
    }, [fieldGroupData, mapDatesForStore, tagStoreWithFields])

    if (statusTagFields) {
        return null
    }

    const submitErrorMessage = submitError?.error?.data

    const messageForError = () => fieldGroupData?.name && submitErrorMessage?.hasOwnProperty(fieldGroupData.name)

    const classForError = () => messageForError() ? 'submitError' : ''

    return (
        <div id={'date-picker'}
             className={classForError()}
        >
            <Form.Item
                label={getLabelWrapper(fieldGroupData, dispatch, additionalStep)}
                name={fieldGroupData.label}
                className={statusTagFields}
            >
                <>
                    <Input.Group size="small">{components}</Input.Group>
                    {((ageValidate && fieldGroupData.name === 'date_birth') || messageForError())
                        && <div
                            className={'ant-form-item-explain-error'}
                        >
                            {uiMessage ? uiMessage : submitError ? capitalizeFirstLetter(String(submitErrorMessage[fieldGroupData.name])) : ''}
                        </div>}
                </>
            </Form.Item>
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    validationRulers: state.step.validation.properties,
    required: state.step.validation.required,
    currentStep: state.step.currentStep,
    errorBar: state.additional.errorBar,
    tagStoreWithFields: state.form.tagStoreWithFields,
})

export default connect(mapStateToProps)(DataGroupDropDownComponent)
