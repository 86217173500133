import {
  GET_BILLING_INFORMATION, GET_BILLING_INFORMATION_SUCCESS, SET_PAYMENT_ERROR,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  billingInformationLoader: false,
  billingInformation: undefined,
};

const BillingsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BILLING_INFORMATION: {
      return {
        ...state,
        billingInformationLoader: true,
      }
    }

    case GET_BILLING_INFORMATION_SUCCESS: {
      return {
        ...state,
        billingInformationLoader: false,
        billingInformation: action.payload
      }
    }
    case SET_PAYMENT_ERROR: {
      console.log('Billing Redux: ', action.payload)
      return {
        ...state,
        paymentError: action.payload
      }
    }
    default:
      return state;
  }
}

export default BillingsReducer;
