import React, {useEffect} from "react";
import "./assets/vendors/style";
import "./styles/wieldy.less";
import DashboardApp from "./containers/App/DashboardApp";
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux'
import {Route, Switch} from "react-router-dom";
import configureStore from './appRedux/store';
import {createBrowserHistory} from "history";
import {persistUrlAnalyticQueryParams} from "@dash/gov-plus__front-end__form/src/util/UrlQueryParams";
import UtmDto from "@dash/gov-plus__front-end__form/src/dto/ParamsUriDto/UtmDto";

const store = configureStore();

const DashboardAppContainer = () => {
    const history = createBrowserHistory();
    const paramsDto = new UtmDto()

    useEffect(() => {
        persistUrlAnalyticQueryParams(paramsDto);
    }, [])

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path="/" component={DashboardApp}/>
                </Switch>
            </ConnectedRouter>
        </Provider>
    )
};

export default DashboardAppContainer;
