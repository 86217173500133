import React, { useEffect, useState } from "react"
import { Form, Input } from "antd"
import Autocomplete from "react-google-autocomplete"
import { alpha2ToAlpha3 } from "i18n-iso-countries";
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { setObservers, setTagFields, setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import { useDispatch, useSelector } from "react-redux"
import { savePrefilled } from "../../../../Redux/store/user/user.actions"
import { RootState } from "../../../../App"
import { formSelector } from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { delayTimer } from "../../../../Pages/FormPage/FormPage"
import {
  handlerOnBlurAutofill,
  handlerOnChangeAutofill,
  MAX_HIDE_AUTOFILL_LENGTH,
  rulesValue,
} from "../AddressAutofill/service"
import { ValidationItemInterface } from "../../../../Redux/InterfacesEntity/validation.interface"
import errorValidation from "../../../../assets/image/errorValidation.svg"
import successValidation from "../../../../assets/image/successValidation.svg"
import FlattenDataDto from "../../../../dto/FLattenDataDto/FlattenDataDto";
import { env } from "../../../../env";

type CityBirthAutofillProps = {
  field: FieldInterface,
  validationRule: ValidationItemInterface,
}

const GOOGLE_API_KEY = env.REACT_APP_GOOGLE_API_KEY ?? "AIzaSyACddPEeOGY8Ln0YHcy6DfQrLmK5Nvs4eY"

const parseAddressAutofill = ({ address_components }: any) => {
  const cityTypes = ["locality"]
  const countryTypes = ["country"]
  const stateTypes = ["administrative_area_level_1"]
  const getLongValue = ({ types, address_components }: any) =>
    address_components?.reduce(
      (acc: string, type: any) => (types.includes(type.types[0]) ? acc + " " + type.long_name : acc),
      ""
    )
  const getShortValue = ({ types, address_components }: any) =>
    address_components?.reduce(
      (acc: string, type: any) => (types.includes(type.types[0]) ? acc + type.short_name : acc),
      ""
    )
  const cityValue = getLongValue({ types: cityTypes, address_components }).trim()
  const countryShortValue = alpha2ToAlpha3(getShortValue({ types: countryTypes, address_components }).trim())
  const countryLongValue = getLongValue({ types: countryTypes, address_components }).trim()
  const stateShortValue = getShortValue({ types: stateTypes, address_components }).trim()
  const stateLongValue = getLongValue({ types: stateTypes, address_components }).trim()

  return { countryShortValue, countryLongValue, stateShortValue, stateLongValue, cityValue }
}

const setFieldsInStore = ({
  cityValue,
  countryShortValue,
  countryLongValue,
  stateShortValue,
  stateLongValue,
  field,
  dispatch,
}: any) => {
  [
    {
      fieldName: "country_birth__full_name",
      fieldValue: countryLongValue,
    },
    {
      fieldName: "country_birth",
      fieldValue: countryShortValue,
    },
    {
      fieldName: "state_birth__full_name",
      fieldValue: stateLongValue,
    },
    {
      fieldName: "state_birth",
      fieldValue: countryShortValue === "USA" ? stateShortValue : stateLongValue,
    },
    {
      fieldName: "city_birth",
      fieldValue: cityValue,
    },
    {
      fieldName: field.name,
      fieldValue: cityValue,
    },
  ].forEach(({ fieldName, fieldValue }) => dispatch(setValueCurrentField(fieldName, fieldValue)));

  [
    {
      fieldTag: FlattenDataDto.VAR_COUNTRY_OF_BIRTH,
      fieldValue: countryShortValue,
    },
    {
      fieldTag: FlattenDataDto.VAR_STATE_OF_BIRTH,
      fieldValue: stateShortValue,
    },
    {
      fieldTag: FlattenDataDto.VAR_CITY_OF_BIRTH,
      fieldValue: cityValue,
    },
  ].forEach(({ fieldTag, fieldValue }, index) => {
    setTimeout(() => dispatch(savePrefilled({ [fieldTag]: fieldValue })), index * 500)
    dispatch(setTagFields({ [fieldTag]: fieldValue }))
  })

  dispatch(setObservers(field.observable, "No"))
  dispatch(setObservers(field.observable, "Yes"))
}

function initAutocomplete() {
  const googleContainer = document.querySelector(
    ".ant-form-item.autofill__container.autofill-city-birth__container.city_birth"
  ) || <div></div>
  const googleMenuContainers = document.querySelectorAll(".pac-container") || <div></div>
  const googleMenuContainer = Array.from(googleMenuContainers).find((container) => container.children.length)
  if (googleContainer && googleMenuContainer) {
    // @ts-ignore
    googleContainer.style.position = "relative"
    // @ts-ignore
    googleMenuContainer.style.position = "relative"
    // @ts-ignore
    googleContainer.append(googleMenuContainer)
  }
}

export const CityBirthAutofill: React.FunctionComponent<CityBirthAutofillProps> = ({ field, validationRule }) => {
  const [statusInput, setStatusInput] = useState(true)
  const [statusFocus, setStatusFocus] = useState(false)

  const dispatch = useDispatch()
  const submitObject = useSelector<RootState, { submitObject: {} | any }>(formSelector).submitObject
  const submitError = useSelector<RootState, { submitError: {} | any }>(formSelector).submitError
  const [value, setValue] = useState("")

  useEffect(() => {
    if (submitObject["country_birth"] && submitObject["country_birth"] !== "USA") {
      dispatch(setObservers(field.observable_citizenship, "Yes"))
      dispatch(setObservers(field.observable_autofill_state, "Is USA"))
      delayTimer(0).then(() => dispatch(setObservers(field.observable_autofill_state, "Not USA")))
    }
    if (submitObject["country_birth"] && submitObject["country_birth"] === "USA") {
      dispatch(setObservers(field.observable_autofill_state, "Not USA"))
      delayTimer(0).then(() => dispatch(setObservers(field.observable_autofill_state, "Is USA")))
      dispatch(setObservers(field.observable_citizenship, "No"))
    }
  }, [dispatch, field.observable_citizenship, submitObject["country_birth"]])

  useEffect(() => {
    if (!submitError?.message) {
      dispatch(setObservers(field.observable, "No"))
      dispatch(setObservers(field.observable_autofill_state, "No"))
    }
  }, [dispatch, field.observable, field.observable_autofill_state, submitError?.message])

  useEffect(() => {
    delayTimer(500).then(() => setStatusInput(false))
  }, [])

  useEffect(() => {
    if (submitError?.message) {
      setValue(submitObject[field.name])
    }
  }, [field.name, setStatusInput, submitError?.message, submitObject])

  return (
    <Form.Item
      label={"City of birth"}
      name={field.name}
      className={`autofill__container autofill-city-birth__container ${statusInput} ${field.name} focus-field_${statusFocus}`}
      rules={rulesValue({ field, submitObject, validationRule, value })}
    >
      <>
      <Form.Item name={field.name} initialValue={submitObject[field.name] ?? ""}>
        <>
        {statusInput && (
          <Input
            value={submitObject[field.name]}
            onFocus={() => {
              setStatusInput(false)
              setStatusFocus(true)
            }}
            className={`autofill__input autofill-city-birth__input ${statusInput} ${field.name}`}
            // onChange={()=>{delayTimer(500).then(()=>initAutocomplete())}}
            autoComplete="none"
            onBlur={() => setStatusFocus(false)}
            // onMouseLeave={()=>setStatusInput(false)}
          />
        )}
        {!statusInput && (
          <Autocomplete
            // @ts-ignore
            value={value}
            id="mapMoveHere"
            onChange={(e) => {
              initAutocomplete()
              handlerOnChangeAutofill({
                e,
                setValue,
                // @ts-ignore
                value: e.target.value,
                dispatchObserver: (value) => dispatch(setObservers(field.observable, value)),
                MAX_HIDE_AUTOFILL_LENGTH,
                setFieldInStore: (nameField, value) => dispatch(setValueCurrentField(nameField, value)),
                nameField: field.name,
              })
            }}
            onFocus={() => setStatusFocus(true)}
            onBlur={() =>
              handlerOnBlurAutofill({
                setStatusFocus,
                value,
                dispatch,
                field,
                valueStateBirthState: { value: submitObject["country_birth"] },
              })
            }
            className={`autofill__autocomplete autofill-city-birth__autocomplete ${statusInput} ${field.name}`}
            placeholder={""}
            apiKey={GOOGLE_API_KEY}
            onPlaceSelected={(place) => {
              const { cityValue, countryShortValue, countryLongValue, stateShortValue, stateLongValue } =
                parseAddressAutofill({ address_components: place.address_components })
              setFieldsInStore({
                cityValue,
                countryShortValue,
                countryLongValue,
                stateShortValue,
                stateLongValue,
                field,
                dispatch,
              })
              setValue(cityValue)
              setStatusInput(true)
              setStatusFocus(true)
            }}
            options={{
              strictBounds: true,
              types: ["locality"],
            }}
          />
        )}
        </>
      </Form.Item>
      <div className={`validation-error-icon__container`}>
        <img src={errorValidation} alt={"error"} />
      </div>
      <div className={`validation-success-icon__container`}>
        <img src={successValidation} alt={"success"} />
      </div>
      </>
    </Form.Item>
  )
}
