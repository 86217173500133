const currentYear = new Date().getFullYear();
export enum ServicesNames {
    FirstBirthCertificate = "First birth certificate",
    BirthCertificateCertifiedCopy = "Certified copy of birth certificate",
    BirthCertificateReplacement = "Birth certificate replacement",

    // Social security card
    FirstSocialSecurityCard = "First Social Security card",
    SocialSecurityCardUpdates = "Social Security card updates",
    SocialSecurityCardNameChange = "Social Security card name change",
    SocialSecurityCardReplacement = "Social Security card replacement",

    SSCardReplacemnt = "S.S. card replacement",
    SSCardNameChange = "S.S. card name change",
    FirstSSCard = "First S.S. card",
    SSCardUpdates = "S.S. card updates",

    // EIN
    NewEin = "New EIN",
    EinRetrieval = "EIN retrieval",
    IRSEinConfirmationLetter = "IRS confirmation letter",
    EinsUpdate = "EIN updates",


    // Address
    BusinessAddressChange = "Business Address change",
    IndividualAddressChange = "Individual address change",

    // TSA precheck
    NewTSAPreCheck = "New TSA PreCheck®",
    TsaPreCheckRenewal = "TSA PreCheck® renewal",

    // Passport
    PassportRenewal = "Passport Renewal",
    PassportFirst = "First Passport",
    PassportLost = "Lost Passport",
    PassportStolen = "Stolen Passport",
    PassportDamage = "Damaged Passport",

    // Taxes
    TAXES = "Taxes 2023",
    TAXES_V1_1 = "Federal and State tax filing",
    TAXES_WITHOUT_YEAR = "Taxes",
}

export const PossiblesTaxServicesNames = [
    `Taxes ${currentYear - 1}`,
    `Federal and State tax filing`,
]