import { Action } from "../../interfacesAction/action.interface"
import {IUpsellStepVisualStatus} from "../../InterfacesEntity/upsell.step.interfaces";

export const ActionTypes = {
  SET_VALUE_FOR_SELECT: "[SELECT] set value for select",

  SET_CURRENT_STEP_SIGNATURE: "[SIGNATURE] Set current step signature",
  SET_STATUS_SIGNATURE: "[SIGNATURE] set status signature",
  SET_STATUS_SIGNATURE_OPEN: "[SET_STATUS_SIGNATURE_OPEN] set status signature open block",
  SET_STATUS_PROCESSING_VALIDATION_OPEN: "[SET_STATUS_PROCESSING_VALIDATION_OPEN] set status processing validation open block",
  SET_STATUS_UPLOAD_DOCUMENT_OPEN: "[SET_STATUS_UPLOAD_DOCUMENT_OPEN] set status upload document open block",
  SET_STATUS_UPLOAD_ADDITIONAL_DOCUMENT_OPEN:
    "[SET_STATUS_UPLOAD_ADDITIONAL_DOCUMENT_OPEN] set status upload additional document open block",
  SET_CAN_NOTARIZE: "[CAN_NOTARIZE] set can notarize",
  SET_NOTARIZATION_LINK: "[SET_NOTARIZATION_LINK] set link for notarization",
  SET_EIN_NUMBER: "[EIN_NUMBER] set ein number",
  SET_UPSELL_INFORMATION: "[SET_UPSELL_INFORMATION] set upsell information",
  SET_UPSELL_PROCESSING_INFORMATION: "[SET_UPSELL_PROCESSING_INFORMATION] set upsell information in processing",
  SET_STATUS_QUESTIONARY: "[QUESTIONARY] set status questionary",
  SET_ADDRESS_VALIDATION: "[SET_ADDRESS_VALIDATION] set address validation",
  SET_UPSELL_STEP: "[SET_UPSELL_STEP] set upsell step",
  SET_ARRAY_UPSELL_STEP: "[SET_ARRAY_UPSELL_STEP] set upsell step",
  SET_NEW_ADDRESS_VALIDATION: "[SET_NEW_ADDRESS_VALIDATION] set new address validation",
  SET_STATUS_QUESTIONNAIRE_FRONT: "[QUESTIONNAIRE] set status questionnaire for front",
  SET_STATUS_PASSPORT_DETAILS: "[DETAILS] set status passport details",
  SET_STATUS_TSA_TO_OPEN_DETAILS: "[SET_STATUS_TSA_TO_OPEN_DETAILS] set status passport details to open",
  SET_STATUS_GOVERNMENT_FEE_OPEN_DETAILS: "[SET_STATUS_GOVERNMENT_FEE_OPEN_DETAILS] set status government fee to open",
  SET_STATUS_TSA_DETAILS: "[DETAILS] set status TSA details",

  SET_SUBMIT_LOCAL_STORAGE: "[LOCAL STORAGE] set submit local storage",

  APPROVE_ADRESS: "[ADRESS] approve adress",
  APPROVE_ADDRESS_FIELD: "[ADDRESS] approve adDress FIELD",

  SET_SIGNATURE: "[SIGNATURE] Set signature",
  SET_FIELDS: "[CORRECT INFORMATION] Set fields",
  SET_ANSWERS_QUESTIONARY: "[QUESTIONARY] Set_answers_questionary",

  SET_STATUS_UPLOAD_DOCUMENTS: "[DOCUMENTS] set status upload documents",
  SET_STATUS_UPLOAD_ADDITIONAL_DOCUMENTS: "[DOCUMENTS] set status upload additional documents",
  SET_STATUS_PHOTO_UPLOAD: "[PHOTO] set status photo upload",

  UPLOAD_DOCUMENTS: "[DOCUMENTS] Upload documents",
  CLEAR_TEMP_DOCUMENTS: "[DOCUMENTS] Clear Temp Documents",
  SET_DOCUMENTS: "[DOCUMENTS] Set_documents",
  SET_CLEAR_TEMP_DOCUMENT: "[DOCUMENTS] Set_clear_temp_documents",
  UPLOAD_ADDITIONAL_DOCUMENTS: "[DOCUMENTS] Upload additional documents",
  SET_ADDITIONAL_DOCUMENTS: "[DOCUMENTS] Set_additional_documents",
  REMOVE_DOCUMENT: "[DOCUMENT] remove document",

  SEND_SIGNATURE: "[USER DATA] send signature",
  SEND_SIGNATURE_FIELD_QUESTIONARY: "[USER DATA] send signature field questionary",
  SUBMIT_PASSPORT_DETAILS_ADDITIONAL_FORM: "[USER DATA] submit passport details additional form",

  SET_ERROR_BAR_VALUE: "[ERROR] set error bar value",

  SET_LINK_VALUE: "[LINK] set link value",

  GET_FAILURE: "[getFailureAction] get failure action",

  SUBMIT_ADDITIONAL_COA: "[ADDITIONAL_COA] get additional coa",
  SUBMIT_ADDITIONAL_DATA: "[ADDITIONAL_DATA] get additional data",
  SET_STATUS_SIGNATURE_PAGE: "[STATUS] set status signature page",

  NEXT_UPSELL_PAGE: "[UPSELL] Next upsell page",
  NEXT_UPSELL_PROCESSING_PAGE: "[UPSELL] Next upsell processing page",

  CLEAR_ADDITIONAL_IN_START_APP: "[ADDITIONAL] clear additional in start app",
  CLEAR_ARRAYS_DOCUMENTS: "[ADDITIONAL] clear arrays documents",
  LAUNCH_TAX_ASSISTANCE: "[TAX] launch tax assistance",
  SET_TAX_RETURN_INFORMATION: "[TAX] set tax return information",
  REFRESH_TAX_RETURN_INFORMATION: "[TAX] refresh tax return information",
}

export const setCurrentStepSignature = (index: number): Action<number> => ({
  type: ActionTypes.SET_CURRENT_STEP_SIGNATURE,
  payload: index,
})

export const setStatusSignature = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_SIGNATURE,
  payload: status,
})
export const setStatusSignatureOpen = (open: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_SIGNATURE_OPEN,
  payload: open,
})

export const setStatusUploadDocumentOpen = (open: boolean): Action<boolean> => ({
  type: ActionTypes.SET_STATUS_UPLOAD_DOCUMENT_OPEN,
  payload: open,
})

export const setStatusUploadAdditionalDocumentOpen = (open: boolean): Action<boolean> => ({
  type: ActionTypes.SET_STATUS_UPLOAD_ADDITIONAL_DOCUMENT_OPEN,
  payload: open,
})

export const setCanNotarize = (canNotarize: boolean): Action<boolean> => ({
  type: ActionTypes.SET_CAN_NOTARIZE,
  payload: canNotarize,
})
export const setNotarizationLink = (notarizationLink: string): Action<string> => ({
  type: ActionTypes.SET_NOTARIZATION_LINK,
  payload: notarizationLink,
})
export const setEinNumber = (einNumber: boolean): Action<boolean> => ({
  type: ActionTypes.SET_EIN_NUMBER,
  payload: einNumber,
})

export const setUpsellInformation = (upsellInformation: any): Action<any> => ({
  type: ActionTypes.SET_UPSELL_INFORMATION,
  payload: upsellInformation,
})

export const setUpsellProcessingInformation = (upsellProcessingInformation: any): Action<any> => ({
  type: ActionTypes.SET_UPSELL_PROCESSING_INFORMATION,
  payload: upsellProcessingInformation,
})

export const setStatusPassportDetails = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_PASSPORT_DETAILS,
  payload: status,
})

export const setStatusTSADetails = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_TSA_DETAILS,
  payload: status,
})

export const setStatusTSADetailsToOpen = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_TSA_TO_OPEN_DETAILS,
  payload: status,
})

export const setStatusGovernmentFeeOpen = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_GOVERNMENT_FEE_OPEN_DETAILS,
  payload: status,
})

export const setStatusPhotoUpload = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_PHOTO_UPLOAD,
  payload: status,
})

export const setStatusQuestionary = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_QUESTIONARY,
  payload: status,
})

// export const setStatusServices = (status: string): Action<string> => ({
//   type: ActionTypes.SET_STATUS_SERVICES,
//   payload: status,
// })

export const setAddressValidation = (status: string): Action<string> => ({
  type: ActionTypes.SET_ADDRESS_VALIDATION,
  payload: status,
})

export const setUpsellStep = (status: string): Action<string> => ({
  type: ActionTypes.SET_UPSELL_STEP,
  payload: status,
})

export const setArrayUpsellStep = (upsells: IUpsellStepVisualStatus[]): Action<any> => ({
  type: ActionTypes.SET_ARRAY_UPSELL_STEP,
  payload: upsells,
})

export const setNewAddressValidation = (status: string): Action<string> => ({
  type: ActionTypes.SET_NEW_ADDRESS_VALIDATION,
  payload: status,
})

export const setStatusQuestionnaireFront = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_QUESTIONNAIRE_FRONT,
  payload: status,
})

export const submitAdditionalCoa = (object: any): Action<any> => ({
  type: ActionTypes.SUBMIT_ADDITIONAL_COA,
  payload: object,
})
export const submitAdditionalData = (object: any): Action<any> => ({
  type: ActionTypes.SUBMIT_ADDITIONAL_DATA,
  payload: object,
})
// export const submitAdditionalCoaFinal = (object: any): Action<any> => ({
//   type: ActionTypes.SUBMIT_ADDITIONAL_COA_FINAL,
//   payload: object,
// })

export const approveAdress = (obj: {}): Action<{}> => ({
  type: ActionTypes.APPROVE_ADRESS,
  payload: obj,
})

export const setApproveAddressField = (obj: {}): Action<{}> => ({
  type: ActionTypes.APPROVE_ADDRESS_FIELD,
  payload: obj,
})

export const setSignature = (array: any): Action<any> => ({
  type: ActionTypes.SET_SIGNATURE,
  payload: array,
})

export const setFields = (array: []): Action<[]> => ({
  type: ActionTypes.SET_FIELDS,
  payload: array,
})

export const setAnswersQuestionary = (obj: any): Action<any> => ({
  type: ActionTypes.SET_ANSWERS_QUESTIONARY,
  payload: obj,
})

export const setStatusUploadDocuments = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_UPLOAD_DOCUMENTS,
  payload: status,
})

export const setStatusUploadAdditionalDocuments = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_UPLOAD_ADDITIONAL_DOCUMENTS,
  payload: status,
})

export const uploadDocuments = (obj: any): Action<any> => ({
  type: ActionTypes.UPLOAD_DOCUMENTS,
  payload: obj,
})

export const clearTempDocuments = (obj: any): Action<any> => ({
  type: ActionTypes.CLEAR_TEMP_DOCUMENTS,
  payload: obj,
})

export const setDocuments = (obj: any): Action<any> => ({
  type: ActionTypes.SET_DOCUMENTS,
  payload: obj,
})

export const removeDocument = (document: any): Action<any> => ({
  type: ActionTypes.REMOVE_DOCUMENT,
  payload: document,
})

export const clearArraysDocuments = () => ({
  type: ActionTypes.CLEAR_ARRAYS_DOCUMENTS
})

export const setClearTempDocuments = (obj: any): Action<any> => ({
  type: ActionTypes.SET_CLEAR_TEMP_DOCUMENT,
  payload: obj,
})

export const uploadDAdditionalDocuments = (obj: any): Action<any> => ({
  type: ActionTypes.UPLOAD_ADDITIONAL_DOCUMENTS,
  payload: obj,
})

export const setAdditionalDocuments = (obj: any): Action<any> => ({
  type: ActionTypes.SET_ADDITIONAL_DOCUMENTS,
  payload: obj,
})

export const submitSignature = (object: any): Action<any> => ({
  type: ActionTypes.SEND_SIGNATURE,
  payload: object,
})

export const submitAdditionalForm = (object: any): Action<any> => ({
  type: ActionTypes.SEND_SIGNATURE_FIELD_QUESTIONARY,
  payload: object,
})

export const submitPassportDetailsAdditionalForm = (object: any): Action<any> => ({
  type: ActionTypes.SUBMIT_PASSPORT_DETAILS_ADDITIONAL_FORM,
  payload: object,
})

export const setErrorBarValue = (value: boolean): Action<any> => ({
  type: ActionTypes.SET_ERROR_BAR_VALUE,
  payload: value,
})

export const setLinkValue = (value: string): Action<string> => ({
  type: ActionTypes.SET_LINK_VALUE,
  payload: value,
})

export const setStatusSignaturePage = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_SIGNATURE_PAGE,
  payload: status,
})

export const getFailureAction = (error?: {}): Action<{}> => ({
  type: ActionTypes.GET_FAILURE,
  payload: error,
})

export const nextUpsellPage = (obj: any): Action<any> => ({
  type: ActionTypes.NEXT_UPSELL_PAGE,
  payload: obj,
})

export const nextUpsellProcessingPage = (obj: any): Action<any> => ({
  type: ActionTypes.NEXT_UPSELL_PROCESSING_PAGE,
  payload: obj,
})

export const clearAdditionalInStartApp = () => ({
  type: ActionTypes.CLEAR_ADDITIONAL_IN_START_APP,
})

export const launchTaxAssistanceAction = () => ({
  type: ActionTypes.LAUNCH_TAX_ASSISTANCE,
})

export const setTaxReturnInformation = (obj: any) => ({
  type: ActionTypes.SET_TAX_RETURN_INFORMATION,
  payload: obj,
})

export const refreshTaxReturnInformation = () => ({
  type: ActionTypes.REFRESH_TAX_RETURN_INFORMATION
})
