import {useEffect, useState} from "react";
import {PaymentModelTypes} from "../constants/PaymentModelTypes";
import {offerApis} from "../api/api";

export const usePaymentModel = () => {
    const [paymentModel, setPaymentModel] = useState<PaymentModelTypes>(PaymentModelTypes.ONE_TIME_PAYMENT_FEE)
    const [loading, setLoading] = useState(false)

    function getPaymentModelTypes() {
        setLoading(true)
        offerApis.getPaymentModel().then(response => {
            setLoading(false)
            setPaymentModel(response.data)
        }).catch(error => {
            setLoading(false)
            console.log(error)
        })

    }

    useEffect(() => {
        getPaymentModelTypes()
    }, [])

    return {
        paymentModel,
        loading
    }
}