import {put} from "redux-saga/effects";
import {setBillingInformation} from "../step/step.actions";
import {setPaymentMethod, showAddPaymentButton} from "../form/form.actions";

/*
    set Billing Information to redux store
*/
export function *putBillingInformation(billingInformation: any) {
    yield put(setBillingInformation(billingInformation))
    if (billingInformation.default_payment_methods) {
        yield put(setPaymentMethod(billingInformation.default_payment_methods))
    } else {
        yield put(setPaymentMethod(""))
    }
    if (billingInformation.payment_methods?.length > 0) {
        yield put(showAddPaymentButton(true))
    }
}
