import React, {FC} from "react";
import GText from "../../atoms/Text/Text";
import GInputText from "../../molecules/InputText/InputText";
import {Form} from "antd";
import GFormItem from "../../organisms/FormItem/FormItem";
import GButton from "../../molecules/Button/GButton";
import {SIZE} from "../../types/types";
import "./ActivateRenewal.scss"
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes";
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg";
import { COLORS, COLORS_NEW } from '../../types/Colors';
import { IExpirationDate, IProductRenewalForm } from "../../types/Models";
import InputMask from 'react-input-mask';
import DateSelectorDropdownGroup from "../../organisms/DateSelectorDropdownGroup/DateSelectorDropdownGroup";
import { Rule } from "antd/lib/form";

type ActivateRenewalProps = {
    product: IProductRenewalForm,
    onSubmit: (data: object) => any
}

const ActivateRenewal: FC<ActivateRenewalProps> = ({ product, onSubmit }: ActivateRenewalProps) => {
  const headerText = `Add your ${product?.solutionName || 'service'} details to activate automatic renewal`
  const [form] = Form.useForm<IProductRenewalForm>()
    const getInputMask: string = (
      () => {
          switch (product.name) {
              case "PAS":
              case "Passport":
                  return "999999999"
              case "TSA":
                  return "*********"
              default:
                  return "99999999999999999"
          }
      }
  )()
  const maskPlaceHolder = getInputMask.replaceAll(/[9*]/g, product.name === 'PAS' ? "X" : " ")
  const letterSpacingButton : React.CSSProperties = {
    letterSpacing: "-0.1px"
  }
  const validationNumberDocument: Rule[] = [
    {required: true, message: 'Required'},
    {
        validator: (rule, value, callback) => {
            if (!value || value.trim() === "" || value === maskPlaceHolder) {
                callback();
                return;
            }
            if(product.name === 'PAS'){

              if (/^\d+$/.test(value) === false) {
                  callback(`Please enter a valid ${product.solutionName} number`);
                  return;
              }
            }
            if(product.name === 'TSA'){

              if (/^\w+$/.test(value) === false) {
                  callback(`Please enter a valid ${product.solutionName} number`);
                  return;
              }
            }
            callback();
        }
    }
];
  const validationExpirationDate: Rule[] = [
    {
      validator: (rule, value, callback) => {  
      if(value?.month === undefined || value?.day === undefined || value?.year === undefined){
        callback()
        return
      }
      const expirationDate = formatObjetToDate(value)
      const isExpirationDateValid = isAfterToday(expirationDate)
      if (isExpirationDateValid) {
          callback()
          return
        } else {
          callback("You cannot set up auto-renewal for an expired document ")
          return
        }
      },
    },
  ]
  function formatObjetToDate({ year, month, day }: IExpirationDate) {
    return new Date(year, month - 1, day)
  }
  function isAfterToday(expirationDate: Date){
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1; 
    const year = today.getFullYear();
    const todayReduce = new Date(year, month - 1, day);
    if (expirationDate > todayReduce) return true;
    return false; 
  }
  function onChangeExpirationDate(){
    form.validateFields(["expirationDate"]);
  }
  

  return (
    <div className="GActivateRenewal">
      <section className="GActivateRenewal__Header">
        <GText text={headerText} size={SIZE.HEADING_MDLG} />
      </section>
      <Form form={form} onFinish={onSubmit} className={`GActivateRenewal__Form`} layout={`vertical`}>
        <GFormItem
          name="documentNumber"
          label={
            <div>
                      <GText text={`What's your ${product.solutionName || 'service'} number?`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                  </div>
              }
              rules={validationNumberDocument}
              >
                  <InputMask
                      mask={getInputMask}
                      maskPlaceholder={null}
                  >
                      <GInputText inputMode={"numeric"} />
                  </InputMask>
        </GFormItem>
        <GFormItem
          name="expirationDate"
          label={
                  <div>
                      <GText text={`What's your ${product.solutionName || 'service'} expiration date?`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                  </div>
              }
              rules={validationExpirationDate}
              >
              <DateSelectorDropdownGroup fieldName="expirationDate" totalYearsShow={30} isBefore={false} onChangeValue={onChangeExpirationDate}/>
        </GFormItem>
        
        <div className={"GActivateRenewal__Form__BtnContainer"}>
          <GButton
            text={`Automatically renew my ${product?.solutionName || 'service'}`}
            isSubmit={true}
            textSize={SIZE.PARAGRAPH_BOLD_16}
            size={"large"}
          />
        </div>
      </Form>
    </div>
  )
}

export default ActivateRenewal