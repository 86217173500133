import React from "react"
import RulesCheckBoxModal from "../../../Hocs/RulesCheckBoxModal/RulesCheckBoxModal"
import { connect } from "react-redux"
import { checkCurrentFieldContent } from "../../FormComponents/FormHelper/FormHelperMainSteps/FormHelperMainSteps"

type HelperMobileComponentProps = {
  setValueModal: any,
  currentField: {} | any,
  dispatch: any,
}

const HelperMobileComponent: React.FunctionComponent<HelperMobileComponentProps> = ({
  setValueModal,
  currentField,
  dispatch,
}) => {
  const helperContent = currentField?.helper?.description && checkCurrentFieldContent(currentField)

  const headerName = currentField?.label

  return (
    // @ts-ignore
    <RulesCheckBoxModal setValueModal={setValueModal} headerName={headerName} dispatch={dispatch}>
      <div className="modal-bl__content-container">{helperContent}</div>
    </RulesCheckBoxModal>
  )
}
const mapStateToProps = (state: any) => ({
  currentField: state.form.helper,
})

export default connect(mapStateToProps)(HelperMobileComponent)
