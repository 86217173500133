/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
/* eslint-disable indent */
import React, { useEffect, useState, useMemo } from "react"
import { connect } from "react-redux"
import { Form } from "antd"
import { ValidationItemInterface } from "../../../../Redux/InterfacesEntity/validation.interface"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import {
  setActiveInput,
  setValueCurrentField,
  setValueModal,
} from "../../../../Redux/store/form/form.actions"
import { setValueForSelect } from "../../../../Redux/store/select/select.actions"
import { setNameActualInput } from "../../../../Redux/store/step/step.actions"
import NestRenderInputComponent, {getHandleValidationByRule} from "./NestRenderInputComponent/NestRenderInputComponent"
import InputModalComponent from "./InputModalComponent/InputModalComponent"
import masterCard from "../../../../assets/image/credit-card-mastercard.jpg"
import card from "../../../../assets/image/card.svg"
import visa from "../../../../assets/image/card-visa.svg"
import amex from "../../../../assets/image/card-amex.svg"
import discover from "../../../../assets/image/card-discovery.svg"
import jcbCard from "../../../../assets/image/card-jcb.svg"
import dinerClubCard from "../../../../assets/image/card-dinerclub.svg"
import iconHelper from "../../../../assets/image/iconHelper.svg"
import {checkCitesAutofillValidation, hiddenField, setPrefilledValueInSubmitObject} from "./serviceInput"
import {SIZE} from "../../../atomicDesign/types/types";
import {COLORS, COLORS_NEW} from "../../../atomicDesign/types/Colors";
import GText from "../../../atomicDesign/atoms/Text/Text";
import {FIELD_CLASS_TO_BE_MASKED} from "../../../../util/FullHistoryMaskedFields";


const arrayIcons = [visa, amex, jcbCard, dinerClubCard, masterCard, discover]
const arrayCvvIcons = [card]

type InputComponentProps = {
  field: FieldInterface,
  validationRule: ValidationItemInterface,
  required?: boolean,
  value: string | any,
  observer?: boolean | any,
  observers: {} | any,
  submitObject: {} | any,
  nameActualInput: string,
  showValueInputModal?: string,
  currentField?: {} | any,
  currentStep: { additionalStep: boolean },
  tagStoreWithFields: { [key: string]: string },
  dispatch: any,
}

const InputComponent: React.FunctionComponent<InputComponentProps> = ({
  field,
  validationRule,
  required,
  value,
  observer,
  observers,
  submitObject,
  nameActualInput,
  showValueInputModal,
  currentField,
  currentStep,
  tagStoreWithFields,
  dispatch,
}) => {
  const { additionalStep } = currentStep

  const nameOption = field.nameBlock

  const whyDoWeNeed = field.modal && field.modal.prevText ? "launch-modal-below" : ""

  // crut for change digital on * in SSN field
  const hiddenSSN =
    field.name === "ssn_descendent"
      ? "ssn_descendent_hidden"
    :field.name === "ssn_applicant"
      ? "ssn_applicant_hidden"
      : field.name === "ssn_mother_num"
      ? "ssn_mother_num_hidden"
      : field.name === "ssn_father_num"
      ? "ssn_father_num_hidden"
      : field.name === "ssn"
      ? "ssn_hidden"
      : field.name === "ssn_oth"
      ? "ssn_oth_hidden"
      : field.ssn === "ssn"
      ? `${field.name}_hidden`
      : ""
  // for dynamic change current option in select
  useEffect(() => {
    if (field.dynamicSelect && nameOption) setValueForSelectCommon(field.dynamicSelect, nameOption, field.name, value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function setValueForSelectCommon(dynamicSelect: string, nameOption: string, nameField: string, valueField: string) {
    if (field.dynamicSelect && nameOption) dispatch(setValueForSelect(nameOption, `${field.name}`, value))
  }

  //state for showOn/off default value
  const [statusDefaultValue, setStatusDefaultValue] = useState("on")

  useEffect(() => {
    if (field.defaultValue && !submitObject[field.name] && statusDefaultValue === "on") {
      dispatch(setValueCurrentField(field.name, field.defaultValue))
      setStatusDefaultValue("off")
    }
  }, [dispatch, field.defaultValue, field.name, statusDefaultValue, submitObject])

  useEffect(() => {
    if (field.mask === "999-99-9999" && hiddenSSN) {
      dispatch(setValueCurrentField(field.name, ""))
      // crut for change digital on *
      dispatch(setValueCurrentField(hiddenSSN, ""))
      // dispatch(removeVariableCurrentField(hiddenSSN))
    }
  }, [dispatch, field.mask, field.name, hiddenSSN])

  useEffect(() => {
    if (
      field.mask === "999-99-9999" &&
      hiddenSSN &&
      field.divider_fields &&
      field.divider_fields &&
      submitObject[hiddenSSN] &&
      submitObject[hiddenSSN].length === 11
    ) {
      const arrayValueSSN = submitObject[hiddenSSN].split("-")
      field.divider_fields.forEach((item: any, index: number) => {
        dispatch(setValueCurrentField(item, arrayValueSSN[index]))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, field.divider_fields, field.mask, field.name, hiddenSSN, submitObject[hiddenSSN]])

  // for special classes
  const specialInput = field.className?.includes('special-input') ?  "special-input" : "usual-input"

  const phoneMobile = field.label === "Phone number" ? "phone-number" : ""

  const iconsInput = (field.icons || field.iconCvv) ? "icons" : "no-icons"

  type GetPrepositionType = () => string

  const maskedForPrivacy = field.className?.includes(FIELD_CLASS_TO_BE_MASKED)

  // get "new" before name
  const getPreposition = () => {
    if (
      field?.preposition?.text &&
      (field?.preposition?.visibility ||
        (observers?.preposition?.target === field.name &&
          (observers?.preposition?.value === "Self-selected name change" ||
            observers?.preposition?.value === "Adoption")))
    )
      return field.preposition.text + " "
    else return ""
  }

  //state for show/hide error validation onFocus/onBlur
  const [validationStatus, setValidationStatus] = useState("off")

  type GetLabelType = () => string

  // change name depends form gender select
  const getLabel = () => {
    if (submitObject[`gender`] === "female" && field.name === "old_lname" && field.labelSecond) {
      return field.labelSecond
    } else {
      return field.label
    }
  }

  const checkExistPreposition = (getPreposition: GetPrepositionType, getLabel: GetLabelType) =>
    getPreposition() ? getPreposition() + getLabel().toLowerCase() : getLabel()

  const getLabelWrapper = (field: FieldInterface, iconHelper: string, additionalStep: boolean | undefined) => {
    return (
      <div className={`helper-icon__label-container`}>
        <GText
            text={checkExistPreposition(getPreposition, getLabel)}
            size={SIZE.PARAGRAPH_BOLD_16}
            color={COLORS_NEW.BLACK_900}
        />
        {field.helper && !additionalStep && (
          <div
            className={`helper-icon__container`}
            onClick={() => showOnModalHandler(field.name, "helper-mobile", field)}
            // tabIndex={1} onBlur={() => showOffModalHandler("helper-mobile")}
          >
            <img src={iconHelper} alt={"info"} />
          </div>
        )}
      </div>
    )
  }

  // state for modals "why do you need"
  const [hint, setHint] = useState(false)

  const showOnModal = (modalStateName: string, field: FieldInterface) => {
    dispatch(setActiveInput(field))
    dispatch(setValueModal(modalStateName))
  }

  const showOnModalHandler = (name: string, state: string, field: FieldInterface) => {
    dispatch(setNameActualInput(name))
    if (state === "hint") {
      setHint(true)
    }
    if (state === "helper-mobile") {
      showOnModal(state, field)
      setHint(false)
    }
  }

  const showOffModalHandler = (state: string) => {
    dispatch(setNameActualInput(""))
    if (state === "hint") {
      setHint(false)
    }
  }

  const [errorState, setErrorState] = useState("success")

  useEffect(() => {
    const error = document.querySelector(".ant-form-item-has-error")

    if (error) {
      setErrorState("error")
    } else {
      setErrorState("success")
    }
  }, [validationStatus])

  const statusTagFields = useMemo(() => hiddenField(field?.tag, tagStoreWithFields), [field?.tag, tagStoreWithFields])
  useEffect(() => {
    setPrefilledValueInSubmitObject(field.name, field?.tag, setValueCurrentField, tagStoreWithFields, dispatch)
  }, [dispatch, field.name, field?.tag, tagStoreWithFields])

  //for dynamic autofill validation
  const [statusRender, setStatusRender] = useState(true)
  const [errorStateAutofill, setErrorStateAutofill] = useState("success")

  useEffect(() => {
    if (checkCitesAutofillValidation(field.name) && submitObject[field.name] && !getHandleValidationByRule(validationRule, submitObject[field.name])) {
      let timerId: any
      setStatusRender(false)
      setErrorStateAutofill("error")
      setValidationStatus("on")
      setTimeout(()=>setStatusRender(true), 1000)
      return () => clearTimeout(timerId)
    }
  }, [dispatch, field.name, setValidationStatus, submitObject, validationRule])

  const [statusFocus, setStatusFocus] = useState(false)

  if (statusTagFields) {
    return null
  }


  return (
    <>
      <Form.Item
        label={getLabelWrapper(field, iconHelper, additionalStep)}
        // eslint-disable-next-line max-len
        className={`form-item__${specialInput}-container form-item__${iconsInput}-container ${phoneMobile}-container ${whyDoWeNeed} focus-field_${statusFocus}`}
      >
        {statusRender &&
          <NestRenderInputComponent
            field={field}
            validationRule={validationRule}
            value={value}
            submitObject={submitObject}
            setValidationStatus={setValidationStatus}
            errorStateAutofill={errorStateAutofill}
            setErrorStateAutofill={setErrorStateAutofill}
            validationStatus={validationStatus}
            {...{statusFocus, setStatusFocus, setStatusRender}}
              maskedForPrivacy={maskedForPrivacy}
          />}
        {!statusRender && <NestRenderInputComponent
                field={field}
                validationRule={validationRule}
                value={value}
                submitObject={submitObject}
                setValidationStatus={setValidationStatus}
                errorStateAutofill={errorStateAutofill}
                setErrorStateAutofill={setErrorStateAutofill}
                validationStatus={validationStatus}
                {...{statusFocus, setStatusFocus, setStatusRender}}
                maskedForPrivacy={maskedForPrivacy}
            />
        }
        {field.hint && <p className={`field-hint`}>{field.hint}</p>}
      </Form.Item>
      {field.modal && (
        <InputModalComponent
          field={field}
          nameActualInput={nameActualInput}
          showOnModalHandler={showOnModalHandler}
          showOffModalHandler={showOffModalHandler}
          hint={hint}
          validationStatus={validationStatus}
          errorState={errorState}
        />
      )}
      <div className={`form-item__icon-container`}>
        {field.icons && arrayIcons.map((item: string, ind: number) => <img key={ind} src={item} alt={item} />)}
        {field.iconCvv && arrayCvvIcons.map((item: string, ind: number) => <img key={ind} src={item} alt={item} />)}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  observers: state.form.observers,
  submitObject: state.form.submitObject,
  showValueInputModal: state.step.showValueInputModal,
  nameActualInput: state.step.nameActualInput,
  currentField: state.form.helper,
  currentStep: state.step.currentStep,
  tagStoreWithFields: state.form.tagStoreWithFields,
})

export default connect(mapStateToProps)(InputComponent)
