import React, {useEffect} from "react";
import {
  checkUserToken,
  setInitUrl,
  setUserToken,
  showGrowlMessage
} from "../../appRedux/actions";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {EntryPointFlows} from "@dash/gov-plus__front-end__form/src/util/EntryPointFlows";
import {USER_IP} from "@dash/gov-plus__front-end__form/src/persistence/session/UserIpRepository";
import SessionStorageHandler from "@dash/gov-plus__front-end__form/src/persistence/SessionStorageHandler";
import {getParams} from "../../assets/helpers/getParams";
import ToApplyFormIdRepository from "@dash/gov-plus__front-end__form/src/persistence/session/ToApplyFormIdRepository";
import ToApplyServiceIdRepository from "@dash/gov-plus__front-end__form/src/persistence/session/ToApplyServiceIdRepository";
import AuthUserBearerTokenRepository from "@dash/gov-plus__front-end__form/src/persistence/session/AuthUserBearerTokenRepository";

export const setNavigationFormIdSolutionId = () => {
  const params = getParams(window.location.search, ["to_apply_form_id", "to_apply_service_id"]);
  if(params?.to_apply_service_id)
    ToApplyServiceIdRepository.getInstance().setValue(params?.to_apply_service_id);
  if(params?.to_apply_form_id)
    ToApplyFormIdRepository.getInstance().setValue(params?.to_apply_form_id)
}

export const useNavigationHandling = () => {
  const bearerToken = AuthUserBearerTokenRepository.getInstance().getValue();
  const {authToken, userLogOutStatus, tokenStatus, userTokenInCookiesStatus} = useSelector(({auth}) => auth);
  const isDirectionRTL = useSelector(({settings}) => settings.isDirectionRTL);
  const {pathname} = useSelector(({router}) => router.location);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // call check user token action
  useEffect(() => {
    const userBearerToken = AuthUserBearerTokenRepository.getInstance().getValue();

    if (userBearerToken) {
      if(sessionStorage.getItem(EntryPointFlows.EntryPoint) == null) {
        dispatch(checkUserToken(userBearerToken))
      }
    }

    let navigationLink = "";

    if (pathname === "/signin") {
      navigationLink = "/formsApp";
    }

    if (!bearerToken &&
      pathname !== "/signup" &&
      pathname !== "/activate" &&
      pathname !== "/change-password" &&
      pathname !== "/resolve" &&
      pathname !== "/resolve/"
    ) {
      navigationLink = "/signin";
    }

    if (pathname === "/forms" || pathname === "/products/productInfo"
        || pathname === "/products/productInfo/"
    ) {
      navigationLink = "/formsApp";
    }

    navigationLink && history.push(navigationLink);
  }, []);

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add('rtl');
      document.documentElement.setAttribute('data-direction', 'rtl')
    } else {
      document.documentElement.classList.remove('rtl');
      document.documentElement.setAttribute('data-direction', 'ltr')
    }
  }, [isDirectionRTL]);

  useEffect(() => {
    if (pathname === "/forms") {
      history.push("/formsApp")
    }
    if (authToken && (pathname === "/signin") && userTokenInCookiesStatus) {
      history.push("/formsApp");
    }

    // disabled for new products page

    // if (history.location.pathname === '/') {
    //   history.push("/signin");
    //   dispatch(setInitUrl(location.pathname));
    // }

  }, [pathname, history.location.pathname, userTokenInCookiesStatus]);


  useEffect(() => {

    if ((pathname === "/signin" || pathname === "/") && authToken && userTokenInCookiesStatus) {
      history.push("/formsApp");
    }
    if (userLogOutStatus) {
      history.push('/signin', "fromLogout");
    }
    if (tokenStatus === "common.USER_TOKEN_EXPIRED") {
      SessionStorageHandler.clearIgnoringKey([USER_IP]);
      dispatch(setUserToken({
        userToken: ""
      }))
      dispatch(showGrowlMessage({
        messageInfo: "Your session has expired, please try to log in again.",
        growlStatus: false,
        code: 400,
        showMessage: true,
      }))
    }

  }, [userLogOutStatus, authToken, tokenStatus, userTokenInCookiesStatus]);

};