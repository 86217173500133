import {breadCrumbsArr} from "./breadCrumbsList";

export const breadCrumbsHelper = {
  routes: (currentPage, productsData, productId, params) => {
    if (currentPage === "/") {
      return [
        {
          path: '/',
          breadcrumbName: 'Dashboard',
        }
      ];
    }
    switch (currentPage) {
      case currentPage: {
        return breadCrumbsArr(productsData, productId, params)[currentPage];
      }
      default:{
        return [
          {
            path: '/formsApp',
            breadcrumbName: 'Dashboard',
          }
        ];
      }
    }
  },
}
