import IBuilder from "../IBuilder";
import UpsellShown from "./UpsellShown";
import IEvent from "../IEvent";
import BaseBuilder from "../BaseBuilder";

/**
 * Upsell Shown Builder
 * @class UpsellShownBuilder
 * @extends {BaseBuilder}
 * @implements {IBuilder}
 * @description Upsell Shown Builder
 */
export default class UpsellShownBuilder extends BaseBuilder {
    private _upsellName: string | undefined;
    private _upsellPrice: string | undefined;
    private _upsellCategory: string | undefined;

    /**
     * Set upsell name
     * @param name
     * @returns {IBuilder}
     * @description This method is used to set upsell name
     */
    setUpsellName(name: string | undefined) {
        this._upsellName = name;
        return this;
    }

    /**
     * Set upsell price
     * @param price
     * @returns {IBuilder}
     * @description This method is used to set upsell price
     */
    setPrice(price: string | undefined) {
        this._upsellPrice = price;
        return this;
    }

    /**
     * Set upsell category
     * @param category
     * @returns {IBuilder}
     * @description This method is used to set upsell category
     */
    setUpsellCategory(category: string | undefined) {
        this._upsellCategory = category;
        return this;
    }

    /**
     * Set ip address
     * @param ipAddress
     * @returns {IBuilder}
     * @description This method is used to set ip address
     */
    setIpAddress(ipAddress: string | undefined) {
        this._ipAddress = ipAddress;
        return this;
    }

    /**
     * Build event
     * @returns {IEvent}
     * @description This method is used to build event
     */
    build(): IEvent {
        return new UpsellShown(this._upsellName, this._upsellPrice, this._upsellCategory, this._ipAddress);
    }
}
