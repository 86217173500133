import {
    CREATE_TAX_LINK_START, CREATE_TAX_LINK_SUCCESS, CREATE_TAX_LINK_ERROR,
} from "../../constants/ActionTypes";

export const createTaxLink = (taxes) => {
    return {
        type:CREATE_TAX_LINK_START,
        payload: taxes,
    };
};

export const createTaxLinkSuccess = (taxes) => {

    return {
        type: CREATE_TAX_LINK_SUCCESS,
        payload: taxes,
    };
};

export const createTaxLinkFailure = (error) => {
    return {
        type: CREATE_TAX_LINK_ERROR,
        payload: error,
    };
};
