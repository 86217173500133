import SignedIn from "./SignedIn";
import IEvent from "../IEvent";
import BaseBuilder from "../BaseBuilder";
import IBuilder from "../IBuilder";

/**
 * Event for when a user signs in
 * @class SignedInBuilder
 * @extends {BaseBuilder}
 * @implements {IBuilder}
 * @description Event for when a user signs in
 */
export default class SignedInBuilder extends BaseBuilder {
    private _email: string | undefined;

    /**
     * Set email
     * @param value
     * @returns {IBuilder}
     * @description This method is used to set email
     */
    setEmail(value: string | undefined) {
        this._email = value;
        return this;
    }

    /**
     * Set ip address
     * @param ipAddress
     * @returns {IBuilder}
     * @description This method is used to set ip address
     */
    setIpAddress(ipAddress: string | undefined) {
        this._ipAddress = ipAddress;
        return this;
    }

    /**
     * Build event
     * @returns {IEvent}
     * @description This method is used to build event
     */
    build(): IEvent {
        return new SignedIn(this._email, this._ipAddress);
    }
}
