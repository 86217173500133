import React, { FC } from "react"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GAuthHeader from "../../molecules/AuthHeader/AuthHeader"
import "./Welcome.scss"
import GSpinner from "../../atoms/Spinner/Spinner"

type GWelcomeProps = {}

const TITLE = "Welcome"
const DESCRIPTION = "Welcome to GOV+. Redirecting you to the dashboard..."

const GWelcome: FC<GWelcomeProps> = () => {
  return (
    <div className={`GWelcome`}>
      <GAuthHeader title={TITLE} description={DESCRIPTION} />
      <div className={`GWelcome__SpinnerContainer`}>
        <GSpinner />
      </div>
    </div>
  )
}
export default GWelcome
