import { useCallback } from "react"
import React, { FC } from "react"
import { useDispatch } from "react-redux"
import {
  ItemArrayComponentsInterface,
  ItemInterface,
} from "../../../../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { sentDocumentToBack } from "../../../../../../Redux/store/upload/upload.actions"
import { renderBasicBlockContainer } from "../../../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import LayoutBaseContainer from "../../../../UploadPhoto/Layouts/LayoutBaseContainer/LayoutBaseContainer"
import LayoutDndUploadPhoto from "../../../../UploadPhoto/Layouts/LayoutDndUploadPhoto/LayoutDndUploadPhoto"
import { dynamicPropsInterface } from "./DNDUploadDocumentContainer"

type UploadDocumentDndAreaProps = {
  item: ItemInterface,
  arrayComponents?: ItemArrayComponentsInterface[],
  dynamicProps?: dynamicPropsInterface,
}

const UploadDocumentDndArea: FC<UploadDocumentDndAreaProps> = ({
  item: { className, children: childrenItem, text, state, childrenByDrag },
  arrayComponents,
  ...props
}) => {
  const { dynamicProps } = props
  const dispatch = useDispatch()
  // for drag and drop upload
  const handleUploadDocument = useCallback(
    (file: File) => file && dispatch(sentDocumentToBack({ ...dynamicProps, file })),
    [dispatch]
  )

  const dragIsActive = renderBasicBlockContainer(
    { className, text, childrenItem: childrenByDrag, arrayComponents, state },
    LayoutBaseContainer,
    props
  )

  return (
    <div className={className}>
      {/*@ts-ignore*/}
      <LayoutDndUploadPhoto
        onDrop={handleUploadDocument}
        {...{
          dragIsActive,
        }}
      >
        {renderBasicBlockContainer(
          { className, text, childrenItem, arrayComponents, state },
          LayoutBaseContainer,
          props
        )}
      </LayoutDndUploadPhoto>
    </div>
  )
}

export default React.memo(UploadDocumentDndArea)
