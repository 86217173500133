import React from "react"

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
}

export const SvgPhoto = ({ width, height, color }: ISvgCheck) => {
  return (
      <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.832031" y="0.65625" width="64" height="64" rx="32" fill="#EEF4FB"/>
          <g clipPath="url(#clip0_15232_121167)">
              <path d="M38.1667 38.906V37.5728C38.1667 36.8656 37.8857 36.1873 37.3856 35.6873C36.8855 35.1872 36.2072 34.9062 35.5 34.9062H30.1667C29.4594 34.9062 28.7811 35.1872 28.281 35.6873C27.781 36.1873 27.5 36.8656 27.5 37.5728V38.906" stroke="#185EC8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M32.8346 31.7393C34.3074 31.7393 35.5013 30.5455 35.5013 29.0728C35.5013 27.6001 34.3074 26.4062 32.8346 26.4062C31.3619 26.4062 30.168 27.6001 30.168 29.0728C30.168 30.5455 31.3619 31.7393 32.8346 31.7393Z" stroke="#185EC8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M43.832 38.6562V41.434C43.832 42.0234 43.6213 42.5886 43.2462 43.0054C42.8712 43.4221 42.3625 43.6563 41.832 43.6563H38.832" stroke="#185EC8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M26.832 43.6562L24.0543 43.6562C23.4649 43.6562 22.8997 43.4455 22.4829 43.0705C22.0662 42.6954 21.832 42.1867 21.832 41.6562L21.832 38.6562" stroke="#185EC8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M38.832 21.6562L41.6098 21.6562C42.1992 21.6562 42.7644 21.867 43.1812 22.242C43.5979 22.6171 43.832 23.1258 43.832 23.6562L43.832 26.6562" stroke="#185EC8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M21.832 26.6563L21.832 23.8785C21.832 23.2891 22.0427 22.7239 22.4178 22.3071C22.7929 21.8904 23.3016 21.6563 23.832 21.6563L26.832 21.6562" stroke="#185EC8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
              <clipPath id="clip0_15232_121167">
                  <rect width="24" height="24" fill="white" transform="translate(20.832 20.6562)"/>
              </clipPath>
          </defs>
      </svg>



  )
}


