import EmailVerificationSucceeded from "./EmailVerificationSucceeded";
import IEvent from "../IEvent";
import BaseBuilder from "../BaseBuilder";

export default class EmailVerificationSucceededBuilder extends BaseBuilder {
    private _emailVerified: boolean | undefined;
    private _emailData: any | undefined;

    setEmailVerified(value: boolean | undefined) {
        this._emailVerified = value;
        return this;
    }

    setEmailData(value: any | undefined) {
        this._emailData = value;
        return this;
    }

    setIpAddress(ipAddress: string | undefined) {
        this._ipAddress = ipAddress;
        return this;
    }

    build(): IEvent {
        return new EmailVerificationSucceeded(this._emailVerified, this._emailData, this._ipAddress);
    }
}
