import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import NotesReducer from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import Notifications from "./Notifications";
import Products from "./ProductsReducer";
import Offers from "./Offers"
import Billings from "./Billings"
import CheckUserStatusReducer from "./CheckUserStatus";
import accountSettingsReducer from "./profileSettings";
import Users from "./Users";
import Tax from "./Tax";
import IDP from "./IDP";

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: NotesReducer,
  contact: Contact,
  common: Common,
  notifications: Notifications,
  allProducts: Products,
  allOffers: Offers,
  checkUserStatus: CheckUserStatusReducer,
  accountSettingsReducer,
  Users,
  billingInformation: Billings,
  tax: Tax,
  identityProtectionData: IDP
});

export default createRootReducer
