import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

export const TO_APPLY_FORM_ID = "to_apply_form_id"

class ToApplyFormIdRepository extends BaseSessionStorageRepository {
    private static _instance: ToApplyFormIdRepository;

    private constructor() {
        super();
    }

    public static getInstance(): ToApplyFormIdRepository {
        if (!ToApplyFormIdRepository._instance) {
            ToApplyFormIdRepository._instance = new ToApplyFormIdRepository();
        }
        return ToApplyFormIdRepository._instance;
    }

    getKeyName(): string {
        return TO_APPLY_FORM_ID;
    }

    getValue(): string | null {
        return super.getValue();
    }
}

export default ToApplyFormIdRepository;