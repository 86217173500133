import React, {useEffect, useMemo, useState} from "react"
import { connect, useDispatch } from "react-redux"
import { getPropertiesForEvents } from "../../../../services/gtm/backEndEvent"
import GButton, { BUTTON_TYPE } from "../../../atomicDesign/molecules/Button/GButton"
import { handlerClickButtonPassportDetails } from "../PassportDetailsComponent/PassportDetailsComponent"
import { setStatusQuestionary } from "../../../../Redux/store/additionalForm/additional.actions"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import "./QuestionaryPassport.scss"
import GText from "../../../atomicDesign/atoms/Text/Text"
import { SIZE } from "../../../atomicDesign/types/types"
import { COLORS, COLORS_NEW } from "../../../atomicDesign/types/Colors"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"

type QuestionnaireBlockProcessProps = {
  blocksStep: any,
  submitObject: any,
  formId: string,
  appId: string,
  loadingButtonState: string,
}

interface OptionFieldInterface {
  optionName: string;
  submitValue: string;
}

const FORM_ID = {
  PASSPORT_RENEWAL: "passport-renewal",
}

// eslint-disable-next-line max-len
const QuestionnaireBlockProcess: React.FunctionComponent<QuestionnaireBlockProcessProps> = ({
  blocksStep,
  submitObject,
  formId,
  appId,
  loadingButtonState,
}) => {
  const dispatch = useDispatch()
  const [disableBtn, setDisabledBtn] = useState(false)
  const [loadingBtnOption, setLoadingBtnOption] = useState<OptionFieldInterface>()
  useEffect(() => {
    /* Event Processing Step */
  }, [])

  const {
    content: {
      description,
      name: nameBlock,
      openContent: {
        radioButtons,
        header,
        radioButtons: { nameRadio },
      },
    },
  } = blocksStep

  const { form_id } = getPropertiesForEvents()
  const breakPoint = useBreakpoint()

  const filterRadioButtons = useMemo(() => {
    if (form_id === FORM_ID.PASSPORT_RENEWAL) {
      return radioButtons
    }
    const { options } = radioButtons ?? {}
    return {
      ...radioButtons,
      options: options.filter(({ form_id: formIdOption }: { [key: string]: any }) => formIdOption === form_id),
    }
  }, [form_id, radioButtons])

  const optionClick = (option: OptionFieldInterface) => {
    setDisabledBtn(true)
    setLoadingBtnOption(option)

    generateAdditionalFields(option.optionName, filterRadioButtons.options)
    dispatch(setStatusQuestionary("completed"))
    dispatch(setValueCurrentField(filterRadioButtons.name, option.submitValue))
  }

  const generateAdditionalFields = (value: string, options: OptionFieldInterface[]) => {
    options.find(
      ({ optionName, submitValue }: OptionFieldInterface) =>
        optionName === value &&
        handlerClickButtonPassportDetails(
          true,
          { ...submitObject, form_type: submitValue },
          dispatch,
          {},
          appId,
          formId
        )
    )
  }

  return (
    <div className={"g-questionary-container"}>
      <GText text={header} size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_20 : SIZE.PARAGRAPH_BOLD_16} />
      <div className={"options-container"}>
        {filterRadioButtons.options.map((option: OptionFieldInterface, index: number) => (
          <div key={index} className={"option-button"}>
            <GButton
              textSize={SIZE.PARAGRAPH_BOLD_16}
              text={option.optionName}
              type={BUTTON_TYPE.SECONDARY}
              isSubmit={false}
              showIcon={false}
              disabled={disableBtn}
              loading={loadingBtnOption ? loadingBtnOption.optionName === option.optionName : false}
              click={() => {
                optionClick(option)
              }}
              style={{height: breakPoint.xs ? 44 : 56}}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
  loadingButtonState: state.loading.loadingButtonState,
})

export default connect(mapStateToProps)(QuestionnaireBlockProcess)
