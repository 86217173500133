import TemplateOneBuilder from "../TemplateOneBuilder";
import {ITemplateOneBuilder} from "./ITemplateOneBuilder";
import {IUpsellProcessingData} from "../../../StepUpsell";
import TemplateOne from "../TemplateOne";
import thirdPartyUpsellImage from "../../../../../../../../assets/image/upsells/third_party_offer.png";

export class ThirdPartyUpsell implements ITemplateOneBuilder{
    buildTemplate(builder: TemplateOneBuilder, data: IUpsellProcessingData, solutionName: string): TemplateOne {
       builder
           .setImageHeader(thirdPartyUpsellImage)
           .setPrice(undefined)
           .setFooterText("Clicking “See details” will open this offer in a new tab, so you won’t lose any progress on your current TSA PreCheck® application.")
           .setTagInformation('Last chance upgrade')
           .setCheckListItems([
                'Amex Platinum offers a rich welcome bonus of 80,000 Membership Rewards® Points after you spend $8,000 on purchases on your new Card in your first 6 months of Card Membership.',
                'Earn points while you travel with 5X Membership Rewards® Points for flights booked directly with airlines or with American Express Travel (up to $500,000 per calendar year)',
           ])
       return builder.build();
    }
}