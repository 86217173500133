import { Action } from "../../interfacesAction/action.interface"

export const ActionTypes = {
  SEND_EMAIL: "[EMAIL] send email",
  SET_ANSWER_EMAIL: "[EMAIL] set answer email",

  SEND_MESSAGE: "[SUPPORT] send message",
  GET_CHECK_CUSTOMER_BY_EMAIL: "[AUTH] get check customer by email",
  SET_CHECK_CUSTOMER_BY_EMAIL: "[AUTH] set check customer by email",
  LOG_IN_CUSTOMER_BY_EMAIL: "[AUTH] log in customer by email",
  SET_STATUS_AUTH_MODAL: "[AUTH] set status auth modal",

  GET_ARRAY_COUNTRY_CODES: "[COUNTRY_CODE] get array country code",
  SET_ARRAY_COUNTRY_CODES: "[COUNTRY_CODE] set array country code",

  GET_FAILURE: "[getFailureAction] get failure action",
}

export const sendEmail = (obj: {}): Action<{}> => ({
  type: ActionTypes.SEND_EMAIL,
  payload: obj,
})

export const setAnswerEmail = (answer: {}): Action<{}> => ({
  type: ActionTypes.SET_ANSWER_EMAIL,
  payload: answer,
})

export const makeCustomerSupport = (obj: {}): Action<{}> => ({
  type: ActionTypes.SEND_MESSAGE,
  payload: obj,
})

export const getFailureAction = (error?: {}): Action<{}> => ({
  type: ActionTypes.GET_FAILURE,
  payload: error,
})

export const getCheckCustomerByEmail = (submitObj: {}): Action<{}> => ({
  type: ActionTypes.GET_CHECK_CUSTOMER_BY_EMAIL,
  payload: submitObj,
})

export const setCheckCustomerByEmail = (obj: {}): Action<{}> => ({
  type: ActionTypes.SET_CHECK_CUSTOMER_BY_EMAIL,
  payload: obj,
})

export const logInCustomerByEmail = (submitObj: {}): Action<{}> => ({
  type: ActionTypes.LOG_IN_CUSTOMER_BY_EMAIL,
  payload: submitObj,
})

export const setStatusAuthModal = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_AUTH_MODAL,
  payload: status,
})

export const getArrayCountryCode = () => ({
  type: ActionTypes.GET_ARRAY_COUNTRY_CODES,
})

export const setArrayCountryCode = (arrayCodes: []): Action<[]> => ({
  type: ActionTypes.SET_ARRAY_COUNTRY_CODES,
  payload: arrayCodes,
})
