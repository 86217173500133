import React, { FC } from "react"
import "./GAdditionalStepCard.scss"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import GButton from "../../molecules/Button/GButton"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { BlockStepTypes } from "../../../../Constants/BlockStepTypes"

export enum CARD_STATUS {
  DISABLED,
  PROCESS,
  DONE,
}

type GAdditionalStepCardProps = {
  index?: string,
  title: string,
  titleDone?: string,
  information?: string,
  ctaText?: string,
  ctaForm?: any,
  cardStatus: number,
  children?: React.ReactNode,
  loadingButton?:boolean,
  blockStepType?: string
}

const GAdditionalStepCard: FC<GAdditionalStepCardProps> = ({
  index,
  title,
  titleDone,
  information,
  ctaText,
  ctaForm,
  cardStatus,
  children,
  loadingButton= false,
  blockStepType
}) => {
  const completedBlock = blockStepType === BlockStepTypes.COMPLETED
  const breakPoint = useBreakpoint()
  const indexHeaderStyles: React.CSSProperties = {
    backgroundColor: cardStatus === CARD_STATUS.DISABLED ? COLORS_NEW.BLACK_100 : COLORS_NEW.ROYAL_BLUE_300
  }
  return (
    <div className={`GAdditionalStepCard`}>
      <div className={`GAdditionalStepCard__Header`}>
        {(cardStatus !== CARD_STATUS.DONE && !completedBlock) && index && (
          <div className={`GAdditionalStepCard__Header__Index`} style={indexHeaderStyles}>
            <GText text={index} size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_20 : SIZE.PARAGRAPH_BOLD_16} color={cardStatus === CARD_STATUS.DISABLED ? COLORS_NEW.BLACK_400 : COLORS_NEW.ROYAL_BLUE_700} />
          </div>
        )}
        {(cardStatus === CARD_STATUS.DONE || completedBlock) && (
          <div className={`GAdditionalStepCard__Header__Icon`}>
            <GIconsSvgType
              type={ICONS_SVG_TYPE.CHECK_CIRCLE}
              width={breakPoint.md ? 32 : 24}
              height={breakPoint.md ? 32 : 24}
              color={COLORS.WHITE}
              backgroundColor={COLORS_NEW.GREEN_600}
            />
          </div>
        )}
        <div className={`GAdditionalStepCard__Header__Title`}>
          <GText
            text={title}
            size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_22 : SIZE.PARAGRAPH_BOLD_18}
            color={(cardStatus === CARD_STATUS.DONE || completedBlock) ? COLORS_NEW.GREEN_600 : (cardStatus === CARD_STATUS.DISABLED ? COLORS_NEW.BLACK_400 : COLORS_NEW.BLACK_1000)}
          />
        </div>
      </div>
      {cardStatus !== CARD_STATUS.DONE && information && (
        <div className={`GAdditionalStepCard__Information`}>
          <GText text={information} size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14} color={COLORS_NEW.BLACK_800} innerHtml={information}/>
        </div>
      )}
      {children && <div className={`GAdditionalStepCard__Form`}>{children}</div>}
      {ctaForm && ctaText && (
        <div className={`GAdditionalStepCard__CallToAction`}>
          <GButton
            text={ctaText}
            textSize={SIZE.PARAGRAPH_BOLD_16}
            size={"large"}
            click={ctaForm}
            disabled={cardStatus === CARD_STATUS.DISABLED || loadingButton}
            showIcon={false}
            loading={loadingButton}
          />
        </div>
      )}
    </div>
  )
}

export default GAdditionalStepCard
