import React, {useEffect} from "react"
import "./ProcessingByGovernmentComponent.scss"
import GAdditionalStep from "../../../atomicDesign/templates/AdditionalStep/AdditionalStep"
//@ts-ignore
import {useApplications} from "../../../../../../dashboard-govplus-front/src/hooks/useApplications"
import AuthUserBearerTokenRepository from "../../../../persistence/session/AuthUserBearerTokenRepository"
import {getImageFromLocalStorage, replaceSolution, replaceWords} from "../../../../util/ProcessingTrackingHelper"
import GSpinner from "../../../atomicDesign/atoms/Spinner/Spinner"
import GSmallTrackingCard
    from "../../../atomicDesign/templates/AdditionalStep/StepStatusTracking/shared/SmallTrackingCard/SmallTrackingCard"
import StringHelper from "../../../../util/StringHelper"

type ProcessingByGovernmentProp = {
    blocksStep: any,
    solutionName: string,
    solutionNameToAdditionalStepsCard: string,
    solutionId: string,
    index: number,
    showStatus: string,
    applicationId: string,
    toCustomer?: boolean
    showEstimateWaitTime?: boolean
}

const ProcessingByGovernmentComponent: React.FunctionComponent<ProcessingByGovernmentProp> = (
    {
        blocksStep,
        solutionName,
        solutionNameToAdditionalStepsCard,
        solutionId,
        index,
        showStatus,
        applicationId,
        toCustomer = true,
        showEstimateWaitTime = true
    }) => {

    const {
        getPostTrackingData,
        postData,
        loading
    } = useApplications();
    const authToken = AuthUserBearerTokenRepository.getInstance().getValue();
    useEffect(() => {
        if (showStatus === "process" && authToken !== null) {
            getPostTrackingData(authToken as string, applicationId, toCustomer);
        }
    }, [applicationId, authToken]);

    return (
        <>
            {
                (loading && showStatus === "process") && (
                    <div className="GProcessingByGovernment__card">
                        <GSpinner/>
                    </div>
                )
            }
            {
                (!loading || showStatus !== "process") && (
                    <GAdditionalStep
                        title={replaceSolution(blocksStep.content.title, solutionName)}
                        titleDone={replaceSolution(blocksStep.content.titleDone, solutionName)}
                        information={blocksStep?.content?.information}
                        index={index.toString()}
                        stepShowStatus={showStatus}
                    >
                        {
                            postData !== undefined &&
                            <GSmallTrackingCard
                                showGovernmentIcon={true}
                                governmentAgency={StringHelper.correctCapitalization(postData?.governmentOfficeLocation?.name || "")}
                                imgSrc={getImageFromLocalStorage(solutionId)}
                                solution={solutionNameToAdditionalStepsCard}
                                width={68}
                                height={87}
                                numberOfWeeks={postData?.processingNumberOfWeeks || ""}
                                textProgressBar={"Processing"}
                                showEstimateWaitTime={showEstimateWaitTime}
                            />
                        }

                    </GAdditionalStep>
                )
            }
        </>
    )
}
export default ProcessingByGovernmentComponent
