import { Action } from "../../interfacesAction/action.interface"
import { saveStatusUploadPhotoInterface } from "../../../Components/FormComponents/UploadPhoto/UploadPhotoSaveButton/UploadPhotoSaveButton"

export const ActionTypes = {
  SEND_PHOTO_TO_BACK: "[PHOTO] sent photo to back",
  SEND_DOCUMENT_TO_BACK: "[DOCUMENT] sent document to back",
  SET_LINK_PHOTO: "[PHOTO] set link photo",
  SET_LINK_ORIGINAL_PHOTO: "[PHOTO] set link original photo",
  SET_STATUS_UPLOAD_PHOTO: "[PHOTO] set status upload photo",
  SET_DYNAMIC_UPLOAD_PHOTO_ERROR_MESSAGE: "[SET_DYNAMIC_UPLOAD_PHOTO_ERROR_MESSAGE] set error from backend in upload photo",
  SET_STATUS_UPLOAD_DOCUMENT: "[DOCUMENT] set status upload document",
  REMOVE_PHOTO_TO_BACK: "[PHOTO] remove photo to back",
  REMOVE_DOCUMENT_TO_BACK: "[DOCUMENT] remove document to back",
  SAVE_PHOTO_TO_BACK: "[PHOTO] save photo to back",
  SET_CURRENT_FILE_NAME: "[PHOTO] set current file name",

  SET_UPLOADS_DOCUMENTS: "[DOCUMENT] set upload documents",
  SET_CURRENT_PARAMS_UPLOAD_DOCUMENT: "[DOCUMENT] set current params upload document",
  SET_CURRENT_STATES_UPLOAD_DOCUMENTS: "[DOCUMENT] set current states upload documents",
  SET_BLOB_UPLOAD_DOCUMENTS: "[DOCUMENT] set blob upload documents",
  SET_DOCUMENTS_NAME_BY_FIELD_NAME: "[DOCUMENTS] set documents name by field name",

  CLEAR_UPLOAD_IN_START_APP: "[UPLOAD] clear upload in start app",
  CLEAR_BLOB_DOCUMENTS_UPLOAD: "[UPLOAD] clear blob documents upload",

  GET_FAILURE: "[getFailureAction] get failure action",
}

export const sentPhotoToBack = (photo: File): Action<File> => ({
  type: ActionTypes.SEND_PHOTO_TO_BACK,
  payload: photo,
})

export const sentDocumentToBack = (document: {}): Action<{}> => ({
  type: ActionTypes.SEND_DOCUMENT_TO_BACK,
  payload: document,
})

export const setLinkPhoto = (link: string): Action<string> => ({
  type: ActionTypes.SET_LINK_PHOTO,
  payload: link,
})

export const setLinkOriginalPhoto = (link: string): Action<string> => ({
  type: ActionTypes.SET_LINK_ORIGINAL_PHOTO,
  payload: link,
})


export const setStatusUploadPhoto = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_UPLOAD_PHOTO,
  payload: status,
})

export const setDynamicUploadPhotoErrorMessage = (message: string): Action<string> => ({
  type: ActionTypes.SET_DYNAMIC_UPLOAD_PHOTO_ERROR_MESSAGE,
  payload: message,
})

export const setStatusUploadDocuments = (status: string): Action<string> => ({
  type: ActionTypes.SET_STATUS_UPLOAD_DOCUMENT,
  payload: status,
})

export const removeStatusUploadPhoto = (): Action<never> => ({
  type: ActionTypes.REMOVE_PHOTO_TO_BACK,
})

export const removeUploadDocument = (uploadData: {}): Action<{}> => ({
  type: ActionTypes.REMOVE_DOCUMENT_TO_BACK,
  payload: uploadData,
})

export const saveStatusUploadPhoto = (
  submitObj: saveStatusUploadPhotoInterface
): Action<saveStatusUploadPhotoInterface> => ({
  type: ActionTypes.SAVE_PHOTO_TO_BACK,
  payload: submitObj,
})

export const setUploadDocuments = (uploadData: {}): Action<{}> => ({
  type: ActionTypes.SET_UPLOADS_DOCUMENTS,
  payload: uploadData,
})

export const setCurrentParamsUploadDocument = (uploadData: {}): Action<{}> => ({
  type: ActionTypes.SET_CURRENT_PARAMS_UPLOAD_DOCUMENT,
  payload: uploadData,
})

export const setCurrentStatesUploadDocuments = (uploadData: {}): Action<{}> => ({
  type: ActionTypes.SET_CURRENT_STATES_UPLOAD_DOCUMENTS,
  payload: uploadData,
})

export const setBlobUploadDocuments = (uploadData: {}): Action<{}> => ({
  type: ActionTypes.SET_BLOB_UPLOAD_DOCUMENTS,
  payload: uploadData,
})

export const setDocumentsNameByFieldName = (uploadData: {}): Action<{}> => ({
  type: ActionTypes.SET_DOCUMENTS_NAME_BY_FIELD_NAME,
  payload: uploadData,
})

export const setCurrentFileName = (uploadFileName: string): Action<string> => ({
  type: ActionTypes.SET_CURRENT_FILE_NAME,
  payload: uploadFileName,
})

export const clearUploadInStartApp = () => ({
  type: ActionTypes.CLEAR_UPLOAD_IN_START_APP,
})

export const clearBlobDocumentsUpload = () => ({
  type: ActionTypes.CLEAR_BLOB_DOCUMENTS_UPLOAD
})

export const getFailureAction = (error?: {}): Action<{}> => ({
  type: ActionTypes.GET_FAILURE,
  payload: error,
})
