import React, {useCallback, useEffect} from "react"
import SubscriptionAbout from "../../../SubscriptionPaymentForm/SubscriptionAbout";
import SubscriptionPaymentForm from "../../../SubscriptionPaymentForm/SubscriptionPaymentForm";
import './PaymentSubscriptionInformation.scss'

type PaymentSubscriptionInformationProps = {
  blockObject: { name: string, subscriptionPriceInformation: [], contentSections: [] } | any,
  setSelectedPaymentSubscriptionPlan: any
}

const PaymentSubscriptionInformation: React.FunctionComponent<PaymentSubscriptionInformationProps> = ({
  blockObject,
  setSelectedPaymentSubscriptionPlan
}) => {

  const subscriptionPriceInformation = blockObject?.subscriptionPriceInformation
  const contentSections = blockObject?.contentSections

  const getYearlyPlan = useCallback((subscriptionPriceInformation: any[]) => {
    const rowYearly = subscriptionPriceInformation.find((row: any) => row.method === "yearly");
    return {method: rowYearly?.method, price: rowYearly?.yearPrice};
  }, [subscriptionPriceInformation])

  useEffect(() => {
    setSelectedPaymentSubscriptionPlan(getYearlyPlan(subscriptionPriceInformation))
  }, []);

  return (
    <div className={'PaymentSubscriptionInformation'}>
      <SubscriptionAbout contentSections={contentSections}/>

      <SubscriptionPaymentForm
        subscriptionPriceInformation={subscriptionPriceInformation}
        setSelectedPlan={setSelectedPaymentSubscriptionPlan}
      />
    </div>
  )
}

export default PaymentSubscriptionInformation
