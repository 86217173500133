import React, { FC, useState } from "react"
import "./DriverLicenseDetailsForm.scss"
import { Form } from "antd"
import { FormInput, validateDriverLicenseNumber, usaStates } from "../../../../../util/IdentityProtectionHelper"
import GText from "../../../atoms/Text/Text"
import GButton from "../../../molecules/Button/GButton"
import GInputText from "../../../molecules/InputText/InputText"
import GSelect from "../../../molecules/Select/Select"
import { COLORS_NEW } from "../../../types/Colors"
import { SIZE, BUTTON_TYPE } from "../../../types/types"
import useIsMobile from "../../../../../../../dashboard-govplus-front/src/hooks/useIsMobile"

const DriverLicenseDetailsForm: FC<FormInput> = ({ recordType, handleFormActions }) => {
  const [driverLicenseForm] = Form.useForm()
  const isMobile = useIsMobile(768)

  const [driverLicenseState, setDriverLicenseState] = useState(usaStates[0].value)
  const [driverLicenseStateError, setDriverLicenseStateError] = useState("")
  const [driverLicenseNumberError, setDriverLicenseNumberError] = useState("")

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase()
    driverLicenseForm.setFieldValue("driverLicenseNumber", value)
    if (value.length >= 7) {
      setDriverLicenseNumberError("")
    }
  }

  const handleStateChange = (value: any) => {
    setDriverLicenseState(value)
    validateField("driverLicenseState")
  }

  const validateField = async (fieldName: string) => {
    try {
      await driverLicenseForm.validateFields([fieldName])
      if (fieldName === "driverLicenseState") {
        setDriverLicenseStateError("")
      }
    } catch (error: any) {
      const errorMessage = error.errorFields[0]?.errors[0] || ""
      if (fieldName === "driverLicenseState") {
        setDriverLicenseStateError(errorMessage)
      }
    }
  }

  const handleFormSubmit = async () => {
    try {
      const valid = await driverLicenseForm.validateFields()
      if (valid) {
        const submittedData = {
          recordType,
          value: `${driverLicenseState}|${driverLicenseForm.getFieldValue("driverLicenseNumber")}`,
        }
        handleFormActions(submittedData)
        setDriverLicenseStateError("")
        setDriverLicenseNumberError("")
      }
    } catch (error: any) {
      console.error(error)
      const errors = error.errorFields
      setDriverLicenseStateError(errors.find((e: any) => e.name[0] === "driverLicenseState")?.errors[0] || "")
      setDriverLicenseNumberError(errors.find((e: any) => e.name[0] === "driverLicenseNumber")?.errors[0] || "")
    }
  }

  const getDriverLicenseFormLabel = () => {
    return (
      <div className={"DriverLicenseDetailsForm__Item__Label"}>
        <GText text={"State"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
      </div>
    )
  }

  const getDriverLicenseNumberFormLabel = () => {
    return (
      <div className={"DriverLicenseDetailsForm__Item__Label"}>
        <GText text={"Driver's license number"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
      </div>
    )
  }

  const formItemStyle = isMobile ? { maxWidth: "100%", width: "100%" } : { maxWidth: "160px", width: "100%" }

  return (
    <Form
      className={"DriverLicenseDetailsForm"}
      layout={"vertical"}
      form={driverLicenseForm}
      initialValues={{ remember: true }}
      name={"basic"}
      onFinish={handleFormSubmit}
    >
      <Form.Item
        style={formItemStyle}
        label={getDriverLicenseFormLabel()}
        name="driverLicenseState"
        rules={[{ required: true, message: "Please select a state" }]}
        hasFeedback={false}
        help={driverLicenseStateError}
        validateStatus={driverLicenseStateError ? "error" : ""}
      >
        <GSelect onChange={handleStateChange} options={usaStates} placeHolder={"Select state"} />
      </Form.Item>

      <Form.Item
        className={"DriverLicenseDetailsForm__Item"}
        label={getDriverLicenseNumberFormLabel()}
        name={"driverLicenseNumber"}
        rules={[
          { required: true, message: "Driver's license number is required." },
          {
            validator: validateDriverLicenseNumber,
          },
        ]}
        hasFeedback={false}
        help={driverLicenseNumberError}
        validateStatus={driverLicenseNumberError ? "error" : ""}
      >
        <GInputText onChange={handleInputChange} minLength={7} maxLength={20} />
      </Form.Item>

      <div className={"DriverLicenseDetailsForm__Actions"}>
        <GButton
          type={BUTTON_TYPE.SECONDARY}
          showIcon={false}
          style={{ height: "44px" }}
          text={"Add"}
          click={handleFormSubmit}
        />
      </div>
    </Form>
  )
}

export default DriverLicenseDetailsForm
