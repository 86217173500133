import React, { FC } from "react"
import { Form } from "antd"
import "./FormItem.scss"
import { NamePath, Rule } from "rc-field-form/lib/interface"

export enum FORM_ITEM_STATUSES {
  DEFAULT = "",
  ERROR = "error",
  SUCCESS = "success",
}

type GFormItemProps = {
  children?: React.ReactNode,
  label?: any,
  name: string,
  initialValue?: string,
  status?: string,
  message?: string,
  rules?: Rule[],
  dependencies?: NamePath[],
  preserve?: boolean,
  valuePropName?: string,
  style?: any,
}

const GFormItem: FC<GFormItemProps> = ({
  children,
  label,
  name,
  initialValue,
  status = FORM_ITEM_STATUSES.DEFAULT,
  message,
  dependencies,
  rules,
  preserve,
  valuePropName,
  style,
}) => {
  let currentStatus = FORM_ITEM_STATUSES.DEFAULT
  if (status === FORM_ITEM_STATUSES.ERROR) {
    currentStatus = FORM_ITEM_STATUSES.ERROR
  }

  const getHelpMessage = () => {
    return <div className={`GFormItem__item__Message GFormItem__item__Message--${currentStatus}`}>{message}</div>
  }

  return (
    <div className={`GFormItem`}>
      <Form.Item
        required={false}
        style={style}
        className={`GFormItem__item`}
        label={label}
        name={name}
        initialValue={initialValue}
        help={message ? getHelpMessage() : undefined}
        rules={rules}
        dependencies={dependencies}
        preserve={preserve}
        valuePropName={valuePropName}
        validateFirst={false}
      >
        {children}
      </Form.Item>
    </div>
  )
}

export default GFormItem
