import IEvent from "../IEvent"
import BaseBuilder from "../BaseBuilder"
import IdentityProtectionCreditScoreStarted from "./IdentityProtectionCreditScoreStarted"

/**
 * Identity Protection Verification Started Builder
 * @class IdentityProtectionCreditScoreStartedBuilder
 * @extends {BaseBuilder}
 * @implements {IBuilder}
 * @description Identity Protection Verification Started Builder
 */

export default class IdentityProtectionCreditScoreStartedBuilder extends BaseBuilder {
    /**
     * Set ip address
     * @param ipAddress
     * @returns {IBuilder}
     * @description This method is used to set ip address
     */
  setIpAddress(ipAddress: string | undefined) {
    this._ipAddress = ipAddress
    return this
  }

  /**
   * Build event
   * @returns {IEvent}
   * @description This method is used to build event
   */
  build(): IEvent {
    return new IdentityProtectionCreditScoreStarted(this._ipAddress)
  }
}
