import React from "react";
import {FC} from "react";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import GIconDescription, {IGIconDescription} from "../../molecules/IconDescription/GIconDescription";
import "./GBenefitsList.scss"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

export interface IGBenefitsList {
    title: string;
    items: IGIconDescription[];
}

export type GBenefitsListProps = { props: IGBenefitsList };

const GBenefitsList: FC<GBenefitsListProps> = ({props}) => {

    return (
        <div className="GBenefitsList">
            <GText text={props.title} size={SIZE.HEADING_MDLG} color={COLORS_NEW.BLACK_1000}></GText>
            <div className="ItemList">
                {props.items.map((item, key)=> {
                    return <div key={key}><GIconDescription props={item} /></div>
                })}
            </div>
        </div>
    )
}
export default GBenefitsList