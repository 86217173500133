import React from "react"
import { Form, Input } from "antd"

const SmsUserModalContent: React.FunctionComponent = () => (
  <label className={`sms_user_modal__login_label check-status__email-container`}>
    <Form.Item
      label="Verification code"
      name="sms"
      rules={[
        { required: true, message: "Required" },
        // { pattern: new RegExp("^[0-9]+$"), message: "Number required" },
      ]}
    >
      <Input className={`sms_user_modal__login_input`} />
    </Form.Item>
  </label>
)

export default SmsUserModalContent
