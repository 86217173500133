import React from "react";
import {specialStatusConstants} from "../../productsAndStatusesConstants";
import TrackingToCustomer from "./TrackingToCustomer";
import NoSuchProductOrStatus from "../../../NoSuchProductOrStatus";

const Ssn = ({statusName, productId, productName}) => {
  const StatusRender = () =>{
    switch (statusName){
      case specialStatusConstants[productName].tracking_to_customer:{
        return (
          <TrackingToCustomer
            productId={productId}
          />
        )
      }
      default: return (
        <NoSuchProductOrStatus
          description={"Sorry page for this status is not ready yet."}
          appId={productId}
          productName={productName}
          productStatus={statusName}
        />
      )
    }
  }

  return (
    <div>{StatusRender()}</div>
  )
}

export default Ssn;
