import React, { FC } from "react"
import "./AlertBackgroundMessage.scss"
import { AlertBackgroundMessageProps } from "./AlertBackgroundMessage.props"
import IconsSvg from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import { COLORS_NEW } from "../../types/Colors"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { AlertBackgroundTypes } from "../../../../util/IdentityProtectionHelper"

const GAlertBackgroundMessage: FC<AlertBackgroundMessageProps> = ({ message, status }) => {
  const getBackgroundColor = () => {
    switch (status) {
      case AlertBackgroundTypes.SUCCESS:
        return COLORS_NEW.GREEN_300
      case AlertBackgroundTypes.ERROR:
        return COLORS_NEW.RED_300
      case AlertBackgroundTypes.WARNING:
        return COLORS_NEW.YELLOW_300
      default:
        return COLORS_NEW.GREEN_300
    }
  }

  const getIcon = () => {
    switch (status) {
      case AlertBackgroundTypes.SUCCESS:
        return <IconsSvg type={ICONS_SVG_TYPE.ICON_CHECK_CIRCLE} width={24} height={24} />
      case AlertBackgroundTypes.ERROR:
        return <IconsSvg type={ICONS_SVG_TYPE.ICON_SVG_ERROR} width={24} height={24} />
      case AlertBackgroundTypes.WARNING:
        return <IconsSvg type={ICONS_SVG_TYPE.ICON_SVG_ALERT} width={24} height={24} />
      default:
        return <IconsSvg type={ICONS_SVG_TYPE.ICON_CHECK_CIRCLE} width={24} height={24} />
    }
  }
  return (
    <div className={"GAlertBackgroundMessage"} style={{ background: getBackgroundColor() }}>
      <div className={"GAlertBackgroundMessage__Icon"}>{getIcon()}</div>
      <GText text={message} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_1000} />
    </div>
  )
}

export default GAlertBackgroundMessage
