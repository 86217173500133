import React, { ChangeEvent, useRef, FC } from "react"
import { useDispatch } from "react-redux"
import { sentPhotoToBack } from "../../../../Redux/store/upload/upload.actions"
import { BasicBlockContainerProps } from "../UploadPhotoService/interfaces/interfaces"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

const UploadPhotoSubmitButton: FC<BasicBlockContainerProps> = ({ item: { className, text }, arrayComponents }) => {
  const ref: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()

  const handlerOnClickSubmitPhoto = (inputElement: React.RefObject<HTMLInputElement>) => inputElement.current?.click()

  const handlerChangeUploadPhoto = ({ target: { files } }: ChangeEvent<HTMLInputElement>) =>
    files?.length && dispatch(sentPhotoToBack(files[0]))

  return (
    <>
      <ButtonComponent className={className} text={text} handlerOnClick={() => handlerOnClickSubmitPhoto(ref)} />
      <input
        ref={ref}
        type={"file"}
        className={`${className}__input-file`}
        onChange={(e) => handlerChangeUploadPhoto(e)}
        style={{ height: `0`, width: `0` }}
      />
    </>
  )
}

export default UploadPhotoSubmitButton
