import React, {useEffect, useState} from 'react';
import {Divider, Form, Radio} from 'antd';
import SubscriptionPaymentItem, {SubscriptionPaymentItemProps} from "../../SubscriptionPaymentForm/SubscriptionPaymentForm/SubscriptionPaymentItem";
import GText from "../../atomicDesign/atoms/Text/Text";
import {SIZE} from "../../atomicDesign/types/types";
import {COLORS, COLORS_NEW} from "../../atomicDesign/types/Colors";
import './style.scss';
import {COMBINED_PAYMENT_METHOD_APPLICATION_ONLY} from "../../../shared/constans/payment";

interface SubscriptionPriceInformationProps extends SubscriptionPaymentItemProps {
  id: number;
}

interface CombinedPaymentProps {
  priceInformation: any;
  subscriptionPriceInformation: SubscriptionPriceInformationProps[];
  service?: string;
  setSelectedPlan: any;
  children: any;
}

const CombinedPaymentForm: React.FC<CombinedPaymentProps> = ({
  priceInformation,
  subscriptionPriceInformation,
  service,
  setSelectedPlan,
  children
}) => {
  const [activeId, setActiveId] = useState(1)

  const {content: priceInformationContent} = priceInformation;

  useEffect(() => {
    setSelectedPlan({method: priceInformation?.content?.method, price:priceInformation?.content?.price})
  }, []);

  const onRadioChange = (selectedData: string) => {
    const selectedRow = subscriptionPriceInformation.find((priceInfo) => priceInfo.method === selectedData);
    const price = selectedRow?.yearPrice ? selectedRow?.yearPrice : selectedRow?.price;
    setSelectedPlan({method: selectedRow?.method || selectedData, price: Number(price || priceInformationContent?.price)});
  };

  return (
    <div className={'combined-payment-form'}>
      <Form.Item
        name="period"
        rules={[
          {required: true, message: "Please select your payment option"}
        ]}
        initialValue={COMBINED_PAYMENT_METHOD_APPLICATION_ONLY}
      >
        <Radio.Group
          onChange={(e) => onRadioChange(e.target.value)}
          className={"payment-method-information"}
          ref={(el) => {
              el?.style?.setProperty('display', 'flex', 'important')
          }}
        >
          <li
            key={0}
            className={'payment-method-information__item'}
          >
            <SubscriptionPaymentItem
              price={priceInformationContent.price}
              method={priceInformationContent.method}
              saleLabel={priceInformationContent.offer}
              salePrice={priceInformationContent.savePrice}
              saleName={service}
              showPeriodSuffix={false}
            />
            <div className={'payment-footer'}>
              <GText
                text={`Government fees not included`}
                size={SIZE.PARAGRAPH_REGULAR_16}
                color={COLORS.SLATE_GRAY_500}
              />
            </div>
          </li>

          {/* <div className={'payment-method-information-about'}>
            <div className={'payment-method-information-about__divider'}>
              <Divider className={'payment-method-information-about__divider__line'} />
              <GText text={'OR'} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} style={{minWidth: 30}}/>
              <Divider className={'payment-method-information-about__divider__line'} />
            </div>
            {children}
          </div> */}

          {subscriptionPriceInformation && subscriptionPriceInformation.map(({id, price, method, saleLabel, salePrice, yearPrice}) => (
            <li
              key={id}
              onClick={() => setActiveId(id)}
              className={'payment-method-information__item'}
            >
              <SubscriptionPaymentItem
                price={price}
                method={method}
                saleLabel={saleLabel}
                salePrice={salePrice}
                yearPrice={yearPrice}
                showBestPriceLabel={method === 'yearly'}
              />
            </li>
          ))}
        </Radio.Group>
      </Form.Item>
      {/* <div className={'payment-footer'}>
          <GText
              text={`Government fees not included`}
              size={SIZE.PARAGRAPH_REGULAR_16}
              color={COLORS.SLATE_GRAY_500}
          />
      </div> */}
    </div>
  );
};

export default CombinedPaymentForm;
