import { put, select } from "redux-saga/effects"
import { setNewPrefilledObject, setSubmitFormError, setSubmitObject } from "./form.actions"
import { setArraySteps, setFormArraySteps } from "../step/step.actions"
import { getToolsForForms } from "../step/service.prefilled.data"
import FlattenDataDto from "../../../dto/FLattenDataDto/FlattenDataDto";

interface paymentErrorInterface {
  nameError: string;
  nameCorrespondField: string[];
}

type arrayPaymentErrorInterfaceType = paymentErrorInterface[]

export const arrayPaymentError = [
  {
    nameError: "Invalid Account Number",
    nameCorrespondField: ["card_no"],
  },
  {
    nameError: "Wrong Credit Card Number",
    nameCorrespondField: ["card_no"],
  },
  {
    nameError: "Wrong Name on Card",
    nameCorrespondField: ["f_name_pay, l_name_pay"],
  },
  {
    nameError: "Account Number Does Not Match Payment Type",
    nameCorrespondField: ["card_no"],
  },
  {
    nameError: "Duplicated Payment",
    nameCorrespondField: ["any_field"],
  },
  {
    nameError: "Duplicated Payment.",
    nameCorrespondField: ["any_field"],
  },
  {
    nameError: "Wrong CVV Code",
    nameCorrespondField: ["ssc"],
  },
  {
    nameError: "We had found an error processing your payment. Please try again",
    nameCorrespondField: ["any_field"],
  },
  {
    nameError: "Postal code and address match",
    nameCorrespondField: ["any_field"],
  },
  {
    nameError: "AVS service not supported by issuer",
    nameCorrespondField: ["any_field"],
  },
  {
    nameError: "AVS system not available",
    nameCorrespondField: ["any_field"],
  },
  {
    nameError: "Address unavailable",
    nameCorrespondField: ["any_field"],
  },
  {
    nameError: "AVS not performed",
    nameCorrespondField: ["any_field"],
  },
  {
    nameError: "Your address does not match your credit card, please correct your billing address",
    nameCorrespondField: ["adress_pay"],
  },
  {
    nameError: "Wrong Billing Address",
    nameCorrespondField: ["adress_pay"],
  },
  {
    nameError: "Your ZIP code does not match your credit card, please correct your billing address",
    nameCorrespondField: ["zip_pay"],
  },
  {
    nameError: "Wrong Billing Zipcode",
    nameCorrespondField: ["zip_pay"],
  },
  {
    nameError: "Your address and ZIP code do not match your credit card, please correct your billing address",
    nameCorrespondField: ["adress_pay", "zip_pay"],
  },
  {
    nameError: "Invalid Payment Type",
    nameCorrespondField: ["any_field"],
  },
  {
    nameError: "Wrong Expiration Date",
    nameCorrespondField: ["expiration_date-month", "expiration_date-year"],
  },
  {
    nameError: "Your transaction has been declined",
    nameCorrespondField: ["any_field"],
  },
]

type getCorrespondFieldByErrorPaymentType = (
  nameError: string,
  arrayPaymentError: arrayPaymentErrorInterfaceType
) => string[] | null

export const getCorrespondFieldByErrorPayment: getCorrespondFieldByErrorPaymentType = (nameError, arrayPaymentError) =>
  arrayPaymentError.find(({ nameError: nameErrorObject }) => nameErrorObject === nameError)?.nameCorrespondField ?? null

export const clearSubmitByEmptyProp = (submitObject: { [key: string]: any }) => {
  const clearedObject: { [key: string]: any } = []
  for (let keySubmitObject in submitObject) {
    if (submitObject[keySubmitObject] !== "" && submitObject[keySubmitObject] !== "Select a State") {
      clearedObject[keySubmitObject] = submitObject[keySubmitObject]
    }
  }
  return clearedObject
}

export function* removeEmailInJson(success: {} | any) {
  // @ts-ignore
  const store = yield select((s: any) => s)
  // @ts-ignore
  const product = yield sessionStorage.getItem("formNameForEvent") || ""
  if (
    success.email_delete_response &&
    product !== "EIN" &&
    store.form.tagStoreWithFields[FlattenDataDto.VAR_EMAIL]
  ) {
    const { "personal.communication.email": email, ...newTagStoreWithFields } = yield store.form.tagStoreWithFields
    const { initialArraySteps } = yield store.step
    yield put(setNewPrefilledObject(newTagStoreWithFields))
    yield sessionStorage.setItem("tagPrefilledFields", JSON.stringify(newTagStoreWithFields))
    const { arrayStepsAfterPrefilled, prefilledStore } = yield getToolsForForms({
      arraySteps: initialArraySteps,
      flattenData: newTagStoreWithFields,
      prefilledStore: {},
      product
    })
    yield put(setFormArraySteps(arrayStepsAfterPrefilled))
    yield put(setArraySteps(arrayStepsAfterPrefilled))
    yield put(setSubmitObject(prefilledStore))
  }
  yield put(setSubmitFormError(success?.user_exists))
  throw success?.user_exists?.message
}
