import React, {FC} from "react";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS_NEW} from "../../types/Colors";
import "./ApplicationCardTemplate.scss";
import IconsSvg from "../../atoms/IconsSvg/IconsSvg";
import {PREPAID_TEXT, PREPAID_TOOLTIP, PrepaidProduct} from "../../../../util/ProductHelper";
import {SolutionNames} from "../../../../util/SolutionNames";
import GTooltip from "../../atoms/Tooltip/Tooltip";
import GTag from "../../molecules/Tag/GTag";

type ApplicationCardProps = {
    image: string,
    productName: string,
    children: React.ReactNode,
    iconType?: string,
    iconColor?: string,
    iconBackground?: string,
    iconSize?: number,
    applicationText?: string,
    HeaderContent?: FC<any>,
    prepaid?: PrepaidProduct | false
}

const GApplicationCardMobile = ({
    image,
    productName,
    iconType,
    children,
    iconColor,
    iconSize = 27.64,
    iconBackground,
    applicationText,
    HeaderContent,
    prepaid = false
}: ApplicationCardProps) => {

    const getTitleAndProgressBar = () => {
        let prepaidTooltip = "";
        if(prepaid) {
            const key = prepaid?.productId;
            prepaidTooltip = prepaid ? PREPAID_TOOLTIP
                .replace("{solution}", SolutionNames[key as keyof typeof SolutionNames])
                .replace("{date}", prepaid.datePaid) : "";
        }

        return (
            <>
                <GText text={productName} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} />

                {prepaid && (
                    <GTooltip text={prepaidTooltip}>
                        <div>
                            <GTag
                                text={PREPAID_TEXT}
                                sizeText={SIZE.PARAGRAPH_BOLD_14}
                                colorBackground={COLORS_NEW.YELLOW_200}
                                colorText={COLORS_NEW.YELLOW_700}
                                tooltip={true}
                            />
                        </div>
                    </GTooltip>
                )}
            </>
        )
    }

    return (
        <div className={"GApplicationCardMobile"}>
          <div className={"GApplicationCardMobile__Header"}>
            <section className={"GApplicationCardMobile__Header__ImageHeader"}>
                {iconType && <IconsSvg type={iconType} width={iconSize} height={iconSize} color={iconColor} backgroundColor={iconBackground}/>}
                <img src={image} />
            </section>
            <section className={"GApplicationCardMobile__Header__Title"}>
                {
                    getTitleAndProgressBar()
                }
                  {applicationText && <GText text={applicationText} innerHtml={applicationText} size={SIZE.PARAGRAPH_REGULAR_14} color={COLORS_NEW.BLACK_800} />}
                  {HeaderContent && <HeaderContent />}
              </section>
          </div>
          {children && <div className={"GApplicationCard__Content"}>
              <section className={"GApplicationCard__Content__Options"}>
                  {children}
              </section>
          </div>}
        </div>
    )
}

export default GApplicationCardMobile