import { combineReducers } from "redux"
import { stepReducer } from "./store/step/step.reducer"
import { formReducer } from "./store/form/form.reducer"
import { selectReducer } from "./store/select/select.reducer"
import { additionalReducer } from "./store/additionalForm/additional.reducer"
import { loadingReducer } from "./store/loading/loading.reducer"
import { packageReducer } from "./store/packagePages/packagePages.reducer"
import { emailReducer } from "./store/email/email.reducer"
import { cashReducer } from "./store/cash/cash.reducer"
import { validationReducer } from "./store/validation/validation.reducer"
import { uploadReducer } from "./store/upload/upload.reducer"
import { userReducer } from "./store/user/user.reduser"
import { billingReducer } from "./store/billing/billing.reducer";

export default combineReducers({
  step: stepReducer,
  form: formReducer,
  select: selectReducer,
  additional: additionalReducer,
  loading: loadingReducer,
  package: packageReducer,
  email: emailReducer,
  cash: cashReducer,
  validation: validationReducer,
  upload: uploadReducer,
  user: userReducer,
  billing: billingReducer,
})
