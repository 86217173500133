import {ProductsIds} from "./ProductsIds";
export enum SolutionIds {
    PAS = "PAS",
    CER = "CER",
    EIN = "EIN",
    SSN = "SSN",
    COA = "COA",
    TSA = "TSA",
    UNDEFINED = "undefined"
}

export enum SolutionNames {
    PAS = "Passport",
    CER = "Birth certificate",
    EIN = "EIN number",
    SSN = "Social Security card",
    COA = "Address change",
    TSA = "TSA PreCheck®",
    UNDEFINED = "undefined"
}

export enum SolutionNamesForAdditionalSteps {
    PAS = "passport",
    CER = "birth certificate",
    EIN = "EIN number",
    SSN = "Social Security card",
    COA = "address change",
    TSA = "TSA PreCheck®",
    UNDEFINED = "undefined"
}

export function  getSolutionNameByProductId(productId: string | undefined): SolutionNames {
    switch (productId) {
        case ProductsIds.PassportId:
            return SolutionNames.PAS;
        case ProductsIds.BirthCertificateId:
            return SolutionNames.CER;
        case ProductsIds.ChangeAddressId:
            return SolutionNames.COA;
        case ProductsIds.EinId:
            return SolutionNames.EIN;
        case ProductsIds.SSNId:
            return SolutionNames.SSN;
        case ProductsIds.TsaId:
            return SolutionNames.TSA;
        default:
            return SolutionNames.UNDEFINED;
    }
}

export function getSolutionNameToLowerCase(solutionName: string) {
    let lowerCase = solutionName?.toLowerCase()
    lowerCase = lowerCase.replaceAll('ein', 'EIN')
    lowerCase = lowerCase.replaceAll('ssn', 'SSN')
    lowerCase = lowerCase.replaceAll('social security', 'Social Security')
    lowerCase = lowerCase.replaceAll('tsa precheck', 'TSA PreCheck')
    return lowerCase
}

export function getNewFormIdFromSolutionId(solution: string) {
    switch (solution) {
        case SolutionIds.PAS:
            return "passport-new"
        case SolutionIds.TSA:
            return "precheck-new"
        case SolutionIds.SSN:
            return "social-security-card-new"
        case SolutionIds.CER:
            return "vital-records-change"
        case SolutionIds.EIN:
            return "ein-new"
        default:
            return null;
    }
}

export function capitalizeFirstLetter(text: string) {
    return text[0].toUpperCase() + text.substring(1)
}