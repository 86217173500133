export const UploadSagaHelper = {
    addApplicationIdToPictureName: (appId: string | null, photo: any) => {
        let result = photo.name
        if(appId!=='' && appId!==null)
        {
            const parts = photo.name.split('.');
            const lastPart = parts.pop();
            const modifiedString = appId+'.'+lastPart;
            result = modifiedString;
        }
        return new File([photo], result, {
            type: photo.type,
            lastModified: photo.lastModified,
        });
    },
}