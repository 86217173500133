import React, { FC } from "react"
import BasicBlockContainer from "../UploadPhoto/MainBlockContainer/BasicBlockContainer"
import { arrayUploadPhotoComponents } from "../UploadPhoto/UploadPhotoService/datas/arrayUploadPhotoComponents"
import { BasicBlockContainerProps } from "../UploadPhoto/UploadPhotoService/interfaces/interfaces"

const ReviewPageContainer: FC<BasicBlockContainerProps> = ({ item }) => {
  return <BasicBlockContainer item={item} arrayComponents={arrayUploadPhotoComponents} />
}

export default ReviewPageContainer
