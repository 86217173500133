export const getParams = (hrefWithParams, params) => {
  const data = new URLSearchParams(hrefWithParams);
  let returnedObject = {};
  if (hrefWithParams) {
    params.forEach((param, id) =>{
      returnedObject[params[id]] = data.get(param)
    })
    return returnedObject;
  }
}

export const checkEmailRegEx = (email) =>{
  return new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+\.[a-zA-Z]{2,3}$/).test(email);
};
