import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

export const CATEGORY = "Category"

class CategoryRepository extends BaseSessionStorageRepository {
    private static _instance: CategoryRepository;

    private constructor() {
        super();
    }

    public static getInstance(): CategoryRepository {
        if (!CategoryRepository._instance) {
            CategoryRepository._instance = new CategoryRepository();
        }
        return CategoryRepository._instance;
    }

    getKeyName(): string {
        return CATEGORY;
    }

    getValue(): string | null {
        return super.getValue() ?? null;
    }
}

export default CategoryRepository;