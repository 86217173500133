import {IconSvg} from "../svgImages/SvgImages";
import "./index.less";
import React from "react";
import GoogleMapReact from "google-map-react";
import { env } from "../../env";

const GoogleMapComponent = ({lat, lng, message, defaultCenter}) =>{
  const MarkerComponent = ({message}) => <div><IconSvg type={"mapMarker"}/> {message}</div>;
  const googleApiKey = env.REACT_APP_GOOGLE_API_KEY;
  return(
    <div className={"googleMapContainer"}>
      <GoogleMapReact
        bootstrapURLKeys={{key: googleApiKey}}
        defaultCenter={defaultCenter}
        defaultZoom={17}
      >
        <MarkerComponent
          lat={lat}
          lng={lng}
          message={message}
        />
      </GoogleMapReact>
    </div>
  )
}

export default GoogleMapComponent;
