import "./Radio.scss"
import {RadioProps} from "./RadioProps";
import React, {FC} from "react";

const GRadio: FC<RadioProps> = ({selected, isHighlighted}) => {
    return (
        <div className={`GRadio ${selected ? 'selected' : ''} ${isHighlighted ? 'highlighted' : ''}`}>
            <div className={'point'}></div>
        </div>
    )
}
export default GRadio;