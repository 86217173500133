import React, {FC} from "react";
import './PostTrackingCard.scss'
import GText from "../../../../../atoms/Text/Text";
import {SIZE} from "../../../../../types/types";
import {
  formatLocation,
  getExpectedDeliveryAddress,
  ShipmentStatuses,
  showDeliveryStatus
} from "../../../../../../../util/ProcessingTrackingHelper";
import GAnimatedStatusBar from "../../../../../molecules/AnimatedStatusBar/AnimatedStatusBar";
import {COLORS_NEW} from "../../../../../types/Colors";
import {PostTrackingCardProps} from "./PostTrackingCard.props";
import {ICONS_SVG_TYPE} from "../../../../../atoms/IconsSvg/IconsSvgTypes";
import IconsSvg from "../../../../../atoms/IconsSvg/IconsSvg";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const GPostTrackingCard: FC<PostTrackingCardProps> = ({ postData, loading}) => {

  const breakPoint = useBreakpoint()

  if (postData?.error) {
    return <GText
        text={postData?.error?.message}
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
    />
  }

  const {trackingDetails, destinationLocation, estDeliveryDate, trackingNumber} = postData;
  const latestDetail = trackingDetails[trackingDetails.length - 1];
  const {status, message, dateTime, city, state} = latestDetail;
  return (
      <div className={'GPostTrackingCard'}>
        {loading && (
            <div className={'GPostTrackingCard__LoadingOverlay'}>
              <div className={'GPostTrackingCard__Preloader'}></div>
            </div>
        )}
        <div className={'GPostTrackingCard__Tracking'}>
          <div className={'GPostTrackingCard__Subheading'}>
            <GText
                text={'Your USPS tracking number is:'}
                size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14}
                color={COLORS_NEW.BLACK_800}
            />
            <b>&nbsp;</b>
            <GText
                text={trackingNumber}
                size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_16 : SIZE.PARAGRAPH_BOLD_14}
                color={COLORS_NEW.ROYAL_BLUE_700}
            />
          </div>
          <div className={'GPostTrackingCard__Progress'}>
            <GAnimatedStatusBar
                text={showDeliveryStatus(status)}
                status={status as ShipmentStatuses}
            />
          </div>
          <div className={'GPostTrackingCard__Blocks'}>
            <div className={'GPostTrackingCard__Blocks__Left'}>
              <div className={'GPostTrackingCard__Blocks__Content'}>
                <div className={'GPostTrackingCard__Blocks__Title'}>
                  <IconsSvg type={ICONS_SVG_TYPE.ICON_INFO} width={breakPoint.md ? 21 : 16} height={breakPoint.md ? 21 : 16}/>
                  <GText
                      text={'Status'}
                      size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_16 : SIZE.PARAGRAPH_BOLD_14}
                      color={COLORS_NEW.BLACK_1000}
                  />
                </div>
                <div className={'GPostTrackingCard__Blocks__Status'}>
                  <GText
                      text={showDeliveryStatus(status)}
                      size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16}
                      color={COLORS_NEW.GREEN_700}
                  />
                </div>
                <div className={'GPostTrackingCard__Blocks__Address'}>
                  <GText
                      text={dateTime}
                      size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14}
                      color={COLORS_NEW.BLACK_800}
                  />
                  <GText
                      text={message}
                      size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14}
                      color={COLORS_NEW.BLACK_800}
                  />
                  <GText
                      text={`${formatLocation(city, state)}`}
                      size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14}
                      color={COLORS_NEW.BLACK_800}
                  />
                </div>
              </div>
            </div>
            <div className={'GPostTrackingCard__Blocks__Right'}>
              <div className={'GPostTrackingCard__Blocks__Content'}>
                <div className={'GPostTrackingCard__Blocks__Title'}>
                  <IconsSvg type={ICONS_SVG_TYPE.ICON_BOX} width={breakPoint.md ? 21 : 16} height={breakPoint.md ? 21 : 16}/>
                  <GText
                      text={'Expected Delivery'}
                      size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_16 : SIZE.PARAGRAPH_BOLD_14}
                      color={COLORS_NEW.BLACK_1000}
                  />
                </div>
                <div className={'GPostTrackingCard__Blocks__Status'}>
                  <GText
                      text={estDeliveryDate || ""}
                      size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16}
                      color={COLORS_NEW.ROYAL_BLUE_700}
                  />
                </div>
                <div className={'GPostTrackingCard__Blocks__Address'}>
                  <GText
                      text={getExpectedDeliveryAddress(destinationLocation.zip, destinationLocation.street1, destinationLocation.street2)}
                      size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14}
                      color={COLORS_NEW.BLACK_800}
                  />
                  <GText
                      text={`${formatLocation(destinationLocation.city, destinationLocation.state)}`}
                      size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14}
                      color={COLORS_NEW.BLACK_800}
                  />
                  <GText
                      text={destinationLocation.country || ""}
                      size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14}
                      color={COLORS_NEW.BLACK_800}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default GPostTrackingCard