import React from "react"

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
  fillCircle?: boolean;
}

export const SvgDollarCircle= ({
  width = 65,
  height = 65,
  color = "#185EC8",
  backgroundColor = "#13BA68",
}: ISvgCheck) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill={backgroundColor}/>
      <path d="M14 7.24487V20.7536" stroke={color} strokeWidth="1.47368" strokeLinecap="round" strokeLinejoin="round"/>
      <path 
        d="M17.0698 9.70093H12.4646C11.8946 9.70093 11.3479 9.92735 10.9449 10.3304C10.5419 10.7334 10.3154 11.2801 10.3154 11.8501C10.3154 12.42 10.5419 12.9667 10.9449 13.3697C11.3479 13.7727 11.8946 13.9992 12.4646 13.9992H15.5347C16.1047 13.9992 16.6513 14.2256 17.0544 14.6286C17.4574 15.0317 17.6839 15.5783 17.6839 16.1483C17.6839 16.7183 17.4574 17.2649 17.0544 17.668C16.6513 18.071 16.1047 18.2974 15.5347 18.2974H10.3154" 
        stroke={color} 
        strokeWidth="1.47368" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <defs>
      <clipPath id="clip0_16803_194084">
        <rect width="14.7368" height="14.7368" fill={color} transform="translate(6.63184 6.63074)"/>
      </clipPath>
      <clipPath id="clip1_16803_194084">
        <rect width="14.7368" height="14.7368" fill={color} transform="translate(6.63184 6.63074)"/>
      </clipPath>
      </defs>
    </svg>

  )
}
