import React, { FC } from "react"
import { BasicBlockContainerProps } from "../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../App"
import {
  formSelector,
  uploadSelector,
} from "../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { saveStatusUploadPhoto } from "../../../Redux/store/upload/upload.actions"
import ButtonComponent from "../UploadPhoto/ButtonComponent/ButtonComponent"

const ReviewPageButtonSubmit: FC<BasicBlockContainerProps> = ({ item: { className, text } }) => {
  // const dispatch = useDispatch()

  // const { appId: client_token, formId: form_id } = useSelector<RootState, { [key: string]: any }>(formSelector)
  // const linkPhoto = useSelector<RootState, { linkPhoto: string }>(uploadSelector).linkPhoto

  const handlerOnClickSave = () => {
    // dispatch(saveStatusUploadPhoto({ linkPhoto, client_token, form_id }))
  }

  // console.log("handlerOnClickSave")

  return <ButtonComponent className={className} text={text} handlerOnClick={handlerOnClickSave} />
}

export default ReviewPageButtonSubmit
