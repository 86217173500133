import React, {useEffect, useState} from "react";
import "./ResetPassword.less";
import {Form} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
  hideMessage, resetPassword, showAuthMessage,
} from "../../appRedux/actions";
import Header from "../Header";
import {userService} from "../../api/api";
import { svgIconsVariables } from "../../components/svgImages/svgIconsVariables";
import {useRequirementsValidation} from "../../HOC/useRequirementsValidation";
import GSpinner from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Spinner/Spinner";
import GChangePassword
  from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/ChangePassword/ChangePassword";
import {notifyError} from "@dash/gov-plus__front-end__form/src/util/helper";
import GLogo from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Logo/Logo";

const ResetPasswordNextStep = () => {
  const dispatch = useDispatch();
  const {showMessage, alertMessage } = useSelector(({auth}) => auth);
  const [, setUiMessage] = useState("");
  const [, setErrClass] = useState(false);
  const [, setErrEmailClass] = useState(false);
  const [passValidationVisibleStatus, setPassValidationVisibleStatus] = useState(false);
  const [validationRequirements, setValidationRequirements] = useState(false)
  const [passLengthsStatus, passUpperStatus, passNumberStatus, passSymbolStatus, validator] = useRequirementsValidation()
  const [confirmPasswords, setConfirmPasswords] = useState(true);
  const [, setErrorClassName] = useState(false);
  const [resetToken, setResetToken] = useState("");
  const [resetTokenCheckLoader, setResetTokenCheckLoader] = useState(false);
  const [formValues, setFormValues] = useState({})

  const history = useHistory();
  const [form] = Form.useForm();

  const requirements = {
    length: {
      icon: true,
      status: passLengthsStatus,
      message: 'Use 8 or more characters'
    },
    upperCase: {
      icon: true,
      status: passUpperStatus,
      message: 'Use upper case letters (e.g. A)'
    },
    numbers: {
      icon: true,
      status: passNumberStatus,
      message: 'Use a number (e.g. 1234)'
    },
    symbols: {
      icon: true,
      status: passSymbolStatus,
      message: 'Use a symbol (e.g. !@#$)'
    }
  }

  useEffect(() => {
    if (alertMessage === "change-password.PASSWORD_SUCCESSFULLY_UPDATED"){
      history.push("/signin");
    }
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
  }, [alertMessage]);

  useEffect(async ()=>{
    const passwordResetToken = window.location.hash.split("=")[1];
    if(passwordResetToken){
      setResetToken(passwordResetToken)
      try {
        setResetTokenCheckLoader(true)
        await userService.checkResetToken(passwordResetToken)
        setResetTokenCheckLoader(false)
      }catch (err){
        setResetTokenCheckLoader(true)
        const {data} = err.response;
        if(data.messages === "change-password.RESET_TOKEN_NOT_FOUND" || data.messages === "common.RESET_TOKEN_EXPIRED"){
          history.push("/signin");
          dispatch(showAuthMessage({
            code: data.code,
            messageInfo: data.messageInfo,
            messages: data.messages
          }))
          setResetTokenCheckLoader(false)
        }
        notifyError(err);
      }
    }
  },[])

  const onFinish = values => {
    const {password} =values;
    if (passLengthsStatus === 'valid'
      && passUpperStatus === 'valid'
      && passNumberStatus === 'valid'
      && passSymbolStatus === 'valid'
      && confirmPasswords){
      dispatch(resetPassword({password, resetToken}));
    } else {
      setErrorClassName(true)
      if (confirmPasswords) {
        setPassValidationVisibleStatus(true)
      }
    }
  };

  const onChangePassword = (e) => {
    setFormValues(form.getFieldsValue())
    validator(e.target.value, 'onChange/onFocus')
  }

  const onFocusPassword = (e) => {
    setPassValidationVisibleStatus(false);
    setValidationRequirements(true)
    setConfirmPasswords(true)
    validator(e.target.value, 'onChange/onFocus')
  }

  const onChangeConfirm = () => {
    setFormValues(form.getFieldsValue())
    const formValuesLocal = form.getFieldsValue()
    setConfirmPasswords(formValuesLocal.password === formValuesLocal.confirm_password)
  }

  const onBlurPassword = (e) => {
    validator(e.target.value, 'onBlur')
    if (e.target.value.length === 0) {
      setValidationRequirements(false)
    } else {
      if (passLengthsStatus !== 'valid'
        || passUpperStatus !== 'valid'
        || passNumberStatus !== 'valid'
        || passSymbolStatus !== 'valid') {
        setPassValidationVisibleStatus(true)
      } else {
        setPassValidationVisibleStatus(false)
        setValidationRequirements(false)
      }
    }
    onChangeConfirm()
  }

 const iconRender = (visible) => {
    if (visible) {
      return svgIconsVariables.openedLock
    } else {
      return svgIconsVariables.closedLock
    }
  }

  const validationMessageRender = () => {
    if (!confirmPasswords && formValues.confirm_password.length === 0) {
      return 'Please confirm your password'
    } else {
      if (!confirmPasswords) {
        return "The passwords you entered don't match"
      } else {
        return ''
      }
    }
  }

  return (
    <div className="gx-app-login-wrap">
      <div className={'gx-app-login-logo'}>
        <GLogo width={110} height={28} />
      </div>
      {
        resetTokenCheckLoader
            ? <GSpinner />
            :
            <GChangePassword
                formOnFinish={onFinish}
                form={form}
                loader={resetTokenCheckLoader}
                formOnChange={()=>{
                  setErrEmailClass(false)
                  setErrEmailClass(false)
                  setErrClass(false)
                  setUiMessage("")
                }}
                passwordErrorMessage={passValidationVisibleStatus}
                onFocusPassword={onFocusPassword}
                onBlurPassword={onBlurPassword}
                onChangePassword={onChangePassword}
                validationRequirements={validationRequirements}
                requirements={requirements}
                onChangeConfirm={onChangeConfirm}
                confirmValidationMessage={validationMessageRender()}
            />
      }
    </div>
  );
};

export default ResetPasswordNextStep;
