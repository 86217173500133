export enum LoadingState {
  Loading = "loading",
  Loaded = "loaded",
  NotFound = "notFound",
  Error = "error",
}

export enum LoadingStateUploadPhoto {
  PreLoad = "preload",
  Loading = "loading",
  Loaded = "loaded",
  FormatError = "format_error",
  Completed = "completed",
  Error = "error",
  MaxSizeError = "More than 10 MB",
  BackendError = "backend_error",
  SavingImages = "saving_images",
}

export enum LoadingStateUploadDocument {
  Empty = "",
  PreLoad = "preload",
  Process = "process",
  Loading = "loading",
  Loaded = "loaded",
  Completed = "completed",
  FormatError = "format_error",
  Error = "error",
  BackendError = "BackendError",
  MaxSizeError = "More than 10 MB",
  WrongFormat = "Wrong format file",
  OnlyOnePage = "PDF must be with one page",
  UploadDocument = "Please upload a document",
  NETWORK_ERROR = "Network Error",
}
