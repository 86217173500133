import {
  CHECK_STATUS,
  CHECK_STATUS_SUCCSSES,
  CHECK_STATUS_ERR
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  errMessage: ""
};

const CheckUserStatusReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHECK_STATUS: {
      return {
        ...state,
        loader: true,
      }
    }
    case CHECK_STATUS_SUCCSSES: {
      return {
        ...state,
        loader: false,
      }
    }
    case CHECK_STATUS_ERR: {
      return {
        ...state,
        loader: false,
        errMessage: action.errMessage
      }
    }
    default:
      return state;
  }
}

export default CheckUserStatusReducer;
