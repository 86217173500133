import React, { FC } from "react"
import "./DriverLicenseRecord.scss"
import { RecordContentProps } from "../../../../../util/IdentityProtectionHelper"
import GText from "../../../atoms/Text/Text"
import { COLORS_NEW } from "../../../types/Colors"
import { SIZE } from "../../../types/types"

const DriverLicenseRecord: FC<RecordContentProps> = ({ ValueToMonitor, currentUserName }) => {
  return (
    <div className={"DriverLicenseFormRecordContent"}>
      <GText text={ValueToMonitor as string} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} />
      <GText
        text={currentUserName?.firstName + " " + currentUserName?.lastName}
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
    </div>
  )
}

export default DriverLicenseRecord
