import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

const USER_TAX_URL = "user_tax_url"

class UserTaxUrlRepository extends BaseSessionStorageRepository {
    private static _instance: UserTaxUrlRepository;

    private constructor() {
        super();
    }

    public static getInstance():UserTaxUrlRepository {
        if (!UserTaxUrlRepository._instance) {
            UserTaxUrlRepository._instance = new UserTaxUrlRepository();
        }
        return UserTaxUrlRepository._instance;
    }

    getKeyName(): string {
        return USER_TAX_URL;
    }
}

export default UserTaxUrlRepository;
