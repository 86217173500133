import { FC } from "react"
import { SIZE } from "../../types/types"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import React from "react"
import GText from "../../atoms/Text/Text"
import "./GImageTitle.scss"

export interface IGImageTitle {
  title: string;
  titleSize?: string;
  titleColor?: string;
  image: string;
  imageStyle?: any;
}

const GImageTitle: FC<IGImageTitle> = ({
  title,
  titleSize = SIZE.HEADING_LG,
  titleColor = COLORS_NEW.BLACK_1000,
  image,
  imageStyle ,
}) => {

  return (
    <div className="GImageTitle">
      <GText text={""} innerHtml={title} size={titleSize} color={titleColor} />
      <img className="image" src={image} style={imageStyle} alt={""} />
    </div>
  )
}

export default GImageTitle
