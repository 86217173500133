import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from './registerServiceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {ErrorBoundary} from "@sentry/react";
import DashboardAppContainer from "./DashboardAppContainer";
import ErrorLog from "@dash/gov-plus__front-end__form/src/services/ErrorLog/ErrorLog";
import { env } from "./env";

ErrorLog.getInstance(env.REACT_APP_SENTRY_DSN, env.NODE_ENV);

ReactDOM.render(
    <ErrorBoundary>
        <DashboardAppContainer/>
    </ErrorBoundary>, document.getElementById('root'));

serviceWorker.unregister();
