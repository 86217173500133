import {createContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    checkProductForDuplicate,
    getFellingPresent,
    getProducts,
    setSelectedFormId,
    showModalsType
} from "../appRedux/actions";
import {IInsuranceClaim} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Models";
import { useApplicationServices } from "../services/ApplicationServices";
import { Product } from "../constants/ProductsTypes";
import {postTrackingApis} from "../api/api";

interface IApplicationsContext {
    checkSameApplication: (selectedApplication: IInsuranceClaim, startApplicationAfterCheck?: boolean, callback?: () => void) => void,
    filterProducts: () => void,
    filteredProducts: Object,
    availableProducts: Product[],
    getPostTrackingData: (authToken: string, applicationId: string, toCustomer: boolean) => Promise<any>,
    loading: boolean,
    postData: any,
    getProductsInfo: () => void,
    allProductsLoader: boolean
}

// @ts-ignore
export const ApplicationsContext = createContext<IApplicationsContext>()

// @ts-ignore
export const ApplicationsProvider = ({ children }) => {
    const dispatch = useDispatch()
    const {startApplication, filterApplications} = useApplicationServices()
    // @ts-ignore
    const {duplicateApplications, allProducts, anotherGovProducts, allProductsLoader} = useSelector(({allProducts}) => allProducts);

    // @ts-ignore
    const {authToken} = useSelector(({auth}) => auth);

    const [filteredProducts, setFilteredProducts] = useState(allProducts)
    const [loading, setLoading] = useState(false);
    const [postData, setPostData] = useState();

    const getPostTrackingData = async (authToken: string, applicationId: string, toCustomer = true) => {
        setLoading(true);

        postTrackingApis.postTrackingData(authToken, applicationId, toCustomer)
            .then((response) => {
                const postData = response.data
                setPostData(postData)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
            })
    };

    const filterProducts = () => {
        const filteredProducts = filterApplications()
        setFilteredProducts(filteredProducts)
    }

    const checkSameApplication = (selectedApplication: IInsuranceClaim, startApplicationAfterCheck?: boolean, callback?: () => void, ) => {
        const selectedForm = selectedApplication.formId
        sessionStorage.setItem("Solution", selectedApplication.solution)
        if (typeof selectedForm === "string") {
            sessionStorage.setItem("Service", selectedForm)
            sessionStorage.setItem("Category", selectedForm)
        }
        dispatch(setSelectedFormId(selectedForm))

        // @ts-ignore
        if (!Object.keys(duplicateApplications).includes(selectedForm)) {
            dispatch(checkProductForDuplicate({
                client_token: authToken,
                form_id: selectedForm,
                array: duplicateApplications,
                callback: (array: any) => {
                    // @ts-ignore
                    if (Object.keys(array).includes(selectedForm)) {
                        dispatch(showModalsType('duplicateApplication'))
                        callback?.()
                    } else if (startApplicationAfterCheck && selectedForm) {
                        startApplication(selectedForm)
                        callback?.()
                    }
                }
            }))
        } else {
            setTimeout(() => {
                dispatch(showModalsType('duplicateApplication'))
                callback?.()
            }, 400)
        }
    }
    const getProductsInfo = () => {
        if (authToken) {
            dispatch(getProducts(authToken));
            dispatch(getFellingPresent())
        }
    }
    useEffect(filterProducts, [allProducts, window.location.pathname])

    useEffect(getProductsInfo, [authToken, window.location.pathname]);

    const returnValue = {
        checkSameApplication,
        filterProducts,
        filteredProducts,
        availableProducts: anotherGovProducts,
        getPostTrackingData,
        postData,
        loading,
        getProductsInfo,
        allProductsLoader
    }

    return <ApplicationsContext.Provider value={returnValue}>
        {children}
    </ApplicationsContext.Provider>
}