import AuthUserBearerTokenRepository from "../../../persistence/session/AuthUserBearerTokenRepository";
import ClientTokenRepository from "../../../persistence/cookies/ClientTokenRepository";
import UserTaxUrlRepository from "../../../persistence/session/UserTaxUrlRepository";
import {getClientTokenForResponse} from "../step/service.step.saga";
import ApplicationIdRepository from "../../../persistence/session/ApplicationIdRepository";
import FormIdRepository from "../../../persistence/session/FormIdRepository";

export const AdditionalSagaHelper = {
    setAuthBearerToken: (token: string) => {
        AuthUserBearerTokenRepository.getInstance().setValue(token);
    },
    setClientToken: (token: string) => {
        ClientTokenRepository.getInstance().setValue(token);
    },
    removeClientToken: () => {
        ClientTokenRepository.getInstance().removeItem();
    },
    setUserTaxUrl: (value: string) => {
        UserTaxUrlRepository.getInstance().setValue(value);
    },
    getTaxSubmitObj: () => {
        const {client_token} = getClientTokenForResponse()
        const application_id = ApplicationIdRepository.getInstance().getValue();
        const form_id = FormIdRepository.getInstance().getValue()

        return {client_token, application_id, form_id};
    }
}