/* eslint-disable */
import { connect } from "react-redux"

type AddressBlockComponentProps = {
  item: {} | any,
  billingAddress: {} | any,
  item2: string,
}

type ValidatedPropTypes = {
  verification: string,
  item: string,
  item2: string | any,
}

const AddressBlockComponent: React.FunctionComponent<AddressBlockComponentProps> = ({ item, billingAddress }) => {
  const ValidatedItem: React.FC<ValidatedPropTypes> = ({ verification, item, item2 }) => {
    const source = billingAddress?.[verification]?.address_verified

    let values: any =
      source?.[item] || source?.[item2]
        ? {
            address1: "Address 1: ",
            address2: "Address 2: ",
            zip_code: "ZIP Code: ",
            city: "City: ",
            state: "State: ",
          }
        : {
            address1: "Address 1: ",
            address2: "Address 2: ",
            zipcode: "ZIP Code: ",
            city_name: "City: ",
            state_code: "State: ",
          }

    return (
      <>
        <div className={"address_info__container__infoBlock"}>
          <div className={"address_info__container_label"}>{values[item]}</div>
          <div className={"address_info__container_info"}>{source?.[item] || source?.[item2]}</div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="address_change__block_adress">
        <h3 className="address_change__block_type">Old Address</h3>
        <div className={"old_address__container"}>
          <div className={"address_info__container"}>
            <ValidatedItem verification={"old_address_verification"} item={"address1"} item2={"address1"} />
            <ValidatedItem verification={"old_address_verification"} item={"address2"} item2={"address2"} />
            <ValidatedItem verification={"old_address_verification"} item={"zip_code"} item2={"zipcode"} />
            <ValidatedItem verification={"old_address_verification"} item={"city"} item2={"city_name"} />
            <ValidatedItem verification={"old_address_verification"} item={"state"} item2={"state_code"} />
          </div>
        </div>
      </div>

      <div className="address_change__block_adress">
        <h3 className="address_change__block_type">New Address</h3>
        <div className={"old_address__container"}>
          <div className={"address_info__container"}>
            <ValidatedItem verification={"new_address_verification"} item={"address1"} item2={"address1"} />
            <ValidatedItem verification={"new_address_verification"} item={"address2"} item2={"address2"} />
            <ValidatedItem verification={"new_address_verification"} item={"zip_code"} item2={"zipcode"} />
            <ValidatedItem verification={"new_address_verification"} item={"city"} item2={"city_name"} />
            <ValidatedItem verification={"new_address_verification"} item={"state"} item2={"state_code"} />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  billingAddress: state.form.billingAddress,
})

export default connect(mapStateToProps)(AddressBlockComponent)
