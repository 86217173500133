// @ts-nocheck

import React, {FC, useEffect, useRef, useState} from "react";
import {PaymentRequestTokenEvent} from "@stripe/stripe-js";
import {PriceInfo} from "../../types/types";
import {connect, useDispatch} from "react-redux";
import Service from "../../../../services/form-service";
import {useSetupBraintreeGooglePay} from "./useSetupBraintreeGooglePay";
import './BraintreePaymentButtonContainer.scss';
import DomElementsHelper from "../../../../util/DomElementsHelper";
import {buildPaymentError, PaymentError} from "../../../../dto/PaymentError";
import {setPaymentError} from "../../../../Redux/store/form/form.actions";
import {useSetupBraintreeApplePay} from "./useSetupBraintreeApplePay";
import {LoadingState} from "../../../../shared/constans/user-from-view-mode.enum";


type BraintreePaymentButtonProps = {
    onTokenCreated?: (event: PaymentRequestTokenEvent, userCoupon: any) => Promise<void>;
    priceInfo: PriceInfo,
    marginButton?: number;
    userCoupon?: any;
    submitObject?: any;
    setError?: (paymentError: PaymentError | null) => void;
    loadingButtonState: string;
}

const BraintreePaymentButton: FC<BraintreePaymentButtonProps> = ({
                                                                     onTokenCreated,
                                                                     priceInfo,
                                                                     marginButton,
                                                                     userCoupon,
                                                                     submitObject,
                                                                     setError,
                                                                     loadingButtonState
                                                                 }) => {
    const refGPayButtonContainer = useRef(null);
    const refApplePayButtonContainer = useRef(null);
    const [authorization, setAuthorization] = useState<string | null>(null);
    const dispatch = useDispatch();

    const createClient = require('braintree-web/client').create;

    const isApplePaySupported = () => {
        // @ts-ignore
        const {ApplePaySession} = window;
        return ApplePaySession && ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments();
    }

    const dispatchPaymentError = (paymentError: PaymentError | null) => {
        dispatch(setPaymentError(paymentError));
    }

    const submitPaymentCallback = async (tokenId: string, walletName: string, session?: any, status?: any) => {
        if (onTokenCreated && tokenId) {
            onTokenCreated({
                walletName,
                // @ts-ignore
                token: {
                    id: tokenId
                },
                session,
                status
            }, userCoupon)
        }
    }

    const {
        setupBraintreeGooglePay,
        onClickGooglePayBtn
    } = useSetupBraintreeGooglePay(createClient, submitPaymentCallback, dispatchPaymentError, setError);

    const {
        setupBraintreeApplePay,
        getApplePayButton
    } = useSetupBraintreeApplePay(createClient, submitPaymentCallback, dispatchPaymentError, setError);

    useEffect(() => {
        Service.paymentGatewayApis({}).then((response) => {
            setAuthorization(response?.success?.token);
        });
    }, [])

    useEffect(() => {
        //@ts-ignore
        if (isApplePaySupported() && authorization) {
            setupBraintreeApplePay(authorization).then((applePayInstance: any) => {
                if (!applePayInstance) {
                    return;
                }
                DomElementsHelper.removeAllChildren(refApplePayButtonContainer?.current)
                refApplePayButtonContainer?.current?.appendChild(getApplePayButton(priceInfo, applePayInstance));
            });
        } else if (window?.PaymentRequest && authorization) {
            setupBraintreeGooglePay(authorization).then((button: any) => {
                if (button) {
                    DomElementsHelper.removeAllChildren(refGPayButtonContainer?.current)
                    refGPayButtonContainer?.current?.appendChild(button);
                }
            });
        }
    }, [priceInfo, userCoupon, authorization]);

    const customStyle: React.CSSProperties = {
        marginBottom: marginButton ? `${marginButton}px` : '0',
        ...(loadingButtonState === LoadingState.Loading) && { display: 'none' }
    }

    return (<>
        {
            isApplePaySupported() ?
                (
                    authorization &&
                    <div className={`BraintreeApplePayButtonContainer`} ref={refApplePayButtonContainer}
                         style={customStyle}/>
                ) : (
                    window?.PaymentRequest &&
                    <div className={`BraintreeGooglePayButtonContainer`} ref={refGPayButtonContainer} onClick={() => {
                        onClickGooglePayBtn(priceInfo)
                    }} style={customStyle}/>
                )
        }
    </>)
}

const mapStateToProps = (state: any) => {
    return {
        userCoupon: state.billing?.userCoupon,
        loadingButtonState: state?.loading?.loadingButtonState
    }
}

export default connect(mapStateToProps)(BraintreePaymentButton)