import React, { FC } from "react"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GAuthHeader from "../../molecules/AuthHeader/AuthHeader"
import "./AddPhone.scss"
import { Form } from "antd"
import GText from "../../atoms/Text/Text"
import {INPUT_STATUS, SIZE} from "../../types/types"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import GButton from "../../molecules/Button/GButton"
import GAuthFooter from "../../molecules/AuthFooter/AuthFooter"
import GFormItem, { FORM_ITEM_STATUSES } from "../../organisms/FormItem/FormItem"
import ReactInputMask from "react-input-mask"
import GInputText from "../../molecules/InputText/InputText";

type GAddPhoneProps = {
  formOnFinish: any,
  onFinishFailed: any,
  form: any,
  formType: string,
  formItemPhoneInitialValue: string,
  valShowMessage: any,
  onPhoneChange: any,
  onPhoneKeyPress: any,
  phoneMaxLength: number,
  maskPhone: any,
  history: any,
  loader: boolean,
  onFooterFirstLinkClick: any,
}

const TITLE = "Add Your Phone Number"

export enum ADD_PHONE_FORM_TYPE {
  PHONE_INTERNATIONAL = "phoneInternational",
  PHONE_USA = "phoneUSA",
}

const GAddPhone: FC<GAddPhoneProps> = ({
  formOnFinish,
  onFinishFailed,
  form,
  formType,
  formItemPhoneInitialValue,
  valShowMessage,
  onPhoneChange,
  onPhoneKeyPress,
  phoneMaxLength,
  maskPhone,
  history,
  loader,
  onFooterFirstLinkClick,
}) => {
  return (
    <div className={`GAddPhone`}>
      <GAuthHeader title={TITLE} />
      <Form
        initialValues={{ remember: true }}
        name={`basic`}
        onFinish={formOnFinish}
        onFinishFailed={onFinishFailed}
        className={`GAddPhone__Form`}
        layout={`vertical`}
        form={form}
      >
        {formType === ADD_PHONE_FORM_TYPE.PHONE_INTERNATIONAL && (
          <GFormItem
            label={
              <div className={`GAddPhone__Form__Item__Label`}>
                <GText text={`Phone number`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_1000} />
              </div>
            }
            name={formType}
            initialValue={formItemPhoneInitialValue}
            status={`${valShowMessage !== "" ? FORM_ITEM_STATUSES.ERROR : FORM_ITEM_STATUSES.DEFAULT}`}
            message={valShowMessage}
            rules={[{ required: true }]}
          >
            <GInputText
              type={`text`}
              inputMode={`tel`}
              onChange={onPhoneChange}
              onKeyPress={onPhoneKeyPress}
              maxLength={phoneMaxLength}
              inputStatus={`${valShowMessage !== "" ? INPUT_STATUS.ERROR : INPUT_STATUS.DEFAULT}`}
            />
          </GFormItem>
        )}

        {formType === ADD_PHONE_FORM_TYPE.PHONE_USA && (
          <GFormItem
            label={
              <div className={`GAddPhone__Form__Item__Label`}>
                <GText text={`Phone number`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_1000} />
              </div>
            }
            name={formType}
            initialValue={formItemPhoneInitialValue}
            status={`${valShowMessage !== "" ? FORM_ITEM_STATUSES.ERROR : FORM_ITEM_STATUSES.DEFAULT}`}
            message={valShowMessage}
          >
            <ReactInputMask inputMode={`tel`} type={`text`} onChange={onPhoneChange} mask={maskPhone}>
              <GInputText
                inputMode={"tel"}
                type={`text`}
                inputStatus={`${valShowMessage !== "" ? INPUT_STATUS.ERROR : INPUT_STATUS.DEFAULT}`}
              />
            </ReactInputMask>
          </GFormItem>
        )}

        <GText
          text={`Standard call, message, or data rates may apply`}
          size={SIZE.PARAGRAPH_REGULAR_14}
          color={COLORS_NEW.BLACK_900}
        />

        <div className={`GSignIn__Form__BtnContainer`}>
          <GButton
            loading={loader}
            text={`Access my dashboard`}
            textSize={SIZE.PARAGRAPH_BOLD_16}
            size={"large"}
            isSubmit={true}
          />
        </div>
        <GAuthFooter firstLinkText={`Go back`} firstLinkShowIcon={true} firstLinkClick={onFooterFirstLinkClick} />
      </Form>
    </div>
  )
}
export default GAddPhone
