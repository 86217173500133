import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

export const FORM_NAME_FOR_EVENT = "formNameForEvent"

class FormNameForEventRepository extends BaseSessionStorageRepository {
    private static _instance: FormNameForEventRepository;

    private constructor() {
        super();
    }

    public static getInstance(): FormNameForEventRepository {
        if (!FormNameForEventRepository._instance) {
            FormNameForEventRepository._instance = new FormNameForEventRepository();
        }
        return FormNameForEventRepository._instance;
    }

    getKeyName(): string {
        return FORM_NAME_FOR_EVENT;
    }

    getValue(): string | null {
        return super.getValue() ?? null;
    }
}

export default FormNameForEventRepository;