export enum InsuredDocumentsText {
    TeaserHeader = "Your documents. Protected.",
    TeaserSubtext = "Insure your most important documents against loss, damage, and theft. When an accident happens, you get an instant replacement.",
    GetInsuredButton = "Insure my government documents",
    ReplaceWithNoCost = "Insure your documents against lost, stolen, and damage so you can instantly replace them with GOV+.",
    MyInsuredDocuments = "My insured documents",
    InsuranceCancelHeader = "Are you sure you want to <br> lose document protection?",
    InsuranceCancelExplanation = "Without GOV+ Subscription, you'll no longer have insurance for your lost, " +
        "stolen and damaged<br> documents. If an accident happens, GOV+ won't instantly replace your documents- " +
        "<br>and you'll have to navigate the process yourself.",
}

export enum IdentityProtectionText {
    MyIdentityProtection = "My identity protection",
    TeaserHeader = "Government documents are a prime target for identity thieves. That’s why GOV+<br>actively monitors them and all your personal information for signs of<br>unauthorized use. You can rest easy knowing we’re on it.",
    IDPLandingSubheader = "Stay on top of any fraudulent activity associated<br>with your personal information. GOV+ has you covered.",
    IDPLandingSubheaderMobile = "Stay on top of any fraudulent activity associated with your personal information. GOV+ has you covered.",
    TeaserHeaderMobile = "Stay on top of any fraudulent activity associated with your personal information and government documents.",
    IdentityTheftProtectionModalHeader = "$1,000,000 identity protection<br> coverage",
    IdentityInsuranceCardLeft = "GOV+ helps you regain control of your good<br>name and finances after identity theft occurs.",
    IdentityInsuranceCardRight = "GOV+ subscription includes Identity Theft<br>Insurance with zero deductible. ",
    MyIdentityProtectionSubheader = "Stay on top of any fraudulent activity associated with your<br>personal information and government documents.",
    IdentityProtectionExplanation = "Without GOV+, your personal information will no longer be monitored for fraudulent activity,<br> leaving your government documents vulnerable. You’ll lose our $1 million identity theft<br> coverage — and become responsible for any expenses due to identity theft.",
}

export enum RenewalDocumentsText {
    MyAutomaticRenewals = "My automatic renewals",
    AutomaticRenewalSubHeader = "Never worry about expiration dates again. Get automatic renewal for all your documents before they expire.",
    TeaserHeader = "Your documents. Always up to date.",
    TeaserSubHeader = "With GOV+, your documents are automatically renewed before they expire. You never have to worry about getting stuck without a document again.",
    TeaserButton = "Automatically renew my documents",
    RenewalCancelHeader = "Are you sure you want to lose automatic renewal?",
    RenewalCancelExplanation = "Without GOV+, you’ll no longer have automatic renewal for your documents. " +
        "You’ll have to stay on top of your expiration dates — and run the risk of getting stuck without the documents you need."
    
}

export enum IdentityProtectionTeaserText {
    MyIdentityProtection = "My identity protection",
    TeaserSubHeader = "Government documents are a prime target for identity thieves. That’s why  <br> GOV+ actively monitors all your personal information for signs of <br> unauthorized use. You can rest easy knowing we’re on it.",
    TeaserHeader = "Your identity. Protected.",
    TeaserButton = "Protect my identity",
    IdentityProtectionCancelHeader = "Are you sure you want to <br> lose identity protection?",
    IdentityProtectionCancelExplanation = "Without GOV+, your personal information will no longer be monitored for fraudulent activity, <br> leaving your government documents vulnerable. You’ll lose our $1 million identity theft <br> coverage — and become responsible for any expenses due to identity theft."

}

export enum FamilyMembersText {
    TeaserHeader = "Family documents. In one place.",
    TeaserSubHeader = "File your whole family's applications under one account -- and stay organized with easy access to everyone's critical documents",
    TeaserButton = "Add my family members",
    MyFamily = "My family",
    FamilyMembersSubHeader = "Manage your whole family's government documents — from new applications to renewal and protection. Everyone's most important records are here when you need them.",
    AddMoreFamilyMembers = "Add more family members",
    FamilyMemberNewApplicationSubHeader = "We've already done most of the work for you. Select an application " +
        "to add a few more required details. Don't worry -- you'll never fill out the same information twice",
    AddFamilyMembersHeader = "Add family members to your GOV+ account",
    AddFamilyMembersSubscriptionPrice = "Add one or more family members to calculate your new subscription price. " +
        "The more you add, the more you save",
    FamilyMembersBenefit = "Each family member on your GOV+ account will benefit from the following: ",
    AddFamilyMembers = "Add family member(s)",
    AddFamilyMemberFormHeader = "The last government form you'll ever fill out for your family member",
    AddFamilyMemberFormSubHeader = "Provide this information just once. GOV+ will pre-complete all your family member's" +
        " applications for you and save you from hours of extra paperwork.",
    AddFamilyMember = "Add family member",
}

export enum SubscriptionText {
    SubscriptionLogoText = "PREMIUM",
    SubscriptionHeader = "Make your life easier with a GOV+ subscription",
    SubscriptionSubHeader = "You'll save hours of time and have total peace of mind",
    PaymentAuthorizationDescription = 'I authorize GOV+ to charge my credit card for ${subscriptionPrice}',
    SubscriptionFailed = "Subscription failed",
    SubscriptionPlanTag = 'Cancel anytime',
    UpgradeToPremium = "Upgrade to GOV+ Premium",
    IndividualPlan = "Individual plan",
    CancelPlan = "Cancel plan",
    ManageSubscription = "Manage subscription",
    CancelSubscription = "Cancel subscription",
    CancelReasonTitle = "We're sorry to see you go",
    CancelReasonSubtitle = "Would you be willing to tell us why you're leaving GOV+? Your honest feedback will help us improve",
    CancellationReason = "Cancellation reason",
    CancelSubscriptionApplications = "Are you sure you want to interrupt your active applications?",
    CanselIndentityProtection = "Are you sure you want to <br> lose identity protection?",
    CancelSubscriptionApplicationsSubHeader = "Without GOV+, you may lose any government fees you’ve paid and have to start " +
        "the application process from scratch. You’ll no longer have access to application tracking or GOV+ support in case something goes wrong.",
    CancelSubscriptionApplicationsInterrupted = "I understand that if I cancel now my active applications will be interrupted",
    CancelSubscriptionDocumentsProtected = "I understand that if I cancel now my documents will no longer be protected",
    CancelSubscriptionRenewal = "I understand that if I cancel now my documents will no longer be renewed automatically",
    SubscriptionCancelAuthorization = `I understand I may cancel my subscription at any time in my dashboard, via chat or by emailing contact@govplus.com`,
    CancelIdentityProtection = "I understand that if I cancel now my identity <br> will no longer be monitored or protected",
    SubscriptionCancelled = "Your subscription has been cancelled",
    SubscriptionCancelledExplanation = "You will no longer be charged for your GOV+ subscription. You can still enjoy access to all the benefits of GOV+ Subscription until {date}. " +
        "After this date, you’ll lose access to your dashboard and organized records. " +
        "If you change your mind, please contact us.",
    DefaultPrice = "$11.99/month",
    SubscriptionRenewsOn = "Your plan renews on {date}",
    SubscriptionActiveUntil = "Your plan is active until {date}",
    PleaseProvideReason = "Please provide cancellation reason",
    TotalReview = "<span>3,300+</span> reviews",

}

export const SubscriptionTextLists = {
    DEFAULT_CAPABILITIES_LIST: [
        "<span> Unlimited access </span> to all your prefilled government applications",
        "<span> Automatic renewal </span> of your documents before they expire",
        "<span> Insurance replacement </span> against lost, stolen, or damaged documents",
        "<span> Expert review </span> to avoid mistakes and prevent delays ",
    ],
    HYBRID_CAPABILITIES_LIST: [
        "<span> Automatic renewal </span> of your documents before they expire",
        "<span> Insurance replacement </span> against lost, stolen, or damaged documents",
    ],
    INSURANCE_CAPABILITIES_LIST: [
        "Protection against lost, stolen, and damaged documents"
    ],
    RENEWAL_CAPABILITIES_LIST: [
        "Automatic renewal of your documents before they expire"
    ],
}

export enum GeneralText {
    GetItGovPlus = "Get it with GOV+",
    LetGovPlusGetIt = "Let GOV+ get it for you",
    KeepPremium = "Keep GOV+",
    GetPremium = 'Get GOV+ Subscription',
    Subscribe = "Subscribe",
    InvalidCreditCard = "Your credit card number is not valid",
    Default = "Default",
    StartsAt = "Starts at",
    TrackApplication = "Continue application",
    Completed = "Completed",
    Prefilled = "Prefilled",
    ApplicationCompleted = "Application completed",
    MyApplicationsSubheader = 'Keep track of all your government applications in one place, ' +
    'so you always know where you are in the process.',
    MyApplicationsSubheaderMobile = 'Keep track of all your government applications in one place, ' +
    'so you always know where you are in the process.',
    CheckYourEmail = "Don’t forget to check your email to activate your GOV+ account!",
    PageNotFound = "Page not found",
    PageNotFoundSubHeader = "The page you are looking for doesn't exist or has been moved."
}

export enum WarningsText {
    UploadDocumentsWarning = "Make sure the documents you upload <span>are not expired </span> to avoid having your application rejected and potentially losing your mandatory government fees.",
    PassportPhotoWarning = "Carefully follow the instructions below to avoid having your application rejected and potentially losing your mandatory government fees."
}

export const VariablesToBeReplaced = {
    SubscriptionPrice: '{subscriptionPrice}'
}

export enum AfterPaymentOfferText {
    OfferHeader = "Autofill, Expedited Processing, Automatic Renewals, Identity Theft Protection, and more",
    NewMembersFree = "New members, try Premium free for 7 days",
    TryFree = "Try Premium free for 7 days. As low as $9.99/month",
    UnlockFullAccess = "Unlock full access for as low as $9.99/month",
    StartFreeTrial = "Start your free 7-day trial",
    UpgradeToGovPlusHeader = "Upgrade to GOV+ Premium and save",
    SaveWithGovPlusUnlimited = "Save with GOV+ Unlimited and never worry about your documents again",
    GetGovPlusPremium = "Get GOV+ Premium and never worry about your documents again",
    UnlockThisWithGovPlus = "Unlock this feature with a GOV+ membership and enjoy even more benefits for your peace of mind and protection",
    Offer = "This feature is only available with a GOV+ membership. Our membership also offer other benefits for your peace of mind and protection.",
    GetUnlimitedApplicationsHeader = "Get unlimited applications and automatic document management",
    IncludesUnlimitedApplications = "Includes <span style='text-decoration: underline'>Unlimited</span> GOV+ Applications",
    ValidForYear = "Valid for a year",
    DiscountTag = "Discount available only on this page",
    SkipOffer = "Skip, I prefer risking extra costs, hassle and identity theft without GOV+ Premium",
    SkipOfferShort = "Skip, I prefer risking extra costs, hassle and identity theft",
    AutomaicDocumentManagement = "Automatic Document Management",
    DocumentFraudProtection = "and Document Fraud Protection",
    Premium = "Premium",
    NeverFilloutForms = "Never Fill Out Government Forms Again",
    NeverFilloutFormsDescription = "Save time and avoid mistakes with our smart autofill. Go Premium and your next application will be ready before you start.",
    ExpeditedProcessing = "Expedited Processing",
    ExpeditedProcessingDescription = "GOV+ Premium gets your documents faster than ever with faster verifications, appointments, and submission.",
    ExpeditedProcessingDescription2 = "Get your documents faster by being first-in-line at GOV+",
    AutoRenewal = "Automatic Document Renewal",
    AutoRenewalDescription = "Go Premium and never worry about expired documents again—GOV+ renews them automatically free of charge, just pay the government fee.",
    AutoRenewalExpiringDocuments = "Auto-renewal of expiring documents",
    InstantDocReplacement = "Instant Document Replacements",
    InstantDocReplacementDescription = "Lost, stolen or damaged documents? GOV+ Premium protects your documents so you get an instant replacement for free, you just pay the government fee.",
    InstantReplacementOfDocuments = "Instant replacement of lost or damaged documents",
    TotalIdentityProtection = "Total Identity Theft Protection",
    IdentityTheftProtection = "Identity Theft Protection",
    IdentityTheftRestorationInCase = "Identity theft restoration in case of theft",
    TotalIdentityProtectionDescription = "Government documents are a prime target for identity thieves as you carry them with you, GOV+ Premium protects your identity from theft giving you total peace of mind.",
    TotalIdentityProtectionDescription2 = "Protect yourself with monitoring and up to $1 million coverage",
    FreeTrialAuthorization = `By clicking "Start your free 7-day trial", I authorize GOV+ to charge {price} to my credit card on file after 7 days. This membership will automatically renew every {period}. I understand that I can cancel my membership anytime on my dashboard.`,
    FullAccessAuthorization = `By clicking "Unlock full access", I authorize GOV+ to charge {price} to my credit card on file. This membership will automatically renew every {period}.`,
    DocumentsAndPersonalMonitoring = "Documents and personal information monitoring",
    UpTo1MCoverage = "Up to $1 million in identity theft coverage",
    RealTimeAlerts = "Real-time alerts and credit monitoring",
    SmartAutofill = "Smart Autofill",
    SmartAutofillDescription = "Save time across all our applications",
    FreeAutoRenewals = "Free Automatic Renewals",
    FreeAutoRenewalsDescription = "No more expired documents or last minute rushes",
    FreeInstantReplacements = "Free Instant Replacements",
    FreeInstantReplacementsDescription = "No more getting stuck due to lost or damaged documents",
    ExpertReview = "Expert Review",
    ExpertReviewDescription = "Avoid costly mistakes and unnecessary delays",
    ExpressAssistance = "Express Assistance",
    ExpressAssistanceDescription = "Be first in line with our 24/7 phone and chat support"
}

export const UpgradeOfferBenefits = {
    ALL_BENEFITS: [
        "Auto-renewal of Expiring Documents",
        "Instant Replacement of Lost/Damaged Documents",
        "Identity Theft Protection and Restoration",
        "Up to $1 Million in Coverage",
        "Personal Information and Credit Score Monitoring/Alerts"
    ],
    CURRENT_BENEFITS: [
        "Auto-renewal of Expiring Documents",
        "Instant Replacement of Lost/Damaged Documents"
    ]
}

export const DocumentsPageText = {
    DocumentsPageHeader: "We've already filled out all your government applications for you. Just keep using GOV+ and your information will be automatically reused, saving you even more time!",
    IdentityProtectionCoverage: "Identity Protection Coverage",
    MonitoredInformation: "Monitored Information",
}