import {constants} from "./constatnts";

export const helper = {
  validation: (commonMessage, userEmail) => {
    switch (commonMessage) {
      case constants.commonMessage.USER_NOT_AUTHORIZED: {
        return {
          message: `It looks like you did not activate your account yet, we just sent you an email at ${userEmail} to finish activating your account and set your password.`,
          errEmailClassStatus: true,
        }
      }
      case constants.commonMessage.USER_NOT_FOUND: {
        return {
          message: (
            <span>
              We couldn’t find an account matching the email you entered. Please check and try again.
            </span>
          ),
          errEmailClassStatus: true
        }
      }
      default: return {
        message: "",
        errEmailClassStatus: null,
      };
    }
  }
};
