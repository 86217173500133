import React from "react"

interface ISvgCheck {
    width: number;
    height: number;
    color: string;
}

export const SgvThinkPlusSeparator = ({width = 19, height = 19, color = "#185EC8"}: ISvgCheck) => {
    const viewBox = `0 0 ${width} ${height}`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} fill="none">
            <path d="M12.5 5V19" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.5 12H19.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
