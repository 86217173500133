import React from "react";
import "./index.less"
import {useHistory} from "react-router-dom";
import GoogleMapComponent from "../../../../../../components/GoogleMapComponent";
import {IconSvg} from "../../../../../../components/svgImages/SvgImages";
import {useSelector} from "react-redux";
import GText from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Text/Text";
import {SIZE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import {COLORS} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";

const CompletedReNewExpired = ({productId}) => {
  const {
    allProducts,
    anotherGovProducts,
  } = useSelector(({allProducts}) => allProducts);
  const {name, lastName} = useSelector(({auth}) => auth.userData);
  const {
    status,
    appointment_times_selected: {label},
    appointment_address: {city, state, zipcode, address1, address2},
    add: {latLng},
  } = allProducts[productId];

  const descriptionUnderGoogleMap = "Please be prepared to pay the $78 government fee. Acceptable payment methods include credit card, debit card, money order, company check, or certified/cashier’s check."
  const history = useHistory();
  return (
    <div className={"TSA_completedStatus-container"}>
      <div className={"TSA_completedStatus-container-headerContainer"}>
        <IconSvg type={status}/>
        <GText text={'Your appointment is confirmed'} size={SIZE.HEADING_LG} color={COLORS.DARK_BLUE_600} />
        <GText text={descriptionUnderGoogleMap} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.SLATE_GRAY_500} />
      </div>

      <div className={"TSA_completedStatus-container-statusBody"}>
        <div className={"TSA_completedStatus-container-statusBody-header"}>
          <div className={"TSA_completedStatus-container-statusBody-header-appointmentTime"}>
            <GText text={'Appointment Time'} size={SIZE.HEADING_XXS} color={COLORS.DARK_BLUE_600} />
            <GText text={label} size={SIZE.HEADING_MD} color={COLORS.ROYAL_BLUE_600} />
          </div>
          <div className={"TSA_completedStatus-container-statusBody-header-appointmentLocation"}>
            <GText text={'Appointment Location'} size={SIZE.HEADING_XXS} color={COLORS.DARK_BLUE_600} />
            <div className={"TSA_completedStatus-container-statusBody-header-appointmentLocation_address"}>
              <GText text={address1} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.DARK_BLUE_600} />
              <GText text={address2} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.DARK_BLUE_600} />
              <GText text={`${city} ${state}, ${zipcode}`} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.DARK_BLUE_600} />
            </div>
          </div>
        </div>

        <div className={"TSA_completedStatus-container-statusBody-productInfo"}>
          <div className={"TSA_completedStatus-container-statusBody-productInfo-googleMap"}>
            <GoogleMapComponent
              message={""}
              lat={latLng.lat}
              lng={latLng.lng}
              defaultCenter={latLng}
            />
          </div>
        </div>
      </div>
      <div className={"TSA_completedStatus-container_backButton"}>
        <p
          className="checkStatusContainer_mainBlock-backButton"
          onClick={() => {history.push("/products")}}
        >
          <IconSvg type={"back_arrow_left"}/>Back</p>
      </div>
    </div>
  );
};

export default CompletedReNewExpired;
