import React, { FC } from "react"
import GBlock from "../../templates/GBlock/GBlock"
import { DocumentSectionProps } from "./DocumentSection.props"

const DocumentSection: FC<DocumentSectionProps> = ({ title, titleIcon, hasTitleIcon, children }) => (
  <GBlock title={title} hasTitleIcon={hasTitleIcon} titleIcon={titleIcon}>
    <div className="GDocuments__Content">{children}</div>
  </GBlock>
)

export default DocumentSection
