import React, { FC, useState } from "react"
import "./MedicalIDDetailsForm.scss"
import { Form } from "antd"
import { FormInput, validateMedicalId } from "../../../../../util/IdentityProtectionHelper"
import GText from "../../../atoms/Text/Text"
import GButton from "../../../molecules/Button/GButton"
import GInputText from "../../../molecules/InputText/InputText"
import { COLORS_NEW } from "../../../types/Colors"
import { SIZE, BUTTON_TYPE } from "../../../types/types"

const MedicalIDDetailsForm: FC<FormInput> = ({ recordType, handleFormActions }) => {
  const [medicalIDForm] = Form.useForm()
  const [medicalIdError, setMedicalIdError] = useState("")

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase()
    medicalIDForm.setFieldValue("medicalId", event.target.value.toUpperCase())

    if (value.length >= 5) {
      setMedicalIdError("")
    }
  }

  const getMedicalIdFormLabel = () => {
    return (
      <div className={"MedicalIDDetailsForm__Item__Label"}>
        <GText text={"Medical ID number"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
      </div>
    )
  }

  const handleFormSubmit = async () => {
    try {
      const valid = await medicalIDForm.validateFields()
      if (valid) {
        const submittedData = {
          recordType,
          value: medicalIDForm.getFieldValue("medicalId"),
        }
        handleFormActions(submittedData)
        setMedicalIdError("")
      }
    } catch (error) {
      console.error(error)
      setMedicalIdError("Please enter a valid medical ID number")
    }
  }

  return (
    <Form
      className={"MedicalIDDetailsForm"}
      layout={"vertical"}
      form={medicalIDForm}
      initialValues={{ remember: true }}
      name={"basic"}
      onFinish={handleFormSubmit}
    >
      <Form.Item
        className={"MedicalIDDetailsForm__Item"}
        label={getMedicalIdFormLabel()}
        name={"medicalId"}
        initialValue={""}
        rules={[{ required: true, validator: validateMedicalId }]}
        hasFeedback={false}
        help={medicalIdError}
        validateStatus={medicalIdError ? "error" : ""}
      >
        <GInputText onChange={handleInputChange} minLength={5} maxLength={32} />
      </Form.Item>

      <div className={"MedicalIDDetailsForm__Actions"}>
        <GButton
          type={BUTTON_TYPE.SECONDARY}
          showIcon={false}
          style={{ height: "44px" }}
          text={"Add"}
          click={handleFormSubmit}
        />
      </div>
    </Form>
  )
}

export default MedicalIDDetailsForm
