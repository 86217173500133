/* eslint-disable indent */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { ValidationItemInterface } from "../../../../../Redux/InterfacesEntity/validation.interface"
import { FieldInterface } from "../../../../../Redux/InterfacesEntity/field.interface"
import InputMask from "react-input-mask"
import {
  getAutocomplete,
  removeObserver,
  setObservers,
  setPaymentError,
  setSubmitFormError,
  setTagFields,
  setValueCurrentField,
} from "../../../../../Redux/store/form/form.actions"
import { setValueForSelect } from "../../../../../Redux/store/select/select.actions"
import lockIcon from "../../../../../assets/image/lockIcon.svg"
import { getPatternRules, getValidations, validatorConfirm } from "../validation"
import { setCashState } from "../../../../../Redux/store/cash/cash.action"
import {
  SubmitObjectInterface,
  ArrayCasesDenyValidationType,
  arrayCasesDenyValidation,
  paymentErrorInterface,
} from "../serviceInput"
import { savePrefilled } from "../../../../../Redux/store/user/user.actions"
import { getScrollElementById } from "../../../FormBlock/FormBlock"
import { Dispatch } from "../../SelectComponent/useDefaultOption"
import {delayTimer} from "../../../../../Pages/FormPage/FormPage";
import GFormItem from "../../../../atomicDesign/organisms/FormItem/FormItem";
import {ICONS_SVG_TYPE} from "../../../../atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import GInputText from "../../../../atomicDesign/molecules/InputText/InputText";
import ClientTokenRepository from "../../../../../persistence/cookies/ClientTokenRepository";
import AuthUserBearerTokenRepository from "../../../../../persistence/session/AuthUserBearerTokenRepository";




type NestRenderInputComponentProps = {
  field: FieldInterface,
  validationRule: ValidationItemInterface,
  value: string,
  submitObject: {} | any,
  setValidationStatus: any,
  errorStateAutofill: string,
  setErrorStateAutofill: any,
  validationStatus: string,
  paymentError: paymentErrorInterface,
  tagStoreWithFields: { [key: string]: string },
  submitError: { [key: string]: any },
  statusFocus: boolean,
  setStatusFocus: Dispatch<boolean>,
  setStatusRender: Dispatch<boolean>,
  dispatch: any,
  maskedForPrivacy?: boolean
}

const poRegex = /\bP(ost|ostal)?([ \.]*O(ffice)?)?([ \.]*Box)?\b/i

export const getHandleValidationByRule = (validationRule: any, value: string) => {
  const re = validationRule?.pattern
  const match = value?.match(new RegExp(re))
  if(validationRule?.deny_pb_box !== undefined) {
    return match && !poRegex.test(value)
  }
  return match
}

// eslint-disable-next-line max-len
const NestRenderInputComponent: React.FunctionComponent<NestRenderInputComponentProps> = ({
  field,
  validationRule,
  value,
  submitObject,
  setValidationStatus,
  errorStateAutofill,
  setErrorStateAutofill,
  validationStatus,
  paymentError,
  tagStoreWithFields,
  submitError,
  statusFocus,
  setStatusFocus,
  setStatusRender,
  dispatch,
  maskedForPrivacy=false
}) => {
  const nameOption = field.nameBlock
  // crut for change digital on * in SSN field
  const hiddenSSN =
    field.name === "ssn_descendent"
      ? "ssn_descendent_hidden"
      : field.name === "ssn_applicant"
      ? "ssn_applicant_hidden"
      : field.name === "ssn_mother_num"
      ? "ssn_mother_num_hidden"
      : field.name === "ssn_father_num"
      ? "ssn_father_num_hidden"
      : field.name === "ssn"
      ? "ssn_hidden"
      : field.name === "ssn_oth"
      ? "ssn_oth_hidden"
      : ""

  // for dynamic change current option in select
  useEffect(() => {
    if (field.dynamicSelect && nameOption) setValueForSelectCommon(field.dynamicSelect, nameOption, field.name, value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  function setValueForSelectCommon(dynamicSelect: string, nameOption: string, nameField: string, valueField: string) {
    if (field.dynamicSelect && nameOption) dispatch(setValueForSelect(nameOption, `${field.name}`, value))
  }

  // for special classes
  const specialInput = field.className === "special-input" ? "special-input" : "usual-input"

  const phoneMobile = field.label === "Phone number" ? "phone-number" : ""

  const iconsInput = field.icons ? "icons" : "no-icons"

  // store for dynamic select for prev name
  const handlerObserverState = (observable: {}, nameCurrentField: string, valueCurrentField: string) => {
    if (valueCurrentField) dispatch(setObservers(observable, valueCurrentField))
    else {
      // eslint-disable-next-line array-callback-return
      Object.entries(observable).map((item: any) => {
        if (item[0].includes(`confirm_${nameCurrentField}`)) dispatch(removeObserver(item[0]))
      })
    }
  }

  //state for show/hide error validation onFocus/onBlur
  const handlerOnFocusValidation = () => {
    setValidationStatus("off")
    setStatusFocus(true)
    setErrorStateAutofill("success")
  }

  const handlerOnFocus = () => {
    handlerOnFocusValidation()
    if(submitError.message && field.name === "email") {
      dispatch(setSubmitFormError({}))
    }
  }

  const handlerOnBlurValidation = () => {
    setValidationStatus("off")
    setStatusFocus(false)
  }

  const handlerOnBlur = async (e:any) => {
    handlerOnBlurValidation()
    if ((field.name === "phone_number" || field.name === "phone") && submitObject[field.name]?.length !== 14) {
      return null
    }
    if ((field.name === "ssn" || field.name === "ssn_applicant") && submitObject[field.name]?.length !== 11) {
      return null
    }
    if (field.name === "email") {
      if (submitObject[field.name] !== e?.target?.value && submitObject[field.name] === e?.target?.value?.trim()) {
        setStatusRender(false)
        delayTimer(1000).then(() => setStatusRender(true))
      }
    }
    if (field.dynamicSelect && nameOption) setValueForSelectCommon(field.dynamicSelect, nameOption, field.name, value)
    dispatch(setCashState({ ...submitObject }))
    if (
      field.tag &&
      !hiddenSSN &&
      submitObject[field.name] &&
      getHandleValidationByRule(validationRule, submitObject[field.name])
    ) {
      dispatch(savePrefilled({ [field.tag]: submitObject[field.name] }))
    }
    if (field.tag && hiddenSSN) {
      dispatch(savePrefilled({ [field.tag]: submitObject[`${field.name}_hidden`] }))
    }
    if (!!getPaymentError(field.name, paymentError)) {
      dispatch(setPaymentError(null))
    }
  }

  // crut for change digital on * in SSN field
  const getNumberValue = (value: string) => (!isNaN(Number(value)) && value?.length === 1 ? value : "")

  const getStarsInValue = (value: string, name?: string) =>
    value?.length !== 0 && value?.length < 8 && name !== hiddenSSN ? `${value.slice(0, -1)}*` : value

  const getMaskSSN = (value: string, mask: string | any, inputType: string, name?: string) => {
    const arrDividers = mask
      ?.split("")
      .reduce((acc: any, item: any, index: any) => (item === "-" ? acc.concat(index) : acc), [])

    const valueWithStars = getStarsInValue(value, name)

    if (arrDividers.includes(valueWithStars?.length) && inputType !== "deleteContentBackward") {
      return `${valueWithStars}-`
    }

    return `${valueWithStars}`
  }

  const getValue = (e: any, field: FieldInterface) =>
    e?.target?.value?.length === field?.mask?.length ? e?.target?.value : e?.target?.value + getNumberValue(e?.key)

  const getValueForHidden = (currentSymbol: string, field: FieldInterface, nameHidden: string) =>
    submitObject?.[nameHidden]?.length === field?.mask?.length
      ? submitObject[nameHidden]
      : submitObject[nameHidden] + getNumberValue(currentSymbol)

  const getNewInputMask = (e: any, nameHidden: string, field: FieldInterface) => {
    const value = getValue(e, field)
    const valueForHidden = getValueForHidden(e?.nativeEvent?.data, field, nameHidden)
    const maskFileName = getMaskSSN(value, field?.mask, e?.nativeEvent?.data ?? e?.nativeEvent?.inputType)
    const maskSSN = getMaskSSN(
      valueForHidden,
      field?.mask,
      e?.nativeEvent?.data ?? e?.nativeEvent?.inputType,
      nameHidden
    )
    return { value, valueForHidden, maskFileName, maskSSN }
  }

  const addNewSymbol = (usualName: string, nameHidden: string, usualSSN: string, maskSSN: string) => {
    dispatch(setValueCurrentField(usualName, usualSSN))
    dispatch(setValueCurrentField(nameHidden, maskSSN))
  }

  type checkRemoveFromMiddleType = (usualValue: string, maskValue: string, usualSSNFullString: string) => boolean

  const checkRemoveFromMiddle: checkRemoveFromMiddleType = (usualValue, maskValue, usualSSNFullString) => {
    const createArrayForComparingLength = (value: string) => value.split("-").filter((item: string) => item !== "")
    // if value or dash removes from middle string it will return true and block this process
    return (
      (usualSSNFullString.charAt(usualSSNFullString.length - 1) !== "-" &&
        createArrayForComparingLength(usualValue).length !== createArrayForComparingLength(maskValue).length) ||
      !!usualValue
        .split("-")
        .find((itemUsualValue: string, indexUsualValue) =>
          maskValue
            .split("-")
            .find(
              (itemMaskValue: string, indexMaskValue) =>
                indexUsualValue === indexMaskValue && itemUsualValue.length !== itemMaskValue.length
            )
        )
    )
  }

  const replaceGabs = (value: string) => value.replaceAll(" ", "")

  const removeSSNMaskSymbol = (
    usualName: string,
    nameHidden: string,
    maskSSN: string,
    usualSSN: string,
    mask: string
  ) => {
    //crud for remove not last element
    const cutLastPartSSN = (value: string) => value.split("-").slice(0, -1).join("-")
    if (checkRemoveFromMiddle(cutLastPartSSN(maskSSN), cutLastPartSSN(usualSSN), usualSSN)) {
      return
    }

    if (maskSSN.length !== usualSSN.length) {
      const arrDividers = mask
        ?.split("")
        .reduce((acc: any, item: any, index: any) => (item === "-" ? acc.concat(index) : acc), [])

      if (arrDividers.includes(maskSSN?.length)) {
        return addNewSymbol(usualName, nameHidden, maskSSN.slice(0, -1), usualSSN.slice(0, -2))
      } else {
        return addNewSymbol(usualName, nameHidden, maskSSN.slice(), usualSSN.slice(0, -1))
      }
    }
  }
  const [inputValue, setInputValue] = useState(submitObject[field.name])
  const handlerOnChangeInput = (e: any, field: FieldInterface, nameHidden: string) => {
    if (!hiddenSSN) {
      if (field.name === "expiration_date") {
        let currentValue = e.target.value
        if (currentValue.length === 3 && currentValue.charAt(currentValue.length - 1) === "/") {
          currentValue = currentValue.slice(0, -1)
        }
        if (currentValue.length <= 5 && !RegExp("[a-zA-Z]").test(currentValue)) {
          if (parseInt(currentValue) >= 2 && currentValue.length === 1) {
            currentValue = "0" + currentValue + "/"
            setInputValue(currentValue)
          } else if (currentValue.length === 2 && parseInt(currentValue) > 12) {
            currentValue = "0" + currentValue.charAt(0) + "/" + currentValue.charAt(1)
            setInputValue(currentValue)
          } else if (currentValue.length === 3 && currentValue.charAt(currentValue.length - 1) != "/") {
            const last = currentValue.charAt(currentValue.length - 1)
            const rest = currentValue.slice(0, -1)
            currentValue = rest + "/" + last
            setInputValue(currentValue)
          } else {
            setInputValue(currentValue)
          }
        }
      }
      if(field.name === "residential_city_name") {
        dispatch(setValueCurrentField(field.name, e.target.value))
      } else {
        dispatch(setValueCurrentField(field.name, e.target.value.trim()))
      }
      !field.mask && setInputValue(e.target.value)
      if (field.observable) {
        handlerObserverState(field.observable, field.name, e.target.value)
      }
    } else if (
      hiddenSSN &&
      !isNaN(Number(e?.nativeEvent?.data)) &&
      e?.target?.value?.[e?.target?.value?.length - 1] === e?.nativeEvent?.data &&
      e?.nativeEvent?.inputType !== "deleteContentBackward"
    ) {
      const { maskFileName, maskSSN } = getNewInputMask(e, nameHidden, field)
      if (replaceGabs(String(maskSSN)).length !== maskFileName.length) {
        return
      }
      addNewSymbol(field.name, nameHidden, maskFileName, maskSSN)
    } else if (
      hiddenSSN &&
      !isNaN(Number(e?.nativeEvent?.data)) &&
      e?.nativeEvent?.inputType === "deleteContentBackward"
    ) {
      const { value, maskSSN } = getNewInputMask(e, nameHidden, field)
      removeSSNMaskSymbol(field.name, nameHidden, value, maskSSN, field.mask)
    } else if (
      hiddenSSN &&
      isNaN(Number(e?.nativeEvent?.data)) &&
      (submitObject[field.name] === "" || submitObject[nameHidden] === "")
    ) {
      dispatch(setValueCurrentField(field.name, ""))
      dispatch(setValueCurrentField(nameHidden, ""))
    }

    // for autocomplete city and state by post code
    if (field.autocomplete && e.target.value.length === 5) {
      dispatch(getAutocomplete(e.target.value, field.autocompleteCities, field.autocompleteStates))
    }
    if (field?.tag && !hiddenSSN) {
      sessionStorage.setItem("tagFields", JSON.stringify({ [field?.tag]: e?.target?.value }))
      dispatch(setTagFields({ [field?.tag]: e?.target?.value }))
    }
    if (field.tag && hiddenSSN) {
      sessionStorage.setItem(
        "tagFields",
        JSON.stringify({ [field?.tag]: String(submitObject[`${field.name}_hidden`]) + e?.target?.value?.slice(-1) })
      )
      dispatch(
        setTagFields({ [field?.tag]: String(submitObject[`${field.name}_hidden`]) + e?.target?.value?.slice(-1) })
      )
    }
  }

  //for maxLength validation
  const getMaxLength = (
    { maxLength, minLength }: { maxLength?: number | undefined, minLength?: number | undefined },
    valueInput: string
  ) =>
    maxLength && !minLength
      ? valueInput.length - 1 < maxLength
        ? Promise.resolve()
        : Promise.reject("error length")
      : Promise.resolve()

  const validateExpirationDate = (valueInput: string) => {
    if (!valueInput || valueInput.trim() === "") return Promise.reject("Required")
    const expirationDateArray = valueInput.split("/")
    if (expirationDateArray.length !== 2) return Promise.reject("Invalid expiration date format")
    if (isNaN(parseInt(expirationDateArray[0])) || parseInt(expirationDateArray[0]) > 12 || parseInt(expirationDateArray[0]) === 0)
      return Promise.reject("Invalid expiration month")

    const currentYear = new Date().getFullYear().toString().slice(-2)
    if (isNaN(parseInt(expirationDateArray[1])) || parseInt(expirationDateArray[1]) < parseInt(currentYear))
      return Promise.reject("Invalid expiration year")
    return Promise.resolve()
  }

  const validateRejectPOBox = (valueInput: string) => {
    if (poRegex.test(valueInput)) return Promise.reject("PO Boxes are not authorized")
    else return Promise.resolve()
  }

  const {
    nortonContainer,
    nortonContainer: {
      classNameFormItem: nortonClassNameFormItem = "",
      classNameContainer: nortonClassNameContainer = "",
      id: nortonId = "",
    } = {},
  } = field

  useEffect(() => {
    if (nortonContainer) {
      const script = document.createElement("script")
      // script.src = "./norton.ts"
      script.async = true
      script.type = "application/javascript"
      // @ts-ignore
      script.innerHTML =
        "var _dcid = _dcid || []\n" +
        '    __dcid.push({"cid":"DigiCertClickID_Uq17yXvX","tag":"Uq17yXvX"})\n' +
        "    ;(function () {\n" +
        '      var cid=document.createElement("script")\n' +
        "      cid.async=true\n" +
        '      cid.src="//seal.digicert.com/seals/cascade/seal.min.js"\n' +
        '      var s = document.getElementsByTagName("script")\n' +
        "      var ls = s[(s.length - 1)]\n" +
        "      ls.parentNode.insertBefore(cid, ls.nextSibling)\n" +
        "    }())"

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const denyValidation = useCallback(
    (
      submitObject: SubmitObjectInterface,
      arrayCasesDenyValidation: ArrayCasesDenyValidationType,
      currentFieldName: string
    ) => {
      const { action, dependsField } =
        arrayCasesDenyValidation.find(({ nameField }) => nameField === currentFieldName) ?? {}
      const newValue = submitObject[currentFieldName ?? ""]
        ?.split?.("")
        ?.filter((letter: any) => !isNaN(Number(letter)))
        .filter((symbol: any) => symbol.trim())
        .join("")
      const checkDifferentValues =
        submitObject[currentFieldName] !== newValue && typeof submitObject[currentFieldName] === "string"

      if ((action && dependsField ? action(submitObject, dependsField) : false) && checkDifferentValues) {
        dispatch(setValueCurrentField(currentFieldName, newValue))
      }
      return action && dependsField ? action(submitObject, dependsField) : false
    },
    [dispatch]
  )

  const getPaymentError = (currentFieldName: string, paymentError: paymentErrorInterface) =>
    paymentError?.nameField?.includes(currentFieldName || "empty") ? "error-payment" : ""

  const rulesValue = () => {
    if (field.name === "expiration_date") {
      return [
        {
          validator: () => {
            return validateExpirationDate(submitObject[field.name])
          },
        },
      ]
    } else if (field.name === "card_name") {
      return [{ required: true, message: "Required" }]
    } else if (field.name === "card_no") {
      return [{ required: true, message: "Required" }]
    } else if (validationRule && validationRule.pattern) {
      const validationsResult = [
        {
          validator: () => {
            // eslint-disable-next-line max-len
            return (
              getValidations(
                submitObject[hiddenSSN || field.name],
                field,
                validationRule.pattern,
                denyValidation(submitObject, arrayCasesDenyValidation, field.name)
              ) || getMaxLength(field, submitObject[field.name])
            )
          },
        },
        () => getPatternRules(validationRule.pattern, field),
        (getFieldValue: any) => ({
          validator(_: any, value: any) {
            if (field.dependencies)
              // eslint-disable-next-line max-len
              return validatorConfirm(value, getFieldValue, field.dependencies)
            return Promise.resolve()
          },
        }),
      ]
      if(field.deny_pb_box !== undefined && field.deny_pb_box) {
        validationsResult.push({
          validator: () => {
            return validateRejectPOBox(submitObject[field.name])
          },
        })
      }
      return validationsResult
    } else {
      return undefined
    }
  }

  const onClickRedirectToSighIn = () => {
    AuthUserBearerTokenRepository.getInstance().removeItem();
    ClientTokenRepository.getInstance().removeItem();
  }

  const errorSubmitFieldName = useMemo(() => submitError?.field_name, [submitError?.field_name])

  const errorSubmitMessage = useMemo(() => submitError?.message, [submitError?.message])

  const statusErrorSubmit = useMemo(() => errorSubmitFieldName === field?.name, [errorSubmitFieldName, field?.name])

  const textPaymentError = getPaymentError(field.name, paymentError)
  const hasPaymentError = textPaymentError !== "" && textPaymentError !== undefined

  useEffect(() => {
    if (statusErrorSubmit) {
      setValidationStatus("on")
      getScrollElementById({ id: `${errorSubmitFieldName}_scrollLabelById` })
    }
  }, [errorSubmitFieldName, statusErrorSubmit])

  const checkOnSighIn = ({ errorSubmitMessage }: { [key: string]: string }) => {
    if (errorSubmitMessage.includes("sign in")) {
      const startLinkIndex = errorSubmitMessage.indexOf("sign in")
      return (
        <>
          <span className={"submit-error__start-text"}>{errorSubmitMessage.slice(0, startLinkIndex)}</span>{" "}
          <a href="/signin" className={"submit-error__text_container"} onClick={onClickRedirectToSighIn}>
            {" "}
            <span className={"submit-error__link"}>
              {errorSubmitMessage.slice(startLinkIndex, startLinkIndex + 7)}
            </span>{" "}
          </a>
          <span className={"submit-error__end-text"}>{errorSubmitMessage.slice(startLinkIndex + 7)}</span>
        </>
      )
    }
    return (
      <div className={"submit-error__text_container"}>
        <span className={"submit-error__text"}>{errorSubmitMessage}</span>
      </div>
    )
  }

  const getValueFromStates = (name: string, autocompleteObserver: boolean) =>
    name === "residential_city_name" || autocompleteObserver ? submitObject[field.name] : inputValue


  return (

    <>
      <GFormItem
          name={field.name}
          initialValue={submitObject[field.name] ?? value ?? field.defaultValue ?? ""}
          preserve={false}
          rules={rulesValue()}
          dependencies={[field.dependencies]}
      >
        <div>
          {!hiddenSSN && field.name === "expiration_date" && (
              <InputMask
                  mask={field.mask || ""}
                  value={inputValue || ""}
                  maskPlaceholder={null}
                  onChange={(e: any) => handlerOnChangeInput(e, field, hiddenSSN)}
                  onFocus={() => handlerOnFocus()}
                  onBlur={e => handlerOnBlur(e)}
              >
                <GInputText
                    placeholder={field.placeholder || ""}
                    maxLength={field.maxLength || 50}
                    minLength={field.minLength || 0}
                    inputMode={field.input_mode}
                    type={field.keyboard_mobile_type}
                    maskedForPrivacy={maskedForPrivacy}
                />
              </InputMask>
          )}
          {!hiddenSSN && field.name !== "expiration_date" && !field.mask && (
              <GInputText
                  placeholder={field.placeholder || ""}
                  maxLength={field.maxLength || 50}
                  minLength={field.minLength || 0}
                  value={getValueFromStates(field.name, field.autocompleteObserver) || ""}
                  onChange={(e: any) => handlerOnChangeInput(e, field, hiddenSSN)}
                  onFocus={() => handlerOnFocus()}
                  onBlur={(e: any) => handlerOnBlur(e)}
                  inputMode={field.input_mode}
                  suffixError={field.icons || field.iconCvv ? undefined : ICONS_SVG_TYPE.X_CIRCLE}
                  suffixSuccess={field.icons || field.iconCvv ? undefined : ICONS_SVG_TYPE.CHECK}
                  maskedForPrivacy={maskedForPrivacy}
              />
          )}
          {!hiddenSSN && field.name != "expiration_date" && field.mask && (
              <InputMask
                  mask={field.mask || ""}
                  value={value || ""}
                  maskPlaceholder={null}
                  onChange={(e: any) => handlerOnChangeInput(e, field, hiddenSSN)}
                  onFocus={() => handlerOnFocus()}
                  onBlur={e => handlerOnBlur(e)}
              >
                <GInputText
                    placeholder={field.placeholder || ""}
                    maxLength={field.maxLength || 50}
                    minLength={field.minLength || 0}
                    inputMode={field.input_mode}
                    suffixError={field.icons || field.iconCvv  ? undefined : ICONS_SVG_TYPE.X_CIRCLE}
                    suffixSuccess={field.icons || field.iconCvv ? undefined : ICONS_SVG_TYPE.CHECK}
                    maskedForPrivacy={maskedForPrivacy}
                />
              </InputMask>
          )}
          {hiddenSSN && (
              <InputMask
                  mask={""}
                  value={value || ""}
                  maskPlaceholder={null}
                  onChange={(e: any) => handlerOnChangeInput(e, field, hiddenSSN)}
                  onFocus={() => handlerOnFocus()}
                  onBlur={e => handlerOnBlur(e)}
              >
                <GInputText
                    placeholder={field.placeholder || ""}
                    maxLength={field.mask.length}
                    inputMode={field.input_mode}
                    type={field.keyboard_mobile_type}
                    suffixError={ICONS_SVG_TYPE.X_CIRCLE}
                    suffixSuccess={ICONS_SVG_TYPE.CHECK}
                    maskedForPrivacy={maskedForPrivacy}
                />
              </InputMask>
          )}
          {nortonContainer && <div className={nortonClassNameContainer} id={nortonId}></div>}
          {(field.security && <img src={lockIcon} alt={"lock"}></img>) || <div></div>}
        </div>
      </GFormItem>
      {statusErrorSubmit && <div className={"submit-error__block"}>{checkOnSighIn({ errorSubmitMessage })}</div>}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  currentStep: state.step.currentStep,
  paymentError: state.form.paymentError,
  tagStoreWithFields: state.form.tagStoreWithFields,
  submitError: state.form.submitError,
})

export default connect(mapStateToProps)(NestRenderInputComponent)
