import React, {useEffect} from "react"
import {connect, useSelector} from "react-redux"
import {
    setStatusSignature, setStatusSignatureOpen,
} from "../../../../Redux/store/additionalForm/additional.actions"
import SignatureComponent from "../SignatureComponent/SignatureComponent"
import {RootState} from "../../../../App"
import {additionalSelector} from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import {delayTimer} from "../../../../Pages/FormPage/FormPage"
import {getScrollElementById} from "../../FormBlock/FormBlock"
import GAdditionalStep, {StepVisualStatus} from "../../../atomicDesign/templates/AdditionalStep/AdditionalStep"
import GAdditionalStepUpsell from "../../../atomicDesign/templates/AdditionalStep/AdditionalStepUpsell";
import ProcessingValidationComponent from "../ProcessingValidationComponent/ProcessingValidationComponent"
import {getImageFromLocalStorage, replaceSolution} from "../../../../util/ProcessingTrackingHelper"
import GSmallTrackingCard
    from "../../../atomicDesign/templates/AdditionalStep/StepStatusTracking/shared/SmallTrackingCard/SmallTrackingCard"
import CompletedEINComponent from "../CompletedEINComponent/CompletedEINComponent"
import {SolutionIds, SolutionNames} from "../../../../util/SolutionNames"
import { useApplications } from "../../../../../../dashboard-govplus-front/src/hooks/useApplications"
import {getIdNameFromStatuses} from "../../../../util/BlockStep";
import { BlockStepTypes } from "../../../../Constants/BlockStepTypes"

type BlocksStepsEinProps = {
    blocksStep: any,
    index: number,
    showStatus: string,
    submitObject: {} | any,
    formId: string,
    statusSignatureBlockOpen: string,
    dispatch: any,
    upsellProcessingInformation: any,
    einNumber: string,
    application_id: string
}
const BlocksStepsEin: React.FunctionComponent<BlocksStepsEinProps> = ({
                                                                          blocksStep,
                                                                          index,
                                                                          showStatus,
                                                                          submitObject,
                                                                          formId,
                                                                          statusSignatureBlockOpen,
                                                                          dispatch,
                                                                          upsellProcessingInformation,
                                                                          einNumber,
                                                                          application_id
                                                                      }) => {
    const uniqSession = submitObject["unique-section-id"]
    const {filteredProducts} = useApplications()
    //@ts-ignore
    const myProduct = filteredProducts[application_id]?.add;
    const download_link = myProduct?.download_link;
    const {statusSignaturePage} = useSelector<RootState, { statusSignaturePage: string } | undefined>(
        additionalSelector
    ) ?? {statusSignaturePage: ""}
    useEffect(() => {
        delayTimer(300).then(() => getScrollElementById({id: `${statusSignaturePage}_scrollById`}))
    }, [statusSignaturePage])

    function downloadSS4(): void {
        if(!download_link) return;
        window.open(download_link, "_blank")
    }

    let idNameFromStatuses = getIdNameFromStatuses(blocksStep.status)

    return (
        <div className={`steps-signature-container`} id={`${idNameFromStatuses}${index !== 1 ? "_scrollById" : ""}`}>
            {blocksStep.type === BlockStepTypes.UPSELL && upsellProcessingInformation && upsellProcessingInformation[blocksStep.status] && (
                <GAdditionalStepUpsell
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    formId={formId}
                    uniqSession={uniqSession}
                    blockStatus={blocksStep.status}
                />
            )}
            {blocksStep.type === BlockStepTypes.SIGNATURE && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusSignatureBlockOpen}
                >
                    <SignatureComponent nestBlock={blocksStep.content.nestBlock}/>
                </GAdditionalStep>
            )}
            {(blocksStep.type === BlockStepTypes.VALIDATION) && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                >
                    <ProcessingValidationComponent
                        nameProduct={SolutionIds.EIN}
                        title={SolutionNames.EIN}
                        subtitle={blocksStep.content?.nestBlock?.subheader ? blocksStep.content?.nestBlock?.subheader : " "}
                    />
                </GAdditionalStep>
            )}
            {blocksStep.type === BlockStepTypes.PROCESSING && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={replaceSolution(blocksStep.content.titleDone, SolutionNames.EIN)}
                    information={replaceSolution(blocksStep.content.information, SolutionNames.EIN)}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                >
                    <GSmallTrackingCard
                        showGovernmentIcon={false}
                        imgSrc={getImageFromLocalStorage(SolutionIds.EIN)}
                        solution={SolutionNames.EIN}
                        width={68}
                        height={87}
                        numberOfWeeks={blocksStep.content?.nestBlock?.estimatedWaitTime || ""}
                        textProgressBar={"Processing"}
                    />
                </GAdditionalStep>
            )}
            {blocksStep.type === BlockStepTypes.COMPLETED && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={replaceSolution(blocksStep.content.information, SolutionNames.EIN)}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    blockStepType={blocksStep.type}
                >
                    <CompletedEINComponent
                        title={"Your EIN is"}
                        body={einNumber}
                        textButton="Download a copy of my SS-4 form"
                        onSendClick={() => downloadSS4()}
                    />
                </GAdditionalStep>
            )}
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    statusSignature: state.additional.statusSignature,
    statusUpsellStep: state.additional.statusUpsellStep,
    statusQuestionnaireFront: state.additional.statusQuestionnaireFront,
    einNumber: state.additional.einNumber,
    submitObject: state.form.submitObject,
    currentResponse: state.step.currentResponse,
    loadingButtonState: state.loading.loadingButtonState,
    application_id: state.form.application_id,
    appId: state.form.appId,
    formId: state.form.formId,
    missingInformationStatus: state.form.missingInformationStatus,
    statusDocuments: state.additional.statusDocuments,
    customer: state.form.customer,
    statusSignatureBlockOpen: state.additional.statusSignatureBlockOpen,
    upsellProcessingInformation: state.additional.upsellProcessingInformation
})
export default connect(mapStateToProps)(BlocksStepsEin)
