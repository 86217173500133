import React, {useState} from "react"
import "./style.less"
import {Link} from "react-router-dom";
import {Input, Form} from "antd"
import {ArrowLeftOutlined} from "@ant-design/icons"
import SettingsButton from "../../../components/SettingsButton";
import {useSelector} from "react-redux";

const EditNamePage = () => {
  const {name, lastName} = useSelector(({auth}) => auth.userData);
  const [firstName, setFirstName] = useState(name);
  const [middleName, setMiddleName] = useState("test_middleName");
  const [lastNameState, setLastNameState] = useState(lastName);


  const changeFirstName = (e) => {
    setFirstName(e.target.value)
  }
  const changeMiddleName = (e) => {
    setMiddleName(e.target.value)
  }
  const changeLastName = (e) => {
    setLastNameState(e.target.value)
  }

  const sendNewData = (inputsData) => {
    // here we will make request
  }

  return (
    <div className="EditNamePage">
      <Link to="/account-settings" className="EditNamePage-link">
        <ArrowLeftOutlined className="EditNamePage-link-icon"/>Account Settings
      </Link>
      <h1 className="EditNamePage-title">Edit Name</h1>
      <Form
        className="EditNamePage-form"
        layout="vertical"
        onFinish={sendNewData}
      >
        <div className={"EditNamePage-form_firstName"}>
          <Form.Item
            name={"firstName"}
            label="First Name"
            className="EditNamePage-form-box-item"
          >
            <Input
              className="EditNamePage-form-box-item-input"
              value={firstName}
              onChange={changeFirstName}
            />
          </Form.Item>
        </div>

        <div className={"EditNamePage-form_middleName"}>
          <Form.Item
            name={"middleName"}
            label="Middle Name"
            className="EditNamePage-form-box-item"
          >
            <Input
              className="EditNamePage-form-box-item-input"
              value={middleName}
              onChange={changeMiddleName}
            />
          </Form.Item>
        </div>

        <div className={"EditNamePage-form_lastName"}>
          <Form.Item
            name={"lastName"}
            label="Last Name"
            className="EditNamePage-form-box-item"
          >
            <Input
              className="EditNamePage-form-box-item-input"
              value={lastNameState}
              onChange={changeLastName}
            />
          </Form.Item>
        </div>
        <SettingsButton buttonText={"Save Name"}/>
      </Form>
    </div>
  )
}

export default EditNamePage;
