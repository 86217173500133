import iconHelper from "../../assets/image/iconHelper.svg"
import React from "react"
import { FieldInterface } from "../../Redux/InterfacesEntity/field.interface"
import { setActiveInput, setValueModal } from "../../Redux/store/form/form.actions"
import { FieldsType } from "../../Components/FormComponents/FieldsComponents/DataGroupDropDownComponent/types"
import GText from "../../Components/atomicDesign/atoms/Text/Text"
import { SIZE } from "../../Components/atomicDesign/types/types"
import { COLORS, COLORS_NEW } from "../../Components/atomicDesign/types/Colors"

const showOnModalHandler = (field: {} | any, modalStateName: string, dispatch: any) => {
  dispatch(setActiveInput(field))
  dispatch(setValueModal(modalStateName))
}

export const getLabelWrapper = (
  field: FieldInterface | FieldsType,
  dispatch: any,
  additionalStep: string | boolean | undefined,
  getDynamicVariable?: any,
  tagStoreWithFields?: { [key: string]: string }
) => {
  return (
    <div className={`helper-icon__label-container`}>
      <GText
        text={getDynamicVariable?.(field, tagStoreWithFields) ?? field.label}
        size={SIZE.PARAGRAPH_BOLD_16}
        color={COLORS_NEW.BLACK_900}
      />
      {field.helper && !additionalStep && (
        <div
          className={`helper-icon__container`}
          onClick={() => showOnModalHandler && showOnModalHandler(field, "helper-mobile", dispatch)}
        >
          <img src={iconHelper} alt={"info"} />
        </div>
      )}
    </div>
  )
}

export const getLabel = (label: string) => {
  return <GText text={label} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
}
