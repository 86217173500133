import {FORM_ITEM_STATUSES} from "../organisms/FormItem/FormItem";
import {INPUT_STATUS} from "../types/types";

export function getInputStatusFromItemStatus(itemStatus: string | undefined): string {
    switch (itemStatus) {
        case FORM_ITEM_STATUSES.ERROR:
            return INPUT_STATUS.ERROR
        case FORM_ITEM_STATUSES.SUCCESS:
            return INPUT_STATUS.SUCCESS
        default:
            return INPUT_STATUS.DEFAULT
    }
}