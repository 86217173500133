import React from "react"

interface ISgvAuthSignIn {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
}

export const SgvAuthSignIn = ({ width, height, color, backgroundColor }: ISgvAuthSignIn) => {
  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx="32" fill={backgroundColor} />
      <path
        d="M40 41V39C40 37.9391 39.5786 36.9217 38.8284 36.1716C38.0783 35.4214 37.0609 35 36 35H28C26.9391 35 25.9217 35.4214 25.1716 36.1716C24.4214 36.9217 24 37.9391 24 39V41"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 31C34.2091 31 36 29.2091 36 27C36 24.7909 34.2091 23 32 23C29.7909 23 28 24.7909 28 27C28 29.2091 29.7909 31 32 31Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
