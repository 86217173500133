import TemplateOneBuilder from "../TemplateOneBuilder";
import {ITemplateOneBuilder} from "./ITemplateOneBuilder";
import {IUpsellProcessingData} from "../../../StepUpsell";
import TemplateOne from "../TemplateOne";
import {getExpeditedProcessingImage} from "../../../../../../../../util/UpsellHelper";

export class ExpeditedProcessingUpsell implements ITemplateOneBuilder {
    buildTemplate(builder: TemplateOneBuilder, data: IUpsellProcessingData, solutionName: string): TemplateOne {
        builder
            .setImageHeader(getExpeditedProcessingImage(solutionName))
            .setPreviousPrice('77')
            .setTagInformation('Last chance upgrade')
        return builder.build();
    }
}