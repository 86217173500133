import { Select } from "antd"
import { Moment } from "moment"
import { criterionTypes } from "./constants"
import { CriterionType, MinMaxType, MonthsType } from "./types"

export const optionsArrayFromMinMax = (min: number | undefined, max: number | undefined, isReversed?: boolean) => {
  if (min && max) {
    const newArr: JSX.Element[] = []
    const updateNewArr = (i: number) => {
      const c = (
        <Select.Option key={i} value={i}>
          {i}
        </Select.Option>
      )
      newArr.push(c)
    }

    if (isReversed) {
      for (let i = max; i >= min; i -= 1) {
        updateNewArr(i)
      }
    } else {
      for (let i = min; i <= max; i += 1) {
        updateNewArr(i)
      }
    }

    return newArr
  }
}
export const setStartMinMax = (criterions: CriterionType[] | undefined, thisMoment: Moment) => {
  const thisYear = thisMoment.year()
  let expirationMoment: Moment | undefined
  let minAgeMoment: Moment | undefined
  let maxAgeMoment: Moment | undefined

  let minStartYear = thisYear - 150
  let maxStartYear = thisYear
  let minStartDate = 1
  let maxStartDate = 31
  let minStartMonth = 1
  let maxStartMonth = 12

  if (criterions && criterions.length) {
    const { AGE, YEAR, DATE, MONTH, EXPIRATION } = criterionTypes

    criterions.forEach(({ type, value, isMax }) => {
      if (isMax) {
        switch (type) {
          case YEAR:
            maxStartYear = value
            break
          case DATE:
            maxStartDate = value
            break
          case MONTH:
            maxStartMonth = value
            break
          case AGE:
            minStartYear = thisYear - +value
            maxAgeMoment = thisMoment.subtract(+value, "years")
            break
          case EXPIRATION:
            minStartYear = thisYear
            maxStartYear = thisYear + +value
            expirationMoment = thisMoment.add(+value, "years")
            break
          default:
            break
        }
      } else {
        switch (type) {
          case YEAR:
            minStartYear = value
            break
          case DATE:
            minStartDate = value
            break
          case MONTH:
            minStartMonth = value
            break
          case AGE:
            maxStartYear = thisYear - +value
            minAgeMoment = thisMoment.subtract(+value, "years")
            break
          case EXPIRATION:
            minStartYear = thisYear
            maxStartYear = thisYear + +value
            expirationMoment = thisMoment.add(+value, "years")
            break
          default:
            break
        }
      }
    })
  }

  return {
    minStartYear,
    maxStartYear,
    minStartDate,
    maxStartDate,
    minStartMonth,
    maxStartMonth,
    expirationMoment,
    maxAgeMoment,
    minAgeMoment,
  }
}

export const getMonthDate = (
  defaultMonths: {
    index: number,
    min: number,
    max: number,
    name: string,
  }[],
  minMonth: MinMaxType,
  maxMonth: MinMaxType,
  minDate: MinMaxType,
  maxDate: MinMaxType
) => {
  const monthDate = []
  let minMonthIndex = 1
  let maxMonthIndex = 12
  if (minMonth) {
    minMonthIndex = minMonth < 1 && minMonth > 12 ? 0 : minMonth - 1
  }
  if (maxMonth) {
    maxMonthIndex = maxMonth < 1 && maxMonth > 12 ? 12 : maxMonth
  }

  for (let index = minMonthIndex; index < maxMonthIndex; index += 1) {
    const monthObj = defaultMonths[index]

    let { min, max } = monthObj
    if (minDate) {
      min = +minDate > min && +minDate < max ? +minDate : min
    }
    if (maxDate) {
      max = +maxDate < max && +maxDate > min ? +maxDate : max
    }

    monthDate.push(monthObj)
  }

  return monthDate
}
export const updateMaxDay = (newMonthsData: MonthsType) => {
  let maxDay = 0
  newMonthsData.forEach(({ max }) => {
    if (max > maxDay) {
      maxDay = max
    }
  })
  return maxDay
}

export const checkPatternValidity = (dateString: string) => {
  return new RegExp('[0-9]{2}/[0-9]{2}/[0-9]{4}').test(dateString)
}

export const capitalizeFirstLetter = (string: string) => {
  return string.split('')[0].toUpperCase() + string.slice(1);
}