import React, { FC } from "react"
import "./RecordCount.scss"
import { RecordCountProps } from "./RecordCount.props"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS_NEW } from "../../types/Colors"

const GRecordCount: FC<RecordCountProps> = ({ currentStep, totalSteps }) => {
  return (
    <div className={"GRecordCount"}>
      <div className={"GRecordCount__Steps"}>
        <GText text={`${currentStep}/${totalSteps}`} size={SIZE.PARAGRAPH_BOLD_12} color={COLORS_NEW.ROYAL_BLUE_700} />
      </div>
    </div>
  )
}

export default GRecordCount
