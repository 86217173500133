import IErrorLog from "./IErrorLog";
import IErrorExternalAPIs from "./ExternalAPIs/IErrorExternalAPIs";
import SentryIO from "./ExternalAPIs/SentryIO";
import DefaultApi from "./ExternalAPIs/DefaultApi";

/**
 * ErrorLog is a service that is responsible for logging errors and messages to an external API.
 */
export default class ErrorLog implements IErrorLog {

    MESSAGE = "ErrorLog: Must Initialize ErrorLog first";

    /**
     * The instance of the error log.
     * @type {ErrorLog}
     * @private
     */
    private static _instance: ErrorLog;

    /**
     * The external API that will be used to log errors and messages.
     * @type {IErrorExternalAPIs}
     * @private
     */
    private readonly _handler: IErrorExternalAPIs | null;

    /**
     * Creates an instance of ErrorLog.
     * @param handler
     * @private
     */
    private constructor(handler: IErrorExternalAPIs) {
        this._handler = handler;
        this._handler.init();
    }

    /**
     * Gets the instance of the error log.
     * This function is used to initialize the error log if it has not been initialized.
     *
     * @param key
     * @param environment
     */
    static getInstance(key: string, environment: string): ErrorLog {
        if(!ErrorLog._instance) {
            return new ErrorLog(new DefaultApi());
        }
        return ErrorLog._instance;
    }

    /**
     * Notifies the external API of an error.
     * @param error
     * @returns The ID of the error.
     */
    notifyError(error: Error): string {
        return this._handler ? this._handler.captureError(error) : this.MESSAGE;
    }

    /**
     * Notifies the external API of a message.
     * @param message
     * @returns The ID of the message.
     */
    notifyMessage(message: string): string {
        return this._handler ? this._handler.captureMessage(message) : this.MESSAGE;
    }
}