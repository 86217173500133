export const dividerSNN = (submitObject: any) => {
  if (submitObject["form_id"].indexOf("ssn") !== -1) {
    let ssn_applicant = submitObject.ssn_applicant
    let ssn_mother_num = submitObject.ssn_mother_num
    let ssn_father_num = submitObject.ssn_father_num

    let ssn_father_unknown = submitObject.ssn_father_unknown
    let ssn_mother_unknown = submitObject.ssn_mother_unknown

    let ssn_applicant1,
      ssn_applicant2,
      ssn_applicant3,
      ssn_mother_num1,
      ssn_mother_num2,
      ssn_mother_num3,
      ssn_father_num1,
      ssn_father_num2,
      ssn_father_num3

    if (ssn_applicant && ssn_applicant.indexOf("-") !== -1) {
      const arr = ssn_applicant.split("-")
      ssn_applicant = arr.reduce((acc: string, item: string) => acc + item, "")
      ssn_applicant1 = arr[0]
      ssn_applicant2 = arr[1]
      ssn_applicant3 = arr[2]
    }

    if (ssn_mother_num && ssn_mother_num.indexOf("-") !== -1) {
      const arr = ssn_mother_num.split("-")
      ssn_mother_num = arr.reduce((acc: string, item: string) => acc + item, "")
      ssn_mother_num1 = arr[0]
      ssn_mother_num2 = arr[1]
      ssn_mother_num3 = arr[2]
    }

    if (ssn_father_num && ssn_father_num.indexOf("-") !== -1) {
      const arr = ssn_father_num.split("-")
      ssn_mother_num = arr.reduce((acc: string, item: string) => acc + item, "")
      ssn_father_num1 = arr[0]
      ssn_father_num2 = arr[1]
      ssn_father_num3 = arr[2]
    }

    return {
      ssn_applicant: ssn_applicant || "",
      ssn_applicant1: ssn_applicant1 || "",
      ssn_applicant2: ssn_applicant2 || "",
      ssn_applicant3: ssn_applicant3 || "",
      ssn_mother_num: ssn_mother_num || "",
      ssn_mother_num1: ssn_mother_num1 || "",
      ssn_mother_num2: ssn_mother_num2 || "",
      ssn_mother_num3: ssn_mother_num3 || "",
      ssn_father_num: ssn_father_num || "",
      ssn_father_num1: ssn_father_num1 || "",
      ssn_father_num2: ssn_father_num2 || "",
      ssn_father_num3: ssn_father_num3 || "",
      ssn_father_unknown: ssn_father_unknown || "N",
      ssn_mother_unknown: ssn_mother_unknown || "N",
    }
  } else return {}
}
