import React, {FC, ReactNode, useState} from "react"
import { Select } from "antd"
import "./Select.scss"
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes";
import {COLORS} from "../../types/Colors";
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg";

export interface IOption {
  value: string;
  label: string;
}

type GSelectProps = {
  children?:ReactNode
  placeHolder?: string,
  icon?: ReactNode,
  onChange?: any,
  value?: string,
  disabled?: boolean,
  onSelect?: any,
  width?: string,
  height?: string,
  options?: any,
  showSearch?: boolean,
  filterOption?: any,
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>,
  searchValue?: string,
  onSearch?: (value: string) => void,
  mode?: "multiple" | "tags" | undefined,
  showArrow?: boolean,
  allowClear?: boolean,
  defaultValue?: any
}

const GSelect: FC<GSelectProps> = ({
  placeHolder = "Select",
  icon,
  onChange,
  value,
  disabled,
  onSelect,
  options,
  width,
  height,
  showSearch,
  filterOption,
  onMouseEnter,
  searchValue,
  onSearch,
  mode,
  showArrow = true,
  allowClear = false,
  defaultValue,
  children
}) => {
  const [selectDropDownOpen, setSelectDropDownOpen] = useState(false)

  const dropdownStyle: React.CSSProperties = {
    maxWidth: "250px",
  }
  const selectStyle: React.CSSProperties = {
    width: width ?? "100%",
    height: height ?? "44px",
  }

  const onDropdownVisibleChange = (open: boolean) => {
    setSelectDropDownOpen(open)
  }

  return (
        <Select
            defaultValue={defaultValue?.value}
            className={`GSelect ${!showSearch && "GSelect--Rotated"}`}
            style={selectStyle}
            placeholder={placeHolder}
            suffixIcon={showSearch && selectDropDownOpen ? <GIconsSvgType type={ICONS_SVG_TYPE.SEARCH} color={COLORS.SLATE_GRAY_500} width={13} height={12} /> : icon}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={onChange}
            dropdownStyle={dropdownStyle}
            value={value}
            disabled={disabled}
            onSelect={onSelect}
            showSearch={showSearch}
            filterOption={filterOption}
            onMouseEnter={onMouseEnter}
            virtual={false}
            mode={mode}
            onSearch={onSearch}
            showArrow={showArrow}
            allowClear={allowClear}
            onDropdownVisibleChange={onDropdownVisibleChange}
        >
          {options &&
              options.map((item: any, index: number) => {
                const value = 'value' in item ? item.value  : item.props.value
                let label = 'label' in item? item.label : item.props.children?.toString()
                if(!label) label = value
                return (
                    <Select.Option key={index}  value={value} >
                      {label}
                    </Select.Option>
                )
              })}
        </Select>
  )
}

export default GSelect
