import TemplateOneBuilder from "../TemplateOneBuilder";
import {ITemplateOneBuilder} from "./ITemplateOneBuilder";
import {IUpsellProcessingData} from "../../../StepUpsell";
import TemplateOne from "../TemplateOne";
import {getExpeditedShippingImage} from "../../../../../../../../util/UpsellHelper";

export class ExpeditedShippingUpsell implements ITemplateOneBuilder{
    buildTemplate(builder: TemplateOneBuilder, data: IUpsellProcessingData, solutionName: string): TemplateOne {
       builder
           .setImageHeader(getExpeditedShippingImage(solutionName))
           .setPreviousPrice('77')
           .setCheckListItems([])
           .setTagInformation('Last chance upgrade')
       return builder.build();
    }
}