import React, { useEffect, useState } from "react"
import { Form, Checkbox } from "antd"
import { ValidationItemInterface } from "../../../../Redux/InterfacesEntity/validation.interface"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../App"
import { ModalContentInterface } from "../../../ModalWindows/PrivacyPolicyComponent/PrivacyPolicyComponent"
import { arrayStepSelector } from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"

type CheckBoxAgreeComponentProps = {
  field: FieldInterface,
  validationRule: ValidationItemInterface | null,
  value: string,
}

// eslint-disable-next-line max-len
const CheckBoxAgreeComponent: React.FunctionComponent<CheckBoxAgreeComponentProps> = ({ field }) => {
  const [checked, setChecked] = useState(false)

  const dispatch = useDispatch()

  const onCheckboxChange = async (e: any) => {
    await setChecked(e.target.checked)
    dispatch(setValueCurrentField(field.name, e.target.checked ? "on" : "off"))
  }

  const validation = (rule: any, value: any, callback: (error?: string) => void) => {
    if (checked) {
      return callback()
    }
    return callback("Please accept the terms and conditions")
  }

  interface getLinksOnWordPressPagesInterface {
    (classNameProps: string): string | undefined;
  }

  const modalContent = useSelector<RootState, { modalContent: ModalContentInterface | null }>(
    arrayStepSelector
  )?.modalContent

  const checkBoxType = field.type === "checkBoxAgree" ? "check-box__agree" : ""

  const getLinksOnWordPressPages = (classNameProps: string) =>
    [
      { className: "terms-of-use", keyStore: "termOfUse" },
      { className: "privacy-policy", keyStore: "privacyPolicy" },
    ].find(({ className }) => className === classNameProps)?.keyStore

  // it was modal, but now it is simple link to wordpress page
  const showOnModal = (
    e: any,
    name: string,
    modalContent: ModalContentInterface | null,
    getLinksOnWordPressPages: getLinksOnWordPressPagesInterface
  ) => {
    if (e.target.className === name) {
      // @ts-ignore
      e.target.href = modalContent?.[getLinksOnWordPressPages(name)]?.wpLink
      e.target.click()
      // dispatch(setValueModal(name))
    }
  }

  useEffect(() => {
    const termsLink = document.querySelector(".terms-of-use")
    const privacyLink = document.querySelector(".privacy-policy")
    if (termsLink || privacyLink) {
      ;[termsLink, privacyLink]
        .filter((item: any) => item)
        .forEach((item: any) => {
          document.addEventListener("click", (e) =>
            showOnModal(e, item.className, modalContent, getLinksOnWordPressPages)
          )
        })
      return () => {
        ;[termsLink, privacyLink].forEach((item: any) => {
          document.removeEventListener("click", (e) =>
            showOnModal(e, item.className, modalContent, getLinksOnWordPressPages)
          )
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form.Item name={field.name} rules={[{ validator: validation }]} className={`check-box ${checkBoxType}`}>
      <Checkbox checked={checked} onChange={onCheckboxChange} className={`check-box__content-container`}>
        <p dangerouslySetInnerHTML={{ __html: field.description }} className={`check-box__content-text`} />
      </Checkbox>
    </Form.Item>
  )
}

// const mapStateToProps = (state: any) => ({})

export default CheckBoxAgreeComponent
