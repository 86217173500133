import React, { FC } from "react"
import "./DashedButton.scss"
import { DashedButtonProps } from "./DashedButton.props"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS_NEW } from "../../types/Colors"

const GDashedButton: FC<DashedButtonProps> = ({ label, click }) => {
  return (
    <button className={"GDashedButton"} onClick={click}>
      <div className={"GDashedButton__Label"}>
        <GIconsSvgType type={ICONS_SVG_TYPE.ICON_PLUS} width={25} height={25} />
        <GText text={label} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_500} />
      </div>
    </button>
  )
}

export default GDashedButton
