import {useState} from "react";


export function useRequirementsValidation() {
  const [passLengthsStatus, setPassLengthsStatus] = useState('default');
  const [passUpperStatus, setPassUpperStatus] = useState('default');
  const [passNumberStatus, setPassNumberStatus] = useState('default');
  const [passSymbolStatus, setPassSymbolStatus] = useState('default')

  const falseSwitcher = (type) => {
    switch (type) {
      case 'onChange/onFocus':
        return 'default'
      case 'onBlur':
        return 'notValid'
      default: return null
    }
  }

  const validator = (password, type) => {
    password.length >= 8
      ? setPassLengthsStatus('valid')
      : setPassLengthsStatus(falseSwitcher(type));
    /[A-Z]/.test(password)
      ? setPassUpperStatus('valid')
      : setPassUpperStatus(falseSwitcher(type));
    /\d/g.test(password)
      ? setPassNumberStatus('valid')
      : setPassNumberStatus(falseSwitcher(type));
    new RegExp(/[-±ˆ!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/).test(password)
      ? setPassSymbolStatus('valid')
      : setPassSymbolStatus(falseSwitcher(type));
  }

  return [passLengthsStatus, passUpperStatus, passNumberStatus, passSymbolStatus, validator]
}