import React, { FC } from "react"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import ItemSelectGroupComponent from "./ItemSelectGroupComponent "
import Form from "antd/lib/form"
import {getLabel} from "../../../../services/service-function/field";

type SelectGroupComponentProps = {
  field: FieldInterface,
}

const SelectGroupComponent: FC<SelectGroupComponentProps> = ({ field: { type, fields, label, name } }) => {
  return (
    <Form.Item label={getLabel(label)} name={name}>
      <div className={`${type}`}>
        {fields?.map((subField: FieldInterface) => (
          <React.Fragment key={subField.name}>
            <ItemSelectGroupComponent field={subField} />
          </React.Fragment>
        ))}
      </div>
    </Form.Item>
  )
}

export default SelectGroupComponent
