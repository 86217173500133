import {call, put, select, takeEvery} from "redux-saga/effects"
import {
  ActionTypes,
  getFailureAction, refreshTaxReturnInformation,
  setAdditionalDocuments,
  setClearTempDocuments,
  setDocuments,
  setErrorBarValue,
  setLinkValue,
  setStatusQuestionary,
  setStatusSignatureOpen,
  setStatusUploadAdditionalDocuments,
  setStatusUploadDocuments, setTaxReturnInformation,
  setUpsellInformation,
} from "./additional.actions"
import Service from "../../../services/form-service"
import {getAdditionalArraySteps, setCurrentResponse} from "../step/step.actions"
import {setLoadingButtonState, setLoadingState} from "../loading/loading.action"
import {LoadingState, LoadingStateUploadDocument} from "../../../shared/constans/user-from-view-mode.enum"
import {setPackageResponse} from "../packagePages/packagePages.action"
import {getPath, getSuccess} from "../form/form.saga"
import {
  setPaymentError,
  setPaymentMethod,
  setPDF, setQuestionary,
  setServicesCustomer,
  setSubmitFormError,
  setStatusOnSubmitObject,
  setValueCurrentField, setSubmitObject,
} from "../form/form.actions"
import {setUploadDocuments} from "../upload/upload.actions"
import {
  arrayExtensionsCorrectUploadFile,
  checkCorrectExtensionUploadFile,
  MAX_SIZE,
  setStatusSignaturePageSaga,
} from "../upload/upload.saga"
import {checkLaunchFunctionByPageName, setStatusesCommonProducts} from "../../../services/statusesService/setStatuses"
import {arrayPagesName} from "../../../services/statusesService/dateByStatuses"
import {getClientTokenForResponse, getRoot} from "../step/service.step.saga"
import {getPropertiesForEvents} from "../../../services/gtm/backEndEvent"
import {putUpsellProcessingInformation} from "./UpsellProcessing";
import {
  OfferAction,
  OfferType,
  PaymentError,
  PaymentRequestToken,
  SubscriptionStatuses
} from "../../../Components/atomicDesign/types/types";
import OrderCompletedBuilder
  from "../../../Components/atomicDesign/analytic/event/OrderCompleted/OrderCompletedBuilder";
import UserIpRepository from "../../../persistence/session/UserIpRepository";
import GAnalyticEvents from "../../../Components/atomicDesign/analytic/AnalyticEvents";
import {putBillingInformation} from "../billing/BillingInformation";
import {PurchaseOffer} from "../../../util/PurchaseOffer";
import FormIdRepository from "../../../persistence/session/FormIdRepository";
import {buildPaymentError} from "../../../dto/PaymentError";
import {AdditionalSagaHelper} from "./AdditionalSagaHelper";
import UtmRepository from "../../../persistence/cookies/UtmRepository";
import {productsApis, subscriptionService, userService} from "../../../../../dashboard-govplus-front/src/api/api";
import {EventsHelper} from "../../../util/EventsHelper";
import {ColumnTaxHelper} from "../../../util/ColumnTaxHelper";
import {useHelperSubscription} from "../../../../../dashboard-govplus-front/src/hooks/useHelperSubscription";
import OrderCompleted from "../../../Components/atomicDesign/analytic/event/OrderCompleted/OrderCompleted";
import {FormSagaHelper} from "../form/FormSagaHelper";

const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time))

function* render(success: {} | any) {
  yield put(setPackageResponse(success))
  window.scrollTo(0, 0)
}

function* approveAdressSaga(actions: any): any {
  try {
    yield put(setLoadingButtonState(LoadingState.Loading))
    yield put(getFailureAction(""))
    const { client_token } = yield getClientTokenForResponse()
    const submitObj = yield { ...actions.payload, client_token, form_id: FormIdRepository.getInstance().getValue() }
    const response = yield Service.updateAddress(submitObj)
    const success = yield getSuccess(response)
    yield put(setValueCurrentField("type", ""))
    if (response.error) {
      let message = ""
      if (response.error?.data) {
        for (let key in response.error.data) {
          message += response.error.data[key][0] + ". "
        }
      }
      throw new Error(message)
    } else if (response.success?.verifiedAddress?.error) {
      window.scrollTo(0, 0)
      yield put(setCurrentResponse(success))
      yield put(getAdditionalArraySteps(success?.page))
      yield call(setStatusSignaturePageSaga, success)
      if (success?.client_token) {
        yield call(AdditionalSagaHelper.removeClientToken);
        yield call(AdditionalSagaHelper.setClientToken, success?.client_token);
        yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
      }
      throw new Error(response.success?.verifiedAddress?.error)
    } else {
      window.scrollTo(0, 0)
      yield put(setCurrentResponse(success))
      if(success?.pdf){
        yield put(setPDF(success?.pdf))
      }
      yield put(getAdditionalArraySteps(success?.page))
      yield call(setStatusSignaturePageSaga, success)
      if (success?.client_token) {
        yield call(AdditionalSagaHelper.removeClientToken);
        yield call(AdditionalSagaHelper.setClientToken, success?.client_token);
        yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
      }
      if (success?.services) {
        yield put(setServicesCustomer(success?.services))
      }
      if (success?.selectedServices) {
        yield put(setCurrentResponse(success?.selectedServices))
      }
      yield put(setLoadingState(LoadingState.Loaded))
      yield put(setLoadingButtonState(LoadingState.Loaded))
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(getFailureAction(e.message))
    }
    yield put(setLoadingState(LoadingState.Loaded))
    yield put(setLoadingButtonState(LoadingState.Loaded))
  }
}

function* sendSignatureSaga(actions: any): any {
  try {
    const { client_token } = yield getClientTokenForResponse()
    const submitObj = { ...actions.payload, client_token, form_id: FormIdRepository.getInstance().getValue() }
    const additionalSteps = { ...submitObj.additionalSteps }
    delete submitObj.dataPricing
    delete submitObj.additionalSteps
    yield put(setLoadingButtonState(LoadingState.Loading))
    const response = yield Service.submitAdditionalForm(submitObj)
    const success = yield getSuccess(response)
    if (response.error) {
      throw new Error("Error")
    }
    if (success) {
      yield render(success)
    }
    if (success?.status) {
      yield put(setStatusOnSubmitObject(success.status))
    }
    if (success.page) {
      yield put(getAdditionalArraySteps(success.page))
    }
    if (success?.dashLink) {
      /* Event Processing Completed */
      /* Event Check Status */
      yield delay(3000)
      yield window.location.href = `${getRoot()}${success?.dashLink}`
    }
    if (success.link) {
      yield put(setLinkValue(success.link))
    }
    if (success.relationship) {
      yield put(setValueCurrentField("relationship", success.relationship))
    }
    if (success.uploadData) {
      yield put(setUploadDocuments(success.uploadData))
    }
    if (success?.client_token) {
      yield call(AdditionalSagaHelper.removeClientToken);
      yield call(AdditionalSagaHelper.setClientToken, success?.client_token);
      yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
    }
    if (success?.billingInformation) {
      yield putBillingInformation(success.billingInformation)
    }
    yield call(setStatusSignaturePageSaga, success)
    sessionStorage.setItem("statusSignature", "completed")
    yield call(setStatusesCommonProducts, additionalSteps, success.status, success.page)
    yield put(setLoadingButtonState(LoadingState.Loaded))
    yield put(setStatusSignatureOpen(""))
    const objUpdated = yield call(FormSagaHelper.updatePropertyForFilterStep, success);
    if(Object.keys(objUpdated).length > 0) {
      yield put(setSubmitObject(objUpdated))
    }
  } catch (e) {
    yield put(setLoadingState(LoadingState.Error))
    // console.log(e)
    yield put(setLoadingState(LoadingState.Loaded))
    yield put(setLoadingButtonState(LoadingState.Loaded))
  }
}

function* submitAdditionalFormSaga(actions: any): any {
  try {
    const { client_token } = yield getClientTokenForResponse()
    const submitObj = { ...actions.payload, client_token, form_id: FormIdRepository.getInstance().getValue() }
    delete submitObj.dataPricing
    yield put(setLoadingButtonState(LoadingState.Loading))
    window.scrollTo(0, 0)
    const response = yield Service.submitAdditionalForm(submitObj)
    const success = yield getSuccess(response)
    if (response.error) {
      throw new Error("Error")
    }

    // Insert logic to continue the steps flow in case of a NAM Application
    if (submitObj?.form_id?.includes('nam')) {
      if(success?.checkAddress) {
        yield put(setCurrentResponse(success.checkAddress))
      }
      if(success?.questions) {
        yield put(setQuestionary(success?.questions?.questionnaire))
      }
      if (success?.uploadData) {
        yield put(setUploadDocuments(success.uploadData))
      }
      const store = yield select((s: any) => s)
      const {additionalSteps} = yield store.step
      if (yield call(checkLaunchFunctionByPageName, arrayPagesName, success?.page)) {
        yield call(setStatusSignaturePageSaga, success)
        yield call(setStatusesCommonProducts, additionalSteps, success?.status, success?.page)
      }
    }

    if (success?.status) {
      yield put(setStatusOnSubmitObject(success.status))
    }

    if (success?.dashLink) {
      yield delay(3000)
      yield window.location.href = `${getRoot()}${success?.dashLink}`
    }
    window.scrollTo(0, 0)
    if (success?.client_token) {
      yield call(AdditionalSagaHelper.removeClientToken);
      yield call(AdditionalSagaHelper.setClientToken, success?.client_token);
      yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
    }
    if (success?.billingInformation) {
      yield putBillingInformation(success.billingInformation)
    }

    yield put(setLoadingButtonState(LoadingState.Loaded))
    if (success?.status) {
      yield call(setStatusSignaturePageSaga, success)
    }
  } catch (e) {
    yield put(setErrorBarValue(true))
    yield put(setLoadingButtonState(LoadingState.Loaded))
  }
}

function* submitPassportDetailsAdditionalFormSaga({ payload: submitObj }: any): any {
  try {
    const { client_token } = yield getClientTokenForResponse()
    const newSubmitObj = { ...submitObj, client_token, form_id: FormIdRepository.getInstance().getValue() }
    const { fields: submitFields } = newSubmitObj
    /**
     * Insert passport_type field on passport forms
     * since the field needs to be "book" by default
     * and can't be selected anymore
     */
    if (submitFields?.form_id?.includes('passport')) {
      submitFields.passport_type = "book"
    }
    yield put(setLoadingButtonState(LoadingState.Loading))
    const response = yield Service.submitAdditionalForm(newSubmitObj)
    if (response.error) {
      yield put(setSubmitFormError(response))
      throw new Error("Error")
    }
    const { success } = response
    const store = yield select((s: any) => s)
    const { additionalSteps } = yield store.step
    if (submitFields["form_type"]) {
      window.scrollTo(0, 0)
    } else {
      window.scrollTo(0, 500)
    }
    if (success?.status) {
      yield put(setStatusOnSubmitObject(success.status))
    }
    if (success.uploadData) {
      yield put(setUploadDocuments(success.uploadData))
    }
    if(success?.pdf) {
      yield put(setPDF(success?.pdf))
    }
    if (yield call(checkLaunchFunctionByPageName, arrayPagesName, success?.page)) {
      yield call(setStatusSignaturePageSaga, success)
      yield call(setStatusesCommonProducts, additionalSteps, success?.status, success?.page)
    }
    if (success?.client_token) {
      yield call(AdditionalSagaHelper.removeClientToken);
      yield call(AdditionalSagaHelper.setClientToken, success?.client_token);
      yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
    }

    if (success?.billingInformation) {
      yield putBillingInformation(success.billingInformation)
    }

    yield put(setLoadingButtonState(LoadingState.Loaded))
  } catch (e) {
    // console.log(e)
    yield put(setLoadingButtonState(LoadingState.Loaded))
    yield put(setErrorBarValue(true))
    const { fields: submitFields } = submitObj
    if (submitFields["form_type"]) {
      yield put(setStatusQuestionary("process"))
    }
  }
}

function* submitAdditionalFormSagaCOA(actions: any): any {
  try {
    const { client_token } = yield getClientTokenForResponse()
    const submitObj = { ...actions.payload, client_token, form_id: FormIdRepository.getInstance().getValue() }
    delete submitObj.dataPricing
    yield put(setLoadingButtonState(LoadingState.Loading))
    yield delay(3000)
    window.scrollTo(0, 0)
    const response = yield Service.submitAdditionalForm(submitObj)
    const success = yield getSuccess(response)
    if (response.error) {
      throw new Error("Error")
    }
    if (success?.status) {
      yield put(setStatusOnSubmitObject(success.status))
    }
    const store = yield select((s: any) => s)
    const { additionalSteps } = yield store.step
    if (yield call(checkLaunchFunctionByPageName, arrayPagesName, success?.page)) {
      yield call(setStatusSignaturePageSaga, success)
      yield call(setStatusesCommonProducts, additionalSteps, success?.status, success?.page)
    }
    if ((success.checkAddress && success.checkAddress.status) || success.page) {
      yield put(getAdditionalArraySteps(success.page))
    }
    if (success.selectedServices) {
      yield put(setCurrentResponse(success.selectedServices))
    }
    if (success?.client_token) {
      yield call(AdditionalSagaHelper.removeClientToken);
      yield call(AdditionalSagaHelper.setClientToken, success?.client_token);
      yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
    }
    yield put(setLoadingButtonState(LoadingState.Loaded))
  } catch (e) {
    // console.log(e)
    yield put(setErrorBarValue(true))
    yield put(setLoadingButtonState(LoadingState.Loaded))
  }
}

function* submitlocalStorageSaga(action: any): any {
  try {
    const payload = typeof action.payload === "object" ? { ...action.payload } : { signature: action.payload }
    if (yield sessionStorage.getItem("submitAdditional")) {
      const submitAdditional = JSON.parse(localStorage.getItem("submitAdditional") || "{}")
      const newSubmitAdditional = { ...submitAdditional, ...payload }
      yield sessionStorage.setItem("submitAdditional", JSON.stringify(newSubmitAdditional))
    } else {
      yield sessionStorage.setItem("submitAdditional", JSON.stringify(payload))
    }
  } catch (e) {
    // console.log(e)
  }
}

function* submitAdditionalData(actions: any): any {
  try {
    const { client_token } = yield getClientTokenForResponse()
    const submitObj = { ...actions.payload,
       client_token, form_id: FormIdRepository.getInstance().getValue(),
       userIp: UserIpRepository.getInstance().getValue()  }
    delete submitObj.dataPricing
    yield put(setLoadingButtonState(LoadingState.Loading))
    window.scrollTo(0, 0)
    const response = yield Service.submitAdditionalForm(submitObj)
    const success = yield getSuccess(response)
    if (response.error) {
      throw new Error("Error")
    }
    if (success?.status) {
      yield put(setStatusOnSubmitObject(success.status))
    }
    const store = yield select((s: any) => s)
    const { additionalSteps } = yield store.step
    if (yield call(checkLaunchFunctionByPageName, arrayPagesName, success?.page)) {
      yield call(setStatusSignaturePageSaga, success)
      yield call(setStatusesCommonProducts, additionalSteps, success?.status, success?.page)
    }
    if ((success.checkAddress && success.checkAddress.status) || success.page) {
      yield put(getAdditionalArraySteps(success.page))
    }
    if (success.selectedServices) {
      yield put(setCurrentResponse(success.selectedServices))
    }
    if (success?.client_token) {
      yield call(AdditionalSagaHelper.removeClientToken);
      yield call(AdditionalSagaHelper.setClientToken, success?.client_token);
      yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
    }
    const objUpdated = yield call(FormSagaHelper.updatePropertyForFilterStep, success);
    if(Object.keys(objUpdated).length > 0) {
      yield put(setSubmitObject(objUpdated))
    }
    yield put(setLoadingButtonState(LoadingState.Loaded))
  } catch (e) {
    yield put(setErrorBarValue(true))
    yield put(setLoadingButtonState(LoadingState.Loaded))
  }
}


function* uploadDocumentsSaga(actions: any): any {
  try {
    if (actions.payload.typeDocument === "uploadingFile") {
      yield put(setStatusUploadDocuments(LoadingStateUploadDocument.PreLoad))
    } else {
      yield put(setStatusUploadAdditionalDocuments(LoadingStateUploadDocument.Process))
    }
    const {
      payload: { file: { name = "", size = "" } = {} },
    } = yield actions
    const extensionCurrentFile = yield name.split(".")[name.split(".").length - 1]
    if (
      name &&
      !(yield call(checkCorrectExtensionUploadFile, extensionCurrentFile, arrayExtensionsCorrectUploadFile))
    ) {
      throw new Error(LoadingStateUploadDocument.WrongFormat)
    }

    if (size > MAX_SIZE) {
      throw new Error(LoadingStateUploadDocument.MaxSizeError)
    }
    const {
      payload: { isFinalSubmit },
    } = actions
    //temporary
    if (actions.type === "[DOCUMENTS] Upload documents") {
      yield put(setDocuments(actions.payload))
      if (isFinalSubmit) {
        yield put(setStatusUploadDocuments("completed"))
        yield put(setStatusUploadAdditionalDocuments("process"))
      }
    } else {
      yield put(setAdditionalDocuments(actions.payload))
      if (isFinalSubmit) {
        yield put(setStatusUploadAdditionalDocuments("completed"))
      }
    }
    const { client_token } = yield getClientTokenForResponse()
    const submitObj = { ...actions.payload, client_token, form_id: FormIdRepository.getInstance().getValue() }
    const response = yield Service.updateDocument(submitObj)
    const { success } = response
    const store = yield select((s: any) => s)
    const { additionalSteps } = yield store.step
    yield put(setLoadingButtonState("loaded"))
    if (yield call(checkLaunchFunctionByPageName, arrayPagesName, success?.page)) {
      yield call(setStatusSignaturePageSaga, success)
      yield call(setStatusesCommonProducts, additionalSteps, success?.status, success?.page)
    }
    if (success?.client_token) {
      yield call(AdditionalSagaHelper.removeClientToken);
      yield call(AdditionalSagaHelper.setClientToken, success?.client_token);
      yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
    }

    if (success?.billingInformation) {
      yield putBillingInformation(success.billingInformation)
    }

    if (response.error) {
      throw new Error(response?.error?.data?.message ?? response?.error?.message)
    } else {
      const success = yield getSuccess(response)
      if (success.page) {
        yield put(getAdditionalArraySteps(success.page))
      }
      if (success.billingInformation) {
        yield putBillingInformation(success.billingInformation)
      }

      if (success?.dashLink) {
        /* Event Processing Completed */
        /* Event Check Status */
        yield delay(3000)
        yield window.location.href = `${getRoot()}${success?.dashLink}`
      }
    }
  } catch (e) {
    yield put(setLoadingButtonState("loaded"))
    // console.log(e)
    //@ts-ignore
    if (e.message === LoadingStateUploadDocument.FormatError) {
      yield put(setStatusUploadDocuments(LoadingStateUploadDocument.FormatError))
      if (actions.type === "[DOCUMENTS] Upload documents") {
        yield put(setDocuments(actions.payload))
        yield put(setStatusUploadDocuments(LoadingStateUploadDocument.FormatError))
      } else {
        yield put(setAdditionalDocuments(actions.payload))
        yield put(setStatusUploadAdditionalDocuments(LoadingStateUploadDocument.FormatError))
      }
    } else if (actions.type === "[DOCUMENTS] Upload documents") {
      yield put(setStatusUploadDocuments("process"))
      yield put(setStatusUploadAdditionalDocuments(""))
    } else {
      yield put(setStatusUploadAdditionalDocuments("process"))
    }
  }
}

function* clearTempDocumentsSaga(actions: any): any {
  try {
    yield put(setClearTempDocuments(actions.payload))
  } catch (e) {
    console.log(e)
  }
}

/**
 * List of pages that may be shown after the user completes a form with a success response.
 * TODO Move to another file in an enum. Check in json files if we are using all of them.
 */
const processingPages = [
  'sss_after3',
  'address_after',
  'passport_after',
  'passport_new_after',
  'name_change_after',
  'success_address',
  'tsa_questionnaire_page',
  "tsa_questionnaire_page_renewal",
  'funnel/process',
  'electronic_signature_page'
]

function* nextUpsellPageSaga(actions: any): any {
  let paymentRequest: PaymentRequestToken | null  = null;

  if(actions.payload.paymentRequestEvent){
    paymentRequest = actions.payload.paymentRequestEvent;
  }

  try {
    const { client_token } = yield getClientTokenForResponse()
    const { service } = yield getPropertiesForEvents()

    let submitObj = { ...actions.payload, client_token, serviceName: service }

    if(paymentRequest){
      submitObj.paymentRequestEvent = null;
      submitObj = {...submitObj, creditCardToken: paymentRequest.creditCardToken}
    }

    yield put(setLoadingButtonState(LoadingState.Loading))
    yield put(setPaymentError(null))

    let response;
    if(OfferAction.Accept === actions.payload.action){
      response = yield Service.acceptUpsellPage(submitObj)
    } else {
      response = yield Service.cancelUpsellPage(submitObj)
    }


    if (response.error) {
      yield putPaymentError(response?.error?.message)
      actions.payload.errorCallback?.(response.error.message || response.error.data?.error?.message)
    } else {
      const success = yield getSuccess(response)

      if (success.page === "upsell" && success.upsellInformation) {
        yield put(setUpsellInformation(success.upsellInformation))
      }

      yield putUpsellProcessingInformation(success)

      if (success.page) {
        yield put(getAdditionalArraySteps(success.page))
        yield call(setStatusSignaturePageSaga, success)
      }
      if (success?.status) {
        yield put(setStatusOnSubmitObject(success.status))
      }
      if (success?.dashLink) {
        /* Event Processing Completed */
        /* Event Check Status */
        yield delay(3000)
        yield window.location.href = `${getRoot()}${success?.dashLink}`
      }
      if (success?.client_token) {
        yield call(AdditionalSagaHelper.removeClientToken);
        yield call(AdditionalSagaHelper.setClientToken, success?.client_token);
        yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
      }

      if(success.billingInformation) {
        yield putBillingInformation(success.billingInformation)
      }

      if (success?.order_completed_event) {
        //We will include identify event here from useHelperSubscription
        yield call(triggerOrderCompletedEvent, success?.order_completed_event);
        if (actions.payload.purchasedCallback) {
          actions.payload.purchasedCallback()
        }
      }

      if (success &&  processingPages.includes(success.page)) {
        yield getPath( { nameGetParam: "page", valueGetParam: "processing" })
      } else if(success && success.page === "upsell"){
        yield getPath( { nameGetParam: "page", valueGetParam: "upsell" })
      }
    }
  } catch (e: any) {
    yield putPaymentError(e.response?.data?.data?.error?.message)
    console.log(e)
    actions.payload.errorCallback?.(e.response?.data?.data?.error?.message || e.error?.message)
  }

  if(paymentRequest && paymentRequest.requestCompletedCallback){
    paymentRequest.requestCompletedCallback(paymentRequest.event);
  }

  yield put(setLoadingButtonState(LoadingState.Loaded))
}

function* nextUpsellProcessingPageSaga(actions: any): any {
  let paymentRequest: PaymentRequestToken | null  = null;

  if(actions.payload.paymentRequestEvent){
    paymentRequest = actions.payload.paymentRequestEvent;
  }

  try {
    const { client_token } = yield getClientTokenForResponse()
    const { service } = getPropertiesForEvents();

    let submitObj = { ...actions.payload, client_token, serviceName: service }

    if(paymentRequest){
      submitObj.paymentRequestEvent = null;
      submitObj = {...submitObj, creditCardToken: paymentRequest.creditCardToken}
    }

    yield put(setLoadingButtonState(LoadingState.Loading))
    yield put(setPaymentError(null))
    let response;
    if(OfferAction.Accept === actions.payload.action){
      response = yield Service.acceptUpsellPage(submitObj)
    }else{
      response = yield Service.cancelUpsellPage(submitObj)
    }

    if (response.error) {
      yield putPaymentError(response?.error?.message)
    } else {
      const success = yield getSuccess(response)

      if (success.page) {
        yield put(getAdditionalArraySteps(success.page))
        yield call(setStatusSignaturePageSaga, success)
      }

      if (success?.status) {
        yield put(setStatusOnSubmitObject(success.status))
      }

      yield putUpsellProcessingInformation(success)

      if (success?.order_completed_event) {
        //We will include identify event here from useHelperSubscription
        const { triggerOrderCompletedEvent: triggerOrderCompletedEventOnSubscription } = useHelperSubscription()
        yield call(triggerOrderCompletedEventOnSubscription,{ data: { event_order_completed: success.order_completed_event } }, OrderCompleted.TYPE_ORDER_COMPLETED_PAYMENT_PAGE);
      }

      if(success.billingInformation){
        yield putBillingInformation(success.billingInformation)
      }

      if (success.page) {
        yield put(getAdditionalArraySteps(success.page))
        yield call(setStatusSignaturePageSaga, success)
      }

      const purchaseOffer: PurchaseOffer = actions.payload.purchaseOffer
      if(purchaseOffer && purchaseOffer.offerPurchasedEvent){
        purchaseOffer.offerPurchasedEvent(purchaseOffer.offerData);
      }
    }
  } catch (e: any) {
    yield putPaymentError(e.response?.data?.data?.error?.message)
    console.log(e)
  }

  if(paymentRequest && paymentRequest.requestCompletedCallback){
    paymentRequest.requestCompletedCallback(paymentRequest.event);
  }

  yield put(setLoadingButtonState(LoadingState.Loaded))
}

function *putPaymentError(message: string): any{
  const paymentError = buildPaymentError(message)
  yield put(setPaymentError(paymentError))
}

async function triggerOrderCompletedEvent(data: any) {
  /**
   * data?.products is an array of products. But in case of our business logic, we only have one product.
   */
  const orderCompletedBuilder = new OrderCompletedBuilder();
  const userIpRepository = UserIpRepository.getInstance();
  const ipAddress = userIpRepository.getValue() ?? "";
  orderCompletedBuilder.setIpAddress(ipAddress);
  const properties = data?.properties;
  const options = data?.options;

  orderCompletedBuilder
      .setGclid(properties?.gclid ?? "")
      .setMsclkid(properties?.msclkid ?? "")
      .setFbc(properties?.fbc ?? "")
      .setFbp(properties?.fbp ?? "")
      .setOrderId(properties?.order_id ?? "")
      .setValue(properties?.value ?? "")
      .setRevenue(properties?.revenue ?? "")
      .setCurrency(properties?.currency ?? "")
      .setUtmMedium(properties?.utm_medium ?? "")
      .setUtmSource(properties?.utm_source ?? "")
      .setUtmCampaign(properties?.utm_campaign ?? "")
      .setUtmContent(properties?.utm_content ?? "")
      .setUtmTerm(properties?.utm_term ?? "")
      .setEventType(OrderCompleted.TYPE_ORDER_COMPLETED_PAYMENT_PAGE);

  const products = properties?.products;
  if (Array.isArray(products) && products.length > 0) {
    const product = products[0];
    orderCompletedBuilder
        .setProductId(product?.product_id ?? "")
        .setSolution(product?.solution ?? "")
        .setService(product?.service ?? "")
        .setProductPrice(product?.price ?? "")
        .setProductQuantity(product?.quantity ?? "")
        .setProductCategory(product?.category ?? "")
        .setProductName(product?.name ?? "")
        .setSubdivision(product?.subdivision ?? "");
  }

  orderCompletedBuilder
      .setLastUtmMedium(properties?.utm_medium_last ?? "")
      .setLastUtmSource(properties?.utm_source_last ?? "")
      .setLastUtmCampaign(properties?.utm_campaign_last ?? "")
      .setLastUtmContent(properties?.utm_content_last ?? "")
      .setLastUtmTerm(properties?.utm_term_last ?? "");

  if(options?.referrer) {
    orderCompletedBuilder
        .setReferrerId(options?.referrer?.id)
        .setReferrerType(options?.referrer?.type);
  }

  const subscriptions = properties?.subscription;
  if (Array.isArray(subscriptions) && subscriptions.length > 0) {
    const subscription = subscriptions[0];
    orderCompletedBuilder
        .setSubscriptionId(subscription?.subscription_id ?? "")
        .setSubscriptionIdExternal(subscription?.subscription_external_id ?? "")
        .setSubscriptionCreationDate(subscription?.subscription_start_date ?? "")
        .setSubscriptionPaymentFrequency(subscription?.subscription_payment_frequency ?? "")
        .setSubscriptionPlan(subscription?.subscription_plan ?? "")
        .setSubscriptionPrice(subscription?.subscription_price ?? "")
        .setSubscriptionStatus(subscription?.subscription_status ?? "");
  }

  if(options?.traits) {
    orderCompletedBuilder.setEmail(options?.traits?.email);
  }

  await GAnalyticEvents.track(orderCompletedBuilder.build());
}

async function triggerIdentifyEvent(email: string, activated = false) {
  const clientToken = getClientTokenForResponse()
  const utmVarsJson = UtmRepository.getInstance().getUtm();

  /**
   * Promise.allSettled is used to make sure that all promises are resolved, even if some of them fail. This return an array of objects with the following structure:
   * {
   *  status: "fulfilled" | "rejected", // depending on the promise status
   *  value: any // the value returned by the promise or the error
   * }
   */
  const data = await Promise.allSettled([
    userService.userDataFromDash(),
    userService.userFlatternData(),
    subscriptionService.getUserSubscriptionStatus(),
    userService.getIntercomUserHash(clientToken),
    productsApis.getAllProductsApi(clientToken?.client_token)
  ]);
  const userDataResponse = data[0].status === "fulfilled" ? data[0].value : null;
  const flattenResponse = data[1].status === "fulfilled" ? data[1].value : null;
  const isUserSubscribed = data[2].status === "fulfilled" ? data[2].value : null;
  const intercomUserHash = data[3].status === "fulfilled" ? data[3].value.data.user_hash : "";
  const applications = data[4].status === "fulfilled" && data[4]?.value?.data?.success ? data[4].value.data.success.applications : [];

  const {
    outerId,
    address,
    firstName,
    lastName,
    phoneNumber,
    dateOfBirth,
    authorized,
    createdAt
  } = EventsHelper.getDataToIdentify(userDataResponse, flattenResponse);

  let isActivated = activated ? activated : authorized === 1

  await EventsHelper.triggerIdentify(
      outerId,
      address,
      email,
      firstName,
      lastName,
      phoneNumber,
      dateOfBirth,
      applications.length > 0 || isUserSubscribed?.data?.status === SubscriptionStatuses.ACTIVE,
      isUserSubscribed?.data.status === SubscriptionStatuses.ACTIVE || isUserSubscribed?.data.status === SubscriptionStatuses.CANCELLED || isUserSubscribed?.data.status === SubscriptionStatuses.IN_TRIAL,
      utmVarsJson,
      isActivated,
      intercomUserHash,
      createdAt
  );
}

function* launchTaxAssistanceSaga(): any {
  try {
    let submitObj = yield call(AdditionalSagaHelper.getTaxSubmitObj);
    yield put(setLoadingButtonState(LoadingState.Loading))
    const response = yield Service.getColumnTaxInformation(submitObj)

    if (response.error) {
      throw new Error(response?.error?.message)
    } else {
      const success = yield getSuccess(response)
      if (success.page && success.page === "taxes_after") {
        if(success?.tax_return) {
          yield put(setTaxReturnInformation(success?.tax_return));
        }

        if(success.user_url_response && success.user_url_response.user_url) {
          yield call(
              ColumnTaxHelper.openColumnTaxModule,
              success?.user_url_response?.user_url,
          )
          yield put(refreshTaxReturnInformation());
        }
      }
      yield put(getAdditionalArraySteps(success.page))
      yield call(setStatusSignaturePageSaga, success)
    }
  } catch (e: any) {
    yield put(setErrorBarValue(true))
    yield put(setLoadingButtonState(LoadingState.Loaded))
  }
}

function* refreshTaxReturnInformationSaga(): any {
  try {
    let submitObj = yield call(AdditionalSagaHelper.getTaxSubmitObj);
    const response = yield Service.getColumnTaxInformation(submitObj)
    if (response.error) {
      throw new Error(response?.error?.message)
    } else {
      const success = yield getSuccess(response)
      if (success.page && success.page === "taxes_after") {
        if(success?.tax_return) {
          yield put(setTaxReturnInformation(success?.tax_return));
        }
      }
      yield put(getAdditionalArraySteps(success.page))
      yield call(setStatusSignaturePageSaga, success)
    }
  } catch (e: any) {
    yield put(setErrorBarValue(true))
  }

  yield put(setLoadingButtonState(LoadingState.Loaded))
}

export default function* additionalSaga() {
  yield takeEvery(ActionTypes.APPROVE_ADRESS, approveAdressSaga)
  yield takeEvery(ActionTypes.SEND_SIGNATURE, sendSignatureSaga)
  yield takeEvery(ActionTypes.SEND_SIGNATURE_FIELD_QUESTIONARY, submitAdditionalFormSaga)
  yield takeEvery(ActionTypes.SUBMIT_PASSPORT_DETAILS_ADDITIONAL_FORM, submitPassportDetailsAdditionalFormSaga)
  yield takeEvery(ActionTypes.SUBMIT_ADDITIONAL_COA, submitAdditionalFormSagaCOA)
  yield takeEvery(ActionTypes.SUBMIT_ADDITIONAL_DATA, submitAdditionalData)
  yield takeEvery(ActionTypes.SET_SUBMIT_LOCAL_STORAGE, submitlocalStorageSaga)
  yield takeEvery(ActionTypes.UPLOAD_DOCUMENTS, uploadDocumentsSaga)
  yield takeEvery(ActionTypes.CLEAR_TEMP_DOCUMENTS, clearTempDocumentsSaga)
  yield takeEvery(ActionTypes.UPLOAD_ADDITIONAL_DOCUMENTS, uploadDocumentsSaga)
  yield takeEvery(ActionTypes.NEXT_UPSELL_PAGE, nextUpsellPageSaga)
  yield takeEvery(ActionTypes.NEXT_UPSELL_PROCESSING_PAGE, nextUpsellProcessingPageSaga)
  yield takeEvery(ActionTypes.LAUNCH_TAX_ASSISTANCE, launchTaxAssistanceSaga)
  yield takeEvery(ActionTypes.REFRESH_TAX_RETURN_INFORMATION, refreshTaxReturnInformationSaga)
}
