import { getPropertiesForEvents } from "../gtm/backEndEvent"
import FormIdRepository from "../../persistence/session/FormIdRepository";

const { form_id: formId } = getPropertiesForEvents()

const formIddLocalStorage = FormIdRepository.getInstance().getValue();
const subscriptionId = sessionStorage.getItem("subscriptionId")

export const getOldFormIdLocalStorage = () =>
  formIddLocalStorage !== formId
    ? sessionStorage.setItem("old_form_id", formIddLocalStorage ?? "")
    : sessionStorage.setItem("old_form_id", formId)

export const clearLocalStorage = () => {
  if (
    formId &&
    formIddLocalStorage &&
    (formId.split("-")[0] !== formIddLocalStorage.split("-")[0] || (subscriptionId && formId !== formIddLocalStorage))
  ) {
    ;[
      "form_id",
      "paymentGov",
      "billingAddress",
      "observers",
      "application_id",
      "wordpress_logo",
      "wordpress_header",
      "client_token",
    ].forEach((name: string) => {
      sessionStorage.removeItem(name)
    })
  }
}

export const clearSubmitObjLocalStorage = () => {
  ;["check_status"].forEach((name: string) => {
    sessionStorage.removeItem(name)
  })
}

export const clearCheckStatusLocalStorage = () => {
  ;["finallySubmitObject"].forEach((name: string) => {
    sessionStorage.removeItem(name)
  })
}
