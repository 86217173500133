import React, { FC, useState } from "react"
import { BasicBlockContainerProps } from "../../../../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { useSelector } from "react-redux"
import { RootState } from "../../../../../../App"
import { uploadSelector } from "../../../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { currentStateDocumentsUploadInterface } from "./UploadDocumentActionBlock"
import { Document, Page, pdfjs } from "react-pdf"
import { TYPES_FILES } from "../../../../../../Redux/store/upload/upload.saga"

const UploadDocumentImgItem: FC<BasicBlockContainerProps> = ({ item: { className, text }, ...props }) => {
  const { dynamicProps = { name_field: "" } } = props
  const { name_field } = dynamicProps
  const linkPhotoBlob =
    useSelector<RootState, { blobDocumentsUpload: currentStateDocumentsUploadInterface }>(uploadSelector)
      ?.blobDocumentsUpload?.[name_field] ?? {}

  const { name } = linkPhotoBlob

  const extensionCurrentFile = name?.split(".")[name.split(".").length - 1]

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages)
  }

  return extensionCurrentFile === TYPES_FILES.PDF ? (
    <Document file={linkPhotoBlob} onLoadSuccess={onDocumentLoadSuccess}>
      <Page pageNumber={pageNumber} />
    </Document>
  ) : (
    <img className={className} src={linkPhotoBlob} alt={text} />
  )
}

export default UploadDocumentImgItem
