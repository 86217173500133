/* eslint-disable react-redux/useSelector-prefer-selectors */
import React, { FC, useEffect, useState } from "react"
import "./MissingFieldsModal.scss"
import { Form } from "antd"
import { MissingFieldsModalProps } from "./MissingFieldsModal.props"
import {
  computeDayOptions,
  MissingFieldsLabels,
  MissingFieldsTypes,
  months,
  RecordType,
  replaceCharsInValues,
  updateObject,
  usaStates,
  validateEmail,
  validatePhoneNumber,
  validateSSN,
} from "../../../../util/IdentityProtectionHelper"
import GSelect from "../../molecules/Select/Select"
import GInputText from "../../molecules/InputText/InputText"
import GFormItem from "../../organisms/FormItem/FormItem"
import GModal from "../GModal/GModal"
import GText from "../../atoms/Text/Text"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import { BUTTON_TYPE, SIZE } from "../../types/types"
import GButton from "../../molecules/Button/GButton"
import InputMask from "react-input-mask"
import { useSelector } from "react-redux"
import "../../molecules/InputText/InputText.scss"
import SSNInput from "../../molecules/SSNInput/SSNInput"

const MissingFieldsModal: FC<MissingFieldsModalProps> = ({ open, onCancel, missingFields, handleUpdateUser }) => {
  const [missingFieldsForm] = Form.useForm()

  // @ts-ignore
  const { updateCustomerLoader } = useSelector((state) => state.identityProtectionData)

  const [selectedMonth, setSelectedMonth] = useState(undefined)
  const [selectedYear, setSelectedYear] = useState(undefined)
  const [selectedDay, setSelectedDay] = useState(undefined)
  const [ssn, setSSN] = useState("")
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [address1, setAddress1] = useState("")
  const [address2, setAddress2] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [zip, setZip] = useState("")
  const [phone, setPhone] = useState("")
  const [showAddress2, setShowAddress2] = useState(false)

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value)
  }

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value)
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress1(event.target.value)
  }

  const handleAddress2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress2(event.target.value)
  }

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value)
  }

  const handleStateChange = (value: any) => {
    setState(value)
  }

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZip(event.target.value)
  }

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value)
  }

  const handleChangeMonth = (value: any) => {
    setSelectedMonth(value)

    if (selectedMonth !== undefined && selectedYear !== undefined) {
      const dayOptions = computeDayOptions(selectedMonth, selectedYear)

      missingFieldsForm.setFieldsValue({
        [`${RecordType.BD}-day`]: dayOptions,
      })
    }
  }

  const handleChangeYear = (value: any) => {
    setSelectedYear(value)

    if (selectedMonth !== undefined) {
      handleChangeMonth(selectedMonth)
    }
  }

  const handleChangeDay = (value: any) => {
    setSelectedDay(value)
  }

  const handleFormSubmit = async () => {
    try {
      const valid = await missingFieldsForm.validateFields()
      if (valid) {
        // update sending data to allowed structure by backend
        const submittedData = replaceCharsInValues(updateObject(missingFieldsForm.getFieldsValue()))
        handleUpdateUser(submittedData)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const month = missingFieldsForm.getFieldValue(`${MissingFieldsTypes.dateOfBirth}-month`)
    const year = missingFieldsForm.getFieldValue(`${MissingFieldsTypes.dateOfBirth}-year`)
    setSelectedMonth(month)
    setSelectedYear(year)
  }, [missingFieldsForm])

  return (
    <GModal open={open} onCancel={onCancel}>
      <Form
        form={missingFieldsForm}
        className={"MissingFieldsModal"}
        layout={"vertical"}
        initialValues={{ remember: true }}
        name={"basic"}
        onFinish={handleFormSubmit}
      >
        <div className={"MissingFieldsModal__Header"}>
          <GText
            text={"Add your personal information to activate monitoring"}
            size={SIZE.PARAGRAPH_BOLD_32}
            color={COLORS_NEW.BLACK_1000}
          />
        </div>

        {missingFields?.includes(MissingFieldsTypes.dateOfBirth) && (
          <div key={MissingFieldsTypes.dateOfBirth} className={"MissingFieldsModal__Content"}>
            <GText
              text={MissingFieldsLabels.dateOfBirth}
              size={SIZE.PARAGRAPH_BOLD_16}
              color={COLORS_NEW.BLACK_900}
              style={{ marginBottom: "12px" }}
            />
            <div className={"MissingFieldsModal__Content__Inputs__Row"}>
              <GFormItem
                name={`${MissingFieldsTypes.dateOfBirth}-month`}
                initialValue="Month"
                rules={[
                  {
                    required: false,
                    message: "Please select a month",
                  },
                ]}
              >
                <GSelect
                  placeHolder="Month"
                  onChange={handleChangeMonth}
                  options={months.map((month, index) => ({ value: index + 1, label: month }))}
                  defaultValue={"January"}
                />
              </GFormItem>

              <GFormItem
                name={`${MissingFieldsTypes.dateOfBirth}-day`}
                initialValue="Day"
                rules={[
                  {
                    required: false,
                    message: "Please select a day",
                  },
                ]}
              >
                <GSelect
                  placeHolder="Day"
                  onChange={handleChangeDay}
                  disabled={!selectedMonth || !selectedYear}
                  options={computeDayOptions(
                    missingFieldsForm.getFieldValue(`${MissingFieldsTypes.dateOfBirth}-month`),
                    missingFieldsForm.getFieldValue(`${MissingFieldsTypes.dateOfBirth}-year`)
                  )}
                />
              </GFormItem>

              <GFormItem
                name={`${MissingFieldsTypes.dateOfBirth}-year`}
                initialValue="Year"
                rules={[
                  { required: false, message: "Please select a year" },
                  ({ getFieldValue }) => ({
                    validator: async (_, value) => {
                      const birthYear = value
                      const currentYear = new Date().getFullYear()
                      const currentMonth = new Date().getMonth() + 1
                      const currentDay = new Date().getDate()
                      const birthDate = new Date(
                        birthYear,
                        getFieldValue(`${MissingFieldsTypes.dateOfBirth}-month`) - 1,
                        getFieldValue(`${MissingFieldsTypes.dateOfBirth}-day`)
                      )
                      const age = currentYear - birthDate.getFullYear()

                      if (
                        age < 18 ||
                        (age === 18 &&
                          (currentMonth < birthDate.getMonth() + 1 ||
                            (currentMonth === birthDate.getMonth() + 1 && currentDay < birthDate.getDate())))
                      ) {
                        return Promise.reject(new Error("Minimum age is 18."))
                      }

                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <GSelect
                  placeHolder={"Year"}
                  onChange={handleChangeYear}
                  options={Array.from({ length: new Date().getFullYear() - 1900 + 1 }, (_, i) => ({
                    value: 1900 + i,
                    label: 1900 + i,
                  })).reverse()}
                />
              </GFormItem>
            </div>
          </div>
        )}

        {missingFields?.includes(MissingFieldsTypes.ssn) && (
          <div key={MissingFieldsTypes.ssn} className={"MissingFieldsModal__Content"}>
            <div className={"MissingFieldsModal__Content__Inputs"}>
              <GFormItem
                key={MissingFieldsTypes.ssn}
                name={MissingFieldsTypes.ssn}
                rules={[{ required: true, validator: validateSSN }]}
                label={
                  <div className={"MissingFieldsModal__Item__Label"}>
                    <GText text={MissingFieldsLabels.ssn} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                  </div>
                }
              >
                <SSNInput onChange={setSSN} />
              </GFormItem>
            </div>
          </div>
        )}

        {missingFields?.includes(MissingFieldsTypes.email) && (
          <div key={MissingFieldsTypes.email} className={"MissingFieldsModal__Content"}>
            <div className={"MissingFieldsModal__Content__Inputs"}>
              <GFormItem
                key={MissingFieldsTypes.oldEmail}
                name={MissingFieldsTypes.oldEmail}
                rules={[{ required: true, validator: validateEmail }]}
                label={
                  <GText text={MissingFieldsLabels.email} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                }
              >
                <GInputText onChange={handleEmailChange} />
              </GFormItem>
            </div>
          </div>
        )}

        {missingFields?.includes(MissingFieldsTypes.firstName) && (
          <div key={MissingFieldsTypes.firstName} className={"MissingFieldsModal__Content"}>
            <div className={"MissingFieldsModal__Content__Inputs"}>
              <GFormItem
                key={MissingFieldsTypes.firstName}
                name={MissingFieldsTypes.firstName}
                rules={[{ required: true, message: "First name is required", pattern: /^[a-zA-Z\s]+$/ }]}
                label={
                  <GText
                    text={MissingFieldsLabels.firstName}
                    size={SIZE.PARAGRAPH_BOLD_16}
                    color={COLORS_NEW.BLACK_900}
                  />
                }
              >
                <GInputText onChange={handleFirstNameChange} />
              </GFormItem>
            </div>
          </div>
        )}

        {missingFields?.includes(MissingFieldsTypes.lastName) && (
          <div key={MissingFieldsTypes.lastName} className={"MissingFieldsModal__Content"}>
            <div className={"MissingFieldsModal__Content__Inputs"}>
              <GFormItem
                key={MissingFieldsTypes.lastName}
                name={MissingFieldsTypes.lastName}
                rules={[{ required: true, message: "Last name is required", pattern: /^[a-zA-Z\s]+$/ }]}
                label={
                  <GText
                    text={MissingFieldsLabels.lastName}
                    size={SIZE.PARAGRAPH_BOLD_16}
                    color={COLORS_NEW.BLACK_900}
                  />
                }
              >
                <GInputText onChange={handleLastNameChange} />
              </GFormItem>
            </div>
          </div>
        )}

        {missingFields?.includes(MissingFieldsTypes.phone) && (
          <div key={MissingFieldsTypes.phone} className={"MissingFieldsModal__Content"}>
            <div className={"MissingFieldsModal__Content__Inputs"}>
              <GFormItem
                key={MissingFieldsTypes.phone}
                name={MissingFieldsTypes.phone}
                rules={[{ required: true, validator: validatePhoneNumber }]}
                label={
                  <div className={"MissingFieldsModal__Item__Label"}>
                    <GText text={"Phone number"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                  </div>
                }
              >
                <InputMask mask={"(999) 999-9999"} onChange={handlePhoneChange} maskPlaceholder={null}>
                  <GInputText inputMode={"numeric"} />
                </InputMask>
              </GFormItem>
            </div>
          </div>
        )}

        {(missingFields?.includes(MissingFieldsTypes.address1) ||
          missingFields?.includes(MissingFieldsTypes.address2) ||
          missingFields?.includes(MissingFieldsTypes.city) ||
          missingFields?.includes(MissingFieldsTypes.state) ||
          missingFields?.includes(MissingFieldsTypes.zip)) && (
          <div key={MissingFieldsTypes.address1} className={"MissingFieldsModal__Content"}>
            <div className={"MissingFieldsModal__Content__Inputs__Column"}>
              <GFormItem
                key={MissingFieldsTypes.address1}
                name={MissingFieldsTypes.address1}
                label={
                  <div className={"MissingFieldsModal__Item__Label"}>
                    <GText text={"Street address"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                  </div>
                }
                rules={[{ required: true, message: "Address is required", pattern: /^[a-zA-Z0-9\s]+$/ }]}
              >
                <GInputText onChange={handleAddressChange} />
              </GFormItem>

              {!showAddress2 && (
                <GText
                  text={"Add Apt #, Floor, etc."}
                  color={COLORS.ROYAL_BLUE_600}
                  size={SIZE.PARAGRAPH_REGULAR_16}
                  style={{ marginTop: "-12px", marginBottom: "20px", cursor: "pointer" }}
                  onClick={() => setShowAddress2(!showAddress2)}
                />
              )}

              {showAddress2 && (
                <GFormItem
                  key={MissingFieldsTypes.address2}
                  name={MissingFieldsTypes.address2}
                  label={
                    <div className={"MissingFieldsModal__Item__Label"}>
                      <GText text={"Address 2"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                    </div>
                  }
                  rules={[{ required: true }]}
                >
                  <GInputText value={""} onChange={handleAddress2Change} />
                </GFormItem>
              )}

              <GFormItem
                key={MissingFieldsTypes.city}
                name={MissingFieldsTypes.city}
                rules={[{ required: true, message: "City is required", pattern: /^[a-zA-Z\s]+$/ }]}
                label={
                  <div className={"MissingFieldsModal__Item__Label"}>
                    <GText text={"City"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                  </div>
                }
              >
                <GInputText value={""} onChange={handleCityChange} />
              </GFormItem>

              <div className={"MissingFieldsModal__Content__Address"}>
                <GFormItem
                  label={
                    <div className={"MissingFieldsModal__Item__Label"}>
                      <GText text={"State"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                    </div>
                  }
                  key={MissingFieldsTypes.state}
                  name={MissingFieldsTypes.state}
                  initialValue={"Select state"}
                  rules={[{ required: true, message: "Please select a state" }]}
                >
                  <GSelect
                    onChange={handleStateChange}
                    options={usaStates}
                    defaultValue={usaStates[0].value}
                    placeHolder={"Select type"}
                  />
                </GFormItem>

                <GFormItem
                  label={
                    <div className={"MissingFieldsModal__Item__Label"}>
                      <GText text={"Zip Code"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                    </div>
                  }
                  key={MissingFieldsTypes.zip}
                  name={MissingFieldsTypes.zip}
                  rules={[{ required: true, message: "Please enter a valid ZIP code", pattern: /^[0-9]{5,5}$/ }]}
                >
                  <GInputText onChange={handleZipChange} value={""} inputMode={"numeric"} />
                </GFormItem>
              </div>
            </div>
          </div>
        )}

        <div className={"MissingFieldsModal__Actions"}>
          <GButton
            type={BUTTON_TYPE.PRIMARY}
            showIcon={true}
            style={{ width: "100%" }}
            text={"Protect my identity"}
            click={handleFormSubmit}
            loading={updateCustomerLoader}
          />
        </div>
      </Form>
    </GModal>
  )
}

export default MissingFieldsModal
