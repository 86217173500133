import {all, fork, put, takeEvery} from "redux-saga/effects";
import {productsApis, userService} from "../../api/api";
import {
  CHECK_DUPLICATE_PRODUCT,
  GET_FELLING_PRESENT,
  GET_GOVERNMENT_PRODUCTS, GET_GOVERNMENT_PRODUCTS_SUCCSSES,
  GET_PRODUCTS, GET_WEBFLOW_SOLUTIONS_DATA_IN_DASH,
  SEND_APPOINTMENT
} from "../../constants/ActionTypes";
import {
  checkProductForDuplicateSuccess,
  getFellingPresentSuccsses,
  getGovernmentProductsSuccsses, getProducts,
  getProductsSuccsses, getWebFlowSolutionsInDash
} from "../actions";
import {notifyError} from "@dash/gov-plus__front-end__form/src/util/helper";
import AuthUserBearerTokenRepository from "@dash/gov-plus__front-end__form/src/persistence/session/AuthUserBearerTokenRepository";

function* getProductsSaga({authToken}) {
  try {
    const response = yield productsApis.getAllProductsApi(authToken);
    let applications = {};
    let userProductsName = []

    if (response.data.success.applications) {
      for (let i in response.data.success.applications) {
        applications[response.data.success.applications[i].application_id] = response.data.success.applications[i]
        userProductsName.push(response.data.success.applications[i]?.product_name)
      }
      response.data.success.applications = applications;
    }
    yield put(getProductsSuccsses({applications, userProductsName, productLoaded: true}))
  } catch (err) {
    notifyError(err);
  }
}

function* getAnotherGovProductsSaga() {
  try {
    const response = yield productsApis.getGovProducts();

    let webFlowServicesData = {}
    const anotherGovProductsWithPresent = () => response.data.data?.governmentCards.forEach((item) => {
      for (let z in item.collapseInfo) {
        webFlowServicesData[item.collapseInfo[z].id] = {
          CTA: item.collapseInfo[z].CTA,
          text: item.collapseInfo[z].text,
          image: item.image,
          illustration: item.illustration,
          illustration_l:item.illustration_l,
          illustration_m:item.illustration_m,
          title: item.title,
          formId: item.id,
        }
      }
      return item;
    })
    anotherGovProductsWithPresent();

    localStorage.setItem("webFlowServicesData", JSON.stringify(webFlowServicesData))
    localStorage.setItem("webFlowData", JSON.stringify(webFlowServicesData))

    yield put(getGovernmentProductsSuccsses({
      anotherGovProducts: response.data.data?.governmentCards,
      webFlowData: webFlowServicesData,
    }))

    yield put(getWebFlowSolutionsInDash())

  } catch (err) {
    notifyError(err);
  }
}

function* getProductsPercentSaga() {
  try {
    const productsPercentArr = yield userService.getProgressBar(AuthUserBearerTokenRepository.getInstance().getValue())
    yield put(getFellingPresentSuccsses({
      gettingPercentStatus: true,
      productsPercentArr: productsPercentArr.data.success
    }))

  } catch (err) {
    notifyError(err);
  }
}

function* sendGovAppointment({payload}) {
  const {token, form_id, application_id, appointment_time} = payload;
  try {

    const sendGovAppointmentRequest = yield productsApis.saveGovAppointment(token, form_id, application_id, appointment_time);
    yield put(getProducts(token))

    console.log(sendGovAppointmentRequest)
  } catch (err) {
    notifyError(err);
  }
}
function* checkUserSelectedAppForDuplicate({payload}) {
  const {form_id, client_token, array, callback} = payload;
  try {
    const duplicateApplicationResponse = yield productsApis.checkUserSelectedAppForDuplicate(form_id, client_token);
    if (!Object.keys(array).includes(duplicateApplicationResponse?.data?.success?.sameApplication?.form_id)) {
      if (!duplicateApplicationResponse.data?.success?.sameApplication === false) {
        array[duplicateApplicationResponse?.data?.success?.sameApplication?.form_id] = duplicateApplicationResponse?.data?.success?.sameApplication
      }
    }
    yield put(checkProductForDuplicateSuccess(array))
    callback?.(array)
  } catch (err) {
    notifyError(err);
  }
}

function* getWebFlowSolutionsDataInDashSaga() {
  const response = yield productsApis.getSolutionsData()
  const webFlowData = yield getWebFlowSolutionData(response?.data?.data)
  yield localStorage.setItem("webFlowSolutionData", JSON.stringify(webFlowData))
}

export const getWebFlowSolutionData = (solutionsArray) =>
    solutionsArray
        .map(({ identifier, illustration, "illustration-l": illustrationL, "illustration-m-half": illustrationM }) => ({
          identifier,
          illustration,
          illustrationL,
          illustrationM,
        }))
        .reduce((acc, { identifier, ...props }) => {
          return { ...acc, [identifier]: { ...props, identifier } }
        }, {})


export function* getProductsSagaFork() {
  yield takeEvery(GET_PRODUCTS, getProductsSaga);
}

export function* sendGovAppointmentFork() {
  yield takeEvery(SEND_APPOINTMENT, sendGovAppointment);
}

export function* getProductsPercentSagaFork() {
  yield takeEvery(GET_FELLING_PRESENT, getProductsPercentSaga);
}

export function* getAnotherGovProductsFork() {
  yield takeEvery(GET_GOVERNMENT_PRODUCTS, getAnotherGovProductsSaga);
}

export function* getWebFlowSolutionsDataInDashFork() {
  yield takeEvery(GET_WEBFLOW_SOLUTIONS_DATA_IN_DASH, getWebFlowSolutionsDataInDashSaga);
}

export function* checkUserSelectedAppForDuplicateFork() {
  yield takeEvery(CHECK_DUPLICATE_PRODUCT, checkUserSelectedAppForDuplicate);
}


export default function* rootSaga() {
  yield all([
    fork(getProductsSagaFork),
    fork(getAnotherGovProductsFork),
    fork(getProductsPercentSagaFork),
    fork(sendGovAppointmentFork),
    fork(checkUserSelectedAppForDuplicateFork),
    fork(getWebFlowSolutionsDataInDashFork),
  ]);
}
