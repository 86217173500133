import React, { FC } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../App"
import {
  additionalSelector,
  formSelector,
  uploadSelector,
} from "../../../FormComponents/UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { removeStatusUploadPhoto, saveStatusUploadPhoto } from "../../../../Redux/store/upload/upload.actions"
import { LoadingStateUploadPhoto } from "../../../../shared/constans/user-from-view-mode.enum"
import GUpload, {GUploadProps, GUploadStatus} from "../../molecules/Upload/GUpload"

type GUploadPhotoProps = Pick<GUploadProps, "accept" | "text" | "action" | "disabled" | "multiple" | "change" | "drop" | "method">;

const GUploadPhoto: FC<GUploadPhotoProps> = (props) => {
  const dispatch = useDispatch()
  const { appId: client_token, formId: form_id } = useSelector<RootState, { [key: string]: any }>(formSelector)
  const linkPhoto = useSelector<RootState, { linkPhoto: string }>(uploadSelector).linkPhoto
  const linkOriginalPhoto = useSelector<RootState, { linkOriginalPhoto: string }>(uploadSelector).linkOriginalPhoto
  const currentStateUpload = useSelector<RootState, { stateLoadingUploadPhoto: string }>(
    uploadSelector
  ).stateLoadingUploadPhoto
  const currentStatusPhotoUpload = useSelector<RootState, { statusPhotoUpload: string }>(
    additionalSelector
  ).statusPhotoUpload
  const errorMessage =
    useSelector<RootState, { dynamicUploadPhotoErrorMessage: string | null }>(uploadSelector)
      .dynamicUploadPhotoErrorMessage ?? ""

  const handlerOnClickRemove = () => dispatch(removeStatusUploadPhoto())

  const handlerOnClickSave = () => {
    dispatch(saveStatusUploadPhoto({ linkPhoto, linkOriginalPhoto, client_token, form_id }))
  }

  const getStateUpload = () => {
    switch (currentStateUpload) {
      case LoadingStateUploadPhoto.BackendError:
        return GUploadStatus.Error
      case LoadingStateUploadPhoto.Loading:
        return GUploadStatus.Loading
      case LoadingStateUploadPhoto.Loaded:
        return GUploadStatus.Loaded
      case LoadingStateUploadPhoto.SavingImages:
        return GUploadStatus.Saving
      default:
        return GUploadStatus.Preload
    }
  }
  const getStatusPhotoUpload = () => {
    switch (currentStatusPhotoUpload) {
      case LoadingStateUploadPhoto.SavingImages:
        return GUploadStatus.Saving
      case LoadingStateUploadPhoto.PreLoad:
        return GUploadStatus.Preload
      default:
        return -1
    }
  }

  return (
    <GUpload
      text={props.text}
      action={props.action}
      accept={props.accept}
      disabled={props.disabled}
      multiple={props.multiple}
      change={props.change}
      method={props.method}
      drop={props.drop}
      state={getStateUpload()}
      statusPhotoUpload={getStatusPhotoUpload()}
      errorMessage={errorMessage}
      previewSrc={linkPhoto}
      showAccept={true}
      handlerOnClickSave={handlerOnClickSave}
      showRemove={true}
      handlerOnClickRemove={handlerOnClickRemove}
    />
  )
}

export default GUploadPhoto
