import React, {useEffect, useRef} from "react";
import {Layout} from "antd";
import {useDispatch, useSelector} from "react-redux";
import Topbar from "../Topbar/index";
import FormsApp from "../../routes/index";
import {useHistory} from "react-router-dom";
import {getFooterData, toggleCollapsedSideNav, updateWindowWidth} from "../../appRedux/actions";
import {usePreAuthUser} from "./usePreAuthUser";
import GDrawer from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Drawer/GDrawer";
import GSidebar from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/Sidebar/Sidebar";
import {getUrlParams, parseObjectToString} from "../../util/helper";
import { getTopScrollPosition } from "@dash/gov-plus__front-end__form/src/Components/FormComponents/FormBlock/FormBlock";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const {Content} = Layout;

const FormsAppContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const bodyContent = useRef();
  const breakpoint = useBreakpoint()
  const {authToken} = useSelector(({auth}) => auth);
  const {productIdFromEmailCheckStatus} = useSelector(({allProducts}) => allProducts);
  const {retargetingFormId, retargetingAppId} = useSelector(({notes}) => notes);

  const {navCollapsed} = useSelector(({common}) => common);

  usePreAuthUser(authToken);

  useEffect(()=>{
    if(productIdFromEmailCheckStatus !== ""){
      history.push(`/products/productInfo/${productIdFromEmailCheckStatus}`);
    }

    let paramsStr = parseObjectToString(getUrlParams(window.location.href))
    if(retargetingFormId && retargetingAppId){
      history.push(`/forms/?formId=${retargetingFormId}&applicationId=${retargetingAppId}${paramsStr !== "" ? "&" + paramsStr: ""}`)
    }
    if(retargetingFormId && !retargetingAppId){
      history.push(`/forms/?formId=${retargetingFormId}${paramsStr !== "" ? "&" + paramsStr: ""}`)
    }

  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth));
    })
    dispatch(getFooterData());
  }, [dispatch]);

  useEffect(() => {
    getTopScrollPosition()
  }, [history.location.pathname])

  const width = useSelector(({common}) => common.width);

  const closeSideBar = () => dispatch(toggleCollapsedSideNav(false))

  return (
    <Layout className="gx-app-layout">
      <GSidebar/>
      <GDrawer navCollapsed={navCollapsed} screenWidth={width} closeDrawer={closeSideBar}/>
      <Layout style={{overflowY: breakpoint.lg ? "auto" : ""}}>
        <Topbar />
        <Content className={"forMobile"}>
          <FormsApp/>
        </Content>
      </Layout>
    </Layout>
  )
};
export default FormsAppContainer;

