import React, {FC} from "react"
import "./GErrorLoginMessage.scss"

export interface STRReplace {
    key: string,
    value: string
}

interface GErrorLoginMessageProps {
    message: string,
    addEventClickToWord?: string,
    eventClick?: any,
    stringReplace?: STRReplace
}

/**
 * This component is to render the error message in sign in page when something wrong occurs when user try to log in
 * As you can see we have the message and you can add even click to some string of the message.
 *
 * @param message Message will be rendered
 * @param addEventClickToWord String you want to add event click
 * @param eventClick Function triggered when you click on the string
 * @constructor
 */
const GErrorLoginMessage: FC<GErrorLoginMessageProps> = ({
                                                   message,
                                                   addEventClickToWord,
                                                   eventClick,
                                                   stringReplace
                                               }) => {

    const applyReplace = (pMessage: string): string => {
        let customMessage = pMessage;
        if(stringReplace && stringReplace.key && stringReplace.value) {
            customMessage = pMessage.replace(stringReplace?.key, stringReplace?.value);
        }
        return customMessage;
    }

    const elementClick = (e: any) => {
        if (e.target.className === "GErrorLoginMessage__CustomEvent") {
            eventClick(e);
        }
    };

    const getCustomMessageWithReplaceAndEvent = (pMessage: string) => {
        let customMessage = pMessage;
        if (addEventClickToWord && eventClick) {
            return customMessage.replace(addEventClickToWord, `<span class="GErrorLoginMessage__CustomEvent">${addEventClickToWord}</span>`);
        }
        return customMessage;
    }

    let customMessage = applyReplace(message);
    customMessage = getCustomMessageWithReplaceAndEvent(customMessage);
    return <span
        className={`GErrorLoginMessage`}
        onClick={elementClick}
        dangerouslySetInnerHTML={{ __html: customMessage }} />
}

export default GErrorLoginMessage
