import React, { FC } from "react"
import "./DismissAlertStatus.scss"
import IconsSvg from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GText from "../../atoms/Text/Text"
import { COLORS_NEW } from "../../types/Colors"
import { SIZE } from "../../types/types"
import { DismissAlertStatusProps } from "./DismissAlertStatus.props"
import { isDismissed, isRedAlert } from "../../../../util/IdentityProtectionHelper"

const DismissAlertStatus: FC<DismissAlertStatusProps> = ({ alert }) => {
  const renderAlertStatus = () => {
    if (isRedAlert(alert)) {
      return (
        <>
          <GText text={"This wasn't me"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.RED_700} />
          <IconsSvg type={ICONS_SVG_TYPE.ICON_RED_X} width={24} height={24} />
        </>
      )
    } else if (isDismissed(alert) && alert?.CanRedAlert === "1") {
      return (
        <>
          <GText text={"This was me"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.GREEN_700} />
          <IconsSvg type={ICONS_SVG_TYPE.ICON_GREEN_CHECK} width={24} height={24} />
        </>
      )
    } else {
      return null
    }
  }

  return <div className={"DismissAlertStatus"}>{renderAlertStatus()}</div>
}

export default DismissAlertStatus