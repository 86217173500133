import React, {useEffect} from "react";
import GBillingSettings from "../../Components/atomicDesign/templates/BillingSettings/GBillingSettings";
import {useSubscription} from "../../../../dashboard-govplus-front/src/hooks/useSubscription";
import {usePayment} from "../../../../dashboard-govplus-front/src/hooks/usePayment";
import GSpinner from "../../Components/atomicDesign/atoms/Spinner/Spinner";

const BillingSettingsPage = () => {

    const {billingInformation, loading, getBillingInformation, savePaymentMethod, updateDefaultPaymentMethod} = usePayment()

    useEffect(getBillingInformation, [])

    return (
        <div className={"GBillingSettingsPage"}>
            <GBillingSettings
                title={"Billing settings"}
                paymentMethods={billingInformation?.payment_methods || []}
                defaultPaymentMethod={billingInformation?.default_payment_methods}
                savePaymentMethod={savePaymentMethod}
                onSelectPaymentMethod={updateDefaultPaymentMethod}
                loading={loading}
            />
        </div>
    )
}

export default BillingSettingsPage