import React, { FC } from "react"
import "./TabRoundedNotification.scss"
import { TabRoundedNotificationProps } from "./TabRoundedNotification.props"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS } from "../../types/Colors"

const GTabRoundedNotification: FC<TabRoundedNotificationProps> = ({ value, style, fontSize }) => {
  return (
    <div className={"GTabRoundedNotification"} style={style}>
      {value ? (
        <GText text={value.toString()} size={fontSize || SIZE.PARAGRAPH_REGULAR_16} color={COLORS.WHITE} />
      ) : null}
    </div>
  )
}

export default GTabRoundedNotification
