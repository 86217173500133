/* eslint-disable array-callback-return */
import { setValueCurrentField, setPrevNameOption, setObserversChain } from "../../../Redux/store/form/form.actions"
import { setValueForSelect } from "../../../Redux/store/select/select.actions"

export const checkShowOn = (
  observers: {} | any,
  blockObject: {} | any,
  observer: boolean,
  submitObject: {} | any,
  selectOptions: {} | any,
  dispatch: any
) => {
  if (!observer) return true
  // not this property on block
  else if (
    !observers[blockObject.name] && // first render block
    blockObject.visibility === true
  ) {
    return true
  } else if (
    !observers[blockObject.name] &&
    blockObject.name === "block_country_of_citizenship" &&
    blockObject.arrayVisibilityOptions?.includes(submitObject["country_birth"])
  ) {
    return true
  } else if (observers[blockObject.name]) {
    if (
      observers[blockObject.name].type === "showStatesOn" &&
      blockObject.arrayVisibilityOptions?.includes(observers[blockObject.name].value)
    ) {
      return true
    }
    if (
      observers[blockObject.name].type === "showStatesOnNon" &&
      !blockObject.arrayNoVisibilityOptions?.includes(observers[blockObject.name].value)
    ) {
      return true
    }
    if (
      observers[blockObject.name].type === "showCitizenship/ImmigrationOn" &&
      blockObject.arrayVisibilityOptions.includes(observers[blockObject.name].value)
    ) {
      return true
    }
    // if option for current block was chose
    if (observers[blockObject.name].type === "showOn" && observers[blockObject.name].value && observers[blockObject.name].value !== blockObject.visibility) {
      return true
    } else if (
      observers[blockObject.name].type === "showOnForListRadio" &&
      observers[blockObject.name].value === blockObject.visibility
    ) {
      return true
    } else if (
      observers[blockObject.name].type === "showOn" &&
      (observers[blockObject.name].target === "block_diff_name_prev" ||
        observers[blockObject.name].target === "mailing_address_block") &&
      observers[blockObject.name].value === blockObject.visibility
    ) {
      blockObject.fields.map((item: any) => {
        if (submitObject[item.name] !== "") {
          dispatch(setValueCurrentField(item.name, ""))
        }
        if (submitObject[`${item.name}__full_name`]) {
          dispatch(setValueCurrentField(`${item.name}__full_name`, ""))
        }
      })
    } else if (
      observers[blockObject.name].type === "showOn" &&
      observers[blockObject.name].target === "Previous_name_Block" &&
      observers[blockObject.name].value === "No"
    ) {
      if (submitObject[blockObject.fields[0].name] !== "") {
        dispatch(setValueCurrentField(blockObject.fields[0].name, ""))
        blockObject.fields[0].fields.map((item: any) => {
          if (submitObject[item.name] !== "") {
            dispatch(setValueCurrentField(item.name, ""))
          }
        })
      }
    }
    // for radio button
    else if (observers[blockObject.name].type === "showCitizenshipOn") {
      if (observers[blockObject.name].value !== blockObject.visibility) return true
      // for radio button
      else if (
        observers[observers[blockObject.name].targetNext] &&
        observers[observers[blockObject.name].targetNext].value !== "Yes"
      ) {
        let objNext = {
          ...observers[observers[blockObject.name].targetNext],
          value: "Yes",
        }
        dispatch(setObserversChain(objNext))
        blockObject.fields.map((item: any) => {
          if (submitObject[item.name] !== "Yes") dispatch(setValueCurrentField(item.name, "Y"))
          dispatch(setValueCurrentField(`${item.name}__full_name`, "Yes"))
        })
      }
    } else if (
      observers[blockObject.name].type === "selectCountryBirthOn" &&
      observers[blockObject.name].value === "United States"
    ) {
      return true
    } else if (
      observers[blockObject.name].type === "selectCountryBirthOn" &&
      observers[blockObject.name].value !== "United States"
    ) {
      blockObject.fields.map((item: any) => {
        if (submitObject[item.name] !== "") dispatch(setValueCurrentField(item.name, ""))
        if (submitObject[`${item.name}__full_name`] !== "")
          dispatch(setValueCurrentField(`${item.name}__full_name`, ""))
      })
      return false
    }

    // for select
    else if (
      observers[blockObject.name].type === "selectSpecifyRelationshipOn" &&
      observers[blockObject.name].value === "Other"
    ) {
      return true
    }
    // for select
    else if (
      observers[blockObject.name].type === "selectRelationshipNameOn" &&
      observers[blockObject.name].value !== "Self"
    ) {
      return true
    }
    // for
    else if (
      observers[blockObject.name].type === "selectRelationshipNameOn" &&
      observers[blockObject.name].value === "Self"
    ) {
      blockObject.fields.map((item: any) => {
        if (submitObject[item.name] !== "") dispatch(setValueCurrentField(item.name, ""))
      })
      return false
    }
    // for select
    else if (
      observers[blockObject.name].type === "selectSpecifyRelationshipOn" &&
      (observers[blockObject.name].value === "Self-selected name change" ||
        observers[blockObject.name].value === "Adoption")
    )
      return true
    // for select
    else if (
      observers[blockObject.name].type === "selectSpecifyRelationshipOn" &&
      (observers[blockObject.name].value !== "Self-selected name change" ||
        observers[blockObject.name].value !== "Adoption")
    ) {
      blockObject.fields.map((item: any) => {
        if (submitObject[item.name] !== "") dispatch(setValueCurrentField(item.name, ""))
      })
    }
    // for select
    else if (observers[blockObject.name].type === "inputConfirmEmailOn" && observers[blockObject.name].value !== "")
      return true
    else if (
      observers[blockObject.name].type === "showBlockRelativesOn" &&
      new Date().getFullYear() - submitObject["date_birth-year"] < 18
    ) {
      return true
    } else if (
      observers[blockObject.name].type === "showBlockRelativesOn" &&
      new Date().getFullYear() - submitObject["date_birth-year"] >= 17
    ) {
      blockObject.fields.map((item: any) => {
        if (typeof item === "object" && !item.fields) {
          if (submitObject[item.name] !== "") dispatch(setValueCurrentField(item.name, ""))
        }
        if (typeof item === "object" && item.fields && item.fields.length) {
          item.fields.map((item: any) => {
            if (submitObject[item.name] !== "") dispatch(setValueCurrentField(item.name, ""))
          })
        }
      })
      return false
    }
    // for dataPicker
    else if (observers[blockObject.name].value === blockObject.visibility && !blockObject.autofill) {
      blockObject.fields.map((item: {} | any) => {
        if (item.type === "input" && submitObject[item.name]) {
          dispatch(setValueCurrentField(item.name, ""))
          if (item.dynamicSelect && item.nameBlock) dispatch(setValueForSelect(item.nameBlock, `${item.name}`, ""))
        } else if (selectOptions[item.nameBlock] && selectOptions[item.nameBlock][item.name])
          dispatch(setPrevNameOption(item.nameBlock, item.name, ""))
        return false
      })
    }
    // checkBox
    else if (observers[blockObject.name].type === "showOnCheckBox" && observers[blockObject.name].value === false) {
      return true
    }
    // checkBox
    else if (observers[blockObject.name].type === "showOther" && observers[blockObject.name].value === "other") {
      return true
    } else if (observers[blockObject.name].type === "showVeteran" && observers[blockObject.name].value === "veteran") {
      return true
    }
    return false
  }
  return false
}

const TYPES = {
  SHOW_ON: "showOn",
  SHOW_CITIZEN_SHIP_ON: "showCitizenshipOn",
  SHOW_ON_ADDRESS_AUTO_FILL: "showOnAddressAutofill",
  SHOW_ON_CITY_BIRTH_AUTO_FILL: "showOnCityBirthAutofill",
}

interface checkSubscriptionInterface {
  observers: { [key: string]: any };
  blockObject: { [key: string]: any };
}

export const checkSubscription = ({ observers, blockObject }: checkSubscriptionInterface) => {
  return (
    [
      {
        condition:
          observers?.[blockObject?.name]?.type === TYPES.SHOW_ON &&
          observers?.[blockObject?.name]?.value === blockObject?.visibility,
        value: true,
      },
      {
        condition:
          observers?.[blockObject?.name]?.type === TYPES.SHOW_CITIZEN_SHIP_ON &&
          observers?.[blockObject?.name]?.value === blockObject?.visibility,
        value: true,
      },
    ].find(({ condition }) => condition)?.value ?? false
  )
}

interface checkShowOnAutofillInterface {
  observers: { [key: string]: any };
  blockObject: { [key: string]: any };
}

export const checkShowOnAutofill = ({ observers, blockObject }: checkShowOnAutofillInterface) => {
  return (
    [
      {
        condition:
          observers?.[blockObject?.name]?.type === TYPES.SHOW_ON_ADDRESS_AUTO_FILL &&
          observers?.[blockObject?.name]?.value === blockObject?.visibility,
        value: true,
      },
      {
        condition:
          observers?.[blockObject?.name]?.type === TYPES.SHOW_ON_CITY_BIRTH_AUTO_FILL &&
          observers?.[blockObject?.name]?.value === blockObject?.visibility,
        value: true,
      },
    ].find(({ condition }) => condition)?.value ?? false
  )
}
