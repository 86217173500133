import React, {useState} from 'react'
import '../../styles/pages/account-settings-page.less'
import { Link } from "react-router-dom";
import {Input} from 'antd'
import {useSelector} from "react-redux";

const AccountSettingsPage = () => {

  const [pageInfo,] = useState({
    title: 'Account Settings',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ante leo, mattis sit amet imperdiet a, luctus quis quam. In hac habitasse platea dictumst.',
    fileText: 'File your other government forms instantly',
    available: 'Available Products',
    currentPage: window.location.href.split('/')[3]
  })
  const userData = useSelector(({auth}) => auth.userData);

  const settingsSectionsInfo = [
    {
      title: 'Name',
      link: `/name`,
      value: `${userData.lastName} ${userData.name}`
    },
    {
      title: 'Email',
      link: `/email-edit`,
      value: `${userData.eMail}`,
    },
    {
      title: 'Phone Number',
      link: `/phone`,
      value: `${userData.phone ? userData.phone : "Phone number is empty" }`
    },
    {
      title: 'Password',
      link: `/password`,
      value: 'asd'
    }
  ]

  const chooseInputType = (type, value) => type !== 'Password'
    ? <Input
      className='AccountSettingsPage-setting-valueBox-input'
      disabled
      value={value}
    />
    : <Input.Password
      className='AccountSettingsPage-setting-valueBox-input'
      disabled
      visibilityToggle={false}
      value={value}
    />


  const accountSettingsBoxes = arr => arr.map(item =>
    <div key={item.link}>
      <div
        className='AccountSettingsPage-setting'>
        <div className='AccountSettingsPage-setting-titleBox'>
          <span className='AccountSettingsPage-setting-titleBox-title'>{item.title}</span></div>
        <div className='AccountSettingsPage-setting-valueBox'>
          {chooseInputType(item.title, item.value)}
        </div>
        <div className='AccountSettingsPage-setting-linkBox'>
          <div className='AccountSettingsPage-setting-linkBox-link'>
            <Link to={`account-settings${item.link}`}>Edit</Link>
          </div>
        </div>
      </div>
      <hr/>
    </div>)

  const accountData = accountSettingsBoxes(settingsSectionsInfo)

  const {title} = pageInfo

  return <div className='AccountSettingsPage'>
    <h1 className='AccountSettingsPage-title'>{title}</h1>
    <hr/>
      {accountData}
  </div>
}

export default AccountSettingsPage
