import React from "react"

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
}

export const SvgExpeditedProcessing = ({
  width = 28,
  height = 28,
  color = "white",
  backgroundColor = "#13BA68",
}: ISvgCheck) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill={backgroundColor}/>
        <path 
          fill-rule="evenodd" 
          clipRule="evenodd" 
          d="M10.1333 13.9991C10.1333 11.2559 12.3572 9.032 15.1005 9.032C17.8437 9.032 20.0676 11.2559 20.0676 13.9991C20.0676 16.7424 17.8437 18.9663 15.1005 18.9663C12.3572 18.9663 10.1333 16.7424 10.1333 13.9991ZM15.1005 7.802C11.6779 7.802 8.90332 10.5766 8.90332 13.9991C8.90332 17.4217 11.6779 20.1963 15.1005 20.1963C18.523 20.1963 21.2976 17.4217 21.2976 13.9991C21.2976 10.5766 18.523 7.802 15.1005 7.802ZM15.7172 10.6498C15.7172 10.3102 15.4419 10.0348 15.1022 10.0348C14.7625 10.0348 14.4872 10.3102 14.4872 10.6498V13.9991C14.4872 14.2321 14.6188 14.445 14.8272 14.5492L17.06 15.6656C17.3638 15.8175 17.7332 15.6944 17.8851 15.3906C18.037 15.0868 17.9139 14.7174 17.6101 14.5655L15.7172 13.619V10.6498Z" 
          fill={color}
        />
        <path d="M8.47363 9.39392H11.8508" stroke={color} strokeWidth="1.23" strokeLinecap="round"/>
        <path d="M7.24512 12.4641H9.54775" stroke={color} strokeWidth="1.23" strokeLinecap="round"/>
        <path d="M7.24512 15.5343H9.54775" stroke={color} strokeWidth="1.23" strokeLinecap="round"/>
        <path d="M8.47363 18.6045H11.8508" stroke={color} strokeWidth="1.23" strokeLinecap="round"/>
      <defs>
      <clipPath id="clip0_16253_128948">
        <rect width="14.7368" height="14.7368" fill={color} transform="translate(6.63184 6.63074)"/>
      </clipPath>
      </defs>
    </svg>
  )
}
