import React, {CSSProperties, FC} from "react";
import GIconsSvgType from "../IconsSvg/IconsSvg";
import {SIZE} from "../../types/types";
import GText from "../Text/Text";
import "./ServiceCardContentBadge.scss"
import {COLORS, COLORS_NEW} from "../../types/Colors";

type GServiceCardContentBadgeProps = {
    text: string,
    iconType: string,
    active?: boolean,
    style?: React.CSSProperties,
    className?: string,
}
const GServiceCardContentBadge: FC<GServiceCardContentBadgeProps> = ({text, iconType, active = true, style, className}) => {

    return(
        <span className={`GServiceCardContentBadge ${className || ''}`} style={style}>
            <GIconsSvgType
                type={iconType}
                width={24}
                height={24}
                color={active ? COLORS.WHITE : COLORS_NEW.BLACK_400}
                backgroundColor={ active ? COLORS_NEW.GREEN_600 : COLORS_NEW.BLACk_100}
                fillCircle={true}
            />
            <div className={"GServiceCardContentBadge__Text"}>
                <GText
                    text={text}
                    size={SIZE.PARAGRAPH_REGULAR_16}
                    color={active ? COLORS_NEW.BLACK_800 : COLORS.BLACk_400}
                />
            </div>

        </span>
    )
}

export default GServiceCardContentBadge