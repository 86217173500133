import React, {FC, useEffect, useState} from "react";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS_NEW} from "../../types/Colors";
import {Charge} from "../../../FormComponents/BlockFormWithFields/BillingInformation/BlockBillingInformation";
import "./GMandatoryFeeItem.scss"
import {Radio} from "antd";
import GRadioButton from "../RadioButton/RadioButton";
import FormItem from "antd/es/form/FormItem";
import {useDispatch} from "react-redux";
import {setValueCurrentField} from "../../../../Redux/store/form/form.actions";
import {FEE_ITEM_KEY} from "../../../../util/FeeItemKey";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

interface GMandatoryFeeItemProps {
    charges: Charge[],
}

const GMandatoryFeeItem: FC<GMandatoryFeeItemProps> = ({charges}) => {
    const TOTAL = "Total";
    const ITEM_NAME = FEE_ITEM_KEY;
    const defaultId = charges.length > 0 ? charges[0].id : 0;
    const dispatch = useDispatch();
    const breakPoint = useBreakpoint();
    const getChargePrice = (chargeId: any) : number => {
        return charges.find((charge) => charge.id === chargeId)?.amount || 0;
    }

    const setChargeValues = (value: any) => {
        setValue(value);
        selPriceSelected(getChargePrice(value));
        dispatch(setValueCurrentField(ITEM_NAME, value));
    }

    const [value, setValue] = useState(defaultId);
    const [priceSelected, selPriceSelected] = useState<number>(getChargePrice(defaultId));

    const onChange = (e: any) => {
        setChargeValues(e.target.value)
    }

    useEffect(() => {
        dispatch(setValueCurrentField(ITEM_NAME, defaultId));
    }, []);

    useEffect(() => {
        selPriceSelected(getChargePrice(value));
    }, [charges]);

    useEffect(() => {
        setChargeValues(defaultId)
    }, [defaultId]);


    return (
        <FormItem name={ITEM_NAME}>
            <div className={`GMandatoryFeeItem`}>
                <div className={`GMandatoryFeeItem__ItemsContainer`}>
                    <Radio.Group onChange={onChange} value={value} defaultValue={value}>
                        {charges.map((charge, index: number) => {
                            return (
                                <GRadioButton value={charge.id} key={index}>
                                    <div className={`GRadioItem`}>
                                        <GText
                                            text={charge.name}
                                            size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_20 : SIZE.PARAGRAPH_REGULAR_16}
                                            color={COLORS_NEW.BLACK_800}
                                        />
                                        <div className={'fee-price'}>
                                            <GText
                                                text={`$${charge.amount}`}
                                                size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_20 : SIZE.PARAGRAPH_REGULAR_16}
                                                color={COLORS_NEW.BLACK_800}
                                            />
                                        </div>
                                    </div>
                                </GRadioButton>
                            )
                        })}
                    </Radio.Group>
                </div>
                <div className={`GMandatoryFeeItem__TotalPrice`}>
                    <div className={`GMandatoryFeeItem__TotalPrice__Item`}>
                        <GText text={TOTAL} size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_20 : SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                        <GText text={`$${priceSelected}`} size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_20 : SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                    </div>
                </div>
            </div>
        </FormItem>
    )
}

export default GMandatoryFeeItem