import {
  CHECK_USER_ACTIVATE_LINK, CHECK_USER_ACTIVATE_LINK_RESULT,
  CHECK_USER_EMAIL_EXIST,
  CHECK_USER_EMAIL_EXIST_FAILED,
  CHECK_USER_EMAIL_EXIST_SUCCESS,
  CHECK_USER_TOKEN,
  CHECK_USER_TOKEN_FROM_COOKIES,
  CHECK_USER_TOKEN_FROM_COOKIES_FAILED,
  CHECK_USER_TOKEN_FROM_COOKIES_SUCCESS,
  CLEAR_MESSAGE, CLEAR_STORAGE,
  CONFIRM_USER_SMS,
  CONFIRM_USER_SMS_SUCCESS,
  GET_USER_IP,
  GET_USER_IP_SUCCESS,
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER, RESET_INSTRUCTIONS,
  RESET_PASSWORD,
  SAVE_USER_DATA,
  SET_USER_TOKEN,
  SHOW_GROWL_MESSAGE,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  USER_LOG_IN_DATA_SAVE,
  USER_PRE_AUTH,
  USER_PRE_AUTH_FAILED,
  USER_PRE_AUTH_SUCCESS,
  USER_TOKEN_EXPAIRED,
  SET_IS_AUTH_FINISHED
} from "../../constants/ActionTypes";

import OuterIdRepository from "@dash/gov-plus__front-end__form/src/persistence/session/OuterIdRepository";
import AuthUserBearerTokenRepository from "@dash/gov-plus__front-end__form/src/persistence/session/AuthUserBearerTokenRepository";

const INIT_STATE = {
  userData: {
    password: "",
    name: JSON.parse(localStorage.getItem("user"))?.name || JSON.parse(sessionStorage.getItem("user"))?.name || "",
    lastName: JSON.parse(localStorage.getItem("user"))?.last_name || JSON.parse(sessionStorage.getItem("user"))?.last_name || "",
    eMail: JSON.parse(localStorage.getItem("user"))?.email || JSON.parse(sessionStorage.getItem("user"))?.email || "",
    phone: JSON.parse(localStorage.getItem("user"))?.phone || JSON.parse(sessionStorage.getItem("user"))?.phone || "",
    id: JSON.parse(localStorage.getItem("user"))?.user_id || JSON.parse(sessionStorage.getItem("user"))?.user_id || "",
    address: "",
    birthday: "",
    city: "",
    country: "",
    smsToken: "",
    sms: "",
    isUserLoggedIn: false
  },
  userIp: "",
  outerId: (JSON.parse(sessionStorage.getItem("user"))?.outer_id || JSON.parse(localStorage.getItem("user"))?.outer_id) || OuterIdRepository.getInstance().getValue(),
  authToken: AuthUserBearerTokenRepository.getInstance().getValue(),
  authStatus: '',
  tokenStatus: '',
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: "",
  growlStatus: null,
  messageInfo: "",
  userLogOutStatus: null,
  userTokenInCookiesStatus: null,
  checkUserEmailExistStatus: null,
  isUserActivateLinkActive: null,
  isAuthFinished: false
};

const AuthReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_PRE_AUTH: {
      return {
        ...state,
        loader: true,
      }
    }
    case USER_PRE_AUTH_SUCCESS: {
      return {
        ...state,
        loader: false,
        authToken: action.userData.token,
        outerId: action.userData.outer_id,
        userData: {
          ...state.userData,
          id: action.userData.user_id
        }

      }
    }
    case USER_PRE_AUTH_FAILED: {
      return {
        ...state,
        loader: false,
        messageInfo: action
      }
    }
    case SAVE_USER_DATA: {
      return {
        ...state,
        userData: {
          ...state.userData,
          eMail: action.payload.eMail,
          password: action.payload.password,
          phone: action.payload.phoneNumber,
          rememberMe: action.payload.rememberMe,
          smsToken: action.payload.smsToken,
          sms: "",
        },
      }
    }

    case GET_USER_IP: {
      return {
        ...state,
      }
    }

    case GET_USER_IP_SUCCESS: {
      return {
        ...state,
        userIp: action.payload.userIP
      }
    }

    case SET_USER_TOKEN: {
      return {
        ...state,
        authToken: action.payload.userToken,
        outerId: action.payload.outerId,
        userData:{
          ...state.userData,
          name: action.payload.userName || state.userData.name,
          lastName: action.payload.lastName || state.userData.lastName,
        }
      }
    }

    case CLEAR_MESSAGE: {
      return {
        ...state,
        messageInfo: "",
        alertMessage: "",
        showMessage: null
      }
    }

    case SHOW_GROWL_MESSAGE: {
      return {
        ...state,
        authStatus: action.payload.code,
        messageInfo: action.payload.messageInfo,
        alertMessage: "",
        showMessage: action.payload.showMessage,
        growlStatus: action.payload.growlStatus
      }
    }

    case USER_LOG_IN_DATA_SAVE: {
      return {
        ...state,
        userData: {
          ...state.userData,
          eMail: action.payload.email,
          password: action.payload.password,
          rememberMe: action.payload.rememberMe
        },
      }
    }

    case RESET_PASSWORD: {
      return {
        ...state,
        messageInfo: action.payload.messageInfo,
        growlStatus: action.payload.suppressGrowl,
      }
    }
     case RESET_INSTRUCTIONS: {
      return {
        ...state,
       loader: true,
      }
    }

    case SIGNUP_USER: {
      return {
        ...state,
        messageInfo: action.payload.messageInfo,
        growlStatus: action.payload.suppressGrowl,
        userData: {
          ...state.userData,
        },
        loader: true,
      }
    }

    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        messageInfo: action.payload.messageInfo,
        growlStatus: action.payload.suppressGrowl,
        loader: false,
        authStatus: 'success'
      }
    }

    case CONFIRM_USER_SMS: {
      return {
        ...state,
        loader: false,
        messageInfo: action.payload.messageInfo,
      }
    }

    case CONFIRM_USER_SMS_SUCCESS: {
      return {
        ...state,
        alertMessage: action,
        loader: true,
      }
    }

    case SIGNIN_USER: {
      return {
        ...state,
        loader: true,
      }
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        userData: {
          name: JSON.parse(localStorage.getItem("user"))?.name || "",
          lastName: JSON.parse(localStorage.getItem("user"))?.last_name || "",
          eMail: JSON.parse(localStorage.getItem("user"))?.email || "",
          phone: JSON.parse(localStorage.getItem("user"))?.phone || "",
          id: JSON.parse(localStorage.getItem("user"))?.user_id || "",
          address: action.payload.address,
          birthday: action.payload.birthday,
          city: action.payload.city,
          country: action.payload.country,
          isUserLoggedIn: true,
        },
        otpToken: "",
        outerId: action.payload.outer_id || OuterIdRepository.getInstance().getValue(),
        authToken: action.payload.authToken || AuthUserBearerTokenRepository.getInstance().getValue(),
        authStatus: null,
        loader: false,
        alertMessage: action.payload.messages || "",
        showMessage: false,
        initURL: '',
        messageInfo: action.payload.messageInfo,
        growlStatus: action.payload.suppressGrowl,
        userLogOutStatus: false,
      }
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }

    case SIGNOUT_USER: {
      for(let key in localStorage) {
        if (key.split('/')[0] !== "otpToken") {
          localStorage.removeItem(key);
        }
      }
      return {
        ...state,
        userData: {
          password: "",
          name: "",
          lastName: "",
          eMail: "",
          phone: "",
          id: "",
          address: "",
          birthday: "",
          city: "",
          country: "",
          smsToken: "",
          sms: "",
          isUserLoggedIn: false,
        },
        outerId: null,
        authToken: null,
        authStatus: '',
        loader: false,
        alertMessage: '',
        showMessage: false,
        initURL: "",
        growlStatus: null,
        messageInfo: "",
      }
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        userData: {
          password: "",
          name: "",
          lastName: "",
          eMail: "",
          phone: "",
          id: "",
          address: "",
          birthday: "",
          city: "",
          country: "",
          smsToken: "",
          sms: "",
          isUserLoggedIn: false,
        },
        outerId: null,
        authToken: null,
        authStatus: '',
        loader: false,
        alertMessage: '',
        showMessage: false,
        initURL: "",
        growlStatus: null,
        messageInfo: "",
        userLogOutStatus: action.payload.logOutStatus
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        messageInfo: action.payload?.messageInfo,
        alertMessage: action.payload?.messages,
        authStatus: action.payload?.code,
        showMessage: action.payload?.code >= 400,
        growlStatus: action.payload?.suppressGrowl,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        showMessage: false,
        loader: false
      }
    }

    case CHECK_USER_TOKEN: {
      return {
        ...state,
      }
    }

    case USER_TOKEN_EXPAIRED: {
      return {
        ...state,
        tokenStatus: action.payload.errorMessage,
        userLogOutStatus: action.payload.errorMessage === "common.USER_TOKEN_EXPIRED" || null
      }
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case CHECK_USER_TOKEN_FROM_COOKIES: {
      return {
        ...state,
        loader: true
      }
    }
    case CHECK_USER_TOKEN_FROM_COOKIES_SUCCESS: {
      return {
        ...state,
        userTokenInCookiesStatus: action.tokenStatus,
        loader: false
      }
    }
    case CHECK_USER_TOKEN_FROM_COOKIES_FAILED: {
      return {
        ...state,
        userTokenInCookiesStatus: action.tokenStatus,
        loader: false
      }
    }

    case CHECK_USER_EMAIL_EXIST: {
      return {
        ...state,
        loader: true
      }
    }

    case CHECK_USER_EMAIL_EXIST_SUCCESS: {
      return {
        ...state,
        checkUserEmailExistStatus: action.payload.checkUserEmailExistStatus,
        loader: false
      }
    }

    case CHECK_USER_EMAIL_EXIST_FAILED: {
      return {
        ...state,
        checkUserEmailExistStatus: action.payload.checkUserEmailExistStatus,
        loader: false
      }
    }
    case CHECK_USER_ACTIVATE_LINK: {
      return {
        ...state,
        loader: true
      }
    }
    case CHECK_USER_ACTIVATE_LINK_RESULT: {
      return {
        ...state,
        loader: false,
        isUserActivateLinkActive: action.payload.isUserLinkActive
      }
    }
    case CLEAR_STORAGE: {
      return {
        ...state,
        userData: {
          password: "",
          name: "",
          lastName: "",
          eMail: "",
          phone: "",
          id: "",
          address: "",
          birthday: "",
          city: "",
          country: "",
          smsToken: "",
          sms: "",
          isUserLoggedIn: false,
        },
        outerId: null,
        authToken: null,
        authStatus: '',
        tokenStatus: '',
        loader: false,
        alertMessage: '',
        showMessage: false,
        initURL: "",
        growlStatus: null,
        messageInfo: "",
        userLogOutStatus: null,
        userTokenInCookiesStatus: null,
        checkUserEmailExistStatus: null,
        isUserActivateLinkActive: null,
      }
    }
    case SET_IS_AUTH_FINISHED: {
      return {
        ...state,
        isAuthFinished: action.payload,
      }
    }
    default:
      return state;
  }
}

export default AuthReducer;
