import React, { FC } from "react"
import "./AuthFooter.scss"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { Link } from "react-router-dom"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"

type GAuthFooterProps = {
  firstLinkText: string,
  firstLinkShowIcon?: boolean,
  firstLinkUrl?: string,
  firstLinkClick?: any,
  description?: string,
  secondLinkText?: string,
  secondLinkUrl?: string,
  secondLinkClick?: any,
  secondBlockInRow?: boolean,
}

const GAuthFooter: FC<GAuthFooterProps> = ({
  firstLinkText,
  firstLinkShowIcon = false,
  firstLinkUrl,
  firstLinkClick,
  description,
  secondLinkText,
  secondLinkUrl,
  secondLinkClick,
  secondBlockInRow = false,
}) => {
  const styleBlock: React.CSSProperties = {
    display: secondBlockInRow ? "block" : "flex",
  }

  return (
    <div className={`GAuthFooter`}>
      <div className={`GAuthFooter__Container`}>
        <Link to={firstLinkUrl ? firstLinkUrl : ""} onClick={firstLinkClick}>
          <div className={`GAuthFooter__Container__Link__Container`}>
            {firstLinkShowIcon && (
              <div className={`GAuthFooter__Container__Link__Container__Icon`}>
                <GIconsSvgType
                  type={ICONS_SVG_TYPE.CHEVRON_LEFT}
                  width={20}
                  height={20}
                  color={COLORS_NEW.ROYAL_BLUE_700}
                />
              </div>
            )}
            <GText text={firstLinkText} size={SIZE.PARAGRAPH_REGULAR_14} color={COLORS_NEW.ROYAL_BLUE_700} />
          </div>
        </Link>
      </div>
      {description && secondLinkText && <hr className={`GAuthFooter__Divider`} />}
      {(description || secondLinkText) && (
        <div className={`GAuthFooter__Container`} style={styleBlock}>
          {description && <GText text={description} size={SIZE.PARAGRAPH_REGULAR_14} color={COLORS_NEW.BLACK_700} />}
          {secondLinkText && (
            <div className={`GAuthFooter__Container--Link`}>
              <GText
                text={secondLinkText}
                size={SIZE.LINK_REGULAR_14}
                color={COLORS_NEW.ROYAL_BLUE_700}
                href={secondLinkUrl}
                onClick={secondLinkClick}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default GAuthFooter
