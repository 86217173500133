import React, {FC, useState} from "react"
import GText from "../../atoms/Text/Text"
import { Button } from "antd"
import {COLORS, COLORS_NEW} from "../../types/Colors"
import { SizeType } from "antd/es/config-provider/SizeContext"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import { SIZE } from "../../types/types"
import "./GButton.scss"

export enum BUTTON_SIZE {
  LARGE = "large",
  MIDDLE = "middle",
  SMALL = "small",
}

export enum BUTTON_TYPE {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DANGER = "danger",
  RED_BG = "red_bg",
  GREEN_BG = "green_bg",
}

export interface IButton {
  type?: string;
  click?: React.MouseEventHandler<HTMLElement>;
  size?: SizeType;
  disabled?: boolean;
  text: string;
  textSize?: string;
  isSubmit?: boolean;
  loading?: boolean;
  showIcon?: boolean;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  iconType?: string;
}

/**
 * Used <GButton type={BUTTON_TYPE.PRIMARY} name={'Button name'} click={function()} size="large" icon="ICONS_SVG_TYPE.ICON_NAME" />
 * @param name Required. Text in the button
 * @param type Optional. BUTTON_TYPE.PRIMARY or BUTTON_TYPE.SECONDARY
 * @param click Optional. Action for the button
 * @param size Optional. "large" | "middle" | "small"
 * @param disabled Optional. show the button active or not
 * @param isSubmit Optional. boolean for submit o button type
 */

const GButton: FC<IButton> = ({
  type = BUTTON_TYPE.PRIMARY,
  click,
  size = BUTTON_SIZE.LARGE,
  text,
  textSize = SIZE.PARAGRAPH_BOLD_16,
  disabled = false,
  isSubmit = false,
  loading = false,
  showIcon = true,
  iconType = ICONS_SVG_TYPE.ARROW_FORWARD,
  style,
  textStyle
}) => {
  const touchscreen = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const [isHover, setIsHover] = useState(false)

  const handleMouseEnter = () => {
    !touchscreen && setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  let textColor = COLORS.WHITE
  let iconColor = COLORS.WHITE
  const ICON_WIDTH = 24
  const ICON_height = 24
  let btnBackgroundColor = isHover ? COLORS_NEW.ROYAL_BLUE_900 : COLORS_NEW.ROYAL_BLUE_700
  let btnColor = COLORS.WHITE
  let border = "none"

  if (type === BUTTON_TYPE.SECONDARY) {
    iconColor = isHover ? COLORS_NEW.ROYAL_BLUE_900 : COLORS_NEW.ROYAL_BLUE_700
    textColor = isHover ? COLORS_NEW.ROYAL_BLUE_900 : COLORS_NEW.ROYAL_BLUE_700
    border = isHover ? "solid 1px " + COLORS_NEW.ROYAL_BLUE_900 : "solid 1px " + COLORS_NEW.ROYAL_BLUE_700
    btnBackgroundColor = COLORS.WHITE
  }

  if (type === BUTTON_TYPE.DANGER) {
    iconColor = isHover ? COLORS.RED_800 : COLORS.RED_600
    textColor = isHover ? COLORS.RED_800 : COLORS.RED_600
    border = isHover ? "solid 1px " + COLORS.RED_800 : "solid 1px " + COLORS.RED_600
    btnBackgroundColor = COLORS.WHITE
  }

  if (type === BUTTON_TYPE.RED_BG) {
    iconColor = COLORS.WHITE
    textColor = COLORS.WHITE
    btnBackgroundColor = isHover ? COLORS_NEW.RED_900 : COLORS_NEW.RED_700
    border = "none"
  }

  if (type === BUTTON_TYPE.GREEN_BG) {
    iconColor = COLORS.WHITE
    textColor = COLORS.WHITE
    btnBackgroundColor = isHover ? COLORS_NEW.GREEN_900 : COLORS_NEW.GREEN_700
    border = "none"
  }

  let gButton: React.CSSProperties = {
    height: style?.height || 56,
    backgroundColor: btnBackgroundColor,
    border: border,
    color: btnColor,
    borderRadius: 12,
    ...style,
  }

  return (
    <Button
      className={`GButton GButton--${type}`}
      style={gButton}
      htmlType={isSubmit ? "submit" : "button"}
      disabled={disabled}
      onClick={click}
      size={size}
      loading={loading}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {<GText size={textSize} text={text} color={textColor} style={textStyle} />}
      {showIcon && !loading && (
        <div className={`GButton__IconContainer`}>
          <GIconsSvgType
            type={iconType}
            color={textStyle?.color || iconColor}
            width={ICON_WIDTH}
            height={ICON_height}
          />
        </div>
      )}
    </Button>
  )
}

export default GButton
