import React, {FC} from "react"
import {HEADER_SIZE_THEME, IHeader} from "./Header"
import {SIZE} from "../../types/types"

import {COLORS_NEW} from "../../types/Colors"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
import GText from "../../atoms/Text/Text";
import {getPropertiesForEvents} from "../../../../services/gtm/backEndEvent"
import "./HeaderForm.scss"
import ToApplyFormIdRepository from "../../../../persistence/session/ToApplyFormIdRepository";

import headerImgGlo from "../../../../assets/image/applications-image-horizontal-glo.webp"
import headerImgGloMobile from "../../../../assets/image/applications-image-horizontal-glo-mobile.webp"

type GHeaderFormProps = {
    headerProps: IHeader,
    processingPage?: boolean
}

const GHeaderForms: FC<GHeaderFormProps> = ({headerProps, processingPage = false}) => {
    const breakPoint = useBreakpoint()
    const webFlowServicesData = JSON.parse(localStorage.getItem("webFlowSolutionData") ?? "{}") ?? {}
    let linkPhotoProcessingSteps;
    let shadow = true
    const {solution, formType} = getPropertiesForEvents()

    if (ToApplyFormIdRepository.getInstance().getValue()) {
        linkPhotoProcessingSteps =
            webFlowServicesData[ToApplyFormIdRepository.getInstance().getValue() ?? ""]?.illustrationM.url
    } else {
        linkPhotoProcessingSteps = solution
            ? processingPage ? webFlowServicesData[solution]?.illustrationL.url : webFlowServicesData[solution]?.illustrationL.url
            : webFlowServicesData[formType.toUpperCase()]?.illustrationL.url

        shadow = solution ? true : formType.toUpperCase() !== "GLO"
    }

    const size = headerProps.size ?? HEADER_SIZE_THEME.HEADER_FORMS

    const gHeaderFormsContent: React.CSSProperties = {
        padding: "40px 0 0 0",
        maxWidth: size,
        width: !breakPoint.xs ? size : "100%",
        backgroundColor: COLORS_NEW.ROYAL_BLUE_200,
        borderRadius: processingPage ? "12px" : "16px",
        maxHeight: processingPage ? (!breakPoint.xs ? 260 : 170) : (breakPoint.xs ? 314 : 372),
        height: processingPage ? (!breakPoint.xs ? 260 : 170) : (breakPoint.xs ? 314 : 372),
        position: "relative",
        overflow: "hidden"
    }

    const cardShadow = "0px 8px 32px 8px rgba(0, 0, 0, 0.08)"

    let gHeaderFormsContentProductImage: React.CSSProperties = {
        marginTop: solution ? "24px" : "6px",
        display: "flex",
        justifyContent: "center",
        position: processingPage ? "absolute" : 'relative',
        bottom: processingPage ? 0 : '',
        width: "100%",
        borderRadius: 11
    }

    let image: React.CSSProperties = {
        maxHeight: breakPoint.xs ? "164px" : "230px",
        width: breakPoint.xs ? solution === "TSA" ? "auto" : "117.35px" : "auto",
        height: solution === "TSA" ? "125px" : "auto",
        maxWidth: 304,
        boxShadow: "none",
        translate: processingPage ? "0 20px" : "none"
    }

    return (
        processingPage ?
        <>
        <div style={gHeaderFormsContent}>
            <div className={`HeaderForm--HideShadow`} style={gHeaderFormsContentProductImage}>
                {solution && <img style={image} src={linkPhotoProcessingSteps} alt={""}/>}
            </div>
        </div>
        <div className={"HeaderForm__Title HeaderForm__Title__Processing"} style={{marginTop: 32}}>
            <GText
                text={headerProps.title}
                size={SIZE.HEADING_XL}
                color={COLORS_NEW.BLACK_1000}
                innerHtml={!breakPoint.xs ? headerProps.title : headerProps.title.replaceAll("<br>", " ")}
            />
            <GText
                text={headerProps.description}
                size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_20 : SIZE.PARAGRAPH_BOLD_16}
                color={COLORS_NEW.BLACK_500}
                innerHtml={!breakPoint.xs ? headerProps.description : headerProps.description.replaceAll("<br>", " ")}
            />
        </div>
        </>
        :
        <div style={gHeaderFormsContent}>
            <div className={"HeaderForm__Title HeaderForm__Title__Processing"}>
                <GText
                    text={headerProps.title}
                    size={!breakPoint.xs ? SIZE.HEADING_LG : SIZE.HEADING_MD}
                    color={COLORS_NEW.BLACK_1000}
                    maxWidth={"448px"}
                    innerHtml={!breakPoint.xs ? headerProps.title : headerProps.title.replaceAll("<br>", " ")}
                />
                <GText
                    text={headerProps.description}
                    size={!breakPoint.xs ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16}
                    color={COLORS_NEW.BLACK_600}
                    innerHtml={!breakPoint.xs ? headerProps.description : headerProps.description.replaceAll("<br>", " ")}
                />
            </div>

            <div className={`HeaderForm--HideShadow`} style={gHeaderFormsContentProductImage}>
                {
                    solution && <img style={image} src={linkPhotoProcessingSteps} alt={""}/>
                }
                {
                    !solution && <div className="HeaderFormGLOImg">
                        <img src={breakPoint.md ? headerImgGlo : headerImgGloMobile}/>
                    </div> 
                }

            </div>
        </div>
    )
}

export default GHeaderForms
