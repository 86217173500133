import React, { useEffect, useMemo } from "react"
import FormPageContent from "./FormPageContent/FormPageContent"
import { connect } from "react-redux"
import { StepInterface } from "../../Redux/InterfacesEntity/step.interface"
import { getArraySteps, getStoreFromEditor, setCurrentStep } from "../../Redux/store/step/step.actions"
import CheckStatusComponent from "../../Components/CheckStatusComponent/CheckStatusComponent"
import { useHistory } from "react-router-dom"
import defineIndexCurrentStep from "./defineIndexCurrentStep"
import {
  clearSubmitInStartAPP, getFailureAction,
  setFormId,
  setNewPrefilledObject, setPaymentError,
  setValueCurrentField,
} from "../../Redux/store/form/form.actions"
import PageMainContainer from "../../Hocs/PageMainLayout/PageMainContainer"
import { getPropertiesForEvents } from "../../services/gtm/backEndEvent"
import { getTopScrollPosition } from "../../Components/FormComponents/FormBlock/FormBlock"
import { clearAdditionalInStartApp } from "../../Redux/store/additionalForm/additional.actions"
import { clearUploadInStartApp } from "../../Redux/store/upload/upload.actions"
import {
  CER_DISABLE_STATES,
  CER_FORM_IDS,
  getDisableCerStateMessage,
  STATE_BIRTH_TAG_FIELD,
} from "../../shared/constans/cer-contants"
import AuthUserBearerTokenRepository from "../../persistence/session/AuthUserBearerTokenRepository";

type FormPageProps = {
  arraySteps: [] | any,
  currentStep: StepInterface,
  indexCurrentStep: number,
  loading: string,
  errorSubmit: {} | any,
  eventPageName: string,
  labelForm: string,
  dispatch: any,
  tagFields: any,
}

export const delayTimer = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const FormPage: React.FunctionComponent<FormPageProps> = ({
  arraySteps,
  currentStep,
  indexCurrentStep,
  loading,
  errorSubmit,
  eventPageName,
  labelForm,
  dispatch,
  tagFields,
}) => {

  const clientId = new URLSearchParams(window.location.search).get("clientId")
  const clientIdError = new URLSearchParams(window.location.search).get("clientId-error")

  let history = useHistory()

  const { form_id: formId } = useMemo(() => getPropertiesForEvents() ?? {}, [])

  useEffect(() => {
    dispatch(setNewPrefilledObject({}))
    dispatch(clearSubmitInStartAPP())
    dispatch(clearAdditionalInStartApp())
    dispatch(clearUploadInStartApp())
    delayTimer(500).then(() => {
      dispatch(setValueCurrentField("form_id", formId))
      dispatch(setValueCurrentField("form-type", formId?.split("-")[formId?.split("-")?.length - 1]))
      dispatch(setFormId(formId))
    })
  }, [dispatch, formId])

  useEffect(() => {
    if (CER_FORM_IDS.includes(formId) && tagFields) {
      const stateBirthTag = tagFields[STATE_BIRTH_TAG_FIELD]
      if (stateBirthTag) {
        const isDisabled = CER_DISABLE_STATES.find((state) => state.code === stateBirthTag)
        if (isDisabled) {
          sessionStorage.setItem("returnFormApps", getDisableCerStateMessage(isDisabled.name))
          history.push("/formsApp")
        }
      }
    }
  }, [tagFields, dispatch, history])

  useEffect(() => {
    if (currentStep?.application_category) {
      dispatch(setValueCurrentField("application_category", currentStep?.application_category))
    }
  }, [dispatch, currentStep])

  useEffect(() => {
    window.addEventListener("EditorJsonChanged", () => dispatch(getStoreFromEditor(indexCurrentStep)), false)
  }, [dispatch, indexCurrentStep])

  useEffect(() => {
    if (AuthUserBearerTokenRepository.getInstance().getValue()) {
      dispatch(getArraySteps())
    } else {
      history.push({ pathname: `/formsApp` })
    }
  }, [dispatch, history])

  useEffect(() => {
    dispatch(setCurrentStep(indexCurrentStep || 0))
  }, [dispatch, indexCurrentStep, arraySteps])

  // for define indexCurrentStep
  useEffect(() => {
    defineIndexCurrentStep(indexCurrentStep, arraySteps, history, dispatch)
  }, [arraySteps, dispatch, history, history.location.search, indexCurrentStep])

  // gtm events
  useEffect(() => {
    if (
      (currentStep?.name === "sss-after2" || currentStep?.name === "sss-after1") &&
      !sessionStorage.getItem("address_validation_event")
    ) {
      sessionStorage.setItem("address_validation_event", "true")

      /* Event Processing Step */
    }
  }, [currentStep])

  useEffect(() => {
    setTimeout(() => getTopScrollPosition(), 150)
  }, [currentStep])

  useEffect(() => {
    /**
     * Clear form errors when switching forms
     * avoid form errors from previous form to be present on a new one
     */
    dispatch(setPaymentError(null))
    dispatch(getFailureAction(null))
  }, [history.location.pathname])

  const returnJSONPartApplication = () => {
    return (
      <>
        {currentStep?.mainLayout && <PageMainContainer currentStep={currentStep} />}
        {!currentStep?.mainLayout && <FormPageContent />}
      </>
    )
  }

  interface ItemDataInterface {
    constraint: string;
    message: string;
    property: string;
  }

  type ItemsDataType = ItemDataInterface[]

  const renderErrorResponse = ({ message = "", data }: { message: string, data: ItemsDataType }) => {
    return (
      <>
        <h3 className="list-error__header">{message}</h3>
        <ul className={"list-error__container"}>
          {data?.map?.(({ constraint, message: messageData, property }) => (
            <p className={"list-error__text"}>{`${message} ${constraint} ${messageData} ${property}`}</p>
          ))}
        </ul>
      </>
    )
  }

  const returnCheckStatus = () => {
    return (
      <div className={"check-status-page__container"}>
        <CheckStatusComponent />
      </div>
    )
  }
  return (
    <>
      {(loading === "loading" && !errorSubmit && (
        <div className={`common-loader__container`}>
          <div className="preloader" />
        </div>
      )) ||
        (loading === "notFound" && <></>) ||
        (loading === "loaded" &&
          // eslint-disable-next-line prettier/prettier
          ((((arraySteps && arraySteps.length > 0) || formId !== "check-status") &&
            !clientIdError &&
            returnJSONPartApplication()) ||
            // eslint-disable-next-line prettier/prettier
            (((formId === "check-status" && !clientId) || clientIdError) && returnCheckStatus()))) ||
        (loading === "error" && (
          <div className={`common-loader__container`}>
            <div className={`common-loader__text`}>{renderErrorResponse(errorSubmit?.error || {})}</div>
          </div>
        ))}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    arraySteps: state.step.arraySteps,
    currentStep: state.step.currentStep,
    indexCurrentStep: state.step.indexCurrentStep,
    loading: state.loading.loadingState,
    errorSubmit: state.form.error,
    labelForm: state.step.labelForm,
    tagFields: state.form.tagStoreWithFields,
  }
}

export default connect(mapStateToProps)(FormPage)
