import React from "react"
import { setValueModal } from "../../Redux/store/form/form.actions"
import { connect } from "react-redux"
import TermsOfUseComponent from "./TermsOfUseComponent/TermsOfUseComponent"
import PrivacyPolicyComponent from "./PrivacyPolicyComponent/PrivacyPolicyComponent"
import ThankYouPageComponent from "./ThankYouPageComponent/ThankYouPageComponent"
import HelperMobileComponent from "./HelperMobileComponent/HelperMobileComponent"
import LoginUserModalContainer from "./LoginUserModal/LoginUserModalContainer"
import AnimationDownLoadPrefilled from "./AnimationDownLoadPrefilled/AnimationDownLoadPrefilled"
import AnimationSuccessPrefilled from "./AnimationSuccessPrefilled/AnimationSuccessPrefilled"
import ReviewPageModal from "./ReviewPageModal/ReviewPageModal"

type ModalWindowProps = {
  modalValue: string,
  dispatch: any,
}

const ModalWindow: React.FunctionComponent<ModalWindowProps> = ({ modalValue, dispatch }) => {
  const setModalContentClassContainer = (modalValue: string) => {
    if (modalValue === "terms-of-use") {
      return "modal-window__container-info"
    } else if (modalValue === "privacy-policy") {
      return "modal-window__container-info"
    } else if (modalValue === "helper") {
      return "modal-window__container-info"
    } else if (modalValue === "thank-you-page") {
      return "modal-window__thank-you-page"
    } else {
      return "modal-window__container-info"
    }
  }

  const modalContentClassContainer = setModalContentClassContainer(modalValue)

  const handlerOnClickContainer = (modalValue: string) => {
    if (modalValue !== "animation_prefilled") {
      dispatch(setValueModal(""))
    }
  }

  const typeModals: any = {
    "terms-of-use": <TermsOfUseComponent dispatch={dispatch} setValueModal={setValueModal} />,
    "privacy-policy": <PrivacyPolicyComponent dispatch={dispatch} setValueModal={setValueModal} />,
    "thank-you-page": <ThankYouPageComponent dispatch={dispatch} setValueModal={setValueModal} />,
    "helper-mobile": <HelperMobileComponent setValueModal={setValueModal} />,
    login_user: <LoginUserModalContainer setValueModal={setValueModal} />,
    animation_prefilled_part_one: <AnimationDownLoadPrefilled />,
    animation_prefilled_part_two: <AnimationSuccessPrefilled dispatch={dispatch} />,
    review_page_modal: <ReviewPageModal />,
  }
  const modalValueClass = modalValue.includes("animation_prefilled") ? "animation_prefilled" : modalValue
  return (
    <>
      {modalValue && (
        <div
          className={`modal-window__container ${modalValueClass}`}
          onClick={(e) => handlerOnClickContainer(modalValue)}
        >
          <div className={modalContentClassContainer} onClick={(e) => e.stopPropagation()}>
            {typeModals[modalValue]}
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  modalValue: state.form.modalValue,
})

export default connect(mapStateToProps)(ModalWindow)
