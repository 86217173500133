
import { useContext } from "react";
// @ts-ignore
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import { HistoryContext } from "../context/HistoryContext";

export const useNavigation = () => {
    const history = useHistory()
    const navigate = (route: string) => {
        history.push(route)
    }

    return {
        navigate
    }
}

export const useNavigationHistory = () => {
    const context = useContext(HistoryContext)
    if (!context) {
        throw new Error('Applications context is missing the provider and cannot be accessed')
    }
    return context
}