import IPersistenceHandler from "../IPersistenceHandler";
import IPersistenceRepository from "../IPersistenceRepository";
import LocalStorageHandler from "../LocalStorageHandler";

abstract class BaseLocalStorageRepository implements IPersistenceRepository {
    private _handler: IPersistenceHandler;
    private readonly _key: string;

    protected constructor() {
        this._key = this.getKeyName();
        this._handler = new LocalStorageHandler();
    }

    setValue(value: string, defaultValue?: string | null): void {
        this._handler.set(this._key, value ?? defaultValue)
    }

    getValue(): string | null {
        return this._handler.get(this._key) ?? null;
    }

    exist(): boolean {
        return this._handler.has(this._key);
    }

    abstract getKeyName(): string;

    removeItem(): void {
        return this._handler.remove(this._key);
    }
}

export default BaseLocalStorageRepository;
