import React, { FC } from "react"
import GHeaderForms from "./HeaderForms"
import {getPropertiesForEvents, getServiceAndSolutionNameForFormsHeaders} from "../../../../services/gtm/backEndEvent";
import {IGBenefitsList} from "../../organisms/BenefitsList/GBenefitsList";
import GHeaderPayment from "./Payment/HeaderPayment";
import GHeaderSubscriptionPayment from "./SubscriptionPayment/HeaderSubscriptionPayment";
import GHeaderCombinedPayment from "./CombinedPayment/HeaderCombinedPayment";
import {ProductsIds} from "../../../../util/ProductsIds";
import {HeaderInformation} from "../../../../util/HeaderText";
import ToApplyFormIdRepository from "../../../../persistence/session/ToApplyFormIdRepository";
import ServiceRepository from "../../../../persistence/session/ServiceRepository";
import FormIdRepository from "../../../../persistence/session/FormIdRepository";
import SolutionRepository from "../../../../persistence/session/SolutionRepository";
import StringHelper from "../../../../util/StringHelper";
import {connect} from "react-redux";
import {StepInterface} from "../../../../Redux/InterfacesEntity/step.interface";
import {
  STEP_NAME_PAYMENT_PAGE,
  STEP_PAYMENT_TYPE_COMBINED,
  STEP_PAYMENT_TYPE_SUBSCRIPTION
} from "../../../../shared/constans/payment";
// @ts-ignore
import { useUser } from "../../../../../../dashboard-govplus-front/src/hooks/useUser";
import { COLORS_NEW } from "../../types/Colors";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

export enum HEADER_THEME_TYPE {
  HEADER_FORMS = "header_forms", HEADER_PAYMENT = "header_payment"
}
export enum HEADER_SIZE_THEME {
  HEADER_FORMS ="604px" ,
  ADDITIONAL_STEPS = "800px"
}

export enum HEADER_PRODUCT_IMAGE_TYPE {
  TYPE_WEBFLOW = "webflow",
}

export interface IProductImage {
  type: string;
  product: string;
  src: string;
}

export interface IHeader {
  theme: string;
  title: string;
  description: string;
  productImage: IProductImage;
  title2: string;
  benefits: IGBenefitsList
  size?: string,
  fieldsCount?: number | string,
}

export type GHeaderProps = {
  headerProps: IHeader,
  currentStep: StepInterface | any,
}

const GHeader: FC<GHeaderProps> = ({ headerProps, currentStep }) => {
  const processingPage = currentStep?.formType === "processing_steps"
  const breakPoint = useBreakpoint()

  const gHeaderContainer: React.CSSProperties = {
    position: "relative",
    backgroundColor: processingPage ? "white" : COLORS_NEW.Background,
    padding: processingPage ? breakPoint.md ? 32 : 16 : 0,
    borderRadius: 24,
    maxWidth: 810,
    boxShadow: processingPage ? "0px 8px 24px 4px rgba(0, 0, 0, 0.04)" : ''
  }

  const { service, solutionName } = getServiceAndSolutionNameForFormsHeaders()
  const { form_id: formId } = getPropertiesForEvents()
  const {userFlattenData} = useUser()

  const capitalizedService = StringHelper.capitalizeServiceName(service)
  const capitalizedSolution = StringHelper.capitalizeServiceName(solutionName)

  if(formId === ProductsIds.GloFormId && ToApplyFormIdRepository.getInstance().getValue() == null) {
    headerProps.title = HeaderInformation.GLO_GENERAL_HEADER_TITLE
    headerProps.description = HeaderInformation.GLO_GENERAL_HEADER_DESCRIPTION
  } else {
    if(!ServiceRepository.getInstance().getValue()) {
      if(formId === ProductsIds.GloFormId) {
        headerProps.title = HeaderInformation.FORM_GENERAL_HEADER_TITLE.replace("{solution}", solutionName).replace("{service}", capitalizedService)
        headerProps.description = HeaderInformation.FORM_GENERAL_HEADER_DESCRIPTION.replace("{solution}", solutionName).replace("{service}", capitalizedService)
      } else {
        headerProps.title = headerProps.title.replace("{solution}", solutionName).replace("{service}", capitalizedService)
        headerProps.description = headerProps.description.replace("{solution}", solutionName).replace("{service}", capitalizedService)
      }
    } else {
      headerProps.title = headerProps.title.replace("{solution}", capitalizedSolution).replace("{service}", capitalizedService)
      headerProps.description = headerProps.description.replace("{solution}", capitalizedSolution).replace("{service}", capitalizedService)
    }

    if (headerProps.fieldsCount) {
      headerProps.title = headerProps.title.replaceAll("{X}", headerProps.fieldsCount.toString())
    }

    // If user hasn't filled any field on GLO form
    // Excluding country of birth since it gets autofilled on the form itself
    if (headerProps.theme !== HEADER_THEME_TYPE.HEADER_PAYMENT && currentStep.type === "submit_form" && (
      !userFlattenData?.data.personal || Object.keys(userFlattenData?.data?.personal).length === 0 || (
          Object.keys(userFlattenData?.data?.personal).length === 1 &&
          userFlattenData?.data?.personal?.demographics?.countryOfBirth
      )
    )) {
      headerProps.title = HeaderInformation.FORM_HEADER_WITHOUT_REMAINING_QUESTIONS.replace("{solution}", solutionName).replace("{service}", capitalizedService)
      headerProps.description = HeaderInformation.FORM_HEADER_DESCRIPTION_WITHOUT_REMAINING_QUESTIONS.replace("{solution}", solutionName).replace("{service}", capitalizedService)
    }
  }

  const getComponentTheme = () => {
    switch (headerProps.theme){
      case HEADER_THEME_TYPE.HEADER_FORMS:
        return <GHeaderForms headerProps={headerProps} processingPage={processingPage} />
      case HEADER_THEME_TYPE.HEADER_PAYMENT:
        if(currentStep.name === STEP_NAME_PAYMENT_PAGE){
          switch (currentStep.paymentType) {
            case STEP_PAYMENT_TYPE_SUBSCRIPTION:
              return <GHeaderSubscriptionPayment props={headerProps}/>
            case STEP_PAYMENT_TYPE_COMBINED:
              return <GHeaderCombinedPayment props={headerProps}/>

          }
        }
        return <GHeaderPayment props={headerProps}/>
      default:
        return <></>
    }
  }

  return (
    <div className="GHeader" style={gHeaderContainer}>
      {getComponentTheme()}
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  currentStep: state.step.currentStep
})
export default connect(mapStateToProps)(GHeader)
