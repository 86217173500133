import React from "react"

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
  fillCircle?: boolean;
}

export const SvgUserCheckCircle= ({
  width = 65,
  height = 65,
  color = "#185EC8",
  backgroundColor = "#13BA68",
}: ISvgCheck) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill={backgroundColor}/>
      <path 
        d="M16.4563 15.8412H11.544C10.8926 15.8412 10.2679 16.1 9.80728 16.5606C9.34666 17.0212 9.08789 17.6459 9.08789 18.2973V19.5254" 
        stroke={color} 
        strokeWidth="1.47368" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M14 17.9626L15.405 19.3357C15.5499 19.4773 15.7819 19.4754 15.9244 19.3314L19.3794 15.8412" 
        stroke={color} 
        strokeWidth="1.47368" 
        strokeLinecap="round"
      />
      <path 
        d="M14.0001 13.3851C15.3566 13.3851 16.4562 12.2854 16.4562 10.9289C16.4562 9.57243 15.3566 8.47278 14.0001 8.47278C12.6436 8.47278 11.5439 9.57243 11.5439 10.9289C11.5439 12.2854 12.6436 13.3851 14.0001 13.3851Z" 
        stroke={color} 
        strokeWidth="1.47368" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>

  )
}
