import React, {useEffect, useState} from "react";
import "./index.less"
import Header from "../../containers/Header";
import {Form} from "antd";
import {saveUserData, setCheckStatusEmailProductId} from "../../appRedux/actions";
import {useDispatch, useSelector} from "react-redux";
import {userService} from "../../api/api";
import {useHistory} from "react-router-dom";
import GAddPhone, {ADD_PHONE_FORM_TYPE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/AddPhone/AddPhone";
import {notifyError} from "@dash/gov-plus__front-end__form/src/util/helper";
import GLogo from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Logo/Logo";


const AddPhoneNumber = () => {
  const {userData: {password, eMail}} = useSelector(({auth}) => auth);
  const [valShowStatus, setValShowStatus] = useState(false);
  const [valShowMessage, setValShowMessage] = useState("");
  const [sendPhoneNumberLoaderStatus, setSendPhoneNumberLoaderStatus] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const checkEmailForGovPlus = new RegExp("@govplus.com").test(eMail) ? "99999999999999" : "(999) 999-9999";

  const [formAddForm] = Form.useForm()

  const onFinish = async (event) => {

    const phone = event.phoneUSA?.match(/\d/g)?.join("") || event.phoneInternational;

    if ((!new RegExp('^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$|^\\d{10}$').test(event.phoneUSA)) || (event.phoneInternational && phone.length < 11)) {
      setValShowStatus(true)
      setValShowMessage("Please fill correct correct phone number")
    } else {
      try {
        setSendPhoneNumberLoaderStatus(true)
        const {data} = await userService.generateSms(phone);
        dispatch(saveUserData({
          password,
          eMail,
          phoneNumber: phone,
          smsToken: data?.data?.smsToken
        }))
        localStorage.setItem("phone", phone)
        if (data?.data?.smsToken) {
          history.push("/verification_code", "confirmPhone")
        }else{
          setValShowMessage("Something went wrong, please check your phone number.")
          setValShowStatus(true)
        }
        setSendPhoneNumberLoaderStatus(false)
      } catch (err) {
        const {response:{data}} = err;

        if (data.messageInfo){
          setValShowMessage(data.messageInfo)
        }else{
          setValShowMessage("Something went wrong, please check your phone number.")
        }
        setValShowStatus(true)
        setSendPhoneNumberLoaderStatus(false)
        notifyError(err);
      }
    }
  };

  useEffect(() => {
    if (password === "" && eMail === "") {
      history.push("/signin")
    }
  }, [password, eMail])

  return (
    <div className="gx-app-login-wrap addPhoneNumber">
      <div className={'gx-app-login-logo'}>
        <GLogo width={110} height={28} />
      </div>
      <GAddPhone
          formOnFinish={onFinish}
          form={formAddForm}
          onFinishFailed={()=>{
            setValShowStatus(true)
          }}
          formType={new RegExp("@govplus.com").test(eMail) ? ADD_PHONE_FORM_TYPE.PHONE_INTERNATIONAL : ADD_PHONE_FORM_TYPE.PHONE_USA}
          formItemPhoneInitialValue={JSON.parse(localStorage.getItem("userDataFromGov"))?.phone ? JSON.parse(localStorage.getItem("userDataFromGov")).phone : ""}
          valShowMessage={valShowMessage}
          onPhoneChange={()=> {
            setValShowStatus(false)
            setValShowMessage("")
          }}
          onPhoneKeyPress={(event)=> {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault()
            }
          }}
          phoneMaxLength={14}
          maskPhone={checkEmailForGovPlus}
          loader={sendPhoneNumberLoaderStatus}
          history={history}
          onFooterFirstLinkClick={() => {
            history.goBack()
            dispatch(setCheckStatusEmailProductId({productIdFromEmailCheckStatus: ""}))
          }}
      />
    </div>
  );
};

export default AddPhoneNumber;
