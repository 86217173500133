import React from "react"
import { ItemInterface } from "../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import BasicBlockContainer from "../UploadPhoto/MainBlockContainer/BasicBlockContainer"
import { arrayUploadPhotoComponents } from "../UploadPhoto/UploadPhotoService/datas/arrayUploadPhotoComponents"

type PackagePageAdditionalProps = {
  description: ItemInterface,
}

const DescriptionPageComponent: React.FunctionComponent<PackagePageAdditionalProps> = ({ description: item }) => (
  <BasicBlockContainer item={item} arrayComponents={arrayUploadPhotoComponents} />
)

export default DescriptionPageComponent
