import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

const TIMEZONE = "timezone"

class UserTimezoneRepository extends BaseSessionStorageRepository {
    private static _instance: UserTimezoneRepository;

    private constructor() {
        super();
    }

    public static getInstance():UserTimezoneRepository {
        if (!UserTimezoneRepository._instance) {
            UserTimezoneRepository._instance = new UserTimezoneRepository();
        }
        return UserTimezoneRepository._instance;
    }

    getKeyName(): string {
        return TIMEZONE;
    }
}

export default UserTimezoneRepository;
