import React, { FC } from "react"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GAuthHeader from "../../molecules/AuthHeader/AuthHeader"
import "./Reset.scss"
import { Form } from "antd"
import GText from "../../atoms/Text/Text"
import {INPUT_STATUS, SIZE} from "../../types/types"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import GButton from "../../molecules/Button/GButton"
import GAuthFooter from "../../molecules/AuthFooter/AuthFooter"
import GFormItem, { FORM_ITEM_STATUSES } from "../../organisms/FormItem/FormItem"
import GInputText from "../../molecules/InputText/InputText";


type GResetProps = {
  formOnFinish: any,
  formOnFocus: any,
  formOnChange: any,
  formOnBlur: any,
  form: any,
  emailSuffixErrorIcon: any,
  emailErrorMessage: any,
  loader: boolean,
}

const TITLE = "Reset password"
const DESCRIPTION =
  "Enter the email address linked to your account and we’ll send you instructions to reset your password by email."

const GReset: FC<GResetProps> = ({
  formOnFinish,
  formOnFocus,
  formOnChange,
  formOnBlur,
  form,
  emailSuffixErrorIcon,
  emailErrorMessage,
  loader,
}) => {
  return (
    <div className={`GReset`}>
      <GAuthHeader title={TITLE} description={DESCRIPTION} />
      <Form
        initialValues={{ remember: true }}
        name={`basic`}
        onFinish={formOnFinish}
        onFocus={formOnFocus}
        onChange={formOnChange}
        onBlur={formOnBlur}
        className={`GReset__Form`}
        layout={`vertical`}
        form={form}
      >
        <GFormItem
          label={
            <div className={`GReset__Form__Item__Label`}>
              <GText text={`Email`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_1000} />
            </div>
          }
          name="email"
          status={`${emailErrorMessage !== "" ? FORM_ITEM_STATUSES.ERROR : FORM_ITEM_STATUSES.DEFAULT}`}
          message={emailErrorMessage}
        >
          <GInputText
            suffixError={emailSuffixErrorIcon}
            inputStatus={`${emailErrorMessage !== "" ? INPUT_STATUS.ERROR : INPUT_STATUS.DEFAULT}`}
          />
        </GFormItem>

        <div className={`GReset__Form__BtnContainer`}>
          <GButton
            disabled={loader}
            text={`Send reset instructions`}
            size={"large"}
            isSubmit={true}
          />
        </div>
        <GAuthFooter
          firstLinkText={`Sign in`}
          firstLinkShowIcon={true}
          firstLinkUrl={`/signin`}
          description={`Don’t have an account?`}
          secondLinkText={`Sign up.`}
          secondLinkUrl={`/signup`}
        />
      </Form>
    </div>
  )
}
export default GReset
