import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED, HIDE_MESSAGE_PASSWORD_CHANGE,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  changePasswordStatus: false,
  changePasswordMessage: ""
};

const accountSettingsReducer = (state = INIT_STATE, action)=>{
  switch (action.type){
    case CHANGE_PASSWORD:{
      return{
        changePasswordStatus: true,
        changePasswordMessage: action.changePasswordMessage
      }
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordStatus: false,
      }
    }
    case CHANGE_PASSWORD_FAILED: {
      return {
        changePasswordStatus: false,
        changePasswordMessage: action.changePasswordMessage
      }
    }
    case HIDE_MESSAGE_PASSWORD_CHANGE: {
      return {
        ...state,
        changePasswordStatus: false,
        changePasswordMessage: ""
      }
    }
    default:
      return state;
  }
};

export default accountSettingsReducer;