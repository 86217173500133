import IEvent from "../IEvent"
import BaseBuilder from "../BaseBuilder"
import IdentityProtectionCoverageStarted from "./IdentityProtectionCoverageStarted";

/**
 * Identity Protection Activation Started Builder
 * @class IdentityProtectionCoverageStartedBuilder
 * @extends {BaseBuilder}
 * @implements {IBuilder}
 * @description Identity Protection Activation Started Builder
 */
export default class IdentityProtectionCoverageStartedBuilder extends BaseBuilder {
  private _bundle_id: string | undefined;
  private _package_name: string | undefined;

  /**
   * Set ip address
   * @param ipAddress
   * @returns {IBuilder}
   * @description This method is used to set ip address
   */

  setIpAddress(ipAddress: string | undefined) {
    this._ipAddress = ipAddress
    return this
  }

  /**
   * Set bundle id
   * @param bundle_id
   * @returns {IBuilder}
   * @description This method is used to set bundle_id
   */

  setBundleId(bundle_id: string | undefined) {
    this._bundle_id = bundle_id;
    return this;
  }


  /**
   * Set package name
   * @param package_name
   * @returns {IBuilder}
   * @description This method is used to set package name
   */

  setPackageName(package_name: string | undefined) {
    this._package_name = package_name
    return this
  }

  /**
   * Build event
   * @returns {IEvent}
   * @description This method is used to build event
   */
  build(): IEvent {
    return new IdentityProtectionCoverageStarted(this._bundle_id, this._package_name, this._ipAddress);
  }
}
