import BaseBuilder from "../BaseBuilder";
import IEvent from "../IEvent";
import IdentityProtectionRecordMonitoringUpdated from "./IdentityProtectionRecordMonitoringUpdated";

/**
 * Identity Protection Record Monitoring Updated
 * @class IdentityProtectionUpdated
 * @extends {Event}
 * @implements {IEvent}
 * @description Identity Protection Record Monitoring Updated event
 */
export default class IdentityProtectionRecordMonitoringUpdatedBuilder extends BaseBuilder {
    private _recordType: string | undefined;

    /**
     * Set recordType
     * @param recordType
     * @returns {IBuilder}
     * @description This method is used to set recordType
     */
    setRecordType(recordType: string | undefined) {
        this._recordType = recordType;
        return this;
    }

    /**
     * Set ip address
     * @param ipAddress
     * @returns {IBuilder}
     * @description This method is used to set ip address
     */
    setIpAddress(ipAddress: string | undefined) {
        this._ipAddress = ipAddress;
        return this;
    }

    /**
     * Build event
     * @returns {IEvent}
     * @description This method is used to build event
     */
    build(): IEvent {
        return new IdentityProtectionRecordMonitoringUpdated(this._recordType, this._ipAddress);
    }
}
