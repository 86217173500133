export enum FormsKeys {
    // The id of the product that the user wants to complete in GLO flow
    ToApplyFormId = "to_apply_form_id",
    // The id of the service that the user wants to complete in GLO flow
    ToApplyServiceId = "to_apply_service_id",
    SessionFormId = "form_id",
    FormId = "formId",
    Data = "data",
    Page = "page",
    GloSaved= "gloSaved",
}
