export const variablesForTable = {
  columns:{
    service:[
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status"
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date"
      }
    ],
    agency: [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status"
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date"
      }
    ],
  },
}
