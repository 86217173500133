import React from "react";
import "./index.less"
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import SettingsButton from "../../../../../../components/SettingsButton";
import GoogleMapComponent from "../../../../../../components/GoogleMapComponent";
import {sendAppointment} from "../../../../../../appRedux/actions";
import {IconSvg} from "../../../../../../components/svgImages/SvgImages";
import GText from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Text/Text";
import {SIZE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import {COLORS} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";
import GButton from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Button/GButton";
import { env } from "../../../../../../env";

const AppointmentSelection = ({productId}) => {
  const {allProducts, allProductsLoader} = useSelector(({allProducts}) => allProducts);
  const {userData: {name, lastName}, authToken} = useSelector(({auth}) => auth);
  const dispatch = useDispatch()
  const history = useHistory();
  const apointmentDate = allProducts[productId]?.appointment_times[0]?.label;
  const letLang = allProducts[productId]?.add.latLng;
  const selectTimes = allProducts[productId]?.appointment_times;
  const {address2, address1, city, zipcode, state} = allProducts[productId]?.appointment_address;

  const handleSendAppointment = (selectedVal) => {
    dispatch(sendAppointment({
      token: authToken,
      form_id: allProducts[productId].form_id,
      appointment_time: selectedVal,
      application_id: productId,
    }))
  }

  return (
    <div className={"TSA_completedStatus-container"}>
      <div className={"TSA_completedStatus-container-headerContainer"}>
        <IconSvg type={allProducts[productId]?.status}/>
        <GText text={'Please select your appointment below:'} size={SIZE.HEADING_LG} color={COLORS.DARK_BLUE_600} />
        <GText text={apointmentDate} size={SIZE.HEADING_MD} color={COLORS.ROYAL_BLUE_600} />
      </div>

      <div className={"TSA_completedStatus-container-statusBody"}>
        <div className={"TSA_completedStatus-container-statusBody-header"}>
          <div className={"TSA_completedStatus-container-statusBody-header-appointmentTime"}>
          </div>
          <div className={"TSA_completedStatus-container-statusBody-header-appointmentLocation"}>
            <GText text={'Please select a time'} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.SLATE_GRAY_500} />
            <div className={"TSA_completedStatus-container-statusBody-header-appointmentLocation-selectTime"}>
              {selectTimes.map((item, id) => {
                return (
                  <div
                    key={id}
                    className={"TSA_completedStatus-container-statusBody-header-appointmentLocation-selectTime-buttonsContainer"}
                  >
                    <GButton
                        text={item.time}
                        disabled={allProductsLoader}
                        loading={allProductsLoader}
                        click={() => {
                                   handleSendAppointment(item.value)
                              }}
                        showIcon={false}
                        textSize={SIZE.PARAGRAPH_REGULAR_16}
                    />
                  </div>)
              })
              }
            </div>
            <div className={"TSA_completedStatus-container-statusBody-header-appointmentLocation-description"}>
              <p>if you’re not available at for any of those appointments, please <a
                href={`${env.REACT_APP_GOV_HOME_PAGE_URL}/contact-us`} className={"assistant-button"}>
                   contact us </a>so we send you new ones</p>
            </div>
          </div>
          <div className={"TSA_completedStatus-container-statusBody-header-addressInfo"}>
            <GText text={'Appointment Location'} size={SIZE.HEADING_XXS} color={COLORS.DARK_BLUE_600} />
            <GText text={address1} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.DARK_BLUE_600} />
            <GText text={address2} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.DARK_BLUE_600} />
            <GText text={`${city} ${state}, ${zipcode}`} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.DARK_BLUE_600} />
          </div>
        </div>

        <div className={"TSA_completedStatus-container-statusBody-productInfo"}>
          <div className={"TSA_completedStatus-container-statusBody-productInfo-googleMap"}>
            <GoogleMapComponent
              message={""}
              lat={letLang.lat}
              lng={letLang.lng}
              defaultCenter={{
                lat: letLang.lat,
                lng: letLang.lng
              }}
            />
          </div>
        </div>
      </div>
      <div className={"TSA_completedStatus-container_backButton"}>
        <p onClick={() => {
          history.push("/products")
        }} className="checkStatusContainer_mainBlock-backButton"><IconSvg
          type={"back_arrow_left"}/> Back
        </p>
      </div>
    </div>
  );
};

export default AppointmentSelection;
