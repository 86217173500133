type ColorOptions = {
  [key: string]: string,
}

export const COLORS_NEW: ColorOptions = {

  SLAYE_100: "#F7F9FA",
  SLATE_200: "#EDF2F5",

  ROYAL_BLUE_100: "#F0F6FF",
  ROYAL_BLUE_200: "#E2EEFF",
  ROYAL_BLUE_300: "#C4DBFF",
  ROYAL_BLUE_400: "#88B7FF",
  ROYAL_BLUE_500: "#5E9EFF",
  ROYAL_BLUE_600: "#3585FF",
  ROYAL_BLUE_700: "#216DE3",
  ROYAL_BLUE_900: "#0A48A8",
  ROYAL_BLUE_1000: "#003787",

  RED_100: "#FFF4F5",
  RED_200: "#FEEAEB",
  RED_300: "#FFD3D4",
  RED_400: "#FEA9AC",
  RED_500: "#FF7381",
  RED_600: "#F54254",
  RED_700: "#E52C3F",
  RED_800: "#D1172B",
  RED_900: "#B90719",
  RED_1000: "#9C0000",

  GREEN_100: "#EAFFEC",
  GREEN_200: "#D5FADA",
  GREEN_300: "#B4EDC1",
  GREEN_400: "#8FDB9F",
  GREEN_500: "#66CF8A",
  GREEN_600: "#13BA68",
  GREEN_700: "#00A660",
  GREEN_800: "#03874F",
  GREEN_900: "#00663B",
  GREEN_1000: "#005431",

  YELLOW_100: "#FFF9EB",
  YELLOW_200: "#FFF3C3",
  YELLOW_300: "#FEDF88",
  YELLOW_400: "#FFC65B",
  YELLOW_500: "#FAB533",
  YELLOW_600: "#FAA300",
  YELLOW_700: "#ED872B",
  YELLOW_800: "#DB7327",
  YELLOW_900: "#C96216",
  YELLOW_1000: "#B84E00",

  BLACK_100: "#EBEBEB",
  BLACK_200: "#CACACA",
  BLACK_300: "#B2B2B2",
  BLACK_400: "#9B9B9B",
  BLACK_500: "#848484",
  BLACK_600: "#6E6E6E",
  BLACK_700: "#525252",
  BLACK_800: "#3E3E3E",
  BLACK_900: "#2B2B2B",
  BLACK_1000: "#191919",

  Background: "#F7F9FA",
  ORANGE: "#FF6A2A"
}

export const COLORS: ColorOptions = {
  //Royal blue
  /*Royal blue s our action color, used for primary buttons, links, etc.*/
  ROYAL_BLUE_25: "#F0F6FF",
  ROYAL_BLUE_50: "#E2EEFF",
  ROYAL_BLUE_100: "#C4DBFF",
  ROYAL_BLUE_200: "#88B7FF",
  ROYAL_BLUE_300: "#5E9EFF",
  ROYAL_BLUE_400: "#3485FF",
  ROYAL_BLUE_500: "#206DE3",
  ROYAL_BLUE_600: "#185EC8",
  ROYAL_BLUE_700: "#1650A8",
  ROYAL_BLUE_700_TAX: "#216DE3",
  ROYAL_BLUE_800: "#0F418C",

  //Dark blue
  /*Darkblue is the brand color. The main color is Darkblue 600.*/
  DARK_BLUE_25: "#EEF4FB",
  DARK_BLUE_50: "#E1E7F2",
  DARK_BLUE_100: "#CDD3E3",
  DARK_BLUE_200: "#ABB3CB",
  DARK_BLUE_300: "#7F8DB0",
  DARK_BLUE_400: "#596E99",
  DARK_BLUE_500: "#2B4E82",
  DARK_BLUE_600: "#113469",
  DARK_BLUE_700: "#0A2B5C",
  DARK_BLUE_800: "#001A41",

  //Slate gray
  /*Slate is our neutral color and is the foundation of the color system. Almost everything in the UI design — text, form fields, backgrounds, dividers — are usually gray.*/
  SLATE_GRAY_25: "#F1F3F4",
  SLATE_GRAY_50: "#E3E7EB",
  SLATE_GRAY_100: "#C9CFD9",
  SLATE_GRAY_200: "#AAB3C2",
  SLATE_GRAY_300: "#919DB0",
  SLATE_GRAY_400: "#74849B",
  SLATE_GRAY_500: "#627086",
  SLATE_GRAY_600: "#515D72",
  SLATE_GRAY_700: "#3B485A",
  SLATE_GRAY_800: "#2C3747",

  //Green
  GREEN_25: "#EAFFEC",
  GREEN_50: "#D3F8D7",
  GREEN_100: "#C3E8C6",
  GREEN_200: "#A6E1B3",
  GREEN_300: "#6AD48E",
  GREEN_400: "#10BF6B",
  GREEN_500: "#00A15D",
  GREEN_600: "#01874F",
  GREEN_700: "#00663B",
  GREEN_700_TAX: "#00A660",
  GREEN_800: "#004025",
  GREEN_600_NEW: "#13BA68",

  //Yellow
  YELLOW_25: "#FFF9EB",
  YELLOW_50: "#FFF3C4",
  YELLOW_100: "#FEDF88",
  YELLOW_200: "#FEC65A",
  YELLOW_300: "#FAB534",
  YELLOW_400: "#F09C2E",
  YELLOW_500: "#D17B2E",
  YELLOW_600: "#A65221",
  YELLOW_700: "#78432B",
  YELLOW_800: "#472417",

  //Red
  RED_25: "#FFF4F5",
  RED_50: "#FFEAEB",
  RED_100: "#FFD3D4",
  RED_200: "#FEA9AD",
  RED_300: "#FF7886",
  RED_400: "#F75061",
  RED_500: "#E93A4C",
  RED_600: "#D11F32",
  RED_700: "#BA0C1F",
  RED_800: "#8C000F",

  //Sky blue
  /*Sky blue is our old brand color. The main color is Skyblue 500.*/
  SKY_BLUE_25: "#F5FBFF",
  SKY_BLUE_50: "#EBF9FF",
  SKY_BLUE_100: "#DCF1FC",
  SKY_BLUE_200: "#C8EAFA",
  SKY_BLUE_300: "#9EDCF7",
  SKY_BLUE_400: "#51BFEF",
  SKY_BLUE_500: "#159EDA",
  SKY_BLUE_600: "#0A91CC",
  SKY_BLUE_700: "#007BB0",
  SKY_BLUE_800: "#006CA9",

  //ColorPicker Variables
  WHITE: "#fff",
  BLACK: "#000",

  BLACK_200: "#CACACA",
  BLACK_500: "#848484"
}
