import { FC, useCallback, useEffect, useState } from "react"
import { BasicBlockContainerProps } from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { useSelector } from "react-redux"
import { RootState } from "../../App"
import {
  renderBasicBlockContainer,
  userSelector,
} from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import LayoutBaseContainer from "../../Components/FormComponents/UploadPhoto/Layouts/LayoutBaseContainer/LayoutBaseContainer"
import { Dispatch } from "../../Components/FormComponents/FieldsComponents/SelectComponent/useDefaultOption"

interface animationPercentsInterface {
  percentsBase: number;
  percentsCurrent: number;
  timerId?: number | any;
  setBasePercents: Dispatch<number>;
}

const DynamicPercentsLayout: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text, state, type },
  arrayComponents,
}) => {
  const percents = useSelector<RootState, { percents: { [key: string]: number } }>(userSelector).percents ?? ""
  const [basePercents, setBasePercents]: [number, Dispatch<number>] = useState(0)

  const getAnimationPercents = useCallback(
    ({ percentsBase, percentsCurrent, ...props }: animationPercentsInterface) => {
      const timerId: any = setTimeout(() => {
        if (percentsBase >= percentsCurrent) {
          setBasePercents(percentsCurrent)
          clearInterval(timerId)
          return
        } else {
          setBasePercents(percentsBase + 1)
          getAnimationPercents({ ...props, percentsBase: percentsBase + 1, percentsCurrent })
        }
      }, 70/(percentsCurrent-percentsBase))
    },
    []
  )

  useEffect(() => {
    getAnimationPercents({ percentsBase: basePercents, percentsCurrent: percents?.oneProduct, setBasePercents })
  }, [getAnimationPercents, percents?.oneProduct])

  return renderBasicBlockContainer(
    { className, text: `${basePercents}%`, childrenItem, arrayComponents, state },
    LayoutBaseContainer
  )
}

export default DynamicPercentsLayout
