import {ITemplateOneBuilder} from "./ITemplateOneBuilder";
import TemplateOneBuilder from "../TemplateOneBuilder";
import {IUpsellProcessingData} from "../../../StepUpsell";
import TemplateOne from "../TemplateOne";
import insuranceCerImage from "../../../../../../../../assets/image/upsells/insure_cer.webp";
import insurancePasImage from "../../../../../../../../assets/image/upsells/insure_pas.webp";
import insuranceSsnImage from "../../../../../../../../assets/image/upsells/insure_ssn.png";
import insuranceEinImage from "../../../../../../../../assets/image/upsells/insure_ein.png";
import {SolutionNames} from "../../../../../../../../util/SolutionNames";
// @ts-ignore
import {PaymentModelTypes} from "../../../../../../../../../../dashboard-govplus-front/src/constants/PaymentModelTypes";


export class DocumentInsuranceUpgrade implements ITemplateOneBuilder{
    upgradeInsuranceBenefits: string[] = [
        'Expedited application processing',
        'Automatic document renewals',
        'Expert application review to prevent mistakes and delays',
        'Applications for your family members all in one place'
    ];
    upgradeInsuranceBenefitsHybrid: string[] = [
        "<span class='bold'>Unlimited access</span> to all your prefilled government applications",
        "<span class='bold'>Automatic renewal</span> of your documents before they expire",
    ];

    buildTemplate(builder: TemplateOneBuilder, data: IUpsellProcessingData, solutionName: string, paymentModel: PaymentModelTypes): TemplateOne {
        let pageBuilder = builder
            .setImageHeader(this.getInsuranceImage(solutionName))
            .setCheckListItems(
                paymentModel === PaymentModelTypes.SUBSCRIPTION_FEE || paymentModel === PaymentModelTypes.COMBINED_FEE ?
                    this.upgradeInsuranceBenefits :
                    this.upgradeInsuranceBenefitsHybrid
            )
            .setPrice(`${data.price}`)
            .setTagInformation('Cancel anytime')
            .setImageHeaderSubtitle("With GOV+ Subscription, you also get:")
       return pageBuilder.build();
    }

    /**
     * Get the image for the insurance upgrade, based on the solution name
     *
     * @param solutionName: string
     * @return string | undefined
     */

    private getInsuranceImage(solutionName: string): string | undefined {
        switch (solutionName) {
            case SolutionNames.PAS:
                return insurancePasImage;
            case SolutionNames.SSN:
                return insuranceSsnImage;
            case SolutionNames.CER:
                return insuranceCerImage;
            case SolutionNames.EIN:
                return insuranceEinImage;
            default:
                return undefined;
        }
    }
}