import IErrorExternalAPIs from "./IErrorExternalAPIs";

/**
 * @class SentryIO
 */
export default class DefaultApi implements IErrorExternalAPIs {

    constructor() {}

    init(): void {}

    /**
     * Notify an error to the error log
     * @param error
     * @returns {string}
     * @override IErrorExternalAPIs.notifyError
     */
    public captureError(error: Error): string {
        return "";
    }

    /**
     * Notify a message to the error log
     * @param message
     * @returns {string}
     * @override IErrorExternalAPIs.notifyMessage
     */
    public captureMessage(message: string): string {
        return "";
    }
}