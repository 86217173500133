import {Input, Radio, Form} from "antd";

export const helper = {
  typeSwitcher: (type, label, items, defaultValue, name) => {
    switch (type) {
      case "radio": {
        return <Form.Item label={label} name={name}>
          <Radio.Group defaultValue={defaultValue}>
            {items.map((item, id) => {
              return <div>
                <div>
                  <Radio value={item.value}>{item.textInButtons}</Radio>
                </div>
                {item.value === "Y" && <p>I am here</p>}
              </div>
            })}
          </Radio.Group>
        </Form.Item>
      }
    }
    switch (type) {
      case "input": {
        return <div>
          <label>{label}</label>
          <Input/>
        </div>
      }
    }
  },
}
