export enum ServicesIds {
    // Birth certificate
    FirstBirthCertificate = "vital-records-new",
    BirthCertificateCertifiedCopy = "vital-records-change",

    // Social security card
    FirstSocialSecurityCard = "social-security-card-new",
    SocialSecurityCardUpdates = "social-security-card-other",
    SocialSecurityCardNameChange = "social-security-card-change",
    SocialSecurityCardReplacement = "social-security-card-replace",

    // EIN
    NewEin = "ein-new",
    IRSEinConfirmationLetter = "ein-verificationletter",
    EinRetrieval = "ein-retrievelost",
    EinUpdates = "ein-change",

    // Address
    BusinessAddressChange = "address-change-business",
    IndividualAddressChange = "address-change-individual",

    // TSA precheck
    NewTSAPreCheck = "precheck-new",
    TSAprecheckRenewal = "precheck-renewal",


    // Passport
    PassportRenewal = "passport-renewal",
    PassportLost = "passport-lost",
    PassportStolen = "passport-stolen",
    PassportDamage = "passport-damaged",
    PassportNew = "passport-new",
    PassportRenewalPriorityProcessing = "passport-renewal-priority-processing",
}