import {IHeader} from "../Header";
import {FC} from "react";
import React from "react";
import "./GHeaderCombinedPayment.scss"
import GImageTitle from "../../../molecules/ImageTitle/GImageTitle";
import {getPropertiesForEvents} from "../../../../../services/gtm/backEndEvent";
import {SIZE} from "../../../types/types";
import {COLORS_NEW} from "../../../types/Colors";
import GProgressBar from "../../../molecules/ProgressBar/ProgressBar";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";


type GHeaderCombinedPaymentProps = {
    props: IHeader,
}

const GHeaderCombinedPayment: FC<GHeaderCombinedPaymentProps> = (content) => {
    const breakPoint = useBreakpoint();
    const headerContent: IHeader = content.props;
    const {form_id: formId} = getPropertiesForEvents()
    const webFlowServicesData = JSON.parse(localStorage.getItem("webFlowServicesData") ?? "{}") ?? {}
    let linkPhotoProcessingSteps = ""
    const {formType} = getPropertiesForEvents()

    linkPhotoProcessingSteps = formId
      ? webFlowServicesData[formId]?.illustration_l
      : webFlowServicesData[formType.toUpperCase()]?.illustration_l
   
    let image: React.CSSProperties = {
        // maxHeight: breakPoint.md ? solution === SolutionNames.PAS || solution === "PAS" ? "190px" : "200px" : "160px",
        maxHeight: breakPoint.xs ? "164px" : "230px",
        width: breakPoint.xs ? "117.35px" : "auto",
        maxWidth: 304
    }

    return (
      <div className="GHeaderCombinedPayment">
        <GProgressBar value={90} customText={"Ready to file"} showIcon={false} color={COLORS_NEW.GREEN_600} showIconRight />
        <GImageTitle
            title={headerContent.title}
            imageStyle={image}
            image={linkPhotoProcessingSteps}
            titleSize={SIZE.HEADING_LG}
        />
      </div>
    )
}

export default GHeaderCombinedPayment