import { setCurrentStep, setIndexCurrentStep } from "../../Redux/store/step/step.actions"

const defineIndexCurrentStep = (
  indexCurrentStep: string | number,
  arraySteps: [] | any,
  history: History | any,
  dispatch: any
) => {
  const params = history.location.search.split("&indexCurrentStep")[0]

  const dispatchFunc = (indexCurrentStep: string | number) => {
    dispatch(setCurrentStep(indexCurrentStep !== null ? +indexCurrentStep : 0))
    dispatch(setIndexCurrentStep(indexCurrentStep !== null ? +indexCurrentStep : 0))
  }

  // have indexCurrentStep in get params
  if (new URLSearchParams(window.location.search).get("indexCurrentStep") && arraySteps) {
    const urlIndexCurrentStep = new URLSearchParams(window.location.search).get("indexCurrentStep") ?? 0
    const urlStorage = +JSON.parse(sessionStorage.getItem("currentStep") || "{}")

    if (urlIndexCurrentStep > indexCurrentStep && urlIndexCurrentStep > urlStorage) {
      const url = new URL(window.location.href).toString()
      let newUrl = url.replace(`indexCurrentStep=${urlIndexCurrentStep}`, `indexCurrentStep=${indexCurrentStep}`)
      window.history.pushState(null, "", newUrl.toString())
      return
    }

    if (urlIndexCurrentStep < indexCurrentStep) {
      dispatch(setIndexCurrentStep(Number(urlIndexCurrentStep)))
    }

    if (urlIndexCurrentStep !== null && urlIndexCurrentStep < arraySteps.length) {
      dispatchFunc(urlIndexCurrentStep)
    }
    // if (
    //   indexCurrentStep !== null &&
    //   arraySteps.length &&
    //   indexCurrentStep >= arraySteps.length &&
    //   +indexCurrentStep !== 0
    // ) {
    //   history.push(`${params}&indexCurrentStep=${0}`)
    // }
  }

  // don't have indexCurrentStep in get params but have in localStorage
  if (!new URLSearchParams(window.location.search).get("indexCurrentStep") && sessionStorage.getItem("currentStep")) {
    let indexCurrentStep = sessionStorage.getItem("currentStep") || 0
    // for case when use change link manually and index bigger than amount steps
    if (indexCurrentStep >= (arraySteps && arraySteps.length)) {
      indexCurrentStep = 0
    }
    if (+indexCurrentStep) {
      history.push(`${params}&indexCurrentStep=${indexCurrentStep}`)
    }
    dispatchFunc(indexCurrentStep)
  }

  // don't have indexCurrentStep in get params and localStorage
  // if (!new URLSearchParams(window.location.search).get("indexCurrentStep") && !localStorage.getItem("currentStep")) {
  // history.push(`${params}&indexCurrentStep=${indexCurrentStep || 0}`)
  // }
}

export default defineIndexCurrentStep
