export const GCancelSubscriptionMockStaticTexts = {
    DEFAULT_CANCEL_SUBSCRIPTION_TITLE: "We'll miss you, {firstname}!",
    DEFAULT_CANCEL_SUBSCRIPTION_SUBTITLE: "What would your prefer to do?",
    DEFAULT_PAUSE_SUBSCRIPTION_CARD: {
        title: "Put my subscription on hold",
        description: "We’ll keep your account data for 6 months, free of charge. Reopen your account at any time during that window by logging again to GOV+.",
        ctaButtonText: "Pause subscription",
    },
    DEFAULT_CANCEL_SUBSCRIPTION_CARD: {
        title: "Cancel my subscription",
        description: "For security purposes, your account data will be permanently deleted now. There are no recovery options. If you want to file with GOV+ again, you’ll have to re-enter your information from scratch.",
        ctaButtonText: "Cancel subscription",
    },
}