import { interceptorFunc } from "./requests/uploadServise"
import { getPropertiesForEvents } from "./gtm/backEndEvent"
import { apiAxiosInstance } from "./form-service_instace"
import {UrlQueryParams} from "../util/UrlQueryParams";
import UserIpRepository from "../persistence/session/UserIpRepository";
import { env } from "../env";

const axios = require("axios")

const host = env.REACT_APP_HOST ?? "https://api.govplus.dev"

interface objectSubmitInterface {
  client_token: string;
  form_id: string;
}

export default class Service {
  static interceptor = interceptorFunc()

  static getForm = async (props: objectSubmitInterface) => {
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/getForm", props)
    return response.data
  }

  static checkStatusForm = async (submitObj: {} | any) => {
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/checkStatus", submitObj)
    return response.data
  }

  static updateAddress = async (address: {}) => {
    const requestBody = this.checkApplicationIdInRequest(address);
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/updateAddress", requestBody)
    return response.data
  }

  static submitAdditionalForm = async (submit: {}) => {
    const { applicationId: application_id, form_id, analyticsUserId, analyticsAnonymousId } = getPropertiesForEvents()
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/submitAdditionalForm", {
      ...submit,
      application_id,
      form_id,
      userId: analyticsUserId,
      anonymousId: analyticsAnonymousId,
      userIp: UserIpRepository.getInstance().getValue()
    })
    return response.data
  }
  static submitAdditionalFormCoa = async (submit: {}) => {
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/submitTest", { ...submit })
    return response.data
  }

  static submitAdditionalFormCoaFinal = async (submit: {}) => {
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/submitAdditionalCoa", {
      ...submit,
    })
    return response.data
  }

  static submitGeneratePack = async (submit: {}) => {
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/generate", { ...submit })
    return response.data
  }

  static cacheForm = async (store: {}) => {
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/cacheForm", { ...store })
    return response.data
  }

  static submitForm = async (store: {}) => {
    const { analyticsUserId, analyticsAnonymousId } = getPropertiesForEvents()
    store = { ...store, userId: analyticsUserId, anonymousId: analyticsAnonymousId, userIp: UserIpRepository.getInstance().getValue() };
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/submitForm", store)
    return response.data
  }

  static submitPayment = async (store: {}) => {
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/submitPayment", store)
    return response.data
  }

  private static checkApplicationIdInRequest(store: any){
    if(store.application_id && store.application_id !== '' ){
      return store
    }
    const { applicationId: applicationIdStore, analyticsUserId, analyticsAnonymousId } = getPropertiesForEvents()
    const queryParams = new URLSearchParams(window.location.search);
    const applicationIdUrl = queryParams.get(UrlQueryParams.ApplicationId);

    return {...store, application_id: applicationIdUrl ?? applicationIdStore, userId: analyticsUserId, anonymousId: analyticsAnonymousId, userIp: UserIpRepository.getInstance().getValue()}
  }

  static confirmSubscription = async (store: {}) => {
    const requestBody = this.checkApplicationIdInRequest(store)
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/confirmSubscription", requestBody)
    return response.data
  }

  static payApplicationFee = async (store: {}) => {
    const requestBody = this.checkApplicationIdInRequest(store)
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/payApplicationFee", requestBody)
    return response.data
  }

  static payGovernmentFees = async (store: {}) => {
    const requestBody = this.checkApplicationIdInRequest(store)
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/payGovernmentFees", requestBody)
    return response.data
  }

  static payGovernmentFeesFromScrap = async (store: {}) => {
    const requestBody = this.checkApplicationIdInRequest(store)
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/payGovernmentFeesFromScrap", requestBody)
    return response.data
  }

  static sendEmail = async (obj: {} | any) => {
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/sendPackageEmail", obj)
    return response.data
  }

  static sendMessage = async (obj: {} | any) => {
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/sendMessage", obj)
    return response.data
  }

  static validateApp = async (obj: {} | any) => {
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/validateApp", obj)
    return response.data
  }

  static getAutocomplete = async (obj: {} | any) => {
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/getCityState", obj)
    return response.data
  }

  static checkAddress = async (obj: {} | any) => {
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/checkAddress", obj)
    return response.data
  }

  static getUtmValues = async (obj: {} | any) => {
    const response = await apiAxiosInstance.utmBackInstance("post", "/api_gov/utm/getUtmValues", obj)
    return response.data
  }

  static updateDocument = async (obj: {} | any) => {
    const { applicationId: application_id, analyticsUserId, analyticsAnonymousId } = getPropertiesForEvents()
    obj = { ...obj, application_id }
    const formData = new FormData()
    const arrayFields: any = [
      "client_token",
      "form_id",
      "file",
      "name_field",
      "name_document",
      "file_name",
      "isFinalSubmit",
      "typeDocument",
      "application_id",
    ]
    arrayFields.forEach((field: string) => formData.append(field, obj[field]))
    formData.append("userId", analyticsUserId);
    formData.append("anonymousId", analyticsAnonymousId);
    formData.append("userIp", UserIpRepository.getInstance().getValue());

    const response = await axios.post(`${host}/api_gov/form/uploadDocuments`, formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    return response.data
  }

  static clearTempDocument = async (obj: {} | any) => {
    const newObj = { ...obj }
    const formData = new FormData()
    const arrayFields = ["authUserBearerToken", "form_id", "typeDocument", "name_field"]
    arrayFields.forEach((field: string) => formData.append(field, newObj[field]))
    const response = await axios.post(`${host}/api_gov/form/clearTempDocuments`, formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return response.data
  }

  static acceptUpsellPage = async (obj: any)=>{
      let requestBody = this.checkApplicationIdInRequest(obj)
      const { analyticsUserId, analyticsAnonymousId } = getPropertiesForEvents()

      requestBody = {
        ...requestBody,
        userId: analyticsUserId,
        anonymousId: analyticsAnonymousId,
        userIp: UserIpRepository.getInstance().getValue()
      }

      const response = await axios.post(`${host}/api_gov/offer/acceptCta`, requestBody)
      return response.data
  }
  static cancelUpsellPage = async (obj: any)=> {
      let requestBody = this.checkApplicationIdInRequest(obj)
      const { analyticsUserId, analyticsAnonymousId } = getPropertiesForEvents()

      requestBody = {
        ...requestBody,
        userId: analyticsUserId,
        anonymousId: analyticsAnonymousId,
        userIp: UserIpRepository.getInstance().getValue()
      }

      const response = await axios.post(`${host}/api_gov/offer/cancelCta`, requestBody)
      return response.data
  }

  static nextUpsellPage = async (obj: {} | any) => {
    const formData = new FormData()
    const arrayFields: any = [
      "client_token",
      "form_id",
      "upsell_funnel_id",
      "upsell_page_id",
      "action",
      "iterator",
      "handler",
      "uniq_session",
      "serviceName",
    ]
    arrayFields.forEach((field: string) => formData.append(field, obj[field]))
    const { analyticsUserId, analyticsAnonymousId } = getPropertiesForEvents()
    formData.append("userId", analyticsUserId);
    formData.append("anonymousId", analyticsAnonymousId);
    formData.append("userIp", UserIpRepository.getInstance().getValue());

    const response = await axios.post(`${host}/api_gov/upsell/nextUpsellPage`, formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return response.data
  }

  static nextUpsellProcessingPage = async (obj: {} | any) => {
    const requestBody = this.checkApplicationIdInRequest(obj)
    const formData = new FormData()
    const arrayFields: any = [
      "client_token",
      "form_id",
      "upsell_funnel_id",
      "upsell_page_id",
      "action",
      "iterator",
      "handler",
      "uniq_session",
      "serviceName",
      "application_id",
      "payment_method",
      "expiration_date",
      "card_no",
      "ssc",
      "zip_pay"
    ]
    arrayFields.forEach((field: string)=>{
      const fieldValue = requestBody[field]
      if(fieldValue){
        formData.append(field, fieldValue)
      }
    })
    const { analyticsUserId, analyticsAnonymousId } = getPropertiesForEvents()
    formData.append("userId", analyticsUserId);
    formData.append("anonymousId", analyticsAnonymousId);
    formData.append("userIp", UserIpRepository.getInstance().getValue());

    const response = await axios.post(`${host}/api_gov/upsell/nextUpsellProcessingPage`, formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return response.data
  }

  static postCurrentStoreInInterval = async (store: {}) => {
    const response = await axios.post(`http://192.168.1.169.admin.docker.bluebird.loc/api/form/cacheForm`, store)
    return response.data
  }

  static getSubmitObjForTax(obj: any) {
    const {analyticsUserId, analyticsAnonymousId} = getPropertiesForEvents()
    return {
      ...obj,
      userId: analyticsUserId,
      anonymousId: analyticsAnonymousId,
      userIp: UserIpRepository.getInstance().getValue()
    }
  }

  static getColumnTaxInformation = async (obj: {} | any) => {
    obj = Service.getSubmitObjForTax(obj);
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/form/getColumnTaxInformation", obj)
    return response.data
  }

  static paymentGatewayApis = async (submitObj: {} | any) => {
    const response = await apiAxiosInstance.formsBackInstance("post", "/api_gov/paymentgateway/authorizationToken", submitObj)
    return response.data
  }
}
