import React from "react"

interface ISvgInfo {
    width: number;
    height: number;
    color: string;
}

export const SvgInfo = ({width, height, color = "#ED872B"}: ISvgInfo) => {
    const viewBox = `0 0 ${width} ${height}`
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.50016 14.6666C12.1821 14.6666 15.1668 11.6818 15.1668 7.99992C15.1668 4.31802 12.1821 1.33325 8.50016 1.33325C4.81826 1.33325 1.8335 4.31802 1.8335 7.99992C1.8335 11.6818 4.81826 14.6666 8.50016 14.6666Z" stroke={color} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.5 10.6667V8" stroke={color} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.5 5.33325H8.50667" stroke={color} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
