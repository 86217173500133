import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {showGrowlMessage} from "../../../appRedux/actions";
import axios from "axios";
import {retargetingLinkNavigator} from "./retargetingLinkNavigator";
import {userService} from "../../../api/api";
import UserLogin from "../../../util/UserLogin";

export const useNavigationHandling = (retargetingToken) => {
  const {authToken, alertMessage, outerId} = useSelector(({auth}) => auth);
  const {retargetingFormId, retargetingAppId} = useSelector(({notes}) => notes);
  const {anotherGovProducts} = useSelector(({allProducts}) => allProducts);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUserLogout, setIsUserLogout] = useState("")

  useEffect(async () => {
    const {data} = await userService.getDataFromRetargetingLink(retargetingToken);

    /**
     * If user has the token in auth store (It's logged in) and the token from the retargeting link is different
     * from this token, then the user will be logged out
     * else
     * the user will be redirected to the retargeting link
     */
    if(outerId && data.data.outer_id !== outerId) {
      await logout();
    }else{
      retargetingLinkNavigator.checkRetargetingToken(retargetingToken, dispatch, history, anotherGovProducts.length)
    }

  }, [isUserLogout]);

  const logout = async () => {
    await UserLogin.logout(dispatch, history);
    dispatch(showGrowlMessage({
      messageInfo: "You’ve been logged out for security reasons, please login again.",
      growlStatus: false,
      code: 403,
    }))

  }

  useEffect(() => {
    if (authToken && retargetingFormId) {
      const redirectUserFromRetargetLink = setTimeout(() => {
        history.push(retargetingAppId
          ? `/forms/?formId=${retargetingFormId}&applicationId=${retargetingAppId}`
          : `/forms/?formId=${retargetingFormId}`
        )
      }, 1500)

      return () => clearTimeout(redirectUserFromRetargetLink)
    }

    if (alertMessage === "common.USER_NOT_FOUND") {
      history.push("/signin");
    }

    if (alertMessage === "common.SUCCESSFUL_REQUEST" && (retargetingFormId || retargetingAppId)) {
      history.push("/signin");
    }
  }, [authToken, alertMessage, retargetingFormId, retargetingAppId, anotherGovProducts]);
};
