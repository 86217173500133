import React, { FC } from "react"
import { Drawer, Menu } from "antd"
import { useLocation } from "react-router-dom"
import { getItems } from "../../templates/Sidebar/Sidebar"
import "./GDrawer.scss"

type GDrawerProps = {
  navCollapsed: boolean,
  screenWidth: number,
  closeDrawer: () => void
}
const GDrawer: FC<GDrawerProps> = ({ navCollapsed, screenWidth, closeDrawer}) => {
  const location = useLocation()

  return (
    <>
      {screenWidth < 992 && (
        <Drawer className={`GDrawer`} placement="top" closable={false} open={navCollapsed} onClose={closeDrawer} >
          <Menu className={`GDrawer__Menu`} mode="inline" items={getItems(location, closeDrawer)} />
        </Drawer>
      )}
    </>
  )
}

export default GDrawer
