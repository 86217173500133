import React from "react";
import ReactPlayer from 'react-player'
import "./VideoPlayer.scss"

export type GVideoPlayerProps = {
  width?: number | string,
  height?: number | string,
  url: string,
  muted?: boolean
  loop?: boolean,
  playing?: boolean,
  controls?: boolean,
  onReady?: () => void,
  style?: React.CSSProperties
}

const GVideoPlayer = ({
    width = "100%",
    height = "auto",
    url,
    muted = true,
    loop = false,
    playing = true,
    controls = true,
    onReady,
    style
  } : GVideoPlayerProps) => {
   
  return (
    <ReactPlayer 
      onReady={onReady}
      style={style} 
      width={width} 
      height={height} 
      muted={muted} 
      loop={loop} 
      playing={playing} 
      controls={controls} 
      url={url}  />
  )
}

export default GVideoPlayer