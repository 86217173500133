import React from "react"

export const SvgLogo = (width: number, height: number) => {
  return (
    <svg width={width} height={height} viewBox="0 0 84 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M82.6406 12.9756H77.523V18.2109H72.7583V12.9756H67.6406V8.47564H72.7583V3.21094H77.523V8.47564H82.6406V12.9756Z"
        fill="#216DE3"
      />
      <path
        d="M10.6736 21.2109C17.0661 21.2109 21.3471 16.901 21.0868 9.78532H10.4711V13.5456H15.9959C15.6198 15.3969 13.5372 16.9299 10.6736 16.9299C7.08678 16.9299 4.7438 14.2688 4.7438 10.7109C4.7438 7.23986 7.08678 4.60763 10.6736 4.60763C13.1901 4.60763 14.9835 5.90928 15.6488 7.64482H20.7107C19.7273 3.45061 15.9959 0.210938 10.6736 0.210938C4.36777 0.210938 0 4.75226 0 10.7109C0 16.6985 4.33884 21.2109 10.6736 21.2109Z"
        fill="#191919"
      />
      <path
        d="M34.1494 21.2109C40.3106 21.2109 44.9097 16.6407 44.9097 10.7109C44.9097 4.81011 40.3395 0.210938 34.1494 0.210938C27.9593 0.210938 23.3891 4.75226 23.3891 10.7109C23.3891 16.6985 27.9304 21.2109 34.1494 21.2109ZM34.1494 16.8142C30.6205 16.8142 28.1329 14.2109 28.1329 10.7109C28.1329 7.26879 30.6205 4.60763 34.1494 4.60763C37.6494 4.60763 40.137 7.26879 40.137 10.7109C40.137 14.2109 37.6494 16.8142 34.1494 16.8142Z"
        fill="#191919"
      />
      <path
        d="M53.4035 20.8349H58.1183L65.639 0.586971H60.6059L55.92 14.6448H55.6886L51.0316 0.586971H45.9407L53.4035 20.8349Z"
        fill="#191919"
      />
    </svg>
  )
}
