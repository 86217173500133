import IEvent from "../IEvent";
import BaseBuilder from "../BaseBuilder";
import DocumentRenewalCompleted from "./DocumentRenewalCompleted";

/**
 * Document Renewal Completed Builder
 * @class DocumentRenewalCompletedBuilder
 * @extends {BaseBuilder}
 * @implements {IBuilder}
 * @description Document Renewal Completed Builder
 */
export default class DocumentRenewalCompletedBuilder extends BaseBuilder {
    private _documentSolution: string | undefined;

    /**
     * Set solution
     * @param solution
     * @returns {IBuilder}
     * @description This method is used to set solution
     */
    setDocumentSolution(solution: string | undefined) {
        this._documentSolution = solution;
        return this;
    }
    
    /**
     * Set ip address
     * @param ipAddress
     * @returns {IBuilder}
     * @description This method is used to set ip address
     */
    setIpAddress(ipAddress: string | undefined) {
        this._ipAddress = ipAddress;
        return this;
    }

    /**
     * Build event
     * @returns {IEvent}
     * @description This method is used to build event
     */
    build(): IEvent {
        return new DocumentRenewalCompleted(this._documentSolution, this._ipAddress);
    }
}
