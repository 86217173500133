import {COLORS, COLORS_NEW} from "../../types/Colors"
import { SIZE } from "../../types/types"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"

export interface ISideVarItem {
  link: string;
  icon: string;
  text: string;
  key: string;
  active: string[];
  requiresAccount: boolean;
}

export interface ISideVarSettings {
  backgroundColor: string;
  width: number;
  iconColor: string;
  iconColorActive: string;
  iconWidth: number;
  iconHeight: number;
  textColor: string;
  textColorActive: string;
  textSize: string;
  itemColor: string;
  itemColorActive: string;
  itemsMenu: ISideVarItem[];
}

export const SIDE_VAR_THEME: ISideVarSettings = {
  backgroundColor: COLORS.WHITE,
  width: 282,
  iconColor: COLORS_NEW.BLACK_600,
  iconColorActive: COLORS_NEW.ROYAL_BLUE_700,
  iconWidth: 24,
  iconHeight: 24.5,
  textColor: COLORS_NEW.BLACK_600,
  textColorActive: COLORS_NEW.ROYAL_BLUE_700,
  textSize: SIZE.PARAGRAPH_BOLD_16,
  itemColor: COLORS.WHITE,
  itemColorActive: COLORS_NEW.ROYAL_BLUE_200,
  itemsMenu: [
    {
      link: "/",
      icon: ICONS_SVG_TYPE.DASHBOARD,
      text: "Dashboard",
      key: "dashboard",
      active: ["/"],
      requiresAccount: false,
    },
    {
      link: "/formsApp",
      icon: ICONS_SVG_TYPE.NEW_APPLICATION,
      text: "Start new application",
      key: "goForm",
      active: ["/forms", "/formsApp"],
      requiresAccount: false
    },
    {
      link: "/products",
      icon: ICONS_SVG_TYPE.MY_APPLICATIONS,
      text: "My applications",
      key: "products",
      active: ["/products"],
      requiresAccount: false
    },
    {
      link: "/myInsuredDocuments",
      icon: ICONS_SVG_TYPE.PASSPORT,
      text: "My insured documents",
      key: "myInsuredDocuments",
      active: ["/myInsuredDocuments"],
      requiresAccount: false
    },
    {
      link: "/myAutomaticRenewals",
      icon: ICONS_SVG_TYPE.RENEWAL,
      text: "My automatic renewals",
      key: "myAutomaticRenewals",
      active: ["/myAutomaticRenewals"],
      requiresAccount: false
    },
    {
      link: "/myIdentity",
      icon: ICONS_SVG_TYPE.MY_IDENTITY,
      text: "My identity protection",
      key: "myIdentity",
      active: ["/myIdentity"],
      requiresAccount: false,
    },
  ],
}
