/* eslint-disable max-len */
/* eslint-disable indent */
import React from "react"
import { connect } from "react-redux"
import MapComponent from "./MapCompoment/MapComponent"

type PackageAdditionalNewComponentProps = {
  blocksStep: any,
  numberStep: number,
  packageResponse: {} | any,
}

// eslint-disable-next-line max-len
const PackageAdditionalNewComponent: React.FunctionComponent<PackageAdditionalNewComponentProps> = ({
  blocksStep,
  numberStep,
  packageResponse,
}) => {
  const { name } = blocksStep.content

  const headerMail = blocksStep && blocksStep.content.header && blocksStep.content.header.header

  const mainMail = blocksStep && blocksStep.content.main

  return (
    <>
      {name === "additionalMail-new" && (
        <>
          <div
            // eslint-disable-next-line max-len
            className={`icon-container_process icon-container_process-additional-mail icon-container_process-additional-mail-new`}
          >
            <p className={`icon-number`}>{numberStep}</p>
          </div>
          <div className={`div-bg steps-package__content-step`}>
            <h4 className={`content-step__header`}>{headerMail}</h4>
            <div className={`content-step__work-mode-container`}>
              <div className={`content-step__path-container`}>
                <div className={`content-step__address-container`}>
                  <h5 className={`content-step__address-header`}>{mainMail.address.header}</h5>
                  <p className={`content-step__address-text`}>
                    {(packageResponse && packageResponse.ssaOfficeAddress1) || mainMail.address.first}
                  </p>
                  <p className={`content-step__address-text`}>
                    {(packageResponse && packageResponse.ssaOfficeAddress2) || mainMail.address.second}
                  </p>
                  <p className={`content-step__address-text`}>
                    {(packageResponse && packageResponse.ssaOfficeAddress3) || mainMail.address.third}
                  </p>
                </div>
                <div className={`content-step__itinerary-container`}>
                  <h5 className={`content-step__itinerary-header`}>{mainMail.itinerary.header}</h5>
                  <p className={`content-step__itinerary-text`}>
                    {(packageResponse && packageResponse.itineraryDuration) || mainMail.itinerary.first}
                  </p>
                  <p className={`content-step__itinerary-text`}>
                    {(packageResponse && packageResponse.address) || mainMail.itinerary.second}
                  </p>
                  <p className={`content-step__itinerary-text`}>
                    {(packageResponse && packageResponse.city_state_zip) || mainMail.itinerary.third}
                  </p>
                </div>
              </div>
              <div className={`content-step__work-mode-container`}>
                <h5 className={`content-step__work-mode-header`}>{mainMail.hours.header}</h5>
                <div className={`content-step__day-container`}>
                  <p className={`content-step__work-mode-day`}>{mainMail.hours.monday.name}</p>
                  <p className={`content-step__work-mode-time`}>{mainMail.hours.monday.time}</p>
                </div>
                <div className={`content-step__day-container`}>
                  <p className={`content-step__work-mode-day`}>{mainMail.hours.tuesday.name}</p>
                  <p className={`content-step__work-mode-time`}>{mainMail.hours.tuesday.time}</p>
                </div>
                <div className={`content-step__day-container`}>
                  <p className={`content-step__work-mode-day`}>{mainMail.hours.wednesday.name}</p>
                  <p className={`content-step__work-mode-time`}>{mainMail.hours.wednesday.time}</p>
                </div>
                <div className={`content-step__day-container`}>
                  <p className={`content-step__work-mode-day`}>{mainMail.hours.thursday.name}</p>
                  <p className={`content-step__work-mode-time`}>{mainMail.hours.thursday.time}</p>
                </div>
                <div className={`content-step__day-container`}>
                  <p className={`content-step__work-mode-day`}>{mainMail.hours.friday.name}</p>
                  <p className={`content-step__work-mode-time`}>{mainMail.hours.friday.time}</p>
                </div>
                <div className={`content-step__day-container`}>
                  <p className={`content-step__work-mode-day`}>{mainMail.hours.saturday.name}</p>
                  <p className={`content-step__work-mode-time`}>{mainMail.hours.saturday.time}</p>
                </div>
                <div className={`content-step__day-container`}>
                  <p className={`content-step__work-mode-day`}>{mainMail.hours.sunday.name}</p>
                  <p className={`content-step__work-mode-time`}>{mainMail.hours.sunday.time}</p>
                </div>
              </div>
            </div>
            <div className={`content-step__map-container`}>
              <MapComponent
                mapCenter={(packageResponse && packageResponse.toAddress) || mainMail.map}
                googleMapsApiKey={(packageResponse && packageResponse.googleMapsAPIKey) || mainMail.apiKey}
              />
            </div>
            <div className={`content-step__info-container`}>
              <h5 className={`content-step__info-header`}>{mainMail.description.header}</h5>
              <p
                className={`content-step__info-text`}
                dangerouslySetInnerHTML={{ __html: mainMail.description.first }}
              ></p>
              <p className={`content-step__info-text`}>{mainMail.description.second}</p>
              <p className={`content-step__info-text`}>{mainMail.description.third}</p>
              <p className={`content-step__info-text`}>{mainMail.description.fourth}</p>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(PackageAdditionalNewComponent)
