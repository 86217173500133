import React, { FC } from "react"
import "./BankRecord.scss"
import { RecordContentProps } from "../../../../../util/IdentityProtectionHelper"
import GText from "../../../atoms/Text/Text"
import { COLORS_NEW } from "../../../types/Colors"
import { SIZE } from "../../../types/types"

const BankRecord: FC<RecordContentProps> = ({ ValueToMonitor, RNumber }) => {
  return (
    <div className={"BankRecord"}>
      <GText text={RNumber as string} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} />
      <GText text={`Routing number: ${ValueToMonitor}`} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
    </div>
  )
}

export default BankRecord
