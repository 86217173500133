import React from "react"

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
  fillCircle?: boolean;
}

export const SvgExpediteDocument = ({
  width = 65,
  height = 65,
  color = "#185EC8",
  backgroundColor = "#EEF4FB",
  fillCircle = true,
}: ISvgCheck) => {
  return (
    <svg width={width} height={height} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="30" height="30" rx="15" fill={backgroundColor} />
      <path
        d="M18.2491 9.66406H13.9155C13.6281 9.66406 13.3525 9.77821 13.1494 9.98139C12.9462 10.1846 12.832 10.4602 12.832 10.7475V19.415C12.832 19.7024 12.9462 19.9779 13.1494 20.1811C13.3525 20.3843 13.6281 20.4984 13.9155 20.4984H20.416C20.7033 20.4984 20.9789 20.3843 21.1821 20.1811C21.3853 19.9779 21.4994 19.7024 21.4994 19.415V12.9144L18.2491 9.66406Z"
        stroke={color}
        strokeWidth="1.33366"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.25 9.66406V12.9144H21.5003"
        stroke={color}
        strokeWidth="1.33366"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.83436 15L12.168 15"
        stroke={color}
        strokeWidth="1.33366"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1678 11.6719L12.168 11.6719"
        stroke={color}
        strokeWidth="1.33366"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1678 18.3359L12.168 18.3359L11.5012 18.3359"
        stroke={color}
        strokeWidth="1.33366"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
