import React from "react"
import { connect } from "react-redux"
import ReCAPTCHA from "react-google-recaptcha"
import { setValueCurrentField } from "../../Redux/store/form/form.actions"

type ReCaptchaComponentProps = {
  dispatch: any,
}

const ReCaptchaComponent: React.FunctionComponent<ReCaptchaComponentProps> = ({ dispatch }) => {
  // const key: string = "6Lcimz8bAAAAAJ-qUw-XBQOufvnfUj7bBQGhLm1p"
  const key: string = "6LfKfJ0aAAAAAIU8vz173NUGjuaJy5tz2818l1Td"

  const onChange = (value: string | any) => {
    dispatch(setValueCurrentField("reCaptcha", value))
  }

  return (
    <div className={"reCaptcha__container"}>
      <ReCAPTCHA sitekey={key} onChange={onChange} />
    </div>
  )
}

const mapStateToProps = (state: any) => ({})

export default connect(mapStateToProps)(ReCaptchaComponent)
