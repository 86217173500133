import GModal from "../GModal/GModal";
import ActivateInsurance from "../ActivateInsurance/ActivateInsurance";
import React, {FC} from "react";
import {IInsuredDocument} from "../../types/Models";

interface ActivateInsuranceModalProps {
    isOpen: boolean,
    onClose: () => void,
    onSubmit: (data: IInsuredDocument) => void,
    product: { name: string, solutionName: string }
}

const ActivateInsuranceModal: FC<ActivateInsuranceModalProps> = ({
     isOpen,
     onClose,
     onSubmit,
     product
}) => {
    return (
        <GModal open={isOpen} onCancel={onClose} width={527}>
            <ActivateInsurance onSubmit={onSubmit} product={product}/>
        </GModal>
    )
}

export default ActivateInsuranceModal