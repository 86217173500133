import React, {FC, useEffect, useState} from "react";
import {StripePaymentButtonFormProps} from "./StripePaymentButtonFormProps";

import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';
import {PaymentRequest} from "@stripe/stripe-js";
import GButton from "../../molecules/Button/GButton";
import "./StripePaymentButtonForm.scss"
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import {connect, useDispatch} from "react-redux";
import {getFailureAction, setPaymentError} from "../../../../Redux/store/form/form.actions";
import * as stripeJs from "@stripe/stripe-js";
import { Divider } from "antd";

const ERROR_VALIDATION_MESSAGE = 'Please agree terms and conditions first.';
const NAME_VALIDATION_FIELD = 'agreement_terms_v2';

const StripePaymentButtonForm: FC<StripePaymentButtonFormProps> = ({onTokenCreated, priceInfo, marginButton, userCoupon, submitObject}) => {
    const stripe = useStripe();
    const dispatch = useDispatch()
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
    const [showAppsButton, setShowAppsButton] = useState<boolean>(false);
    const containerStyles: React.CSSProperties = {
        marginBottom: marginButton ? marginButton : "0px"
    }

    const handleGooglePayClick = async (event: stripeJs.StripePaymentRequestButtonElementClickEvent) => {
        if(submitObject?.[NAME_VALIDATION_FIELD] === 'off' || !submitObject?.[NAME_VALIDATION_FIELD]) {
            event.preventDefault();

            const termsErrorData = {
                message: ERROR_VALIDATION_MESSAGE,
                errorDescription: ERROR_VALIDATION_MESSAGE,
                nameField: NAME_VALIDATION_FIELD,
                error: ERROR_VALIDATION_MESSAGE
            }

            dispatch(getFailureAction(termsErrorData));
            dispatch(setPaymentError(termsErrorData));
            return;
        } else {
            dispatch(getFailureAction(null));
            dispatch(setPaymentError(null));
        }
    };

    useEffect(() => {
        if (stripe) {
            let payRequest = paymentRequest;

            if(payRequest) {
                if (!payRequest.isShowing()) {
                    payRequest.update({
                        total: {
                            label: priceInfo.productName,
                            amount: priceInfo.totalPay,
                        },
                        displayItems: [{label: priceInfo.productName, amount: priceInfo.totalPay}],
                    });
                }
            } else {
                payRequest = stripe.paymentRequest({
                    country: 'US',
                    currency: 'usd',
                    total: {
                        label: priceInfo.productName,
                        amount: priceInfo.totalPay,
                    },
                    displayItems: [{label: priceInfo.productName, amount: priceInfo.totalPay}],
                    disableWallets: ["link"]
                });
            }

            if (onTokenCreated && payRequest) {
                payRequest.off('token');
                payRequest.on('token', (event) => {
                    onTokenCreated(event, userCoupon);
                });
            }

            setPaymentRequest(payRequest);

            payRequest.canMakePayment().then(result => {
                if (result) {
                    setShowAppsButton(true);
                }
            });
        }
    }, [stripe, priceInfo, userCoupon]);


    return (
        <>
            {paymentRequest && showAppsButton && (
                <div style={containerStyles} className={'StripePaymentButtonForm'}>
                    <PaymentRequestButtonElement
                        options={{
                            paymentRequest,
                            style: {
                                paymentRequestButton: {
                                    type: "default",
                                    theme: "dark",
                                    height: "56px"
                                }
                            }
                        }}
                    />
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        userCoupon: state.billing?.userCoupon
    }
}

export default connect(mapStateToProps)(StripePaymentButtonForm)