import React, { FC } from "react"
import "./GCard.scss"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS_NEW } from "../../types/Colors"
import IconsSvg from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import { CardProps, TitleProps } from "./GCard.props"
import { useHistory } from "react-router-dom"
import { renderNotificationIcon } from "../../../../util/IdentityProtectionHelper"
import useIsMobile from "../../../../../../dashboard-govplus-front/src/hooks/useIsMobile"

const Title: FC<TitleProps> = ({
  hasReviewLink,
  redirectLink,
  title,
  alertsCount,
  hasAdditionalElement,
  additionalElement,
}) => {
  const history = useHistory()
  const isMobile = useIsMobile(768)

  const alertNotificationStyles = { background: COLORS_NEW.RED_700, width: "32px", height: "32px" }
  const alertNotificationLabelFont = SIZE.PARAGRAPH_BOLD_16

  return (
    <div className={"GCard__Review"}>
      <div className={"GCard__Review__Alert"}>
        <div className={"GCard__Review__Alert__Counter"}>
          <GText
            text={title}
            size={isMobile ? SIZE.PARAGRAPH_BOLD_24 : SIZE.PARAGRAPH_BOLD_28}
            color={COLORS_NEW.BLACK_1000}
            style={{ letterSpacing: "-0.5px" }}
          />
          {renderNotificationIcon(alertsCount, alertNotificationStyles, alertNotificationLabelFont)}
        </div>
        {hasAdditionalElement && additionalElement}
      </div>

      {hasReviewLink && redirectLink && (
        <div className={"GCard__Review__Link"} onClick={() => history.push(redirectLink)}>
          <GText
            text={"Review all"}
            size={isMobile ? SIZE.PARAGRAPH_BOLD_14 : SIZE.PARAGRAPH_BOLD_16}
            color={COLORS_NEW.ROYAL_BLUE_700}
          />
          <IconsSvg type={ICONS_SVG_TYPE.ICON_REDIRECT_REVIEW} width={28} height={28} />
        </div>
      )}
    </div>
  )
}

const GCard: FC<CardProps> = ({
  title,
  children,
  hasLogo,
  logoWidth,
  logoHeight,
  logo,
  hasReviewLink,
  alertsCount,
  redirectLink,
  hasAdditionalElement,
  additionalElement,
  style,
  hasLine = true,
  rightElement,
  hasRightElement,
}) => {
  const showLogo = () => {
    return hasLogo ? <div style={{ width: `${logoWidth}px`, height: `${logoHeight}px` }}>{logo}</div> : null
  }

  return (
    <div className={"GCard"} style={style}>
      <div className={"GCard__Row"}>
        {showLogo()}
        <div className={"GCard__Column"} style={hasLine ? { gap: "24px" } : { gap: "8px" }}>
          <div className={"GCard__Column__Logo"}>
            <Title
              title={title}
              hasReviewLink={hasReviewLink}
              alertsCount={alertsCount}
              redirectLink={redirectLink}
              hasAdditionalElement={hasAdditionalElement}
              additionalElement={additionalElement}
            />
          </div>
          {hasLine && <div className={"GCard__HeaderLine"} />}
          {children}
        </div>
        {hasRightElement && rightElement}
      </div>
    </div>
  )
}

export default GCard
