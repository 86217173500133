import {getGovernmentProducts, getRetargetingLinkData} from "../../../appRedux/actions";

export const retargetingLinkNavigator = {
  checkRetargetingToken: (retargetingToken, dispatch, history, anotherGovProductsLength, outerId)=>{
    if (retargetingToken) {
      dispatch(getRetargetingLinkData(retargetingToken, outerId))
    } else {
      history.push("/signin");
    }
  }
}
