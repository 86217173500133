import { FC } from "react"
import { BasicBlockContainerProps } from "../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { renderBasicBlockContainer } from "../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import LayoutBaseContainer from "../UploadPhoto/Layouts/LayoutBaseContainer/LayoutBaseContainer"

const ReviewPageFieldContainer: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text, state, sub_name, field_type, label },
  arrayComponents,
  ...props
}) => {
  const reviewPageProps = {
    sub_name,
    field_type,
    label,
  }
  return renderBasicBlockContainer({ className, text, childrenItem, arrayComponents, state }, LayoutBaseContainer, {
    ...{ props, reviewPageProps },
  })
}

export default ReviewPageFieldContainer
