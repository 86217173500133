import React, {FC} from "react";
import FamilyMemberCard from "../../organisms/FamilyMemberCard/FamilyMemberCard";
import './FamilyMembersList.scss'

interface FamilyMembersListProps {
    familyMembers: any[]
}
const FamilyMembersList: FC<FamilyMembersListProps> = ({ familyMembers }) => {

    return <div className={"GFamilyMembersList"}>
        {familyMembers.map(member =>
            <FamilyMemberCard
                firstName={member.firstName}
                lastName={member.lastName}
                middleName={member.middleName}
                relationship={member.relationship}
                insuredDocuments={member.insuredDocuments}
                renewal={member.renewal}
                expeditedProcessing={member.expeditedProcessing}
            />
        )}
    </div>
}

export default FamilyMembersList