import React, {FC, useEffect, useRef, useState} from "react";
import "./RenewalDocumentCardContent.scss"
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import GServiceCardContentBadge from "../../atoms/ServiceCardContentBadge/ServiceCardContentBadge";
import GButton, {BUTTON_TYPE} from "../../molecules/Button/GButton";
import {Link} from "react-router-dom";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import {GeneralText} from "../../../../shared/text/Text";
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes";
import {Path} from "../../../../util/PageHelper";
import { IProductForRenewal, IProductRenewalForm, IRenewalClaim, IRenewalDocument } from "../../types/Models";
import { formatToDate} from "../../../../util/helper";
import ActivateRenewalModal from "../../templates/ActivateRenewalModal/ActivateRenewalModal";
import { useSubscription } from "../../../../../../dashboard-govplus-front/src/hooks/useSubscription";
import { useHelperSubscription } from "../../../../../../dashboard-govplus-front/src/hooks/useHelperSubscription";

import {capitalizeFirstLetter, getNewFormIdFromSolutionId} from "../../../../util/SolutionNames";
import { useApplications } from "../../../../../../dashboard-govplus-front/src/hooks/useApplications";

interface RenewalDocumentCardContentProps {
    document: IRenewalDocument
    onSubscribe? : () => void
}

const RenewalDocumentCardContent: FC<RenewalDocumentCardContentProps> = ({
    document,
    onSubscribe
}) => {
   const [renewalModal, setRenewalModal] = useState(false)
   const [loadingButton, setLoadingButton] = useState(false)
   const {renewDocument, makeRenewal, subscriptionIsValid } = useSubscription()
   const {checkSameApplication} = useApplications()
   const {
    triggerDocumentRenewalStartedAnalyticEvent, 
    triggerDocumentRenewalCompletedAnalyticEvent
    } = useHelperSubscription()
   const isMounted = useRef(true);
   useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
    const product : IProductRenewalForm = {
     name: document.name,
    // @ts-ignore
     solutionName: document.solutionName
   }
   const buttonStyles: React.CSSProperties = {
        height: 44,
    }
    const letterSpacingText: React.CSSProperties = {
        letterSpacing: "-0.1px"
    }
    function formatObjetToDate(expirationDate:any) {
        const expirationDateFormated = new Date(expirationDate.year,  expirationDate.month - 1, expirationDate.day);
        return expirationDateFormated.toISOString();
      }
   
    const onStartedRenewal = () => {
        
        const claim: IRenewalClaim = {
            name: document.name,
            solution: document.name,
        }
        if (isMounted.current) {
           makeRenewal(claim)
        }
    }
    const onActivatedRenewal = () => {
        triggerDocumentRenewalStartedAnalyticEvent(document.name)
        if (isMounted.current) {
            setRenewalModal(true)
        }
    }

    const getNewDocumentWithGovPlus = () => {
        setLoadingButton(true)
        const formId = getNewFormIdFromSolutionId(document.name)
        if (formId) {
            const application = {
                name: document.name,
                solution: document.name,
                formId
            }
            checkSameApplication(application, true, () => {
                setLoadingButton(false)
            })
        }
    }

    const handleRenewalDocument = (data: IProductRenewalForm) => {
        triggerDocumentRenewalCompletedAnalyticEvent(document.name)
        const forActivatedRenewal: IProductForRenewal ={
            name: document.name,
            expirationDate: formatObjetToDate(data.expirationDate),
            documentNumber: data.documentNumber!,
        }
        renewDocument(forActivatedRenewal, () => {
          if (isMounted.current) {
            setRenewalModal(false)
          }
        })
    }
    
    return (
        <>
            <ActivateRenewalModal
                isOpen={renewalModal}
                onClose={() => setRenewalModal(false)}
                onSubmit={handleRenewalDocument}
                product={product}
            />
            {document.activatedSince &&
                <div className="GRenewalDocumentCardContent">
                    <div className={"GRenewalDocumentCardContent__Header"}>
                        <GText text={capitalizeFirstLetter(document.solutionName!)} size={SIZE.PARAGRAPH_BOLD_20} />
                    </div>
                    <div className={"GRenewalDocumentCardContent__Dates"}>
                        <GServiceCardContentBadge text={`Expires on ${formatToDate(document.expirationDate)}`} iconType={ICONS_SVG_TYPE.CHECK_CIRCLE} />
                        {
                            document.isEligible === false && 
                                <GServiceCardContentBadge text={`Automatically renews on ${formatToDate(document.automaticRenewalDate)}`} iconType={ICONS_SVG_TYPE.CHECK_CIRCLE} />
                        }
                    </div>
                    {
                        document.isEligible && 
                            <div className={"GRenewalDocumentCardContent__BtnContainer"}>
                                <GButton
                                    text={`Renew ${document.solutionName}`}
                                    showIcon={false}
                                    textSize={SIZE.PARAGRAPH_BOLD_16}
                                    style={{...buttonStyles}}
                                    click={onStartedRenewal}
                                />
                            </div>
                    }
                </div>
            }
            {!document.activatedSince &&
                <div className={"GRenewalDocumentCardContent InactiveContent"}>
                    <div className={"GRenewalDocumentCardContent__InactiveHeader"}>
                        <GText text={capitalizeFirstLetter(document.solutionName!)} size={SIZE.PARAGRAPH_BOLD_20} />
                        <GButton
                            text={`Activate auto-renewal`}
                            showIcon={false}
                            textSize={SIZE.PARAGRAPH_BOLD_16}
                            style={buttonStyles}
                            click={subscriptionIsValid() ? onActivatedRenewal: onSubscribe}
                            loading={loadingButton}
                        />
                    </div>
                    <div className={"GRenewalDocumentCardContent__InactiveText"}>
                        <GText text={`No ${document.solutionName}?`} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800}/>
                        <GText 
                            text={GeneralText.LetGovPlusGetIt} 
                            size={SIZE.PARAGRAPH_REGULAR_16} 
                            color={COLORS_NEW.ROYAL_BLUE_700} 
                            onClick={getNewDocumentWithGovPlus} 
                            style={{textDecoration: "underline", cursor: "pointer"}}
                        />
                    </div>
                </div>
            }
        </>

    )
}

export default RenewalDocumentCardContent