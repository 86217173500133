import {put} from "redux-saga/effects";
import {setUpsellProcessingInformation} from "./additional.actions";

/*
    set upsellProcessingInformation to redux store, if it exists, otherwise set empty object
*/
export function *putUpsellProcessingInformation(success: any) {
    if(success?.upsellProcessingInformation) {
        yield put(setUpsellProcessingInformation(success?.upsellProcessingInformation))
    } else {
        yield put(setUpsellProcessingInformation({}))
    }
}

