export interface DeliveryContent {
    title: string;
    information: string;
    titleDone: string;
    trackingNumber: string;
    status: string;
    statusDetail: string;
    estDeliveryDate: string;
    originLocation: Location;
    destinationLocation: Location;
    trackingDetails: TrackingUpdate[];
}

export interface TrackerDetails {
    trackingNumber: string;
    status: string;
    statusDetail: string;
    estDeliveryDate: string;
    originLocation: Location;
    destinationLocation: Location;
    trackingDetails: TrackingUpdate[];
}

export interface Location {
    street1: string | undefined;
    street2: string | undefined;
    city: string;
    state: string;
    country: string;
    zip: string;
    name: string;
}

export interface TrackingUpdate {
    status: ShipmentStatuses
    message: string;
    dateTime: string;
    city: string | null;
    state: string | null;
    country: string | null;
    zip: string | null;
}

export const enum ShipmentStatuses {
    DELIVERED = 'delivered',
    PRE_TRANSIT = 'pre_transit',
    IN_TRANSIT = 'in_transit',
    OUT_FOR_DELIVERY = 'out_for_delivery',
    RETURN_TO_SENDER = 'return_to_sender'
}

export const enum ProcessingStatuses {
    processing =  'processing',
    tracking =  'tracking',
    processing_bot_error = 'processing_bot_error',
    processing_validation = 'processing_validation',
    pending_shipment = 'pending_shipment',
    pending_payload_to_pfl = 'pending_shipment',
    pending_shipment_by_pfl = 'pending_shipment_by_pfl',
    tracking_to_customer = 'tracking_to_customer',
    processing_follow_instructions = 'processing_follow_instructions',
    tracking_to_government = 'tracking_to_government',
    processing_by_government = 'processing_by_government',
    pending_receiving_document_confirmation = 'pending_receiving_document_confirmation',
    completed = 'completed'
}

export interface Address {
    address1: string;
    zip: string;
    city: string;
    state: string;
    type: AddressTitles;
}

export const enum Status {
    Pending = 'Pending',
    Completed = 'Completed',
}

export const enum AddressTitles {
    OldAddress = 'Old Address',
    NewAddress = 'New Address',
}

export const enum AgencyOrProviderTitles {
    GovernmentAgencies = 'Government Agencies',
    ServiceProviders = 'Service Providers',
}

export const showDeliveryStatus = (status: string) => {
    switch (status) {
        case ShipmentStatuses.PRE_TRANSIT:
            return 'Pre-Transit'
        case ShipmentStatuses.IN_TRANSIT:
            return 'In Transit'
        case ShipmentStatuses.OUT_FOR_DELIVERY:
            return 'Out for Delivery'
        case ShipmentStatuses.DELIVERED:
            return 'Delivered'
        case ShipmentStatuses.RETURN_TO_SENDER:
            return 'Return to Sender'
        default:
            return ''
    }
}

export function replaceWords(str: string, replacements: { [key: string]: string }) {
    const pattern = /{([\w]+)}/g;
    if (!str ) return str;    
    return str.replace(pattern, (match, p1) => {
      return replacements[p1] || match;
    });
}

export const formatLocation = (city: string | null, state: string | null) => {
    const formatCity = (city: string | null) => {
        if (!city) return null;
        return city.split(" ").map(word => word[0].toUpperCase() + word.slice(1).toLowerCase()).join(" ");
    }

    if (!city && !state) return '';

    let location = '';

    if (city) {
        location += formatCity(city);
    }

    if (state) {
        if (city) location += ', ';
        location += state;
    }

    return location;
}

export const getExpectedDeliveryAddress = (zip: string | null, street1?: string | null, street2?: string | null) => {
    if (!street2) {
        return `${zip} ${street1}`
    }
    return `${zip} ${street1} ${street2}` || ""
}

export function formatStatus(status: string): string {
    const words = status.split("_");

    const formattedWords = [
        words[0].charAt(0).toUpperCase() + words[0].slice(1),
        ...words.slice(1).map(word => word.toLowerCase())
    ];

    return formattedWords.join(" ");
}

export function replaceSolution(str: string, replacementWord: string): string {
    if (str?.includes("{solution}")) {
      return str.replace(/\{solution\}/g, replacementWord);
    } else {
      return str;
    }
  }

export function getImageFromLocalStorage(name: string) {
    let solutions = JSON.parse(localStorage.getItem("webFlowSolutionData") as any)
    return solutions[name]?.illustration?.url
}

