import React from "react"

interface ISvgCheckOverCircle {
  width: number;
  height: number;
  color: string;
}

export const SvgCheckOverCircle = ({ width, height, color }: ISvgCheckOverCircle) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6667 7.38666V8C14.6658 9.43761 14.2003 10.8364 13.3396 11.9879C12.4788 13.1393 11.2689 13.9816 9.89023 14.3893C8.51162 14.7969 7.03817 14.7479 5.68964 14.2497C4.34112 13.7515 3.18976 12.8307 2.4073 11.6247C1.62484 10.4187 1.25319 8.99204 1.34778 7.55754C1.44237 6.12304 1.99813 4.75755 2.93218 3.66471C3.86623 2.57188 5.12852 1.81025 6.53079 1.49343C7.93306 1.1766 9.40017 1.32156 10.7133 1.90666"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.6667 2.66667L8 9.34L6 7.34" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
