import React, {useEffect, useMemo} from "react";
import "./index.less"
import {useDispatch, useSelector} from "react-redux";
import {
  getFellingPresent,
  getProducts,
  getUserFlattenData,
  setRetargetingLink,
  setSelectedFormId,
  showModalsType,
} from "../../appRedux/actions";
import lazyLoader from "../../HOC/lazyLoader";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import Spinner from "../../components/spiner/Spinner";
import {
  CER_DISABLE_STATES,
  CER_FORM_IDS,
  getDisableCerCountryMessage,
  getDisableCerStateMessage,
} from "@dash/gov-plus__front-end__form/src/shared/constans/cer-contants";
import GText from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Text/Text";
import {COLORS_NEW} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";
import {SIZE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import {FormsKeys} from "@dash/gov-plus__front-end__form/src/util/FormsKeys";
import {HeaderInformation} from "@dash/gov-plus__front-end__form/src/util/HeaderText";
import {getServiceNameById} from "@dash/gov-plus__front-end__form/src/services/gtm/backEndEvent";
import {CountryCode} from "@dash/gov-plus__front-end__form/src/util/CountryCode";
import {ProductsIds} from "@dash/gov-plus__front-end__form/src/util/ProductsIds";
import ProductHelper, {PrepaidProduct} from "@dash/gov-plus__front-end__form/src/util/ProductHelper";
import ToApplyFormIdRepository from "@dash/gov-plus__front-end__form/src/persistence/session/ToApplyFormIdRepository";
import ToApplyServiceIdRepository from "@dash/gov-plus__front-end__form/src/persistence/session/ToApplyServiceIdRepository";
import {useUser} from "../../hooks/useUser";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import StringHelper from "@dash/gov-plus__front-end__form/src/util/StringHelper"

const GovernmentServices = lazyLoader(() => import("../../components/GovernmentServices/GovernmentServices"));

const FormApp = () => {
  const {
    anotherGovProducts,
    productsPresent,
    userProductsName,
    userSelectedProducts,
    duplicateApplications,
    duplicateApplicationLoader,
    selectedFormId,
    allProducts,
    allProductsLoader,
    govProductsLoader,
  } = useSelector(({allProducts}) => allProducts);
  const {authToken, loader} = useSelector(({auth}) => auth);
  const {userFlattenData} = useSelector(({Users}) => Users);
  const dispatch = useDispatch();
  const {getUserSubmittedData} = useUser()
  let headerTitle = HeaderInformation.DASHBOARD_HEADER_TITLE
  let headerDescription = HeaderInformation.DASHBOARD_HEADER_DESCRIPTION
  const history = useHistory();
  const breakpoint = useBreakpoint()
  const toApplyFormId = ToApplyFormIdRepository.getInstance().getValue();
  const getCardConfig = (govCards) => {
    const cardConfig = {
      isDisabled: false,
      disableMessage: '',
    }

    const include = CER_FORM_IDS.find(ids => {
      const findId = govCards.collapseInfo.find(cids => cids.formId === ids)
      return !!findId;
    })

    if(include && userFlattenData){
      if(userFlattenData.country_of_birth && userFlattenData.country_of_birth !== CountryCode.USA) {
        cardConfig.isDisabled = true
        cardConfig.disableMessage = getDisableCerCountryMessage(userFlattenData.country_of_birth)
        return cardConfig
      }

      const stateBirthTag = userFlattenData.state_of_birth
      if(stateBirthTag){
        const isDisabled = CER_DISABLE_STATES.find(state=>state.code === stateBirthTag);
        if (isDisabled){
          cardConfig.isDisabled = true
          cardConfig.disableMessage = getDisableCerStateMessage(isDisabled.name)
          return cardConfig
        }
      }
    }
    return cardConfig
  }

  const setHeaderInformation = () => {
    if(sessionStorage.getItem(FormsKeys.GloSaved) != null) {
      if(ToApplyFormIdRepository.getInstance().getValue() != null) {
        headerTitle = HeaderInformation.DASHBOARD_FROM_GLO_GENERAL_HEADER_TITLE
        headerDescription = HeaderInformation.DASHBOARD_FROM_PRODUCT_SERVICE_HEADER_DESCRIPTION.replace("{service}", getServiceNameById(ToApplyServiceIdRepository.getInstance().getValue()));
      } else {
        headerTitle = HeaderInformation.DASHBOARD_FROM_GLO_GENERAL_HEADER_TITLE
        headerDescription = HeaderInformation.DASHBOARD_FROM_GLO_GENERAL_HEADER_DESCRIPTION
      }
    } else {
      headerTitle = HeaderInformation.DASHBOARD_FROM_GLO_GENERAL_HEADER_TITLE_WITH_OUT_SAVE_GLO
      headerDescription = HeaderInformation.DASHBOARD_FROM_GLO_GENERAL_HEADER_DESCRIPTION_WITH_OUT_SAVE_GLO
    }
  }

  const userFilteredProducts = useMemo(() => {
    const filteredProducts = anotherGovProducts?.filter((item) => {

      /**
       * If card is disabled the priority should be bigger
       * @type {{disableMessage: string, isDisabled: boolean}}
       */
      const cardConfig = getCardConfig(item);
      if(cardConfig.isDisabled) {
        item.priority = 100;
      }

      /**
       * Ready to file (coming from GLO)
       */
      if (item.id === userSelectedProducts) {
        item.priority = -1;
      }

      /**
       * Verify if product was prepaid
       * @type {PrepaidProduct | false}
       */
      item.prepaid = ProductHelper.wasProductPrepaid(item.id, allProducts)
      if(item.prepaid) {
        item.priority = 0;
      }

      item.collapseInfo.sort((a, b) => {
        return parseFloat(a.priority) - parseFloat(b.priority)
      })

      item.selected = item.id === toApplyFormId

      let ctaButtonText = `Get ${StringHelper.capitalizeServiceName(item.title)}`;

      /**
       * Guy patches
       */
      if(item.id === ProductsIds.SSNId) {
        ctaButtonText = `Get ${item.title.replace("Social Security Card", "S.S. card").replace("Social Security card", "S.S. card")}`;
      }
      if(item.id === ProductsIds.TAXId) {
        ctaButtonText = `File taxes`;
      }
      if(item.id === ProductsIds.ChangeAddressId) {
        ctaButtonText = 'Change address'
      }

      item.ctaButtonText = ctaButtonText;
      return item;
    });

    return filteredProducts?.sort((a, b) => {
      if(a.id === toApplyFormId) return -1;
      if(b.id === toApplyFormId) return 1;
      return parseFloat(a.priority) - parseFloat(b.priority)
    })

  }, [anotherGovProducts, userProductsName, userSelectedProducts, allProducts]);

  useEffect(() => {
    if (authToken) {
      dispatch(getProducts(authToken));
      dispatch(getFellingPresent())
      dispatch(getUserFlattenData(authToken))
    }
  }, [authToken])


  useEffect(() => {

    if (Object.keys(duplicateApplications).includes(selectedFormId) && !duplicateApplicationLoader && selectedFormId) {
      dispatch(showModalsType('duplicateApplication'))
    } else {
      if (selectedFormId && !duplicateApplicationLoader) {
        history.push(`forms/?formId=${selectedFormId}&&indexCurrentStep=0`)
        dispatch(setRetargetingLink(selectedFormId));
        dispatch(setSelectedFormId(''))
      }
    }
  }, [duplicateApplications, duplicateApplicationLoader])

  useEffect(() => {
    const isReturned = sessionStorage.getItem('returnFormApps')
    sessionStorage.removeItem('returnFormApps')
    // clear record of previously made insurance claim
    sessionStorage.removeItem('insurance_claim')
  }, [])

  // Check for user dashboard data on context in case GLO was submitted
  useEffect(getUserSubmittedData, [])

  setHeaderInformation()

  return (
    <div className={"getNewFormContainer"}>
      {loader || allProductsLoader || govProductsLoader
        ? <Spinner/>
        : <div className={`getNewFormContainer__content`}>
            <div className="header">
              <GText text={headerTitle} innerHtml={headerTitle} size={breakpoint.xs ? SIZE.HEADING_MDLG : SIZE.HEADING_LG} color={COLORS_NEW.BLACK_1000} maxWidth={"480px"}/>
              <GText text={headerDescription} innerHtml={headerDescription} size={breakpoint.xs ? SIZE.PARAGRAPH_BOLD_16 : SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_500}/>
            </div>


          <div className={"getNewFormContainer-productsBox"}>
            {userFilteredProducts?.map((govCards) => {
              return (
                <div key={govCards.id}>
                  <GovernmentServices
                    productsFillingProgress={productsPresent[govCards.id]}
                    card={govCards}
                    cardConfig={getCardConfig(govCards)}
                  />
                </div>
              )
            })}
          </div>
        </div>
      }
    </div>
  )
}

export default FormApp;
