import React from "react"

interface ISvgBilling {
    width?: number;
    height?: number;
    color?: string;
}

export const SvgBilling = ({ width = 20, height = 20, color = "#6E6E6E" }: ISvgBilling) => {
    const viewBox = `0 0 ${width} ${height}`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} fill="none">
            <path d="M17.5 3.33301H2.50004C1.57957 3.33301 0.833374 4.0792 0.833374 4.99967V14.9997C0.833374 15.9201 1.57957 16.6663 2.50004 16.6663H17.5C18.4205 16.6663 19.1667 15.9201 19.1667 14.9997V4.99967C19.1667 4.0792 18.4205 3.33301 17.5 3.33301Z" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M0.833374 8.33301H19.1667" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
