import {
  CHECK_STATUS, CHECK_STATUS_ERR,
  CHECK_STATUS_SUCCSSES
} from "../../constants/ActionTypes";

export const getCheckStatus = (clientData) => {
  return {
    type: CHECK_STATUS,
    payload: clientData
  };
};

export const getCheckStatusSuccsses = (clientData) => {
  return {
    type: CHECK_STATUS_SUCCSSES,
    payload: clientData
  };
};

export const getCheckStatusFailed = (errMessage) => {
  return {
    type: CHECK_STATUS_ERR,
    errMessage
  };
};
