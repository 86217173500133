export interface PaymentError {
    message: string
    errorDescription: string;
    nameField?: any;
}

export function buildPaymentError(errorMessage?: string, nameField?: any): PaymentError {
    const errorDescription = errorMessage ?? "Something went wrong";
    return {
        message: errorDescription,
        errorDescription: errorDescription,
        nameField: nameField
    }
}