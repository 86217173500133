/* eslint-disable array-callback-return */
import { connect } from "react-redux"
import React from "react"
import importantIcon from "../../../../../../assets/image/important.svg"

type StatusComponentProps = {
  item: {} | any,
  packageResponse: {} | any,
}

// eslint-disable-next-line max-len
const StatusComponent: React.FunctionComponent<StatusComponentProps> = ({ item, packageResponse }) => {
  return (
    <>
      <div className={"status_change_container"}>
        <h3 className="address_change__status-header1">{item.header}</h3>
        <div className={"pending_status_container"}>
          <img src={importantIcon} alt="status" />
          <span className="address_change__pending">
            {item.status}: {packageResponse && packageResponse.date}
          </span>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  packageResponse: state.package.packageResponse,
})

export default connect(mapStateToProps)(StatusComponent)
