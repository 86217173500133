import React from "react";
import {specialStatusConstants} from "../../productsAndStatusesConstants";
import Completed from "./Completed/Completed";
import NoSuchProductOrStatus from "../../../NoSuchProductOrStatus";

const Passport = ({statusName, productId, productName}) => {
  const StatusRender = () =>{
    switch (statusName){
      case specialStatusConstants[productName].completed:{
        return (
          <Completed
            productId={productId}
            status={statusName}
          />
        )
      }
      default: return (
        <NoSuchProductOrStatus
          description={"Sorry page for this status is not ready yet."}
          appId={productId}
          productName={productName}
          productStatus={statusName}
        />
      )
    }
  }

  return (
    <div>{StatusRender()}</div>
  )
}

export default Passport;
