/* eslint-disable indent */
// noinspection DuplicatedCode

import React, { useCallback } from "react"
import { connect } from "react-redux"
import { Form, Input, DatePicker } from "antd"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import moment from "moment"
import { setCashState } from "../../../../Redux/store/cash/cash.action"
import { useEffect, useState } from "react"
import {getLabel} from "../../../../services/service-function/field";

type DateGroupComponentsProps = {
  blockObject: any,
  submitObject: {} | any,
  required: [string],
  dispatch: any,
  observers: any,
}

// eslint-disable-next-line max-len
const DateGroupComponents: React.FunctionComponent<DateGroupComponentsProps> = ({
  blockObject,
  submitObject,
  required,
  dispatch,
  observers,
}) => {
  const defaultValue = blockObject.defaultValue || ""
  const monthFormat = "MM"
  const dayFormat = "DD"
  const yearFormat = "YYYY"
  const format = "MM-DD-YYYY"
  const customFormat = (value: any, label: any) => {
    switch (label) {
      case "Month":
        return `${value.format(monthFormat)}`
      case "Date":
        return `${value.format(dayFormat)}`
      case "Year":
        return `${value.format(yearFormat)}`
      default:
        return `${value.format(format)}`
    }
  }

  const [flagDate, setFlagDate]: any = useState(false)

  useEffect(() => {
    let startDateMileseconds = new Date(submitObject.start_forward_date).getTime()
    let endDateMileseconds = new Date(submitObject.end_forward_date).getTime()
    if (endDateMileseconds - startDateMileseconds <= 604800000) {
      setFlagDate("End Forward Date value should be 7 day or more than Start Forward Date")
    } else if (endDateMileseconds - startDateMileseconds <= 1296000000) {
      setFlagDate("The minimum length for filing a temporary Change of Address is 15 days")
    } else if (endDateMileseconds - startDateMileseconds >= 15552000000) {
      setFlagDate(
        "The Date to Stop Forwarding Mail cannot be more than 6 months after the Date to Begin Forwarding Mail."
      )
    } else {
      setFlagDate(false)
    }
  }, [submitObject.start_forward_date, submitObject.end_forward_date])

  const appType = submitObject.application_type

  const setInitialDate = useCallback(() => {
    dispatch(setValueCurrentField(blockObject.name, moment().format("MM-DD-YYYY")))
    blockObject?.fields?.map((itemDate: any, index: number) =>
      dispatch(
        setValueCurrentField(
          itemDate.name,
          (itemDate.label === "Year" && moment(itemDate.defaultValue).format("YYYY")) ||
            (itemDate.label === "Month" && moment(itemDate.defaultValue).format("MM")) ||
            (itemDate.label === "Date" && moment(itemDate.defaultValue).format("DD"))
        )
      )
    )
  }, [blockObject.fields, blockObject.name, dispatch])

  useEffect(() => {
    setInitialDate()
  }, [setInitialDate])

  useEffect(() => {
    // console.log("1")
    if (!submitObject?.start_forward_date) {
      dispatch(setValueCurrentField('start_forward_date', moment().format('L')))
      dispatch(
        setCashState({
          ...submitObject,
          ['start_forward_date']: moment().format('L'),
        })
      )
    }
  }, [])

  function onChange(
    blockObject: any,
    itemDate: FieldInterface,
    date: any,
    dateString: string,
    nameBlock: string,
    label: string
  ) {
    if (submitObject[nameBlock] && label === "Date") {
      let newString
      if (typeof submitObject[nameBlock] === "object") newString = submitObject[nameBlock].format("YYYY-MM-DD")
      else newString = submitObject[nameBlock]

      const newStringStart = newString.slice(0, 3)
      const newStringEnd = newString.slice(5)
      const dispatchString = `${newStringStart}${date.format("DD")}${newStringEnd}`
      dispatch(setValueCurrentField(nameBlock, dispatchString))
      dispatch(
        setCashState({
          ...submitObject,
          [nameBlock]: dispatchString,
        })
      )
      return
    }
    if (submitObject[nameBlock] && label === "Month") {
      let newString
      if (typeof submitObject[nameBlock] === "object") newString = submitObject[nameBlock].format("YYYY-MM-DD")
      else newString = submitObject[nameBlock]
      const newStringStart = newString.slice(2)
      const dispatchString = `${date.format("MM")}${newStringStart}`
      dispatch(setValueCurrentField(nameBlock, dispatchString))
      dispatch(
        setCashState({
          ...submitObject,
          [nameBlock]: dispatchString,
        })
      )
      return
    }
    if (submitObject[nameBlock] && label === "Year") {
      let newString
      if (typeof submitObject[nameBlock] === "object") newString = submitObject[nameBlock].format("YYYY-MM-DD")
      else newString = submitObject[nameBlock]
      const newStringStart = newString.slice(0, 6)
      const dispatchString = `${newStringStart}${date.format("YYYY")}`
      dispatch(setValueCurrentField(nameBlock, dispatchString))

      dispatch(
        setCashState({
          ...submitObject,
          [nameBlock]: dispatchString,
        })
      )
      return
    }
    if (label === "full") {
      // let newString
      // if (typeof submitObject[nameBlock] === "object") newString = submitObject[nameBlock].format("MM-DD-YYYY")
      // else newString = submitObject[nameBlock]
      const newStringArray = dateString.split("-")
      const dispatchString = `${newStringArray[0]}/${newStringArray[1]}/${newStringArray[2]}`
      dispatch(setValueCurrentField(nameBlock, dispatchString))

      dispatch(
        setCashState({
          ...submitObject,
          [nameBlock]: dispatchString,
        })
      )
      return
    }
  }

  const validation = (rule: any, value: any, callback: (error?: string) => void) => {
    if (submitObject[rule.field]) {
      return callback()
    }
    return callback("Required")
  }
  // different_dob_date-year

  return (
    <div className={`date-group__change-address-container ${blockObject.error_render}`}>
      {/* eslint-disable-next-line eqeqeq */}
      {appType != "permanent" && flagDate && (
        <div className={`addrres-change_error-date-validation_main ${blockObject.error_render}`}>
          <p className={`addrres-change_error-date-validation ${blockObject.error_render}`}>
            {(blockObject.error_render && flagDate) || "."}
          </p>
        </div>
      )}

      {(!blockObject.showObserver ||
        (observers &&
          observers.end_forward_date &&
          observers.end_forward_date.value === "Temporary" &&
          blockObject.showObserver)) && (
        <Form.Item label={getLabel(blockObject.label)} name={blockObject.label}>
          <Input.Group size="small">
            {blockObject.fields &&
              blockObject.fields.length &&
              blockObject?.fields?.map((itemDate: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <Form.Item
                      className={`date-group__data-picker ${itemDate.label === 'full' ? 'fullOneDatePicker' : ''}`}
                      name={itemDate.name}
                      preserve={false}
                      initialValue={
                        (submitObject[itemDate.nameBlock] && moment(submitObject[itemDate.nameBlock])) ||
                        (defaultValue && moment(defaultValue)) ||
                        (itemDate && moment(itemDate.defaultValue))
                      }
                    >
                      <DatePicker
                        onChange={(data, dataString) =>
                          onChange(blockObject, itemDate, data, dataString, itemDate.nameBlock, itemDate.label)
                        }
                        picker={itemDate.label === 'full' ? 'date' : itemDate.label.toLowerCase()}
                        placeholder={itemDate.label === 'Date' ? 'date' : itemDate.label}
                        //  defaultValue={}
                        format={(value) => customFormat(value, itemDate.label)}
                        value={submitObject[itemDate.nameBlock] && moment(submitObject[itemDate.nameBlock])}
                      />
                    </Form.Item>
                  </React.Fragment>
                )
              })}
          </Input.Group>
        </Form.Item>
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
  required: state.step.validation.required,
  observers: state.form.observers,
})

export default connect(mapStateToProps)(DateGroupComponents)
