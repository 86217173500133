import React, {useEffect} from "react";
import {checkUserTokenActive, showGrowlMessage} from "../../appRedux/actions";
import {useDispatch, useSelector} from "react-redux";
import ClientTokenRepository from "@dash/gov-plus__front-end__form/src/persistence/cookies/ClientTokenRepository";

/**
 * Check if Client token is active except that the current URL
 * is in a list of URLs that doesn't need activated account.
 */
export const useCheckClientTokenInCookies = () => {
  const {pathname} = useSelector(({router}) => router.location);
  const dispatch = useDispatch();

  useEffect(() => {
    const pagesWhereNotCheckCookies = [
      "/signin",
      "/",
      "/activate",
      "/activate/",
      "/reset",
      "/signup",
      "/change-password",
      "/resolve",
      "/resolve/",
      "/addPhone",
      "/welcomepage",
      "/verification_code",
      "/formsApp"
    ];
    const clientToken = ClientTokenRepository.getInstance().getValue();
    if (clientToken) {
      dispatch(checkUserTokenActive(clientToken));
    } else if (!pagesWhereNotCheckCookies.includes(pathname)) {
      dispatch(showGrowlMessage({
        messageInfo: "Please login to continue.",
        growlStatus: false,
        code: 400,
        showMessage: true,
      }));
    }
  }, []);
};
