export const insuranceForm = {
  headerText:{
    type: "h1",
    label: "Protect your government documents against loss, damaged, theft and expiration.",
  },
  formBlock:{
    insuranceItems: [
      {
        type: "radio",
        name: "passportData",
        label: "Do you have a valid passport in your possession?",
        defaultValue: "Y",
        items: [
          {textInButtons: "Yes", value: "Y"},
          {textInButtons: "No", value: "N"},
        ],
        additionalField:[
          {
            type: "input",
            label: "What is your Passport number?",
          },
          {
            type: "dataPiker",
            label: "What is your passport expiration date?",
          },
        ],
      },

      {
        type: "radio",
        name: "birthCertificateData",
        label: "Do you have a birth certificate in your possession?",
        items: [
          {textInButtons: "Yes", value: "Y"},
          {textInButtons: "No", value: "N"},
        ],
      },
      {
        type: "radio",
        name: "EIN_Data",
        label: "Do you have an EIN Number?",
        items: [
          {textInButtons: "Yes", value: "Y"},
          {textInButtons: "No", value: "N"},
        ],
        additionalField: [
          {
            label: "What is your EIN Number?",
            type: "input",
          }
        ]
      },
      {
        type: "radio",
        name: "SSN_Data",
        label: "Do you have a valid Social Security card in your possession?",
        items: [
          {textInButtons: "Yes", value: "Y"},
          {textInButtons: "No", value: "N"},
        ],
        additionalField: [
          {
            type: "input",
            label: "What is your Social Security number?",
          }
        ]
      },
      {
        type: "radio",
        name: "TSA_Data",
        label: "Are you enrolled in TSA PreCheck?",
        items: [
          {textInButtons: "Yes", value: "Y"},
          {textInButtons: "No", value: "N"},
        ],
        additionalField: [
          {
            type: "dataPiker",
            label: "When did your TSA PreCheck enrollment start?",
          },
          {
            type: "input",
            label: "What is your KTN Number?",
          },
        ]
      },
    ],
  },
}
