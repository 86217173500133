import GSubscribeWindow from "../../organisms/SubscribeWindow/GSubscribeWindow";
import GModal from "../GModal/GModal";
import React, {FC} from "react";
import {SubscriptionTextLists} from "../../../../shared/text/Text";

interface SubscribeWindowModalProps {
    isOpen: boolean,
    onClose: () => void,
    onSubmit: () => void,
    loading: boolean,
    benefitsList: string[]
}
const SubscribeWindowModal: FC<SubscribeWindowModalProps> = ({
    isOpen,
    onClose,
    onSubmit,
    loading = false,
    benefitsList
 }) => {
    return (
        <GModal open={isOpen} onCancel={onClose} width={800} extraClassName="SubscribePopup" forceRender>
            <GSubscribeWindow
                onSubscribe={onSubmit}
                loading={loading}
                benefitsList={benefitsList}
            />
        </GModal>
    )
}

export default SubscribeWindowModal