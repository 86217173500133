/* eslint-disable array-callback-return */
import { connect } from "react-redux"
import React from "react"
import importantIcon from "../../../../../../assets/image/important.svg"

type ImportantBlockComponentProps = {
  item: {} | any,
}
// eslint-disable-next-line max-len
const ImportantBlockComponent: React.FunctionComponent<ImportantBlockComponentProps> = ({ item }) => {
  return (
    <>
      <div className="address_change__important-block">
        <div className={"importantMessage"}>
          <img className="address_change__important-image" src={importantIcon} alt="important" />
          <h3 className="address_change__important-header-h3" dangerouslySetInnerHTML={{ __html: item.header }} />
        </div>

        <div className={"transaction_info"}>
          <p className="address_change__important-text" dangerouslySetInnerHTML={{ __html: item.text1 }} />
          <ul className="address_change__important-list">
            <li className="address_change__important-listItem1">
              <p dangerouslySetInnerHTML={{ __html: item.list1 }} />
            </li>
            <li className="address_change__important-listItem2">
              <p dangerouslySetInnerHTML={{ __html: item.list2 }} />
            </li>
          </ul>
          <p className="address_change__important-text2" dangerouslySetInnerHTML={{ __html: item.text2 }} />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(ImportantBlockComponent)
