/* eslint-disable array-callback-return */
import { connect } from "react-redux"
import React from "react"
import checkedIcon from "../../../../../../assets/image/check.svg"

type SelectProvidersComponentProps = {
  item: {} | any,
  currentResponse: {} | any,
}

// eslint-disable-next-line max-len
const SelectProvidersComponent: React.FunctionComponent<SelectProvidersComponentProps> = ({ currentResponse }) => {
  return (
    <>
      {Array.isArray(currentResponse) &&
        currentResponse?.map((value: any, index: number) => (
          <div key={index} className={`address_change__selectprovider-block`}>
            <img className="address_change__selectprovider-image" src={checkedIcon} alt="checked" />
            <p className="address_change__selectprovider-text">{value.company_name}</p>
          </div>
        ))}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  currentResponse: state.step.currentResponse,
})

export default connect(mapStateToProps)(SelectProvidersComponent)
