/* eslint-disable max-len */
import React, { FC } from "react"
import "./IdentityProtectionSupport.scss"
import GText from "../../../Components/atomicDesign/atoms/Text/Text"
import { COLORS_NEW } from "../../../Components/atomicDesign/types/Colors"
import { SIZE } from "../../../Components/atomicDesign/types/types"
import IntercomHelper from "../../../util/IntercomHelper"

const IdentityProtectionSupport: FC = () => {
  const contactUsClick = () => {
    IntercomHelper.showBubbleChat()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      contactUsClick()
    }
  }

  return (
    <div className={"IdentityProtectionSupport"}>
      <GText text={"Your identity protection"} size={SIZE.PARAGRAPH_BOLD_40} color={COLORS_NEW.BLACK_1000} />

      <p className={"IdentityProtectionSupport__Label"}>
        {`We apologize for the inconvenience, but we couldn't complete the enrollment process for your Identity protection service. Please `}
        <span onClick={contactUsClick} onKeyDown={handleKeyDown} role="link" tabIndex={0}>
          {`contact`}
        </span>
        {` our customer support team for further assistance.`}
      </p>
    </div>
  )
}

export default IdentityProtectionSupport
