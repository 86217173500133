import React, {useEffect} from 'react';
import {Form, FormInstance, Radio} from 'antd';
import SubscriptionPaymentItem from "../../SubscriptionPaymentForm/SubscriptionPaymentForm/SubscriptionPaymentItem";
import GText from "../../atomicDesign/atoms/Text/Text";
import {SIZE} from "../../atomicDesign/types/types";
import {COLORS, COLORS_NEW} from "../../atomicDesign/types/Colors";
import './style.scss';
import {Charge} from "../../FormComponents/BlockFormWithFields/BillingInformation/BlockBillingInformation";
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import ServicesHelper from '../../../util/ServicesHelper';
import { getServiceNameById } from '../../../services/gtm/backEndEvent';
import FormIdRepository from '../../../persistence/session/FormIdRepository';

interface ChargePaymentProps {
  charges: Charge[];
  service?: string;
  setSelectedCharge: any;
  form?: FormInstance;
}

const ChargePaymentForm: React.FC<ChargePaymentProps> = ({
                                                           charges,
                                                           service,
                                                           setSelectedCharge,
                                                           form
                                                         }) => {
  const getDefaultCharge = () => charges[0]

  const {id: defaultId} = getDefaultCharge()

  const breakPoint = useBreakpoint()
  const serviceId = FormIdRepository.getInstance().getValue()
  const serviceName = service || (serviceId && getServiceNameById(serviceId))
  const includesGovernmentFees = ServicesHelper.involvesGovernmentFee(serviceName)

  useEffect(() => {
    if (charges?.length) {
      const defaultCharge = getDefaultCharge()
      setSelectedCharge({method: defaultCharge?.id, price: defaultCharge?.price})
      form?.setFieldsValue({itemPriceChargeId: defaultCharge?.id})
    }
  }, [charges]);


  const onRadioChange = (selectedData: string) => {
    const selectedRow = charges.find((charge) => charge.id === selectedData);
    selectedRow && setSelectedCharge({
      method: selectedRow.id,
      price: Number(selectedRow.price)
    });
  };

  const chargesIncludePriorityProcessing = () => charges.some((charge) => charge.name.includes('Priority'))
  const paymentFooterText = (() => {
    const text = includesGovernmentFees && "Government fees not included.";

    if (chargesIncludePriorityProcessing() && text) {
      return `Processing times start once documents are received. <br> ${text}`
    } else if (text) {
      return text;
    } else {
      return null
    }
  })()

  return (
    <div className={'charge-payment-form'}>
      <Form.Item
        name="itemPriceChargeId"
        rules={[
          {required: true, message: "Please select your payment option"}
        ]}
        initialValue={defaultId}
      >
        <Radio.Group
          onChange={(e) => onRadioChange(e.target.value)}
          className={"payment-method-information"}
          ref={(el) => {
            el?.style?.setProperty('display', 'flex', 'important')
          }}
        >
          {charges && charges.map((charge, index) => (
          <>
            <li
              key={charge.id}
              className={'payment-method-information__item'}
            >
              <SubscriptionPaymentItem
                  method={charge.id}
                  price={charge.price || charge.amount}
                  salePrice={charge.salePrice}
                  saleLabel={charge.saleLabel}
                  saleName={charge.description || service}
                  showPeriodSuffix={false}
              />
            </li>
              {
                charges.length > 1 &&
                index == 0 &&
                <div className={'traveling-soon'}>
                  <GText
                      text={'Traveling soon? GOV+ has you covered!'}
                      color={COLORS_NEW.ROYAL_BLUE_700}
                      size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16}
                      style={{marginTop: 8}}
                  />
                </div>
              }
            </>
          ))}
        </Radio.Group>
      </Form.Item>
      {paymentFooterText && <div
          className={'payment-footer'}
          style={{textAlign: chargesIncludePriorityProcessing() ? "center" : "right"}}>
          <GText
            text={paymentFooterText}
            innerHtml={paymentFooterText}
            size={SIZE.PARAGRAPH_REGULAR_16}
            color={COLORS.SLATE_GRAY_500}
            style={{textAlign: "right"}}
        />
      </div>}
    </div>
  );
};

export default ChargePaymentForm;