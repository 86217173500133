import React from "react"
import {SIZE} from "../../../atomicDesign/types/types"
import {COLORS} from "../../../atomicDesign/types/Colors"
import GIconsSvgType from "../../../atomicDesign/atoms/IconsSvg/IconsSvg"
import {ICONS_SVG_TYPE} from "../../../atomicDesign/atoms/IconsSvg/IconsSvgTypes"
import "./ProcessingValidationComponent.scss"
import GStepApplicationCard, { StepApplicationCardContentAlign } from "../../../atomicDesign/molecules/StepApplicationCard/StepApplicationCard"
import { getImageFromLocalStorage } from "../../../../util/ProcessingTrackingHelper"

type ProcessingValidationComponentProps = {
    nameProduct: string,
    title: string,
    subtitle: string,
}

const ProcessingValidationComponent: React.FunctionComponent<ProcessingValidationComponentProps> = ({nameProduct, title, subtitle}) => {

    return (
        <div className={`GProcessingValidationComponent`}>
            <GStepApplicationCard
                title={title}
                subtitle={subtitle}
                productImage={getImageFromLocalStorage(nameProduct)}
                contentAlign={StepApplicationCardContentAlign.Center}
                iconType= {ICONS_SVG_TYPE.SEARCH_STEP}
            />
        </div>
    )
}
export default ProcessingValidationComponent
