import React, {FC, useEffect, useState} from "react";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import "./GUpsellCardSubscriptionCrossSellHeader.scss";
import {SolutionNames} from "../../../../util/SolutionNames";
import GUpsellCardApplicationCard from "../UpsellCardApplicationCard/GUpsellCardApplicationCard";
import productsImg from "../../../../assets/image/applications-image-horizontal-subscription-after-payment.webp"
import productsImgMobile from "../../../../assets/image/applications-image-horizontal-subscription-after-payment-mobile.webp"
// @ts-ignore
import { useApplications } from "../../../../../../dashboard-govplus-front/src/hooks/useApplications";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import ProductsTableList from "../../organisms/ProductsTableList/ProductsTableList";

type GUpsellCardSubscriptionCrossSellHeaderProps = {
    acquiredService: string
    offeredService: string
}
const GUpsellCardSubscriptionCrossSellHeader = ({
    acquiredService,
    offeredService
  } : GUpsellCardSubscriptionCrossSellHeaderProps) => {
    const [servicesImages, setServicesImages] = useState({acquiredImage: '', offeredImage: ''});
    const {availableProducts} = useApplications()
    const products = availableProducts
        .filter(product => product.active)
        .sort((a,b) => a.priority - b.priority)
        .map(product => `${product.title} ($67 value)`)
    const breakpoint = useBreakpoint()

    useEffect(() => {
        const webFlowData = JSON.parse(localStorage.getItem("webFlowSolutionData") || '');
        if (webFlowData && webFlowData !== '') {
            const servicesImages = Object.keys(webFlowData).reduce((accumulator, currentValue) => {
                if (SolutionNames[currentValue as keyof typeof SolutionNames] === acquiredService) {
                    accumulator.acquiredImage = webFlowData[currentValue]?.illustration?.url
                }
                if (SolutionNames[currentValue as keyof typeof SolutionNames] === offeredService) {
                    accumulator.offeredImage = webFlowData[currentValue]?.illustration?.url
                }
                return accumulator;
            }, {acquiredImage: '', offeredImage: ''})
            setServicesImages(servicesImages);
        }
    }, []);

    const acquiredServiceShortened = acquiredService === SolutionNames.SSN ? 'S.S. card' : acquiredService
    const offeredServiceShortened = offeredService === SolutionNames.SSN ? 'S.S. card' : offeredService

    return (
        <div className={"GUpsellCardComponentHeaderSubscription"}>
            <div className="GUpsellCardComponentHeaderSubscription__Container">
                <div className="GUpsellCardComponentHeaderSubscription__Container__AcquiredService">
                    <GUpsellCardApplicationCard isOffered={false} solution={acquiredServiceShortened} status={'Completed'}
                                                productImage={servicesImages?.acquiredImage}/>
                </div>
                <GText text={"+"} size={SIZE.HEADING_XXL} color={COLORS_NEW.BLACK_1000}/>
                <div className="GUpsellCardComponentHeaderSubscription__Container__OfferedService">
                    <GUpsellCardApplicationCard isOffered={true} solution={offeredServiceShortened} status={'Ready' +
                        ' to file' +
                        ' now'} productImage={servicesImages?.offeredImage}/>
                </div>
            </div>
            <div className={"GUpsellCardComponentHeaderSubscription__SubscriptionOffer"}>
              <GText text={`File unlimited applications including your ${offeredService}  with GOV+ Unlimited`} size={breakpoint.md ? SIZE.HEADING_MDLG : SIZE.PARAGRAPH_BOLD_22} />
              <img src={breakpoint.md ? productsImg: productsImgMobile} />
                <ProductsTableList products={products} />
            </div>
        </div>
    )
}

export default GUpsellCardSubscriptionCrossSellHeader;