import React, {useEffect, useState} from 'react'
import GAdditionalStep, {StepVisualStatus} from "../../../atomicDesign/templates/AdditionalStep/AdditionalStep";
import {LoadingState} from "../../../../shared/constans/user-from-view-mode.enum";
import QuestionaryNAM from "../QuestionaryNAM/QuestionaryNAM";
import {connect, useSelector} from "react-redux";
import AddressVerificationFormFields
    from "../../AdditionalPageComponent/AdditionalPageByType/AddressVerification/AddressVerificationFormFields";
import AddressVerificationFormSelect
    from "../../AdditionalPageComponent/AdditionalPageByType/AddressVerification/AddressVerificationFormSelect";
import {
    setAddressValidation, setStatusGovernmentFeeOpen, setStatusPhotoUpload, setStatusSignature, setStatusSignatureOpen,
    setStatusTSADetailsToOpen
} from "../../../../Redux/store/additionalForm/additional.actions";
import {delayTimer} from "../../../../Pages/FormPage/FormPage";
import {getScrollElementById} from "../../FormBlock/FormBlock";
import {RootState} from "../../../../App";
import {additionalSelector} from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto";
import QuestionaryComponent from "../QuestionaryComponent/QuestionaryComponent";
import PassportDetailsComponent from "../PassportDetailsComponent/PassportDetailsComponent";
import GUploadPassportPhotoStep from "../../../atomicDesign/organisms/UploadPassportPhotoStep/GUploadPassportPhotoStep";
import GovernmentFeesFormFields
    from "../../AdditionalPageComponent/AdditionalPageByType/GovermentFees/GovernmentFeesFormFields";
import {ServicesIds} from "../../../../util/ServicesIds";
import SignatureComponent from "../SignatureComponent/SignatureComponent";
import UploadComponent from "../UploadComponent/UploadComponent";
import moment from "moment";
import {setValueCurrentField} from "../../../../Redux/store/form/form.actions";
import {getIdNameFromStatuses} from "../../../../util/BlockStep";
import { BlockStepTypes } from '../../../../Constants/BlockStepTypes';

type BlocksStepsNamProps = {
    blocksStep: any,
    index: number,
    showStatus: string,
    statusQuestionnaireFront: string,
    statusDetailsToOpen: string,
    statusPhotoUpload: string,
    statusDocuments: string,
    statusGovernmentFeeOpen: string,
    statusSignatureBlockOpen: string,
    setStripeElements: any,
    loadingButtonState: string,
    dispatch: any,
    currentResponse: any,
    statusAddress: string,
    statusSignature: string,
    submitObject: {} | any
}

enum PassportDsType {
    DS_11 = "ds11",
    DS_82 = "ds82",
    DS_5504 = "ds5504"
}

export const setPassportDsTypeForNam = (submitObject: any, dispatch: any) => {
    if (submitObject['has_us_passport'] && submitObject['issue_date_book'] && submitObject['date_name_change']) {
        let passportDsType = PassportDsType.DS_82;
        const issueDateBook = moment(submitObject['issue_date_book'])
        const nameChangeDate = moment(submitObject['date_name_change']).subtract(1, 'year');
        const dateBirth = moment(submitObject['date_birth']).add(16, 'years');

        if (issueDateBook.isAfter(nameChangeDate) && issueDateBook.isAfter(moment().subtract(1, 'year'))) {
            passportDsType = PassportDsType.DS_5504;
        } else if (issueDateBook.isBefore(moment().subtract(15, 'years')) || dateBirth.isAfter(issueDateBook)) {
            passportDsType = PassportDsType.DS_11;
        }

        if (submitObject['passport_ds_type'] !== passportDsType) {
            dispatch(setValueCurrentField('passport_ds_type', passportDsType));
        }
    }
}

const BlocksStepsNam: React.FC<BlocksStepsNamProps> = ({
                                                           blocksStep,
                                                           index,
                                                           showStatus,
                                                           statusQuestionnaireFront,
                                                           statusDetailsToOpen,
                                                           statusPhotoUpload,
                                                           statusDocuments,
                                                           statusGovernmentFeeOpen,
                                                           statusSignatureBlockOpen,
                                                           setStripeElements,
                                                           loadingButtonState,
                                                           dispatch,
                                                           currentResponse,
                                                           statusAddress,
                                                           statusSignature,
                                                           submitObject
                                                       }) => {

    const [passportPhotoState, setPassportPhotoState] = useState("")
    const {statusSignaturePage} = useSelector<RootState, { statusSignaturePage: string } | undefined>(
        additionalSelector
    ) ?? {statusSignaturePage: ""}

    useEffect(() => {
        delayTimer(300).then(() => getScrollElementById({id: `${statusSignaturePage}_scrollById`}))
    }, [statusSignaturePage])

    const handlerPreQuestionnaireClick = () => {
    }

    const handlerAddressValidation = (status: string) => {
        dispatch(setAddressValidation(status))
    }

    const handlerDetailsToOpen = (status: string) => {
        dispatch(setStatusTSADetailsToOpen(status))
    }

    const handlerUploadPhotoBlock = (status: string) => {
        setPassportPhotoState(status)
        dispatch(setStatusPhotoUpload(status))
    }

    const handlerGovernmentFeeOpen = (status: string) => {
        dispatch(setStatusGovernmentFeeOpen(status))
    }

    const handlerSignature = (status: string) => {
        dispatch(setStatusSignature(status))
        dispatch(setStatusSignatureOpen(status))
    }

    const getAddressForms = () => {
        if (currentResponse?.verifiedAddress?.error) {
            return (
                <AddressVerificationFormFields
                    forceLoadingAndDisabled={true}
                    blocksStep={blocksStep}
                    statusSignaturePage={statusSignature}
                />
            )
        } else {
            return <AddressVerificationFormSelect addressContent={blocksStep.content}/>
        }
    }

    let idNameFromStatuses = getIdNameFromStatuses(blocksStep.status)

    return (
        <div className={`steps-signature-container`} id={`${idNameFromStatuses}${index !== 1 ? "_scrollById" : ""}`}>
            {blocksStep.type === BlockStepTypes.QUESTIONNAIRE && (
                <GAdditionalStep
                    index={index.toString()}
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusQuestionnaireFront}
                    ctaForm={handlerPreQuestionnaireClick}
                    loadingButton={loadingButtonState === LoadingState.Loading}
                >
                    <QuestionaryNAM blocksStep={blocksStep}/>
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.INVALID_ADDRESS && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusAddress}
                    ctaForm={() => handlerAddressValidation(StepVisualStatus.STATUS_PROCESS)}
                >
                    {getAddressForms()}
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.QUESTIONNAIRE_SSN && (
                <GAdditionalStep
                    index={index.toString()}
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusQuestionnaireFront}
                    ctaForm={handlerPreQuestionnaireClick}
                    loadingButton={loadingButtonState === LoadingState.Loading}
                >
                    <QuestionaryComponent blocksStep={blocksStep}/>
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.ADDITIONAL_INFORMATION_STEP && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusDetailsToOpen}
                    index={index.toString()}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    ctaForm={() => {
                        handlerDetailsToOpen(StepVisualStatus.STATUS_PROCESS)
                    }}
                >
                    <PassportDetailsComponent blocksStep={blocksStep} statusSignaturePage={statusSignaturePage}/>
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.UPLOAD_PHOTO && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={passportPhotoState}
                    ctaForm={() => handlerUploadPhotoBlock(StepVisualStatus.STATUS_PROCESS)}
                >
                    <GUploadPassportPhotoStep/>
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.GOVERNMENT_FEES && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusGovernmentFeeOpen}
                    index={index.toString()}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    ctaForm={() => handlerGovernmentFeeOpen(StepVisualStatus.STATUS_PROCESS)}
                >
                    <GovernmentFeesFormFields blocksStep={blocksStep} setStripeElements={setStripeElements}
                                              index={index}/>
                </GAdditionalStep>
            )}

            {(blocksStep.type === BlockStepTypes.UPLOADING_FILE ||
                blocksStep.type === BlockStepTypes.UPLOADING_ADDITIONAL_FILE ||
                blocksStep.status === "identity_document" ||
                blocksStep.status === "citizenship_document") && (
                <UploadComponent
                    blocksStep={blocksStep}
                    index={index}
                    showStatus={showStatus}
                    loadingButtonState={loadingButtonState}
                    statusDocuments={statusDocuments}
                    relationship={""}
                    proofOnFlag={true}
                />
            )}

            {blocksStep.type === BlockStepTypes.SIGNATURE && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusSignatureBlockOpen}
                    ctaForm={() => handlerSignature(StepVisualStatus.STATUS_PROCESS)}
                >
                    <SignatureComponent nestBlock={blocksStep.content.nestBlock}/>
                </GAdditionalStep>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    statusQuestionnaireFront: state.additional.statusQuestionnaireFront,
    statusDetailsToOpen: state.additional.statusDetailsToOpen,
    statusPhotoUpload: state.additional.statusPhotoUpload,
    statusDocuments: state.additional.statusDocuments,
    statusGovernmentFeeOpen: state.additional.statusGovernmentFeeOpen,
    statusSignatureBlockOpen: state.additional.statusSignatureBlockOpen,
    loadingButtonState: state.loading.loadingButtonState,
    currentResponse: state.step.currentResponse,
    statusAddress: state.additional.statusAddress,
    statusSignature: state.additional.statusSignature,
    submitObject: state.form.submitObject
})

export default connect(mapStateToProps)(BlocksStepsNam)
