import FlattenDataDto from "../../dto/FLattenDataDto/FlattenDataDto";

export const CER_FORM_IDS = ['vital-records-new', 'vital-records-change'];
export const CER_DISABLE_STATES = [
    {
        code:"KY",
        name:"Kentucky"
    },
    {
        code:"DE",
        name:"Delaware"
    },
    {
        code:"TN",
        name:"Tennessee"
    },
    {
        code:"NYC",
        name:"New York City"
    }
];

export const STATE_BIRTH_TAG_FIELD = FlattenDataDto.VAR_STATE_OF_BIRTH;

export function getDisableCerStateMessage(state: string){
    return `We cannot process birth certificates for the state of ${state} at this time`;
}

export function getDisableCerCountryMessage(country: string){
    return `Birth certificates is just for United States citizens. We cannot process birth certificates for the country of ${country} at this time`;
}