import React, { useEffect, useState } from "react"
import BasicBlockContainer from "../../Components/FormComponents/UploadPhoto/MainBlockContainer/BasicBlockContainer"
import { arrayUploadPhotoComponents } from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/datas/arrayUploadPhotoComponents"
import GHeader, { HEADER_SIZE_THEME, HEADER_THEME_TYPE } from "../../Components/atomicDesign/templates/Headers/Header"
import GProgressBar from "../../Components/atomicDesign/molecules/ProgressBar/ProgressBar"
import { useSelector } from "react-redux"
import { RootState } from "../../App"
import { userSelector } from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { StepInterface } from "../../Redux/InterfacesEntity/step.interface"
import {COLORS, COLORS_NEW} from "../../Components/atomicDesign/types/Colors"
import { useUser } from "../../../../dashboard-govplus-front/src/hooks/useUser"
import GNotification, { NOTIFICATION_TYPE } from "../../Components/atomicDesign/organisms/Notification/Notification"
import { GeneralText } from "../../shared/text/Text"
import { ICONS_SVG_TYPE } from "../../Components/atomicDesign/atoms/IconsSvg/IconsSvgTypes"
import { useUrlParams } from "../../../../dashboard-govplus-front/src/hooks/useUrlParams"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { STEP_NAME_PAYMENT_PAGE } from "../../shared/constans/payment"
import { useApplications } from "../../../../dashboard-govplus-front/src/hooks/useApplications"

type PageMainContainerProps = {
  currentStep?: StepInterface,
}

const PageMainContainer: React.FunctionComponent<PageMainContainerProps> = ({ currentStep }) => {
  const breakPoint = useBreakpoint()
  const {isUserLoggedIn, userFlattenData} = useUser()
  const {getProductsInfo} = useApplications()
  const percents = useSelector<RootState, { percents: any }>(userSelector).percents ?? ""
  const item = currentStep?.mainLayout
  if (!item) {
    return null
  }

  const processingPage = currentStep?.formType === "processing_steps"
  const paymentPage = currentStep?.formType === "payment"

  const pVarPercent = percents?.oneProduct ?? 0
  const progressBarContainer: React.CSSProperties = {
    marginTop: paymentPage && breakPoint.xs ? "20px" : "24px",
    width: "100%",
  }

  const formContainer: React.CSSProperties = {
    marginTop: paymentPage && breakPoint.xs ? "20px" : processingPage ? breakPoint.md ? "40px" : "24px" : "24px",
    width: "100%",
  }

  const pageStyles: React.CSSProperties = {
    backgroundColor: processingPage ? "transparent" : COLORS.WHITE,
    padding: processingPage ? "0" : (breakPoint.xs ? "24px" : "32px"),
    borderRadius: 24,
    boxShadow: processingPage ? "none" : "0px 8px 24px 4px rgba(0, 0, 0, 0.04)",
    maxWidth: (currentStep.formType === 'payment' || processingPage) ? 810 : 668
  }
  
  const notificationStyles: React.CSSProperties = {
    marginBottom: breakPoint.xs ? 32 : 40
  }
  const getHeader = () => {
    return {
      ...item.header,
      size: currentStep?.additionalStep ?
          (!breakPoint.sm ? HEADER_SIZE_THEME.ADDITIONAL_STEPS : "100%") :
          (!breakPoint.xs ? HEADER_SIZE_THEME.HEADER_FORMS : "100%"),
      fieldsCount: getFieldsCount()
    }
  }

  const getFieldsCount = () => {
    let total = 0
    // Get currently displayed items from the json form
    const formBlocks = document.getElementsByClassName("ant-form-item")

    // Convert HTMLCollectionOf<Element> to array (can't be iterated otherwise)
    // Do not count instances of items inside items with the same class (happens with inputs and date pickers)
    Array.from(formBlocks).forEach(block => {
      if (!block.classList.contains("GFormItem__item") && !block.classList.contains("date-group__fieldData-picker") ) {
        total++
      }
    })
    // Exclude the button form item
    return total > 0 ? total - 1 : "a few"
  }

  const skippedGlo = (
    !userFlattenData?.data.personal || 
    Object.keys(userFlattenData?.data?.personal).length === 0 || 
    (Object.keys(userFlattenData?.data?.personal).length === 1 && userFlattenData?.data?.personal?.demographics?.countryOfBirth)
  )
 useEffect(() => {
  getProductsInfo()
 }, [currentStep])
  return (
    <div style={pageStyles}>
      {!isUserLoggedIn() && processingPage && <div style={notificationStyles}>
        <GNotification text={GeneralText.CheckYourEmail} type={NOTIFICATION_TYPE.WARNING} icon={ICONS_SVG_TYPE.INFORMATION}/>
        </div>}
      {item.header && (
        <>
          <GHeader headerProps={getHeader()} />
          {!currentStep?.additionalStep && (
            <div style={progressBarContainer}>
              {item.header.theme === HEADER_THEME_TYPE.HEADER_FORMS && 
                <GProgressBar value={pVarPercent} color={COLORS_NEW.GREEN_600} showIcon={false} />}
            </div>
          )}
        </>
      )}

      <div style={formContainer}>
        <BasicBlockContainer item={item} arrayComponents={arrayUploadPhotoComponents} />
      </div>
    </div>
  )
}

export default PageMainContainer
