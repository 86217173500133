/* eslint-disable indent */
import React, { useRef } from "react"
import { connect } from "react-redux"

type FormHelperMainStepsProps = {
  currentField: {} | any,
  helperContent: {} | any,
}

const checkOnArray = (item: string | [] | any, index: number) => {
  if (typeof item === "string") {
    return <p className={"form-helper__main-text"} dangerouslySetInnerHTML={{ __html: item }}></p>
  } else {
    return (
      <ul className={"form-helper__main-list-container"}>
        {item.map((listItem: any, idx: number) => {
          return (
            <li key={idx} className={"form-helper__main-list-text"}>
              <span>{listItem}</span>
            </li>
          )
        })}
      </ul>
    )
  }
}

export const checkCurrentFieldContent = (currentField: {} | any) => {
  if (currentField.helper && typeof currentField.helper.description === "string") {
    return (
      <p className={"form-helper__main-text"} dangerouslySetInnerHTML={{ __html: currentField.helper.description }}></p>
    )
  } else {
    return (
      currentField.helper &&
      Object.values(currentField.helper.description).map((item: any, index: number) => {
        return <React.Fragment key={index}>{checkOnArray(item, index)}</React.Fragment>
      })
    )
  }
}

// eslint-disable-next-line max-len
const FormHelperMainSteps: React.FunctionComponent<FormHelperMainStepsProps> = ({ currentField, helperContent }) => {
  const helperRef: any = useRef()

  const checkHelperContent = helperContent?.blockOnFormPage ?? {}

  return (
    <>
      <div ref={helperRef} className={`form-helper ${window.pageYOffset > 300 ? "form-helper_top" : false}`}>
        <h2 className={"form-helper__header"}>{checkHelperContent?.label || "Application Help"}</h2>
        {checkHelperContent?.hint_label && !currentField?.helper && <p>{checkHelperContent?.hint_label}</p>}
        <div className={"form-helper__main"}>
          {currentField?.helper?.header && (
            <h4 className={"form-helper__main-title"}>{currentField?.helper?.header}</h4>
          )}
          {currentField?.helper?.hint && <p className={"form-helper__main-hint"}>{currentField?.helper?.hint}</p>}
          {checkCurrentFieldContent(currentField)}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({})

export default connect(mapStateToProps)(FormHelperMainSteps)
