import React from "react"

interface ISvgX {
  width: number;
  height: number;
  color: string;
}

export const SvgX = ({ width, height, color }: ISvgX) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.427 3.57031L3.56934 10.428"
        stroke={color}
        strokeWidth="1.14295"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.56934 3.57031L10.427 10.428"
        stroke={color}
        strokeWidth="1.14295"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
