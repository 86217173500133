import React, { FC } from "react"
import "./GChip.scss"
import { SIZE } from "../../types/types"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import GText from "../../../../Components/atomicDesign/atoms/Text/Text"
import { GChipProps } from "./GChip.props"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import IconsSvg from "../../atoms/IconsSvg/IconsSvg"
import GButton from "../../molecules/Button/GButton"
import { RecordType, getRecordTypeName } from "../../../../util/IdentityProtectionHelper"
import useIsMobile from "../../../../../../dashboard-govplus-front/src/hooks/useIsMobile"
import GTag from "../../molecules/Tag/GTag"

const GChip: FC<GChipProps> = ({ recordType, isPresent, onClick, dynamic }) => {
  const statusColor = isPresent ? COLORS_NEW.BLACK_600 : COLORS_NEW.RED_700
  const canEdit = isPresent && recordType !== RecordType.SSN
  const isMobile = useIsMobile(768)

  if (isMobile) {
    return (
      <div className={"GChip"} onClick={onClick}>
        <div className={canEdit ? "GChip__Content" : "GChip__Disabled"}>
          <div className={"GChip__Top"}>
            <div className={"GChip__Top__Left"}>
              {isPresent ? (
                <IconsSvg type={ICONS_SVG_TYPE.ICON_APPROVED_STATUS} width={32} height={36} />
              ) : (
                <IconsSvg type={ICONS_SVG_TYPE.ICON_REJECTED_STATUS} width={32} height={36} />
              )}
              <div className={"GChip__Content__Info"}>
                <div className={"GChip__Content__Text"}>
                  <GText text={getRecordTypeName(recordType)} size={SIZE.PARAGRAPH_BOLD_18} color={COLORS.BLACK_700} />
                </div>
                <div className={"GChip__Content__Status"}>
                  {isPresent ? (
                    <GText text={"Monitored"} size={SIZE.PARAGRAPH_BOLD_14} color={statusColor} />
                  ) : (
                    <GTag
                      text={"\u2022 Not monitored"}
                      colorBackground={COLORS_NEW.RED_200}
                      colorText={COLORS_NEW.RED_700}
                    />
                  )}
                </div>
              </div>
            </div>
            {canEdit && dynamic && (
              <div className={"GChip__Edit"}>
                <IconsSvg type={ICONS_SVG_TYPE.ICON_EDIT} width={20} height={20} />
              </div>
            )}
          </div>

          {!isPresent && dynamic && (
            <div className={"GChip__Content__Actions"}>
              <GButton text={"Monitor"} showIcon={false} textSize={SIZE.PARAGRAPH_BOLD_14} click={onClick} />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={"GChip"} onClick={onClick}>
      {canEdit && dynamic && (
        <div className={"GChip__Edit"}>
          <IconsSvg type={ICONS_SVG_TYPE.ICON_EDIT} width={20} height={20} />
        </div>
      )}
      <div className={canEdit ? "GChip__Content" : "GChip__Disabled"}>
        <div className={"GChip__Content__Text"}>
          {isPresent ? (
            <IconsSvg type={ICONS_SVG_TYPE.ICON_APPROVED_STATUS} width={32} height={36} />
          ) : (
            <IconsSvg type={ICONS_SVG_TYPE.ICON_REJECTED_STATUS} width={32} height={36} />
          )}

          <GText text={getRecordTypeName(recordType)} size={SIZE.PARAGRAPH_BOLD_18} color={COLORS.BLACK_700} />
        </div>
        <div className={"GChip__Content__Status"}>
          {isPresent ? (
            <GText text={"Monitored"} size={SIZE.PARAGRAPH_BOLD_14} color={statusColor} />
          ) : (
            <GTag text={"\u2022 Not monitored"} colorBackground={COLORS_NEW.RED_200} colorText={COLORS_NEW.RED_700} />
          )}
        </div>
        {!isPresent && dynamic && (
          <div className={"GChip__Content__Actions"}>
            <GButton text={"Monitor"} showIcon={false} textSize={SIZE.PARAGRAPH_BOLD_14} click={onClick} />
          </div>
        )}
      </div>
    </div>
  )
}

export default GChip
