import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { ValidationItemInterface } from "../../../../Redux/InterfacesEntity/validation.interface"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import {
  setObservers,
  setTagFields,
  setTagPrefilledObject,
  setValueCurrentField,
} from "../../../../Redux/store/form/form.actions"
import RadioGroupRenderComponent from "./RadioGroupRenderComponent/RadioGroupRenderComponent"
import { getOptionsName } from "../SelectComponent/createOptions"
import { setCashState } from "../../../../Redux/store/cash/cash.action"

import { handlerClickButtonPassportDetails } from "../../PostSubmitComponents/PassportDetailsComponent/PassportDetailsComponent"
import { setStatusQuestionary } from "../../../../Redux/store/additionalForm/additional.actions"
import { getClientTokenForResponse } from "../../../../Redux/store/step/service.step.saga"
import { savePrefilled } from "../../../../Redux/store/user/user.actions"

type RadioGroupComponentProps = {
  field: FieldInterface | any,
  value: ValidationItemInterface,
  submitObject: {} | any,
  nameActualInput: string,
  currentField: {} | any,
  appId: string,
  formId: string,
  dispatch: any,
}

// eslint-disable-next-line max-len
const RadioGroupComponent: React.FunctionComponent<RadioGroupComponentProps> = ({
  field,
  value,
  submitObject,
  nameActualInput,
  currentField,
  appId,
  formId,
  dispatch,
}) => {
  const trimOption = (optionName: string, submitAdditionalOption: string | undefined, dispatch: any) => {
    if (optionName && submitAdditionalOption === "last-word__lower-case") {
      // submit for show on front
      dispatch(setValueCurrentField(`${field.name}__full_name`, optionName))
      return optionName.split(" ")[optionName.split(" ").length - 1].toLowerCase()
    } else if (submitAdditionalOption === "first-letter") {
      // submit for show on front
      dispatch(setValueCurrentField(`${field.name}__full_name`, optionName))
      return optionName && optionName.split("")[0]
    } else {
      return optionName
    }
  }

  useEffect(() => {
    const defaultValueWrapper = getOptionsName(field?.defaultValue)
    if (!submitObject[`${field.name}_full_name`] && !submitObject[`${field.name}`] && field.submitAdditionalOption) {
      //if need to make something with option
      const defaultValue = trimOption(defaultValueWrapper.renderValue, field.submitAdditionalOption, dispatch)
      // submit for back
      dispatch(setValueCurrentField(field.name, defaultValue))
    } else if (
      !submitObject[`${field.name}_full_name`] &&
      !submitObject[`${field.name}`] &&
      !field.submitAdditionalOption
    ) {
      dispatch(setValueCurrentField(field.name, defaultValueWrapper.submitValue))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, field])

  // for hard value
  const [render, setRender] = useState(true)
  const [timeId, setTimeId]: any = useState(null)

  useEffect(() => {
    if (field.hard_value && field.hard_value !== submitObject[`${field.name}_full_name`]) {
      const defaultValue = trimOption(field.hard_value, field.submitAdditionalOption, dispatch)
      dispatch(setValueCurrentField(field.name, defaultValue))
      setRender(false)
      setTimeId(setTimeout(() => setRender(true), 0))
      if (field.hard_value) {
        dispatch(setObservers(field.observable, field.hard_value))
      }
    }
    return () => clearTimeout(timeId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    field,
    field?.hard_value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    submitObject[`${field?.name}_full_name`],
  ])

  // for set itemPriceId for delivery
  useEffect(() => {
    if (field.defaultValue && field.name === "data-pricing") {
      const defaultPriority = field.options.find((option: {} | any) => option.price === field.defaultValue)?.itemPriceId
      dispatch(setValueCurrentField(`itemPriceId`, defaultPriority))
    }
  }, [dispatch, field.defaultValue, field.name, field.options])

  const checkSubmitValue = (value: string, options: [] | any) => {
    const chooseOption = options.find(
      (item: any) =>
        (typeof item === "string" && value === item) || (typeof item === "object" && item.optionName === value)
    )
    if (typeof chooseOption === "string") {
      return chooseOption
    } else if (typeof chooseOption === "object") {
      return chooseOption.submitValue
    }
  }

  interface OptionFieldInterface {
    optionName: string;
    submitValue: string;
  }

  const generateAdditionalFields = (value: string, options: OptionFieldInterface[]) => {
    options.find(
      ({ optionName, submitValue }: OptionFieldInterface) =>
        optionName === value &&
        handlerClickButtonPassportDetails(
          true,
          { ...submitObject, form_type: submitValue },
          dispatch,
          {},
          appId,
          formId
        )
    )
  }

  const handlerChangeOptionForm = (value: {} | [] | string | any, field: FieldInterface) => {
    // for generate additional fields(later it will change name on form_type)
    if (field.name === "form_type") {
      generateAdditionalFields(value, field.options)
      dispatch(setStatusQuestionary("completed"))
    }
    if (field.name === "data-pricing") {
      const optionObj = field.options.find((item: any) => item.price === value) || {
        price: field.defaultValue,
        itemPriceId: field.itemPriceId,
      }

      dispatch(setValueCurrentField(field.name, optionObj.price))
      dispatch(setValueCurrentField(`itemPriceId`, optionObj.itemPriceId))
      dispatch(setValueCurrentField(`${field.name}__full_name`, optionObj.price))
      dispatch(
        setCashState({
          ...submitObject,
          [field.name]: optionObj.price,
          [`${field.name}__full_name`]: optionObj.price,
        })
      )
    } else if (field.name.indexOf("billing_address") !== -1 && field.type === "radio") {
      ;((Array.isArray(value) && value) || []).forEach((item: any) =>
        dispatch(setValueCurrentField(item.name, item.value))
      )
    } else {
      const checkedValue = checkSubmitValue(value, field.options)
      dispatch(setValueCurrentField(field.name, trimOption(checkedValue, field.submitAdditionalOption, dispatch)))
      dispatch(setValueCurrentField(`${field.name}__full_name`, value))
      dispatch(
        setCashState({
          ...submitObject,
          [field.name]: checkedValue,
          [`${field.name}__full_name`]: value,
        })
      )
      if (field.tag) {
        dispatch(savePrefilled({ [field.tag]: submitObject[field.name] }))
        sessionStorage.setItem("tagFields", JSON.stringify({ [field?.tag]: trimOption(checkedValue, field.submitAdditionalOption, dispatch) }))
        dispatch(setTagFields({ [field?.tag]: trimOption(checkedValue, field.submitAdditionalOption, dispatch) }))
      }
    }
    //for sent event by option
    if (field.localStorageSave) {
      sessionStorage.setItem(field.localStorageSave, getOptionsName(value).renderValue)
    }
    // if (field?.tag) {
    //   sessionStorage.setItem("tagFields", JSON.stringify({ [field?.tag]: value }))
    //   dispatch(setTagFields({ [field?.tag]: value }))
    // }
  }

  //for sent event by option
  useEffect(() => {
    if (field.localStorageSave) {
      sessionStorage.setItem(
        field.localStorageSave,
        submitObject[`${field.name}__full_name`] || getOptionsName(field.defaultValue).renderValue
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {field?.options?.length !== undefined && render && (
        <RadioGroupRenderComponent
          field={field}
          value={value}
          submitObject={submitObject}
          handlerChangeOptionForm={handlerChangeOptionForm}
        />
      )}
      {field?.options?.length !== undefined && !render && (
        <RadioGroupRenderComponent
          field={field}
          value={value}
          submitObject={submitObject}
          handlerChangeOptionForm={handlerChangeOptionForm}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
  nameActualInput: state.step.nameActualInput,
  currentField: state.form.helper,
  appId: state.form.appId,
  formId: state.form.formId,
})

export default connect(mapStateToProps)(RadioGroupComponent)
