import IEvent from "./event/IEvent";

const GAnalyticEvents = {
    // @ts-ignore
    identify: async (event: IEvent) => {
        const eventOptions = event.getEventOptions();
        if(eventOptions) {
            // @ts-ignore
            await window?.analytics?.identify(event.getUserId(), event.getEventData(), eventOptions)
        } else {
            // @ts-ignore
            await window?.analytics?.identify(event.getUserId(), event.getEventData())
        }
    },
    // @ts-ignore
    page: async (event: IEvent) => await window?.analytics?.page(event.pageName(), event.getEventData()),
    // @ts-ignore
    alias: async (event: IEvent) => await window?.analytics?.alias(event.getPreviousId(), event.getUserId()),
    track: async (event: IEvent) => {
        const eventOptions = event.getEventOptions();
        if(eventOptions) {
            // @ts-ignore
            await window?.analytics?.track(event.getEventName(), event.getEventData(), eventOptions)
        } else {
            // @ts-ignore
            await window?.analytics?.track(event.getEventName(), event.getEventData())
        }
    },
    // @ts-ignore
    reset: async () => await window?.analytics?.reset(),
}

export default GAnalyticEvents;