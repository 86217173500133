import React, {useCallback, useEffect} from "react"
import './PaymentCombinedInformation.scss'
import GText from "../../../atomicDesign/atoms/Text/Text";
import {SIZE} from "../../../atomicDesign/types/types";
import {COLORS_NEW} from "../../../atomicDesign/types/Colors";
import CombinedPaymentForm from "../../../CombinedPaymentForm/CombinedPaymentForm";
import CombinedAbout from "../../../CombinedPaymentForm/CombinedAbout";
import {getPropertiesForEvents, getServiceAndSolutionNameForFormsHeaders} from "../../../../services/gtm/backEndEvent";
import productsGroupImage from "../../../../assets/image/product-group-gradient-carousel.webp";
import productsGroupImageMobile from "../../../../assets/image/product-group-gradient-carousel-mobile.webp";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {ProductsIds} from "../../../../util/ProductsIds";
import ChargePaymentForm from "../../../ChargePaymentForm/ChargePaymentForm";
import {FormInstance} from "antd";

type PaymentCombinedInformationProps = {
  blockObject: { name: string, subscriptionPriceInformation: [], contentSections: [] } | any,
  setSelectedPaymentSubscriptionPlan: any,
  itemPriceCharges?: any,
  form?: FormInstance
}

const PaymentCombinedInformation: React.FunctionComponent<PaymentCombinedInformationProps> = ({
  blockObject,
  setSelectedPaymentSubscriptionPlan,
  itemPriceCharges,
  form
}) => {
  const { service } = getServiceAndSolutionNameForFormsHeaders()
  const {solution} = getPropertiesForEvents()
  const breakPoint = useBreakpoint();

  const subscriptionPriceInformation = blockObject?.subscriptionPriceInformation
  const priceInformation = blockObject?.priceInformation
  const contentSections = blockObject?.contentSections

  if(solution === ProductsIds.TsaId) {
    priceInformation.content.price = 97
    priceInformation.content.savePrice = 127
  }

  const productsGroupImgCarousel = !breakPoint.xs ? productsGroupImage : productsGroupImageMobile;

  return (
    <div className={'PaymentCombinedInformation'}>
      {/*<div className={`ChooseOptionTitle`}>*/}
      {/*  <GText text={'Choose your option'} size={SIZE.PARAGRAPH_BOLD_24} color={COLORS_NEW.ROYAL_BLUE_700}/>*/}
      {/*  <GText text={`${service} only`} size={breakPoint.xs ? SIZE.HEADING_XL : SIZE.HEADING_LG} color={COLORS_NEW.BLACK_1000}/>*/}
      {/*</div>*/}

      {
        itemPriceCharges?.length ?
          <ChargePaymentForm
            charges={itemPriceCharges}
            service={service}
            setSelectedCharge={setSelectedPaymentSubscriptionPlan}
            form={form}
          /> :
          <CombinedPaymentForm
            priceInformation={priceInformation}
            subscriptionPriceInformation={subscriptionPriceInformation}
            setSelectedPlan={setSelectedPaymentSubscriptionPlan}
            service={service}
          >
            <div className={'CombinedPaymentFormAbout'}>
              {/* <div className={'CombinedPaymentFormAbout__Header'}>
              <GText text={'Unlimited access'} size={breakPoint.xs ? SIZE.HEADING_XL : SIZE.HEADING_LG} color={COLORS_NEW.BLACK_1000} />
              <GText text={'to all your prefilled government applications'} size={breakPoint.xs ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_24} color={COLORS_NEW.BLACK_500}/>
              </div>
              <img src={productsGroupImgCarousel} alt={""}/>
              <CombinedAbout contentSections={contentSections} /> */}
            </div>
          </CombinedPaymentForm>
      }
    </div>
  )
}

export default PaymentCombinedInformation
