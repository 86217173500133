import BaseCookiesRepository from "./BaseCookiesRepository";

const UTM_VARS_KEY = "utmVars";

/**
 * @interface UtmFormat
 * @description This interface is responsible for defining the utm variables
 */
interface UtmFormat {
    utm_source: string | null,
    utm_medium: string | null,
    utm_campaign: string | null,
    utm_content: string | null,
    utm_term: string | null
}

/**
 * @class UtmRepository
 * @extends BaseCookiesRepository
 * @description This class is responsible for managing the utm variables in the cookies
 */
class UtmRepository extends BaseCookiesRepository {

    static UTM_DEFAULT_VALUE = "direct";

    /**
     * @private static attribute
     * @static _instance
     * @type {UtmRepository}
     * @memberof UtmRepository
     * @description This is the instance of the class
     */
    private static _instance: UtmRepository;

    /**
     * @constructor
     * @private
     * @memberof UtmRepository
     * @description This is the constructor of the class. Is private because this class is a singleton
     */
    private constructor() {
        super()
    }

    /**
     * @public static method
     * @static  getInstance
     * @returns {UtmRepository}
     * @memberof UtmRepository
     * @description This method is responsible for returning the instance of the class
     */
    public static getInstance(): UtmRepository {
        if (!UtmRepository._instance) {
            UtmRepository._instance = new UtmRepository();
        }
        return UtmRepository._instance;
    }

    /**
     * @public method
     * @method getKeyName
     * @returns {string}
     * @memberof UtmRepository
     * @description This method is responsible for returning the key name of the cookies
     */
    getKeyName(): string {
        return UTM_VARS_KEY;
    }

    /**
     * @public method
     * @method setUtm
     * @param {UtmFormat} obj
     * @memberof UtmRepository
     * @description This method is responsible for setting the utm variables in the cookies
     */
    setUtm(obj: UtmFormat) {
       this.setValue(JSON.stringify(obj));
    }

    /**
     * @public method
     * @method getUtm
     * @returns {UtmFormat}
     * @memberof UtmRepository
     * @description This method is responsible for returning the utm variables in the cookies
     */
    getUtm(): UtmFormat {
        const value = this.getValue();
        return JSON.parse(value);
    }

    getValue(): string {
        const value = super.getValue();
        return  value ?? "{}";
    }
}

export default UtmRepository;
