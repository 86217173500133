import {
    GET_IDENTITIES,
    GET_IDENTITIES_SUCCESS,
    GET_IDENTITIES_FAILURE,
    GET_RECORDS,
    GET_RECORDS_SUCCESS,
    GET_RECORDS_FAILURE,
    GET_ALERTS,
    GET_ALERTS_SUCCESS,
    GET_ALERTS_FAILURE,
    GET_EMAIL_BREACHES,
    GET_EMAIL_BREACHES_SUCCESS,
    GET_EMAIL_BREACHES_FAILURE,
    POST_RECORD,
    POST_RECORD_SUCCESS,
    POST_RECORD_FAILURE,
    DELETE_RECORD,
    DELETE_RECORD_SUCCESS,
    DELETE_RECORD_FAILURE,
    DISMISS_ALERT,
    DISMISS_ALERT_SUCCESS,
    DISMISS_ALERT_FAILURE,
    GET_CREDIT_SCORES,
    GET_CREDIT_SCORES_SUCCESS,
    GET_CREDIT_SCORES_FAILURE,
    VERIFY_USER_CREDIT_SCORES,
    VERIFY_USER_CREDIT_SCORES_SUCCESS,
    VERIFY_USER_CREDIT_SCORES_FAILURE,
    PATCH_USER_BUNDLE,
    PATCH_USER_BUNDLE_SUCCESS,
    PATCH_USER_BUNDLE_FAILURE,
    PATCH_USER_ADDRESS,
    PATCH_USER_ADDRESS_SUCCESS,
    PATCH_USER_ADDRESS_FAILURE,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAILURE,
    SET_MISSING_FIELDS,
    GET_MISSING_FIELDS,
    OPEN_MISSING_FIELDS_MODAL,
    CHECK_VERIFICATION_RESULT,
    CHECK_VERIFICATION_RESULT_SUCCESS,
    CHECK_VERIFICATION_RESULT_FAILURE,
    USER_REDIRECTED_TO_THANK_YOU_PAGE,
    RESET_USER_REDIRECTED_TO_THANK_YOU_PAGE,
    USER_REDIRECTED_TO_THANK_YOU_PAGE_SUCCESS,
    RESET_USER_REDIRECTED_TO_THANK_YOU_PAGE_SUCCESS,
    SET_SCROLL_TARGET
} from "../../constants/ActionTypes";

export const getIdentities = () => {
    return {
        type: GET_IDENTITIES,
    };
}

export const getIdentitiesSuccess = (identities) => {
    return {
        type: GET_IDENTITIES_SUCCESS,
        payload: identities
    };
}

export const getIdentitiesFailure = (error) => {
    return {
        type: GET_IDENTITIES_FAILURE,
        payload: error
    };
}

export const getRecords = (type) => {
    return {
        type: GET_RECORDS,
        payload: type
    };
}

export const getRecordsSuccess = (records) => {
    return {
        type: GET_RECORDS_SUCCESS,
        payload: records
    };
}

export const getRecordsFailure = (error) => {
    return {
        type: GET_RECORDS_FAILURE,
        payload: error
    };
}

export const getAlerts = (type) => {
    return {
        type: GET_ALERTS,
        payload: type
    };
}

export const getAlertsSuccess = (alerts) => {
    return {
        type: GET_ALERTS_SUCCESS,
        payload: alerts
    };
}

export const getAlertsFailure = (error) => {
    return {
        type: GET_ALERTS_FAILURE,
        payload: error
    };
}

export const getEmailBreaches = () => {
    return {
        type: GET_EMAIL_BREACHES,
    };
}

export const getEmailBreachesSuccess = (emailBreaches) => {
    return {
        type: GET_EMAIL_BREACHES_SUCCESS,
        payload: emailBreaches
    };
}

export const getEmailBreachesFailure = (error) => {
    return {
        type: GET_EMAIL_BREACHES_FAILURE,
        payload: error
    };
}

export const postRecord = (record) => {
    return {
        type: POST_RECORD,
        payload: record
    };
}

export const postRecordSuccess = (record) => {
    return {
        type: POST_RECORD_SUCCESS,
        payload: record
    };
}

export const postRecordFailure = (error) => {
    return {
        type: POST_RECORD_FAILURE,
        payload: error
    };
}

export const deleteRecord = (recordId) => {
    return {
        type: DELETE_RECORD,
        payload: recordId
    };
}

export const deleteRecordSuccess = (recordId) => {
    return {
        type: DELETE_RECORD_SUCCESS,
        payload: recordId
    };
}

export const deleteRecordFailure = (error) => {
    return {
        type: DELETE_RECORD_FAILURE,
        payload: error
    };
}

export const dismissAlert = (alertId) => {
    return {
        type: DISMISS_ALERT,
        payload: alertId
    };
}

export const dismissAlertSuccess = (alertId) => {
    return {
        type: DISMISS_ALERT_SUCCESS,
        payload: alertId
    };
}

export const dismissAlertFailure = (error) => {
    return {
        type: DISMISS_ALERT_FAILURE,
        payload: error
    };
}

export const getCreditScores = () => {
    return {
        type: GET_CREDIT_SCORES,
    };
}

export const getCreditScoresSuccess = (scores) => {
    return {
        type: GET_CREDIT_SCORES_SUCCESS,
        payload: scores
    };
}

export const getCreditScoresFailure = (error) => {
    return {
        type: GET_CREDIT_SCORES_FAILURE,
        payload: error
    };
}

export const verifyUserCreditScores = (data) => {
    return {
        type: VERIFY_USER_CREDIT_SCORES,
        payload: data
    };
}

export const verifyUserCreditScoresSuccess = (verificationStatus) => {
    return {
        type: VERIFY_USER_CREDIT_SCORES_SUCCESS,
        payload: verificationStatus
    };
}

export const verifyUserCreditScoresFailure = (error) => {
    return {
        type: VERIFY_USER_CREDIT_SCORES_FAILURE,
        payload: error
    };
}

export const patchUserBundle = (bundleData) => {
    return {
        type: PATCH_USER_BUNDLE,
        payload: bundleData
    };
}

export const patchUserBundleSuccess = (updatedBundle) => {
    return {
        type: PATCH_USER_BUNDLE_SUCCESS,
        payload: updatedBundle
    };
}

export const patchUserBundleFailure = (error) => {
    return {
        type: PATCH_USER_BUNDLE_FAILURE,
        payload: error
    };
}

export const patchUserAddress = (addressData) => {
    return {
        type: PATCH_USER_ADDRESS,
        payload: addressData
    };
}

export const patchUserAddressSuccess = (updatedAddress) => {
    return {
        type: PATCH_USER_ADDRESS_SUCCESS,
        payload: updatedAddress
    };
}

export const patchUserAddressFailure = (error) => {
    return {
        type: PATCH_USER_ADDRESS_FAILURE,
        payload: error
    };
}

export const updateCustomer = (customerData) => {
    return {
        type: UPDATE_CUSTOMER,
        payload: customerData
    };
}

export const updateCustomerSuccess = (updatedCustomer) => {
    return {
        type: UPDATE_CUSTOMER_SUCCESS,
        payload: updatedCustomer
    };
}

export const updateCustomerFailure = (error) => {
    return {
        type: UPDATE_CUSTOMER_FAILURE,
        payload: error
    };
}

export const setMissingFields = (missingFields) => {
    return {
        type: SET_MISSING_FIELDS,
        payload: missingFields,
    }
};

export const getMissingFields = (missingFields) => {
    return {
        type: GET_MISSING_FIELDS,
        payload: missingFields
    }
}

export const openMissingFieldsModal = () => {
    return {
        type: OPEN_MISSING_FIELDS_MODAL
    }
}

export const checkVerificationStatus = () => {
    return {
        type: CHECK_VERIFICATION_RESULT,
    }
}

export const checkVerificationStatusSuccess = (verificationStatus) => {
    return {
        type: CHECK_VERIFICATION_RESULT_SUCCESS,
        payload: verificationStatus
    };
}

export const checkVerificationStatusFailure = (error) => {
    return {
        type: CHECK_VERIFICATION_RESULT_FAILURE,
        payload: error
    };
}

export const userRedirectedToThankYouPage = () => ({
    type: USER_REDIRECTED_TO_THANK_YOU_PAGE,
});

export const userRedirectedToThankYouPageSuccess = () => ({
    type: USER_REDIRECTED_TO_THANK_YOU_PAGE_SUCCESS,
});

export const resetUserRedirectedToThankYouPage = () => ({
    type: RESET_USER_REDIRECTED_TO_THANK_YOU_PAGE,
});

export const resetUserRedirectedToThankYouPageSuccess = () => ({
    type: RESET_USER_REDIRECTED_TO_THANK_YOU_PAGE_SUCCESS,
});

export const setScrollTarget = (target) => ({
    type: SET_SCROLL_TARGET,
    payload: target
  });