import React, {useEffect} from "react"
import {connect, useSelector} from "react-redux"
import {
    launchTaxAssistanceAction,
    setStatusTSADetailsToOpen,
} from "../../../../Redux/store/additionalForm/additional.actions"
import yesIcon from "../../../../assets/image/bigYes.svg"
import {RootState} from "../../../../App"
import {additionalSelector} from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import {delayTimer} from "../../../../Pages/FormPage/FormPage"
import {getScrollElementById} from "../../FormBlock/FormBlock"
import GAdditionalStep, {StepVisualStatus} from "../../../atomicDesign/templates/AdditionalStep/AdditionalStep"
import GAdditionalStepUpsell from "../../../atomicDesign/templates/AdditionalStep/AdditionalStepUpsell";
import GButton, {BUTTON_TYPE} from "../../../atomicDesign/molecules/Button/GButton";
import {SIZE} from "../../../atomicDesign/types/types";
import {LoadingState} from "../../../../shared/constans/user-from-view-mode.enum";
import StepTaxProcessing from "../../../atomicDesign/templates/AdditionalStep/StepTaxProcessing/StepTaxProcessing";
import {ServicesNames} from "../../../../util/ServicesNames";
import {TaxConstant} from "../../../../util/TaxConstant";
import {Path} from "../../../../util/PageHelper";
import StepTaxPendingTracking
    from "../../../atomicDesign/templates/AdditionalStep/StepTaxPendingTracking/StepTaxPendingTracking";
import StepTaxTracking from "../../../atomicDesign/templates/AdditionalStep/StepTaxTracking/StepTaxTracking";
import {getIdNameFromStatuses} from "../../../../util/BlockStep";
import { BlockStepTypes } from "../../../../Constants/BlockStepTypes"

type BlocksStepsTaxProps = {
    blocksStep: any,
    index: number,
    showStatus: string,
    statusDetailsToOpen: string,
    submitObject: {} | any,
    loadingButtonState: string,
    formId: string,
    dispatch: any,
    upsellProcessingInformation: any
}

const BlocksStepsTax: React.FunctionComponent<BlocksStepsTaxProps> = ({
                                                                          blocksStep,
                                                                          index,
                                                                          showStatus,
                                                                          statusDetailsToOpen,
                                                                          submitObject,
                                                                          loadingButtonState,
                                                                          formId,
                                                                          dispatch,
                                                                          upsellProcessingInformation
                                                                      }) => {
    const uniqSession = submitObject["unique-section-id"]
    const handlerDetailsToOpen = (status: string) => {
        dispatch(setStatusTSADetailsToOpen(status))
    }

    const {statusSignaturePage} = useSelector<RootState, { statusSignaturePage: string } | undefined>(
        additionalSelector
    ) ?? {statusSignaturePage: ""}

    useEffect(() => {
        delayTimer(300).then(() => getScrollElementById({id: `${statusSignaturePage}_scrollById`}))
    }, [statusSignaturePage])

    const launchTaxAssistance = () => {
        dispatch(launchTaxAssistanceAction())
    }

    const openIRSRefundPage = () => {
        window.open(Path.IRS_REFUND_PAGE, '_blank');
    }

    let idNameFromStatuses = getIdNameFromStatuses(blocksStep.status)

    return (
        <div className={`steps-signature-container`} id={`${idNameFromStatuses}${index !== 1 ? "_scrollById" : ""}`}>
            {blocksStep.type === BlockStepTypes.CONTENT && (
                <div className={`status_complete`}>
                    <div className={`icon-container_complete`}>
                        <img src={yesIcon} alt="approve"/>
                    </div>
                    <div className={`div-bg`}>
                        <p>{blocksStep?.content?.complete}</p>
                    </div>
                </div>
            )}

            {blocksStep.type === BlockStepTypes.UPSELL && upsellProcessingInformation && upsellProcessingInformation[blocksStep.status] && (
                <GAdditionalStepUpsell
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    formId={formId}
                    uniqSession={uniqSession}
                    blockStatus={blocksStep.status}
                />
            )}

            {blocksStep.type === BlockStepTypes.PENDING && (
                <GAdditionalStep
                    index={index.toString()}
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusDetailsToOpen}
                >
                    <GButton
                        textSize={SIZE.PARAGRAPH_BOLD_16}
                        text={blocksStep.content.button}
                        isSubmit={false}
                        showIcon={false}
                        disabled={false}
                        loading={loadingButtonState === LoadingState.Loading}
                        click={launchTaxAssistance}
                    />
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.IN_PROGRESS && (
                <GAdditionalStep
                    index={index.toString()}
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusDetailsToOpen}
                >
                    <StepTaxProcessing
                        title={ServicesNames.TAXES_WITHOUT_YEAR}
                        progress={75}
                        progressText={TaxConstant.IN_PROGRESS_TEXT}
                        descriptionText={TaxConstant.DESCRIPTION_TEXT}
                        ctaText={TaxConstant.CTA_PROCESSING_TEXT}
                        ctaDisabled={loadingButtonState === LoadingState.Loading}
                        ctaLoading={loadingButtonState === LoadingState.Loading}
                        ctaOnClick={launchTaxAssistance}
                    />
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.PROCESSING && (
                <StepTaxPendingTracking
                    index={index.toString()}
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusDetailsToOpen}
                    ctaDisabled={loadingButtonState === LoadingState.Loading}
                    ctaLoading={loadingButtonState === LoadingState.Loading}
                    failContent={blocksStep.content.titleFail}
                    launchTaxAssistance={launchTaxAssistance}
                />
            )}

            {blocksStep.type === BlockStepTypes.TRACKING && (
                <GAdditionalStep
                    index={index.toString()}
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusDetailsToOpen}
                >
                    <StepTaxTracking
                        ctaOnClick={openIRSRefundPage}
                        ctaMoreDetailClick={launchTaxAssistance}
                        ctaMoreDetailLoading={loadingButtonState === LoadingState.Loading}
                        ctaMoreDetailDisabled={loadingButtonState === LoadingState.Loading}
                    />
                </GAdditionalStep>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    statusDetailsToOpen: state.additional.statusDetailsToOpen,
    submitObject: state.form.submitObject,
    loadingButtonState: state.loading.loadingButtonState,
    formId: state.form.formId,
    upsellProcessingInformation: state.additional.upsellProcessingInformation
})

export default connect(mapStateToProps)(BlocksStepsTax)
