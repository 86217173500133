import { ItemInterface } from "../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import UploadPhotoContainerComponent from "../UploadPhoto/UploadPhotoContainerComponent/UploadPhotoContainerComponent"
import React from "react"
import { Button } from "antd"

export const questionnaireTSABlock = ({
  index,
  blocksStep,
  statusPhotoUpload,
}: {
  index?: number,
  blocksStep?: ItemInterface,
  statusPhotoUpload: string,
}) => (
  <>
    <div className={`icon-container_wait ${statusPhotoUpload}`}>
      <p className={`icon-number`}>{index}</p>
    </div>
    {blocksStep && (
      <>
        {!statusPhotoUpload && (
          <div className={`div-bg`}>
            {/*<p className={`signature_complete`}>{blocksStep?.disableText}</p>*/}
            <Button type="primary" className={""} disabled={true}>
              <span className="">{blocksStep?.disableText}</span>
            </Button>
          </div>
        )}
        {statusPhotoUpload === "" && <UploadPhotoContainerComponent item={blocksStep} />}
      </>
    )}
  </>
)
