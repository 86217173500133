import DuplicateApplicationModal from "../components/CustomModal/DuplicateApplicationModal/DuplicateApplicationModal";
import React from "react";
import {useSelector} from "react-redux";
import InsuranceDocuments from "../components/CustomModal/InsuranceDocuments";

const ModalSwitcher = ({modalType}) => {
  const {selectedFormId, duplicateApplications, userSelectedFormId} = useSelector(({allProducts}) => allProducts);
  const switcher = () => {
    switch (modalType) {
      case 'duplicateApplication':
        return (
          <DuplicateApplicationModal productInfo={duplicateApplications?.[selectedFormId || userSelectedFormId] }/>
        )
      case 'insuranceDocumentForm':
        return (
          <InsuranceDocuments/>
        )
      default:
        return null;
    }
  }

  return (
    <>
      {switcher()}
    </>
  )
}

export default ModalSwitcher;
