import React, {FC} from "react"
import {StripePaymentButtonProps} from "./StripePaymentButtonProps";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import StripePaymentButtonForm from "./StripePaymentButtonForm";
import { env } from "../../../../env";

const stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLIC_KEY ?? '');

const StripePaymentButton: FC<StripePaymentButtonProps> = ({onTokenCreated, priceInfo, marginButton, submitObject}) => {
    return (
        <Elements stripe={stripePromise}>
            <StripePaymentButtonForm marginButton={marginButton} priceInfo={priceInfo}
                                     onTokenCreated={onTokenCreated} submitObject={submitObject}></StripePaymentButtonForm>
        </Elements>
    )
}

export default StripePaymentButton