import { Dispatch } from "../SelectComponent/useDefaultOption"
import { getValidations } from "../InputComponent/validation"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { ValidationItemInterface } from "../../../../Redux/InterfacesEntity/validation.interface"
import { Dispatch as DispatchRedux } from "redux"
import { setObservers, setTagFields } from "../../../../Redux/store/form/form.actions"
import { savePrefilled } from "../../../../Redux/store/user/user.actions"
import { getHandleValidationByRule } from "../InputComponent/NestRenderInputComponent/NestRenderInputComponent"

interface HandlerOnChangeAutofillInterface {
  e: any
  setValue: Dispatch<string>;
  value: string;
  dispatchObserver: (a: string) => void;
  MAX_HIDE_AUTOFILL_LENGTH: number;
  setFieldInStore: (a: string, b: string) => void;
  nameField: string;
}

export const MAX_HIDE_AUTOFILL_LENGTH = 12

export const handlerOnChangeAutofill = ({
  e,
  setValue,
  value,
  dispatchObserver,
  MAX_HIDE_AUTOFILL_LENGTH,
  setFieldInStore,
  nameField,
}: HandlerOnChangeAutofillInterface) => {
  // console.log("e", e?.nativeEvent?.inputType === "deleteContentBackward")
  const statusValidation = getHandleValidationByRule({ pattern: "^[a-zA-Z0-9 &.-]{1,80}$|^$" }, value) && e?.nativeEvent?.inputType !== "deleteContentBackward"
  const statusDeleteContentBackward = e?.nativeEvent?.inputType === "deleteContentBackward"
  if ((value && statusValidation) || statusDeleteContentBackward) {
    setValue(value)
    setFieldInStore(nameField, value)
    if (value.length > MAX_HIDE_AUTOFILL_LENGTH) {
      dispatchObserver("Yes")
    }
  }
}

interface RulesValidationInterface {
  field: FieldInterface;
  submitObject: { [key: string]: any };
  validationRule: ValidationItemInterface;
  value: string;
}

export const rulesValue = ({ field, submitObject, validationRule, value }: RulesValidationInterface) => {
  if (validationRule && validationRule.pattern) {
    return [
      {
        validator: () => {
          // eslint-disable-next-line max-len
          return getValidations(value, field, validationRule.pattern, true)
        },
      },
    ]
  } else {
    return undefined
  }
}

interface ValueStateBirthStateInterface {
  value: string
}

interface HandlerOnBlurAutofillInterface {
  setStatusFocus: Dispatch<boolean>;
  value: string;
  dispatch: DispatchRedux;
  field: FieldInterface;
  valueStateBirthState?: ValueStateBirthStateInterface
}

interface ManageCityBirthTypeInterface {
  valueStateBirth: string | undefined;
  dispatch: DispatchRedux;
  field: FieldInterface
}

export const manageCityBirthType = ({valueStateBirth, dispatch, field}: ManageCityBirthTypeInterface) => {
  if(!valueStateBirth) {
    dispatch(setObservers(field.observable_autofill_state, "Not USA"))
  }
}

export const handlerOnBlurAutofill = ({ setStatusFocus, value, dispatch, field, valueStateBirthState }: HandlerOnBlurAutofillInterface) => {
  const {value: valueStateBirth} = valueStateBirthState ?? {}
  if(value && valueStateBirthState) {
    manageCityBirthType({valueStateBirth, dispatch, field})
  }
  
  if (value && getHandleValidationByRule({ pattern: "^[a-zA-Z0-9 &.-]{1,80}$|^$" }, value)) {
    setStatusFocus(true)
    dispatch(setObservers(field.observable, "Yes"))
    dispatch(savePrefilled({ [field?.tag]: value }))
    dispatch(setTagFields({ [field?.tag]: value }))
    return
  }
  setStatusFocus(false)
}
