import {createContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {userService} from "../api/api";

interface IUserContext {
    authToken: string,
    userFlattenData: any,
    getUserFlattenData: (callback? : (data: any) => void) => void,
    userSubmittedData: any,
    getUserSubmittedData: (callback? : (data: any) => void) => void,
    isUserLoggedIn: () => boolean
}

// @ts-ignore
export const UserContext = createContext<IUserContext>()

// @ts-ignore
export const UserProvider = ({ children }) => {
    // @ts-ignore
    const {authToken, userData} = useSelector(({auth}) => auth);
    // @ts-ignore
    const {pathname} = useSelector(({router}) => router.location);
    const [userFlattenData, setUserFlattenData] = useState()
    const [userSubmittedData, setUserSubmittedData] = useState()

    const getUserFlattenData = (callback? : (data: any) => void) => {
        if (authToken) {
            userService.userFlatternData().then(response => {
                setUserFlattenData(response.data.data)
                callback?.(response.data.data)
            }).catch(error => {
                callback?.(false)
                console.log(error)
            })
        }
    }

    const getUserSubmittedData = (callback? : (data: any) => void) => {
        if (authToken) {
            userService.getSubmittedDashUser(authToken).then(response => {
                setUserSubmittedData(response.data)
                callback?.(response.data)
            }).catch(error => {
                callback?.(false)
                console.log(error)
            })
        }
    }

    const isUserLoggedIn = () => !!localStorage.getItem("userSignedIn")

    // Get user's data on the dashboard
    useEffect(getUserFlattenData, [authToken, pathname])

    // Get user's data that has been submitted through a form
    useEffect(getUserSubmittedData, [authToken, pathname])

    const returnValue = {
        authToken,
        userFlattenData,
        userSubmittedData,
        getUserFlattenData,
        getUserSubmittedData,
        isUserLoggedIn,
    }

    return <UserContext.Provider value={returnValue}>
        {children}
    </UserContext.Provider>
}