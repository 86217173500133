import {CardNumberProps} from "./CardNumberProps";
import React, {FC} from "react";
import {
    CreditCardFormFields,
    LabelsColor,
    LabelsSize
} from "../../templates/CreditCardForm/CreditCardFormFields";
import GText from "../../atoms/Text/Text";
import GInputText from "../InputText/InputText";
import GFormItem from "../../organisms/FormItem/FormItem";
import {Rule} from "rc-field-form/lib/interface";
import InputMask from "react-input-mask";
import "./CardNumber.scss"
import masterCard from "../../../../assets/image/credit-card-mastercard.jpg"
import visa from "../../../../assets/image/card-visa.svg"
import amex from "../../../../assets/image/card-amex.svg"
import discover from "../../../../assets/image/card-discovery.svg"
import jcbCard from "../../../../assets/image/card-jcb.svg"
import dinerClubCard from "../../../../assets/image/card-dinerclub.svg"


const GCardNumber: FC<CardNumberProps> = () => {
    const validationRules: Rule[] = [{required: true, message: 'Required'}]
    const arrayIcons = [visa, amex, jcbCard, dinerClubCard, masterCard, discover]
    return (
        <div className={'GCardNumber'}>
            <GFormItem
                name={CreditCardFormFields.CardNumber}
                label={<GText text={'Card number'} size={LabelsSize}
                              color={LabelsColor}/>}
                rules={validationRules}>
                <InputMask
                    mask={"9999999999999999"}
                    maskPlaceholder={null}
                >
                    <GInputText inputMode={'numeric'} maskedForPrivacy />
                </InputMask>
            </GFormItem>
            <div className={`form-item__icon-container`}>
                {arrayIcons.map((item: string) => <img key={item} src={item} alt={item}/>)}
            </div>
        </div>

    )
}
export default GCardNumber;