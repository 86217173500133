import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

const AUTH_USER_BEARER_TOKEN = "authUserBearerToken"

class AuthUserBearerTokenRepository extends BaseSessionStorageRepository {
    private static _instance: AuthUserBearerTokenRepository;

    private constructor() {
        super();
    }

    public static getInstance():AuthUserBearerTokenRepository {
        if (!AuthUserBearerTokenRepository._instance) {
            AuthUserBearerTokenRepository._instance = new AuthUserBearerTokenRepository();
        }
        return AuthUserBearerTokenRepository._instance;
    }

    getKeyName(): string {
        return AUTH_USER_BEARER_TOKEN;
    }
}

export default AuthUserBearerTokenRepository;
