import React from "react"

interface SgvUsersProps {
    width?: number;
    height?: number;
    color?: string;
    backgroundColor?: string;
}

export const SvgIconUsers = ({width=22, height=22, color = "#13BA68", backgroundColor = "white"}: SgvUsersProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1251_40510)">
            <path d="M15.6016 18.5742V16.8242C15.6016 15.896 15.2328 15.0057 14.5764 14.3493C13.9201 13.693 13.0298 13.3242 12.1016 13.3242H5.10156C4.1733 13.3242 3.28307 13.693 2.62669 14.3493C1.97031 15.0057 1.60156 15.896 1.60156 16.8242V18.5742" stroke={color} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.60156 9.82422C10.5346 9.82422 12.1016 8.25722 12.1016 6.32422C12.1016 4.39122 10.5346 2.82422 8.60156 2.82422C6.66857 2.82422 5.10156 4.39122 5.10156 6.32422C5.10156 8.25722 6.66857 9.82422 8.60156 9.82422Z" stroke={color} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.8516 18.5757V16.8257C20.851 16.0502 20.5929 15.2969 20.1178 14.684C19.6426 14.0711 18.9774 13.6333 18.2266 13.4395" stroke={color} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.7266 2.93945C15.4794 3.13222 16.1467 3.57007 16.6232 4.18397C17.0998 4.79788 17.3584 5.55293 17.3584 6.33008C17.3584 7.10723 17.0998 7.86227 16.6232 8.47618C16.1467 9.09009 15.4794 9.52794 14.7266 9.7207" stroke={color} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_1251_40510">
                <rect width={width-1} height={height-1} fill={backgroundColor} transform="translate(0.726562 0.199219)"/>
            </clipPath>
            </defs>
        </svg>

    )
}
