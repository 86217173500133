import React from 'react';
import './index.less';
import {inlineOfferComponentStyle} from "./inlineOfferComponentStyle";
import {Card} from "antd";
import {IconSvg} from "../../../components/svgImages/SvgImages";

const OfferCard = ({title, text, prevPrice, currentPrice, youSave, fillingTime, btnText, offerImage}) => {
  return (
    <Card
      title={<span className="service-title">{title}</span>}
      headStyle={inlineOfferComponentStyle.headStyle}
      bodyStyle={inlineOfferComponentStyle.bodyStyle}
      className={"offerCard"}
      // key={id}
    >
      <div className={'offerCard_image-container'}>
        {offerImage}
      </div>
      <div className={'offerCard_info-container'}>
        <p className='offerCard_info-container_text'>{text}</p>
        <p className="offerCard_info-container_prevPrice-text">${prevPrice}</p>
        <p className="offerCard_info-container_currentPrice-text">${currentPrice}</p>
        <p className="offerCard_info-container_youSave-text">{youSave}</p>
      </div>

      <button className={"offerCard_btnStyle"}>
        {btnText}
        <span className={"offerCard_btnStyle_btnArrow"}>
          <IconSvg type={"leftSideArrowIcon"}/>
        </span>
      </button>
      {fillingTime && <span className='filing-text'>Filing time: {fillingTime} min</span>}
    </Card>
  )
}

export default OfferCard;
