import React, { useEffect } from "react"
import { switchType } from "../../../SwitchBlock/switchType"
import "./AddressVerificationFormSelect.scss"
import GText from "../../../../atomicDesign/atoms/Text/Text"
import { SIZE } from "../../../../atomicDesign/types/types"
import { COLORS } from "../../../../atomicDesign/types/Colors"

type AddressVerificationFormSelectProps = {
  addressContent: {} | any,
}

const AddressVerificationFormSelect: React.FunctionComponent<AddressVerificationFormSelectProps> = ({
  addressContent,
}) => {
  useEffect(() => {
    /* Event Processing Step */
  }, [])

  const addressForm = addressContent.form.formSelect

  return (
    <div className={`AddressVerificationFormSelect`}>
      <div className={`AddressVerificationFormSelect__Form`}>
        {addressForm.fields.map((item: any, key: number) => {
          return (
            <div className={`AddressVerificationFormSelect__Form__Item`} key={key}>
              {switchType(item.type, item, item.name, false)}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AddressVerificationFormSelect
