import React, { FC } from "react"
import "./CollapseTop.scss"
import TabRoundedNotification from "../TabRoundedNotification/TabRoundedNotification"
import { COLORS_NEW } from "../../types/Colors"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { CollapseTopPartProps } from "./CollapseTop.props"
import useIsMobile from "../../../../../../dashboard-govplus-front/src/hooks/useIsMobile"

const CollapseTopPart: FC<CollapseTopPartProps> = ({
  bold,
  topLeftMessage,
  bottomLeftMessage,
  centerMessage,
  timestamp,
  showIcon,
  click,
  style,
}) => {
  const isMobile = useIsMobile(900)

  if (isMobile) {
    return (
      <div className={"CollapseTopPart"} onClick={click}>
        <div className={"CollapseTopPart__Top"}>
          <div className={"CollapseTopPart__Left"}>
            {showIcon && (
              <TabRoundedNotification
                style={{
                  background: bold ? COLORS_NEW.ROYAL_BLUE_700 : COLORS_NEW.BLACK_200,
                  maxWidth: "11px",
                  width: "100%",
                  height: "11px",
                }}
              />
            )}
            <div className={"CollapseTopPart__Left__Labels"}>
              {topLeftMessage && (
                <GText
                  text={topLeftMessage}
                  size={SIZE.PARAGRAPH_BOLD_16}
                  color={bold ? COLORS_NEW.BLACK_1000 : COLORS_NEW.BLACK_400}
                />
              )}
              {bottomLeftMessage && (
                <GText text={bottomLeftMessage} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_400} />
              )}
            </div>
          </div>
          {timestamp && (
            <div className={"CollapseTopPart__Right"}>
              <GText text={timestamp} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_600} />
            </div>
          )}
        </div>
        <div className={"CollapseTopPart__Center"}>
          {centerMessage && (
            <GText text={centerMessage} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_600} />
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={"CollapseTopPart"} onClick={click}>
      <div className={"CollapseTopPart__Left"}>
        {showIcon && (
          <TabRoundedNotification
            style={{
              background: bold ? COLORS_NEW.ROYAL_BLUE_700 : COLORS_NEW.BLACK_200,
              maxWidth: "11px",
              width: "100%",
              height: "11px",
            }}
          />
        )}
        <div className={"CollapseTopPart__Left__Labels"}>
          {topLeftMessage && (
            <GText
              text={topLeftMessage}
              size={SIZE.PARAGRAPH_BOLD_16}
              color={bold ? COLORS_NEW.BLACK_1000 : COLORS_NEW.BLACK_400}
            />
          )}
          {bottomLeftMessage && (
            <GText text={bottomLeftMessage} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_400} />
          )}
        </div>
      </div>
      <div className={"CollapseTopPart__Center"}>
        {centerMessage && <GText text={centerMessage} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_600} />}
      </div>
      {timestamp && (
        <div className={"CollapseTopPart__Right"} style={style}>
          <GText text={timestamp} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_600} />
        </div>
      )}
    </div>
  )
}

export default CollapseTopPart
