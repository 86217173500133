import React from "react";
import GSubscribeWindow
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/organisms/SubscribeWindow/GSubscribeWindow";
import {
    GSubscriptionMockStaticTexts
} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/organisms/SubscribeWindow/GSubscriptionMockStaticTexts";
import GBillingSettings
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/BillingSettings/GBillingSettings";
import {
    GBillingSettingsMockStaticTexts
} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/BillingSettings/GBillingSettingsMockStaticTexts";
import GCancellationReason
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/organisms/CancellationReason/GCancellationReason";
import {
    GCancelReasonMockStaticTexts
} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/organisms/CancellationReason/GCancelReasonMockStaticTexts";
import GCancelSubscription
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/organisms/CancelSubscription/GCancelSubscription";
import {
    GCancelSubscriptionMockStaticTexts
} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/organisms/CancelSubscription/GCancelSubscriptionMockStaticTexts";
import GCanceledSubscription
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/CanceledSubscription/GCanceledSubscription";
import {
    GCancelledSubscriptionMockStaticTexts
} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/CanceledSubscription/GCancelledSubscriptionMockStaticTexts";

import MyAutomaticRenewals
    from "@dash/gov-plus__front-end__form/src/Pages/Renewal/MyAutomaticRenewal/MyAutomaticRenewals";

import FamilyMembersTeaser
    from "@dash/gov-plus__front-end__form/src/Pages/FamilyMembers/FamilyMembersTeaser/FamilyMembersTeaser";

import MyFamily
    from "@dash/gov-plus__front-end__form/src/Pages/FamilyMembers/MyFamily/MyFamily";

import FamilyMembersApplications
    from "@dash/gov-plus__front-end__form/src/Pages/FamilyMembers/FamilyMemberApplications/FamilyMembersApplications";

const TestPageForCheckDesign = () => {
    const componentMargin = {
        marginTop: "40px",
        backgroundColor: "rgb(24, 94, 200)",
        color: "white",
        padding: "10px"
    }

    return (
        <div>
            {/*<h2 style={componentMargin}>Activate renewal modal window </h2>*/}
            {/*<ActivateRenewalModal*/}
            {/*    isOpen={true}*/}
            {/*    onClose={()=>{*/}
            {/*        alert('Closed')*/}
            {/*    }}*/}
            {/*    onSubmit={()=>{*/}
            {/*        alert('Submitted')*/}
            {/*    }}*/}
            {/*    service={"GovPlus Service"}*/}
            {/*/>*/}

            <h2 style={componentMargin}>My family Applications</h2>
            <FamilyMembersApplications />

            <h2 style={componentMargin}>My family</h2>
            <MyFamily />

            <h2 style={componentMargin}>Family members teaser</h2>
            <FamilyMembersTeaser />

            <h2 style={componentMargin}>My Automatic Renewal</h2>
            <MyAutomaticRenewals />

            <h2 style={componentMargin}>Cancelled Subscription</h2>
            <GCanceledSubscription
                title={GCancelledSubscriptionMockStaticTexts.DEFAULT_TITLE}
                description={GCancelledSubscriptionMockStaticTexts.DEFAULT_DESCRIPTION.replaceAll("{date}", GCancelledSubscriptionMockStaticTexts.DEFAULT_DATE)}
            />

            <h2 style={componentMargin}>Cancellation Reason</h2>
            <GCancellationReason
                title={GCancelReasonMockStaticTexts.DEFAULT_CANCEL_REASON_TITLE}
                subTitle={GCancelReasonMockStaticTexts.DEFAULT_CANCEL_REASON_SUBTITLE}
                formTitle={GCancelReasonMockStaticTexts.DEFAULT_CANCEL_FORM_TITLE}
                ctaButtonText={GCancelReasonMockStaticTexts.DEFAULT_CTA_BUTTON_TEXT}
            />

            <h2 style={componentMargin}>Cancel Subscription</h2>
            <GCancelSubscription
                title={GCancelSubscriptionMockStaticTexts.DEFAULT_CANCEL_SUBSCRIPTION_TITLE.replaceAll("{firstname}", "John")}
                subTitle={GCancelSubscriptionMockStaticTexts.DEFAULT_CANCEL_SUBSCRIPTION_SUBTITLE}
                pauseSubscriptionCard={GCancelSubscriptionMockStaticTexts.DEFAULT_PAUSE_SUBSCRIPTION_CARD}
                cancelSubscriptionCard={GCancelSubscriptionMockStaticTexts.DEFAULT_CANCEL_SUBSCRIPTION_CARD}
            />

            <h2 style={componentMargin}>Billing settings</h2>
            <GBillingSettings
                title={GBillingSettingsMockStaticTexts.DEFAULT_TITLE}
                planBlockTitle={GBillingSettingsMockStaticTexts.DEFAULT_PLAN_BLOCK_TITLE}
                planBlockData={GBillingSettingsMockStaticTexts.DEFAULT_PLANS}
                paymentMethods={GBillingSettingsMockStaticTexts.DEFAULT_PAYMENTS_METHODS}
                defaultPaymentMethod={GBillingSettingsMockStaticTexts.DEFAULT_PAYMENTS_METHODS[0].id}
                transactionsHistory={GBillingSettingsMockStaticTexts.DEFAULT_TRANSACTIONS_HISTORY}
            />

            <h2 style={componentMargin}>Subscribe modal window</h2>
            <GSubscribeWindow
                logoText={GSubscriptionMockStaticTexts.DEFAULT_LOGO_TEXT}
                title={GSubscriptionMockStaticTexts.DEFAULT_TITLE}
                subTitle={GSubscriptionMockStaticTexts.DEFAULT_SUB_TITLE}
                benefitsList={GSubscriptionMockStaticTexts.DEFAULT_CAPABILITIES_LIST}
                userCardList={GSubscriptionMockStaticTexts.DEFAULT_CARD_LIST}
                plan={GSubscriptionMockStaticTexts.DEFAULT_SUBSCRIPTION_PLAN}
                tag={GSubscriptionMockStaticTexts.DEFAULT_SUBSCRIPTION_PLAN_TAG}
                ctaText={GSubscriptionMockStaticTexts.DEFAULT_CTA_TEXT}
            />
        </div>
    )
}

export default TestPageForCheckDesign;
