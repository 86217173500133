import React from "react"
import { connect } from "react-redux"
import { Button } from "antd"

type ButtonToggleStepProps = {
  disabled?: boolean,
  handleDecrementClick?: any,
  handleIncrementClick?: any,
  iconTemplate: string,
  buttonNext?: string | boolean | undefined,
  buttonPrev?: string | undefined,
  nameStep: string,
  indexCurrentStep: number,
  children: any,
  dispatch: any,
}

const ButtonToggleStep: React.FunctionComponent<ButtonToggleStepProps> = ({
  disabled,
  handleDecrementClick,
  handleIncrementClick,
  iconTemplate,
  buttonNext,
  buttonPrev,
  nameStep,
  indexCurrentStep,
  children,
  dispatch,
}) => {
  const correctInformationPage = nameStep === "correction_information"
  const arrayNamePrev = ["cancel", "Back home", "Previous"]

  const getNamePrev = typeof buttonPrev === "string" && arrayNamePrev.includes(buttonPrev)

  const visibility =
    disabled || (buttonNext && !buttonPrev && !correctInformationPage) ? "visibility-hidden" : "visibility-visible"

  const namePrev = getNamePrev ? "Previous" : children.props.children

  return (
    <div className={`button-toggle-step__container`}>
      <Button
        // eslint-disable-next-line max-len
        className={`button-toggle-step__${namePrev} ${visibility}`}
        type={"primary"}
        onClick={handleDecrementClick ? handleDecrementClick : handleIncrementClick}
        disabled={disabled}
      >
        {iconTemplate === "arrow-prev" && <span className={iconTemplate}></span>}
        {children}
      </Button>
    </div>
  )
}

const mapStateToProps = (state: any) => ({})

export default connect(mapStateToProps)(ButtonToggleStep)
