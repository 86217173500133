import TemplateOne from "../TemplateOne";
import {IUpsellProcessingData} from "../../../StepUpsell";
import TemplateOneBuilder from "../TemplateOneBuilder";
import {UPSELL_PROPS} from "../../UpsellProps";
import {ITemplateOneBuilder} from "./ITemplateOneBuilder";
import {ExpeditedShippingUpsell} from "./ExpeditedShippingUpsell";
import {DocumentInsuranceUpgrade} from "./DocumentInsuranceUpgrade";
import {AutomaticRenewalUpgrade} from "./AutomaticRenewalUpgrade";
import {CompletedApplicationCrossSell} from "./CompletedApplicationCrossSell";
import {MemberFamilyUpgrade} from "./MemberFamilyUpgrade";
import {OfferType} from "../../../../../../types/types";
import {getSolutionNameToLowerCase} from "../../../../../../../../util/SolutionNames";
import {PaymentModelTypes} from "../../../../../../../../../../dashboard-govplus-front/src/constants/PaymentModelTypes";
import {ThirdPartyUpsell} from "./ThirdPartyUpsell";
import {ExpeditedProcessingUpsell} from "./ExpeditedProcessingUpsell";
import { ThirdPartyUpsellVenture } from "./ThirdPartyUpsellVenture";
import {AuditProtectionUpsell} from "./AuditProtectionUpsell";
import {ExpressProcessingUpsell} from "./ExpressProcessingUpsell";
import { CrossSellSubscription } from "./CrossSellSubscription";

export enum OffersPageNames {
    EXPEDITED_SHIPPING = "EXPEDITED_SHIPPING",
    DOCUMENT_INSURANCE = "GOVPLUS_DOCUMENT_INSURANCE",
    AUTOMATIC_RENEWAL = "GOVPLUS_AUTOMATIC_RENEWAL",
    FAMILY_UPGRADE = "GOVPLUS_FAMILY-UPGRADE",
    THIRD_PARTY_UPSELL = "GOVPLUS_THIRD_PARTY_OFFER",
    EXPEDITED_PROCESSING = "EXPEDITED_PROCESSING",
    THIRD_PARTY_UPSELL_VENTURE = "GOVPLUS_THIRD_PARTY_OFFER_VENTURE",
    AUDIT_PROTECTION = "AUDIT_PROTECTION",
    EXPRESS_PROCESSING_UPSELL = "EXPRESS_PROCESSING",
    SUBSCRIPTION_CROSS_SELL = "SUBSCRIPTION_CROSS_SELL"
}

export class TemplateOneFactory {
    
    public getTemplateOne(solutionName: string, data: IUpsellProcessingData, paymentModel: PaymentModelTypes): TemplateOne {
        const solutionToLower = getSolutionNameToLowerCase(solutionName)
        let templateOneBuilder = new TemplateOneBuilder()
            .setTitle(data.settings_values[UPSELL_PROPS.UPSELL__HEADLINE_STOP].replaceAll(UPSELL_PROPS.SOLUTION_KEY_REPLACE, solutionToLower))
            .setSubtitle(data.settings_values[UPSELL_PROPS.UPSELL__SUB_HEADLINE_STOP].replaceAll(UPSELL_PROPS.SOLUTION_KEY_REPLACE, solutionToLower))
            .setPrice(data.price)
            .setAcceptButton(data.settings_values[UPSELL_PROPS.UPSELL__CALL_TO_ACTION_YES].replaceAll(UPSELL_PROPS.SOLUTION_KEY_REPLACE, solutionToLower))
            .setCancelButton(data.settings_values[UPSELL_PROPS.UPSELL__CALL_TO_ACTION_NO].replaceAll(UPSELL_PROPS.SOLUTION_KEY_REPLACE, solutionToLower))
            .setTestMode(data.test_mode ?? false)
            .setTestModeMessage(data.test_mode_message)
            .setLinkToOpen(data.link_to_open)
            .setService(data.service)
            .setServiceName(data.service_name)
            .setPageType(data.page_type)
            .setCrossSellProductOffer(data.cross_sell_product_offer)

        const templatePageBuilder = this.getTemplateBuilder(data)
        if (templatePageBuilder) {
            return templatePageBuilder.buildTemplate(templateOneBuilder, data, solutionName, paymentModel);
        }

        return templateOneBuilder.build();
    }

    private getTemplateBuilder(data: IUpsellProcessingData): ITemplateOneBuilder | undefined {
        if (data.page_type === OfferType.CrossSell) {
            return new CompletedApplicationCrossSell();
        }
        if (data.page_name?.includes(OffersPageNames.SUBSCRIPTION_CROSS_SELL)) {
            return new CrossSellSubscription();
        }
        switch (data.page_name) {
            case OffersPageNames.EXPEDITED_SHIPPING:
                return new ExpeditedShippingUpsell();
            case OffersPageNames.DOCUMENT_INSURANCE:
                return new DocumentInsuranceUpgrade();
            case OffersPageNames.AUTOMATIC_RENEWAL:
                return new AutomaticRenewalUpgrade();
            case OffersPageNames.FAMILY_UPGRADE:
                return new MemberFamilyUpgrade();
            case OffersPageNames.THIRD_PARTY_UPSELL:
                return new ThirdPartyUpsell();
            case OffersPageNames.EXPEDITED_PROCESSING:
                return new ExpeditedProcessingUpsell();
            case OffersPageNames.THIRD_PARTY_UPSELL_VENTURE:
                return new ThirdPartyUpsellVenture();
            case OffersPageNames.AUDIT_PROTECTION:
                return new AuditProtectionUpsell();
            case OffersPageNames.EXPRESS_PROCESSING_UPSELL:
                return new ExpressProcessingUpsell();
            default:
                return undefined;
        }
    }
}