import { FC } from "react"
import { renderBasicBlockContainer } from "../UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { BasicBlockContainerProps } from "../UploadPhotoService/interfaces/interfaces"
import LayoutBaseContainer from "../Layouts/LayoutBaseContainer/LayoutBaseContainer"

const defaultObject = {
  className: "",
  children: undefined,
  text: "",
  state: "",
}

const BasicBlockContainer: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text, state } = defaultObject,
  arrayComponents,
  ...props
}) => {
  return renderBasicBlockContainer(
    { className, text, childrenItem, arrayComponents, state },
    LayoutBaseContainer,
    props
  )
}

export default BasicBlockContainer
