import IParamsUri from "./IParamsUri";
import IParamsUriDto from "./IParamsUriDto";

/**
 * @class ParamsUriBaseDto
 * @description Base class for ParamsUriDto
 */
class ParamsUriBaseDto implements IParamsUriDto {
    protected _params: URLSearchParams;

    /**
     * Creates an instance of ParamsUriBaseDto.
     * @memberof ParamsUriBaseDto
     * @description Creates an instance of ParamsUriBaseDto. This class use URLSearchParams to get params from window.location.search
     */
    constructor() {
        this._params = new URLSearchParams(window.location.search);
    }

    /**
     * @param key
     * @returns {string | null}
     * @memberof ParamsUriBaseDto
     * @description Get param by key
     */
    getParams(key: string): string | null {
        return this._params.get(key);
    }

    /**
     * @returns {IParamsUri[]}
     * @memberof ParamsUriBaseDto
     * @description Get all params
     */
    getAllParams(): IParamsUri[] {
        const allParams: IParamsUri[] = [];
        this._params.forEach((value: string, key: string) => {
            allParams.push({
                key: key,
                value: value
            })
        });
        return allParams;
    }
}

export default ParamsUriBaseDto;