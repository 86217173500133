/* eslint-disable indent */
import { ActionTypes } from "./form.actions"
import { Action } from "../../interfacesAction/action.interface"
import { HelperInterface } from "../../InterfacesEntity/helper.interface"
import { getInitialObject } from "./initialObj"
import { paymentErrorInterface } from "../../../Components/FormComponents/FieldsComponents/InputComponent/serviceInput"
import {initialObservers} from "./serviceForm";
import ApplicationIdRepository from "../../../persistence/session/ApplicationIdRepository";
import AuthUserBearerTokenRepository from "../../../persistence/session/AuthUserBearerTokenRepository";

const formId = ""

const utm = require("@segment/utm-params")
export const getUtm = () => {
  let utmPoints = utm(document.location.href)
  utmPoints = { ...utmPoints, campaign: utmPoints.name }
  delete utmPoints.name
  return utmPoints
}

const initialObject = {
  ...getInitialObject(),
  utm: getUtm(),
  form_id: formId,
  formtype: formId.split("-")[formId.split("-").length - 1],
  "form-type": formId.split("-")[formId.split("-").length - 1],
  client_token: AuthUserBearerTokenRepository.getInstance().getValue() || "",
  old_client_token: sessionStorage.getItem("old_client_token") || "",
  application_category: "individual",
}

const localObserversObject = JSON.parse(localStorage.getItem("observers") || "{}")

export interface State {
  helper: HelperInterface | {};
  modalValue: string;
  submitObject: {} | any;
  observers: {} | any;
  selectOptions: {} | any;
  questionary: [] | any;
  onScrollY: number | any;
  error: {} | any;
  formId: string;
  application_id: string;
  customer: null | { email: string };
  paymentError: paymentErrorInterface | null;
  tagStoreWithFields: { [key: string]: any } | {};
  tagFields: { [key: string]: any } | {};
  submitError: { [key: string]: any } | {};
}

const initialState: State = {
  helper: {},
  modalValue: "",
  submitObject: {}, //removed initial project
  observers: { ...localObserversObject, ...initialObservers },
  selectOptions: {},
  questionary: [],
  onScrollY: 0,
  error: null,
  formId: formId,
  application_id: ApplicationIdRepository.getInstance().getValue() ?? "",
  customer: null,
  paymentError: null,
  tagStoreWithFields: JSON.parse(sessionStorage.getItem("tagPrefilledFields") ?? "{}") ?? {},
  tagFields: JSON.parse(sessionStorage.getItem("tagFields") ?? "{}") ?? {},
  submitError: {},
}

export const formReducer = (state: State = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.SET_INITIAL_HELPER:
      return {
        ...state,
        helper: {},
      }
    case ActionTypes.SET_VALUE_CURRENT_FIELD:
      const newSubmitObject = { ...state.submitObject, ...action.payload }
      // sessionStorage.setItem("finallySubmitObject", JSON.stringify(newSubmitObject))
      return {
        ...state,
        submitObject: newSubmitObject,
      }
    case ActionTypes.REMOVE_VARIABLE_CURRENT_FIELD:
      const newSubmitObjectRemove = { ...state.submitObject }
      delete newSubmitObjectRemove[action.payload]
      // sessionStorage.setItem("finallySubmitObject", JSON.stringify(newSubmitObjectRemove))
      return {
        ...state,
        submitObject: newSubmitObjectRemove,
      }
    case ActionTypes.SET_ACTIVE_INPUT:
      return {
        ...state,
        helper: action.payload,
      }
    case ActionTypes.SET_VALUE_MODAL:
      return {
        ...state,
        modalValue: action.payload,
      }
    case ActionTypes.SET_SCROLL_Y_VALUE:
      return {
        ...state,
        onScrollY: action.payload,
      }
    case ActionTypes.SET_INITIAL_FIELDS:
      return {
        ...state,
        submitObject: {},
      }
    case ActionTypes.SET_OBSERVERS_CHAIN:
      let newObserversChain = {
        ...state.observers,
        [action.payload.target]: action.payload,
      }
      sessionStorage.setItem("observers", JSON.stringify({ ...state.observers, ...newObserversChain }))
      return {
        ...state,
        observers: newObserversChain,
      }
    case ActionTypes.SET_OBSERVERS:
      const { observablesObj } = action.payload ?? {}
      for (const obj in observablesObj) {
        observablesObj[obj] = {
          ...observablesObj[obj],
          value: action.payload.value,
        }
      }
      sessionStorage.setItem("observers", JSON.stringify({ ...state.observers, ...observablesObj }))
      return {
        ...state,
        observers: { ...state.observers, ...observablesObj },
      }
    case ActionTypes.REMOVE_OBSERVER:
      let newObservers = { ...state.observers }
      if (action.payload) {
        delete newObservers[action.payload]
        sessionStorage.setItem("observers", JSON.stringify(newObservers))
      }
      return {
        ...state,
        observers: newObservers,
      }
    case ActionTypes.SET_PREV_NAME_OPTION:
      const { nameOption, nameField, valueField } = action.payload
      return {
        ...state,
        selectOptions: {
          ...state.selectOptions,
          [nameOption]: {
            ...state.selectOptions[nameOption],
            [nameField]: valueField,
          },
        },
      }
    case ActionTypes.SET_CHANGED_ADDRESS:
      return {
        ...state,
        submitObject: { ...state.submitObject, ...action.payload },
      }
    case ActionTypes.SET_CLIENT_ID:
      return {
        ...state,
        clientId: action.payload,
      }
    case ActionTypes.SET_CLIENT_EMAIL:
      return {
        ...state,
        clientEmail: action.payload,
      }
    case ActionTypes.SET_APP_ID:
      return {
        ...state,
        application_id: action.payload,
      }
    case ActionTypes.SET_FORM_ID:
      return {
        ...state,
        formId: action.payload,
      }
    case ActionTypes.SET_PDF:
      return {
        ...state,
        linkImgPDF: action.payload,
      }
    case ActionTypes.SET_USER_IP:
      return {
        ...state,
        userIp: action.payload,
      }
    case ActionTypes.SET_QUESTIONARY:
      return {
        ...state,
        questionary: action.payload,
      }
    case ActionTypes.SET_BILLING_ADRESS:
      return {
        ...state,
        billingAddress: action.payload,
      }
    case ActionTypes.SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      }
    case ActionTypes.SET_SERVICES_CUSTOMER:
      return {
        ...state,
        servicesCustomer: action.payload,
      }
    case ActionTypes.SET_SUBMIT_OBJECT:
      const submitObject = { ...state.submitObject, ...action.payload }
      return {
        ...state,
        submitObject: submitObject,
      }
    case ActionTypes.SET_STATUS_ON_SUBMIT_OBJECT:
      const submitObjectWithStatus = { ...state.submitObject, status: action.payload }
      return {
        ...state,
        submitObject: submitObjectWithStatus,
      }
    case ActionTypes.SET_PREFILLED_OBJECT:
      return {
        ...state,
        tagStoreWithFields: { ...state.tagStoreWithFields, ...action.payload },
      }
    case ActionTypes.SET_NEW_PREFILLED_OBJECT:
      return {
        ...state,
        tagStoreWithFields: { ...action.payload },
      }
    case ActionTypes.SET_TAG_FIELDS:
      return {
        ...state,
        tagFields: { ...state.tagFields, ...action.payload },
      }
    case ActionTypes.SET_DISCOUNT:
      return {
        ...state,
        discount: action.payload,
      }
    case ActionTypes.SET_PRICING:
      return {
        ...state,
        pricing: action.payload,
      }
    case ActionTypes.SET_DOCUMENT_LIST:
      return {
        ...state,
        documentList: action.payload,
      }
    case ActionTypes.SET_SUBMIT_FORM_ERROR:
      return {
        ...state,
        submitError: action.payload,
      }
    case ActionTypes.SET_PAYMENT_ERROR:
      return {
        ...state,
        paymentError: action.payload,
      }
    case ActionTypes.GET_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    case ActionTypes.STRIPE_LOADED:
      return {
        ...state,
        stripeLoaded: action.payload,
      }
    case ActionTypes.PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      }
    case ActionTypes.SHOW_STRIPE_FORM:
      return {
        ...state,
        showStripe: action.payload,
      }
    case ActionTypes.CLEAR_SUBMIT_BY_EMPTY_PROP:
      return {
        ...state,
        submitObject: action.payload,
      }
    case ActionTypes.CLEAR_SUBMIT_IN_START_APP:
      return {
        ...state,
        submitObject: {},
      }
    case ActionTypes.SHOW_ADD_PAYMENT_METHOD_BUTTON:
      return {
        ...state,
        showPaymentMethodButton: action.payload,
      }
    case ActionTypes.SET_STATUS_STRIPE:
      return {
        ...state,
        statusStripe: action.payload,
      }
    default:
      return state
  }
}
