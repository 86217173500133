/* eslint-disable indent */
import { Button } from "antd"
import React, { useRef } from "react"
import { connect } from "react-redux"
import { setInitialHelper } from "../../../../Redux/store/form/form.actions"
import { setCurrentStep, setIndexCurrentStep } from "../../../../Redux/store/step/step.actions"
import ClientDataComponent from "../../PostSubmitComponents/ClientDataComponent/ClientDataComponent"

type AdditionalTabletHelperComponentProps = {
  currentStep: {} | any,
  clientId: any,
  statusSignature: string,
  indexCurrentStep: number,
  dispatch: any,
}

// eslint-disable-next-line max-len
const AdditionalTabletHelperComponent: React.FunctionComponent<AdditionalTabletHelperComponentProps> = ({
  currentStep,
  clientId,
  statusSignature,
  indexCurrentStep,
  dispatch,
}) => {
  const helperRef: any = useRef()

  const helperContent = currentStep.stepContent.blocksOnFormPage[0].blockOnFormPage.formsInBlockOnFormPage[1]

  const arrAdditionalText = helperContent?.blockOnFormPage?.content?.description
    ? Object.values(helperContent?.blockOnFormPage?.content?.description ?? {})
    : []

  const handlerAnyClickButton = (nextIndex: number) => {
    dispatch(setCurrentStep(nextIndex))
    dispatch(setInitialHelper())
  }

  const handleDecrementClick = () => {
    window.scrollTo(0, 0)
    dispatch(setIndexCurrentStep(indexCurrentStep + 1))
    handlerAnyClickButton(indexCurrentStep + 1)
  }

  const { helperOn } = currentStep

  const packageOnly = helperContent?.blockOnFormPage?.type === "package-helper"

  return (
    <>
      {!packageOnly && helperContent?.blockOnFormPage?.type === "additional-helper" && (
        <div className={"additional-helper__container"}>
          <div
            ref={helperRef}
            className={`additional-helper form-helper ${window.pageYOffset > 300 ? "form-helper_top" : false}`}
          >
            <h2 className={"additional-helper form-helper__header"}>{helperContent.blockOnFormPage.content.header} </h2>
            <ol className={"additional-helper form-helper__main"}>
              {arrAdditionalText.map((item: any, index: number) => (
                <li key={index} className={`additional-helper__text`}>
                  {item}
                </li>
              ))}
            </ol>
          </div>
          {helperContent.blockOnFormPage.personalData && (
            <ClientDataComponent item={helperContent} clientId={clientId} />
          )}

          {statusSignature === "process" && indexCurrentStep === 0 && false && (
            <div className={`correct-block`}>
              <h3 className={`correct-block__header`}>Mistakes on your form?</h3>
              <Button type="primary" className={`correct-block__button`} onClick={handleDecrementClick}>
                <span className="">{`Correct my information`}</span>
              </Button>
            </div>
          )}
        </div>
      )}

      {packageOnly && <ClientDataComponent item={helperContent} clientId={clientId} />}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  currentStep: state.step.currentStep,
  clientId: state.form.clientId,
  statusSignature: state.additional.statusSignature,
  indexCurrentStep: state.step.indexCurrentStep,
})

export default connect(mapStateToProps)(AdditionalTabletHelperComponent)
