import React from "react"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
import SubscriptionAboutIcon from "../../../SubscriptionPaymentForm/SubscriptionAbout/SunbscriptionAboutLogo"
import GText from "../../atoms/Text/Text"
import { COLORS_NEW } from "../../types/Colors"
import { SIZE } from "../../types/types"
import "./ProductsTableList.scss"

export type ProductsTableListProps = {
  products: string[];
}


const ProductsTableList = ({products}: ProductsTableListProps) => {
  const breakpoint = useBreakpoint()

  return (
  <div className={"Products"}>
      <div className={"ProductsList"}>
          {products.slice(0, Math.ceil(products.length / 2)).map((product, index) => <div key={`${product}_${index}`} className={"ProductItem"}>
              <SubscriptionAboutIcon/>
              <GText
                  text={product}
                  size={breakpoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16}
                  color={COLORS_NEW.BLACK_600}
              />
          </div>)}
      </div>
      <div className={"ProductsList ProductsListMobile"}>
          {products.slice(Math.ceil(products.length / 2), products.length).map((product, index) => <div key={`${product}_${index}`} className={"ProductItem"}>
              <SubscriptionAboutIcon/>
              <GText
                  text={product}
                  size={breakpoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16}
                  color={COLORS_NEW.BLACK_600}
              />
          </div>)}
      </div>
  </div>
  )
}

export default ProductsTableList