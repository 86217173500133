import React, {useEffect, useMemo, useState} from "react"
import {connect, useSelector} from "react-redux"
import {FieldInterface} from "../../../Redux/InterfacesEntity/field.interface"
import SwitchBlock from "../SwitchBlock/SwitchBlock"
import {checkShowOn, checkShowOnAutofill} from "./checkShowOn"
import BlocksSteps from "../PostSubmitComponents/BlocksSteps/BlocksSteps"
import PackageStepsComponent from "../PostSubmitComponents/PackageStepsComponent/PackageStepsComponent"
import {getArrayDynamicBlock, getLabelDynamicBlock, getArrayBlockSteps} from "./dynamicBlock"
import BlocksStepsPassport from "../PostSubmitComponents/BlocksSteps/BlocksStepsPassport"
import BlocksStepsCer from "../PostSubmitComponents/BlocksSteps/BlocksStepsCer"
import {RootState} from "../../../App"
import {additionalSelector} from "../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import {getStatusBlock} from "../../../services/statusesService/getStatesBlocks"
import BlockBillingInformation, {BillingInformation, Charge} from "./BillingInformation/BlockBillingInformation"
import BlocksStepsTsa from "../PostSubmitComponents/BlocksSteps/BlocksStepsTsa"
import {setPrefilledValueInSubmitObject} from "../FieldsComponents/InputComponent/serviceInput"
import {setValueCurrentField} from "../../../Redux/store/form/form.actions"
import CheckStatusTagFile from "./CheckStatusTagFile"
import BlocksStepsCoa from "../PostSubmitComponents/BlocksSteps/BlocksStepsCoa"
import lockIcon from "../../../assets/image/icon-lock.png"
import "./BlockFormWithFields.scss"
// @ts-ignore
import kountSDK from "@kount/kount-web-client-sdk"
import GProductPrice from "../../atomicDesign/molecules/ProductPrice/ProductPrice";
import {getServiceAndSolutionNameForFormsHeaders} from "../../../services/gtm/backEndEvent";
import ServicesHelper from "../../../util/ServicesHelper";
import GText from "../../atomicDesign/atoms/Text/Text";
import {PriceInfo, SIZE} from "../../atomicDesign/types/types";
import {COLORS, COLORS_NEW} from "../../atomicDesign/types/Colors";
import UiStringMessages from "../../../messages/UiStringMessages";
import {BlockStep} from "../../../util/BlockStep";
import {BlockStepType} from "../../../util/BlockStepType";
import GMandatoryFeeItem from "../../atomicDesign/molecules/GMandatoryFeeItem/GMandatoryFeeItem";
import {COMBINED_PAYMENT_METHOD_APPLICATION_ONLY, STEP_TYPE_PAYMENT} from "../../../shared/constans/payment";
import GNotification, {NOTIFICATION_TYPE} from "../../atomicDesign/organisms/Notification/Notification";
import {FormError} from "../../../dto/FormError";
import {ICONS_SVG_TYPE} from "../../atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import FormIdRepository from "../../../persistence/session/FormIdRepository";
import {PaymentRequestTokenEvent} from "@stripe/stripe-js";
import {FEE_ITEM_KEY} from "../../../util/FeeItemKey";
import {PossiblesTaxServicesNames, ServicesNames} from "../../../util/ServicesNames";
import BlocksStepsTax from "../PostSubmitComponents/BlocksSteps/BlocksStepsTax";
import PaymentSubscriptionInformation from "./PaymentSubscriptionInformation/PaymentSubscriptionInformation";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import BlocksStepsEin from "../PostSubmitComponents/BlocksSteps/BlocksStepsEin"
import PaymentCombinedInformation from "./PaymentCombinedInformation/PaymentCombinedInformation";
import {PaymentButtonsFactory} from "../../atomicDesign/templates/PaymentButtons/PaymentButtonsFactory";
import BlocksStepsNam, {setPassportDsTypeForNam} from "../PostSubmitComponents/BlocksSteps/BlocksStepsNam";
import {FormInstance} from "antd";
import { env } from "../../../env"

type BlockFormWithFieldsProps = {
  blockObject: { headerFields: string, fields: [FieldInterface] } | any,
  indexBlock?: number,
  submitObject: {} | any,
  observers: any,
  selectOptions: any,
  currentResponse: {} | any,
  statusQuestionary: string,
  dynamicBlocks: [{}] | any,
  additionalSteps: [{}] | any,
  indexCurrentStep: number,
  dispatch: any,
  setStripeElements?: any,
  paymentError?: {
    nameField: any,
    errorDescription: string,
  },
  tagStoreWithFields: { [key: string]: string },
  showStripe: boolean,
  billingInformation: BillingInformation,
  upsellProcessingInformation: any,
  charges: Charge[],
  currentStep: any,
  formError: FormError,
  onGatewayPaymentStartedWithToken?: (event: PaymentRequestTokenEvent, userCoupon: any, subscriptionPlanMethod?: string | null) => Promise<void>
  userCoupon?: any,
  itemPriceCharges: any;
  form?: FormInstance
}

// eslint-disable-next-line max-len
const BlockFormWithFields: React.FunctionComponent<BlockFormWithFieldsProps> = ({
  blockObject,
  indexBlock,
  submitObject,
  observers,
  selectOptions,
  currentResponse,
  statusQuestionary,
  dynamicBlocks,
  additionalSteps,
  indexCurrentStep,
  dispatch,
  setStripeElements,
  paymentError,
  tagStoreWithFields,
  showStripe,
  billingInformation,
  upsellProcessingInformation,
  charges,
  currentStep,
  formError,
  onGatewayPaymentStartedWithToken,
  userCoupon,
  itemPriceCharges,
  form
}) => {

  const SECURE_PAYMENT = "Secure payment";

  const observer = blockObject.type === "observer"
  const breakPoint = useBreakpoint()

  const arrayFields = getArrayDynamicBlock(blockObject, dynamicBlocks, submitObject)

  const formId = submitObject.form_id || FormIdRepository.getInstance().getValue()
  const arrayBlockSteps = useMemo(
    () => getArrayBlockSteps(blockObject.blocksSteps, upsellProcessingInformation, additionalSteps?.upsells_information, formId),
    [blockObject.blocksSteps, upsellProcessingInformation, additionalSteps]);

  let dynamicLabel = getLabelDynamicBlock(blockObject, dynamicBlocks, submitObject)

  const nameFieldStyles: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: breakPoint.md ? "1fr 1fr" : "1fr",
    gap: 8

  }

  const heightAndColorFieldStyles: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: breakPoint.md ? "2fr 1fr 1fr" : "1fr",
    gap: 12
  }

  /**
   * Remove label if it's 'Add new payment method'
   * when no other payment methods have been added
   */
  if (billingInformation?.payment_methods.length === 0 &&
      dynamicLabel?.toLowerCase()?.includes('add new payment method')) {
    dynamicLabel = null
  }

  const showOn = checkShowOn(observers, blockObject, observer, submitObject, selectOptions, dispatch)

  const showOnAutofill = checkShowOnAutofill({ observers, blockObject })

  const bigLabel = blockObject.bigLabel ? "big-label" : ""

  const statusMistake = blockObject.statusMistake ? "error" : ""

  const paddingTop = !blockObject.label ? "padding-top" : ""

  const template = blockObject.template

  const name = currentResponse && currentResponse.verifiedAddress && currentResponse.verifiedAddress.original.name

  const showOnContent = blockObject.defaultContent !== "hidden" || statusQuestionary === "complete"

  const [showingFields, setShowingFields] = useState([])

  const productName = FormIdRepository.getInstance().getValue()?.split("-")[0]
  const enableEditor = new URLSearchParams(window.location.search).get("enableEditor")

  const isPayment = blockObject.name === "billing_information"
  const isSubscriptionPlan = blockObject?.name === "subscription_plans"
  const isMandatoryFees = blockObject.name === "Mandatory_fees"
  const { service } = getServiceAndSolutionNameForFormsHeaders()
  const [priceInformation, setPriceInformation] = useState(blockObject.priceInformation);
  const [selectedPaymentSubscriptionPlan, setSelectedPaymentSubscriptionPlan] = useState({method: '', price: 0});

  const applyServiceNameFilter = (service: string) => {
    let serviceName = service;
    if(PossiblesTaxServicesNames.includes(service)) {
      serviceName = ServicesNames.TAXES_V1_1;
    }
    return serviceName;
  }

  const applyServiceOfferFilter = (service: string, serviceOffer: string) => {
    let serviceOfferName: string | null = serviceOffer;
    if(PossiblesTaxServicesNames.includes(service)) {
      serviceOfferName = null;
    }
    return serviceOfferName;
  }

  if (priceInformation) {
    priceInformation.content.service = applyServiceNameFilter(service);
    priceInformation.content.offer = applyServiceOfferFilter(service, priceInformation.content.offer);
  }

  const isShowForm = () => {
    if (!isPayment) {
      return true;
    }
    if (isPayment && showStripe) {
      return true;
    }
    if (isPayment && ((!showStripe) && billingInformation?.payment_methods?.length === 0)) {
      return true;
    }
    return false;
  }

  const showPaymentForm = isShowForm();

  if (isPayment) {
    const sessionId = submitObject['unique-section-id']
    const kountConfig = {
      clientID: env.REACT_APP_KOUNT_CLIENT_ID,
      hostname: env.REACT_APP_KOUNT_DDC_URL,
      isSinglePageApp: true,
    };
    const kount = kountSDK(kountConfig, sessionId)
  }

  const getSelectedCharge = () =>{
    if(!charges || charges.length <= 0){
      return undefined;
    }
    if(!submitObject[FEE_ITEM_KEY]){
        return charges[0];
    }
    return charges.find((charge) => charge.id == submitObject[FEE_ITEM_KEY]);
  }

  const setLocalPrice = (price: number) => {
    const savePrice = Number(priceInformation?.content?.savePrice.replace(/\D/g, ''))
    const discount = savePrice - price;

    if(priceInformation) {
      priceInformation.content.price = `$${price}`;
      if (userCoupon && userCoupon?.available) {
        priceInformation.content.offer = `Save $${discount} today with your special offer`;
      }
      setPriceInformation({...priceInformation, ...priceInformation.content})
    }
  }
  const getPrisingInformation = (): PriceInfo | undefined =>{
    let discount = 0;
    let price = 0;
    if(userCoupon && userCoupon?.available){
      discount = userCoupon.discount;
    }
    if(isPayment && (blockObject.subscriptionInformationSections || blockObject.combinedInformationSections)){
      let productName = blockObject.combinedInformationSections ?
        selectedPaymentSubscriptionPlan.method === COMBINED_PAYMENT_METHOD_APPLICATION_ONLY ? service : "GOV+" +
          " Subscription" : "GOV+ Subscription"
      price = Number(selectedPaymentSubscriptionPlan.price || 0) * 100 - discount * 100;
      setLocalPrice(price/100);

      if(itemPriceCharges?.length) {
        productName = itemPriceCharges.find((charge: any) => charge.id === selectedPaymentSubscriptionPlan.method)?.description || service;
      }

      return {
        productName,
        totalPay: price
      };
    }
    if(blockObject.priceInformation?.content){
      const priceStr = blockObject.priceInformation.content.price.slice(1);
      price = Number.parseInt(priceStr) * 100 - discount * 100;
      setLocalPrice(price/100);
      return {productName: service, totalPay: price};
    }
    const charge = getSelectedCharge();
    if(charge){
      price = charge.amount * 100 - discount * 100;
      setLocalPrice(price/100);
      return {productName: charge.name, totalPay: price};
    }

    return undefined;
  };

  const payPriceInfo = useMemo(getPrisingInformation, [submitObject[FEE_ITEM_KEY], blockObject.priceInformation, charges, userCoupon, selectedPaymentSubscriptionPlan.price]);

  useEffect(() => {
    if (blockObject && blockObject.name === "application_block") {
      const timers: number[] = blockObject.fields.map(
        // eslint-disable-next-line array-callback-return
        (field: any, index: number) => {
          setTimeout(() => setShowingFields(blockObject.fields.slice(0, index + 1)), index * 2000)
        }
      )
      return () => timers.forEach((timer) => clearTimeout(timer))
    }
  }, [blockObject.name, blockObject.fields, blockObject])

  const getFilteredBlocks = (blocksSteps: any[])=> {
    const filter1 = blocksSteps?.filter(({ filterBlockStep, filterBlockStep: { variable = "", values = [""] } = {} }) =>
        submitObject.hasOwnProperty(variable) ? (filterBlockStep ? values.includes(submitObject[variable]) : true) : true
    )
    /*
        The blocks that are going to be rendered are returned by applying a filter to eliminate
        the upsell type blocks that are not going to be rendered. Based on the fact that the
        upsellProcessingInformation variable must contain the object that contains the upsell information
     */
    return filter1.filter((block: BlockStep) => {
      if (block.type !== BlockStepType.Upsell)
        return true;
      return upsellProcessingInformation?.[block.status] !== undefined
    })
  }

  const getFilterFields = (arrayFields: FieldInterface[]) =>
    arrayFields?.filter(({ filterField, filterField: { variable = "", values = [""] } = {} }) =>
      filterField && submitObject && variable ? values.includes(submitObject[variable]) : true
    )

  const { statusSignaturePage } = useSelector<RootState, { statusSignaturePage: string } | undefined>(additionalSelector) ?? {statusSignaturePage: ""}

  const submitArrayBlock = [
    "steps",
    indexCurrentStep,
    "stepContent",
    "blocksOnFormPage",
    0,
    "blockOnFormPage",
    "formsInBlockOnFormPage",
    "formsInBlockOnFormPage",
    0,
    "form",
    "blocksWithFieldsOrNestBlocks",
    indexBlock,
  ]

  const getLaunchEditor = (e: MouseEvent | any, submitArray: any[]) => {
    // @ts-ignore
    if (window.gedit__loadByPath) {
      // e.preventDefault()
      // @ts-ignore
      window.gedit__loadByPath(submitArray)
    }
  }
  const getFormError = (): string | undefined => {
    if (blockObject?.payment_error && currentStep.formType !== STEP_TYPE_PAYMENT && (formError || paymentError) ) {
      return formError?.error ?? paymentError?.errorDescription;
    }
    return undefined

  }

  const hasFormError = getFormError()

  // Remove empty conditional elements from the DOM
  useEffect(() => {
    const fieldElements = document.querySelectorAll(".field-in-block")
    if (fieldElements?.length > 0) {
      fieldElements.forEach(element => {
        if (element) {
          // In case a form field is empty, it'll have a <div> element with noting inside and cause spacing issues
          if (element.firstChild?.childNodes.length === 0) {
            element.setAttribute("class", `${element.getAttribute("class")} hide-element`)
          }
          else {
            // the hide-element class will add several times because of re-renders. Remove all of them when the element
            // is required to show
            const defaultClass = element.getAttribute("class")
                ?.split(" ")
                .filter(className => className !== 'hide-element')
            element.setAttribute("class", defaultClass?.join(" ") || "")
          }
        }
      })
    }
    // Do the same for all elements with observer-false class that shouldn't be on view for passport details questionnaire
    const observerFalseElements = document.querySelectorAll(".passport-details_block")
    if (observerFalseElements.length > 0) {
      observerFalseElements.forEach(element => {
        // Remove specifically passport detail blocks with empty div
        if (element.childNodes.length === 2 && element.childNodes[1].childNodes.length === 0) {
          element.setAttribute("class", `${element.getAttribute("class")} hide-element`)
        }
        else {
          const defaultClass = element.getAttribute("class")
              ?.split(" ")
              .filter(className => className !== 'hide-element')
          element.setAttribute("class", defaultClass?.join(" ") || "")
        }
      })
    }

    /*
      Need to check the DS type of the passport and set it in the submitObject so that in the case of NAM
       applications we can send it to the backend and show or not certain blocks depending on it in real time.
    */
    if(submitObject) {
      setPassportDsTypeForNam(submitObject, dispatch)
    }
  }, [submitObject])

  const filterFields = getFilterFields(arrayFields)

  const renameChargesToGovernmentFee = (charges: Charge[]) => {
    return charges.map((charge: Charge) => {
      if (
          [
            'express processing',
            'priority processing'
          ].some(term => charge.name.toLowerCase().includes(term))
      ) {
        charge.name = "Government fees";
      }
      return charge
    })
  }

  return (
    <>
      {blockObject.type === "content" && blockObject.content && showOnContent && (
        <div className={`block-type-content`}>
          {blockObject.label && (
            <h3
              // eslint-disable-next-line max-len
              className={`block-type-content__header ${bigLabel} ${statusMistake}`}
            >
              {blockObject.label}
            </h3>
          )}
          {blockObject.content.name && (
            <p className={`block-type-content__name`}>{`Dear ${name},` || blockObject.content.name}</p>
          )}
          {blockObject.content.text.map((item: any, index: number) => {
            let link = false
            if (item.indexOf("</")) link = true
            return (
              (link && (
                <p
                  // eslint-disable-next-line max-len
                  className={`block-type-content__text block-type-content__text_link`}
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              )) || (
                <p className={`block-type-content__text`} key={index}>
                  {item}
                </p>
              )
            )
          })}
        </div>
      )}

      {isPayment && blockObject.subscriptionInformationSections &&
        <>
          <PaymentSubscriptionInformation
            blockObject={blockObject.subscriptionInformationSections}
            setSelectedPaymentSubscriptionPlan={setSelectedPaymentSubscriptionPlan}
          />

          <div className={`SecureImageTitle`}>
            <GText text={SECURE_PAYMENT} size={SIZE.HEADING_MD} color={COLORS_NEW.BLACK_1000} />
          </div>
        </>
      }

      {
        isPayment
        && blockObject.combinedInformationSections
        && <>
            <PaymentCombinedInformation
                blockObject={blockObject.combinedInformationSections}
                setSelectedPaymentSubscriptionPlan={setSelectedPaymentSubscriptionPlan}
                itemPriceCharges={itemPriceCharges}
                form={form}
            />

            <div className={`SecureImageTitle`}>
              <GText text={SECURE_PAYMENT} size={SIZE.HEADING_LG} color={COLORS_NEW.BLACK_1000} />
            </div>
        </>
      }

      {isPayment && showPaymentForm && payPriceInfo && PaymentButtonsFactory.getButton({
        marginButton: 32,
        priceInfo: payPriceInfo,
        onTokenCreated: async (tokenEvent, userCoupon) => {
          await onGatewayPaymentStartedWithToken?.(tokenEvent, userCoupon, selectedPaymentSubscriptionPlan.method)
        },
        submitObject: submitObject,
      })}

      {(showOn || showOnAutofill) && (
        <>
          {dynamicLabel && blockObject.type !== "content" && (
            <div className={"list-fields-in-block__header"}>
              <h3 className={"list-fields-in-block__title"}>{dynamicLabel}</h3>
              {enableEditor && (
                <button className={"button_editor"} onClick={(e) => getLaunchEditor(e, submitArrayBlock)}>
                  Editor
                </button>
              )}
            </div>
          )}

          {blockObject.type !== "content" && blockObject.centerLabel && (
              <div className={"list-fields-in-block__header text-center"}>
                <GText text={blockObject.centerLabel} size={SIZE.HEADING_MDLG} color={COLORS_NEW.BLACK_1000} />
              </div>
          )}

          <div
            // eslint-disable-next-line max-len
            className={`list-fields-in-block__main list-fields-in-block__main-observer-${observer}`}
          >
            {(blockObject?.security && <div>
              <div className={"list-fields-in-block__main-security"}>
                {(blockObject.security && (
                    <img src={lockIcon} className={"list-fields-in-block__main-security-icon"} alt={"security-icon"} />
                )) || <div />}
                {blockObject.security && (
                    <p className={"list-fields-in-block__main-security-description"}> {blockObject.security.description}</p>
                )}
              </div>
            </div>)}
            {(blockObject?.label_information && <div>
              <div className={"list-fields-in-block__main-security"}>
                {blockObject.label_information && (
                    <p className={"list-fields-in-block__main-security-description"}> {blockObject.label_information.description}</p>
                )}
              </div>
            </div>)}

            {blockObject.description && (
              <p
                // eslint-disable-next-line max-len
                className={`list-fields-in-block__description list-fields-in-block__description_${blockObject.name}`}
              >
                {blockObject.description}
              </p>
            )}

            {blockObject.name === "subscription_plans" && (
                <ul
                    // eslint-disable-next-line max-len
                    className={`list-fields-in-block ${blockObject.name} list-fields-in-block_${paddingTop} list-fields-in-block_${template}`}>
                  {blockObject.name === "subscription_plans" &&
                      blockObject.priceInformation && (
                          <li key={`price-information`}>
                            <GProductPrice productPriceProps={priceInformation}></GProductPrice>
                            {ServicesHelper.involvesGovernmentFee(service) && (
                                <div className={`Payment-GovernmentFee-Information`} key={`last`}>
                                  <GText text={UiStringMessages.GOVERNMENT_FEES_NOT_INCLUDED} size={SIZE.PARAGRAPH_REGULAR_18} color={COLORS_NEW.BLACK_500} />
                                </div>
                            )}
                          </li>
                      )}

                  {blockObject.name === "subscription_plans" &&
                      getFilterFields(arrayFields).map((item: FieldInterface | any, index: number) => {
                        const submitArrayField = [...submitArrayBlock, "fields", index]
                        return (
                            <li
                                className={`field-in-block ${blockObject.name} editor_${!!enableEditor}`}
                                key={index}
                                onClick={(e) => getLaunchEditor(e, submitArrayField)}
                            >

                              <SwitchBlock field={item} value={submitObject[item.name]} observer={observer} />
                            </li>
                        )
                      })}
                </ul>
            )}

            {arrayFields && arrayFields.length !== 0 && blockObject.name !== "subscription_plans" && (
              <ul
                // eslint-disable-next-line max-len
                className={`list-fields-in-block ${blockObject.name} list-fields-in-block_${paddingTop} list-fields-in-block_${template}`}
              >


                {isPayment && <BlockBillingInformation blockObject={blockObject} setStripeElements={setStripeElements} />}

                {blockObject.name !== "subscription_plans" &&
                  blockObject.name !== "application_block"  &&
                  arrayFields.length !== 0 && showPaymentForm  &&
                  filterFields.map((item: FieldInterface | any, index: number) => {
                    const submitArrayField = [...submitArrayBlock, "fields", index]
                    return (
                      <React.Fragment key={index}>
                        {item.name === "firstname" &&
                          <div style={nameFieldStyles}>
                            <CheckStatusTagFile
                              {...{
                                item,
                                blockObject,
                                enableEditor,
                                index,
                                getLaunchEditor,
                                submitArrayField,
                                submitObject,
                                observer,
                                dispatch,
                                tagStoreWithFields,
                                setPrefilledValueInSubmitObject,
                                setValueCurrentField,
                                observers,
                                selectOptions,
                              }}
                            />
                            <CheckStatusTagFile
                              {...{
                                item: filterFields[index + 1],
                                blockObject,
                                enableEditor,
                                index,
                                getLaunchEditor,
                                submitArrayField,
                                submitObject,
                                observer,
                                dispatch,
                                tagStoreWithFields,
                                setPrefilledValueInSubmitObject,
                                setValueCurrentField,
                                observers,
                                selectOptions,
                              }}
                            />
                          </div>
                        }
                        {item.name === "height" &&
                        filterFields[index + 1].name === "eye_color" &&
                        filterFields[index + 2].name === "hair_color" &&
                          <div style={heightAndColorFieldStyles}>
                            <CheckStatusTagFile
                              {...{
                                item,
                                blockObject,
                                enableEditor,
                                index,
                                getLaunchEditor,
                                submitArrayField,
                                submitObject,
                                observer,
                                dispatch,
                                tagStoreWithFields,
                                setPrefilledValueInSubmitObject,
                                setValueCurrentField,
                                observers,
                                selectOptions,
                              }}
                            />
                            <CheckStatusTagFile
                              {...{
                                item: filterFields[index + 1],
                                blockObject,
                                enableEditor,
                                index,
                                getLaunchEditor,
                                submitArrayField,
                                submitObject,
                                observer,
                                dispatch,
                                tagStoreWithFields,
                                setPrefilledValueInSubmitObject,
                                setValueCurrentField,
                                observers,
                                selectOptions,
                              }}
                            />
                            <CheckStatusTagFile
                              {...{
                                item: filterFields[index + 2],
                                blockObject,
                                enableEditor,
                                index,
                                getLaunchEditor,
                                submitArrayField,
                                submitObject,
                                observer,
                                dispatch,
                                tagStoreWithFields,
                                setPrefilledValueInSubmitObject,
                                setValueCurrentField,
                                observers,
                                selectOptions,
                              }}
                            />
                          </div>
                        }
                        {item.name === "height" &&
                        filterFields[index + 1].name === "Lbs" &&
                        filterFields[index + 2].name === "eye_color" &&
                        filterFields[index + 3].name === "hair_color" &&
                          <div style={nameFieldStyles}>
                            <CheckStatusTagFile
                              {...{
                                item,
                                blockObject,
                                enableEditor,
                                index,
                                getLaunchEditor,
                                submitArrayField,
                                submitObject,
                                observer,
                                dispatch,
                                tagStoreWithFields,
                                setPrefilledValueInSubmitObject,
                                setValueCurrentField,
                                observers,
                                selectOptions,
                              }}
                            />
                            <CheckStatusTagFile
                              {...{
                                item: filterFields[index + 1],
                                blockObject,
                                enableEditor,
                                index,
                                getLaunchEditor,
                                submitArrayField,
                                submitObject,
                                observer,
                                dispatch,
                                tagStoreWithFields,
                                setPrefilledValueInSubmitObject,
                                setValueCurrentField,
                                observers,
                                selectOptions,
                              }}
                            />
                            <CheckStatusTagFile
                              {...{
                                item: filterFields[index + 2],
                                blockObject,
                                enableEditor,
                                index,
                                getLaunchEditor,
                                submitArrayField,
                                submitObject,
                                observer,
                                dispatch,
                                tagStoreWithFields,
                                setPrefilledValueInSubmitObject,
                                setValueCurrentField,
                                observers,
                                selectOptions,
                              }}
                            />
                            <CheckStatusTagFile
                              {...{
                                item: filterFields[index + 3],
                                blockObject,
                                enableEditor,
                                index,
                                getLaunchEditor,
                                submitArrayField,
                                submitObject,
                                observer,
                                dispatch,
                                tagStoreWithFields,
                                setPrefilledValueInSubmitObject,
                                setValueCurrentField,
                                observers,
                                selectOptions,
                              }}
                            />
                          </div>
                        }
                        {
                          item.name !== "firstname" && item.name !== "lastname" &&
                          item.name !== "height" && item.name !== "eye_color" && item.name !== "hair_color" && item.name !== "Lbs" &&
                          <CheckStatusTagFile
                            {...{
                              item,
                              blockObject,
                              enableEditor,
                              index,
                              getLaunchEditor,
                              submitArrayField,
                              submitObject,
                              observer,
                              dispatch,
                              tagStoreWithFields,
                              setPrefilledValueInSubmitObject,
                              setValueCurrentField,
                              observers,
                              selectOptions,
                            }}
                          />
                        }
                      </React.Fragment>
                    )
                  })}
                {blockObject.name === "application_block"  &&
                  blockObject.fields.length !== 0 &&
                  // blockObject.fields[0].name === "issuing")) &&
                  showingFields.map((item: FieldInterface | any) => (
                    <li
                      // eslint-disable-next-line max-len
                      className={`field-in-block ${blockObject.name} field-in-block_application-block`}
                      key={item.name + item.approve}
                    >
                      <SwitchBlock field={item} value={submitObject[item.name]} observer={observer} />
                    </li>
                  ))}
              </ul>
            )}
            {isMandatoryFees && <GMandatoryFeeItem charges={renameChargesToGovernmentFee(charges)}/>}
            {
              // eslint-disable-next-line max-len
              blockObject.blocksSteps && blockObject.blocksSteps.length !== 0 && (
                <ul
                  // eslint-disable-next-line max-len
                  className={`list-fields-in-block list-steps-in-block list-steps-in-block_${paddingTop} list-steps-in-block_${template}`}
                >
                  {getFilteredBlocks(arrayBlockSteps)?.map((item: any, index: number) => {
                    let auxAllProducts =
                      productName !== "passport" &&
                      productName !== "vital" &&
                      productName !== "ein" &&
                      productName !== "precheck" &&
                      productName !== "address" &&
                      productName !== "taxes" &&
                      productName !== "nam"

                    return (
                      <li
                        key={index}
                        // eslint-disable-next-line max-len
                        className={`field-in-block ${blockObject.name} list-steps-in-block__step-${index}-container`}
                      >
                        {item.type !== "package-page" && (
                          <>
                            {auxAllProducts && (
                              <BlocksSteps
                                blocksStep={item}
                                index={index + 1}
                                showStatus={getStatusBlock(
                                  getFilteredBlocks(arrayBlockSteps),
                                  item.status,
                                  statusSignaturePage
                                )}
                              />
                            )}
                            {productName === "passport" && (
                              <BlocksStepsPassport
                                onGatewayPaymentStartedWithToken={onGatewayPaymentStartedWithToken}
                                setStripeElements={setStripeElements}
                                blocksStep={item}
                                index={index + 1}
                                showStatus={getStatusBlock(
                                  getFilteredBlocks(arrayBlockSteps),
                                  item.status,
                                  statusSignaturePage
                                )}
                              />
                            )}
                            {productName === "vital" && (
                              <BlocksStepsCer
                                onGatewayPaymentStartedWithToken={onGatewayPaymentStartedWithToken}
                                blocksStep={item}
                                index={index + 1}
                                setStripeElements={setStripeElements}
                                showStatus={getStatusBlock(
                                  getFilteredBlocks(arrayBlockSteps),
                                  item.status,
                                  statusSignaturePage
                                )}
                              />
                            )}
                            {productName === "ein" && (
                              <BlocksStepsEin
                                blocksStep={item}
                                index={index + 1}
                                showStatus={getStatusBlock(
                                  getFilteredBlocks(arrayBlockSteps),
                                  item.status,
                                  statusSignaturePage
                                )}
                              />
                            )}
                            {productName === "precheck" && (
                              <BlocksStepsTsa
                                onGatewayPaymentStartedWithToken={onGatewayPaymentStartedWithToken}
                                blocksStep={item}
                                index={index + 1}
                                showStatus={getStatusBlock(
                                  getFilteredBlocks(arrayBlockSteps),
                                  item.status,
                                  statusSignaturePage
                                )}
                              />
                            )}

                            {productName === "address" && (
                              <BlocksStepsCoa
                                blocksStep={item}
                                index={index + 1}
                                setStripeElements={setStripeElements}
                                showStatus={getStatusBlock(
                                  getFilteredBlocks(arrayBlockSteps),
                                  item.status,
                                  statusSignaturePage
                                )}
                              />
                            )}

                            {productName === "taxes" && (
                                <BlocksStepsTax
                                    blocksStep={item}
                                    key={index + 1}
                                    index={index + 1}
                                    showStatus={getStatusBlock(
                                        getFilteredBlocks(arrayBlockSteps),
                                        item.status,
                                        statusSignaturePage
                                    )}
                                />
                            )}


                            {productName === "nam" && (
                              <BlocksStepsNam
                                setStripeElements={setStripeElements}
                                blocksStep={item}
                                index={index + 1}
                                showStatus={getStatusBlock(
                                  getFilteredBlocks(arrayBlockSteps),
                                  item.status,
                                  statusSignaturePage
                                )}
                              />
                            )}
                          </>
                        )}
                        {item.type === "package-page" && (
                          <PackageStepsComponent blocksStep={item} numberStep={index + 1} />
                        )}
                      </li>
                    )
                  })}
                </ul>
              )
            }
            {blockObject.hint && <div>{blockObject.hint}</div>}
          </div>
        </>
      )}
      {hasFormError &&(
          <div className={'g-block-form-with-fields-errors'}>
            <GNotification text={hasFormError} type={NOTIFICATION_TYPE.ERROR} icon={ICONS_SVG_TYPE.INFORMATION}/>
          </div>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
  observers: state.form.observers,
  selectOptions: state.form.selectOptions,
  currentResponse: state.step.currentResponse,
  statusQuestionary: state.additional.statusQuestionary,
  dynamicBlocks: state.step.dynamicBlocks,
  additionalSteps: state.step.additionalSteps,
  indexCurrentStep: state.step.indexCurrentStep,
  paymentError: state.form.paymentError,
  tagStoreWithFields: state.form.tagStoreWithFields,
  showStripe: state.form.showStripe,
  billingInformation: state.step.billingInformation,
  upsellProcessingInformation: state.additional.upsellProcessingInformation,
  charges: state.step.billingInformation?.charges,
  currentStep: state.step.currentStep,
  formError: state.form.error,
  userCoupon: state.billing.userCoupon,
  itemPriceCharges: state.billing.itemPriceCharges,
})

export default connect(mapStateToProps)(BlockFormWithFields)