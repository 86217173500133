import { Button, Form } from "antd"
import React from "react"
import closeModal from "../../assets/image/closeModal.svg"

type WrapperContentModalProps = {
  setValueModal: any,
  headerName: string,
  cancelButton?: string,
  submitButton?: string,
  dispatch: any,
  children: any,
}

const WrapperContentModal: React.FunctionComponent<WrapperContentModalProps> = ({
  setValueModal,
  headerName,
  cancelButton,
  submitButton,
  children,
  dispatch,
}) => {
  return (
    <div className="modal-bl">
      <div className="modal-bl__title-container">
        <h2 className="modal-bl__title">{headerName}</h2>
        <div className={`modal-bl__icon-container`}>
          <img
            src={closeModal}
            alt={"info"}
            className={`modal-bl__icon-close`}
            onClick={(e) => dispatch(setValueModal(""))}
          />
        </div>
      </div>
      {children}
      <div className="modal-bl__footer-container">
        {
          <>
            {cancelButton && (
              <button
                className={`modal-bl__footer-button modal-bl__footer-button__${cancelButton.toLowerCase()}`}
                onClick={(e) => dispatch(setValueModal(""))}
              >
                {cancelButton}
              </button>
            )}
            {submitButton && (
              <Form.Item>
                <Button
                  className={`modal-bl__footer-button modal-bl__footer-button__${submitButton.toLowerCase()}`}
                  htmlType="submit"
                >
                  {submitButton}
                </Button>
              </Form.Item>
            )}
          </>
        }
      </div>
    </div>
  )
}

export default WrapperContentModal
