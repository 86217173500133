import React from "react"
import { SvgType } from "../SvgType"

export const SvgIconCheckCircle = ({ width = 25, height = 24 }: SvgType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 21.9961C18.0228 21.9961 22.5 17.5189 22.5 11.9961C22.5 6.47325 18.0228 1.99609 12.5 1.99609C6.97715 1.99609 2.5 6.47325 2.5 11.9961C2.5 17.5189 6.97715 21.9961 12.5 21.9961Z"
        stroke="#03874F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.0625 12.4102L10.4572 14.7504C10.6538 14.9426 10.9686 14.9399 11.162 14.7446L16.9375 8.91016"
        stroke="#03874F"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
