import React from "react"

interface ISgvAuthActivate {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
}

export const SvgAuthActivate = ({ width, height, color, backgroundColor }: ISgvAuthActivate) => {
  return (
    <svg width={width} height={height} viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.585938" width={width} height={height} rx="32" fill={backgroundColor} />
      <g clipPath="url(#clip0_14780_308718)">
        <path
          d="M40.9983 22.5781L38.9983 24.5781M31.3883 32.1881L35.4983 28.0781M35.4983 28.0781L38.4983 31.0781L41.9983 27.5781L38.9983 24.5781M35.4983 28.0781L38.9983 24.5781M31.3883 32.1881C31.9047 32.6976 32.3151 33.3042 32.5961 33.9729C32.877 34.6417 33.0229 35.3594 33.0253 36.0848C33.0278 36.8101 32.8867 37.5288 32.6102 38.1994C32.3338 38.87 31.9274 39.4794 31.4145 39.9923C30.9016 40.5052 30.2922 40.9116 29.6216 41.188C28.951 41.4645 28.2323 41.6056 27.507 41.6032C26.7816 41.6007 26.0639 41.4548 25.3951 41.1739C24.7264 40.8929 24.1198 40.4825 23.6103 39.9661C22.6085 38.9288 22.0541 37.5395 22.0666 36.0974C22.0791 34.6553 22.6576 33.2759 23.6773 32.2561C24.6971 31.2364 26.0765 30.6579 27.5186 30.6454C28.9607 30.6329 30.35 31.1873 31.3873 32.1891L31.3883 32.1881Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14780_308718">
          <rect width="24" height="24" fill="white" transform="translate(20 20.5859)" />
        </clipPath>
      </defs>
    </svg>
  )
}
