import visaCard from "../assets/image/card-visa.svg"
import amexCard from "../assets/image/card-amex.svg"
import masterCard from "../assets/image/mastercard.svg"
import discoveryCard from "../assets/image/card-discovery.svg"
import defaultCard from "../assets/image/default_card.png"
import jcbCard from "../assets/image/card-jcb.svg"
import dinerClubCard from "../assets/image/card-dinerclub.svg"

export function getCardName (str: string): string {
    if (str === "visa") {
        return "VISA"
    } else if (str === "mastercard") {
        return "Master Card"
    } else if (str === "american_express") {
        return "American Express"
    } else if (str === "discover") {
        return "Discover"
    } else if (str === "diners_club") {
        return "Diner's Club"
    } else if (str === "jcb") {
        return "JBC"
    } else if (str === "dinerclub") {
        return "Diner's Club"
    } else {
        return "Unknown"
    }
}

export function getCardImage (str: string)  {
    if (str === "visa") {
        return visaCard
    } else if (str === "mastercard") {
        return masterCard
    } else if (str === "american_express") {
        return amexCard
    } else if (str === "discover") {
        return discoveryCard
    } else if (str === "diners_club") {
        return defaultCard
    } else if (str === "jcb") {
        return jcbCard
    } else if (str === "dinerclub") {
        return dinerClubCard
    } else {
        return false
    }
}