import Event from "../Event"
import { EventOptions, EventProps } from "../EventProperties"
import IEvent from "../IEvent"

const EVENT_NAME = "Identity Protection Alert Confirmed";

/**
 * Identity Protection Alert Confirmed Event
 * @class IdentityProtectionAlertConfirmed
 * @extends {Event}
 * @implements {IEvent}
 * @description Event for Identity Protection Alert Confirmed
 */
export default class IdentityProtectionAlertConfirmed extends Event implements IEvent {
  private readonly _alertClass: string | undefined;
  private readonly _alertId: string | undefined;
  private readonly _firstName: string | undefined;
  private readonly _lastName: string | undefined;
  private readonly _email: string | undefined;
  private readonly _phone?: string | undefined; 

  constructor(
    alertClass: string | undefined,
    alertId: string | undefined,
    firstName: string | undefined,
    lastName: string | undefined,
    email: string | undefined,
    phone: string | undefined,
    ipAddress: string | undefined,
  ) {
    super(EVENT_NAME, ipAddress);
    this._alertClass = alertClass;
    this._alertId = alertId;
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
    this._phone = phone;
  }

  getEventData(): EventProps {
    return {
      ...this.baseEventData(),
      alert_class: this._alertClass,
      alert_id: this._alertId,
      firstName: this._firstName,
      lastName: this._lastName,
      email: this._email,
      phone: this._phone,
    };
  }

  getEventName(): string {
    return this.eventName;
  }

  pageName(): string {
    return "";
  }

  getPreviousId(): string {
    return "";
  }

  getUserId(): string {
    return "";
  }

  getEventOptions(): EventOptions | undefined {
    return undefined;
  }
}

