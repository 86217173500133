import { call, put, select, takeEvery } from "redux-saga/effects"
import { ActionTypes, getFailureAction, setAnswerCheckStatus } from "./cash.action"
import Service from "../../../services/form-service"
import {
  getAdditionalArraySteps,
  setAdditionalSteps,
  setBillingInformation,
  setCurrentResponse,
  setDynamicBlocks,
  setIndexCurrentStep,
  setLabelForm,
  setOptionsForSelect,
  setValidationRulers,
} from "../step/step.actions"
import {
  removeVariableCurrentField,
  setBillingAddress,
  setClientEmail,
  setClientId,
  setCustomer,
  setDocumentList,
  setFormId,
  setPaymentMethod,
  setAppId,
  setPDF,
  setPricing,
  setQuestionary,
  setServicesCustomer,
  setSubmitObject,
  setUserIp, setValueCurrentField,
  showAddPaymentButton,
} from "../form/form.actions"
import { setPackageResponse } from "../packagePages/packagePages.action"
import {
  setCanNotarize, setEinNumber, setNotarizationLink, setTaxReturnInformation,
  setUpsellInformation,
  setUpsellProcessingInformation
} from "../additionalForm/additional.actions"
import {getPath, getSuccess, processingPages} from "../form/form.saga"
import { setUploadDocuments } from "../upload/upload.actions"
import { setStatusSignaturePageSaga } from "../upload/upload.saga"
import { checkLaunchFunctionByPageName, setStatusesCommonProducts } from "../../../services/statusesService/setStatuses"
import { arrayPagesName } from "../../../services/statusesService/dateByStatuses"
import { setLoadingState } from "../loading/loading.action"
import { LoadingState } from "../../../shared/constans/user-from-view-mode.enum"
import ServiceDashboard from "../../../services/requests/dashbordService"
import {
  delay,
  getAllSteps,
  getClientTokenForResponse,
  getParamsFromGetParams, getRoot,
  saveDynamicEvents,
} from "../step/service.step.saga"
import { getTopScrollPosition } from "../../../Components/FormComponents/FormBlock/FormBlock"
import {getWebFlowByIdKey, getWebFlowSolutions} from "../user/user.actions"
import {getPropertiesForEvents} from "../../../services/gtm/backEndEvent";
import FlattenDataDto from "../../../dto/FLattenDataDto/FlattenDataDto";
import OuterIdRepository from "../../../persistence/session/OuterIdRepository";
import {putUpsellProcessingInformation} from "../additionalForm/UpsellProcessing";
import {putBillingInformation} from "../billing/BillingInformation";
import {AdditionalSagaHelper} from "../additionalForm/AdditionalSagaHelper";
import {Path} from "../../../util/PageHelper";
import ApplicationIdRepository from "../../../persistence/session/ApplicationIdRepository";
import {FormSagaHelper} from "../form/FormSagaHelper";
import {setItemPriceCharges} from "../billing/billing.action";

function* setCashStateSaga(actions: any): any {
  try {
    const { client_token } = yield getClientTokenForResponse()
    const submitObj = yield { ...actions.payload, client_token, check: true }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (yield client_token && submitObj) {
      const response = yield Service.cacheForm(submitObj)
    }
  } catch (e) {
    //@ts-ignore
    yield put(getFailureAction(e))
  }
}

function* getCashStateSaga(actions: any): any {
  try {
    const { client_token, statusClientTokenGetParam, statusClientTokenCookie, client_token_cookie } =
      yield getClientTokenForResponse()
    const { form_id } = yield getPropertiesForEvents()
    const { fromDashboardSignature, applicationId: application_id_get_params } = yield getParamsFromGetParams()
    const application_id_store = yield ApplicationIdRepository.getInstance().getValue()
    const application_id = yield application_id_store ?? application_id_get_params
    const submitObj = yield {
      ...actions.payload,
      client_token,
      fromDashboardSignature,
      application_id,
    }
    yield put(setLoadingState(LoadingState.Loading))

    yield getTopScrollPosition()
    let response
    let newSubmitObj
    if (application_id && client_token && submitObj && form_id && form_id !== "undefined" && form_id !== "null") {
      response = yield Service.checkStatusForm(submitObj)
      const flattenData = response?.success?.form?.prefillData?.flattenData
      const flattenDataHandler = flattenData ? new FlattenDataDto(flattenData) : null;
      newSubmitObj = {
        ...submitObj,
        flattenData,
        date_birth: flattenDataHandler ? flattenDataHandler.dateOfBirth : ""
      }
    } else {
      throw new Error("missing data in submit body")
    }
    const success = yield getSuccess(response)

    if (response?.error && typeof response?.error !== "object") {
      yield put(setAnswerCheckStatus(response?.error))
      throw new Error(response?.error)
    }

    if (response?.error && typeof response?.error === "object") {
      throw new Error("redirect to formApp")
    }

    if (success?.dashLink) {
      yield delay(3000)
      yield window.location.href = `${getRoot()}${success?.dashLink}`
    }

    if (success?.outer_id && !OuterIdRepository.getInstance().getValue()) {
      yield OuterIdRepository.getInstance().setValue(response?.success?.outer_id);
    }

    yield put(getWebFlowByIdKey())
    yield put(getWebFlowSolutions())

    yield saveDynamicEvents(getAllSteps(false, success.form))

    const additionalSteps = yield success?.form?.additionalSteps
    yield put(setAdditionalSteps(additionalSteps))

    if (success?.result?.result) {
      yield put(setPackageResponse(success?.result?.result))
    }

    const optionsForSelect = yield success?.form?.predefinedOptions
    yield put(setOptionsForSelect(optionsForSelect))
    yield put(setIndexCurrentStep(0))

    if (success?.page) {
      yield put(getAdditionalArraySteps(success.page))

      if (success?.page === "taxes_after") {
        if(success.user_url_response) {
          yield call(AdditionalSagaHelper.setUserTaxUrl, success?.user_url_response?.user_url);
        }
        if(success?.tax_return) {
          yield put(setTaxReturnInformation(success?.tax_return));
        }
      }
    }

    yield put(setCurrentResponse(success.checkAddress))
    if (success?.sidebarData) {
      yield put(setClientId(success?.sidebarData?.clientId))
      yield put(setClientEmail(success?.sidebarData?.email))
    }
    yield put(setBillingAddress(success?.address))
    yield sessionStorage.setItem("billingAddress", JSON.stringify(success?.address))
    yield put(setBillingAddress(success?.address))
    yield put(setCustomer(success?.customer))
    // for dynamic options in select
    if (success?.services) {
      yield put(setServicesCustomer(success?.services))
    }
    if (success?.selectedServices) {
      yield put(setCurrentResponse(success?.selectedServices))
    }
    if (success?.pricingVars) {
      yield put(setPricing(success?.pricingVars))
    }
    if (success?.billingAddress) {
      yield put(setBillingAddress(success?.billingAddress))
    }
    yield put(setFormId(form_id))
    yield put(setAppId(application_id))
    yield put(setPDF(success?.pdf))

    if (success?.questions) {
      yield put(setQuestionary(success?.questions?.questionnaire))
    }

    if (success?.documentList) {
      yield put(setDocumentList(success?.documentList))
    }
    if (success?.uploadData) {
      yield put(setUploadDocuments(success?.uploadData))
    }
    if(success?.form?.unique_session_id){
      yield put(setValueCurrentField("unique-section-id", success.form.unique_session_id))
    }
    if (success?.form?.dynamicBlocks) {
      yield put(setDynamicBlocks(success?.form?.dynamicBlocks))
    }
    if (success?.form?.steps) {
      yield put(setLabelForm(success?.form?.steps[0]["form_label"]))
      yield put(setValueCurrentField("form_type", success?.form?.steps[0]?.["form_type"]))
    }

    if (success?.form?.prefillData && success.page === "passport_after" && success.status === "questionnaire") {
      const { form_type, form_type__full_name, ...prefillData } = success?.form?.prefillData?.data
      yield put(setSubmitObject({ ...prefillData, status: success?.status }))
    }
    // TODO Move to constants. Check questionnaire status. Check if we just use passport_after or if it's needed to add the new string.
    if (success?.form?.prefillData && (success.page !== "passport_after" || success.page !== "passport_new_after" || success.status !== "questionnaire")) {
      yield put(setSubmitObject({...success?.form?.prefillData?.data, status: success?.status }))
    }
    if (success?.form?.validation) {
      yield put(setValidationRulers(success?.form?.validation))
    }
    if (success?.canNotarize) {
      yield put(setCanNotarize(success?.canNotarize))
    }

    if (success?.notarization_link) {
      yield put(setNotarizationLink(success?.notarization_link))
    }

    if (success?.einNumber) {
      yield put(setEinNumber(success?.einNumber))
    }

    const objUpdated = yield call(FormSagaHelper.updatePropertyForFilterStep, success);
    if(Object.keys(objUpdated).length > 0) {
      yield put(setSubmitObject(objUpdated))
    }
    if (success?.upsellInformation) {
      yield put(setUpsellInformation(success?.upsellInformation))
    }

    yield putUpsellProcessingInformation(success)

    if (yield call(checkLaunchFunctionByPageName, arrayPagesName, success?.page)) {
      yield call(setStatusSignaturePageSaga, success)
      yield call(setStatusesCommonProducts, success?.form?.additionalSteps, success?.status, success?.page)
    }

    if (success?.client_token) {
      yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
    }

    if (success && success.page === "payment") {
      yield getPath({ nameGetParam: "page", valueGetParam: "payment" })
    }

    if (success && success.page === "upsell") {
      yield getPath({ nameGetParam: "page", valueGetParam: "upsell" })
    }

    if (success && processingPages.includes(success.page)) {
      yield getPath({ nameGetParam: "page", valueGetParam: "processing" })
    }

    if (success.billingInformation) {
      yield putBillingInformation(success.billingInformation)
    }

    if (success?.itemPriceCharges) {
      yield put(setItemPriceCharges(success?.itemPriceCharges))
    }

    yield put(setLoadingState(LoadingState.Loaded))
  } catch (e) {
    //@ts-ignore
    yield put(getFailureAction(e))
    console.log(e)
    if(e === "redirect to formApp") {
      yield delay(1000)
      yield (window.location.href = `${getRoot()}/formsApp`)
    }
    yield put(setLoadingState(LoadingState.Loaded))
  }
}

export default function* cashSaga() {
  yield takeEvery(ActionTypes.SET_CASH_STATE, setCashStateSaga)
  yield takeEvery(ActionTypes.GET_CASH_STATE, getCashStateSaga)
}
