import BaseCookiesRepository from "./BaseCookiesRepository";

const FBP_VARS_KEY = "_fbp";

class FbpRepository extends BaseCookiesRepository {

    static FBP_DEFAULT_VALUE = "";

    private static _instance: FbpRepository;

    private constructor() {
        super()
    }

    public static getInstance(): FbpRepository {
        if (!FbpRepository._instance) {
            FbpRepository._instance = new FbpRepository();
        }
        return FbpRepository._instance;
    }

    getKeyName(): string {
        return FBP_VARS_KEY;
    }
}

export default FbpRepository;
