import React from "react";
import "./index.less";
import GLogo from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Logo/Logo";
import {Path} from "@dash/gov-plus__front-end__form/src/util/PageHelper";

const Header = () => {
    const logoUrl = Path.NEW_APPLICATION;
    return (
        <div className={"Header"}>
            <div
                className={'Header_linkWrapper'}
            >
                <div className={'Header_iconBox'}>
                    <GLogo url={logoUrl}/>
                </div>
            </div>
        </div>
    )
}

export default Header
