import React from "react"

interface ISvgLogout {
    width: number;
    height: number;
    color: string;
}

export const SvgLogout = ({ width = 20, height = 20, color = "#6E6E6E" }: ISvgLogout) => {
    const viewBox = `0 0 ${width} ${height}`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} fill="none">
            <path d="M7.5 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H7.5" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.3334 14.1663L17.5 9.99967L13.3334 5.83301" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.5 10H7.5" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
