import React, { FC, useEffect, useRef } from "react"
import {
  formSelector,
  renderBasicBlockContainer,
} from "../UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { BasicBlockContainerProps } from "../UploadPhotoService/interfaces/interfaces"
import LayoutBaseContainer from "../Layouts/LayoutBaseContainer/LayoutBaseContainer"
import { useSelector } from "react-redux"
import { RootState } from "../../../../App"
import { arrayClasses, filterChildByClass, initialEmail } from "./DynamicContentService"

const getDynamicText = (text: string, className: string, ref: React.RefObject<HTMLParagraphElement>) => (
  <p ref={ref} className={`${className}-dynamic`} dangerouslySetInnerHTML={{ __html: text }} />
)

const DynamicContentComponent: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text, state },
  arrayComponents,
}) => {
  const ref = useRef(null)
  const { email = initialEmail } =
    useSelector<RootState, { customer: { email: string } | null }>(formSelector)?.customer ?? {}

  useEffect(() => {
    filterChildByClass(Array.from(ref?.current?.["children"] || []), arrayClasses, email)
  }, [email])

  return renderBasicBlockContainer(
    { className, text: getDynamicText(text, className, ref), childrenItem, arrayComponents, state },
    LayoutBaseContainer
  )
}

export default DynamicContentComponent
