import React, { FC } from "react"
import { SIZE } from "../../../../types/types"
import {COLORS, COLORS_NEW} from "../../../../types/Colors"
import GText from "../../../../atoms/Text/Text"
import "./GSubscriptionPaymentImageDescription.scss"

export interface GSubscriptionPaymentImageDescription {
  title: string;
  titleSize?: string;
  titleColor?: string;
  image: string;
}

const GSubscriptionPaymentImageDescription: FC<GSubscriptionPaymentImageDescription> = ({
  title,
  titleSize = SIZE.HEADING_MD,
  titleColor = COLORS_NEW.BLACK_1000,
  image
}) => {
  return (
    <div className="GSubscriptionPaymentImageDescription">
      <div className="GSubscriptionPaymentImageDescription__Title">
        <GText text={""} innerHtml={title} size={titleSize} color={titleColor} maxWidth="560px" />
      </div>
      <img src={image} alt={""} />
    </div>
  )
}

export default GSubscriptionPaymentImageDescription
