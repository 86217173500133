import {call, put, takeEvery} from "redux-saga/effects"
import {
  ActionTypes,
  getFailureAction,
  setAnswerEmail,
  setArrayCountryCode,
  setCheckCustomerByEmail,
  setStatusAuthModal,
} from "./email.action"
import Service from "../../../services/form-service"
import { LoadingState } from "../../../shared/constans/user-from-view-mode.enum"
import { setLoadingButtonState } from "../loading/loading.action"
import { setValueModal } from "../form/form.actions"

import arrayCountryCodes from "../../../Components/FormComponents/FieldsComponents/PhoneInputComponent/arrayCountryCodes.json"
import ServiceDashboard from "../../../services/requests/dashbordService"
import { getArraySteps } from "../step/step.actions"
import { getClientTokenForResponse } from "../step/service.step.saga"
import {AdditionalSagaHelper} from "../additionalForm/AdditionalSagaHelper";

function* getFailure(e: {} | any) {
  yield put(getFailureAction(e))
  yield put(setLoadingButtonState(LoadingState.Error))
}

function* getAnswer(response: {} | any): any {
  if (response.error) {
    yield put(getFailureAction(response))
    yield put(setLoadingButtonState(LoadingState.Loaded))
    const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time))
    yield delay(3000)
    yield put(getFailureAction(""))
  }
  yield put(setAnswerEmail(response))
  yield put(setLoadingButtonState(LoadingState.Loaded))
}

function* sendEmailSaga(actions: any): any {
  try {
    yield put(setLoadingButtonState(LoadingState.Loading))
    const response = yield Service.submitAdditionalForm(actions.payload)
    yield getAnswer(response)
  } catch (e) {
    getFailure(e)
  }
}

function* sendMessageSaga(actions: any): any {
  try {
    yield put(setLoadingButtonState(LoadingState.Loading))
    yield put(getFailureAction(""))
    const response = yield Service.sendMessage(actions.payload)
    yield getAnswer(response)
    if (response && response.success) {
      yield put(setValueModal("thank-you-page"))
    }
  } catch (e) {
    getFailure(e)
  }
}

function* checkCustomerByEmailSaga({ payload }: any): any {
  try {
    // console.log("payload", payload)
    // const { client_token_cookie } = yield getClientTokenForResponse()
    // if (client_token_cookie) {
    const response = yield ServiceDashboard.checkUserByEmail(payload)
    yield put(setCheckCustomerByEmail(response))
    // yield put(setValueModal("login_user"))
    // }
  } catch (e) {
    getFailure(e)
  }
}

function* getArrayCountryCodesSaga(): any {
  try {
    const response = yield arrayCountryCodes
    yield put(setArrayCountryCode(response))
  } catch (e) {
    getFailure(e)
  }
}

function* logInCustomerByEmailSaga({ payload }: any): any {
  try {
    const responseAuthDashboard = yield ServiceDashboard.logInCustomerByEmail(payload)
    if (responseAuthDashboard?.messages === "common.SUCCESSFUL_REQUEST") {
      yield call(AdditionalSagaHelper.setAuthBearerToken, responseAuthDashboard?.data?.token);
      // yield put(getArraySteps())
      yield put(setValueModal(""))
    }
  } catch (e) {
    // console.log(e)
    yield put(setStatusAuthModal("sms"))
  }
}

export default function* emailSaga() {
  yield takeEvery(ActionTypes.SEND_EMAIL, sendEmailSaga)
  yield takeEvery(ActionTypes.SEND_MESSAGE, sendMessageSaga)
  yield takeEvery(ActionTypes.GET_CHECK_CUSTOMER_BY_EMAIL, checkCustomerByEmailSaga)
  yield takeEvery(ActionTypes.GET_ARRAY_COUNTRY_CODES, getArrayCountryCodesSaga)
  yield takeEvery(ActionTypes.LOG_IN_CUSTOMER_BY_EMAIL, logInCustomerByEmailSaga)
}
