import React from "react"

interface ISvgCheckCircle {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
}

export const SvgCheckCircle = ({ width, height, color, backgroundColor }: ISvgCheckCircle) => {
  const mobileView = window.innerWidth < 600
  return (
    mobileView ? 
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill={backgroundColor}/>
      <path d="M17 8.25L10.125 15.125L7 12" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    :
    <svg width={width} height={height} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.65625" width="36" height="36" rx="18" fill={backgroundColor} />
      <path
        d="M25.1654 14.0703L15.9987 23.237L11.832 19.0703"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
