/* eslint-disable indent */
import { ActionTypes } from "./loading.action"
import { Action } from "../../interfacesAction/action.interface"

export interface State {
  loadingState: string;
  loadingButtonState: string;
}

const initialState: State = {
  loadingState: "loaded",
  loadingButtonState: "loaded",
}

export const loadingReducer = (state: State = initialState, action: Action<String>) => {
  switch (action.type) {
    case ActionTypes.SET_LOADING_STATE:
      return { ...state, loadingState: action.payload }
    case ActionTypes.SET_LOADING_BUTTON_STATE:
      return { ...state, loadingButtonState: action.payload }
    default:
      return state
  }
}
