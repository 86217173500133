import { FC } from "react"
import React from "react"
import "./GIconDescription.scss"
import IconsSvg from "../../atoms/IconsSvg/IconsSvg"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS, COLORS_NEW } from "../../types/Colors"

export interface IGIconDescription {
  icon: string;
  description: string;
}

export type GIconDescriptionProps = {
  props: IGIconDescription,
}

const GIconDescription: FC<GIconDescriptionProps> = ({ props }) => {
  return (
    <div className="GIconDescription">
      <IconsSvg
          width={57}
          height={56}
          type={props.icon}
          color={COLORS_NEW.ROYAL_BLUE_700}
          backgroundColor={COLORS_NEW.ROYAL_BLUE_200}
      />
      <GText text={props.description} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_800} innerHtml={props.description} />
    </div>
  )
}
export default GIconDescription
