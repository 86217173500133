import React, {FC} from "react";
import './GServiceCard.scss'

interface GServiceCardProps {
    imageURL?: string,
    showBadge?: boolean,
    badgeText?: string,
    badgeIcon?: string,
    Content: FC<any>,
    className?: string,
    serviceIsActivated?: boolean
}

const GServiceCardInactive: FC<GServiceCardProps> = (
    {
        imageURL,
        Content,
        className,
    }
) => {

    return <div className={`GServiceCardInactive ${className || ''}`} >
        <div className={'GServiceCardInactive__Content'}>
            {
                imageURL && <div className={'GServiceCardInactive__Content__ImageWrap'}>
                                <img src={imageURL} />
                            </div>
            }
            <Content />
        </div>

    </div>
}

export default GServiceCardInactive