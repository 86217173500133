export const breadCrumbsArr = (productsData, productId, params) => (
  {
    ["/formsApp"]: [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "New application",
      }
    ],
    ["/products"]: [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "My applications",
      }
    ],
    ["/forms/"]: [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "New application",
      },
      {
        breadcrumbName: productsData?.title,
      },
      {
        breadcrumbName: productsData?.text,
      }
    ],
    [`/forms/?formId=${params?.formId}&&applicationId=${params?.applicationId}`]: [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "New application",
      },
      {
        breadcrumbName: productsData?.title,
      },
      {
        breadcrumbName: productsData?.text,
      }
    ],
    [`/forms/?formId=${params?.formId}&&applicationId=${params?.applicationId}&&fromDashboardSignature=true`]: [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "New application",
      },
      {
        breadcrumbName: productsData?.title,
      },
      {
        breadcrumbName: productsData?.text,
      }
    ],
    [`/products/productInfo/${productId}`]: [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "My applications",
      },
      {
        breadcrumbName: productsData?.title,
      },
      {
        breadcrumbName: productsData?.text,
      }
    ],
    ["/referFriend"]: [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "Refer a friend",
      }
    ],
    ["/myInsuredDocuments"]: [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "My insured documents",
      }
    ],
    ["/myAutomaticRenewals"]: [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "My automatic renewals",
      }
    ],
    ["/myFamily"]: [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "My family",
      }
    ],
    ["/billing-settings"]: [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "Billing settings",
      }
    ],
    ['/SubscriptionCancelApplications'] : [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "Billing settings",
      },
      {
        breadcrumbName: "Cancel plan",
      }
    ],
    ['/SubscriptionCancelInsurance'] : [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "Billing settings",
      },
      {
        breadcrumbName: "Cancel plan",
      }
    ],
    ['/SubscriptionCancelRenewal'] : [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "Billing settings",
      },
      {
        breadcrumbName: "Cancel plan",
      }
    ],
    ['/SubscriptionCancelReason'] : [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "Billing settings",
      },
      {
        breadcrumbName: "Cancel plan",
      }
    ],
    ['/SubscriptionCancelled'] : [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "Billing settings",
      },
      {
        breadcrumbName: "Cancel plan",
      }
    ],
    ['/myIdentity'] : [
      {
        path: '/formsApp',
        breadcrumbName: 'Dashboard',
      },
      {
        breadcrumbName: "My identity protection",
      },
    ],
  }
);
