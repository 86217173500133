import React from "react"

export const SvgGuard = ({ width = 28, height = 28, backgroundColor = "#13BA68", color = "#FFF" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16287_7401)">
        <circle cx="14.5" cy="14" r="14" fill={backgroundColor} />
        <path
          d="M14.768 20.1003C14.768 20.1003 19.8059 17.7669 19.8059 14.2669V10.1836L14.768 8.43359L9.7301 10.1836V14.2669C9.7301 17.7669 14.768 20.1003 14.768 20.1003Z"
          stroke={color}
          strokeWidth="1.06061"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.7524 14.3586L13.6924 15.2847C13.7963 15.3871 13.9636 15.3863 14.0665 15.2828L16.7898 12.5439"
          stroke={color}
          strokeWidth="1.06061"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16287_7401">
          <rect width="28" height="28" fill={color} transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
