import React, { FC } from "react"
import { Input } from "antd"
import "./InputPassword.scss"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import { COLORS } from "../../types/Colors"
import {FULL_HISTORY_MASK_CLASS_NAME} from "../../../../util/FullHistoryMaskedFields";

export enum INPUT_PASSWORD_STATUS {
  DEFAULT = "Default",
  ERROR = "Error",
  WARNING = "Warning",
  SUCCESS = "Success",
}

type GInputPasswordProps = {
  onChange: any,
  onBlur: any,
  status?: string,
}

const GInputPassword: FC<GInputPasswordProps> = ({ onChange, onBlur, status = INPUT_PASSWORD_STATUS.DEFAULT }) => {
  let iconColor = COLORS.SLATE_GRAY_500
  if (status === INPUT_PASSWORD_STATUS.ERROR) {
    iconColor = COLORS.RED_500
  }
  return (
    <Input.Password
      onChange={onChange}
      onBlur={onBlur}
      type={`password`}
      iconRender={(visible) =>
        visible ? (
          <GIconsSvgType type={ICONS_SVG_TYPE.LOCK_OPEN} width={20} height={20} color={iconColor} />
        ) : (
          <GIconsSvgType type={ICONS_SVG_TYPE.LOCK_CLOSE} width={20} height={20} color={iconColor} />
        )
      }
      className={`GInputPassword__Input GInputPassword__Input--${status} ${FULL_HISTORY_MASK_CLASS_NAME}`}
    />
  )
}

export default GInputPassword
