import React from "react"

interface ISvgCheck {
    width: number;
    height: number;
    color?: string;
    backgroundColor?: string;
}

export const SvgLocation = ({width = 65, height = 65, color = "#216DE3", backgroundColor = "#E2EEFF"}: ISvgCheck) => {
    const viewBox = `0 0 ${width} ${height}`
    const rx = width/2
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.833008" width={width} height={height} rx={rx} fill={backgroundColor}/>
            <g clipPath="url(#clip0_4566_90002)">
                <path d="M32.233 26.4C32.233 32 25.033 36.8 25.033 36.8C25.033 36.8 17.833 32 17.833 26.4C17.833 24.4904 18.5916 22.659 19.9418 21.3088C21.2921 19.9585 23.1234 19.2 25.033 19.2C26.9426 19.2 28.7739 19.9585 30.1242 21.3088C31.4744 22.659 32.233 24.4904 32.233 26.4Z"
                      stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M25.0328 28.8C26.3583 28.8 27.4328 27.7255 27.4328 26.4C27.4328 25.0745 26.3583 24 25.0328 24C23.7073 24 22.6328 25.0745 22.6328 26.4C22.6328 27.7255 23.7073 28.8 25.0328 28.8Z"
                      stroke="#216DE3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M39.165 28H32.165" stroke="#216DE3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M37.165 33H29.4893" stroke="#216DE3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M37.165 23H31.583H33.8317" stroke="#216DE3" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_4566_90002">
                    <rect width="24" height="24" fill="white" transform="translate(16.833 16)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
