export const useUrlParams = () => {
  const urlQuery = window.location.search
  const urlParams = new URLSearchParams(urlQuery)
  const page = urlParams.get("page")

  const processingPage = page === "processing"
  const paymentPage = page === "payment"

  return {
    page,
    processingPage,
    paymentPage
  }
}