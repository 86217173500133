import { FC, useEffect, useMemo, useState } from "react"
import { BasicBlockContainerProps } from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { getPropertiesForEvents } from "../../services/gtm/backEndEvent"
import { useSelector } from "react-redux"
import { RootState } from "../../App"
import {
  renderBasicBlockContainer,
  userSelector,
} from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import LayoutBaseContainer from "../../Components/FormComponents/UploadPhoto/Layouts/LayoutBaseContainer/LayoutBaseContainer"

const HeaderDynamicTextWebFlowLayout: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text, initial_text, state, replace_text },
  arrayComponents,
}) => {
  const { service, solutionName, webFlowData: webFlowDataLocalStorage, form_id } = getPropertiesForEvents()
  const percents = useSelector<RootState, { percents: any }>(userSelector).percents ?? ""

  const webFlowDataStore = useSelector<RootState, { webFlowData: any }>(userSelector).webFlowData
  const webFlowData = webFlowDataStore ?? webFlowDataLocalStorage

  const productFirstStep = webFlowData?.[form_id ?? ""]
  const { funnelFormHeader } = productFirstStep ?? {}

  const [textRender, setTextRender] = useState("")

  const getReplace = (text: string) => {
    if (replace_text) {
      return text.replace(replace_text, funnelFormHeader ?? "")
    }
    return text.replace("{product}", service)?.replace("{solution}", solutionName)
  }

  const percentsFields = useMemo(() => percents && Number(percents?.oneProduct), [])

  useEffect(() => {
    if (initial_text && !percentsFields) {
      setTextRender(getReplace(initial_text))
    } else {
      setTextRender(getReplace(text))
    }
  }, [getReplace, initial_text, percents, percentsFields, text])

  return renderBasicBlockContainer(
    { className, text: textRender, childrenItem, arrayComponents, state },
    LayoutBaseContainer
  )
}

export default HeaderDynamicTextWebFlowLayout
