import React, {FC, useEffect, useState} from "react";
import "./GSubscribeWindow.scss";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import GButton, { BUTTON_TYPE } from "../../molecules/Button/GButton";
import {SubscriptionText, SubscriptionTextLists, AfterPaymentOfferText, UpgradeOfferBenefits, VariablesToBeReplaced} from "../../../../shared/text/Text";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {COLORS_NEW} from "../../types/Colors";
import productsImg from "../../../../assets/image/upgrade/applications-horizontal-popup.avif"
import productsImgMobile from "../../../../assets/image/upgrade/applications-horizontal-popup-mobile.avif"
import GTag from "../../molecules/Tag/GTag";
import ProductsTableList from "../ProductsTableList/ProductsTableList";
import { useApplications } from "../../../../../../dashboard-govplus-front/src/hooks/useApplications";
// @ts-ignore
import { useSubscription } from "../../../../../../dashboard-govplus-front/src/hooks/useSubscription";
import { PaymentModelTypes } from "../../../../../../dashboard-govplus-front/src/constants/PaymentModelTypes";
import autofillOfferImageMobile from "../../../../assets/image/upgrade/auto-fill-upsell-mobile.avif"
import expeditedOfferImageMobile from "../../../../assets/image/upgrade/expedited-processing-upsell-mobile.avif"
import renewalOfferImageMobile from "../../../../assets/image/upgrade/auto-renewal-offer-mobile.avif"
import insuranceOfferImageMobile from "../../../../assets/image/upgrade/insurance-offer-mobile.avif"
import idpOfferImageMobile from "../../../../assets/image/upgrade/total-idp-offer-mobile.avif"
import expertReviewImageMobile from "../../../../assets/image/upgrade/expert-revew-offer-mobile.avif"
import expressAssistanceImageMobile from "../../../../assets/image/upgrade/express-assistance-offer-mobile.avif"
import SubscriptionOfferBenefitItem from "../../molecules/SubscriptionOfferBenefitItem/SubscriptionOfferBenefitItem";
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes";
import GSubscriptionsMethodsForm from "../../templates/SubscriptionMethodsForm/SubscriptionMethodsForm";
import { SUBSCRIPTION_PERIOD_MONTHLY, SUBSCRIPTION_PERIOD_YEARLY } from "../../../../shared/constans/payment";
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg";
import { usePayment } from "../../../../../../dashboard-govplus-front/src/hooks/usePayment";
import subscriptionMethods from '../../templates/SubscriptionMethodsForm/subscriptionMethods.json'
import { SubscriptionTrialOptions } from "../../../../../../dashboard-govplus-front/src/constants/SubscriptionTrialOptions";


interface GSubscribeWindow {
    onSubscribe: (period?: string, trial?: string) => void,
    loading?: boolean,
    benefitsList: string[]
}

const GSubscribeWindow: FC<GSubscribeWindow> = (
    {
        onSubscribe,
        loading = false,
        benefitsList = SubscriptionTextLists.HYBRID_CAPABILITIES_LIST
    }
) => {
    const [subscriptionSelectedPrice, setSubscriptionSelectedPrice] = useState(119.88);
    const [selectedSubscriptionMethod, setSelectedSubscriptionMethod] = useState(SUBSCRIPTION_PERIOD_YEARLY);
    const {
        getBillingInformation
    } = usePayment()

    const selectSubscriptionPeriod = (period: string) => {
        switch (period) {
            case SUBSCRIPTION_PERIOD_YEARLY:
                setSubscriptionSelectedPrice(119.88)
                break;
            case SUBSCRIPTION_PERIOD_MONTHLY:
                setSubscriptionSelectedPrice(11.99)
                break;
            default:
                break;
        }
        setSelectedSubscriptionMethod(period)
    }

    const getRenewalPeriod = () => {
        switch (selectedSubscriptionMethod) {
            case SUBSCRIPTION_PERIOD_YEARLY:
                return 'year'
            case SUBSCRIPTION_PERIOD_MONTHLY:
                return 'month'
            default:
                return '';
        }
    }

    useEffect(() => {
        getBillingInformation()
    }, [])

    const breakpoint = useBreakpoint()
    const {availableProducts} = useApplications()
    const {paymentModel} = useSubscription()

    const products = availableProducts
        .filter(product => product.active)
        .sort((a,b) => a.priority - b.priority)
        .map(product => `${product.title} ($67 value)`)
    
    const basePrice = 119.88
    const baseMonthlyPrice = 11.99
    const discountPrice = undefined

    switch (paymentModel) {
        case PaymentModelTypes.COMBINED_FEE:
            return (
                <div className={"APUpgradeOfferPopup"}>
                    <GText 
                        text={AfterPaymentOfferText.SaveWithGovPlusUnlimited} 
                        size={breakpoint.md ? SIZE.PARAGRAPH_BOLD_24 : SIZE.PARAGRAPH_BOLD_16} 
                        color={COLORS_NEW.ROYAL_BLUE_700}
                        maxWidth={breakpoint.md ? "490px" : "268px"}
                    />
                    <GText 
                        text={AfterPaymentOfferText.IncludesUnlimitedApplications} 
                        innerHtml={AfterPaymentOfferText.IncludesUnlimitedApplications}
                        size={SIZE.HEADING_MDLG} 
                        maxWidth="586px" 
                    />
                    <img src={productsImg} />
                    <ProductsTableList products={products} />
                    <div className={"SubscriptionBenefits"}>
                        {UpgradeOfferBenefits.CURRENT_BENEFITS.map((benefit, key) =>
                            <GText key={key} text={benefit} size={breakpoint.md ? SIZE.PARAGRAPH_BOLD_24 : SIZE.PARAGRAPH_BOLD_22} color={COLORS_NEW.BLACK_500} />
                        )}
                    </div>
                    <div className={"PriceAndDiscountWrapper"}>
                        {discountPrice ? <>
                            <div className={"PriceAndDiscount"}>
                                <div className="Price">
                                    <GText text={`$${basePrice} / year`} size={SIZE.PARAGRAPH_REGULAR_24} style={{textDecoration:"line-through"}} />
                                    <GText text={`Today only $${discountPrice}`} size={SIZE.PARAGRAPH_BOLD_24} />
                                </div>
                                <GText text={AfterPaymentOfferText.ValidForYear} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_600} />
                            </div>
                            <GTag 
                                colorBackground={COLORS_NEW.YELLOW_200} 
                                colorText={COLORS_NEW.YELLOW_700} 
                                text={AfterPaymentOfferText.DiscountTag} 
                                sizeText={SIZE.PARAGRAPH_BOLD_14}
                            />
                        </> :
                        <div>
                            <GText text={`$${basePrice} / year`} size={SIZE.PARAGRAPH_BOLD_24} />
                        </div>}
                        
                    </div>
                    <div className={"CTA"}>
                        <GButton text={SubscriptionText.UpgradeToPremium} click={() => onSubscribe(selectedSubscriptionMethod, SubscriptionTrialOptions.NO_TRIAL)} loading={loading} />
                    </div>
                </div>
            )
        case PaymentModelTypes.ONE_TIME_PAYMENT_FEE:
            return (
                <div className="SubscriptionPopupHybrid">
                    <div className="SubscriptionHeader">
                        <GText style={{textAlign:"center"}} text={AfterPaymentOfferText.Offer} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.ROYAL_BLUE_700} maxWidth="564px" />
                    </div>
                    <div className="ProductsImage">
                        <img src={breakpoint.xs ? productsImgMobile : productsImg} />
                    </div>
                    <div className="Content">
                        <SubscriptionOfferBenefitItem
                            header={AfterPaymentOfferText.SmartAutofill}
                            subHeader={AfterPaymentOfferText.SmartAutofillDescription}
                            image={autofillOfferImageMobile}
                            mode="vertical"
                            imageWidth={72}
                            icon={ICONS_SVG_TYPE.RENEWAL_CIRCLE_CHECK}
                         />
                         <SubscriptionOfferBenefitItem
                            header={AfterPaymentOfferText.ExpeditedProcessing}
                            subHeader={AfterPaymentOfferText.ExpeditedProcessingDescription2}
                            image={expeditedOfferImageMobile}
                            mode="vertical"
                            imageWidth={72}
                            icon={ICONS_SVG_TYPE.EXPEDITED_PROCESSING}
                         />
                         <SubscriptionOfferBenefitItem
                            header={AfterPaymentOfferText.FreeAutoRenewals}
                            subHeader={AfterPaymentOfferText.FreeAutoRenewalsDescription}
                            image={renewalOfferImageMobile}
                            mode="vertical"
                            imageWidth={72}
                            icon={ICONS_SVG_TYPE.RENEWAL_CIRCLE_BODY}
                         />
                         <SubscriptionOfferBenefitItem
                            header={AfterPaymentOfferText.FreeInstantReplacements}
                            subHeader={AfterPaymentOfferText.FreeInstantReplacementsDescription}
                            image={insuranceOfferImageMobile}
                            mode="vertical"
                            imageWidth={72}
                            icon={ICONS_SVG_TYPE.INSURANCE_SHIELD_CIRCLE}
                         />
                         <SubscriptionOfferBenefitItem
                            header={AfterPaymentOfferText.TotalIdentityProtection}
                            subHeader={AfterPaymentOfferText.TotalIdentityProtectionDescription2}
                            image={idpOfferImageMobile}
                            mode="vertical"
                            imageWidth={72}
                            icon={ICONS_SVG_TYPE.DOLLAR_CIRCLE}
                         />
                         <SubscriptionOfferBenefitItem
                            header={AfterPaymentOfferText.ExpertReview}
                            subHeader={AfterPaymentOfferText.ExpertReviewDescription}
                            image={expertReviewImageMobile}
                            mode="vertical"
                            imageWidth={72}
                            icon={ICONS_SVG_TYPE.USER_CHECK_CIRCLE}
                         />
                         <SubscriptionOfferBenefitItem
                            header={AfterPaymentOfferText.ExpressAssistance}
                            subHeader={AfterPaymentOfferText.ExpressAssistanceDescription}
                            image={expressAssistanceImageMobile}
                            mode="vertical"
                            imageWidth={72}
                            icon={ICONS_SVG_TYPE.CHAT_CIRCLE}
                         />
                    </div>
                    <div className="SubscriptionPayment">
                        <div className={"PaymentHeader"}>
                            <div className={"Logo"}>
                                <GIconsSvgType type={ICONS_SVG_TYPE.LOGO} width={94.3} height={24}/>
                            </div>
                            <div className={"OfferHeaderPrice"}>
                                <GText 
                                    text={AfterPaymentOfferText.UnlockFullAccess} 
                                    size={SIZE.PARAGRAPH_BOLD_18} 
                                    color={COLORS_NEW.BLACK_700} 
                                    style={{textAlign: "center"}}
                                />
                                <GTag
                                    text={SubscriptionText.SubscriptionPlanTag}
                                    sizeText={SIZE.PARAGRAPH_BOLD_14}
                                    colorBackground={COLORS_NEW.YELLOW_200}
                                    colorText={COLORS_NEW.YELLOW_700}
                                />
                            </div>
                        </div>
                        <div className={"SubscriptionOptions"}>
                            <GSubscriptionsMethodsForm
                                subscriptionMethods={subscriptionMethods}
                                defaultSubscriptionMethod={SUBSCRIPTION_PERIOD_YEARLY}
                                selectionSubscriptionMethodChange={selectSubscriptionPeriod}
                                hasTotalPriceBlock={false}
                            />
                        </div>
                        <GButton 
                            text={AfterPaymentOfferText.UnlockFullAccess.substring(0, 18)} 
                            click={() => onSubscribe(selectedSubscriptionMethod, SubscriptionTrialOptions.NO_TRIAL)} 
                            loading={loading} 
                            
                        />
                        <GText 
                            text={AfterPaymentOfferText.FullAccessAuthorization.replace('{price}', `$${subscriptionSelectedPrice.toString()}`).replace('{period}', getRenewalPeriod())} 
                            size={SIZE.PARAGRAPH_REGULAR_16} 
                            color={COLORS_NEW.BLACK_600} 
                            maxWidth="682px" 
                            style={{textAlign: "center"}}
                        />
                    </div>
                </div>
            )
    
        default:
            return <></>;
    }

    

}
export default GSubscribeWindow