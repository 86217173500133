import { FC } from "react"
import {
  renderBasicBlockContainer,
  uploadSelector,
} from "../UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { BasicBlockContainerProps } from "../UploadPhotoService/interfaces/interfaces"
import LayoutBaseContainer from "../Layouts/LayoutBaseContainer/LayoutBaseContainer"
import { useSelector } from "react-redux"
import { RootState } from "../../../../App"

const DynamicNameFile: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text, state, type },
  arrayComponents,
}) => {
  const loadingButtonState =
    useSelector<RootState, { uploadPhotoNameFile: string | null }>(uploadSelector).uploadPhotoNameFile ?? ""
  text = type === "dynamic_file_name__text" ? loadingButtonState : text

  const errorMessage =
    useSelector<RootState, { dynamicUploadPhotoErrorMessage: string | null }>(uploadSelector)
      .dynamicUploadPhotoErrorMessage ?? ""

  text = type === "dynamic_error_answer__text" && errorMessage && text === "*dynamic*" ? errorMessage : text
  
  return renderBasicBlockContainer({ className, text, childrenItem, arrayComponents, state }, LayoutBaseContainer)
}

export default DynamicNameFile
