import React, {FC, useState} from "react";
import GAdditionalStep, {StepShowStatus, StepVisualStatus} from "./AdditionalStep";
import {connect, useDispatch} from "react-redux";
import {IUpsellStepVisualStatus} from "../../../../Redux/InterfacesEntity/upsell.step.interfaces";
import {setArrayUpsellStep} from "../../../../Redux/store/additionalForm/additional.actions";
import StepUpsell, {IUpsellProcessingData} from "./StepUpsell/StepUpsell";
import {getPropertiesForEvents} from "../../../../services/gtm/backEndEvent";
import PurchasedOfferModal from "../PurchasedOfferModal/PurchasedOfferModal";
import {
    computeOfferName,
    getPurchasedConfirmationOfferImage,
    SolutionsToNormalCase
} from "../../../../util/UpsellHelper";
import {FONT_FAMILY, OfferType} from "../../types/types";
import {COLORS} from "../../types/Colors";
import {getSolutionNameToLowerCase} from "../../../../util/SolutionNames";

export type AdditionalStepUpsellProps = {
    index?: string,
    title: string,
    titleDone: string,
    information?: string,
    ctaText?: string,
    stepShowStatus: StepShowStatus | string
    uniqSession: string
    formId: string
    upsellProcessingInformation: any
    blockStatus: string
    statusArrayUpsellStep: IUpsellStepVisualStatus[],
}
const GAdditionalStepUpsell: FC<AdditionalStepUpsellProps> =
    ({
         index,
         title,
         titleDone,
         information,
         ctaText,
         stepShowStatus,
         uniqSession,
         formId,
         upsellProcessingInformation,
         blockStatus,
         statusArrayUpsellStep,
     }) => {
        const dispatch = useDispatch()
        const {solutionName} = getPropertiesForEvents();
        const solutionNameLowerCase = () => {
            return getSolutionNameToLowerCase(solutionName)
        }
        const handlerUpsellStep = (status: string, statusName: string) => {
            const cloneStatuses = Array.from(statusArrayUpsellStep)
            const upsellStatus = cloneStatuses.find(c => c.statusName === statusName)
            if (upsellStatus) {
                upsellStatus.status = status
            } else {
                cloneStatuses.push({statusName, status})
            }
            dispatch(setArrayUpsellStep(cloneStatuses))
        }
        
        let statusUpsellStep = ''
        const upsellStatus = statusArrayUpsellStep?.find(c => c.statusName === blockStatus)
        if (upsellStatus) {
            statusUpsellStep = upsellStatus.status
        }
        const [isOfferPurchasesModalOpen, setIsOfferPurchasesModalOpen] = useState<boolean>(false);
        const [offerPurchasedData, setOfferPurchasedData] = useState<IUpsellProcessingData | undefined>(undefined);
        const isCrossSell = offerPurchasedData?.page_type === OfferType.CrossSell;
        const offerName = computeOfferName(isCrossSell, offerPurchasedData?.service_name, offerPurchasedData?.cross_sell_product_offer)
        let purchasedOfferImage = undefined;
        if (offerPurchasedData) {
            purchasedOfferImage = getPurchasedConfirmationOfferImage(offerPurchasedData, solutionName);
        }

        const offerPurchased = (offerData: IUpsellProcessingData) => {
            setOfferPurchasedData(offerData);
            setIsOfferPurchasesModalOpen(true);
        }
        const closePurchasedOfferModal = () => {
            setIsOfferPurchasesModalOpen(false);
        }

        const getPurchasedCrossSellDescription = () => {
            const descStyles: React.CSSProperties = {
                fontSize: "16px",
                fontFamily: FONT_FAMILY.INTER_REGULAR_400,
                fontWeight: 400,
                lineHeight: "22.4px",
                color: COLORS.SLATE_GRAY_500
            }
            return (
                <p style={descStyles}>
                    You can access your prepaid application and complete it at any time from your dashboard in
                    the <strong>New Application</strong> section.
                </p>
            )
        }

        return (
            <>
                <PurchasedOfferModal offerName={offerName} offerImage={purchasedOfferImage}
                                     currentSolution={SolutionsToNormalCase.includes(solutionName) ? solutionName : getSolutionNameToLowerCase(solutionName)}
                                     isOpen={isOfferPurchasesModalOpen}
                                     onCancel={closePurchasedOfferModal}
                                     description={isCrossSell ? getPurchasedCrossSellDescription() : undefined}/>

                <GAdditionalStep
                    title={title.replaceAll("{solution}", solutionNameLowerCase)}
                    titleDone={titleDone}
                    information={information?.replaceAll("{solution}", solutionNameLowerCase)}
                    ctaText={ctaText}
                    index={index}
                    stepShowStatus={stepShowStatus}
                    stepVisualStatus={statusUpsellStep}
                    ctaForm={() => handlerUpsellStep(StepVisualStatus.STATUS_PROCESS, blockStatus)}
                >
                    <StepUpsell
                        data={upsellProcessingInformation[blockStatus]}
                        template={upsellProcessingInformation[blockStatus].template}
                        formId={formId}
                        uniqSession={uniqSession}
                        purchasedOfferCallback={offerPurchased}
                    />
                </GAdditionalStep>
            </>

        )
    }


const mapStateToProps = (state: any) => ({
    statusArrayUpsellStep: state.additional.statusArrayUpsellStep,
    upsellProcessingInformation: state.additional.upsellProcessingInformation,
})

export default connect(mapStateToProps)(GAdditionalStepUpsell)