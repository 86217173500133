import { FC } from "react"
import { BasicBlockContainerProps } from "../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { renderBasicBlockContainer } from "../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import ReviewPageEditBlockLayout from "../UploadPhoto/Layouts/ReviewPageEditBlockLayout/ReviewPageEditBlockLayout"
import { useDispatch } from "react-redux"
import { setNameActualInput, setNameActualLabel, setNameActualType } from "../../../Redux/store/step/step.actions"
import { setValueModal } from "../../../Redux/store/form/form.actions"

const ReviewPageEditBlock: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text, state },
  arrayComponents,
  reviewPageProps = {},
  ...props
}) => {
  const { sub_name = "", field_type = "", label = "" } = reviewPageProps
  // console.log("reviewPageProp", reviewPageProps)
  // reviewPageProps = { ...reviewPageProps, field_name }
  const dispatch = useDispatch()
  // const submitObject = useSelector<RootState, { submitObject: {} | any }>(formSelector).submitObject
  const onClick = () => {
    dispatch(setNameActualInput(sub_name))
    dispatch(setNameActualType(field_type))
    dispatch(setNameActualLabel(label))
    dispatch(setValueModal("review_page_modal"))
  }

  return renderBasicBlockContainer(
    { className, text, childrenItem, arrayComponents, state },
    ReviewPageEditBlockLayout,
    {
      props: { ...props, reviewPageProps, onClick },
    }
  )
}

export default ReviewPageEditBlock
