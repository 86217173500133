import React, { FC } from "react"
import "./ValidationBlock.scss"
import GCheckListItem from "../../molecules/CheckList/CheckListItem"
import { SIZE } from "../../types/types"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"

enum VALIDATION_BLOCK_STATUS {
  DEFAULT = "default",
  VALID = "valid",
  INVALID = "notValid",
}

type GValidationBlockProps = {
  validationStatus: string,
  validationMessage: string,
}

const GValidationBlock: FC<GValidationBlockProps> = ({ validationStatus, validationMessage }) => {
  const render = (color: string) => {
    return (
      <GCheckListItem
        text={validationMessage}
        textSize={SIZE.PARAGRAPH_REGULAR_16}
        textColor={color}
        bubbleColor={COLORS.WHITE}
        bubbleWidth={20}
        bubbleHeight={20}
        iconsType={ICONS_SVG_TYPE.CHECK_CIRCLE_OUTLINE}
        iconsWidth={20}
        iconsHeight={20}
        iconsColor={color}
      />
    )
  }
  const validationRuleRender = () => {
    if (validationStatus === VALIDATION_BLOCK_STATUS.DEFAULT) {
      return render(COLORS.SLATE_GRAY_500)
    }

    if (validationStatus === VALIDATION_BLOCK_STATUS.VALID) {
      return render(COLORS_NEW.GREEN_700)
    }

    if (validationStatus === VALIDATION_BLOCK_STATUS.INVALID) {
      return render(COLORS_NEW.RED_600)
    }

    return null
  }

  return <div className={"GValidationBlock"}>{validationRuleRender()}</div>
}

export default GValidationBlock
