import {computeSignatureOptions, SignatureCanvas} from "./SignatureCanvas";

export interface SignatureValidationStatus {
    isValid: boolean;
    message?: string;
}

enum SignatureInvalidRule {
    Small = 1, Short
}


const validSignaturePercentCoverage = 20;
const validMinDrawnLines = 1;

const SignatureValidationMessages = {
    [SignatureInvalidRule.Small]: `Signature is too small. Please make sure that your signature covers at least ${validSignaturePercentCoverage}% of the signature pad. Sign again and make sure to fill the pad with your signature.`,
    [SignatureInvalidRule.Short]: 'Signature too short. Please provide your full signature.'
}

/*
* Function that applies the signature validation rules:
* Rule 1: That the firm occupies a minimum of 70% of the total area
* Rule 2 That the signature is not small, means that more than 3 lines have been drawn.
*/
export function validateSignature(canvas: SignatureCanvas, trimmedCanvas: SignatureCanvas, drawnLines: number): SignatureValidationStatus {
    const signatureOptions = computeSignatureOptions(canvas, trimmedCanvas);
    if (signatureOptions.trimmedCanvasCoveragePercentage < validSignaturePercentCoverage) {
        return {
            isValid: false,
            message: SignatureValidationMessages[SignatureInvalidRule.Small]
        };
    }
    if (drawnLines < validMinDrawnLines) {
        return {
            isValid: false,
            message: SignatureValidationMessages[SignatureInvalidRule.Short]
        };
    }
    return {isValid: true};

}

export const DEFAULT_SIGNATURE_VALIDATION: SignatureValidationStatus = {isValid: true};