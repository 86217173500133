export function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(name,value,days) {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export function removeCookie(name, domain) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=/${domain}`;
  // document.cookie = `${name}=; Expires=${(new Date(Date.now() + -1 * 864e5)).toUTCString()};domain=/${domain};path=/`
  // document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

/**
 * This function is used to get url params. It's return an object with key and value of each params
 * The result will like this: {key1: value1, key2: value2}
 *
 * @param url
 * @return {object}
 */
export function getUrlParams (url) {
  let params = {};
  new URL(url).searchParams.forEach(function (val, key) {
    if (params[key] !== undefined) {
      if (!Array.isArray(params[key])) {
        params[key] = [params[key]];
      }
      params[key].push(val);
    } else {
      params[key] = val;
    }
  });
  return params
}

const EXCLUDE_ARRAY = ["form_id", "service"]

/**
 * Parse object to string. This function is used to parse object to string for url params.
 * The string result will be separated by separator param, by default is "&"
 * The result will like this: key1=value1{separator}key2=value2
 *
 * @param data
 * @param separator
 * @return {string}
 */
export function parseObjectToString(data, separator = "&") {
  let result = "";
  for (const [key, value] of Object.entries(data)) {
    if(EXCLUDE_ARRAY.indexOf(key) === -1) {
      const encodedValue = encodeURIComponent(getStringValue(value));
      result+=result===""?`${key}=${encodedValue}`:`${separator}${key}=${encodedValue}`;
    }
  }
  return result
}

/**
 * Get string value from any type
 * @param value
 * @return {string}
 */
export function getStringValue(value) {
  let stringValue = value;
  if (typeof stringValue !== 'string') {
    stringValue = stringValue.toString();
  }
  return stringValue;
}


