import React from "react"

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
  fillCircle?: boolean;
}

export const SvgRenewalCircleCheck= ({
  width = 65,
  height = 65,
  color = "#185EC8",
  backgroundColor = "#13BA68",
}: ISvgCheck) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14" cy="14" r="14" fill={backgroundColor}/>
          <path d="M11.7979 14.2007L12.9483 15.3247C13.0669 15.4406 13.2568 15.4391 13.3734 15.3212L16.2022 12.464" 
            stroke="#FFF9EB" 
            strokeWidth="1.025" 
            strokeLinecap="round"
          />
          <path 
            d="M7.24609 18.2383V15.8405H9.64349" 
            stroke="#FFF9EB" 
            strokeWidth="1.025" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          />
          <path 
            d="M7.24609 15.8411L10.095 18.5184C10.7549 19.1787 11.5712 19.661 12.4679 19.9204C13.3646 20.1798 14.3124 20.2078 15.2228 20.0018C16.1332 19.7958 16.9766 19.3625 17.6743 18.7423C18.372 18.1221 18.9013 17.3352 19.2127 16.4551" 
            stroke="#FFF9EB" 
            strokeWidth="1.025" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          />
          <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M12.892 8.49591C13.718 8.30899 14.5778 8.33439 15.3913 8.56973C16.2048 8.80507 16.9455 9.24268 17.5442 9.84174L17.5557 9.85293L19.4617 11.6441H18.3593C18.0763 11.6441 17.8468 11.8736 17.8468 12.1566C17.8468 12.4397 18.0763 12.6691 18.3593 12.6691H20.7424C20.7656 12.6697 20.7887 12.6687 20.8118 12.6662C21.0689 12.6387 21.2692 12.4211 21.2692 12.1566V9.75879C21.2692 9.47574 21.0398 9.24629 20.7567 9.24629C20.4737 9.24629 20.2442 9.47574 20.2442 9.75879V10.9729L18.2634 9.11137C17.5433 8.39283 16.6534 7.8678 15.6762 7.58511C14.6963 7.30164 13.6606 7.27105 12.6657 7.49618C11.6709 7.72132 10.7492 8.19484 9.98683 8.87255C9.22443 9.55026 8.64613 10.4101 8.30586 11.3718C8.21145 11.6386 8.35122 11.9315 8.61806 12.0259C8.88489 12.1203 9.17774 11.9805 9.27216 11.7137C9.55467 10.9152 10.0348 10.2013 10.6678 9.63863C11.3008 9.07595 12.066 8.68282 12.892 8.49591Z" 
            fill="#FFF9EB"
          />
      <defs>
      <clipPath id="clip0_16253_128883">
        <rect width="28" height="28" fill="white"/>
      </clipPath>
      <clipPath id="clip1_16253_128883">
        <rect width="14.7368" height="14.7368" fill="white" transform="translate(6.63184 6.63074)"/>
      </clipPath>
      </defs>
    </svg>

  )
}
