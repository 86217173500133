import React, { FC } from 'react';

export const SvgIconRedX: FC = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="24" height="24" rx="12" fill="#F54254" />
            <path d="M16.5 8L8.5 16" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.5 8L16.5 16" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};