import React from "react"
import { connect } from "react-redux"
import { Form } from "antd"
import { FieldInterface } from "../../../../../Redux/InterfacesEntity/field.interface"
import {
  setActiveInput,
  setObservers, setTagFields,
  setValueCurrentField
} from "../../../../../Redux/store/form/form.actions"
import {
  arrayEmptyOptions,
  getObservableVarValue,
  getOptions,
  getOptionsName,
  getValidationWithEmptyOption,
  getWrapperStaticOptions,
} from "../createOptions"
import { setCashState } from "../../../../../Redux/store/cash/cash.action"
import { getLabelWrapper } from "../../../../../services/service-function/field"
import { getCheckStaticOptions, dispatchCurrentOption } from "./dispatchShortOptions"
import { StepInterface } from "../../../../../Redux/InterfacesEntity/step.interface"
import {savePrefilled} from "../../../../../Redux/store/user/user.actions";
import '../../DataGroupDropDownComponent/DataDropDownComponent/DataDropDownComponent.less'
import {FIELD_NAMES, getTypeCityBirth, OBSERVER_NAMES, OPTION_CASES, VALUE_CASES} from "./service";
import {CER_DISABLE_STATES, CER_FORM_IDS, getDisableCerStateMessage} from "../../../../../shared/constans/cer-contants";
import GSelect, {IOption} from "../../../../atomicDesign/molecules/Select/Select";
import GIconsSvgType from "../../../../atomicDesign/atoms/IconsSvg/IconsSvg";
import {ICONS_SVG_TYPE} from "../../../../atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import {COLORS} from "../../../../atomicDesign/types/Colors";
import FormIdRepository from "../../../../../persistence/session/FormIdRepository";

type SelectComponentRenderProps = {
  field: FieldInterface,
  staticOptionsForSelect: {}[] | any,
  dynamicOptionsForSelect: {} | any,
  submitObject: {} | any,
  trimOption: any,
  getOptionForPrevName: any,
  getValueForSubmit: any,
  currentStep: StepInterface,
  tagStoreWithFields: {[key:string]: string},
  required: boolean,
  dispatch: any,
}

// eslint-disable-next-line max-len
const SelectComponentRender: React.FunctionComponent<SelectComponentRenderProps> = ({
  field,
  dynamicOptionsForSelect,
  staticOptionsForSelect,
  submitObject,
  trimOption,
  getOptionForPrevName,
  getValueForSubmit,
  currentStep,
  tagStoreWithFields,
  required,
  dispatch,
}) => {
  const { additionalStep } = currentStep

  const getOption = (value: string, options: [] | any) => {
    return options && options.find((item: any) => (item.submit_value ?? item.submitValue) === value)
  }

  // for unsubscribe or subscribe by change select option
  const getUnsubscribe = (value: string, options: [] | any) => {
    const option = getOption(value, options)
    if (option && option.observable) {
      option.observable.forEach((item: any) => {
        dispatch(setValueCurrentField(item.observable_var_name, getObservableVarValue(item.observable_var_value)))
        dispatch(
          setValueCurrentField(item.observable_var_full_name, getObservableVarValue(item.observable_var_full_value))
        )
      })
    }
  }

  const removeSomeFields = (submitObject: { [key: string]: string }) => {
    for (let submitObjectKey in submitObject) {
      if (submitObject[submitObjectKey] === "Select a State") {
        delete submitObject[submitObjectKey]
      }
    }
  }

  const getSelectOption = (value: string, option: any) => {
    //solution for right type city_birth
    if(field.name === FIELD_NAMES.COUNTRY_BIRTH) {
      getTypeCityBirth({ value, VALUE_CASES, dispatch, OPTION_CASES, OBSERVER_NAMES, FIELD_NAMES })
    }
    // It is the temporary solution for remove a default value of select from the submitObject
    removeSomeFields(submitObject)
    if (field.name === "entity_type" && sessionStorage.getItem("formNameForEvent") === "EIN") {
      sessionStorage.setItem("SubCategory", option.children)
      /* Event Form - Service */
    }
    let newValue
    if (field.nameBlock === "block_diff_name_prev") {
      newValue = getValueForSubmit(value)
      // for static options from predefinedOptions options
    } else if (getCheckStaticOptions(staticOptionsForSelect, field.name, getWrapperStaticOptions)) {
      newValue = dispatchCurrentOption(
        value,
        staticOptionsForSelect,
        field.name,
        getWrapperStaticOptions,
        submitObject,
        field.submitAdditionalOption,
        trimOption,
        dispatch
      )
    } else if (field.observable_var === "select_dynamic_blocks" || field.defaultsDocumentOption) {
      const chooseOption: {} | any =
        field?.options?.find((item: any) => (item.submit_value || item.submitValue) === value) || {}
      const renderValue = chooseOption.render_value || chooseOption.optionName
      dispatch(setValueCurrentField(`${field.name}__full_name`, `${renderValue}`))
      dispatch(
        setCashState({
          ...submitObject,
          [field.name]: trimOption(`${value}`, field.submitAdditionalOption, dispatch),
          [`${field.name}__full_name`]: `${renderValue}`,
        })
      )
      if(field.tag) {
        dispatch(savePrefilled({ [field.tag]: submitObject[field.name] }))
      }
      newValue = value
    } else {
      newValue = value
      dispatch(setCashState({ ...submitObject, [field.name]: newValue, [`${field.name}__full_name`]: `${newValue}` }))
      if(field.tag) {
        dispatch(savePrefilled({ [field.tag]: submitObject[field.name] }))
      }
    }
    dispatch(setValueCurrentField(field.name, trimOption(`${newValue}`, field.submitAdditionalOption, dispatch)))
    if (field.observable) {
      dispatch(setObservers(field.observable, value))
    }
    getUnsubscribe(value, field.options)
    getDynamicHelper(value, field.options, dynamicHelper)
    if (field.change_default_dynamic_value) {
      dispatch(setValueCurrentField(`default_dynamic_value`, `${field.name}`))
    }
    if (field?.tag) {
      sessionStorage.setItem("tagFields", JSON.stringify({ [field?.tag]: trimOption(`${newValue}`, field.submitAdditionalOption, dispatch) }))
      dispatch(setTagFields({ [field?.tag]: trimOption(`${newValue}`, field.submitAdditionalOption, dispatch) }))
      dispatch(savePrefilled({ [field.tag]: trimOption(`${newValue}`, field.submitAdditionalOption, dispatch) }))
    }
  }

  // for dynamic helpers
  const dynamicHelper = field.dynamic_helper === "On"

  const getDynamicHelper = (value: string, options: [] | any, dynamicHelper: boolean) => {
    const option = getOption(value, options)
    if (dynamicHelper && option?.helper) {
      dispatch(setActiveInput(option))
    }
  }

  const handlerMouseEnterOnOption = (value: string, options: [] | any, dynamicHelper: boolean) => {
    getDynamicHelper(value, options, dynamicHelper)
  }

  const wrapperStaticOptionsForSelect = getWrapperStaticOptions(staticOptionsForSelect, field.name)

  const getSelectOptions = (): IOption[] => {
    const options :IOption[] = [];
    let indexCount = 1;
    if(field.nameBlock === "block_diff_name_prev"){
      const optionsForPrevName = getOptionForPrevName(dynamicOptionsForSelect);
      optionsForPrevName?.forEach((item: any)=>{
        options.push({label: item[1].trim() || field.emptyOption, value: item[0]})
        indexCount++
      })
      const staticOptions = getOptions(field.options, staticOptionsForSelect[field.name] && staticOptionsForSelect[field.name].data)
      staticOptions?.forEach((item: any)=>{
        const optionName = getOptionsName(item)
        options.push({label: optionName.renderValue, value: "not"})
        indexCount++
      })
    } else{
      const staticOptions =  getOptions(
        field.options,
        staticOptionsForSelect?.[field?.name]?.data || wrapperStaticOptionsForSelect?.data,
        field?.rangeOptions
      )
      staticOptions?.forEach((item: any) => {
        const optionName = getOptionsName(item)
        options.push({label: optionName.renderValue, value: optionName.submitValue})
        indexCount++
      })
    }
    return options;
  }

  return (
    <Form.Item
      label={getLabelWrapper(field, dispatch, additionalStep)}
      name={field.name}
      initialValue={
        (getValidationWithEmptyOption(field, arrayEmptyOptions) ||
        submitObject[`${field.name}__full_name`] ||
        submitObject[`${field.name}`] ||
        getOptionsName(field.defaultValue).renderValue ||
        getOptionsName(field.defaultsDocumentOption).renderValue) ||
        undefined
      }
      rules={[
        {
          required,
          message: "Required",
        },
        {
          pattern: new RegExp(`^(?!${field.emptyOption}$).*`),
          message: "Required",
        },
        {
          pattern: new RegExp(
            `^(?!${
              ((field.defaultAdditionalValue === "Select a State" || field.defaultValidation === "required") &&
                field.defaultAdditionalValue) ||
              ""
            }$).*`
          ),
          message: "Required",
        },
        {
          validator:() => {
            const formId = submitObject.form_id === undefined ?  FormIdRepository.getInstance().getValue() : submitObject.form_id;
            if(field.name==="state_birth" && CER_FORM_IDS.includes(formId)){
              const isDisabled = CER_DISABLE_STATES.find(state=>state.code === submitObject[field.name]);
              if(isDisabled){
                return Promise.reject(getDisableCerStateMessage(isDisabled.name))
              }
            }
            return Promise.resolve()
          }
        }

      ]}
      preserve={false}
    >
      <GSelect
        icon={<GIconsSvgType type={ICONS_SVG_TYPE.DROPDOWN} color={COLORS.SLATE_GRAY_500} width={13} height={12} />}
        options={getSelectOptions()}
        onChange={(value: {} | string | any, option: any) => getSelectOption(value, option)}
        showSearch={!!field.inputOption}
        filterOption={field.inputOption
        // eslint-disable-next-line max-len
          ? (input: string, option: { props: { children: string } }) => option?.props?.children.toLowerCase().indexOf(input.toLowerCase()) === 0
          : false}
        onMouseEnter={() => handlerMouseEnterOnOption(submitObject[field.name], field.options, dynamicHelper)}
      />
    </Form.Item>
  )
}

const mapStateToProps = (state: any) => ({
  currentStep: state.step.currentStep,
  tagStoreWithFields: state.form.tagStoreWithFields,
})

export default connect(mapStateToProps)(SelectComponentRender)
