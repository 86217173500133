import React, { FC } from "react"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GAuthHeader from "../../molecules/AuthHeader/AuthHeader"
import "./ActivateAccount.scss"
import { Form, Input } from "antd"
import GText from "../../atoms/Text/Text"
import {INPUT_STATUS, SIZE} from "../../types/types"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import GFormItem, { FORM_ITEM_STATUSES } from "../../organisms/FormItem/FormItem"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import { INPUT_PASSWORD_STATUS } from "../../molecules/InputPassword/InputPassword"
import GButton from "../../molecules/Button/GButton"
import { Link } from "react-router-dom"
import GValidationBlock from "../../molecules/ValidationBlock/ValidationBlock";
import GInputText from "../../molecules/InputText/InputText";

type GActivateAccountProps = {
  formOnFinish: any,
  form: any,
  email?: any,
  emailErrorMessage: any,
  emailSuffixErrorIcon: any,
  onEmailChange: any,
  passwordErrorMessage: any,
  onFocusPassword: any,
  onBlurPassword: any,
  onChangePassword: any,
  validationRequirements: any,
  requirements: any,
  onChangeConfirm: any,
  confirmValidationMessage: any,
  loader: any,
}

const TITLE = "Create password"
const DESCRIPTION = "Please create your password to finish activating your account and access your dashboard."

const GActivateAccount: FC<GActivateAccountProps> = ({
  formOnFinish,
  form,
  email,
  emailErrorMessage,
  emailSuffixErrorIcon,
  onEmailChange,
  passwordErrorMessage,
  onFocusPassword,
  onBlurPassword,
  onChangePassword,
  validationRequirements,
  requirements,
  onChangeConfirm,
  confirmValidationMessage,
  loader,
}) => {
  const emailFormItemStyle: React.CSSProperties = {
    display: email ? "none" : "block",
    marginBottom: email ? "0" : "20px",
  }
  return (
    <div className={`GActivateAccount`}>
      <GAuthHeader title={TITLE} description={DESCRIPTION} />
      <Form
        initialValues={{ remember: true }}
        name={`basic`}
        onFinish={formOnFinish}
        className={`GActivateAccount__Form`}
        layout={`vertical`}
        form={form}
      >
        <div style={emailFormItemStyle}>
          <GFormItem
            name="email"
            label={
              <div className={`GActivateAccount__Form__Item__Label`}>
                <GText text={`Email`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_1000} />
              </div>
            }
            status={`${
              emailErrorMessage !== "" || email === "" ? FORM_ITEM_STATUSES.ERROR : FORM_ITEM_STATUSES.DEFAULT
            }`}
            message={emailErrorMessage}
          >
            <GInputText
              onChange={onEmailChange}
              suffixError={emailSuffixErrorIcon}
              inputStatus={`${emailErrorMessage !== "" ? INPUT_STATUS.ERROR : INPUT_STATUS.DEFAULT}`}
            />
          </GFormItem>
        </div>

        <Form.Item
          name="password"
          initialValue=""
          label={
            <div className={`GActivateAccount__Form__Item__Label`}>
              <GText text={`New Password`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_1000} />
            </div>
          }
          rules={[{ required: false }]}
          style={{ marginBottom: validationRequirements ? "9px" : "20px" }}
        >
          <Input.Password
            type="password"
            className={`GInputPassword__Input GInputPassword__Input--${
              passwordErrorMessage && passwordErrorMessage !== ""
                ? INPUT_PASSWORD_STATUS.ERROR
                : INPUT_PASSWORD_STATUS.DEFAULT
            }`}
            iconRender={(visible) =>
              visible ? (
                <GIconsSvgType
                  type={ICONS_SVG_TYPE.LOCK_OPEN}
                  width={20}
                  height={20}
                  color={passwordErrorMessage && passwordErrorMessage !== "" ? COLORS.RED_500 : COLORS_NEW.BLACK_700}
                />
              ) : (
                <GIconsSvgType
                  type={ICONS_SVG_TYPE.LOCK_CLOSE}
                  width={20}
                  height={20}
                  color={passwordErrorMessage && passwordErrorMessage !== "" ? COLORS.RED_500 : COLORS_NEW.BLACK_700}
                />
              )
            }
            onFocus={onFocusPassword}
            onBlur={onBlurPassword}
            onChange={onChangePassword}
          />
        </Form.Item>
        {validationRequirements && (
          <div className={`GActivateAccount__Form__GValidationBlock`}>
            {Object.values(requirements).map((rule: any, index) => {
              return (
                <div key={index}>
                  <GValidationBlock validationStatus={rule.status} validationMessage={rule.message} />
                </div>
              )
            })}
          </div>
        )}

        <GFormItem
          label={
            <div className={`GInputPassword__Form__Item__Label`}>
              <GText text={`Confirm Password`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_1000} />
            </div>
          }
          name="confirm_password"
          status={`${
            confirmValidationMessage !== "" || passwordErrorMessage
              ? FORM_ITEM_STATUSES.ERROR
              : FORM_ITEM_STATUSES.DEFAULT
          }`}
          message={confirmValidationMessage}
          initialValue={``}
          rules={[{ required: false }]}
        >
          <Input.Password
            onChange={onChangeConfirm}
            type={`password`}
            iconRender={(visible) =>
              visible ? (
                <GIconsSvgType
                  type={ICONS_SVG_TYPE.LOCK_OPEN}
                  width={20}
                  height={20}
                  color={
                    confirmValidationMessage !== "" || passwordErrorMessage ? COLORS.RED_500 : COLORS_NEW.BLACK_700
                  }
                />
              ) : (
                <GIconsSvgType
                  type={ICONS_SVG_TYPE.LOCK_CLOSE}
                  width={20}
                  height={20}
                  color={
                    confirmValidationMessage !== "" || passwordErrorMessage ? COLORS.RED_500 : COLORS_NEW.BLACK_700
                  }
                />
              )
            }
            className={`GInputPassword__Input GInputPassword__Input--${
              (confirmValidationMessage !== "" || passwordErrorMessage) ? INPUT_PASSWORD_STATUS.ERROR : INPUT_PASSWORD_STATUS.DEFAULT
            }`}
          />
        </GFormItem>
        <div className={`GActivateAccount__Form__BtnContainer`}>
          <GButton loading={loader} text={`Save password`} textSize={SIZE.PARAGRAPH_BOLD_16} size={"large"} isSubmit={true} />
        </div>
        <div className={`GAuthFooter`}>
          <div className={`GAuthFooter__Container`}>
            <Link to={`/signup`}>
              <div className={`GAuthFooter__Container__Link__Container`}>
                <GText text={`Cancel`} size={SIZE.PARAGRAPH_REGULAR_14} color={COLORS_NEW.ROYAL_BLUE_700} />
              </div>
            </Link>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default GActivateAccount
