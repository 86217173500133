export const USER_LOGIN_MESSAGE_CONSTANT = {
  USER_IS_NOT_REGISTERED: "login.USER_IS_NOT_REGISTERED",
  USER_UNAUTHORIZED: "login.USER_UNAUTHORIZED",
  OTP_TOKEN_NOT_PROVIDED: "login.OTP_TOKEN_NOT_PROVIDED",
  USER_TOKEN_EXPIRED: "common.USER_TOKEN_EXPIRED",
  WRONG_USER_PASSWORD: "login.WRONG_USER_PASSWORD",
  SUCCESSFUL_REQUEST: "common.SUCCESSFUL_REQUEST",
  USER_BLOCKED_BY_ADMIN: "login.USER_BLOCKED_BY_ADMIN",
  USER_CANCELLED_BY_ADMIN: "login.USER_CANCELLED_BY_ADMIN",
};

export const USER_IS_NOT_REGISTERED_MESSAGE = "We couldn't find an account matching the email you entered. Please check and try again";
export const USER_IS_NOT_REGISTERED_EVENT_WORD = "sign up";
export const SIGN_UP_REDIRECT_LINK = "/signup?service=GLO&form_id=glo-sub";

export const USER_UNAUTHORIZED_MESSAGE = "It looks like you did not activate your account yet, we just sent you an email at {*email*} to finish activating your account and set your password.";
export const USER_UNAUTHORIZED_STRING_REPLACE = "{*email*}";

export const USER_BLOCKED_BY_ADMIN_STRING_REPLACE = "Your account has been blocked. Please contact us for more information.";
export const USER_BLOCKED_BY_ADMIN_EVENT_WORD = "contact us";

export const USER_CANCELLED_STRING_REPLACE = "Your account has been disabled. For more information contact support."
export const USER_CANCELLED_EVENT_WORD = "contact support";
export const WRONG_USER_PASSWORD = "The password you entered is incorrect";

export const SESSION_EXPIRED_MESSAGE = "Your session has expired, please try to log in again.";
export const EMPTY_EMAIL_MESSAGE = "Please enter your email.";
export const INVALID_EMAIL_MESSAGE = "Please enter a valid email.";
export const EMPTY_PASSWORD_MESSAGE = "Please enter your password.";
