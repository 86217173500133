import React from "react";
import {Button} from "antd";
import "./index.less";
import {IconSvg} from "../svgImages/SvgImages";
import Spinner from "../spiner/Spinner";

const SettingsButton = ({
                          onClickFoo,
                          buttonText,
                          buttonColor= false,
                          disabled,
                          withArrow = false,
                          loader = false,
                          socialIcon = ""

                        }) => {

  const spinnerInButton = () => <div className={"buttonStyle_text-loader"}>
    <div className={'spinner'}>
      <Spinner/>
    </div>
  </div>

  const arrowInButton = () =><div className={"buttonStyle_text-arrow"}>
    <IconSvg type={'button_arrow'}/>
  </div>

  const buttonColorChecker = buttonColor ? {background: `${buttonColor}`} : {}

  return (
    <Button
      htmlType={"submit"}
      disabled={disabled}
      onClick={onClickFoo}
      className={buttonColor ? "specialStyles" : "buttonStyle"}
      style={buttonColorChecker}
    >
      <div className='buttonStyle_text'>
        {buttonText}
        {socialIcon
          ? <span><IconSvg type={socialIcon}/></span>
          : loader ? spinnerInButton() : withArrow && arrowInButton()}
      </div>
    </Button>
  )
};

export default SettingsButton;
