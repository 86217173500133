import React, { FC } from "react"
import GText from "../../atoms/Text/Text"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import { ITag, SIZE } from "../../types/types"
import "./GTag.scss"

const GTag: FC<ITag> = ({
                          text,
                          colorBackground,
                          colorText,
                          click,
                          sizeText = SIZE.PARAGRAPH_REGULAR_14,
                          tooltip= false
}) => {
  const styles: React.CSSProperties = {
    backgroundColor: colorBackground,
  }

  return (
    <div className="g-tag" style={styles}>
      <GText text={text} size={sizeText} color={colorText} />
      {click && (
        <div onClick={click} className={`g-tag__icon_container`}>
          <GIconsSvgType type={ICONS_SVG_TYPE.X} color={colorText} width={16} height={16} />
        </div>
      )}
      {tooltip && (
          <GIconsSvgType
              type={ICONS_SVG_TYPE.INFORMATION}
              width={16}
              height={16}
              color={colorText}
          />
      )}
    </div>
  )
}

export default GTag
