export const inlineOfferComponentStyle = {
  headStyle: {
    textTransform: 'uppercase',
    color: '#113469',
    flex: 'none',
    order: '0',
    flexGrow: '0',
    margin: '0px auto',
    textAlign: 'center'
  },
  bodyStyle: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center'
  }
}
