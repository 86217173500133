import BaseCookiesRepository from "./BaseCookiesRepository";

const MSCLKID_VARS_KEY = "msclkid";

class MsclkidRepository extends BaseCookiesRepository {

    static MSCLKID_DEFAULT_VALUE = "";

    private static _instance: MsclkidRepository;

    private constructor() {
        super()
    }

    public static getInstance(): MsclkidRepository {
        if (!MsclkidRepository._instance) {
            MsclkidRepository._instance = new MsclkidRepository();
        }
        return MsclkidRepository._instance;
    }

    getKeyName(): string {
        return MSCLKID_VARS_KEY;
    }
}

export default MsclkidRepository;
