import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { identityProtectionApis } from "../../api/api";
import {
    getIdentitiesSuccess,
    getRecordsSuccess,
    getAlertsSuccess,
    getEmailBreachesSuccess,
    getCreditScoresSuccess,
    verifyUserCreditScoresSuccess,
    patchUserBundleSuccess,
    patchUserAddressSuccess,
    updateCustomerSuccess,
    postRecordSuccess,
    postRecordFailure,
    showGrowlMessage,
    setMissingFields,
    updateCustomerFailure, patchUserAddressFailure,
    getIdentitiesFailure, getRecordsFailure, getCreditScoresFailure,
    getAlertsFailure, deleteRecordSuccess, deleteRecordFailure, checkVerificationStatusSuccess, checkVerificationStatusFailure,
    userRedirectedToThankYouPageSuccess,
    resetUserRedirectedToThankYouPageSuccess,
    getEmailBreachesFailure,
    patchUserBundleFailure,
    verifyUserCreditScoresFailure
} from "../actions";
import { notifyError } from "@dash/gov-plus__front-end__form/src/util/helper";
import {
    GET_IDENTITIES,
    GET_RECORDS,
    GET_ALERTS,
    GET_EMAIL_BREACHES,
    GET_CREDIT_SCORES,
    VERIFY_USER_CREDIT_SCORES,
    PATCH_USER_BUNDLE,
    PATCH_USER_ADDRESS,
    UPDATE_CUSTOMER, POST_RECORD, DELETE_RECORD,
    CHECK_VERIFICATION_RESULT,
    USER_REDIRECTED_TO_THANK_YOU_PAGE,
    RESET_USER_REDIRECTED_TO_THANK_YOU_PAGE
} from "../../constants/ActionTypes";
import { BUNDLE_TYPES, responesOK } from "@dash/gov-plus__front-end__form/src/util/IdentityProtectionHelper";

function* getIdentitiesSaga() {
    try {
        const response = yield identityProtectionApis.getMyIdentities();
        yield put(getIdentitiesSuccess(response.data));
    } catch (err) {
        yield put(getIdentitiesFailure(err));
        notifyError(err);
    }
}

// helper function to call getIdentitiesSaga in other sagas
function* fetchIdentities() {
    yield call(getIdentitiesSaga);
}

function* getRecordsSaga({ payload }) {
    try {
        const response = yield identityProtectionApis.getRecords(payload);
        yield put(getRecordsSuccess(response.data));
    } catch (err) {
        yield put(getRecordsFailure())
        notifyError(err);
    }
}

function* postRecordSaga({ payload }) {
    try {
        const response = yield identityProtectionApis.postRecord(payload);
        yield put(postRecordSuccess(response.data));

        if (responesOK(response)) {
            const recordsResponse = yield identityProtectionApis.getRecords(payload.recordType);
            yield put(getRecordsSuccess(recordsResponse.data));
            yield* fetchIdentities();
        }
    } catch (err) {
        yield put(postRecordFailure(err));
        yield put(
            showGrowlMessage({
                messageInfo: `Error posting data. Please try again.`,
                growlStatus: false,
                code: 400,
                showMessage: true,
            })
        );
        notifyError(err);
    }
}

function* deleteRecordSaga({ payload }) {

    const { monitorId, recordType } = payload;

    try {
        const response = yield identityProtectionApis.deleteRecord(monitorId)
        yield put(deleteRecordSuccess(response));

        if (responesOK(response)) {
            const recordsResponse = yield identityProtectionApis.getRecords(recordType);
            yield put(getRecordsSuccess(recordsResponse.data));
            yield* fetchIdentities();
        }

    } catch (error) {
        yield put(deleteRecordFailure(error));
        yield put(
            showGrowlMessage({
                messageInfo: `Error deleting record. Please try again.`,
                growlStatus: false,
                code: 400,
                showMessage: true,
            })
        )
    }
}

function* getAlertsSaga({ payload }) {
    try {
        const response = yield identityProtectionApis.getAlerts(payload);
        yield put(getAlertsSuccess(response.data));
    } catch (err) {
        yield put(getAlertsFailure(err))
        notifyError(err);
    }
}

function* getEmailBreachesSaga() {
    try {
        const response = yield identityProtectionApis.getEmailBreaches();
        yield put(getEmailBreachesSuccess(response.data));
    } catch (err) {
        yield put(getEmailBreachesFailure(err))
        notifyError(err);
    }
}

function* getCreditScoresSaga() {
    try {
        const response = yield identityProtectionApis.getCreditScores();
        yield put(getCreditScoresSuccess(response.data));
    } catch (err) {
        yield put(getCreditScoresFailure(err));
        notifyError(err);
    }
}

function* verifyUserCreditScoresSaga({ payload }) {
    try {
        const response = yield identityProtectionApis.verifyUserCreditScores(payload);
        yield put(verifyUserCreditScoresSuccess(response.data));

        // no iframe url from enfortra
        if (!response.data.getAuthenticationOnlyResult.length) {
            yield put(
                showGrowlMessage({
                    messageInfo: `Error loading data. Please try again.`,
                    growlStatus: false,
                    code: 400,
                    showMessage: true,
                })
            )
        }
    } catch (err) {
        yield put(verifyUserCreditScoresFailure(err))

        // if there is any error from enfortra side
        yield put(
            showGrowlMessage({
                messageInfo: `Error loading data. Please try again.`,
                growlStatus: false,
                code: 400,
                showMessage: true,
            })
        )
        notifyError(err);
    }
}

function* patchUserBundleSaga({ payload }) {
    try {
        const response = yield identityProtectionApis.patchUserBundle(payload);
        yield put(patchUserBundleSuccess(response.data));

        if (responesOK(response)) {
            yield put(setMissingFields([]));
            yield* fetchIdentities();
        }
        
        if (payload.bundleId === BUNDLE_TYPES.ADVANCED) {
            const response = yield identityProtectionApis.verifyUserCreditScores({
                result: 1, errorMessage: ""
            });
            yield put(verifyUserCreditScoresSuccess(response.data));
        }

    } catch (err) {
        yield put(patchUserBundleFailure(err))
        yield put(setMissingFields(err.response.data.missingFields));
        yield put(showGrowlMessage({
            messageInfo: `Error loading data. Please try again.`,
            growlStatus: false,
            code: 400,
            showMessage: true,
        }));
        notifyError(err);
    }
}

function* patchUserAddressSaga({ payload }) {
    try {
        const response = yield identityProtectionApis.patchUserAddress(payload);

        if (!response.data.error) {
            yield put(
                showGrowlMessage({
                    messageInfo: `Address updated successfully.`,
                    growlStatus: false,
                    code: 200,
                    showMessage: true,
                })
            )
        }
        yield put(patchUserAddressSuccess(response.data));
        return;
    } catch (err) {
        yield put(patchUserAddressFailure(err));
        yield put(showGrowlMessage({
            messageInfo: `Error loading data. Please try again.`,
            growlStatus: false,
            code: 400,
            showMessage: true,
        }));
        notifyError(err);
    }
}

function* updateCustomerSaga({ payload }) {

    const { bundleId, missingFieldsData } = payload;

    try {
        // Update customer
        const customerResponse = yield identityProtectionApis.addMissingFieldsToUser(missingFieldsData);
        yield put(updateCustomerSuccess(customerResponse.data));

        if (responesOK(customerResponse)) {
            yield put(setMissingFields([]));
        }

        // Update bundle ID if no error occurred in the previous step and bundle ID is available
        if (bundleId) {
            const response = yield identityProtectionApis.patchUserBundle({ bundleId });
            yield put(patchUserBundleSuccess(response.data));

            if (responesOK(response)) {
                yield put(setMissingFields([]));
                yield* fetchIdentities();
            }

            if (bundleId === BUNDLE_TYPES.ADVANCED) {
                const response = yield identityProtectionApis.verifyUserCreditScores({
                    result: 1, errorMessage: ""
                });
                yield put(verifyUserCreditScoresSuccess(response.data));
            }
        }
    } catch (err) {
        yield put(updateCustomerFailure(err));
        yield put(
            showGrowlMessage({
                messageInfo: `Error updating data. Please try later.`,
                growlStatus: false,
                code: 400,
                showMessage: true,
            })
        )
        if (err.response && err.response.status === 400 && err.response.data.missingFields) {
            yield put(setMissingFields(err.response.data.missingFields));
        }
        notifyError(err);
    }
}

function* checkVerificationResultSaga() {
    try {
        const response = yield identityProtectionApis.checkVerificationStatus();
        yield put(checkVerificationStatusSuccess(response.data));
    } catch (err) {
        yield put(checkVerificationStatusFailure(err))
        notifyError(err);
    }
}

export function* getIdentitiesSagaFork() {
    yield takeEvery(GET_IDENTITIES, getIdentitiesSaga);
}

export function* getRecordsSagaFork() {
    yield takeEvery(GET_RECORDS, getRecordsSaga);
}

export function* getAlertsSagaFork() {
    yield takeEvery(GET_ALERTS, getAlertsSaga);
}

function* handleUserRedirectedToThankYouPage() {
    yield put(userRedirectedToThankYouPageSuccess());
}

function* handleResetUserRedirectedToThankYouPage() {
    yield put(resetUserRedirectedToThankYouPageSuccess());
}

export function* getEmailBreachesSagaFork() {
    yield takeEvery(GET_EMAIL_BREACHES, getEmailBreachesSaga);
}

export function* getCreditScoresSagaFork() {
    yield takeEvery(GET_CREDIT_SCORES, getCreditScoresSaga);
}

export function* verifyUserCreditScoresSagaFork() {
    yield takeEvery(VERIFY_USER_CREDIT_SCORES, verifyUserCreditScoresSaga);
}

export function* patchUserBundleSagaFork() {
    yield takeEvery(PATCH_USER_BUNDLE, patchUserBundleSaga);
}

export function* patchUserAddressSagaFork() {
    yield takeEvery(PATCH_USER_ADDRESS, patchUserAddressSaga);
}

export function* updateCustomerSagaFork() {
    yield takeEvery(UPDATE_CUSTOMER, updateCustomerSaga);
}

export function* postRecordSagaFork() {
    yield takeEvery(POST_RECORD, postRecordSaga);
}

export function* deleteRecordSagaFork() {
    yield takeEvery(DELETE_RECORD, deleteRecordSaga);
}

export function* checkVerificationResultSagaFork() {
    yield takeEvery(CHECK_VERIFICATION_RESULT, checkVerificationResultSaga);
}

function* watchUserRedirectedToThankYouPage() {
    yield takeEvery(USER_REDIRECTED_TO_THANK_YOU_PAGE, handleUserRedirectedToThankYouPage);
}

function* watchResetUserRedirectedToThankYouPage() {
    yield takeEvery(RESET_USER_REDIRECTED_TO_THANK_YOU_PAGE, handleResetUserRedirectedToThankYouPage);
}

export default function* rootSaga() {
    yield all([
        fork(getIdentitiesSagaFork),
        fork(getRecordsSagaFork),
        fork(getAlertsSagaFork),
        fork(getEmailBreachesSagaFork),
        fork(getCreditScoresSagaFork),
        fork(verifyUserCreditScoresSagaFork),
        fork(patchUserBundleSagaFork),
        fork(patchUserAddressSagaFork),
        fork(updateCustomerSagaFork),
        fork(postRecordSagaFork),
        fork(deleteRecordSagaFork),
        fork(checkVerificationResultSagaFork),
        watchUserRedirectedToThankYouPage(),
        watchResetUserRedirectedToThankYouPage()
    ]);
}