import React from "react"
import RulesCheckBoxModal from "../../../Hocs/RulesCheckBoxModal/RulesCheckBoxModal"
import { useSelector } from "react-redux"
import { RootState } from "../../../App"
import { ModalContentInterface } from "../PrivacyPolicyComponent/PrivacyPolicyComponent"
import { arrayStepSelector } from "../../FormComponents/UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { Action } from "../../../Redux/interfacesAction/action.interface"

const defaultContent = (
  <div className="modal-bl__content-container">
    <p className="modal-bl__update">Last Updated: May 21, 2020</p>
    <p className="modal-bl__subtitle modal-bl__text upper-case">
      THESE TERMS OF SERVICE AFFECT YOUR LEGAL RIGHTS, SO PLEASE READ THEM CAREFULLY. NOTE THAT SECTION 20 OF THESE
      TERMS CONTAINS A MANDATORY ARBITRATION PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS AND
      LIMITS THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF CERTAIN DISPUTES.
    </p>
    <p className="modal-bl__text">
      These Terms of Service ("Terms") apply to your access to and use of the websites, mobile applications, products,
      or services (collectively, the "Services") of SS CARD ASSIST, LLC, our subsidiaries, and/or other affiliates
      (collectively, "SS CARD ASSIST," "we," "us," or "our"). By clicking to indicate your acceptance of these Terms, or
      by otherwise accessing or using the Services, you agree to be bound by these Terms, the Privacy Policy, and all
      terms incorporated by reference. These Terms do not alter in any way the terms or conditions of any other
      agreement you may have with us, our subsidiaries, or affiliates, in respect of any other products, services,
      websites or otherwise.
    </p>
    <p className="modal-bl__text">
      If you are using the Services on behalf of any entity, you represent and warrant that you are authorized to accept
      these Terms on such entity’s behalf and that such entity agrees to be responsible to us if you or such entity
      violates these Terms.
    </p>
    <p className="modal-bl__text">
      SS CARD ASSIST reserves the right to change or modify these Terms at any time and in our sole discretion. If we
      make changes to these Terms, we will provide notice of such changes, such as by sending a notification, by
      providing notice through the Services or updating the "Last Updated" date at the beginning of these Terms. By
      continuing to access or use the Services, you confirm your acceptance of the revised Terms and all of the terms
      incorporated therein by reference. We encourage you to review the Terms frequently to ensure that you understand
      the terms and conditions that apply when you access or use the Services. If you do not agree to the revised Terms,
      you may not access or use the Services.
    </p>
    <p className="modal-bl__text upper-case">NOT LEGAL ADVICE</p>
    <p className="modal-bl__text">
      Content is not intended to and does not constitute legal advice and no attorney-client relationship is formed, nor
      is anything submitted to this Website treated as confidential. The accuracy, completeness, adequacy or currency of
      the Content is not warranted or guaranteed. Your use of the Content on this Website or materials linked from this
      Website is at your own risk.
    </p>
    <p className="modal-bl__text">
      PROVIDER IS NOT AFFILIATED WITH ANY GOVERNMENT AGENCY. THE FEES ARE COLLECTED TO COVER ANY RELATED ASSISTANCE YOU
      MAY NEED WHEN COMPLETING THE APPLICATIONS, WHICH INCLUDES BUT NOT LIMITED TO, CUSTOMER SERVICE ASSISTANCE, VIA
      PHONE OR EMAIL. PROVIDER DOES NOT CHARGE ANY FEES FOR THE ACTUAL APPLICATIONS OR FORMS. BLANK FORMS CAN BE
      OBTAINED FOR FREE ON THE at SSA.gov
    </p>
    <h3 className="modal-bl__subtitle modal-bl__text">1. Services and Privacy Policy.</h3>
    <p className="modal-bl__text">
      SS CARD ASSIST's Services provide online software services that make it easier for users to complete and submit
      The Social Security Card Application (the SS5 Form) to the Government. SS CARD ASSIST does not provide legal or
      other government services, but rather helps users to: Submit government forms using our simplified application
      generation services and prefilled SS5 application.
    </p>
    <p className="modal-bl__text">
      SS CARD ASSIST's Services provide online software services that make it easier for users to complete and submit
      The Social Security Card Application (the SS5 Form) to the Government. SS CARD ASSIST does not provide legal or
      other government services, but rather helps users to: Submit government forms using our simplified application
      generation services and prefilled SS5 application.
    </p>
    <ul className="modal-bl__list">
      <li className="modal-bl__text">
        The SS5 Application is provided for free and may be completed and filed directly with the Government for free
        without the assistance of SS CARD ASSIST or another third party. SS CARD ASSIST provides simplified online
        preparation and filing software to assist in preparing and filing the Social security card application (SS5). SS
        CARD ASSIST does not provide any professional advice of any kind, whether legal, financial, or otherwise. SS
        CARD ASSIST is not affiliated with any government agencies, including but not limited to The Social Security
        Administration, or other agencies, whether federal, state or local, or other entities.
      </li>
      <li className="modal-bl__text">
        SS CARD ASSIST collects information from you for the purposes of streamlining your form filing experience. With
        your verification and at your direction, SS CARD ASSIST shares the information it collects with governmental
        agencies and any other entities you designate to receive the information, or SS CARD ASSIST may show you how to
        send the information directly to a governmental agency. By using the Services, you hereby consent to such
        collection, use and disclosure.
      </li>
      <li className="modal-bl__text">
        Without limiting the foregoing, please refer to our privacy policy for information about how we collect, use,
        and disclose information about our users.
      </li>
    </ul>
    <p className="modal-bl__subtitle modal-bl__text">NOT LEGAL ADVICE</p>
    <ul className="modal-bl__list">
      <li className="modal-bl__text">
        The Services are not targeted toward or intended for use by anyone under the age of 18. By using the Services,
        you represent and warrant that you are at least 18 years of age and (a) have not been previously suspended or
        removed from the Services, or engaged in any activity that could result in suspension or removal from the
        Services, and (b) have full power and authority to enter into these Terms and in so doing will not violate any
        other agreement to which you are a party. If you are under the age of 18, you represent and warrant that you
        have a parent or guardian’s permission and supervision to use the Services.
      </li>
    </ul>
    <h3 className="modal-bl__subtitle modal-bl__text">10. Repeat Infringer Policy; Copyright Complaints.</h3>
    <ul className="modal-bl__list">
      <li className="modal-bl__text">
        In accordance with the Digital Millennium Copyright Act and other applicable laws, we have adopted a policy of
        limiting access to the App by, or terminating the accounts of, users, in appropriate circumstances and in our
        sole discretion, who infringe the intellectual property rights of others. If you believe that anything on the
        App infringes any copyright that you own or control, you may file a notification of such infringement with our
        designated agent as set forth below:
        <ul className="list__inner">
          <li className="modal-bl__text">
            <span>i.</span>Name of Designated Agent: Debra Moll.
          </li>
          <li className="modal-bl__text">
            <span>ii.</span>Address: 2093 PHILADELPHIA PIKE, #3338, CLAYMONT, DE 19703
          </li>
          <li className="modal-bl__text">
            <span>iii.</span>Email: legal@SS CARD ASSIST.com
          </li>
        </ul>
      </li>
    </ul>
  </div>
)

export const getContent = (contentTermsOfUse: string, defaultContent: JSX.Element) =>
  contentTermsOfUse ? (
    <div className="modal-bl__content-container" dangerouslySetInnerHTML={{ __html: contentTermsOfUse }} />
  ) : (
    defaultContent
  )

export interface DispatchInterface {
  (action: Action<any>): void;
}

type TermsOfUseComponentProps = {
  setValueModal: any,
  dispatch: DispatchInterface,
}

export interface TermsOfUseInterface {
  htmlContent: string;
  wpLink: string;
}

const TermsOfUseComponent: React.FunctionComponent<TermsOfUseComponentProps> = ({ setValueModal, dispatch }) => {
  const contentTermsOfUse =
    useSelector<RootState, { modalContent: ModalContentInterface | null }>(arrayStepSelector)?.modalContent?.[
      "termOfUse"
    ]?.htmlContent ?? ""

  return (
    // @ts-ignore
    <RulesCheckBoxModal setValueModal={setValueModal} headerName={"Terms of Use"} dispatch={dispatch}>
      {getContent(contentTermsOfUse, defaultContent)}
    </RulesCheckBoxModal>
  )
}

export default TermsOfUseComponent
