import React, { FC } from "react"
import "./RadioButton.scss"
import { Radio } from "antd"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS, COLORS_NEW } from "../../types/Colors"

type GRadioButtonProps = {
  disabled?: boolean,
  value?: any,
  label?: string,
  onClick?: any,
  children?: React.ReactNode,
  onMouseEnter?: any,
  className?: any,
}

const GRadioButton: FC<GRadioButtonProps> = ({
  disabled,
  value,
  onClick,
  children,
  label,
  onMouseEnter,
  className,
}) => {
  return (
    <Radio.Button
      onMouseEnter={onMouseEnter}
      className={`${className} GRadioButton`}
      disabled={disabled}
      value={value}
      onClick={onClick}
    >
      {label && <GText text={label} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />}
      {!label && children}
    </Radio.Button>
  )
}

export default GRadioButton
