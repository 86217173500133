import React from "react"

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
  fillCircle?: boolean;
}

export const SvgRenewal= ({
  width = 65,
  height = 65,
  color = "#185EC8",
  backgroundColor = "#EEF4FB",
  fillCircle = false,
}: ISvgCheck) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1637 3.64844V7.28497H11.5273"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.83203 13.3475V9.71094H5.46839"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.35324 6.68197C3.66062 5.81331 4.18302 5.03668 4.87171 4.42454C5.56039 3.81241 6.39292 3.38471 7.2916 3.18136C8.19028 2.97801 9.12582 3.00565 10.0109 3.26168C10.896 3.51771 11.7019 3.9938 12.3532 4.64551L15.1654 7.28806M1.83203 9.71241L4.64415 12.355C5.29551 13.0067 6.10135 13.4828 6.98646 13.7388C7.87157 13.9948 8.80712 14.0225 9.7058 13.8191C10.6045 13.6158 11.437 13.1881 12.1257 12.5759C12.8144 11.9638 13.3368 11.1872 13.6442 10.3185"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
