import React from "react"
import {COLORS} from "../../../types/Colors";

interface ISvgCheck {
    width: number;
    height: number;
    color: string;
    backgroundColor?: string;
}

export const SvgAddDocumentIconCircleBorder = ({
                                                width = 64,
                                                height = 64,
                                                color = COLORS.DARK_BLUE_600,
                                                backgroundColor = "#EEF4FB"
                                            }: ISvgCheck) => {
    const viewBox = `0 0 ${width} ${height}`;
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.1734 1.06057C31.385 0.828124 33.615 0.828124 35.8266 1.06057C38.0382 1.29303 40.2194 1.75665 42.3344 2.44385C44.4493 3.13105 46.4865 4.03803 48.4123 5.14993C50.3382 6.26184 52.1423 7.57255 53.7949 9.06058C55.4475 10.5486 56.9396 12.2057 58.2467 14.0048C59.5538 15.8039 60.6688 17.7351 61.5733 19.7667C62.4778 21.7982 63.1669 23.919 63.6293 26.0942C64.0916 28.2694 64.3247 30.4871 64.3247 32.7109C64.3247 34.9347 64.0916 37.1525 63.6293 39.3277C63.1669 41.5029 62.4778 43.6236 61.5733 45.6552C60.6688 47.6868 59.5538 49.6179 58.2467 51.417C56.9396 53.2161 55.4475 54.8733 53.7949 56.3613C52.1423 57.8493 50.3382 59.16 48.4123 60.2719C46.4865 61.3838 44.4493 62.2908 42.3344 62.978C40.2194 63.6652 38.0382 64.1288 35.8266 64.3613C33.615 64.5938 31.385 64.5938 29.1734 64.3613C26.9618 64.1288 24.7806 63.6652 22.6656 62.978C20.5507 62.2908 18.5135 61.3838 16.5877 60.2719C14.6618 59.16 12.8577 57.8493 11.2051 56.3613C9.5525 54.8733 8.0604 53.2161 6.75328 51.417C5.44615 49.6179 4.3312 47.6868 3.42669 45.6552C2.52218 43.6236 1.8331 41.5029 1.37074 39.3277C0.908388 37.1524 0.675299 34.9347 0.675299 32.7109C0.675299 30.4871 0.908388 28.2694 1.37074 26.0942C1.8331 23.919 2.52218 21.7982 3.42669 19.7667C4.3312 17.7351 5.44615 15.804 6.75328 14.0048C8.0604 12.2057 9.5525 10.5486 11.2051 9.06058C12.8577 7.57255 14.6618 6.26184 16.5877 5.14994C18.5135 4.03803 20.5507 3.13105 22.6656 2.44385C24.7806 1.75665 26.9618 1.29303 29.1734 1.06057Z" fill={backgroundColor}/>
            <path d="M34.5 22.7109H26.5C25.9696 22.7109 25.4609 22.9217 25.0858 23.2967C24.7107 23.6718 24.5 24.1805 24.5 24.7109V40.7109C24.5 41.2414 24.7107 41.7501 25.0858 42.1252C25.4609 42.5002 25.9696 42.7109 26.5 42.7109H38.5C39.0304 42.7109 39.5391 42.5002 39.9142 42.1252C40.2893 41.7501 40.5 41.2414 40.5 40.7109V28.7109L34.5 22.7109Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M34.5 22.7109V28.7109H40.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32.5 38.7109V32.7109" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M29.5 35.7109H35.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
