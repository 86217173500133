import React, {FC, useEffect, useMemo, useState} from "react";
import GText from "../../atoms/Text/Text";
import GInputText from "../../molecules/InputText/InputText";
import {Form, Radio} from "antd";
import GFormItem from "../../organisms/FormItem/FormItem";
import GButton from "../../molecules/Button/GButton";
import {SIZE} from "../../types/types";
import "./ActivateInsurance.scss"
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes";
import GRadioButton from "../../molecules/RadioButton/RadioButton";
import {COLORS_NEW} from "../../types/Colors";
import {IInsuredDocument} from "../../types/Models";
import InputMask from "react-input-mask";
import GNotification, {NOTIFICATION_TYPE} from "../../organisms/Notification/Notification";
import { Rule } from "antd/lib/form";
import {ProductsIds} from "../../../../util/ProductsIds";

type ActivateInsuranceProps = {
    product: {
        name: string,
        type?: string,
        solutionName: string
    },
    onTextChange?: (value: string) => void,
    onNumberChange?: (value: string) => void,
    onSubmit: (data: IInsuredDocument) => void
}

const ActivateInsurance: FC<ActivateInsuranceProps> = ({
    product, onSubmit = (data) => console.log(data), onNumberChange, onTextChange
}) => {
    const headerText = `Add your ${product.solutionName || 'service'} details to activate insurance`
    const [form] = Form.useForm<IInsuredDocument>()

    const confirmPossession = Form.useWatch('confirmPossession', form)

    const handleSubmit = (data: any) => {
        if (showConfirmPossessionField() && confirmPossession !== true) {
            return
        }
        onSubmit(data)
    }

    const productsForNumber = [
        "PAS",
        "Passport",
        "SSN",
        "Social Security Number",
        "EIN",
        "EIN Number",
        "IRS",
    ]
    const cerNames = [
        "CER",
        "Birth Certificate"
    ]

    const showNumberField = () => {
        return productsForNumber.includes(product.name) || (product.type && productsForNumber.includes(product.type))
    }
    const showConfirmPossessionField = () => {
        return cerNames.includes(product.name)
    }

    const getInputMask: string = (
        () => {
            switch (product.name) {
                case ProductsIds.SSNId:
                case "Social Security Number":
                    return "999-99-9999"
                case ProductsIds.PassportId:
                case "Passport":
                    return "999999999"
                case ProductsIds.EinId:
                    return "99-9999999"
                default:
                    return "99999999999999999"
            }
        }
    )()
    const maskPlaceHolder = getInputMask.replaceAll("9", "X")
    const validationNumberDocument: Rule[] = [
        {required: true, message: 'Required'},
        {
            validator: (rule, value, callback) => {
                if (!value || value.trim() === "" || value === maskPlaceHolder) {
                    callback();
                    return;
                }
                if(product.name === ProductsIds.PassportId){

                    if (/^\d+$/.test(value) === false) {
                        callback(`Please enter a valid ${product.solutionName} number`);
                        return;
                    }
                }
                if(product.name === ProductsIds.EinId || product.name === ProductsIds.SSNId){

                    if (/^[\d-]+$/.test(value) === false) {
                        callback(`Please enter a valid ${product.solutionName} number`);
                        return;
                    }
                }
                callback();
            }
        }
    ];

    return <div className="GActivateInsurance">
        <div className={"GActivateInsurance__Header"}>
            <GText text={headerText} size={SIZE.HEADING_MDLG}  />
        </div>
        <Form
            form={form}
            onFinish={handleSubmit}
            className={`GActivateInsurance__Form`}
            layout={`vertical`}
        >
            {showNumberField() && <GFormItem name="documentNumber" rules={validationNumberDocument} label={
                <div>
                    <GText text={`What's your ${product.solutionName || 'service'}${!product.solutionName.includes("number") ? " number" : ""}?`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                </div>
            }>
                <InputMask
                    mask={getInputMask}
                    maskPlaceholder={null}
                >
                    <GInputText inputMode={"numeric"} />
                </InputMask>
            </GFormItem>}
            {showConfirmPossessionField() && <GFormItem name="confirmPossession" rules={[{required: true, message: 'Required'}]} label={
                <div>
                    <GText text={`Do you have your birth certificate in your possession?`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
                </div>
            }>
                <Radio.Group>
                    <GRadioButton value={true}>
                        <div className={`GRadioItem`}>
                            <GText text={"Yes"} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800}></GText>
                        </div>
                    </GRadioButton>
                    <GRadioButton value={false}>
                        <div className={`GRadioItem`}>
                            <GText text={"No"} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800}></GText>
                        </div>
                    </GRadioButton>
                </Radio.Group>
            </GFormItem>}
            {confirmPossession === false && showConfirmPossessionField() &&
                <GNotification
                    text={`To insure your birth certificate, you need to have it in your possession now`}
                    type={NOTIFICATION_TYPE.ERROR}
                    icon={ICONS_SVG_TYPE.INFORMATION}
                /> }
            <div className={"GActivateInsurance__Form__BtnContainer"}>
                <GButton
                    text={`Insure my ${product.solutionName || 'service'}`}
                    isSubmit={true}
                    textSize={SIZE.PARAGRAPH_BOLD_16}
                    size={"large"}
                />
            </div>
        </Form>
    </div>

}

export default ActivateInsurance;