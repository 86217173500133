import {createContext, useEffect, useState} from "react";
// @ts-ignore
import {useHistory} from "react-router-dom/cjs/react-router-dom";

interface IHistoryContext {
  navigate: (url: string) => void
}

// @ts-ignore
export const HistoryContext = createContext<IHistoryContext>()

// @ts-ignore
export const HistoryProvider = ({ children }) => {
  const history = useHistory()

  const navigate = (url: string) => {
    history.push(url)
  }

  const returnValue = {
    navigate
  }


  return <HistoryContext.Provider value={returnValue}>
        {children}
    </HistoryContext.Provider>
}