import TemplateOneBuilder from "../TemplateOneBuilder";
import {ITemplateOneBuilder} from "./ITemplateOneBuilder";
import {IUpsellProcessingData} from "../../../StepUpsell";
import TemplateOne from "../TemplateOne";
import thirdPartyUpsellImage from "../../../../../../../../assets/image/upsells/third_party_upsell_venture.png";

export class ThirdPartyUpsellVenture implements ITemplateOneBuilder{
    buildTemplate(builder: TemplateOneBuilder, data: IUpsellProcessingData, solutionName: string): TemplateOne {
       builder
           .setImageHeader(thirdPartyUpsellImage)
           .setPrice(undefined)
           .setFooterText("Clicking “Save on my government fees” will open this offer in a new tab, so you won’t lose any progress on your current TSA PreCheck® application.")
           .setTagInformation('Last chance upgrade')
           .setCheckListItems([
                'Capital One Venture Rewards offers a rich welcome bonus of 75,000 miles after $4,000 in spend on purchases within 3 months',
                'Earn miles while you travel with 5X Miles per dollar on hotels and rental cars booked through Capital One Travel and get 2 Miles per dollar on every other purchase',
           ])
       return builder.build();
    }
}