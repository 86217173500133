/* eslint-disable */
import React, { useState } from "react"
import { connect } from "react-redux"
import { Form, Select } from "antd"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { getOptionsName } from "../SelectComponent/createOptions"
import RenderValuesComponent from "./RenderValuesComponent"

type SelectMultipleProps = {
  field: FieldInterface,
  submitObject: {} | any,
  dispatch: any,
  servicesCustomer: {} | any,
}

const SelectMultiple: React.FunctionComponent<SelectMultipleProps> = ({
  dispatch,
  field,
  submitObject,
  servicesCustomer,
}) => {
  const { Option } = Select

  const [, setAutocompleteValueShort] = useState(submitObject[field.name])
  const [render, setRender] = useState(true)
  const [timeId, setTimeId]: any = useState(null)

  const optionsBack = (servicesCustomer && servicesCustomer[field.optionNew]) || []

  const handlerOnChange = (value: [string] | any, field: {} | any) => {
    const selectionCap = field.maxSelections && Number.isInteger(field.maxSelections) ? field.maxSelections : optionsBack.length
    if( optionArraylength === undefined || optionArraylength < selectionCap) {

        const chooseOptions = optionsBack.reduce((acc: [] | any, item: {} | any) => {
            if (value.includes(item.company_name)) {
                return acc.concat(item)
            }
            return acc
        }, [])
        const services = {...submitObject["services"], [field.optionNew]: chooseOptions}
        dispatch(setValueCurrentField("services", services))
    }
    else{
        value = value.pop()
    }
  }

  let optionArraylength = submitObject.services && submitObject.services[field.optionNew] && submitObject.services[field.optionNew].length

  return (
    <div className="adress__change_multiple-container_main">
      <div className="adress__change_multiple-description">
        <p>{`${field.description} ( ${optionArraylength ? optionArraylength : 0} out of ${field.options.length})`}</p>
      </div>
      {render && (
        <Form.Item label={field.label} name={field.name}>
          <Select
            mode="multiple"
            placeholder={field.placeholder}
            showArrow
            onChange={(value: {} | string | any) => handlerOnChange(value, field)}
            showSearch={field.inputOption ? true : false}
            // style={{ width: 500 }}
            value={submitObject[field.name]}
            defaultValue={submitObject[field.name]}
            filterOption={
              field.inputOption
                ? (input, option) => {
                    return (
                      option && option.props && option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                    )
                  }
                : false
            }
          >
            {
              // field && field.options
              optionsBack.map((item: any, index: number) => {
                const optionName: {} | any = getOptionsName(item.company_name)

                return (
                  <React.Fragment key={index}>
                    <Option value={optionName.submitValue}>{optionName && optionName.submitValue}</Option>
                  </React.Fragment>
                )
              })
            }
          </Select>
        </Form.Item>
      )}
      {!render && (
        <Form.Item label={field.label} name={field.name}>
          <Select
            mode="multiple"
            placeholder={field.placeholder}
            showArrow
            onChange={(value: {} | string | any) => handlerOnChange(value, field)}
            showSearch={field.inputOption ? true : false}
            style={{ width: 500 }}
            value={submitObject[field.name]}
            defaultValue={submitObject[field.name]}
            filterOption={
              field.inputOption
                ? (input, option) => {
                    return (
                      option && option.props && option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                    )
                  }
                : false
            }
          >
            {
              // field && field.options
              optionsBack.map((item: any, index: number) => {
                const optionName: {} | any = getOptionsName(item.company_name)

                return (
                  <React.Fragment key={index}>
                    <Option value={optionName.submitValue}>{optionName && optionName.renderValue}</Option>
                  </React.Fragment>
                )
              })
            }
          </Select>
        </Form.Item>
      )}
      <RenderValuesComponent
        field={field}
        setAutocompleteValueShort={setAutocompleteValueShort}
        setRender={setRender}
        setTimeId={setTimeId}
        timeId={timeId}
      />
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
  servicesCustomer: state.form.servicesCustomer,
})

export default connect(mapStateToProps)(SelectMultiple)
