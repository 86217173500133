import React from "react"

interface ISvgAddPhoneIcon {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
}

export const SvgAddPhoneIcon = ({ width, height, color, backgroundColor }: ISvgAddPhoneIcon) => {
  return (
    <svg width={width} height={height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" rx="25" fill={backgroundColor} />
      <path
        d="M29.167 16.6667H20.8337C19.9132 16.6667 19.167 17.4128 19.167 18.3333V31.6667C19.167 32.5871 19.9132 33.3333 20.8337 33.3333H29.167C30.0875 33.3333 30.8337 32.5871 30.8337 31.6667V18.3333C30.8337 17.4128 30.0875 16.6667 29.167 16.6667Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M25 30H25.0083" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
