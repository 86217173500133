export const BlockStepTypes = {
  UPSELL: "upsell",
  INVALID_ADDRESS: "invalid_address",
  SIGNATURE: "signature",
  QUESTIONNAIRE: "questionnaire",
  UPLOADING_FILE: "uploadingFile",
  UPLOADING_ADDITIONAL_FILE: "uploadingAdditionalFile",
  ADDITIONAL_INFORMATION_STEP: "additional_information_step",
  TSA_QUESTIONNAIRE: "tsa_questionnaire",
  PROCESSING_VALIDATION: "processing_validation",
  PROCESSING_SHIPMENT: "processing_shipment",
  TRACKING: "tracking",
  PROCESSING_FOLLOW_INSTRUCTIONS: "processing_follow_instructions",
  PROCESSING_BY_GOVERNMENT: "processing_by_government",
  PENDING_RECEIVING_DOCUMENTATION_CONFIRMATION: "pending_receiving_document_confirmation",
  CONTENT: "content",
  GOVERNMENT_FEES: "government_fees",
  SENT_TO_NOTARIZE: "sent_to_notarize",
  PROCESSING_VALIDATION_NOTARIZATION: "processing_validation_notarization",
  SHIPMENT: "shipment",
  PENDING_RECEIVING_DOCUMENT_CONFIRMATION: "pending_receiving_document_confirmation",
  INVALID_OLD_ADDRESS: "invalid_old_address",
  INVALID_NEW_ADDRESS: "invalid_new_address",
  SERVICE_PROVIDERS: "service_providers",
  COMPLETED: "completed",
  VALIDATION: "validation",
  PROCESSING: "processing",
  QUESTIONNAIRE_SSN: "questionnaireSsn",
  UPLOAD_PHOTO: "upload_photo",
  QUESTIONNAIRE_PASSPORT: "questionnairePassport",
  PENDING_SHIPMENT: "pending_shipment",
  IN_PROGRESS: "in_progress",
  PENDING: "pending"
}