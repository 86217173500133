import React from "react";
import { COLORS_NEW } from "../Components/atomicDesign/types/Colors";
import TabRoundedNotification from "../Components/atomicDesign/templates/TabRoundedNotification/TabRoundedNotification";

export const enum RecordType {
  SSN = 'SSN',
  BK = 'BK',
  CC = 'CC',
  DL = 'DL',
  MD = 'MD',
  PP = 'PP',
  EM = 'EM',
  TM = 'TM',
  BD = 'BD',
  ADDRESS = 'ADDRESS',
}

export const getRecordTypeName = (recordType: string): string => {

  const recordTypeMap: { [key: string]: string } = {
    BK: 'Bank accounts',
    CC: 'Credit cards',
    DL: "Driver's license",
    MD: 'Medical ID',
    PP: 'Passport',
    EM: 'Email',
    SSN: 'Social Security Number',
    TM: 'Phone',
    BD: 'Birthday',
    ADDRESS: 'Address'
  };

  return recordTypeMap[recordType];
};

export const enum MissingFieldsTypes {
  dateOfBirth = 'dateOfBirth',
  ssn = 'ssn',
  email = 'email',
  oldEmail = 'oldEmail',
  firstName = 'firstName',
  lastName = 'lastName',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  state = 'state',
  zip = 'zip',
  phone = 'phone'
}

export const enum MissingFieldsLabels {
  dateOfBirth = 'Date of Birth',
  ssn = 'Social Security Number',
  email = 'Email',
  firstName = 'First name',
  lastName = 'Last name',
  address1 = 'Address 1',
  city = 'City',
  state = 'State',
  zip = 'ZIP code',
  phone = 'Phone'
}

export const enum AlertDismissStatuses {
  DISMISS = 'DISMISS',
  RED_ALERT = 'RED_ALERT'
}

export const enum AlertStatuses {
  // enftortra data can be unexpected, so we need to check for both cases
  REDALERT = 'REDALERT',
  RedAlert = 'RedAlert',
  ALERT = 'ALERT',
  Alert = 'Alert',
  DISMISSED = 'DISMISSED',
  Dismissed = 'Dismissed',
  DISMISS = 'DISMISS',
  Dismiss = 'Dismiss',
}

export const enum ScrollTargets {
  creditMonitoring = 'creditMonitoring',
  personalInfo = 'personalInfo',
  identityInsurance = 'identityInsurance',
}

export const isAlert = (alert: any) => {
  return alert.AlertStatus === AlertStatuses.ALERT || alert.AlertStatus === AlertStatuses.Alert
}

export const isRedAlert = (alert: any) => {
  return alert.AlertStatus === AlertStatuses.REDALERT || alert.AlertStatus === AlertStatuses.RedAlert
}

export const isDismissed = (alert: any) => {
  return (
    alert.AlertStatus === AlertStatuses.DISMISS ||
    alert.AlertStatus === AlertStatuses.Dismiss ||
    alert.AlertStatus === AlertStatuses.DISMISSED ||
    alert.AlertStatus === AlertStatuses.Dismissed
  )
}

export const enum DismissAlertStatuses {
  SUCCESS = 'SUCCESS',
}

export const enum BUNDLE_TYPES {
  INITIAL = "866|0",
  MEDIUM = "622|1",
  ADVANCED = "622|2",
}

export const enum BUNDLE_NAMES {
  INITIAL = "AIG + Recovery",
  MEDIUM = "AIG + Recovery +PII + PII SO",
  ADVANCED = "AIG + Recovery +PII + PII SO + 1BM",
}

export const enum IdentityProtectionType {
  RED = "RED",
  ALL = "ALL",
}

export const enum AlertBackgroundTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export const mockTeaserRecords = [
  {
    recordType: RecordType.SSN,
    isPresent: false,
  },
  {
    recordType: RecordType.ADDRESS,
    isPresent: false,
  },
  {
    recordType: RecordType.EM,
    isPresent: false,
  },
  {
    recordType: RecordType.TM,
    isPresent: false,
  },
  {
    recordType: RecordType.MD,
    isPresent: false,
  },
  {
    recordType: RecordType.DL,
    isPresent: false,
  },
  {
    recordType: RecordType.PP,
    isPresent: false,
  },
  {
    recordType: RecordType.BK,
    isPresent: false,
  },
  {
    recordType: RecordType.CC,
    isPresent: false,
  },
]

export const usaStates = [
  { value: 'AL', label: 'AL', fullLabel: 'Alabama' },
  { value: 'AK', label: 'AK', fullLabel: 'Alaska' },
  { value: 'AZ', label: 'AZ', fullLabel: 'Arizona' },
  { value: 'AR', label: 'AR', fullLabel: 'Arkansas' },
  { value: 'CA', label: 'CA', fullLabel: 'California' },
  { value: 'CO', label: 'CO', fullLabel: 'Colorado' },
  { value: 'CT', label: 'CT', fullLabel: 'Connecticut' },
  { value: 'DE', label: 'DE', fullLabel: 'Delaware' },
  { value: 'FL', label: 'FL', fullLabel: 'Florida' },
  { value: 'GA', label: 'GA', fullLabel: 'Georgia' },
  { value: 'HI', label: 'HI', fullLabel: 'Hawaii' },
  { value: 'ID', label: 'ID', fullLabel: 'Idaho' },
  { value: 'IL', label: 'IL', fullLabel: 'Illinois' },
  { value: 'IN', label: 'IN', fullLabel: 'Indiana' },
  { value: 'IA', label: 'IA', fullLabel: 'Iowa' },
  { value: 'KS', label: 'KS', fullLabel: 'Kansas' },
  { value: 'KY', label: 'KY', fullLabel: 'Kentucky' },
  { value: 'LA', label: 'LA', fullLabel: 'Louisiana' },
  { value: 'ME', label: 'ME', fullLabel: 'Maine' },
  { value: 'MD', label: 'MD', fullLabel: 'Maryland' },
  { value: 'MA', label: 'MA', fullLabel: 'Massachusetts' },
  { value: 'MI', label: 'MI', fullLabel: 'Michigan' },
  { value: 'MN', label: 'MN', fullLabel: 'Minnesota' },
  { value: 'MS', label: 'MS', fullLabel: 'Mississippi' },
  { value: 'MO', label: 'MO', fullLabel: 'Missouri' },
  { value: 'MT', label: 'MT', fullLabel: 'Montana' },
  { value: 'NE', label: 'NE', fullLabel: 'Nebraska' },
  { value: 'NV', label: 'NV', fullLabel: 'Nevada' },
  { value: 'NH', label: 'NH', fullLabel: 'New Hampshire' },
  { value: 'NJ', label: 'NJ', fullLabel: 'New Jersey' },
  { value: 'NM', label: 'NM', fullLabel: 'New Mexico' },
  { value: 'NY', label: 'NY', fullLabel: 'New York' },
  { value: 'NC', label: 'NC', fullLabel: 'North Carolina' },
  { value: 'ND', label: 'ND', fullLabel: 'North Dakota' },
  { value: 'OH', label: 'OH', fullLabel: 'Ohio' },
  { value: 'OK', label: 'OK', fullLabel: 'Oklahoma' },
  { value: 'OR', label: 'OR', fullLabel: 'Oregon' },
  { value: 'PA', label: 'PA', fullLabel: 'Pennsylvania' },
  { value: 'RI', label: 'RI', fullLabel: 'Rhode Island' },
  { value: 'SC', label: 'SC', fullLabel: 'South Carolina' },
  { value: 'SD', label: 'SD', fullLabel: 'South Dakota' },
  { value: 'TN', label: 'TN', fullLabel: 'Tennessee' },
  { value: 'TX', label: 'TX', fullLabel: 'Texas' },
  { value: 'UT', label: 'UT', fullLabel: 'Utah' },
  { value: 'VT', label: 'VT', fullLabel: 'Vermont' },
  { value: 'VA', label: 'VA', fullLabel: 'Virginia' },
  { value: 'WA', label: 'WA', fullLabel: 'Washington' },
  { value: 'WV', label: 'WV', fullLabel: 'West Virginia' },
  { value: 'WI', label: 'WI', fullLabel: 'Wisconsin' },
  { value: 'WY', label: 'WY', fullLabel: 'Wyoming' },
];

export const getStateFullName = (stateAbbr: string) => {
  if(stateAbbr) {
    const state = usaStates.find(state => state.value === stateAbbr.toUpperCase());
    return state ? state.fullLabel : 'Invalid state abbreviation';
  }
  return stateAbbr
}

export const cardTypeOptions = [
  { value: "Credit", label: "Credit card" },
  { value: "Debit", label: "Debit card" },
]

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export interface FormInput {
  recordType: RecordType;
  handleFormActions: (data: any) => void;
}

export interface RecordContentProps {
  ValueToMonitor?: string;
  RNumber?: string;
  DLState?: string;
  currentUserName?: {
    firstName: string,
    lastName: string,
  };
  addressTop?: string;
  addressBottom?: string;
  updateCustomerLoader?: boolean;
}

export const getModalTitle = (text: string) => {
  switch (text) {
    case RecordType.BK:
      return 'Bank account monitoring'
    case RecordType.CC:
      return 'Debit / Credit card monitoring'
    case RecordType.DL:
      return 'Driver license monitoring'
    case RecordType.MD:
      return 'Medical ID monitoring'
    case RecordType.PP:
      return 'Passport monitoring'
    case RecordType.EM:
      return 'Email address monitoring'
    case RecordType.TM:
      return 'Phone number monitoring'
    case RecordType.SSN:
      return 'SSN monitoring'
    case RecordType.ADDRESS:
      return 'Address monitoring'
    default:
      return '';
  }
}

export const getModalProtectButtonLabel = (text: string) => {
  switch (text) {
    case RecordType.BK:
      return 'Protect new bank account'
    case RecordType.CC:
      return 'Protect new credit / debit card'
    case RecordType.DL:
      return 'Protect new drivers license'
    case RecordType.MD:
      return 'Protect new Medical ID'
    case RecordType.PP:
      return 'Protect new passport'
    case RecordType.EM:
      return 'Protect new email address'
    case RecordType.TM:
      return 'Protect new phone number'
    case RecordType.SSN:
      return 'Protect new SSN'
    default:
      return '';
  }
}

export const getModalTopQuestion = (recordType: string) => {
  switch (recordType) {
    case RecordType.BK:
      return 'Why you should monitor your bank accounts?';
    case RecordType.CC:
      return 'Why you should monitor your debit / credit cards?';
    case RecordType.DL:
      return 'Why you should monitor your driver license?';
    case RecordType.MD:
      return 'Why you should monitor your medical ID?';
    case RecordType.PP:
      return 'Why you should monitor your Passport?';
    case RecordType.EM:
      return 'Why you should monitor your email?';
    case RecordType.TM:
      return 'Why you should monitor your phone number?';
    case RecordType.SSN:
      return 'Why you should monitor your SSN?';
    case RecordType.ADDRESS:
      return 'Why you should monitor your address?'
    default:
      return '';
  }
}

export const getModalTopCopy = (recordType: string) => {
  switch (recordType) {
    case RecordType.BK:
      return 'If your bank account information is used fraudulently, it can result in identity theft, unauthorized transactions, significant financial losses, and damage to your credit score.';
    case RecordType.CC:
      return 'If your card information falls into the wrong hands, it can result in identity theft, unauthorized transactions, significant financial losses, and damage to your credit score.';
    case RecordType.DL:
      return 'If your driver\'s license information is stolen or misused, it can lead to identity theft, unauthorized access to your personal information, and even involvement in criminal activities committed under your name.';
    case RecordType.MD:
      return 'If your medical ID falls into the wrong hands, it can result in fraudulent medical services, incorrect medical records, and even serious health risks due to misdiagnoses or inappropriate treatments.';
    case RecordType.PP:
      return 'If your passport is stolen or used fraudulently, it can lead to identity theft, illegal border crossings, and even involvement in criminal activities using your identity.';
    case RecordType.EM:
      return 'If your email is breached, cybercriminals can exploit your sensitive data to perpetrate identity theft, financial fraud, or phishing attacks against you and your contacts.';
    case RecordType.TM:
      return 'If your phone number is compromised, cybercriminals can use it to impersonate you, conduct phishing attacks, or gain unauthorized access to your accounts through SMS verification codes.';
    case RecordType.ADDRESS:
      return 'If your address information is used fraudulently, it can result in identity theft and unauthorized access to your personal information.'
    default:
      return '';
  }
}

export const getModalBottomCopy = (recordType: string) => {
  switch (recordType) {
    case RecordType.BK:
      return "Add bank account for monitoring"
    case RecordType.CC:
      return 'Add debit / credit card for monitoring'
    case RecordType.DL:
      return 'Add driver’s license for monitoring'
    case RecordType.MD:
      return 'Add medical ID for monitoring'
    case RecordType.PP:
      return 'Add passport for monitoring'
    case RecordType.EM:
      return 'Add email address for monitoring'
    case RecordType.TM:
      return 'Add phone number for monitoring'
    case RecordType.ADDRESS:
      return 'Add address for monitoring'
    default:
      return '';
  }
}

export const getMaxRecordCount = (recordType: string) => {
  switch (recordType) {
    case RecordType.DL:
    case RecordType.MD:
    case RecordType.PP:
    case RecordType.TM:
      return 1;
    case RecordType.BK:
    case RecordType.EM:
    case RecordType.CC:
      return 10;
    default:
      return 1;
  }
};

export const identityRestorationModalText = [
  {
    headline: "GOV+ Identity Restoration Services help you regain control of your good name and finances after identity theft occurs. Instead of spending a great deal of time and emotional energy in the process of getting your identity back on track, we help simplify the process for you. GOV+ Identity Recovery Experts are highly qualified to protect victims of identity theft and deliver value when it's needed the most.",
    options: [
      { label: "U.S. Based experts trained continuously throughout the year" },
      { label: "One-on-one support until identity is fully restored" },
      { label: "Direct access to your dedicated Identity Recovery Expert" },
      { label: "Disputes will be submitted on your behalf" },
    ],
  }
]

export const identityTheftInsuranceModalText = [
  {
    headline: "GOV+ subscription includes Identity Theft Insurance with zero deductible. We will directly work with you on resolving identity fraud. Our GOV+ experts will help you contact the proper authorities and assist in the paperwork. If you become a victim of identity theft you will be covered for any of the following:",
    options: [
      { label: "Legal defense fees" },
      { label: "Private investigator costs" },
      { label: "And much more" },
    ],
  }

]

export const creditData = [
  { color: COLORS_NEW.GREEN_800, label: "Excellent", ranges: "781-850" },
  { color: COLORS_NEW.GREEN_600, label: "Good", ranges: "721-780" },
  { color: COLORS_NEW.YELLOW_600, label: "Fair", ranges: "661-720" },
  { color: COLORS_NEW.ORANGE, label: "Poor", ranges: "601-660" },
  { color: COLORS_NEW.RED_700, label: "Very poor", ranges: "300-600" },
]

export const topConfirmModalText = "Thanks for letting us know you didn’t authorize this activity. We’ll notify the company to prevent any more unauthorized transactions."
export const bottomConfirmModalText = "You can expect a GOV+ specialist to contact you within 24 hours for further investigation."
export const headerConfirmModalText = "Activity marked as “not me”"

export const getDashedButtonLabel = (recordType: string) => {
  switch (recordType) {
    case RecordType.BK:
      return 'Protect new bank account'
    case RecordType.CC:
      return 'Protect new credit / debit card'
    case RecordType.EM:
      return 'Protect new email '
    case RecordType.TM:
      return 'Protect new phone number'
    case RecordType.DL:
      return 'Protect new driver license'
    case RecordType.MD:
      return 'Protect new medical ID'
    case RecordType.PP:
      return 'Protect new passport'
    case RecordType.ADDRESS:
      return 'Protect new address'
    default:
      return '';
  }
}

export const filterRecordsByType = (records: any[], recordType: any) => {
  return records.filter((record: { type: any; }) => record.type === recordType);
};

export const checkDataLength = (records: any[]) => {
  if (!records || (records.length === 1 && Object.keys(records[0]).length === 0)) {
    return 0;
  }
  return records.length;
}

export const canAddMoreRecords = (recordType: string, records: any[]) => {
  const maxCount = getMaxRecordCount(recordType);
  const currentCount = checkDataLength(records);
  return currentCount < maxCount;
};

export const formattedData = (item: string) => {
  return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
};

export const prepareChartData = (data: any) => {
  const chartData = [];
  for (const item of data) {
    const month = item.Month.split(' ')[0];
    chartData.push({
      name: formattedData(month),
      pv: item.EqfCreditScore,
    });
  }
  return chartData;
}

export const splitHandler = (data: any, separator: string) => {

  if (!Array.isArray(data)) {
    return null;
  }

  if (typeof data[0] === 'string' && data[0].includes(separator)) {
    return data[0].split(separator).map(item => item.trim());
  } else {
    return data;
  }
}

export function removeHTMLEntities(str: string) {
  const decodedStr = str
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'");

  const strippedStr = decodedStr.replace(/<[^>]*>/g, '');
  
  const sentenceRemoved = strippedStr.replace(/\s*If this was not you.*$/, '');
  
  return sentenceRemoved.replace(/\s+/g, ' ').trim();
}

export const computeDayOptions = (month: number, year: number) => {
  const daysInMonth = new Date(year, month, 0).getDate()

  return Array.from({ length: daysInMonth }, (_, i) => ({
    value: i + 1,
    label: i + 1,
  }))
}

export const parseJSON = (data: string | any[]) => {
  if(!data){
    return
  } else if (typeof data === 'string') {
    // enfortra can send an error messsage instead of an array. check, if array can be parsed from their response
    return (data.includes('[') || data.includes(']')) ? JSON.parse(data) : [];
  } else {
    return data
  }
}

export const insertHyphens = (inputString: string) => {
  if (!inputString) {
    return "";
  }

  // @ts-ignore
  return inputString.match(/.{1,4}/g).join("-");
}

export const formatPhoneNumber = (phoneNumber: string) => {

  const regex = /^(\d{3})(\d{0,3})(\d{0,4})$/;

  const match = phoneNumber.match(regex);

  if (!match) {
    return 'Invalid phone number';
  }

  const areaCode = match[1];
  const exchange = match[2] || '';
  const remaining = match[3] || '';

  return `(${areaCode}) ${exchange}-${remaining}`;
}

export const validateEmail = (_: any, value: string) => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (!regex.exec(value)) {
    return Promise.reject(new Error("Please enter a valid email address."));
  }
  return Promise.resolve();
};

export const validateSSN = (_: any, value: string) => {
  const regex = /^\d{3}-?\d{2}-?\d{4}$/

  if (!regex.test(value)) {
    return Promise.reject(new Error("Please enter a valid SSN."))
  }
  return Promise.resolve()
}

export const validateName = (_: any, value: string) => {
  const regex = /^[a-zA-Z '-]+$/;

  if (!regex.test(value)) {
    return Promise.reject(new Error("Name must contain only letters, spaces, hyphens, and apostrophes."));
  }
  else if (value.length < 2 || value.length > 50) {
    Promise.reject(new Error("Name must be between 2 and 50 characters long."));
  }
  return Promise.resolve()
}

export const validateRoutingNumber = (_: any, value: string) => {
  const regex = /^\d{9}$/
  return regex.test(value)
    ? Promise.resolve()
    : Promise.reject(new Error("Routing number must be 9 digits long and contain digits only."));
}

export const validateAccountNumber = (_: any, value: string) => {
  const regex = /^\d{5,64}$/
  return regex.test(value)
    ? Promise.resolve()
    : Promise.reject(new Error("Account number must be 5 digits or more and contain digits only."))
}

export const validateCardNumber = (_: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error("Please enter your card number."));
  }

  const regex = /^[0-9]+$/
  if (!regex.test(value)) {
    return Promise.reject(new Error("Card number must contain only digits."));
  }

  if(value[0] === "3" && value.length !== 15) {
    // american express cards with 15 digits
    return Promise.reject(new Error("American express card number must be 15 digits long."));
  }

  // other cards with 16 digits
  if (value[0] !== "3" && value.length !== 16) {
    return Promise.reject(new Error("Card number must be valid."));
  }

  return Promise.resolve()
}

export const validateDriverLicenseNumber = (_: any, value: string) => {
  const regex = /^[a-zA-Z0-9]{7,20}$/;

  if (!regex.test(value)) {
    return Promise.reject(
      new Error(`Invalid driver's license number.`)
    );
  }

  return Promise.resolve();
};

export const validateMedicalId = (_: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error("Please enter your Medical ID number."));
  }

  const regex = /^[0-9A-Za-z]+$/;
  if (!regex.test(value)) {
    return Promise.reject(new Error("Medical ID number must contain only digits."));
  }

  if (value.length < 5 || value.length > 32) {
    return Promise.reject(new Error("Medical ID number must be between 5 and 32 digits."));
  }

  return Promise.resolve()
}

export const validatePassportNumber = (_: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error("Please enter your passport number."));
  }

  const regex = /^[a-zA-Z0-9]{9}$/

  if (!regex.test(value)) {
    return Promise.reject(new Error("Passport number must be 9 characters long and contain only letters and digits."));
  }

  if (value.length < 5 || value.length > 32) {
    return Promise.reject(new Error("Passport number must be between 5 and 32 digits."));
  }

  return Promise.resolve()
}

export const validatePhoneNumber = (_: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error("Please enter your phone number."));
  }


  const regex = /^\(\d{3}\)\s?\d{3}-\d{4}$/;
  if (!regex.test(value)) {
    return Promise.reject(new Error("Please enter a valid phone number."));
  }

  return Promise.resolve()
}

export const returnLastElements = (data: any[]) => {
  return data.length >= 12 ? data.slice(-12) : data;
}

export const separateAlertsByDismissStatus = (alerts: any[]) => {
  const dismissableAlerts = alerts.filter((alert: { CanDismiss: string }) => alert.CanDismiss === "1");
  const nonDismissableAlerts = alerts.filter((alert: { CanDismiss: string }) => alert.CanDismiss === "0");
  return [...dismissableAlerts, ...nonDismissableAlerts];
}

export const formatDateToShort = (dateString: string): string => {
  let date: Date;

  if (dateString.match(/^\d{4}-\d{2}-\d{2}/)) {
    date = new Date(dateString);
  } else {
    const [datePart] = dateString.split(" ");
    const [month, day, year] = datePart.split("/");
    date = new Date(Number(year), Number(month) - 1, Number(day));
  }

  const month = addLeadingZero(date.getMonth() + 1);
  const day = addLeadingZero(date.getDate());
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

function addLeadingZero(num: number): string {
  return num < 10 ? "0" + num : num.toString();
}

export const calculateOverallAverage = (data: string | any[]) => {
  let totalScore = 0;
  for (const item of data) {
    totalScore += Number(item.EqfCreditScore) + Number(item.TuCreditScore) + Number(item.ExpCreditSCore);
  }
  return Math.round(totalScore / (data.length * 3));
}

export const getCreditRating = (score: number): string => {
  const creditRanges: { [key: string]: number[] } = {
    "Excellent": [781, 850],
    "Good": [721, 780],
    "Fair": [661, 720],
    "Poor": [601, 660],
    "Very Poor": [300, 500]
  };

  for (const rating in creditRanges) {
    const [lowerBound, upperBound] = creditRanges[rating];
    if (score >= lowerBound && score <= upperBound) {
      return rating;
    }
  }

  return ""
};

export const renderNotificationIcon = (count: number | undefined, style: React.CSSProperties, fontSize: string) => {
  return (
    count
      ? <TabRoundedNotification
        fontSize={fontSize}
        value={count}
        style={style}
      />
      : null
  )
}

export const formatDateToCustomString = (date: Date | string, isMobile: boolean = false) => {
  const parsedDate = new Date(date);
  const monthFormat = isMobile ? 'short' : 'long';

  return parsedDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: monthFormat,
    day: 'numeric'
  });
}

export const sortByDate = (arr: any[]): any[] => {

  const parseDate = (dateString: string): Date => {
    let date = new Date(dateString);
    
    if (isNaN(date.getTime())) {
      const [datePart, timePart] = dateString.split(' ');
      const [month, day, year] = datePart.split('/');
      const [time, ampm] = timePart.split(' ');
      const [hours, minutes, seconds] = time.split(':');
      
      let hour = parseInt(hours);
      if (ampm.toLowerCase() === 'pm' && hour !== 12) {
        hour += 12;
      } else if (ampm.toLowerCase() === 'am' && hour === 12) {
        hour = 0;
      }
      
      date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), hour, parseInt(minutes), parseInt(seconds));
    }
    
    return date;
  };

  return arr?.sort((a, b) => {
    const dateA = parseDate(a.DateTime);
    const dateB = parseDate(b.DateTime);
    return dateB.getTime() - dateA.getTime(); 
  }) || [];
};

export const movePresentToStart = (arr: any[], fieldName: string) => {
  const notPresent = arr?.filter(obj => !obj[fieldName]) || [];
  const present = arr?.filter(obj => obj[fieldName]) || [];
  return [...present, ...notPresent] || arr;
}

export const prepareNonEmptyPayload = (submittedData: any) => {
  const nonEmptyPayload = {} as any;

  for (const key in submittedData) {
    const value = submittedData[key];
    if (value !== "" && value !== null && value !== undefined) {
      nonEmptyPayload[key] = value;
    }
  }

  return nonEmptyPayload;
};

export const isEmptyObject = (data: any) => {
  if(data === null || data === undefined){
    return true;
  } else {
    return Object.keys(data).length === 0;
  }
};

export const updateObject = (obj: any) => {
  if (
    obj.hasOwnProperty("dateOfBirth-month") &&
    obj.hasOwnProperty("dateOfBirth-day") &&
    obj.hasOwnProperty("dateOfBirth-year")
  ) {
    const { "dateOfBirth-month": month, "dateOfBirth-day": day, "dateOfBirth-year": year } = obj;

    const padZero = (num: number | string): string => {
      return ('0' + num).slice(-2);
    };

    const dateOfBirth = `${padZero(month)}/${padZero(day)}/${year}`;

    const newObj = { ...obj };
    delete newObj["dateOfBirth-month"];
    delete newObj["dateOfBirth-day"];
    delete newObj["dateOfBirth-year"];

    return { ...newObj, dateOfBirth };
  } else {
    return obj;
  }
};

export const isUserVerified = (str: string) => {
  if (str?.length) {
    return str.includes("results=1")
  }
  return false
}

export const replaceCharsInValues = (obj: any) => {
  const newObj = {};
  for (const key in obj) {
    const value = obj[key];
    const modifiedValue = typeof value === 'string' ? value.replace(/[-\s()\\/]/g, '') : value;
    // @ts-ignore
    newObj[key] = modifiedValue;
  }
  return newObj;
}

export const createMaskRule = (n: number) => {
  return '9'.repeat(n);
}

export const responesOK = (response: any) => {
  return response.status === 204 || response.status === 200 || response.status === 201 || response.status === 202;
}