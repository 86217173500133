import {IUpsellPageContent, PaymentInformation, SIZE} from "../../types/types";
import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import GText from "../../atoms/Text/Text";
import {COLORS_NEW} from "../../types/Colors";
import "./UpgradeUpsellPage.scss"
import {nextUpsellPage} from "../../../../Redux/store/additionalForm/additional.actions";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
import GAnalyticEvents from "../../analytic/AnalyticEvents";
import UserIpRepository from "../../../../persistence/session/UserIpRepository";
import {UpsellCategories} from "../../../../util/UpsellHelper";
import UpsellSelectedBuilder from "../../analytic/event/UpsellSelected/UpsellSelectedBuilder";
import UpsellSkippedBuilder from "../../analytic/event/UpsellSkipped/UpsellSkippedBuilder";
import UpsellShownBuilder from "../../analytic/event/UpsellShown/UpsellShownBuilder";
import {getServiceNameById} from "../../../../services/gtm/backEndEvent";
import { AfterPaymentOfferText, SubscriptionText, UpgradeOfferBenefits, VariablesToBeReplaced } from "../../../../shared/text/Text";
import GTag from "../../molecules/Tag/GTag";
import GButton, { BUTTON_TYPE } from "../../molecules/Button/GButton";
import ConfirmPaymentModal from "../ConfirmPaymentModal/ConfirmPaymentModal";
// @ts-ignore
import { usePayment } from "../../../../../../dashboard-govplus-front/src/hooks/usePayment";
import UpsellSubmitBuilder from "../AdditionalStep/StepUpsell/templates/UpsellSubmitBuilder";
import { UPSELL_ACTIONS } from "../AdditionalStep/StepUpsell/templates/UpsellProps";
// @ts-ignore
import { useApplications } from "../../../../../../dashboard-govplus-front/src/hooks/useApplications";
import { useABTestingPayment } from "../../../../../../dashboard-govplus-front/src/hooks/useABTestingPayment";
import GSpinner from "../../atoms/Spinner/Spinner";

import renewalOfferImage from "../../../../assets/image/upgrade/image-doc-renewal-upsell.webp"
import autofillOfferImage from "../../../../assets/image/upgrade/never-fill-image-upsell.webp"
import expeditedOfferImage from "../../../../assets/image/upgrade/free-expedited-processing-upsell.webp"
import docReplacementOfferImage from "../../../../assets/image/upgrade/image-doc-replacement-upsell.webp"
import identityProtectionOfferImage from "../../../../assets/image/upgrade/image-idp-upsell.webp"
import renewalOfferImageMobile from "../../../../assets/image/upgrade/image-doc-renewal-upsell-mobile.webp"
import autofillOfferImageMobile from "../../../../assets/image/upgrade/never-fill-image-upsell-mobile.webp"
import expeditedOfferImageMobile from "../../../../assets/image/upgrade/free-expedited-processing-upsell-mobile.webp"
import docReplacementOfferImageMobile from "../../../../assets/image/upgrade/image-doc-replacement-upsell-mobile.webp"
import identityProtectionOfferImageMobile from "../../../../assets/image/upgrade/image-idp-upsell-mobile.webp"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg";
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes";
// @ts-ignore
import { useModals } from "../../../../../../dashboard-govplus-front/src/hooks/useModals";
import purchasedSubscriptionImage from "../../../../assets/image/upgrade/successfull_subscription.webp"
import { SUBSCRIPTION_PERIOD_MONTHLY, SUBSCRIPTION_PERIOD_YEARLY } from "../../../../shared/constans/payment";
import GSubscriptionsMethodsForm from "../SubscriptionMethodsForm/SubscriptionMethodsForm";
import subscriptionMethods from '../SubscriptionMethodsForm/subscriptionMethods.json'
import SubscriptionOfferBenefitItem from "../../molecules/SubscriptionOfferBenefitItem/SubscriptionOfferBenefitItem";

export type GUpgradeUpsellPageProps = {
    content: IUpsellPageContent,
    formId: string,
    uniqSession: string
}
const GUpgradeUpsellPage: FC<GUpgradeUpsellPageProps> = ({content, formId, uniqSession}) => {
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingCancellation, setLoadingCancellation] = useState(false)
    const [paymentModal, setPaymentModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()
    const [subscriptionSelectedPrice, setSubscriptionSelectedPrice] = useState(119.88);
    const [selectedSubscriptionMethod, setSelectedSubscriptionMethod] = useState(SUBSCRIPTION_PERIOD_YEARLY);
    const dispatch = useDispatch()
    const {availableProducts} = useApplications()
    const {
        billingInformation,
        getBillingInformation
    } = usePayment()
    const breakpoint = useBreakpoint()
    const {getSubscriptionPaymentOptions} = useABTestingPayment()
    const {openPurchasedModal} = useModals()

    const products = availableProducts
        .filter(product => product.active)
        .sort((a,b) => a.priority - b.priority)
        .map(product => `${product.title} ($67 value)`)

    const basePrice = 119.88
    const discountPrice = undefined
    const service = getServiceNameById(formId)

    const scrollToSubscription = () => {
        document.querySelector(".SubscriptionPayment")?.scrollIntoView({behavior: "smooth", block: "end", inline:"end"})
    }

    const acceptUpsell = () => {
        const upgradeSelectedBuilder = new UpsellSelectedBuilder()
            .setUpsellName("Subscription Interstitial")
            .setPrice(subscriptionSelectedPrice.toString())
            .setUpsellCategory(UpsellCategories.Subscription)
            .setIpAddress(UserIpRepository.getInstance().getValue());
        GAnalyticEvents.track(upgradeSelectedBuilder.build());

        if(content?.upsellInformation?.test_mode) {
            alert(content?.upsellInformation?.test_mode_message)
            upsellUserInteractionClick(UPSELL_ACTIONS.ACTION_ACCEPT)
        } else {
            if (billingInformation?.default_payment_methods) {
                const paymentInformation: PaymentInformation = {
                    isNewPaymentMethod: false,
                    paymentMethod: billingInformation?.default_payment_methods,
                    period: selectedSubscriptionMethod,
                }
                acceptPaymentUpsell(paymentInformation)
            } else {
                setPaymentModal(true)
            }
            
        }
    }
    const cancelUpsell: React.MouseEventHandler<HTMLElement> = () => {

        const upgradeSkippedBuilder = new UpsellSkippedBuilder()
            .setUpsellName("Subscription Interstitial")
            .setPrice(subscriptionSelectedPrice.toString())
            .setUpsellCategory(UpsellCategories.Subscription)
            .setIpAddress(UserIpRepository.getInstance().getValue());
        GAnalyticEvents.track(upgradeSkippedBuilder.build());
        setLoadingCancellation(true)
        setDisabled(true)
        upsellUserInteractionClick(UPSELL_ACTIONS.ACTION_CANCEL)
    }

    const purchasedOfferCallback = () => {
        setLoading(false)
        setDisabled(false)
        setLoadingCancellation(false)
        openPurchasedModal({
            offerName: "GOV+ Premium",
            offerImage: purchasedSubscriptionImage
        })
    }

    const upsellUserInteractionClick = (action: any, payment?: PaymentInformation) => {
        setLoading(true)
        setDisabled(true)
        const upsellSubmitBuilder = new UpsellSubmitBuilder()
                .setFormId(formId)
                .setUpsellFunnelId(content?.upsellInformation?.funnel_id)
                .setUpsellPageId(content?.upsellInformation?.page_id)
                .setIterator(content?.upsellInformation?.page_iterator)
                .setHandler(content?.upsellInformation?.product_handler)
                .setUniqSession(uniqSession)
                .setAction(action)
        if (payment){
            upsellSubmitBuilder.setPaymentInformation(payment);
        }
        let newObj = upsellSubmitBuilder.build().getSubmitObject();
        if (payment?.period === SUBSCRIPTION_PERIOD_YEARLY) {
            const abTestingUpgradeOptions = getSubscriptionPaymentOptions()
            newObj.subscriptionCouponEnabled = abTestingUpgradeOptions.subscriptionCouponEnabled
            newObj.subscriptionCouponId = abTestingUpgradeOptions.subscriptionCouponIdOffer
        }
        dispatch(nextUpsellPage({...newObj, purchasedCallback: purchasedOfferCallback, 
            errorCallback: (errorMessage?: string) => {
                setErrorMessage(errorMessage)
                setPaymentModal(true)
                setLoading(false)
                setDisabled(false)
            }
        }))
    }

    const acceptPaymentUpsell = (payment: PaymentInformation) => {
        upsellUserInteractionClick(UPSELL_ACTIONS.ACTION_ACCEPT, payment)
    }

    const getPaymentAuthorizationDescription = () =>
        SubscriptionText.PaymentAuthorizationDescription.replace(VariablesToBeReplaced.SubscriptionPrice, subscriptionSelectedPrice?.toString())

    const getPriceInfo = () => {
        return {
            productName: 'GOV+ Unlimited',
            totalPay: Number(subscriptionSelectedPrice) * 100
        }
    }

    const selectSubscriptionPeriod = (period: string) => {
        switch (period) {
            case SUBSCRIPTION_PERIOD_YEARLY:
                setSubscriptionSelectedPrice(119.88)
                break;
            case SUBSCRIPTION_PERIOD_MONTHLY:
                setSubscriptionSelectedPrice(11.99)
                break;
            default:
                break;
        }
        setSelectedSubscriptionMethod(period)
    }

    const getRenewalPeriod = () => {
        switch (selectedSubscriptionMethod) {
            case SUBSCRIPTION_PERIOD_YEARLY:
                return 'year'
            case SUBSCRIPTION_PERIOD_MONTHLY:
                return 'month'
            default:
                return '';
        }
    }

    useEffect(() => {
        getBillingInformation()
    }, [])

    useEffect(()=>{
        const upgradeShownBuilder = new UpsellShownBuilder()
            .setUpsellName("Subscription Interstitial")
            .setPrice("119.88")
            .setUpsellCategory(UpsellCategories.Subscription)
            .setIpAddress(UserIpRepository.getInstance().getValue());
        GAnalyticEvents.track(upgradeShownBuilder.build());
    }, [])

    return (
        loadingCancellation ? <GSpinner /> : 
        <div className="APUpgradeOfferWrapper">
            {/* Normal payment modal with all props in case we revert this back to normal */}
            {/* <ConfirmPaymentModal
                title={`GOV+ Premium`}
                close={()=>setPaymentModal(false)}
                isOpen={paymentModal}
                submitPaymentCallback={acceptPaymentUpsell}
                paymentMethods={billingInformation?.payment_methods || []}
                defaultPaymentMethod={billingInformation?.default_payment_methods || ""}
                paymentDescription={`Your GOV+ Premium will be charged to the payment method below:`}
                paymentAuthorizationDescription={[getPaymentAuthorizationDescription(), SubscriptionText.SubscriptionCancelAuthorization]}
                setSubscriptionSelectedPrice={setSubscriptionSelectedPrice}
                isLoading={loading}
                errorMessage={errorMessage}
                showSubscriptionOptions={true}
                priceInfo={getPriceInfo()}
            /> */}
            <ConfirmPaymentModal
                title={`There was an error with your payment method`}
                close={()=>setPaymentModal(false)}
                isOpen={paymentModal}
                submitPaymentCallback={acceptPaymentUpsell}
                paymentMethods={billingInformation?.payment_methods || []}
                defaultPaymentMethod={billingInformation?.default_payment_methods || ""}
                paymentAuthorizationDescription={[]}
                isLoading={loading}
                errorMessage={errorMessage}
                priceInfo={getPriceInfo()}
                mode="payment_error"
                selectedSubscription={selectedSubscriptionMethod}
            />
            <div className={"APUpgradeOffer"}>
                <div className={"SubscriptionOfferHeader"}>
                    <div className={"Logo"}>
                        <GIconsSvgType type={ICONS_SVG_TYPE.LOGO} width={breakpoint.md ? 94.3 : 80.83} height={breakpoint.md ? 24 : 20.57}/>
                        <GText text={AfterPaymentOfferText.Premium} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.ROYAL_BLUE_700} />
                    </div>
                    <div className={"OfferHeader"}>
                        <GText text={AfterPaymentOfferText.OfferHeader} size={breakpoint.md ? SIZE.HEADING_MDLG : SIZE.PARAGRAPH_BOLD_24} maxWidth={breakpoint.xs ? "251px" : ""} />
                        <div className={"OfferSubHeader"}>
                            <GText text={AfterPaymentOfferText.NewMembersFree} size={breakpoint.md ? SIZE.PARAGRAPH_BOLD_22 : SIZE.PARAGRAPH_BOLD_18} color={COLORS_NEW.ROYAL_BLUE_700} maxWidth={breakpoint.xs ? "251px" : ""} />
                            <div className={"OfferHeaderPrice"}>
                                <GText text={"As low as $9.99/month."} size={breakpoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_700} />
                                <GTag
                                    text={SubscriptionText.SubscriptionPlanTag}
                                    sizeText={SIZE.PARAGRAPH_BOLD_14}
                                    colorBackground={COLORS_NEW.YELLOW_200}
                                    colorText={COLORS_NEW.YELLOW_700}
                                />
                            </div>
                        </div>
                    </div>
                    <GButton text={AfterPaymentOfferText.StartFreeTrial} click={scrollToSubscription} disabled={disabled} loading={loading} />
                    
                </div>
                {/* Hiding this section temporarily */}
                <div className={"OfferContent"}>
                    <div className={"SubscriptionBenefits"}>
                        <SubscriptionOfferBenefitItem
                            header={AfterPaymentOfferText.NeverFilloutForms}
                            subHeader={AfterPaymentOfferText.NeverFilloutFormsDescription}
                            image={breakpoint.md ? autofillOfferImage : autofillOfferImageMobile}
                         />
                         <SubscriptionOfferBenefitItem
                            header={AfterPaymentOfferText.ExpeditedProcessing}
                            subHeader={AfterPaymentOfferText.ExpeditedProcessingDescription}
                            image={breakpoint.md ? expeditedOfferImage : expeditedOfferImageMobile}
                            imageWidth={breakpoint.md ? 524.5 : ''}
                         />
                         <SubscriptionOfferBenefitItem
                            header={AfterPaymentOfferText.AutoRenewal}
                            subHeader={AfterPaymentOfferText.AutoRenewalDescription}
                            image={breakpoint.md ? renewalOfferImage : renewalOfferImageMobile}
                            imageWidth={breakpoint.md ? 465.25 : ''}
                         />
                         <SubscriptionOfferBenefitItem
                            header={AfterPaymentOfferText.InstantDocReplacement}
                            subHeader={AfterPaymentOfferText.InstantDocReplacementDescription}
                            image={breakpoint.md ? docReplacementOfferImage : docReplacementOfferImageMobile}
                            imageWidth={breakpoint.md ? 464.25 : ''}
                         />
                         <SubscriptionOfferBenefitItem
                            header={AfterPaymentOfferText.TotalIdentityProtection}
                            subHeader={AfterPaymentOfferText.TotalIdentityProtectionDescription}
                            image={breakpoint.md ? identityProtectionOfferImage : identityProtectionOfferImageMobile}
                            imageWidth={breakpoint.md ? 488 : ''}
                         />
                    </div>
                    <div className="SubscriptionPayment">
                        <div className={"PaymentHeader"}>
                            <div className={"Logo"}>
                                <GIconsSvgType type={ICONS_SVG_TYPE.LOGO} width={94.3} height={24}/>
                                <GText text={AfterPaymentOfferText.Premium} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.ROYAL_BLUE_700} />
                            </div>
                            <div className={"OfferHeaderPrice"}>
                                <GText text={AfterPaymentOfferText.TryFree} size={breakpoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_700} />
                                <GTag
                                    text={SubscriptionText.SubscriptionPlanTag}
                                    sizeText={SIZE.PARAGRAPH_BOLD_14}
                                    colorBackground={COLORS_NEW.YELLOW_200}
                                    colorText={COLORS_NEW.YELLOW_700}
                                />
                            </div>
                        </div>
                        <div className={"SubscriptionOptions"}>
                            <GSubscriptionsMethodsForm
                                subscriptionMethods={subscriptionMethods}
                                defaultSubscriptionMethod={SUBSCRIPTION_PERIOD_YEARLY}
                                selectionSubscriptionMethodChange={selectSubscriptionPeriod}
                                hasTotalPriceBlock={false}
                            />
                        </div>
                        <GButton 
                            text={AfterPaymentOfferText.StartFreeTrial} 
                            click={acceptUpsell} 
                            disabled={disabled} 
                            loading={loading} 
                        />
                        <GText 
                            text={AfterPaymentOfferText.FreeTrialAuthorization.replace('{price}', `$${subscriptionSelectedPrice.toString()}`).replace('{period}', getRenewalPeriod())} 
                            size={SIZE.PARAGRAPH_REGULAR_16} 
                            color={COLORS_NEW.BLACK_600} 
                            maxWidth="682px" 
                        />
                        <GButton 
                            text={AfterPaymentOfferText.SkipOffer}
                            type={BUTTON_TYPE.SECONDARY}
                            showIcon={false}
                            style={{width: "100%"}}
                            click={cancelUpsell}
                        />
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default GUpgradeUpsellPage