import React from "react"
import "./ProcessingShipmentComponent.scss"
import GMediumTrackingCard from "../../../atomicDesign/templates/AdditionalStep/StepStatusTracking/shared/MediumTrackingCard/MediumTrackingCard"

type ProcessingShipmentComponentProps = {
  estimatedWaitTime: string,
  solution: string,
}
const ProcessingShipmentComponent: React.FunctionComponent<ProcessingShipmentComponentProps> = ({estimatedWaitTime, solution}) => {
  return (
    <div className={`GProcessingShipmentComponent`}>
      <GMediumTrackingCard 
         estimatedWaitTime={estimatedWaitTime}
         solution={solution}
      />
    </div>
  )
}
export default ProcessingShipmentComponent
