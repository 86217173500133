/* eslint-disable indent */
import React from "react"
import WithField from "../../../Hocs/WithField/WithField"
import BlockFormWithFields from "../BlockFormWithFields/BlockFormWithFields"
import CheckBoxAgreeComponent from "../FieldsComponents/CheckBoxAgreeComponent/CheckBoxAgreeComponent"
import CheckBoxComponent from "../FieldsComponents/CheckBoxComponent/CheckBoxComponent"
import CheckBoxGroupComponents from "../FieldsComponents/CheckBoxGroupComponents/CheckBoxGroupComponents"
import DataGroupDropDownComponent from "../FieldsComponents/DataGroupDropDownComponent/DataGroupDropDownComponent"
import DateCoaComponents from "../FieldsComponents/DateGroupComponents/DateCoaComponent"
import DateGroupComponents from "../FieldsComponents/DateGroupComponents/DateGroupComponents"
import FieldsDescriptionComponent from "../FieldsComponents/FieldsDescriptionComponent/FieldsDescriptionComponent"
import InputComponent from "../FieldsComponents/InputComponent/InputComponent"
import InputGroupComponent from "../FieldsComponents/InputGroupComponent/InputGroupComponent"
import NumberInputComponent from "../FieldsComponents/NumberInputComponent/NumberInputComponent"
import PriceInformationComponents from "../FieldsComponents/PriceInformationComponents/PriceInformationComponents"
import RadioGroupComponent from "../FieldsComponents/RadioGroupComponent/RadioGroupComponent"
import SelectComponent from "../FieldsComponents/SelectComponent/SelectComponent"
import SelectGroupComponent from "../FieldsComponents/SelectGroupComponent/SelectGroupComponent"
import InputComponent2 from "../FieldsComponents/TestComponent/InputComponent2"
import TextAreaComponent from "../FieldsComponents/TextAreaComponent/TextAreaComponent"
import TimePickerComponentComponent from "../FieldsComponents/TimePickerComponent/TimePickerComponent"
import ValidationAddressComponent from "../FieldsComponents/ValidationAddressComponent/ValidationAddressComponent"
import ApplicationPackageComponent
  from "../PostSubmitComponents/ApplicationPackageComponent/ApplicationPackageComponent"
import CheckBoxPassport from "../PostSubmitComponents/CheckBoxPassportForm/CheckBoxPassport"
import ChoseAddressComponent from "../PostSubmitComponents/ChoseAdressComponent/ChoseAddressComponent"
import PhoneInputContainer from "../FieldsComponents/PhoneInputComponent/PhoneInputContainer"
import CheckBoxAgreeComponentV2 from "../FieldsComponents/CheckBoxAgreeComponent/CheckBoxAgreeComponentV2"
import {AddressAutofill} from "../FieldsComponents/AddressAutofill"
import {CityBirthAutofill} from "../FieldsComponents/CityBirthAutofill"
import SealHandler, {
  DEFAULT_NORTON_IMAGE,
  SEAL_NORTON_ID,
  SealType
} from "../../atomicDesign/molecules/Seal/SealHandler";

export const getValidationRules = (validationObj: { anyOf: { pattern?: string }[] } | any, name: "anyOf" | any) =>
  validationObj["anyOf"]
    ? validationObj["anyOf"].find((objectValidation: { pattern?: string }) => objectValidation["pattern"])?.pattern ??
      ""
    : validationObj[name]

export const switchType = (
  type: string,
  field: {} | any,
  value: string | number | any,
  observer: boolean,
  validationRulers?: {} | any,
  required?: boolean
) => {
  const checkValidationRulers = validationRulers || {}
  switch (type) {
    case "radio":
      return <RadioGroupComponent field={field} value={value} />
    case "check-box__group_data-pricing":
      return <CheckBoxGroupComponents field={field} value={value} />
    case "data-pricing__price-information":
      return <PriceInformationComponents field={field} value={value} />
    case "select":
      return (
        <SelectComponent
          field={field}
          value={value}
          validationRule={getValidationRules(checkValidationRulers, field.name)}
          required={required || false}
        />
      )
    case "select_group":
      return <SelectGroupComponent field={field} />
    case "input":
      return (
        <InputComponent
          field={field}
          value={value}
          validationRule={getValidationRules(checkValidationRulers, field.name)}
          observer={observer}
          required={required || false}
        />
      )
    case "norton_seal":
      return (
          <SealHandler type={SealType.NORTON} id={SEAL_NORTON_ID} defaultImage={DEFAULT_NORTON_IMAGE} />
      )
    case "input_number":
      return (
        <NumberInputComponent
          field={field}
          value={value}
          validationRule={getValidationRules(checkValidationRulers, field.name)}
        />
      )
    case "checkBoxAgree":
      return (
        <CheckBoxAgreeComponent
          field={field}
          validationRule={getValidationRules(checkValidationRulers, field.name)}
          value={value}
        />
      )
    case "checkBoxAgreeV2":
      return (
        <CheckBoxAgreeComponentV2
          field={field}
          validationRule={getValidationRules(checkValidationRulers, field.name)}
          value={value}
        />
      )
    case "check-box":
      return <CheckBoxComponent field={field} value={value} />
    // we do not use
    case "inputGroup":
      return (
        <InputGroupComponent
          validationRule={getValidationRules(checkValidationRulers, field.name)}
          blockObject={field}
        />
      )
    case "textarea":
      return <TextAreaComponent field={field} value={value} />
    case "datePickerGroup":
      return <DateGroupComponents blockObject={field} />
    case "dateCoaGroup":
      return <DateCoaComponents blockObject={field} />
    case "timePickerGroup":
      return <TimePickerComponentComponent blockObject={field} />
    case "observer":
      return <BlockFormWithFields blockObject={field} />
    case "nestBlock":
      return <BlockFormWithFields blockObject={field} />
    case "select-address":
      return <ChoseAddressComponent field={field} />
    case "application-package":
      return <ApplicationPackageComponent field={field} />
    case "description":
      return <FieldsDescriptionComponent field={field} />
    case "error_validation":
      return <ValidationAddressComponent field={field} />
    case "check-box__group_data-pricing check-box_passport":
      return <CheckBoxPassport field={field} />
    case "dateGroupDropDown":
      return <DataGroupDropDownComponent field={field} />
    case "phone_wrapper":
      return <PhoneInputContainer field={field} />
    case "address_autofill":
      return <AddressAutofill field={field} validationRule={getValidationRules(checkValidationRulers, field.name)} />
    case "city_birth_autofill":
      return <CityBirthAutofill field={field} validationRule={getValidationRules(checkValidationRulers, field.name)}/>
    default:
      return <>{WithField(InputComponent2, field, value)()}</>
  }
}
