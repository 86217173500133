import React, { useEffect } from "react"
import { connect } from "react-redux"
import { ValidationItemInterface } from "../../../../Redux/InterfacesEntity/validation.interface"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { Form } from "antd"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import {getLabel} from "../../../../services/service-function/field";

type PriceInformationComponentsProps = {
  field: FieldInterface | any,
  value: ValidationItemInterface,
  submitObject: {} | any,
  dispatch: any,
  pricing: {} | any,
}

// eslint-disable-next-line max-len
const PriceInformationComponents: React.FunctionComponent<PriceInformationComponentsProps> = ({
  field,
  submitObject,
  dispatch,
  pricing,
}) => {
  const defaultValue = field?.defaultValue
  pricing = pricing ?? JSON.parse(localStorage.getItem("pricing") || "[]")
  const defaultValuePrice = pricing?.[field?.defaultValue?.backendValue]

  useEffect(() => {
    if (field.priority) {
      dispatch(setValueCurrentField("priority", field.priority))
    }
    if (defaultValue && defaultValuePrice) {
      dispatch(setValueCurrentField(`${field.name}`, defaultValuePrice))
    }
    // eslint-disable-next-line
  }, [dispatch, field.priority, defaultValue])

  return (
    <Form.Item
      label={getLabel(field.label)}
      name={field.name}
      required={true}
      preserve={false}
      initialValue={submitObject[`defaultValue${field.name}`]}
      className={`check-box-group__container ${field.type} ${field.name}`}
    >
      <div className={`check-box-group__default-option`}>
        <div className={`default-option__header_container`}>
          <p
            // eslint-disable-next-line max-len
            className={`default-option__header-text`}
          >
            {defaultValue?.optionName}
            <span className={`default-option__price-text`}>{defaultValue?.currency + defaultValuePrice}</span>
          </p>
        </div>
        <div className={`default-option__description_container`}>
          <p
            // eslint-disable-next-line max-len
            className={`default-option__description_text`}
          >
            {defaultValue?.descriptionOption}
          </p>
        </div>
      </div>
    </Form.Item>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
  pricing: state.form.pricing,
})

export default connect(mapStateToProps)(PriceInformationComponents)
