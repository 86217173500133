import React, {useEffect, useState} from "react";
import FamilyMemberHeader from "../../../Components/atomicDesign/molecules/FamilyMemberHeader/FamilyMemberHeader";
import ProductPage from "../../../Components/atomicDesign/templates/ProductPage/ProductPage";
import {useSubscription} from "../../../../../dashboard-govplus-front/src/hooks/useSubscription";
import {ICONS_SVG_TYPE} from "../../../Components/atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import {Card} from "antd";
import {IFamilyMember} from "../../../Components/atomicDesign/types/Models";
import "./FamilyMembersApplications.scss"

const FamilyMembersApplications = () => {
    const {selectedFamilyMember} = useSubscription()
    const [familyMember, setFamilyMember] = useState<IFamilyMember>()
    const headerText = `Complete any application for ${familyMember?.firstName} in minutes`
    const subHeaderText = "We’ve already done most of the work for you. " +
        "Select an application to add a few more required details. Don’t worry — " +
        "you’ll never fill out the same information twice."
    const listComponent = () => {
        return <div></div>
    }

    useEffect(() => {
        setFamilyMember(selectedFamilyMember)
    })

    return (
        <div className={"GFamilyMembersApplications"}>
            <Card className={"GFamilyMembersApplications__HeaderCard"}>
                <FamilyMemberHeader firstName={familyMember?.firstName} lastName={familyMember?.lastName} relationship={familyMember?.relationship} />
            </Card>

            <ProductPage List={listComponent}  headerText={headerText} subHeaderText={subHeaderText} />
        </div>
    )
}

export default FamilyMembersApplications