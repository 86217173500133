import { Form } from "antd"
import "./EmailDetailsForm.scss"
import React, { FC, useState } from "react"
import GText from "../../../atoms/Text/Text"
import GButton from "../../../molecules/Button/GButton"
import GInputText from "../../../molecules/InputText/InputText"
import { COLORS_NEW } from "../../../types/Colors"
import { SIZE, BUTTON_TYPE } from "../../../types/types"
import { FormInput, validateEmail } from "../../../../../util/IdentityProtectionHelper"

const EmailDetailsForm: FC<FormInput> = ({ recordType, handleFormActions }) => {
  const [emailForm] = Form.useForm()
  const [emailError, setEmailError] = useState("")

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    emailForm.setFieldValue("email", value)

    if (regex.exec(value)) {
      setEmailError("")
    }
  }

  const getEmailFormLabel = () => {
    return (
      <div className={"EmailDetailsForm__Item__Label"}>
        <GText text={"Email address"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
      </div>
    )
  }

  const handleFormSubmit = async () => {
    try {
      const valid = await emailForm.validateFields()
      if (valid) {
        const submittedData = {
          recordType,
          value: emailForm.getFieldValue("email"),
        }
        handleFormActions(submittedData)
        setEmailError("")
      }
    } catch (error) {
      console.error(error)
      setEmailError("Please enter a valid email address")
    }
  }

  return (
    <Form
      className={"EmailDetailsForm"}
      layout={"vertical"}
      form={emailForm}
      initialValues={{ remember: true }}
      name={"basic"}
      onFinish={handleFormSubmit}
    >
      <Form.Item
        className={"EmailDetailsForm__Item"}
        label={getEmailFormLabel()}
        name={"email"}
        initialValue={""}
        rules={[{ required: true, validator: validateEmail }]}
        hasFeedback={false}
        help={emailError}
        validateStatus={emailError ? "error" : ""}
      >
        <GInputText onChange={handleInputChange} value={""} maxLength={256} />
      </Form.Item>

      <div className={"EmailDetailsForm__Actions"}>
        <GButton
          type={BUTTON_TYPE.SECONDARY}
          showIcon={false}
          style={{ height: "44px" }}
          text={"Add"}
          click={handleFormSubmit}
        />
      </div>
    </Form>
  )
}

export default EmailDetailsForm
