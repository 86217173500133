import {EventProps} from "./EventProperties";

/**
 * Base class for all events
 * @class Event
 */
class Event {
    private readonly _eventName: string;
    private readonly _ipAddress: string | undefined;

    /**
     * Constructor
     * @param eventName
     * @param ipAddress
     * @constructor
     */
    protected constructor(eventName: string, ipAddress: string | undefined) {
        this._eventName = eventName;
        this._ipAddress = ipAddress;
    }

    /**
     * Get event data
     */
    get eventName(): string {
        return this._eventName;
    }

    /**
     * Get event ip address
     */
    baseEventData(): EventProps {
        return {
            ip: this._ipAddress,
        };
    }

    protected addProperty(key: string, value: any, object: any): EventProps {
        if (value === null || typeof value === 'undefined' || value === '') {
            return object;
        }

        if (object.hasOwnProperty(key)) {
            return object;
        }

        return {
            ...object,
            [key]: value,
        }
    }
}

export default Event
