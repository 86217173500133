import React, {useEffect, useState} from 'react';
import './index.less';
import '../../styles/pages/offers-page.less';
import OfferCard from "./OfferCard";
import {ProductsImages} from "../../components/ProductsImages";
import {useDispatch} from "react-redux";
import {getOffers} from "../../appRedux/actions";

const OffersPage = () => {

  const [pageInfo,] = useState({
    title: 'My Offers',
    subtitle: 'In hac habitasse platea dictumst. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ante leo, mattis sit amet imperdiet a, luctus quis.'
  })

  const offersArr = [
    {
      title: 'AT&T',
      logo: 'sdas',
      text: 'Dolor lorem ipsum dolor sit amet, consectetur adipiReferFriendscing elit. ',
      prevPrice: 49.99,
      currentPrice: 42.99,
      youSave: 'You save $7.00 (14% Off)',
      date: '12 Aug 2021',
      id: 1,
      btnText: 'Check Status',
      offerImage: "ATT"
    },
    {
      title: 'Comcact',
      logo: 'sdas',
      text: 'Sit dolor lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
      prevPrice: 49.99,
      currentPrice: 42.99,
      youSave: 'You save $7.00 (14% Off)',
      date: '12 Aug 2021',
      id: 2,
      btnText: 'Check Status',
      offerImage: "COMCAST"
    },
    {
      title: 'Quickbooks',
      logo: 'sdas',
      text: 'Sit dolor lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
      prevPrice: 49.99,
      currentPrice: 42.99,
      youSave: 'You save $7.00 (14% Off)',
      date: '12 Aug 2021',
      id: 3,
      btnText: 'Check Status',
      offerImage: "quickbooks"
    },
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOffers())
  }, [dispatch])

  const cards = (arr) => arr.map((item, id) => {
    const {title, text, prevPrice, currentPrice, btnText, youSave, fillingTime, offerImage} = item;
    return (
      <React.Fragment key={id}>
        <OfferCard
          title={title}
          fillingTime={fillingTime}
          text={text}
          btnText={btnText}
          youSave={youSave}
          currentPrice={currentPrice}
          prevPrice={prevPrice}
          offerImage={<ProductsImages type={offerImage}/>}
        />
      </React.Fragment>
    )
  })

  const {title, subtitle} = pageInfo
  const offersCards = offersArr[0] ? cards(offersArr) : null

  return (
    <div className={"offers-container"}>
      <h1>{title}</h1>
      <h3 className={"offers-container_page-subtitle"}>{subtitle}</h3>
      <div className="offers-container_services-box">
        {offersCards}
      </div>
    </div>
  )
}

export default OffersPage;
