import Event from "../Event"
import {EventOptions, EventProps} from "../EventProperties"
import IEvent from "../IEvent";

const EVENT_NAME = "Application Started";

/**
 * Application Started Event
 * @class ApplicationStarted
 * @extends Event
 * @implements IEvent
 * @description This is the class for Application Started event
 */
export default class ApplicationStarted extends Event implements IEvent {
    private readonly _applicationId: string | undefined;
    private readonly _solution: string | undefined;
    private readonly _service: string | undefined;
    private readonly _category: string | undefined;

    /**
     * Constructor
     * @param applicationId
     * @param solution
     * @param service
     * @param category
     * @param ipAddress
     * @description This is the constructor for Application Started event
     */
    constructor(
        applicationId: string | undefined,
        solution: string | undefined,
        service: string | undefined,
        category: string | undefined,
        ipAddress: string | undefined
    ) {
        super(EVENT_NAME, ipAddress);
        this._applicationId = applicationId;
        this._solution = solution;
        this._service = service;
        this._category = category;
    }

    /**
     * Get event data
     * @returns {EventProps}
     * @description This method is used to get event data
     */
    getEventData(): EventProps {
        return {
            ...super.baseEventData(),
            application_id: this._applicationId,
            solution: this._solution,
            service: this._service,
            subdivision: this._category,
        }
    }

    /**
     * Get event name
     * @returns {string}
     * @description This method is used to get event name
     */
    getEventName(): string {
        return this.eventName;
    }

    /**
     * Get page name
     * @returns {string}
     * @description This method is used to get page name
     */
    pageName(): string {
        return "";
    }

    /**
     * Get previous id
     * @returns {string}
     * @description This method is used to get previous id
     */
    getPreviousId(): string {
        return "";
    }

    /**
     * Get user id
     * @returns {string}
     * @description This method is used to get user id
     */
    getUserId(): string {
        return "";
    }

    getEventOptions(): EventOptions | undefined {
        return undefined;
    }
}
