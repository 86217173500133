import React, { FC } from "react"
import { ICONS_SVG_TYPE } from "./IconsSvgTypes"
import { ISvgIcons } from "../../types/types"
import { SvgHeaderSeparator } from "./svg/SvgHeaderSeparator"
import { SvgLogo } from "./svg/SvgLogo"
import { COLORS } from "../../types/Colors"
import { SvgNewApplication } from "./svg/SvgNewApplication"
import { SvgMyApplications } from "./svg/SvgMyApplications"
import { SvgMyInsuredDocuments } from "./svg/SvgMyInsuredDocuments"
import { SvgMyFamily } from "./svg/SvgMyFamily"
import { SvgGift } from "./svg/SvgGift"
import { SvgMagic } from "./svg/SvgMagic"
import { SvgCheck } from "./svg/SvgCheck"
import { SvgX } from "./svg/SvgX"
import { SvgDropDown } from "./svg/SvgDropDown"
import { SvgInfo } from "./svg/SvgInfo"
import { SvgLocation } from "./svg/SgvLocation"
import { SvgRefresh } from "./svg/SgvRefresh"
import { SvgDocument } from "./svg/SgvDocument"
import { SvgInsurance } from "./svg/SgvInsurance"
import { SvgPhoto } from "./svg/SgvPhoto"
import { SvgSupport } from "./svg/SgvSupport"
import { SvgPlusSeparator } from "./svg/SgvPlusSeparator"
import { SvgMenuUser } from "./svg/SvgMenuUser"
import { SvgUser } from "./svg/SvgUser";
import { SvgBilling } from "./svg/SvgBilling";
import { SvgLogout } from "./svg/SvgLogout";
import {SvgXCircle} from "./svg/SvgXCircle";
import {SvgCheckOverCircle} from "./svg/SvgCheckOverCircle";
import {SvgLockOpen} from "./svg/SvgLockOpen";
import {SvgLockClose} from "./svg/SvgLockClose";
import {SgvAuthSignIn} from "./svg/SgvAuthSignIn";
import {SvgKey} from "./svg/SvgKey";
import {SvgDashArrowLeft} from "./svg/SvgDashArrowLeft";
import {SvgVerificationCodePhone} from "./svg/SvgVerificationCodePhone";
import {SvgCheckCircle} from "./svg/SvgCheckCircle";
import {SvgSearchStepIcon} from "./svg/SvgSearchStepIcon";
import {SvgOvalHeaderStepIcon} from "./svg/SvgOvalHeaderStepIcon";
import {SvgAuthActivate} from "./svg/SvgAuthActivate";
import {SvgAddPhoneIcon} from "./svg/SvgAddPhoneIcon";
import {SvgSignArrowDown} from "./svg/SvgSignArrowDown";
import {SvgDragDrop} from "./svg/SvgDragDrop";
import {SvgSearchIcon} from "./svg/SvgSearcIcon";
import { SvgRenewalCircle } from "./svg/SgvRenewalCircle"
import { SgvRenewalCircleBody } from "./svg/SgvRenewalCircleBody"
import {SvgExpediteDocument} from "./svg/SvgExpediteDocument";
import {SvgInsuranceShield} from "./svg/SvgInsuranceShield";
import {SvgInsuranceShieldCircle} from "./svg/SvgInsuranceShieldCircle";
import {SvgRenewal} from "./svg/SvgRenewal";
import {SvgRenewalAdd} from "./svg/SvgRenewalAdd";
import {SvgUserAddCircle} from "./svg/SvgUserAddCircle";
import {SvgInsuranceAddShieldCircle} from "./svg/SvgInsuranceAddShieldCircle";
import {SgvThinkPlusSeparator} from "./svg/SgvThinkPlusSeparator";
import {SvgAddDocumentIconCircleBorder} from "./svg/SvgAddDocumentIconCircleBorder";
import {SvgCheckTax} from "./svg/SvgCheckTax";
import {SvgTaxRefundIcon} from "./svg/SvgTaxRefundIcon";
import {SvgTaxErrorIcon} from "./svg/SvgTaxErrorIcon";
import {SvgSheets} from "./svg/SvgSheets";
import {SvgGovOffice} from "./svg/SvgGovOffice";
import {SvgPassport} from "./svg/SvgPassport";
import {SvgMagicPencil} from "./svg/SvgMagicPencil";
import {SvgArrowForward} from "./svg/SvgArrowForward";
import {SvgChevronLeft} from "./svg/SvgChevronLeft"
import {SvgCheckCircleOutline} from "./svg/SvgCheckCircleOutline"
import { SvgAlertTriangle } from "./svg/SvgAlertTriangle"
import {SvgClockIcon} from "./svg/SvgClockIcon";
import {SvgGovernment} from "./svg/SvgGovernment";
import {SvgIconInfo} from "./svg/SvgIconInfo";
import {SvgIconBox} from "./svg/SvgIconBox";
import {SvgMyIdentity} from "./svg/SvgMyIdentity";
import {SvgMyCreditScores} from "./svg/SvgMyCreditScores";
import {SvgIconCheck} from "./svg/SvgIconCheck";
import {SvgIconX} from "./svg/SvgIconX";
import {SvgIconCheckCircle} from "./svg/SvgIconCheckCircle";
import SvgReviewRedirectIcon from "./svg/SvgReviewRedirectIcon";
import SvgIconArrowForward from "./svg/SvgIconArrowForward"
import {SvgIconShieldProtected} from "./svg/SvgIconShieldProtected";
import {SvgIconAlert} from "./svg/SvgIconAlert";
import { SvgIconGreenCheck } from "./SvgIconGreenCheck"
import {SvgIconConfirmButton} from "./svg/SvgIconConfirmButton";
import {SvgIconDeclineButton} from "./svg/SvgIconDeclineButton";
import { SvgApprovedStatus } from "./svg/SvgApprovedStatus"
import { SvgRejectedStatus } from "./svg/SvgRejectedStatus"
import { SvgPlusIcon } from "./svg/SvgPlusIcon"
import { SvgIconRedX } from "./svg/SvgIconRedX"
import { SvgInsuranceDollarIcon } from "./svg/SvgInsuranceDollarIcon"
import { SvgIconEdit } from "./svg/SvgIconEdit"
import { SvgTransUnion } from "./svg/SvgTransUnion"
import { SvgEmail } from "./svg/SvgEmail"
import { SvgIconUsers } from "./svg/SvgIconUsers"
import {SvgMenuHamburger} from "./svg/SvgMenuHamburger";
import { SvgSideBarExpandIcon } from "./svg/SvgSideBarExpandIcon"
import { SvgEmailExposuresIcon } from "./svg/SvgEmailExposuresIcon"
import { SvgAlertMessagesIcon } from "./svg/SvgAlertMessagesIcon"
import {SvgClock} from "./svg/SgvClock"
import { SvgChevronRight } from "./svg/SvgChevronRight";
import { SvgIconErrorCircle } from "./svg/SvgIconErrorCircle"
import SvgColoredDotIcon from "./svg/SvgColoredDotIcon"
import { SvgCreditMonitoring } from "./svg/SvgCreditMonitoring"
import { SvgInsuredDocs } from "./svg/SvgInsuredDocs"
import { SvgRefreshNew } from "./svg/SvgRefreshNew"
import { SvgGuard } from "./svg/SvgGuard"
import { SvgDashboard } from "./svg/SvgDashboard"
import { SvgExpeditedProcessing } from "./svg/SvgExpeditedProcessing"
import { SvgRenewalCircleCheck } from "./svg/SvgRenewalCircleCheck"
import { SvgDollarCircle } from "./svg/SvgDollarCircle"
import { SvgUserCheckCircle } from "./svg/SvgUserCheckCircle"
import { SvgChatCircle } from "./svg/SvgChatCircle"

const GIconsSvgType: FC<ISvgIcons> = ({
  type,
  color = COLORS.DARK_BLUE_400,
  width = 6,
  height = 10,
  backgroundColor,
  fillCircle = true,
}) => {
    switch (type) {
      case ICONS_SVG_TYPE.HEADER_SEPARATOR:
          return SvgHeaderSeparator({ width, height, color })
      case ICONS_SVG_TYPE.LOGO:
        return SvgLogo(width, height)
      case ICONS_SVG_TYPE.NEW_APPLICATION:
          return SvgNewApplication({ width, height, color })
        case ICONS_SVG_TYPE.MY_APPLICATIONS:
          return SvgMyApplications({ width, height, color })
      case ICONS_SVG_TYPE.MY_INSURED_DOCUMENTS:
          return SvgMyInsuredDocuments({ width, height, color })
      case ICONS_SVG_TYPE.MY_FAMILY:
          return SvgMyFamily({ width, height, color, backgroundColor })
      case ICONS_SVG_TYPE.GIFT:
          return SvgGift({ width, height, color })
      case ICONS_SVG_TYPE.MAGIC:
          return SvgMagic({ width, height, color })
      case ICONS_SVG_TYPE.CHECK:
          return SvgCheck({ width, height, color })
      case ICONS_SVG_TYPE.X:
          return SvgX({ width, height, color })
      case ICONS_SVG_TYPE.DROPDOWN:
          return SvgDropDown({ width, height, color })
      case ICONS_SVG_TYPE.INFORMATION:
          return SvgInfo({ width, height, color })
      case ICONS_SVG_TYPE.LOCATION:
          return SvgLocation({ width, height, color, backgroundColor })
      case ICONS_SVG_TYPE.REFRESH:
          return SvgRefresh({ width, height, color, backgroundColor })
      case ICONS_SVG_TYPE.REFRESH_NEW:
          return SvgRefreshNew({ width, height, color, backgroundColor })
      case ICONS_SVG_TYPE.DOCUMENT:
          return SvgDocument({ width, height, color })
      case ICONS_SVG_TYPE.INSURANCE:
          return SvgInsurance({ width, height, color, backgroundColor })
      case ICONS_SVG_TYPE.PHOTO:
          return SvgPhoto({ width, height, color })
      case ICONS_SVG_TYPE.SUPPORT:
          return SvgSupport({ width, height, color, backgroundColor })
      case ICONS_SVG_TYPE.PLUS_SEPARATOR:
          return SvgPlusSeparator({ width, height, color })
      case ICONS_SVG_TYPE.THINK_PLUS_SEPARATOR:
          return SgvThinkPlusSeparator({ width, height, color })
      case ICONS_SVG_TYPE.MENU_USER:
          return SvgMenuUser({ width, height, color })
      case ICONS_SVG_TYPE.USER:
          return SvgUser({width, height, color})
      case ICONS_SVG_TYPE.USERS:
           return SvgIconUsers({width, height, color, backgroundColor})
      case ICONS_SVG_TYPE.BILLING:
          return SvgBilling({width, height, color})
      case ICONS_SVG_TYPE.LOGOUT:
          return SvgLogout({width, height, color})
      case ICONS_SVG_TYPE.X_CIRCLE:
          return SvgXCircle({width, height, color})
      case ICONS_SVG_TYPE.CHECK_OVER_CIRCLE:
          return SvgCheckOverCircle({width, height, color})
      case ICONS_SVG_TYPE.LOCK_OPEN:
          return SvgLockOpen({width, height, color})
      case ICONS_SVG_TYPE.LOCK_CLOSE:
          return SvgLockClose({width, height, color})
      case ICONS_SVG_TYPE.AUTH_SIGN_IN:
          return SgvAuthSignIn({width, height, color, backgroundColor})
      case ICONS_SVG_TYPE.KEY:
          return SvgKey({width, height, color, backgroundColor})
      case ICONS_SVG_TYPE.DAS_ARROW_LEFT:
          return SvgDashArrowLeft({width, height, color})
      case ICONS_SVG_TYPE.VERIFICATION_CODE_PHONE_HEADER:
          return SvgVerificationCodePhone({width, height, color, backgroundColor})
      case ICONS_SVG_TYPE.CHECK_CIRCLE:
          return SvgCheckCircle({width, height, color, backgroundColor})
      case ICONS_SVG_TYPE.AUTH_ACTIVATE:
          return SvgAuthActivate({width, height, color, backgroundColor})
      case ICONS_SVG_TYPE.ADD_PHONE_ICON:
          return SvgAddPhoneIcon({width, height, color, backgroundColor})
      case ICONS_SVG_TYPE.SIGNATURE_ARROW_DOWN:
          return SvgSignArrowDown({width, height, color})
        case ICONS_SVG_TYPE.DRAG_DROP:
            return SvgDragDrop()
        case ICONS_SVG_TYPE.SEARCH:
            return SvgSearchIcon({width, color, height})
        case ICONS_SVG_TYPE.SEARCH_STEP:
            return SvgSearchStepIcon({width, color, height})
        case ICONS_SVG_TYPE.OVAL_HEADER_STEP:
            return SvgOvalHeaderStepIcon({width, color, height})
        case ICONS_SVG_TYPE.RENEWAL:
            return SvgRenewal ({width, height, color, backgroundColor, fillCircle})
        case ICONS_SVG_TYPE.RENEWAL_CIRCLE:
            return SvgRenewalCircle({width, height, color, backgroundColor, fillCircle})
        case ICONS_SVG_TYPE.RENEWAL_CIRCLE_BODY:
            return SgvRenewalCircleBody({width, height, color, backgroundColor, fillCircle})
        case ICONS_SVG_TYPE.RENEWAL_ADD:
            return SvgRenewalAdd({width, height, color, backgroundColor, fillCircle})
        case ICONS_SVG_TYPE.EXPEDITE_DOCUMENT:
            return SvgExpediteDocument({width, height, color, backgroundColor, fillCircle})
        case ICONS_SVG_TYPE.INSURANCE_SHIELD:
            return SvgInsuranceShield({width, height, color})
        case ICONS_SVG_TYPE.INSURANCE_SHIELD_CIRCLE:
            return SvgInsuranceShieldCircle({width, height, color, backgroundColor, fillCircle})
        case ICONS_SVG_TYPE.USER_ADD_CIRCLE:
            return SvgUserAddCircle({width, height, color})
        case ICONS_SVG_TYPE.INSURANCE_ADD_SHIELD_CIRCLE:
            return SvgInsuranceAddShieldCircle({width, height, color, backgroundColor})
        case ICONS_SVG_TYPE.ADD_DOCUMENT_ICON_CIRCLE_BORDER:
            return SvgAddDocumentIconCircleBorder({width, height, color, backgroundColor})
        case ICONS_SVG_TYPE.CHECK_CIRCLE_TAX:
            return SvgCheckTax({width, height, color})
        case ICONS_SVG_TYPE.TAX_REFUND_ICON:
            return SvgTaxRefundIcon({width, height, color})
        case ICONS_SVG_TYPE.TAX_ERROR_ICON:
            return SvgTaxErrorIcon({width, height, color})
        case ICONS_SVG_TYPE.SHEETS:
            return SvgSheets({width, height, color, backgroundColor})
        case ICONS_SVG_TYPE.GOV_OFFICE:
            return SvgGovOffice({width, height, color})
        case ICONS_SVG_TYPE.PASSPORT:
            return SvgPassport({width, height, color})
        case ICONS_SVG_TYPE.MAGIC_PENCIL:
            return SvgMagicPencil({width, height, color})
        case ICONS_SVG_TYPE.ARROW_FORWARD:
            return SvgArrowForward({width, height, color})
        case ICONS_SVG_TYPE.CHEVRON_LEFT:
            return SvgChevronLeft({width, height, color})
        case ICONS_SVG_TYPE.CHECK_CIRCLE_OUTLINE:
            return SvgCheckCircleOutline({width, height, color})
        case ICONS_SVG_TYPE.ALERT_TRIANGLE:
            return SvgAlertTriangle({width, height, color})
        case ICONS_SVG_TYPE.MY_IDENTITY:
             return SvgMyIdentity({width, height, color})
        case ICONS_SVG_TYPE.MY_CREDIT_SCORES:
             return SvgMyCreditScores({width, height})
        case ICONS_SVG_TYPE.CONFIRM:
             return SvgIconCheck({width, height, color})
        case ICONS_SVG_TYPE.REJECT:
             return SvgIconX({width, height})
        case ICONS_SVG_TYPE.ICON_CHECK_CIRCLE:
             return SvgIconCheckCircle({width, height})
        case ICONS_SVG_TYPE.ICON_REDIRECT_REVIEW:
             return SvgReviewRedirectIcon({width, height})
        case ICONS_SVG_TYPE.ICON_ARROW_FORWARD:
             return SvgIconArrowForward({width, height})
        case ICONS_SVG_TYPE.ICON_SHIELD_PROTECTED:
             return SvgIconShieldProtected({width, height})
        case ICONS_SVG_TYPE.ICON_ISURANCE_DOLLAR_PROTECTED:
             return SvgInsuranceDollarIcon({width, height})
        case ICONS_SVG_TYPE.ICON_SVG_ALERT:
             return SvgIconAlert({width, height})
        case ICONS_SVG_TYPE.ICON_SVG_ERROR:
             return SvgIconErrorCircle({width, height})
        case ICONS_SVG_TYPE.ICON_APPROVED_STATUS:
             return SvgApprovedStatus({width, height})
        case ICONS_SVG_TYPE.ICON_REJECTED_STATUS:
             return SvgRejectedStatus({width, height})
        case ICONS_SVG_TYPE.ICON_GREEN_CHECK:
             return SvgIconGreenCheck({width, height})
        case ICONS_SVG_TYPE.ICON_RED_X:
             return SvgIconRedX({width, height})
        case ICONS_SVG_TYPE.ICON_APPROVED_BUTTON:
             return SvgIconConfirmButton({width, height})
        case ICONS_SVG_TYPE.ICON_DECLINE_BUTTON:
             return SvgIconDeclineButton({width, height})
        case ICONS_SVG_TYPE.ICON_PLUS:
             return SvgPlusIcon({width, height})
        case ICONS_SVG_TYPE.ICON_EDIT:
            return SvgIconEdit({width, height})
        case ICONS_SVG_TYPE.ICON_TRANS_UNION:
            return SvgTransUnion({width, height})
        case ICONS_SVG_TYPE.ICON_EXPAND_SIDEBAR:
            return SvgSideBarExpandIcon({width, height})
        case ICONS_SVG_TYPE.ICON_ALERT_MESSAGES:
            return SvgAlertMessagesIcon({width, height})
        case ICONS_SVG_TYPE.ICON_EMAIL_EXPOSURES:
            return SvgEmailExposuresIcon({width, height})
        case ICONS_SVG_TYPE.CLOCK_ICON:
            return SvgClockIcon({width, height})
        case ICONS_SVG_TYPE.GOVERNMENT_ICON:
            return SvgGovernment({color, backgroundColor, width, height})
        case ICONS_SVG_TYPE.ICON_INFO:
            return SvgIconInfo({width, height})
        case ICONS_SVG_TYPE.ICON_BOX:
            return SvgIconBox({width, height})
        case ICONS_SVG_TYPE.EMAIL:
            return SvgEmail({width, height, color})
        case ICONS_SVG_TYPE.CLOCK:
            return SvgClock({width, height, color, backgroundColor})
        case ICONS_SVG_TYPE.MENU_HAMBURGER:
            return SvgMenuHamburger({width, height, color})
        case ICONS_SVG_TYPE.CHEVRON_RIGHT:
            return SvgChevronRight({width, height, color})
        case ICONS_SVG_TYPE.COLORED_DOT: 
            return SvgColoredDotIcon({ width, height, color })
        case ICONS_SVG_TYPE.CREDIT_MONITORING: 
            return SvgCreditMonitoring({ width, height})
        case ICONS_SVG_TYPE.INSURANCE_DOCUMENTS: 
            return SvgInsuredDocs({ width, height, color})
        case ICONS_SVG_TYPE.GUARD:
             return <SvgGuard width={width} height={height} backgroundColor={backgroundColor} color={color} />
        case ICONS_SVG_TYPE.CREDIT_MONITORING: 
            return SvgCreditMonitoring({ width, height})
        case ICONS_SVG_TYPE.DASHBOARD:
            return SvgDashboard({ width, height, color, backgroundColor})
        case ICONS_SVG_TYPE.EXPEDITED_PROCESSING:
            return SvgExpeditedProcessing({ width, height, color, backgroundColor})
        case ICONS_SVG_TYPE.RENEWAL_CIRCLE_CHECK:
            return SvgRenewalCircleCheck({width, height, color, backgroundColor})
        case ICONS_SVG_TYPE.DOLLAR_CIRCLE:
            return SvgDollarCircle({width, height, color, backgroundColor})
        case ICONS_SVG_TYPE.USER_CHECK_CIRCLE:
            return SvgUserCheckCircle({width, height, color, backgroundColor})
        case ICONS_SVG_TYPE.CHAT_CIRCLE:
            return SvgChatCircle({width, height, color, backgroundColor})
      default:
        return <>"Unsupported"</>
  }
}

export default GIconsSvgType

