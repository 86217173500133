import {ApplicationStatus} from "./ApplicationStatus";

export const PREPAID_TEXT = "Prepaid";
export const PREPAID_TOOLTIP = "You pre-purchased a {solution} application on {date}";

export interface PrepaidProduct {
    productId: string;
    datePaid: string;
}

const ProductHelper = {
    /**
     * Check if product was prepaid
     * @param productId
     * @param allProducts
     */
    wasProductPrepaid: (productId: string, allProducts: any): PrepaidProduct | false => {
        for (const property in allProducts) {
            if (allProducts[property]?.status === ApplicationStatus.STATUS_SELECT_SERVICE &&
                allProducts[property]?.product_name===productId) {
                return {
                    productId: allProducts[property]?.product_name,
                    datePaid: allProducts[property]?.date_paid,
                }
            }
        }
        return false;
    },
}
export default ProductHelper;