import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

export const SERVICE = "Service"

class ServiceRepository extends BaseSessionStorageRepository {
    private static _instance: ServiceRepository;

    private constructor() {
        super();
    }

    public static getInstance(): ServiceRepository {
        if (!ServiceRepository._instance) {
            ServiceRepository._instance = new ServiceRepository();
        }
        return ServiceRepository._instance;
    }

    getKeyName(): string {
        return SERVICE;
    }

    getValue(): string | null {
        return super.getValue() ?? null;
    }
}

export default ServiceRepository;