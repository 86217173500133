import React from 'react';
import Spinner from "../spiner/Spinner";
import "./index.less";
import UserTaxUrlRepository from "@dash/gov-plus__front-end__form/src/persistence/session/UserTaxUrlRepository";
const TaxComponent = () => {
    const taxLink = UserTaxUrlRepository.getInstance().getValue();
    return (
        <div className={'columnTaxContainer'}>
            {
                taxLink && <iframe
                    className={'columnTaxIframe'}
                    onLoad={() => <Spinner/>}
                    src={taxLink}
                    allowFullScreen
                >
                </iframe>
            }
        </div>
    )
};
export default TaxComponent;



