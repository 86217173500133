import React, { FC } from "react"
import "./Record.scss"
import { RecordProps } from "./Record.props"
import GButton, { BUTTON_TYPE } from "../../molecules/Button/GButton"

const GRecord: FC<RecordProps> = ({ children, click, text, showActionButton }) => {
  return (
    <div className="GBankAccountDetailsCard">
      <div className="GBankAccountDetailsCard__Content">{children}</div>
      {showActionButton && <GButton type={BUTTON_TYPE.DANGER} showIcon={false} click={click} text={text} />}
    </div>
  )
}

export default GRecord
