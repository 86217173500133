import React from "react"
import { SvgType } from "../SvgType"

const SvgColoredDotIcon = ({ width = 8, height = 8, color }: SvgType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="50" fill={color} />
    </svg>
  )
}

export default SvgColoredDotIcon
