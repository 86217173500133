export default class TemplateOne {
    private readonly _imageHeader: string | undefined;
    private readonly _componentHeader: React.ReactElement | undefined;
    private readonly _title: string;
    private readonly _subTitle: string | undefined;
    private readonly _subTitleSize: string | undefined;
    private readonly _footerText: string | undefined;
    private readonly _checkListItems: string[] | undefined;
    private readonly _checkListSubTitle: string | undefined;
    private readonly _previousPrice: string | undefined;
    private readonly _price: string | undefined;
    private readonly _tagInformation: string | undefined;
    private readonly _acceptButton: string;
    private readonly _cancelButton: string;
    private readonly _testMode: boolean;
    private readonly _testModeMessage: string | undefined;
    private readonly _linkToOpen: string | undefined;
    private readonly _imageHeaderSubTitle: string | undefined;
    private readonly _service: string | undefined;
    private readonly _serviceName: string | undefined;
    private readonly _pageType: string | undefined;
    private readonly _crossSellProductOffer: string | undefined;
    private readonly _iconAcceptButton: string | undefined;

    constructor(
        imageHeader: string | undefined,
        componentHeader: React.ReactElement | undefined,
        title: string,
        subtitle: string | undefined,
        subTitleSize: string | undefined,
        footerText: string | undefined,
        checkListItems: string[] | undefined,
        checkListSubTitle: string | undefined,
        previousPrice: string | undefined,
        price: string | undefined,
        tagInformation: string | undefined,
        acceptButton: string,
        cancelButton: string,
        testMode: boolean,
        testModeMessage: string | undefined,
        linkToOpen: string | undefined,
        imageHeaderSubTitle: string | undefined,
        service: string | undefined,
        serviceName: string | undefined,
        pageType: string | undefined,
        crossSellProductOffer: string | undefined,
        iconAcceptButton: string | undefined
    ) {
        this._imageHeader = imageHeader;
        this._componentHeader = componentHeader;
        this._title = title;
        this._subTitle = subtitle;
        this._subTitleSize = subTitleSize
        this._footerText = footerText;
        this._checkListItems = checkListItems;
        this._checkListSubTitle = checkListSubTitle;
        this._previousPrice = previousPrice;
        this._price = price;
        this._tagInformation = tagInformation;
        this._acceptButton = acceptButton;
        this._cancelButton = cancelButton;
        this._testMode = testMode;
        this._testModeMessage = testModeMessage;
        this._linkToOpen= linkToOpen;
        this._imageHeaderSubTitle = imageHeaderSubTitle
        this._service = service
        this._serviceName = serviceName
        this._pageType = pageType
        this._crossSellProductOffer = crossSellProductOffer
        this._iconAcceptButton = iconAcceptButton
    }

    get imageHeader(): string | undefined {
        return this._imageHeader;
    }

    get componentHeader(): React.ReactElement | undefined {
        return this._componentHeader;
    }

    get title(): string {
        return this._title;
    }

    get subTitle(): string | undefined {
        return this._subTitle;
    }
    get subTitleSize(): string | undefined {
        return this._subTitleSize;
    }
    get footerText(): string | undefined {
        return this._footerText;
    }

    get checkListItems(): string[] | undefined {
        return this._checkListItems;
    }

    get checkListSubTitle(): string | undefined {
        return this._checkListSubTitle;
    }

    get previousPrice(): string | undefined {
        return this._previousPrice;
    }

    get price(): string | undefined {
        return this._price;
    }

    get tagInformation(): string | undefined {
        return this._tagInformation;
    }

    get acceptButton(): string {
        return this._acceptButton;
    }

    get cancelButton(): string {
        return this._cancelButton;
    }

    get testMode(): boolean {
        return this._testMode;
    }

    get testModeMessage(): string | undefined {
        return this._testModeMessage;
    }
    get linkToOpen(): string | undefined {
        return this._linkToOpen;
    }

    get imageHeaderSubtitle(): string | undefined {
        return this._imageHeaderSubTitle;
    }

    get imageHeaderSubTitle(): string | undefined {
        return this._imageHeaderSubTitle;
    }

    get service(): string | undefined {
        return this._service;
    }

    get serviceName(): string | undefined {
        return this._serviceName;
    }

    get pageType(): string | undefined {
        return this._pageType;
    }

    get crossSellProductOffer(): string | undefined {
        return this._crossSellProductOffer;
    }

    get iconAcceptButton(): string | undefined {
        return this._iconAcceptButton;
    }
}