export interface SignatureCanvas {
    width: number;
    height: number;
}
export interface SignatureOptions {
    width: number;
    height: number;
    trimmedCanvasCoveragePercentage: number
}

/*
* Function that calculates the percentage that the drawn signature represents of the total drawing area.
*/
export function computeSignatureOptions(canvas: SignatureCanvas, trimmedCanvas: SignatureCanvas): SignatureOptions {
    const canvasSize = canvas.width * canvas.height;
    const trimmedCanvasSize = trimmedCanvas.width * trimmedCanvas.height;
    const trimmedCanvasCoveragePercentage = (trimmedCanvasSize * 100) / canvasSize;
    return {
        width: trimmedCanvas.width,
        height: trimmedCanvas.height,
        trimmedCanvasCoveragePercentage: trimmedCanvasCoveragePercentage
    }
}

