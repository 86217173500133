export enum ABTestOneTimeFeeInputIds {
    ENABLED_ONE_TIME_FEE_TESTING_INPUT_ID = '1ea510ca_73f5_4b5e_8348_307f85e08eec',
    OVERRIDE_ONE_TIME_FEE_PRICE_INPUT_ID = 'ab331883_7eb0_42ce_8b17_5a22ba443527',
}

export enum ABTestSubscriptionInputIds {
    ENABLED_SUBSCRIPTION_COUPON = "b6688f01-b219-4a3e-856b-a4b1becf595b'",
    SUBSCRIPTION_COUPON_ID_OFFER = "02340d6d-63e5-43bf-aa2e-dab7b1ab2215",
    SUBSCRIPTION_COUPON_ID_POPUP = "bd5fe10d-094c-43c4-96ba-ef49a8fb92ef"
}

export const useABTestingPayment = () => {
    const getOneTimeFeePaymentOptions = () => {
        try {
            const oneTimeFeeEnabled = document.getElementById(ABTestOneTimeFeeInputIds.ENABLED_ONE_TIME_FEE_TESTING_INPUT_ID)?.getAttribute("value");
            const overrideOneTimeFeePrice = document.getElementById(ABTestOneTimeFeeInputIds.OVERRIDE_ONE_TIME_FEE_PRICE_INPUT_ID)?.getAttribute("value");

            return {
                oneTimeFeeEnabled: oneTimeFeeEnabled === 'true',
                overrideOneTimeFeePrice: overrideOneTimeFeePrice && !Number.isNaN(overrideOneTimeFeePrice) ? Number.parseInt(overrideOneTimeFeePrice) : undefined,
            }
        } catch (ex) {
            return {
                oneTimeFeeEnabled: false,
                overrideOneTimeFeePrice: null
            }
        }
    }

    const getSubscriptionPaymentOptions = () => {
        try {
            const subscriptionCouponEnabled = document.getElementById(ABTestSubscriptionInputIds.ENABLED_SUBSCRIPTION_COUPON)?.getAttribute("value");
            const couponInputForOffers = document.getElementById(ABTestSubscriptionInputIds.SUBSCRIPTION_COUPON_ID_OFFER)
            const couponInputForPopUp = document.getElementById(ABTestSubscriptionInputIds.SUBSCRIPTION_COUPON_ID_POPUP)

            return {
                subscriptionCouponEnabled: subscriptionCouponEnabled === "true",
                subscriptionCouponIdOffer: couponInputForOffers?.getAttribute("value"),
                subscriptionCouponIdPopup: couponInputForPopUp?.getAttribute("value")
            }
        } catch (ex) {
            return {
                subscriptionCouponEnabled: false,
                subscriptionCouponIdOffer: null,
                subscriptionCouponIdPopup: null
            }
        }
    }

    return {
        getOneTimeFeePaymentOptions,
        getSubscriptionPaymentOptions
    }
}