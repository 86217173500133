import React, {FC} from "react";
import {Rule} from "rc-field-form/lib/interface";
import GFormItem from "../../organisms/FormItem/FormItem";
import {CreditCardFormFields, LabelsColor, LabelsSize} from "../../templates/CreditCardForm/CreditCardFormFields";
import GText from "../../atoms/Text/Text";
import InputMask from "react-input-mask";
import GInputText from "../InputText/InputText";

const GCardZipCode: FC<any> = () => {
    const validationRules: Rule[] = [{required: true, message: 'Required'}]
    return (
        <GFormItem
            name={CreditCardFormFields.BillingZipCode}
            label={<GText text={'Billing ZIP code'} size={LabelsSize}
                          color={LabelsColor}/>}
            rules={validationRules}>
            <InputMask
                mask={"99999"}
                maskPlaceholder={null}
            >
                <GInputText inputMode={'numeric'}/>
            </InputMask>
        </GFormItem>
    )
}
export default GCardZipCode;