/* eslint-disable indent */
import { ActionTypes } from "./packagePages.action"
import { Action } from "../../interfacesAction/action.interface"
import { mapCenter } from "../../InterfacesEntity/mapCenter.interface"
export interface State {
  packageResponse: {} | any;
  mapCenter: mapCenter | any;
}

const initialState: State = {
  packageResponse: null,
  mapCenter: {
    lat: 53.901634,
    lng: 27.545265,
  },
}

export const packageReducer = (state: State = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.SET_PACKAGE_RESPONSE:
      return {
        ...state,
        packageResponse: action.payload,
      }
    default:
      return state
  }
}
