import React from "react"

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
}

export const SvgCheck = ({ width, height, color }: ISvgCheck) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3352 4.33203L7.00013 11.6671L3.66602 8.33297"
        stroke={color}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
