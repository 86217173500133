import React from "react";
import "./index.less";
import {IconSvg} from "../svgImages/SvgImages";


const Index = ({ validationStatus, validationMessage, iconColor, showIcon}) => {

  const validationRuleRender = () => {
    switch (validationStatus) {
      case 'default':
        return (
          <div className={"validations_defaultIcon"}>
            { showIcon
              ? <IconSvg type={"infoIcon"} iconColor={"#74849B"}/>
              : null
            }
            <div className={"validations_defaultIcon_message"}>{validationMessage}</div>
          </div>
        )
      case 'valid':
        return (
          <div className={"validations_successIcon"}>
            { showIcon
              ? <IconSvg type={"successIcon"} iconColor={iconColor}/>
              : null
            }
            <div className={"validations_successIcon_message"} dangerouslySetInnerHTML={{__html: validationMessage}}/>
          </div>
        )
      case 'notValid':
        return (
          <div className={"validations_unSuccessIcon"}>
            { showIcon
              ? <IconSvg type={"unSuccessIcon"} iconColor={iconColor}/>
              : null
            }
            <div className={"validations_unSuccessIcon_message"}>{validationMessage}</div>
          </div>
        )
      default: return null
    }
  }

  return (
    <div className={"validations"}>
      <div>
        {validationRuleRender()}
      </div>
    </div>
  );
};

export default Index;
