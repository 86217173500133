import React, { FC } from "react"
import { Upload } from "antd"
import GText from "../../atoms/Text/Text"
import GButton, { BUTTON_TYPE } from "../Button/GButton"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import { SIZE } from "../../types/types"
import "./GUpload.scss"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GSpinner from "../../atoms/Spinner/Spinner"
import GNotification, { NOTIFICATION_TYPE } from "../../organisms/Notification/Notification"

export enum GUploadStatus {
  Preload,
  Error,
  Loading,
  Loaded,
  Saving,
}

export type GUploadProps = {
  accept?: string,
  text: { title: string, subtitle: string },
  action: any,
  disabled?: boolean,
  multiple?: boolean,
  change?: any,
  drop?: any,
  method?: "post" | "POST" | "PUT" | "PATCH" | "put" | "patch" | undefined,
  state?: number,
  statusPhotoUpload?: number,
  errorMessage?: string,
  previewSrc?: string,
  showAccept?: boolean,
  handlerOnClickSave?: any,
  showRemove?: boolean,
  handlerOnClickRemove?: any,
}

const GUpload: FC<GUploadProps> = ({
  accept = "image/png, image/jpeg, .heic",
  text,
  action,
  disabled = false,
  multiple = true,
  change,
  method = "post",
  drop = (e: any) => console.log("uploading", e.dataTransfer.files),
  state,
  statusPhotoUpload,
  errorMessage,
  previewSrc,
  showAccept = true,
  handlerOnClickSave,
  showRemove = true,
  handlerOnClickRemove,
}) => {
  return (
    <>
      {(state === GUploadStatus.Preload || state === GUploadStatus.Error) && (
        <Upload
          className="GUpload"
          accept={accept}
          action={action}
          disabled={disabled}
          multiple={multiple}
          onChange={change}
          method={method}
          onDrop={drop}
        >
          <div className="GUpload__Container">
            <div className="GUpload__Container__Information">
              <GText text={text.title} color={COLORS_NEW.BLACK_700} size={SIZE.PARAGRAPH_BOLD_16} />
            </div>
            <div className="GUpload__Container__Information GUpload__Container__Information--Icon">
              <GIconsSvgType type={ICONS_SVG_TYPE.DRAG_DROP} />
            </div>
            <div className="GUpload__Container__Information GUpload__Container__Information--SubTitle">
              <GText text={text.subtitle} color={COLORS_NEW.BLACK_600} size={SIZE.PARAGRAPH_REGULAR_16} />
            </div>
          </div>
          <div className="GUpload__Cta">
            <GButton showIcon={false} text={"Browse files to upload"} textSize={SIZE.PARAGRAPH_BOLD_16} />
          </div>
        </Upload>
      )}

      {state === GUploadStatus.Error && errorMessage && (
        <div className="GUpload__Error">
          <GNotification text={errorMessage} type={NOTIFICATION_TYPE.ERROR} icon={ICONS_SVG_TYPE.INFORMATION} />
        </div>
      )}

      {state === GUploadStatus.Loading && (
        <div className="GUpload">
          <div className="GUpload__Container GUpload__Container--Loading">
            <div>
              <div className="GUpload__Container__Information">
                <GSpinner />
              </div>
              <div className="GUpload__Container__Information GUpload__Container__Information--SubTitle">
                <GText text={`Please wait...`} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.SLATE_GRAY_500} />
              </div>
            </div>
          </div>
        </div>
      )}

      {(state === GUploadStatus.Loaded || state === GUploadStatus.Saving) && (
        <div className="GUpload">
          <div className="GUpload__Container GUpload__Container--Loading">
            <div className="GUpload__Container__Information">
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              {previewSrc && (
                <img className="GUpload__Container__Information__Photo" src={previewSrc} alt={`Preview`} />
              )}
            </div>

            {(showAccept || showRemove) && (
              <div className="GUpload__Cta">
                {showAccept && (
                  <GButton
                    showIcon={false}
                    text={"Approve"}
                    type={BUTTON_TYPE.PRIMARY}
                    textSize={SIZE.PARAGRAPH_BOLD_16}
                    loading={statusPhotoUpload === GUploadStatus.Saving}
                    click={handlerOnClickSave}
                    style={{height: 44}}
                  />
                )}
                {showRemove && (
                  <GButton
                    showIcon={false}
                    text={"Take another photo"}
                    type={BUTTON_TYPE.SECONDARY}
                    textSize={SIZE.PARAGRAPH_BOLD_16}
                    disabled={statusPhotoUpload === GUploadStatus.Saving || statusPhotoUpload === GUploadStatus.Preload}
                    click={handlerOnClickRemove}
                    style={{height: 44}}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default GUpload
