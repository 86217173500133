import React, {useState, useEffect} from 'react'
import './style.less'
import {Link} from "react-router-dom";
import {Input, Form} from 'antd'
import {ArrowLeftOutlined} from '@ant-design/icons'
import SettingsButton from "../../../components/SettingsButton";
// import {useSelector} from "react-redux";

const EditEmailPage = () => {
  // const {eMail} = useSelector(({auth})=>auth.userData);

  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [isTheSameEmail, setTheSameEmail] = useState(false);

  const workWithEmailInput = (event)=>{
    setEmail(event.target.value)
  }

  const workWithConfirmEmailInput = (event)=>{
    setConfirmEmail(event.target.value)
  }

  useEffect(() => {
    setTheSameEmail(email === confirmEmail && confirmEmail !== "" && email !== "")
  }, [email, confirmEmail])

  useEffect(() => {
    // here we will get email
  }, [])

  const sendNewEmail = (inputVals) => {
    // here we will make request
    if(isTheSameEmail) {

    }
  }

  return (
    <div className='EditEmailPage'>
      <Link to='/account-settings' className='EditEmailPage-link'>
        <ArrowLeftOutlined className='EditEmailPage-link-icon'/>Account Settings
      </Link>
      <h1 className='EditEmailPage-title'>Edit Email</h1>
      <Form
        className='EditEmailPage-form'
        layout="vertical"
        onFinish={sendNewEmail}
      >
        <Form.Item
          name={"email"}
          label={"Email"}
          rules={[{required: false, message: 'Invalid email', type: "email"}]}
          className='EditEmailPage-form-box-item'
        >
          <Input
            onChange={workWithEmailInput}
            className='EditEmailPage-form-box-item-input'
            value={""}
          />
        </Form.Item>

        <Form.Item
          name={"confirmEmail"}
          label={"Confirm email"}
          rules={[{required: false, message: 'Invalid email', type: "email"}]}
          className='EditEmailPage-form-box-item'
        >
          <Input
            onChange={workWithConfirmEmailInput}
            className='EditEmailPage-form-box-item-input'
            value={""}
          />
        </Form.Item>

        <SettingsButton
          disabled={!isTheSameEmail}
          onClickFoo={sendNewEmail}
          buttonText={'Save Email Address'}
        />

      </Form>
    </div>
  )
}

export default EditEmailPage
