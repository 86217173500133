import React, { FC } from "react"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GAuthHeader from "../../molecules/AuthHeader/AuthHeader"
import "./VerificationCode.scss"
import { Form } from "antd"
import { SIZE } from "../../types/types"
import GButton from "../../molecules/Button/GButton"
import GAuthFooter from "../../molecules/AuthFooter/AuthFooter"
import GFormItem, { FORM_ITEM_STATUSES } from "../../organisms/FormItem/FormItem"
import ReactCodeInput from "react-verification-code-input"
import GCheckBox from "../../molecules/CheckBox/CheckBox"

type GVerificationCodeProps = {
  formOnFinish: any,
  formOnFieldsChange: any,
  form: any,
  loader: boolean,
  showInputValidation: boolean,
  validationMessage: string,
  locationState: string,
  checkPhoneExist: string,
  resendVerificationCode: any,
  resendSmsTimerStatus: any,
  resendSmsTimer: any,
  onFooterFirstLinkClick: any,
  onCheckBoxChangeHandler?: any,
}

const GVerificationCode: FC<GVerificationCodeProps> = ({
  formOnFinish,
  formOnFieldsChange,
  form,
  loader,
  showInputValidation,
  validationMessage,
  locationState,
  checkPhoneExist,

  resendVerificationCode,
  resendSmsTimerStatus,
  resendSmsTimer,
  onFooterFirstLinkClick,

  onCheckBoxChangeHandler,
}) => {
  const TITLE_SEND_CODE = "Enter Verification Code"
  const HEADER_DESCRIPTION_SEND_CODE = `A text message with a Verification Code was just sent to <b class='phone-text'>XXX-XXX-${checkPhoneExist}</b>`
  // eslint-disable-next-line max-len
  const HEADER_DESCRIPTION_CONFIRM_FORM = `We need to verify your phone number to keep your account safe. We just sent a text verification code 
    to <b class='phone-text'>XXX-XXX-${checkPhoneExist}</b><br><br>Open the SMS app on your phone and enter the 6 digit code below.`
  const FOOTER_GO_BACK = "Go Back"
  const FOOTER_GO_BACK_LINK = `/signin`
  const FOOTER_DESCRIPTION = `Didn't receive the Verification Code?`
  const FOOTER_SECOND_LINK_TEXT = resendSmsTimerStatus ? `Please wait ${resendSmsTimer} second(s) to resend.` : `Resend`
  const checkboxLabel = "Remember this device for 30 days"
  const buttonText = "Sign in securely"

  const headerRender = () => {
    if (locationState === "sendCode" || locationState === "confirmPhone") {
      return (
        <GAuthHeader
          title={TITLE_SEND_CODE}
          description={locationState === "sendCode" ? HEADER_DESCRIPTION_SEND_CODE : HEADER_DESCRIPTION_CONFIRM_FORM}
          renderDescriptionHtml={true}
        />
      )
    } else {
      return null
    }
  }

  const footerSecondLinkClick = () => {
    if (!resendSmsTimerStatus) resendVerificationCode()
  }

  const footerRender = () => {
    return (
      <GAuthFooter
        firstLinkText={FOOTER_GO_BACK}
        firstLinkShowIcon={true}
        firstLinkUrl={``}
        firstLinkClick={onFooterFirstLinkClick}
        description={FOOTER_DESCRIPTION}
        secondLinkText={FOOTER_SECOND_LINK_TEXT}
        secondLinkClick={footerSecondLinkClick}
        secondBlockInRow={!!resendSmsTimerStatus}
      />
    )
  }

  return (
    <div className={`GVerificationCode`}>
      {headerRender()}
      <Form
        initialValues={{ remember: true }}
        name={`basic`}
        onFinish={formOnFinish}
        onFieldsChange={formOnFieldsChange}
        className={`GVerificationCode__Form`}
        layout={`vertical`}
        form={form}
      >
        <GFormItem
          name="verification_code"
          initialValue=""
          status={`${showInputValidation ? FORM_ITEM_STATUSES.ERROR : FORM_ITEM_STATUSES.DEFAULT}`}
          message={`${showInputValidation ? validationMessage : ""}`}
        >
          <ReactCodeInput
            className={`GVerificationCode__Form__CodeInput ${
              showInputValidation && "GVerificationCode__Form__CodeInput--Invalid"
            }`}
          />
        </GFormItem>

        <GFormItem initialValue="" name={`rememberMe`} valuePropName={`checked`}>
          <GCheckBox label={checkboxLabel} onChange={onCheckBoxChangeHandler} />
        </GFormItem>

        <div className={`GVerificationCode__Form__BtnContainer`}>
          <GButton
            loading={loader}
            text={buttonText}
            size={"large"}
            isSubmit={true}
          />
        </div>
        {footerRender()}
      </Form>
    </div>
  )
}
export default GVerificationCode
