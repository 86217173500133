/* eslint-disable react-redux/useSelector-prefer-selectors */
import React, { useCallback } from "react"
import "./AddPersonalInfoCard.scss"
import useIsMobile from "../../../../../../dashboard-govplus-front/src/hooks/useIsMobile"
import {
  AlertBackgroundTypes,
  BUNDLE_NAMES,
  BUNDLE_TYPES,
  mockTeaserRecords,
  movePresentToStart,
  RecordType,
} from "../../../../util/IdentityProtectionHelper"
import GSpinner from "../../atoms/Spinner/Spinner"
import GText from "../../atoms/Text/Text"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import { SIZE } from "../../types/types"
import GAlertBackgroundMessage from "../AlertBackgroundMessage/AlertBackgroundMessage"
import GCard from "../GCard/GCard"
import GChip from "../GChip/GChip"
import { AddPersonalInfoProps } from "./AddPersonalInfoCard.props"

const AddressAndSSNMockedCards = [
  {
    recordType: RecordType.SSN,
    isPresent: true,
  },
  {
    recordType: RecordType.ADDRESS,
    isPresent: true,
  },
]

const TeaserRecordsSection: React.FC<AddPersonalInfoProps> = ({ identities, handleTeaserClick, dynamic }) => {
  return (
    <div className={"AddPersonalInfoCard__Content__Cards"}>
      {identities?.map((chip: { recordType: RecordType, isPresent: boolean }, index) => {
        const recordType = chip?.recordType
        const isPresent = chip?.isPresent

        return (
          <GChip
            key={`${recordType}-${index}`}
            recordType={recordType}
            isPresent={isPresent}
            dynamic={dynamic}
            onClick={handleTeaserClick}
          />
        )
      })}
    </div>
  )
}

const AddPersonalInfoCard: React.FC<AddPersonalInfoProps> = ({
  dynamic,
  isTeaserRecordsStatus,
  isTeaserSubscriptionStatus,
  subscriptionTeaserAction,
  setRecordType,
  setOpenAddRecordModal,
  identities,
  handleClickInfoCard,
  identitiesLoader,
  identitiesError,
}) => {
  const isMobile = useIsMobile(768)

  // check if component is in teaser mode - regardless of the subscription or bundleId
  const showTeaserBlock = isTeaserSubscriptionStatus || isTeaserRecordsStatus

  // component in teaser mode
  const handleTeaserRecordsClick = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation()
      // regardless of the subscription
      if (isTeaserSubscriptionStatus) {
        subscriptionTeaserAction?.()
      } else if (isTeaserRecordsStatus) {
        // regardless of the bundleId. if user has missing fields, they will be prompted to update their information
        handleClickInfoCard?.(BUNDLE_TYPES.MEDIUM, BUNDLE_NAMES.MEDIUM)
      }
    },
    [handleClickInfoCard, isTeaserRecordsStatus, isTeaserSubscriptionStatus, subscriptionTeaserAction]
  )

  // component in non-teaser mode, user can update records
  const handleNotTeaserRecordsClick = (recordType: RecordType) => {
    if (recordType !== RecordType.SSN) {
      setRecordType?.(recordType)
      setOpenAddRecordModal?.(true)
    }
  }

  const cardContent = () => {
    if (showTeaserBlock) {
      return (
        <TeaserRecordsSection
          dynamic={dynamic}
          identities={mockTeaserRecords}
          handleTeaserClick={handleTeaserRecordsClick}
          isTeaserSubscriptionStatus={isTeaserSubscriptionStatus}
          isTeaserRecordsStatus={isTeaserRecordsStatus}
        />
      )
    }
    return (
      <div className={"AddPersonalInfoCard__Content__Cards"}>
        {AddressAndSSNMockedCards.concat(isMobile ? movePresentToStart(identities, "isPresent") : identities)?.map(
          (chip: { recordType: RecordType, isPresent: boolean }, index) => {
            const recordType = chip?.recordType
            const isPresent = chip?.isPresent

            return (
              <GChip
                key={`${recordType}-${index}`}
                recordType={recordType}
                isPresent={isPresent}
                dynamic={dynamic}
                onClick={() => handleNotTeaserRecordsClick(recordType)}
              />
            )
          }
        )}
      </div>
    )
  }

  const renderErrorBlock = () => {
    if (!showTeaserBlock) {
      return (
        <GAlertBackgroundMessage
          status={AlertBackgroundTypes.ERROR}
          message={"Error fetching user data. Please try again later."}
        />
      )
    }
    return null
  }

  const mobileCardStyle = isMobile ? { padding: "28px" } : {}

  return (
    <section>
      {dynamic && (
        <div className={"AddPersonalInfoCard__Header"}>
          <GText
            text={"Identity monitoring"}
            size={SIZE.PARAGRAPH_BOLD_32}
            color={COLORS_NEW.BLACK_1000}
            style={{ letterSpacing: "-1px" }}
          />
        </div>
      )}

      <div className={identitiesLoader ? "AddPersonalInfoCard__Content__Loading" : "AddPersonalInfoCard__Content"}>
        <GCard title={"Monitored information"} style={mobileCardStyle}>
          <>
            <div className={"AddPersonalInfoCard__Content__Text"}>
              <GText
                text={"Add your personal information to get the most out of your identity protection."}
                size={SIZE.PARAGRAPH_BOLD_16}
                color={COLORS.BLACK_500}
              />
            </div>

            {identitiesLoader && (
              <div className={"AddPersonalInfoCard__Overlay"}>
                <div className={"AddPersonalInfoCard__Spinner"}>
                  <GSpinner />
                </div>
              </div>
            )}
            {!identitiesError && cardContent()}
            {identitiesError && !identities?.length && renderErrorBlock()}
          </>
        </GCard>
      </div>
    </section>
  )
}

export default AddPersonalInfoCard
