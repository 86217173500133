import {useSubscription} from "../../../../dashboard-govplus-front/src/hooks/useSubscription";
import React, {FC, useEffect, useMemo, useState} from "react";
import GSpinner from "../../Components/atomicDesign/atoms/Spinner/Spinner";
import {MyInsuredDocumentsProps} from "../Insurance/MyInsuredDocuemnts/MyInsuredDocuments";
import {MyAutomaticRenewalsProps} from "../Renewal/MyAutomaticRenewal/MyAutomaticRenewals";
import {IInsuredDocument, IRenewalDocument} from "../../Components/atomicDesign/types/Models";
import {
    MyIdentityPageProps
} from "../IdentityProtection/MyIdentity/MyIdentityPage.props";
// @ts-ignore
import {useModals} from "../../../../dashboard-govplus-front/src/hooks/useModals";


interface TeaserPageWrapperProps {
    DocumentsPage: FC<MyInsuredDocumentsProps> | FC<MyAutomaticRenewalsProps> | FC<MyIdentityPageProps>,
    documents: IInsuredDocument[] | IRenewalDocument[] | []
}

const TeaserPageWrapper = ({
    DocumentsPage,
    documents
}: TeaserPageWrapperProps) => {
    const {loading: loadingSubscription, checkStatus} = useSubscription()
    const {openPopup, checkIfShowSubscriptionModal, loadingPaymentModel} = useModals()

    useEffect(checkStatus, [])

    const loading = loadingSubscription || loadingPaymentModel

    return (
        <>
        {/* @ts-ignore */}
            {!loading && <DocumentsPage documents={documents} onGetSubscription={checkIfShowSubscriptionModal}/>}
            {(loading) && <GSpinner />}
        </>
    )
}

export default TeaserPageWrapper