import React, { useState } from "react"
import { Button, Select } from "antd"
import GButton from "../../../atomicDesign/molecules/Button/GButton";
import {SIZE} from "../../../atomicDesign/types/types";

type AdditionalQuestionSelectBtnProps = {
  question: any,
  handlerAdditionalQuestionClickButton: any,
  disableBtn: boolean,
  buttonLoading?: string,
}

// eslint-disable-next-line max-len
const AdditionalQuestionSelectBtn: React.FunctionComponent<AdditionalQuestionSelectBtnProps> = ({
  question,
  handlerAdditionalQuestionClickButton,
  disableBtn,
  buttonLoading,
}) => {
  const acceptClick = (selectedValue: string) => {
    handlerAdditionalQuestionClickButton(selectedValue, question.next)
  }
  return (
    <>
      <div className={`additional-question-select-btn`}>
        {question.options?.map((option: string, index: number) => (
          <React.Fragment key={index}>
            <GButton
                textSize={SIZE.PARAGRAPH_BOLD_16}
                text={option}
                isSubmit={false}
                showIcon={false}
                click={() => acceptClick(option)}
                disabled={disableBtn}
                loading={buttonLoading ? buttonLoading === option : false}
            />
          </React.Fragment>
        ))}
      </div>
    </>
  )
}
export default AdditionalQuestionSelectBtn
