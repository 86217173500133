import React from "react";

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
  fillCircle?: boolean;
}

export const SvgInsurance = ({
  width = 65,
  height = 65,
  color = "#185EC8",
  backgroundColor = "#EEF4FB",
  fillCircle = true
}: ISvgCheck) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {fillCircle && <rect
          x="0.164062"
          y="0.65625"
          width="64"
          height="64"
          rx="32"
          fill={backgroundColor}
      />}
      <path
        d="M32.6641 42.6562C32.6641 42.6562 41.1641 38.6562 41.1641 32.6562V25.6562L32.6641 22.6562L24.1641 25.6562V32.6562C24.1641 38.6562 32.6641 42.6562 32.6641 42.6562Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.3594 33.3281L31.1318 35.0745C31.3279 35.2677 31.6432 35.2661 31.8373 35.0709L36.9727 29.9062"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
