import React, {FC, useEffect, useLayoutEffect, useRef, useState} from "react"
import { BasicBlockContainerProps } from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { useSelector } from "react-redux"
import { RootState } from "../../App"
import { userSelector } from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"

const PercentageScaleChildrenLayout: FC<BasicBlockContainerProps> = ({ item: { className } }) => {
  const ref = useRef(null)
  // @ts-ignore
  const offsetWidth = ref?.current?.parentNode.offsetWidth
  const [widthParent, setWidthParent] = useState(offsetWidth ? offsetWidth : 0)
  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      setWidthParent(ref?.current?.parentNode.offsetWidth)
      // @ts-ignore
      const handleResize = () => setWidthParent(ref?.current?.parentNode.offsetWidth)

      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, 0)
  }, [])
  const percents = useSelector<RootState, { percents: any }>(userSelector).percents ?? ""

  const [currentPercent, setCurrentPercent] = useState(0)

  useEffect(() => {
    const timerId = setTimeout(() => setCurrentPercent(percents?.oneProduct), 0)
    return () => clearInterval(timerId)
  }, [percents?.oneProduct])

  return (
    <div
      ref={ref}
      className={className}
      style={{ width: (widthParent / 100) * Number(currentPercent), height: "5px" }}
    />
  )
}

export default PercentageScaleChildrenLayout
