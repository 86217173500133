import {call, put, takeEvery} from "redux-saga/effects"
import ServiceHostDashBoard, {ServiceUser} from "../../../services/requests/userService"
import {ActionTypes, getFormFillPercents, setPercents, setWebFlowData, setWebFlowSolutions} from "./user.actions"
import {delay, getClientTokenForResponse, getRoot} from "../step/service.step.saga"
import ServiceWebFlow from "../../../services/requests/webFlow"
import {getWebFlowData, getWebFlowSolutionData} from "./service.user.saga"
import {getPropertiesForEvents, getServiceAndSolutionNameForFormsHeaders} from "../../../services/gtm/backEndEvent"
import {removeEmailInJson} from "../form/servicePayment"
import {setSubmitFormError} from "../form/form.actions"
import {setLoadingButtonState, setLoadingState} from "../loading/loading.action"
import {LoadingState} from "../../../shared/constans/user-from-view-mode.enum"
import {ErrorMessages} from "../../../util/ErrorMessages"
import GAnalyticEvents from "../../../Components/atomicDesign/analytic/AnalyticEvents";
import UtmRepository from "../../../persistence/cookies/UtmRepository";
import {FormsKeys} from "../../../util/FormsKeys";
import UserIpRepository, {USER_IP} from "../../../persistence/session/UserIpRepository";
import {getISOFormat, notifyError} from "../../../util/helper";
import SessionStorageHandler from "../../../persistence/SessionStorageHandler";
import EmailVerificationFailedBuilder
    from "../../../Components/atomicDesign/analytic/event/EmailVerificationFailed/EmailVerificationFailedBuilder";
import EmailVerificationSucceededBuilder
    from "../../../Components/atomicDesign/analytic/event/EmailVerificationSucceeded/EmailVerificationSucceededBuilder";
import {EventsHelper} from "../../../util/EventsHelper";
import {IAddress} from "../../../Components/atomicDesign/analytic/event/EventProperties";
import OuterIdRepository from "../../../persistence/session/OuterIdRepository";
import {AdditionalSagaHelper} from "../additionalForm/AdditionalSagaHelper";
import SubscriptionService from "../../../services/subscription/subscription-service";

function* savePrefilledSaga({payload}: ReturnType<any>): any {
    try {
        yield ServiceHostDashBoard.savePrefilled({...payload})
        const {form_id} = yield getPropertiesForEvents()
        const {client_token} = yield getClientTokenForResponse()

        if (yield client_token && form_id && form_id !== "undefined" && form_id !== "null") {
            yield put(getFormFillPercents({client_token, form_id, check: true}))
        }
    } catch (e) {
        // console.log(e)
    }
}

function* setUserDataSaga({payload}: ReturnType<any>): any {
    try {
        yield ServiceHostDashBoard.setUserData({...payload})
    } catch (e) {
        // console.log(e)
    }
}

function* getFormFillPercentsSaga({payload}: ReturnType<any>): any {
    try {
        const {form_id, client_token} = yield payload
        if (yield client_token && form_id && form_id !== "undefined" && form_id !== "null") {
            const response = yield ServiceUser.getFormFillPercents({form_id, client_token, check: true})
            if (response?.success) {
                yield put(setPercents(response?.success))
            }
        }
    } catch (e) {
        // console.log(e)
    }
}

function* submitGLOSaga({payload}: ReturnType<any>): any {

    try {
        yield put(setLoadingButtonState(LoadingState.Loading))
        const {client_token} = yield getClientTokenForResponse()
        const {submitObject, tagFields} = payload
        const {analyticsUserId, analyticsAnonymousId} = getPropertiesForEvents();
        const response = yield ServiceUser.submitGLO({
            client_token, submitObject,
            flattenData: tagFields,
            userId: analyticsUserId,
            anonymousId: analyticsAnonymousId,
            userIp: UserIpRepository.getInstance().getValue(),
            utm: UtmRepository.getInstance().getUtm(),
        })

        if (response.error) {
            yield put(setLoadingButtonState(LoadingState.Loaded))
            throw new Error(response.error.message)
        }

        if (response?.success?.event_email_verification_failed || response?.success?.event_email_verification_success) {
            let eventInfo;
            let eventBuilder;
            if (response?.success?.event_email_verification_failed) {
                eventInfo = response?.success?.event_email_verification_failed;
                eventBuilder = new EmailVerificationFailedBuilder();
            }
            if (response?.success?.event_email_verification_success) {
                eventInfo = response?.success?.event_email_verification_success;
                eventBuilder = new EmailVerificationSucceededBuilder();
            }

            if (eventInfo && eventBuilder) {
                eventBuilder.setEmailVerified(eventInfo?.properties?.emailVerified)
                    .setEmailData(eventInfo?.properties?.email_data)
                    .setIpAddress(UserIpRepository.getInstance().getValue());
                GAnalyticEvents.track(eventBuilder.build());
            }
        }

        if (response?.success?.email_validation) {
            const email_data = {
                field_name: 'email',
                status: response?.success?.email_validation.status,
                message: response?.success?.email_validation?.feedback,
            }
            if (email_data.status == 'ERROR') {
                yield put(setSubmitFormError(email_data))
                throw email_data?.message
            }
        }

        if (response?.success?.user_exists) {
            yield removeEmailInJson(response?.success)
            yield put(setSubmitFormError(response?.success?.user_exists))
            throw response?.success?.user_exists?.message
        }

        if (response?.success?.client_token) {
            yield call(AdditionalSagaHelper.setAuthBearerToken, response?.success?.client_token);
        }

        yield sessionStorage.setItem(FormsKeys.GloSaved, "true")

        if (response?.success?.outer_id && !OuterIdRepository.getInstance().getValue()) {
            yield OuterIdRepository.getInstance().setValue(response?.success?.outer_id);
        }
        const utmVars = UtmRepository.getInstance().getUtm();

        yield call(triggerIdentifyEvent, response?.success.user_hash, utmVars, submitObject, response, false, false);

        yield call(triggerSignedUpEvent, submitObject, utmVars, response);


        yield delay(300)
        yield put(setLoadingButtonState(LoadingState.Loaded))
        yield (payload.redirectCallBack?.())
    } catch (e: any) {
        if (e.message === ErrorMessages.AuthorizationErrorMessage) {
            SessionStorageHandler.clearIgnoringKey([USER_IP]);
            yield delay(300)
            yield (window.location.href = `${getRoot()}/signin`)
        }
        yield put(setLoadingState(LoadingState.Loaded))
        yield put(setLoadingButtonState(LoadingState.Loaded))
        notifyError(e)
    }
}

function* getWebFlowByIdKeySaga(): any {
    try {
        const response = yield ServiceWebFlow.getHeaderData()
        const webFlowData = yield getWebFlowData(response)
        yield put(setWebFlowData(webFlowData))
        // yield localStorage.setItem("webFlowData", JSON.stringify(webFlowData))
    } catch (e) {
    }
}

function* getWebFlowSolutionsDataSaga(): any {
    const response = yield ServiceWebFlow.getSolutionsData()
    const webFlowData = yield getWebFlowSolutionData(response)
    yield put(setWebFlowSolutions(webFlowData))
    yield localStorage.setItem("webFlowSolutionData", JSON.stringify(webFlowData))
}

async function triggerIdentifyEvent(userHash: string, utmVars: any, submitObject: any, response: any, isPaid: boolean = false, isActivated: boolean = false) {
    const responseProfill = await ServiceHostDashBoard.getUsersData();
    const createdAt = responseProfill?.created_at;
    const dateOfBirth = submitObject?.date_birth ?? "";
    const address: IAddress = {
        street: submitObject?.residential_address1 ?? "",
        city: submitObject?.residential_city_name ?? "",
        state: submitObject?.residential_state_code ?? "",
        postalCode: submitObject?.residential_zipcode ?? "",
    }

    const isSubscribed = await SubscriptionService.isUserSubscribed();

    await EventsHelper.triggerIdentify(
        OuterIdRepository.getInstance().getValue() ?? "",
        address,
        submitObject?.email ?? "",
        submitObject?.firstname ?? "",
        submitObject?.lastname ?? "",
        "",
        dateOfBirth,
        isPaid,
        isSubscribed,
        utmVars,
        isActivated,
        userHash,
        createdAt
    );
}

async function triggerSignedUpEvent(submitObject: any, utmVars: any, response: any) {
    const {solutionName, service, subCategory} = getServiceAndSolutionNameForFormsHeaders();

    let street = submitObject.residential_address1;
    if (submitObject.address2_old) {
        street += " " + submitObject.address2_old;
    }

    const address: IAddress = {
        street: street ?? "",
        city: submitObject?.residential_city_name ?? "",
        state: submitObject?.residential_state_code ?? "",
        country: "USA",
        postalCode: submitObject.residential_zipcode ?? "",
    }

    await EventsHelper.triggerSignedUp(
        utmVars,
        response?.success?.created_at ?? "",
        submitObject?.email ?? "",
        submitObject?.firstname ?? "",
        submitObject?.lastname ?? "",
        address,
        getISOFormat(submitObject.date_birth ?? null),
        solutionName ?? "",
        service ?? "",
        subCategory ?? "",
        ""
    )
}

export default function* userSaga() {
    yield takeEvery(ActionTypes.GET_FORM_FILL_PERCENTS, getFormFillPercentsSaga)
    yield takeEvery(ActionTypes.SAVE_PREFILLED, savePrefilledSaga)
    yield takeEvery(ActionTypes.SET_USERS_DATA, setUserDataSaga)
    yield takeEvery(ActionTypes.SUBMIT_GLO, submitGLOSaga)
    yield takeEvery(ActionTypes.GET_WEBFLOW_BY_ID_KEY, getWebFlowByIdKeySaga)
    yield takeEvery(ActionTypes.GET_WEBFLOW_SOLUTIONS_DATA, getWebFlowSolutionsDataSaga)
}
