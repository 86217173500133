export enum ClickedButton {
    YES = 'Y',
    NO = 'N',
}

export enum QuestionnaireButtonText {
    YES = 'Yes',
    NO = 'No',
}

export enum QuestionnaireButtonSize {
    SMALL = 'small',
}