export const GrowMessage = {
    LOGIN_TO_CONTINUE: "Please login to continue.",
    ACTIVATION_LINK_INVALID: "Your activation link is invalid. Please reset your password to obtain a new activation link.",
    ACCOUNT_ALREADY_ACTIVATED: "Your account has already been activated. Please log in.",
    EXPIRED_LINK: "Your link has expired, we just sent you a new one at {email}, please check your emails.",
};

export const AlertMessage = {
    PROFILE_ANOTHER_USER_ALREADY_REGISTERED_WITH_THIS_USERNAME: "profile.ANOTHER_USER_ALREADY_REGISTERED_WITH_THIS_USERNAME",
    COMMON_CREATED_SUCCESSFULLY: "common.CREATED_SUCCESSFULLY",
    LOGIN_USER_UNAUTHORIZED: "login.USER_UNAUTHORIZED",
}

export const UiMessage = {
    EMAIL_ALREADY_TAKEN: "This email has already been taken",
    WRONG_DATA: "Something wrong check your data please",
    INVALID_EMAIL: "Please enter a valid email.",
    CONFIRM_PASSWORD: "Please confirm your password",
    PASSWORDS_NOT_MATCH: "Passwords do not match, please try again",
    EMPTY_MESSAGE: "",
}