import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

const OFFER_COUPON_VAR_KEY = "offer_coupon";

class OfferCouponRepository extends BaseSessionStorageRepository {
    private static _instance: OfferCouponRepository;

    private constructor() {
        super();
    }

    public static getInstance(): OfferCouponRepository {
        if (!OfferCouponRepository._instance) {
            OfferCouponRepository._instance = new OfferCouponRepository();
        }
        return OfferCouponRepository._instance;
    }

    getKeyName(): string {
        return OFFER_COUPON_VAR_KEY;
    }
}

export default OfferCouponRepository;