import React, { useState } from "react"
import { connect } from "react-redux"
import { Dispatch } from "../../FormComponents/FieldsComponents/SelectComponent/useDefaultOption"
import { Form } from "antd"
import LoginUserModalContent from "./LoginUserModalContent"
import WrapperContentModal from "../../../Hocs/WrapperContentModal/WrapperContentModal"
import { logInCustomerByEmail } from "../../../Redux/store/email/email.action"
import SmsUserModalContent from "./SmsUserModalContent"
import LoginRedirectDashboardContent from "./LoginRedirectDashboardContent"
import { env } from "../../../env"

type LoginUserModalContainerProps = {
  setValueModal: any,
  statusAuthModal: string,
  dispatch: any,
}

export type setCredentialValueType = (value: string, setValue: Dispatch<string>) => void

const redirectLink = env.REACT_APP_DASHBOARD

const LoginUserModalContainer: React.FunctionComponent<LoginUserModalContainerProps> = ({
  setValueModal,
  statusAuthModal,
  dispatch,
}) => {
  const [form] = Form.useForm()
  const [currentValues, setCurrentValues] = useState({})

  const onFinish = (values: {}) => {
    const submitValues = { ...currentValues, ...values, otp_token: null }
    setCurrentValues(submitValues)
    dispatch(logInCustomerByEmail(submitValues))
  }

  const onFinishFailed = (errorInfo: {} | any) => {
    // console.log("Failed:", errorInfo)
  }

  return (
    <Form
      className={"login_user_modal__form-container check-status__form-container"}
      form={form}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {/*@ts-ignore*/}
      <WrapperContentModal
        setValueModal={setValueModal}
        headerName={"LogIn"}
        cancelButton={"Cancel"}
        submitButton={"Ok"}
        dispatch={dispatch}
      >
        <div className="modal-bl__content-container login_user_modal__content">
          {statusAuthModal === "login" && <LoginUserModalContent />}
          {statusAuthModal === "sms" && <SmsUserModalContent />}
          {statusAuthModal === "redirect" && <LoginRedirectDashboardContent />}
        </div>
      </WrapperContentModal>
    </Form>
  )
}
const mapStateToProps = (state: any) => ({
  statusAuthModal: state.email.statusAuthModal,
})

export default connect(mapStateToProps)(LoginUserModalContainer)
