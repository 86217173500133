import TemplateOneBuilder from "../TemplateOneBuilder";
import {ITemplateOneBuilder} from "./ITemplateOneBuilder";
import {IUpsellProcessingData} from "../../../StepUpsell";
import TemplateOne from "../TemplateOne";
import familyImage from "../../../../../../../../assets/image/upsells/family-upgrade.png";
export class MemberFamilyUpgrade implements ITemplateOneBuilder{
    benefits: string[] = [
        'Expedited application processing',
        'Automatic document renewals',
        'Protection against lost, stolen, and damaged documents',
        'Expert application review to prevent mistakes and delays'
    ];

    buildTemplate(builder: TemplateOneBuilder, data: IUpsellProcessingData, solutionName: string): TemplateOne {
        let pageBuilder = builder
            .setImageHeader(familyImage)
            .setCheckListItems(this.benefits)
            .setPrice(`${data.price}`)
            .setTagInformation('Cancel anytime')
            .setImageHeaderSubtitle("With GOV+ Subscription, you also get:")
        return pageBuilder.build();
    }

}