import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCSSES
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  notifications: [
    {noteDescription: "Lorem ipsum dolor sit.", noteTime: "2mins ago"},
    {noteDescription: "Dolor lorem ipsum dolor sit.", noteTime: "7mins ago"},
    {noteDescription: "Sit amet lorem 1", noteTime: "15mins ago"},
    {noteDescription: "Sit amet lorem 2", noteTime: "60mins ago"},
    {noteDescription: "Sit amet lorem 3", noteTime: "120mins ago"},
    {noteDescription: "Sit amet lorem 4", noteTime: "150mins ago"},
    {noteDescription: "Sit amet lorem 5", noteTime: "180mins ago"},
  ]
};

const NotificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      return {
        ...state,
        loader: true
      }
    }
    case GET_NOTIFICATIONS_SUCCSSES: {
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload]
      }
    }
    default:
      return state;
  }
}

export default NotificationReducer;
