import React from "react"

interface ISvgUser {
  width: number;
  height: number;
  color: string;
}

export const SvgUserAddCircle = ({ width, height, color }: ISvgUser) => {
  return (
    <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.6734 0.349637C30.885 0.117186 33.115 0.117186 35.3266 0.349637C37.5382 0.582089 39.7194 1.04572 41.8344 1.73291C43.9493 2.42011 45.9865 3.32709 47.9123 4.439C49.8382 5.55091 51.6423 6.86162 53.2949 8.34964C54.9475 9.83766 56.4396 11.4948 57.7467 13.2939C59.0538 15.093 60.1688 17.0242 61.0733 19.0557C61.9778 21.0873 62.6669 23.2081 63.1293 25.3833C63.5916 27.5585 63.8247 29.7762 63.8247 32C63.8247 34.2238 63.5916 36.4415 63.1293 38.6167C62.6669 40.7919 61.9778 42.9127 61.0733 44.9443C60.1688 46.9758 59.0538 48.907 57.7467 50.7061C56.4396 52.5052 54.9475 54.1623 53.2949 55.6504C51.6423 57.1384 49.8382 58.4491 47.9123 59.561C45.9865 60.6729 43.9493 61.5799 41.8344 62.2671C39.7194 62.9543 37.5382 63.4179 35.3266 63.6504C33.115 63.8828 30.885 63.8828 28.6734 63.6504C26.4618 63.4179 24.2806 62.9543 22.1656 62.2671C20.0507 61.5799 18.0135 60.6729 16.0877 59.561C14.1618 58.4491 12.3577 57.1384 10.7051 55.6504C9.0525 54.1623 7.5604 52.5052 6.25328 50.7061C4.94615 48.907 3.8312 46.9758 2.92669 44.9443C2.02218 42.9127 1.3331 40.7919 0.870745 38.6167C0.408388 36.4415 0.175299 34.2238 0.175299 32C0.175299 29.7762 0.408388 27.5585 0.870744 25.3833C1.3331 23.2081 2.02218 21.0873 2.92669 19.0557C3.8312 17.0242 4.94615 15.093 6.25328 13.2939C7.5604 11.4948 9.0525 9.83766 10.7051 8.34964C12.3577 6.86162 14.1618 5.55091 16.0877 4.439C18.0135 3.32709 20.0507 2.42011 22.1656 1.73291C24.2806 1.04571 26.4618 0.58209 28.6734 0.349637Z"
        fill="#EEF4FB"
      />
      <path
        d="M36 41V39C36 37.9391 35.5786 36.9217 34.8284 36.1716C34.0783 35.4214 33.0609 35 32 35H25C23.9391 35 22.9217 35.4214 22.1716 36.1716C21.4214 36.9217 21 37.9391 21 39V41"
        stroke="#113469"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5 31C30.7091 31 32.5 29.2091 32.5 27C32.5 24.7909 30.7091 23 28.5 23C26.2909 23 24.5 24.7909 24.5 27C24.5 29.2091 26.2909 31 28.5 31Z"
        stroke="#113469"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M40 28V34" stroke="#113469" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M43 31H37" stroke="#113469" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
