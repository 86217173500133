import React from 'react';

export const SvgIconArrowForward = ({width = 25, height = 25}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5867_70832)">
        <path d="M5.5 12.5039L19.5 12.5039" stroke="#216DE3" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
        <path d="M12.5 5.50391L19.5 12.5039L12.5 19.5039" stroke="#216DE3" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_5867_70832">
          <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
        </clipPath>
      </defs>
    </svg>

  );
}

export default SvgIconArrowForward;