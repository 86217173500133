import React, { FC, useState } from "react"
import { Typography } from "antd"
import { TitleModel, SIZE, FONT_FAMILY } from "../../types/types"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import "./Text.scss"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { NavLink } from "react-router-dom"

/**
 * Used <Text size={SIZE.HEADING_XXL} text={'Text value'} color={COLOR.ROYAL_BLUE_600} type="bold" />
 * @param text Required. Text Value.
 * @param size Required.
 * @param color Optional.
 * @param weight Optional
 * @param href Optional. Just in case of Link.
 * @param innerHtml
 * @param onClick
 * @param style
 * @param maxWidth
 */
const GText: FC<TitleModel> = ({
  text,
  size = SIZE.PARAGRAPH_REGULAR_20,
  color = COLORS_NEW.BLACK_1000,
  weight,
  href,
  linkTo,
  innerHtml,
  onClick,
  style,
  maxWidth,
}) => {
  type FontSizesOptions = {
    [key: string]: {
      size: string,
      family: string,
      weight: number,
      lineHeight: string,
      letterSpacing?: string,
    },
  }

  const breakPoint = useBreakpoint();

  const arrSize: FontSizesOptions = {
    [SIZE.HEADING_XXL]: {
      size: "44px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "52.8px",
      letterSpacing: "-1px"
    },
    [SIZE.HEADING_XL]: {
      size: breakPoint.xs ? "32px" : "40px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: breakPoint.xs ? "41.6px" : "48px",
      letterSpacing: "-1px",
    },
    [SIZE.HEADING_LG]: {
      size: breakPoint.xs ? "28px" : "36px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: breakPoint.xs ? "36.4px" : "46.8px",
      letterSpacing: "-1px",
    },
    [SIZE.HEADING_MDLG]: {
      size: breakPoint.xs ? "28px" : "32px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: breakPoint.xs ? "36.4px" : "41.6px",
      letterSpacing: "-1px",
    },
    [SIZE.HEADING_MD]: {
      size: breakPoint.xs ? "25px" : "28px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: breakPoint.xs ? "32.5px" : "36.4px",
      letterSpacing: "-1px",
    },
    [SIZE.HEADING_SM]: {
      size: "25px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "32.5px",
      letterSpacing: "-1px",
    },
    [SIZE.HEADING_XS]: {
      size: "21px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "27.3px",
      letterSpacing: "-1px",
    },
    [SIZE.HEADING_XXS]: {
      size: "18px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "23.4px",
      letterSpacing: "-1px",
    },
    [SIZE.HEADING_XXXS]: {
      size: "14px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "18.2px",
      letterSpacing: "-1px",
    },
    [SIZE.PARAGRAPH_BOLD_44]: {
      size: "44px",
      family: FONT_FAMILY.INTER_REGULAR_400,
      weight: 600,
      lineHeight: "52px",
    },
    [SIZE.PARAGRAPH_SEMI_BOLD_40]: {
      size: "40px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "48px",
    },
    [SIZE.PARAGRAPH_BOLD_40]: {
      size: "40px",
      family: FONT_FAMILY.INTER_REGULAR_400,
      weight: 600,
      lineHeight: "48px",
    },
    [SIZE.PARAGRAPH_REGULAR_35]: {
      size: "35px",
      family: FONT_FAMILY.INTER_REGULAR_400,
      weight: 400,
      lineHeight: "45.5px",
      letterSpacing: "-0.1px",
    },
    [SIZE.PARAGRAPH_BOLD_36]: {
      size: "36px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "46.8px",
    },
    [SIZE.PARAGRAPH_BOLD_32]: {
      size: "32px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "41.6px",
    },
    [SIZE.PARAGRAPH_BOLD_28]: {
      size: "28px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "36.4px",
    },
    [SIZE.PARAGRAPH_BOLD_22]: {
      size: breakPoint.xs ? "20px" : "22px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: breakPoint.xs ? "28px" : "30.8px",
      letterSpacing: "-0.1px",
    },
    [SIZE.PARAGRAPH_REGULAR_20]: {
      size: breakPoint.xs ? "18px" : "20px",
      family: FONT_FAMILY.INTER_REGULAR_400,
      weight: 400,
      lineHeight:breakPoint.xs ? "25.2px" : "28px",
      letterSpacing: "-0.1px",
    },
    [SIZE.PARAGRAPH_BOLD_24]: {
      size: breakPoint.xs ? "22px" : "24px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: breakPoint.xs ? "30.8px" : "33.6px",
      letterSpacing: "-0.1px",
    },
    [SIZE.PARAGRAPH_BOLD_20]: {
      size: breakPoint.xs ? "18px" : "20px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight:breakPoint.xs ? "25.2px" : "28px",
      letterSpacing: "-0.1px",
    },
    [SIZE.PARAGRAPH_REGULAR_18]: {
      size: "18px",
      family: FONT_FAMILY.INTER_REGULAR_400,
      weight: 400,
      lineHeight: "25.2px",
      letterSpacing: "-0.1px",
    },
    [SIZE.PARAGRAPH_BOLD_18]: {
      size: "18px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "25.2px",
      letterSpacing: "-0.1px",
    },
    [SIZE.PARAGRAPH_REGULAR_16]: {
      size: "16px",
      family: FONT_FAMILY.INTER_REGULAR_400,
      weight: 400,
      lineHeight: "22.4px",
      letterSpacing: "-0.1px"
    },
    [SIZE.PARAGRAPH_BOLD_16]: {
      size: "16px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "22.4px",
      letterSpacing: "-0.1px"
    },
    [SIZE.PARAGRAPH_REGULAR_14]: {
      size: "14px",
      family: FONT_FAMILY.INTER_REGULAR_400,
      weight: 400,
      lineHeight: "19.6px",
      letterSpacing: "-0.1px",
    },
    [SIZE.PARAGRAPH_BOLD_14]: {
      size: "14px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "19.6px",
      letterSpacing: "-0.1px",
    },
    [SIZE.PARAGRAPH_REGULAR_12]: {
      size: "12px",
      family: FONT_FAMILY.INTER_REGULAR_400,
      weight: 400,
      lineHeight: "16.8px",
      letterSpacing: "-0.1px",
    },
    [SIZE.PARAGRAPH_BOLD_12]: {
      size: "12px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "16.8px",
      letterSpacing: "-0.1px",
    },
    [SIZE.PARAGRAPH_REGULAR_11]: {
      size: "11px",
      family: FONT_FAMILY.INTER_REGULAR_400,
      weight: 400,
      lineHeight: "15.4px",
      letterSpacing: "-0.1px",
    },
    [SIZE.PARAGRAPH_BOLD_11]: {
      size: "11px",
      family: FONT_FAMILY.INTER_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "15.4px",
      letterSpacing: "-0.1px",
    },
    [SIZE.LINK_REGULAR_14]: {
      size: "14px",
      family: FONT_FAMILY.INTER_REGULAR_400,
      weight: 400,
      lineHeight: "19.6px",
      letterSpacing: "-0.1px",
    },
    [SIZE.LINK_REGULAR_16]: {
      size: "16px",
      family: FONT_FAMILY.INTER_REGULAR_400,
      weight: 400,
      lineHeight: "22.4px",
      letterSpacing: "-0.1px",
    },
    [SIZE.SILKA_SEMI_BOLD_19]: {
      size: "19.08px",
      family: FONT_FAMILY.SILKA_SEMI_BOLD_600,
      weight: 600,
      lineHeight: "41.74px",
      letterSpacing: "-0.1px",
    },
  }

  const isLink = [SIZE.LINK_REGULAR_14, SIZE.LINK_REGULAR_16].includes(size)

  const styles: React.CSSProperties = {
    fontSize: arrSize[size].size,
    fontFamily: arrSize[size].family,
    color: color,
    lineHeight: arrSize[size].lineHeight,
    fontWeight: weight ?? arrSize[size].weight,
    margin: 0,
    letterSpacing: arrSize[size].letterSpacing,
    cursor: isLink ? "pointer" : "",
    ...style
  }

  if(maxWidth) {
    styles.maxWidth = maxWidth
  }

  const parseHtmlText = () => {
    switch (size) {
      case SIZE.HEADING_XXL:
      case SIZE.HEADING_XL:
      case SIZE.HEADING_LG:
      case SIZE.HEADING_MDLG:
      case SIZE.HEADING_MD:
      case SIZE.HEADING_SM:
      case SIZE.HEADING_XS:
      case SIZE.HEADING_XXS:
      case SIZE.HEADING_XXXS: {
        if (innerHtml) {
          return (
              <Typography.Title className={"GText"} onClick={onClick} style={styles}>
                <p dangerouslySetInnerHTML={{ __html: innerHtml }} style={styles} />
              </Typography.Title>
          )
        }
        return <Typography.Title onClick={onClick} style={styles}>{text}</Typography.Title>
      }
      case SIZE.PARAGRAPH_BOLD_44:
      case SIZE.PARAGRAPH_SEMI_BOLD_40:
      case SIZE.PARAGRAPH_BOLD_40:
      case SIZE.PARAGRAPH_REGULAR_35:
      case SIZE.PARAGRAPH_BOLD_36:
      case SIZE.PARAGRAPH_BOLD_32:
      case SIZE.PARAGRAPH_BOLD_28:
      case SIZE.PARAGRAPH_REGULAR_20:
      case SIZE.PARAGRAPH_BOLD_24:
      case SIZE.PARAGRAPH_BOLD_22:
      case SIZE.PARAGRAPH_BOLD_20:
      case SIZE.PARAGRAPH_REGULAR_18:
      case SIZE.PARAGRAPH_BOLD_18:
      case SIZE.PARAGRAPH_REGULAR_16:
      case SIZE.PARAGRAPH_BOLD_16:
      case SIZE.PARAGRAPH_REGULAR_14:
      case SIZE.PARAGRAPH_BOLD_14:
      case SIZE.PARAGRAPH_REGULAR_12:
      case SIZE.PARAGRAPH_REGULAR_11:
      case SIZE.PARAGRAPH_BOLD_12:
      case SIZE.PARAGRAPH_BOLD_11:
      case SIZE.SILKA_SEMI_BOLD_19: {
        if (innerHtml) {
          return (
            <Typography.Paragraph className={"GText"} onClick={onClick} style={styles}>
              <p dangerouslySetInnerHTML={{ __html: innerHtml }} style={styles} />
            </Typography.Paragraph>
          )
        }
        return <Typography.Paragraph  onClick={onClick} style={styles}>{text}</Typography.Paragraph>
      }
      case SIZE.LINK_REGULAR_14:
      case SIZE.LINK_REGULAR_16: {
        return (
          
          href ? <Typography.Link onClick={onClick} style={styles} href={href}>
            {text}
          </Typography.Link> 
          : linkTo ?
          <NavLink to={linkTo}><Typography.Paragraph  onClick={onClick} style={styles}>{text}</Typography.Paragraph></NavLink>
          : 
          <Typography.Paragraph  onClick={onClick} style={styles}>{text}</Typography.Paragraph>
        )
      }
      default: {
        return <>Unsupported</>
      }
    }
  }

  return parseHtmlText()
}

export default GText
