import IPersistenceHandler from "../IPersistenceHandler";
import IPersistenceRepository from "../IPersistenceRepository";
import SessionStorageHandler from "../SessionStorageHandler";

abstract class BaseSessionStorageRepository implements IPersistenceRepository {
    private _handler: IPersistenceHandler;
    private readonly _key: string;

    protected constructor() {
        this._key = this.getKeyName();
        this._handler = new SessionStorageHandler();
    }

    exist(): boolean {
        return this._handler.has(this._key);
    }

    getValue(): string | null {
        return this._handler.get(this._key) ?? null;
    }

    setValue(value: string, defaultValue?: string | null): void {
        return this._handler.set(this._key, value ?? defaultValue);
    }

    abstract getKeyName(): string;

    removeItem(): void {
        return this._handler.remove(this._key);
    }
}

export default BaseSessionStorageRepository;
