import React, {Suspense} from "react";
import "./index.less"
import "../../styles/pages/products-page.less"
import {useSelector} from "react-redux";
import Spinner from "../../components/spiner/Spinner";
import GMyApplicationCard from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/MyApplicationCard/MyApplicationCard";
import MyApplicationTeaserPage from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/MyApplicationTeaserPage/MyApplicationTeaserPage";
import GText from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Text/Text";
import {SIZE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import {COLORS_NEW} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";
import { GeneralText } from "@dash/gov-plus__front-end__form/src/shared/text/Text";
import { useApplications } from "../../hooks/useApplications";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const ProductsPage = () => {
  const {userData} = useSelector(({auth}) => auth);
  const {allProducts, allProductsLoader,anotherGovProducts, webFlowData} = useSelector(({allProducts}) => allProducts);
  const breakpoint = useBreakpoint()
  const {filteredProducts} = useApplications()
  const data = new URLSearchParams(window.location.search)

  const subheader = breakpoint.md ? GeneralText.MyApplicationsSubheader : GeneralText.MyApplicationsSubheaderMobile
  const headerSize = breakpoint.md ? SIZE.HEADING_XL : SIZE.HEADING_MDLG
  const subheaderSize = breakpoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16
  return (
    <div className={"cards-container"}>
      {allProductsLoader
          ? <Spinner/>
          : <>
          <section className={"cards-header"}>
            <GText text={'My applications'} size={headerSize} color={COLORS_NEW.BLACK_1000}/>
            <div className='page-desc'>
              <GText 
                text={subheader}
                size={subheaderSize} 
                color={COLORS_NEW.BLACK_500}
                maxWidth={breakpoint.md ? 520 : 335}
              />
            </div>
          </section>

            {!Object.keys(filteredProducts).length
                ? <div className={"cards-container_toNewAppRouterButton"}>
                    <MyApplicationTeaserPage/>
                </div>
                : <div className="cards-container_services-box">
                  {Object.keys(filteredProducts).map((id, index) => {
                    return (
                        <Suspense fallback={<Spinner/>} key={id}>
                          <div className={id === data.get("newProductId") ? "animateNewProduct" : null}>
                            <GMyApplicationCard
                                date={filteredProducts[id].date_of_apply || filteredProducts[id].dateofapply}
                                title={filteredProducts[id].product_name}
                                imageType={filteredProducts[id].product_name}
                                productId={id}
                                user={userData}
                                allProducts ={allProducts}
                                anotherGovProducts ={anotherGovProducts}
                                webFlowData ={webFlowData}
                                completedPercentage={filteredProducts[id].completion}
                            />
                          </div>
                        </Suspense>
                    )
                  })}
                </div>
            }
          </> }

    </div>
  )
}

export default ProductsPage
