import React, {FC} from "react";
import GText from "../../atoms/Text/Text";
import {PROGRESS_BAR_ORIENTATION, SIZE} from "../../types/types";
import {COLORS_NEW} from "../../types/Colors";
import moment from "moment";
import GButton from "../Button/GButton";
import "./ApplicationCard.scss"
import ApplicationCardTemplate from "../../templates/ApplicationCardTemplate/ApplicationCardTemplate";
import GProgressBar from "../ProgressBar/ProgressBar";
import {GeneralText} from "../../../../shared/text/Text";
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes";
import ApplicationCardTemplateMobile from "../../templates/ApplicationCardTemplate/ApplicationCardTemplateMobile";

export enum ApplicationCardContentAlign {
    Center = "center", Left = "left"
}

export type ApplicationCardProps = {
    dateOfApply: string,
    solution: string,
    service: string,
    productImage: string,
    customerName: string,
    hasCheckStatus?: boolean,
    checkStatusClick?: React.MouseEventHandler<HTMLElement>;
    contentAlign: ApplicationCardContentAlign,
    completedPercentage: number
}

const GApplicationCard: FC<ApplicationCardProps> =
    ({
         dateOfApply,
         contentAlign,
         checkStatusClick,
         hasCheckStatus = false,
         service,
         customerName,
         productImage,
         solution,
         completedPercentage = 0,
     }) => {
        const applicationCompleted = completedPercentage === 100
        return(
        <>
            {window.innerWidth > 599 ?
                <ApplicationCardTemplate
                    image={productImage}
                    productName={solution}
                    iconType={applicationCompleted ? ICONS_SVG_TYPE.CHECK_CIRCLE : ''}
                    iconBackground={COLORS_NEW.GREEN_700}
                    iconColor={"white"}
                >
                    <GProgressBar
                        value={completedPercentage}
                        orientation={PROGRESS_BAR_ORIENTATION.LINEAR}
                        showIcon={false}
                        color={COLORS_NEW.GREEN_600}
                    />
                    <GText text={`Submission date: ${moment(dateOfApply).format("LL")}`} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
                    {hasCheckStatus && (
                        <div className={`GMyApplicationCard__Body__CheckStatusButton`}>
                            <GButton
                                text={applicationCompleted ? GeneralText.ApplicationCompleted : GeneralText.TrackApplication}
                                textStyle={{
                                    color: applicationCompleted ? COLORS_NEW.GREEN_700 : "white"
                                }}
                                textSize={SIZE.PARAGRAPH_BOLD_16}
                                size={"large"}
                                click={checkStatusClick}
                                style={{
                                    backgroundColor: applicationCompleted ? COLORS_NEW.GREEN_100 : COLORS_NEW.ROYAL_BLUE_700,
                                    height: 44
                                }}
                                iconType={applicationCompleted ? ICONS_SVG_TYPE.CHECK : ICONS_SVG_TYPE.ARROW_FORWARD}
                            />
                        </div>
                    )}
                </ApplicationCardTemplate>
                :
                <ApplicationCardTemplateMobile 
                    image={productImage}
                    productName={solution}
                    iconType={applicationCompleted ? ICONS_SVG_TYPE.CHECK_CIRCLE : ''}
                    iconBackground={COLORS_NEW.GREEN_700}
                    iconColor={"white"}
                    applicationText={`Submission date: <br> ${moment(dateOfApply).format("LL")}`}
                >
                    <GProgressBar
                        value={completedPercentage}
                        orientation={PROGRESS_BAR_ORIENTATION.LINEAR}
                        showIcon={false}
                        color={COLORS_NEW.GREEN_600}
                    />
                    {hasCheckStatus && (
                        <div className={`GMyApplicationCard__Body__CheckStatusButton`}>
                            <GButton
                                text={applicationCompleted ? GeneralText.ApplicationCompleted : GeneralText.TrackApplication}
                                textStyle={{
                                    color: applicationCompleted ? COLORS_NEW.GREEN_700 : "white"
                                }}
                                textSize={SIZE.PARAGRAPH_BOLD_16}
                                size={"large"}
                                click={checkStatusClick}
                                style={{
                                    backgroundColor: applicationCompleted ? COLORS_NEW.GREEN_100 : COLORS_NEW.ROYAL_BLUE_700,
                                    height: 44,
                                }}
                                iconType={applicationCompleted ? ICONS_SVG_TYPE.CHECK : ICONS_SVG_TYPE.ARROW_FORWARD}
                            />
                        </div>
                    )}
                </ApplicationCardTemplateMobile>
            }
            </>
        )

    }


export default GApplicationCard


