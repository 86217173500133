import SubscriptionCancelled from "./SubscriptionCancelled";
import IEvent from "../IEvent";
import BaseBuilder from "../BaseBuilder";

export default class SubscriptionCancelledBuilder extends BaseBuilder {
    private _subscriptionId: string | undefined;
    private _subscriptionExternalId: string | undefined;
    private _subscriptionPlan: string | undefined;
    private _subscriptionFrequency: string | undefined;
    private _subscriptionPrice: string | undefined;
    private _subscriptionStatus: string | undefined;
    private _subscriptionStartDate: string | undefined;
    private _subscriptionCancellationReason: string | undefined;
    private _subscriptionCancellationChannel: string | undefined;

    /**
     * Set subscription id
     * @param subscriptionId
     * @returns {SubscriptionCancelledBuilder}
     */
    setSubscriptionId(subscriptionId: string | undefined) {
        this._subscriptionId = subscriptionId;
        return this;
    }

    setSubscriptionExternalId(value: string | undefined) {
        this._subscriptionExternalId = value;
        return this;
    }

    setSubscriptionPlan(value: string | undefined) {
        this._subscriptionPlan = value;
        return this;
    }

    setSubscriptionFrequency(value: string | undefined) {
        this._subscriptionFrequency = value;
        return this;
    }

    setSubscriptionPrice(value: string | undefined) {
        this._subscriptionPrice = value;
        return this;
    }

    setSubscriptionStatus(value: string | undefined) {
        this._subscriptionStatus = value;
        return this;
    }

    setSubscriptionStartDate(value: string | undefined) {
        this._subscriptionStartDate = value;
        return this;
    }

    setSubscriptionCancellationReason(value: string | undefined) {
        this._subscriptionCancellationReason = value;
        return this;
    }

    setSubscriptionCancellationChannel(value: string | undefined) {
        this._subscriptionCancellationChannel = value;
        return this;
    }

    /**
     * Set ip address
     * @param ipAddress
     * @returns {SubscriptionCancelledBuilder}
     */
    setIpAddress(ipAddress: string | undefined) {
        this._ipAddress = ipAddress;
        return this;
    }

    /**
     * Build
     * @returns {IEvent}
     * @description This method is used to build
     */
    build(): IEvent {
        return new SubscriptionCancelled(
            this._subscriptionId,
            this._ipAddress,
            this._subscriptionExternalId,
            this._subscriptionPlan,
            this._subscriptionFrequency,
            this._subscriptionPrice,
            this._subscriptionStatus,
            this._subscriptionStartDate,
            this._subscriptionCancellationReason,
            this._subscriptionCancellationChannel
        );
    }
}
