import React, {useEffect, useRef, useState} from "react";
import './style.less'
import {Route, Switch, useHistory, Redirect} from "react-router-dom";
import AccountSettingsPage from "./AccountSettingsPage";
import asyncComponent from "../util/asyncComponent";
import ProductsPage from "./ProductsPage";
import OffersPage from "./OffersPage";
import EditNamePage from "./AccountSettingsPage/EditNamePage";
import EditEmailPage from "./AccountSettingsPage/EditEmailPage";
import EditPhonePage from "./AccountSettingsPage/EditPhonePage";
import EditPasswordPage from "./AccountSettingsPage/EditPasswordPage";
import ChangePayment from "./BillingSettings/ChangePayment";
import CheckFormPage from "./Check Forms";
import CheckStatus from "./CheckStatusPage";
import PostalStatusPage from "./PostalStatusPage";
import {useDispatch, useSelector} from "react-redux";
import FormApp from "./FormApp";
import {FormsAppContainer} from "../components/FormsAppContainer";
import GFooter from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Footer/Footer";
import InsuranceMainPageWrapper from "@dash/gov-plus__front-end__form/src/Pages/Insurance/InsuranceMainPageWrapper";
import RenewalMainPageWrapper from "@dash/gov-plus__front-end__form/src/Pages/Renewal/RenewalMainPageWrapper";
import {saveUserDataFlattern, setRetargetingLink, setSelectedFormId, showModalsType} from "../appRedux/actions";
import CustomModal from "../components/CustomModal/CustomModal";
import ModalSwitcher from "./modalSwitcher";
import ReferFriendPage from "./ReferFriend";
import TestPageForCheckDesign from "./TestPageForCheckDesign/TestPageForCheckDesign";
import {FormsKeys} from "@dash/gov-plus__front-end__form/src/util/FormsKeys";
import {ProductsIds} from "@dash/gov-plus__front-end__form/src/util/ProductsIds";
import {UrlPaths} from "@dash/gov-plus__front-end__form/src/util/UrlPaths";
import {EntryPointFlows} from "@dash/gov-plus__front-end__form/src/util/EntryPointFlows";
import {getUrlParams, parseObjectToString} from "../util/helper";
import ForBreadcrumbsRepository from "@dash/gov-plus__front-end__form/src/persistence/local/ForBreadcrumbsRepository";
import ToApplyFormIdRepository from "@dash/gov-plus__front-end__form/src/persistence/session/ToApplyFormIdRepository";
import ToApplyServiceIdRepository from "@dash/gov-plus__front-end__form/src/persistence/session/ToApplyServiceIdRepository";
import TestUpsellPage from "./TestUpsellPage";
import BillingSettingsPage from "@dash/gov-plus__front-end__form/src/Pages/BillingSettings/BillingSettingsPage";
import InsuranceDocumentsCancel
  from "@dash/gov-plus__front-end__form/src/Pages/Insurance/InsuranceDocumentsCancel/InsuranceDocumentsCancel";
import GCancellationReason
  from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/organisms/CancellationReason/GCancellationReason";
import RenewalDocumentsCancel
  from "@dash/gov-plus__front-end__form/src/Pages/Renewal/RenewalDocumentsCancel/RenewalDocumentsCancel";
import SubscriptionCancelApplications from "./SubscriptionCancelApplications/SubscriptionCancelApplications";
import GCanceledSubscription
  from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/CanceledSubscription/GCanceledSubscription";
  import VerificationSuccess
  from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/VerificationSuccess/VerificationSuccess";
import Documents
  from "@dash/gov-plus__front-end__form/src/Pages/Documents/Documents";
import ApplicationIdRepository
  from "@dash/gov-plus__front-end__form/src/persistence/session/ApplicationIdRepository";
import {getParams} from "../assets/helpers/getParams";
import { HistoryProvider } from "../context/HistoryContext";
import AlertMessagesPage
  from "@dash/gov-plus__front-end__form/src/Pages/IdentityProtection/AlertMessages/AlertMessagesPage";
import EmailExposuresPage
  from "@dash/gov-plus__front-end__form/src/Pages/IdentityProtection/EmailExposures/EmailExposuresPage";
import IdentityProtectionMainPageWrapper
  from "@dash/gov-plus__front-end__form/src/Pages/IdentityProtection/IdentityProtectionMainPageWrapper";
  import CreditScoreVerification
  from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/CreditScoreVerification/CreditScoreVerification";
  import IdentityTheftProtectionAgreement
  from "@dash/gov-plus__front-end__form/src/Pages/IdentityProtection/IdentityTheftProtectionAgreement/IdentityTheftProtectionAgreement";
  import IdentityProtectionCancel
  from "@dash/gov-plus__front-end__form/src/Pages/IdentityProtection/IdentityProtectionCancel/IdentityProtectionCancel";
  import IdentityProtectionSupport
  from "@dash/gov-plus__front-end__form/src/Pages/IdentityProtection/IdentityProtectionSupport/IdentityProtectionSupport";


const FormsApp = () => {
  const dispatch = useDispatch();
  const mainContentRef = useRef();
  const history = useHistory();
  const {authToken} = useSelector(({auth}) => auth);
  const {showModalType} = useSelector(({settings}) => settings);
  const {userSelectedFormId} = useSelector(({allProducts}) => allProducts);
  const {duplicateApplications, duplicateApplicationLoader} = useSelector(({allProducts}) => allProducts);
  const webFlowServiceData = JSON.parse(localStorage.getItem("webFlowServicesData"))
  const paramsStr = parseObjectToString(getUrlParams(window.location.href))
  const isUserHasDuplicateApplication = Object.keys(duplicateApplications)

  useEffect(() => {
    if (authToken !== null) {
      dispatch(saveUserDataFlattern())
    }
  }, [dispatch])

  useEffect(() => {
    if (authToken !== null && duplicateApplicationLoader === false) {
      if (userSelectedFormId && !isUserHasDuplicateApplication.includes(userSelectedFormId)) {
        dispatch(setRetargetingLink(userSelectedFormId));

        ForBreadcrumbsRepository.getInstance().setValue(JSON.stringify({
          headerText: `${webFlowServiceData[userSelectedFormId]?.title}`,
          value: webFlowServiceData[userSelectedFormId]?.text
        }));

        ApplicationIdRepository.getInstance().removeItem();
        history.push(`/forms/?formId=${userSelectedFormId}&indexCurrentStep=0${paramsStr !== "" ? "&" + paramsStr: ""}`);
      } else {
        history.push("/formsApp");
        dispatch(showModalsType('duplicateApplication'));
      }
    } else if (authToken !== null) {
      if (userSelectedFormId) {
        dispatch(setRetargetingLink(userSelectedFormId));
        ApplicationIdRepository.getInstance().removeItem();
        history.push(`/forms/?formId=${userSelectedFormId}&indexCurrentStep=0${paramsStr !== "" ? "&" + paramsStr: ""}`);
        const headerBreadcrubs = setTimeout(() => {
          if(webFlowServiceData) {
            localStorage.setItem("forBreadcrumbs", JSON.stringify({
              headerText: `${webFlowServiceData[userSelectedFormId]?.title}`,
              value: webFlowServiceData[userSelectedFormId]?.text
            }))
          }
        }, 500)
        return ()=> clearTimeout(headerBreadcrubs)
      }
    }
  }, [authToken, userSelectedFormId])

  useEffect(() => {
    const params = getParams(window.location.search, ["formId"]);
    if(!duplicateApplicationLoader && params?.formId) {
      if (Object.keys(duplicateApplications).includes(params.formId)) {
        dispatch(setSelectedFormId(''))
        history.push("/formsApp");
        dispatch(showModalsType('duplicateApplication'))
      }
    }
  }, [duplicateApplicationLoader])

  /**
   * As the Glo url use /signup as the entry point we add this function here
   * that takes url params for Glo form and persist them locally.
   */
  function persistServiceIdForGloFlow() {
    if(history.location.pathname === UrlPaths.SignUp) {
      let formId = new URLSearchParams(window.location.search).get(FormsKeys.SessionFormId)
      const toApplyFormId = new URLSearchParams(window.location.search).get(FormsKeys.ToApplyFormId)
      const toApplyServiceId = new URLSearchParams(window.location.search).get(FormsKeys.ToApplyServiceId)

      if (formId === ProductsIds.GloFormId) {
        if(toApplyFormId !== null && toApplyServiceId !== null) {
          ToApplyFormIdRepository.getInstance().setValue(toApplyFormId);
          ToApplyServiceIdRepository.getInstance().setValue(toApplyServiceId);

          sessionStorage.setItem(EntryPointFlows.EntryPoint, EntryPointFlows.GloEntryPoint)
        } else {
          sessionStorage.setItem(EntryPointFlows.EntryPoint, EntryPointFlows.GloEntryPoint)
        }
      }
    }
  }

  useEffect(() => {
    persistServiceIdForGloFlow();
  }, [history.location.pathname])
  
  return (
    <>
      <div className={"mainContentContainer"} ref={mainContentRef}>
        <div className="gx-main-content-wrapper">

          <CustomModal
            nodeModalChildren={<ModalSwitcher modalType={showModalType}/>}
            showModalStatus={showModalType.length}
          />
          <HistoryProvider>

          <Switch>
            {authToken !== null && <Redirect from={'/signup'} to={{
              pathname: "/formsApp",
              search: `${paramsStr !== "" ? "?" + paramsStr : ""}`
            }}/>}

            <Route path={'/formsApp'} render={() => <FormApp/>}/>
            <Route path={'/signup'} render={() => <FormApp/>}/>
            <Route exact path={'/forms'} component={FormsAppContainer}/>
            <Route exact path={'/forms/*'} component={FormsAppContainer}/>
            <Route exact path={'/myIdentity'} component={IdentityProtectionMainPageWrapper}/>
          <Route exact path={'/alertMessages'} component={AlertMessagesPage}/>
          <Route exact path={'/myEmailExposures'} component={EmailExposuresPage}/>
          <Route exact path={'/identityTheftProtectionPolicy'} component={IdentityTheftProtectionAgreement}/>
          <Route exact path={'/myIdentity/userVerification'} component={CreditScoreVerification}/>
          <Route exact path={'/verificationSuccess'} component={VerificationSuccess}/>
          <Route exact path={'/'} component={Documents}/>
          <Route exact path={'/myInsuredDocuments'} component={InsuranceMainPageWrapper}/>
            <Route exact path={'/myAutomaticRenewals'} component={RenewalMainPageWrapper}/>
            <Route exact path={'/SubscriptionCancelApplications'} component={SubscriptionCancelApplications}/>
            <Route exact path={'/SubscriptionCancelInsurance'} component={InsuranceDocumentsCancel}/>
            <Route exact path={'/SubscriptionCancelRenewal'} component={RenewalDocumentsCancel}/>
            <Route exact path={'/SubscriptionCancelIdentityProtection'} component={IdentityProtectionCancel}/>
          <Route exact path={'/identityProtectionSupport'} component={IdentityProtectionSupport}/>
          <Route exact path={'/SubscriptionCancelReason'} component={GCancellationReason}/>
            <Route exact path={'/SubscriptionCancelled'} component={GCanceledSubscription}/>
            <Route exact path='/products' component={ProductsPage}/>
            <Route exact path='/products/productInfo' component={ProductsPage}/>
            <Route exact path='/products/productInfo/:id' render={() => <CheckStatus/>}/>
            <Route exact path='/products/productInfo/postalstatus/:id' render={() => <PostalStatusPage/>}/>

            <Route exact path='/offers' component={OffersPage}/>
            <Route exact path='/check_forms' component={CheckFormPage}/>
            <Route exact path='/referFriend' component={ReferFriendPage}/>

            <Route exact path={"/account-settings"} render={() => <AccountSettingsPage/>}/>
            <Route exact path='/account-settings/name' render={() => <EditNamePage/>}/>
            <Route exact path='/account-settings/email-edit' component={EditEmailPage}/>
            <Route exact path='/account-settings/phone' component={EditPhonePage}/>
            <Route exact path='/account-settings/password' component={EditPasswordPage}/>

            <Route exact path='/billing-settings' component={BillingSettingsPage}/>
            <Route exact path='/billing-settings/change_payment' component={ChangePayment}/>

            <Route exact path='/TestPageForCheckDesign' component={TestPageForCheckDesign}/>

            <Route path={"/notifications"} component={asyncComponent(() => import('./NotificationsPage'))}/>
            <Route path={"/products"} component={asyncComponent(() => import('./ProductsPage'))}/>
            <Route path={`/edit-password`}
                  component={asyncComponent(() => import('./AccountSettingsPage/EditPasswordPage'))}/>
            <Route path={`/edit-name`} component={asyncComponent(() => import('./AccountSettingsPage/EditNamePage'))}/>
            <Route path={"/email-edit"}
                  component={asyncComponent(() => import('./AccountSettingsPage/EditEmailPage'))}/>
            <Route path={"/phone-edit"}
                  component={asyncComponent(() => import('./AccountSettingsPage/EditPhonePage'))}/>
            <Route exact path={'/test-stripe'} component={TestUpsellPage}/>
          </Switch>

          </HistoryProvider>

        </div>
        <div className={"footerContainer"}>
          <GFooter />
        </div>
      </div>
      
    </>
  )
};

export default FormsApp;
