/* eslint-disable max-len */
import { Form, Input } from "antd"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { getCashState, setAnswerCheckStatus } from "../../../Redux/store/cash/cash.action"
import FormTools from "../../FormComponents/FormTools/FormTools"
import errorValidation from "../../../assets/image/errorValidation.svg"
import successValidation from "../../../assets/image/successValidation.svg"

type CheckStatusFormComponentProps = {
  answerError: string,
  dispatch: any,
}

const CheckStatusFormComponent: React.FunctionComponent<CheckStatusFormComponentProps> = ({
  answerError,
  dispatch,
}) => {
  const [form] = Form.useForm()

  const showStatus = answerError ? "on" : "off"

  const [validationIdStatus, setValidationIdStatus] = useState("off")
  const [validationEmailStatus, setValidationEmailStatus] = useState("off")

  const getValidationOn = () => {
    setValidationIdStatus("on")
    setValidationEmailStatus("on")
  }

  const handlerOnFocus = (name: string) => {
    if (name === "transaction_id") {
      setValidationIdStatus(`off`)
    } else if (name === "email") {
      setValidationEmailStatus(`off`)
    }
  }

  const handlerOnBlur = (name: string) => {
    if (name === "transaction_id") {
      setValidationIdStatus(`on`)
    } else if (name === "email") {
      setValidationEmailStatus(`on`)
    }
  }

  //  for switch error/success icons
  const [idErrorState, setIdErrorState] = useState("")
  const [mailErrorState, setEmailErrorState] = useState("")

  const getErrorsState = () => {
    const idContainer = document.querySelector(".check-status__id-container")
    const idError = idContainer && idContainer.querySelector(".ant-form-item-has-error")
    const idSuccess = idContainer && idContainer.querySelector(".ant-form-item-has-success")

    const emailContainer = document.querySelector(".check-status__email-container")
    const emailError = emailContainer && emailContainer.querySelector(".ant-form-item-has-error")
    const emailSuccess = emailContainer && emailContainer.querySelector(".ant-form-item-has-success")

    if (idError) {
      setIdErrorState("error")
    }
    if (idSuccess) {
      setIdErrorState("success")
    }

    if (emailError) {
      setEmailErrorState("error")
    }
    if (emailSuccess) {
      setEmailErrorState("success")
    }
  }

  const getErrorsByClickButton = () => {
    getErrorsState()
    getValidationOn()
  }

  useEffect(() => {
    getErrorsState()
  }, [validationIdStatus, validationEmailStatus])

  const onFinish = (values: {}) => {
    dispatch(getCashState(values))
    dispatch(setAnswerCheckStatus(""))
  }

  const onFinishFailed = (errorInfo: {} | any) => {
    // console.log("Failed:", errorInfo)

    getErrorsByClickButton()
  }

  return (
    <Form
      className={"check-status__form-container"}
      form={form}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className={`check-status__fields-container`}>
        <label className={`check-status__id-container check-status__id-container_${validationIdStatus}`}>
          <Form.Item
            label="Client ID"
            name="transaction_id"
            rules={[
              { required: true, message: "Required" },
              { pattern: new RegExp("^[0-9]+$"), message: "Number required" },
            ]}
          >
            <Input
              className={`check-status__id-input check-status__id-input_${validationIdStatus}`}
              onFocus={() => {
                handlerOnFocus("transaction_id")
              }}
              onBlur={() => {
                handlerOnBlur("transaction_id")
              }}
            />
          </Form.Item>
          {validationIdStatus === "on" && idErrorState === "error" && (
            <div className={`validation-error-icon__container`}>
              <img src={errorValidation} alt={"error"} />
            </div>
          )}
          {validationIdStatus === "on" && idErrorState === "success" && (
            <div className={`validation-success-icon__container`}>
              <img src={successValidation} alt={"success"} />
            </div>
          )}
        </label>

        <label className={`check-status__email-container check-status__email-container_${validationEmailStatus}`}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Require" },
              {
                pattern: new RegExp("^\\S+@\\S+\\.\\S+$"),
                message: "invalid Email",
              },
            ]}
            className={`check-status__email-container_${validationEmailStatus} check-status__email-container_${validationEmailStatus}`}
          >
            <Input
              className={`check-status__email-input_${validationEmailStatus}`}
              onFocus={(e: any) => {
                handlerOnFocus("email")
              }}
              onBlur={(e: any) => {
                handlerOnBlur("email")
              }}
            />
          </Form.Item>
          {validationEmailStatus === "on" && mailErrorState === "error" && (
            <div className={`validation-error-icon__container`}>
              <img src={errorValidation} alt={"error"} />
            </div>
          )}
          {validationEmailStatus === "on" && mailErrorState === "success" && (
            <div className={`validation-success-icon__container`}>
              <img src={successValidation} alt={"success"} />
            </div>
          )}
        </label>
        <div
          className={`error-signature__container error-signature__container_${showStatus} check-status__container_${"error"}`}
        >
          <p className={`error-signature__text check-status__text`}>{answerError}</p>
        </div>
      </div>
      <FormTools
        arraySteps={[]}
        indexCurrentStep={0}
        handleDecrementClick={() => null}
        type={"checkStatus"}
        reCaptcha={null}
        buttonNext={true}
        nameStep={"Check Status"}
      />
    </Form>
  )
}

const mapStateToProps = (state: any) => ({
  answerError: state.cash.answerError,
})

export default connect(mapStateToProps)(CheckStatusFormComponent)
