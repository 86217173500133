/* eslint-disable react-redux/useSelector-prefer-selectors */
import React, { useEffect } from "react"
import "./CreditScoreVerification.scss"
import GSpinner from "../../atoms/Spinner/Spinner"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS_NEW } from "../../types/Colors"
import { useDispatch, useSelector } from "react-redux"
import { verifyUserCreditScores } from "../../../../../../dashboard-govplus-front/src/appRedux/actions"

const CreditScoreVerification = () => {
  const dispatch = useDispatch()

  const { verifyUserCreditScoresLoader, verifyUserCreditScoresData, isUserRedirectedToThankYouPage } = useSelector(
    // @ts-ignore
    (state) => state.identityProtectionData
  )

  useEffect(() => {
    dispatch(verifyUserCreditScores({ result: 1, errorMessage: "" }))
  }, [dispatch])

  return (
    <div className={"CreditScoreVerification"}>
      <div className={"CreditScoreVerification__Header"}>
        <GText text={"Verify your identity"} size={SIZE.PARAGRAPH_BOLD_40} color={COLORS_NEW.BLACK_1000} />
      </div>
      <div
        className={
          verifyUserCreditScoresLoader
            ? "CreditScoreVerification__Content__Loading"
            : "CreditScoreVerification__Content"
        }
      >
        {verifyUserCreditScoresLoader && (
          <div className={"CreditScoreVerification__Content__Overlay"}>
            <div className={"CreditScoreVerification__Content__Spinner"}>
              <GSpinner />
            </div>
          </div>
        )}

        <iframe
          className={
            isUserRedirectedToThankYouPage
              ? "CreditScoreVerification__Content__ThankYouPage"
              : "CreditScoreVerification__Content__Questionnaire"
          }
          src={verifyUserCreditScoresData?.getAuthenticationOnlyResult}
          title={"Credit scores"}
          width={"100%"}
          height={"100%"}
        ></iframe>
      </div>
    </div>
  )
}

export default CreditScoreVerification
