import React from "react";
import {App} from "@dash/gov-plus__front-end__form";
import "./dashboardFromContainer/index.less"
import "./dashboardFromContainer/index.scss"

export const FormsAppContainer = () => {
  return <div className={"dashboardFormContainer"}>
    <App/>
  </div>
}
