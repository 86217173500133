import React, { FC } from "react"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import GText from "../../atoms/Text/Text"
import "./CheckListItem.scss"

type GCheckListItemProps = {
  text: string,
  textSize: string,
  textColor: string,
  bubbleColor: string,
  bubbleWidth: number,
  bubbleHeight: number,
  iconsType: string,
  iconsWidth: number,
  iconsHeight: number,
  iconsColor: string,
}

/**
 * Example of use
 * <GCheckListItem
      text={text}
      textSize={SIZE.PARAGRAPH_16}
      textColor={COLORS.SLATE_GRAY_500}
      bubbleColor={COLORS.GREEN_50}
      bubbleWidth={bubbleWidth}
      bubbleHeight={bubbleHeight}
      iconsType={ICONS_SVG_TYPE.CHECK}
      iconsWidth={18}
      iconsHeight={17}
      iconsColor={COLORS.GREEN_500}/>
 *
 * @param text
 * @param textSize
 * @param textColor
 * @param bubbleColor
 * @param bubbleWidth
 * @param bubbleHeight
 * @param iconsType
 * @param iconsWidth
 * @param iconsHeight
 * @param iconsColor
 * @constructor
 */
const GCheckListItem: FC<GCheckListItemProps> = ({
  text,
  textSize,
  textColor,
  bubbleColor,
  bubbleWidth,
  bubbleHeight,
  iconsType,
  iconsWidth,
  iconsHeight,
  iconsColor,
}) => {
  const getBubbleStyle = (color: string) => {
    const bubbleStyles: React.CSSProperties = {
      width: bubbleWidth,
      height: bubbleHeight,
      backgroundColor: color,
      minWidth: bubbleWidth,
      minHeight: bubbleHeight,
    }
    return bubbleStyles
  }

  return (
    <div className={`GCheckListItem`}>
      <div className={`GCheckListItem__Bubble`} style={getBubbleStyle(bubbleColor)}>
        <GIconsSvgType type={iconsType} width={iconsWidth} height={iconsHeight} color={iconsColor} />
      </div>
      <div className={`GCheckListItem__TextContainer`}>
        <GText text={text} size={textSize} color={textColor} innerHtml={text} />
      </div>
    </div>
  )
}

export default GCheckListItem
