interface getWebFlowDataIconInterface {
  fileId: string;
  url: string;
  alt: string | null;
}

interface getWebFlowDataInterface {
  identifier: string;
  cta: string;
  description: string;
  name: string;
  icon: getWebFlowDataIconInterface;
  "funnel-form-header": string;
  "funnel-form-image": string;
  "seo-description": string;
  "seo-title": string;
}

interface SolutionsData {
  identifier: string;
  illustration: getWebFlowDataIconInterface;
  "illustration-l": getWebFlowDataIconInterface;
  "illustration-m-half": getWebFlowDataIconInterface;
}

export const getWebFlowSolutionData = (solutionsArray: SolutionsData[]) =>
  solutionsArray
    .map(({ identifier, illustration, "illustration-l": illustrationL, "illustration-m-half": illustrationM }) => ({
      identifier,
      illustration,
      illustrationL,
      illustrationM,
    }))
    .reduce((acc, { identifier, ...props }) => {
      return { ...acc, [identifier]: { ...props, identifier } }
    }, {})

export const getWebFlowData = (arrayProducts: getWebFlowDataInterface[]) =>
  arrayProducts
    .map(
      ({
        identifier,
        cta,
        description,
        name,
        icon,
        "funnel-form-header": funnelFormHeader,
        "funnel-form-image": funnelFormImage,
        "seo-description": seoDescription,
        "seo-title": seoTitle,
      }) => ({
        identifier,
        cta,
        description,
        name,
        icon,
        funnelFormHeader,
        funnelFormImage,
        seoDescription,
        seoTitle,
      })
    )
    .reduce((acc, { identifier, ...props }) => {
      return { ...acc, [identifier]: { ...props, identifier } }
    }, {})
