import React, { FC } from "react"
import "./RenderServicesSelected.scss"
import {IBackendService} from "./CategorySelection"
import GTag from "../../../molecules/Tag/GTag"
import { COLORS } from "../../../types/Colors"

type GRenderServicesSelectedProps = {
  servicesSelected: IBackendService[],
  onDeleteService: any,
}

const GRenderServicesSelected: FC<GRenderServicesSelectedProps> = ({ servicesSelected, onDeleteService }) => {
  return (
    <>
      {servicesSelected.length > 0 && (
        <div className={`GRenderServicesSelected`}>
          {servicesSelected.map((item: IBackendService, index) => {
            return (
              <div className={`GRenderServicesSelected__Container`} key={index}>
                <GTag
                  text={item.company_name}
                  colorBackground={COLORS.GREEN_50}
                  colorText={COLORS.GREEN_600}
                  click={() => {
                    onDeleteService(item)
                  }}
                />
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default GRenderServicesSelected
