import React, { FC, useState } from "react"
import "./BankAccountDetailsForm.scss"
import GInputText from "../../../molecules/InputText/InputText"
import { Form } from "antd"
import GText from "../../../atoms/Text/Text"
import GButton from "../../../molecules/Button/GButton"
import { COLORS_NEW } from "../../../types/Colors"
import { SIZE, BUTTON_TYPE } from "../../../types/types"
import InputMask from "react-input-mask"
import {
  FormInput,
  createMaskRule,
  validateAccountNumber,
  validateRoutingNumber,
} from "../../../../../util/IdentityProtectionHelper"

const BankAccountDetailsForm: FC<FormInput> = ({ recordType, handleFormActions }) => {
  const [bankAccountForm] = Form.useForm()

  const [routingNumberError, setRoutingNumberError] = useState("")
  const [accountNumberError, setAccountNumberError] = useState("")

  const handleInputRoutingNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    bankAccountForm.setFieldValue("routingNumber", value)

    if (value.length === 9) {
      setRoutingNumberError("")
    }
  }

  const handleInputAccountNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    bankAccountForm.setFieldValue("accountNumber", value)

    if (value.length >= 5) {
      setAccountNumberError("")
    }
  }

  const getBankRoutingFormLabel = () => {
    return (
      <div className={"BankAccountDetailsForm__Item__Label"}>
        <GText text={"Bank routing number"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
      </div>
    )
  }

  const getBankAccountFormLabel = () => {
    return (
      <div className={"BankAccountDetailsForm__Item__Label"}>
        <GText text={"Bank account number"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
      </div>
    )
  }

  const handleFormSubmit = async () => {
    try {
      const valid = await bankAccountForm.validateFields()
      if (valid) {
        const submittedData = {
          recordType,
          value: `${bankAccountForm.getFieldValue("routingNumber")}|${bankAccountForm.getFieldValue("accountNumber")}`,
        }
        handleFormActions(submittedData)
        setRoutingNumberError("")
        setAccountNumberError("")
      }
    } catch (error: any) {
      console.error(error)
      const errors = error.errorFields
      setRoutingNumberError(errors.find((e: any) => e.name[0] === "routingNumber")?.errors[0] || "")
      setAccountNumberError(errors.find((e: any) => e.name[0] === "accountNumber")?.errors[0] || "")
    }
  }

  return (
    <Form
      layout={"horizontal"}
      form={bankAccountForm}
      className={"BankAccountDetailsForm"}
      initialValues={{ remember: true }}
      name={"basic"}
      onFinish={handleFormSubmit}
    >
      <Form.Item
        className={"BankAccountDetailsForm__Item"}
        label={getBankRoutingFormLabel()}
        name="routingNumber"
        initialValue={""}
        rules={[{ required: true, validator: validateRoutingNumber }]}
        hasFeedback={false}
        help={routingNumberError}
        validateStatus={routingNumberError ? "error" : ""}
      >
        <InputMask mask={createMaskRule(9)} maskPlaceholder={null} onChange={handleInputRoutingNumberChange}>
          <GInputText inputMode={"numeric"} maskedForPrivacy />
        </InputMask>
      </Form.Item>

      <Form.Item
        className={"BankAccountDetailsForm__Item"}
        label={getBankAccountFormLabel()}
        name={"accountNumber"}
        initialValue={""}
        rules={[{ required: true, validator: validateAccountNumber }]}
        hasFeedback={false}
        help={accountNumberError}
        validateStatus={accountNumberError ? "error" : ""}
      >
        <InputMask mask={createMaskRule(64)} maskPlaceholder={null} onChange={handleInputAccountNumberChange}>
          <GInputText inputMode={"numeric"} maskedForPrivacy minLength={5} maxLength={64} />
        </InputMask>
      </Form.Item>

      <div className={"BankAccountDetailsForm__Actions"}>
        <GButton
          click={handleFormSubmit}
          type={BUTTON_TYPE.SECONDARY}
          showIcon={false}
          style={{ height: "44px" }}
          text={"Add"}
        />
      </div>
    </Form>
  )
}

export default BankAccountDetailsForm
