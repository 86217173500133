import React from 'react';
import { SvgType } from '../SvgType';

export const SvgIconAlert = ({width = 25, height = 24}: SvgType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z"
        stroke="#DB7327" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 16V12" stroke="#DB7327" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 8H12.51" stroke="#DB7327" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

