import React from 'react';
import {SvgType} from "../SvgType";

export const SvgMyCreditScores = ({width = 24, height = 24}: SvgType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 14.0004L16 10.0004M3.34 19.0004C2.46222 17.4803 2.00007 15.7558 2 14.0004C1.99993 12.245 2.46195 10.5205 3.33962 9.00022C4.21729 7.47997 5.47967 6.21753 6.99989 5.33981C8.52011 4.46209 10.2446 4 12 4C13.7554 4 15.4799 4.46209 17.0001 5.33981C18.5203 6.21753 19.7827 7.47997 20.6604 9.00022C21.538 10.5205 22.0001 12.245 22 14.0004C21.9999 15.7558 21.5378 17.4803 20.66 19.0004"
        stroke="#6E6E6E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}