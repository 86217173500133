import React, {useEffect} from "react"
import {connect, useSelector} from "react-redux"
import {
    setAddressValidation,
    setStatusQuestionnaireFront,
    setStatusSignature,
    setStatusSignatureOpen,
    submitAdditionalData
} from "../../../../Redux/store/additionalForm/additional.actions"
import SignatureComponent from "../SignatureComponent/SignatureComponent"
import QuestionaryComponent from "../QuestionaryComponent/QuestionaryComponent"
import yesIcon from "../../../../assets/image/bigYes.svg"
import UploadComponent from "../UploadComponent/UploadComponent"
import PassportDetailsComponent from "../PassportDetailsComponent/PassportDetailsComponent"
import {passportDetailsCompleted} from "./renderFunctions/renderFunctions"
import {questionnaireTSABlock} from "../../QuestionnaireTSA/QuestionnaireTSAEntryPoint"
import AddressVerificationFormSelect
    from "../../AdditionalPageComponent/AdditionalPageByType/AddressVerification/AddressVerificationFormSelect"
import AddressVerificationFormFields
    from "../../AdditionalPageComponent/AdditionalPageByType/AddressVerification/AddressVerificationFormFields"
import {RootState} from "../../../../App"
import {additionalSelector} from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import {delayTimer} from "../../../../Pages/FormPage/FormPage"
import {getScrollElementById} from "../../FormBlock/FormBlock"
import GAdditionalStep, {StepVisualStatus} from "../../../atomicDesign/templates/AdditionalStep/AdditionalStep"
import {LoadingState} from "../../../../shared/constans/user-from-view-mode.enum"
import GAdditionalStepUpsell from "../../../atomicDesign/templates/AdditionalStep/AdditionalStepUpsell";
import ProcessingValidationComponent from "../ProcessingValidationComponent/ProcessingValidationComponent"
import ProcessingShipmentComponent from "../ProcessingShipmentComponet/ProcessingShipmentComponent"
import {replaceSolution} from "../../../../util/ProcessingTrackingHelper"
import TrackingCardStepComponent from "../TrackingCardStepComponent/TrackingCardStepComponent"
import GFollowInstructionsCard
    from "../../../atomicDesign/templates/AdditionalStep/StepStatusTracking/shared/FollowInstructionsCard/FollowInstructionsCard"
import {videoLinkFollowInstruction} from "../../../../../../dashboard-govplus-front/src/api/apiHelper"
import ProcessingByGovernmentComponent from "../ProcessingByGovernmentComponent/ProcessingByGovernmentComponent"
import PendingReceivingConfirmationComponent
    from "../PendingReceivingConfirmationComponent/PendingReceivingConfirmationComponent"
import handWithSSN from "../../../../assets/image/hand-with-ssn.webp"
import {getIdNameFromStatuses} from "../../../../util/BlockStep";
import { BlockStepType } from "../../../../util/BlockStepType"
import { BlockStepTypes } from "../../../../Constants/BlockStepTypes"

type BlocksStepsProps = {
    blocksStep: any,
    index: number,
    showStatus: string,
    statusSignature: string,
    statusUpsellStep: string,
    statusQuestionnaireFront: string,
    submitObject: {} | any,
    currentResponse: any,
    loadingButtonState: string,
    appId: string,
    formId: string,
    statusDocuments: string,
    customer: {} | any,
    statusAddress: string,
    statusSignatureBlockOpen: string,
    dispatch: any,
    upsellProcessingInformation: any,
    application_id: string
}

const BlocksSteps: React.FunctionComponent<BlocksStepsProps> = ({
                                                                    blocksStep,
                                                                    index,
                                                                    showStatus,
                                                                    statusSignature,
                                                                    statusUpsellStep,
                                                                    statusQuestionnaireFront,
                                                                    submitObject,
                                                                    currentResponse,
                                                                    loadingButtonState,
                                                                    appId,
                                                                    formId,
                                                                    statusDocuments,
                                                                    customer,
                                                                    statusAddress,
                                                                    statusSignatureBlockOpen,
                                                                    dispatch,
                                                                    upsellProcessingInformation,
                                                                    application_id
                                                                }) => {
    const originData = currentResponse?.verifiedAddress?.original
    const uniqSession = submitObject["unique-section-id"]
    const solutionName = blocksStep.content?.nestBlock?.solutionName ? blocksStep.content?.nestBlock?.solutionName : " "
    const solutionId = blocksStep.content?.nestBlock?.solutionId ? blocksStep.content?.nestBlock?.solutionId : " "
    const handlerAddressValidation = (status: string) => {
        dispatch(setAddressValidation(status))
    }
    const handlerSignature = (status: string) => {
        dispatch(setStatusSignature(status))
        dispatch(setStatusSignatureOpen(status))
        sessionStorage.setItem("statusSignature", status)
    }

    const handlerPreQuestionnaireClick = () => {
        dispatch(setStatusQuestionnaireFront("process"))
        sessionStorage.setItem("statusQuestionnaireFront", "process")
    }

    const getAddressForms = () => {
        if (currentResponse?.verifiedAddress?.error) {
            return (
                <AddressVerificationFormFields
                    forceLoadingAndDisabled={true}
                    blocksStep={blocksStep}
                    statusSignaturePage={statusSignature}
                />
            )
        } else {
            return <AddressVerificationFormSelect addressContent={blocksStep.content}/>
        }
    }

    const {statusSignaturePage} = useSelector<RootState, { statusSignaturePage: string } | undefined>(
        additionalSelector
    ) ?? {statusSignaturePage: ""}

    useEffect(() => {
        delayTimer(300).then(() => getScrollElementById({id: `${statusSignaturePage}_scrollById`}))
    }, [statusSignaturePage])

    function sendConfirmationOfReceipt(): void {
        dispatch(submitAdditionalData({
            confirm_received_document: true,
        }))
    }

    let idNameFromStatuses = getIdNameFromStatuses(blocksStep.status)

    return (
        <div className={`steps-signature-container`} id={`${idNameFromStatuses}${index !== 1 ? "_scrollById" : ""}`}>
            {blocksStep.type === BlockStepTypes.UPSELL && upsellProcessingInformation && upsellProcessingInformation[blocksStep.status] && (
                <GAdditionalStepUpsell
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    formId={formId}
                    uniqSession={uniqSession}
                    blockStatus={blocksStep.status}
                />
            )}

            {blocksStep.type === BlockStepTypes.INVALID_ADDRESS && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusAddress}
                    ctaForm={() => handlerAddressValidation(StepVisualStatus.STATUS_PROCESS)}
                >
                    {getAddressForms()}
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.SIGNATURE && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusSignatureBlockOpen}
                    ctaForm={() => handlerSignature(StepVisualStatus.STATUS_PROCESS)}
                >
                    <SignatureComponent nestBlock={blocksStep.content.nestBlock}/>
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.QUESTIONNAIRE && (
                <GAdditionalStep
                    index={index.toString()}
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusQuestionnaireFront}
                    ctaForm={handlerPreQuestionnaireClick}
                    loadingButton={loadingButtonState === LoadingState.Loading}
                >
                    <QuestionaryComponent blocksStep={blocksStep}/>
                </GAdditionalStep>
            )}

            {(blocksStep.type === BlockStepTypes.UPLOADING_FILE || blocksStep.type === BlockStepTypes.UPLOADING_ADDITIONAL_FILE) && (
                <UploadComponent
                    blocksStep={blocksStep}
                    index={index}
                    showStatus={showStatus}
                    loadingButtonState={loadingButtonState}
                    statusDocuments={statusDocuments}
                    relationship={submitObject["relationship"]}
                    proofOnFlag={submitObject["relationship"] !== "myself"}
                />
            )}
            {blocksStep.type === BlockStepTypes.ADDITIONAL_INFORMATION_STEP && (
                <PassportDetailsComponent blocksStep={blocksStep} statusSignaturePage={""}/>
            )}
            {blocksStep.type === BlockStepTypes.ADDITIONAL_INFORMATION_STEP &&
                passportDetailsCompleted(yesIcon, blocksStep, loadingButtonState, () => {
                })}

            {blocksStep.type === BlockStepTypes.TSA_QUESTIONNAIRE && questionnaireTSABlock({
                index,
                blocksStep,
                statusPhotoUpload: ""
            })}

            {blocksStep.type === BlockStepTypes.PROCESSING_VALIDATION && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                >
                    <ProcessingValidationComponent
                        nameProduct={solutionId}
                        title={solutionName}
                        subtitle={blocksStep.content?.nestBlock?.subheader ? blocksStep.content?.nestBlock?.subheader : " "}
                    />
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.PROCESSING_SHIPMENT && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={replaceSolution(blocksStep.content.information, solutionName)}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                >
                    <ProcessingShipmentComponent
                        estimatedWaitTime={blocksStep.content?.nestBlock?.estimatedWaitTime ? blocksStep.content?.nestBlock?.estimatedWaitTime : " "}
                        solution={solutionName}
                    />
                </GAdditionalStep>
            )}
            {blocksStep.type === BlockStepTypes.TRACKING && (
                <TrackingCardStepComponent
                    blocksStep={blocksStep}
                    solutionName={solutionName}
                    index={index}
                    showStatus={showStatus}
                    applicationId={application_id}
                    replaceInformation={true}
                    toCustomer={blocksStep.status === "tracking_to_customer"}
                />
            )}
            {blocksStep.type === BlockStepTypes.PROCESSING_FOLLOW_INSTRUCTIONS && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={replaceSolution(blocksStep.content.information, solutionName)}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                >
                    <GFollowInstructionsCard videoLink={videoLinkFollowInstruction}/>
                </GAdditionalStep>
            )}
            {blocksStep.type === BlockStepTypes.PROCESSING_BY_GOVERNMENT && (
                <ProcessingByGovernmentComponent
                    blocksStep={blocksStep}
                    solutionName={solutionName}
                    solutionNameToAdditionalStepsCard={solutionName}
                    solutionId={solutionId}
                    index={index}
                    showStatus={showStatus}
                    applicationId={application_id}
                    toCustomer={false}
                />
            )}
            {blocksStep.type === BlockStepTypes.PENDING_RECEIVING_DOCUMENT_CONFIRMATION && (
                <GAdditionalStep
                    title={replaceSolution(blocksStep.content.title, solutionName)}
                    titleDone={replaceSolution(blocksStep.content.titleDone, solutionName)}
                    information={replaceSolution(blocksStep.content.information, solutionName)}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                >
                    <PendingReceivingConfirmationComponent
                        title={solutionName}
                        subtitle={"Estimated delay: 8 weeks"}
                        nameProduct={solutionId}
                        textButton={`I’ve received my ${solutionName}`}
                        onClickBtn={sendConfirmationOfReceipt}
                        loadingBtnState={loadingButtonState}
                        imageLeft={handWithSSN}
                    />
                </GAdditionalStep>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    statusSignature: state.additional.statusSignature,
    statusUpsellStep: state.additional.statusUpsellStep,
    application_id: state.form.application_id,
    statusQuestionnaireFront: state.additional.statusQuestionnaireFront,
    submitObject: state.form.submitObject,
    currentResponse: state.step.currentResponse,
    loadingButtonState: state.loading.loadingButtonState,
    appId: state.form.appId,
    formId: state.form.formId,
    missingInformationStatus: state.form.missingInformationStatus,
    statusDocuments: state.additional.statusDocuments,
    statusAddress: state.additional.statusAddress,
    customer: state.form.customer,
    statusSignatureBlockOpen: state.additional.statusSignatureBlockOpen,
    upsellProcessingInformation: state.additional.upsellProcessingInformation
})

export default connect(mapStateToProps)(BlocksSteps)
