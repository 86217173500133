import React from 'react';

import {ProductsTypes} from '../../constants/productsImages';
import {Image} from "antd";

//desktop
import PAS_desktop from "../../assets/images/Products/desktop/PAS.png";
import COA_desktop from "../../assets/images/Products/desktop/COA.png";
import CER_desktop from "../../assets/images/Products/desktop/CER.png";
import EIN_desktop from "../../assets/images/Products/desktop/EIN.png";
import ITIN_desktop from "../../assets/images/Products/desktop/ITIN.png";
import SSN_desktop from "../../assets/images/Products/desktop/SSN.png";
import ERecords from "../../assets/images/Products/E-Records.png";
import globalEntry from "../../assets/images/Products/Global_Entry.png";
import tsa from "../../assets/images/Products/tsa.png";

import US_POSTAL_SERVICE from "../../assets/images/PostalServicesImage/USPostalService.png";
import FEDEX_SERVICE from "../../assets/images/PostalServicesImage/fedex.png";
import ATT from "../../assets/images/OffersPage/ATT.svg";
import COMCAST from "../../assets/images/OffersPage/COMCAST.png";
import QUICKBOOKS from "../../assets/images/OffersPage/quickbooks.png";

const imageElems = (imageWidth, imageHeight, imageType, preview = false) => {
  return (
    <div style={{
      height: (imageType === ProductsTypes.US_POSTAL_SERVICE || imageType === ProductsTypes.FEDEX_SERVICE) && "175px"
    }}>
      <Image
        style={{
          imageRendering: "-webkit-optimize-contrast",
        }}
        width={imageWidth}
        height={imageHeight}
        src={imageType}
        preview={preview}
      />
    </div>
  )
}

export const ProductsImages = ({type, imageWidth, imageHeight}) => {
  switch (type) {
    case ProductsTypes.ADDRESS_CHANGE_IMAGE:
      return (
        imageElems(imageWidth, imageHeight, COA_desktop)
      )
    case ProductsTypes.COA:
      return (
        imageElems(imageWidth, imageHeight, COA_desktop)
      )
    case ProductsTypes.PASSPORT:
      return (
        imageElems(imageWidth, imageHeight, PAS_desktop)
      )
    case ProductsTypes.PAS:
      return (
        imageElems(imageWidth, imageHeight, PAS_desktop)
      )
    case ProductsTypes.EIN:
      return (
        imageElems(imageWidth, imageHeight, EIN_desktop)
      )
    case ProductsTypes.VITALS:
      return (
        imageElems(imageWidth, imageHeight, CER_desktop)
      )
    case ProductsTypes.E_RECORDS:
      return (
        imageElems(imageWidth, imageHeight, ERecords)
      )
    case ProductsTypes.GLOBAL_ENTRY:
      return (
        imageElems(imageWidth, imageHeight, globalEntry)
      )
    case ProductsTypes.ITIN:
      return (
        imageElems(imageWidth, imageHeight, ITIN_desktop)
      )
    case ProductsTypes.ITI:
      return (
        imageElems(imageWidth, imageHeight, ITIN_desktop)
      )
    case ProductsTypes.ATT:
      return (
        imageElems(imageWidth, imageHeight, ATT)
      )
    case ProductsTypes.COMCAST:
      return (
        imageElems(imageWidth, imageHeight, COMCAST)
      )
    case ProductsTypes.QUICKBOOKS:
      return (
        imageElems(imageWidth, imageHeight, QUICKBOOKS)
      )
    case ProductsTypes.US_POSTAL_SERVICE:
      return (
        imageElems(imageWidth, imageHeight, US_POSTAL_SERVICE)
      )
    case ProductsTypes.FEDEX_SERVICE:
      return (
        imageElems(imageWidth, imageHeight, FEDEX_SERVICE)
      )
    case ProductsTypes.SSN_CARD:
      return (
        imageElems(imageWidth, imageHeight, SSN_desktop)
      )
    case ProductsTypes.SSN:
      return (
        imageElems(imageWidth, imageHeight, SSN_desktop)
      )
    case ProductsTypes.TSA:
      return (
        imageElems(imageWidth, imageHeight, tsa)
      )
    default:
      return <div style={{
        height: "175px"
      }}/>
  }
}

