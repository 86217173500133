import React, { FC } from "react"
import { RecordContentProps } from "../../../../../util/IdentityProtectionHelper"
import GText from "../../../atoms/Text/Text"
import { COLORS_NEW } from "../../../types/Colors"
import { SIZE } from "../../../types/types"

const EmailRecord: FC<RecordContentProps> = ({ ValueToMonitor }) => {
  return <GText text={ValueToMonitor as string} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} />
}

export default EmailRecord
