import ParamsUriBaseDto from "./ParamsUriBaseDto";

/**
 * UTM DTO
 * @class UtmDto
 * @extends {ParamsUriBaseDto}
 * @description UTM DTO
 */
class UtmDto extends ParamsUriBaseDto {
    UTM_SOURCE_KEY = "utm_source";
    UTM_MEDIUM_KEY = "utm_medium";
    UTM_CAMPAIGN_KEY = "utm_campaign";
    UTM_CONTENT_KEY = "utm_content";
    UTM_TERM_KEY = "utm_term";
    GCLID_KEY = "gclid";
    MSCLKID_KEY = "msclkid";
    EVERFLOW_ID = "_ef_transaction_id";
    IMPACT_RADIUS_ID = "irclickid";
    OFFER_COUPON_CODE = "offer"

    private readonly _utmSource: string | null;
    private readonly _utmMedium: string | null;
    private readonly _utmCampaign: string | null;
    private readonly _utmContent: string | null;
    private readonly _utmTerm: string | null;
    private readonly _gclid: string | null;
    private readonly _msclkid: string | null;
    private readonly _everFlowId: string | null;
    private readonly _impactRadiusId: string | null;
    private readonly _offerCouponCode: string | null;

    /**
     * Creates an instance of UtmDto.
     * @memberof UtmDto
     * @description Creates an instance of UtmDto.
     */
    constructor() {
        super();
        this._utmSource = this.getParams(this.UTM_SOURCE_KEY)
        this._utmMedium = this.getParams(this.UTM_MEDIUM_KEY)
        this._utmCampaign = this.getParams(this.UTM_CAMPAIGN_KEY)
        this._utmContent = this.getParams(this.UTM_CONTENT_KEY)
        this._utmTerm = this.getParams(this.UTM_TERM_KEY)
        this._gclid = this.getParams(this.GCLID_KEY)
        this._msclkid = this.getParams(this.MSCLKID_KEY)
        this._everFlowId = this.getParams(this.EVERFLOW_ID);
        this._offerCouponCode = this.getParams(this.OFFER_COUPON_CODE);

        // @ts-ignore
        if(typeof this._everFlowId === "string" && this._everFlowId.length > 0 && typeof EF != "undefined" && EF.getAdvertiserTransactionId != "undefined" && typeof EF.getAdvertiserTransactionId == "function" && EF.getAdvertiserTransactionId(1) != "undefined" && EF.getAdvertiserTransactionId(1) != "") {
            // @ts-ignore
            this._everFlowId = EF.getAdvertiserTransactionId(1);
        }
        this._impactRadiusId = this.getParams(this.IMPACT_RADIUS_ID)
    }

    /**
     * @readonly
     * @type {string | null}
     * @memberof UtmDto
     * @description UTM Source
     */
    get utmSource(): string | null {
        return this._utmSource;
    }

    /**
     * @readonly
     * @type {string | null}
     * @memberof UtmDto
     * @description UTM Medium
     */
    get utmMedium(): string | null {
        return this._utmMedium;
    }

    /**
     * @readonly
     * @type {string | null}
     * @memberof UtmDto
     * @description UTM Campaign
     */
    get utmCampaign(): string | null {
        return this._utmCampaign;
    }

    /**
     * @readonly
     * @type {string | null}
     * @memberof UtmDto
     * @description UTM Content
     */
    get utmContent(): string | null {
        return this._utmContent;
    }

    /**
     * @readonly
     * @type {string | null}
     * @memberof UtmDto
     * @description UTM Terms
     */
    get utmTerm(): string | null {
        return this._utmTerm;
    }

    /**
     * @readonly
     * @type {string | null}
     * @memberof UtmDto
     * @description GCLID
     */
    get gclid(): string | null {
        return this._gclid;
    }

    /**
     * @readonly
     * @type {string | null}
     * @memberof UtmDto
     * @description MSCLKID
     * @description Microsoft Click ID
     */
    get msclkid(): string | null {
        return this._msclkid;
    }

    /**
     * @readonly
     * @type {string | null}
     * @memberof UtmDto
     * @description EverFlow ID
     * @description EverFlow Transaction ID
     */
    get everFlowId(): string | null {
        return this._everFlowId;
    }

    /**
     * @readonly
     * @type {string | null}
     * @memberof UtmDto
     * @description Impact Radius ID
     * @description Impact Radius Click ID
     * @description Impact Radius Transaction ID1
     */
    get impactRadiusId(): string | null {
        return this._impactRadiusId;
    }

    /**
     * @readonly
     * @type {string | null}
     */
    get offerCouponCode(): string | null {
        return this._offerCouponCode;
    }
}

export default UtmDto;
