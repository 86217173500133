import React, { ReactElement } from "react"
import { DropTargetMonitor, useDrop } from "react-dnd"
import { NativeTypes } from "react-dnd-html5-backend"
export type OnDrop = (inputTarget: File) => void

type LayoutDndUploadPhotoProps = {
  onDrop: OnDrop,
  dragIsActive?: ReactElement<any, any>,
  children: any
}

const LayoutDndUploadPhoto: React.FunctionComponent<LayoutDndUploadPhotoProps> = ({
  onDrop,
  dragIsActive,
  children,
}) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop(inputTarget: { files: File[] }) {
      onDrop(inputTarget.files[0])
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  const isActive = canDrop && isOver
  const noNoticeStyles = "1px #666 dashed"
  const noticeStyles = "1px #123468 solid"

  const className = `layout-dnd-upload-box__container ${isActive ? "drag-is-active" : "drag-is-not-active"}`
  return (
    <div className={className} style={{ border: isActive ? noticeStyles : noNoticeStyles }} ref={drop}>
      {isActive && dragIsActive}
      {children}
    </div>
  )
}

export default LayoutDndUploadPhoto
