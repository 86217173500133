/* eslint-disable indent */
import React, { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { StepInterface } from "../../../Redux/InterfacesEntity/step.interface"
import { nextUpsellPage } from "../../../Redux/store/additionalForm/additional.actions"
import { LoadingState } from "../../../shared/constans/user-from-view-mode.enum"
import {getTopScrollPosition} from "../../../Components/FormComponents/FormBlock/FormBlock";
import {IUpsellPageContent} from "../../../Components/atomicDesign/types/types";
import GUpgradeUpsellPage from "../../../Components/atomicDesign/templates/UpgradeUpsellPage/UpgradeUpsellPage";


type FormPageContentProps = {
  currentStep: StepInterface | any,
  upsellInformation: any,
  formId: string,
  loadingButtonState: string,
  uniqSession: string,
}

const UpsellContent: React.FunctionComponent<FormPageContentProps> = ({
  currentStep,
  upsellInformation,
  formId,
  loadingButtonState,
  uniqSession,
}) => {
  const { type = "", hideMain = "" } = currentStep !== null ? currentStep : {}
  const additionalPage = type?.indexOf("additional_page") !== -1
  
  const isUpsell = currentStep?.name === "upsell"
  let isHardCode = false
  let html = ""
  let upsellCss = ""
  let upsellPageValues = ""
  let upsellPageSettings = ""
  if (isUpsell && upsellInformation) {
    html = upsellInformation.upsellPageHtml
    upsellCss = upsellInformation.upsellPageCss
    upsellPageValues = upsellInformation.upsellPageValues
    upsellPageSettings = upsellInformation.upsellPageSettings

    isHardCode = upsellInformation.upsell_page_name === 'GOVPLUS_PREMIUM'
  }


  const dispatch = useDispatch()

  const getUpsellHardCodeContent = (): IUpsellPageContent => {
    return {
      name: upsellInformation.upsell_page_name,
      title: upsellInformation.upsellPageValues.upsell__headline,
      price: upsellInformation.upsell_page_price,
      upsellInformation: upsellInformation
    }
  }

  const upsellUserInteractionClick = (action: any) => {
    if (action === "accept" && upsellInformation.test_mode === true) {
      alert(upsellInformation.test_mode_message)
    }
    let newObj = { ...upsellInformation, action: action, form_id: formId, uniq_session: uniqSession }
    dispatch(nextUpsellPage(newObj))
  }

  const upsellClick = (e: any) => {
    if (e.target.className === "upsell__call-to-action-yes") {
      upsellUserInteractionClick("accept")
    } else if (e.target.className === "upsell__call-to-action-no") {
      upsellUserInteractionClick("cancel")
    }
  }

  useEffect(() => {
    if (!additionalPage && !hideMain && isUpsell) {
      for (let [key, value] of Object.entries(upsellPageSettings)) {
        // @ts-ignore
        const type = value.type
        let element = document.getElementById(key)
        if (type === "image") {
          for (let [key1, value1] of Object.entries(upsellPageValues)) {
            if (key === key1 && element) {
              // @ts-ignore
              element.src = value1
            }
          }
        } else {
          for (let [key1, value1] of Object.entries(upsellPageValues)) {
            if (key === key1 && element) {
              if (value1.trim() !== "") {
                element.innerHTML = value1
              } else {
                element.remove()
              }
            }
          }
        }
      }

      let element = document.getElementsByClassName("upsell__call-to-action-yes")
      if (loadingButtonState === LoadingState.Loading && element.length > 0) {
        element[0].classList.add("ant-btn-loading")
        let tmpHtml = element[0].innerHTML
        element[0].innerHTML =
          '<span style="padding-right-right: 10px" role="img" aria-label="loading" class="anticon anticon-loading anticon-spin" id="anticon-loading"><svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg></span></span>&nbsp&nbsp' +
          tmpHtml
      }
      if (loadingButtonState === LoadingState.Loaded && element.length > 0) {
        element[0].classList.remove("ant-btn-loading")
        let antIconLoading = document.getElementById("anticon-loading")
        antIconLoading?.parentNode?.removeChild(antIconLoading)
      }
    }
  })

  return (
    <>
      {!isHardCode && (
          <div>
            <link rel="stylesheet" type="text/css" href={upsellCss} />
            <div onClick={upsellClick} dangerouslySetInnerHTML={{ __html: html }} />
          </div>
      )}
      {isHardCode && (
          <GUpgradeUpsellPage content={getUpsellHardCodeContent()} formId={formId} uniqSession={uniqSession}></GUpgradeUpsellPage>
      )}

    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    currentStep: state.step.currentStep,
    upsellInformation: state.additional.upsellInformation,
    formId: state.form.formId,
    loadingButtonState: state.loading.loadingButtonState,
  }
}

export default connect(mapStateToProps)(UpsellContent)
