import React from "react"
import { connect } from "react-redux"
import { FileOutlined, GlobalOutlined } from "@ant-design/icons"
import pencil from "../../../../assets/image/vectorSvg.svg"
import yesIcon from "../../../../assets/image/smallYes.svg"
import noIcon from "../../../../assets/image/BigNo.svg"

type ApplicationPackageComponentProps = {
  field?: any,
}

// eslint-disable-next-line max-len
const ApplicationPackageComponent: React.FunctionComponent<ApplicationPackageComponentProps> = ({ field }) => {
  const getIconType = (label: string) => {
    if (label === `issuing`) return <FileOutlined />
    if (label === `locating`) return <GlobalOutlined />
    if (label === `package-generation`)
      return (
        <span className="anticon">
          {" "}
          <img src={pencil} alt="pencil" />{" "}
        </span>
      )
    return <FileOutlined />
  }

  const getApproveIcon = (approve: boolean) => {
    if (approve)
      return (
        <span className="anticon anticon-package">
          {" "}
          <img src={yesIcon} alt="approve" />{" "}
        </span>
      )
    return (
      <span className="anticon anticon-error">
        {" "}
        <img src={noIcon} alt="error" />{" "}
      </span>
    )
  }

  const approve = field && field.approve ? "approve" : ""

  return (
    <div className={`application-package-block__container`}>
      <div className={`application-package-block__content`}>
        {getIconType(field.name)}
        <p className={`application-package-block__text`}>{field.content}</p>
        {getApproveIcon(field.approve)}
      </div>
      <div
        className={`application-package-block__decoration-line 
        application-package-block__decoration-line_${approve}`}
      >
        <div className="decoration-line__container">
          <div className="decoration-line__loading"></div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({})

export default connect(mapStateToProps)(ApplicationPackageComponent)
