export const initialObservers = {
  block_address_autofill_zipcode: {
    type: "showOnAddressAutofill",
    target: "block_address_autofill_zipcode",
    value: "Yes",
  },
  block_address_autofill_city: {
    type: "showOnAddressAutofill",
    target: "block_address_autofill_city",
    value: "Yes",
  },
  block_address_autofill_state: {
    type: "showOnAddressAutofill",
    target: "block_address_autofill_state",
    value: "Yes",
  },
  block_city_birth_autofill_country: {
    type: "showOnCityBirthAutofill",
    target: "block_city_birth_autofill_country",
    value: "Yes",
  },
  block_citizenship_autofill_state: {
    type: "showOnCityBirthAutofill",
    target: "block_citizenship_autofill_state",
    value: "Not USA",
  },
}
