import React, {useEffect, useState} from "react";
import "./ResetPassword.less";
import {Form} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {clearMessage, resetInstructions,} from "../../appRedux/actions";
import Header from "../Header";
import {helper} from "./helper";
import {checkEmailRegEx} from "../../assets/helpers/getParams";
import {SignInHelper} from "../SignIn/SignInHelper";
import {ICONS_SVG_TYPE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import GReset from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/Reset/Reset";
import GResetNotification
  from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/ResetNotification/ResetNotification";
  import GLogo from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Logo/Logo";

const ResetPassword = () => {
  const {showMessage, alertMessage, loader} = useSelector(({auth}) => auth);

  const dispatch = useDispatch();
  const history = useHistory()

  const [uiMessage, setUiMessage] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [commonMessages, setCommonMessages] = useState("");
  const [formResetPassword] = Form.useForm()

  const onFinish = (values) => {
    setUserEmail(values.email)
    if (!checkEmailRegEx(values.email)) {
      setUiMessage("Invalid email address")
    } else {
      dispatch(resetInstructions(values.email.trim()));
    }
  };

  const emailValidationOnBlur = (emailInput)=>{
    const emailForField = emailInput.target.value.trim();
    formResetPassword.setFieldsValue({
      email: emailForField,
    })

    const emailErrorMessages = SignInHelper.checkEmailFields(emailForField)
    setUiMessage(emailErrorMessages)
    !emailErrorMessages && setCommonMessages("")
  }

  useEffect(() => {
    if (alertMessage) {
      setCommonMessages(alertMessage);
    }
    const {message} = helper.validation(commonMessages, userEmail);
    setUiMessage(message);
  }, [alertMessage, showMessage, commonMessages, userEmail])

  return (
    <div className="gx-app-login-wrap">
      <div className={'gx-app-login-logo'}>
        <GLogo width={110} height={28} />
      </div>
      {commonMessages === "common.EMAIL_RESET_SENT"
          ?
          <GResetNotification email={userEmail} />
          :
          <GReset
              formOnFinish={onFinish}
              formOnFocus={() => {
                if (alertMessage) {
                  dispatch(clearMessage())
                }
              }}
              formOnChange={() => {
                setUiMessage("")
              }}
              formOnBlur={emailValidationOnBlur}
              form={formResetPassword}
              emailSuffixErrorIcon={ICONS_SVG_TYPE.X_CIRCLE}
              emailErrorMessage={uiMessage}
              loader={loader}
          />
      }
    </div>
  );
};

export default ResetPassword
