import { FC } from "react"
import { renderBasicBlockContainer } from "../UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { BasicBlockContainerProps } from "../UploadPhotoService/interfaces/interfaces"
import LayoutContentContainer from "../Layouts/LayoutContentContainer/LayoutContentContainer"

const PointContentComponent: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text },
  arrayComponents,
}) => renderBasicBlockContainer({ className, text, childrenItem, arrayComponents }, LayoutContentContainer)

export default PointContentComponent
