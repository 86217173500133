export enum UPSELL_TEMPLATES {
    UPSELL_TEMPLATE_ONE = "template1",
}

export enum UPSELL_PROPS {
    UPSELL__HEADLINE_STOP = "upsell__headline-stop",
    UPSELL__SUB_HEADLINE_STOP = "upsell__sub_headline-stop",
    UPSELL__CALL_TO_ACTION_YES = "upsell__call-to-action-yes",
    UPSELL__CALL_TO_ACTION_NO = "upsell__call-to-action-no",
    SOLUTION_KEY_REPLACE = "{solution}",
    OFFERED_SOLUTION_KEY_REPLACE = "{offeredSolution}"
}

export enum UPSELL_ACTIONS {
    ACTION_ACCEPT = "accept",
    ACTION_CANCEL = "cancel",
}