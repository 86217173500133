import SignupStarted from "./SignupStarted";
import IEvent from "../IEvent";
import BaseBuilder from "../BaseBuilder";

export default class SignupStartedBuilder extends BaseBuilder {

    setIpAddress(ipAddress: string | undefined) {
        this._ipAddress = ipAddress;
        return this;
    }

    build(): IEvent {
        return new SignupStarted(this._ipAddress);
    }
}
