import Event from "../Event"
import { EventOptions, EventProps } from "../EventProperties"
import IEvent from "../IEvent"

const EVENT_NAME = "Identity Protection Record Monitoring Updated"

/**
 * Identity Protection Record Monitoring Updated
 * @class IdentityProtectionRecordMonitoringUpdated
 * @extends {Event}
 * @implements {IEvent}
 * @description Identity Protection Record Monitoring Updated event
 */

export default class IdentityProtectionRecordMonitoringUpdated extends Event implements IEvent {

  private readonly _recordType: string | undefined;

  /**
   * Creates an instance of IdentityProtectionRecordMonitoringUpdated.
   * @param recordType
   * @memberof IdentityProtectionRecordMonitoringUpdated
   * @description Identity Protection Record Monitoring Updated event
   */

  constructor(
    recordType: string | undefined,
    ipAddress: string | undefined
  ) {
    super(EVENT_NAME, ipAddress);
    this._recordType = recordType;
  }

  getEventData(): EventProps {
    return {
      ...super.baseEventData(),
    }
  }

  getEventName(): string {
    return this.eventName
  }

  pageName(): string {
    return ""
  }

  getPreviousId(): string {
    return ""
  }

  getUserId(): string {
    return ""
  }

  getEventOptions(): EventOptions | undefined {
    return undefined
  }
}
