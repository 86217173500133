import IBuilder from "../IBuilder";
import IEvent from "../IEvent";
import Page from "./Page";

export default class PageBuilder implements IBuilder{
    private _pageName: string;
    private _userIp: string;

    constructor() {
        this._pageName = "";
        this._userIp = "";
    }

    setPageName(value: string) {
        this._pageName = value;
        return this;
    }

    setUserIp(value: string) {
        this._userIp = value;
        return this;
    }

    build(): IEvent {
        return new Page(this._pageName, this._userIp);
    }
}
