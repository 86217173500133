import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

export const USER_IP = "userIp"

class UserIpRepository extends BaseSessionStorageRepository {
    private static _instance: UserIpRepository;

    private constructor() {
        super();
    }

    public static getInstance(): UserIpRepository {
        if (!UserIpRepository._instance) {
            UserIpRepository._instance = new UserIpRepository();
        }
        return UserIpRepository._instance;
    }

    getKeyName(): string {
        return USER_IP;
    }

    getValue(): string {
        return super.getValue() ?? "";
    }
}

export default UserIpRepository;
