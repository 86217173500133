import React from "react";
import ProductPage from "../../../Components/atomicDesign/templates/ProductPage/ProductPage";
import {ICONS_SVG_TYPE} from "../../../Components/atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import {FamilyMembersText} from "../../../shared/text/Text";
import FamilyMembersList from "../../../Components/atomicDesign/templates/FamilyMembersList/FamilyMembersList";
import {useSubscription} from "../../../../../dashboard-govplus-front/src/hooks/useSubscription";
import GText from "../../../Components/atomicDesign/atoms/Text/Text";
import {SIZE} from "../../../Components/atomicDesign/types/types";
import {COLORS} from "../../../Components/atomicDesign/types/Colors";
import './MyFamily.scss'
import GIconsSvgType from "../../../Components/atomicDesign/atoms/IconsSvg/IconsSvg";

const MyFamily = () => {
    const {familyMembers} = useSubscription()

    const listComponent = () => {
        return <FamilyMembersList familyMembers={familyMembers} />
    }
    const footer = () => {
        return (
            <div className={"GMyFamily__Footer"}>
                <GIconsSvgType type={ICONS_SVG_TYPE.THINK_PLUS_SEPARATOR} width={24} height={24} color={COLORS.SLATE_GRAY_500}/>
                <GText
                    text={FamilyMembersText.AddFamilyMembers}
                    size={SIZE.PARAGRAPH_REGULAR_16} href={""}
                    color={COLORS.SLATE_GRAY_500}
                    style={{textDecoration: "underline"}}
                />
            </div>
        )
    }
    return (
        <ProductPage
            List={listComponent}
            headerText={FamilyMembersText.MyFamily}
            subHeaderText={FamilyMembersText.FamilyMembersSubHeader}
            Footer={footer}
        />
    )
}

export default MyFamily