import React, {FC} from "react"

import "./StepTaxProcessing.scss";
import {connect} from "react-redux";
import imageIlu from "./Image/illu.png"
import GText from "../../../atoms/Text/Text";
import {PROGRESS_BAR_ORIENTATION, SIZE} from "../../../types/types";
import GProgressBar from "../../../molecules/ProgressBar/ProgressBar";
import {COLORS} from "../../../types/Colors";
import GIconsSvgType from "../../../atoms/IconsSvg/IconsSvg";
import {ICONS_SVG_TYPE} from "../../../atoms/IconsSvg/IconsSvgTypes";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import GButton, {BUTTON_TYPE} from "../../../molecules/Button/GButton";
import {TaxConstant} from "../../../../../util/TaxConstant";

export type StepTaxProcessingProps = {
    title: string,
    progress: number,
    progressText: string,
    descriptionText: string,
    ctaText: string,
    ctaDisabled?: boolean,
    ctaLoading?: boolean,
    ctaOnClick?: any,
    icon?: string,
    showMoreDetail?: boolean,
    ctaMoreDetailText?: string,
    ctaMoreDetailDisabled?: boolean,
    ctaMoreDetailLoading?: boolean,
    ctaMoreDetailClick?: any,
}

const StepTaxProcessing: FC<StepTaxProcessingProps> = ({
                                                           title,
                                                           progress,
                                                           progressText,
                                                           descriptionText,
                                                           ctaText,
                                                           showMoreDetail = false,
                                                           ctaMoreDetailText = TaxConstant.CTA_SEE_MORE_DETAIL,
                                                           ctaMoreDetailClick,
                                                           ctaDisabled = false,
                                                           ctaLoading = false,
                                                           ctaMoreDetailDisabled = false,
                                                           ctaMoreDetailLoading = false,
                                                           ctaOnClick,
                                                           icon = ICONS_SVG_TYPE.CHECK_CIRCLE_TAX
                                                       }) => {
    const breakpoint = useBreakpoint();

    const textStyle: React.CSSProperties = {
        letterSpacing: "-0.1px"
    };

    const pgOrientation = breakpoint.xs ? PROGRESS_BAR_ORIENTATION.VERTICAL : PROGRESS_BAR_ORIENTATION.LINEAR;

    return (
        <>
            <div className={`StepTaxProcessing`}>
                <div className={`StepTaxProcessing__Container`}>
                <div className={`StepTaxProcessing__Container__Content`}>
                    <div className={`StepTaxProcessing__Container__Content__ImageContainer`}>
                        <img className={`StepTaxProcessing__Container__Content__ImageContainer__Image`} src={imageIlu} alt={``}/>
                    </div>
                    <div className={`StepTaxProcessing__Container__Content__InformationContainer`}>
                        <GText text={title} size={SIZE.HEADING_XS} />
                        <GProgressBar value={progress} orientation={pgOrientation} customText={progressText} />
                        {
                            !breakpoint.xs && (<div className={`StepTaxProcessing__Container__Content__InformationContainer__Description`}>
                                    <div>
                                        <GIconsSvgType type={icon} color={COLORS.SLATE_GRAY_500} width={24} height={24} />
                                    </div>

                                    <GText text={descriptionText} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.SLATE_GRAY_500} style={textStyle} innerHtml={descriptionText}/>
                                </div>
                            )}
                    </div>
                </div>
                {
                    breakpoint.xs && (<div className={`StepTaxProcessing__Container__Description`}>
                        <div>
                            <GIconsSvgType type={icon} color={COLORS.SLATE_GRAY_500} width={24} height={24} />
                        </div>

                        <GText text={descriptionText} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.SLATE_GRAY_500} style={textStyle} innerHtml={descriptionText}/>
                    </div>
                )}
            </div>
            </div>
            <div className={`StepTaxProcessingBtnContainer`}>
                <GButton
                    textSize={SIZE.PARAGRAPH_BOLD_16}
                    text={ctaText}
                    isSubmit={false}
                    showIcon={false}
                    disabled={ctaDisabled}
                    loading={ctaLoading}
                    click={ctaOnClick}
                />

                {
                    showMoreDetail && (
                        <GButton
                            type={BUTTON_TYPE.SECONDARY}
                            textSize={SIZE.PARAGRAPH_BOLD_16}
                            text={ctaMoreDetailText}
                            isSubmit={false}
                            showIcon={false}
                            disabled={ctaMoreDetailDisabled}
                            loading={ctaMoreDetailLoading}
                            click={ctaMoreDetailClick}
                        />
                    )
                }
            </div>
        </>
    );
}

const mapStateToProps = (state: any) => ({
    loadingButtonState: state.loading.loadingButtonState
})

export default connect(mapStateToProps)(StepTaxProcessing)