import Event from "../Event"
import {EventOptions, EventProps} from "../EventProperties"
import IEvent from "../IEvent";

const EVENT_NAME = "Subscription Cancellation Started";

/**
 * Account Activated Event
 * @class SubscriptionCancellationStarted
 */
export default class SubscriptionCancellationStarted extends Event implements IEvent {
    private readonly _subscriptionId: string | undefined;

    /**
     * Constructor
     * @param subscriptionId
     * @param ipAddress
     */
    constructor(
        subscriptionId: string | undefined,
        ipAddress: string | undefined
    ) {
        super(EVENT_NAME, ipAddress);
        this._subscriptionId = subscriptionId;
    }

    /**
     * Get event data
     * @returns {EventProps}
     * @description This method is used to get event data
     */
    getEventData(): EventProps {
        return {...super.baseEventData(), subscription_id: this._subscriptionId}
    }

    /**
     * Get event name
     * @returns {string}
     * @description This method is used to get event name
     */
    getEventName(): string {
        return this.eventName;
    }

    /**
     * Get page name
     * @returns {string}
     * @description This method is used to get page name
     */
    pageName(): string {
        return "";
    }

    /**
     * Get previous id
     * @returns {string}
     * @description This method is used to get previous id
     */
    getPreviousId(): string {
        return "";
    }

    /**
     * Get user id
     * @returns {string}
     * @description This method is used to get user id
     */
    getUserId(): string {
        return "";
    }

    getEventOptions(): EventOptions | undefined {
        return undefined;
    }
}
