import React, { ChangeEvent, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import {
  setStatusUploadAdditionalDocuments,
  setStatusUploadDocuments,
  uploadDAdditionalDocuments,
  uploadDocuments,
  clearTempDocuments,
  setStatusUploadDocumentOpen,
  setStatusUploadAdditionalDocumentOpen,
} from "../../../../Redux/store/additionalForm/additional.actions"
import UploadSelectsRenderComponentV1 from "./UploadSelectsRenderComponent/UploadSelectsRenderComponentV1"
import { RootState } from "../../../../App"
import { additionalSelector } from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { LoadingStateUploadDocument } from "../../../../shared/constans/user-from-view-mode.enum"
import { getClientTokenForResponse } from "../../../../Redux/store/step/service.step.saga"
import fakeJSON from "../../../../services/CER/upload.data.json"
import { BOX_TYPE, SIZE } from "../../../atomicDesign/types/types"
import HeaderImage from "../../../../assets/image/illu-upload-proof-of-identity.png"
import UploadGoodImage from "../../../../assets/image/pic-upload-good.webp"
import Step1Image from "../../../../assets/image/documents-step1.webp"
import Step2Image from "../../../../assets/image/documents-step2.webp"
import Step3Image from "../../../../assets/image/documents-step3.webp"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
import "./UploadComponent.scss"
import GBoxImage from "../../../atomicDesign/molecules/BoxImage/GBoxImage"
import GAdditionalStepCard, { CARD_STATUS } from "../../../atomicDesign/organisms/AdditionalStepCard/AdditionalStepCard"
import GButton from "../../../atomicDesign/molecules/Button/GButton"
import { setLoadingButtonState } from "../../../../Redux/store/loading/loading.action"
import GIconsSvgType from "../../../atomicDesign/atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../../atomicDesign/atoms/IconsSvg/IconsSvgTypes"
import { COLORS_NEW } from "../../../atomicDesign/types/Colors"
import GText from "../../../atomicDesign/atoms/Text/Text"
import { WarningsText } from "../../../../shared/text/Text"
import { BlockStepTypes } from "../../../../Constants/BlockStepTypes"

type UploadComponentProps = {
  blocksStep: {} | any,
  index: number,
  showStatus: string,
  loadingButtonState: {} | any,
  documents: {} | null | any,
  proofOnFlag?: boolean,
  additionalDocuments: {} | any,
  statusDocuments: string,
  statusAdditionalDocuments: string,
  documentList: {}[],
  relationship: string,
  stateUploadDocuments: { primary_document: string, proof_relationship: string | null } | any,
  formId: string,
  currentResponse: any,
  customer: {} | any,
  statusSignature: string,
  statusDocumentsBlockOpen: boolean,
  statusAdditionalDocumentsBlockOpen: boolean,
}

const UploadComponent: React.FunctionComponent<UploadComponentProps> = ({
  blocksStep,
  index,
  showStatus,
  loadingButtonState,
  documents,
  proofOnFlag,
  additionalDocuments,
  statusDocuments,
  statusAdditionalDocuments,
  documentList,
  relationship,
  stateUploadDocuments,
  formId,
  currentResponse,
  customer,
  statusSignature,
  statusDocumentsBlockOpen,
  statusAdditionalDocumentsBlockOpen,
}) => {
  const { blocks } = blocksStep?.content ?? {}
  const [nameDocumentStore, setNameDocumentStore] = useState("")
  const { client_token: clientToken } = getClientTokenForResponse()
  const breakpoint = useBreakpoint()

  let boxImages: any[] = [
    {
      type: BOX_TYPE.HORIZONTAL,
      images: [{ image: HeaderImage }],
      imageWidth: breakpoint.xs ? 178 : 568,
      imageHeight: "auto",
    },
    {
      type: BOX_TYPE.VERTICAL,
      numberTitle: "1",
      title: "Use good lighting",
      subtitle: "Turn off your flash. Take the photo in a location with good lighting to avoid reflection and glare.",
      images: [{ image: UploadGoodImage }, { image: Step1Image }],
      imageWidth: breakpoint.xs ? "100%" : 124,
      imageHeight: "auto",
    },
    {
      type: BOX_TYPE.VERTICAL,
      numberTitle: "2",
      title: "Straighten and crop",
      subtitle:
        "Place your document on a flat surface, and hold your phone parallel. Capture the entire document, and crop the image to the ID size if needed.",
      images: [{ image: UploadGoodImage }, { image: Step2Image }],
      imageWidth: breakpoint.xs ? "100%" : 124,
      imageHeight: "auto",
    },
    {
      type: BOX_TYPE.VERTICAL,
      numberTitle: "3",
      title: "Take clear images",
      subtitle:
        "Make sure the image is clear and not blurry. Do not reduce the image resolution as that will reduce quality as well.",
      images: [{ image: UploadGoodImage }, { image: Step3Image }],
      imageWidth: breakpoint.xs ? "100%" : 124,
      imageHeight: "auto",
    },
  ]

  const dispatch = useDispatch()

  const getFileNameAndExt = (filename = ""): { name: string, extension: string } => {
    const lastDotSeparatorIndex = filename.lastIndexOf(".")
    return {
      name: filename.slice(0, lastDotSeparatorIndex) || "",
      extension: filename.slice(lastDotSeparatorIndex + 1) || "",
    }
  }

  const dispatchDocument = (
    typeStep: string,
    clientToken: string,
    fieldName: string | null,
    nameDocumentStore: string | null,
    newFileName: string | null,
    file: any
  ) => {
    if (typeStep === "uploadingFile") {
      dispatch(
        uploadDocuments({
          client_token: clientToken,
          name_field: fieldName,
          name_document: nameDocumentStore,
          file_name: newFileName,
          file,
          typeDocument: typeStep,
        })
      )
    } else {
      dispatch(
        uploadDAdditionalDocuments({
          client_token: clientToken,
          name_field: fieldName,
          name_document: nameDocumentStore,
          file_name: newFileName,
          file,
          typeDocument: typeStep,
        })
      )
    }
  }

  const handlerUploadDocument = (
    e: ChangeEvent<HTMLInputElement>,
    clientToken: string,
    fieldName: string,
    typeStep: string,
    nameDocumentStore: string
  ) => {
    const file = e && e.target.files && e.target.files[0]

    const { name, extension } = getFileNameAndExt(file?.name)
    const newFileName = file && `${name} (.${extension})`

    dispatchDocument(typeStep, clientToken, fieldName, nameDocumentStore, newFileName, file)
  }

  const clearDocuments = (typeStep: string, clientToken: string) => {
    dispatch(
      clearTempDocuments({
        client_token: clientToken,
        typeDocument: typeStep,
      })
    )
  }

  const getNameUploadDocument = (name: string, documents: [], additionalDocuments: [], type: string) => {
    let arrayDocuments: [] = []
    if (type === "uploadingFile") {
      arrayDocuments = documents
    }
    if (type === "uploadingAdditionalFile") {
      arrayDocuments = additionalDocuments
    }
    return (arrayDocuments.find((document: {} | any) => name === document.name_field) || { file_name: "" }).file_name
  }

  const [documentSelected, setDocumentSelected] = useState<[string]>([""])

  const showButton = (
    arrayDocuments: [] | any,
    arrayAdditionalDocuments: [] | any,
    documentList: {},
    documentListValue: string,
    documentSelected: [string],
    errorStatus: boolean
  ) => {
    if (!documentSelected[0] || errorStatus) {
      return
    }

    const optionsForSelect: any = Object.entries(documentList || {}).find(
      (document: [string, unknown]) => document[0] === documentListValue
    )?.[1]

    const uploadDocuments: any = Object.entries(optionsForSelect || {}).find(
      (document: [string, unknown]) => document[0] === documentSelected[0]
    )?.[1]

    let lengthMaxOptionsForCurrentDocument
    if (typeof uploadDocuments === "string") {
      lengthMaxOptionsForCurrentDocument = 1
    } else if (typeof uploadDocuments === "object") {
      lengthMaxOptionsForCurrentDocument = Object.keys(uploadDocuments?.files ?? {})?.length
    } else {
      lengthMaxOptionsForCurrentDocument = 0
    }

    return typeof uploadDocuments === "object" || typeof uploadDocuments === "string"
      ? lengthMaxOptionsForCurrentDocument === arrayDocuments?.length ||
          lengthMaxOptionsForCurrentDocument === arrayAdditionalDocuments?.length
      : !(arrayDocuments?.length || arrayAdditionalDocuments?.length)
  }

  const handlerSendDocuments = (typeDocument: string, relationship: string, clientToken: string, form_id: string) => {
    if (typeDocument === "uploadingFile") {
      dispatch(setLoadingButtonState("loading"))
      dispatch(setStatusUploadDocuments("completed"))
      sessionStorage.setItem("statusDocuments", "completed")
      dispatch(setStatusUploadAdditionalDocuments("process"))
      sessionStorage.setItem("additionalDocuments", "process")
      dispatch(
        uploadDocuments({
          typeDocument,
          isFinalSubmit: true,
          client_token: clientToken,
          form_id,
        })
      )
      dispatch(setStatusUploadDocumentOpen(false))
    }
    if (typeDocument === "uploadingAdditionalFile") {
      dispatch(setLoadingButtonState("loading"))
      dispatch(setStatusUploadAdditionalDocuments("completed"))
      dispatch(uploadDAdditionalDocuments({ typeDocument, isFinalSubmit: true, client_token: clientToken, form_id }))
      dispatch(setStatusUploadAdditionalDocumentOpen(false))
    }
  }

  const { statusSignaturePage } = useSelector<RootState, { statusSignaturePage: string } | undefined>(
    additionalSelector
  ) ?? { statusSignaturePage: "" }

  interface uploadsDocumentBlocksInterface {
    statusSignaturePage: string;
    statusDocumentsLink: string;
    valuePrevView: string;
    valueShowOn: string[];
    valueCompleted: string;
  }

  const arrayUploadsDocumentBlocks = [
    {
      statusSignaturePage: "citizenship_document",
      statusDocumentsLink: "statusAdditionalDocuments",
      valuePrevView: "",
      valueShowOn: ["process", LoadingStateUploadDocument.FormatError],
      valueCompleted: "completed",
    },
    {
      statusSignaturePage: "pending_documents",
      statusDocumentsLink: "statusDocuments",
      valuePrevView: "",
      valueShowOn: ["process", LoadingStateUploadDocument.FormatError],
      valueCompleted: "completed",
    },
    {
      statusSignaturePage: "identity_document",
      statusDocumentsLink: "statusDocuments",
      valuePrevView: "",
      valueShowOn: ["process", LoadingStateUploadDocument.FormatError],
      valueCompleted: "completed",
    },
    {
      statusSignaturePage: "name_change_documents",
      statusDocumentsLink: "statusDocuments",
      valuePrevView: "",
      valueShowOn: ["process", LoadingStateUploadDocument.FormatError],
      valueCompleted: "completed",
    },
    {
      statusSignaturePage: "pending_proof_relationship",
      statusDocumentsLink: "statusAdditionalDocuments",
      valuePrevView: "",
      valueShowOn: ["process", LoadingStateUploadDocument.FormatError],
      valueCompleted: "completed",
    },
  ]

  const getShowOn = (
    statusSignaturePageCurrent: string,
    statusObj: { [key: string]: string },
    blockStepStatus: string,
    arrayUploadsDocumentBlocks: uploadsDocumentBlocksInterface[]
  ) => {
    return arrayUploadsDocumentBlocks.find(
      ({ statusSignaturePage, statusDocumentsLink, valueShowOn }: uploadsDocumentBlocksInterface) => {
        return (
          statusSignaturePage === statusSignaturePageCurrent &&
          statusSignaturePage === blockStepStatus &&
          valueShowOn.includes(statusObj[statusDocumentsLink])
        )
      }
    )
  }

  if (!documentList?.[blocksStep?.documentListValue] && !stateUploadDocuments?.[blocksStep?.documentListValue]) {
    return null
  }

  const handlerDocumentOpen = () => {
    dispatch(setStatusUploadDocumentOpen(true))
  }

  const handlerAdditionalDocumentOpen = () => {
    dispatch(setStatusUploadAdditionalDocumentOpen(true))
  }

  const information = blocksStep.content.information
    .replace("{firstName}", customer.first_name)
    .replace("{lastName}", customer.last_name)

  const getInstructionsBlock = () => {
    return (
      <div className={"instructions-container"}>
        {boxImages.map((item: any, index) => {
          return (
            <div key={index}>
              <GBoxImage
                type={item.type}
                images={item.images}
                imageWidth={item.imageWidth}
                imageHeight={item.imageHeight}
                title={item.title}
                numberTitle={item.numberTitle}
                information={item.information}
                subtitle={item.subtitle}
              />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      {blocksStep.type === BlockStepTypes.UPLOADING_FILE && !statusDocumentsBlockOpen && showStatus === "" && (
        <GAdditionalStepCard
          title={blocksStep.content.title}
          cardStatus={CARD_STATUS.DISABLED}
          index={index.toString()}
        />
      )}
      {blocksStep.type === BlockStepTypes.UPLOADING_FILE && !statusDocumentsBlockOpen && showStatus === "process" && (

          <GAdditionalStepCard
              title={blocksStep.content.title}
              cardStatus={CARD_STATUS.PROCESS}
              information={information}
              index={index.toString()}
          >
            <>
              {
                <div className={`${blocksStep?.type}`}>
                  {getInstructionsBlock()}
                  <div className={"UploadDocuments_Warning"}>
                    <GIconsSvgType type={ICONS_SVG_TYPE.ALERT_TRIANGLE} color={COLORS_NEW.RED_600} width={40} height={24} />
                    <GText text={WarningsText.UploadDocumentsWarning} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.RED_600} innerHtml={WarningsText.UploadDocumentsWarning} />
                </div>
                  <UploadSelectsRenderComponentV1
                      blocksStep={blocksStep}
                      documents={documents}
                      additionalDocuments={additionalDocuments}
                      blocks={blocks}
                      handlerUploadDocument={handlerUploadDocument}
                      getNameUploadDocument={getNameUploadDocument}
                      nameDocumentStore={nameDocumentStore}
                      setNameDocumentStore={setNameDocumentStore}
                      clientToken={clientToken}
                      documentList={documentList || stateUploadDocuments || fakeJSON?.uploadData} //because was created parallel store
                      stateUploadDocuments={stateUploadDocuments || documentList || fakeJSON?.uploadData} // use one of these stores in future
                      clearTempDocuments={clearDocuments}
                      documentSelectState={{ documentSelected, setDocumentSelected }}
                  />
                  <GButton
                      loading={loadingButtonState === "loading"}
                      textSize={SIZE.PARAGRAPH_BOLD_16}
                      text={blocksStep.content.button}
                      showIcon={false}
                      click={() => handlerSendDocuments(blocksStep?.type, relationship, clientToken, formId)}
                      disabled={
                        !showButton(
                            documents,
                            additionalDocuments,
                            documentList || stateUploadDocuments,
                            blocksStep.documentListValue,
                            documentSelected,
                            statusDocuments === LoadingStateUploadDocument?.FormatError
                        )
                      }
                  />
                </div>
              }
            </>
          </GAdditionalStepCard>
      )}

      {blocksStep.type === BlockStepTypes.UPLOADING_FILE && showStatus === "completed" && (
        <GAdditionalStepCard
          title={blocksStep.content.title}
          cardStatus={CARD_STATUS.DONE}
          titleDone={blocksStep.content.titleDone}
        />
      )}

      {blocksStep.type === BlockStepTypes.UPLOADING_ADDITIONAL_FILE && !statusAdditionalDocumentsBlockOpen && showStatus === "" && (
        <GAdditionalStepCard
          title={blocksStep.content.title}
          cardStatus={CARD_STATUS.DISABLED}
          index={index.toString()}
        />
      )}
      {blocksStep.type === BlockStepTypes.UPLOADING_ADDITIONAL_FILE &&
        !statusAdditionalDocumentsBlockOpen &&
        showStatus === "process" && (
              <GAdditionalStepCard
                  title={blocksStep.content.title}
                  cardStatus={CARD_STATUS.PROCESS}
                  information={information}
                  index={index.toString()}
              >
                <>
                  {
                    <div className={`${blocksStep?.type}`}>
                      {getInstructionsBlock()}
                      <div className={"UploadDocuments_Warning"}>
                        <GIconsSvgType type={ICONS_SVG_TYPE.ALERT_TRIANGLE} color={COLORS_NEW.RED_600} width={40} height={24} />
                        <GText text={WarningsText.UploadDocumentsWarning} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.RED_600} innerHtml={WarningsText.UploadDocumentsWarning} />
                      </div>
                      <UploadSelectsRenderComponentV1
                          blocksStep={blocksStep}
                          documents={documents}
                          additionalDocuments={additionalDocuments}
                          blocks={blocks}
                          handlerUploadDocument={handlerUploadDocument}
                          getNameUploadDocument={getNameUploadDocument}
                          nameDocumentStore={nameDocumentStore}
                          setNameDocumentStore={setNameDocumentStore}
                          clientToken={clientToken}
                          documentList={documentList || stateUploadDocuments || fakeJSON?.uploadData} //because was created parallel store
                          stateUploadDocuments={stateUploadDocuments || documentList || fakeJSON?.uploadData} // use one of these stores in future
                          clearTempDocuments={clearDocuments}
                          documentSelectState={{ documentSelected, setDocumentSelected }}
                      />
                      <GButton
                          textSize={SIZE.PARAGRAPH_BOLD_16}
                          text={blocksStep.content.button}
                          showIcon={false}
                          loading={loadingButtonState === "loading"}
                          disabled={
                            !showButton(
                                documents,
                                additionalDocuments,
                                documentList || stateUploadDocuments,
                                blocksStep.documentListValue,
                                documentSelected,
                                statusDocuments === LoadingStateUploadDocument?.FormatError
                            )
                          }
                          click={() => handlerSendDocuments(blocksStep?.type, relationship, clientToken, formId)}
                      />
                    </div>
                  }
                </>
              </GAdditionalStepCard>
      )}

      {blocksStep.type === BlockStepTypes.UPLOADING_ADDITIONAL_FILE && showStatus === "completed" && (
        <GAdditionalStepCard
          title={blocksStep.content.title}
          cardStatus={CARD_STATUS.DONE}
          titleDone={blocksStep.content.titleDone}
        />
      )}

    </>
  )
}

const mapStateToProps = (state: any) => ({
  documents: state.additional.documents,
  additionalDocuments: state.additional.additionalDocuments,
  statusAdditionalDocuments: state.additional.statusAdditionalDocuments,
  documentList: state.form.documentList,
  stateUploadDocuments: state.upload.uploadsDocuments,
  formId: state.form.formId,
  currentResponse: state.step.currentResponse,
  customer: state.form.customer,
  statusSignature: state.additional.statusSignature,
  statusDocumentsBlockOpen: state.additional.statusDocumentsBlockOpen,
  statusAdditionalDocumentsBlockOpen: state.additional.statusAdditionalDocumentsBlockOpen,
})

export default connect(mapStateToProps)(UploadComponent)
