import React, { useEffect } from "react"

import "./TrackingCardStepComponent.scss"
import GAdditionalStep from "../../../atomicDesign/templates/AdditionalStep/AdditionalStep"
//@ts-ignore
import GPostTrackingCard from "../../../atomicDesign/templates/AdditionalStep/StepStatusTracking/shared/PostTrackingCard/PostTrackingCard"
//@ts-ignore
import { useApplications } from "../../../../../../dashboard-govplus-front/src/hooks/useApplications"
import AuthUserBearerTokenRepository from "../../../../persistence/session/AuthUserBearerTokenRepository"
import { replaceSolution, replaceWords } from "../../../../util/ProcessingTrackingHelper"
import GSpinner from "../../../atomicDesign/atoms/Spinner/Spinner"
import StringHelper from '../../../../util/StringHelper';

type TrackingCardStepComponentProps = {
  blocksStep: any,
  solutionName: string,
  index: number,
  showStatus: string,
  applicationId: string,
  replaceInformation?: boolean,
  toCustomer?: boolean
}

const TrackingCardStepComponent: React.FunctionComponent<TrackingCardStepComponentProps> = ({blocksStep, solutionName, index, showStatus, applicationId, replaceInformation = true, toCustomer = true}) => {
 
  const {
    getPostTrackingData,
    postData,
    loading
  } = useApplications();    
  const authToken = AuthUserBearerTokenRepository.getInstance().getValue();
  useEffect(() => {
    if(showStatus === "process"){
      getPostTrackingData(authToken as string, applicationId, toCustomer);
    }
  }, [applicationId, authToken, toCustomer,showStatus]);

  function getInformation(information: string){
    if(replaceInformation && information !== null && postData !== undefined){
      return replaceWords(information, {
        solution: solutionName,
        numberOfDays: postData?.deliveryNumberOfDays || "",
        governmentAgency: StringHelper.correctCapitalization(postData?.destinationLocation?.name || ""),
      })
    }
    return information;
  }
  return (
    <>
      {
        (loading && showStatus === "process") && ( 
          <div className="GTrackingCardStep__card">
            <GSpinner />
          </div>
        )
      }
      {
        !loading && (
          <GAdditionalStep
            title={replaceSolution(blocksStep.content.title, solutionName)}
            titleDone={replaceSolution(blocksStep.content.titleDone, solutionName)}
            information={getInformation(blocksStep?.content?.information)}
            index={index.toString()}
            stepShowStatus={showStatus}
          >
            {
              postData !== undefined && <GPostTrackingCard postData={postData} loading={loading}/>
            }
            
          </GAdditionalStep>
          )
      }
    </>
  )
}
export default TrackingCardStepComponent
