import React, { ChangeEvent, useRef, FC } from "react"
import { useDispatch } from "react-redux"
import { sentDocumentToBack } from "../../../../../../Redux/store/upload/upload.actions"
import {
  ItemArrayComponentsInterface,
  ItemInterface,
} from "../../../../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { dynamicPropsInterface } from "./DNDUploadDocumentContainer"
import GButton from "../../../../../atomicDesign/molecules/Button/GButton";
import "./styles.scss"
import {SIZE} from "../../../../../atomicDesign/types/types";

export type UploadDocumentSubmitButtonProps = {
  item: ItemInterface,
  arrayComponents?: ItemArrayComponentsInterface[],
  dynamicProps?: dynamicPropsInterface,
}

const UploadDocumentSubmitButton: FC<UploadDocumentSubmitButtonProps> = ({ item: { className, text }, ...props }) => {
  const { dynamicProps } = props
  const ref: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()

  const handlerOnClickSubmitPhoto = (inputElement: React.RefObject<HTMLInputElement>) => inputElement.current?.click()

  const handlerChangeUploadPhoto = ({ target: { files } }: ChangeEvent<HTMLInputElement>) =>
    files?.length && dispatch(sentDocumentToBack({ ...dynamicProps, file: files[0] }))

  return (
    <>
      <div className={'btn-upload'}>
        <GButton textSize={SIZE.PARAGRAPH_BOLD_16} text={text} showIcon={false} click={() => handlerOnClickSubmitPhoto(ref)} />
      </div>
      <input
        ref={ref}
        type={"file"}
        className={`${className}__input-file`}
        onChange={(e) => handlerChangeUploadPhoto(e)}
        style={{ height: `0`, width: `0` }}
      />
    </>
  )
}

export default UploadDocumentSubmitButton
