import {PageNames, PageInUrl, Path} from "../../../../util/PageHelper";
import {FormsKeys} from "../../../../util/FormsKeys";
import {ProductsIds} from "../../../../util/ProductsIds";

export default class PathToPageEventHelper {
  static UNDEFINED_PAGE = "undefined";

  static getPageFromPath(path:string): string {
    switch (path) {
      case Path.SIGN_IN:
        return PageNames.SIGN_IN;
      case Path.SIGN_UP:
        return PageNames.SIGN_UP;
      case Path.RESET_PASSWORD:
        return PageNames.RESET_PASSWORD;
      case Path.MY_APPLICATIONS:
        return PageNames.MY_APPLICATIONS;
      case Path.NEW_APPLICATION:
        return PageNames.NEW_APPLICATION;
      case Path.FORM:
        return PageNames.FORM;
      case Path.ADD_PHONE:
        return PageNames.ADD_PHONE;
      case Path.VERIFICATION_CODE:
        return PageNames.VERIFICATION_CODE;
      case Path.WELCOME:
        return PageNames.WELCOME;
      case Path.CHANGE_PASSWORD:
        return PageNames.CHANGE_PASSWORD;
      default:{
        if(path.includes(Path.NEW_APPLICATION_PRODUCT_INFO)) {
          return PageNames.NEW_APPLICATION_PRODUCT_INFO
        }
        return this.UNDEFINED_PAGE;
      }
    }
  }

  static getPageNameFromQuery(query:URLSearchParams): string {
    if(query.has(FormsKeys.FormId) && query.get(FormsKeys.FormId) === ProductsIds.GloFormId)
      return PageNames.SIGN_UP;
    if(query.has(FormsKeys.FormId) && query.get(FormsKeys.FormId) !== ProductsIds.GloFormId) {
      if(query.has(FormsKeys.Page) && query.get(FormsKeys.Page) === PageInUrl.Payment) {
        return PageNames.PAYMENT;
      }
      if(query.has(FormsKeys.Page) && query.get(FormsKeys.Page) === PageInUrl.Upsell) {
        return PageNames.UPSELL;
      }
      if(query.has(FormsKeys.Page) && query.get(FormsKeys.Page) === PageInUrl.Processing) {
        return PageNames.PROCESSING;
      }
      return PageNames.FORM;
    }
    //
    return this.UNDEFINED_PAGE;
  }

  static checkActivationAccount(query:URLSearchParams): string {
    if(query.has(FormsKeys.Data))
      return PageNames.ACTIVATION_ACCOUNT;
    return this.UNDEFINED_PAGE;
  }

  static getPagePathWithQueryParams(): string {
    return location.pathname;
  }

  static isInFormPage(query:URLSearchParams): boolean {
    return(
        query.has(FormsKeys.FormId) &&
        query.has(FormsKeys.Page) &&
        query.get(FormsKeys.FormId) !== ProductsIds.GloFormId &&
        query.get(FormsKeys.Page) === PageInUrl.Form
    )
  }
}
