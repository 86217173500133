import React, {useEffect, useState} from "react";
import "./index.less"
import {Form} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
  checkUserActiveLinkForExpired,
  checkUserExist,
  checkUserTokenActive, clearStorage,
  hideMessage,
  saveUserData, setCheckStatusEmailProductId,
  showGrowlMessage, userSignOut
} from "../../appRedux/actions";
import Header from "../Header";
import {userService} from "../../api/api";
import {signUpHelper} from "./helper";
import {checkEmailRegEx} from "../../assets/helpers/getParams";
import {useRequirementsValidation} from "../../HOC/useRequirementsValidation";
import GActivateAccount
  from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/ActivateAccount/ActivateAccount";
import {ICONS_SVG_TYPE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import GSpinner from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Spinner/Spinner";
import PasswordSetBuilder from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/PasswordSet/PasswordSetBuilder";
import GAnalyticEvents from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/AnalyticEvents";
import {notifyError} from "@dash/gov-plus__front-end__form/src/util/helper";
import UserIpRepository from "@dash/gov-plus__front-end__form/src/persistence/session/UserIpRepository";
import {USER_IP} from "@dash/gov-plus__front-end__form/src/persistence/session/UserIpRepository";
import SessionStorageHandler from "@dash/gov-plus__front-end__form/src/persistence/SessionStorageHandler";
import {getUrlAnalyticQueryParams} from "@dash/gov-plus__front-end__form/src/util/UrlQueryParams";
import UserLogin from "../../util/UserLogin";
import ClientTokenRepository from "@dash/gov-plus__front-end__form/src/persistence/cookies/ClientTokenRepository";
import AuthUserBearerTokenRepository from "@dash/gov-plus__front-end__form/src/persistence/session/AuthUserBearerTokenRepository";
import {Path} from "@dash/gov-plus__front-end__form/src/util/PageHelper"
import StringHelper from "@dash/gov-plus__front-end__form/src/util/StringHelper"
import {AlertMessage, GrowMessage, UiMessage} from "./SignUpConstants";
import GLogo from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Logo/Logo";

const SignUp = () => {
  const [passValidationVisibleStatus, setPassValidationVisibleStatus] = useState(false);
  const [validationRequirements, setValidationRequirements] = useState(false)
  const [passLengthsStatus, passUpperStatus, passNumberStatus, passSymbolStatus, validator] = useRequirementsValidation()
  const [confirmPasswords, setConfirmPasswords] = useState(true);
  const [errorClassName, setErrorClassName] = useState(false);
  const [uiMessage, setUiMessage] = useState("")
  const [formValues, setFormValues] = useState({})

  const {
    authToken,
    showMessage,
    alertMessage,
    checkUserEmailExistStatus,
    userTokenInCookiesStatus,
    loader,
    isUserActivateLinkActive,
  } = useSelector(({auth}) => auth);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const data = new URL(window.location.href).searchParams.get("data");
  const decodedDataFromParams = signUpHelper.isDataFromLinkValid(data);
  const [formLoader, setFormLoader] = useState(false)

  const requirements = {
    length: {
      icon: true,
      status: passLengthsStatus,
      message: 'Use 8 or more characters'
    },
    upperCase: {
      icon: true,
      status: passUpperStatus,
      message: 'Use upper case letters (e.g. A)'
    },
    numbers: {
      icon: true,
      status: passNumberStatus,
      message: 'Use a number (e.g. 1234)'
    },
    symbols: {
      icon: true,
      status: passSymbolStatus,
      message: 'Use a symbol (e.g. !@#$)'
    }
  }

  /**
   * Verify if token from URL is the same as token from cookies
   */
  function logout() {
    ClientTokenRepository.getInstance().removeItem();
    return UserLogin.logout(dispatch, history);
  }

  async function redirectToSignInWithGrowMessage(message) {
    history.push(Path.SIGN_IN);
    dispatch(showGrowlMessage({
      messageInfo: message,
      growlStatus: false,
      code: 400
    }))
  }

  useEffect(async () => {
    if (decodedDataFromParams === false) {
      await redirectToSignInWithGrowMessage(GrowMessage.ACTIVATION_LINK_INVALID)
    }
  }, [])

  useEffect(() => {
    if (showMessage) {
      if (alertMessage === AlertMessage.PROFILE_ANOTHER_USER_ALREADY_REGISTERED_WITH_THIS_USERNAME || uiMessage === UiMessage.EMAIL_ALREADY_TAKEN) {
        setUiMessage(UiMessage.EMAIL_ALREADY_TAKEN)
      } else {
        setUiMessage(UiMessage.WRONG_DATA)
      }
    }
    if (alertMessage === AlertMessage.COMMON_CREATED_SUCCESSFULLY || alertMessage === AlertMessage.LOGIN_USER_UNAUTHORIZED) {
      history.push(Path.VERIFICATION_CODE, "sendCode")
    }
  }, [showMessage, alertMessage]);

  useEffect(async () => {
    if (ClientTokenRepository.getInstance().getValue()) {
      dispatch(checkUserTokenActive(ClientTokenRepository.getInstance().getValue()));
    }

    if (data && decodedDataFromParams) {
      dispatch(checkUserExist({userEmail: decodedDataFromParams.email}))
      dispatch(checkUserActiveLinkForExpired({token: decodedDataFromParams.client_token}))
    }

    if ((!ClientTokenRepository.getInstance().getValue() && !data) || checkUserEmailExistStatus) {
      history.push(Path.SIGN_IN);
    }

    if (decodedDataFromParams &&
        decodedDataFromParams?.application_id !== null
    ) {
      dispatch(setCheckStatusEmailProductId({productIdFromEmailCheckStatus: decodedDataFromParams.application_id}))
    }
  }, [checkUserEmailExistStatus]);

  useEffect(async () => {
    const userIsActivatedHisAcc_and_TryToUseActivationLink = isUserActivateLinkActive === false && checkUserEmailExistStatus === false;
    const userAccWasActivated_and_DoNotHaveAppId = checkUserEmailExistStatus === true && !ClientTokenRepository.getInstance().getValue() && !decodedDataFromParams.application_id
    const userAccWasActivated_and_HaveAppId = checkUserEmailExistStatus === true && !ClientTokenRepository.getInstance().getValue() && decodedDataFromParams.application_id

    if (userIsActivatedHisAcc_and_TryToUseActivationLink) {
      await redirectToSignInWithGrowMessage(
          StringHelper.replaceKeyByValue(GrowMessage.EXPIRED_LINK, '{email}', decodedDataFromParams.email)
      );
    }

    if (userAccWasActivated_and_DoNotHaveAppId) {
      await redirectToSignInWithGrowMessage(GrowMessage.ACCOUNT_ALREADY_ACTIVATED);
    }

    if (userAccWasActivated_and_HaveAppId) {
      await redirectToSignInWithGrowMessage(GrowMessage.LOGIN_TO_CONTINUE);
    }

    if (checkUserEmailExistStatus === false) {
      for (let key in localStorage) {
        if (key.split('/')[0] !== "otpToken" &&
            !['webFlowServicesData', "webFlowSolutionData"].includes(key)
        ) {
          localStorage.removeItem(key);
        }
      }
      SessionStorageHandler.clearIgnoringKey([USER_IP]);

      localStorage.setItem('userDataFromGov', JSON.stringify({
        'email': decodedDataFromParams?.email,
        'phone': decodedDataFromParams?.phone,
        'outer_id': decodedDataFromParams?.outer_id,
        'name': decodedDataFromParams?.first_name,
        'last_name': decodedDataFromParams?.last_name,
        'client_token': decodedDataFromParams?.client_token
      }));

      form.setFieldsValue({
        email: JSON.parse(localStorage.getItem("userDataFromGov"))?.email || "",
      });
    }
  }, [checkUserEmailExistStatus, userTokenInCookiesStatus, isUserActivateLinkActive])

  const onFinish = async ({email, password}) => {
    setFormLoader(true)
    if (!checkEmailRegEx(email)) {
      setUiMessage(UiMessage.INVALID_EMAIL)
    }

    if (!password) {
      setPassValidationVisibleStatus(true)
    }

    if (passLengthsStatus === 'valid'
      && passUpperStatus === 'valid'
      && passNumberStatus === 'valid'
      && passSymbolStatus === 'valid'
      && confirmPasswords
      && checkEmailRegEx(email)) {

      const {
        utmVars,
        analytic_fbc,
        analytic_fbp,
        analytic_gclid,
        analytic_msclkid,
        analytic_ever_flow_id,
        analytic_impact_radius_id
      } = getUrlAnalyticQueryParams();

      await userService.saveUtmVars(
          email,
          utmVars,
          analytic_fbc,
          analytic_fbp,
          analytic_gclid,
          analytic_msclkid,
          analytic_ever_flow_id,
          analytic_impact_radius_id
      ).catch(() => {});

      try {
        const {response} = await userService.checkUserExist(email);
        if (response.data.found) {
          setFormLoader(false)
          setUiMessage(UiMessage.EMAIL_ALREADY_TAKEN)
        }
      } catch (err) {
        const passwordSetBuilder = new PasswordSetBuilder()
            .setEmail(email)
            .setIpAddress(UserIpRepository.getInstance().getValue());
        GAnalyticEvents.track(passwordSetBuilder.build());
        dispatch(saveUserData({
          eMail: email,
          password
        }))
        setFormLoader(false)
        history.push(Path.ADD_PHONE)
        notifyError(err);
      }
    } else {
      setFormLoader(false)
      setErrorClassName(true)
      if (confirmPasswords) {
        setPassValidationVisibleStatus(true)
      }
    }
  };

  const onChangePassword = (e) => {
    setFormValues(form.getFieldsValue())
    validator(e.target.value, 'onChange/onFocus')
  }

  const onFocusPassword = (e) => {
    setPassValidationVisibleStatus(false);
    setValidationRequirements(true)
    setConfirmPasswords(true)
    validator(e.target.value, 'onChange/onFocus')
  }

  const onChangeConfirm = () => {
    setFormValues(form.getFieldsValue())
    const formValuesLocal = form.getFieldsValue()
    setConfirmPasswords(formValuesLocal.password === formValuesLocal.confirm_password)
  }

  const onBlurPassword = (e) => {
    validator(e.target.value, 'onBlur')
    if (e.target.value.length === 0) {
      setValidationRequirements(false)
    } else {
      if (passLengthsStatus !== 'valid'
        || passUpperStatus !== 'valid'
        || passNumberStatus !== 'valid'
        || passSymbolStatus !== 'valid') {
        setPassValidationVisibleStatus(true)
      } else {
        setPassValidationVisibleStatus(false)
        setValidationRequirements(false)
      }
    }
    onChangeConfirm()
  }

  const validationMessageRender = () => {
    if (!confirmPasswords && formValues.confirm_password.length === 0) {
      return UiMessage.CONFIRM_PASSWORD
    } else {
      if (!confirmPasswords) {
        return UiMessage.PASSWORDS_NOT_MATCH
      } else {
        return UiMessage.EMPTY_MESSAGE
      }
    }
  }

  return (
    <div className="gx-app-login-wrap">
      <div className={'gx-app-login-logo'}>
        <GLogo width={110} height={28} />
      </div>
      {
        loader
            ? <GSpinner />
            : <GActivateAccount
                formOnFinish={onFinish}
                form={form}
                email={decodedDataFromParams?.email}
                emailErrorMessage={uiMessage}
                emailSuffixErrorIcon={ICONS_SVG_TYPE.X_CIRCLE}
                onEmailChange={()=> {
                  setErrorClassName(false)
                  setUiMessage("")
                  dispatch(hideMessage())
                }}
                passwordErrorMessage={passValidationVisibleStatus}
                onFocusPassword={onFocusPassword}
                onBlurPassword={onBlurPassword}
                onChangePassword={onChangePassword}
                validationRequirements={validationRequirements}
                requirements={requirements}
                onChangeConfirm={onChangeConfirm}
                confirmValidationMessage={validationMessageRender()}
                loader={formLoader}
            />
      }
    </div>
  )
    ;
};

export default SignUp;
