import React from "react"
import {COLORS} from "../../../types/Colors";

interface ISvgMyFamily {
    width?: number;
    height?: number;
    color?: string;
    backgroundColor?: string;
}

export const SvgMyFamily = ({
                                width = 64,
                                height = 64,
                                color = COLORS.DARK_BLUE_600,
                                backgroundColor = COLORS.DARK_BLUE_25
                            }: ISvgMyFamily) => {
    const viewBox = `0 0 ${width} ${height}`
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.1734 0.349637C31.385 0.117186 33.615 0.117186 35.8266 0.349637C38.0382 0.582089 40.2194 1.04572 42.3344 1.73291C44.4493 2.42011 46.4865 3.32709 48.4123 4.439C50.3382 5.55091 52.1423 6.86162 53.7949 8.34964C55.4475 9.83766 56.9396 11.4948 58.2467 13.2939C59.5538 15.093 60.6688 17.0242 61.5733 19.0557C62.4778 21.0873 63.1669 23.2081 63.6293 25.3833C64.0916 27.5585 64.3247 29.7762 64.3247 32C64.3247 34.2238 64.0916 36.4415 63.6293 38.6167C63.1669 40.7919 62.4778 42.9127 61.5733 44.9443C60.6688 46.9758 59.5538 48.907 58.2467 50.7061C56.9396 52.5052 55.4475 54.1623 53.7949 55.6504C52.1423 57.1384 50.3382 58.4491 48.4123 59.561C46.4865 60.6729 44.4493 61.5799 42.3344 62.2671C40.2194 62.9543 38.0382 63.4179 35.8266 63.6504C33.615 63.8828 31.385 63.8828 29.1734 63.6504C26.9618 63.4179 24.7806 62.9543 22.6656 62.2671C20.5507 61.5799 18.5135 60.6729 16.5877 59.561C14.6618 58.4491 12.8577 57.1384 11.2051 55.6504C9.5525 54.1623 8.0604 52.5052 6.75328 50.7061C5.44615 48.907 4.3312 46.9758 3.42669 44.9443C2.52218 42.9127 1.8331 40.7919 1.37074 38.6167C0.908388 36.4415 0.675299 34.2238 0.675299 32C0.675299 29.7762 0.908388 27.5585 1.37074 25.3833C1.8331 23.2081 2.52218 21.0873 3.42669 19.0557C4.3312 17.0242 5.44615 15.093 6.75328 13.2939C8.0604 11.4948 9.5525 9.83766 11.2051 8.34964C12.8577 6.86162 14.6618 5.55091 16.5877 4.439C18.5135 3.32709 20.5507 2.42011 22.6656 1.73291C24.7806 1.04571 26.9618 0.58209 29.1734 0.349637Z"
                fill={backgroundColor}/>
            <path
                d="M37.5 41V39C37.5 37.9391 37.0786 36.9217 36.3284 36.1716C35.5783 35.4214 34.5609 35 33.5 35H25.5C24.4391 35 23.4217 35.4214 22.6716 36.1716C21.9214 36.9217 21.5 37.9391 21.5 39V41"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M29.5 31C31.7091 31 33.5 29.2091 33.5 27C33.5 24.7909 31.7091 23 29.5 23C27.2909 23 25.5 24.7909 25.5 27C25.5 29.2091 27.2909 31 29.5 31Z"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M43.5 41.0028V39.0028C43.4993 38.1165 43.2044 37.2556 42.6614 36.5551C42.1184 35.8547 41.3581 35.3544 40.5 35.1328"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M36.5 23.1328C37.3604 23.3531 38.123 23.8535 38.6676 24.5551C39.2122 25.2567 39.5078 26.1196 39.5078 27.0078C39.5078 27.896 39.2122 28.7589 38.6676 29.4605C38.123 30.1621 37.3604 30.6625 36.5 30.8828"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
