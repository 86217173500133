import React, {useEffect, useState} from 'react';
import "./index.less";
import {useSelector} from "react-redux";
import Spinner from "../../components/spiner/Spinner";
import {Collapse, Select} from "antd";


const CheckFormPage = () => {
  const {allGovForms, loader} = useSelector(({govFormsInfo}) => govFormsInfo);
  const [formName, setFormName] = useState("")
  const [valuesFromObject, setValuesFromObject] = useState([])
  const [values, setValues] = useState([])

  const checkFormsFoo = (name) => {
    setFormName(prevState => {
      if (prevState === name) {
        setValuesFromObject([])
      }
    })
    let arr = []
    Object.keys(allGovForms).forEach(govFormName => {
      allGovForms[govFormName].forEach(formItems => {
        Object.values(formItems).forEach(item => {
          if (typeof item === "string" && item === name) {
            arr.push(govFormName)
            setValuesFromObject(Array.from(new Set(arr)))
          }
        })
      })
    })
  }

  useEffect(() => {
    let newArr = []
    allGovForms[formName]?.forEach((values) => {
      if (typeof values.label === "string" && values.label.length ) {
        newArr.push(values.label)
        setValues(Array.from(new Set(newArr)))
      }
    })
  }, [allGovForms, formName, valuesFromObject])

  const selectForm = (event) => {
    setFormName(event)
  }

  return (
    <div className={"CheckForm-container"}>
      <h1>Check Form Fill Presents</h1>
      {loader
        ? <div className={"CheckForm-container_checkList"}>Getting data from forms ... please wait<Spinner/></div>
        : <div>
          <Select
            showSearch
            optionFilterProp="children"
            className={"CheckForm-container_formSelector"}
            placeholder="Select a form"
            onSelect={selectForm}
          >
            {Object.keys(allGovForms).map(
              formsName =>
                <Select.Option
                  key={formsName}
                  value={formsName || formName}
                  children={formsName}
                />)}
          </Select>

          <div className={"CollapseContainer"}>

            <h2>{formName}</h2>
            <Collapse accordion  onChange={(e) => checkFormsFoo(values[e])}>
              {values.map((values, id) => {
                return (
                  <Collapse.Panel key={id} header={values}>
                    {
                      valuesFromObject.map((formId) => {
                        return (
                          <p key={formId}>{formId}</p>
                        )
                      })
                    }
                  </Collapse.Panel>
                )
              })}
            </Collapse>
          </div>
        </div>
      }


    </div>
  )
}

export default CheckFormPage
