/* eslint-disable react-redux/useSelector-prefer-selectors */
import React, { FC, useCallback, useState, useMemo } from "react"
import "./AddressDetailsForm.scss"
import GInputText from "../../../molecules/InputText/InputText"
import { Form } from "antd"
import useSubmittableForm from "../../../../../../../dashboard-govplus-front/src/hooks/useSubmittableForm"
import GText from "../../../atoms/Text/Text"
import GButton from "../../../molecules/Button/GButton"
import { COLORS, COLORS_NEW } from "../../../types/Colors"
import { SIZE, BUTTON_TYPE } from "../../../types/types"
import { FormInput, MissingFieldsTypes, createMaskRule, usaStates } from "../../../../../util/IdentityProtectionHelper"
import GSelect from "../../../molecules/Select/Select"
import useIsMobile from "../../../../../../../dashboard-govplus-front/src/hooks/useIsMobile"
import { useHelperSubscription } from "../../../../../../../dashboard-govplus-front/src/hooks/useHelperSubscription"
import { patchUserAddress, updateCustomer } from "../../../../../../../dashboard-govplus-front/src/appRedux/actions"
import AuthUserBearerTokenRepository from "../../../../../persistence/session/AuthUserBearerTokenRepository"
import { useUser } from "../../../../../../../dashboard-govplus-front/src/hooks/useUser"
import { useDispatch } from "react-redux"
import InputMask from "react-input-mask"

const AddressDetailsForm: FC<FormInput> = ({ recordType, handleFormActions }) => {
  const [addressAccountForm] = Form.useForm()
  const isMobile = useIsMobile(768)
  const submittable = useSubmittableForm(addressAccountForm, [
    MissingFieldsTypes.address1,
    MissingFieldsTypes.city,
    MissingFieldsTypes.state,
    MissingFieldsTypes.zip,
  ])

  const dispatch = useDispatch()
  const { userFlattenData } = useUser()
  const oldEmail = userFlattenData?.data.personal.communication.email
  const client_token = AuthUserBearerTokenRepository.getInstance().getValue()

  const [addressState, setAddressState] = useState(usaStates[0].value)
  const [addressZip, setAddressZip] = useState("")
  const [addressCity, setAddressCity] = useState("")
  const [address1, setAddress1] = useState("")
  const [address2, setAddress2] = useState("")
  const [showAddress2, setShowAddress2] = useState(false)

  const handleAddressChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress1(event.target.value)
  }, [])

  const handleAddress2Change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress2(event.target.value)
  }, [])

  const handleCityChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAddressCity(event.target.value)
  }, [])

  const handleZipChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAddressZip(event.target.value)
  }, [])

  const handleStateChange = useCallback((value: string) => {
    setAddressState(value)
  }, [])

  const submittedToGovData = useMemo(() => {
    return {
      missingFieldsData: {
        address1,
        address2,
        city: addressCity,
        state: addressState,
        zipCode: addressZip,
        oldEmail,
        client_token,
      },
    }
  }, [address1, address2, addressCity, addressState, addressZip, client_token, oldEmail])

  const submittedToEnfortraData = useMemo(() => {
    return {
      address: { address1, address2, city: addressCity, state: addressState, zipCode: addressZip },
    }
  }, [address1, address2, addressCity, addressState, addressZip])

  const handleFormSubmit = async () => {
    try {
      const valid = await addressAccountForm.validateFields()
      if (valid) {
        // update address on enfortra side
        dispatch(patchUserAddress(submittedToEnfortraData))
        // update address on gov side
        dispatch(updateCustomer(submittedToGovData))
        handleFormActions(submittedToGovData)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Form
      layout={"vertical"}
      form={addressAccountForm}
      className={"AddressDetailsForm"}
      initialValues={{ remember: true }}
      name={"basic"}
      onFinish={handleFormSubmit}
    >
      <Form.Item
        className={"AddressDetailsForm__Item"}
        key={MissingFieldsTypes.address1}
        name={MissingFieldsTypes.address1}
        label={
          <div className={"MissingFieldsModal__Item__Label"}>
            <GText text={"Street address"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
          </div>
        }
        rules={[{ required: false, message: "Address is required", pattern: /^[a-zA-Z0-9\s]+$/ }]}
      >
        <div className={showAddress2 ? "AddressDetailsForm__Item" : "AddressDetailsForm__Item__Annotation"}>
          <GInputText onChange={handleAddressChange} />
          {!showAddress2 && (
            <GText
              text={"Add Apt #, Floor, etc."}
              color={COLORS.ROYAL_BLUE_600}
              size={SIZE.PARAGRAPH_REGULAR_16}
              style={{ marginTop: "4px" }}
              onClick={() => setShowAddress2(!showAddress2)}
            />
          )}
        </div>
      </Form.Item>

      {showAddress2 && (
        <Form.Item
          className={"AddressDetailsForm__Item"}
          key={MissingFieldsTypes.address2}
          name={MissingFieldsTypes.address2}
          label={
            <div className={"MissingFieldsModal__Item__Label"}>
              <GText text={"Address 2"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
            </div>
          }
          rules={[{ required: false }]}
        >
          <GInputText onChange={handleAddress2Change} />
        </Form.Item>
      )}

      <Form.Item
        className={"AddressDetailsForm__Item"}
        key={MissingFieldsTypes.city}
        name={MissingFieldsTypes.city}
        rules={[{ required: false, message: "City is required", pattern: /^[a-zA-Z\s]+$/ }]}
        label={
          <div className={"MissingFieldsModal__Item__Label"}>
            <GText text={"City"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
          </div>
        }
      >
        <GInputText value={""} onChange={handleCityChange} />
      </Form.Item>

      <div className={"AddressDetailsForm__Row"}>
        <Form.Item
          label={
            <div className={"MissingFieldsModal__Item__Label"}>
              <GText text={"State"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
            </div>
          }
          key={MissingFieldsTypes.state}
          name={MissingFieldsTypes.state}
          initialValue={"Select state"}
          rules={[{ required: false, message: "Please select a state" }]}
        >
          <GSelect
            onChange={handleStateChange}
            options={usaStates}
            defaultValue={usaStates[0].value}
            placeHolder={"Select type"}
          />
        </Form.Item>
        <Form.Item
          className={"AddressDetailsForm__Item"}
          label={
            <div className={"MissingFieldsModal__Item__Label"}>
              <GText text={"Zip Code"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
            </div>
          }
          key={MissingFieldsTypes.zip}
          name={MissingFieldsTypes.zip}
          rules={[{ required: false, message: "Please enter a valid ZIP code", pattern: /^[0-9]{5,5}$/ }]}
        >
          <InputMask mask={createMaskRule(5)} maskPlaceholder={null} onChange={handleZipChange}>
            <GInputText inputMode={"numeric"} />
          </InputMask>
        </Form.Item>

        {!isMobile && (
          <GButton
            click={handleFormSubmit}
            disabled={!submittable}
            type={BUTTON_TYPE.SECONDARY}
            showIcon={false}
            style={{ height: "44px" }}
            text={"Add"}
          />
        )}
      </div>

      {isMobile && (
        <div className={"AddressDetailsForm__Actions"}>
          <GButton
            click={handleFormSubmit}
            disabled={!submittable}
            type={BUTTON_TYPE.SECONDARY}
            showIcon={false}
            style={{ height: "44px" }}
            text={"Add"}
          />
        </div>
      )}
    </Form>
  )
}

export default AddressDetailsForm
