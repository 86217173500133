import React from 'react';
import { SvgType } from '../SvgType';

export const SvgPlusIcon = ({ width = 25, height = 25 }: SvgType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 5.5V19.5" stroke="#848484" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.5 12.5H19.5" stroke="#848484" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
