import VerificationSignIn from "./header/verificationSignIn";
import VerificationSignUp from "./header/verificationSignUp";
import React from "react";
import FooterSignIn from "./footer/footerSignIn";
import FooterSignUp from "./footer/footerSignUp";

export const verificationHelper = {
  verificationRender: (identifyState, resendMessageShowStatus, checkPhoneExist) => {
    switch (identifyState) {
      case "sendCode" :
        return <VerificationSignIn
          resendMessageShowStatus={resendMessageShowStatus}
          checkPhoneExist={checkPhoneExist}
        />
      case "confirmPhone" :
        return <VerificationSignUp
          resendMessageShowStatus={resendMessageShowStatus}
          checkPhoneExist={checkPhoneExist}
        />
      default:
        return null
    }
  },
  footerRender: (identifyState, history, resendVerificationCode, resendSmsTimerStatus, resendSmsTimer, dispatch) => {
    switch (identifyState) {
      case "sendCode":
        return <FooterSignIn
          history={history}
          resendVerificationCode={resendVerificationCode}
          resendSmsTimerStatus={resendSmsTimerStatus}
          resendSmsTimer={resendSmsTimer}
          dispatch={dispatch}
        />
      case "confirmPhone":
        return <FooterSignUp
          history={history}
          resendVerificationCode={resendVerificationCode}
          resendSmsTimerStatus={resendSmsTimerStatus}
          resendSmsTimer={resendSmsTimer}
          dispatch={dispatch}
        />
      default:
        return null
    }
  },
  checkPhoneExist: () => {
    if (localStorage.getItem("phoneSlice")) {
      return localStorage.getItem("phoneSlice")
    }
    if (localStorage.getItem("phone")) {
      return localStorage.getItem("phone")?.slice(-4)
    }
    if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user")).phone.toString().slice(-4)
    }
  }
};
