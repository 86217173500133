import React, {FC} from "react";

import GServiceCardActive from "../../templates/GServiceCard/GServiceCardActive";
import RenewalDocumentCardContent from "../RenewalDocumentCardContent/RenewalDocumentCardContent";
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes";
import GServiceCardInactive from "../../templates/GServiceCard/GServiceCardInactive";
import { IRenewalDocument } from "../../types/Models";

type RenewalDocumentCardProps = {
    document: IRenewalDocument,
    className?: string,
    onSubscribe?: () => void
}

const RenewalDocumentCard: FC<RenewalDocumentCardProps> = (
    {
        document,
        className,
        onSubscribe
    }) => {

    const content = () => {
        return <RenewalDocumentCardContent 
                    document={document}
                    onSubscribe={onSubscribe}
                />
    }

    return (
        <>
            {
                document.activatedSince && <GServiceCardActive
                    badgeIcon={ICONS_SVG_TYPE.RENEWAL_CIRCLE_BODY}
                    imageURL={document.image}
                    Content={content}
                    className={className}
                />
            }
            {
                !document.activatedSince && <GServiceCardInactive
                    imageURL={document.image}
                    Content={content}
                    className={className}
                />
            }
        </>
    )

}

export default RenewalDocumentCard