import IPersistenceHandler from "./IPersistenceHandler";

class LocalStorageHandler implements IPersistenceHandler {

    get(key: string): string | null {
        return localStorage.getItem(key);
    }

    has(key: string): boolean {
        return this.get(key) !== null;
    }

    set(key: string, value: string): void {
        localStorage.setItem(key, value)
    }

    remove(key: string): void {
        localStorage.removeItem(key)
    }
}

export default LocalStorageHandler