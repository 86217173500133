import React, { FC } from "react"
import GText from "../../atoms/Text/Text"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import { BOX_TYPE, SIZE } from "../../types/types"
import "./GBoxImage.scss"
import GNotification, {NOTIFICATION_TYPE} from "../../organisms/Notification/Notification";
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"


export type GBoxImageProps = {
  type: string,
  title?: string,
  numberTitle?: string,
  subtitle?: string,
  images: Array<any>,
  imageWidth?: number | string,
  imageHeight?: number | string,
  information?: string,
}

const GBoxImage: FC<GBoxImageProps> = ({
  type,
  title = "",
  numberTitle,
  subtitle = "",
  images,
  imageWidth,
  imageHeight,
  information,
}) => {
  const breakPoint = useBreakpoint()
  const putimage = (img: any, key: any) => {
    const correctImage = key === 0
    return (
      <div key={key} className="GBoxImage__Vertical__Container__ImgContainer__Img">
        <img src={img} alt="imagen_step" style={imageStyle} />
        <GText text={correctImage ? 'Correct' : 'Incorrect'} color={correctImage ? COLORS_NEW.GREEN_600 : COLORS_NEW.RED_600} size={SIZE.PARAGRAPH_BOLD_14} />
      </div>
    )
  }

  const imageStyle: React.CSSProperties = {
    width: imageWidth,
    height: imageHeight,
    minWidth: imageWidth,
    minHeight: imageHeight,
  }

  return (
    <div className={`GBoxImage`}>
      {type === BOX_TYPE.HORIZONTAL && (
        <div className="GBoxImage__Horizontal">
          <div className="GBoxImage__Horizontal__TitleContainer">
            <div className="GBoxImage__Horizontal__TitleContainer__Title">
              <GText text={title} color={COLORS_NEW.BLACK_1000} size={SIZE.PARAGRAPH_REGULAR_16} />
            </div>
            <div className="GBoxImage__Horizontal__TitleContainer__SubTitle">
              <GText text={subtitle} color={COLORS_NEW.BLACK_1000} size={SIZE.PARAGRAPH_REGULAR_16} />
            </div>
          </div>
          <div className="GBoxImage__Horizontal__ImgContainer">
            <img src={images[0].image} alt="header_step" style={imageStyle} />
          </div>
        </div>
      )}

      {type === BOX_TYPE.VERTICAL && (
        <div className="GBoxImage__Vertical">
          <div className="GBoxImage__Vertical__Container">
            <div className="GBoxImage__Vertical__Container__DescriptionContainer">
              <div className={"GBoxImage__Vertical__Container__DescriptionContainer__Title"}>
                <div className="GBoxImage__Vertical__Container__DescriptionContainer__Title__Index">
                  <GText
                      text={numberTitle?.toString() ?? ""}
                      size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_16 : SIZE.PARAGRAPH_BOLD_14}
                      color={COLORS_NEW.ROYAL_BLUE_700}
                  />
                </div>
                <GText text={title} color={COLORS_NEW.BLACK_1000} size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16} />
              </div>
              <GText text={subtitle} color={COLORS_NEW.BLACK_800} size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14} innerHtml={subtitle} />
            </div>
            <div className={"GBoxImage__Vertical__Container__ImgContainer"}>{images.map((image: any, key) => putimage(image.image, key))}</div>
          </div>
          {information && (
            <div className={"GBoxImage__Vertical__Information"}>
              <GNotification
                text={information}
                type={NOTIFICATION_TYPE.INFORMATION}
                icon={ICONS_SVG_TYPE.INFORMATION}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default GBoxImage
