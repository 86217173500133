import React, {useEffect, useState} from "react";
import {SignInHelper} from "./SignInHelper";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import ReactDomServer from "react-dom/server";


export const useAlertMessagesInSignIn = (alertMessage, eMail)=>{
  const [uiMessage, setUiMessage] = useState("")

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (alertMessage) {
      const {messageInfo, redirectPage,} = SignInHelper.checkSignInMessage(
        alertMessage,
        eMail,
        dispatch,
        history
      );

      const message = React.isValidElement(messageInfo) ? ReactDomServer.renderToString(messageInfo) : messageInfo
      setUiMessage(message);

      if (redirectPage) {
        redirectPage();
      }
    }
  }, [alertMessage]);

  return([
    uiMessage,
    setUiMessage,
  ]);
}
