import React from 'react';
import {SvgType} from "../SvgType";

export const SvgIconDeclineButton = ({width = 21, height = 20}: SvgType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 5L5.5 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.5 5L15.5 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
