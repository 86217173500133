import React, {FC, useEffect} from 'react';
import './COAStatusTracking.scss'
import {
    AddressSectionProps,
    AgencyOrProvider,
    COAStatusTrackingProps,
    StatusSectionProps,
} from "./COAStatusTracking.props";
import GText from '../../../../atoms/Text/Text';
import {SIZE} from "../../../../types/types";
import {COLORS_NEW} from "../../../../types/Colors";
import {
    AddressTitles,
    AgencyOrProviderTitles, formatStatus,
} from "../../../../../../util/ProcessingTrackingHelper";
import {useApplications} from "../../../../../../../../dashboard-govplus-front/src/hooks/useApplications"
import GSpinner from "../../../../atoms/Spinner/Spinner";

const AddressSection: FC<AddressSectionProps> = ({title, addressData}) => {
    return (
        <div className='GChangeAddressStatus__top--section'>
            <GText text={title} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.GREEN_700}/>

            <div className={'GChangeAddressStatus__top--address'}>

                <GText text={'Address 1'} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_800}/>

                <GText text={addressData?.address1} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800}/>

                <GText text={'ZIP Code'} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_800}/>

                <GText text={addressData?.zipcode} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800}/>

                <GText text={'City'} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_800}/>

                <GText text={addressData?.city_name} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800}/>

                <GText text={'State'} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_800}/>

                <GText text={addressData?.state_code} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800}/>
            </div>
        </div>
    );
};

const StatusSection: React.FC<StatusSectionProps> = ({title, details}) => {

    const headerCells = ['Name', 'Status', 'Date'];
    return (
        <div className="GChangeAddressStatus__section">
            <div className="GChangeAddressStatus__section--heading">
                <GText text={title} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000}/>
            </div>

            <div className="GChangeAddressStatus__section--details">
                <div className="GChangeAddressStatus__section--details__row">
                    {headerCells.map((header, index) => (
                        <div key={`${header}-${index}`} className="GChangeAddressStatus__section--details__cell">
                            <GText text={header} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_1000}/>
                        </div>
                    ))}
                </div>

                {details.map((detail: AgencyOrProvider, index: number) => {
                    const isLast = index === details.length - 1;
                    return <div
                        key={`${detail}-${index}`}
                        className={`GChangeAddressStatus__section--details__row ${isLast ? "GChangeAddressStatus__section--details__row-last" : ""}`}
                    >

                        <div className="GChangeAddressStatus__section--details__cell">
                            <GText text={detail.name} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800}/>
                        </div>

                        <div className="GChangeAddressStatus__section--details__cell">
                            <GText text={formatStatus(detail.status)} size={SIZE.PARAGRAPH_BOLD_16}
                                   color={detail.status === 'pending' ? COLORS_NEW.YELLOW_600 : COLORS_NEW.GREEN_600}/>
                        </div>

                        <div className="GChangeAddressStatus__section--details__cell">
                            <GText text={detail.date} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800}/>
                        </div>

                    </div>
                })}
            </div>
        </div>
    );
};

const GCOAStatusTracking: FC<COAStatusTrackingProps> = ({application_id, showFooter}) => {
    const {filteredProducts, getProductsInfo, allProductsLoader} = useApplications()

    if (!filteredProducts)
        return <></>

    useEffect(() => {
        getProductsInfo()
    }, [])

    // @ts-ignore
    const myProduct = filteredProducts[application_id]?.add;
    let services, governmentAgencies, oldAddressVerification, newAddressVerification
    services = myProduct?.services
    governmentAgencies = myProduct?.governmentAgencies
    oldAddressVerification = myProduct?.billingAddress?.old_address_verification
    newAddressVerification = myProduct?.billingAddress?.new_address_verification

    if (!oldAddressVerification && !newAddressVerification) return null;
    if (!services && !governmentAgencies) return null;

    return (
        allProductsLoader ? <div style={{display: "flex", justifyContent: "center"}}><GSpinner/></div> : <div className="GChangeAddressStatus">
            <div className={'GChangeAddressStatus__top'}>
                {oldAddressVerification && <AddressSection
                    title={AddressTitles.OldAddress}
                    addressData={oldAddressVerification?.address_verified}
                />}

                {newAddressVerification && <AddressSection
                    title={AddressTitles.NewAddress}
                    addressData={newAddressVerification?.address_verified}
                />}
            </div>

            {(governmentAgencies && showFooter) && <StatusSection
                title={AgencyOrProviderTitles.GovernmentAgencies}
                details={governmentAgencies}
            />}

            {(services && showFooter) && <StatusSection
                title={AgencyOrProviderTitles.ServiceProviders}
                details={services}
            />}
        </div>
    );
}

export default GCOAStatusTracking