import React, {useEffect, useState} from "react";
import ReactDomServer from 'react-dom/server';
import "./index.less"
import {Form} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {hideMessage, userSignIn} from "../../appRedux/actions";
import Header from "../Header";
import Spinner from "../../components/spiner/Spinner";
import {SignInHelper} from "./SignInHelper";
import {useAlertMessagesInSignIn} from "./useAlertMessagesInSignIn";
import {checkEmailRegEx} from "../../assets/helpers/getParams";
import {ICONS_SVG_TYPE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import GSignIn from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/SignIn/SignIn";
import GLogo from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Logo/Logo";

const SignIn = () => {
  const {alertMessage, loader, userData} = useSelector(({auth}) => auth);
  const {eMail} = useSelector(({auth}) => auth.userData);

  const history = useHistory();
  const dispatch = useDispatch();

  const [uiEmailMessage, setUiEmailMessage] = useState("")
  const [uiPasswordMessage, setUiPasswordMessage] = useState("")
  const [uiMessage, setUiMessage] = useAlertMessagesInSignIn(alertMessage, eMail);
  const [isLocalStorageHasOtp, setIsLocalStorageHasOtp] = useState(null);
  const [formSIgnIn] = Form.useForm()

  useEffect(() => {
    if (alertMessage) {
      const {messageInfo, redirectPage} = SignInHelper.checkSignInMessage(
        alertMessage,
        eMail,
        dispatch,
        history,
        isLocalStorageHasOtp
      );

      const message = React.isValidElement(messageInfo) ? ReactDomServer.renderToString(messageInfo) : messageInfo

      setUiMessage(message);

      if (redirectPage) {
        redirectPage();
      }
    }
  }, [alertMessage, uiMessage]);

  const onFinish = ({email, password}) => {
    const emailValCheck = checkEmailRegEx(email);

    const otpTokenStatus = !!localStorage.getItem(`otpToken/${email}`)
    setIsLocalStorageHasOtp(otpTokenStatus)

    const emailErrorMessages = SignInHelper.checkEmailFields(email);
    setUiEmailMessage(emailErrorMessages);
    const passErrorMessages = SignInHelper.checkPasswordFields(password);
    setUiPasswordMessage(passErrorMessages);

    if (email && password && emailValCheck) {
      dispatch(userSignIn({email: email.trim(), password}))
    }
  };

  const emailValidationOnBlur = (emailInput)=>{
    const emailForField = emailInput.target.value.trim();
    formSIgnIn.setFieldsValue({
      email: emailForField,
    })

    const emailErrorMessages = SignInHelper.checkEmailFields(emailForField)
    setUiEmailMessage(emailErrorMessages)
  }
  const passwordValidationOnBlur = (emailInput)=>{
    const passwordErrorMessages = SignInHelper.checkPasswordFields(emailInput.target.value)
    setUiPasswordMessage(passwordErrorMessages)
  }

  return (
    <div className="gx-app-login-wrap">
      <div className={'gx-app-login-logo'}>
        <GLogo width={110} height={28} />
      </div>
      <GSignIn
        formOnFinish={onFinish}
        form={formSIgnIn}
        onEmailChange={()=>{
          dispatch(hideMessage())
          setUiEmailMessage("");
        }}
        onEmailBlur={emailValidationOnBlur}
        emailSuffixErrorIcon={ICONS_SVG_TYPE.X_CIRCLE}
        emailErrorMessage={uiEmailMessage}
        onPasswordChange={()=>{
          dispatch(hideMessage())
          setUiPasswordMessage("")
        }}
        onPasswordBlur={passwordValidationOnBlur}
        passwordErrorMessage={uiPasswordMessage}
        loader={loader}
        onFooterSecondLinkClick={() => {
          dispatch(hideMessage())
        }}
        signInErrorMessage={uiMessage}
      />
    </div>
  );
};

export default SignIn;
