import {
  CHECK_DUPLICATE_PRODUCT,
  CHECK_DUPLICATE_PRODUCT_CLEAR,
  CHECK_DUPLICATE_PRODUCT_SUCCESS,
  FIRST_SELECTED_PRODUCT,
  GET_FELLING_PRESENT,
  GET_FELLING_PRESENT_SUCCSSES,
  GET_GOVERNMENT_PRODUCTS,
  GET_GOVERNMENT_PRODUCTS_SUCCSSES,
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCSSES, GET_WEBFLOW_SOLUTIONS_DATA_IN_DASH,
  SEND_APPOINTMENT,
  SET_BREADCRUMBS_VALUE,
  SET_CHECK_STATUS_EMAIL_PRODUCT_ID,
  SET_SELECTED_FORM_ID
} from "../../constants/ActionTypes";

export const getProducts = (authToken) => {
  return {
    type: GET_PRODUCTS,
    authToken
  };
};

export const getProductsSuccsses = (products) => {
  return {
    type: GET_PRODUCTS_SUCCSSES,
    payload: products
  };
};

export const getGovernmentProducts = () => {
  return {
    type: GET_GOVERNMENT_PRODUCTS,
  };
};

export const getGovernmentProductsSuccsses = (anotherGovProducts) => {
  return {
    type: GET_GOVERNMENT_PRODUCTS_SUCCSSES,
    payload: anotherGovProducts
  };
};

export const getWebFlowSolutionsInDash = () => {
  return {
    type: GET_WEBFLOW_SOLUTIONS_DATA_IN_DASH
  };
};

export const getFellingPresent = () => {
  return {
    type: GET_FELLING_PRESENT,
  };
};

export const getFellingPresentSuccsses = (payload) => {
  return {
    type: GET_FELLING_PRESENT_SUCCSSES,
    payload
  };
};

export const setFirstUserSelectedProduct = (payload) => {
  return {
    type: FIRST_SELECTED_PRODUCT,
    payload
  };
};

export const sendAppointment = (payload) => {
  return {
    type: SEND_APPOINTMENT,
    payload
  };
};

export const setBreadcrumbsValue = (obj) => {
  return {
    type: SET_BREADCRUMBS_VALUE,
    payload: obj
  }
}

export const setCheckStatusEmailProductId = (payload) => {
  return {
    type: SET_CHECK_STATUS_EMAIL_PRODUCT_ID,
    payload
  }
}

export const checkProductForDuplicate = (payload) => {
  return {
    type: CHECK_DUPLICATE_PRODUCT,
    payload
  };
};

export const checkProductForDuplicateSuccess = (duplicateProductInfo) => {
  return {
    type: CHECK_DUPLICATE_PRODUCT_SUCCESS,
    payload: duplicateProductInfo
  };
};

export const checkProductForDuplicateClear = () => {
  return {
    type: CHECK_DUPLICATE_PRODUCT_CLEAR,
  };
};

export const setSelectedFormId = (formId) => {
  return {
    type: SET_SELECTED_FORM_ID,
    payload: formId
  };
};

