import IPersistenceHandler from "../IPersistenceHandler";
import CookiesHandler from "../CookiesHandler";
import IPersistenceRepository from "../IPersistenceRepository";

/**
 * Base class for cookies repositories
 * @class BaseCookiesRepository
 * @extends IPersistenceRepository
 * @description This class is responsible for managing the cookies
 */
abstract class BaseCookiesRepository implements IPersistenceRepository {

    /**
     * @property {IPersistenceHandler} _handler
     * @private
     * @description The handler for the cookies
     */
    private _handler: IPersistenceHandler;

    /**
     * @property {string} _key
     * @private
     * @description The key for the cookies
     */
    private readonly _key: string;

    /**
     * @constructor
     * @protected
     * @description The constructor of the class
     */
    protected constructor() {
        this._key = this.getKeyName();
        this._handler = new CookiesHandler();
    }

    /**
     * @method setValue
     * @param value
     * @param defaultValue
     * @description Set the value for the cookies
     */
    setValue(value: string, defaultValue?: string | null): void {
        this._handler.set(this._key, value ?? defaultValue)
    }

    /**
     * @method getValue
     * @description Get the value for the cookies
     */
    getValue(): string | null {
        return this._handler.get(this._key);
    }

    /**
     * @method exist
     * @description Verify if the cookies exist
     */
    exist(): boolean {
        return this._handler.has(this._key);
    }

    /**
     * @method getKeyName
     * @description Abstract method to get the key name
     */
    abstract getKeyName(): string;

    removeItem(): void {
        return this._handler.remove(this._key);
    }
}

export default BaseCookiesRepository;
