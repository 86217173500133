import React from "react"
import { Radio } from "antd"
import "./PaymentMethodInformation.scss"
import GText from "../../../atomicDesign/atoms/Text/Text";
import {PaymentMethod, SIZE} from "../../../atomicDesign/types/types";
import {COLORS, COLORS_NEW} from "../../../atomicDesign/types/Colors";
import {getCardImage, getCardName} from "../../../../util/CreditCardHelper";
import {GeneralText} from "../../../../shared/text/Text";

type PaymentMethodInformationProps = {
  paymentMethod: PaymentMethod,
  isDefault?: boolean
}

// eslint-disable-next-line max-len
const PaymentMethodInformation: React.FunctionComponent<PaymentMethodInformationProps> = ({
  paymentMethod,
  isDefault = false

}) => {
  const cardName = getCardName(paymentMethod.cardBrand)
  const cardImage = getCardImage(paymentMethod.cardBrand)
  return (
    <Radio id={paymentMethod.id} value={paymentMethod.id} className={'GPaymentMethodRadioButton'}>
      <div className={"content"}>
        <div className={'line-1'}>
          <div className={'brand'}>
            {cardImage && (
                <img src={cardImage} alt={cardName} />
            )}
           <GText text={cardName} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_800}/>
            {isDefault && <GText text={GeneralText.Default} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.GREEN_600} />}
          </div>
          <div>
            <GText text={`Expires ${paymentMethod.cardExpiryMonth}/${paymentMethod.cardExpiryYear}`} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
          </div>
        </div>
        <div>
          <GText text={`Ending in ${paymentMethod.cardLast4}`} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
        </div>
      </div>
    </Radio>
  )
}

export default PaymentMethodInformation
