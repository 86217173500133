import {
  setStatusPassportDetails,
  setStatusPhotoUpload,
  setStatusQuestionary,
  setStatusSignature,
  setStatusUploadAdditionalDocuments,
  setStatusUploadDocuments,
} from "../../Redux/store/additionalForm/additional.actions"

export const arrayActions = [
  {
    status: "signature",
    action: setStatusSignature,
  },
  {
    status: "pending_signature",
    action: setStatusSignature,
  },
  {
    status: "questionnaire",
    action: setStatusQuestionary,
  },
  {
    status: "missing_information",
    action: setStatusPassportDetails,
  },
  {
    status: undefined,
    action: setStatusPassportDetails,
  },
  {
    status: "pending_documents",
    action: setStatusUploadDocuments,
  },
  {
    status: "upload_photo",
    action: setStatusPhotoUpload,
  },
  {
    status: "identity_document",
    action: setStatusUploadDocuments,
  },
  {
    status: "citizenship_document",
    action: setStatusUploadAdditionalDocuments,
  },
]

export const arrayPagesName = [
  "success_address",
  "passport_after",
  "passport_new_after",
  "name_change_after",
  "sss_after3",
  "electronic_signature_page",
  "tsa_questionnaire_page",
  "tsa_questionnaire_page_renewal",
  "address_after",
  "taxes_after"
]
