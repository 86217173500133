import React from "react"
import {
  BasicBlockContainerProps,
  ItemArrayComponentsInterface,
  ItemInterface,
  LayoutBaseContainerInterface,
  RenderBasicChildrenInterface,
} from "../interfaces/interfaces"
import { RootState } from "../../../../../App"

export const arrayStepSelector = (state: RootState) => state.step
export const uploadSelector = (state: RootState) => state.upload
export const formSelector = (state: RootState) => state.form
export const additionalSelector = (state: RootState) => state.additional
export const loadingSelector = (state: RootState) => state.loading
export const validationSelector = (state: RootState) => state.validation
export const emailSelector = (state: RootState) => state.email
export const userSelector = (state: RootState) => state.user

export const createClassName = (className: string, state: string | undefined) => `${className} ${state ?? ""}`

export const renderBasicBlockContainer = (
  { className, text, childrenItem, arrayComponents, state }: RenderBasicChildrenInterface,
  LayoutContainer: React.FC<LayoutBaseContainerInterface>,
  props: {} = {}
) => {
  let element = text
  if(typeof text === 'string') {
    element = <div dangerouslySetInnerHTML={{__html: text}} />
  }
  return (
      <LayoutContainer className={createClassName(className, state)} {...props}>
        {element}
        {childrenItem?.map(
            (child: ItemInterface | undefined) =>
                child && (
                    <React.Fragment key={`${child?.name} ${child?.sub_name}`}>
                      {setPropsCurrentComponent(
                          child,
                          findComponentByTag(arrayComponents, child.tagName),
                          arrayComponents,
                          props
                      )}
                    </React.Fragment>
                )
        )}
      </LayoutContainer>
  )
}

export const setPropsCurrentComponent = (
  item: ItemInterface,
  Component: React.FC<BasicBlockContainerProps> | undefined,
  arrayComponents: ItemArrayComponentsInterface[] | undefined,
  props: {}
) => (Component ? <Component item={item} arrayComponents={arrayComponents} {...props} /> : undefined)

export const findComponentByTag = (arrayComponents: ItemArrayComponentsInterface[] | undefined, tagNameBlock: string) =>
  arrayComponents?.find(({ tagName }) => tagName.find((itemTagName) => itemTagName === tagNameBlock))?.Component
