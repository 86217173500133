import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

const OUTER_ID = "outer_id"

class OuterIdRepository extends BaseSessionStorageRepository {
    private static _instance: OuterIdRepository;

    private constructor() {
        super();
    }

    public static getInstance(): OuterIdRepository {
        if (!OuterIdRepository._instance) {
            OuterIdRepository._instance = new OuterIdRepository();
        }
        return OuterIdRepository._instance;
    }

    getKeyName(): string {
        return OUTER_ID;
    }
}

export default OuterIdRepository;
