import React, {useRef, useState} from 'react';
import './FollowInstructionsCard.scss';
import {FollowInstructionsCardProps} from "./GFollowInstructionsCard.props";
import GVideoPlayer from '../../../../../molecules/VideoPlayer/VideoPlayer';

const GFollowInstructionsCard: React.FC<FollowInstructionsCardProps> = ({videoLink}) => {
  const [isLoading, setIsLoading] = useState(true);
  const videoRef = useRef(null);

  const handleVideoLoaded = () => {
    setIsLoading(false);
  };

  return (
    <div className={'GFollowInstructionsCard'}>
      <div className={'GFollowInstructionsCard__VideoContainer'}>
        {isLoading && (
          <div className={'GFollowInstructionsCard__LoadingOverlay'}>
            <div className={'GFollowInstructionsCard__Preloader'}></div>
          </div>
        )}

        <div className={"GFollowInstructionsCard__VideoContainer__UploadPhotoVideo"}>
           <GVideoPlayer onReady={handleVideoLoaded} width={746} height={420} url={videoLink} loop={true} controls={false} />
        </div>
      </div>
    </div>
  );
};


export default GFollowInstructionsCard;
