import React, {FC, useEffect, useState} from "react";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import "./GUpsellCardComponentHeader.scss";
import {SolutionNames} from "../../../../util/SolutionNames";
import GUpsellCardApplicationCard from "../UpsellCardApplicationCard/GUpsellCardApplicationCard";

type GUpsellCardHeaderComponentProps = {
    acquiredService: string
    offeredService: string
    discount: string
}
const GUpsellCardComponentHeader: FC<GUpsellCardHeaderComponentProps> = ({
                                                                             acquiredService,
                                                                             offeredService,
                                                                             discount
                                                                         }) => {
    const [servicesImages, setServicesImages] = useState({acquiredImage: '', offeredImage: ''});

    useEffect(() => {
        const webFlowData = JSON.parse(localStorage.getItem("webFlowSolutionData") || '');
        if (webFlowData && webFlowData !== '') {
            const servicesImages = Object.keys(webFlowData).reduce((accumulator, currentValue) => {
                if (SolutionNames[currentValue as keyof typeof SolutionNames] === acquiredService) {
                    accumulator.acquiredImage = webFlowData[currentValue]?.illustration?.url
                }
                if (SolutionNames[currentValue as keyof typeof SolutionNames] === offeredService) {
                    accumulator.offeredImage = webFlowData[currentValue]?.illustration?.url
                }
                return accumulator;
            }, {acquiredImage: '', offeredImage: ''})
            setServicesImages(servicesImages);
        }
    }, []);

    return (
        <div className={"GUpsellCardComponentHeader"}>
            <div className="GUpsellCardComponentHeader__Container">
                <div className="GUpsellCardComponentHeader__Container__AcquiredService">
                    <GUpsellCardApplicationCard isOffered={false} solution={acquiredService} status={'Completed'}
                                                productImage={servicesImages?.acquiredImage}/>
                </div>
                <GText text={"+"} size={SIZE.HEADING_XXL} color={COLORS_NEW.BLACK_1000}/>
                <div className="GUpsellCardComponentHeader__Container__OfferedService">
                    <GUpsellCardApplicationCard isOffered={true} discount={discount} solution={offeredService} status={'Ready' +
                        ' to file' +
                        ' now'} productImage={servicesImages?.offeredImage}/>
                </div>
            </div>
        </div>
    )
}

export default GUpsellCardComponentHeader;