import { FC } from "react"
import { renderBasicBlockContainer } from "../UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { BasicBlockContainerProps } from "../UploadPhotoService/interfaces/interfaces"
import LayoutH1ContentContainer from "../Layouts/LayoutH1ContentContainer/LayoutH1ContentContainer"

const HeaderH1ContentComponent: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text },
  arrayComponents,
}) => renderBasicBlockContainer({ className, text, childrenItem, arrayComponents }, LayoutH1ContentContainer)

export default HeaderH1ContentComponent
