import React, { useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { Radio, Form } from "antd"
import { ValidationItemInterface } from "../../../../../Redux/InterfacesEntity/validation.interface"
import { FieldInterface } from "../../../../../Redux/InterfacesEntity/field.interface"
import { setActiveInput, setObservers, setValueCurrentField } from "../../../../../Redux/store/form/form.actions"
import RadioChoosePackageComponent from "./RadioChoosePackageComponent/RadioChoosePackageComponent"
import { getOptionsName } from "../../SelectComponent/createOptions"
import RadioCircleComponent from "./RadioCircleComponent/RadioCircleComponent"
import { getLabelWrapper } from "../../../../../services/service-function/field"
import { hiddenField, setPrefilledValueInSubmitObject } from "../../InputComponent/serviceInput"
import GRadioButton from "../../../../atomicDesign/molecules/RadioButton/RadioButton";

type RadioGroupRenderComponentProps = {
  field: FieldInterface | any,
  value: ValidationItemInterface,
  submitObject: {} | any,
  handlerChangeOptionForm: any,
  currentStep: { additionalStep: boolean },
  tagStoreWithFields: { [key: string]: string },
  tagFields: { [key: string]: string },
  dispatch: any,
}

// eslint-disable-next-line max-len
const RadioGroupRenderComponent: React.FunctionComponent<RadioGroupRenderComponentProps> = ({
  field,
  value,
  submitObject,
  handlerChangeOptionForm,
  currentStep,
  tagStoreWithFields,
  tagFields,
  dispatch,
}) => {
  const { additionalStep } = currentStep
  const options = (field && field.options) || []
  const template = field && field.template ? field.template : ""

  const dynamicHelper = !!field.dynamic_helper

  // for dynamic helpers
  const getDynamicHelper = (option: {} | any, dynamicHelper: boolean) => {
    if (dynamicHelper && option.helper) {
      dispatch(setActiveInput(option))
    }
  }

  const handlerMouseEnterOnOption = (option: {} | any, dynamicHelper: boolean) => {
    getDynamicHelper(option, dynamicHelper)
  }

  const statusTagFields = useMemo(() => hiddenField(field?.tag, tagStoreWithFields), [field?.tag, tagStoreWithFields])
  useEffect(() => {
    setPrefilledValueInSubmitObject(field.name, field.tag, setValueCurrentField, tagStoreWithFields, dispatch)
  }, [dispatch, field.name, field.tag, tagStoreWithFields])

  const fieldLabelPlaceHolder = field?.field_label_placeholder

  const getDynamicVariable = (
    { label, corresponds_tag = [] }: FieldInterface,
    tagStoreWithFields: { [key: string]: string }
  ) => {
    const newLabel = corresponds_tag
      ? corresponds_tag.reduce((acc, tag) => acc.replace(tag, tagStoreWithFields[tag]), label)
      : label
    if (newLabel?.includes?.(String(undefined))) {
      return fieldLabelPlaceHolder
    }
    return newLabel
  }
  const getSavedOptionValue = () => {
    if (submitObject[field.name] == "1") {
      return "Yes"
    } else if (submitObject[field.name] == "0") {
      return "No"
    } else {
      return ""
    }
  }

  useEffect(() => {
    field.observable && dispatch(setObservers(field.observable, getSavedOptionValue() || getOptionsName(field.defaultValue).renderValue))
  }, [])
  
  return (
    <>
      <Form.Item
        label={getLabelWrapper(field, dispatch, additionalStep, getDynamicVariable, tagStoreWithFields)}
        name={field.name}
        required={true}
        initialValue={
          getSavedOptionValue() ||
          getOptionsName(field.defaultValue).renderValue
        }
        rules={[{ required: true, message: "Required" }]}
        preserve={false}
        className={`form-item__${field.name} ${statusTagFields}`}
      >
        <Radio.Group
          onChange={(e: any) => {
            handlerChangeOptionForm(e.target.value, field)
            field.observable && dispatch(setObservers(field.observable, e.target.value))
          }}
          // eslint-disable-next-line prettier/prettier
          className={
            // eslint-disable-next-line max-len
            `input-radio__button input-radio__button-${field.name} ${template} ${field.type} `
          }
          value={value}
          buttonStyle="solid"
        >
          {options &&
            options.map((option: any, index: number) => {
              const disabled = option.disabled === true
              return (
                <React.Fragment key={index}>
                  {(option.type === "button" && (
                    <GRadioButton
                      label={option.optionName}
                      value={option.optionName}
                      disabled={disabled}
                      onClick={(e: any) => {
                        e.stopPropagation()
                      }}
                    />
                  )) ||
                    (option.type === "circle" && (
                      <RadioCircleComponent
                        field={field}
                        handlerMouseEnterOnOption={handlerMouseEnterOnOption}
                        option={option}
                        dynamicHelper={dynamicHelper}
                      />
                    )) ||
                    (option.type === "radioChoosePackage" && (
                      <RadioChoosePackageComponent field={field} option={option} />
                    ))}
                </React.Fragment>
              )
            })}
        </Radio.Group>
      </Form.Item>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  currentStep: state.step.currentStep,
  tagStoreWithFields: state.form.tagStoreWithFields,
  tagFields: state.form.tagFields,
})

export default connect(mapStateToProps)(RadioGroupRenderComponent)
