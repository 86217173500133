import React from "react";
import Spinner from "../components/spiner/Spinner";
import "./lazyLoader.less";


const lazyLoader = (importComp, fallback) => {
  return class extends React.Component {
    state = {
      component: null,
    };

    componentDidMount() {
      importComp().then((comp) => this.setState({ component: comp.default }));
    }

    render() {
      const C = this.state.component;
      return C ? (
        <C {...this.props} />
      ) : fallback ? (
        fallback
      ) : (
        <div className={"productSpinner"}>
          <Spinner/>
        </div>
      );
    }
  };
};
export default lazyLoader;
