import React, { useEffect, useState } from "react"
import { ValidationItemInterface } from "../../../../Redux/InterfacesEntity/validation.interface"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import { connect, useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../App"
import { ModalContentInterface } from "../../../ModalWindows/PrivacyPolicyComponent/PrivacyPolicyComponent"
import { arrayStepSelector } from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { BillingInformation } from "../../BlockFormWithFields/BillingInformation/BlockBillingInformation"
import GFormItem from "../../../atomicDesign/organisms/FormItem/FormItem"
import GCheckBox from "../../../atomicDesign/molecules/CheckBox/CheckBox"
import { env } from "../../../../env"

type CheckBoxAgreeComponentV2Props = {
  billingInformation: BillingInformation,
  field: FieldInterface,
  // eslint-disable-next-line react-redux/no-unused-prop-types
  validationRule: ValidationItemInterface | null,
  // eslint-disable-next-line react-redux/no-unused-prop-types
  value: string,
}

// eslint-disable-next-line max-len
const CheckBoxAgreeComponentV2: React.FunctionComponent<CheckBoxAgreeComponentV2Props> = ({
  field,
  billingInformation,
}) => {
  const [checked, setChecked] = useState(false)

  const dispatch = useDispatch()
  const onCheckboxChange = async (e: any) => {
    await setChecked(e.target.checked)
    dispatch(setValueCurrentField(field.name, e.target.checked ? "on" : "off"))
  }

  const validation = (rule: any, value: any, callback: (error?: string) => void) => {
    if (checked) {
      return callback()
    }
    return callback("Please accept the terms and conditions")
  }

  interface getLinksOnWordPressPagesInterface {
    (classNameProps: string): string | undefined;
  }

  const modalContent = useSelector<RootState, { modalContent: ModalContentInterface | null }>(
    arrayStepSelector
  )?.modalContent

  const getLinksOnWordPressPages = (classNameProps: string) =>
    [
      { className: "terms-of-use", keyStore: "termOfUse" },
      { className: "privacy-policy", keyStore: "privacyPolicy" },
    ].find(({ className }) => className === classNameProps)?.keyStore

  // it was modal, but now it is simple link to wordpress page
  const showOnModal = (
    e: any,
    name: string,
    modalContent: ModalContentInterface | null,
    getLinksOnWordPressPages: getLinksOnWordPressPagesInterface
  ) => {
    if (e.target.className === name) {
      // @ts-ignore
      e.target.href = modalContent?.[getLinksOnWordPressPages(name)]?.wpLink
      e.target.click()
      // dispatch(setValueModal(name))
    }
  }

  useEffect(() => {
    const termsLink = document.querySelector(".terms-of-use")
    const privacyLink = document.querySelector(".privacy-policy")
    if (termsLink || privacyLink) {
      ;[termsLink, privacyLink]
        .filter((item: any) => item)
        .forEach((item: any) => {
          document.addEventListener("click", (e) =>
            showOnModal(e, item.className, modalContent, getLinksOnWordPressPages)
          )
        })
      return () => {
        ;[termsLink, privacyLink].forEach((item: any) => {
          document.removeEventListener("click", (e) =>
            showOnModal(e, item.className, modalContent, getLinksOnWordPressPages)
          )
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const paymentTerms = billingInformation?.payment_terms + "<div class='opacity-field-bottom'></div>"
  const termsUrl = `${env.REACT_APP_CURRENT_DOMAIN}terms-conditions`
  const privacyUrl = `${env.REACT_APP_CURRENT_DOMAIN}privacy-policy`
  const checkBoxLabel = field.checkBoxLabel?.replace("TERMS_URL", termsUrl).replace("PRIVACY_URL", privacyUrl)
  return (
    <>
      {billingInformation?.payment_terms && (
        <div className={`check-box__content-text`}>
          <div className={`check-box__content-text__container`} dangerouslySetInnerHTML={{ __html: paymentTerms }} />
        </div>
      )}
      <GFormItem name={field.name} rules={[{ validator: validation }]}>
        <GCheckBox
          checked={checked}
          onChange={onCheckboxChange}
          innerHtml={checkBoxLabel}
          label={checkBoxLabel ?? ""}
        ></GCheckBox>
      </GFormItem>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  billingInformation: state.step.billingInformation,
})

export default connect(mapStateToProps)(CheckBoxAgreeComponentV2)
