import React from "react"
import { FieldInterface } from "../../Redux/InterfacesEntity/field.interface"

type WithFieldProps = {
  field: FieldInterface | any,
  value: string,
}

function WithField<P>(
  WrappedField: React.ComponentType<(P & WithFieldProps) | any>,
  field: FieldInterface,
  value: string
) {
  const ComponentWithExtraInfo = () => {
    return <WrappedField field={field} value={value} />
  }
  return ComponentWithExtraInfo
}

export default WithField
