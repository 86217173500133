import React from "react"

interface ISvgOvalHeaderStepIcon {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
}

export const SvgOvalHeaderStepIcon = ({ width, height, color, backgroundColor }: ISvgOvalHeaderStepIcon) => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.125" width="48" height="48" rx="24" fill="#13BA68"/>
      <g clipPath="url(#clip0_1022_65799)">
        <path d="M26.0539 25.5C25.8517 25.2919 25.6975 25.0624 25.6251 24.8128C25.31 23.7273 26.4237 22.9436 27.1485 22.041C27.6876 21.3697 27.8804 20.8169 27.8751 19.9648C27.8633 18.0513 26.1962 16.5 24.1251 16.5C22.054 16.5 20.361 18.0513 20.3751 19.9648C20.3814 20.8223 20.6095 21.4346 21.1485 22.041C21.9826 22.9791 22.9074 23.6184 22.672 24.8128C22.6227 25.063 22.4867 25.2923 22.2979 25.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.375 29.625C17.375 28.5895 18.2145 27.75 19.25 27.75H20.75H24.125H27.5H29C30.0355 27.75 30.875 28.5895 30.875 29.625V29.625C30.875 30.6605 30.0355 31.5 29 31.5H19.25C18.2145 31.5 17.375 30.6605 17.375 29.625V29.625Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1022_65799">
        <rect width="18" height="18" fill="white" transform="translate(15.125 15)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
