import React from "react";
import "./index.less"
import SimpleStatusesForProducts from "../../../../simpleStatusesForProducts";
import {useSelector} from "react-redux";
import DeliveredInfoBox from "../../../../../../components/DeliveredInfoBox";

const TrackingToCustomer = ({productId}) =>{
  const {
    allProducts,
    allProductsLoader,
    anotherGovProducts,
  } = useSelector(({allProducts}) => allProducts);
  const {name, lastName} = useSelector(({auth}) => auth.userData);
  const classNameForContainer = "TrackingToCustomer_container"
  const headerText = "Your Social Security card preparation package is on its way";
  const descriptionText = " Your package will be delivered to your door. Please allow 1 to 3 business days.";
  const trackingDescription = "Easily track your package using the link below."
  const buttonText = "";

  const {
      trackingNum,
      trackingUri,
      provider,
      address: {address1, city_name, zipcode, country_code}
  } = allProducts[productId].add;

  const productImage = anotherGovProducts?.find(item => item.id === allProducts[productId]?.product_name)?.image;
  const productName = anotherGovProducts?.find(item => item.id === allProducts[productId]?.product_name)?.title;

  return(
    <div className={classNameForContainer}>
      <SimpleStatusesForProducts
        iconStatus={allProducts[productId]?.status}
        userName={name}
        headerText={headerText}
        description={descriptionText}
        buttonText={buttonText}
        productImage={productImage}
        productName={productName}
        productId={productId}
      />

      <p className={`${classNameForContainer}-trackingDescription`}>{trackingDescription}</p>

      <DeliveredInfoBox
        trackNumber={trackingNum}
        deliverAddress={`${address1}, ${city_name} ${zipcode}, ${country_code}`}
        redirectLink={trackingUri}
        provider={provider}
      />

    </div>
  )
}

export default TrackingToCustomer;
