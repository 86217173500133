import React from "react";
import "./index.less"
import SimpleStatusesForProducts from "../../../../simpleStatusesForProducts";
import {useSelector} from "react-redux";
import GText from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Text/Text";
import {SIZE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import {COLORS} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";

const CompletedReNew = ({productId}) =>{
  const {
    allProducts,
    allProductsLoader,
    anotherGovProducts,
  } = useSelector(({allProducts}) => allProducts);
  const {name} = useSelector(({auth}) => auth.userData);

  const {
    status,
    add: {ktn_expired_date, ktn_number}
  } = allProducts[productId];
  const containerClass = "CompletedReNew-container"
  const headerText = "Your TSA PreCheck® has been renewed!";
  const descriptionText = "Your TSA PreCheck® has been renewed and will be valid for the next 5 years, you can immediately start using your KTN number when you book your plane tickets.";
  const productImage = anotherGovProducts?.find(item => item.id === allProducts[productId]?.product_name)?.image;
  const productName = anotherGovProducts?.find(item => item.id === allProducts[productId]?.product_name)?.title;

  return(
    <div className={containerClass}>
      <SimpleStatusesForProducts
        iconStatus={status}
        userName={name}
        headerText={headerText}
        description={descriptionText}
        buttonText={""}
        productImage={productImage}
        productName={productName}
        productId={productId}
      />
      <div className={`${containerClass}_footerContainer`}>
        <div className={`${containerClass}_footerContainer-KTN_Number`}>
          <GText text={'Your KTN Number is:'} size={SIZE.HEADING_XS} color={COLORS.DARK_BLUE_600} />
          <GText text={ktn_number} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS.DARK_BLUE_600} />
        </div>

        <div className={`${containerClass}_footerContainer-TSA_expire`}>
          <GText text={'Your TSA PreCheck® will expire on'} size={SIZE.HEADING_XS} color={COLORS.DARK_BLUE_600} />
          <GText text={ktn_expired_date} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS.DARK_BLUE_600} />
        </div>
      </div>
    </div>
  )
}

export default CompletedReNew;
