import React, {useEffect, useState} from "react";
import "./index.less";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../../components/spiner/Spinner";
import {getProducts, setSelectedFormId, showModalsType} from "../../appRedux/actions";
import ProductSwicher from "./specialStatusesForProducts/ProductSwicher";
import {productAndStatusesDataList} from "./productAndStatusesDataList";
import SimpleStatusesForProducts from "./simpleStatusesForProducts";
import {ProductsIds} from "@dash/gov-plus__front-end__form/src/util/ProductsIds";

const CheckStatus = () => {
  const {authToken} = useSelector(({auth}) => auth);
  const {name} = useSelector(({auth}) => auth.userData);
  const {
    allProducts,
    allProductsLoader,
    anotherGovProducts,
    productLoaded,
  } = useSelector(({allProducts}) => allProducts);

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [simpleStatusesList, setSimpleStatusesList] = useState([]);

  useEffect(() => {
    const simpleStatuses = productAndStatusesDataList[allProducts[params.id]?.product_name]
    setSimpleStatusesList(Object.keys(simpleStatuses ? simpleStatuses : []))

    const productIdUrl = history.location.pathname.split("/")[history.location.pathname.split("/").length - 1]

    if(productLoaded && Object.keys(allProducts).length <= 0) {
      history.push('/error-404', 'application not found')
    }
    if (Object.keys(allProducts).length && !Object.keys(allProducts).includes(productIdUrl)) {
      history.push('/error-404', 'application not found')
    }

    const productName = allProducts[params.id]?.product_name;
    if (!(productName === ProductsIds.TsaId ||
        productName === ProductsIds.NAMId ||
        (
            allProducts[params.id]?.app_error !== null &&
            !allProducts[params.id]?.status.includes('error')
        ))
    ) {
      history.push({
        pathname: `/forms/?formId=${allProducts[params.id]?.form_id}&&applicationId=${allProducts[params.id]?.application_id}`,
      })
      dispatch(showModalsType(''))
      dispatch(setSelectedFormId(''))
    }
  }, [allProducts])

  useEffect(() => {
    if (authToken && !allProducts[params.id]) {
      dispatch(getProducts(authToken));
    }
  }, []);


  const blockedForSomeReason_Description = allProducts[params.id]?.error_message
    && <p dangerouslySetInnerHTML={{__html: allProducts[params.id]?.error_message}}/>


  const handleBlockedProducts = () => {
    if (allProducts[params.id]?.app_error !== null) {
      return <SimpleStatusesForProducts
        productName={anotherGovProducts?.find(item => item.id === allProducts[params.id]?.product_name)?.title}
        productImage={anotherGovProducts?.find(item => item.id === allProducts[params.id]?.product_name)?.illustration}
        iconStatus={"blocked_for_some_reason"}
        description={blockedForSomeReason_Description || "No error data"}
        userName={name}
        headerText={"Your application is blocked"}
        buttonText={"Contact Us"}
        productId={params.id}
        productCompletion={allProducts[params.id]?.completion}
      />
    } else {
      return <div>
        {simpleStatusesList.includes(allProducts[params.id]?.status)
          ? <SimpleStatusesForProducts
            productName={anotherGovProducts?.find(item => item.id === allProducts[params.id]?.product_name)?.title}
            productImage={anotherGovProducts?.find(item => item.id === allProducts[params.id]?.product_name)?.illustration}
            iconStatus={allProducts[params.id]?.status}
            description={productAndStatusesDataList[allProducts[params.id]?.product_name][allProducts[params.id]?.status].productStatusInfo}
            userName={name}
            headerText={productAndStatusesDataList[allProducts[params.id]?.product_name][allProducts[params.id]?.status].applicationHeaderDescriptionText}
            buttonText={productAndStatusesDataList[allProducts[params.id]?.product_name][allProducts[params.id]?.status].buttonTextForCheckStatus}
            productId={params.id}
            productCompletion={allProducts[params.id]?.completion}
          />
          : <ProductSwicher
            productName={allProducts[params.id]?.product_name}
            statusName={allProducts[params.id]?.status}
            productId={params.id}
          />
        }
      </div>
    }
  }

  return (
    <div className={"checkStatusPageBlock"}>
      {allProductsLoader ? <Spinner/> : handleBlockedProducts()}
    </div>
  );
}

export default CheckStatus;
