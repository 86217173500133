import React, { useEffect } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Form } from "antd"
import BlockFormWithFields from "../../BlockFormWithFields/BlockFormWithFields"
import { submitPassportDetailsAdditionalForm } from "../../../../Redux/store/additionalForm/additional.actions"
import { RootState } from "../../../../App"
import { loadingSelector } from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { setCurrentBlock } from "../../../../Redux/store/step/step.actions"
import GButton from "../../../atomicDesign/molecules/Button/GButton"
import { SIZE } from "../../../atomicDesign/types/types"

type PassportDetailsComponentProps = {
  blocksStep: { statusBlockStep: string, button: string, status: string, content: any, name: number },
  forceLoadingAndDisabled?: boolean,
  statusSignaturePage: string,
  errorMessage: string,
}

export const handlerClickButtonPassportDetails = (
  // e: any,
  setUploadPermission: boolean,
  submitObject: {} | any,
  dispatch: any,
  successPageSubmit: any,
  appId: string,
  formId: string
) => {
  dispatch(
    submitPassportDetailsAdditionalForm({
      ...successPageSubmit,
      fields: submitObject,
      setUploadPermission,
      client_token: appId,
      form_id: formId,
    })
  )
}

const PassportDetailsComponent: React.FunctionComponent<PassportDetailsComponentProps> = ({
  blocksStep,
  forceLoadingAndDisabled = false,
  statusSignaturePage,
  errorMessage,
}) => {
  const loadingButtonState = useSelector<RootState, { loadingButtonState: string }>(loadingSelector).loadingButtonState
  const { status: statusBlockStep } = blocksStep

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentBlock(blocksStep))
  }, [])

  useEffect(() => {
    /* Event Processing Step */
  }, [])

  const loadingStatus =
    loadingButtonState === "loading" && (statusBlockStep === statusSignaturePage || forceLoadingAndDisabled)
  const loadingText = loadingStatus ? "Loading" : blocksStep.button
  return (
    <>
      <div className={`passport-details`}>
        {blocksStep?.content?.passportCard.map((item: any) => {
          return (
            <div className="passport-details_block" key={item.name}>
              <p className={`passport-details_block__title`}>Additional Information</p>
              <BlockFormWithFields blockObject={item} />
              {errorMessage && (
                <div className="payment-error__block-container ">
                  <div className="payment-error__content-container ">
                    <ul className="payment-error__errors__list-error ">
                      <li className="payment-error__errors__text-error payment-error__errors__text-error_0">
                        {errorMessage}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )
        })}
        <Form.Item>
          <GButton
            text={loadingText}
            isSubmit={true}
            showIcon={false}
            textSize={SIZE.PARAGRAPH_BOLD_16}
            loading={loadingStatus}
            disabled={loadingStatus}
          />
        </Form.Item>
      </div>
    </>
  )
}
const mapStateToProps = (state: any) => ({
  errorMessage: state.additional.getFailure,
})

export default connect(mapStateToProps)(PassportDetailsComponent)
