import React from "react"

interface ISvgCheck {
    width: number;
    height: number;
    color: string;
    backgroundColor?: string;
    fillCircle?: boolean;
}

export const SvgInsuranceShieldCircle = ({
   width = 30,
   height = 30,
   color = "#FFFFFF",
   backgroundColor = "#D5FAD9",
   fillCircle = true,
}: ISvgCheck) => {
    return (
        <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            {fillCircle && <rect width="30" height="30" rx="15" fill={backgroundColor}/>}
            <path
                d="M14.9992 21.6704C14.9992 21.6704 20.6663 19.0035 20.6663 15.0031V10.3361L14.9992 8.33594L9.33203 10.3361V15.0031C9.33203 19.0035 14.9992 21.6704 14.9992 21.6704Z"
                stroke={color}
                strokeWidth="1.33366"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.793 15.4455L13.9746 16.6098C14.1054 16.7386 14.3157 16.7375 14.4451 16.6074L17.8689 13.1641"
                stroke={color}
                strokeWidth="1.33366"
                strokeLinecap="round"
            />
        </svg>
    )
}
