/* eslint-disable */
import { DatePicker, Form, Input } from "antd"
import moment from "moment"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { setCashState } from "../../../../Redux/store/cash/cash.action"
import { setObservers, setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import { getLabelWrapper } from "../../../../services/service-function/field"
import iconHelper from "../../../../assets/image/iconHelper.svg";

type DateGroupComponentsProps = {
  blockObject: { headerFields: string, fields: [FieldInterface] } | any,
  submitObject: {} | any,
  required: [string],
  nameActualInput: string,
  currentField: {} | any,
  currentStep: {additionalStep: boolean}
  dispatch: any,
}

// eslint-disable-next-line max-len
const DateGroupComponents: React.FunctionComponent<DateGroupComponentsProps> = ({
  blockObject,
  submitObject,
  required,
  currentStep,
  dispatch,
}) => {
  const  { additionalStep } = currentStep
  const defaultValue = blockObject.defaultValue || ""
  const monthFormat = "MM"
  const dayFormat = "DD"
  const yearFormat = "YYYY"
  const format = "MM-DD-YYYY"
  const customFormat = (value: any, label: any) => {
    switch (label) {
      case "Month":
        return `${value.format(monthFormat)}`
      case "Date":
        return `${value.format(dayFormat)}`
      case "Year":
        return `${value.format(yearFormat)}`
      default:
        return `${value.format(format)}`
    }
  }

  function getDisabledDate(current: any, nameBlock: string, disabled: string | undefined) {

    if (disabled === "Off") {
      return
    }
    if (nameBlock === "Expiration_Date") return current && current <= moment().endOf("day")
    // Can not select days after today
    if (disabled === "future") {
      return current && current >= moment().endOf("day")
    }
  }

  // crut for initialization ein data cl_month_month, later it will be scaling
  useEffect(() => {
    if (blockObject.defaultValue) {
      dispatch(setValueCurrentField(blockObject.fields[0].name, blockObject.defaultValue))
      // blockObject.fields.forEach((picker: any) => {
      //   if (picker.defaultValue) {
      //     dispatch(setValueCurrentField(picker.name, picker.defaultValue))
      //   }
      // })
    }
  }, [blockObject.defaultValue, blockObject.fields, dispatch])

  function onChange(
    blockObject: any,
    itemDate: FieldInterface,
    date: any,
    dateString: string,
    nameBlock: string,
    label: string
  ) {
    if (date === null) {
      dispatch(setValueCurrentField(nameBlock, ""))
      // eslint-disable-next-line array-callback-return
      blockObject.fields.map((item: any) => {
        dispatch(setValueCurrentField(item.name, ""))
        dispatch(setCashState({ ...submitObject, [item.name]: "" }))
      })
      return
    }

    if (!dateString.split("-")[1]) {
      // check on year data picker
      const ageOfClient = new Date().getFullYear() - date.format("YYYY")
      dispatch(setObservers(blockObject.observable, ageOfClient + ""))
    }

    dispatch(setValueCurrentField(itemDate.name, dateString.split("-")[2] || dateString.split("-")[1] || dateString))

    if (!submitObject[nameBlock]) {
      // first launch
      dispatch(setValueCurrentField(nameBlock, date))
      dispatch(setCashState({ ...submitObject, nameBlock: date }))
      return
    }

    if (submitObject[nameBlock] && label === "Date") {
      let newString
      if (typeof submitObject[nameBlock] === "object") newString = submitObject[nameBlock].format("YYYY-MM-DD")
      else newString = submitObject[nameBlock]
      const newStringStart = newString.split("T")[0].slice(0, -2)
      const dispatchString = `${newStringStart}${date.format("DD")}`
      dispatch(setValueCurrentField(nameBlock, dispatchString))
      dispatch(
        setCashState({
          ...submitObject,
          [nameBlock]: dispatchString,
          [itemDate.name]: dateString.split("-")[2] || dateString.split("-")[1] || dateString,
        })
      )
      return
    }
    if (submitObject[nameBlock] && label === "Month") {
      let newString
      if (typeof submitObject[nameBlock] === "object") newString = submitObject[nameBlock].format("YYYY-MM-DD")
      else newString = submitObject[nameBlock]
      const newStringStart = newString.split("T")[0].slice(0, 5)
      const newStringEnd = newString.split("T")[0].slice(-3)
      const dispatchString = `${newStringStart}${date.format("MM")}${newStringEnd}`
      dispatch(setValueCurrentField(nameBlock, dispatchString))
      dispatch(
        setCashState({
          ...submitObject,
          [nameBlock]: dispatchString,
          [itemDate.name]: dateString.split("-")[2] || dateString.split("-")[1] || dateString,
        })
      )
      return
    }
    if (submitObject[nameBlock] && label === "Year") {
      let newString
      if (typeof submitObject[nameBlock] === "object") newString = submitObject[nameBlock].format("YYYY-MM-DD")
      else newString = submitObject[nameBlock]
      const newStringEnd = newString.split("T")[0].slice(4)
      const dispatchString = `${date.format("YYYY")}${newStringEnd}`
      dispatch(setValueCurrentField(nameBlock, dispatchString))
      dispatch(
        setCashState({
          ...submitObject,
          [nameBlock]: dispatchString,
          [itemDate.name]: dateString.split("-")[2] || dateString.split("-")[1] || dateString,
        })
      )
      return
    }
  }

  const validation = (
    rule: any,
    value: any,
    callback: (error?: string) => void,
    { optionalField }: { optionalField: string }
  ) => {
    if (submitObject[rule.field] || optionalField) {
      return callback()
    }
    return callback("Required")
  }

  // different_dob_date-year
  return (
    <>
      <Form.Item
          label={getLabelWrapper(blockObject, dispatch, additionalStep)}
                 name={blockObject.label}>
        <Input.Group size="small">
          {blockObject.fields &&
            blockObject.fields.length &&
            blockObject.fields.map((itemDate: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <Form.Item
                    className={"date-group__data-picker"}
                    name={itemDate.name}
                    preserve={false}
                    rules={[
                      {
                        validator: (rule: any, value: any, callback: (error?: string) => void) =>
                          validation(rule, value, callback, itemDate),
                      },
                    ]}
                    initialValue={
                      (submitObject[itemDate.nameBlock] && moment(submitObject[itemDate.nameBlock])) ||
                      (defaultValue && moment(defaultValue))
                      //  ||  (itemDate && moment(itemDate.defaultValue))
                    }
                  >
                    <DatePicker
                      onChange={(data, dataString) =>
                        onChange(blockObject, itemDate, data, dataString, itemDate.nameBlock, itemDate.label)
                      }
                      picker={itemDate.label.toLowerCase()}
                      placeholder={itemDate.label}
                      format={(value) => customFormat(value, itemDate.label)}
                      disabledDate={(current) => getDisabledDate(current, itemDate.nameBlock, itemDate.disabled)}
                      value={submitObject[itemDate.nameBlock] && moment(submitObject[itemDate.nameBlock])}
                    />
                  </Form.Item>
                </React.Fragment>
              )
            })}
        </Input.Group>
      </Form.Item>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
  required: state.step.validation.required,
  nameActualInput: state.step.nameActualInput,
  currentField: state.form.helper,
  currentStep: state.step.currentStep,
})

export default connect(mapStateToProps)(DateGroupComponents)
