import React, {FC, useState} from "react"

import "./StepTaxTracking.scss";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {TaxConstant} from "../../../../../util/TaxConstant";
import StepTaxProcessing from "../StepTaxProcessing/StepTaxProcessing";
import {ITaxReturn} from "../StepTaxPendingTracking/StepTaxPendingTracking";
import {connect} from "react-redux";
import {ICONS_SVG_TYPE} from "../../../atoms/IconsSvg/IconsSvgTypes";

export type StepTaxTrackingProps = {
    ctaDisabled?: boolean,
    ctaLoading?: boolean,
    ctaMoreDetailLoading?: boolean,
    ctaMoreDetailDisabled?: boolean,
    ctaOnClick?: any,
    taxReturn: ITaxReturn,
    ctaMoreDetailClick?: any
}

const StepTaxTracking: FC<StepTaxTrackingProps> = ({
                                                       ctaDisabled = false,
                                                       ctaLoading = false,
                                                       ctaMoreDetailLoading = false,
                                                       ctaMoreDetailDisabled = false,
                                                       ctaOnClick,
                                                       taxReturn,
                                                       ctaMoreDetailClick
                                                   }) => {

    const [taxStatus, setTaxStatus] = useState<string>(taxReturn?.status || "");
    const [taxSubmissionStatus, setTaxSubmissionStatus] = useState<string>(taxReturn?.submission_status || "");
    const [refundValue, setRefundValue] = useState<number>(taxReturn?.refund_or_amount_owed_cents_total/100 || 0);

    return (
        <div className={`StepTaxTracking`}>
            <StepTaxProcessing
                title={`${TaxConstant.TAXES_ESTIMATED_REFUNDED} $${refundValue}`}
                progress={75}
                progressText={TaxConstant.PROGRESS_PROCESSING_TEXT}
                descriptionText={`${TaxConstant.DESCRIPTION_ESTIMATED_WAIT_TEXT} 21 days`}
                ctaText={TaxConstant.CTA_TRACK_TEXT}
                showMoreDetail={true}
                ctaMoreDetailClick={ctaMoreDetailClick}
                ctaOnClick={ctaOnClick}
                ctaDisabled={ctaDisabled}
                ctaLoading={ctaLoading}
                ctaMoreDetailDisabled={ctaMoreDetailLoading}
                ctaMoreDetailLoading={ctaMoreDetailDisabled}
                icon={ICONS_SVG_TYPE.GOV_OFFICE}
            />
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    taxReturn: state.additional.taxReturn
})

export default connect(mapStateToProps)(StepTaxTracking)