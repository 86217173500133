import createSagaMiddleware from "redux-saga";
import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import thunk from 'redux-thunk';
import rootSaga from "../sagas/index";
import createRootReducer from '../reducers'

const createBrowserHistory = require('history').createBrowserHistory;


export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, sagaMiddleware, routeMiddleware];

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

// watcher for reset store on logout
const resetEnhancer = rootReducer => (state, action) => {
  if (action.type !== 'SIGNOUT_USER') return rootReducer(state, action);
  const newState = rootReducer(undefined, {});
  newState.router = state.router;
  return newState;
};

export default function configureStore(preloadedState = {}) {
  const store = createStore(
    resetEnhancer(createRootReducer(history)), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      ),
    ),
  );


  sagaMiddleware.run(rootSaga);
  return store;
}
