import UtmRepository from "../persistence/cookies/UtmRepository";
import IdentifyBuilder from "../Components/atomicDesign/analytic/event/Identify/IdentifyBuilder";
import {getISOFormat} from "./helper";
import GAnalyticEvents from "../Components/atomicDesign/analytic/AnalyticEvents";
import {IAddress} from "../Components/atomicDesign/analytic/event/EventProperties";
import {getPropertiesForEvents} from "../services/gtm/backEndEvent";
import SignedInBuilder from "../Components/atomicDesign/analytic/event/SignedIn/SignedInBuilder";
import PasswordResetRequestedBuilder
    from "../Components/atomicDesign/analytic/event/PasswordResetRequested/PasswordResetRequestedBuilder";
import SignedUpBuilder from "../Components/atomicDesign/analytic/event/SignedUp/SignedUpBuilder";
import UserIpRepository from "../persistence/session/UserIpRepository";
import CheckoutStartedBuilder from "../Components/atomicDesign/analytic/event/CheckoutStarted/CheckoutStartedBuilder";
import PageBuilder from "../Components/atomicDesign/analytic/event/Page/PageBuilder";
import SignupStartedBuilder from "../Components/atomicDesign/analytic/event/SignupStarted/SignupStartedBuilder";
import ApplicationStartedBuilder
    from "../Components/atomicDesign/analytic/event/ApplicationStarted/ApplicationStartedBuilder";
import Service from "../services/form-service";

export enum EventsProductCategory {
    PRODUCT_CATEGORY_ONE_TIME_FEE = 'one-time fee',
    PRODUCT_CATEGORY_SUBSCRIPTION = 'subscription',
    PRODUCT_CATEGORY_GOVERNMENT_FEE = 'government fee',
    PRODUCT_CATEGORY_UPSELL = 'upsell',
    PRODUCT_CATEGORY_UPGRADE = 'upgrade',
    PRODUCT_CATEGORY_CROSS_SELL = 'cross-sell',
}

export const EventsHelper = {
    getDataToIdentify: (
        userDataResponse: any,
        flattenResponse: any
    ): any => {
        const userDataInformation = userDataResponse?.data?.data;
        const outerId = userDataInformation?.outer_id ?? "";
        const authorized = userDataInformation?.authorised ?? 0;

        let address = {
            street: userDataInformation?.address ?? "",
            city: userDataInformation?.city ?? "",
            state: "",
            postalCode: "",
        }
        let dateOfBirth = userDataInformation?.birthday ?? "";
        const firstName = userDataInformation?.firstname ?? "";
        const lastName = userDataInformation?.lastname ?? "";
        let phoneNumber = userDataInformation?.phone ?? "";

        let createdAt = "";

        if (flattenResponse?.data?.code === 200) {
            const flattenInformation = flattenResponse?.data?.data?.data?.personal;
            phoneNumber = flattenInformation?.communication?.phoneNumber ?? phoneNumber;
            dateOfBirth = flattenInformation?.demographics?.dateOfBirth ?? dateOfBirth;
            address.street = flattenInformation?.location?.permanent?.address1 ?? address.street;
            address.city = flattenInformation?.location?.permanent?.city ?? address.city;
            address.state = flattenInformation?.location?.permanent?.state ?? address.state;
            address.postalCode = flattenInformation?.location?.permanent?.zipCode ?? address.postalCode;

            createdAt = flattenResponse?.data?.data?.created_at ?? "";
        }
        return {outerId, address, firstName, lastName, phoneNumber, dateOfBirth, authorized, createdAt}
    },
    getUtmLastFromJson: (
        jsonData: any
    ) => {
        const utmMediumLast = (!jsonData.utm_medium || jsonData.utm_medium === UtmRepository.UTM_DEFAULT_VALUE) ? "" : jsonData.utm_medium;
        const utmSourceLast = (!jsonData.utm_source || jsonData.utm_source === UtmRepository.UTM_DEFAULT_VALUE) ? "" : jsonData.utm_source;
        const utmCampaignLast = (!jsonData.utm_campaign || jsonData.utm_campaign === UtmRepository.UTM_DEFAULT_VALUE) ? "" : jsonData.utm_campaign;
        const utmContentLast = (!jsonData.utm_content || jsonData.utm_content === UtmRepository.UTM_DEFAULT_VALUE) ? "" : jsonData.utm_content;
        const utmTermLast = (!jsonData.utm_term || jsonData.utm_term === UtmRepository.UTM_DEFAULT_VALUE) ? "" : jsonData.utm_term;

        return {
            utmMediumLast,
            utmSourceLast,
            utmCampaignLast,
            utmContentLast,
            utmTermLast
        };
    },
    getUtmFirstFromBack: async (email: string) => {
        const utmResponse = await Service.getUtmValues({email}).catch(() => {
            return {
                utmMedium: "",
                utmSource: "",
                utmCampaign: "",
                utmContent: "",
                utmTerms: ""
            }
        });
        let utmMedium = utmResponse?.data?.utmValues?.utm_medium ?? "";
        let utmSource = utmResponse?.data?.utmValues?.utm_source ?? "";
        let utmCampaign = utmResponse?.data?.utmValues?.utm_campaign ?? "";
        let utmContent = utmResponse?.data?.utmValues?.utm_content ?? "";
        let utmTerm = utmResponse?.data?.utmValues?.utm_terms ?? "";

        utmMedium = (utmMedium === UtmRepository.UTM_DEFAULT_VALUE) ? "" : utmMedium;
        utmSource = (utmSource === UtmRepository.UTM_DEFAULT_VALUE) ? "" : utmSource;
        utmCampaign = (utmCampaign === UtmRepository.UTM_DEFAULT_VALUE) ? "" : utmCampaign;
        utmContent = (utmContent === UtmRepository.UTM_DEFAULT_VALUE) ? "" : utmContent;
        utmTerm = (utmTerm === UtmRepository.UTM_DEFAULT_VALUE) ? "" : utmTerm;

        return {
            utmMedium,
            utmSource,
            utmCampaign,
            utmContent,
            utmTerm
        };
    },
    triggerIdentify: async (
        userId: string,
        address: IAddress,
        email: string,
        firstName: string,
        lastName: string,
        phone: string,
        dateOfBirth: string,
        hasTransaction: boolean,
        isSubscribed: boolean,
        jsonData: any,
        isActivated: boolean,
        userHash: string,
        createdAt: string
    ) => {
        const { retargetingLink } = getPropertiesForEvents()
        const {
            utmMediumLast,
            utmSourceLast,
            utmCampaignLast,
            utmContentLast,
            utmTermLast
        } = EventsHelper.getUtmLastFromJson(jsonData);

        const {
            utmMedium,
            utmSource,
            utmCampaign,
            utmContent,
            utmTerm
        } = await EventsHelper.getUtmFirstFromBack(email);

        const identifyBuilder = new IdentifyBuilder()
            .setUserId(userId)
            .setAddress(address)
            .setBirthDay(getISOFormat(dateOfBirth))
            .setCreatedAt(createdAt)
            .setEmail(email)
            .setFirstName(firstName)
            .setHasTransaction(hasTransaction)
            .setLastName(lastName)
            .setPhone(phone)
            .setIsSubscribed(isSubscribed)
            .setUtmMedium(utmMedium)
            .setUtmSource(utmSource)
            .setUtmCampaign(utmCampaign)
            .setUtmContent(utmContent)
            .setUtmTerm(utmTerm)
            .setLastUtmMedium(utmMediumLast)
            .setLastUtmSource(utmSourceLast)
            .setLastUtmCampaign(utmCampaignLast)
            .setLastUtmContent(utmContentLast)
            .setLastUtmTerm(utmTermLast)
            .setIsActivated(isActivated)
            .setIntercomUserHash(userHash);

        if(retargetingLink && retargetingLink.trim() !== ""){
            identifyBuilder.setRetargetingLink(retargetingLink);
        }

        await GAnalyticEvents.identify(identifyBuilder.build());
    },
    triggerSignedUp: async (
        jsonData: any,
        createdAt: string,
        email: string,
        firstName: string,
        lastName: string,
        address:IAddress,
        dateBirth: string,
        solutionName: string,
        service: string,
        subCategory: string,
        phone: string,
    ) => {
        const {
            utmMediumLast,
            utmSourceLast,
            utmCampaignLast,
            utmContentLast,
            utmTermLast
        } = EventsHelper.getUtmLastFromJson(jsonData);

        const {
            utmMedium,
            utmSource,
            utmCampaign,
            utmContent,
            utmTerm
        } = await EventsHelper.getUtmFirstFromBack(email);

        const signedUpEvent = new SignedUpBuilder()
            .setCreatedAt(createdAt)
            .setEmail(email)
            .setFirstName(firstName)
            .setLastName(lastName)
            .setAddress({
                street: address.street,
                city: address.city,
                state: address.state,
                country: address.country,
                postalCode: address.postalCode,
            })
            .setPhone(phone)
            .setBirthDay(dateBirth)
            .setSolution(solutionName)
            .setService(service)
            .setCategory(subCategory)
            .setIpAddress(UserIpRepository.getInstance().getValue())
            .setUtmMedium(utmMedium)
            .setUtmSource(utmSource)
            .setUtmCampaign(utmCampaign)
            .setUtmContent(utmContent)
            .setUtmTerms(utmTerm)
            .setLastUtmMedium(utmMediumLast)
            .setLastUtmSource(utmSourceLast)
            .setLastUtmCampaign(utmCampaignLast)
            .setLastUtmContent(utmContentLast)
            .setLastUtmTerm(utmTermLast);

        await GAnalyticEvents.track(signedUpEvent.build());
    },
    triggerCheckoutStarted: async (
        value: string | undefined,
        revenue: string | undefined,
        currency: string | undefined,
        productId: string | undefined,
        service: string | undefined,
        solution: string | undefined,
        price: string | undefined,
        quantity: string | undefined,
        productCategory: string | undefined,
        subdivision: string,
    ) => {

        const productIdValue = productCategory && productCategory === EventsProductCategory.PRODUCT_CATEGORY_SUBSCRIPTION ? "" : productId;
        let serviceValue = productCategory && productCategory === EventsProductCategory.PRODUCT_CATEGORY_SUBSCRIPTION ? "" : service;
        const priceValue = productCategory && productCategory === EventsProductCategory.PRODUCT_CATEGORY_SUBSCRIPTION ? "" : price;

        if (solution === "Passport") {
            serviceValue = serviceValue?.concat(" | Normal");
        }

        const checkoutStartedEvent = new CheckoutStartedBuilder()
            .setValue(value)
            .setRevenue(revenue)
            .setCurrency(currency)
            .setProductId(productIdValue)
            .setProductName(serviceValue)
            .setService(service)
            .setSolution(solution)
            .setProductPrice(priceValue)
            .setProductQuantity(quantity)
            .setProductCategory(productCategory)
            .setSubdivision(subdivision)
            .build();
        await GAnalyticEvents.track(checkoutStartedEvent);
    },
    triggerSignupStarted: async () => {
        const signupStarted = new SignupStartedBuilder()
            .build();
        return await GAnalyticEvents.track(signupStarted);
    },
    triggerPageAnalytic: async (
        pageName: string,
        ipAddress: string
    ) => {
        const pageAnalytic = new PageBuilder()
            .setPageName(pageName ?? "")
            .setUserIp(ipAddress ?? "")
            .build();
        return await GAnalyticEvents.page(pageAnalytic);
    },
    triggerApplicationStarted: async (
        solutionName: string,
        service: string,
        ipAddress: string
    ) => {
        const applicationsStartedBuilder = new ApplicationStartedBuilder()
            .setSolution(solutionName ?? "")
            .setService(service ?? "")
            .setIpAddress(ipAddress ?? "");
        return await GAnalyticEvents.track(applicationsStartedBuilder.build());
    },
    triggerLogin: async (email: string, userIp: string) => {
        const signedInEvent = new SignedInBuilder()
            .setEmail(email ?? "")
            .setIpAddress(userIp ?? "");
        return GAnalyticEvents.track(signedInEvent.build());
    },
    triggerPasswordResetRequested: async (email: string, userIp: string) => {
        const passwordResetRequestedBuilder = new PasswordResetRequestedBuilder()
            .setEmail(email ?? "")
            .setIpAddress(userIp ?? "");
        return await GAnalyticEvents.track(passwordResetRequestedBuilder.build());
    }
};
