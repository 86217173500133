import React from 'react';

export const SvgApprovedStatus = ({width = 32, height = 36}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9985 36C15.9985 36 31.2985 28.8 31.2985 18V5.4L15.9985 0L0.698486 5.4V18C0.698486 28.8 15.9985 36 15.9985 36Z"
        fill="#13BA68"/>
      <path d="M21.9988 13.5L13.7488 21.75L9.99878 18" stroke="white" strokeWidth="1.875" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
  );
}