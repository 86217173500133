import React, {FC, useMemo} from "react"

import {useHistory} from "react-router-dom"
import GApplicationCard, {ApplicationCardContentAlign} from "../ApplicationCard/ApplicationCard";
import {ProductsIds} from "../../../../util/ProductsIds";
import { useDispatch } from "react-redux";
import { setSelectedFormId, showModalsType } from "../../../../../../dashboard-govplus-front/src/appRedux/actions";

type GMyApplicationCardProps = {
  date: string,
  productId: string,
  allProducts: any,
  anotherGovProducts: any,
  webFlowData: any,
  completedPercentage: number,
  continueApplication?: boolean
}
const GMyApplicationCard: FC<GMyApplicationCardProps> = ({
  date,
  productId,
  allProducts,
  anotherGovProducts,
  webFlowData,
  completedPercentage = 0,
  continueApplication = true
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const checkProductStatus = (productId: { toString: () => any }) => {
    const productName = allProducts[productId?.toString()]?.product_name;
    if (productName === ProductsIds.TsaId ||
        productName === ProductsIds.NAMId ||
        (
            allProducts[productId?.toString()]?.app_error !== null &&
            !allProducts[productId?.toString()]?.status.includes('error')
        )
    ) {
      history.push(`products/productInfo/${productId?.toString()}`)
    } else {
      /**
       * Commented the above code because in Processing2.0 we will remove the productInfo page and directly redirect
       * to the processing page
       */
      history.push({
        pathname: `/forms/?formId=${allProducts[productId?.toString()]?.form_id}&&applicationId=${allProducts[productId?.toString()]?.application_id}`,
      })
    }
    dispatch(showModalsType(''))
    dispatch(setSelectedFormId(''))
  }
  const productImage = useMemo(() => {
    return anotherGovProducts?.find((item: { id: any }) => item.id === allProducts[productId]?.product_name)?.illustration
  }, [allProducts, anotherGovProducts, productId])
  const customerName = allProducts[productId]?.first_name + " " + allProducts[productId]?.last_name

  return (
    <GApplicationCard
        dateOfApply={date}
        solution={anotherGovProducts?.find((item: { id: any }) => item.id === allProducts[productId]?.product_name)?.title}
        service={webFlowData[allProducts[productId]?.form_id]?.text}
        productImage={productImage}
        customerName={customerName}
        contentAlign={ApplicationCardContentAlign.Left}
        hasCheckStatus={continueApplication}
        checkStatusClick={() => {
          checkProductStatus(productId)
        }}
        completedPercentage={completedPercentage}
    />
  )
}
export default GMyApplicationCard
