import React, { FC } from "react"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { Form, Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../App"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import { formSelector } from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import GSelect, {IOption} from "../../../atomicDesign/molecules/Select/Select";
import GIconsSvgType from "../../../atomicDesign/atoms/IconsSvg/IconsSvg";
import {ICONS_SVG_TYPE} from "../../../atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import {COLORS} from "../../../atomicDesign/types/Colors";
const { Option } = Select

type ItemSelectGroupComponentProps = {
  field: FieldInterface,
}

const ItemSelectGroupComponent: FC<ItemSelectGroupComponentProps> = ({
  field: { type, options, name, defaultValue, emptyOption },
}) => {
  const dispatch = useDispatch()
  const submitObject = useSelector<RootState, { submitObject: {} | any }>(formSelector).submitObject

  const handlerOnChangeOption = (value: string, name: string) => dispatch(setValueCurrentField(name, value))
  const getOptions = (): IOption[] | undefined =>{
    return options?.map((item: any, index:number) => {
      return {value: item, label: item}
    })
  }
  return (
    <Form.Item
      name={name}
      initialValue={submitObject[name] ?? defaultValue ?? "Select"}
      rules={[
        {
          pattern: new RegExp(`^(?!${emptyOption}$).*`),
          message: "Required",
        },
      ]}
      preserve={false}
      className={`date-group__data-picker ${type} container`}
    >
      <GSelect
          icon={<GIconsSvgType type={ICONS_SVG_TYPE.DROPDOWN} color={COLORS.SLATE_GRAY_500} width={13} height={12} />}
          value={submitObject[name]}
          onChange={(value: string) => handlerOnChangeOption(value, name)}
          options={getOptions()}
      />
    </Form.Item>
  )
}

export default ItemSelectGroupComponent
