import {call, put, takeEvery} from "redux-saga/effects"
import {
    ActionTypes,
    getFailureAction,
    setBillingAddress,
    setClientEmail,
    setClientId,
    setCustomer,
    setDocumentList,
    setPaymentError,
    setPaymentMethod,
    setPDF,
    setPricing,
    setQuestionary,
    setServicesCustomer,
    setSubmitObject,
    setValueCurrentField,
    showAddPaymentButton,
    submitForm,
    removeVariableCurrentField,
    setAppId,
    setStripeLoaded,
    setTagFields,
    setSubmitFormError, showStripeForm, setFormId,
    setStatusOnSubmitObject,
} from "./form.actions"
import Service from "../../../services/form-service"
import {dividerSNN} from "../../../services/service-function/dividerSNN"
import {
    getAdditionalArraySteps,
    setBillingInformation,
    setCurrentResponse,
    setCurrentStep,
    setIndexCurrentStep,
} from "../step/step.actions"
import {LoadingState} from "../../../shared/constans/user-from-view-mode.enum"
import {setLoadingButtonState, setLoadingState} from "../loading/loading.action"
import {getPropertiesForEvents, getServiceAndSolutionNameForFormsHeaders, handleFormTypeSwitch} from "../../../services/gtm/backEndEvent"
import {setUploadDocuments} from "../upload/upload.actions"
import {
    setCanNotarize, setStatusSignaturePage,
    setUpsellInformation
} from "../additionalForm/additional.actions"
import {setStatusSignaturePageSaga} from "../upload/upload.saga"
import {arrayPaymentError, getCorrespondFieldByErrorPayment, removeEmailInJson} from "./servicePayment"
import {delay, getClientTokenForResponse, getRoot, removeCookie, setCookie} from "../step/service.step.saga"
import {
    getScrollElementByClassName,
    getTopScrollPosition,
} from "../../../Components/FormComponents/FormBlock/FormBlock"
import {
    getHandleValidationByRule
} from "../../../Components/FormComponents/FieldsComponents/InputComponent/NestRenderInputComponent/NestRenderInputComponent"
import {savePrefilled, setUsersData} from "../user/user.actions"
import {ERRORS} from "../step/constants"
import GAnalyticEvents from "../../../Components/atomicDesign/analytic/AnalyticEvents";
import PathToPageEventHelper from "../../../Components/atomicDesign/analytic/event/PathToPageEventTranslator";
import UserIpRepository, {USER_IP} from "../../../persistence/session/UserIpRepository";
import UtmRepository from "../../../persistence/cookies/UtmRepository";
import FlattenDataDto from "../../../dto/FLattenDataDto/FlattenDataDto";
import ApplicationSubmittedBuilder
    from "../../../Components/atomicDesign/analytic/event/ApplicationSubmitted/ApplicationSubmittedBuilder";
import {getISOFormat, notifyError} from "../../../util/helper";
import OuterIdRepository from "../../../persistence/session/OuterIdRepository";
import SessionStorageHandler from "../../../persistence/SessionStorageHandler";
import {putUpsellProcessingInformation} from "../additionalForm/UpsellProcessing";
import OrderCompletedBuilder
  from "../../../Components/atomicDesign/analytic/event/OrderCompleted/OrderCompletedBuilder";
import {EventsHelper} from "../../../util/EventsHelper";
import {IAddress} from "../../../Components/atomicDesign/analytic/event/EventProperties";
import {FormSagaHelper} from "./FormSagaHelper";
import {putBillingInformation} from "../billing/BillingInformation";
import {getUrlAnalyticQueryParams} from "../../../util/UrlQueryParams";
import FormIdRepository from "../../../persistence/session/FormIdRepository";
import {buildPaymentError} from "../../../dto/PaymentError";
import {AdditionalSagaHelper} from "../additionalForm/AdditionalSagaHelper";
import {NEW_PAYMENT_METHOD_VALUE} from "../../../util/PaymentMethodsHelper";
import {PaymentRequestToken} from "../../../Components/atomicDesign/types/types";
import OfferCouponRepository from "../../../persistence/session/OfferCouponRepository";
import {setItemPriceCharges, setUserCouponState} from "../billing/billing.action";
import ServiceHostDashBoard from "../../../services/requests/userService"
import SubscriptionService from "../../../services/subscription/subscription-service";
//@ts-ignore
import {useHelperSubscription} from "../../../../../dashboard-govplus-front/src/hooks/useHelperSubscription";
import OrderCompleted from "../../../Components/atomicDesign/analytic/event/OrderCompleted/OrderCompleted";
import { ServicesIds } from "../../../util/ServicesIds"

const getStateToken = ({retargetingLink}: { retargetingLink: string }) => {
    let url
    try {
        url = new URL(retargetingLink)
    } catch (e) {
        console.log(e)
    }
    return url?.searchParams?.get?.("retargetingToken")
}

export function* getSuccess(response: {} | any) {
    const {success} = yield response ?? {}
    if (success) {
        return success
    }
    return response
}

export const getPath = ({nameGetParam, valueGetParam}: { nameGetParam: string, valueGetParam: string }) => {
    const pathname = window.location.pathname
    const queryParams = new URLSearchParams(window?.location?.search)
    if (queryParams.has(nameGetParam)) {
        queryParams.set(nameGetParam, valueGetParam)
    } else {
        queryParams.append(nameGetParam, valueGetParam)
    }
    window.history.pushState(null, "", pathname + "?" + queryParams.toString())
    const pageName = PathToPageEventHelper.getPagePathWithQueryParams();
    if (pageName !== PathToPageEventHelper.UNDEFINED_PAGE) {
        const userIp = UserIpRepository.getInstance().getValue();

        const pageAnalytic = EventsHelper.triggerPageAnalytic(pageName, userIp);

        if (PathToPageEventHelper.isInFormPage(queryParams)) {
            const {solutionName, service} = getServiceAndSolutionNameForFormsHeaders();
            const applicationsStarted = EventsHelper.triggerApplicationStarted(solutionName, service, UserIpRepository.getInstance().getValue());
        }
    }
}

export const processingPages = [
    "sss_after3",
    "address_after",
    "passport_after",
    "passport_new_after",
    "name_change_after",
    "success_address",
    "tsa_questionnaire_page",
    "tsa_questionnaire_page_renewal",
    "funnel/process",
    "electronic_signature_page",
]

const paymentFields = [
    "card_no",
    "expiration_date",
    "ssc",
    "zip_pay",
    "f_name_pay",
    "l_name_pay",
    "city_pay",
    "state_pay",
    "adress_pay",
]

function* submitFormSaga(actions: any): any {
    try {
        const {arraySteps, indexCurrentStep, newSubmitObject: submitObject} = yield actions.payload
        const {client_token} = yield getClientTokenForResponse()
        const {retargetingLink} = getPropertiesForEvents()
        const dividerObj = yield dividerSNN(submitObject)

        const {
            utmVars,
            analytic_fbc,
            analytic_fbp,
            analytic_gclid,
            analytic_msclkid,
            analytic_ever_flow_id,
            analytic_impact_radius_id
        } = getUrlAnalyticQueryParams();

        const newSubmitObj = yield {
            ...submitObject,
            ...dividerObj,
            client_token,
            check: true,
            utm_vars: utmVars,
            analytic_fbc,
            analytic_fbp,
            analytic_gclid,
            analytic_msclkid,
            analytic_ever_flow_id,
            analytic_impact_radius_id,
            insurance_claim: sessionStorage.getItem("insurance_claim") ?? false
        }

        if (arraySteps && indexCurrentStep === arraySteps.length - 1) {
            yield put(getFailureAction(null))
            yield put(setLoadingButtonState(LoadingState.Loading))
            newSubmitObj["data-pricing"] = "0"
            let response: any
            if (newSubmitObj && client_token) {
                response = yield Service.submitForm(newSubmitObj)
            }

      yield call(FormSagaHelper.TriggerEventEmailVerification, response);

      const emailValidation = yield call(FormSagaHelper.EmailValidation, response);
      if(emailValidation && emailValidation.status === 'ERROR') {
        yield put(setSubmitFormError(emailValidation))
        throw emailValidation?.message
      }

            if (response?.success?.outer_id) {
                yield OuterIdRepository.getInstance().setValue(response?.success?.outer_id);
            }

            if (response?.success?.switched_type) {
                yield handleFormTypeSwitch(response.success.switched_type)
                yield getPath({nameGetParam: "formId", valueGetParam: "passport-new"})

                const newFormType = {
                    form_id: response.success.switched_type
                }
                // @ts-ignore
                newFormType['form-type'] = 'NEW'
                yield put(setSubmitObject(newFormType))
                yield put(setFormId(response.success.switched_type))
            }

            const {solutionName, service, subCategory} = getServiceAndSolutionNameForFormsHeaders();

            if (response?.success) {
                const applicationSubmitted = new ApplicationSubmittedBuilder()
                    .setApplicationId(response?.success?.application_id)
                    .setSolution(solutionName)
                    .setService(service)
                    .setCategory(subCategory)
                    .setIpAddress(UserIpRepository.getInstance().getValue());
                GAnalyticEvents.track(applicationSubmitted.build());
            }
            if (response?.success?.initial_user) {
                if (response?.success?.client_token) {
                    yield call(AdditionalSagaHelper.setAuthBearerToken, response?.success?.client_token);
                    yield call(
                        triggerIdentifyEvent,
                        response?.success?.user_hash,
                        utmVars,
                        submitObject,
                        newSubmitObj,
                        response?.success?.has_transaction,
                        response?.success?.is_activated
                    );
                }

                yield sessionStorage.setItem("initial_user", String(response?.success.initial_use))
                yield call(triggerSignedUpEvent, utmVars, newSubmitObj, response, solutionName, service, subCategory);
            }

            let success = yield getSuccess(response)

            if (success?.application_id) {
                yield call(FormSagaHelper.SetAppIdSessionValue, success?.application_id);
                const token = yield getStateToken({retargetingLink})
                yield put(setUsersData({token, state: {app_id: success?.application_id}}))
                yield put(setAppId(success?.application_id))
            }

            if (success?.user_exists) {
                yield removeEmailInJson(success)
                yield put(setSubmitFormError(success?.user_exists))
                throw success?.user_exists?.message
            }

            if (response.error || response["validation errors"]) {
                if (response.error.message === "invalid address") {
                    const {new_address_verification, old_address_verification} = response.error.data
                    if (
                        (new_address_verification && !new_address_verification.status) ||
                        (old_address_verification && !old_address_verification.status)
                    ) {
                        yield put(getFailureAction(response.error.data))
                    }

                    throw response.error.message
                }

                yield put(getFailureAction(response))
                yield put(setLoadingState(LoadingState.Error))
                yield put (setLoadingButtonState(LoadingState.Error))
                yield put(getFailureAction(null))
                yield put(setIndexCurrentStep(arraySteps.length - 1))
                yield put(setSubmitFormError(response))

                if (response.error.message === "You are not authorized to access this page, please log in.") {
                    SessionStorageHandler.clearIgnoringKey([USER_IP]);
                    yield delay(300)
                    yield (window.location.href = `${getRoot()}/signin`)
                }
                throw new Error(response.error.message)
                yield put(setLoadingState(LoadingState.Loaded))
            } else if (success) {
                let paymentGov = ""
                if (success.billingInformation) {
                    yield putBillingInformation(success.billingInformation)
                    paymentGov = "true"
                }
                if (success.sidebarData) {
                    yield put(setClientEmail(success.sidebarData.email))
                    yield sessionStorage.setItem("emailGov", success.sidebarData.email)
                    yield put(setClientId(success.sidebarData.subscriptionId))
                } else {
                }

                if (success.documentList) {
                    yield put(setDocumentList(success.documentList))
                }

                if (success.address || success.checkAddress) {
                    yield put(setBillingAddress(success.address || success.checkAddress))
                }

                if (success.checkAddress) {
                    yield put(setCurrentResponse(success.checkAddress))
                }

                if (success.services) {
                    yield put(setServicesCustomer(success.services))
                }

                if (success.pricingVars) {
                    yield put(setPricing(success.pricingVars))
                    yield sessionStorage.setItem("pricing", JSON.stringify(success.pricingVars))
                }

                if (success?.client_token) {
                    yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
                }

                yield put(getAdditionalArraySteps(success?.page ?? "payment"))

                yield put(setCustomer(success.customer))
                yield sessionStorage.setItem("billingAddress", JSON.stringify(success.address || success.checkAddress))
                yield sessionStorage.setItem("paymentGov", paymentGov)
                if (success.checkAddress && success.checkAddress.new_address_verification) {
                    yield put(setBillingAddress(success.checkAddress))
                }
                sessionStorage.setItem("backEndEvent", "")
                if (success?.additionalData) {
                    yield put(setSubmitObject(success?.additionalData))
                }

                if (success?.pdf) {
                    yield put(setPDF(success?.pdf))
                }

                if (success?.questions?.questionnaire) {
                    yield put(setQuestionary(success?.questions?.questionnaire))
                }

                if (success.uploadData) {
                    yield put(setUploadDocuments(success.uploadData))
                }

                if (success.canNotarize) {
                    yield put(setCanNotarize(success.canNotarize))
                }

                /**
                 * This case is just used on Passport
                 */
                success = yield call(FormSagaHelper.patchSetFormType, newSubmitObj, success)

                const objUpdated = yield call(FormSagaHelper.updatePropertyForFilterStep, success);
                if(Object.keys(objUpdated).length > 0) {
                    yield put(setSubmitObject(objUpdated))
                }

                if (success.upsellInformation) {
                    yield put(setUpsellInformation(success.upsellInformation))
                }

                yield putUpsellProcessingInformation(success)

                if (success) {
                    yield getPath({nameGetParam: "page", valueGetParam: "payment"})
                    yield put(removeVariableCurrentField("ssn"))
                }

                if (success && processingPages.includes(success.page)) {
                    yield getPath({nameGetParam: "page", valueGetParam: "processing"})
                }

                if (success && success.page === "upsell") {
                    yield getPath({nameGetParam: "page", valueGetParam: "upsell"})
                }

                if (success && processingPages?.includes?.(success?.page) && success?.isFirstApp) {
                    /* Event Start Processing */
                }

                if (success) {
                    yield put(removeVariableCurrentField("ssn"))
                }
                
                if (success?.itemPriceCharges) {
                    yield put(setItemPriceCharges(success.itemPriceCharges))
                }
                
                yield put(setLoadingState(LoadingState.Loaded))
                yield put(setLoadingButtonState(LoadingState.Loaded))

                if (success?.sidebarData && success?.sidebarData?.subscriptionId) {
                    yield call(setStatusSignaturePageSaga, success)
                }

                if(success.status) {
                    yield put(setStatusSignaturePage(success.status))
                }

                if (success?.itemPriceCharges) {
                    yield put(setItemPriceCharges(success.itemPriceCharges))
                }
            }
        }
    } catch (e: any) {
        // const errors = [e, e?.message, e?.error, e?.error?.message]
        if (e === "Not Found") {
            yield put(setLoadingState(LoadingState.NotFound))
            yield put(setLoadingButtonState(LoadingState.NotFound))
        }
        else if (e !== "invalid address") {
            yield put(setLoadingState(LoadingState.Error))
            yield put(setLoadingButtonState(LoadingState.Error))
            //@ts-ignore
            yield put(getFailureAction(e))
            yield delay(300)
        } else if (e?.message?.includes("please log in")) {
            SessionStorageHandler.clearIgnoringKey([USER_IP]);
            yield delay(300)
            yield (window.location.href = `${getRoot()}/signin`)
        }
        notifyError(e);
        yield put(setLoadingState(LoadingState.Loaded))
        yield put(setLoadingButtonState(LoadingState.Loaded))
    }
}

function* submitPaymentSaga(actions: any): any {
    try {
        const {arraySteps, indexCurrentStep, newSubmitObject: submitObject} = yield actions.payload
        const {client_token} = yield getClientTokenForResponse()

        const dividerObj = yield dividerSNN(submitObject)
        const newSubmitObj = {
            ...submitObject,
            ...dividerObj,
            client_token,
        }
        const {ssn_applicant, ssn_applicant1, ssn_applicant2, ssn_applicant3} = submitObject

        if (arraySteps && indexCurrentStep === arraySteps.length - 1) {
            yield put(getFailureAction(null))
            yield put(setLoadingButtonState(LoadingState.Loading))
            yield getTopScrollPosition()
            const response = yield Service.submitPayment(newSubmitObj)

            const success = yield getSuccess(response)

            if (response?.error) {
                yield put(getFailureAction(response))

                yield put(setLoadingButtonState(LoadingState.Error))
                const paymentError = buildPaymentError(response?.error?.message, getCorrespondFieldByErrorPayment(response?.error?.message, arrayPaymentError))
                yield put(setPaymentError(paymentError))
                const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time))
                yield delay(3000)
                // const url = window.location.href.split("&indexCurrentStep")[0]
                // let newUrl = new URL(`${url}&indexCurrentStep=${arraySteps.length - 1}`)
                // window.history.pushState(null, "", newUrl.toString())
                yield put(setIndexCurrentStep(arraySteps.length - 1))
                yield put(setValueCurrentField("ssn_applicant", ssn_applicant))
                yield put(setValueCurrentField("ssn_applicant1", ssn_applicant1))
                yield put(setValueCurrentField("ssn_applicant2", ssn_applicant2))
                yield put(setValueCurrentField("ssn_applicant3", ssn_applicant3))
            } else {
                if (success.documentList) {
                    yield put(setDocumentList(success.documentList))
                }
                const nameAdditionalSteps: string = yield success.page || success.checkAddress.status
                yield put(setIndexCurrentStep(0))
                yield put(setCurrentStep(0))
                yield put(getAdditionalArraySteps(nameAdditionalSteps))
                yield put(setCurrentResponse(success.checkAddress))
                if (success.services) {
                    yield put(setServicesCustomer(success.services))
                }

                if (success.sidebarData) {
                    yield put(setClientEmail(success.sidebarData.email))
                    yield sessionStorage.setItem("emailGov", success.sidebarData.email)
                    yield put(setClientId(success.sidebarData.clientId))
                    yield sessionStorage.setItem("appIdGov", success.sidebarData.clientId)
                } else {
                    yield sessionStorage.setItem("appIdGov", "true")
                }
                yield sessionStorage.setItem("paymentGov", "")
                yield put(setPDF(success?.pdf))
                yield put(setQuestionary(success?.questions?.questionnaire))
                yield put(setLoadingButtonState(LoadingState.Loaded))
                if (success.uploadData) {
                    yield put(setUploadDocuments(success.uploadData))
                }
                if (success.canNotarize) {
                    yield put(setCanNotarize(success.canNotarize))
                }
                if (success?.client_token) {
                    yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
                }
                if (success.upsellInformation) {
                    yield put(setUpsellInformation(success.upsellInformation))
                }

                yield putUpsellProcessingInformation(success)

                if (success) {
                    yield getPath({nameGetParam: "page", valueGetParam: "processing"})
                    yield put(removeVariableCurrentField("ssn"))
                }
                if (success && processingPages?.includes?.(success?.page) && success?.isFirstApp) {
                    /* Event Start Processing */
                }
                yield call(setStatusSignaturePageSaga, success)
            }
        }
    } catch (e) {
        if (e === "Not Found") yield put(setLoadingButtonState(LoadingState.NotFound))
        else yield put(setLoadingButtonState(LoadingState.Error))
        //@ts-ignore
        yield put(getFailureAction(e))
    }
}

function* confirmSubscriptionSaga(actions: any): any {
    const { triggerOrderCompletedEvent: triggerOrderCompletedEventOnSubscription } = useHelperSubscription()
    let paymentRequest: PaymentRequestToken | null  = null;
    try {

        const {newSubmitObject: submitObject, values: actionValues} = yield actions.payload
        const {solutionName, service} = getServiceAndSolutionNameForFormsHeaders();

        const {client_token} = yield getClientTokenForResponse()
        const formId = submitObject?.form_id || FormIdRepository.getInstance().getValue()

        const {
            utmVars,
            analytic_fbc,
            analytic_fbp,
            analytic_gclid,
            analytic_msclkid,
            analytic_ever_flow_id,
            analytic_impact_radius_id
        } = getUrlAnalyticQueryParams();

        let newSubmitObj = {
            ...submitObject,
            client_token,
            item_price_id: actionValues.item_price_id,
            payment_method: actionValues.payment_method,
            serviceName: service,
            form_id: formId,
            utm_vars: utmVars,
            analytic_fbc,
            analytic_fbp,
            analytic_gclid,
            analytic_msclkid,
            solution: solutionName,
            analytic_ever_flow_id,
            analytic_impact_radius_id,
            period: actionValues.period
        }

        if (actionValues.paymentRequestEvent) {
            paymentRequest = actionValues.paymentRequestEvent;
        }

        if (paymentRequest) {
            newSubmitObj = {...newSubmitObj, creditCardToken: paymentRequest.creditCardToken};
        }

        yield put(getFailureAction(null))
        yield put(setLoadingButtonState(LoadingState.Loading))
        const response = yield Service.confirmSubscription(newSubmitObj)
        const success = yield getSuccess(response)
        if (response?.error) {
            /* Event Backend Events */
            if (response?.billingInformation) {
                yield handleBillingInformationAfterPaymentError(response.billingInformation)
            }

            yield put(getFailureAction(response))

            yield put(setLoadingButtonState(LoadingState.Error))

            //todo handle the posible new payment method added into de billingInformation

            const paymentError = buildPaymentError(response?.error?.message, getCorrespondFieldByErrorPayment(response?.error?.message, arrayPaymentError))
            yield put(setPaymentError(paymentError))

            yield getScrollElementByClassName({className: "list-fields-in-block__header"})
            yield put(setIndexCurrentStep(0))
        } else {

            yield triggerIdentifyEvent(success.user_hash, utmVars, submitObject, newSubmitObj, success?.has_transaction, success?.is_activated);

            yield getTopScrollPosition()
            yield resetPaymentFields()
            yield put(showStripeForm(false))

            if (success.documentList) {
                yield put(setDocumentList(success.documentList))
            }
            const nameAdditionalSteps: string = yield success.page || success.checkAddress?.status
            yield put(setIndexCurrentStep(0))
            yield put(setCurrentStep(0))
            yield put(getAdditionalArraySteps(nameAdditionalSteps))
            yield put(setCurrentResponse(success.checkAddress))
            if (success.services) {
                yield put(setServicesCustomer(success.services))
            }

            if (success.sidebarData) {
                yield put(setClientEmail(success.sidebarData.email))
                yield sessionStorage.setItem("emailGov", success.sidebarData.email)
                yield put(setClientId(success.sidebarData.subscriptionId))
            }
            yield sessionStorage.setItem("paymentGov", "")
            yield put(setPDF(success?.pdf))
            yield put(setQuestionary(success?.questions?.questionnaire))
            yield put(setLoadingButtonState(LoadingState.Loaded))
            if (success.uploadData) {
                yield put(setUploadDocuments(success.uploadData))
            }
            if (success.canNotarize) {
                yield put(setCanNotarize(success.canNotarize))
            }

            const objUpdated = yield call(FormSagaHelper.updatePropertyForFilterStep, success);
            if(Object.keys(objUpdated).length > 0) {
                yield put(setSubmitObject(objUpdated))
            }

            if (success?.client_token) {
                yield call(AdditionalSagaHelper.removeClientToken);
                yield call(AdditionalSagaHelper.setClientToken, success?.client_token);
                yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
            }
            if (success.upsellInformation) {
                yield put(setUpsellInformation(success.upsellInformation))
            }

            yield putUpsellProcessingInformation(success)

            if (success.billingInformation) {
                yield putBillingInformation(success.billingInformation)
            }

            if (success && processingPages.includes(success.page)) {
                yield getPath({nameGetParam: "page", valueGetParam: "processing"})
            } else if (success && success.page === "upsell") {
                yield getPath({nameGetParam: "page", valueGetParam: "upsell"})
            }

            if (success?.event_order_completed) {
                const { event_order_completed } = success
                yield call(triggerOrderCompletedEventOnSubscription,{ data: { event_order_completed } }, OrderCompleted.TYPE_ORDER_COMPLETED_PAYMENT_PAGE);
            }

                // Remove offer coupon from cookies
            yield OfferCouponRepository.getInstance().removeItem();

                // remove coupon from store
            yield put(setUserCouponState(null))

            yield call(setStatusSignaturePageSaga, success)
            window.scrollTo(0, 0)
        }
    } catch (e) {
        if (e === "Not Found") yield put(setLoadingButtonState(LoadingState.NotFound))
        else yield put(setLoadingButtonState(LoadingState.Error))
        //@ts-ignore
        yield put(getFailureAction(e))
    }

    if (paymentRequest?.requestCompletedCallback) {
        paymentRequest.requestCompletedCallback(paymentRequest.event);
    }
}

function* resetPaymentFields() {
    for (let i = 0; i < paymentFields.length; i++) {
        yield put(setValueCurrentField(paymentFields[i], ""))
    }
}

function* handleBillingInformationAfterPaymentError(billingInformation: any) {
    yield put(setBillingInformation(billingInformation))
    yield resetPaymentFields()
    yield put(setStripeLoaded(false))
    if (billingInformation.payment_methods.length > 0) {
        yield put(setPaymentMethod(billingInformation.default_payment_methods ?? billingInformation.payment_methods[0].id))
        yield put(showAddPaymentButton(true))
        yield put(showStripeForm(false))
    } else {
        yield put(setPaymentMethod(NEW_PAYMENT_METHOD_VALUE))
        yield put(showAddPaymentButton(false))
        yield put(showStripeForm(true))
    }
}

function* payApplicationFeeSaga(actions: any): any {
    let paymentRequest: PaymentRequestToken | null  = null;
    try {

        const {arraySteps, indexCurrentStep, newSubmitObject: submitObject, userCoupon} = yield actions.payload
        const {solutionName, service} = getServiceAndSolutionNameForFormsHeaders();

        const {client_token} = yield getClientTokenForResponse()
        const formId = submitObject.form_id === undefined ? FormIdRepository.getInstance().getValue() : submitObject.form_id

        const {
            utmVars,
            analytic_fbc,
            analytic_fbp,
            analytic_gclid,
            analytic_msclkid,
            analytic_ever_flow_id,
            analytic_impact_radius_id
        } = getUrlAnalyticQueryParams();

        let newSubmitObj = {
            ...submitObject,
            client_token,
            item_price_id: actions.payload.values.item_price_id,
            payment_method: actions.payload.values.payment_method,
            serviceName: service,
            form_id: formId,
            utm_vars: utmVars,
            analytic_fbc,
            analytic_fbp,
            analytic_gclid,
            analytic_msclkid,
            solution: solutionName,
            analytic_ever_flow_id,
            analytic_impact_radius_id,
            coupon_id: userCoupon?.available ? userCoupon?.couponId : null
        }

        const actionValues = actions.payload.values

        if (actionValues.paymentRequestEvent) {
            paymentRequest = actionValues.paymentRequestEvent;
        }

        if (actionValues.itemPriceChargeId) {
            newSubmitObj = {...newSubmitObj, itemPriceChargeId: actionValues.itemPriceChargeId};
        }

        if (paymentRequest) {
            newSubmitObj = {...newSubmitObj, creditCardToken: paymentRequest.creditCardToken};
        }

        const {ssn_applicant, ssn_applicant1, ssn_applicant2, ssn_applicant3} = submitObject

        if (arraySteps && indexCurrentStep === arraySteps.length - 1) {
            yield put(getFailureAction(null))
            yield put(setLoadingButtonState(LoadingState.Loading))
            const response = yield Service.payApplicationFee(newSubmitObj)
            const success = yield getSuccess(response)
            if (response?.error) {
                /* Event Backend Events */
                if (response?.billingInformation) {
                    yield handleBillingInformationAfterPaymentError(response.billingInformation)
                }

                response?.error?.message ?
                    yield put(getFailureAction({error: response?.error?.message})) :
                    yield put(getFailureAction(response))

                yield put(setLoadingButtonState(LoadingState.Error))

                //todo handle the posible new payment method added into de billingInformation

                const paymentError = buildPaymentError(response?.error?.message, getCorrespondFieldByErrorPayment(response?.error?.message, arrayPaymentError))
                yield put(setPaymentError(paymentError))
                yield getScrollElementByClassName({className: "list-fields-in-block__header"})
                yield put(setIndexCurrentStep(arraySteps.length - 1))
                yield put(setValueCurrentField("ssn_applicant", ssn_applicant))
                yield put(setValueCurrentField("ssn_applicant1", ssn_applicant1))
                yield put(setValueCurrentField("ssn_applicant2", ssn_applicant2))
                yield put(setValueCurrentField("ssn_applicant3", ssn_applicant3))
            } else {

                yield triggerIdentifyEvent(success.user_hash, utmVars, submitObject, newSubmitObj, success?.has_transaction, success?.is_activated);

                yield getTopScrollPosition()
                yield resetPaymentFields()
                yield put(showStripeForm(false))

                if (success.documentList) {
                    yield put(setDocumentList(success.documentList))
                }
                const nameAdditionalSteps: string = yield success.page || success.checkAddress.status
                yield put(setIndexCurrentStep(0))
                yield put(setCurrentStep(0))
                yield put(getAdditionalArraySteps(nameAdditionalSteps))
                yield put(setCurrentResponse(success.checkAddress))
                if (success.services) {
                    yield put(setServicesCustomer(success.services))
                }

                if (success.sidebarData) {
                    yield put(setClientEmail(success.sidebarData.email))
                    yield sessionStorage.setItem("emailGov", success.sidebarData.email)
                    yield put(setClientId(success.sidebarData.subscriptionId))
                }
                yield sessionStorage.setItem("paymentGov", "")
                yield put(setPDF(success?.pdf))
                yield put(setQuestionary(success?.questions?.questionnaire))
                yield put(setLoadingButtonState(LoadingState.Loaded))
                if (success.uploadData) {
                    yield put(setUploadDocuments(success.uploadData))
                }
                if (success.canNotarize) {
                    yield put(setCanNotarize(success.canNotarize))
                }

                const objUpdated = yield call(FormSagaHelper.updatePropertyForFilterStep, success);
                if(Object.keys(objUpdated).length > 0) {
                    yield put(setSubmitObject(objUpdated))
                }

                if (success?.client_token) {
                    yield call(AdditionalSagaHelper.removeClientToken);
                    yield call(AdditionalSagaHelper.setClientToken, success?.client_token);
                    yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
                }
                if (success.upsellInformation) {
                    yield put(setUpsellInformation(success.upsellInformation))
                }

                yield putUpsellProcessingInformation(success)

                if (success.billingInformation) {
                    yield putBillingInformation(success.billingInformation)
                }

                if (success && processingPages.includes(success.page)) {
                    yield getPath({nameGetParam: "page", valueGetParam: "processing"})
                } else if (success && success.page === "upsell") {
                    yield getPath({nameGetParam: "page", valueGetParam: "upsell"})
                }

                if (success?.order_completed_event) {
                    yield triggerOrderCompletedEvent(success?.order_completed_event);
                }

                // Remove offer coupon from cookies
                yield OfferCouponRepository.getInstance().removeItem();

                // remove coupon from store
                yield put(setUserCouponState(null))

                yield call(setStatusSignaturePageSaga, success)
                window.scrollTo(0, 0)
            }
        }
    } catch (e) {
        if (e === "Not Found") yield put(setLoadingButtonState(LoadingState.NotFound))
        else yield put(setLoadingButtonState(LoadingState.Error))
        //@ts-ignore
        yield put(getFailureAction(e))
    }

    if (paymentRequest && paymentRequest.requestCompletedCallback) {
        paymentRequest.requestCompletedCallback(paymentRequest.event);
    }
}

function* payGovernmentFeesFromScrapSaga(actions: any): any {
    try {
        yield put(setPaymentError(null))
        const submitObject = yield actions.payload
        const {client_token} = yield getClientTokenForResponse()
        const {solutionName, service, subCategory, applicationId} = yield getPropertiesForEvents()
        const newSubmitObj = {
            ...submitObject,
            client_token,
            form_id: FormIdRepository.getInstance().getValue(),
        }
        yield put(getFailureAction(null))
        yield put(setLoadingButtonState(LoadingState.Loading))
        const response = yield Service.payGovernmentFeesFromScrap(newSubmitObj)

        const success = yield getSuccess(response)

        if (response?.error) {
            yield put(getFailureAction(response))
            yield put(setLoadingButtonState(LoadingState.Error))
            const paymentError = buildPaymentError(response?.error?.message, getCorrespondFieldByErrorPayment(response?.error?.message, arrayPaymentError))
            yield put(setPaymentError(paymentError))
            yield getScrollElementByClassName({className: "payment-error__content-container"})

            if (response?.billingInformation) {
                yield handleBillingInformationAfterPaymentError(response.billingInformation)
            }
        } else {
            for (let i = 0; i < paymentFields.length; i++) {
                yield put(setValueCurrentField(paymentFields[i], ""))
            }

            if (success?.dashLink) {
                yield delay(3000)
                yield (window.location.href = `${getRoot()}${success?.dashLink}`)
            }
            if (success.documentList) {
                yield put(setDocumentList(success.documentList))
            }
            const nameAdditionalSteps: string = yield success.page || success.checkAddress?.status
            yield put(setIndexCurrentStep(0))
            yield put(setCurrentStep(0))
            yield put(getAdditionalArraySteps(nameAdditionalSteps))
            yield put(setCurrentResponse(success.checkAddress))
            if (success.services) {
                yield put(setServicesCustomer(success.services))
            }

            if (success.sidebarData) {
                yield put(setClientEmail(success.sidebarData.email))
                yield sessionStorage.setItem("emailGov", success.sidebarData.email)
                yield put(setClientId(success.sidebarData.subscriptionId))
            }
            yield sessionStorage.setItem("paymentGov", "")
            yield put(setPDF(success?.pdf))
            yield put(setQuestionary(success?.questions?.questionnaire))
            yield put(setLoadingButtonState(LoadingState.Loaded))
            if (success.uploadData) {
                yield put(setUploadDocuments(success.uploadData))
            }
            if (success.canNotarize) {
                yield put(setCanNotarize(success.canNotarize))
            }
            if (success?.client_token) {
                yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
            }
            if (success.upsellInformation) {
                yield put(setUpsellInformation(success.upsellInformation))
            }

            yield putUpsellProcessingInformation(success)

            yield call(setStatusSignaturePageSaga, success)
        }
    } catch (e) {
        if (e === "Not Found") yield put(setLoadingButtonState(LoadingState.NotFound))
        else yield put(setLoadingButtonState(LoadingState.Error))
        //@ts-ignore
        yield put(getFailureAction(e))
    }
}

function* payGovernmentFeesSaga(actions: any): any {
    let paymentRequest: PaymentRequestToken | null  = null;
    try {
        yield put(setPaymentError(null))
        const {newSubmitObject: submitObject} = yield actions.payload
        const {client_token} = yield getClientTokenForResponse()
        let newSubmitObj = {
            client_token,
            ...submitObject,
            form_id: FormIdRepository.getInstance().getValue(),
            payment_method: actions.payload.values.payment_method,
        }

        const actionValues = actions.payload.values

        if (actionValues.paymentRequestEvent) {
            paymentRequest = actionValues.paymentRequestEvent;
        }

        if (paymentRequest) {
            newSubmitObj = {...newSubmitObj, creditCardToken: paymentRequest.creditCardToken};
        }

        yield put(getFailureAction(null))
        yield put(setLoadingButtonState(LoadingState.Loading))
        const response = yield Service.payGovernmentFees(newSubmitObj)

        const success = yield getSuccess(response)
        if (success.status) {
            yield put(setStatusOnSubmitObject(success.status))
          }
        //In the future this should be removed, by incorporating new steps after the payGovernmentFee occurs.
        if (success?.dashLink) {
            yield delay(3000)
            yield (window.location.href = `${getRoot()}${success?.dashLink}`)
        }
        if (response?.error) {
            yield put(getFailureAction(response))
            yield put(setLoadingButtonState(LoadingState.Error))
            const paymentError = buildPaymentError(response?.error?.message, getCorrespondFieldByErrorPayment(response?.error?.message, arrayPaymentError))
            yield put(setPaymentError(paymentError))
            yield getScrollElementByClassName({className: "payment-error__content-container"})
            if (response?.billingInformation) {
                yield handleBillingInformationAfterPaymentError(response.billingInformation)
            }
        } else {
            for (let i = 0; i < paymentFields.length; i++) {
                yield put(setValueCurrentField(paymentFields[i], ""))
            }

            if (success?.order_completed_event) {
                yield triggerOrderCompletedEvent(success?.order_completed_event);
            }

            if (success.documentList) {
                yield put(setDocumentList(success.documentList))
            }
            const nameAdditionalSteps: string = yield success.page || success.checkAddress?.status
            yield put(setIndexCurrentStep(0))
            yield put(setCurrentStep(0))
            yield put(getAdditionalArraySteps(nameAdditionalSteps))
            yield put(setCurrentResponse(success.checkAddress))
            if (success.services) {
                yield put(setServicesCustomer(success.services))
            }

            if (success.sidebarData) {
                yield put(setClientEmail(success.sidebarData.email))
                yield sessionStorage.setItem("emailGov", success.sidebarData.email)
                yield put(setClientId(success.sidebarData.subscriptionId))
            }
            yield sessionStorage.setItem("paymentGov", "")
            yield put(setPDF(success?.pdf))
            yield put(setQuestionary(success?.questions?.questionnaire))
            yield put(setLoadingButtonState(LoadingState.Loaded))
            if (success.uploadData) {
                yield put(setUploadDocuments(success.uploadData))
            }
            if (success.canNotarize) {
                yield put(setCanNotarize(success.canNotarize))
            }
            const objUpdated = yield call(FormSagaHelper.updatePropertyForFilterStep, success);
            if(Object.keys(objUpdated).length > 0) {
                yield put(setSubmitObject(objUpdated))
            }
            if (success?.client_token) {
                yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
            }
            if (success.upsellInformation) {
                yield put(setUpsellInformation(success.upsellInformation))
            }

            yield putUpsellProcessingInformation(success)

            yield call(setStatusSignaturePageSaga, success)
        }
    } catch (e) {
        if (e === "Not Found") yield put(setLoadingButtonState(LoadingState.NotFound))
        else yield put(setLoadingButtonState(LoadingState.Error))
        //@ts-ignore
        yield put(getFailureAction(e))
    }

    if (paymentRequest && paymentRequest.requestCompletedCallback) {
        paymentRequest.requestCompletedCallback(paymentRequest.event);
    }
}

function* getAutocompleteSaga(actions: any): any {
    try {
        const {value, nameCities, nameStates} = yield actions.payload
        let response
        if (yield value) {
            response = yield Service.getAutocomplete({zip: value, check: true})
        } else {
            throw new Error(ERRORS.MISSING_VALUE)
        }
        const {city, state} = yield response ?? {}

        const tags = sessionStorage.getItem("tagFields")
        if (city && getHandleValidationByRule({pattern: "^[a-zA-Z0-9 '.,-]{1,30}$|^$"}, city)) {
            yield put(setValueCurrentField(nameCities, city))
            if(tags?.includes('mailing')) {
                yield put(setTagFields({"personal.location.mailing.city": city}))
                yield put(savePrefilled({ "personal.location.mailing.city": city }))
            } else if (tags?.includes('permanent')) {
                yield put(setTagFields({"personal.location.permanent.city": city}))
                yield put(savePrefilled({ "personal.location.permanent.city": city }))
            }
        }
        if (state) {
            yield put(setValueCurrentField(nameStates, state))
            if(tags?.includes('mailing')) {
                yield put(setTagFields({"personal.location.mailing.state": state}))
                yield put(savePrefilled({ "personal.location.mailing.state": state }))
            } else if (tags?.includes('permanent')) {
                yield put(setTagFields({"personal.location.permanent.state": state}))
                yield put(savePrefilled({ "personal.location.permanent.state": state }))
            }
        }
    } catch (e) {
        //@ts-ignore
        // yield put(getFailureAction(e.message))
    }
}

function* checkAddressValidationSaga(actions: any): any {
    try {
        const checkAddress = yield Service.checkAddress(actions.payload.newSubmitObject)
        const {new_address_verification, old_address_verification} = checkAddress

        if (
            new_address_verification &&
            new_address_verification.status &&
            old_address_verification &&
            old_address_verification.status
        ) {
            yield put(submitForm(actions.payload))
        } else {
            yield put(getFailureAction(checkAddress))
        }
    } catch (e) {
        //@ts-ignore
        yield put(getFailureAction(e))
    }
}

async function triggerIdentifyEvent(userHash: string, utmVars: string, submitObject: any, newSubmitObj: any, hasTransaction: boolean = false, isActivated: boolean = false) {
    const responseProfill = await ServiceHostDashBoard.getUsersData();
    const createdAt = responseProfill?.created_at;
    const utmVarsJson = JSON.parse(utmVars);
    const flattenDataHandler = new FlattenDataDto(submitObject.flattenData);
    const dateOfBirth = flattenDataHandler.dateOfBirth ?? newSubmitObj.date_birth;
    const address: IAddress = {
        street: flattenDataHandler.address1 ?? "",
        city: flattenDataHandler.city ?? "",
        state: flattenDataHandler.state ?? "",
        postalCode: flattenDataHandler.zipCode ?? "",
    }

    const isSubscribed = await SubscriptionService.isUserSubscribed();

    await EventsHelper.triggerIdentify(
        OuterIdRepository.getInstance().getValue() ?? "",
        address,
        flattenDataHandler.email ?? "",
        flattenDataHandler.firstName ?? "",
        flattenDataHandler.lastName ?? "",
        flattenDataHandler.phoneNumber ?? "",
        dateOfBirth,
        hasTransaction,
        isSubscribed,
        utmVarsJson,
        isActivated,
        userHash,
        createdAt
    )
}

async function triggerSignedUpEvent(utmVars: string, newSubmitObj: any, response: any, solutionName: string, service: string, subCategory: string) {
    const utmVarsJson = JSON.parse(utmVars);
    const flattenDataHandler = new FlattenDataDto(newSubmitObj.flattenData);

    const dateOfBirth = flattenDataHandler?.dateOfBirth ?? newSubmitObj.date_birth;

    let street = response?.success?.address?.address1;
    if (response?.success?.address?.address2) {
        street += " " + response?.success?.address?.address2;
    }

    const address: IAddress = {
        street: street ?? "",
        city: response?.success?.address?.city_name ?? "",
        state: response?.success?.address?.state_code ?? "",
        country: response?.success?.address?.country_code ?? "",
        postalCode: response?.success?.address?.zipcode ?? "",
    }

    await EventsHelper.triggerSignedUp(
        utmVarsJson,
        response?.success?.created_at ?? "",
        flattenDataHandler?.email ?? "",
        flattenDataHandler?.firstName ?? "",
        flattenDataHandler?.lastName ?? "",
        address,
        getISOFormat(dateOfBirth),
        solutionName ?? "",
        service ?? "",
        subCategory ?? "",
        flattenDataHandler.phoneNumber ?? ""
    )
}

async function triggerOrderCompletedEvent(data: any) {
    /**
     * data?.products is an array of products. But in case of our business logic, we only have one product.
     */
    const orderCompletedBuilder = new OrderCompletedBuilder()
        .setIpAddress(UserIpRepository.getInstance().getValue() ?? "")
        .setGclid(data?.properties?.gclid ?? "")
        .setMsclkid(data?.properties?.msclkid ?? "")
        .setFbc(data?.properties?.fbc ?? "")
        .setFbp(data?.properties?.fbp ?? "")
        .setOrderId(data?.properties?.order_id ?? "")
        .setValue(data?.properties?.value ?? "")
        .setRevenue(data?.properties?.revenue ?? "")
        .setCurrency(data?.properties?.currency ?? "")
        .setUtmMedium(data?.properties?.utm_medium ?? "")
        .setUtmSource(data?.properties?.utm_source ?? "")
        .setUtmCampaign(data?.properties?.utm_campaign ?? "")
        .setUtmContent(data?.properties?.utm_content ?? "")
        .setUtmTerm(data?.properties?.utm_term ?? "")
        .setProductId(data?.properties?.products?.[0]?.product_id ?? "")
        .setSolution(data?.properties?.products?.[0]?.solution ?? "")
        .setService(data?.properties?.products?.[0]?.service ?? "")
        .setProductPrice(data?.properties?.products?.[0]?.price ?? "")
        .setProductQuantity(data?.properties?.products?.[0]?.quantity ?? "")
        .setProductCategory(data?.properties?.products?.[0]?.category ?? "")
        .setProductName(data?.properties?.products?.[0]?.name ?? "")
        .setSubdivision(data?.properties?.products?.[0]?.subdivision ?? "")
        .setLastUtmMedium(data?.properties?.utm_medium_last ?? "")
        .setLastUtmSource(data?.properties?.utm_source_last ?? "")
        .setLastUtmCampaign(data?.properties?.utm_campaign_last ?? "")
        .setLastUtmContent(data?.properties?.utm_content_last ?? "")
        .setLastUtmTerm(data?.properties?.utm_term_last ?? "")
        .setEventType(OrderCompleted.TYPE_ORDER_COMPLETED_PAYMENT_PAGE)

    if (data?.options?.referrer) {
        orderCompletedBuilder
            .setReferrerId(data?.options?.referrer?.id)
            .setReferrerType(data?.options?.referrer?.type);
    }

    if (data?.options?.traits) {
        orderCompletedBuilder.setEmail(data?.options?.traits?.email);
    }

    if (data?.properties?.coupon) {
        orderCompletedBuilder.setCoupon(data?.properties?.coupon);
    }

    const subscriptions = data?.properties?.subscription;
    if (Array.isArray(subscriptions) && subscriptions.length > 0) {
        const subscription = subscriptions[0];
        orderCompletedBuilder
            .setSubscriptionId(subscription?.subscription_id ?? "")
            .setSubscriptionIdExternal(subscription?.subscription_external_id ?? "")
            .setSubscriptionCreationDate(subscription?.subscription_start_date ?? "")
            .setSubscriptionPaymentFrequency(subscription?.subscription_payment_frequency ?? "")
            .setSubscriptionPlan(subscription?.subscription_plan ?? "")
            .setSubscriptionPrice(subscription?.subscription_price ?? "")
            .setSubscriptionStatus(subscription?.subscription_status ?? "");
    }

    await GAnalyticEvents.track(orderCompletedBuilder.build());
}

export default function* formSaga() {
    yield takeEvery(ActionTypes.SUBMIT_FORM, submitFormSaga)
    yield takeEvery(ActionTypes.MAKE_PAYMENT, submitPaymentSaga)
    yield takeEvery(ActionTypes.CONFIRM_SUBSCRIPTION, confirmSubscriptionSaga)
    yield takeEvery(ActionTypes.PAY_APPLICATION_FEE, payApplicationFeeSaga)
    yield takeEvery(ActionTypes.PAY_GOVERNMENT_FEES, payGovernmentFeesSaga)
    yield takeEvery(ActionTypes.PAY_GOVERNMENT_FEES_FROM_SCRAP, payGovernmentFeesFromScrapSaga)
    yield takeEvery(ActionTypes.GET_AUTOCOMPLETE, getAutocompleteSaga)
    yield takeEvery(ActionTypes.CHECK_ADDRESS_VALIDATION, checkAddressValidationSaga)
}
