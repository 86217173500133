import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"

// for compare value and field.mask by count digital
const getAmountSymbolsForMask = (mask: string) => {
  return mask?.split("").reduce((acc: number, item: any) => {
    if (!isNaN(+item)) return acc + 1
    else return acc
  }, 0)
}

//custom validator
export const getValidations = (
  value: string,
  field: FieldInterface,
  validationPattern: string,
  statusValidation: boolean
) => {
  // it need for empty and required fields, exclude digital
  const getValidatorRequired = (value: boolean, optional: boolean) => {
    if (value || optional) {
      return Promise.resolve()
    }
    return Promise.reject(new Error("Required"))
  }

  //for phone number
  // const getPhoneValidation = (value: string) => {
  //   if (value?.[6] === "1" || value?.[7] === "1") {
  //     return Promise.reject(new Error("Incorrect phone number"))
  //   }
  //   return Promise.resolve()
  // }

  const getPhoneValidationByStatus = (statusValidation: boolean) =>
    statusValidation ? Promise.resolve() : Promise.reject(new Error("Incorrect phone number"))

  // only if field.mask exist
  const getValidationForMask = (
    validation: boolean,
    optional: boolean,
    name?: string,
    compareMask?: boolean,
    value?: string,
    ssn?: string,
    mask?: string
  ) => {
    if (validation || optional) {
      return Promise.resolve()
    } else if (
      name === "ssn_applicant" ||
      name === "ssn_mother_num" ||
      name === "ssn_father_num" ||
      name === "ssn" ||
      name === "ssn_oth" ||
      ssn === "ssn"
    ) {
      if (!compareMask && value && getAmountSymbolsForMask(value) <= 5) {
        return Promise.reject(new Error(`Required`))
      }
      if (value && getAmountSymbolsForMask(value) > 5) {
        return Promise.reject(new Error(`Format ${field.placeholder}`))
      }
    }
    if (value && getAmountSymbolsForMask(value) === 0) {
      return Promise.reject(new Error(`Required`))
    } else if (name === "phone" || name === "phone_number") {
      if (value && getAmountSymbolsForMask(value) > 0) {
        return Promise.reject(new Error(`Invalid US Phone`))
      }
    }
    if (mask === "99-9999999" && value && value.length < 10) {
      return Promise.reject(new Error(`Invalid EIN`))
    }
  }

  // only digital patternValidation
  const getValidatorDigital =
    // eslint-disable-next-line max-len
    (onlyDigits: boolean, value: string, minLength?: number, maxLength?: number) => {
      if (!onlyDigits) {
        return Promise.reject(new Error(`Only digits`))
      } else if (minLength && maxLength && ((value && value.length < maxLength) || !value)) {
        if (!value) {
          return Promise.reject(new Error(`Required`))
        } else if (value.length >= minLength) {
          return Promise.resolve()
        }
        return Promise.reject(new Error(`Must be ${minLength}-${maxLength} digits`))
      } else if (!minLength && maxLength && ((value && value.length < maxLength) || !value)) {
        return Promise.reject(new Error(`Requires ${maxLength} Digits`))
      } else {
        return Promise.resolve()
      }
    }

  // It need that compare store and mask
  const newMask =
    field.mask &&
    field.mask
      ?.split("")
      .map((item: any) => {
        if (!isNaN(item)) {
          return "_"
        }
        return item
      })
      .join("")

  // if it have mask, count digital must equaled
  const compareMask = field.mask && getAmountSymbolsForMask(field.mask) === getAmountSymbolsForMask(value)

  const digital =
    validationPattern.indexOf("^([0-9]{") !== -1 || validationPattern.indexOf("^[0-9]{") !== -1 ? true : false

  const getOnlyDigits = (value: string) => {
    const count = value?.split("").reduce((acc: any, item: any) => {
      if (isNaN(item)) {
        return acc + 1
      } else {
        return acc
      }
    }, 0)
    if (count === 0) {
      return true
    } else {
      return false
    }
  }

  // const getMaxLength = (maxLength: number | undefined, valueInput: string) =>
  //   maxLength ? (valueInput.length < maxLength ? Promise.resolve() : Promise.reject("")) : Promise.resolve()

  // if ((field.name === "phone" || field.name === "phone_number") && value?.length === 14) {
  //   return getPhoneValidation(value)
  // }

  if ((field.name === "phone" || field.name === "phone_number") && value?.length !== 14) {
    return getPhoneValidationByStatus(statusValidation)
  }

  if (value?.trim()?.length && !field.mask && !field.maxLength) {
    return getValidatorRequired(true, field.optionalField)
  }

  if (field.mask && compareMask) {
    return getValidatorRequired(true, field.optionalField)
  }

  if (!value?.trim()?.length && !digital) {
    return getValidatorRequired(false, field.optionalField)
  }

  if ((!value || newMask === value) && !digital) {
    return getValidatorRequired(false, field.optionalField)
  }

  if (value && !field.maxLength) {
    // eslint-disable-next-line max-len
    return getValidationForMask(false, field.optionalField, field.name, compareMask, value, field.ssn, field.mask)
  }

  if (digital && !value) {
    // eslint-disable-next-line max-len
    return getValidatorDigital(true, field.optionalField, field.minLength, field.maxLength)
  }

  if (digital && value) {
    // eslint-disable-next-line max-len
    return getValidatorDigital(getOnlyDigits(value), value, field.minLength, field.maxLength)
  }
}

/**
 * This method is called from the validation rules of fields components. These rules are aware of the validation
 * pattern for each input, which comes from the backend JSON forms. The method returns an error message based on
 * the field pattern.
 * @param pattern: string
 * @param field: FieldInterface
 * @returns {
 *     parent: string,
 *     message: string
 * }
 */

export const getPatternRules = (pattern: string, field: FieldInterface) => {
  const getPatternValidation = (pattern: string) => {
    if (field.mask) return ""
    else return pattern
  }

  const getMessageError = (pattern: string) => {
    if (pattern.indexOf("^[a-zA-Z -]") !== -1) {
      return `Only letters, spaces and (-)`
    }
    if (pattern.indexOf("[a-zA-Z0-9& -]") !== -1 || pattern.indexOf("[a-zA-Z0-9 &-]") !== -1) {
      return `Only Alphanumeric, (-) and (&)`
    }
    if (pattern.indexOf("[a-zA-Z0-9 ]") !== -1) {
      return `Only Alphanumeric`
    }
    if (pattern.indexOf("^[A-Za-z]+$") !== -1) {
      return `The field First Name allows only letters with no spaces.`
    }
    if (pattern.indexOf("^[A-Za-z&+]+$") !== -1) {
      return `The field Middle Name allows only letters with no spaces. Please remove all other characters from this field.`
    }
    if (pattern.indexOf("^[a-zA-Z][A-Za-z&-]+$") !== -1) {
      return `The field Last Name allows only letters with no spaces and the following character ( - ) and must begin and end with a letter`
    }
    // if (pattern === "[^[A-Za-z]+$&-]") {
    //   return `The field Last Name allows only letters with no spaces and the following character ( - ) and must begin and end with a letter.`
    // }
    if (pattern.indexOf("+@") !== -1) {
      return `Invalid Email`
    }
    if (pattern.indexOf("^((?:[1-9 -.][0-9 -.]*)(?:\\.[0-9 -.]+)?)$") !== -1) {
      return `Total employees must be at least 1`
    }
    if (pattern.indexOf("^\\d{8}$|^\\w{1}\\d{7}$") !== -1) {
      return `Requires ${8} symbols`
    }
    if (pattern.indexOf("[c,C]{1})(\\d{8})$|^$") !== -1) {
      return `Starts with C and followed by 8 digits`
    }
    if (pattern.indexOf("^(\\d{9})$|^$") !== -1) {
      return `Required: 9 digits`
    }
    return "Error validation"
  }

  const excludeFields = () => {
    if (field.name === "zipcode" || field.name === "card_no" || field.name === "ssc" || field.name === "zip_pay") {
      return false
    } else {
      return true
    }
  }

  return {
    // eslint-disable-next-line max-len
    pattern: new RegExp((excludeFields() && getPatternValidation(pattern)) || ""),
    message: getMessageError(pattern),
  }
}

//confirm for email
export const validatorConfirm = (value: string, getFieldValue: any, dependencies: string) => {
  if (!value || getFieldValue(dependencies) === value) {
    return Promise.resolve()
  }
  return Promise.reject(new Error("Emails are not matching"))
}
