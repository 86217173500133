import React, { FC } from "react"

interface PageMainLayoutInterface {
  className: string;
  children: any;
}

const PageMainLayout: FC<PageMainLayoutInterface> = ({ className, children }) => (
  <div className={className}>{children}</div>
)

export default PageMainLayout
