import React from "react"
import "./IdentityInsuranceCard.scss"
import { IdentityInsuranceProps } from "./IdentityInsuranceCard.props"
import IconsSvg from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GText from "../../atoms/Text/Text"
import { COLORS_NEW } from "../../types/Colors"
import { SIZE } from "../../types/types"
import GCard from "../GCard/GCard"
import useIsMobile from "../../../../../../dashboard-govplus-front/src/hooks/useIsMobile"
import GButton from "../../molecules/Button/GButton"
import GTag from "../../molecules/Tag/GTag"

const ActionsBlock: React.FC<IdentityInsuranceProps> = ({
  subscriptionTeaserAction,
  subscriptionTeaserButtonLabel,
}) => {
  return (
    <div className={"MyIdentityPage__Body__Actions"}>
      <GButton
        text={subscriptionTeaserButtonLabel || ""}
        showIcon={true}
        textSize={SIZE.PARAGRAPH_BOLD_16}
        click={subscriptionTeaserAction}
      />
    </div>
  )
}

const IdentityInsuranceCard: React.FC<IdentityInsuranceProps> = ({
  dynamic,
  subscriptionTeaserStatus,
  subscriptionTeaserAction,
  openIdentityRestorationModal,
  openIdentityTheftInsuranceModal,
}) => {
  const isMobile = useIsMobile(768)

  return (
    <section>
      <div className={"IdentityInsuranceCard"}>
        {dynamic && (
          <div className={"IdentityInsuranceCard__Header"}>
            <GText
              text={"Identity protection coverage"}
              size={isMobile ? SIZE.PARAGRAPH_BOLD_28 : SIZE.PARAGRAPH_BOLD_32}
              color={COLORS_NEW.BLACK_1000}
              style={{ letterSpacing: "-1px" }}
            />
          </div>
        )}

        <div className={"IdentityInsuranceCard__Content"}>
          <GCard
            hasLogo
            hasLine={false}
            title={"Identity restoration"}
            logo={<IconsSvg type={ICONS_SVG_TYPE.ICON_SHIELD_PROTECTED} width={54} height={64} />}
            hasAdditionalElement={!subscriptionTeaserStatus}
            additionalElement={
              <GTag text={"\u2022 Activated"} colorBackground={COLORS_NEW.GREEN_200} colorText={COLORS_NEW.GREEN_700} />
            }
            hasRightElement={subscriptionTeaserStatus}
            rightElement={
              <ActionsBlock
                subscriptionTeaserStatus={subscriptionTeaserStatus}
                subscriptionTeaserAction={subscriptionTeaserAction}
                subscriptionTeaserButtonLabel={"Activate identity protection"}
                linkAction={openIdentityRestorationModal}
                linkLabel={"More details"}
              />
            }
          >
            <div className={"IdentityInsuranceCard__Content__Text"}>
              <GText
                text={"GOV+ helps you regain control of your good name and finances after identity theft occurs."}
                size={SIZE.PARAGRAPH_BOLD_16}
                color={COLORS_NEW.BLACK_500}
              />
            </div>
            {!subscriptionTeaserStatus && (
              <div className={"MyIdentityPage__Body__Link"} onClick={openIdentityRestorationModal}>
                <GText
                  text={"More details"}
                  size={isMobile ? SIZE.PARAGRAPH_BOLD_14 : SIZE.PARAGRAPH_BOLD_16}
                  color={COLORS_NEW.ROYAL_BLUE_700}
                />
                <IconsSvg type={ICONS_SVG_TYPE.ICON_REDIRECT_REVIEW} width={28} height={28} />
              </div>
            )}
          </GCard>
          <GCard
            hasLogo
            title={"Up to $1,000,000 coverage"}
            logo={<IconsSvg type={ICONS_SVG_TYPE.ICON_ISURANCE_DOLLAR_PROTECTED} width={54} height={64} />}
            hasLine={false}
            hasAdditionalElement={!subscriptionTeaserStatus}
            additionalElement={
              <GTag text={"\u2022 Activated"} colorBackground={COLORS_NEW.GREEN_200} colorText={COLORS_NEW.GREEN_700} />
            }
            hasRightElement={subscriptionTeaserStatus}
            rightElement={
              <ActionsBlock
                subscriptionTeaserStatus={subscriptionTeaserStatus}
                subscriptionTeaserAction={subscriptionTeaserAction}
                subscriptionTeaserButtonLabel={"Activate identity protection"}
                linkAction={openIdentityTheftInsuranceModal}
                linkLabel={"More details"}
              />
            }
          >
            <div className={"IdentityInsuranceCard__Content__Text"}>
              <GText
                text={"GOV+ subscription includes Identity Theft Insurance with zero deductible."}
                size={SIZE.PARAGRAPH_BOLD_16}
                color={COLORS_NEW.BLACK_500}
              />
            </div>

            {!subscriptionTeaserStatus && (
              <div className={"MyIdentityPage__Body__Link"} onClick={openIdentityTheftInsuranceModal}>
                <GText
                  text={"More details"}
                  size={isMobile ? SIZE.PARAGRAPH_BOLD_14 : SIZE.PARAGRAPH_BOLD_16}
                  color={COLORS_NEW.ROYAL_BLUE_700}
                />
                <IconsSvg type={ICONS_SVG_TYPE.ICON_REDIRECT_REVIEW} width={28} height={28} />
              </div>
            )}
          </GCard>
        </div>
      </div>
    </section>
  )
}

export default IdentityInsuranceCard
