import SwitchBlock from "../SwitchBlock/SwitchBlock"
import React, { useCallback, useEffect, useMemo } from "react"
import { ItemInterface } from "../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { FieldInterface } from "../../../Redux/InterfacesEntity/field.interface"
import { hiddenField } from "../FieldsComponents/InputComponent/serviceInput"
import { checkShowOnAutofill, checkSubscription } from "./checkShowOn"
import { clearChildrenHiddenBlock } from "./dynamicBlock"
import { getPropertiesForEvents } from "../../../services/gtm/backEndEvent"
import {ProductsIds} from "../../../util/ProductsIds";

type PageMainContainerProps = {
  item: FieldInterface,
  blockObject: { [key: string]: string },
  enableEditor: any,
  index: number,
  getLaunchEditor: (e: any, submitArrayField: []) => void,
  submitArrayField: any,
  submitObject: { [key: string]: any },
  observer: boolean,
  dispatch: any,
  tagStoreWithFields: any,
  setPrefilledValueInSubmitObject: any,
  setValueCurrentField: any,
  observers: { [key: string]: any },
  selectOptions: any,
}

const CheckStatusTagFile: React.FunctionComponent<PageMainContainerProps> = ({
  item,
  blockObject,
  enableEditor,
  index,
  getLaunchEditor,
  submitArrayField,
  submitObject,
  observer,
  dispatch,
  tagStoreWithFields,
  setPrefilledValueInSubmitObject,
  setValueCurrentField,
  observers,
  selectOptions,
}) => {
  const SPLITTER = "-"
  const mapDatesForStore = useCallback(
    (valueTag: string | undefined, arraySubFields: string[], SPLITTER: string) =>
      valueTag
        ?.split(SPLITTER)
        .forEach((splitterDate, index) => dispatch(setValueCurrentField(arraySubFields[index], splitterDate))),
    [dispatch]
  )
  const statusTagFields = useMemo(() => hiddenField(item?.tag, tagStoreWithFields), [item?.tag, tagStoreWithFields])
  useEffect(() => {
    setPrefilledValueInSubmitObject(item.name, item?.tag, setValueCurrentField, tagStoreWithFields, dispatch)

    if (item?.type === "dateGroupDropDown") {
      const arraySubFields = item?.fields?.map(({ name }) => name).reverse()
      mapDatesForStore(tagStoreWithFields[item?.tag ?? ""], arraySubFields ?? [], SPLITTER)
    }
  }, [
    dispatch,
    item?.fields,
    item.name,
    item?.tag,
    item?.type,
    mapDatesForStore,
    setPrefilledValueInSubmitObject,
    setValueCurrentField,
    statusTagFields,
    tagStoreWithFields,
  ])

  //temporary for PTP 13.10
  const { form_id } = useMemo(() => getPropertiesForEvents(), [])

  if (statusTagFields) {
    return null
  }

  const showOnAutofill = checkShowOnAutofill({ observers, blockObject: item })

  if (item.type === "observer" && !showOnAutofill && observers?.[item.name] && form_id === ProductsIds.GloFormId) {
    return null
  }

  if (item.type === "observer" && !observers?.[item.name] && !item.observer_default) {
    return null
  }

  if (item.type === "observer" && observers?.[item.name] && checkSubscription({ observers, blockObject: item })) {
    clearChildrenHiddenBlock({ fields: item?.fields, setValueCurrentField, dispatch, submitObject })
    return null
  }

  return (
    <li
      className={`field-in-block ${blockObject.name} editor_${!!enableEditor} ${item.name}`}
      key={index}
      onClick={(e) => getLaunchEditor(e, submitArrayField)}
      id={`${item.name}_scrollLabelById`}
    >
      <SwitchBlock field={item} value={submitObject[item.name]} observer={observer} />
    </li>
  )
}

export default CheckStatusTagFile
