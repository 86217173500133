/* eslint-disable react-redux/useSelector-prefer-selectors */
import React from "react"
import "./EmailBreachCard.scss"
import { AlertBackgroundTypes, formatDateToShort } from "../../../../util/IdentityProtectionHelper"
import GText from "../../atoms/Text/Text"
import { COLORS_NEW } from "../../types/Colors"
import { SIZE } from "../../types/types"
import GAlertBackgroundMessage from "../AlertBackgroundMessage/AlertBackgroundMessage"
import GCard from "../GCard/GCard"
import GTable from "../Table/Table"
import { EmailBreachCardProps } from "./EmailBreachCard.props"
import GSpinner from "../../atoms/Spinner/Spinner"

const EmailBreachCard: React.FC<EmailBreachCardProps> = ({
  emailBreaches,
  hasReviewLink,
  redirectLink,
  emailBreachesLoader,
  emailBreachError,
}) => {
  const headerCells = ["Company name", "Date of breach"]

  const transformEmailBreach = (alerts: { [key: string]: string }[]): { subject: string, date: string }[] => {
    return (
      alerts?.map((alert) => ({
        subject: alert?.Name,
        date: formatDateToShort(alert?.AddedDate),
      })) || []
    )
  }

  const renderEmailBreach = (emailBreaches: any[]) => {
    return emailBreaches && emailBreaches?.length > 0 ? (
      <>
        <div className={"EmailBreachCard__Text"}>
          <GText
            size={SIZE.PARAGRAPH_BOLD_16}
            color={COLORS_NEW.BLACK_500}
            text={`There are ${emailBreaches?.length} alerts that need your attention. Review now to keep your identity safe.`}
          />
        </div>
        <GTable
          headerCells={headerCells}
          details={Array.isArray(emailBreaches) ? transformEmailBreach(emailBreaches) : []}
        />
      </>
    ) : (
      <GAlertBackgroundMessage
        status={AlertBackgroundTypes.SUCCESS}
        message={"Good news! There is no suspicious activity that needs your attention."}
      />
    )
  }

  return (
    <section className={emailBreachesLoader ? "EmailBreachCard__Loading" : "EmailBreachCard"}>
      <GCard
        title={"Email breach"}
        alertsCount={emailBreaches?.length}
        hasReviewLink={hasReviewLink}
        redirectLink={redirectLink}
      >
        {emailBreachesLoader && (
          <div className={"EmailBreachCard__Overlay"}>
            <div className={"EmailBreachCard__Spinner"}>
              <GSpinner />
            </div>
          </div>
        )}
        {!emailBreachError && emailBreaches && renderEmailBreach(emailBreaches)}

        {emailBreachError && !emailBreaches?.length && (
          <GAlertBackgroundMessage
            status={AlertBackgroundTypes.ERROR}
            message={"Error fetching user data. Please try again later."}
          />
        )}
      </GCard>
    </section>
  )
}

export default EmailBreachCard
