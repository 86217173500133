import React, { useEffect } from "react"
import "./IdentityProtectionCancel.scss"
import { useNavigation } from "../../../../../dashboard-govplus-front/src/hooks/useNavigation"
import GButton from "../../../Components/atomicDesign/molecules/Button/GButton"
import { GeneralText, IdentityProtectionTeaserText, SubscriptionText } from "../../../shared/text/Text"
import ProductPage from "../../../Components/atomicDesign/templates/ProductPage/ProductPage"
import { COLORS } from "../../../Components/atomicDesign/types/Colors"
import GText from "../../../Components/atomicDesign/atoms/Text/Text"
import { SIZE } from "../../../Components/atomicDesign/types/types"
import AddPersonalInfoCard from "../../../Components/atomicDesign/templates/AddPersonalInfoCard/AddPersonalInfoCard"
import IdentityInsuranceCard from "../../../Components/atomicDesign/templates/IdentityInsuranceCard/IdentityInsuranceCard"
import { useIdentityProtection } from "../../../../../dashboard-govplus-front/src/hooks/useIdentityProtection"
import { IdentityProtectionType } from "../../../util/IdentityProtectionHelper"

const IdentityProtectionCancel = () => {
  const { navigate } = useNavigation()
  const { identities, getMyIdentities, getAlerts, getEmailBreaches } = useIdentityProtection()

  const proceedCancellation = () => {
    navigate("/SubscriptionCancelReason")
  }

  const keepSubscription = () => {
    navigate("./billing-settings")
  }

  const letterSpacingText = {
    letterSpacing: "-0.1px",
    cursor: "pointer",
  }

  useEffect(() => {
    getMyIdentities()
    getAlerts(IdentityProtectionType.ALL)
    getEmailBreaches()
  }, [])

  const Footer = () => {
    return (
      <div className={"IdentityProtectionCancel__Footer"}>
        <GButton
          text={GeneralText.KeepPremium}
          click={keepSubscription}
          showIcon={false}
          textSize={SIZE.PARAGRAPH_REGULAR_16}
          textStyle={{
            letterSpacing: "-0.1px",
            fontWeight: 600,
          }}
        />
        <GText
          text={SubscriptionText.CancelIdentityProtection}
          innerHtml={SubscriptionText.CancelIdentityProtection}
          size={SIZE.PARAGRAPH_REGULAR_16}
          color={COLORS.ROYAL_BLUE_500}
          style={letterSpacingText}
          onClick={proceedCancellation}
        />
      </div>
    )
  }

  const ContentPage = () => {
    return (
      <div className={"IdentityProtectionCancel"}>
        <IdentityInsuranceCard dynamic={false} />
        <AddPersonalInfoCard
          identities={identities?.records?.filter((record: any) => record.isPresent)}
          dynamic={false}
          isTeaserRecordsStatus={false}
          isTeaserSubscriptionStatus={false}/>
      </div>
    )
  }

  return (
    <ProductPage
      List={ContentPage}
      headerText={IdentityProtectionTeaserText.IdentityProtectionCancelHeader}
      subHeaderText={IdentityProtectionTeaserText.IdentityProtectionCancelExplanation}
      Footer={Footer}
      showDivider={false}
    />
  )
}

export default IdentityProtectionCancel
