import {FC} from "react";
import {BasicBlockContainerProps} from "../../../../UploadPhoto/UploadPhotoService/interfaces/interfaces";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../App";
import {currentStateDocumentsUploadInterface} from "./UploadDocumentActionBlock";
import {
    formSelector,
    renderBasicBlockContainer,
    uploadSelector
} from "../../../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto";
import LayoutBaseContainer from "../../../../UploadPhoto/Layouts/LayoutBaseContainer/LayoutBaseContainer";

const DynamicArrayVariables: FC<BasicBlockContainerProps> = ({
                                                                  item: {
                                                                      className,
                                                                      children: childrenItem,
                                                                      text,
                                                                      state,
                                                                      dynamic_array_variables
                                                                  },
                                                                  arrayComponents,
                                                              }) => {
    const submitObject =
        useSelector<RootState, { submitObject: currentStateDocumentsUploadInterface }>(formSelector).submitObject

    const dynamicText = dynamic_array_variables?.reduce((acc: string, variable: string) =>
        acc.replace(variable, submitObject[variable]), text) ?? ""

    return renderBasicBlockContainer({className, text:dynamicText, childrenItem, arrayComponents, state},
        LayoutBaseContainer
    )
}

export default DynamicArrayVariables