import React, { FC } from "react"
import "./DocumentsTemplateCard.scss"
import { DocumentTrackingCardProps } from "./DocumentsTemplateCard.props"
import GTag from "../../molecules/Tag/GTag"
import GText from "../../atoms/Text/Text"
import { COLORS_NEW } from "../../types/Colors"
import { PROGRESS_BAR_ORIENTATION, SIZE } from "../../types/types"
import GProgressBar from "../../molecules/ProgressBar/ProgressBar"
import IconsSvg from "../../atoms/IconsSvg/IconsSvg"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"

const DocumentsTemplateCard: FC<DocumentTrackingCardProps> = ({
  productName,
  productStatus,
  productImage,
  isActive,
  imageBgColor,
  cardStyle,
  icon,
  footer,
  click,
  hasProgressBar,
  completedPercentage,
}) => {
  const tagTextColor = isActive ? COLORS_NEW.GREEN_700 : COLORS_NEW.BLACK_400
  const tagBgColor = isActive ? COLORS_NEW.GREEN_200 : COLORS_NEW.BLACK_100
  const labelTextColor = isActive ? COLORS_NEW.BLACK_1000 : COLORS_NEW.BLACK_600

  return (
    <div className={"DocumentsTemplateCard"} style={cardStyle} onClick={click}>
      <div className={"DocumentsTemplateCard__Content"}>
        {productImage && <div className={"DocumentsTemplateCard__Content__Image"} style={imageBgColor}>
          <img src={productImage} alt="Product logo" />
          {icon && <div className={"DocumentsTemplateCard__Content__Image__Icon"}>{icon}</div>}
        </div>}

        <div className={"DocumentsTemplateCard__Content__Block"}>
          <div className={"DocumentsTemplateCard__Content__Label"}>
            <IconsSvg
              type={ICONS_SVG_TYPE.COLORED_DOT}
              width={8}
              height={8}
              color={isActive ? COLORS_NEW.GREEN_600 : COLORS_NEW.RED_700} 
            />
            <GText text={productName} size={SIZE.PARAGRAPH_BOLD_16} color={labelTextColor} />
          </div>
          {hasProgressBar && (
            <div className={"DocumentsTemplateCard__Content__ProgressBar"}>
              <GProgressBar
                value={completedPercentage as number}
                orientation={PROGRESS_BAR_ORIENTATION.LINEAR}
                showIcon={false}
                color={COLORS_NEW.GREEN_600}
              />
            </div>
          )}
          {productStatus && (
            <div className={"DocumentsTemplateCard__Content__Tag"}>
              <GTag text={productStatus} colorBackground={tagBgColor} colorText={tagTextColor} />
            </div>
          )}
          {footer && <div className={"DocumentsTemplateCard__Content__Footer"}>{footer}</div>}
        </div>
      </div>
    </div>
  )
}

export default DocumentsTemplateCard
