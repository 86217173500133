export enum PageNames {
    SIGN_IN = 'Sign in',
    SIGN_UP = 'Sign up',
    FORGOT_PASSWORD = 'Forgot password',
    RESET_PASSWORD = 'Reset password',
    MY_APPLICATIONS = 'My applications',
    NEW_APPLICATION_PRODUCT_INFO = 'Product Detail',
    NEW_APPLICATION = 'New application',
    FORM = 'Form',
    ADD_PHONE = 'Add Phone',
    VERIFICATION_CODE = 'Verification Code',
    WELCOME = 'Welcome',
    COA_FIRST_STEPS = 'COA First steps',
    COA_LAST_STEPS = 'COA Last steps',
    SSN_FIRST_STEPS = 'SSN First steps',
    SSN_LAST_STEPS = 'SSN Last steps',
    CHECK_STATUS = 'Check status',
    PAYMENT = 'Payment',
    UPSELL = 'Upsell',
    PROCESSING = 'Processing',
    ACTIVATION_ACCOUNT = 'Activation Account',
    CHANGE_PASSWORD = 'Change Password',
}

export enum PageInUrl {
    Form = "form",
    Payment = "payment",
    Upsell = "upsell",
    Processing = "processing",
}

export enum Path {
    // Auth
    SIGN_IN = '/signin',
    SIGN_UP = '/signup',
    RESET_PASSWORD = '/reset',
    // Applications
    MY_APPLICATIONS = '/products',
    MY_APPLICATIONS_ = '/products',
    NEW_APPLICATION = '/formsApp',
    NEW_APPLICATION_PRODUCT_INFO = '/products/productInfo',
    FORM = '/forms/',
    ADD_PHONE = '/addPhone',
    VERIFICATION_CODE = '/verification_code',
    WELCOME = '/welcomepage',
    CHANGE_PASSWORD = '/change-password',
    TAXES_PROCESSING = '/taxes/taxes-processing',
    IRS_REFUND_PAGE = 'https://www.irs.gov/refunds',
    DOCUMENTS_PAGE = '/',
}

export const SinInPageTitle = 'Sign In - Dashboard | GOV+';
export const CommonPageTitle = 'GOV+ | Dashboard';