import React, {FC} from "react";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import './GServiceCard.scss'
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg";
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes";

interface GServiceCardProps {
    imageURL?: string,
    showBadge?: boolean,
    badgeIcon?: string,
    Content: FC<any>,
    className?: string,
    serviceIsActivated?: boolean
}

const GServiceCardActive: FC<GServiceCardProps> = (
    {
        imageURL,
        showBadge = true,
        badgeIcon = ICONS_SVG_TYPE.CHECK_CIRCLE,
        Content,
        className,
    }
) => {

    return <div className={`GServiceCard ${className || ''}`}>
        <div
            className={"GServiceCard__ImageHeader"}>
            {showBadge &&
            <div className={"GServiceCard-Badge"}>
                <GIconsSvgType type={badgeIcon} width={38} height={38} color={COLORS.WHITE} backgroundColor={COLORS_NEW.GREEN_600}/>
            </div>
            }
            <img src={imageURL} alt={``} />
        </div>
        <Content />
    </div>
}

export default GServiceCardActive