import BaseLocalStorageRepository from "./BaseLocalStorageRepository";

const FOR_BREADCRUMBS_KEY = "forBreadcrumbs";

class ForBreadcrumbsRepository extends BaseLocalStorageRepository{

    private static _instance: ForBreadcrumbsRepository;

    private constructor() {
        super();
    }

    public static getInstance(): ForBreadcrumbsRepository {
        if (!ForBreadcrumbsRepository._instance) {
            ForBreadcrumbsRepository._instance = new ForBreadcrumbsRepository();
        }
        return ForBreadcrumbsRepository._instance;
    }
    
    getKeyName(): string {
        return FOR_BREADCRUMBS_KEY;
    }
}

export default ForBreadcrumbsRepository;