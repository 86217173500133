import React from "react";
import {specialStatusConstants} from "../../productsAndStatusesConstants";
import PendingStatusComponent from "./COA_pendingStatusComponent";
import NoSuchProductOrStatus from "../../../NoSuchProductOrStatus";

const AddressChange = ({statusName, productId, productName})=>{
  const StatusRender = () =>{
    switch (statusName){
      // temporary cause all COA special statuses is equal PendingStatusComponent
      case specialStatusConstants[productName][statusName]:{
        return (
          <PendingStatusComponent
            productId={productId}
            status={statusName}
          />
        )
      }
      default: return (
        <NoSuchProductOrStatus
          description={"Sorry page for this status is not ready yet."}
          appId={productId}
          productName={productName}
          productStatus={statusName}
        />
      )
    }
  }

  return (
    <div>{StatusRender()}</div>
  )
}

export default AddressChange;
