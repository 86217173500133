import { Form, Input, TimePicker } from "antd"
import moment from "moment"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { connect } from "react-redux"
import React, { useState } from "react"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import {getLabel} from "../../../../services/service-function/field";

type TimePickerComponentProps = {
  blockObject: { headerFields: string, fields: [FieldInterface] } | any,
  submitObject: {} | any,
  dispatch: any,
}

// eslint-disable-next-line max-len
const TimePickerComponentComponent: React.FunctionComponent<TimePickerComponentProps> = ({
  blockObject,
  submitObject,
  dispatch,
}) => {
  const format = "HH:mm"

  const handlerOnChangeTimePicker = (time: moment.Moment | null, timeString: string, nameBlock: string) => {
    dispatch(setValueCurrentField(nameBlock, time))
  }

  const handlerOnSelectTimePicker = (time: moment.Moment | undefined, nameBlock: string) => {
    dispatch(setValueCurrentField(nameBlock, time))
  }

  const [render, setRender] = useState(true)
  const [timeId, setTimeId]: any = useState(null)

  const handlerOnBlurTimePicker = () => {
    setTimeId(setTimeout(() => setRender(false), 0))
    setTimeId(setTimeout(() => setRender(true), 1))
    return () => clearTimeout(timeId)
  }

  return (
    <Form.Item
      label={getLabel(blockObject?.label)}
      // eslint-disable-next-line max-len
      className={"date-group__data-picker time-group__data-picker"}
    >
      <Input.Group size="small">
        {blockObject?.fields?.map((itemTime: any, index: number) => {
          return (
            <React.Fragment key={index}>
              {render && (
                <Form.Item
                  name={itemTime.name}
                  initialValue={submitObject[blockObject.name]}
                  // rules={[{ validator: validation }]}
                >
                  <TimePicker
                    format={format}
                    onChange={(time, timeString) => handlerOnChangeTimePicker(time, timeString, blockObject.name)}
                    onSelect={(time) => handlerOnSelectTimePicker(time, blockObject.name)}
                    onBlur={handlerOnBlurTimePicker}
                    value={submitObject[blockObject.name]}
                  />
                </Form.Item>
              )}
              {!render && (
                <Form.Item
                  name={itemTime.name}
                  initialValue={submitObject[blockObject.name]}
                  // rules={[{ validator: validation }]}
                >
                  <TimePicker
                    format={format}
                    onChange={(time, timeString) => handlerOnChangeTimePicker(time, timeString, blockObject.name)}
                    onSelect={(time) => handlerOnSelectTimePicker(time, blockObject.name)}
                    onBlur={handlerOnBlurTimePicker}
                    // value={submitObject[blockObject.name]}
                  />
                </Form.Item>
              )}
            </React.Fragment>
          )
        })}
      </Input.Group>
    </Form.Item>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
})

export default connect(mapStateToProps)(TimePickerComponentComponent)
