import Event from "../Event"
import {EventOptions, EventProps} from "../EventProperties"
import IEvent from "../IEvent";

const EVENT_NAME = "Order Completed";

export default class OrderCompleted extends Event implements IEvent {

    static TYPE_ORDER_COMPLETED_PAYMENT_PAGE = "Order Completed Payment Page";
    static TYPE_ORDER_COMPLETED_NOT_PAYMENT_PAGE = "Order Completed Not Payment Page";

    private readonly _gclid: string | undefined;
    private readonly _msclkid: string | undefined;
    private readonly _fbc: string | undefined;
    private readonly _fbp: string | undefined;
    private readonly _orderId: string | undefined;
    private readonly _value: string | undefined;
    private readonly _revenue: string | undefined;
    private readonly _currency: string | undefined;
    private readonly _productId: string | undefined;
    private readonly _service: string | undefined;
    private readonly _solution: string | undefined;
    private readonly _productPrice: string | undefined;
    private readonly _productQuantity: string | undefined;
    private readonly _productCategory: string | undefined;
    private readonly _productName: string | undefined;
    private readonly _utmCampaign: string | undefined;
    private readonly _utmContent: string | undefined;
    private readonly _utmMedium: string | undefined;
    private readonly _utmSource: string | undefined;
    private readonly _utmTerm: string | undefined;
    private readonly _lastUtmCampaign: string | undefined;
    private readonly _lastUtmContent: string | undefined;
    private readonly _lastUtmMedium: string | undefined;
    private readonly _lastUtmSource: string | undefined;
    private readonly _lastUtmTerm: string | undefined;
    private readonly _subdivision: string | undefined;
    private readonly _referrerId: string | undefined;
    private readonly _referrerType: string | undefined;
    private readonly _email: string | undefined;
    private readonly _coupon: string | undefined;
    private readonly _subscriptionId: string | undefined
    private readonly _subscriptionIdExternal: string | undefined
    private readonly _subscriptionPlan: string | undefined
    private readonly _subscriptionPaymentFrequency: string | undefined
    private readonly _subscriptionPrice: string | undefined
    private readonly _subscriptionStatus: string | undefined
    private readonly _subscriptionCreationDate: string | undefined
    private readonly _eventType: string | undefined

    constructor(
        ipAddress: string | undefined,
        gclid: string | undefined,
        msclkid: string | undefined,
        fbc: string | undefined,
        fbp: string | undefined,
        orderId: string | undefined,
        value: string | undefined,
        revenue: string | undefined,
        currency: string | undefined,
        productId: string | undefined,
        service: string | undefined,
        solution: string | undefined,
        productPrice: string | undefined,
        productQuantity: string | undefined,
        productCategory: string | undefined,
        productName: string | undefined,
        utmMedium: string | undefined,
        utmSource: string | undefined,
        utmCampaign: string | undefined,
        utmContent: string | undefined,
        utmTerm: string | undefined,
        lastUtmMedium: string | undefined,
        lastUtmSource: string | undefined,
        lastUtmCampaign: string | undefined,
        lastUtmContent: string | undefined,
        lastUtmTerm: string | undefined,
        subdivision: string | undefined,
        referrerId: string | undefined,
        referrerType: string | undefined,
        email: string | undefined,
        coupon: string | undefined,
        subscriptionId: string | undefined,
        subscriptionIdExternal: string | undefined,
        subscriptionPlan: string | undefined,
        subscriptionPaymentFrequency: string | undefined,
        subscriptionPrice: string | undefined,
        subscriptionStatus: string | undefined,
        subscriptionCreationDate: string | undefined,
        eventType: string | undefined
    ) {
        super(EVENT_NAME, ipAddress);
        this._gclid = gclid;
        this._msclkid = msclkid;
        this._fbc = fbc;
        this._fbp = fbp;
        this._orderId = orderId;
        this._value = value;
        this._revenue = revenue;
        this._currency = currency;
        this._productId = productId;
        this._service = service;
        this._solution = solution;
        this._productPrice = productPrice;
        this._productQuantity = productQuantity;
        this._productCategory = productCategory;
        this._productName = productName;
        this._utmMedium = utmMedium;
        this._utmSource = utmSource;
        this._utmCampaign = utmCampaign;
        this._utmContent = utmContent;
        this._utmTerm = utmTerm;
        this._lastUtmMedium = lastUtmMedium;
        this._lastUtmSource = lastUtmSource;
        this._lastUtmCampaign = lastUtmCampaign;
        this._lastUtmContent = lastUtmContent;
        this._lastUtmTerm = lastUtmTerm;
        this._subdivision = subdivision;
        this._referrerId = referrerId;
        this._referrerType = referrerType;
        this._email = email;
        this._coupon = coupon;
        this._subscriptionId = subscriptionId;
        this._subscriptionIdExternal = subscriptionIdExternal;
        this._subscriptionPlan = subscriptionPlan;
        this._subscriptionPaymentFrequency = subscriptionPaymentFrequency;
        this._subscriptionPrice = subscriptionPrice;
        this._subscriptionStatus = subscriptionStatus;
        this._subscriptionCreationDate = subscriptionCreationDate;
        this._eventType = eventType;
    }

    getEventData(): EventProps {
        let product = {};
        product = this.addProperty('quantity', this._productQuantity, product);
        product = this.addProperty('category', this._productCategory, product);
        product = this.addProperty('price', this._productPrice, product);
        product = this.addProperty('name', this._productName, product);
        product = this.addProperty('product_id', this._productId, product);

        if(this._eventType === OrderCompleted.TYPE_ORDER_COMPLETED_PAYMENT_PAGE) {
            product = this.addProperty('solution', this._solution, product);
            product = this.addProperty('service', this._service, product);
            product = this.addProperty('subdivision', this._subdivision, product);
        }

        let data = super.baseEventData();
        data = this.addProperty('gclid', this._gclid, data);
        data = this.addProperty('msclkid', this._msclkid, data);
        data = this.addProperty('fbc', this._fbc, data);
        data = this.addProperty('fbp', this._fbp, data);
        data = this.addProperty('order_id', this._orderId, data);
        data = this.addProperty('orderId', this._orderId, data);
        data = this.addProperty('value', this._value, data);
        data = this.addProperty('revenue', this._revenue, data);
        data = this.addProperty('total', this._revenue, data);
        data = this.addProperty('currency', this._currency, data);
        data = this.addProperty('utm_medium', this._utmMedium, data);
        data = this.addProperty('utm_source', this._utmSource, data);
        data = this.addProperty('utm_campaign', this._utmCampaign, data);
        data = this.addProperty('utm_content', this._utmContent, data);
        data = this.addProperty('utm_term', this._utmTerm, data);
        data = this.addProperty('utm_medium_last', this._lastUtmMedium, data);
        data = this.addProperty('utm_source_last', this._lastUtmSource, data);
        data = this.addProperty('utm_campaign_last', this._lastUtmCampaign, data);
        data = this.addProperty('utm_content_last', this._lastUtmContent, data);
        data = this.addProperty('utm_term_last', this._lastUtmTerm, data);
        data = this.addProperty('order_category', this._productCategory, data);
        data = this.addProperty('coupon', this._coupon, data);
        data = this.addProperty('solution', this._solution, data);
        data = this.addProperty('service', this._service, data);

        let subscription = {};
        subscription = this.addProperty('subscription_id', this._subscriptionId, subscription);
        subscription = this.addProperty('subscription_external_id', this._subscriptionIdExternal, subscription);
        subscription = this.addProperty('subscription_payment_frequency', this._subscriptionPaymentFrequency, subscription);
        subscription = this.addProperty('subscription_plan', this._subscriptionPlan, subscription);
        subscription = this.addProperty('subscription_price', this._subscriptionPrice, subscription);
        subscription = this.addProperty('subscription_start_date', this._subscriptionCreationDate, subscription);
        subscription = this.addProperty('subscription_status', this._subscriptionStatus, subscription);

        return {
            ...data,
            products: [
                product
            ],
            subscription: [
                subscription
            ]
        }
    }

    getEventName(): string {
        return this.eventName;
    }

    pageName(): string {
        return "";
    }

    getPreviousId(): string {
        return "";
    }

    getUserId(): string {
        return "";
    }

    getEventOptions(): EventOptions | undefined {
        let options = undefined;
        if(this._referrerId && this._referrerType) {
            options = {
                referrer: {
                    id: this._referrerId,
                    type: this._referrerType
                }
            }
        }
        if(this._email) {
            options = {
                ...options,
                traits: {
                    email: this._email
                }
            }
        }
        return options;
    }
}
