import { createContext, useMemo, useState } from "react";
import SubscribeWindowModal from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/SubscribeWindowModal/SubscribeWindowModal";
import { PaymentModelTypes } from "../constants/PaymentModelTypes";
import { SubscriptionText, SubscriptionTextLists, VariablesToBeReplaced } from "@dash/gov-plus__front-end__form/src/shared/text/Text";
import { usePaymentModel } from "../hooks/usePaymentModel";
import UpsellSelectedBuilder from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/UpsellSelected/UpsellSelectedBuilder";
import { UpsellCategories } from "@dash/gov-plus__front-end__form/src/util/UpsellHelper";
import UserIpRepository from "@dash/gov-plus__front-end__form/src/persistence/session/UserIpRepository";
import GAnalyticEvents from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/AnalyticEvents";
import { useUser } from "../hooks/useUser";
import { usePayment } from "../hooks/usePayment";
import UpsellShownBuilder from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/UpsellShown/UpsellShownBuilder";
import { getRoot } from "@dash/gov-plus__front-end__form/src/Redux/store/step/service.step.saga";
import { PaymentInformation } from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import UpsellSkippedBuilder from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/UpsellSkipped/UpsellSkippedBuilder";
import { useSubscription } from "../hooks/useSubscription";
import ConfirmPaymentModal from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/ConfirmPaymentModal/ConfirmPaymentModal";
import PurchasedOfferModal from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/PurchasedOfferModal/PurchasedOfferModal";
import { SUBSCRIPTION_PERIOD_YEARLY } from "@dash/gov-plus__front-end__form/src/shared/constans/payment";
import { SubscriptionTrialOptions } from "../constants/SubscriptionTrialOptions";

type IModalsConext = {
  checkIfShowSubscriptionModal: () => void,
  openPopup: () => void,
  closePopup: () => void,
  loadingPaymentModel: boolean,
  openPurchasedModal: (data: PurchasedOfferModalData) => void
}

type PurchasedOfferModalData = {
    offerName: string, 
    offerImage: string
}

// @ts-ignore
export const ModalsContext = createContext<IModalsConext>()

// @ts-ignore
export const ModalsProvider = ({ children }) => {
  const {subscribe, loading: loadingSubscription} = useSubscription()
  const [subscriptionModal, setSubscriptionModal] = useState(false)
  const [paymentModal, setPaymentModal] = useState(false)
  const [paymentModalError, setPaymentModalError] = useState(false)
  const [purchasedOfferModal, setPurchasedOfferModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [subscriptionSelectedPrice, setSubscriptionSelectedPrice] = useState(0);
  const {userSubmittedData} = useUser()
  const [purchasedOfferData, setPurchasedOfferData] = useState<PurchasedOfferModalData>()
  const [selectedSubscription, setSelectedSubscription] = useState(SUBSCRIPTION_PERIOD_YEARLY)
  const [selectedTrial, setSelectedTrial] = useState(SubscriptionTrialOptions.MINIMUM)

  const isUserValid = ()=>{
      return userSubmittedData?.id
  }

  const {
      billingInformation,
      getBillingInformation,
      loading: loadingPayment,
  } = usePayment()

  const {paymentModel, loading: loadingPaymentModel} = usePaymentModel()

  const benefitsList = useMemo(() => {
      switch (paymentModel) {
          case PaymentModelTypes.SUBSCRIPTION_FEE:
          case PaymentModelTypes.COMBINED_FEE:
              return SubscriptionTextLists.DEFAULT_CAPABILITIES_LIST
          case PaymentModelTypes.ONE_TIME_PAYMENT_FEE:
              return SubscriptionTextLists.HYBRID_CAPABILITIES_LIST
          default:
              return []
      }
  }, [paymentModel])

  const onGetSubscription = (period?: string, trial?: string) => {
    period && setSelectedSubscription(period)
    trial && setSelectedTrial(trial)
      const upgradeSelectedBuilder = new UpsellSelectedBuilder()
          .setUpsellName("Subscription Popup")
          .setPrice("119.88")
          .setUpsellCategory(UpsellCategories.Subscription)
          .setIpAddress(UserIpRepository.getInstance().getValue());
      GAnalyticEvents.track(upgradeSelectedBuilder.build());

      getBillingInformation((response) => {
        // @ts-ignore
        if (response?.payment_methods?.length === 0 || !response?.default_payment_methods) {
            closePopup()
            if(response) {
                setPaymentModal(true)
            }
        } else {
            const payment: PaymentInformation = {
                isNewPaymentMethod: false,
                // @ts-ignore
                paymentMethod: response.default_payment_methods,
                period,
                trial
            }
            onPayment(payment)
        }
      })
  }

  const checkIfShowSubscriptionModal = () => {
      if(isUserValid()){
          const upgradeShownBuilder = new UpsellShownBuilder()
              .setUpsellName("Subscription Popup")
              .setPrice("119.88")
              .setUpsellCategory(UpsellCategories.Subscription)
              .setIpAddress(UserIpRepository.getInstance().getValue());
          GAnalyticEvents.track(upgradeShownBuilder.build());
          openPopup()
      }
      else{
          window.location.href = `${getRoot()}/signup?service=GLO&form_id=glo-sub`
      } 
  }
  
  const onPayment = (data: PaymentInformation) => {
      subscribe(data, (response: any) => {
          if (response.errorCard === true) {
              setErrorMessage(response.msg)
              showPaymentModalError()
              closePopup()
          } else {
              setErrorMessage('')
              setPaymentModal(false)
              setPaymentModalError(false)
              closePopup()
          }
      })
  }

  const onCloseSubscriptionModal = () => {
      const upgradeSkippedBuilder = new UpsellSkippedBuilder()
          .setUpsellName("Subscription Popup")
          .setPrice("119.88")
          .setUpsellCategory(UpsellCategories.Subscription)
          .setIpAddress(UserIpRepository.getInstance().getValue());
      GAnalyticEvents.track(upgradeSkippedBuilder.build());
      closePopup()
  }

  const openPopup = () => {
    setSubscriptionModal(true)
    const popup = document.querySelector(".SubscribePopup")
    popup && popup.parentElement?.setAttribute("style","display: block")
    popup && popup.setAttribute("style","display: block")
    popup?.querySelector(".ant-modal")?.setAttribute("style", "display: inline-block")
  }

  const closePopup = () => {
      const popup = document.querySelector(".SubscribePopup")
      popup && popup.parentElement?.setAttribute("style","display: none")
      popup && popup.setAttribute("style","display: none")
      popup?.querySelector(".ant-modal")?.setAttribute("style", "display: none")

  }

  const showPaymentModalError = () => {
    if (!paymentModal) {
        setPaymentModalError(true)
    }
  }

  const openPurchasedModal = (data: PurchasedOfferModalData) => {
    setPurchasedOfferData(data)
    setPurchasedOfferModal(true)
  }

  const getPaymentAuthorizationDescription = () =>
    SubscriptionText.PaymentAuthorizationDescription.replace(VariablesToBeReplaced.SubscriptionPrice, subscriptionSelectedPrice?.toString())

  const getPriceInfo = () => {
      return {
          productName: 'GOV+ Subscription',
          totalPay: Number(subscriptionSelectedPrice) * 100
      }
  }

  const loading = loadingPaymentModel || loadingSubscription || loadingPayment

  const returnValue = {
    checkIfShowSubscriptionModal,
    openPopup,
    closePopup,
    loadingPaymentModel,
    openPurchasedModal
  }

  return (
    <ModalsContext.Provider value={returnValue}>
        <PurchasedOfferModal 
            offerName={purchasedOfferData?.offerName} 
            offerImage={purchasedOfferData?.offerImage}
            isOpen={purchasedOfferModal}
            onCancel={() => setPurchasedOfferModal(false)}
        />
      <SubscribeWindowModal
        isOpen={subscriptionModal}
        onClose={onCloseSubscriptionModal}
        onSubmit={onGetSubscription}
        loading={loading}
        benefitsList={benefitsList}
      />
      <ConfirmPaymentModal
          title={`GOV+ Premium`}
          close={()=>setPaymentModal(false)}
          isOpen={paymentModal}
          submitPaymentCallback={onPayment}
          paymentMethods={billingInformation?.payment_methods || []}
          defaultPaymentMethod={billingInformation?.default_payment_methods || ""}
          paymentDescription={`Your GOV+ Premium will be charged to the payment method below:`}
          paymentAuthorizationDescription={[getPaymentAuthorizationDescription(), SubscriptionText.SubscriptionCancelAuthorization]}
          setSubscriptionSelectedPrice={setSubscriptionSelectedPrice}
          isLoading={loading}
          errorMessage={errorMessage}
          showSubscriptionOptions={true}
          priceInfo={getPriceInfo()}
          selectedSubscription={selectedSubscription}
          selectedTrial={selectedTrial}
      />
      <ConfirmPaymentModal
        title={`There was an error with your payment method`}
        close={()=>setPaymentModalError(false)}
        isOpen={paymentModalError}
        submitPaymentCallback={onPayment}
        paymentMethods={billingInformation?.payment_methods || []}
        defaultPaymentMethod={billingInformation?.default_payment_methods || ""}
        paymentAuthorizationDescription={[]}
        isLoading={loading}
        errorMessage={errorMessage}
        priceInfo={getPriceInfo()}
        mode="payment_error"
        selectedSubscription={selectedSubscription}
        selectedTrial={selectedTrial}
    />
      {children}
    </ModalsContext.Provider>
  )

}