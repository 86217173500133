import React, {useState, useEffect} from 'react'
import './style.less'
import {Link} from "react-router-dom";
import {Input, Form} from 'antd'
import {ArrowLeftOutlined} from '@ant-design/icons'
import SettingsButton from "../../../components/SettingsButton";

const EditEmailPage = () => {
  const [phone, setPhone] = useState()
  const [confirmPhonePassword, setConfirmPhonePassword] = useState("")
  const [confirmPhoneStatus, setConfirmPhoneStatus] = useState(false)

  useEffect(() => {
    // here we will get email
    setPhone('+12648643')
  }, [])

  const changePhone = (e) => {
    setPhone(e.target.value)
  }

  const changePhonePassword = (e) => {
    setConfirmPhonePassword(e.target.value)
  }

  const sendNewPhone = (inputsData) => {
    setConfirmPhoneStatus(prevState => !prevState);
    // here we will make request
  }

  return <div className='EditPhonePage'>
    <Link to='/account-settings' className='EditPhonePage-link'>
      <ArrowLeftOutlined className='EditPhonePage-link-icon'/>Account Settings
    </Link>
    <h1 className='EditPhonePage-title'>Edit Phone Number</h1>
    <Form
      name={"editPhonePage"}
      className='EditPhonePage-form'
      layout="vertical"
      onFinish={sendNewPhone}
    >
      <Form.Item
        name={"phoneNumber"}
        label='Phone Number'
        className='EditPhonePage-form-box-item'
        rules={
          [{
            pattern: new RegExp(/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i),
            required: false,
            message: 'Invalid phone number'
          }]
        }
      >
        <Input
          className='EditPhonePage-form-box-item-input'
          value={phone}
          onChange={changePhone}/>
      </Form.Item>


      <div className={"confirmPhonePasswordContainer"}>
        <p className={"confirmPhonePasswordContainer_description"}>
          By entering a mobile phone number, you consent to receive automated security notification via text message
          from GOV+. Message and data rates may apply. Message frequency varies.
        </p>
        <Form.Item
          name={"password"}
          label='Password'
          className='confirmPhonePasswordContainer_passwordBlock'
        >
          <Input
            className='confirmPhonePasswordContainer_passwordBlock-input'
            type={"password"}
            onChange={changePhonePassword}
          />
        </Form.Item>
      </div>

      <SettingsButton
        disabled={confirmPhonePassword.length === 0 && !confirmPhoneStatus}
        buttonText={"Save Phone Number"}
      />
    </Form>

    <div className={"cancelLink"}>
        <span onClick={() => {
          setConfirmPhoneStatus(prevState => !prevState)
        }}>Cancel</span>
    </div>

  </div>
}

export default EditEmailPage
