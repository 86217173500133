const IntercomHelper = {
    showBubbleChat:() => {
        // @ts-ignore
        if (typeof window.Intercom === "function") {
            // @ts-ignore
            window.Intercom('show');
        } else {
            window.open('https://govplus.com/contact-us');
        }
    }
}
export default IntercomHelper;