import {CHANGE_PASSWORD, CHANGE_PASSWORD_FAILED, HIDE_MESSAGE_PASSWORD_CHANGE} from "../../constants/ActionTypes";

export const changePassword = (payload)=>{
  return{
    type:CHANGE_PASSWORD,
    changePasswordData: {
      currentPassword: payload.currentPassword,
      newPassword: payload.newPassword,
    }
  }
};

export const changePasswordShowMessage = (payload) => {
  return {
    type: CHANGE_PASSWORD_FAILED,
    changePasswordMessage: payload
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE_PASSWORD_CHANGE,
  };
};