import React from "react"

interface ISvgVerificationCodePhone {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
}

export const SvgVerificationCodePhone = ({ width, height, color, backgroundColor }: ISvgVerificationCodePhone) => {
  return (
    <svg width={width} height={height} viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.585938" width={width} height={height} rx="32" fill={backgroundColor} />
      <g clipPath="url(#clip0_14767_439034)">
        <path
          d="M32 42.5859C37.5228 42.5859 42 38.1088 42 32.5859C42 27.0631 37.5228 22.5859 32 22.5859C26.4772 22.5859 22 27.0631 22 32.5859C22 38.1088 26.4772 42.5859 32 42.5859Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32 36.5859L36 32.5859L32 28.5859"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M28 32.5859H36" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_14767_439034">
          <rect width="24" height="24" fill="white" transform="translate(20 20.5859)" />
        </clipPath>
      </defs>
    </svg>
  )
}
