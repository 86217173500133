import { setObservers, setValueCurrentField } from "../../../../../Redux/store/form/form.actions"
import { Dispatch } from "redux"

export const VALUE_CASES = {
  USA: "United States",
}

export const OPTION_CASES = {
  IS_USA: "Is USA",
  NOT_USA: "Not USA",
}

export const OBSERVER_NAMES = {
  AUTOFILL_STATE_BIRTH: {
    block_citizenship_autofill_state: {
      type: "showOnCityBirthAutofill",
      target: "block_citizenship_autofill_state",
    },
  },
}

export const FIELD_NAMES = {
  COUNTRY_BIRTH: "country_birth",
  STATE_BIRTH: "state_birth",
}

interface GetTypeCityBirthInterface {
  value: string;
  VALUE_CASES: { [key: string]: string };
  dispatch: Dispatch;
  OPTION_CASES: { [key: string]: string };
  OBSERVER_NAMES: { [key: string]: {} };
  FIELD_NAMES: { [key: string]: string };
}

export const getTypeCityBirth = ({
  value,
  VALUE_CASES,
  dispatch,
  OPTION_CASES,
  OBSERVER_NAMES,
  FIELD_NAMES,
}: GetTypeCityBirthInterface) => {
  dispatch(setValueCurrentField(FIELD_NAMES.STATE_BIRTH, ``))
  if (value === VALUE_CASES.USA) {
    dispatch(setObservers(OBSERVER_NAMES.AUTOFILL_STATE_BIRTH, OPTION_CASES.IS_USA))
    return
  }
  dispatch(setObservers(OBSERVER_NAMES.AUTOFILL_STATE_BIRTH, OPTION_CASES.NOT_USA))
}
