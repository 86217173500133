import React from "react"
import { connect } from "react-redux"

import {StepInterface} from "../../../../Redux/InterfacesEntity/step.interface";
import GButton from "../../../atomicDesign/molecules/Button/GButton";
import "./ButtonNextComponent.scss"
import {STEP_NAME_PAYMENT_PAGE} from "../../../../shared/constans/payment";
import {SIZE} from "../../../atomicDesign/types/types";
type ButtonNextComponentProps = {
  type: string,
  buttonNext?: string | boolean | undefined,
  loadingButtonState: string,
  checkStatusClass: string,
  getNextButtonName: any,
  stripeLoaded: boolean | null,
  currentStep: StepInterface | any,
}

// eslint-disable-next-line max-len
const ButtonNextComponent: React.FunctionComponent<ButtonNextComponentProps> = ({
  type,
  buttonNext,
  loadingButtonState,
  checkStatusClass,
  getNextButtonName,
  stripeLoaded,
  currentStep,
}) => {
  const loadingContent = loadingButtonState === "loading" ? "Loading" : getNextButtonName(type, buttonNext)
  let buttonEnable=true
  const gButtonContainerCss: React.CSSProperties = {}
  if(currentStep.name === STEP_NAME_PAYMENT_PAGE){
    gButtonContainerCss.display = 'flex'
    gButtonContainerCss.flexDirection = 'column';
  }
  return (

      <div className="g-button-next-component" style={gButtonContainerCss}>
        <GButton
            text={loadingContent}
            disabled={!buttonEnable}
            loading={loadingButtonState === "loading"}
            isSubmit={true}
            size={'small'}
            showIcon={true}
            textSize={SIZE.PARAGRAPH_BOLD_16}
        />
      </div>

  )
}

const mapStateToProps = (state: any) => {
  return {
    stripeLoaded: state.form.stripeLoaded,
    currentStep: state.step.currentStep,
  }
}

export default connect(mapStateToProps)(ButtonNextComponent)
