import React, {useEffect, useState} from "react";
import "./index.less";
import Header from "../Header";
import Spinner from "../../components/spiner/Spinner";
import {getParams} from "../../assets/helpers/getParams";
import {useNavigationHandling} from "./hooks/useNavigationHandler";
import {IconSvg} from "../../components/svgImages/SvgImages";
import {useSelector} from "react-redux";

const Resolve = () => {
  const {retargetingToken} = getParams(window.location.search, ["retargetingToken"]) || {};
  const {retargetUserData} = useSelector(({notes}) => notes);
  const [isUserNameExist, setIsUserNameExist] = useState("");
  useNavigationHandling(retargetingToken);

  useEffect(() => {
    if (retargetUserData.firstName) {
      setIsUserNameExist(retargetUserData.firstName)
    }
  }, [retargetUserData.firstName])

  return (
    <div className={"gx-app-login-wrap resolve_container"}>
      <Header/>
      {!isUserNameExist
        ? <Spinner/>
        : <div className={"gx-app-login-container resolve_container-block"}>
          <div>
            <IconSvg type={"userAddIcon"}/>
            <h1>Welcome back
              {!retargetUserData.firstName ? "!" : <span> {retargetUserData.firstName},</span>}
            </h1>
            <p>Please wait as we prepare your application.</p>
          </div>
          <Spinner/>
        </div>
      }
    </div>
  );
};

export default Resolve;
