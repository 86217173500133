/* eslint-disable indent */
import { ActionTypes } from "./select.actions"
import { Action } from "../../interfacesAction/action.interface"
import { dynamicOptionsSelects } from "../../../services/initial-data/initialDynamicSelects"
export interface State {
  block_diff_name_prev: {} | any;
}

const initialState: State = {
  block_diff_name_prev: dynamicOptionsSelects.block_diff_name_prev || {},
}

export const selectReducer = (state: State = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.SET_VALUE_FOR_SELECT:
      const blockName = action.payload.nameCurrentBlock
      const fieldName = action.payload.nameCurrentField
      const value = action.payload.valueCurrentField

      const newObject = {
        ...state.block_diff_name_prev,
        [blockName]: {
          ...state.block_diff_name_prev[blockName],
          [fieldName]: value,
        },
      }
      return {
        ...state,
        block_diff_name_prev: newObject,
      }
    default:
      return state
  }
}
