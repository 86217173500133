import React from "react"

interface ISvgClock {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
}

export const SvgClock = ({width, height, color, backgroundColor}: ISvgClock) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_26420_91625)">
        <path d="M8.16667 15.5677C11.8486 15.5677 14.8333 12.5829 14.8333 8.90104C14.8333 5.21914 11.8486 2.23438 8.16667 2.23438C4.48477 2.23438 1.5 5.21914 1.5 8.90104C1.5 12.5829 4.48477 15.5677 8.16667 15.5677Z"
              stroke={color}
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"/>
        <path
          d="M8.16797 4.89844V8.89844L10.8346 10.2318"
          stroke={color}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_26420_91625">
          <rect width={width} height={height} fill="white" transform="translate(0.167969 0.898438)"/>
        </clipPath>
      </defs>
    </svg>
  )
}
