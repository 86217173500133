import React, { FC } from "react"
import { connect } from "react-redux"
import "./StepUpsell.scss"
import {getPropertiesForEvents} from "../../../../../services/gtm/backEndEvent";

import UpsellTemplateOneComponent from "./templates/templateOne/UpsellTemplateOneComponent";
// @ts-ignore
import {UPSELL_PROPS, UPSELL_TEMPLATES} from "./templates/UpsellProps";

// @ts-ignore
import UpsellSubmitBuilder from "./templates/UpsellSubmitBuilder";
import {TemplateOneFactory} from "./templates/templateOne/Factory/TemplateOneFactory";
// @ts-ignore
import {usePaymentModel} from "../../../../../../../dashboard-govplus-front/src/hooks/usePaymentModel";
import {PaymentModelTypes} from "../../../../../../../dashboard-govplus-front/src/constants/PaymentModelTypes";

export interface IUpsellProcessingData {
  settings_values?: any;
  funnel_id?: number;
  page_id?: number;
  page_name?: string;
  price?: string;
  page_iterator?: number;
  test_mode?: boolean;
  test_mode_message?: string;
  link_to_open?: string;
  product_handler?: string;
  service?: string;
  service_name?: string;
  page_type: string;
  cross_sell_product_offer?: string
}

export type GStepUpsellProps = {
  data: IUpsellProcessingData,
  template: string,
  formId: string,
  uniqSession: string,
  purchasedOfferCallback? : (offerData: IUpsellProcessingData) => void
}

const GStepUpsell: FC<GStepUpsellProps> = ({ data, template, formId, uniqSession, purchasedOfferCallback}) => {
  const paymentModel = PaymentModelTypes.ONE_TIME_PAYMENT_FEE
  const getTemplate = () => {
    if (template === UPSELL_TEMPLATES.UPSELL_TEMPLATE_ONE) {

      /*ToDo: When we will merge analytic branch, we need to change the way to get solutionName because this way was refactored. */
      const {solutionName} = getPropertiesForEvents();
      const templateFactory = new TemplateOneFactory();
      const templateOne = templateFactory.getTemplateOne(solutionName, data, paymentModel);
      const upsellSubmitBuilder = new UpsellSubmitBuilder()
          .setFormId(formId)
          .setUpsellFunnelId(data.funnel_id)
          .setUpsellPageId(data.page_id)
          .setIterator(data.page_iterator)
          .setHandler(data.product_handler)
          .setUniqSession(uniqSession);


      return <UpsellTemplateOneComponent
                templateInformation={templateOne}
                upsellSubmitObject={upsellSubmitBuilder}
                offerData={data}
                purchasedOfferCallback={purchasedOfferCallback}
             />
    }

    return <div>Unsupported Upsell Template</div>
  }

  return <div className={`GStepUpsell`}>{getTemplate()}</div>
}

const mapStateToProps = (state: any) => ({
  loadingButtonState: state.loading.loadingButtonState,
  submitObject: state.form.submitObject,
})

export default connect(mapStateToProps)(GStepUpsell)
