/* eslint-disable react-redux/useSelector-prefer-selectors */
import React, { FC } from "react"
import { useLocation, NavLink } from "react-router-dom"
import Sider from "antd/lib/layout/Sider"
import { Menu } from "antd"
import GLogo from "../../molecules/Logo/Logo"
import "./Sidebar.scss"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import GText from "../../atoms/Text/Text"
import { ISideVarItem, SIDE_VAR_THEME } from "./SidebarTheme"
import {useUser} from "../../../../../../dashboard-govplus-front/src/hooks/useUser"
import {Path} from "../../../../util/PageHelper"

type GSidebarProps = {}

const isActive = (item: ISideVarItem, location: any) => {
  let result = false
  if (item.active.some((str) => location.pathname.includes(str))) {
    result = true
  }
  return result
}

export const getItems = (location: any, closeSideBar?: () => void) => {
  const { userSubmittedData } = useUser()

  const isUserValid = () => {
    return userSubmittedData?.id
  }

  return SIDE_VAR_THEME.itemsMenu
    .filter((item: ISideVarItem) => !(item.requiresAccount && !isUserValid()))
    .map((item: ISideVarItem) => {
      return {
        label: (
          <NavLink to={item.link} exact activeClassName={`GSidebar__Menu__Item--Active`} onClick={closeSideBar}>
            <div className={`GSidebar__Menu__Item`}>
              <GIconsSvgType
                type={item.icon}
                color={`${SIDE_VAR_THEME.iconColor}`}
                width={SIDE_VAR_THEME.iconWidth}
                height={SIDE_VAR_THEME.iconHeight}
              />
              <GText text={item.text} size={SIDE_VAR_THEME.textSize} color={`${SIDE_VAR_THEME.iconColor}`} />
            </div>
          </NavLink>
        ),
        key: item.key,
      }
    })
}

const GSidebar: FC<GSidebarProps> = () => {
  const location = useLocation()
  const logoUrl = Path.DOCUMENTS_PAGE

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const sideBarBG: React.CSSProperties = { backgroundColor: SIDE_VAR_THEME.backgroundColor }
  return (
    <Sider breakpoint="lg" collapsedWidth="0" theme={"light"} className="GSidebar" width={SIDE_VAR_THEME.width}>
      <div className="GSidebar__Content">
        <GLogo url={logoUrl} />
        <Menu className={"GSidebar__Menu"} mode="inline" items={getItems(location)} />
      </div>
      {/*This component should be added in the feature*/}
      {/*<GReferral />*/}
    </Sider>
  )
}

export default GSidebar
