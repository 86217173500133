import Event from "../Event"
import {EventOptions, EventProps} from "../EventProperties"
import IEvent from "../IEvent"

const EVENT_NAME = "Identity Protection Activation Completed"

/**
 * Identity Protection Activation Completed
 * @class IdentityProtectionCoverageCompleted
 * @extends {Event}
 * @implements {IEvent}
 * @description Identity Protection Activation Completed event
 */

export default class IdentityProtectionCoverageCompleted extends Event implements IEvent {
    private readonly _bundle_id: string | undefined;
    private readonly _package_name: string | undefined;

    /**
     * Creates an instance of IdentityProtectionCoverageCompleted
     * @memberof IdentityProtectionCoverageCompleted
     * @description Identity Protection Activation Completed event
     */

    constructor(
        bundle_id: string | undefined, 
        package_name: string | undefined,
        ipAddress: string | undefined) {
        super(EVENT_NAME, ipAddress)
        this._bundle_id = bundle_id;
        this._package_name = package_name;
    }

    getEventData(): EventProps {
        return {
            ...super.baseEventData(),
            bundle_id: this._bundle_id,
            package_name: this._package_name,
        }
    }

    getEventName(): string {
        return this.eventName
    }

    pageName(): string {
        return ""
    }

    getPreviousId(): string {
        return ""
    }

    getUserId(): string {
        return ""
    }

    getEventOptions(): EventOptions | undefined {
        return undefined
    }
}
