export const ProductsTypes = {
  ADDRESS_CHANGE_IMAGE: "AddressChange",
  PASSPORT: "PASSPORT",
  PAS: "PAS",
  TSA: "tsa",
  EIN: "EIN",
  VITALS: "CER",
  SSN_CARD: "ssCard",
  SSN: "SSN",
  COA: "COA",

  GLOBAL_ENTRY: "",
  ITIN: "itin",
  ITI: "ITI",
  E_RECORDS: "e-records",


  ATT: 'ATT',
  COMCAST: "COMCAST",
  QUICKBOOKS: "quickbooks",
  US_POSTAL_SERVICE: "usPostalService",
  FEDEX_SERVICE: "FeDex"
}
