/* eslint-disable indent */
import { initialSubmitObject } from "../../../services/initial-data/initialSubmitObject"
let formId = new URLSearchParams(window.location.search).get("formId")

// if (!formId) {
//   const url = window.location.href
//   formId = url.split("/")[window.location.href.split("/").length - 1]
// }

export const getInitialObject = () => {
  return formId && formId.split("-")[0] === "ssn"
    ? {
        ...initialSubmitObject,
      }
    : {}
}
