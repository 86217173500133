import React, {FC, useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import GUpsellCard, {CARD_THEME} from "../../../../../organisms/UpsellCard/GUpsellCard";
import "./UpsellTemplateOneComponent.scss";
import GText from "../../../../../atoms/Text/Text";
import {
    OfferType,
    PaymentError,
    PaymentInformation,
    SIZE
} from "../../../../../types/types";
import {COLORS, COLORS_NEW} from "../../../../../types/Colors";
import {LoadingState} from "../../../../../../../shared/constans/user-from-view-mode.enum";
import TemplateOne from "./TemplateOne";
import UpsellSubmitBuilder from "../UpsellSubmitBuilder";
import {nextUpsellProcessingPage} from "../../../../../../../Redux/store/additionalForm/additional.actions";
import {UPSELL_ACTIONS} from "../UpsellProps";
import UserIpRepository from "../../../../../../../persistence/session/UserIpRepository";
import GAnalyticEvents from "../../../../../analytic/AnalyticEvents";
import UpsellShownBuilder from "../../../../../analytic/event/UpsellShown/UpsellShownBuilder";
import UpsellSkippedBuilder from "../../../../../analytic/event/UpsellSkipped/UpsellSkippedBuilder";
import UpsellSelectedBuilder from "../../../../../analytic/event/UpsellSelected/UpsellSelectedBuilder";
import ConfirmPaymentModal from "../../../../ConfirmPaymentModal/ConfirmPaymentModal";
import {
    BillingInformation
} from "../../../../../../FormComponents/BlockFormWithFields/BillingInformation/BlockBillingInformation";
import {ISubmitObject} from "../UpsellSubmit";
import {setPaymentError} from "../../../../../../../Redux/store/form/form.actions";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {computeOfferName} from "../../../../../../../util/UpsellHelper";
import {IUpsellProcessingData} from "../../StepUpsell";
import {PurchaseOffer} from "../../../../../../../util/PurchaseOffer";
import {GeneralText, SubscriptionText, VariablesToBeReplaced} from "../../../../../../../shared/text/Text";
// @ts-ignore
import { useABTestingPayment } from "../../../../../../../../../dashboard-govplus-front/src/hooks/useABTestingPayment";
import { SUBSCRIPTION_PERIOD_YEARLY } from "../../../../../../../shared/constans/payment";


type UpsellTemplateOneComponentProps = {
    templateInformation: TemplateOne,
    upsellSubmitObject: UpsellSubmitBuilder,
    loadingButtonState: string,
    billingInformation: BillingInformation,
    paymentError: PaymentError | null;
    purchasedOfferCallback? : (offerData: IUpsellProcessingData) => void
    offerData: IUpsellProcessingData
}

const GUpsellTemplateOneComponent: FC<UpsellTemplateOneComponentProps> =
    ({
         templateInformation,
         upsellSubmitObject,
         loadingButtonState,
         billingInformation,
         paymentError,
         purchasedOfferCallback,
         offerData
     }) => {
        const breakPoint = useBreakpoint()

        const dispatch = useDispatch()
        const [acceptBtnDisable, setAcceptBtnDisable] = useState<boolean>(false)
        const [acceptBtnLoading, setAcceptBtnLoading] = useState<boolean>(false)
        const [cancelBtnDisable, setCancelBtnDisable] = useState<boolean>(false)
        const [isConfirmPaymentModalOpen, setIsConfirmPaymentModalOpen] = useState<boolean>(false);
        const isCrossSell = templateInformation.pageType === OfferType.CrossSell;
        const isUpgrade = templateInformation.pageType === OfferType.Upgrade;
        const offerName = computeOfferName(isCrossSell, templateInformation.serviceName, templateInformation.crossSellProductOffer);
        const [subscriptionSelectedPrice, setSubscriptionSelectedPrice] = useState(0);
        const {getSubscriptionPaymentOptions} = useABTestingPayment()

        const handleAcceptButton = () => {
            triggerAcceptUpsellAnalyticEvent();
            if (!templateInformation.testMode) {
                if(templateInformation?.linkToOpen){
                    acceptUpsellWithoutCost();

                    /**
                     * This code is related with the third party A/B testing. G will provide the custom link to open
                     * the third party A/B testing. If the link is provided, then the link will be opened in the new
                     * tab. If the link is not provided, then the link come from backend database will be opened in
                     * the new tab.
                     */
                        // @ts-ignore
                    let link = window?.GovPlusSettings?.thirdPartyLink ?? templateInformation.linkToOpen;
                    window.open(link, '_blank');
                    return;
                }
                showConfirmPaymentModal();
                return;
            }
            acceptTestUpsell();
        }

        const triggerAcceptUpsellAnalyticEvent = () => {
            let eventBuilder = new UpsellSelectedBuilder();
            if (eventBuilder) {
                eventBuilder.setUpsellName(gettingServiceName(templateInformation))
                    .setPrice(templateInformation?.price || '0')
                    .setUpsellCategory(templateInformation.pageType)
                    .setIpAddress(UserIpRepository.getInstance().getValue());
                GAnalyticEvents.track(eventBuilder.build());
            }
        }

        const disableButtons = () => {
            setAcceptBtnDisable(true)
            setCancelBtnDisable(true)
        }

        const acceptPaymentUpsell = (payment: PaymentInformation) => {
            upsellSubmitObject.setAction(UPSELL_ACTIONS.ACTION_ACCEPT);
            upsellSubmitObject.setPaymentInformation(payment);
            const newObj = upsellSubmitObject.build().getSubmitObject();
            if (payment.period === SUBSCRIPTION_PERIOD_YEARLY) {
                const abTestingUpgradeOptions = getSubscriptionPaymentOptions()
                newObj.subscriptionCouponEnabled = abTestingUpgradeOptions.subscriptionCouponEnabled
                newObj.subscriptionCouponId = abTestingUpgradeOptions.subscriptionCouponIdOffer
            }
            acceptUpsellAction(newObj, purchasedOfferCallback);
        }

        const acceptUpsellAction = (submitObject: ISubmitObject, purchasedOfferCallback?: (offerInformation: any) => void) => {
            disableButtons();
            setAcceptBtnLoading(true)
            const purchaseOffer: PurchaseOffer={offerData: offerData, offerPurchasedEvent: purchasedOfferCallback};
            dispatch(nextUpsellProcessingPage({...submitObject, purchaseOffer}))
        }

        const acceptTestUpsell = () => {
            if (templateInformation.testMode) {
                alert(templateInformation.testModeMessage);
            }
            upsellSubmitObject.setAction(UPSELL_ACTIONS.ACTION_ACCEPT);
            const newObj = upsellSubmitObject.build().getSubmitObject();
            acceptUpsellAction(newObj);
        }
        const acceptUpsellWithoutCost = () => {
            upsellSubmitObject.setAction(UPSELL_ACTIONS.ACTION_ACCEPT);
            const newObj = upsellSubmitObject.build().getSubmitObject();
            acceptUpsellAction(newObj);
        }

        const cancelUpsell = (e?: any) => {
            e?.preventDefault();
            if (cancelBtnDisable) return;

            setAcceptBtnDisable(true);
            setCancelBtnDisable(true);

            let eventBuilder = new UpsellSkippedBuilder();
            if (eventBuilder) {
                eventBuilder.setUpsellName(gettingServiceName(templateInformation))
                    .setPrice(templateInformation?.price || '0')
                    .setUpsellCategory(templateInformation.pageType)
                    .setIpAddress(UserIpRepository.getInstance().getValue());
                GAnalyticEvents.track(eventBuilder.build());
            }

            upsellSubmitObject.setAction(UPSELL_ACTIONS.ACTION_CANCEL);
            const newObj = upsellSubmitObject.build().getSubmitObject();
            dispatch(nextUpsellProcessingPage(newObj));
        }

        useEffect(() => {
            let eventBuilder = new UpsellShownBuilder();
            if (eventBuilder) {
                eventBuilder.setUpsellName(gettingServiceName(templateInformation))
                    .setPrice(templateInformation?.price || '0')
                    .setUpsellCategory(templateInformation.pageType)
                    .setIpAddress(UserIpRepository.getInstance().getValue());
                GAnalyticEvents.track(eventBuilder.build());
            }
        }, [])

        const gettingServiceName = (templateInformation: TemplateOne) => {
            /**
             * In case of cross sell, the service name is calculated depending on product offer.
             */
            if(templateInformation.pageType === OfferType.CrossSell){
                return templateInformation.pageType + " " + templateInformation.crossSellProductOffer;
            }
            return templateInformation.serviceName;
        }


        const showConfirmPaymentModal = () => {
            setIsConfirmPaymentModalOpen(true);
        };

        const closeConfirmPaymentModal = () => {
            if(loadingButtonState !== LoadingState.Loading){
                setIsConfirmPaymentModalOpen(false);
                dispatch(setPaymentError(null));
                setAcceptBtnDisable(false)
                setCancelBtnDisable(false)
            }
        };

        const  paymentAuthorizationDescription = () => {
            const description = []
            /**
             * Hard coding the payment authorization description for now.
             */
            let paymentAuthorizationDescription = `By clicking Submit, I authorize GOV+ to charge my credit card for $${templateInformation.price}.`
            if(templateInformation.service === "govplus-completed-application"){
                paymentAuthorizationDescription += ` Government fees not included.`;
            }

            const subscriptionPaymentAuthorizationDescription = SubscriptionText.PaymentAuthorizationDescription.replace(VariablesToBeReplaced.SubscriptionPrice, subscriptionSelectedPrice?.toString())
            description.push(isUpgrade ? subscriptionPaymentAuthorizationDescription : paymentAuthorizationDescription)

            if (offerData.page_type === OfferType.Upgrade) {
                description.push(SubscriptionText.SubscriptionCancelAuthorization)
            }
            return description;
        }

        const getUpsellCardPriceInformation = () =>
             templateInformation.pageType === OfferType.Upgrade ?
                `<span>${GeneralText.StartsAt}</span> <br>` +
                `$${templateInformation.price}/month` :
                `$${templateInformation.price}`

        const getPriceInfo = () => {
          if(!isUpgrade && templateInformation.price && offerName) {
            const priceStr = templateInformation.price;
            const price = Number.parseInt(priceStr) * 100;
            return {productName: offerName, totalPay: price};
          } else if (subscriptionSelectedPrice) {
            return {
              productName: 'GOV+ Subscription',
              totalPay: Number(subscriptionSelectedPrice) * 100
            }
          } else {
            return undefined
          }
        }

        return (
            <div className={`GUpsellTemplateOneComponent`}>
                <ConfirmPaymentModal
                    title={`Get your ${offerName} with just one ${breakPoint.xs ? 'tap' : 'click'}`}
                    close={closeConfirmPaymentModal}
                    isOpen={isConfirmPaymentModalOpen}
                    submitPaymentCallback={acceptPaymentUpsell}
                    paymentMethods={billingInformation?.payment_methods || []}
                    defaultPaymentMethod={billingInformation?.default_payment_methods}
                    paymentDescription={`Your ${offerName} will be charged to the payment method below:`}
                    setSubscriptionSelectedPrice={setSubscriptionSelectedPrice}
                    paymentAuthorizationDescription={paymentAuthorizationDescription()}
                    isLoading={acceptBtnDisable && loadingButtonState === LoadingState.Loading}
                    errorMessage={paymentError?.message}
                    priceInfo={getPriceInfo()}
                    showSubscriptionOptions={templateInformation.pageType === OfferType.Upgrade}
                />

                <GUpsellCard
                    theme={CARD_THEME.ONE}
                    className={`${templateInformation.pageType} ${templateInformation.service}`}
                    imageHeader={templateInformation.imageHeader}
                    componentHeader={templateInformation.componentHeader}
                    title={templateInformation.title}
                    subtitle={templateInformation.subTitle}
                    subtitleSize={templateInformation.subTitleSize}
                    checkList={templateInformation.checkListItems}
                    checkListSubTitle={templateInformation.checkListSubTitle}
                    prevPriceInformation={templateInformation.previousPrice && `$${templateInformation.previousPrice}`}
                    priceInformation={templateInformation.price && getUpsellCardPriceInformation()}
                    tagInformation={templateInformation.tagInformation}
                    logoSubTitle={templateInformation.imageHeaderSubtitle}
                    textButton={templateInformation.acceptButton}
                    iconButton={templateInformation.iconAcceptButton}
                    footerText={templateInformation.footerText}
                    ctaClick={handleAcceptButton}
                    ctaDisable={acceptBtnDisable && loadingButtonState === LoadingState.Loading}
                    ctaLoading={acceptBtnLoading && loadingButtonState === LoadingState.Loading}
                />

                <div className={`GUpsellTemplateOneComponent__CtaCancelText`}>
                    <GText text={'Or'} size={SIZE.PARAGRAPH_REGULAR_16}
                           color={COLORS_NEW.BLACK_600}/>
                    <GText
                        text={templateInformation.cancelButton}
                        size={SIZE.LINK_REGULAR_16}
                        color={COLORS_NEW.BLACK_600}
                        href="#"
                        onClick={cancelUpsell}
                    />
                </div>
            </div>
        )
    }

const mapStateToProps = (state: any) => ({
    loadingButtonState: state.loading.loadingButtonState,
    billingInformation: state.step.billingInformation,
    paymentError: state.form.paymentError,
})

export default connect(mapStateToProps)(GUpsellTemplateOneComponent)