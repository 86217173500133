import {createContext, useEffect, useState} from "react";
import {
    ICustomer,
    IFamilyMember,
    IInsuranceClaim,
    IInsuredDocument,
    IProductForRenewal,
    IRenewalClaim,
    IRenewalDocument,
    ISubscription,
    ISubscriptionStatus,
} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Models";
import familyMembersData from "./testData/familyMembers.json";
import {subscriptionService} from "../api/api";
import {
    InsuranceClaims,
    PaymentInformation,
    SubscriptionStatuses,
    SubscriptionSubmitData
} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import {usePayment} from "../hooks/usePayment";
import {useApplications} from "../hooks/useApplications";
import {useSelector} from "react-redux";
import {
    getSolutionNameByProductId,
    getSolutionNameToLowerCase
} from "@dash/gov-plus__front-end__form/src/util/SolutionNames";
import {SubscriptionText} from "@dash/gov-plus__front-end__form/src/shared/text/Text";
import {useHelperSubscription} from "../hooks/useHelperSubscription";
import ApplicationIdRepository from "@dash/gov-plus__front-end__form/src/persistence/session/ApplicationIdRepository";
import OrderCompleted from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/OrderCompleted/OrderCompleted";
import solutionsData from "./solutionsData.json"
import { useUser } from "../hooks/useUser";
import { useABTestingPayment } from "../hooks/useABTestingPayment";
import { useBuildDataForSubscription } from "../hooks/useBuildDataForSubscription";
import { usePaymentModel } from "../hooks/usePaymentModel";
import { PaymentModelTypes } from "../constants/PaymentModelTypes";
import { SUBSCRIPTION_PERIOD_YEARLY } from "@dash/gov-plus__front-end__form/src/shared/constans/payment";

interface ISubscriptionContext {
    insuredDocuments: IInsuredDocument[],
    renewedDocuments: IRenewalDocument[],
    familyMembers: IFamilyMember[],
    getFamilyMemberById: (id: string) => void,
    getFirstFamilyMember: () => void,
    selectedFamilyMember: IFamilyMember | undefined,
    subscriptionIsActive: boolean,
    subscriptionIsCancelled: boolean,
    subscriptionTrial: boolean,
    loading: boolean,
    subscribe: (paymentInformation: PaymentInformation, callback: (paymentMethod: any) => void) => void,
    unsubscribe: (cancelReason: string, callback: (success: boolean) => void) => void,
    insureDocument: (document: IInsuredDocument, callback: (data: IInsuredDocument | false) => void) => void,
    renewDocument: (document: IProductForRenewal, callback: (data: IProductForRenewal | false) => void) => void,
    checkStatus: () => void,
    makeClaim: (claim: IInsuranceClaim) => void,
    subscription: ISubscriptionStatus | undefined,
    makeRenewal: (claim: IRenewalClaim ) => void,
    subscriptionIsValid: () => boolean,
    paymentModel: PaymentModelTypes
}

// @ts-ignore
export const SubscriptionContext = createContext<ISubscriptionContext>()

// @ts-ignore
export const SubscriptionProvider = ({ children }) => {
    // @ts-ignore
    const {authToken} = useSelector(({auth}) => auth);
    const [subscription, setSubscription] = useState<ISubscription>()
    const [customer, setCustomer] = useState<ICustomer>()
    const [insuredDocuments, setInsuredDocuments] = useState<IInsuredDocument[]>([])
    const [renewedDocuments, setRenewedDocuments] = useState<IRenewalDocument[]>([])

    const [familyMembers, setFamilyMembers] = useState<IFamilyMember[]>(familyMembersData)
    const [selectedFamilyMember, setSelectedFamilyMember] = useState<IFamilyMember | undefined>()

    const [subscriptionIsActive, setSubscriptionIsActive] = useState(false)
    const [subscriptionIsCancelled, setSubscriptionIsCancelled] = useState(false)
    const [subscriptionTrial, setSubscriptionTrial] = useState(false)

    const [loading, setLoading] = useState(false)

    const {isUserLoggedIn}= useUser()
    const {getCustomer} = usePayment()
    const {checkSameApplication} = useApplications()
    const {
        triggerOrderCompletedEvent,
        triggerIdentifyEvent,
        triggerSubscriptionCancelledAnalyticEvent
    } = useHelperSubscription()

    const {getSubscriptionPaymentOptions} = useABTestingPayment()

    const {paymentModel} = usePaymentModel()
    
    const {  buildDataForSubscription } = useBuildDataForSubscription()

    const [delayDocumentsCheck, setDelayDocumentsCheck] = useState(false)

    const subscriptionIsValid = () => subscriptionIsActive || subscriptionIsCancelled || subscriptionTrial

    const getFamilyMemberById = (id: string) => {
        setSelectedFamilyMember(familyMembers.find(member => member.id === id))
    }
    //for testing
    const getFirstFamilyMember = () => {
        const familyMember = familyMembers[0]
        setSelectedFamilyMember(familyMember)
    }

    const assignImageToDocuments = (documents: IInsuredDocument[] | IRenewalDocument[]) => {
        // @ts-ignore
        let solutions = JSON.parse(localStorage.getItem("webFlowSolutionData"))
        if (!solutions || solutions.length === 0) {
            solutions = solutionsData
        }
        documents.forEach(document => {
            // @ts-ignore
            document.image = solutions[document.name]?.illustration?.url
        })
    }

    const assignSolutionNameToDocuments = (documents: IInsuredDocument[] | IRenewalDocument[]) => {
        documents.forEach(document => {
            document.solutionName = getSolutionNameToLowerCase(getSolutionNameByProductId(document.name))
        })
    }

    const checkStatus = () => {
        setLoading(true)
        subscriptionService.getUserSubscriptionStatus()
            .then(response => {
                const subscription : ISubscriptionStatus = response.data
                // set default plan data
                // @ts-ignore
                subscription.planData = {
                    planName: SubscriptionText.IndividualPlan,
                    planPrice: subscription.planPrice ? subscription.planPrice : "14.99",
                }
                setSubscription(subscription)
                swapSubscriptionStatus(subscription.status)
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const swapSubscriptionStatus = (status: SubscriptionStatuses) => {
        /**
         * Avoid changing states if not necessary
         */
        switch (status) {
            case SubscriptionStatuses.ACTIVE:
                if (subscriptionIsActive) {
                    setLoading(false)
                    return
                }
                else {
                    setSubscriptionIsActive(true)
                    setSubscriptionIsCancelled(false)
                    setSubscriptionTrial(false)
                }
                break
            case SubscriptionStatuses.CANCELLED:
                if (subscriptionIsCancelled) {
                    setLoading(false)
                    return
                }
                else {
                    setSubscriptionIsActive(false)
                    setSubscriptionIsCancelled(true)
                    setSubscriptionTrial(false)
                }
                break
            case SubscriptionStatuses.IN_TRIAL:
                if (subscriptionTrial) {
                    setLoading(false)
                    return
                }
                else {
                    setSubscriptionIsActive(false)
                    setSubscriptionIsCancelled(false)
                    setSubscriptionTrial(true)
                }
                break
            case SubscriptionStatuses.EXPIRED:
                if (subscriptionIsActive) {
                    setSubscriptionIsActive(false)
                }
                if (subscriptionIsCancelled) {
                    setSubscriptionIsCancelled(false)
                }
                setLoading(false)
                break
        }
    }


    const subscribe = (paymentInformation: PaymentInformation, callback: (paymentMethod: any) => void) => {
        const abTestingUpgradeOptions = getSubscriptionPaymentOptions()
        let parsedAbTesting = {
            subscriptionCouponEnabled: abTestingUpgradeOptions.subscriptionCouponEnabled,
            subscriptionCouponId: abTestingUpgradeOptions.subscriptionCouponIdPopup
        }
        if (paymentInformation.period !== SUBSCRIPTION_PERIOD_YEARLY) {
            parsedAbTesting = {
                subscriptionCouponEnabled: false,
                subscriptionCouponId: undefined
            }
        }
        setLoading(true)
        const paymentRequestToken = paymentInformation?.requestToken
        const dataForSubscription:SubscriptionSubmitData = buildDataForSubscription(paymentInformation, parsedAbTesting)
        paymentRequestToken?.requestCompletedCallback?.(paymentRequestToken?.event)
        subscriptionService.subscribeGOV(dataForSubscription)
            .then(async response => {
                setDelayDocumentsCheck(true)
                const customer = response.data.customerInformation?.customer
                const customerApplications = response.data.customerInformation?.applications
                const subscriptionList = response.data.event_order_completed?.properties?.subscription
                // Check status of the last element of the array is active
                const subscriptionStatus = subscriptionList?.slice(-1)?.pop()?.subscription_status === SubscriptionStatuses.ACTIVE
                await triggerIdentifyEvent(customer, !!customerApplications?.length || subscriptionStatus, true)
                await triggerOrderCompletedEvent(response, OrderCompleted.TYPE_ORDER_COMPLETED_NOT_PAYMENT_PAGE)
                callback?.(response.data)
                checkStatus()
            })
            .catch(error => {
                setLoading(false);
                if (error.response.data.error && error.response.data.error.type === "BadDataToPaymentException") {
                    callback?.({errorCard:true, msg: error.response.data.error.message});
                } else {
                    callback?.({errorCard:true, msg: SubscriptionText.SubscriptionFailed});
                }
            })
               
    }

    const unsubscribe = (cancelFeedback: string, callback?: (success: boolean) => void) => {
        setLoading(true)
        subscriptionService.unsubscribe({
            cancelFeedback
        }).then(response => {
            triggerSubscriptionCancelledAnalyticEvent(
                subscription?.id.toString(),
                subscription?.externalSubscriptionId,
                subscription?.planId,
                subscription?.paymentFrequency,
                subscription?.planData?.planPrice.toString(),
                SubscriptionStatuses.CANCELLED,
                subscription?.startDate ?? "",
                cancelFeedback,
                subscription?.cancellationChannel ?? "Self"
            );
            setLoading(false)
            checkStatus()
            callback?.(true)
        }).catch(error => {
            setLoading(false)
            console.log(error)
            callback?.(false)
        })
    }

    const getInsuredDocuments = () => {
        setLoading(true)
        subscriptionService.getInsuredDocumentsFromUser()
            .then(response => {
                assignImageToDocuments(response.data)
                assignSolutionNameToDocuments(response.data)
                setLoading(false)
                setInsuredDocuments(response.data)
                setDelayDocumentsCheck(false)
            })
            .catch(error => {
                setLoading(false)
                console.log(error)
            })
    }

    const getRenewedDocuments = () => {
        setLoading(true)
        subscriptionService.getRenewalDocumentsFromUser()
            .then(response => {
                assignImageToDocuments(response?.data)
                assignSolutionNameToDocuments(response?.data)
                setLoading(false)
                setRenewedDocuments(response?.data)
                setDelayDocumentsCheck(false)
            })
            .catch(error => {
                setLoading(false)
                console.log(error)
            })
    }

    const insureDocument = (document: IInsuredDocument, callback: (data: IInsuredDocument | false) => void) => {
        setLoading(true)
        const requestData = {
            ...document,
            customerId: customer && customer.customerId
        }
        subscriptionService.insureDocument(requestData)
            .then(response => {
                setInsuredDocuments(insuredDocuments.map(
                    document => document.name === response.data.name ? {...document, insured: true} : document
                ))
                setLoading(false)
                callback?.(response.data)
            })
            .catch(error => {
                if (error.respose.status === 409) {
                    getInsuredDocuments()
                }
                setLoading(false)
                console.log(error)
                callback?.(false)
            })
    }
    const renewDocument = (product: IProductForRenewal, callback: (data: IProductForRenewal | false) => void) => {
        setLoading(true)
        const requestData = {
            ...product,
            customerId: customer && customer.customerId
        }
        subscriptionService.renewalDocument(requestData)
            .then(response => {
                setRenewedDocuments(renewedDocuments.map(
                    document => document.name === response.data.name 
                    ? 
                   
                        {   ...document, 
                            activatedSince: response.data.activatedSince,
                            expirationDate: response.data.expirationDate,
                            automaticRenewalDate: response.data.automaticRenewalDate,
                            isEligible: response.data.isEligible} 
                    : document
                ))
                setLoading(false)
                callback?.(response.data)
            })
            .catch(error => {
                setLoading(false)
                console.log(error)
                callback?.(false)
            })
    }

    const getFormIdFromClaim = (claim: IInsuranceClaim) => {
        if (claim.name.includes("PAS")) {
            switch (claim.type) {
                case InsuranceClaims.Lost:
                    return "passport-lost"
                case InsuranceClaims.Stolen:
                    return "passport-stolen"
                case InsuranceClaims.Damaged:
                    return "passport-damaged"
            }
        } else if (claim.name.includes("SSN")) {
            return "social-security-card-replace"
        } else if (claim.name.includes("CER")) {
            return "vital-records-change"
        } else if (claim.name.includes("EIN")) {
            return "ein-retrievelost"
        }
    }
    const getFormIdFromRenewal = (claim: IRenewalClaim) => {
        if (claim.name.includes("PAS"))  return "passport-renewal"      
        if (claim.name.includes("TSA")) return "precheck-renewal"
        return "";
            
    }
    const makeClaim = (claim: IInsuranceClaim) => {
        setLoading(true)
        ApplicationIdRepository.getInstance().removeItem()
        const application = {
            ...claim,
            formId: getFormIdFromClaim(claim)
        }
        sessionStorage.setItem("insurance_claim", "1")
        checkSameApplication(application, true, ()=>{
            setLoading(false)
        })
    }
    const makeRenewal = (claim: IRenewalClaim) => {
        setLoading(true);
        ApplicationIdRepository.getInstance().removeItem()
        const application = {
            ...claim,
            formId: getFormIdFromRenewal(claim)
        }
        sessionStorage.setItem("created_through_document_renewal", "1")
        checkSameApplication(application, true, ()=>{
            setLoading(false)
        })
    }

    useEffect(checkStatus, [authToken])

    useEffect(() => {
        getCustomer((data) => {
            if (data?.customer) {
                setCustomer(data.customer)
            }
        })
    }, [authToken, subscriptionIsActive, subscriptionIsCancelled, subscriptionTrial])

    useEffect(() => {
            if (delayDocumentsCheck) {
                setLoading(true)
                setTimeout(getInsuredDocuments, 7000)
                setTimeout(getRenewedDocuments, 7000)
            } else {
                getInsuredDocuments()
                getRenewedDocuments()
            }
    }, [subscriptionIsActive, subscriptionIsCancelled, subscriptionTrial, authToken])

    const returnValue = {
        insuredDocuments,
        renewedDocuments,
        familyMembers,
        getFamilyMemberById,
        getFirstFamilyMember,
        selectedFamilyMember,
        subscriptionIsActive,
        subscriptionIsCancelled,
        subscriptionTrial,
        loading,
        subscribe,
        unsubscribe,
        insureDocument,
        renewDocument,
        checkStatus,
        makeClaim,
        subscription,
        makeRenewal,
        subscriptionIsValid,
        paymentModel
    }

    return (
        <SubscriptionContext.Provider value={returnValue}>
            {children}
        </SubscriptionContext.Provider>
    )
}