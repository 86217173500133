import { call, put, select, takeEvery } from "redux-saga/effects"
import {
  ActionTypes,
  setBlobUploadDocuments,
  setCurrentFileName,
  setCurrentStatesUploadDocuments,
  setDocumentsNameByFieldName, setDynamicUploadPhotoErrorMessage,
  setLinkPhoto,
  setLinkOriginalPhoto,
  setStatusUploadPhoto,
} from "./upload.actions"
import { LoadingStateUploadDocument, LoadingStateUploadPhoto } from "../../../shared/constans/user-from-view-mode.enum"
import ServiceUpload from "../../../services/requests/uploadServise"
import {
  removeDocument,
  setAdditionalDocuments,
  setDocuments,
  setStatusPhotoUpload,
  setStatusSignaturePage,
  setStatusUploadAdditionalDocuments,
  setStatusUploadDocuments,
} from "../additionalForm/additional.actions"
import Service from "../../../services/form-service"
import { getAdditionalArraySteps } from "../step/step.actions"
import { checkLaunchFunctionByPageName, setStatusesCommonProducts } from "../../../services/statusesService/setStatuses"
import { arrayPagesName } from "../../../services/statusesService/dateByStatuses"
import { getClientTokenForResponse, getRoot, removeCookie, setCookie } from "../step/service.step.saga"
import { setLoadingButtonState } from "../loading/loading.action"
import {putBillingInformation} from "../billing/BillingInformation";
import FormIdRepository from "../../../persistence/session/FormIdRepository";
import ApplicationIdRepository from "../../../persistence/session/ApplicationIdRepository";
import {UploadSagaHelper} from "./UploadSagaHelper";
import {AdditionalSagaHelper} from "../additionalForm/AdditionalSagaHelper";
import { setStatusOnSubmitObject } from "../form/form.actions"
import { env } from "../../../env"

const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time))

interface PayloadInterface {
  photo: File;
}

interface ActionInterface {
  payload: PayloadInterface;
}
  
interface SuccessInterface {
  status?: string;
  result?: string;
}

export function* setStatusSignaturePageSaga({ status, result }: SuccessInterface) {
  if (status) {
    yield put(setStatusSignaturePage(status))
  }
  if (result && result !== "uploaded") {
    yield put(setStatusUploadAdditionalDocuments("process"))
    sessionStorage.setItem("additionalDocuments", "process")
  }
}

interface ExtensionsCorrectUploadFileInterface {
  type: string;
}

export type ArrayExtensionsCorrectUploadFileType = ExtensionsCorrectUploadFileInterface[]

export const TYPES_FILES = {
  JPEG: "jpeg",
  PNG: "png",
  JPG: "jpg",
  PDF: "pdf",
  HEIC: "heic",
}

export const arrayExtensionsCorrectUploadFile = [
  { type: TYPES_FILES.JPEG },
  { type: TYPES_FILES.PNG },
  { type: TYPES_FILES.JPG },
  { type: TYPES_FILES.PDF },
  { type: TYPES_FILES.HEIC },
]

export const MAX_SIZE = env.REACT_APP_UPLOAD_MAX_SIZE ?? 10000000;

export const checkCorrectExtensionUploadFile = (
  fileExtension: string,
  arrayExtensionsCorrectUploadFile: ArrayExtensionsCorrectUploadFileType
) => arrayExtensionsCorrectUploadFile.find(({ type }) => type === fileExtension.toLowerCase())

function* sentPhotoToBackSaga({ payload: photo }: ActionInterface | any): any {
  try {
    const appId = ApplicationIdRepository.getInstance().getValue()
    const photoWithAppId = yield call(UploadSagaHelper.addApplicationIdToPictureName, appId, photo)
    yield put(setDynamicUploadPhotoErrorMessage(""))

    const { name = "" } = yield photoWithAppId
    yield put(setCurrentFileName(name))
    const extensionCurrentFile = yield name.split(".")[name.split(".").length - 1]

    if (
      !(yield call(
        checkCorrectExtensionUploadFile,
        extensionCurrentFile,
        arrayExtensionsCorrectUploadFile.filter(({ type }) => type !== TYPES_FILES.PDF)
      ))
    ) {
      throw new Error(LoadingStateUploadPhoto.FormatError)
    }

    if (photo.size > MAX_SIZE) {
      throw new Error(LoadingStateUploadPhoto.MaxSizeError)
    }

    yield put(setStatusUploadPhoto(LoadingStateUploadPhoto.Loading))

    const { file, original_file, code, messsage } = yield ServiceUpload.uploadPhoto(photoWithAppId)

    if(code === "OK") {
      yield put(setLinkPhoto(file))
      yield put(setLinkOriginalPhoto(original_file))
      yield put(setStatusUploadPhoto(LoadingStateUploadPhoto.Loaded))
    } else {
      yield put(setStatusUploadPhoto(LoadingStateUploadPhoto.BackendError))
      yield put(setDynamicUploadPhotoErrorMessage(messsage))
    }
  } catch (e: any) {
    if (e.message === LoadingStateUploadPhoto.FormatError) {
      yield put(setStatusUploadPhoto(LoadingStateUploadPhoto.FormatError))
    }
    if (e.message === LoadingStateUploadPhoto.MaxSizeError) {
      yield put(setStatusUploadPhoto(LoadingStateUploadPhoto.MaxSizeError))
    }
  }
}

export const DOCUMENT_TYPES = {
  UPLOADING_DOCUMENTS: "uploadingFile",
  UPLOAD_ADDITIONAL_DOCUMENTS: "uploadingAdditionalFile",
}

export const DOCUMENT_NAMES = {
  UPLOADING_DOCUMENTS: "documents",
  UPLOAD_ADDITIONAL_DOCUMENTS: "additionalDocuments",
}

const getDocumentByType = (typeDocument: string) =>
  [
    { type: DOCUMENT_TYPES.UPLOADING_DOCUMENTS, action: setStatusUploadDocuments },
    { type: DOCUMENT_TYPES.UPLOAD_ADDITIONAL_DOCUMENTS, action: setStatusUploadAdditionalDocuments },
  ].find(({ type }) => type === typeDocument)

function* sentDocumentToBackSaga({ payload: document }: ActionInterface | any): any {
  const { file, isFinalSubmit, name_field, typeDocument } = yield document
  const { name: fileName, size } = file
  const { client_token } = yield getClientTokenForResponse()
  const submitDocument = yield { ...document, client_token, form_id: FormIdRepository.getInstance().getValue() }
  try {
    yield put(setDocumentsNameByFieldName({ [name_field]: fileName }))
    const extensionCurrentFile = yield fileName.split(".")[fileName.split(".").length - 1]

    if (!(yield call(checkCorrectExtensionUploadFile, extensionCurrentFile, arrayExtensionsCorrectUploadFile))) {
      yield put(setCurrentStatesUploadDocuments({ [name_field]: LoadingStateUploadDocument.FormatError }))
      throw new Error(LoadingStateUploadDocument.FormatError)
    }

    if (size > MAX_SIZE) {
      throw new Error(LoadingStateUploadDocument.MaxSizeError)
    }

    yield put(setCurrentStatesUploadDocuments({ [name_field]: LoadingStateUploadDocument.Loading }))

    if (typeDocument === DOCUMENT_TYPES.UPLOADING_DOCUMENTS) {
      yield put(setStatusUploadDocuments(LoadingStateUploadDocument.PreLoad))
    } else {
      yield put(setStatusUploadDocuments(LoadingStateUploadDocument.Completed))
      yield put(setStatusUploadAdditionalDocuments(LoadingStateUploadDocument.Process))
    }

    //temporary old code

    const response = yield Service.updateDocument(submitDocument)
    if (response.error) {
      throw new Error(response?.error?.data?.message ?? response?.error?.message)
    }
    yield put(setLoadingButtonState("loaded"))

    if (typeDocument === DOCUMENT_TYPES.UPLOADING_DOCUMENTS) {
      yield put(setDocuments(submitDocument))
      if (isFinalSubmit) {
        yield put(setStatusUploadDocuments(LoadingStateUploadDocument.Completed))
        yield put(setStatusUploadAdditionalDocuments(LoadingStateUploadDocument.Process))
      }
    } else {
      yield put(setAdditionalDocuments(submitDocument))
      if (isFinalSubmit) {
        yield put(setStatusUploadAdditionalDocuments(LoadingStateUploadDocument.Completed))
      }
    }

    const store = yield select((s: any) => s)
    const { additionalSteps } = yield store.step
    const { success } = response
    if (success) {
      if (success.billingInformation) {
        yield putBillingInformation(success.billingInformation)
      }
      yield put(setCurrentStatesUploadDocuments({ [name_field]: LoadingStateUploadDocument.Loaded }))
      if (extensionCurrentFile === TYPES_FILES.PDF) {
        yield put(setBlobUploadDocuments({ [name_field]: file }))
      } else {
        yield put(setBlobUploadDocuments({ [name_field]: URL.createObjectURL(file) }))
      }
    }
    if (yield call(checkLaunchFunctionByPageName, arrayPagesName, success?.page)) {
      yield call(setStatusSignaturePageSaga, success)
      yield call(setStatusesCommonProducts, additionalSteps, success?.status, success?.page)
    }
  } catch (e: any) {
    yield put(setLoadingButtonState("loaded"))
    yield put(setCurrentStatesUploadDocuments({ [name_field]: LoadingStateUploadDocument.PreLoad }))
    //@ts-ignore
    if (
      e.message === LoadingStateUploadDocument.FormatError ||
      e.message === LoadingStateUploadDocument.MaxSizeError ||
      e.message === LoadingStateUploadDocument.OnlyOnePage ||
      e.message === LoadingStateUploadDocument.UploadDocument
    ) {
      yield put(setCurrentStatesUploadDocuments({ [name_field]: e.message }))
      if (typeDocument === DOCUMENT_TYPES.UPLOADING_DOCUMENTS) {
        yield put(setDocuments(submitDocument))
        yield put(setStatusUploadDocuments(LoadingStateUploadDocument.FormatError))
      } else {
        yield put(setAdditionalDocuments(submitDocument))
        yield put(setStatusUploadAdditionalDocuments(LoadingStateUploadDocument.FormatError))
      }
    } else if (typeDocument === DOCUMENT_TYPES.UPLOADING_DOCUMENTS) {
      yield put(setCurrentStatesUploadDocuments({ [name_field]: e.message }))
      yield put(setStatusUploadDocuments(LoadingStateUploadDocument.Process))
      yield put(setStatusUploadAdditionalDocuments(LoadingStateUploadDocument.Empty))
    } else {
      yield put(setStatusUploadAdditionalDocuments(LoadingStateUploadDocument.Process))
    }
  }
}

function* removePhotoToBackSaga({ payload: photo }: ActionInterface | any): any {
  try {
    yield delay(1000)
    yield put(setLinkPhoto(""))
    yield put(setStatusUploadPhoto(LoadingStateUploadPhoto.PreLoad))
  } catch (e) {
    yield put(setStatusUploadPhoto(LoadingStateUploadPhoto.Error))
  }
}

function* removeDocumentToBackSaga({ payload: document }: ActionInterface | any): any {
  const { name_field, typeDocument } = yield document
  try {
    yield put(removeDocument(document))
    const actionDocumentByType = yield getDocumentByType(typeDocument)?.action
    if (typeDocument === DOCUMENT_TYPES.UPLOADING_DOCUMENTS) {
      yield put(actionDocumentByType(LoadingStateUploadDocument.PreLoad))
    }
    yield put(setCurrentStatesUploadDocuments({ [name_field]: LoadingStateUploadDocument.PreLoad }))
    yield put(setBlobUploadDocuments({ [name_field]: "" }))
  } catch (e) {
    // console.log(e)
  }
}

function* savePhotoToBackSaga({ payload: submitObj }: ActionInterface | any): any {
  try {
    const { client_token } = yield getClientTokenForResponse()
    const newSubmitObj = { ...submitObj, client_token, form_id: FormIdRepository.getInstance().getValue() }
    window.scrollTo(0, 400)
    yield put(setStatusPhotoUpload(LoadingStateUploadPhoto.SavingImages))
    const response = yield Service.submitAdditionalForm(newSubmitObj)
    yield put(setStatusPhotoUpload(LoadingStateUploadPhoto.Completed))
    yield put(setStatusUploadDocuments(LoadingStateUploadDocument.Process))
    const { success, error } = response
    if(success) {
      const store = yield select((s: any) => s)
      const { additionalSteps } = yield store.step
      if (yield call(checkLaunchFunctionByPageName, arrayPagesName, success?.page)) {
        yield call(setStatusSignaturePageSaga, success)
        yield call(setStatusesCommonProducts, additionalSteps, success?.status, success?.page)
      }
      if (success.status) {
        yield put(setStatusOnSubmitObject(success.status))
      }
      if (success.billingInformation) {
        yield putBillingInformation(success.billingInformation)
      }
      if (success?.page) {
        yield put(getAdditionalArraySteps(success?.page))
      }
      if (success?.dashLink) {
        /* Event Processing Completed */
        /* Event Check Status */
        yield delay(3000)
        yield (window.location.href = `${getRoot()}${success?.dashLink}`)
      }
      if (success?.client_token) {
        yield call(AdditionalSagaHelper.removeClientToken);
        yield call(AdditionalSagaHelper.setClientToken, success?.client_token);
        yield call(AdditionalSagaHelper.setAuthBearerToken, success?.client_token);
      }

      yield put(setLinkPhoto(""))
      yield put(setStatusUploadPhoto(LoadingStateUploadPhoto.PreLoad))
    }
    if(error) {
      throw new Error(error.message)
    }
  } catch (e) {
    let msg = `${e}`
    window.scrollTo(0, 300)
    yield put(setStatusPhotoUpload("process"))
    yield put(setStatusUploadDocuments(""))
    yield put(setStatusUploadPhoto(LoadingStateUploadPhoto.BackendError))
    yield put(setDynamicUploadPhotoErrorMessage(msg))
  }
}

export default function* uploadSaga() {
  yield takeEvery(ActionTypes.SEND_PHOTO_TO_BACK, sentPhotoToBackSaga)
  yield takeEvery(ActionTypes.SEND_DOCUMENT_TO_BACK, sentDocumentToBackSaga)
  yield takeEvery(ActionTypes.REMOVE_PHOTO_TO_BACK, removePhotoToBackSaga)
  yield takeEvery(ActionTypes.REMOVE_DOCUMENT_TO_BACK, removeDocumentToBackSaga)
  yield takeEvery(ActionTypes.SAVE_PHOTO_TO_BACK, savePhotoToBackSaga)
}
