import React from "react";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS} from "../../types/Colors";
import {TotalPriceProps} from "./TotalPrice.props";

import './TotalPrice.scss'

const GTotalPrice: React.FC<TotalPriceProps> = ({selectedPrice}) => {
    return (
        <div className={'GTotalPrice'}>
            <GText
                text={`Total: $${selectedPrice}`}
                size={SIZE.PARAGRAPH_BOLD_20}
                color={COLORS.SLATE_GREY_600}
            />
        </div>
    );
}

export default GTotalPrice;