/* eslint-disable array-callback-return */
import { connect } from "react-redux"
import React from "react"
import checkImg from "../../../../../../assets/image/check.svg"

type AddressBlockComponentProps = {
  item: {} | any,
  currentResponse: {} | any,
}

// eslint-disable-next-line max-len
const AddressBlockComponent: React.FunctionComponent<AddressBlockComponentProps> = ({ item, currentResponse }) => {
  return (
    <>
      {item && (
        <div className="address_change__status__table">
          <h3 className="address_change__status__table-header">{item[0].header}</h3>
          <div className="address_change__status__table-block">
            <div className="address_change__status__table-block_name">
              <p className="address_change__status_name-p1">Name</p>
              <p className="address_change__status_name-p2">{item[0].name}</p>
            </div>
            <div className="address_change__status-value">
              <p className="address_change__status-p1">Status</p>
              <p className="address_change__status-p2">
                {item[0].imgCheckStatus ? <img src={checkImg} alt="check.svg" /> : null}
                {item[0].status}
              </p>
            </div>
          </div>
          <h3 className="address_change__status__table-header">{item[1].header}</h3>

          <div className="address_change__status__table-block">
            <div className="address_change__status__table-block_name">
              <p className="address_change__status_name-p1">Name</p>
              {currentResponse &&
                currentResponse
                  .slice(1)
                  .map((value: any) => <p className="address_change__status_name-p2">{value.company_name}</p>)}
            </div>
            <div className="address_change__status-value">
              <p className="address_change__status-p1">Status</p>
              {currentResponse &&
                currentResponse.slice(1).map((value: any) => (
                  <p className="address_change__status-p2">
                    {item[1].imgCheckStatus ? <img src={checkImg} alt="check.svg" /> : null}
                    {value.status}
                  </p>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  currentResponse: state.step.currentResponse,
})

export default connect(mapStateToProps)(AddressBlockComponent)
