export const customGrowlHelper = {
  svgIconType: (authStatus) => {
    return authStatus === 400 || authStatus === 500
      ? "error_growl_message"
      : authStatus === 403 || authStatus === 409
        ? "warning_growl_message"
        : authStatus === 200 || authStatus === 201
          ? "success_growl_message"
          : "info_growl_message"
  },
  errorClassName: (authStatus) => {
    return authStatus === 400 || authStatus === 500
      ? "errorMessageInfo"
      : authStatus === 403 || authStatus === 409
        ? "warningMessageInfo"
        : authStatus === 200 || authStatus === 201
          ? "succsesMessageInfo"
          : "info_growl_message"
  }
};
