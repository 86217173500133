import React from "react";
import './index.less'
import {IconSvg} from "../../../components/svgImages/SvgImages";
import SettingsButton from "../../../components/SettingsButton";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import ProductTicket from "../../../components/ProductTicket/ProductTicket";
import GText from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Text/Text";
import {SIZE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import {COLORS, COLORS_NEW} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";
import {SolutionNames} from "@dash/gov-plus__front-end__form/src/util/SolutionNames";
import GApplicationCard
    , {
    ApplicationCardContentAlign
} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/ApplicationCard/ApplicationCard";
import GButton from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Button/GButton";
import { env } from "../../../env";

const SimpleStatusesForProducts = ({
                                     iconStatus,
                                     userName,
                                     headerText,
                                     description,
                                     productImage,
                                     productName,
                                     buttonText,
                                     productId,
                                     productCompletion
                                   }) => {
  const containerClassName = "simpleStatuses-container"
  const {allProducts, webFlowData} = useSelector(({allProducts}) => allProducts);
  const history = useHistory();
  const application = allProducts[productId]
  const dateOfApply = application?.date_of_apply ?? application?.dateofapply
  const customerName = application?.first_name + " " + application?.last_name
    let service = ""
    if(application){
        service = webFlowData[application.form_id].text
    }

  const redirectToForm = () => {
    const webFlowServiceData = JSON.parse(localStorage.getItem("webFlowServicesData"))

    if (iconStatus === "download") {
      window.open(allProducts[productId]?.add.download_link, "_blank")
    } else if (allProducts[productId]?.status === "signature" && allProducts[productId]?.product_name === "PAS") {
      history.push({
        pathname: `/forms/?formId=${allProducts[productId]?.form_id}&&applicationId=${allProducts[productId]?.application_id}&&fromDashboardSignature=true`,
      })
    } else if (productName === SolutionNames.CER && iconStatus === "sent_to_notarize") {
      window.open(allProducts[productId]?.add.notarization_link, "_blank")
    } else if (buttonText === "Contact Us") {
      window.open(`${env.REACT_APP_GOV_HOME_PAGE}/contact-us`, "_blank")
    } else {
      history.push({
        pathname: `/forms/?formId=${allProducts[productId]?.form_id}&&applicationId=${allProducts[productId]?.application_id}`,
      })
    }

    localStorage.setItem("forBreadcrumbs", JSON.stringify({
      headerText: `${webFlowServiceData[allProducts[productId]?.form_id]?.title}`,
      value: webFlowServiceData[allProducts[productId]?.form_id]?.text
    }));
  }

  return (
    <div className={containerClassName}>
      <div className={`${containerClassName}-header`}>
          <GText text={headerText} size={SIZE.HEADING_XL} color={COLORS_NEW.BLACK_1000} />
          <GText text={description} size={SIZE.PARAGRAPH_BOLD_18} color={COLORS_NEW.BLACK_500} />
      </div>

      <div className={`${containerClassName}-productBox`}>
        <GApplicationCard
            dateOfApply={dateOfApply}
            solution={productName}
            service={service}
            productImage={productImage}
            customerName={customerName}
            contentAlign={ApplicationCardContentAlign.Center}
            hasCheckStatus={false}
            completedPercentage={productCompletion || 50}
        />
      </div>

      {buttonText && <div className={`${containerClassName}-buttonContainer`}>
        {
          buttonText
          &&
            <GButton textSize={SIZE.PARAGRAPH_BOLD_16} text={buttonText} click={redirectToForm} size={"middle"} showIcon={false} />
        }
      </div>}

    </div>
  );
};

export default SimpleStatusesForProducts;
