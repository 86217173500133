import React, { FC } from "react"
import { saveStatusUploadPhoto } from "../../../../Redux/store/upload/upload.actions"
import { useDispatch, useSelector } from "react-redux"
import { BasicBlockContainerProps } from "../UploadPhotoService/interfaces/interfaces"
import ButtonComponent from "../ButtonComponent/ButtonComponent"
import { RootState } from "../../../../App"
import { formSelector, uploadSelector } from "../UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"

export interface saveStatusUploadPhotoInterface {
  linkPhoto?: string;
  linkOriginalPhoto?: string
  client_token: string;
  form_id: string;
}

const UploadPhotoSaveButton: FC<BasicBlockContainerProps> = ({ item: { className, text } }) => {
  const dispatch = useDispatch()

  const { appId: client_token, formId: form_id } = useSelector<RootState, { [key: string]: any }>(formSelector)
  const linkPhoto = useSelector<RootState, { linkPhoto: string }>(uploadSelector).linkPhoto
  const linkOriginalPhoto = useSelector<RootState, { linkOriginalPhoto: string }>(uploadSelector).linkOriginalPhoto

  const handlerOnClickSave = () => {
    dispatch(saveStatusUploadPhoto({ linkPhoto, linkOriginalPhoto, client_token, form_id }))
  }

  return <ButtonComponent className={className} text={text} handlerOnClick={handlerOnClickSave} />
}

export default UploadPhotoSaveButton
