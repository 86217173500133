import React, {useEffect} from "react"
import { connect, useDispatch } from "react-redux"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import plusIcon from "../../../../assets/image/icon-pluss.svg"
import PaymentMethodInformation from "./PaymentMethodInformation"
import { Form, Radio } from "antd"
import { setPaymentMethod, showAddPaymentButton, showStripeForm } from "../../../../Redux/store/form/form.actions"
import "./BlockBillingInformation.scss"
import {PaymentMethod} from "../../../atomicDesign/types/types";
import {NEW_PAYMENT_METHOD_VALUE} from "../../../../util/PaymentMethodsHelper";

export interface Charge {
  id: string;
  name: string;
  amount: number;
  salePrice?: number;
  saleLabel?: string;
  price?: number;
  description?: string;
}

export interface BillingInformation {
  payment_secret: string;
  payment_methods: PaymentMethod[];
  default_payment_methods: string;
  need_subscription: boolean;
  charges: Charge[];
  payment_terms: string;
  customer_id: string;
}

type BlockBillingInformationProps = {
  blockObject: { headerFields: string, fields: [FieldInterface] } | any,
  billingInformation: BillingInformation,
  setStripeElements?: any,
  stripeLoaded: boolean | null,
  paymentMethod: string,
  showStripe: boolean,
  showPaymentMethodButton: boolean,
  statusStripe: string
}

// eslint-disable-next-line max-len
const BlockBillingInformation: React.FunctionComponent<BlockBillingInformationProps> = ({
  blockObject,
  billingInformation,
  setStripeElements,
  stripeLoaded,
  paymentMethod,
  showStripe,
  showPaymentMethodButton,
  statusStripe
}) => {
  const dispatch = useDispatch()
  const isPayment = blockObject.name === "billing_information"
  const loadingStripe = stripeLoaded ? stripeLoaded : false
  const stripeFormVisibleClass =
    (showStripe === undefined || !showStripe) && billingInformation?.payment_methods?.length > 0 ? "d-none" : "d-block"

  const handlerChangeOptionForm = (value: string) => {
    dispatch(setPaymentMethod(value))
    const showStripeFormBlock = value === NEW_PAYMENT_METHOD_VALUE
    dispatch(showStripeForm(showStripeFormBlock))
    dispatch(showAddPaymentButton(!showStripeFormBlock))
  }



  useEffect(() => {
    document.getElementById(paymentMethod)?.click()
  }, [paymentMethod])

  let gIndex = 0
  return (
    <>
      {(billingInformation?.payment_methods?.length > 0 || showPaymentMethodButton) && (
          <Form.Item name="payment_method"  initialValue={paymentMethod}>
            <Radio.Group
              onChange={(e: any) => {
                handlerChangeOptionForm(e.target.value)
              }}
              className={"payment-method-information"}
            >
              {billingInformation.payment_methods.length > 0 &&
                billingInformation.payment_methods.map((item: PaymentMethod, index: number) => {
                  gIndex = index
                  return (
                    <li key={gIndex} className={`payment-method-information__item ${paymentMethod === item.id ? 'selected' : ''}`}>
                      <PaymentMethodInformation paymentMethod={item} isDefault={item.id === billingInformation.default_payment_methods} />
                    </li>
                  )
                })}
              {showPaymentMethodButton && (
                <li key={(gIndex += 1)} className={`payment-method-information__item payment-method-information__item--btn`}>
                  <Radio className={"radio-new-payment-method"} value={NEW_PAYMENT_METHOD_VALUE}>
                    <img
                        className={"payment-method-information__add-payment-method-btn__icon-plus"}
                        src={plusIcon}
                        alt={`Add`}
                    />
                    {`Add new payment method`}
                  </Radio>
                </li>
              )}
            </Radio.Group>
          </Form.Item>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  billingInformation: state.step.billingInformation,
  stripeLoaded: state.form.stripeLoaded,
  paymentMethod: state.form.paymentMethod,
  showStripe: state.form.showStripe,
  showPaymentMethodButton: state.form.showPaymentMethodButton,
  statusStripe: state.form.statusStripe
})

export default connect(mapStateToProps)(BlockBillingInformation)
