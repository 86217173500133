import React, {useEffect, useState} from "react"
import {connect, useSelector} from "react-redux"
import {
    setStatusGovernmentFeeOpen,
    setStatusPhotoUpload,
    setStatusQuestionnaireFront,
    setStatusSignature,
    setStatusSignatureOpen,
    setStatusTSADetailsToOpen,
} from "../../../../Redux/store/additionalForm/additional.actions"
import SignatureComponent from "../SignatureComponent/SignatureComponent"
import yesIcon from "../../../../assets/image/bigYes.svg"
import PassportDetailsComponent from "../PassportDetailsComponent/PassportDetailsComponent"
import QuestionnaireBlockProcess from "../QuestionaryPassport/QuestionaryPassport"
import {formCompete} from "./renderFunctions/renderFunctions"
import UploadComponent from "../UploadComponent/UploadComponent"
import {RootState} from "../../../../App"
import {additionalSelector} from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import GovernmentFeesFormFields
    from "../../AdditionalPageComponent/AdditionalPageByType/GovermentFees/GovernmentFeesFormFields"
import {getScrollElementById} from "../../FormBlock/FormBlock"
import {delayTimer} from "../../../../Pages/FormPage/FormPage"
import GAdditionalStep, {StepVisualStatus} from "../../../atomicDesign/templates/AdditionalStep/AdditionalStep"
import GUploadPassportPhotoStep from "../../../atomicDesign/organisms/UploadPassportPhotoStep/GUploadPassportPhotoStep"
import {getPropertiesForEvents} from "../../../../services/gtm/backEndEvent";
import {ServicesIds} from "../../../../util/ServicesIds";
import GAdditionalStepUpsell from "../../../atomicDesign/templates/AdditionalStep/AdditionalStepUpsell";
import {PaymentRequestTokenEvent} from "@stripe/stripe-js";
import GPassportStatusTracking
    from "../../../atomicDesign/templates/AdditionalStep/StepStatusTracking/Passport/PassportStatusTracking";
import {SolutionIds, SolutionNames, SolutionNamesForAdditionalSteps} from "../../../../util/SolutionNames";
import {ProcessingStatuses, replaceWords} from "../../../../util/ProcessingTrackingHelper";
import TrackingCardStepComponent from "../TrackingCardStepComponent/TrackingCardStepComponent"
import {getIdNameFromStatuses} from "../../../../util/BlockStep";
import ProcessingByGovernmentComponent from "../ProcessingByGovernmentComponent/ProcessingByGovernmentComponent"
import { BlockStepTypes } from "../../../../Constants/BlockStepTypes"

type BlocksStepsProps = {
    blocksStep: any,
    index: number,
    showStatus: string,
    statusSignature: string,
    statusQuestionnaire: string,
    statusQuestionnaireFront: string,
    statusDetails: string,
    statusDetailsToOpen: string,
    statusDocuments: string,
    statusPhotoUpload: string,
    statusAdditionalDocuments: string,
    currentResponse: any,
    loadingButtonState: string,
    appId: string,
    formId: string,
    customer: {} | any,
    statusSignatureBlockOpen: string,
    statusGovernmentFeeOpen: string,
    setStripeElements: any,
    dispatch: any,
    upsellProcessingInformation: any,
    statusUpsellStep: string,
    submitObject: {} | any,
    onGatewayPaymentStartedWithToken?: (event: PaymentRequestTokenEvent, userCoupon: any) => Promise<void>,
    application_id: string
}

const BlocksSteps: React.FunctionComponent<BlocksStepsProps> = ({
                                                                    blocksStep,
                                                                    index,
                                                                    showStatus,
                                                                    statusSignature,
                                                                    statusDetails,
                                                                    statusDetailsToOpen,
                                                                    statusQuestionnaire,
                                                                    statusQuestionnaireFront,
                                                                    statusDocuments,
                                                                    statusPhotoUpload,
                                                                    statusAdditionalDocuments,
                                                                    currentResponse,
                                                                    loadingButtonState,
                                                                    appId,
                                                                    formId,
                                                                    customer,
                                                                    statusSignatureBlockOpen,
                                                                    statusGovernmentFeeOpen,
                                                                    setStripeElements,
                                                                    dispatch,
                                                                    upsellProcessingInformation,
                                                                    statusUpsellStep,
                                                                    submitObject,
                                                                    onGatewayPaymentStartedWithToken,
                                                                    application_id
                                                                }) => {
    const {form_id} = getPropertiesForEvents()
    const [passportPhotoState, setPassportPhotoState] = useState("")
    const handlerSignature = (status: string) => {
        dispatch(setStatusSignature(status))
        dispatch(setStatusSignatureOpen(status))
    }

    const handlerGovernmentFeeOpen = (status: string) => {
        dispatch(setStatusGovernmentFeeOpen(status))
    }

    const handlerPreQuestionnaireClick = () => {
        dispatch(setStatusQuestionnaireFront("process"))
        sessionStorage.setItem("statusQuestionnaireFront", "process")
    }

    const handlerDetailsToOpen = (status: string) => {
        dispatch(setStatusTSADetailsToOpen(status))
    }

    const handlerUploadPhotoBlock = (status: string) => {
        setPassportPhotoState(status)
        dispatch(setStatusPhotoUpload(status))
    }

    const {statusSignaturePage} = useSelector<RootState, { statusSignaturePage: string } | undefined>(
        additionalSelector
    ) ?? {statusSignaturePage: ""}

    useEffect(() => {
        delayTimer(300).then(() => getScrollElementById({id: `${statusSignaturePage}_scrollById`}))
    }, [statusSignaturePage])

    const uniqSession = submitObject["unique-section-id"]

    let idNameFromStatuses = getIdNameFromStatuses(blocksStep.status)

    return (
        <div className={`steps-signature-container`} id={`${idNameFromStatuses}${index !== 1 ? "_scrollById" : ""}`}>
            {blocksStep.type === BlockStepTypes.CONTENT && formCompete(yesIcon, blocksStep.content.complete)}

            {blocksStep.type === BlockStepTypes.UPSELL && upsellProcessingInformation && upsellProcessingInformation[blocksStep.status] && (
                <GAdditionalStepUpsell
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    formId={formId}
                    uniqSession={uniqSession}
                    blockStatus={blocksStep.status}
                />
            )}

            {blocksStep.type === BlockStepTypes.QUESTIONNAIRE_PASSPORT && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusQuestionnaireFront}
                    index={index.toString()}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    ctaForm={handlerPreQuestionnaireClick}
                >
                    <QuestionnaireBlockProcess formId={formId} appId={appId} blocksStep={blocksStep}/>
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.ADDITIONAL_INFORMATION_STEP && (
                <GAdditionalStep
                    title={blocksStep.content?.title}
                    titleDone={blocksStep.content?.titleDone}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusDetailsToOpen}
                    index={index.toString()}
                    information={blocksStep.content?.information}
                    ctaText={blocksStep.content?.button}
                    ctaForm={() => {
                        handlerDetailsToOpen(StepVisualStatus.STATUS_PROCESS)
                    }}
                >
                    <PassportDetailsComponent blocksStep={blocksStep} statusSignaturePage={statusSignaturePage}/>
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.SIGNATURE && form_id === ServicesIds.PassportRenewal && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusSignatureBlockOpen}
                    ctaForm={() => handlerSignature(StepVisualStatus.STATUS_PROCESS)}
                >
                    <SignatureComponent nestBlock={blocksStep.content.nestBlock}/>
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.UPLOAD_PHOTO && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={passportPhotoState}
                    ctaForm={() => handlerUploadPhotoBlock(StepVisualStatus.STATUS_PROCESS)}
                >
                    <GUploadPassportPhotoStep/>
                </GAdditionalStep>
            )}

            {(blocksStep.type === BlockStepTypes.UPLOADING_FILE||
                blocksStep.type === BlockStepTypes.UPLOADING_ADDITIONAL_FILE ||
                blocksStep.status === "identity_document" ||
                blocksStep.status === "citizenship_document") && (
                <UploadComponent
                    blocksStep={blocksStep}
                    index={index}
                    showStatus={showStatus}
                    loadingButtonState={loadingButtonState}
                    statusDocuments={statusDocuments}
                    relationship={""}
                    proofOnFlag={true}
                />
            )}

            {blocksStep.type === BlockStepTypes.GOVERNMENT_FEES && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusGovernmentFeeOpen}
                    index={index.toString()}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    ctaForm={() => handlerGovernmentFeeOpen(StepVisualStatus.STATUS_PROCESS)}
                >
                    <GovernmentFeesFormFields onGatewayPaymentStartedWithToken={onGatewayPaymentStartedWithToken}
                                              blocksStep={blocksStep} setStripeElements={setStripeElements}
                                              index={index}/>
                </GAdditionalStep>
            )}

            {(blocksStep.type === ProcessingStatuses.processing
                || blocksStep.type === ProcessingStatuses.processing_validation
                || blocksStep.type === ProcessingStatuses.pending_shipment
                || blocksStep.type === ProcessingStatuses.processing_follow_instructions
                || blocksStep.type === ProcessingStatuses.pending_receiving_document_confirmation) && (
                <GAdditionalStep
                    index={index.toString()}
                    title={replaceWords(blocksStep?.content?.title, {solution: SolutionNamesForAdditionalSteps.PAS})}
                    titleDone={replaceWords(blocksStep?.content?.titleDone, {solution: SolutionNamesForAdditionalSteps.PAS})}
                    stepShowStatus={showStatus}
                    information={replaceWords(blocksStep?.content?.information, {solution: SolutionNamesForAdditionalSteps.PAS})}
                >
                    <GPassportStatusTracking
                        nameProduct={SolutionIds.PAS}
                        title={SolutionNames.PAS}
                        subtitle={blocksStep?.content?.nestBlock?.subheader || ""}
                        status={blocksStep?.type}
                        showStatus={showStatus}
                        applicationId={application_id}
                        loadingBtnState={loadingButtonState}
                    />
                </GAdditionalStep>
            )}
            { blocksStep.type === ProcessingStatuses.processing_by_government && (
                <ProcessingByGovernmentComponent
                    blocksStep={blocksStep}
                    solutionName={SolutionNamesForAdditionalSteps.PAS}
                    solutionNameToAdditionalStepsCard={SolutionNames.PAS}
                    solutionId={SolutionIds.PAS}
                    index={index}
                    showStatus={showStatus}
                    applicationId={application_id}
                    toCustomer={true}
                    showEstimateWaitTime={false}
                />
             )}
            {blocksStep.type === ProcessingStatuses.tracking && (
                <TrackingCardStepComponent
                    blocksStep={blocksStep}
                    solutionName={SolutionNamesForAdditionalSteps.PAS}
                    index={index}
                    showStatus={showStatus}
                    applicationId={application_id}
                    replaceInformation={true}
                    toCustomer={blocksStep?.status === ProcessingStatuses.tracking_to_customer}
                />
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    statusSignature: state.additional.statusSignature,
    statusQuestionnaire: state.additional.statusQuestionary,
    statusQuestionnaireFront: state.additional.statusQuestionnaireFront,
    statusDetails: state.additional.statusDetails,
    statusDetailsToOpen: state.additional.statusDetailsToOpen,
    statusDocuments: state.additional.statusDocuments,
    statusPhotoUpload: state.additional.statusPhotoUpload,
    statusAdditionalDocuments: state.additional.statusAdditionalDocuments,
    currentResponse: state.step.currentResponse,
    loadingButtonState: state.loading.loadingButtonState,
    application_id: state.form.application_id,
    appId: state.form.appId,
    formId: state.form.formId,
    missingInformationStatus: state.form.missingInformationStatus,
    statusSignatureBlockOpen: state.additional.statusSignatureBlockOpen,
    statusGovernmentFeeOpen: state.additional.statusGovernmentFeeOpen,
    customer: state.form.customer,
    upsellProcessingInformation: state.additional.upsellProcessingInformation,
    statusUpsellStep: state.additional.statusUpsellStep,
    submitObject: state.form.submitObject,
})

export default connect(mapStateToProps)(BlocksSteps)
