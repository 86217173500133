import React, {useEffect} from "react";
import {checkProductForDuplicate, preAuthUser, setUserToken} from "../../appRedux/actions";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getParams} from "../../assets/helpers/getParams";
import AuthUserBearerTokenRepository from "@dash/gov-plus__front-end__form/src/persistence/session/AuthUserBearerTokenRepository";
import ClientTokenRepository from "@dash/gov-plus__front-end__form/src/persistence/cookies/ClientTokenRepository";

export const usePreAuthUser = (authToken) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = getParams(window.location.search, ["form_id"]);
  const {duplicateApplications} = useSelector(({allProducts}) => allProducts);

  useEffect(() => {
    let userTokenFromCookie = ClientTokenRepository.getInstance().getValue();
    const userTokenFromSession = AuthUserBearerTokenRepository.getInstance().getValue();
    const isUserNeedPreAuth = authToken === null && history.location.pathname === "/signup" && !userTokenFromCookie;

    userTokenFromCookie = userTokenFromCookie ?? userTokenFromSession;

    if (isUserNeedPreAuth) {
      const preAuthCall = setTimeout(() => {
        dispatch(preAuthUser({}));
      }, 0)
      return () => clearTimeout(preAuthCall);
    }

    if(params?.form_id && userTokenFromCookie){
      dispatch(checkProductForDuplicate({
        client_token: userTokenFromCookie,
        form_id: params.form_id,
        array: duplicateApplications
      }))
    }

    if (userTokenFromCookie && !isUserNeedPreAuth) {
      AuthUserBearerTokenRepository.getInstance().setValue(userTokenFromCookie)
      dispatch(setUserToken({userToken: userTokenFromCookie}))
    }
  }, [authToken])
};
