import React from "react"

export const SvgRefreshNew = ({ width = 29, height = 29, backgroundColor = "#13BA68", color = "#FFF" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16287_27305)">
        <circle cx="14.5" cy="14" r="14" fill={backgroundColor} />
        <g clipPath="url(#clip1_16287_27305)">
          <path
            d="M20.6402 9.5332V12.8826H17.2909"
            stroke={color}
            strokeWidth="1.06061"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.3595 18.4647V15.1152H11.7088"
            stroke={color}
            strokeWidth="1.06061"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.76074 12.3247C10.0438 11.5246 10.525 10.8093 11.1593 10.2455C11.7936 9.68166 12.5604 9.28772 13.3882 9.10043C14.2159 8.91314 15.0776 8.93859 15.8928 9.17441C16.7081 9.41023 17.4503 9.84873 18.0502 10.449L20.6403 12.8829M8.35962 15.1159L10.9497 17.5498C11.5497 18.1501 12.2919 18.5886 13.1071 18.8244C13.9224 19.0602 14.784 19.0856 15.6118 18.8984C16.4395 18.7111 17.2063 18.3171 17.8406 17.7533C18.4749 17.1895 18.9561 16.4742 19.2392 15.6741"
            stroke={color}
            strokeWidth="1.06061"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_16287_27305">
          <rect width="28" height="28" fill="white" transform="translate(0.5)" />
        </clipPath>
        <clipPath id="clip1_16287_27305">
          <rect width="14.7368" height="14.7368" fill="white" transform="translate(7.13159 6.63086)" />
        </clipPath>
      </defs>
    </svg>
  )
}
