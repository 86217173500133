import React, { FC } from "react"
import { BasicBlockContainerProps } from "../../../../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { useDispatch } from "react-redux"
import { removeUploadDocument } from "../../../../../../Redux/store/upload/upload.actions"
import ButtonComponent from "../../../../UploadPhoto/ButtonComponent/ButtonComponent"
import remove from "../../../../../../assets/image/remove.svg"
import GButton, {BUTTON_TYPE} from "../../../../../atomicDesign/molecules/Button/GButton";
import {SIZE} from "../../../../../atomicDesign/types/types";

const UploadDocumentRemoveButton: FC<BasicBlockContainerProps> = ({ item: { className, text }, dynamicProps = {} }) => {
  const dispatch = useDispatch()
  const handlerOnClickRemove = () => dispatch(removeUploadDocument(dynamicProps))

  return   <div className={'btn-upload'}><GButton text={text} showIcon={false} click={handlerOnClickRemove} type={BUTTON_TYPE.SECONDARY} textSize={SIZE.PARAGRAPH_BOLD_16} /></div>
}

export default UploadDocumentRemoveButton
