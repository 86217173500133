import {
  GET_FOOTER_DATA, GET_FOOTER_DATA_SUCCESS, GET_RETARGETING_DATA_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  footerData: null,
  retargetingFormId: null,
  retargetingAppId: null,
  retargetUserData: {
    firstName: "",
    lastName: "",
  }
};


const NotesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FOOTER_DATA: {
      return {
        ...state,
      }
    }
    case GET_FOOTER_DATA_SUCCESS: {
      return {
        ...state,
        footerData: action.payload,
      }
    }
    case GET_RETARGETING_DATA_SUCCESS: {
      return {
        ...state,
        retargetingFormId: action.payload.formId,
        retargetingAppId:action.payload.appId,
        retargetUserData: {
          firstName: action.payload.userName,
          lastName: action.payload.lastName,
        }
      }
    }

    default:
      return state;
  }
}

export default NotesReducer;
