import React from "react"
import {SIZE, WEIGHT} from "../../../atomicDesign/types/types"
import IconsSvg from "../../../atomicDesign/atoms/IconsSvg/IconsSvg";
import {COLORS, COLORS_NEW} from "../../../atomicDesign/types/Colors"
import {ICONS_SVG_TYPE} from '../../../atomicDesign/atoms/IconsSvg/IconsSvgTypes';
import "./CompletedEINComponent.scss"
import GText from "../../../atomicDesign/atoms/Text/Text"

type CompletedEINComponentProps = {
    title: string,
    body: string,
    textButton: string,
    onSendClick: () => void
}

const CompletedEINComponent: React.FunctionComponent<CompletedEINComponentProps> = ({
                                                                                        title,
                                                                                        body,
                                                                                        textButton,
                                                                                        onSendClick
                                                                                    }) => {
    const letterSpacingText: React.CSSProperties = {
        letterSpacing: "-0.1px"
    }

    function formatNumber(einNumber: string) {
        if (!einNumber || einNumber.length < 2) return ' ';
        return einNumber.replace(/^(\d{2})(\d{7})$/, '$1-$2');
    }

    return (
        <>
            <div className={`GCompletedEINComponent`}>
                <div className="GCompletedEINComponent__title">
                    <GText text={title} size={SIZE.PARAGRAPH_BOLD_18} style={letterSpacingText}
                           weight={WEIGHT.WEIGHT_600} color={COLORS_NEW.BLACK_600}/>
                </div>
                <div className="GCompletedEINComponent__body">
                    <GText text={formatNumber(body)} size={SIZE.HEADING_MDLG} style={{letterSpacing: "-0.5px"}}
                           color={COLORS_NEW.BLACK_1000}/>
                </div>
                <div className="GCompletedEINComponent__button">
                    <IconsSvg type={ICONS_SVG_TYPE.EMAIL} width={24} height={24} color={COLORS_NEW.ROYAL_BLUE_700}/>
                    <GText text={textButton} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.ROYAL_BLUE_700}
                           onClick={onSendClick}/>
                </div>
            </div>
        </>
    )
}
export default CompletedEINComponent
