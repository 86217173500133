import Event from "../Event"
import {EventOptions, EventProps} from "../EventProperties"
import IEvent from "../IEvent";

const EVENT_NAME = "Upsell Skipped";

/**
 * Upsell Skipped
 * @class UpsellSkipped
 * @extends {Event}
 * @implements {IEvent}
 * @description Upsell Skipped Event
 */
export default class UpsellSkipped extends Event implements IEvent {
    private readonly _upsellName: string | undefined;
    private readonly _upsellPrice: string | undefined;
    private readonly _upsellCategory: string | undefined;

    /**
     * Creates an instance of UpsellSkipped.
     * @param upsellName
     * @param upsellPrice
     * @param upsellCategory
     * @param ipAddress
     * @memberof UpsellSkipped
     * @description Upsell Skipped Event
     */
    constructor(
        upsellName: string | undefined,
        upsellPrice: string | undefined,
        upsellCategory: string | undefined,
        ipAddress: string | undefined
    ) {
        super(EVENT_NAME, ipAddress);
        this._upsellName = upsellName;
        this._upsellPrice = upsellPrice;
        this._upsellCategory = upsellCategory;
    }

    /**
     * Get Event Data
     * @description Get Event Data
     * @returns {EventProps}
     * @memberof UpsellSkipped
     */
    getEventData(): EventProps {
        return {
            ...this.baseEventData(),
            upsell_name: this._upsellName,
            upsell_price: this._upsellPrice,
            upsell_category: this._upsellCategory,
        }
    }

    /**
     * Get Event Name
     * @description Get Event Name
     * @returns {string}
     * @memberof UpsellSkipped
     */
    getEventName(): string {
        return this.eventName;
    }

    /**
     * Get Page Name
     * @description Get Page Name
     * @returns {string}
     * @memberof UpsellSkipped
     */
    pageName(): string {
        return "";
    }

    /**
     * Get Previous Id
     * @description Get Previous Id
     * @returns {string}
     * @memberof UpsellSkipped
     */
    getPreviousId(): string {
        return "";
    }

    /**
     * Get User Id
     * @description Get User Id
     * @returns {string}
     * @memberof UpsellSkipped
     */
    getUserId(): string {
        return "";
    }

    getEventOptions(): EventOptions | undefined {
        return undefined;
    }
}
