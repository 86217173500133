import React from "react";
import "./index.less"
import SettingsButton from "../SettingsButton";
import {ProductsImages} from "../ProductsImages";
import {showGrowlMessage} from "../../appRedux/actions";
import {useDispatch} from "react-redux";
import GButton from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Button/GButton";
import {SIZE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import GText from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Text/Text";
import {COLORS} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";

const DeliveredInfoBox = ({deliverAddress, trackNumber, redirectLink, provider}) =>{
  const redirectToProvider = ()=>{
    window.open(redirectLink, "_blank");
  }

  return(
    <div className={"PostalStatusPageContainer-userPackageInfo"}>
        <div className={"PostalStatusPageContainer-userPackageInfo-deliverData-address"}>
            <GText text={'Your package will be delivered to:'} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS.SLATE_GRAY_700}/>

            {deliverAddress
                ? <GText text={deliverAddress} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.SLATE_GRAY_500}/>
                : <GText text={'No deliver address data'} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.SLATE_GRAY_500}/>
            }
        </div>
        <div className={"PostalStatusPageContainer-userPackageInfo-deliverData-trackingNum"}>
            <GText text={`Your ${provider} Tracking number`} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS.SLATE_GRAY_700} />
            {trackNumber
                ?  <GText text={trackNumber} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.ROYAL_BLUE_600} />
                : <GText text={'No any track data'} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.RED_500} />
            }
        </div>
      <div className={"PostalStatusPageContainer-userPackageInfo_button"}>
        <GButton text={`Track on ${provider}`} textSize={SIZE.PARAGRAPH_BOLD_16} click={redirectToProvider} disabled={!redirectLink} showIcon={false} />
      </div>

      <div className={"PostalStatusPageContainer-userPackageInfo_postalImage"}>
          {
              provider === "USPS" ? <ProductsImages type={"usPostalService"} imageWidth={150} /> : <ProductsImages type={"FeDex"} imageWidth={150} />
          }

      </div>
    </div>
  );
};

export default DeliveredInfoBox;
