import { Action } from "../../interfacesAction/action.interface"

export const ActionTypes = {
  SET_CASH_STATE: "[CASH] set cash state",

  GET_CASH_STATE: "[CASH] get cash state",

  SET_ANSWER_CHECK_STATUS: "[CASH] set answer check status",

  GET_FAILURE: "[getFailureAction] get failure action",
}

export const setCashState = (submitObject: {}): Action<{}> => ({
  type: ActionTypes.SET_CASH_STATE,
  payload: submitObject,
})

export const getCashState = (submitObject: {}): Action<{}> => ({
  type: ActionTypes.GET_CASH_STATE,
  payload: submitObject,
})

export const setAnswerCheckStatus = (answer: {}): Action<{}> => ({
  type: ActionTypes.SET_ANSWER_CHECK_STATUS,
  payload: answer,
})

export const getFailureAction = (error?: {}): Action<{}> => ({
  type: ActionTypes.GET_FAILURE,
  payload: error,
})
