export enum TaxConstant {
    IN_PROGRESS_TEXT = "In progress",
    PROGRESS_PROCESSING_TEXT = "Processing",
    DESCRIPTION_TEXT = "Estimated completion time: few minutes",
    DESCRIPTION_ESTIMATED_WAIT_TEXT = "<b>IRS</b> <br> Estimated wait time:",
    CTA_PROCESSING_TEXT = "Finish tax return",
    CTA_TRACK_TEXT = "Track tax refund",
    ESTIMATED_REFUND = "Estimated refund",
    FEDERAL_TAX_RETURN = "Federal return status",
    STATE_RETURN_STATUS = "State return status",
    CTA_SEE_MORE_DETAIL = "See more details",
    TAXES_ESTIMATED_REFUNDED = "Estimated refund: "
}