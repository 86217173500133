import React from "react"
import { Button } from "antd"
import moment from "moment"
import { ItemInterface } from "../../../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import GUploadPassportPhotoStep
    from "../../../../atomicDesign/organisms/UploadPassportPhotoStep/GUploadPassportPhotoStep";

export const formCompete = (yesIcon: string, complete: string) => (
  <div className={`status_complete`}>
    <div className={`icon-container_complete`}>
      <img src={yesIcon} alt="approve" />
    </div>
    <div className={`div-bg`}>
      <p>{complete}</p>
    </div>
  </div>
)


export const passportQuestionnaireToOpen = (
    index: number,
    { description, button }: { description: string, button: string },
    loadingButtonState: string,
    handlerFunc: (arg: string) => void
) => {
    let information = <p className={`step-signature__description`} dangerouslySetInnerHTML={{__html: description}} />
    return (
        <>
            <div className={`icon-container_wait`}>
                <p className={`icon-number`}>{index}</p>
            </div>
            <div className={`div-bg`}>
                {information}
                <Button
                    type="primary"
                    className={"step-additional-information"}
                    onClick={() => handlerFunc("process")}
                    loading={loadingButtonState === "loading"}
                >
                    <span className="">{button}</span>
                </Button>
            </div>
        </>
    )
}

export const questionnaireBlockDisable = (
  statusQuestionnaire: string,
  index: number,
  { description, button }: { description: string, button: string },
  handlerQuestionnaire: (arg: string) => void
) => (
  <>
    <div
      className={`${
        (statusQuestionnaire !== "process" && "icon-container_process") ||
        (statusQuestionnaire === "complete" && "icon-container_complete")
      }`}
    >
      <p className={`icon-number`}>{index}</p>
    </div>
    <div className={`div-bg`}>
      <p className={`step-signature__description`}>{description}</p>
      <Button type="primary" className={""} onClick={() => handlerQuestionnaire("process")}>
        <span className="">{button}</span>
      </Button>
    </div>
  </>
)

export const questionnaireBlockComplete = (
  yesIcon: string,
  { complete }: { complete: string},
  statusDetails: string
) => {
  return (
    <div className={`status_complete`}>
      <div className={`icon-container_complete passport_details_${statusDetails}`}>
        <img src={yesIcon} alt="approve" />
      </div>
      <div className={`div-bg`}>
        <p className={`step-signature__description`}>{complete}</p>
      </div>
    </div>
  )
}

export const signatureAndPassportDetailsDisable = (
  status: string,
  index: number,
  { descriptionWait, button }: { descriptionWait: string, button?: string },
  handlerFunc: (arg: string) => void,
  typeBlock: string,
  nameCustomer?: string
) => (
  <>
    <div
      className={`${
        (status !== "complete" && "icon-container_wait") || (status === "complete" && "icon-container_process")
      }`}
    >
      <p className={`icon-number`}>{index}</p>
    </div>
    <div className={`div-bg`}>
      <p className={`step-signature__description`}>{descriptionWait}</p>
      <Button disabled={status !== "complete"} type="primary" className={""} onClick={() => handlerFunc("process")}>
        {typeBlock === "passport_details" && <span className="">Required</span>}
        {typeBlock === "signature" && nameCustomer && (
          <span className="">{button?.replace("John", nameCustomer).replace("02/01/2021", moment().format("L"))}</span>
        )}
      </Button>
    </div>
  </>
)

export const coaSignatureCompleted = (
  index: number,
  name: string,
  statusSignature: string,
  showStatus: string,
  yesIcon: string,
  {
    complete,
    descriptionWait,
    button,
    descriptionProcess,
  }: { complete: string, descriptionWait: string, button: string, descriptionProcess: string },
  loadingButtonState: string,
  handlerFunc: (arg: string) => void
) => {
    let information = <p className={`step-signature__description`} dangerouslySetInnerHTML={{__html: descriptionWait}} />
  return (
    <div className={`status_complete`}>
      {showStatus && showStatus === "completed" && (
        <div className={`icon-container_complete`}>
          <img src={yesIcon} alt="approve" />
        </div>
      )}

      {showStatus !== "completed" && (
        <div className={`icon-container_wait`}>
          <p className={`icon-number`}>{index}</p>
        </div>
      )}

      {showStatus === "completed" && (
        <div className={`div-bg`}>
          <p className={`step-signature__description`}>{complete}</p>
        </div>
      )}

      {showStatus !== "completed" && (
        <div className={`div-bg`}>
            {information}
          <Button type="primary" disabled={showStatus === ""} className={`step-additional-information`} onClick={() => handlerFunc("process")}>
            <span className="">{button.replace("John", name).replace("02/01/2021", moment().format("L"))}</span>
          </Button>
        </div>
      )}
    </div>
  )
}

export const coaInformationCompleted = (
  index: number,
  statusInformation: string,
  showStatus: string,
  yesIcon: string,
  {
    complete,
    buttonComplete,
    type,
    descriptionWait,
    button,
  }: { complete: string, buttonComplete: string, type: string, descriptionWait: string, button: string },
  loadingButtonState: string,
  handlerFunc: (arg: string) => void
) => {
    let information = <p className={`step-signature__description`} dangerouslySetInnerHTML={{__html: descriptionWait}} />
  return (
    <div className={`status_complete`}>
      {showStatus && showStatus === "completed" && (
        <div className={`icon-container_complete`}>
          <img src={yesIcon} alt="approve" />
        </div>
      )}

      {showStatus !== "completed" && (
        <div className={`icon-container_wait`}>
          <p className={`icon-number`}>{index}</p>
        </div>
      )}

      {showStatus === "completed" && (
        <div className={`div-bg`}>
          <p className={`step-signature__description`}>{complete}</p>
        </div>
      )}

      {showStatus !== "process" && showStatus !== "completed" && (
        <div className={`div-bg`}>
            {information}
            <Button type="primary" className={"step-additional-information"} disabled={showStatus === ""}>
            <span className="">{button}</span>
          </Button>
        </div>
      )}
    </div>
  )
}

export const servicesCompleted = (
  index: number,
  statusServices: string,
  showStatus: string,
  yesIcon: string,
  {
    complete,
    buttonComplete,
    type,
    description,
    button,
  }: { complete: string, buttonComplete: string, type: string, description: string, button: string },
  loadingButtonState: string,
  handlerFunc: (arg: string) => void
) => {
  return (
    <div className={`status_complete`}>
      {showStatus && showStatus === "completed" && (
        <div className={`icon-container_complete`}>
          <img src={yesIcon} alt="approve" />
        </div>
      )}

      {showStatus !== "completed" && statusServices !== "completed" && (
        <div className={`icon-container_wait`}>
          <p className={`icon-number`}>{index}</p>
        </div>
      )}

      {showStatus === "completed" && (
        <div className={`div-bg`}>
          <p className={`step-signature__description`}>{complete}</p>
        </div>
      )}

      {showStatus !== "completed" && statusServices !== "completed" && statusServices !== "process" && (
        <div className={`div-bg`}>
            <p className={`step-signature__description`} dangerouslySetInnerHTML={{__html: description}} />
          <Button
            type="primary"
            className={`step-additional-information`}
            onClick={() => handlerFunc("process")}
            disabled={showStatus === ""}
          >
            <span className="">{button}</span>
          </Button>
        </div>
      )}
    </div>
  )
}

export const passportDetailsToOpen = (
    index: number,
    { descriptionWait, buttonComplete, type }: { descriptionWait: string, buttonComplete: string, type: string },
    loadingButtonState: string,
    handlerFunc: (arg: string) => void,
    showStatus?:string
) => {
    let information = <p className={`step-signature__description`} dangerouslySetInnerHTML={{__html: descriptionWait}} />
    return (
        <>
            <div className={`icon-container_wait`}>
                <p className={`icon-number`}>{index}</p>
            </div>
            <div className={`div-bg`}>
                {information}
                <Button
                    type="primary"
                    className={"step-additional-information"}
                    onClick={() => handlerFunc("process")}
                    loading={loadingButtonState === "loading"}
                    disabled={showStatus !== "process"}
                >
                    <span className="">{buttonComplete}</span>
                </Button>
            </div>
        </>
    )
}

export const stepDetailToOpen = (
    index: number,
    { descriptionWait, buttonComplete, type }: { descriptionWait: string, buttonComplete: string, type: string },
    loadingButtonState: string,
    handlerFunc: (arg: string) => void,
    showStatus?:string
) => {
    let information = <p className={`step-signature__description`} dangerouslySetInnerHTML={{__html: descriptionWait}} />
    return (
        <>
            <div className={`icon-container_wait`}>
                <p className={`icon-number`}>{index}</p>
            </div>
            <div className={`div-bg`}>
                {information}
                <Button
                    type="primary"
                    className={"step-additional-information"}
                    onClick={() => handlerFunc("process")}
                    loading={loadingButtonState === "loading"}
                    disabled={showStatus !== "process"}
                >
                    <span className="">{buttonComplete}</span>
                </Button>
            </div>
        </>
    )
}

export const passportDetailsCompleted = (
  yesIcon: string,
  { complete, buttonComplete, type }: { complete: string, buttonComplete: string, type: string },
  loadingButtonState: string,
  handlerFunc: (arg: string) => void
) => (
  <div className={`status_complete`}>
    <div className={`icon-container_complete`}>
      <img src={yesIcon} alt="approve" />
    </div>
    <div className={`div-bg`}>
      <p className={`step-signature__description`}>{complete}</p>
      {type !== "additional_information_step" && (
        <Button
          type="primary"
          className={"step-signature__button-replace"}
          onClick={() => handlerFunc("process")}
          loading={loadingButtonState === "loading"}
          disabled={true}
        >
          <span className="">{buttonComplete}</span>
        </Button>
      )}
    </div>
  </div>
)

export const signatureOrUploadDocumentCompleted = (yesIcon: string, complete: string) => (
  <div className={`status_complete`}>
    <div className={`icon-container_complete`}>
      <img src={yesIcon} alt="approve" />
    </div>
    <div className={`div-bg`}>
      <p className={`signature_complete`}>{complete}</p>
    </div>
  </div>
)

export const uploadPhotoBlock = (
  index: number,
  blocksStep: ItemInterface,
  {disableText,button}:{disableText:string, button:string},
  statusPhotoUpload: string,
  showStatus: string,
  handlerFunc: any,
) => {
    const descriptionElement = <p className={`step-signature__description`} dangerouslySetInnerHTML={{__html: disableText}}/>
    return (
        <>
            <div className={`icon-container_wait ${statusPhotoUpload}`}>
                <p className={`icon-number`}>{index}</p>
            </div>
            {blocksStep && (
                <>
                    {!statusPhotoUpload && (
                        <div className={`div-bg`}>
                            {descriptionElement}
                            <Button
                                type="primary"
                                className={`step-additional-information`}
                                onClick={() => handlerFunc("process")}
                                disabled={showStatus === ""}
                            >
                                <span className="">{button}</span>
                            </Button>
                        </div>
                    )}
                    {/* {statusPhotoUpload === "process" && <UploadPhotoContainerComponent item={blocksStep} />} */}
                    {
                      statusPhotoUpload === "process" && <GUploadPassportPhotoStep />
                    }
                </>
            )}
        </>
    )
}

export const addressComponent = (
  index: number,
  statusAddress: string,
  showStatus: string,
  yesIcon: string,
  {
    complete,
    buttonComplete,
    type,
    description,
    button,
  }: { complete: string, buttonComplete: string, type: string, description: string, button: string },
  loadingButtonState: string,
  handlerFunc: (arg: string) => void
) => {
    const descriptionElement = <p className={`step-signature__description`} dangerouslySetInnerHTML={{__html: description}}/>
  return (
    <div className={`status_complete`}>
      {showStatus && showStatus === "completed" && (
        <div className={`icon-container_complete`}>
          <img src={yesIcon} alt="approve" />
        </div>
      )}

      {showStatus !== "completed" && statusAddress !== "completed" && (
        <div className={`icon-container_wait`}>
          <p className={`icon-number`}>{index}</p>
        </div>
      )}

      {showStatus === "completed" && (
        <div className={`div-bg`}>
          <p className={`step-signature__description`}>{complete}</p>
        </div>
      )}

      {showStatus !== "completed" && statusAddress !== "completed" && statusAddress !== "process" && (
        <div className={`div-bg`}>
          <p className={`step-signature__description`}>{descriptionElement}</p>
          <Button
            type="primary"
            className={"step-additional-information"}
            onClick={() => handlerFunc("process")}
            disabled={showStatus === ""}
          >
            <span className="">{button}</span>
          </Button>
        </div>
      )}
    </div>
  )
}

export const signatureCompleted = (
  index: number,
  name: string,
  statusSignature: string,
  showStatus: string,
  yesIcon: string,
  {
    complete,
    descriptionWait,
    button,
    descriptionProcess,
  }: { complete: string, descriptionWait: string, button: string, descriptionProcess: string },
  loadingButtonState: string,
  handlerFunc: (arg: string) => void
) => {
    let information = <p className={`step-signature__description`} dangerouslySetInnerHTML={{__html: descriptionWait}} />

    // console.log("button", button, name)
  return (
    <div className={`status_complete`}>
      {showStatus && showStatus === "completed" && (
        <div className={`icon-container_complete`}>
          <img src={yesIcon} alt="approve" />
        </div>
      )}

      {showStatus !== "completed" && (
        <div className={`icon-container_wait`}>
          <p className={`icon-number`}>{index}</p>
        </div>
      )}

      {showStatus === "completed" && (
        <div className={`div-bg`}>
          <p className={`step-signature__description`}>{complete}</p>
        </div>
      )}

      {showStatus !== "completed" && (
        <div className={`div-bg`}>
            {information}
            <Button type="primary" disabled={showStatus === ""} className={"step-additional-information"} onClick={() => handlerFunc("process")}>
            <span className="">{button.replace("John", name).replace("02/01/2021", moment().format("L"))}</span>
          </Button>
        </div>
      )}
    </div>
  )
}

export const uploadDocumentCompleted = (
  index: number,
  name: string,
  statusSignature: string,
  showStatus: string,
  yesIcon: string,
  { complete, label, buttonDisable }: { complete: string, label: string, buttonDisable: string },
  loadingButtonState: string,
  handlerFunc: (arg: string) => void
) => {
    let information = <p className={`step-signature__description`} dangerouslySetInnerHTML={{__html: label}} />
  return (
    <div className={`status_complete`}>
      {showStatus && showStatus === "completed" && (
        <div className={`icon-container_complete`}>
          <img src={yesIcon} alt="approve" />
        </div>
      )}

      {showStatus !== "completed" && (
        <div className={`icon-container_wait`}>
          <p className={`icon-number`}>{index}</p>
        </div>
      )}

      {showStatus === "completed" && (
        <div className={`div-bg`}>
          <p className={`step-signature__description`}>{complete}</p>
        </div>
      )}

      {showStatus !== "completed" && (
        <div className={`div-bg`}>
            {information}
            <Button type="primary" disabled={showStatus === ""} className={"step-additional-information"} onClick={() => handlerFunc("process")}>
            <span className="">{buttonDisable.replace("John", name).replace("02/01/2021", moment().format("L"))}</span>
          </Button>
        </div>
      )}
    </div>
  )



}

export const govermentFeesCompleted = (
    index: number,
    name: string,
    statusSignature: string,
    showStatus: string,
    yesIcon: string,
    {
        complete,
        descriptionWait,
        button,
        descriptionProcess,
    }: { complete: string, descriptionWait: string, button: string, descriptionProcess: string },
    loadingButtonState: string,
    handlerFunc: (arg: string) => void
) => {
    let information = <p className={`step-signature__description`} dangerouslySetInnerHTML={{__html: descriptionWait}} />
    return (
        <div className={`status_complete`}>
            {showStatus && showStatus === "completed" && (
                <div className={`icon-container_complete`}>
                    <img src={yesIcon} alt="approve" />
                </div>
            )}

            {showStatus !== "completed" && (
                <div className={`icon-container_wait`}>
                    <p className={`icon-number`}>{index}</p>
                </div>
            )}

            {showStatus === "completed" && (
                <div className={`div-bg`}>
                    <p className={`step-signature__description`}>{complete}</p>
                </div>
            )}

            {showStatus !== "completed" && (
                <div className={`div-bg`}>
                    {information}
                    <Button type="primary" disabled={showStatus === ""} className={`step-additional-information`} onClick={() => handlerFunc("process")}>
                        <span className="">{button.replace("John", name).replace("02/01/2021", moment().format("L"))}</span>
                    </Button>
                </div>
            )}
        </div>
    )
}


export const tsaQuestionnaireCompleted = (
  index: number,
  name: string,
  statusQuestionnaire: string,
  showStatus: string,
  yesIcon: string,
  {
    complete,
    description,
    button,
    descriptionProcess,
  }: { complete: string, description: string, button: string, descriptionProcess: string },
  loadingButtonState: string,
  handlerFunc: (arg: string) => void
) => {
  let information = <p className={`step-signature__description`} dangerouslySetInnerHTML={{__html: description}} />
  return (
    <div className={`status_complete`}>
      {showStatus && showStatus === "completed" && (
        <div className={`icon-container_complete`}>
          <img src={yesIcon} alt="approve" />
        </div>
      )}

      {showStatus !== "completed" && (
        <div className={`icon-container_wait`}>
          <p className={`icon-number`}>{index}</p>
        </div>
      )}

      {showStatus === "completed" && (
        <div className={`div-bg`}>
          <p className={`step-signature__description`}>{complete}</p>
        </div>
      )}

      {showStatus !== "completed" && (
        <div className={`div-bg`}>
          {information}
          <Button type="primary" disabled={showStatus === ""} className={`step-additional-information`} onClick={() => handlerFunc("process")}>
            <span className="">{button.replace("John", name).replace("02/01/2021", moment().format("L"))}</span>
          </Button>
        </div>
      )}
    </div>
  )
}
