import React, {useEffect, useRef} from "react"
import {connect} from "react-redux"
import { Select } from "antd"
import {Dispatch} from "../../../FieldsComponents/SelectComponent/useDefaultOption"
import DNDUploadDocumentContainer from "./DNDUploadDocument/DNDUploadDocumentContainer"
import {clearBlobDocumentsUpload} from "../../../../../Redux/store/upload/upload.actions"
import {clearArraysDocuments} from "../../../../../Redux/store/additionalForm/additional.actions"
import GSelect from "../../../../atomicDesign/molecules/Select/Select";
import GText from "../../../../atomicDesign/atoms/Text/Text";
import {SIZE} from "../../../../atomicDesign/types/types";
import {COLORS, COLORS_NEW} from "../../../../atomicDesign/types/Colors";
import "./styles.scss"
import GIconsSvgType from "../../../../atomicDesign/atoms/IconsSvg/IconsSvg";
import {ICONS_SVG_TYPE} from "../../../../atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import { BlockStepTypes } from "../../../../../Constants/BlockStepTypes"

type UploadSelectsRenderComponentV1Props = {
    blocksStep: {} | any,
    documents: {} | null | any,
    additionalDocuments: {} | any,
    blocks: [],
    handlerUploadDocument: any,
    getNameUploadDocument:any,
    nameDocumentStore: string,
    setNameDocumentStore: any,
    clientToken:string,
    documentList: ({})[],
    stateUploadDocuments: { primary_document: string, proof_relationship: string | null },
    clearTempDocuments: any,
    documentSelectState: {documentSelected: [string], setDocumentSelected: Dispatch<string>} | any,
    formId: string;
    dispatch: any;
}

const UploadSelectsRenderComponentV1: React.FunctionComponent<UploadSelectsRenderComponentV1Props> = ({
  blocksStep,
  documents,
  additionalDocuments,
  blocks,
  handlerUploadDocument,
  getNameUploadDocument,
  nameDocumentStore,
  setNameDocumentStore,
  clientToken,
  documentList,
  stateUploadDocuments,
  clearTempDocuments,
  documentSelectState,
  formId,
  dispatch
}) => {
  const { Option } = Select
  const fileInputsRefs = useRef<Record<string, HTMLInputElement | null>>({});

  const { documentSelected, setDocumentSelected } = documentSelectState
    
  const handlerOnChangeSelect = (
    clientToken:string,
    field: {name: string, backendValue: string},
    typeStep: string,
    input: HTMLInputElement,
    nameDocument: string,
    dispatch: any) => {
    clearTempDocuments(typeStep, clientToken)
    dispatch(clearArraysDocuments())
    dispatch(clearBlobDocumentsUpload())
    handlerChooseDocument(nameDocument, field)
  }

  const handlerChooseDocument = (nameDocument: string, field: {name: string, backendValue: string} | any) => {
    const {backendValue} = field
    if (nameDocument !== nameDocumentStore) {
      setNameDocumentStore(nameDocument)
    }
    if(stateUploadDocuments) {
      setNameDocumentStore(backendValue)
    }
    let aux = [];
    aux.push(nameDocument);
    setDocumentSelected(aux);
  }

  interface DocumentListInterface {
      primary_documents: {} | [],
      proof_relationship: {} | []
    }

  const getDocumentList = (backendValue: string, documentList: DocumentListInterface | undefined | any) => {
    const arrayDocument = documentList?.[backendValue] && Array.isArray(documentList?.[backendValue])
      ? documentList?.[backendValue]
      : Object.entries(documentList?.[backendValue]??{})

    return arrayDocument?.length
      ? arrayDocument.map((document: any[])=> {
        let documentObj = document[1]
        return {
          submit_value: document[0], render_value: (typeof document[1] === 'string') ? document[1] : documentObj.name
        }
      }) : []
  }

  const getDocumentToFileList = (backendValue: string, documentList: DocumentListInterface | undefined | any) => {
    const arrayDocument = documentList?.[backendValue] && Array.isArray(documentList?.[backendValue])
      ? documentList?.[backendValue]
      : Object.entries(documentList?.[backendValue]??{})

    return arrayDocument?.length
      ? arrayDocument : []
  }

  const getBackendValue = (backendValue: string, stateUploadDocuments: {} | any) => {
    if(backendValue && stateUploadDocuments?.[backendValue] === null ) {
      return null
    }
    return backendValue
  }

  const showFileInputContainer = (nameDocument: string) => {
    if (documentSelected.length) {
      return documentSelected.some((document: {} | any) => nameDocument === document)
    }
    return false
  }

  useEffect(() => {
    const typeDocument =
            blocksStep.type === BlockStepTypes.UPLOADING_FILE ? "upload_document_event" : "upload_additional_document_event"
    if (!sessionStorage.getItem(typeDocument)) {
      sessionStorage.setItem(typeDocument, "true")
      /* Event Processing Step */
    }
  })
  
  const getFileUploadDocumentV1 = (field:any, nameDocument:any, documentKey:any, documentParentKey:any, key:any, formId: string) => {
    return (
      <DNDUploadDocumentContainer
        {...{field, blocksStep, nameDocument, documentKey, documentParentKey, key, showFileInputContainer, formId}}
        item={{...blocksStep?.content?.uploadDocumentActionContainer, nameDocument}}
      />
    )
  }

  return (
    <>
      {
        blocks?.map((block: {} | any) => (
          <div
            className={`step-upload__block step-upload__block_${block.name} ${blocksStep.type}`}
            key={block.name}
          >
            <div className={'upload-title-container'}>
              <GText text={block.header} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900}/>
            </div>

            {block && block.fields.map((field: {} | any) => {

              if(getBackendValue(field.backendValue, stateUploadDocuments) === null) {
                return null
              }

              return (
                <div
                  className={`step-upload__field step-upload__field_${block.name}-${field.name} ${blocksStep.type}`}
                  key={field.name}
                >
                  {(blocksStep.type === BlockStepTypes.UPLOADING_FILE || blocksStep.type === BlockStepTypes.UPLOADING_ADDITIONAL_FILE) &&  field.type === "select" && (
                    <>
                      <GSelect
                        icon={<GIconsSvgType type={ICONS_SVG_TYPE.DROPDOWN} color={COLORS_NEW.BLACK_600} width={13} height={12} />}
                        placeHolder={blocksStep.select_placeholder ?? "Select document"}
                        onChange={(nameDocument: string | any) =>
                          handlerOnChangeSelect(clientToken || "ct", field,
                            // eslint-disable-next-line prettier/prettier
                            blocksStep.type, fileInputsRefs.current[field.name]!, nameDocument, dispatch)
                        }
                        disabled={documentList && !getDocumentList(field.backendValue, documentList)?.length}
                        options={((documentList && getDocumentList(field.backendValue, documentList)) || field.options || []).map(
                          (option: string | any, index: number) => (
                            <Option key={index} value={option.submit_value || ""}>
                              {option.render_value || ""}
                            </Option>
                          )
                        )}
                      >
                      </GSelect>


                      {((documentList && getDocumentToFileList(field.backendValue, documentList)) || field.options || []).map(
                        (option: any[]) => {
                          const documentKey = option[0];
                          const documentOptions = option[1];
                          if(typeof documentOptions === 'string') {
                            return getFileUploadDocumentV1(field, documentOptions, documentKey, documentKey, documentKey, formId);
                          } else {
                            return Object.keys(documentOptions?.files??{}).map(
                              (keyName: string) => {
                                return getFileUploadDocumentV1(field, documentOptions.files[keyName], documentKey + "-" + keyName, documentKey, documentKey + "-" + keyName, formId);
                              }
                            );
                          }
                        }
                      )}
                    </>
                  )}
                </div>
              )})}
          </div>
        ))
      }
    </>
  )
}

const mapStateToProps = (state: any) => ({
  formId: state.form.formId,
})

export default connect(mapStateToProps)(UploadSelectsRenderComponentV1)
