import React, { useState } from "react"
import { connect } from "react-redux"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import InputComponent from "../InputComponent/InputComponent"

type TextAreaComponentProps = {
  field: FieldInterface | any,
  submitObject: {} | any,
}

// eslint-disable-next-line max-len
const FieldsDescriptionComponent: React.FunctionComponent<TextAreaComponentProps> = ({ field, submitObject }) => {
  const showNestField = field.nest_input

  const [statusNestField, setStatusNestField] = useState(false)

  return (
    <>
      {showNestField && (
        <div className={"nest_field_container"}>
          {!statusNestField && (
            <p
              dangerouslySetInnerHTML={{ __html: field.description }}
              className={"nest_field_text"}
              onClick={() => setStatusNestField(true)}
            />
          )}
          {statusNestField && (
            <InputComponent
              field={field?.nest_input}
              value={submitObject[field?.nest_input?.name]}
              validationRule={{ type: "", pattern: "" }}
              observer={false}
              required={false}
            />
          )}
        </div>
      )}

      {!showNestField && (
        <div className={"description_field_container"}>
          <p>{field.description}</p>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
})

export default connect(mapStateToProps)(FieldsDescriptionComponent)
