import React from "react"

interface ISvgMyApplications {
  width: number;
  height: number;
  color: string;
}

export const SvgMyApplications = ({ width = 24, height = 25, color }: ISvgMyApplications) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 3.71094H3V10.7109H10V3.71094Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 3.71094H14V10.7109H21V3.71094Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 14.7109H14V21.7109H21V14.7109Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.7109H3V21.7109H10V14.7109Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
