
/*
* CCS class that will use fullHistory to apply a privacy mask to the fields that contain it
*/
export const FULL_HISTORY_MASK_CLASS_NAME = 'fs-mask'

/*
* constant that must include in the className property the fields that come from the json of the backend that will apply privacy masks
*/
export const FIELD_CLASS_TO_BE_MASKED = 'MaskedForPrivacy'
