import React, { ReactNode } from "react"
import GText from "../../atoms/Text/Text"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
import { SIZE } from "../../types/types"
import { COLORS_NEW } from "../../types/Colors"

import "./SubscriptionOfferBenefitItem.scss"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"

type SubscriptionOfferBenefitItemProps = {
  header: string,
  subHeader: string,
  image: string,
  imageWidth?: string | number,
  mode?: "horizontal" | "vertical",
  icon?: string,
  children?: ReactNode
}

const SubscriptionOfferBenefitItem = ({
  header,
  subHeader,
  image,
  imageWidth,
  mode = "horizontal",
  icon,
  children
} : SubscriptionOfferBenefitItemProps) => {

  const breakpoint = useBreakpoint()

  switch (mode) {
    case "horizontal":
      return (
        <div className={"SubscriptionBenefitItem"}>
          <div className={"SubscriptionBenefitItemHeader"}>
              <GText 
                  text={header} 
                  size={breakpoint.md ? SIZE.HEADING_MD : SIZE.PARAGRAPH_BOLD_24} 
                  maxWidth={breakpoint.md ? "" : "295px" } 
              />
              <GText 
                  text={subHeader} 
                  size={breakpoint.md ? SIZE.PARAGRAPH_BOLD_16 : SIZE.PARAGRAPH_BOLD_14} 
                  color={COLORS_NEW.BLACK_600} 
                  maxWidth={breakpoint.md ? "666px" : "295px" }
              />
          </div>
          <img src={image} width={imageWidth} />
          {children && children}
      </div>
      )
    case "vertical":
      return (
        <div className={"SubscriptionBenefitItemVertical"}>
          <div className="SubscriptionBenefitImage">
            {icon && <GIconsSvgType type={icon} width={28} height={28} backgroundColor={COLORS_NEW.GREEN_600} color={"white"} />}
            <img width={imageWidth} height={imageWidth} src={image} />
          </div>
          <div className={"SubscriptionBenefitItemContent"}>
              <GText 
                  text={header} 
                  size={breakpoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16}
              />
              <GText 
                  text={subHeader} 
                  size={breakpoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14} 
                  color={COLORS_NEW.BLACK_700}
              />
              {children && children}
          </div>
      </div>
      )
  }

  
}

export default SubscriptionOfferBenefitItem