import React from "react"

interface ISvgCheck {
    width: number;
    height: number;
    color?: string;
    backgroundColor?: string;
}

export const SvgSupport = ({width, height, color = "#216DE3", backgroundColor = "#E2EEFF"}: ISvgCheck) => {
    const viewBox = `0 0 ${width} ${height}`
    const rx = width / 2
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.166016" width={width} height={height} rx={rx} fill={backgroundColor}/>
            <path
                d="M19.166 34V28C19.166 25.6131 20.1142 23.3239 21.8021 21.636C23.4899 19.9482 25.7791 19 28.166 19C30.553 19 32.8421 19.9482 34.53 21.636C36.2178 23.3239 37.166 25.6131 37.166 28V34"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M37.166 35C37.166 35.5304 36.9553 36.0391 36.5802 36.4142C36.2052 36.7893 35.6964 37 35.166 37H34.166C33.6356 37 33.1269 36.7893 32.7518 36.4142C32.3767 36.0391 32.166 35.5304 32.166 35V32C32.166 31.4696 32.3767 30.9609 32.7518 30.5858C33.1269 30.2107 33.6356 30 34.166 30H37.166V35ZM19.166 35C19.166 35.5304 19.3767 36.0391 19.7518 36.4142C20.1269 36.7893 20.6356 37 21.166 37H22.166C22.6964 37 23.2052 36.7893 23.5802 36.4142C23.9553 36.0391 24.166 35.5304 24.166 35V32C24.166 31.4696 23.9553 30.9609 23.5802 30.5858C23.2052 30.2107 22.6964 30 22.166 30H19.166V35Z"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
