import React, { FC } from "react"
import "./CreditCardRecord.scss"
import visa from "../../../../../assets/image/card-visa.svg"
import amex from "../../../../../assets/image/card-amex.svg"
import discover from "../../../../../assets/image/card-discovery.svg"
import masterCard from "../../../../../assets/image/credit-card-mastercard.jpg"
import jcbCard from "../../../../../assets/image/card-jcb.svg"
import dinerClubCard from "../../../../../assets/image/card-dinerclub.svg"
import { RecordContentProps } from "../../../../../util/IdentityProtectionHelper"
import GText from "../../../atoms/Text/Text"
import { COLORS_NEW } from "../../../types/Colors"
import { SIZE } from "../../../types/types"

const CreditCardRecord: FC<RecordContentProps> = ({ ValueToMonitor }) => {
  const getCardTypeImage = (cardNumber: string) => {
    if (/^(?:2131|1800|35)/.test(cardNumber)) {
      return jcbCard
    }

    if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(cardNumber)) {
      return dinerClubCard
    }

    const firstDigit = cardNumber[0]

    switch (firstDigit) {
      case "3":
        return amex
      case "4":
        return visa
      case "5":
        return masterCard
      default:
        return visa
    }
  }

  return (
    <div className={"CreditCardRecord"}>
      <img src={getCardTypeImage(ValueToMonitor as string)} alt="credit card" />
      <GText
          text={ValueToMonitor as string}
          size={SIZE.PARAGRAPH_BOLD_20}
          color={COLORS_NEW.BLACK_1000}
      />
    </div>
  )
}

export default CreditCardRecord
