import BaseSessionStorageRepository from "./BaseSessionStorageRepository";

export const FORM_ID = "form_id"

class FormIdRepository extends BaseSessionStorageRepository {
    private static _instance: FormIdRepository;

    private constructor() {
        super();
    }

    public static getInstance(): FormIdRepository {
        if (!FormIdRepository._instance) {
            FormIdRepository._instance = new FormIdRepository();
        }
        return FormIdRepository._instance;
    }

    getKeyName(): string {
        return FORM_ID;
    }

    getValue(): string | null {
        return super.getValue() ?? null;
    }
}

export default FormIdRepository;