import React, { FC } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../../App"
import { BasicBlockContainerProps } from "../UploadPhotoService/interfaces/interfaces"
import { uploadSelector } from "../UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"

const UploadPhotoImgItem: FC<BasicBlockContainerProps> = ({ item: { className, text } }) => {
  const linkPhoto = useSelector<RootState, { linkPhoto: string }>(uploadSelector).linkPhoto

  return <img className={className} src={linkPhoto} alt={text} />
}

export default UploadPhotoImgItem
