export const moveUpToParentSpan = (target: any, className: string) => {
  let n = 10

  let newTarget = target.parentElement

  while (newTarget.className === className || n > 0) {
    newTarget = newTarget.parentElement
    if (newTarget.classList.contains(className)) {
      return newTarget
    }
    n--
  }
  return target
}

export const moveInNextInput = (e: any, item: {} | any, className: string) => {
  if (
    (e.target.value.length === item.maxLength && e.key !== "Backspace") ||
    (!e.target.value.length && e.key === "Backspace")
  ) {
    Array.from(moveUpToParentSpan(e.target, className).getElementsByTagName("input")).forEach(
      (input: {} | any, index: number, array: [] | any) => {
        if (e.target === input && e.target !== array[array.length - 1] && e.key !== "Backspace")
          return array[index + 1].focus()
        else if (e.target === input && e.target !== array[0] && e.key === "Backspace") return array[index - 1].focus()
      }
    )
  }
}
