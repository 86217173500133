/* eslint-disable indent */
import { ActionTypes } from "./step.actions"
import { Action } from "../../interfacesAction/action.interface"
import { StepInterface } from "../../InterfacesEntity/step.interface"
import { ValidationInterface } from "../../InterfacesEntity/validation.interface"
import { ModalContentInterface } from "../../../Components/ModalWindows/PrivacyPolicyComponent/PrivacyPolicyComponent"

const getLocalCurrentStep = () => {
  let localCurrentStep = +JSON.parse(sessionStorage.getItem("currentStep") || "{}")
  if (localCurrentStep > 3) localCurrentStep = 0
  return localCurrentStep
}
export interface State {
  additionalSteps: any[] | any;
  arraySteps: any[] | null;
  formArraySteps: any[];
  initialArraySteps: any[] | null;
  currentStep: StepInterface | null;
  currentBlock: any;
  indexCurrentStep: number;
  validation: ValidationInterface | {};
  optionsForSelect: {} | any;
  typeStep: string;
  currentResponse: {} | any;
  nameActualInput: string;
  nameActualLabel: string;
  nameActualType: string;
  showValueInputModal: string;
  modalContent: ModalContentInterface | null;
  eventPageName: string;
  labelForm: string;
}

const initialState: State = {
  additionalSteps: [],
  arraySteps: null,
  formArraySteps: [],
  initialArraySteps: null,
  currentStep: null,
  indexCurrentStep: getLocalCurrentStep() > 0 ? getLocalCurrentStep() : 0,
  currentBlock: null,
  validation: {},
  optionsForSelect: {},
  typeStep: "",
  currentResponse: {},
  nameActualInput: "",
  nameActualLabel: "",
  nameActualType: "",
  showValueInputModal: "off",
  modalContent: null,
  eventPageName: "",
  labelForm: "",
}

export const stepReducer = (state: State = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.SET_ARRAY_STEPS:
      return {
        ...state,
        arraySteps: action.payload,
      }
    case ActionTypes.SET_FORM_ARRAY_STEPS:
      return {
        ...state,
        formArraySteps: action.payload,
      }
    case ActionTypes.SET_INITIAL_ARRAY_STEPS:
      return {
        ...state,
        initialArraySteps: action.payload,
      }
    case ActionTypes.SET_ADDITIONAL_STEPS:
      return {
        ...state,
        additionalSteps: action.payload,
      }
    case ActionTypes.SET_VALIDATION_RULERS:
      return {
        ...state,
        validation: action.payload,
      }
    case ActionTypes.SET_OPTIONS_FOR_SELECT:
      return {
        ...state,
        optionsForSelect: action.payload,
      }
    case ActionTypes.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: (state.arraySteps && state.arraySteps[action.payload]) || null,
      }
    case ActionTypes.SET_CURRENT_BLOCK:
      return {
        ...state,
        currentBlock: action.payload,
      }
    case ActionTypes.SET_INDEX_CURRENT_STEP:
      sessionStorage.setItem("currentStep", JSON.stringify(action.payload))
      return {
        ...state,
        indexCurrentStep: action.payload,
      }
    case ActionTypes.GET_ADDITIONAL_ARRAY_STEPS:
      return {
        ...state,
        arraySteps: state.additionalSteps[action.payload],
      }
    case ActionTypes.SET_CURRENT_RESPONSE:
      return {
        ...state,
        currentResponse: action.payload,
      }
    case ActionTypes.SET_TYPE_STEP:
      return {
        ...state,
        typeStep: action.payload,
      }
    case ActionTypes.SET_NAME_ACTUAL_INPUT:
      return {
        ...state,
        nameActualInput: action.payload,
      }
    case ActionTypes.SET_NAME_ACTUAL_LABEL:
      return {
        ...state,
        nameActualLabel: action.payload,
      }
    case ActionTypes.SET_NAME_ACTUAL_TYPE:
      return {
        ...state,
        nameActualType: action.payload,
      }
    case ActionTypes.SET_SHOW_VALUE_MODAL_INPUT:
      return {
        ...state,
        showValueInputModal: action.payload,
      }
    case ActionTypes.SET_MODAL_CONTENT:
      return {
        ...state,
        modalContent: action.payload,
      }
    case ActionTypes.SET_DYNAMIC_BLOCKS:
      return {
        ...state,
        dynamicBlocks: action.payload,
      }
    case ActionTypes.SET_BILLING_INFORMATION:
      return {
        ...state,
        billingInformation: action.payload,
      }
    case ActionTypes.SET_EVENT_PAGE_NAME:
      return {
        ...state,
        eventPageName: action.payload,
      }
    case ActionTypes.SET_DYNAMIC_FIELDS:
      return {
        ...state,
        dynamicFields: action.payload,
      }
    case ActionTypes.SET_LABEL_FORM:
      return {
        ...state,
        labelForm: action.payload,
      }
    default:
      return state
  }
}
