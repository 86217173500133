import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import InputComponent from "../InputComponent/InputComponent"

type ValidationAddressComponentProps = {
  field: FieldInterface
  // eslint-disable-next-line prettier/prettier
  error: {} | any,
}

// eslint-disable-next-line max-len
const ValidationAddressComponent: React.FunctionComponent<ValidationAddressComponentProps> = ({
  field,
  error,
}) => {

  const errorSelf = error && error[field.error_name!]
  const status = errorSelf && errorSelf.status
  const { nest_input} = field

  const [statusInput, setStatusInput] = useState(false)

  const text = error && error[field.error_name!] && error[field.error_name!].message
    .replace("Click here to switch to a Business Address Change instead (your information will be transfered automatically).",
      "<a> Click here to switch to a Business Address Change instead (your information will be transfered automatically). </a>")

  useEffect(() => {
    const wrapperLink = document.querySelector(".error-validation-container")
    if (wrapperLink) {
      const link: any = wrapperLink.querySelector("a")
      link &&
      link.addEventListener("click", () => {
        setStatusInput(true)
      })
      return () =>
        link &&
        link.removeEventListener("click", () => {
          setStatusInput(true)
        })
    }
  })
 
  

  return <>    
    {!status && <div className={"error-validation-container"}>
      {!statusInput && errorSelf && <p dangerouslySetInnerHTML={{ __html: text }}></p>}
      {statusInput && nest_input &&  <InputComponent
        field={field.nest_input}
        value={""}
        validationRule={
          {type: "",
            pattern: ""
          }
        }
        observer={false}
        required={false}
      />}
    </div>}
  </>
}

const mapStateToProps = (state: any) => ({
  error: state.form.error,
})

export default connect(mapStateToProps)(ValidationAddressComponent)
