import EmailVerificationFailedBuilder
    from "../../../Components/atomicDesign/analytic/event/EmailVerificationFailed/EmailVerificationFailedBuilder";
import EmailVerificationSucceededBuilder
    from "../../../Components/atomicDesign/analytic/event/EmailVerificationSucceeded/EmailVerificationSucceededBuilder";
import UserIpRepository from "../../../persistence/session/UserIpRepository";
import GAnalyticEvents from "../../../Components/atomicDesign/analytic/AnalyticEvents";
import ApplicationIdRepository from "../../../persistence/session/ApplicationIdRepository";
import {put} from "redux-saga/effects";
import {setStatusOnSubmitObject, setSubmitObject} from "./form.actions";
import {ServicesIds} from "../../../util/ServicesIds";

export const FormSagaHelper = {

    SetAppIdSessionValue:(appid:any): any =>{
        ApplicationIdRepository.getInstance().setValue(appid);
    },

    EmailValidation: (response: any): any => {
        const emailValidation = response?.success?.email_validation;
        if (emailValidation) {
            return {
                field_name: 'email',
                status: emailValidation?.status,
                message: emailValidation?.feedback,
            }
        }
        return null;
    },
    TriggerEventEmailVerification: async (response: any) => {
        if(response?.success?.event_email_verification_failed || response?.success?.event_email_verification_success) {
            let eventInfo;
            let eventBuilder;
            if(response?.success?.event_email_verification_failed) {
                eventInfo =  response?.success?.event_email_verification_failed;
                eventBuilder = new EmailVerificationFailedBuilder();
            }
            if(response?.success?.event_email_verification_success) {
                eventInfo = response?.success?.event_email_verification_success;
                eventBuilder = new EmailVerificationSucceededBuilder();
            }

            if(eventInfo && eventBuilder) {
                eventBuilder.setEmailVerified(eventInfo?.properties?.emailVerified)
                    .setEmailData(eventInfo?.properties?.email_data)
                    .setIpAddress(UserIpRepository.getInstance().getValue());
                await GAnalyticEvents.track(eventBuilder.build());
            }
        }
    },
    updatePropertyForFilterStep(successResponse: any) {
        let objUpdated: any = {};
        if(successResponse.dataForFilterStep) {
            const dataForFilterStep = successResponse?.dataForFilterStep
            //CER app
            if(dataForFilterStep.hasOwnProperty("canNotarize")) {
                objUpdated.canNotarize = dataForFilterStep?.canNotarize ? "true" : "false"
            }

            //CER app
            if(dataForFilterStep.hasOwnProperty("is_return_envelope_required")) {
                objUpdated.isReturnEnvelopeRequired = dataForFilterStep?.is_return_envelope_required ? "true" : "false"
            }
        }
        if (successResponse.hasOwnProperty("status")) {
            objUpdated.status = successResponse?.status
        }
        if (successResponse.hasOwnProperty("form_type")) {
            objUpdated.form_type = successResponse.form_type
        }
        return objUpdated;
    },
    patchSetFormType(submitObject: any, success: any) {
        let formType;

        if(!success?.switched_type) {
            formType = FormSagaHelper.getFormType(submitObject?.form_id)
        } else {
            formType = FormSagaHelper.getFormType(success?.switched_type)
        }

        if(formType) {
            success.form_type = formType;
        }

        return success;
    },
    getFormType(formId: any) {
        switch (formId) {
            case ServicesIds.PassportRenewal:
                return "Y";
            case ServicesIds.PassportLost:
                return "NL";
            case ServicesIds.PassportStolen:
                return "NS";
            case ServicesIds.PassportDamage:
                return "YD";
            case ServicesIds.PassportNew:
                return "NEW";
            default:
                return null;
        }
    }
}