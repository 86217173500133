import React, {FC} from "react";
import {SIZE} from "../../types/types";
import "./GMyApplicationTeaserPage.scss"
import GServiceCardInactive from "../GServiceCard/GServiceCardInactive";
import GButton from "../../molecules/Button/GButton";
import { useHistory } from "react-router-dom";

type MyApplicationTeaserPageProps = {
 
}

const MyApplicationTeaserPage: FC<MyApplicationTeaserPageProps> = () => {
    const history = useHistory();
    const teaserPageMyApplication = () => {
        return <GButton 
          text={'+ Start application'} 
          showIcon={false}
          textSize={SIZE.PARAGRAPH_BOLD_16} 
          click={() => history.push("/formsApp")}
          />
      }
    return <div className={"GMyApplicationTeaserPage"}>
         <GServiceCardInactive Content={teaserPageMyApplication}/>
    </div>
}

export default MyApplicationTeaserPage;