import ApplicationStarted from "./ApplicationStarted";
import IEvent from "../IEvent";
import BaseBuilder from "../BaseBuilder";
import IBuilder from "../IBuilder";

/**
 * Application Started Event Builder
 * @class ApplicationStartedBuilder
 * @extends BaseBuilder
 * @description This is the class for build the Application Started event
 */
export default class ApplicationStartedBuilder extends BaseBuilder {
    private _applicationId: string | undefined;
    private _solution: string | undefined;
    private _service: string | undefined;
    private _category: string | undefined;

    /**
     * Set application id
     * @param applicationId
     * @returns {ApplicationStartedBuilder}
     * @description This method is used to set application id
     */
    setApplicationId(applicationId: string | undefined) {
        this._applicationId = applicationId;
        return this;
    }

    /**
     * Set solution
     * @param solution
     * @returns {ApplicationStartedBuilder}
     * @description This method is used to set solution
     */
    setSolution(solution: string | undefined) {
        this._solution = solution;
        return this;
    }

    /**
     * Set service
     * @param service
     * @returns {ApplicationStartedBuilder}
     * @description This method is used to set service
     */
    setService(service: string | undefined) {
        this._service = service;
        return this;
    }

    /**
     * Set category
     * @param category
     * @returns {ApplicationStartedBuilder}
     * @description This method is used to set category
     */
    setCategory(category: string | undefined) {
        this._category = category;
        return this;
    }

    /**
     * Set ip address
     * @param ipAddress
     * @returns {IBuilder}
     * @description This method is used to set ip address
     */
    setIpAddress(ipAddress: string | undefined) {
        this._ipAddress = ipAddress;
        return this;
    }

    /**
     * Build
     * @returns {IEvent}
     * @description This method is used to build the event
     */
    build(): IEvent {
        return new ApplicationStarted(this._applicationId, this._solution, this._service, this._category, this._ipAddress);
    }
}
