import React, { FC } from "react"
import "./Table.scss"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS_NEW } from "../../types/Colors"
import { TableProps } from "./Table.props"

export const GTable: FC<TableProps> = ({ headerCells, details }) => {
  return (
    <div className={"GTable"}>
      <div className={"GTable__Section__Details"}>
        <div className={"GTable__Section__Details__Row"}>
          {headerCells.map((header, index) => (
            <div key={`${header}-${index}`} className="GTable__Section__Details__Cell">
              <GText text={header} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_1000} />
            </div>
          ))}
        </div>

        {details.map((detail: any, index: number) => {
          const keys = Object.keys(detail)
          return (
            <div key={`${JSON.stringify(detail)}-${index}`} className="GTable__Section__Details__Row">
              {keys.map((key, keyIndex) => (
                <div key={`${index}-${keyIndex}`} className="GTable__Section__Details__Cell">
                  <GText text={`${detail[key]}`} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
                </div>
              ))}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default GTable
