import {subscriptionService, userService} from "../api/api";
import {
    BillingInformation
} from "../../../govplus-frontend/src/Components/FormComponents/BlockFormWithFields/BillingInformation/BlockBillingInformation";
import {useState} from "react";
import {CreditCard} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import {useUser} from "./useUser";

export const usePayment = () => {
    const [loading, setLoading] = useState(false)
    const [billingInformation, setBillingInformation] = useState<BillingInformation>()
    const {userFlattenData, userSubmittedData} = useUser()
    const getBillingInformation = (callback?: (response: boolean | BillingInformation) => void) => {
        setLoading(true)
        userService.getUserBillingInformation(sessionStorage.getItem("authUserBearerToken"))
            .then(response => {
                setBillingInformation(response.data)
                setLoading(false)
                callback?.(response.data)
            })
            .catch(error => {
                callback?.(false)
                setLoading(false)
                console.log(error)
            })
    }

    const savePaymentMethod = (paymentMethod: string, creditCard: CreditCard, callback?: (data: any) => void) => {
        const data = {
            client_token: sessionStorage.getItem("authUserBearerToken"),
            email: userSubmittedData?.email,
            first_name: userSubmittedData?.firstName,
            last_name: userSubmittedData?.lastName,
            phone: userSubmittedData?.phone,
            payment_method: paymentMethod,
            card_no: creditCard.number,
            expiration_date: creditCard.expirationDate,
            ssc: creditCard.cvv,
            zip_pay: creditCard.billingZipCode,
        }
        setLoading(true)
        userService.saveUserPaymentMethod(data)
            .then(response => {
                getBillingInformation(() => {
                    callback?.(response)
                    setLoading(false)
                })
            })
            .catch(error => {
                setLoading(false)
                callback?.(error.response.data)
            })
    }

    const updateDefaultPaymentMethod = (paymentMethod: string, userIsSubscribed: boolean = false) => {
        if (paymentMethod === "Intent" || paymentMethod === billingInformation?.default_payment_methods) return
        const data = {
            client_token: sessionStorage.getItem("authUserBearerToken"),
            payment_method_id: paymentMethod
        }
        userService.updateDefaultPaymmentMethod(data)
            .then(response => {
                if (userIsSubscribed) {
                    updateSubscriptionPaymentMethod(
                        response.data.default_payment_method_id,
                        (success) => {
                            if (success) {
                                // @ts-ignore
                                setBillingInformation({...billingInformation, default_payment_methods: response.data.default_payment_method_id})
                            }
                            setLoading(false)
                        }
                    )
                } else {
                    // @ts-ignore
                    setBillingInformation({...billingInformation, default_payment_methods: response.data.default_payment_method_id})
                    setLoading(false)
                }
            })
            .catch(error => {
                setLoading(false)
                console.log(error)
            })
    }

    const updateSubscriptionPaymentMethod = (paymentMethod: string, callback?: (success: boolean) => void) => {
        if (paymentMethod === "Intent") return
        subscriptionService.updateSubscriptionPaymentMethod(paymentMethod)
            .then(response => {
                callback?.(true)
            })
            .catch(error => {
                console.log(error.message)
                callback?.(false)
            })
    }

    const getCustomer = (callback: (data: any) => void) => {
        setLoading(true)
        userService.getCustomer(sessionStorage.getItem("authUserBearerToken"))
            .then(response => {
                setLoading(false)
                callback?.(response.data)
            })
            .catch(error => {
                callback?.(false)
                setLoading(false)
                console.log(error)
            })
    }

    return {
        billingInformation,
        getBillingInformation,
        loading,
        savePaymentMethod,
        getCustomer,
        updateDefaultPaymentMethod,
    }
}