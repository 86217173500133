export const iconSvgTypes = {
  GOV_ICON: 'gov_icon',
  INVALID_ADDRESS: "invalid_address",
  BLOCKED:"blocked_wronginformation",
  DELEVERED_TO_GOV: "delivered_to_gov",
  TRACKING_TO_CUSTOMER: "tracking_to_customer",
  USER_ICON: "userIcon",
  BILLING_ICON: "billingIcon",
  LOG_OUT_ICON: "logOutIcon",
  PLUS_ICON: "plusIcon",
  INFO_ICON: "infoIcon",
  LEFT_SIDE_ARROW_ICON: "leftSideArrowIcon",
  RIGHT_SIDE_ARROW_ICON: "rightSideArrowIcon",
  CLOSE_CROSS: 'closeCross',
  PRODUCTS_ICON: "productsIcon",
  OFFERS_ICON: "offersIcon",
  VISA_ICON: "visaIcon",
  QUESTION_ICON: "questionIcon",
  NOTIFICATION_BELL_ICON: "notificationsBellIcon",
  MENU_ARROW_ICON: "menuArrowIcon",
  MENU_USER_ICON: "menuUserIcon",
  SUCCESS_ICON: "successIcon",
  SUCCESS_ICON_V2: "successIconV2",
  UNSUCCESS_ICON: "unSuccessIcon",
  CLOCK_ICON: "clockIcon",
  ICON_IN_PRODUCT_PAGE: "iconInProductPage",
  ACCEPTED: "accepted",
  NO_SIGNATURE: "signature",
  NO_DOCUMENTS: "pending_documents",
  IDENTITY_DOCUMENT: "identity_document",
  CITIZENSHIP_DOCUMENT: "citizenship_document",
  NAME_CHANGE_DOCUMENT: "name_change_documents",
  UPLOAD_DOCUMENT: "upload_documents",
  UPLOAD_TAXES: "upload_taxes",
  PENDING_PACKAGE_VALIDATION: "pending_package_validation",
  PENDING_NOTARIZE: "pending_notarize",
  SEND_TO_NOTARIZE: "sent_to_notarize",
  DOWNLOAD: "download",
  PHONE_ICON: "phoneIcon",
  PROCESSING_SHIPMENT: "processing_shipment",
  COMPLETED: "completed",
  VALIDATION_ERR: "validation_err",
  BACK_ARROW_LEFT: "back_arrow_left",
  DUPLICATE_APP: "duplicate_app",
  CLOSE_ICON: "close_icon",

  PACKAGEVALIDATION: "packagevalidation",
  SHIPMENTTOCLIENT: "shipmenttoclient",
  SHIPPED: "shipped",

  NO_PASSPORT_DETAILS: "noPassportDetails",
  UPLOAD_PHOTO: "upload_photo",
  NO_QUESTIONNAIRE: "questionnaire",
  MISSING_INFORMATION: "missing_information",

  SHIPPED_BOX: "shipped_box",
  IN_TRANSIT: "inTransit",
  DELIVERED: "delivered",
  INPUT_ERROR: "inputError",
  FORGOT_PASSWORD_KEY: "forGotPassword",

  PROCESSING: "processing",
  PROCESSING_VALIDATION: "processing_validation",
  PENDING_SHIPMENT: "pending_shipment",

  SIGN_IN_ICON: "signInIcon",
  SIGN_UP_ICON: "signUpIcon",
  ENTER_VERIFICATION_CODE_ADD_PHONE: "enterVerificationCodeAddPhone",
  ENTER_VERIFICATION_CODE_CONFIRM_PHONE: "enterVerificationCodeConfirmPhone",
  ERROR_MESSAGE_INFO_ICON: "errorMessageInfoIcon",
  WARNING_MESSAGE_INFO_ICON: "warningMessageInfoIcon",
  SUCCSES_MESSAGE_INFO_ICON: "succsesMessageInfoIcon",
  USER_ADD_ICON: "userAddIcon",

  CLOSEDLOCK: "closedLock",
  OPENEDLOCK: "openedLock",
  HEADER_SEPARATOR: "headerSeparator",
  DROPDOWN_ARROW_FOR_SELECTOR_INPUT: "dropdownArrowForSelectorInput",
  BUTTON_ARROW: "button_arrow",
  NEW_APPLICATION_LINK: "newApplicationLink",
  OLD_ADDRESS: "oldAddress",
  NEW_ADDRESS: "newAddress",
  ARROW_BETWEN_OLD_AND_NEW:  "arrowBetwenOldAndNew",
  NOTARIZATION :"notarization",
  CONGRATULATION_ICON_FOR_MODAL: "congratulationIconInModal",
  MY_INSURED_DOCUMENTS:"myInsuredDocuments",
  REVIEW:"review",
  MAIL_ICON:"mailIcon",
  DELIVERED_TO_GOV: "delivered_to_gov",
  GOVERNMENT_FEES: "government_fees",
  RELATIONSHIP_DOCUMENT: "relationship_document",
  SHIPMENT: "shipment",
  READY_TO_PROCESS: "ready_to_process",
  BOOKING: "booking",
  QUESTIONAIRE: "questionaire",
  QUESTIONAIRE_SSN: "questionnaire_ssn",
  QUESTIONAIRE_HAS_SSN: "questionnaire_has_ssn",
  QUESTIONAIRE_HAS_TAX_AND_PAS: "questionnaire_has_tax_and_pas",
  QUESTIONAIRE_HAS_VA_AND_PAS_DL: "questionnaire_has_va_and_pas_dl_new_name",
  PENDING_ADDITIONAL_INFORMATION_PAS: "additional_information_pas",
  PENDING_ADDITIONAL_INFORMATION_VA: "additional_information_va",
  PENDING_ADDITIONAL_INFORMATION: "pending_additional_information",
  MAP_MARKER: "mapMarker",
  NO_GOV_PRODUCTS_ICON: "noGovProductsIcon",
  BOOKING_CONFIRMED: "booking_confirmed",
  PENDING_APPOINTMENT_SELECTION: "appointment_selection",
  DONE_ICON: "doneIcon",
  INVALID_NEW_ADDRESS: "invalid_new_address",
  INVALID_OLD_ADDRESS: "invalid_old_address",
  DISCOUNT: "discount",
  DISCOUNT_STAR: "discount_stars",
  PROCESSING_BOT_NEW_ERROR: "processing_bot_new_error",
  PROCESSING_ERROR: "processing_error",
  BLOCKED_101_OTHER: "blocked_101others",
  BLOCKED_SOLEPROPRIETOR: "blocked_soleproprietor",
  PROCESSING_BOT_RENEWAL_CHECK: "processing_bot_renewal_check",
  PROCESSING_BOT_RENEWAL_SUBMIT_APPLICATION: "processing_bot_renewal_submit_application",
  APPOINTMENT_SELECTION_RENEWAL: "appointment_selection_renewal",
  COMPLETED_RENEW: "completed_renew",
  PROCESSING_NOTIFICATIONS_SERVICES: "processing_notifications_services",
  PROCESSING_DUPLICATE: "processing_duplicate",
  PROCESSING_NOTIFICATIONS_AGENCIES: "processing_notifications_agencies",
  COMPLETED_NEW: "completed_new",
  COMPLETED_RENEW_EXPIRED: "completed_renew_expired",
  PROCESSING_BOT_ERROR: "processing_bot_error",
  BLOCKED_FOR_SOME_REASON: "blocked_for_some_reason",
  PROCESSING_VALIDATION_NOTARIZATION: "processing_validation_notarization",
  SERVICE_PROVIDERS: "service_providers",
  SHARE_LINK_ICON: "shareLinkIcon",
  SIGNUP_STAR: "signUpStar",
  SUCCESS_SHARING: "successSharing",
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  LINKEDIN: "linkedin",
  COPY_LINK: "copyLink",
  ERROR_GROWL_MESSAGE: "error_growl_message",
  WARNING_GROWL_MESSAGE: "warning_growl_message",
  SUCCESS_GROWL_MESSAGE: "success_growl_message",
  INFO_GROWL_MESSAGE: "info_growl_message",
  GROWL_CLOSE_ICON: "growlCloseIcon",
  INSURED: 'insured',
  CHECKED_ICON: 'checkedIcon',
  LITTLE_QUESTION_ICON: 'littleQuestionIcon',
  NOT_CHECKED_ICON: 'notCheckedIcon',
  HALF_INSURED: 'halfInsured',
  PLUS_ICON_BLUE: 'plusIconBlue',
  ADD_DOCUMENT_ICON_CIRCLE_BORDER: "addDocumentIconCircleBorder",
}
