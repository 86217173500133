import React, {FC, useEffect, useState} from "react"
import { Progress } from "antd"
import {COLORS, COLORS_NEW} from "../../types/Colors"
import { PROGRESS_BAR_ORIENTATION, SIZE } from "../../types/types"
import GText from "../../atoms/Text/Text"
import "./ProgressBar.scss"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg";
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes";
import {GeneralText} from "../../../../shared/text/Text";


export interface IProgressBar {
  color?: string;
  value: number;
  showInfo?: boolean;
  orientation?: string;
  customText?: string;
  showIcon?: boolean;
  showIconRight?: boolean,
  filledText?: string;
}
/**
 * Used <GProgressBar color={COLOR.NAME_COLOR} value={50} />
 * @param color Optional.
 * @param className Optional.
 * @param value Required.
 */
const GProgressBar: FC<IProgressBar> = ({
  color,
  value,
  showInfo = true,
  orientation = PROGRESS_BAR_ORIENTATION.LINEAR,
  customText = null,
  showIcon= true,
  showIconRight = false,
  filledText = "Completed"
}) => {
  const [prefilledValue, setPrefilledValue] = useState(0);
  let barColor = COLORS.SLATE_GRAY_25
  let textColor = COLORS.SLATE_GRAY_300

  let gProgress: React.CSSProperties = {
    flexGrow: 1
  }

  let gProgressTextContainer: React.CSSProperties = {
    display: "flex",
    gap: 6,
    alignItems: "center"
  }

  if (showInfo && showIcon && orientation === PROGRESS_BAR_ORIENTATION.LINEAR) {
    gProgress = { ...gProgress, marginInlineEnd: "calc(-9em - 20px)", paddingInlineEnd: "calc(9em + 10px)" }
    // gProgressTextContainer = { ...gProgressTextContainer, position: "absolute", right: "0", top: "1px" }
  } else if (showInfo && !showIcon && orientation === PROGRESS_BAR_ORIENTATION.LINEAR) {
    gProgress = { ...gProgress, marginInlineEnd: "calc(-9em - 5px)", paddingInlineEnd: "calc(9em - 5px)" }
    // gProgressTextContainer = { ...gProgressTextContainer, position: "absolute", right: "0", top: "1px" }
  }

  if (!!value && value > 0) {
    barColor = color ?? COLORS_NEW.ROYAL_BLUE_600
    textColor = color ?? COLORS_NEW.ROYAL_BLUE_600
  }

  /*
  * On startup, force the progress bar value to trigger a re-render
  * This makes the animation of the progress bar visually visible for the user
  * rather than just the progress bar being already at its value on the first render
  */
  useEffect(() => {
    setTimeout(() => setPrefilledValue(value))
  }, [value])

  const applicationCompleted = value === 100

  const getTextContainer = () => {
    let text = applicationCompleted && filledText === GeneralText.Completed ?
        filledText :
        `${value === 0 || value === undefined ? "0" : value}% ${filledText}`

    if (customText) {
      text = customText;
    }
    return (
      <div style={gProgressTextContainer}>
        <GText
          text={text}
          size={SIZE.PARAGRAPH_REGULAR_12}
          color={textColor}
          weight="500"
        />
        {showIconRight && <GIconsSvgType type={ICONS_SVG_TYPE.CHECK_CIRCLE} width={19} height={19} color={"white"} backgroundColor={color}/>}
      </div>
    )
  }

  return (
    <div className={`GProgressBar`}>
      {showInfo && orientation === PROGRESS_BAR_ORIENTATION.VERTICAL && getTextContainer()}
      <div className={"GProgressBar__Linear"}>
        {showIcon && <GIconsSvgType type={ICONS_SVG_TYPE.MAGIC_PENCIL} width={19} height={19} color={prefilledValue > 0 ? COLORS_NEW.ROYAL_BLUE_600 : COLORS_NEW.BLACK_400}/>}
        <Progress
            className={`GProgressBar__Progress`}
            style={gProgress}
            type={`line`}
            status={`normal`}
            strokeColor={barColor}
            percent={prefilledValue}
            showInfo={false}
            size={`small`}
        />
        {showInfo && orientation === PROGRESS_BAR_ORIENTATION.LINEAR && getTextContainer()}
      </div>
    </div>
  )
}

export default GProgressBar