/* eslint-disable indent */
import React from "react"
import { connect } from "react-redux"
import { FieldInterface } from "../../../../../Redux/InterfacesEntity/field.interface"
import cvv from "../../../../../assets/image/cvv.jpeg"

type InputModalComponentProps = {
  field: FieldInterface,
  nameActualInput: string,
  showOnModalHandler: any,
  showOffModalHandler: any,
  hint: boolean,
  validationStatus: string,
  errorState: string,
}

// eslint-disable-next-line max-len
const InputModalComponent: React.FunctionComponent<InputModalComponentProps> = ({
  field,
  nameActualInput,
  showOnModalHandler,
  showOffModalHandler,
  hint,
  validationStatus,
  errorState,
}) => {
  return (
    <>
      <p
        className={"link-to-modal"}
        onMouseOver={() => showOnModalHandler(field.name, "hint")}
        onMouseLeave={() => showOffModalHandler("hint")}
      >
        {field.modal && field.modal.prevText}
      </p>

      <p
        className={"link-to-modal mobile"}
        onClick={() => showOnModalHandler(field.name, "hint")}
        tabIndex={1}
        onBlur={() => showOffModalHandler("hint")}
      >
        {field.modal && field.modal.prevText}
      </p>
      {nameActualInput === field.name && hint && (
        <div className={"modal-input__container-above-container"}>
          <div
            // eslint-disable-next-line max-len
            className={`modal-input__container modal-input__container_${validationStatus}-${errorState}-validation`}
          >
            {(field.name === "ssc" && <img className={`modal-input__img`} src={cvv} alt="cards" />) || (
              <p className={"modal-input__text"}>{field.modal && field.modal.textInModal}</p>
            )}
          </div>
          <div className={`modal-input__container mobile`} tabIndex={1}>
            {(field.name === "ssc" && <img className={`modal-input__img`} src={cvv} alt="cards" />) || (
              <p className={"modal-input__text"}>{field.modal && field.modal.textInModal}</p>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({})

export default connect(mapStateToProps)(InputModalComponent)
