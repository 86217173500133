export const ICONS_SVG_TYPE = {
  HEADER_SEPARATOR: "header_separator",
  LOGO: "logo",
  NEW_APPLICATION: "new_application",
  ADD_DOCUMENT_ICON_CIRCLE_BORDER: "ADD_DOCUMENT_ICON_CIRCLE_BORDER",
  MY_APPLICATIONS: "my_applications",
  MY_INSURED_DOCUMENTS: "my_insured_documents",
  MY_FAMILY: "my_family",
  GIFT: "gift",
  MAGIC: "magic",
  CHECK: "check",
  CHECK_CIRCLE: "check_circle",
  CHECK_OVER_CIRCLE: "check_over_circle",
  X: "x",
  X_CIRCLE: "x_circle",
  EMAIL: "email",
  DROPDOWN: "dropdown",
  INFORMATION: "information",
  LOCATION: "location",
  DOCUMENT: "document",
  INSURANCE: "insurance",
  INSURANCE_SHIELD: "insurance_shield",
  INSURANCE_SHIELD_CIRCLE: "insurance_shield_circle",
  INSURANCE_ADD_SHIELD_CIRCLE: "Insurance_add_shield_circle",
  SUPPORT: "support",
  REFRESH: "refresh",
  REFRESH_NEW: "refresh_new",
  PHOTO: "photo",
  PLUS_SEPARATOR: "plus_separator",
  THINK_PLUS_SEPARATOR: "think_plus_separator",
  MENU_USER: "menuUserIcon",
  USER: "userIcon",
  USER_ADD_CIRCLE: "UserAddCircle",
  USERS:"usersIcon",
  BILLING: "billingIcon",
  LOGOUT: "logOutIcon",
  LOCK_OPEN: "lock_open",
  LOCK_CLOSE: "lock_close",
  AUTH_SIGN_IN: "sgv_auth_sign_in",
  KEY: "key",
  DAS_ARROW_LEFT: "das_arrow_left",
  VERIFICATION_CODE_PHONE_HEADER: "verification_code_phone_header",
  AUTH_ACTIVATE: "svg_auth_activate",
  ADD_PHONE_ICON: "add_phone_icon",
  SIGNATURE_ARROW_DOWN: "signature_arrow_down",
  DRAG_DROP:"DRAG_DROP",
  SEARCH:"SEARCH",
  SEARCH_STEP:"SearchStep",
  OVAL_HEADER_STEP:"OVAL_HEADER_STEP",
  RENEWAL:"RENEWAL",
  RENEWAL_CIRCLE: "RENEWAL_CIRCLE",
  RENEWAL_CIRCLE_BODY: "RENEWAL_CIRCLE_BODY",
  RENEWAL_CIRCLE_CHECK: "RENEWAL_CIRCLE_CHECK",
  RENEWAL_ADD: "RENEWAL_ADD",
  EXPEDITE_DOCUMENT: "expedite_document",
  CHECK_CIRCLE_TAX: "check_circle_tax",
  TAX_REFUND_ICON: "tax_refund_icon",
  TAX_ERROR_ICON: "tax_error_icon",
  SHEETS: "Sheets",
  GOV_OFFICE: "gov_office",
  PASSPORT: "passport",
  MAGIC_PENCIL: "magic_pencil",
  ARROW_FORWARD: "arrow_forward",
  CHEVRON_LEFT: "chevron_left",
  CHECK_CIRCLE_OUTLINE: "check_outline",
  ALERT_TRIANGLE: "alert_triangle",
  CLOCK_ICON: "clock_icon",
  GOVERNMENT_ICON: "government_icon",
  ICON_INFO: "icon_info",
  ICON_BOX: "icon_box",
  MY_IDENTITY: "my_identity",
  MY_CREDIT_SCORES: "my_credit_scores",
  CONFIRM: "confirm",
  REJECT: "reject",
  ICON_CHECK_CIRCLE: "icon_check_circle",
  ICON_REDIRECT_REVIEW: "icon_redirect_review",
  ICON_ARROW_FORWARD: "icon_arrow_forward",
  ICON_SHIELD_PROTECTED: "icon_shield_protected",
  ICON_ISURANCE_DOLLAR_PROTECTED: "icon_isurance_dollar_protected",
  ICON_SVG_ALERT: "icon_svg_alert",
  ICON_SVG_ERROR: "icon_svg_error",
  ICON_APPROVED_STATUS: "icon_approved_status",
  ICON_REJECTED_STATUS: "icon_rejected_status",
  ICON_GREEN_CHECK: "icon_green_check",
  ICON_RED_X: "icon_red_x",
  ICON_APPROVED_BUTTON: "icon_approved_button",
  ICON_DECLINE_BUTTON: "icon_decline_button",
  ICON_PLUS: "icon_plus",
  ICON_EDIT: "icon_edit",
  ICON_TRANS_UNION: "icon_trans_union",
  ICON_EXPAND_SIDEBAR: "icon_expand_sidebar",
  ICON_ALERT_MESSAGES: "icon_alert_messages",
  ICON_EMAIL_EXPOSURES: "icon_email_exposures",
  MENU_HAMBURGER: "menu_hamburger",
  CLOCK: 'clock',
  CHEVRON_RIGHT: "chevron_right",
  COLORED_DOT: "colored_dot",
  CREDIT_MONITORING: "credit_monitoring",
  INSURANCE_DOCUMENTS: "insurance_documents",
  GUARD: "guard",
  DASHBOARD: "dashboard",
  EXPEDITED_PROCESSING: "expedited_proccesing",
  DOLLAR_CIRCLE: "dollar_circle",
  CHAT_CIRCLE: "chat_circle",
  USER_CHECK_CIRCLE: "user_check_circle"
}
