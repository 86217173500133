import { env } from "../env";

export const ColumnTaxHelper = {
    openColumnTaxModule:(url: string) => {
        const environment = env.COLUMN_TAX_ENVIRONMENT ?? 'sandbox';

        return new Promise((resolve, reject) => {
            // @ts-ignore
            if (typeof ColumnTax !== "undefined" && typeof ColumnTax.openModule === "function") {
                // @ts-ignore
                ColumnTax.openModule({
                    userUrl: url,
                    environment: environment,
                    onClose: () => {
                        resolve(true);
                    },
                    style: {}
                });
            } else {
                reject(new Error('ColumnTax or ColumnTax.openModule not available'));
            }
        });
    }
}