import React, {FC} from "react"
import {Form, Input} from "antd"
import "./InputText.scss"
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg"
import {COLORS} from "../../types/Colors"
import {INPUT_STATUS} from "../../types/types";
import {getInputStatusFromItemStatus} from "../../helper/InputHelpers";
import {FULL_HISTORY_MASK_CLASS_NAME} from "../../../../util/FullHistoryMaskedFields";

type GInputTextProps = {
  onChange?: any,
  onBlur?: any,
  onFocus?: any,
  value?: string,
  suffix?: any,
  suffixError?: any,
  suffixSuccess?: any,
  inputStatus?: string,
  placeholder?: string,
  maxLength?: number,
  minLength?: number
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined
  type?: string;
  onKeyPress?: any;
  maskedForPrivacy?: boolean
}

const GInputText: FC<GInputTextProps> =
    ({
         onChange,
         onBlur,
         onFocus,
         value,
         suffix,
         suffixError,
         suffixSuccess,
         inputStatus,
         placeholder,
         maxLength,
         minLength,
         inputMode,
         type,
         onKeyPress,
         maskedForPrivacy=false
     }) => {
        const {status} = Form.Item.useStatus()
        if (!inputStatus) {
            inputStatus = getInputStatusFromItemStatus(status)
        }
        let suffixIcon = suffix ? (
            <GIconsSvgType type={suffix} width={20} height={20} color={COLORS.SLATE_GRAY_500}/>
        ) : (
            <span/>
        )
        if (inputStatus === INPUT_STATUS.ERROR) {
            suffixIcon = suffixError ? (
                <GIconsSvgType type={suffixError} width={20} height={20} color={COLORS.RED_500}/>
            ) : (
                <span/>
            )
        }

        if (inputStatus === INPUT_STATUS.SUCCESS) {
            suffixIcon = suffixSuccess ? (
                <GIconsSvgType type={suffixSuccess} width={20} height={20} color={COLORS.GREEN_500}/>
            ) : (
                <span/>
            )
        }
        return (
            <Input
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                value={value}
                suffix={suffixIcon}
                className={`GInputText__Input GInputText__Input--${inputStatus} ${type === 'number' ? 'input-no-spinner' : ''} ${maskedForPrivacy ? FULL_HISTORY_MASK_CLASS_NAME : ''}`}
                minLength={minLength}
                maxLength={maxLength}
                placeholder={placeholder}
                inputMode={inputMode}
                type={type}
                onKeyPress={onKeyPress}
            />
        )
    }

export default GInputText
