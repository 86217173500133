import React, {useEffect, useState} from 'react';
import "./index.less";
import {Alert} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {IconSvg} from "../../../components/svgImages/SvgImages";
import {clearMessage} from "../../../appRedux/actions";
import {customGrowlHelper} from "./helper";

const CustomGrowl = () => {
  const {messageInfo, growlStatus, authStatus} = useSelector(({auth}) => auth);
  const [messagesArr, setMessagesArr] = useState([]);
  const dispatch = useDispatch();
  const {svgIconType, errorClassName} = customGrowlHelper;

  const alertHeaderMessage = () => {
    if (errorClassName(authStatus) === "errorMessageInfo") {
      return "Error"
    }
    if (errorClassName(authStatus) === "warningMessageInfo") {
      return "Warning"
    }
    if (errorClassName(authStatus) === "succsesMessageInfo") {
      return "Success"
    }
    if (errorClassName(authStatus) === "info_growl_message") {
      return "Info"
    }
  }

  useEffect(() => {
    if (messageInfo?.length && !growlStatus) {
      setMessagesArr(prevState => [...prevState, {
        messageInfo,
        svgIconType: svgIconType(authStatus),
        errorClassName: errorClassName(authStatus),
        alertHeaderMessage: alertHeaderMessage()
      }])
    }
    dispatch(clearMessage())

    const clearMessagesArr = setTimeout(()=>{
      setMessagesArr([])
    }, 5000)

    return ()=> clearTimeout(clearMessagesArr)
  }, [messageInfo])

  return (
    <div className={"CustomGrowl"}>
      {messagesArr.map((item, id) => {
        return <div className={`CustomGrowl-item ${item.errorClassName}`} key={id}>
          <Alert
            icon={<div className={"icon"}><IconSvg type={item.svgIconType}/></div>}
            message={""}
            description={item.messageInfo}
            type={null}
            showIcon={false}
            closable={true}
            closeIcon={<IconSvg type={"growlCloseIcon"}/>}
            onClose={() => {
              setMessagesArr(prevState => prevState.filter((item, itemId) => itemId !== id))
            }}
          />
        </div>
      })}
    </div>
  );
};

export default CustomGrowl;
