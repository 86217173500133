/* eslint-disable indent */
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"

const parseOptionObj = (option: {} | any) => {
  if (
    (option.submit_value && option.submit_value.length > 0) ||
    (option.submitValue !== undefined && "" + option.submitValue)
  ) {
    return {
      submitValue: option.submit_value || option.submitValue,
      renderValue: option.render_value || option.optionName,
    }
  } else {
    return {
      submitValue: option.name,
      renderValue: option.name,
    }
  }
}

export const getOptionsName = (option: {} | string | any) => {
  switch (typeof option) {
    case "string":
      return {
        submitValue: option,
        renderValue: option,
      }
    case "object":
      return parseOptionObj(option)
    default:
      return {
        submitValue: "",
        renderValue: "",
      }
  }
}

interface RangeOptionsInterface {
  min: number;
  max: number;
}

const getIsNotRangeOptions = (options: [], arrayOptionsForField: []) =>
  arrayOptionsForField ? arrayOptionsForField : options

const getIsRangeOptions = ({ min, max }: RangeOptionsInterface) => Array.from(new Array(max - min), (x, i) => String(i))

export const getOptions = (options: [], arrayOptionsForField: [], rangeOptions?: RangeOptionsInterface) =>
  rangeOptions ? getIsRangeOptions(rangeOptions) : getIsNotRangeOptions(options, arrayOptionsForField)

export const getDynamicallyOptions = (subscribes: [], selectOptions: {}) => {
  if (subscribes && subscribes.length) {
    const arrOptionsSubscribe = subscribes.map((item: {} | any) => item.blockName)

    const dynamicallyOptions = Object.entries(selectOptions)
      .filter((option: {} | any) => arrOptionsSubscribe.includes(option[0]))
      .map((option: {} | any) => {
        let newArr
        // eslint-disable-next-line array-callback-return
        subscribes.map((subscribe: {} | any) => {
          if (subscribe.blockName === option[0]) {
            newArr = [option[0], { ...option[1], fields: subscribe.fields }]
          }
        })
        return newArr
      })
    return dynamicallyOptions
  } else return []
}

export const getStringValue = (obj: {} | any) => {
  return Object.values(obj).reduce((acc, field) => {
    if (typeof field === "string" && field) return acc + field + " "
    else return acc + ""
  }, "")
}

export const getWrapperStaticOptions = (staticOptionsForSelect: { data: [], fields: [] } | [], fieldName: string) => {
  if (Array.isArray(staticOptionsForSelect)) {
    return staticOptionsForSelect.find((item: { fields: [] }) =>
      item.fields.find((field: { name: string }) => field.name === fieldName)
    )
  }
  return staticOptionsForSelect
}

export const trimOption = (
  optionName: string,
  submitAdditionalOption: string | undefined,
  dispatch: any,
  fieldName: string
) => {
  if (optionName && submitAdditionalOption === "last-word__lower-case") {
    dispatch(setValueCurrentField(`${fieldName}__full_name`, optionName))
    return optionName.split(" ")[optionName.split(" ").length - 1].toLowerCase()
  } else return optionName
}

export const getObservableVarValue = (value: string | null | undefined) => (value ? value : value === null ? null : "")

export const arrayEmptyOptions = ["Lbs", "hair_color", "eye_color"]

export const getValidationWithEmptyOption = ({ name, emptyOption }: FieldInterface, arrayEmptyOptions: string[]) =>
  !arrayEmptyOptions.find((nameFromArray) => nameFromArray === name) ? emptyOption : false
