import React from "react"
import closeModal from "../../assets/image/closeModal.svg"

type RulesCheckBoxModalProps = {
  setValueModal: any,
  headerName: string,
  dispatch: any,
  children: any,
}

const RulesCheckBoxModal: React.FunctionComponent<RulesCheckBoxModalProps> = ({
  setValueModal,
  headerName,
  children,
  dispatch,
}) => {
  return (
    <div className="modal-bl">
      <div className="modal-bl__title-container">
        <h2 className="modal-bl__title">{headerName}</h2>
        <div className={`modal-bl__icon-container`}>
          <img
            src={closeModal}
            alt={"info"}
            className={`modal-bl__icon-close`}
            onClick={(e) => dispatch(setValueModal(""))}
          />
        </div>
      </div>
      {children}
      <div className="modal-bl__footer-container">
        <button className={"modal-bl__footer-button"} onClick={(e) => dispatch(setValueModal(""))}>
          {"Ok"}
        </button>
      </div>
    </div>
  )
}

export default RulesCheckBoxModal
