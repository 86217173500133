import BasicBlockContainer from "../../MainBlockContainer/BasicBlockContainer"
import PointContentComponent from "../../PointContentComponent/PointContentComponent"
import UploadPhotoActionBlock from "../../UploadPhotoActionBlock/UploadPhotoActionBlock"
import UploadPhotoDndArea from "../../UploadPhotoDndArea/UploadPhotoDndArea"
import UploadPhotoSubmitButton from "../../UploadPhotoSubmitButton/UploadPhotoSubmitButton"
import UploadPhotoLoadingButton from "../../UploadPhotoLoadingButton/UploadPhotoLoadingButton"
import UploadPhotoSaveButton from "../../UploadPhotoSaveButton/UploadPhotoSaveButton"
import UploadPhotoRemoveButton from "../../UploadPhotoRemoveButton/UploadPhotoRemoveButton"
import UploadPhotoImgItem from "../../UploadPhotoImgItem/UploadPhotoImgItem"
import ButtonCheckEmailContainer from "../../../CommonThankYouPage/ButtonCheckEmailContainer/ButtonCheckEmailContainer"
import ImgComponent from "../../ImgComponent/ImgComponent"
import HeaderH1ContentComponent from "../../HeaderH1ContentComponent/HeaderH1ContentComponent"
import HeaderH3ContentComponent from "../../HeaderH3ContentComponent/HeaderH3ContentComponent"
import DynamicContentComponent from "../../DynamicContentComponent/DynamicContentComponent"
import DynamicNameFile from "../../DynamicNameFile/DynamicNameFile"
import PaymentErrorContent from "../../../BlockFormWithFields/PaymentErrorContent/PaymentErrorContent"
import UploadDocumentDndArea from "../../../PostSubmitComponents/UploadComponent/UploadSelectsRenderComponent/DNDUploadDocument/UploadDocumentDndArea"
import UploadDocumentSubmitButton from "../../../PostSubmitComponents/UploadComponent/UploadSelectsRenderComponent/DNDUploadDocument/UploadDocumentSubmitButton"
import UploadDocumentActionBlock from "../../../PostSubmitComponents/UploadComponent/UploadSelectsRenderComponent/DNDUploadDocument/UploadDocumentActionBlock"
import UploadDocumentRemoveButton from "../../../PostSubmitComponents/UploadComponent/UploadSelectsRenderComponent/DNDUploadDocument/UploadDocumentRemoveButton"
import UploadDocumentImgItem from "../../../PostSubmitComponents/UploadComponent/UploadSelectsRenderComponent/DNDUploadDocument/UploadDocumentImgItem"
import DynamicNameByFieldName from "../../../PostSubmitComponents/UploadComponent/UploadSelectsRenderComponent/DNDUploadDocument/DynamicNameByFileName"
import DynamicErrorAnswer from "../../DynamicErrorAnswer/DynamicErrorAnswer"
import ReviewPageFieldContainer from "../../../ReviewPage/ReviewPageFieldContainer"
import ReviewPageFieldValue from "../../../ReviewPage/ReviewPageFieldValue"
import ReviewPageEditBlock from "../../../ReviewPage/ReviewPageEditBlock"
import ReviewPageButtonSubmit from "../../../ReviewPage/ReviewPageButtonSubmit"
import FormBaseBlock from "../../../../../Hocs/PageMainLayout/FormBaseBlock"
import DynamicPercentsLayout from "../../../../../Hocs/PageMainLayout/DynamicPercentsLayout"
import PercentageScaleChildrenLayout from "../../../../../Hocs/PageMainLayout/PercentageScaleChildrenLayout"
import DynamicProductHeaderLayout from "../../../../../Hocs/PageMainLayout/DynamicProductHeaderLayout"
import DynamicHeaderCardLayout from "../../../../../Hocs/PageMainLayout/DynamicHeaderCardLayout"
import DynamicServiceCardLayout from "../../../../../Hocs/PageMainLayout/DynamicServiceCardLayout"
import DynamicLogoProductLayout from "../../../../../Hocs/PageMainLayout/DynamicLogoProductLayout"
import DynamicArrayVariables
  from "../../../PostSubmitComponents/UploadComponent/UploadSelectsRenderComponent/DNDUploadDocument/DynamicArrayVariables";
import HeaderDynamicTextWebFlowLayout from "../../../../../Hocs/PageMainLayout/HeaderDynamicTextWebFlowLayout";

const arrayBaseUploadPhotoComponents = [
  {
    tagName: ["BasicBlockContainer", "div", "textPoint", undefined],
    Component: BasicBlockContainer,
  },
  {
    tagName: ["DynamicContentContainer"],
    Component: DynamicContentComponent,
  },
  {
    tagName: ["DynamicNameFile"],
    Component: DynamicNameFile,
  },
  {
    tagName: ["DynamicNameByFieldName"],
    Component: DynamicNameByFieldName,
  },
  {
    tagName: ["DynamicErrorAnswer"],
    Component: DynamicErrorAnswer,
  },
  {
    tagName: ["h1"],
    Component: HeaderH1ContentComponent,
  },
  {
    tagName: ["h3"],
    Component: HeaderH3ContentComponent,
  },
  {
    tagName: ["span"],
    Component: PointContentComponent,
  },
  {
    tagName: ["UploadPhotoActionBlock", "TSAQuestionnaireActionBlock"],
    Component: UploadPhotoActionBlock,
  },
  {
    tagName: ["UploadDocumentActionBlock"],
    Component: UploadDocumentActionBlock,
  },
  {
    tagName: ["UploadPhotoDndArea"],
    Component: UploadPhotoDndArea,
  },
  {
    tagName: ["UploadDocumentDndArea"],
    Component: UploadDocumentDndArea,
  },
  {
    tagName: ["UploadPhotoSubmitButton"],
    Component: UploadPhotoSubmitButton,
  },
  {
    tagName: ["ReviewPageButtonSubmit"],
    Component: ReviewPageButtonSubmit,
  },
  {
    tagName: ["UploadDocumentSubmitButton"],
    Component: UploadDocumentSubmitButton,
  },
  {
    tagName: ["UploadPhotoLoadingButton"],
    Component: UploadPhotoLoadingButton,
  },
  {
    tagName: ["UploadPhotoSaveButton"],
    Component: UploadPhotoSaveButton,
  },
  {
    tagName: ["UploadPhotoRemoveButton"],
    Component: UploadPhotoRemoveButton,
  },
  {
    tagName: ["UploadDocumentRemoveButton"],
    Component: UploadDocumentRemoveButton,
  },
  {
    tagName: ["ButtonCheckEmail"],
    Component: ButtonCheckEmailContainer,
  },
  {
    tagName: ["UploadPhotoImgItem"],
    Component: UploadPhotoImgItem,
  },
  {
    tagName: ["UploadDocumentImgItem"],
    Component: UploadDocumentImgItem,
  },
  {
    tagName: ["ImgCommon", "listUploadUploadDocumentsInstructionsIcon"],
    Component: ImgComponent,
  },
]

const arrayUploadPhotoInstructionComponents = [
  {
    tagName: [
      "descriptionUploadPhotoInstructionsIcon",
      "listUploadUploadPhotoInstructionsIcon",
      "dragAndDropAreaIcon",
      "formatErrorImgUploadPhoto",
      "dragAndDropSpinnerIcon",
      "successIconDND",
      "dragDocumentIcon",
      "dragPhotoIcon",
    ],
    Component: ImgComponent,
  },
  {
    tagName: ["DynamicArrayVariables"],
    Component: DynamicArrayVariables,
  },
]

const arrayErrorComponents = [
  {
    tagName: ["ErrorPaymentContent"],
    Component: PaymentErrorContent,
  },
]

const arrayReviewPage = [
  {
    tagName: ["ReviewPageFieldContainer"],
    Component: ReviewPageFieldContainer,
  },
  {
    tagName: ["ReviewPageFieldValue"],
    Component: ReviewPageFieldValue,
  },
  {
    tagName: ["ReviewPageEditBlock"],
    Component: ReviewPageEditBlock,
  },
]

const arrayMainLayoutPage = [
  {
    tagName: ["FormBaseBlock"],
    Component: FormBaseBlock,
  },
  {
    tagName: ["DynamicPercentsLayout"],
    Component: DynamicPercentsLayout,
  },
  {
    tagName: ["PercentageScaleChildrenLayout"],
    Component: PercentageScaleChildrenLayout,
  },
  {
    tagName: ["DynamicProductHeaderLayout"],
    Component: DynamicProductHeaderLayout,
  },
  {
    tagName: ["DynamicHeaderCardLayout"],
    Component: DynamicHeaderCardLayout,
  },
  {
    tagName: ["DynamicServiceCardLayout"],
    Component: DynamicServiceCardLayout,
  },
  {
    tagName: ["DynamicLogoProductLayout"],
    Component: DynamicLogoProductLayout,
  },
  {
    tagName: ["HeaderDynamicTextWebFlowLayout"],
    Component: HeaderDynamicTextWebFlowLayout,
  },
]

export const arrayUploadPhotoComponents = [
  ...arrayBaseUploadPhotoComponents,
  ...arrayUploadPhotoInstructionComponents,
  ...arrayErrorComponents,
  ...arrayReviewPage,
  ...arrayMainLayoutPage,
]
