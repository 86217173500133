import {showGrowlMessage} from "../../appRedux/actions";
import {
    EMPTY_EMAIL_MESSAGE,
    EMPTY_PASSWORD_MESSAGE,
    INVALID_EMAIL_MESSAGE,
    SESSION_EXPIRED_MESSAGE,
    SIGN_UP_REDIRECT_LINK,
    USER_BLOCKED_BY_ADMIN_EVENT_WORD,
    USER_BLOCKED_BY_ADMIN_STRING_REPLACE,
    USER_CANCELLED_EVENT_WORD,
    USER_CANCELLED_STRING_REPLACE,
    USER_IS_NOT_REGISTERED_EVENT_WORD,
    USER_IS_NOT_REGISTERED_MESSAGE,
    USER_LOGIN_MESSAGE_CONSTANT,
    USER_UNAUTHORIZED_MESSAGE,
    USER_UNAUTHORIZED_STRING_REPLACE,
    WRONG_USER_PASSWORD
} from "./SignInConstants";
import React from "react";
import {checkEmailRegEx} from "../../assets/helpers/getParams";
import GErrorLoginMessage
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/ErrorLoginMessage/GErrorLoginMessage";
import {Path} from "@dash/gov-plus__front-end__form/src/util/PageHelper";
import IntercomHelper from "@dash/gov-plus__front-end__form/src/util/IntercomHelper";

export const SignInHelper = {
    /**
     * This function return a custom error login message when you need to add some click event to a specific word
     *
     * @param message
     * @param eventWord
     * @param eventClick
     * @return {JSX.Element}
     * @private
     */
    _getStringMessageWithEventClick: (message, eventWord, eventClick) => {
        return <GErrorLoginMessage
            message={message}
            addEventClickToWord={eventWord}
            eventClick={eventClick}
        />
    },

    /**
     * This function return a custom error login message when you need to replace a specific string
     *
     * @param message
     * @param keyReplace
     * @param valueReplace
     * @return {JSX.Element}
     * @private
     */
    _getStringMessageWithStringReplace: (message, keyReplace, valueReplace) => {
        return <GErrorLoginMessage
            message={message}
            stringReplace={{
                key: keyReplace,
                value: valueReplace
            }}
        />
    },

    /**
     * This function return a custom error login message
     *
     * @param message
     * @return {JSX.Element}
     * @private
     */
    _getStringMessage: (message) => {
        return <GErrorLoginMessage
            message={message}
        />
    },

    /**
     * This function return a custom message when user is not registered
     * @param history
     * @return {JSX.Element}
     * @private
     */
    _getCustomMessageUserIsNotRegistered: (history) => {
        return SignInHelper._getStringMessageWithEventClick(USER_IS_NOT_REGISTERED_MESSAGE, USER_IS_NOT_REGISTERED_EVENT_WORD, () => {
            history.push(SIGN_UP_REDIRECT_LINK);
        });
    },

    /**
     * This function return a custom message when user is Unauthorized
     * @param eMail
     * @return {JSX.Element}
     * @private
     */
    _getCustomMessageUserUnauthorized: (eMail) => {
        return SignInHelper._getStringMessageWithStringReplace(USER_UNAUTHORIZED_MESSAGE, USER_UNAUTHORIZED_STRING_REPLACE, eMail);
    },

    /**
     * This function return a custom message when user is Blocked by admin
     * @return {JSX.Element}
     * @private
     */
    _getCustomMessageUserBlockedByAdmin: () => {
        return SignInHelper._getStringMessageWithEventClick(USER_BLOCKED_BY_ADMIN_STRING_REPLACE, USER_BLOCKED_BY_ADMIN_EVENT_WORD, () => {
            IntercomHelper.showBubbleChat();
        });
    },

    /**
     * This function return a custom message when user is Cancelled
     * @return {JSX.Element}
     * @private
     */
    _getCustomMessageUserCancelled: () => {
        return SignInHelper._getStringMessageWithEventClick(USER_CANCELLED_STRING_REPLACE, USER_CANCELLED_EVENT_WORD, () => {
            IntercomHelper.showBubbleChat();
        });
    },

    /**
     * This function return a custom message when user is Cancelled
     * @return {JSX.Element}
     * @private
     */
    _getCustomMessageWrongUserPassword: () => {
        return SignInHelper._getStringMessage(WRONG_USER_PASSWORD);
    },

    checkSignInMessage: (messageInfo = "", eMail, dispatch, history, isLocalStorageHasOtp) => {
        switch (messageInfo) {
            case USER_LOGIN_MESSAGE_CONSTANT.USER_IS_NOT_REGISTERED: {
                return {
                    messageInfo: SignInHelper._getCustomMessageUserIsNotRegistered(history),
                    redirectPage: null
                }
            }
            case USER_LOGIN_MESSAGE_CONSTANT.USER_UNAUTHORIZED: {
                return {
                    messageInfo: SignInHelper._getCustomMessageUserUnauthorized(eMail),
                    redirectPage: null
                }
            }
            case USER_LOGIN_MESSAGE_CONSTANT.USER_BLOCKED_BY_ADMIN: {
                return {
                    messageInfo: SignInHelper._getCustomMessageUserBlockedByAdmin(),
                    redirectPage: null
                }
            }
            case USER_LOGIN_MESSAGE_CONSTANT.USER_CANCELLED_BY_ADMIN: {
                return {
                    messageInfo: SignInHelper._getCustomMessageUserCancelled(),
                    redirectPage: null
                }
            }
            case USER_LOGIN_MESSAGE_CONSTANT.OTP_TOKEN_NOT_PROVIDED: {
                return {
                    messageInfo: "",
                    redirectPage: () => {
                        history.push(Path.VERIFICATION_CODE, "sendCode")
                        dispatch(showGrowlMessage({
                            messageInfo: isLocalStorageHasOtp ? SESSION_EXPIRED_MESSAGE : null,
                            growlStatus: !isLocalStorageHasOtp,
                            code: 400,
                        }))
                        localStorage.removeItem(`otpToken/${eMail}`);
                    }
                }
            }
            case USER_LOGIN_MESSAGE_CONSTANT.USER_TOKEN_EXPIRED: {
                return {
                    messageInfo: "",
                    redirectPage: () => {
                        history.push(Path.VERIFICATION_CODE, "sendCode")
                        dispatch(showGrowlMessage({
                            messageInfo: isLocalStorageHasOtp ? SESSION_EXPIRED_MESSAGE : null,
                            growlStatus: !isLocalStorageHasOtp,
                            code: 400,
                        }))
                        localStorage.removeItem(`otpToken/${eMail}`);
                    }
                }
            }
            case USER_LOGIN_MESSAGE_CONSTANT.WRONG_USER_PASSWORD: {
                return {
                    messageInfo: SignInHelper._getCustomMessageWrongUserPassword(),
                    showStyles: true,
                    redirectPage: null
                }
            }
            case USER_LOGIN_MESSAGE_CONSTANT.SUCCESSFUL_REQUEST: {
                return {
                    messageInfo: "",
                    redirectPage: () => {
                        history.push(Path.WELCOME)
                    }
                }
            }
            default:
                return {
                    messageInfo: "",
                    showStyles: null,
                    redirectPage: null
                }
        }
    },

    /**
     * Verify if stringValue is empty
     * @param stringValue
     * @return {boolean}
     * @private
     */
    _isEmptyString: (stringValue) => {
        return stringValue === "";
    },

    checkEmailFields: (email) => {
        if (SignInHelper._isEmptyString(email)) {
            return EMPTY_EMAIL_MESSAGE;
        }
        if (!SignInHelper._isEmptyString(email) && !checkEmailRegEx(email)) {
            return INVALID_EMAIL_MESSAGE;
        }
        return ""
    },

    checkPasswordFields: (password) => {
        if (SignInHelper._isEmptyString(password)) {
            return EMPTY_PASSWORD_MESSAGE;
        }
        return "";
    }
}
