import React from "react"

interface ISvgSearchIcon {
  width: number;
  height: number;
  color: string;
}

export const SvgSearchIcon = ({ width, height, color }: ISvgSearchIcon) => {
  return (
      <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 19.0039C15.4183 19.0039 19 15.4222 19 11.0039C19 6.58563 15.4183 3.00391 11 3.00391C6.58172 3.00391 3 6.58563 3 11.0039C3 15.4222 6.58172 19.0039 11 19.0039Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M20.9999 21.0023L16.6499 16.6523" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
}
