import cardListAvatar1 from '../../../../assets/image/cardListAvatar1.png'
import cardListAvatar2 from '../../../../assets/image/cardListAvatar2.png'
import cardListAvatar3 from '../../../../assets/image/cardListAvatar3.png'
export const GSubscriptionMockStaticTexts = {
    DEFAULT_LOGO_TEXT: "PREMIUM",
    DEFAULT_TITLE: "Simplify your life <br> with GOV+ Subscription",
    DEFAULT_SUB_TITLE: "Protect and renew your important documents",
    DEFAULT_CAPABILITIES_LIST: [
        "Applications for your family members all in one place",
        "Expedited application processing",
        "Automatic renewal of your documents before they expire",
        "Protection against lost, stolen, and damaged documents",
        "Always be in front of the queue to talk to our experts"
    ],
    INSURANCE_CAPABILITIES_LIST: [
        "Protection against lost, stolen, and damaged documents"
    ],
    RENEWAL_CAPABILITIES_LIST: [
        "Automatic renewal of your documents before they expire"
    ],
    DEFAULT_CARD_LIST: [
        {
            image: cardListAvatar1,
            name: "Steven R.",
            description: "Seems quicker and less painful than waiting at the post office. Gotta love GOV+.",
            totalStarts: 5,
            yellowStarts: 5
        },
        {
            image: cardListAvatar2,
            name: "Claudia F.",
            description: "GOV+ is user-friendly and makes the process seamless. I would certainly recommend GOV+ to family and friends.",
            totalStarts: 5,
            yellowStarts: 5
        },
        {
            image: cardListAvatar3,
            name: "Taifilele T.",
            description: "This is the easiest way I have ever fill out my passport without wasting papers. I love it!",
            totalStarts: 5,
            yellowStarts: 5
        }
    ],
    DEFAULT_SUBSCRIPTION_PLAN: '$14.99/month',
    DEFAULT_SUBSCRIPTION_PLAN_TAG: 'Cancel Anytime',
    DEFAULT_CTA_TEXT: 'Get GOV+ Subscription'
}