const TestUpsellPage = () => {

    return (
        <>
            <h1>Integration Test</h1>
        </>

    )
}

export default TestUpsellPage