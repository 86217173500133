import React from 'react';
import './index.less';
import {Input, Table} from "antd";
import SettingsButton from "../../components/SettingsButton";
import {IconSvg} from "../../components/svgImages/SvgImages";

const ReferFriendPage = () => {
  const headerText = "Help a friend by making their life easier!"
  const descriptionText = "Help a friend by recommending GOV+ and you will both benefit from a $25 credit going towards government fees or GOV+ subscription."
  const referrals = [
    {
      title: 'Your referral',
      dataIndex: 'invitedUserName',
    },
    {
      title: 'Status',
      dataIndex: 'dateWhenInvited',
    }
  ];
  const data = [
    {
      key: '1',
      invitedUserName: 'John Brown',
      dateWhenInvited: "Sent email invite on Jun 23 2022",
    },
    {
      key: '2',
      invitedUserName: 'Jim Green',
      dateWhenInvited: "Sent email invite on Jun 23 2022",
    }
  ];

  const howItWorkSteps = [
    {iconType: "shareLinkIcon", description: "Share your link"},
    {iconType: "signUpStar", description: "Have your friends sign up for GOV+"},
    {iconType: "successSharing", description: "You and your friend will each get a $25 credit"},
  ];

  return (
    <div className={"referFriend_container"}>
      <div className={"referFriend_container-description"}>
        <h1>{headerText}</h1>
        <p>{descriptionText}</p>
      </div>

      <div className={"referFriend_container_body"}>

        <div className={"referFriend_container_body_formBlock"}>
          <div className={"referFriend_container_body_formBlock_inviteHandlers formsBlock"}>
            <div className={"referFriend_container_body_formBlock_inviteHandlers_inviteSettings"}>
              <h3>Invite friends</h3>
              <div className={"referFriend_container_body_formBlock_inviteHandlers_inviteSettings_inviteLinkInput"}>
                <label>Share your personal referral link</label>
                <div className={"formInputsContainer"}>
                  <Input/>
                  <SettingsButton
                    buttonText={"Copy link"}
                    withArrow={true}
                    socialIcon={"copyLink"}
                  />
                </div>
              </div>
              <div className={"referFriend_container_body_formBlock_inviteHandlers_inviteSettings_sendEmail"}>
                <label>Send an email</label>
                <div className={"formInputsContainer"}>
                  <Input/>
                  <SettingsButton
                    buttonText={"Send invites"}
                  />
                </div>
              </div>

              <div className={"referFriend_container_body_formBlock_inviteHandlers_inviteSettings_socialButtons"}>
                <label>Share on social media</label>
                <div className={"socialButtonsContainer"}>
                  <SettingsButton
                    socialIcon={"facebook"}
                    buttonText={<span className={"socialName"}>Facebook</span>}
                    buttonColor={"#1A74E5"}
                  />
                  <SettingsButton
                    socialIcon={"twitter"}
                    buttonText={<span className={"socialName"}>Twitter</span>}
                    buttonColor={"#1D9BF0"}
                  />
                  <SettingsButton
                    socialIcon={"linkedin"}
                    buttonText={<span className={"socialName"}>LinkedIn</span>}
                    buttonColor={"#0B66C2"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={"referFriend_container_body_formBlock_creditsData formsBlock"}>
            <h3>Your GOV+ credits</h3>
            <div className={"referFriend_container_body_formBlock_creditsData-creditsInfo"}>
              <p>Credits</p>
              <p>$125</p>
            </div>
          </div>

          <div className={"referFriend_container_body_formBlock_prevsReferrals formsBlock"}>
            <h3>Your referrals</h3>
            <div className={"referralsTable_container"}>
              <Table
                pagination={false}
                columns={referrals}
                dataSource={data}
              />
            </div>
          </div>
        </div>

        <div className={"referFriend_container_body_howItWork"}>
          <h3>How it works</h3>
          <div className={"stepsContainer"}>
            {howItWorkSteps.map((item, id)=>{
              return <div className={"stepsContainer-item"} key={id}>
                <div className={"stepsContainer-item-icon"}>
                  <IconSvg type={item.iconType}/>
                </div>
                <p>{item.description}</p>
              </div>
            })}
          </div>


        </div>
      </div>
    </div>
  )
}

export default ReferFriendPage;
