import React, {FC} from "react";
import GFormItem from "../../organisms/FormItem/FormItem";
import {
    CreditCardFormFields,
    LabelsColor, LabelsSize
} from "../../templates/CreditCardForm/CreditCardFormFields";
import GText from "../../atoms/Text/Text";
import GInputText from "../InputText/InputText";
import {Rule} from "rc-field-form/lib/interface";
import InputMask from "react-input-mask";

const GExpirationDate: FC<any> = () => {
    const placeholder = "MM/YY";
    const validationRules: Rule[] = [
        {required: true, message: 'Required'},
        {
            validator: (rule, value, callback) => {
                if (!value || value.trim() === "" || value === placeholder) {
                    callback();
                    return;
                }

                const expirationDateArray = value.split("/");
                if (expirationDateArray.length !== 2) {
                    callback("Invalid expiration date format");
                    return;
                }

                if (isNaN(parseInt(expirationDateArray[0])) || parseInt(expirationDateArray[0]) > 12 || parseInt(expirationDateArray[0]) === 0) {
                    callback("Invalid expiration month")
                    return;
                }

                const currentYear = new Date().getFullYear().toString().slice(-2)
                if (isNaN(parseInt(expirationDateArray[1])) || parseInt(expirationDateArray[1]) < parseInt(currentYear)) {
                    callback("Invalid expiration year");
                    return;
                }
                callback();
            }
        }
    ];

    return (

        <GFormItem
            name={CreditCardFormFields.ExpirationDate}
            label={<GText text={'Expiration date'} size={LabelsSize}
                          color={LabelsColor}/>}
            rules={validationRules}
        >
            <InputMask
                mask={"99/99"}
                maskPlaceholder={placeholder}
            >
                <GInputText inputMode={'numeric'} suffix={''}/>
            </InputMask>
        </GFormItem>
    )
}

export default GExpirationDate;