import instructionList1 from "../../../../assets/image/instructionList1.jpg"
import instructionList2 from "../../../../assets/image/instructionList2.jpg"
import instructionList3 from "../../../../assets/image/instructionList3.jpg"
import instructionList4 from "../../../../assets/image/instructionList4.jpg"
import instructionList5 from "../../../../assets/image/instructionList5.jpg"
import instructionList6 from "../../../../assets/image/instructionList6.jpg"
import instructionDescription from "../../../../assets/image/instructionDescription.svg"
import errorValidation from "../../../../assets/image/errorValidation.svg"
import dndPreload from "../../../../assets/image/dndPreload.svg"
import iconLoadingDND from "../../../../assets/image/iconLoadingDND.svg"
import successValidation from "../../../../assets/image/successValidation.svg"
import green_success from "../../../../assets/image/green_success.svg"
import drag_upload from "../../../../assets/image/drag_upload.svg"
import checkStatusIcon from "../../../../assets/image/checkStatusIcon.svg"
import pencilEdit from "../../../../assets/image/pencilEdit.svg"
import successLayoutPrefilled from "../../../../assets/image/success_layout_prefilled.svg"
import ProcessingStepHeaderIcon from "../../../../assets/image/processing_steps_header_icon.svg"
import GovernmentFeesStatus from "../../../../assets/image/government_fees.svg"
import CustomNewError from "../../../../assets/image/alert-icon-red.svg"
export const uploadPhotoInstructionList = [
  {
    src: "instructionList_1",
    svg: instructionList1,
  },
  {
    src: "instructionList_2",
    svg: instructionList2,
  },
  {
    src: "instructionList_3",
    svg: instructionList3,
  },
  {
    src: "instructionList_4",
    svg: instructionList4,
  },
  {
    src: "instructionList_5",
    svg: instructionList5,
  },
  {
    src: "instructionList_6",
    svg: instructionList6,
  },
  {
    src: "green_success",
    svg: green_success,
  },
  {
    src: "check_status_icon",
    svg: checkStatusIcon,
  },
]

export const uploadPhotoInstructionDescription = [
  {
    src: "instruction_description",
    svg: instructionDescription,
  },
]

export const dragAndDropAreaIcons = [
  {
    src: "drag_and_drop_icon",
    svg: dndPreload,
  },
  {
    src: "drag_and_drop_spinner_icon",
    svg: iconLoadingDND,
  },
  {
    src: "success-icon-dnd",
    svg: successValidation,
  },
  {
    src: "drag_and_drag_document_icon",
    svg: drag_upload,
  },
  {
    src: "drag_and_drag_photo_icon",
    svg: drag_upload,
  },
]

export const errorsIcons = [
  {
    src: "format_error_icon_img",
    svg: CustomNewError,
  },

]

export const reviewPageIcons = [
  {
    src: "checkStatusEditIcon",
    svg: pencilEdit,
  },
]

export const mainLayoutIcons = [
  {
    src: "success_layout_prefilled",
    svg: successLayoutPrefilled,
  },
  {
    src: "SsnLogoThankYouPage",
    svg: successLayoutPrefilled,
  },
  {
    src: "processing_steps_header_icon",
    svg: ProcessingStepHeaderIcon,
  },
  {
    src: "status-government-fees",
    svg: GovernmentFeesStatus,
  },
  {
    src: "",
    svg: successLayoutPrefilled,
  },
]
