const excludedServiceNamesFromCapitalization = [
    "EIN",
    "TSA",
    "PreCheck®",
    "PreCheck",
    "IRS",
    "Social",
    "Security",
    "S.S."
]
// correctCapitalization function will capitalize the first letter of each word 
// in a string, except for the words in the lowercaseWords array.

const lowercaseWords = ['of', 'and', 'the', 'in', 'on', 'at', 'for', 'a', 'an'];

const StringHelper = {
    replaceKeyByValue: (str: string, key: string, value: string) => {
        return str.replace(key, value);
    },
    capitalizeServiceName: (service: string) => {
        // Apply proper capitalization to service's name (first letter to lower case)
        let serviceWords = service.split(" ")
        const result = serviceWords.map(word => {
            if (!excludedServiceNamesFromCapitalization.includes(word)) {
                return word.toLowerCase()
            }
            return word
        })
        return result.join(" ")

    },
    capitalizeFirstLetter: (str: string) => {
        const strLowerCase = str.toLowerCase();
        const firstLetter = strLowerCase.charAt(0);
        const firstLetterCap = firstLetter.toUpperCase()
        const remainingLetters = strLowerCase.slice(1)
        return firstLetterCap + remainingLetters
    },
    correctCapitalization: (str: string) => {
        if(str.length < 2) return str;
        const words = str.toLowerCase().split(' ');
        const result = words.map((word, index) => {
            if (index === 0 || !lowercaseWords.includes(word)) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            } else {
                return word;
            }
        });
        return result.join(' ');
    }
}

export default StringHelper;