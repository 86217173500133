/* eslint-disable array-callback-return */
import { connect } from "react-redux"
import React, { useEffect } from "react"
import { getAdditionalArraySteps } from "../../../../../../Redux/store/step/step.actions"

type StepsCoaComponentProps = {
  dispatch: any,
  item: {} | any,
  customer: {} | any,
}

// eslint-disable-next-line max-len
const StepsCoaComponent: React.FunctionComponent<StepsCoaComponentProps> = ({ item, dispatch, customer }) => {
  const getSubscribe = () => {
    dispatch(getAdditionalArraySteps("customer_support"))
  }

  useEffect(() => {
    const wrapperLink = document.querySelector(".address_change__steps-text")

    if (wrapperLink) {
      const link: any = wrapperLink.querySelector("a")
      link &&
        link.addEventListener("click", () => {
          getSubscribe()
        })
      return () =>
        link &&
        link.removeEventListener("click", () => {
          getSubscribe()
        })
    }
  })

  return (
    <>
      <span className={"steps_line"}></span>
      {item.map((value: any, index: number) => (
        <div key={index} className="address_change__steps-block">
          <div className="address_change__steps-index">{index + 1}</div>
          <p
            dangerouslySetInnerHTML={{ __html: value.text.replace("test@test.com", customer && customer.email) }}
            className={"address_change__steps-text"}
          ></p>

          {/* <p className="address_change__steps-text">{value.text}</p> */}
        </div>
      ))}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  customer: state.form.customer,
})

export default connect(mapStateToProps)(StepsCoaComponent)
