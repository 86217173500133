import React, {ReactElement} from "react";
import {ITemplateOneBuilder} from "./ITemplateOneBuilder";
import TemplateOneBuilder from "../TemplateOneBuilder";
import {IUpsellProcessingData} from "../../../StepUpsell";
import TemplateOne from "../TemplateOne";
import {UPSELL_PROPS} from "../../UpsellProps";
import {SolutionNames} from "../../../../../../../../util/SolutionNames";
import {ProductsIds} from "../../../../../../../../util/ProductsIds";
import GUpsellCardSubscriptionCrossSellHeader from "../../../../../../molecules/UpsellCardSubscriptionCrossSellHeader/GUpsellCardSubscriptionCrossSellHeader";
import { SIZE } from "../../../../../../types/types";

export class CrossSellSubscription implements ITemplateOneBuilder {

    buildTemplate(builder: TemplateOneBuilder, data: IUpsellProcessingData, solutionName: string): TemplateOne {
        const {
            offeredService,
        } = this.getExtraData(data.cross_sell_product_offer)

        let pageBuilder = builder
            .setTitle('')
            .setSubtitle(
                data.settings_values[UPSELL_PROPS.UPSELL__SUB_HEADLINE_STOP]
                    .replaceAll(UPSELL_PROPS.SOLUTION_KEY_REPLACE, solutionName)
                    .replaceAll(UPSELL_PROPS.OFFERED_SOLUTION_KEY_REPLACE, offeredService)
            )
            .setSubtitleSize(SIZE.PARAGRAPH_BOLD_24)
            .setAcceptButton(data.settings_values[UPSELL_PROPS.UPSELL__CALL_TO_ACTION_YES])
            .setCancelButton(data.settings_values[UPSELL_PROPS.UPSELL__CALL_TO_ACTION_NO])
            .setComponentHeader(this.getComponentHeader(solutionName, offeredService))
        return pageBuilder.build();
    }

    /**
     * @return ReactElement | undefined
     * @param acquiredService
     * @param offeredService
     * @param discount
     */
    private getComponentHeader(acquiredService: string, offeredService: string): ReactElement {
        return <GUpsellCardSubscriptionCrossSellHeader acquiredService={acquiredService} offeredService={offeredService}/>;
    }

    /**
     * @return string[]
     */
    private getExtraData(offerProduct: string | undefined) {

        /**
         * This is a temporary solution until we have the real data
         */
        switch (offerProduct) {
            case ProductsIds.TsaId:
                return {
                    offeredService: SolutionNames.TSA,
                };
            case ProductsIds.PassportId:
                return {
                    offeredService: SolutionNames.PAS
                }
            default:
                return {
                    discount: '',
                    offeredService: '',
                    subCheckListText: '',
                    previousPrice: '',
                    benefits: []
                }
        }
    }
}