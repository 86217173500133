import React from "react";
import "./Tsa.less";
import {specialStatusConstants} from "../../productsAndStatusesConstants";
import AppointmentSelection from "./AppointmentSelection";
import CompletedNew from "./CompletedNew";
import CompletedReNewExpired from "./CompletedReNewExpired";
import CompletedReNew from "./CompletedReNew";
import NoSuchProductOrStatus from "../../../NoSuchProductOrStatus";

const Tsa = ({statusName, productId, productName}) => {
  const StatusRender = () =>{
    switch (statusName){
      case specialStatusConstants[productName].appointment_selection:{
        return (
          <AppointmentSelection
            productId={productId}
          />
        )
      }
      case specialStatusConstants[productName].completed_new:{
        return (
          <CompletedNew productId={productId}/>
        )
      }
      case specialStatusConstants[productName].completed_renew_expired:{
        return (
          <CompletedReNewExpired productId={productId}/>
        )
      }
      case specialStatusConstants[productName].completed_renew:{
        return (
          <CompletedReNew productId={productId}/>
        )
      }
      default: return (
        <NoSuchProductOrStatus
          description={"Sorry page for this status is not ready yet."}
          appId={productId}
          productName={productName}
          productStatus={statusName}
        />
      )
    }
  }

  return (
    <div className={"TSA-container"}>{StatusRender()}</div>
  )
}

export default Tsa;
