import React from "react";
import {useSubscription} from "../../../../dashboard-govplus-front/src/hooks/useSubscription";
import MyInsuredDocuments from "./MyInsuredDocuemnts/MyInsuredDocuments";
import TeaserPageWrapper from "../Subscription/TeaserPageWrapper";

const InsuranceMainPageWrapper = () => {
    const {insuredDocuments} = useSubscription()

    return (
        <TeaserPageWrapper DocumentsPage={MyInsuredDocuments} documents={insuredDocuments} />
    )

}

export default InsuranceMainPageWrapper