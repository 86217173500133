/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
import { connect } from "react-redux"
import React from "react"
// import { Button } from "antd"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import closeIcon from "../../../../assets/image/close.svg"
import {
  setValueCurrentField
} from "../../../../Redux/store/form/form.actions"



type renderValuesProps = {
    submitObject: {} | any,
  field: FieldInterface,
  setAutocompleteValueShort: any,
  setRender:any,
  setTimeId: any,
  timeId: any,
  dispatch: any,
}


// eslint-disable-next-line max-len
const RenderValuesComponent: React.FunctionComponent<renderValuesProps> = ({
  dispatch,
  submitObject,
  setAutocompleteValueShort,
  setRender,
  setTimeId,
  timeId,
  field
}) => {

  const {services} = submitObject
  const optionsArray:[] | any = services && services[field.optionNew] || []


  const handlerDeleteValue = (value: {} | any) => {
    clearTimeout(timeId)
    const newArray = optionsArray.filter((item:any)=>item.company_name!==value.company_name)
    setAutocompleteValueShort(newArray)
    const services = {...submitObject["services"], [field.optionNew]: newArray}
    // console.log(services)
    dispatch(setValueCurrentField("services",services))
    setRender(false)
    setTimeId(setTimeout(() => setRender(true), 0))
  }

  return (
    <>
      <div className="chage__address_render_values-block">
        {optionsArray.map((item:any) =>
          <div className="chage__address_render_values-item">
            {item.company_name} {item.company_name && <img onClick={() => {handlerDeleteValue(item)}}
              src={closeIcon}
              alt="close"></img>}
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
})

export default connect(mapStateToProps)(RenderValuesComponent)
