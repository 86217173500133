import React, {useEffect, useState} from "react"
import {connect, useSelector} from "react-redux"
import {
    setAddressValidation,
    setNewAddressValidation,
    setStatusGovernmentFeeOpen,
    setStatusSignature,
    setStatusSignatureOpen,
    setStatusTSADetailsToOpen,
} from "../../../../Redux/store/additionalForm/additional.actions"
import yesIcon from "../../../../assets/image/bigYes.svg"
import PassportDetailsComponent from "../PassportDetailsComponent/PassportDetailsComponent"
import SignatureComponent from "../SignatureComponent/SignatureComponent"
import AddressVerificationFormFields
    from "../../AdditionalPageComponent/AdditionalPageByType/AddressVerification/AddressVerificationFormFields"
import AddressVerificationFormSelect
    from "../../AdditionalPageComponent/AdditionalPageByType/AddressVerification/AddressVerificationFormSelect"
import GovernmentFeesFormFields
    from "../../AdditionalPageComponent/AdditionalPageByType/GovermentFees/GovernmentFeesFormFields"
import {RootState} from "../../../../App"
import {additionalSelector} from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import {delayTimer} from "../../../../Pages/FormPage/FormPage"
import {getScrollElementById} from "../../FormBlock/FormBlock"
import GAdditionalStep, {StepVisualStatus} from "../../../atomicDesign/templates/AdditionalStep/AdditionalStep"
import GStepServiceProviderSelection
    from "../../../atomicDesign/templates/AdditionalStep/StepServiceProviderSelection/StepServiceProviderSelection"
import FormIdRepository from "../../../../persistence/session/FormIdRepository";
import GAdditionalStepUpsell from "../../../atomicDesign/templates/AdditionalStep/AdditionalStepUpsell";
import {useApplications} from "../../../../../../dashboard-govplus-front/src/hooks/useApplications"
import GCOAStatusTracking
    from "../../../atomicDesign/templates/AdditionalStep/StepStatusTracking/COA/COAStatusTracking";
import {ProcessingStatuses, replaceSolution} from "../../../../util/ProcessingTrackingHelper";
import {SolutionNames} from "../../../../util/SolutionNames";
import {getIdNameFromStatuses} from "../../../../util/BlockStep";
import { BlockStepTypes } from "../../../../Constants/BlockStepTypes"

type BlocksStepsCoaProps = {
    blocksStep: any,
    index: number,
    showStatus: string,
    statusAdditionalPage: string,
    statusDetailsToOpen: string,
    statusGovernmentFeeOpen: string,
    statusServices: string,
    statusDetails: string,
    statusSignature: string,
    statusQuestionary: string,
    statusQuestionnaireFront: string,
    submitObject: {} | any,
    currentResponse: any,
    loadingButtonState: string,
    appId: string,
    formId: string,
    statusDocuments: string,
    customer: {} | any,
    dispatch: any,
    statusSignatureBlockOpen: string,
    statusAddress: string,
    statusNewAddress: string,
    setStripeElements: any,
    upsellProcessingInformation: any,
    application_id: string
}

export const STATUS_INIT = "init"
export const STATUS_PROCESS = "process"

const BlocksStepsCoa: React.FunctionComponent<BlocksStepsCoaProps> = ({
                                                                          blocksStep,
                                                                          index,
                                                                          showStatus,
                                                                          statusAdditionalPage,
                                                                          statusDetailsToOpen,
                                                                          statusServices,
                                                                          statusDetails,
                                                                          statusSignature,
                                                                          statusQuestionary,
                                                                          statusQuestionnaireFront,
                                                                          submitObject,
                                                                          currentResponse,
                                                                          loadingButtonState,
                                                                          appId,
                                                                          formId,
                                                                          statusDocuments,
                                                                          customer,
                                                                          dispatch,
                                                                          statusSignatureBlockOpen,
                                                                          statusGovernmentFeeOpen,
                                                                          statusAddress,
                                                                          statusNewAddress,
                                                                          setStripeElements,
                                                                          upsellProcessingInformation, application_id
                                                                      }) => {
    const originData =
        currentResponse &&
        currentResponse.verifiedAddress &&
        currentResponse.verifiedAddress.original &&
        currentResponse.verifiedAddress.original
    const name = (originData && `${originData.name.split(" ")[0]}`) || (customer && customer.first_name)

    const [stepServiceNotificationStatus, setStepServiceNotificationStatus] = useState(STATUS_INIT)

    const form_id = FormIdRepository.getInstance().getValue();

    const handlerSignature = (status: string) => {
        dispatch(setStatusSignature(status))
        dispatch(setStatusSignatureOpen(status))
    }

    const handlerAddressValidation = (status: string) => {
        dispatch(setAddressValidation(status))
    }
    const handlerNewAddressValidation = (status: string) => {
        dispatch(setNewAddressValidation(status))
    }

    const handlerDetailsToOpen = (status: string) => {
        dispatch(setStatusTSADetailsToOpen(status))
    }

    const handlerGovernmentFeeOpen = (status: string) => {
        dispatch(setStatusGovernmentFeeOpen(status))
    }

    const getAddressForms = () => {
        if (currentResponse?.verifiedAddress?.error) {
            return (
                <AddressVerificationFormFields
                    forceLoadingAndDisabled={true}
                    blocksStep={blocksStep}
                    statusSignaturePage={statusSignature}
                />
            )
        } else {
            return <AddressVerificationFormSelect addressContent={blocksStep.content}/>
        }
    }

    const {statusSignaturePage} = useSelector<RootState, { statusSignaturePage: string } | undefined>(
        additionalSelector
    ) ?? {statusSignaturePage: ""}

    useEffect(() => {
        delayTimer(300).then(() => getScrollElementById({id: `${statusSignaturePage}_scrollById`}))
    }, [statusSignaturePage])

    const uniqSession = submitObject["unique-section-id"]

    let idNameFromStatuses = getIdNameFromStatuses(blocksStep.status)

    return (
        <div className={`steps-signature-container`} id={`${idNameFromStatuses}${index !== 1 ? "_scrollById" : ""}`}>
            {blocksStep.type === BlockStepTypes.CONTENT && (
                <div className={`status_complete`}>
                    <div className={`icon-container_complete`}>
                        <img src={yesIcon} alt="approve"/>
                    </div>
                    <div className={`div-bg`}>
                        <p>{blocksStep.content.complete}</p>
                    </div>
                </div>
            )}

            {blocksStep.type === BlockStepTypes.INVALID_OLD_ADDRESS && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusAddress}
                    ctaForm={() => handlerAddressValidation(StepVisualStatus.STATUS_PROCESS)}
                >
                    {getAddressForms()}
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.INVALID_NEW_ADDRESS && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusNewAddress}
                    ctaForm={() => handlerNewAddressValidation(StepVisualStatus.STATUS_PROCESS)}
                >
                    {getAddressForms()}
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.UPSELL && upsellProcessingInformation && upsellProcessingInformation[blocksStep.status] && (
                <GAdditionalStepUpsell
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    formId={formId}
                    uniqSession={uniqSession}
                    blockStatus={blocksStep.status}
                />
            )}

            {blocksStep.type === BlockStepTypes.SERVICE_PROVIDERS && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={stepServiceNotificationStatus}
                    ctaForm={() => setStepServiceNotificationStatus(StepVisualStatus.STATUS_PROCESS)}
                >
                    <GStepServiceProviderSelection categories={blocksStep.content.categories}/>
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.ADDITIONAL_INFORMATION_STEP && (
                <GAdditionalStep
                    title={blocksStep.title}
                    titleDone={blocksStep.titleDone}
                    information={blocksStep.information}
                    ctaText={blocksStep.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusDetailsToOpen}
                    ctaForm={() => handlerDetailsToOpen(StepVisualStatus.STATUS_PROCESS)}
                >
                    <PassportDetailsComponent
                        forceLoadingAndDisabled={true}
                        blocksStep={form_id === "address-change-individual" ? blocksStep.individual : blocksStep.business}
                        statusSignaturePage={""}
                    />
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.SIGNATURE && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    index={index.toString()}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusSignatureBlockOpen}
                    ctaForm={() => handlerSignature(StepVisualStatus.STATUS_PROCESS)}
                >
                    <SignatureComponent nestBlock={blocksStep.content.nestBlock}/>
                </GAdditionalStep>
            )}

            {blocksStep.type === BlockStepTypes.GOVERNMENT_FEES && (
                <GAdditionalStep
                    title={blocksStep.content.title}
                    titleDone={blocksStep.content.titleDone}
                    stepShowStatus={showStatus}
                    stepVisualStatus={statusGovernmentFeeOpen}
                    index={index.toString()}
                    information={blocksStep.content.information}
                    ctaText={blocksStep.content.button}
                    ctaForm={() => handlerGovernmentFeeOpen(StepVisualStatus.STATUS_PROCESS)}
                >
                    <GovernmentFeesFormFields blocksStep={blocksStep} setStripeElements={setStripeElements}
                                              index={index}/>
                </GAdditionalStep>
            )}

            {(blocksStep.type === ProcessingStatuses.processing_validation
                || blocksStep.type === ProcessingStatuses.completed) && (
                    <GAdditionalStep
                        title={replaceSolution(blocksStep?.content?.title, SolutionNames.COA)}
                        titleDone={replaceSolution(blocksStep?.content?.titleDone, SolutionNames.COA)}
                        information={replaceSolution(blocksStep?.content?.information, SolutionNames.COA)}
                        index={index.toString()}
                        stepShowStatus={showStatus}
                    >
                        <GCOAStatusTracking application_id={application_id} showFooter={blocksStep.type !== ProcessingStatuses.completed}/>
                    </GAdditionalStep>
                )}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    statusSignature: state.additional.statusSignature,
    statusAddress: state.additional.statusAddress,
    statusNewAddress: state.additional.statusNewAddress,
    statusAdditionalPage: state.additional.statusSignaturePage,
    statusServices: state.additional.statusServices,
    statusDetails: state.additional.statusDetails,
    statusQuestionary: state.additional.statusQuestionary,
    statusQuestionnaireFront: state.additional.statusQuestionnaireFront,
    submitObject: state.form.submitObject,
    currentResponse: state.step.currentResponse,
    loadingButtonState: state.loading.loadingButtonState,
    application_id: state.form.application_id,
    appId: state.form.appId,
    formId: state.form.formId,
    missingInformationStatus: state.form.missingInformationStatus,
    statusDocuments: state.additional.statusDocuments,
    customer: state.form.customer,
    statusSignatureBlockOpen: state.additional.statusSignatureBlockOpen,
    statusDetailsToOpen: state.additional.statusDetailsToOpen,
    statusGovernmentFeeOpen: state.additional.statusGovernmentFeeOpen,
    upsellProcessingInformation: state.additional.upsellProcessingInformation,
})

export default connect(mapStateToProps)(BlocksStepsCoa)
