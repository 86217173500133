import React from "react"
import "./IdentityTheftProtectionAgreement.scss"
import { SIZE } from "../../../Components/atomicDesign/types/types"
import { COLORS_NEW } from "../../../Components/atomicDesign/types/Colors"
import GText from "../../../Components/atomicDesign/atoms/Text/Text"
import GButton from "../../../Components/atomicDesign/molecules/Button/GButton"
import { useHistory } from "react-router-dom"

const PageHeader = () => {
  return (
    <div className={"IdentityTheftProtectionAgreement__Header"}>
      <GText text={"Million Dollar Identity Theft"} size={SIZE.PARAGRAPH_BOLD_40} color={COLORS_NEW.BLACK_1000} />
      <GText text={"Protection Policy"} size={SIZE.PARAGRAPH_BOLD_40} color={COLORS_NEW.BLACK_1000} />
    </div>
  )
}

const MasterPolicy = () => {
  return (
    <div className={"IdentityTheftProtectionAgreement__Content__Top"}>
      <GText text={"Master Policy#: 7081576-109-39601"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_500} />
    </div>
  )
}

const SummaryDescription = () => {
  return (
    <section>
      <GText
        text={"Summary Description of Benefits for the Personal Internet & Identity Coverage Master Policy"}
        size={SIZE.PARAGRAPH_BOLD_20}
        color={COLORS_NEW.BLACK_1000}
      />
      <GText
        text={
          "This Summary is provided to inform you that as a member of Enfortra you are entitled benefits under the Master Policy referenced below. This Summary Description of Benefits does not state all the terms, conditions, and exclusions of the Policy. Your benefits will be subject to all of the terms, conditions, and exclusions of the Master Policy, even if they are not mentioned in this Summary. A complete copy of the Policy will be provided upon request."
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
    </section>
  )
}

const GeneralInformation = () => {
  return (
    <section>
      <GText text={"General Information"} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} />
      <GText
        text={
          "Should you have any questions regarding the Membership Program provided by the Master Policyholder, or wish to view a complete copy of the Master Policy, please call the customer service number located in your membership materials."
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
    </section>
  )
}

const LimitOfInsurance = () => {
  return (
    <section>
      <GText text={"Limit Of Insurance"} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} />
      <GText
        text={"Aggregate Limit of Insurance: $ 1,000,000 per policy period"}
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <GText
        text={"Lost Wages: $ 1000 per week, for 5 weeks maximum"}
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <GText text={"Deductible: $ 0 per policy period"} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
    </section>
  )
}

const Benefits = () => {
  return (
    <section>
      <GText text={"Benefits"} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} />
      <GText text={"a) Costs"} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
      <ol type="i">
        <li>
          <GText
            text={
              "Reasonable and necessary costs incurred by you in the United States for re-filing applications for loans, grants or other credit instruments that are rejected solely as a result of a Stolen Identity Event;"
            }
            size={SIZE.PARAGRAPH_REGULAR_16}
            color={COLORS_NEW.BLACK_800}
          />
        </li>
        <li>
          <GText
            text={
              "Reasonable and necessary costs incurred by you in the United States for re-filing applications for loans, grants or other credit instruments that are rejected solely as a result of a Stolen Identity Event;"
            }
            size={SIZE.PARAGRAPH_REGULAR_16}
            color={COLORS_NEW.BLACK_800}
          />
        </li>
        <li>
          <GText
            text={
              "Reasonable and necessary costs incurred by the insured for up to six credit reports from established credit bureaus (with no more than two reports from any one credit bureau) dated within 12 months after your discovery of a Stolen Identity Event."
            }
            size={SIZE.PARAGRAPH_REGULAR_16}
            color={COLORS_NEW.BLACK_800}
          />
        </li>
      </ol>

      <GText text={"b) Lost Wages"} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
      <GText
        text={
          "Actual lost wages earned in the United States, whether partial or whole days, for time taken off work and away from your work premises solely as a result of your efforts to amend or rectify records as to your true name or identity as a result of a stolen identity event. Actual lost wages includes remuneration for vacation days, discretionary days, floating holidays, and paid personal days and excludes business interruption or future earnings of a self-employed professional. Computation of lost wages for self-employed professionals must be supported by and will be based on prior year tax returns. Coverage is limited to wages lost 12 months after the insured's discovery of a stolen identity event."
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <GText
        text={"c) Legal defense fees and expenses"}
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <GText
        text={
          "Reasonable and necessary fees and expenses incurred in the United States by you with our consent for an attorney appointed by us for:"
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <ol type="i">
        <li>
          <GText
            text={
              "Defending any civil suit brought against you by a creditor or collection agency or entity acting on behalf of a creditor for non-payment of goods or services or default on a loan as a result of a Stolen Identity Event; and"
            }
            size={SIZE.PARAGRAPH_REGULAR_16}
            color={COLORS_NEW.BLACK_800}
          />
        </li>
        <li>
          <GText
            text={
              "Removing any civil judgment wrongfully entered against you as a result of the Stolen Identity Event."
            }
            size={SIZE.PARAGRAPH_REGULAR_16}
            color={COLORS_NEW.BLACK_800}
          />
        </li>
        <li>
          <GText
            text={
              "Criminal defense for charges brought against you as a result of the Stolen Identity Event. However, we will only pay for this after it has been established by acquittal or dropping of charges because you were not in fact the perpetrator."
            }
            size={SIZE.PARAGRAPH_REGULAR_16}
            color={COLORS_NEW.BLACK_800}
          />
        </li>
      </ol>
      <GText
        text={"d) Unauthorized Electronic Fund Transfer Reimbursement"}
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <GText
        text={
          "The principal amount, exclusive of interest and fees, incurred by you and caused by an Unauthorized Electronic Fund Transfer first occurring during the policy period for which you have sought reimbursement from the financial institution which issued the access device and holds the account from which funds were stolen, and for which you have not received reimbursement from any source."
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <GText
        text={
          "A Stolen Identity Event is the theft of your personal identification, social security number, or other method of identifying you, which has or could reasonably result in the wrongful use of such information, including but not limited to Stolen Identity Events occurring on or arising out of your use of the Internet. A Stolen Identity Event does not include the theft or unauthorized or illegal use of your business name, d/b/a or any other method of identifying your business activity."
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <GText
        text={
          "An Unauthorized Electronic Fund Transfer is an electronic fund transfer from your personal deposit account initiated by a person other than you without the actual authority to initiate such transfer and from which you receive no benefit. An Unauthorized Electronic Fund Transfer does not include an electronic fund transfer initiated: 1) by a person who was furnished the access device to your account by you, unless you have notified the financial institution that transfers by such person are no longer authorized; 2) with fraudulent intent by you or any person acting in concert with you; 3) by the financial institution of its employee; or 4) from any business or commercial account."
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
    </section>
  )
}

const CoverageScope = () => {
  return (
    <section>
      <GText text={"Coverage Scope"} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} />
      <GText
        text={
          "The Master Policy provides benefits to you only if you report a Stolen Identity Event or an Unauthorized Electronic Fund Transfer to us at the contact number stated above as soon as you become aware of a Stolen Identity Event or Unauthorized Electronic Fund Transfer, but in no event later than 90 days after the Stolen Identity Event or Unauthorized Electronic Fund Transfer is discovered by you. In addition, you must follow the instructions given to you in a claims kits that you will be provided. These instructions will include notifying major credit bureaus, the Federal Trade Commission's Identity Theft Hotline and appropriate law enforcement authorities. This claims kit will also instruct you how to file for benefits under the policy if the Stolen Identity Event or an Unauthorized Electronic Fund Transfer results in losses covered under the Master Policy."
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <GText
        text={
          "You will only be covered if (1) you report the Stolen Identity Event or an Unauthorized Electronic Fund Transfer to us within 90 days of discovery, and (2) you first discover the Stolen Identity Event or Unauthorized Electronic Fund Transfer while you are enrolled in a Membership Program. You will not be covered if the Stolen Identity Event or Unauthorized Electronic Fund Transfer first occurs after termination of the Master Policy or termination of your membership in the Membership Program."
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
    </section>
  )
}

const LimitsOfInsurance = () => {
  return (
    <section>
      <GText text={"Limits of Insurance"} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} />
      <GText
        text={
          "Our identity theft insurance is underwritten by insurance company subsidiaries or affiliates of American International Group, Inc. (AIG). The description herein is a summary and intended for informational purposes only and does not include all terms, conditions and exclusions of the policies described. Please refer to the actual policies for terms, conditions, and exclusions of coverage. Coverage may not be available in all jurisdictions."
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <GText
        text={
          "The most we shall pay you are the Limits of Insurance shown above. All Legal Costs shall be part of and subject to the Aggregate Limit of Insurance. LEGAL COSTS ARE PART OF, AND NOT IN ADDITION TO, THE LIMIT OF INSURANCE."
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <GText
        text={
          "The most we shall pay you are the Limits of Insurance shown above. All Legal Costs shall be part of and subject to the Aggregate Limit of Insurance. LEGAL COSTS ARE PART OF, AND NOT IN ADDITION TO, THE LIMIT OF INSURANCE."
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <GText
        text={
          "The Lost Wages Limit of Insurance shown above is a sublimit of the Aggregate Limit of Insurance and is the most we shall pay you for lost wages."
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <GText text={"Deductible"} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
      <ol>
        <li>
          <GText
            text={
              "You shall be responsible for the applicable Deductible amount shown above and you may not insure against it."
            }
            size={SIZE.PARAGRAPH_REGULAR_16}
            color={COLORS_NEW.BLACK_800}
          />
        </li>
        <li>
          <GText
            text={"You shall be responsible for only one Deductible during any one policy period."}
            size={SIZE.PARAGRAPH_REGULAR_16}
            color={COLORS_NEW.BLACK_800}
          />
        </li>
      </ol>
    </section>
  )
}

const DuplicateCoverages = () => {
  return (
    <section>
      <GText text={"DUPLICATE COVERAGES"} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} />
      <GText
        text={
          "Should you be enrolled in more than one Membership Program insured by us, or any of our affiliates, we will reimburse you under each Membership Program:"
        }
        size={SIZE.PARAGRAPH_REGULAR_16}
        color={COLORS_NEW.BLACK_800}
      />
      <ol style={{ listStyleType: "lower-alpha", paddingLeft: "20px" }}>
        <li>
          <GText
            text={"subject to the applicable deductibles and limits of liability of each insured Membership Program"}
            color={COLORS_NEW.BLACK_800}
            size={SIZE.PARAGRAPH_REGULAR_16}
          />
        </li>
        <li>
          <GText
            text={
              "but in no event shall the total amount reimbursed to you under all Membership Programs exceed the actual amount of loss."
            }
            color={COLORS_NEW.BLACK_800}
            size={SIZE.PARAGRAPH_REGULAR_16}
          />
        </li>
        <li>
          <GText
            text={
              " in no event shall the Limit of Insurance under all Membership Programs exceed the largest Limit of Insurance available to you under any Membership Program provided by us."
            }
            color={COLORS_NEW.BLACK_800}
            size={SIZE.PARAGRAPH_REGULAR_16}
          />
        </li>
      </ol>
    </section>
  )
}

const ActionsBlock = () => {
  const history = useHistory()

  return (
    <div className={"IdentityTheftProtectionAgreement__Actions"}>
      <GButton
        textSize={SIZE.PARAGRAPH_BOLD_16}
        text={"Go back"}
        showIcon={false}
        click={() => {
          history.push("/myIdentity")
        }}
      />
    </div>
  )
}

const IdentityTheftProtectionAgreement = () => {
  return (
    <div className={"IdentityTheftProtectionAgreement"}>
      <PageHeader />
      <div className={"IdentityTheftProtectionAgreement__Content"}>
        <MasterPolicy />
        <SummaryDescription />
        <GeneralInformation />
        <LimitOfInsurance />
        <Benefits />
        <CoverageScope />
        <LimitsOfInsurance />
        <DuplicateCoverages />
        <ActionsBlock />
      </div>
    </div>
  )
}

export default IdentityTheftProtectionAgreement
