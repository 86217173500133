/* eslint-disable indent */
import React from "react"
import { connect } from "react-redux"
import { setInitialHelper } from "../../../Redux/store/form/form.actions"
import ClientDataComponent from "../PostSubmitComponents/ClientDataComponent/ClientDataComponent"
import { useHistory } from "react-router-dom"
import FormHelperMainSteps from "./FormHelperMainSteps/FormHelperMainSteps"
import FormHelperAdditionalSteps from "./FormHelperAdditionalSteps/FormHelperAdditionalSteps"
import LiveChatComponent from "../PostSubmitComponents/LiveChatComponent/LiveChatComponent"

type FormHelperProps = {
  currentField: any,
  currentStep: {} | any,
  item: any,
  clientId: any,
  statusSignature: string,
  indexCurrentStep: number,
  dispatch: any,
}

const FormHelper: React.FunctionComponent<FormHelperProps> = ({
  currentField,
  currentStep,
  item,
  clientId,
  statusSignature,
  indexCurrentStep,
  dispatch,
}) => {
  let history = useHistory()

  const helperContent =
    typeof item !== "object"
      ? currentStep.stepContent.blocksOnFormPage[0].blockOnFormPage.formsInBlockOnFormPage[1]
      : item

  const arrAdditionalText = helperContent?.blockOnFormPage?.content?.description
    ? Object.values(helperContent.blockOnFormPage.content.description)
    : []

  const handlerAnyClickButton = (nextIndex: number) => {
    dispatch(setInitialHelper())
  }

  const handleIncrementClick = () => {
    window.scrollTo(0, 0)
    const params = new URLSearchParams(window.location.search).toString().split("&indexCurrentStep")[0]

    history.push(`?${params}&indexCurrentStep=${indexCurrentStep + 1}`)
    handlerAnyClickButton(indexCurrentStep + 1)
  }

  const helperOn = currentStep?.helperOn === true
  const packageOnly = helperContent?.blockOnFormPage?.type === "package-helper"

  const customerSupport = helperContent?.blockOnFormPage?.type === "customer-support-helper"
  return (
    <>
      {/* for customer-support whit live chat */}
      {currentStep.name === "customer-support" && <LiveChatComponent />}
      {/* for main pages */}
      {!packageOnly && helperOn && helperContent.blockOnFormPage && helperContent.blockOnFormPage.type === "helper" && (
        <FormHelperMainSteps currentField={currentField} helperContent={helperContent} />
      )}

      {/* for additional pages */}
      {!packageOnly &&
        helperOn &&
        helperContent.blockOnFormPage &&
        helperContent.blockOnFormPage.type === "additional-helper" && (
          <FormHelperAdditionalSteps
            clientId={clientId}
            statusSignature={statusSignature}
            indexCurrentStep={indexCurrentStep}
            handleIncrementClick={handleIncrementClick}
            helperContent={helperContent}
            arrAdditionalText={arrAdditionalText}
          />
        )}

      {/* for package steps */}
      {(packageOnly || customerSupport) && <ClientDataComponent item={helperContent} clientId={clientId} />}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  currentField: state.form.helper,
  currentStep: state.step.currentStep,
  clientId: state.form.clientId,
  statusSignature: state.additional.statusSignature,
  indexCurrentStep: state.step.indexCurrentStep,
})

export default connect(mapStateToProps)(FormHelper)
