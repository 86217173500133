import ProductPage from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/ProductPage/ProductPage";
import {ICONS_SVG_TYPE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/IconsSvg/IconsSvgTypes";
import {COLORS, COLORS_NEW} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";
import {GeneralText, SubscriptionText} from "@dash/gov-plus__front-end__form/src/shared/text/Text";

import React from "react";
import {useApplications} from "../../hooks/useApplications";
import Spinner from "../../components/spiner/Spinner";
import GMyApplicationCard
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/MyApplicationCard/MyApplicationCard";
import {useSelector} from "react-redux";

import "../../styles/pages/products-page.less"
import GButton from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Button/GButton";
import GText from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Text/Text";
import {SIZE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";

import "./SubscriptionCancelApplications.scss"
import {useNavigation} from "../../hooks/useNavigation";
import {useSubscription} from "../../hooks/useSubscription";
import {useHelperSubscription} from "../../hooks/useHelperSubscription";
import { useIdentityProtection } from "../../hooks/useIdentityProtection";

const SubscriptionCancelApplications = () => {

    const {filteredProducts} = useApplications()
    const {identities} = useIdentityProtection()
    const {navigate} = useNavigation()
    const { insuredDocuments, renewedDocuments, subscription } = useSubscription()

    const {triggerSubscriptionCancellationConfirmedAnalyticEvent} = useHelperSubscription()

    const {userData} = useSelector(({auth}) => auth);
    const {allProducts, allProductsLoader, anotherGovProducts, webFlowData} = useSelector(({allProducts}) => allProducts);

    const letterSpacingText = {
        letterSpacing: "-0.1px",
        cursor: "pointer"
    }

    const proceedCancellation = () => {
        triggerSubscriptionCancellationConfirmedAnalyticEvent(subscription.id)

        // @ts-ignore
        if (insuredDocuments.filter(document => document.insured).length > 0) {
            navigate("/SubscriptionCancelInsurance")
        }
        // @ts-ignore
        else if (renewedDocuments.filter(document => document.automaticRenewalDate).length > 0) {
            navigate("/SubscriptionCancelRenewal")
        } 
        else if (identities?.records.filter(record => record.isPresent).length > 0){
            navigate("/SubscriptionCancelIdentityProtection")
        }
        else {
            navigate("/SubscriptionCancelReason")
        }
    }
    const keepSubscription = () => {
        navigate("./billing-settings")
    }

    const listComponent = () => {
        return <div className="cards-container_services-box GSubscriptionCancel__Content">
            {allProductsLoader ? <div style={{height: '20vh', paddingTop: 40}}><Spinner/></div> :
            Object.keys(filteredProducts).map((id, index) => {
                return (
                    <GMyApplicationCard
                        date={filteredProducts[id].date_of_apply || filteredProducts[id].dateofapply}
                        title={filteredProducts[id].product_name}
                        imageType={filteredProducts[id].product_name}
                        productId={id}
                        user={userData}
                        allProducts ={allProducts}
                        anotherGovProducts ={anotherGovProducts}
                        webFlowData ={webFlowData}
                        completedPercentage={filteredProducts[id]?.completion}
                        continueApplication={false}
                    />
                )
            })}
        </div>
    }

    const footer = () => {
        return <>
            <div className={"GSubscriptionCancel__Footer"}>
                <GButton
                    text={GeneralText.KeepPremium}
                    click={keepSubscription}
                    showIcon={false}
                />
                <GText
                    text={SubscriptionText.CancelSubscriptionApplicationsInterrupted}
                    innerHtml={SubscriptionText.CancelSubscriptionApplicationsInterrupted}
                    size={SIZE.PARAGRAPH_REGULAR_16}
                    color={COLORS_NEW.ROYAL_BLUE_700}
                    style={letterSpacingText}
                    onClick={proceedCancellation}
                    maxWidth={"279px"}
                />
            </div>
        </>
    }

    return (
        <ProductPage
            List={listComponent}
            headerText={SubscriptionText.CancelSubscriptionApplications}
            subHeaderText={SubscriptionText.CancelSubscriptionApplicationsSubHeader}
            Footer={footer}
            subHeaderWidth="810px"
        />
    )
}

export default SubscriptionCancelApplications