import {
  GET_OFFERS,
  GET_OFFERS_SUCCSSES
} from "../../constants/ActionTypes";

export const getOffers = () => {
  return {
    type: GET_OFFERS,
  };
};

export const getOffersSuccsses = (offers) => {
  return {
    type: GET_OFFERS_SUCCSSES,
    payload: offers
  };
};
