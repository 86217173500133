// @ts-ignore
import { SubscriptionTrialOptions } from "../../../../../../../../dashboard-govplus-front/src/constants/SubscriptionTrialOptions";
import {PaymentRequestToken} from "../../../../types/types";

export interface ISubmitObject {
    form_id: string,
    funnel_id: number | undefined,
    page_id: number | undefined,
    action: string | undefined,
    page_iterator: number | undefined,
    product_handler: string | undefined
    uniq_session: string | undefined
    payment_method?: string;
    expiration_date?: string;
    card_no?: string
    ssc?: string;
    zip_pay?: string,
    period?: string,
    trial?: string,
    paymentRequestEvent?: PaymentRequestToken,
    subscriptionCouponEnabled?: boolean,
    subscriptionCouponId?: string | null
}


export default class UpsellSubmit {
    private readonly _formId: string;
    private readonly _upsellFunnelId: number | undefined;
    private readonly _upsellPageId: number | undefined;
    private readonly _action: string | undefined;
    private readonly _iterator: number | undefined;
    private readonly _handler: string | undefined;
    private readonly _uniqSession: string | undefined;
    private readonly _paymentMethod: string | undefined;
    private readonly _expirationDate: string | undefined;
    private readonly _cardNo: string | undefined;
    private readonly _ssc: string | undefined;
    private readonly _zipPay: string | undefined;
    private readonly _period: string | undefined;
    private readonly _paymentRequestEvent: PaymentRequestToken | undefined;


    constructor(
        formId: string,
        upsellFunnelId: number | undefined,
        upsellPageId: number | undefined,
        action: string | undefined,
        iterator: number | undefined,
        handler: string | undefined,
        uniqSession: string | undefined,
        paymentMethod: string | undefined,
        expirationDate: string | undefined,
        cardNo: string | undefined,
        ssc: string | undefined,
        zipPay: string | undefined,
        period: string | undefined,
        paymentRequestEvent: PaymentRequestToken | undefined
    ) {
        this._formId = formId;
        this._upsellFunnelId = upsellFunnelId;
        this._upsellPageId = upsellPageId;
        this._action = action;
        this._iterator = iterator;
        this._handler = handler;
        this._uniqSession = uniqSession;
        this._paymentMethod = paymentMethod;
        this._expirationDate = expirationDate;
        this._cardNo = cardNo;
        this._ssc = ssc;
        this._zipPay = zipPay;
        this._period = period;
        this._paymentRequestEvent= paymentRequestEvent;
    }

    getSubmitObject(): ISubmitObject {
        return {
            form_id: this._formId,
            funnel_id: this._upsellFunnelId,
            page_id: this._upsellPageId,
            action: this._action,
            page_iterator: this._iterator,
            product_handler: this._handler,
            uniq_session: this._uniqSession,
            payment_method: this._paymentMethod,
            ssc: this._ssc,
            card_no: this._cardNo,
            expiration_date: this._expirationDate,
            zip_pay: this._zipPay,
            period: this._period,
            trial: SubscriptionTrialOptions.MINIMUM,
            paymentRequestEvent: this._paymentRequestEvent
        }
    }
}