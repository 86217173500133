import React from "react"
import { connect } from "react-redux"
import { Radio } from "antd"
import GRadioButton from "../../../../../atomicDesign/molecules/RadioButton/RadioButton";

type RadioCircleBillingAddressComponentProps = {
  option: {} | any,
  billingAddress: {} | any,
}

// eslint-disable-next-line max-len
const RadioCircleBillingAddressComponent: React.FunctionComponent<RadioCircleBillingAddressComponentProps> = ({
  option,
  billingAddress,
}) => {
  const addressObj =
    billingAddress && billingAddress[option.optionName] && billingAddress[option.optionName].address_verified
  const nameOption =
    addressObj &&
    // eslint-disable-next-line max-len
    `${addressObj.address1}, ${addressObj.city}, ${addressObj.state}, ${addressObj.zip_code}`

  const arrForSubmit = addressObj && [
    { name: "adress_pay", value: addressObj.address1 },
    { name: "adress_pay_2", value: addressObj.address2 },
    { name: "city_pay", value: addressObj.city },
    { name: "state_pay", value: addressObj.state },
    { name: "zip_pay", value: addressObj.zip_code },
  ]

  return (
    <>
      <GRadioButton value={arrForSubmit || option.optionName} label={nameOption || option.label || option.optionName} />
    </>
  )
}

const mapStateToProps = (state: any) => ({
  billingAddress: state.form.billingAddress,
})

export default connect(mapStateToProps)(RadioCircleBillingAddressComponent)
