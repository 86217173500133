/* eslint-disable react-redux/useSelector-prefer-selectors */
import React, { FC, useCallback, useEffect, useState } from "react"
import "./AddRecordModal.scss"
import GText from "../../atoms/Text/Text"
import { useDispatch, useSelector } from "react-redux"
import {
  RecordContentProps,
  RecordType,
  canAddMoreRecords,
  checkDataLength,
  formatPhoneNumber,
  getDashedButtonLabel,
  getMaxRecordCount,
  getModalBottomCopy,
  getModalTitle,
  getModalTopCopy,
  getModalTopQuestion,
  getStateFullName,
  insertHyphens,
} from "../../../../util/IdentityProtectionHelper"
import { SIZE } from "../../types/types"
import { COLORS_NEW } from "../../types/Colors"
import GButton, { BUTTON_TYPE } from "../../molecules/Button/GButton"
import { AddRecordModalProps } from "./AddRecordModal.props"
import GModal from "../GModal/GModal"
import GRecordCount from "../RecordCount/RecordCount"
import BankAccountDetailsForm from "../InputDetailsForms/BankAccountDetailsForm/BankAccountDetailsForm"
import CreditCardDetailsForm from "../InputDetailsForms/CreditCardDetailsForm/CreditCardDetailsForm"
import DriverLicenseDetailsForm from "../InputDetailsForms/DriverLicenseDetailsForm/DriverLicenseDetailsForm"
import EmailDetailsForm from "../InputDetailsForms/EmailDetailsForm/EmailDetailsForm"
import MedicalIDDetailsForm from "../InputDetailsForms/MedicalIDDetailsForm/MedicalIDDetailsForm"
import PassportDetailsForm from "../InputDetailsForms/PassportDetailsForm/PassportDetailsForm"
import PhoneNumberDetailsForm from "../InputDetailsForms/PhoneNumberDetailsForm/PhoneNumberDetailsForm"
import BankRecord from "../ModalRecords/BankRecord/BankRecord"
import CreditCardRecord from "../ModalRecords/CreditCardRecord/CreditCardRecord"
import DriverLicenseRecord from "../ModalRecords/DriverLicenseRecord/DriverLicenseRecord"
import EmailRecord from "../ModalRecords/EmailRecord/EmailRecord"
import MedicalIDRecord from "../ModalRecords/MedicalIDRecord/MedicalIDRecord"
import PassportNumberRecord from "../ModalRecords/PassportNumberRecord/PassportNumberRecord"
import PhoneNumberRecord from "../ModalRecords/PhoneNumberRecord/PhoneNumberRecord"
import GSpinner from "../../atoms/Spinner/Spinner"
import GDashedButton from "../DashedButton/DashedButton"
import GRecord from "../Record/Record"
import AddressDetailsForm from "../InputDetailsForms/AddressDetailsForm/AddressDetailsForm"
import { postRecord, deleteRecord } from "../../../../../../dashboard-govplus-front/src/appRedux/actions";
import AddressRecord from "../ModalRecords/AddressRecord/AddressRecord"

const AddRecordModal: FC<AddRecordModalProps> = ({
  width = 640,
  open,
  onCancel,
  recordType,
  click,
  records,
  currentUserName,
  state,
  zipCode,
  city,
  address1,
  address2,
}) => {

  const dispatch = useDispatch()

  // @ts-ignore
  const { postRecordFailure, customerUpdateError, customerUpdated, updateCustomerLoader, recordsLoader } = useSelector((state) => state.identityProtectionData);

  /**
   * This component has two sources of truth:
   * 1. The status of the records, which determines whether to show the dashed button and the form.
   * 2. The click actions of the user, which can trigger changes in the records and form visibility.
   */

  const [showDashedButton, setShowDashedButton] = useState(false) // show dashed button depending on the records
  const [handleShowDashedButton, setHandleShowDashedButton] = useState(true) // show dashed button depending on the handle clicks
  const [showForm, setShowForm] = useState(true) // show form depending on the records
  const [handleShowForm, setHandleShowForm] = useState(false) // show form depending on the handle clicks
  const [showAddressForm, setShowAddressForm] = useState(false) // show address form depending on the address
  const [handleShowAddressForm, setHandleShowAddressForm] = useState(false) // show address form depending on the handle clicks
  const [handleShowRecord, setHandleShowRecord] = useState(true) // show record depending on the handle clicks

  const handleFormActions = async (submittedData: any) => {
    try {
      if (recordType !== RecordType.ADDRESS) {
        await dispatch(postRecord(submittedData));
        setShowDashedButton(true);
        setShowForm(false);
        setHandleShowForm(false);
        setHandleShowRecord(true);
      } else if (recordType === RecordType.ADDRESS) {
        setShowDashedButton(true);
        setShowAddressForm(false);
        setHandleShowAddressForm(false);
        setHandleShowRecord(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderForm = (recordType: string) => {
    switch (recordType) {
      case RecordType.BK:
        return <BankAccountDetailsForm recordType={recordType} handleFormActions={handleFormActions} />
      case RecordType.CC:
        return <CreditCardDetailsForm recordType={recordType} handleFormActions={handleFormActions} />
      case RecordType.DL:
        return <DriverLicenseDetailsForm recordType={recordType} handleFormActions={handleFormActions} />
      case RecordType.EM:
        return <EmailDetailsForm recordType={recordType} handleFormActions={handleFormActions} />
      case RecordType.MD:
        return <MedicalIDDetailsForm recordType={recordType} handleFormActions={handleFormActions} />
      case RecordType.PP:
        return <PassportDetailsForm recordType={recordType} handleFormActions={handleFormActions} />
      case RecordType.TM:
        return <PhoneNumberDetailsForm recordType={recordType} handleFormActions={handleFormActions} />
      case RecordType.ADDRESS:
        return <AddressDetailsForm recordType={recordType} handleFormActions={handleFormActions} />
      default:
        return null
    }
  }

  const renderRecord = (recordType: string, data: RecordContentProps) => {
    switch (recordType) {
      case RecordType.BK:
        return <BankRecord ValueToMonitor={data.ValueToMonitor} RNumber={data.RNumber} />
      case RecordType.CC:
        return <CreditCardRecord ValueToMonitor={data.ValueToMonitor} />
      case RecordType.DL:
        return <DriverLicenseRecord ValueToMonitor={data.ValueToMonitor} currentUserName={currentUserName} />
      case RecordType.EM:
        return <EmailRecord ValueToMonitor={data.ValueToMonitor} />
      case RecordType.MD:
        return <MedicalIDRecord ValueToMonitor={insertHyphens(data.ValueToMonitor as string)} currentUserName={currentUserName} />
      case RecordType.PP:
        return <PassportNumberRecord ValueToMonitor={data.ValueToMonitor} currentUserName={currentUserName} />
      case RecordType.TM:
        return <PhoneNumberRecord ValueToMonitor={formatPhoneNumber(data.ValueToMonitor as string)} />
      default:
        return null
    }
  }

  const handleDashedButtonClick = () => {
    setHandleShowForm(true)
    setShowForm(true)
    setShowDashedButton(false)
    setHandleShowDashedButton(false)
  }

  const handleModalClose = useCallback(() => {
    click()
    setShowAddressForm(false)
    setHandleShowForm(false)
    setHandleShowAddressForm(false)
    setHandleShowRecord(true)
  }, [click])

  const recordsExist = Boolean(checkDataLength(records)); // check if records exist

  const currentDashedButtonState = showDashedButton || handleShowDashedButton // check between show dashed button depending on records and handle clicks

  const currentFormState = showForm || handleShowForm // check between show form depending on records and handle clicks

  const currentAddressFormState = showAddressForm || handleShowAddressForm // check between show form depending on records and handle clicks

  const canRenderDashedButton = recordType !== RecordType.ADDRESS && recordType !== RecordType.TM && recordsExist && canAddMoreRecords(recordType, records) && currentDashedButtonState && !handleShowForm  // if records exist and can add more records


  // calculating form state for products with max count of records 10
  const canRenderFormTenRecords = recordType !== RecordType.ADDRESS && recordType !== RecordType.TM && getMaxRecordCount(recordType) === 10 && canAddMoreRecords(recordType, records) && currentFormState

  // calculating form state for products with max count of records 1
  const canRenderFormOneRecord = recordType !== RecordType.ADDRESS && recordType !== RecordType.TM && getMaxRecordCount(recordType) === 1 && currentFormState

  const canRenderAddressForm = recordType === RecordType.ADDRESS && currentAddressFormState

  const recordButtonText = getMaxRecordCount(recordType) === 1 ? "Change" : "Delete"

  const getCurrentRecordsCount = () => {
    if (recordType === RecordType.ADDRESS) {
      if (state || address1 || zipCode || city) {
        return 1
      }
      return 0
    }
    return checkDataLength(records) || 0
  }

  const handleRecordExistence = useCallback((recordType: string, records: string[]) => {
    if (recordType !== RecordType.ADDRESS && recordType !== RecordType.TM) {
      if (recordsExist && canAddMoreRecords(recordType, records)) { // if records exist and can add more records
        setShowDashedButton(true);
        setShowForm(false);
      } else if (recordsExist && !canAddMoreRecords(recordType, records)) { // if records exist and cannot add more records
        setShowDashedButton(false);
        setShowForm(false);
      } else if (!recordsExist && canAddMoreRecords(recordType, records)) { // if records do not exist
        setShowForm(true);
        setShowDashedButton(false);
      }
    } else if (recordType === RecordType.ADDRESS) {
      if ((state || address1 || zipCode || city)) { // if address exist
        setShowAddressForm(false);
      } else if (!(state || address1 || zipCode || city)) { // if no address
        setShowAddressForm(true);
      }
    }

  }, [address1, city, recordsExist, state, zipCode])


  const handleDeleteRecord = useCallback((data: any) => {
    dispatch(deleteRecord({
      monitorId: data.MonitorID,
      recordType: recordType,
    }));
  }, [dispatch, recordType]);

  const handleRecordClick = async (data: any) => {
    handleDeleteRecord(data)

    if (getMaxRecordCount(recordType) === 1) {
      setHandleShowForm(true)
      setHandleShowRecord(false)
    }
  }

  const handleAddressClick = () => {
    setHandleShowAddressForm(true)
    setHandleShowRecord(false)
    setShowAddressForm(true)
    setHandleShowRecord(false)
  }

  useEffect(() => {
    handleRecordExistence(recordType, records)
  }, [records, postRecordFailure, showForm, showDashedButton, handleRecordExistence, recordType])

  useEffect(() => {
    if (!customerUpdateError && customerUpdated) {
      setShowAddressForm(false)
    }
  }, [customerUpdateError, customerUpdated])

  return (
    <GModal width={width} open={open} onCancel={onCancel}>
      <div className={recordsLoader ? "AddRecordModal__Loading" : "AddRecordModal"}>
        <div className={"AddRecordModal__Top"}>
          <div className={"AddRecordModal__Top__Header"}>
            <GText text={getModalTitle(recordType)} size={SIZE.PARAGRAPH_BOLD_32} color={COLORS_NEW.BLACK_1000} />
          </div>

          <div className={"AddRecordModal__Top__Subheader"}>
            <GText text={getModalTopQuestion(recordType)} size={SIZE.PARAGRAPH_BOLD_18} color={COLORS_NEW.BLACK_1000} />
            <GText text={getModalTopCopy(recordType)} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800} />
          </div>
        </div>

        <div className={"AddRecordModal__Bottom"}>
          <div className={"AddRecordModal__Bottom__Label"}>
            <GText text={getModalBottomCopy(recordType)} size={SIZE.PARAGRAPH_BOLD_20} color={COLORS_NEW.BLACK_1000} />
            <GRecordCount currentStep={getCurrentRecordsCount()} totalSteps={getMaxRecordCount(recordType)} />
          </div>

          <div className={"AddRecordModal__Bottom"}>
            {recordsLoader && (
              <div className={"AddRecordModal__Bottom__Overlay"}>
                <div className={"AddRecordModal__Bottom__Spinner"}>
                  <GSpinner />
                </div>
              </div>
            )}

            {/* calculating state for the product records if max records count 10 */}
            {recordsExist && recordType !== RecordType.ADDRESS && recordType !== RecordType.TM && getMaxRecordCount(recordType) === 10 &&
              records?.map((data: RecordContentProps) => {
                return (
                  <GRecord key={data.ValueToMonitor} text={recordButtonText} click={() => handleRecordClick(data)} showActionButton={recordType !== RecordType.TM}>
                    {renderRecord(recordType, data)}
                  </GRecord>
                )
              })}

            {/* calculating state for the product records if max records count 1 */}
            {!recordsLoader && recordsExist && recordType !== RecordType.ADDRESS && recordType !== RecordType.TM && getMaxRecordCount(recordType) === 1 && handleShowRecord &&
              records?.map((data: RecordContentProps) => {
                return (
                  <GRecord key={data.ValueToMonitor} text={recordButtonText} click={() => handleRecordClick(data)} showActionButton={recordType !== RecordType.TM}>
                    {renderRecord(recordType, data)}
                  </GRecord>
                )
              })}

            {/* calculating state for the phone record if phone is tracked by enforta and available in gov database */}
            {!recordsLoader && recordsExist && recordType === RecordType.TM && records?.map((data: RecordContentProps) => {
              return (
                <GRecord key={data.ValueToMonitor} text={recordButtonText} click={() => handleRecordClick(data)} showActionButton={false}>
                  {renderRecord(recordType, data)}
                </GRecord>
              )
            })}

            {!recordsLoader && handleShowRecord && recordType === RecordType.ADDRESS && (
              <GRecord key={address1} text={recordButtonText} click={handleAddressClick} showActionButton={true}>
                <AddressRecord
                  addressTop={address1}
                  addressBottom={`${city}, ${getStateFullName(state as string)}`}
                  updateCustomerLoader={updateCustomerLoader}
                />
              </GRecord>
            )}

            {!recordsLoader && canRenderDashedButton && (
              <GDashedButton label={getDashedButtonLabel(recordType)} click={handleDashedButtonClick} />
            )}

            {!recordsLoader && recordType !== RecordType.ADDRESS && recordType !== RecordType.TM && canRenderFormTenRecords && renderForm(recordType)}


            {/* we can't add records, but need to switch to form state */}
            {!recordsLoader && recordType !== RecordType.ADDRESS && recordType !== RecordType.TM && canRenderFormOneRecord && renderForm(recordType)}


            {!recordsLoader && recordType === RecordType.TM && !recordsExist && renderForm(recordType)}

            {!recordsLoader && recordType === RecordType.ADDRESS && canRenderAddressForm && <AddressDetailsForm recordType={recordType} handleFormActions={handleFormActions} />}

          </div>

          <div className={"AddRecordModal__Bottom__Actions"}>
            <GButton text={"Close"} click={handleModalClose} type={BUTTON_TYPE.SECONDARY} showIcon={false} />
          </div>
        </div>
      </div>
    </GModal >
  )
}

export default AddRecordModal




