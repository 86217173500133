import React, {memo, useEffect, useState} from "react";
import "./index.less";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import {ConfigProvider} from 'antd';
import FormsAppContainer from "./FormsAppContainer";
import SignIn from "../SignIn/index";
import ConfirmPhone from "../ConfirmPhone/ConfirmPhone";
import ResetPassword from "../ResetPassword/ResetPassword";
import PageNotFound from "../../components/PageNotFound/PageNotFound";
import WelcomePage from "../../components/WelcomePage";
import SignUp from "../SingUp";
import VerificationCode from "../VerificationCode";
import ResetPasswordNextStep from "../ResetPassword/ResetPasswordNextStep";
import AddPhoneNumber from "../../routes/AddPhonePage";
import Page404 from "../../components/Page404/Page404";
import {setNavigationFormIdSolutionId, useNavigationHandling} from "./useNavigationHandling";
import {useCheckClientTokenInCookies} from "./useCheckClientTokenInCookies";
import {useGetParamsAndSaveData} from "./useGetParamsAndSaveData";
import {getGovernmentProducts} from "../../appRedux/actions";
import CustomGrowl from "./CustomGrowl";
import Resolve from "../Resolve";
import PathToPageEventHelper
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/analytic/event/PathToPageEventTranslator";
import UserIpRepository from "@dash/gov-plus__front-end__form/src/persistence/session/UserIpRepository";
import {userService} from "../../api/api";
import {notifyError} from "@dash/gov-plus__front-end__form/src/util/helper";
import {CommonPageTitle, PageNames, Path, SinInPageTitle} from "@dash/gov-plus__front-end__form/src/util/PageHelper";
import {EventsHelper} from "@dash/gov-plus__front-end__form/src/util/EventsHelper";
import {SubscriptionProvider} from "../../context/SubscriptionContext";
import {UserProvider} from "../../context/UserContext";
import TestUpsellPage from "../../routes/TestUpsellPage";
import {ApplicationsProvider} from "../../context/ApplicationsContext";
import TaxComponent from "../../components/Tax";
import {IdentityProtectionProvider} from "../../context/IdentityProtectionContext";
import { ModalsProvider } from "../../context/ModalsContext";
import VerificationSuccess
from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/VerificationSuccess/VerificationSuccess";

const DashboardApp = () => {
    const isDirectionRTL = useSelector(({settings}) => settings.isDirectionRTL);
    const {anotherGovProducts, webFlowData} = useSelector(({allProducts}) => allProducts);
    const dispatch = useDispatch();

    const [userIp, setUserIp] = useState(UserIpRepository.getInstance().getValue());

    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search)
    const location = useLocation();
    useNavigationHandling();
    useCheckClientTokenInCookies();
    useGetParamsAndSaveData();
    setNavigationFormIdSolutionId();

    useEffect(() => {
        if (!anotherGovProducts?.length || !Object.keys(webFlowData)?.length) {
            dispatch(getGovernmentProducts());
        }
    }, [anotherGovProducts?.length, Object.keys(webFlowData)?.length])

    useEffect(() => {
        if (!userIp) {
            userService.getUserApi().then(result => {
                const ipResult = result.data.data.ip;
                UserIpRepository.getInstance().setValue(ipResult);
                setUserIp(ipResult)
                runPageAnalytic(ipResult);
            }).catch(error => {
                notifyError(error);
            });
        } else {
            runPageAnalytic(userIp);
        }
    }, [history.location.pathname, queryParams.toString()])

    useEffect(() => {
        document.title = location.pathname === Path.SIGN_IN ? SinInPageTitle : CommonPageTitle;
    }, [location.pathname]);

    const runPageAnalytic = (ipAddress) => {
        let pageName = PathToPageEventHelper.getPagePathWithQueryParams();
        if (pageName !== PathToPageEventHelper.UNDEFINED_PAGE) {
            const pageAnalytic = EventsHelper.triggerPageAnalytic(pageName, ipAddress);
        }
        pageName = PathToPageEventHelper.getPageNameFromQuery(queryParams);
        if (pageName === PageNames.SIGN_UP) {
            const signupStarted = EventsHelper.triggerSignupStarted();
        }
    }

    const renderRedirectFormAppWithFormIdAndServiceId = () => {
        return <Redirect to='/formsApp'/>;
    }

    return (
        <UserProvider>
            <ApplicationsProvider>
                <SubscriptionProvider>
                    <IdentityProtectionProvider>
                        <ModalsProvider>
                            <ConfigProvider direction={isDirectionRTL ? 'rtl' : 'ltr'}>
                                <div>
                                    <CustomGrowl/>
                                </div>

                                <Switch>
                                    <Route exact path={'/taxes/taxes-processing'} component={TaxComponent}/>
                                    <Route exact path={'/signin'} component={SignIn}/>
                                    <Route exact path='/welcomepage' component={WelcomePage}/>
                                    <Route exact path='/confirm_phone' component={ConfirmPhone}/>
                                    <Route exact path='/verification_code' component={VerificationCode}/>
                                    <Route exact path='/activate' component={SignUp}/>
                                    <Route exact path='/addPhone' component={AddPhoneNumber}/>
                                    <Route exact path='/reset' component={ResetPassword}/>
                                    <Route exact path='/change-password' component={ResetPasswordNextStep}/>
                                    <Route exact path='/phone-confirm' component={ConfirmPhone}/>

                                    <Route exact path='/resolve' component={Resolve}/>

                                    <Route
                                        exact
                                        path='/redirect-new-application'
                                        render={() => renderRedirectFormAppWithFormIdAndServiceId()}
                                    />

                                    <Route exact path='/error-404' component={Page404}/>

                                    <Route exact path={'/TestPageForCheckDesign'} component={FormsAppContainer}/>
                                    <Route exact path={'/TestUpsell'} component={FormsAppContainer}/>
                                    <Route exact path={'/TestUpsellCard'} component={FormsAppContainer}/>
                                    <Route exact path={'/forms'} component={FormsAppContainer}/>
                                    <Route exact path={'/referFriend'} component={FormsAppContainer}/>
                                    <Route exact path={'/forms/*'} component={FormsAppContainer}/>
                                    <Route exact path={'/myIdentity'} component={FormsAppContainer}/>
                                    <Route exact path={'/alertMessages'} component={FormsAppContainer}/>
                                    <Route exact path={'/myEmailExposures'} component={FormsAppContainer}/>
                                    <Route exact path={'/identityTheftProtectionPolicy'} component={FormsAppContainer}/>
                                    <Route exact path={'/myIdentity/userVerification'} component={FormsAppContainer}/>
                                    {/* this component is shown in enfortra iframe and can't be wrapped into FormsAppContainer */}
                                    <Route exact path={'/verificationSuccess'} component={VerificationSuccess}/>
                                    <Route exact path={'/'} component={FormsAppContainer}/>
                                    <Route exact path={'/myAutomaticRenewals'} component={FormsAppContainer}/>
                                    <Route exact path={'/myInsuredDocuments'} component={FormsAppContainer}/>
                                    <Route exact path={'/myAutomaticRenewals'} component={FormsAppContainer}/>
                                    <Route exact path={'/SubscriptionCancelApplications'} component={FormsAppContainer}/>
                                    <Route exact path={'/SubscriptionCancelInsurance'} component={FormsAppContainer}/>
                                    <Route exact path={'/SubscriptionCancelRenewal'} component={FormsAppContainer}/>
                                    <Route exact path={'/SubscriptionCancelIdentityProtection'} component={FormsAppContainer}/>
                                    <Route exact path={'/identityProtectionSupport'} component={FormsAppContainer}/>
                                    <Route exact path={'/SubscriptionCancelReason'} component={FormsAppContainer}/>
                                    <Route exact path={'/SubscriptionCancelled'} component={FormsAppContainer}/>
                                    <Route exact path={'/signup'} component={FormsAppContainer}/>
                                    <Route exact path='/sample' component={FormsAppContainer}/>
                                    <Route exact path='/formsApp' component={FormsAppContainer}/>
                                    <Route exact path='/products' component={FormsAppContainer}/>
                                    <Route exact path='/products/productInfo' component={FormsAppContainer}/>
                                    <Route exact path='/products/productInfo/postalstatus/:id' component={FormsAppContainer}/>
                                    <Route exact path='/products/productInfo/:id' component={FormsAppContainer}/>
                                    <Route exact path='/offers' component={FormsAppContainer}/>
                                    <Route exact path='/check_forms' component={FormsAppContainer}/>
                                    <Route exact path='/notifications' component={FormsAppContainer}/>
                                    <Route exact path='/users' component={FormsAppContainer}/>
                                    <Route exact path='/account-settings' component={FormsAppContainer}/>
                                    <Route exact path='/account-settings/name' component={FormsAppContainer}/>
                                    <Route exact path='/account-settings/email-edit' component={FormsAppContainer}/>
                                    <Route exact path='/account-settings/phone' component={FormsAppContainer}/>
                                    <Route exact path='/account-settings/password' component={FormsAppContainer}/>
                                    <Route exact path='/billing-settings' component={FormsAppContainer}/>
                                    <Route exact path='/billing-settings/change_payment' component={FormsAppContainer}/>
                                    <Route exact path='/phone-edit' component={FormsAppContainer}/>
                                    <Route exact path='/edit-password' component={FormsAppContainer}/>
                                    <Route exact path='/' component={FormsAppContainer}/>
                                    <Route exact path={'/test-stripe'} component={TestUpsellPage}/>
                                    <Route path="*" component={PageNotFound}/>
                                </Switch>
                        </ConfigProvider>
                        </ModalsProvider>
                    </IdentityProtectionProvider>
                </SubscriptionProvider>
            </ApplicationsProvider>
        </UserProvider>
    )
};

export default memo(DashboardApp);
