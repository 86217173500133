import {call, put, takeEvery} from 'redux-saga/effects';
import {ActionTypes, setUserCouponState} from "./billing.action";
import {setLoadingButtonState} from "../loading/loading.action";
import {LoadingState} from "../../../shared/constans/user-from-view-mode.enum";
import {BillingSagaHelper, UserCouponResponse} from "./BillingSagaHelper";
import {getClientTokenForResponse} from "../step/service.step.saga";

function* getUserCouponSaga() {
    try {
        yield put(setLoadingButtonState(LoadingState.Loading))
        const couponId: string | null = yield call(BillingSagaHelper.getOfferCouponFromRepository);
        const {client_token} = yield call(getClientTokenForResponse);
        const applicationId: string | null = yield call(BillingSagaHelper.getApplicationIdFromRepository);

        if (couponId && client_token && applicationId) {
            const data: UserCouponResponse = yield call(
                BillingSagaHelper.getUserCoupon,
                couponId,
                client_token,
                applicationId
            );

            if (data?.success) {
                yield put(setUserCouponState(data?.success));
            }

        }
        yield put(setLoadingButtonState(LoadingState.Loaded))
    } catch (error) {
        yield put(setLoadingButtonState(LoadingState.Loaded))
    }
}

export default function* billingSaga() {
    yield takeEvery(ActionTypes.SUBMIT_GET_USER_COUPON, getUserCouponSaga)
}