import React  from "react";
import "./Page404.less";
import {useHistory} from "react-router-dom";
import Header from "../../containers/Header";


const Page404 = () => {
  const history = useHistory();

  const goToHomePage = () => {
    history.push('/');
  }

  return (
    <div className="page404_container">
      <Header />
      <div className="page404_main">
        <h1 className="page404_title">Error {history.location.state === 'application not found' ? '404' : ''}</h1>
        <p className="page404_subtitle">We're sorry, but something went wrong. The page you requested has moved or it doesn't exist. We suggest you:</p>
        <ul className="page404_subtitle_list">
          <li className="page_404_subtitle_list_item">Use the Back button in your browser to return to the previous page.</li>
          <li className="page_404_subtitle_list_item">Return to <span onClick={goToHomePage}>your dashboard</span>.</li>
        </ul>
      </div>
    </div>
  )
};

export default Page404;