import React, {ReactElement} from "react";
import {ITemplateOneBuilder} from "./ITemplateOneBuilder";
import TemplateOneBuilder from "../TemplateOneBuilder";
import {IUpsellProcessingData} from "../../../StepUpsell";
import TemplateOne from "../TemplateOne";
import GUpsellCardComponentHeader
    from "../../../../../../molecules/UpsellCardComponentHeader/GUpsellCardComponentHeader";
import {UPSELL_PROPS} from "../../UpsellProps";
import {SolutionNames} from "../../../../../../../../util/SolutionNames";
import {ProductsIds} from "../../../../../../../../util/ProductsIds";

export class CompletedApplicationCrossSell implements ITemplateOneBuilder {



    buildTemplate(builder: TemplateOneBuilder, data: IUpsellProcessingData, solutionName: string): TemplateOne {
        const {
            offeredService,
            subCheckListText,
            previousPrice,
            benefits,
            discount
        } = this.getExtraData(data.cross_sell_product_offer)

        let pageBuilder = builder
            .setTitle(data.settings_values[UPSELL_PROPS.UPSELL__HEADLINE_STOP].replaceAll(UPSELL_PROPS.OFFERED_SOLUTION_KEY_REPLACE, offeredService))
            .setSubtitle(
                data.settings_values[UPSELL_PROPS.UPSELL__SUB_HEADLINE_STOP]
                    .replaceAll(UPSELL_PROPS.SOLUTION_KEY_REPLACE, solutionName)
                    .replaceAll(UPSELL_PROPS.OFFERED_SOLUTION_KEY_REPLACE, offeredService)
            )
            .setAcceptButton(data.settings_values[UPSELL_PROPS.UPSELL__CALL_TO_ACTION_YES].replaceAll(UPSELL_PROPS.OFFERED_SOLUTION_KEY_REPLACE, offeredService))
            .setCancelButton(data.settings_values[UPSELL_PROPS.UPSELL__CALL_TO_ACTION_NO].replaceAll(UPSELL_PROPS.OFFERED_SOLUTION_KEY_REPLACE, offeredService))
            .setComponentHeader(this.getComponentHeader(solutionName, offeredService, discount))
            .setCheckListItems(benefits)
            .setCheckListSubTitle(subCheckListText)
            .setPreviousPrice(previousPrice)
            .setTagInformation('One-time only discount')
        return pageBuilder.build();
    }

    /**
     * @return ReactElement | undefined
     * @param acquiredService
     * @param offeredService
     * @param discount
     */
    private getComponentHeader(acquiredService: string, offeredService: string, discount: string): ReactElement {
        return <GUpsellCardComponentHeader discount={discount} acquiredService={acquiredService} offeredService={offeredService}/>;
    }

    /**
     * @return string[]
     */
    private getExtraData(offerProduct: string | undefined) {

        /**
         * This is a temporary solution until we have the real data
         */
        switch (offerProduct) {
            case ProductsIds.TsaId:
                return {
                    offeredService: SolutionNames.TSA,
                    subCheckListText: 'Tired of long airport security lines? Get or renew TSA <br> PreCheck® in' +
                        ' minutes with GOV+ and travel with ease.',
                    discount: '-45%',
                    previousPrice: '97',
                    benefits: [
                        'New TSA PreCheck®',
                        'TSA PreCheck® renewal'
                    ]
                };
            case ProductsIds.PassportId:
                return {
                    offeredService: SolutionNames.PAS,
                    previousPrice: '87',
                    discount: '-35%',
                    benefits: [
                        'Passport renewal',
                        'First passport',
                        'Lost passport',
                        'Damaged passport',
                        'Stolen passport'
                    ]
                };
            default:
                return {
                    discount: '',
                    offeredService: '',
                    subCheckListText: '',
                    previousPrice: '',
                    benefits: []
                }
        }
    }
}