import React, {FC} from "react";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import "./GUpsellCardApplicationCard.scss"
import acquiredProgressBar from "../../../../assets/image/cross_sells/acquired_progress_bar.png"
import offeredProgressBar from "../../../../assets/image/cross_sells/offered_progress_bar.png"
import GProgressBar from "../ProgressBar/ProgressBar";
import GIconsSvgType from "../../atoms/IconsSvg/IconsSvg";
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes";


export type ApplicationCardProps = {
    isOffered: boolean,
    solution: string,
    status: string,
    discount?: string,
    productImage?: string,
}

const GApplicationCard: FC<ApplicationCardProps> =
    ({
         isOffered,
         solution,
         status,
         discount,
         productImage
     }) => {

        return (
            <div className={`GUpsellCardApplicationCard`}>
                <div className={`GUpsellCardApplicationCard__Header`}>
                    {isOffered && discount &&
                        <div className={`GUpsellCardApplicationCard__Header__PercentDiscount`}>
                            <GText text={discount} size={SIZE.PARAGRAPH_BOLD_18} color={COLORS.WHITE}/>
                        </div>
                    }
                    <div className={`GUpsellCardApplicationCard__Header__Image`}>
                        <img src={productImage} alt={solution}/>
                    </div>
                </div>
                <div className={`GUpsellCardApplicationCard__Body`}>
                    <GText
                        text={solution}
                        size={SIZE.PARAGRAPH_BOLD_20}
                        color={COLORS_NEW.BLACK_1000}
                    />
                    <div className={"GUpsellCardApplicationCard__Body__ProgressBar"}>
                        <GProgressBar value={isOffered ? 90 : 100} showInfo={false} showIcon={false} color={COLORS_NEW.GREEN_600} />
                        <GIconsSvgType type={ICONS_SVG_TYPE.CHECK_CIRCLE} backgroundColor={COLORS_NEW.GREEN_600} color={COLORS.WHITE} width={20} height={20} />
                    </div>
                    <GText
                        text={status}
                        size={SIZE.PARAGRAPH_BOLD_16}
                        color={COLORS_NEW.GREEN_600}
                    />
                </div>
            </div>
        )
    }


export default GApplicationCard


