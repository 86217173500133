import React from "react"

export const SvgInsuranceDollarIcon = ({ width = 54, height = 64 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 59 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8809_53062)">
        <path
          d="M27.0501 68C27.0501 68 54.2501 55.2 54.2501 36V13.6L27.0501 4L-0.149902 13.6V36C-0.149902 55.2 27.0501 68 27.0501 68Z"
          fill="#13BA68"
        />
        <path d="M27.05 68C27.05 68 54.25 55.2 54.25 36V13.6L27.05 4V68Z" fill="#00A660" />
        <path
          d="M19.7758 28.5V44.5H16.8774V31.3203H16.7836L13.0415 33.7109V31.0547L17.018 28.5H19.7758ZM23.8946 28.5H27.4415L32.1915 40.0937H32.379L37.129 28.5H40.6758V44.5H37.8946V33.5078H37.7461L33.3243 44.4531H31.2461L26.8243 33.4844H26.6758V44.5H23.8946V28.5Z"
          fill="white"
        />
      </g>
      <rect x="30.75" width="28" height="28" rx="14" fill="#B4EDC1" />
      <g clipPath="url(#clip1_8809_53062)">
        <g clipPath="url(#clip2_8809_53062)">
          <path
            d="M44.75 6.66797V21.3346"
            stroke="#00663B"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M48.0833 9.33203H43.0833C42.4645 9.33203 41.871 9.57786 41.4334 10.0154C40.9958 10.453 40.75 11.0465 40.75 11.6654C40.75 12.2842 40.9958 12.8777 41.4334 13.3153C41.871 13.7529 42.4645 13.9987 43.0833 13.9987H46.4167C47.0355 13.9987 47.629 14.2445 48.0666 14.6821C48.5042 15.1197 48.75 15.7132 48.75 16.332C48.75 16.9509 48.5042 17.5444 48.0666 17.9819C47.629 18.4195 47.0355 18.6654 46.4167 18.6654H40.75"
            stroke="#00663B"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8809_53062">
          <rect width="54.4" height="64" fill="white" transform="translate(0.25 4)" />
        </clipPath>
        <clipPath id="clip1_8809_53062">
          <rect width="16" height="16" fill="white" transform="translate(36.75 6)" />
        </clipPath>
        <clipPath id="clip2_8809_53062">
          <rect width="16" height="16" fill="white" transform="translate(36.75 6)" />
        </clipPath>
      </defs>
    </svg>
  )
}
