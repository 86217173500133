import Event from "../Event"
import {EventOptions, EventProps} from "../EventProperties"
import IEvent from "../IEvent";

const EVENT_NAME = "Checkout Started";

export default class CheckoutStarted extends Event implements IEvent {
    private readonly _value: string | undefined;
    private readonly _revenue: string | undefined;
    private readonly _currency: string | undefined;
    private readonly _productId: string | undefined;
    private readonly _productName: string | undefined;
    private readonly _service: string | undefined;
    private readonly _solution: string | undefined;
    private readonly _productPrice: string | undefined;
    private readonly _productQuantity: string | undefined;
    private readonly _productCategory: string | undefined;
    private readonly _subdivision: string | undefined;

    constructor(
        ipAddress: string | undefined,
        value: string | undefined,
        revenue: string | undefined,
        currency: string | undefined,
        productId: string | undefined,
        productName: string | undefined,
        service: string | undefined,
        solution: string | undefined,
        productPrice: string | undefined,
        productQuantity: string | undefined,
        productCategory: string | undefined,
        subdivision: string | undefined
    ) {
        super(EVENT_NAME, ipAddress);
        this._value = value;
        this._revenue = revenue;
        this._currency = currency;
        this._productId = productId;
        this._productName = productName;
        this._service = service;
        this._solution = solution;
        this._productPrice = productPrice;
        this._productQuantity = productQuantity;
        this._productCategory = productCategory;
        this._subdivision = subdivision;
    }

    getEventData(): EventProps {
        let product = {};
        product = this.addProperty('product_id', this._productId, product);
        product = this.addProperty('name', this._productName, product);
        product = this.addProperty('solution', this._solution, product);
        product = this.addProperty('service', this._service, product);
        product = this.addProperty('price', this._productPrice, product);
        product = this.addProperty('quantity', this._productQuantity, product);
        product = this.addProperty('subdivision', this._subdivision, product);
        product = this.addProperty('category', this._productCategory, product);

        let data = super.baseEventData();
        data = this.addProperty('value', this._value, data);
        data = this.addProperty('revenue', this._revenue, data);
        data = this.addProperty('currency', this._currency, data);
        data = this.addProperty('solution', this._solution, data);
        data = this.addProperty('service', this._service, data);

        return {
            ...data,
            products: [
                product
            ]
        }
    }

    getEventName(): string {
        return this.eventName;
    }

    pageName(): string {
        return "";
    }

    getPreviousId(): string {
        return "";
    }

    getUserId(): string {
        return "";
    }

    getEventOptions(): EventOptions | undefined {
        return undefined;
    }
}
