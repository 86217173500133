import React, { FC, useMemo } from "react"
import { BasicBlockContainerProps } from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { connect, useSelector } from "react-redux"
import {
  arraysImportedIcons,
  getArraysImportedIcons,
  getSrc,
} from "../../Components/FormComponents/UploadPhoto/ImgComponent/ImgComponentService/ImgComponentService"
import { RootState } from "../../App"
import { userSelector } from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import { getPropertiesForEvents } from "../../services/gtm/backEndEvent"

const UploadPhotoImgItem: FC<BasicBlockContainerProps> = ({
  item: { className, text, src = "", type },
  currentStep,
}) => {
  const { webFlowData: webFlowDataLocalStorage, form_id: formId } = getPropertiesForEvents()
  const webFlowServicesData = JSON.parse(localStorage.getItem("webFlowServicesData") ?? "{}") ?? {}
  const productProcessingSteps = webFlowServicesData?.[formId ?? ""]
  const { image: linkPhotoProcessingSteps } = productProcessingSteps ?? {}

  const webFlowDataStore = useSelector<RootState, { webFlowData: any }>(userSelector).webFlowData ?? {}
  const webFlowData = webFlowDataStore ?? webFlowDataLocalStorage

  let productFirstStep = webFlowData?.[formId ?? ""]
  const { url: linkPhotoFirstStep } = productFirstStep?.funnelFormImage ?? {}

  if (currentStep?.formType === "payment") {
    return <img className={className} src={getSrc(src, getArraysImportedIcons(type, arraysImportedIcons))} alt={text} />
  } else if (currentStep?.formType === "processing_steps") {
    return <img className={className} src={linkPhotoProcessingSteps} alt={text ?? ""} />
  } else {
    return <img className={className} src={linkPhotoFirstStep} alt={text ?? ""} />
  }
}

const mapStateToProps = (state: any) => ({
  currentStep: state.step.currentStep,
})

export default connect(mapStateToProps)(UploadPhotoImgItem)
