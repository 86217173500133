import { useState, useEffect } from 'react';

const useIsMobile = (size: number) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < size);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < size);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [size]);

  return isMobile;
};



export default useIsMobile;