import React from "react"
import {COLORS} from "../../../types/Colors";

interface ISvgCheck {
    width?: number;
    height?: number;
    color?: string;
}

export const SvgTaxErrorIcon = ({width = 33, height = 32, color = COLORS.RED_600}: ISvgCheck) => {
    const viewBox = `0 0 ${width} ${height}`;
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width={height} height={height} rx="16" fill={color}/>
            <path d="M21.5 11L11.5 21" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.5 11L21.5 21" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
