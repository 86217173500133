import IEvent from "../IEvent"
import BaseBuilder from "../BaseBuilder"
import IdentityProtectionCreditScoreCompleted from "./IdentityProtectionCreditScoreCompleted"

/**
 * Identity Protection Verification Completed Builder
 * @class IdentityProtectionCreditScoreCompletedBuilder
 * @extends {BaseBuilder}
 * @implements {IBuilder}
 * @description Identity Protection Verification Completed Builder
 */

export default class IdentityProtectionCreditScoreCompletedBuilder extends BaseBuilder {
    /**
     * Set ip address
     * @param ipAddress
     * @returns {IBuilder}
     * @description This method is used to set ip address
     */
    setIpAddress(ipAddress: string | undefined) {
        this._ipAddress = ipAddress
        return this
    }

    /**
     * Build event
     * @returns {IEvent}
     * @description This method is used to build event
     */
    build(): IEvent {
        return new IdentityProtectionCreditScoreCompleted(this._ipAddress)
    }
}
