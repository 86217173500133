import React, { FC } from "react"
import { BasicBlockContainerProps } from "../../UploadPhoto/UploadPhotoService/interfaces/interfaces"
import ButtonComponent from "../../../FormComponents/UploadPhoto/ButtonComponent/ButtonComponent"
import { useSelector } from "react-redux"
import { RootState } from "../../../../App"
import { formSelector } from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"

const ButtonCheckEmailContainer: FC<BasicBlockContainerProps> = ({ item: { className, text, urlTargetClick } }) => {
  const { email = urlTargetClick } =
    useSelector<RootState, { customer: { email: string } | null }>(formSelector)?.customer ?? {}

  const handlerOnClickSave = () => window.open(`https://${email?.split("@")?.[1]}`, "_blank")

  return <ButtonComponent className={className} text={text} handlerOnClick={handlerOnClickSave} />
}

export default ButtonCheckEmailContainer
