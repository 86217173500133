import { put } from "redux-saga/effects"
import { setValueModal } from "../form/form.actions"
import AuthUserBearerTokenRepository from "../../../persistence/session/AuthUserBearerTokenRepository";
import FormNameForEventRepository from "../../../persistence/session/FormNameForEventRepository";

interface getClientTokenInterface {
  fromDashboard: boolean | undefined;
  client_token_cookie: string;
  client_token_params: string;
  client_token_session_storage: string;
}

export const getClientTokenForResponse = (fromDashboard?: boolean) => {
  const getLocalCookie = (key: string) => {
    const keyValue = document?.cookie?.match("(^|;) ?" + key + "=([^;]*)(;|$)")
    return keyValue ? keyValue[2] : null
  }

  const client_token_params = new URLSearchParams(window.location.search)?.get("authUserBearerToken") ?? ""
  const client_token_session_storage = AuthUserBearerTokenRepository.getInstance().getValue() ?? ""
  const client_token_cookie = getLocalCookie("authUserBearerToken") ?? ""

  const getClientToken = ({
    fromDashboard,
    client_token_cookie,
    client_token_params,
    client_token_session_storage,
  }: getClientTokenInterface) =>
    fromDashboard && !client_token_params
      ? client_token_cookie || client_token_params || client_token_session_storage || ""
      : client_token_params || client_token_session_storage || client_token_cookie || ""

  const client_token = getClientToken({
    fromDashboard,
    client_token_cookie,
    client_token_params,
    client_token_session_storage,
  })

  if (fromDashboard && !client_token_params) {
    AuthUserBearerTokenRepository.getInstance().setValue(client_token);
  }

  return {
    client_token,
    statusClientTokenGetParam: !!client_token_params,
    statusClientTokenSessionStorage: !!client_token_session_storage,
    statusClientTokenCookie: !!client_token_cookie,
    client_token_cookie,
    // fromDashboardSignature,
  }
}

export interface paramInterface {
  name: string;
  action: (arg: string) => void | string | null;
}

export const getParamsFromSessionStorage = () => {
  const arraySessionStorageParams: paramInterface[] = [
    {
      name: "subscriptionId",
      action: sessionStorage.getItem,
    },
    {
      name: "paymentGov",
      action: sessionStorage.getItem,
    },
    {
      name: "billingAddress",
      action: sessionStorage.getItem,
    },
    // {
    //   name: "fromDashboardSignature",
    //   action: sessionStorage.getItem,
    // },
  ]

  return arraySessionStorageParams.reduce((acc, { name, action }) => {
    return { ...acc, [name]: sessionStorage.getItem(name) }
  }, {})
}

export const getParamsFromGetParams = () => {
  const arrayGetParams: paramInterface[] = [
    {
      name: "authUserBearerToken",
      action: new URLSearchParams(window.location.search).get,
    },
    {
      name: "fromDashboard",
      action: new URLSearchParams(window.location.search).get,
    },
    {
      name: "enableEditor",
      action: new URLSearchParams(window.location.search).get,
    },
    {
      name: "fromDashboardSignature",
      action: new URLSearchParams(window.location.search).get,
    },
    {
      name: "applicationId",
      action: new URLSearchParams(window.location.search).get,
    },
    {
      name: "formId",
      action: new URLSearchParams(window.location.search).get,
    },
  ]

  return arrayGetParams.reduce((acc, { name, action }) => {
    return { ...acc, [name]: new URLSearchParams(window.location.search).get(name) }
  }, {})
}

export const getAllSteps = (paymentGov: boolean, response: { [key: string]: any }) =>
  paymentGov ? [...response.additionalSteps.payment] : [...response.steps]

// prefix for dynamic events put to local storage
export const saveDynamicEvents = (allSteps: { [key: string]: string }[] = [{ [`form-name`]: "" }]) =>
  allSteps?.length && allSteps?.[0]?.["form-name"] !== "social_security_card_name_change"
    ? FormNameForEventRepository.getInstance().setValue(allSteps[0]?.["form-name"]?.split("-")[0])
    : FormNameForEventRepository.getInstance().setValue('SSN')

export const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time))

export function* getLoginAcrossDashboard({
  client_token,
  fromDashboard,
}: {
  client_token: string,
  fromDashboard: string,
}) {
  const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time))
  yield put(setValueModal("animation_prefilled_part_one"))
  yield delay(3000)
  yield put(setValueModal("animation_prefilled_part_two"))
  const url = new URL(window.location.href).toString()
  const newClientToken = url.replace(`&client_token=${client_token}`, ``)
  window.history.pushState(null, "", newClientToken.toString())
  const newFromDashBoard = newClientToken.replace(`&fromDashboard=${fromDashboard}`, ``)
  window.history.pushState(null, "", newFromDashBoard.toString())
}

export const getRoot = () => {
  return window.location.href.split("/").slice(0, 3).join("/")
}

export function setCookie(name: string, value: string, days: number) {
  // let expires = "";
  // if (days) {
  //   let date = new Date();
  //   date.setTime(date.getTime() + (days*24*60*60*1000));
  //   expires = "; expires=" + date.toUTCString();
  // }
  // document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

//"client_token"
export const removeCookie = (clientToken: string) => {}
