import axios from "axios";
import AuthUserBearerTokenRepository from "@dash/gov-plus__front-end__form/src/persistence/session/AuthUserBearerTokenRepository";
import { env } from "../env";

const FORM_PATH = "/form"
const UTM_PATH = "/utm"
const SUBSCRIPTION_PATH = "/subscriptionPlan"
const USER_PATH = "/user"
const OFFER_PATH = "/offer"
export const videoLinkFollowInstruction = `${env.REACT_APP_S3_VIDEOS}/follow+the+instruction+746.mp4`
const dashboard_back = env.REACT_APP_GOV_DASHBOARD;
const autofillMicroserviceUri = env.REACT_APP_AUTOFILL_MICROSERVICE_URI;
const gov_back = env.REACT_APP_GOV_MAIN_URL;
const subscription_back = env.REACT_APP_SUBSCRIPTION;
const tax_back = env.REACT_APP_SUBSCRIPTION;
const tracking_back = env.REACT_APP_GOV_MAIN_URL;
const patch_user_back = env.REACT_APP_GOV_MAIN_URL;

const apiInstanceHeaders = (bearerToken) =>
  bearerToken
    ? {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${bearerToken}`
    }
    : {
      "Content-Type": "application/json"
    }

const instance = () => axios.create({
  baseURL: dashboard_back,
  withCredentials: true,
  headers: apiInstanceHeaders(AuthUserBearerTokenRepository.getInstance().getValue())
});

const autofillMicroserviceInstance = () => axios.create({
  baseURL: autofillMicroserviceUri,
  withCredentials: true,
  headers: apiInstanceHeaders(AuthUserBearerTokenRepository.getInstance().getValue())
});

const headers = {
    "Content-Type": "application/json",
}

const formInstance = axios.create({
  baseURL: gov_back + FORM_PATH,
  withCredentials: true,
  headers: headers
});
const offerInstance = axios.create({
    baseURL: gov_back + OFFER_PATH,
    headers: headers
});
const subscriptionInstanceGOV = axios.create({
  baseURL: gov_back,
  withCredentials: true,
  headers: headers
});

const utmInstance = axios.create({
    baseURL: gov_back + UTM_PATH,
    withCredentials: true,
    headers: apiInstanceHeaders(env.REACT_APP_UTM_API_KEY ?? "")
});

const subscriptionInstance = () => axios.create({
    baseURL: subscription_back,
    headers: apiInstanceHeaders(AuthUserBearerTokenRepository.getInstance().getValue())
});

const subscriptionInstanceWithDataBody = () => axios.create({
    baseURL: subscription_back,
    headers: apiInstanceHeaders(AuthUserBearerTokenRepository.getInstance().getValue())
});
const insuranceInstance = () => axios.create({
    baseURL: subscription_back,
    headers: apiInstanceHeaders(AuthUserBearerTokenRepository.getInstance().getValue())
});

const userInstance = axios.create({
    baseURL: gov_back + USER_PATH,
    withCredentials: true,
    headers: headers
});

const taxInstance = axios.create({
    baseURL: tax_back,
    headers: headers
});

const trackingInstance = axios.create({
  baseURL: tracking_back,
  headers: headers
});

const patchUserInstance = axios.create({
  baseURL: patch_user_back,
  headers: headers
});

export const apiAxiosInstance = {
  autofillMicroserviceInstance: (method, urlPoint, params) => {
    return autofillMicroserviceInstance()[method](urlPoint, JSON.stringify(params))
  },
  dashboardBackInstance: (method, urlPoint, params) => {
    return instance()[method](urlPoint, JSON.stringify(params))
  },
  formsBackInstance: (method, urlPoint, params) => {
    return formInstance[method](urlPoint, JSON.stringify(params))
  },
  utmBackInstance: (method, urlPoint, params) => {
    return utmInstance[method](urlPoint, JSON.stringify(params))
  },
  subscriptionBackInstance: (method, urlPoint, params) => {
    return subscriptionInstance()[method](urlPoint, JSON.stringify(params))
  },
  subscriptionBackInstanceGOV: (method, urlPoint, params) => {
    return subscriptionInstanceGOV[method](urlPoint, JSON.stringify(params))
  },
  subscriptionInstanceWithDataBody: (method, urlPoint, params) => {
    return subscriptionInstance()[method](urlPoint, {data: params})
  },
  userBackInstance: (method, urlPoint, params) => {
    return userInstance[method](urlPoint, JSON.stringify(params))
  },
  insuranceBackInstance: (method, urlPoint, params) => {
      return insuranceInstance()[method](urlPoint, JSON.stringify(params))
  },
  taxBackInstance: (method, urlPoint, params) => {
      return taxInstance[method](urlPoint, JSON.stringify(params))
  },
  offerInstance: (method, urlPoint, params) => {
      return offerInstance[method](urlPoint, JSON.stringify(params))
  },
  trackingBackInstance: (method, urlPoint, params) => {
    return trackingInstance[method](urlPoint, JSON.stringify(params))
  },
    identityProtectionBackInstance:(method, urlPoint, params) => {
      return subscriptionInstance()[method](urlPoint, JSON.stringify(params))
    },
  patchUserInstance: (method, urlPoint, params) => {
    return patchUserInstance[method](urlPoint, JSON.stringify(params))
  }
}
