import { FC } from "react"
import {
  LayoutBaseContainerInterface,
  LayoutReviewPageEditBlockInterface,
} from "../../UploadPhotoService/interfaces/interfaces"

const ReviewPageEditBlockLayout: FC<LayoutReviewPageEditBlockInterface> = ({ className, children, props }) => {
  return (
    <div className={className} onClick={props?.onClick}>
      {children}
    </div>
  )
}

export default ReviewPageEditBlockLayout
