import BaseCookiesRepository from "./BaseCookiesRepository";

const EVER_FLOW_ID_VARS_KEY = "_ef_transaction_id";

class EverFlowIdRepository extends BaseCookiesRepository {

    static EVER_FLOW_ID_DEFAULT_VALUE = null;

    private static _instance: EverFlowIdRepository;

    private constructor() {
        super()
    }

    public static getInstance(): EverFlowIdRepository {
        if (!EverFlowIdRepository._instance) {
            EverFlowIdRepository._instance = new EverFlowIdRepository();
        }
        return EverFlowIdRepository._instance;
    }

    getKeyName(): string {
        return EVER_FLOW_ID_VARS_KEY;
    }
}

export default EverFlowIdRepository;
