import { useState } from "react"
import { optionsArrayFromMinMax } from "./helpers"
import { DateControllerType } from "./types"

const DateController: DateControllerType = (minStartDate, maxStartDate, defaultDate) => {
  const [minDate, setMinDate] = useState<number | undefined>(minStartDate)
  const [maxDate, setMaxDate] = useState<number | undefined>(maxStartDate)
  const [dateValue, setDateValue] = useState<number | undefined>(defaultDate)
  const startDates = optionsArrayFromMinMax(minDate, maxDate)
  const [dates, setDates] = useState<JSX.Element[] | undefined>(startDates)

  const setMinMaxDates = (min: number | undefined, max: number | undefined) => {
    if (min && max) {
      if (dateValue) {
        if (dateValue > max) {
          setDateValue(max)
        } else if (dateValue < min) {
          setDateValue(min)
        }
      }
      setMaxDate(max)
      setMinDate(min)
      setDates(optionsArrayFromMinMax(min, max))
    }
  }

  return {
    minDate,
    maxDate,
    setMaxDate,
    setMinDate,
    dateValue,
    setDateValue,
    dates,
    setDates,
    setMinMaxDates,
  }
}

export default DateController
