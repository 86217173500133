import React from "react"
import { Radio } from "antd"
import { FieldInterface } from "../../../../../../Redux/InterfacesEntity/field.interface"
import GRadioButton from "../../../../../atomicDesign/molecules/RadioButton/RadioButton";

type RadioChoosePackageComponentProps = {
  field: FieldInterface | any,
  option: {} | any,
}

// eslint-disable-next-line max-len
const RadioChoosePackageComponent: React.FunctionComponent<RadioChoosePackageComponentProps> = ({
  field,
  option,
}) => {
  return (
    <GRadioButton value={option.price} className={`${field.name}__option-container`}>
      <div className={`${field.name}__option-content`}>
          <div className={`${field.name}__option-header`}>

            {option.optionName && (
                <p
                    // eslint-disable-next-line max-len
                    className={`${field.name}__option-header-name`}
                >
                  {option.optionName}
                </p>
            )}

            {option?.descriptionOption && (
                <p
                    // eslint-disable-next-line max-len
                    className={`${field.name}__option-main-description`}
                >
                  {option?.descriptionOption}
                </p>
            )}
          </div>

          <div className={`${field.name}__option-prices`}>
            <div>
              {option.pricePeriod && (
                  <p
                      // eslint-disable-next-line max-len
                      className={`${field.name}__option-header-price--period`}
                  >
                    {`${option.currency}`}
                    <span>{`${option.pricePeriod}`}</span>
                  </p>
              )}
            </div>
            <div>
              {option.priceSavings && (
                  <p
                      // eslint-disable-next-line max-len
                      className={`${field.name}__option-header-price--savings`}
                  >
                    {`${option.currency}`}
                    <span>{`${option.priceSavings}`}</span>
                  </p>
              )}
              {option.price && (
                  <p
                      // eslint-disable-next-line max-len
                      className={`${field.name}__option-header-price--price`}
                  >
                    Pay {`${option.currency}`}{`${option.price}`} today
                  </p>
              )}
            </div>
          </div>

          {/*<div className={`${field.name}__option-prices`}>*/}

          {/*</div>*/}

          {/*<div className={`${field.name}__option-prices`}>*/}
          {/*  {option.priceSavings && (*/}
          {/*      <p*/}
          {/*          // eslint-disable-next-line max-len*/}
          {/*          className={`${field.name}__option-header-price ${field.name}__option-header-price--savings`}*/}
          {/*      >*/}
          {/*        {`${option.currency}`}*/}
          {/*        <span>{`${option.priceSavings}`}</span>*/}
          {/*      </p>*/}
          {/*  )}*/}
          {/*  {option.price && (*/}
          {/*      <p*/}
          {/*          // eslint-disable-next-line max-len*/}
          {/*          className={`${field.name}__option-header-price ${field.name}__option-header-price--price`}*/}
          {/*      >*/}
          {/*        Pay {`${option.currency}`} {`${option.price}`} today*/}
          {/*      </p>*/}
          {/*  )}*/}
          {/*</div>*/}
        </div>
    </GRadioButton>
  )
}

export default RadioChoosePackageComponent
