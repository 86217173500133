import React from "react"

export const SvgDragDrop = () => {
  return (
      <svg width="68" height="63" viewBox="0 0 68 63" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="5.5" y="16" width="58" height="38" rx="3" stroke="#9B9B9B" strokeWidth="2" strokeDasharray="4 4"/>
          <mask id="mask0_17598_79626"  maskUnits="userSpaceOnUse" x="21" y="8" width="27" height="26">
              <rect x="22.5" y="9" width="24" height="24" rx="1" fill="white" stroke="#9B9B9B" strokeWidth="2"/>
          </mask>
          <g mask="url(#mask0_17598_79626)">
              <rect x="22.5" y="9" width="24" height="24" rx="1" fill="white" stroke="#9B9B9B" strokeWidth="2"/>
              <g clipPath="url(#clip0_17598_79626)">
                  <path d="M42.5 32V30C42.5 28.9391 42.0786 27.9217 41.3284 27.1716C40.5783 26.4214 39.5609 26 38.5 26H30.5C29.4391 26 28.4217 26.4214 27.6716 27.1716C26.9214 27.9217 26.5 28.9391 26.5 30V32" stroke="#9B9B9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M34.5 23C36.7091 23 38.5 21.2091 38.5 19C38.5 16.7909 36.7091 15 34.5 15C32.2909 15 30.5 16.7909 30.5 19C30.5 21.2091 32.2909 23 34.5 23Z" stroke="#9B9B9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
          </g>
          <path d="M37.5 30L44.57 46.97L47.08 39.58L54.47 37.07L37.5 30Z" fill="white" stroke="#9B9B9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M47.5 40L53.5 46" stroke="#9B9B9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <defs>
              <clipPath id="clip0_17598_79626">
                  <rect width="28" height="28" fill="white" transform="translate(20.5 12)"/>
              </clipPath>
          </defs>
      </svg>

  )
}
