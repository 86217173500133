/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { FieldInterface } from "../../../../Redux/InterfacesEntity/field.interface"
import { setValueCurrentField } from "../../../../Redux/store/form/form.actions"
import { getOptionsName, getWrapperStaticOptions, trimOption } from "./createOptions"
import SelectComponentRender from "./SelectComponentRender/SelectComponentRender"
import { useDefaultOption } from "./useDefaultOption"
import { hiddenField, setPrefilledValueInSubmitObject } from "../InputComponent/serviceInput"

type SelectComponentProps = {
  field: FieldInterface,
  value: string,
  dynamicOptionsForSelect: {} | any,
  dispatch: any,
  validationRule?: any,
  staticOptionsForSelect: {}[] | any,
  submitObject: {} | any,
  instanceForm: any,
  nameActualInput: string,
  tagStoreWithFields: { [key: string]: string },
  required: boolean,
}

const SelectComponent: React.FunctionComponent<SelectComponentProps> = ({
  field,
  value,
  dynamicOptionsForSelect,
  dispatch,
  validationRule,
  staticOptionsForSelect,
  submitObject,
  instanceForm,
  tagStoreWithFields,
  required,
}) => {
  const statusTagFields = useMemo(() => hiddenField(field?.tag, tagStoreWithFields), [field?.tag, tagStoreWithFields])
  useEffect(() => {
    setPrefilledValueInSubmitObject(field.name, field.tag, setValueCurrentField, tagStoreWithFields, dispatch)
  }, [dispatch, field.name, field.tag, tagStoreWithFields])

  const getOptionForPrevName = (objectWithBlocks: {} | any) => {
    const filterOption = Object.entries(objectWithBlocks)
      .map((item: {} | any) => [item[0], Object.values(item[1])])
      .filter((item: [] | any) => item[1][0] && item[1][item[1].length - 1])

    const stringValueForOptions = filterOption.map((item: [] | any) => [
      item[0],
      item[1].reduce((acc: string, value: string | any) => {
        if (item[1][0] && item[1][item[1].length - 1]) return acc + " " + value
        else return acc + " "
      }, ""),
    ])
    return stringValueForOptions
  }

  const getValueForSubmit = (value: string) => {
    if (value === "block_current_name") return "new"
    if (value === "block_previous_name") return "previous"
    if (value === "block_name_birth") return "birth"
    if (value === "block_name_other") return "other"
    return "not"
  }

  const wrapperStaticOptionsForSelect = getWrapperStaticOptions(staticOptionsForSelect, field.name)

  interface CurrentStateInterface {
    name: string;
    state_code: string;
  }

  // for autocomplete by post code
  const [autocompleteValueShort, setAutocompleteValueShort] = useState("")
  const [render, setRender] = useState(true)
  const [timeId, setTimeId]: any = useState(null)
  useEffect(
    () => {
      if (field.autocompleteObserver && field && submitObject[`${field.name}`] !== autocompleteValueShort) {
        const currentState: CurrentStateInterface = wrapperStaticOptionsForSelect?.data?.find(
          (item: any) => item.state_code === submitObject[`${field.name}`]
        ) ?? { name: "", state_code: "" }
        if (currentState?.name && currentState?.state_code) {
          dispatch(setValueCurrentField(`${field.name}__full_name`, currentState?.name))
          setAutocompleteValueShort(currentState?.state_code)

          setRender(false)
          setTimeId(setTimeout(() => setRender(true), 0))
          // instanceForm.setFields({name: field.name})
        }
      }
      return () => clearTimeout(timeId)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, field.autocompleteObserver, field.name, staticOptionsForSelect, submitObject]
  )

  // for hard value or defaultDynamicValue
  useEffect(() => {
    if (field.change_default_dynamic_value) {
      dispatch(setValueCurrentField(`default_dynamic_value`, `default`))
    }
    if (
      (field.hard_value && field.hard_value !== submitObject[`${field.name}__full_name`]) ||
      (field.defaultDynamicValue && submitObject["default_dynamic_value"])
    ) {
      const defaultValue = getOptionsName(
        trimOption(field.hard_value || field.defaultDynamicValue, field.submitAdditionalOption, dispatch, field.name)
      )
      dispatch(setValueCurrentField(field.name, defaultValue.submitValue))
      dispatch(setValueCurrentField(`${field.name}__full_name`, defaultValue.renderValue))
      dispatch(setValueCurrentField(`default_dynamic_value`, ``))
      setRender(false)
      setTimeId(setTimeout(() => setRender(true), 0))
    }
    return () => clearTimeout(timeId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    field,
    field.hard_value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    submitObject[`${field.name}`],
  ])

  useDefaultOption(field, submitObject, dispatch, statusTagFields)

  if (statusTagFields) {
    return null
  }

  // it need for initial autocomplete value select
  return (
    <>
      {render && (
        <SelectComponentRender
          field={field}
          staticOptionsForSelect={staticOptionsForSelect}
          dynamicOptionsForSelect={dynamicOptionsForSelect}
          submitObject={submitObject}
          trimOption={trimOption}
          getOptionForPrevName={getOptionForPrevName}
          getValueForSubmit={getValueForSubmit}
          required={required || false}
        />
      )}
      {!render && (
        <SelectComponentRender
          field={field}
          staticOptionsForSelect={staticOptionsForSelect}
          dynamicOptionsForSelect={dynamicOptionsForSelect}
          submitObject={submitObject}
          trimOption={trimOption}
          getOptionForPrevName={getOptionForPrevName}
          getValueForSubmit={getValueForSubmit}
          required={required || false}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  submitObject: state.form.submitObject,
  dynamicOptionsForSelect: state.select.block_diff_name_prev,
  staticOptionsForSelect: state.step.optionsForSelect,
  instanceForm: state.form.instanceForm,
  nameActualInput: state.step.nameActualInput,
  currentField: state.form.helper,
  tagStoreWithFields: state.form.tagStoreWithFields,
})

export default connect(mapStateToProps)(SelectComponent)
