import { interceptorFunc } from "./uploadServise"
import { env } from "../../env"

const axios = require("axios")
const hostDashboard = env.REACT_APP_HOST_DASHBOARD ?? "https://dashboard-back.govplus.dev"
const hostCountryCodes =
  env.REACT_APP_ARRAY_COUNTRY_CODE ?? "https://staging-api-files.govplus.com/static/json/country_code.json"

export default class ServiceDashboard {
  static interceptor = interceptorFunc()

  static checkUserByEmail = async (submitObj: {}) => {
    const response = await axios.post(`${hostDashboard}/users/checkUserExists`, JSON.stringify(submitObj))
    return response.data
  }

  static logInCustomerByEmail = async (submitObj: {}) => {
    const response = await axios.post(`${hostDashboard}/authenticate`, JSON.stringify(submitObj))
    return response.data
  }

  static checkIsTokenActive = async (submitObj: {}) => {
    try {
      const response = await axios.post(`${hostDashboard}/users/checkIsTokenActive`, JSON.stringify(submitObj))
      return response.data
    } catch (e) {
      window.location.href = env.REACT_APP_DASHBOARD ?? "https://dashboard-dev.govplus.com/signin"
    }
  }

  static getArrayCountryCodesService = async () => {
    const response = await axios.get(hostCountryCodes)
    return response.data
  }
}
