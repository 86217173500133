import "./CreditCardForm.scss"
import {CreditCardFormProps} from "./CreditCardFormProps";
import React, {FC} from "react";
import GCardNumber from "../../molecules/CardNumber/CardNumber";
import GCardSecurityCode from "../../molecules/CardSecurityCode/CardSecurityCode";
import GCardZipCode from "../../molecules/CardZipCode/CardZipCode";
import GExpirationDate from "../../molecules/ExpirationDate/ExpirationDate";
import SealHandler, {DEFAULT_NORTON_IMAGE, SEAL_NORTON_ID, SealType} from "../../molecules/Seal/SealHandler";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import PaymentSecurityTag from "../../molecules/PaymmentSecurityTag/PaymentSecurityTag";

const GCreditCardForm: FC<CreditCardFormProps> = ({title}) => {
    return (
        <div className={'CreditCardForm'}>
            {title && (
                <div className={'title'}>
                    <GText text={title} size={SIZE.HEADING_XS}
                           color={COLORS_NEW.BLACK_1000}/>
                </div>
            )}
            <PaymentSecurityTag />
            <div className={'row card-number'}>
                <GCardNumber/>
                <div className={'seal'}>
                    <SealHandler type={SealType.NORTON} id={SEAL_NORTON_ID} defaultImage={DEFAULT_NORTON_IMAGE} />
                </div>
            </div>
            <div className={'row'}>
                <GExpirationDate/>
                <GCardSecurityCode/>
                <GCardZipCode/>
            </div>
        </div>
    )
}
export default GCreditCardForm;