import React from "react"
import "./AgreementModalBlock.scss"
import { AgreementModalBlockProps } from "./AgreementModalBlock.props"
import { useHistory } from "react-router-dom"
import { Modal } from "antd";

const AgreementModalBlock: React.FC<AgreementModalBlockProps> = ({ textBeforeLink, linkText, textAfterLink }) => {
  const history = useHistory();

  return (
    <p className="AgreementModalBlock__Text">
      {textBeforeLink}
      <span
        className="AgreementModalBlock__Link"
        onClick={() => {
          history.push("/identityTheftProtectionPolicy")
        }}
        style={{ cursor: "pointer" }}
      >
        {linkText}
      </span>
      {textAfterLink}
    </p>
  );
};

export default AgreementModalBlock
