import SubscriptionCancellationConfirmed from "./SubscriptionCancellationConfirmed";
import IEvent from "../IEvent";
import BaseBuilder from "../BaseBuilder";

export default class SubscriptionCancellationConfirmedBuilder extends BaseBuilder {
    private _subscriptionId: string | undefined;

    /**
     * Set subscription id
     * @param subscriptionId
     * @returns {SubscriptionCancellationConfirmedBuilder}
     */
    setSubscriptionId(subscriptionId: string | undefined) {
        this._subscriptionId = subscriptionId;
        return this;
    }

    /**
     * Set ip address
     * @param ipAddress
     * @returns {SubscriptionCancellationConfirmedBuilder}
     */
    setIpAddress(ipAddress: string | undefined) {
        this._ipAddress = ipAddress;
        return this;
    }

    /**
     * Build
     * @returns {IEvent}
     * @description This method is used to build
     */
    build(): IEvent {
        return new SubscriptionCancellationConfirmed(this._subscriptionId, this._ipAddress);
    }
}
