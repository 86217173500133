import FlattenDataDto from "../../../dto/FLattenDataDto/FlattenDataDto";

const arraySimpleFields = ["input"]
const arrayCommunicationFieldsTag = [FlattenDataDto.VAR_PHONE_NUMBER, FlattenDataDto.VAR_EMAIL]
const arrayCommunicationFields = [{tag: FlattenDataDto.VAR_PHONE_NUMBER, name: "phone"}, {tag: FlattenDataDto.VAR_EMAIL, name: "email"}]

interface blocksWithFieldsInterface {
  name: string;
  fields?: [];
  tag?: string
  type: string;
}

interface checkPrefilledField {
  field: blocksWithFieldsInterface;
  flattenData: { [key: string]: string };
  prefilledStore: { [key: string]: string };
}

interface checkPrefilledBlock {
  block: blocksWithFieldsInterface;
  flattenData: { [key: string]: string };
  prefilledStore: { [key: string]: string };
}

const checkPrefilledField = ({field, flattenData, prefilledStore}: checkPrefilledField) => {
  if (field?.tag && flattenData?.[field?.tag ?? ""] && arrayCommunicationFieldsTag.includes(field?.tag ?? "") ) {
    prefilledStore[field?.name] = flattenData[field?.tag]
    return null
  }
  return field
}

const showCommunicationBlock = ({ block, flattenData, prefilledStore }: checkPrefilledBlock) => {
  arrayCommunicationFields.forEach((field: {name: string, tag: string}) => prefilledStore[field?.name ?? ""] = flattenData[field?.tag ?? ''])
  if(arrayCommunicationFieldsTag.filter((tag: string) => !flattenData[tag])?.length) {
    return block
  }
  return null
}

const findCaseForBlockOrFieldEIN = ({ blockOrField, flattenData, prefilledStore }: any) => {
  return [
    {
      condition: blockOrField.name === "communication_block",
      action: () => {
        return showCommunicationBlock({ block: blockOrField, flattenData, prefilledStore })
      },
    },
    {
      condition: blockOrField.name !== "communication_block",
      action: () => {
        if(hideEmptyBlocksEIN({arrayBlocks: blockOrField.fields ?? [], flattenData, prefilledStore})) {
          return  {
            ...blockOrField,
            fields: hideEmptyBlocksEIN({arrayBlocks: blockOrField.fields ?? [], flattenData, prefilledStore})
          }
        }
        return {...blockOrField}
      }
    },
    {
      condition: arraySimpleFields.some((field: string) => field === blockOrField.type),
      action: () => {
        return checkPrefilledField({field: blockOrField, flattenData, prefilledStore})
      }
    },
  ]
    .find(({ condition }) => condition)
    ?.action()
}

export const hideEmptyBlocksEIN = ({ arrayBlocks, flattenData, prefilledStore }: any) => {
  const filteredArray: any = arrayBlocks.reduce((acc: any, blockOrField: any) => {
    if (findCaseForBlockOrFieldEIN({ blockOrField, flattenData, prefilledStore })) {
      return [...acc, findCaseForBlockOrFieldEIN({ blockOrField, flattenData, prefilledStore })]
    }
    return [...acc]
  }, [])

  return filteredArray.length ? filteredArray : null
}
