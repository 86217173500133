import {
  FORMS_SAVE_USER_DATA,
  FORMS_SAVE_USER_DATA_SUCCESSES,
  FORMS_SAVE_USER_DATA_UNSUCCESSES, GET_PRODUCTS, GET_USER_DATA_FLATERN,
  SAVE_USER_DATA_FLATERN, SAVE_USER_DATA_FLATERN_FAILED,
  SAVE_USER_DATA_FLATERN_SUCCESSES,
} from "../../constants/ActionTypes";

export const saveUserDataForForm = (payload) => {
  return {
    type: FORMS_SAVE_USER_DATA,
    payload
  };
};

export const saveUserDataForFormSuccesses = (payload) => {
  return {
    type: FORMS_SAVE_USER_DATA_SUCCESSES,
    payload
  };
};

export const saveUserDataForFormUnsuccesses = () => {
  return {
    type: FORMS_SAVE_USER_DATA_UNSUCCESSES,
  };
};

export const saveUserDataFlattern = () => {
  return {
    type: SAVE_USER_DATA_FLATERN,
  };
};

export const saveUserDataFlattenSuccesses = (payload) => {
  return {
    type: SAVE_USER_DATA_FLATERN_SUCCESSES,
    payload
  };
};

export const saveUserDataFlattenFailed = (err) => {
  return {
    type: SAVE_USER_DATA_FLATERN_FAILED,
    err
  };
};
export const getUserFlattenData = (authToken) => {
  return {
    type: GET_USER_DATA_FLATERN,
    authToken
  };
};