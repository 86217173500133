import React from "react"

interface ISvgChevronLeft {
  width?: number;
  height?: number;
  color?: string;
}

export const SvgChevronLeft = ({ width = 20, height = 20, color = "#216DE3" }: ISvgChevronLeft
) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 5.5L8 10.5L13 15.5" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
