import {
    GET_IDENTITIES,
    GET_IDENTITIES_SUCCESS,
    GET_IDENTITIES_FAILURE,
    GET_RECORDS,
    GET_RECORDS_SUCCESS,
    GET_RECORDS_FAILURE,
    GET_ALERTS,
    GET_ALERTS_SUCCESS,
    GET_ALERTS_FAILURE,
    GET_EMAIL_BREACHES,
    GET_EMAIL_BREACHES_SUCCESS,
    GET_EMAIL_BREACHES_FAILURE,
    POST_RECORD,
    POST_RECORD_SUCCESS,
    POST_RECORD_FAILURE,
    DELETE_RECORD,
    DELETE_RECORD_SUCCESS,
    DELETE_RECORD_FAILURE,
    DISMISS_ALERT,
    DISMISS_ALERT_SUCCESS,
    DISMISS_ALERT_FAILURE,
    GET_CREDIT_SCORES,
    GET_CREDIT_SCORES_SUCCESS,
    GET_CREDIT_SCORES_FAILURE,
    VERIFY_USER_CREDIT_SCORES,
    VERIFY_USER_CREDIT_SCORES_SUCCESS,
    VERIFY_USER_CREDIT_SCORES_FAILURE,
    PATCH_USER_BUNDLE,
    PATCH_USER_BUNDLE_SUCCESS,
    PATCH_USER_BUNDLE_FAILURE,
    PATCH_USER_ADDRESS,
    PATCH_USER_ADDRESS_SUCCESS,
    PATCH_USER_ADDRESS_FAILURE,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAILURE,
    SET_MISSING_FIELDS,
    GET_MISSING_FIELDS,
    SET_MISSING_FIELDS_DATA,
    CHECK_VERIFICATION_RESULT,
    CHECK_VERIFICATION_RESULT_SUCCESS,
    CHECK_VERIFICATION_RESULT_FAILURE,
    USER_REDIRECTED_TO_THANK_YOU_PAGE_SUCCESS,
    RESET_USER_REDIRECTED_TO_THANK_YOU_PAGE_SUCCESS,
    SET_SCROLL_TARGET
} from "../../constants/ActionTypes";

const INIT_STATE = {
    identitiesLoader: false,
    identities: [],
    identitiesError: null,
    recordsLoader: false,
    records: [],
    recordsError: null,
    alertsLoader: false,
    alerts: [],
    alertsError: null,
    emailBreachesLoader: false,
    emailBreaches: [],
    emailBreachError: null,
    postRecordLoader: false,
    postRecordFailure: null,
    deleteRecordFailure: null,
    dismissAlertLoader: false,
    creditScoresLoader: false,
    creditScores: [],
    creditScoresError: null,
    verifyUserCreditScoresLoader: false,
    verifyUserCreditScoresData: null,
    verifyUserCreditScoresError: null,
    patchUserBundleLoader: false,
    patchUserBundleUpdated: null,
    patchUserBundleError: null,
    patchUserAddressLoader: false,
    patchUserAddressUpdated: false,
    patchUserAddressFailure: null,
    updateCustomerLoader: false,
    customerUpdated: null,
    customerUpdateError: null,
    missingFields: [],
    missingFieldsData: {},
    openedMissingFieldsModal: false,
    verificationResult: null,
    verificationError: null,
    isUserRedirectedToThankYouPage: false,
    scrollTarget: null,
};

const IDPReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_IDENTITIES: {
            return {
                ...state,
                identitiesLoader: true,
            };
        }
        case GET_IDENTITIES_SUCCESS: {
            return {
                ...state,
                identitiesLoader: false,
                identities: action.payload,
            };
        }
        case GET_IDENTITIES_FAILURE: {
            return {
                ...state,
                identitiesLoader: false,
                identitiesError: action.payload,
            };
        }
        case GET_RECORDS: {
            return {
                ...state,
                recordsLoader: true,
            };
        }
        case GET_RECORDS_SUCCESS: {
            return {
                ...state,
                recordsLoader: false,
                records: action.payload,
            };
        }
        case GET_RECORDS_FAILURE: {
            return {
                ...state,
                recordsLoader: false,
                recordsError: action.payload,
            };
        }
        case GET_ALERTS: {
            return {
                ...state,
                alertsLoader: true,
            };
        }
        case GET_ALERTS_SUCCESS: {
            return {
                ...state,
                alertsLoader: false,
                alerts: action.payload,
            };
        }
        case GET_ALERTS_FAILURE: {
            return {
                ...state,
                alertsLoader: false,
                alertsError: action.payload,
            };
        }
        case GET_EMAIL_BREACHES: {
            return {
                ...state,
                emailBreachesLoader: true,
            };
        }
        case GET_EMAIL_BREACHES_SUCCESS: {
            return {
                ...state,
                emailBreachesLoader: false,
                emailBreaches: action.payload,
            };
        }
        case GET_EMAIL_BREACHES_FAILURE: {
            return {
                ...state,
                emailBreachesLoader: false,
                emailBreachError: action.payload,
            };
        }
        case POST_RECORD: {
            return {
                ...state,
                recordsLoader: true,
            };
        }
        case POST_RECORD_SUCCESS: {
            return {
                ...state,
                recordsLoader: false,
            };
        }
        case POST_RECORD_FAILURE: {
            return {
                ...state,
                recordsLoader: false,
                postRecordFailure: action.payload
            };
        }
        case DELETE_RECORD: {
            return {
                ...state,
                recordsLoader: true,
            };
        }
        case DELETE_RECORD_SUCCESS: {
            return {
                ...state,
                recordsLoader: false,
            };
        }
        case DELETE_RECORD_FAILURE: {
            return {
                ...state,
                recordsLoader: false,
                deleteRecordFailure: action.payload
            };
        }
        case DISMISS_ALERT: {
            return {
                ...state,
                dismissAlertLoader: true,
            };
        }
        case DISMISS_ALERT_SUCCESS: {
            return {
                ...state,
                dismissAlertLoader: false,
            };
        }
        case DISMISS_ALERT_FAILURE: {
            return {
                ...state,
                dismissAlertLoader: false,
            };
        }
        case GET_CREDIT_SCORES: {
            return {
                ...state,
                creditScoresLoader: true,
            };
        }
        case GET_CREDIT_SCORES_SUCCESS: {
            return {
                ...state,
                creditScoresLoader: false,
                creditScores: action.payload,
            };
        }
        case GET_CREDIT_SCORES_FAILURE: {
            return {
                ...state,
                creditScoresLoader: false,
                creditScoresError: action.payload,
            };
        }
        case VERIFY_USER_CREDIT_SCORES: {
            return {
                ...state,
                verifyUserCreditScoresLoader: true,
            };
        }
        case VERIFY_USER_CREDIT_SCORES_SUCCESS: {
            return {
                ...state,
                verifyUserCreditScoresLoader: false,
                verifyUserCreditScoresData: action.payload,
            };
        }
        case VERIFY_USER_CREDIT_SCORES_FAILURE: {
            return {
                ...state,
                verifyUserCreditScoresLoader: false,
                verifyUserCreditScoresError: action.payload,
            };
        }
        case PATCH_USER_BUNDLE: {
            return {
                ...state,
                patchUserBundleLoader: true,
            };
        }
        case PATCH_USER_BUNDLE_SUCCESS: {
            return {
                ...state,
                patchUserBundleLoader: false,
                patchUserBundleUpdated: action.payload,
            };
        }
        case PATCH_USER_BUNDLE_FAILURE: {
            return {
                ...state,
                patchUserBundleLoader: false,
                patchUserBundleError: action.payload,
            };
        }
        case PATCH_USER_ADDRESS: {
            return {
                ...state,
                patchUserAddressLoader: true,
            };
        }
        case PATCH_USER_ADDRESS_SUCCESS: {
            return {
                ...state,
                patchUserAddressLoader: false,
                patchUserAddressUpdated: action.payload
            };
        }
        case PATCH_USER_ADDRESS_FAILURE: {
            return {
                ...state,
                patchUserAddressLoader: false,
                patchUserAddressFailure: action.payload
            };
        }
        case UPDATE_CUSTOMER: {
            return {
                ...state,
                updateCustomerLoader: true,
                customerUpdated: null,
            };
        }
        case UPDATE_CUSTOMER_SUCCESS: {
            return {
                ...state,
                updateCustomerLoader: false,
                customerUpdated: action.payload,
            };
        }
        case UPDATE_CUSTOMER_FAILURE: {
            return {
                ...state,
                updateCustomerLoader: false,
                customerUpdated: false,
                customerUpdateError: action.payload
            };
        }

        case SET_MISSING_FIELDS: {
            return {
                ...state,
                missingFields: action.payload,
            };
        }

        case SET_MISSING_FIELDS_DATA: {
            return {
                ...state,
                missingFieldsData: action.payload,
            };
        }

        case GET_MISSING_FIELDS: {
            return {
                ...state,
                missingFields: action.payload,
            };
        }

        case CHECK_VERIFICATION_RESULT: {
            return {
                ...state,
                verificationResult: null,
                verificationError: null,
            };
        }

        case CHECK_VERIFICATION_RESULT_SUCCESS: {
            return {
                ...state,
                verificationResult: action.payload,
            };
        }

        case CHECK_VERIFICATION_RESULT_FAILURE: {
            return {
                ...state,
                verificationError: action.payload,
            };
        }

        case USER_REDIRECTED_TO_THANK_YOU_PAGE_SUCCESS: {
            return {
                ...state,
                isUserRedirectedToThankYouPage: true,
            };

        }
        case RESET_USER_REDIRECTED_TO_THANK_YOU_PAGE_SUCCESS:
            return {
                ...state,
                isUserRedirectedToThankYouPage: false,
            };
        case SET_SCROLL_TARGET:
                return {
                  ...state,
                  scrollTarget: action.payload
                };

        default:
            return state;
    }
};

export default IDPReducer;