import React from "react"
import {SIZE} from "../../../atomicDesign/types/types"
import {COLORS_NEW} from "../../../atomicDesign/types/Colors"
import "./PendingReceivingConfirmationComponent.scss"
import IconsSvg from "../../../atomicDesign/atoms/IconsSvg/IconsSvg";
import ApplicationCardTemplate from "../../../atomicDesign/templates/ApplicationCardTemplate/ApplicationCardTemplate"
import GText from "../../../atomicDesign/atoms/Text/Text"
import arrow from "../../../../../src/assets/image/line_plus_arrow.webp"
import GButton from "../../../atomicDesign/molecules/Button/GButton"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
import { getImageFromLocalStorage } from "../../../../util/ProcessingTrackingHelper"
import { ICONS_SVG_TYPE } from "../../../atomicDesign/atoms/IconsSvg/IconsSvgTypes";

type PendingReceivingConfirmationComponentProps = {
  nameProduct: string,
  title: string,
  subtitle: string,
  textButton: string,
  onClickBtn: () => void
  loadingBtnState: string,
  imageLeft: string
  showEstimatedDelay?: boolean
}

const PendingReceivingConfirmationComponent: React.FunctionComponent<PendingReceivingConfirmationComponentProps> = 
  ({nameProduct,
    title,
    subtitle,
    textButton,
    onClickBtn,
    loadingBtnState,
    imageLeft,
    showEstimatedDelay = false
  }) => {
  const breakpoint = useBreakpoint()
  function applicationCardComponent() {
    return (
      <ApplicationCardTemplate
          image={getImageFromLocalStorage(nameProduct)}
          productName={title}
        >
          {
            showEstimatedDelay && 
              <div className="GPendingReceivingConfirmation__icon-text">
                <IconsSvg type={ICONS_SVG_TYPE.USERS} width={22} height={22} color= {COLORS_NEW.GREEN_700}/>
                <GText text={subtitle} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.GREEN_700} />
              </div>
            
          }
          
        </ApplicationCardTemplate>)
  } 
  return (
    <div className={`GPendingReceivingConfirmation`}>
      { breakpoint.xs ? (
        <div className="GPendingReceivingConfirmation__container-flex-center GPendingReceivingConfirmation__mobileView">
          <img className="GPendingReceivingConfirmation__mobileView__hand" src={imageLeft} />
          <img className="GPendingReceivingConfirmation__mobileView__arrow-m" src={arrow} /> 
          {applicationCardComponent()}
        </div>
      )
      : (
        <div className="GPendingReceivingConfirmation__container-flex-center GPendingReceivingConfirmation__standardView">
          <img className="GPendingReceivingConfirmation__standardView__hand" src={imageLeft} />
          <img className={breakpoint.xl ? "GPendingReceivingConfirmation__standardView__arrow-l": "GPendingReceivingConfirmation__standardView__arrow-s"} src={arrow} /> 
          {applicationCardComponent()}
        </div>
      )}
      <div className={breakpoint.xs 
        ? "GPendingReceivingConfirmation__button GPendingReceivingConfirmation__mobileView__button"
        : "GPendingReceivingConfirmation__button GPendingReceivingConfirmation__standardView__button"}>
        <GButton
          text={textButton}
          textSize={SIZE.PARAGRAPH_BOLD_16}
          size={"large"}
          click={onClickBtn}
          loading={loadingBtnState === "loading" ? true : false}
          style={{
              backgroundColor: COLORS_NEW.ROYAL_BLUE_600,
              height: 56,
              width: 'fit-content',
            
          }}
          showIcon={false}
        />
      </div>
    </div>
  )
}
export default PendingReceivingConfirmationComponent
