// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const GET_USER_IP = 'GET_USER_IP';
export const GET_USER_IP_SUCCESS = 'GET_USER_IP_SUCCESS';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';

export const CONFIRM_USER_SMS = 'CONFIRM_USER_SMS';
export const CONFIRM_USER_SMS_SUCCESS = 'CONFIRM_USER_SMS_SUCCESS';

export const GET_USER_DATA_FROM_LINK = 'GET_USER_DATA_FROM_LINK';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const CHECK_USER_TOKEN = 'CHECK_USER_TOKEN';
export const USER_TOKEN_EXPAIRED = 'USER_TOKEN_EXPAIRED';


export const USER_LOG_IN_DATA_SAVE = 'USER_LOG_IN_DATA_SAVE';
export const USER_LOG_IN_DATA_CLEAR = 'USER_LOG_IN_DATA_CLEAR';
export const USER_PRE_AUTH = 'USER_PRE_AUTH';
export const USER_PRE_AUTH_SUCCESS = 'USER_PRE_AUTH_SUCCESS';
export const USER_PRE_AUTH_FAILED = 'USER_PRE_AUTH_FAILED';

export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';


export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCSSES = 'GET_NOTIFICATIONS_SUCCSSES';

export const FORMS_SAVE_USER_DATA = 'FORMS_SAVE_USER_DATA';
export const FORMS_SAVE_USER_DATA_SUCCESSES = 'FORMS_SAVE_USER_DATA_SUCCESSES';
export const FORMS_SAVE_USER_DATA_UNSUCCESSES = 'FORMS_SAVE_USER_DATA_UNSUCCESSES';
export const SAVE_USER_DATA_FLATERN = 'SAVE_USER_DATA_FLATERN';
export const GET_USER_DATA_FLATERN = 'GET_USER_DATA_FLATERN';
export const SAVE_USER_DATA_FLATERN_SUCCESSES = 'SAVE_USER_DATA_FLATERN_SUCCESSES';
export const SAVE_USER_DATA_FLATERN_FAILED = 'SAVE_USER_DATA_FLATERN_FAILED';


export const GET_USERS_SUCCSSES = 'GET_NOTIFICATIONS_SUCCSSES';

// Account Settings
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const HIDE_MESSAGE_PASSWORD_CHANGE = "HIDE_MESSAGE_PASSWORD_CHANGE";

// get products
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const RESET_INSTRUCTIONS = 'RESET_INSTRUCTIONS';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SHOW_GROWL_MESSAGE = 'SHOW_GROWL_MESSAGE';
export const SEND_APPOINTMENT = 'SEND_APPOINTMENT';

export const GET_PRODUCTS_ADDITIONAL_INFO = 'GET_PRODUCTS_ADDITIONAL_INFO';
export const GET_PRODUCTS_SUCCSSES = 'GET_PRODUCTS_SUCCSSES';
export const GET_GOVERNMENT_PRODUCTS = 'GET_GOVERNMENT_PRODUCTS';
export const GET_GOVERNMENT_PRODUCTS_SUCCSSES = 'GET_GOVERNMENT_PRODUCTS_SUCCSSES';
export const GET_WEBFLOW_SOLUTIONS_DATA_IN_DASH = 'GET_WEBFLOW_SOLUTIONS_DATA_IN_DASH';
export const GET_FELLING_PRESENT = 'GET_FELLING_PRESENT';
export const GET_FELLING_PRESENT_SUCCSSES = 'GET_FELLING_PRESENT_SUCCSSES';
export const SET_BREADCRUMBS_VALUE = 'SET_BREADCRUMBS_VALUE';
export const FIRST_SELECTED_PRODUCT = 'FIRST_SELECTED_PRODUCT';

export const CHECK_DUPLICATE_PRODUCT = 'CHECK_DUPLICATE_PRODUCT';
export const CHECK_DUPLICATE_PRODUCT_SUCCESS = 'CHECK_DUPLICATE_PRODUCT_SUCCESS';
export const SET_SELECTED_FORM_ID = 'SET_SELECTED_FORM_ID';

export const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFERS_SUCCSSES = 'GET_OFFERS_SUCCSSES';

//check Forms actions
export const CHECK_FORMS_GOV = 'CHECK_FORMS_GOV';
export const CHECK_FORMS_GOV_SUCCSSES = 'CHECK_FORMS_GOV_SUCCSSES';

//check status actions
export const CHECK_STATUS = 'CHECK_STATUS';
export const CHECK_STATUS_SUCCSSES = 'CHECK_STATUS_SUCCSSES';
export const CHECK_STATUS_ERR = 'CHECK_STATUS_ERR';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';

//Billings
export const GET_BILLING_INFORMATION = 'GET_BILLING_INFORMATION';
export const GET_BILLING_INFORMATION_SUCCESS = "GET_BILLING_INFORMATION_SUCCESS";
export const CONFIRM_SUBSCRIPTION = "CONFIRM_SUBSCRIPTION";
export const SET_PAYMENT_ERROR = "SET_PAYMENT_ERROR";
export const GET_FOOTER_DATA = "GET_FOOTER_DATA";
export const GET_FOOTER_DATA_SUCCESS = "GET_FOOTER_DATA_SUCCESS";
export const CHECK_DUPLICATE_PRODUCT_CLEAR = "CHECK_DUPLICATE_PRODUCT_CLEAR";

export const CHECK_USER_TOKEN_FROM_COOKIES = "CHECK_USER_TOKEN_FROM_COOKIES";
export const CHECK_USER_TOKEN_FROM_COOKIES_SUCCESS = "CHECK_USER_TOKEN_FROM_COOKIES_SUCCESS";
export const CHECK_USER_TOKEN_FROM_COOKIES_FAILED = "CHECK_USER_TOKEN_FROM_COOKIES_FAILED";

export const CHECK_USER_EMAIL_EXIST = "CHECK_USER_EMAIL_EXIST";
export const CHECK_USER_EMAIL_EXIST_SUCCESS = "CHECK_USER_EMAIL_EXIST_SUCCESS";
export const CHECK_USER_EMAIL_EXIST_FAILED = "CHECK_USER_EMAIL_EXIST_FAILED";

export const CHECK_USER_ACTIVATE_LINK = "CHECK_USER_ACTIVATE_LINK";
export const CHECK_USER_ACTIVATE_LINK_RESULT = "CHECK_USER_ACTIVATE_LINK_RESULT";

export const SET_CHECK_STATUS_EMAIL_PRODUCT_ID = "SET_CHECK_STATUS_EMAIL_PRODUCT_ID";
export const SET_GENERATED_RETARGETING_LINK = "SET_GENERATED_RETARGETING_LINK";
export const GET_RETARGETING_DATA = "GET_RETARGETING_DATA";
export const GET_RETARGETING_DATA_SUCCESS = "GET_RETARGETING_DATA_SUCCESS";
export const CLEAR_STORAGE = "CLEAR_STORAGE";
export const SET_IS_AUTH_FINISHED = "SET_IS_AUTH_FINISHED"

//get tax page link
export const CREATE_TAX_LINK_START = 'CREATE_TAX_LINK_START';
export const CREATE_TAX_LINK_SUCCESS = 'CREATE_TAX_LINK_SUCCESS';
export const CREATE_TAX_LINK_ERROR = 'CREATE_TAX_LINK_ERROR';

//get identity protection services
export const GET_IDENTITIES = 'GET_IDENTITIES';
export const GET_IDENTITIES_SUCCESS = 'GET_IDENTITIES_SUCCESS';
export const GET_IDENTITIES_FAILURE = 'GET_IDENTITIES_FAILURE';

export const GET_RECORDS = 'GET_RECORDS';
export const GET_RECORDS_SUCCESS = 'GET_RECORDS_SUCCESS';
export const GET_RECORDS_FAILURE = 'GET_RECORDS_FAILURE';

export const GET_ALERTS = 'GET_ALERTS';
export const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';
export const GET_ALERTS_FAILURE = 'GET_ALERTS_FAILURE';

export const GET_EMAIL_BREACHES = 'GET_EMAIL_BREACHES';
export const GET_EMAIL_BREACHES_SUCCESS = 'GET_EMAIL_BREACHES_SUCCESS';
export const GET_EMAIL_BREACHES_FAILURE = 'GET_EMAIL_BREACHES_FAILURE';

export const POST_RECORD = 'POST_RECORD';
export const POST_RECORD_SUCCESS = 'POST_RECORD_SUCCESS';
export const POST_RECORD_FAILURE = 'POST_RECORD_FAILURE';

export const DELETE_RECORD = 'DELETE_RECORD';
export const DELETE_RECORD_SUCCESS = 'DELETE_RECORD_SUCCESS';
export const DELETE_RECORD_FAILURE = 'DELETE_RECORD_FAILURE';

export const DISMISS_ALERT = 'DISMISS_ALERT';
export const DISMISS_ALERT_SUCCESS = 'DISMISS_ALERT_SUCCESS';
export const DISMISS_ALERT_FAILURE = 'DISMISS_ALERT_FAILURE';

export const GET_CREDIT_SCORES = 'GET_CREDIT_SCORES';
export const GET_CREDIT_SCORES_SUCCESS = 'GET_CREDIT_SCORES_SUCCESS';
export const GET_CREDIT_SCORES_FAILURE = 'GET_CREDIT_SCORES_FAILURE';

export const VERIFY_USER_CREDIT_SCORES = 'VERIFY_USER_CREDIT_SCORES';
export const VERIFY_USER_CREDIT_SCORES_SUCCESS = 'VERIFY_USER_CREDIT_SCORES_SUCCESS';
export const VERIFY_USER_CREDIT_SCORES_FAILURE = 'VERIFY_USER_CREDIT_SCORES_FAILURE';

export const PATCH_USER_BUNDLE = 'PATCH_USER_BUNDLE';
export const PATCH_USER_BUNDLE_SUCCESS = 'PATCH_USER_BUNDLE_SUCCESS';
export const PATCH_USER_BUNDLE_FAILURE = 'PATCH_USER_BUNDLE_FAILURE';

export const PATCH_USER_ADDRESS = 'PATCH_USER_ADDRESS';
export const PATCH_USER_ADDRESS_SUCCESS = 'PATCH_USER_ADDRESS_SUCCESS';
export const PATCH_USER_ADDRESS_FAILURE = 'PATCH_USER_ADDRESS_FAILURE';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

export const UPDATE_CUSTOMER_MISSING_FIELDS = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_MISSING_FIELDS_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_MISSING_FIELDS_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

export const SET_LOADING_ALERT = 'SET_LOADING_ALERT';
export const SET_MISSING_FIELDS = 'SET_MISSING_FIELDS';
export const GET_MISSING_FIELDS = 'GET_MISSING_FIELDS';
export const OPEN_MISSING_FIELDS_MODAL = 'OPEN_MISSING_FIELDS_MODAL';
export const CLOSE_MISSING_FIELDS_MODAL = 'CLOSE_MISSING_FIELDS_MODAL';
export const SET_MISSING_FIELDS_DATA = 'SET_MISSING_FIELDS_DATA';

export const CHECK_VERIFICATION_RESULT = 'CHECK_VERIFICATION_RESULT';
export const CHECK_VERIFICATION_RESULT_SUCCESS = 'CHECK_VERIFICATION_RESULT_SUCCESS';
export const CHECK_VERIFICATION_RESULT_FAILURE = 'CHECK_VERIFICATION_RESULT_FAILURE';

export const USER_REDIRECTED_TO_THANK_YOU_PAGE = 'USER_REDIRECTED_TO_THANK_YOU_PAGE';
export const USER_REDIRECTED_TO_THANK_YOU_PAGE_SUCCESS = 'USER_REDIRECTED_TO_THANK_YOU_PAGE_SUCCESS';
export const RESET_USER_REDIRECTED_TO_THANK_YOU_PAGE = 'RESET_USER_REDIRECTED_TO_THANK_YOU_PAGE'
export const RESET_USER_REDIRECTED_TO_THANK_YOU_PAGE_SUCCESS = 'RESET_USER_REDIRECTED_TO_THANK_YOU_PAGE_SUCCESS'

// automatic scroll
export const SET_SCROLL_TARGET = 'SET_SCROLL_TARGET';



