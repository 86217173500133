import React from "react"
import RulesCheckBoxModal from "../../../Hocs/RulesCheckBoxModal/RulesCheckBoxModal"
import { DispatchInterface, getContent, TermsOfUseInterface } from "../TermsOfUseComponent/TermsOfUseComponent"
import { useSelector } from "react-redux"
import { RootState } from "../../../App"
import { arrayStepSelector } from "../../FormComponents/UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"

const defaultContent = (
  <div className="modal-bl__content-container">
    <p className="modal-bl__update">Last Updated: May 21, 2020</p>
    <p className="modal-bl__text upper-case">
      This Privacy Policy explains how information about you is collected, used, and disclosed by Address Change Easy.
      (“Address Change Easy,” “we,” “we,” or “our”). This Privacy Policy applies to information we collect when you use
      our website, mobile applications, online products, and services (collectively, the “Services”), or when you
      otherwise interact with us.
    </p>
    <p className="modal-bl__text">
      We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date
      at the beginning of the policy and posting the revised policy to our Services, and, in some cases, we may provide
      you with additional notice such as by adding a statement to the homepage of our Services or by sending you a
      notification. We encourage you to review the Privacy Policy whenever you access the Services to stay informed
      about our information practices and the ways you can help protect your privacy.
    </p>
    <h3 className="modal-bl__subtitle modal-bl__text">1. Collection of Information</h3>
    <p className="modal-bl__text">We collect information about you in three different ways.</p>
  </div>
)

type PrivacyPolicyComponentProps = {
  setValueModal: any,
  dispatch: DispatchInterface,
}

export interface PrivacyPolicyInterface {
  htmlContent: string;
  wpLink: string;
}

export interface ModalContentInterface {
  privacyPolicy: PrivacyPolicyInterface | undefined;
  termOfUse: TermsOfUseInterface | undefined;
}

const PrivacyPolicyComponent: React.FunctionComponent<PrivacyPolicyComponentProps> = ({ setValueModal, dispatch }) => {
  const contentPrivacyPolicy =
    useSelector<RootState, { modalContent: ModalContentInterface | null }>(arrayStepSelector)?.modalContent?.[
      "privacyPolicy"
    ]?.htmlContent ?? ""
  return (
    // @ts-ignore
    <RulesCheckBoxModal setValueModal={setValueModal} headerName={"Privacy Policy"} dispatch={dispatch}>
      {getContent(contentPrivacyPolicy, defaultContent)}
    </RulesCheckBoxModal>
  )
}

export default PrivacyPolicyComponent
