import {useState} from "react";
import {BraintreeConstants} from "./BraintreeConstants";
import {buildPaymentError, PaymentError} from "../../../../dto/PaymentError";
import {arrayPaymentError, getCorrespondFieldByErrorPayment} from "../../../../Redux/store/form/servicePayment";
import {PriceInfo} from "../../types/types";

export const useSetupBraintreeGooglePay = (
    createClient: any,
    submitPaymentCallback: any,
    dispatchPaymentError: any,
    setError?: (paymentError: PaymentError | null) => void,
) => {

    const googlePayment = require('braintree-web/google-payment');
    const [googlePaymentInstance, setGooglePaymentInstance] = useState<any>(null);

    // @ts-ignore
    const paymentsClient = new google.payments.api.PaymentsClient({
        environment: process?.env?.REACT_APP_ENV == 'production' ? 'PRODUCTION' : "TEST"
    });

    const setupBraintreeGooglePay = async (authorization: any) => {
        try {
            const clientInstance = await createClient({authorization});

            try {
                const googlePayInstance = await googlePayment.create({
                    client: clientInstance,
                    googlePayVersion: 2,
                    googleMerchantId: process?.env?.REACT_APP_GOOGLE_MERCHANT_ID
                });

                const allowedPaymentMethodData = googlePayInstance.createPaymentDataRequest().allowedPaymentMethods;

                setGooglePaymentInstance(googlePayInstance);

                const isReadyToPay = await paymentsClient.isReadyToPay({
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: allowedPaymentMethodData,
                    existingPaymentMethodRequired: true
                });

                if (isReadyToPay.result) {
                    // Set up Google Pay button
                    return paymentsClient.createButton({
                        buttonColor: BraintreeConstants.BUTTON_COLOR,
                        buttonType: BraintreeConstants.BUTTON_TYPE,
                        onClick: () => {}
                    });
                }
                return false;
            } catch (googlePaymentError) {
                console.log(BraintreeConstants.GOOGLE_PAYMENT_ERROR, googlePaymentError)
                return false;
            }
        } catch (clientError) {
            console.log(BraintreeConstants.CLIENT_ERROR, clientError)
            return false;
        }
    }

    const onClickGooglePayBtn = (priceInfo: PriceInfo) => {
        setError?.(null);
        if (googlePaymentInstance) {
            const paymentDataRequest = googlePaymentInstance.createPaymentDataRequest({
                transactionInfo: {
                    currencyCode: 'USD',
                    totalPriceStatus: 'FINAL',
                    totalPrice: `${priceInfo.totalPay/100}`,
                    checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE',
                    totalPriceLabel: priceInfo.productName,
                }
            });
            
            const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
            cardPaymentMethod.parameters.billingAddressRequired = true;
            cardPaymentMethod.parameters.billingAddressParameters = {
                format: "FULL",
                phoneNumberRequired: true
            };

            paymentsClient
                .loadPaymentData(paymentDataRequest)
                .then(function (paymentData: any) {
                    googlePaymentInstance.parseResponse(
                        paymentData,
                        function (err: any, result: any) {
                            if (err) {
                                const paymentError = buildPaymentError(
                                    BraintreeConstants.GOOGLE_PARSE_PAYMENT_RESPONSE_ERROR,
                                    getCorrespondFieldByErrorPayment(
                                        BraintreeConstants.GOOGLE_PARSE_PAYMENT_RESPONSE_ERROR,
                                        arrayPaymentError
                                    )
                                )

                                dispatchPaymentError(paymentError)
                                setError?.(paymentError);
                            }

                            submitPaymentCallback(result?.nonce, "google")
                        }
                    );
                })
                .catch(function (err: any) {
                    console.log(BraintreeConstants.GOOGLE_PARSE_PAYMENT_RESPONSE_ERROR, err)
                    const paymentError = buildPaymentError(
                        BraintreeConstants.GOOGLE_PARSE_PAYMENT_RESPONSE_ERROR,
                        getCorrespondFieldByErrorPayment(
                            BraintreeConstants.GOOGLE_PARSE_PAYMENT_RESPONSE_ERROR,
                            arrayPaymentError
                        )
                    )

                    dispatchPaymentError(paymentError)
                    setError?.(paymentError);
                });
        }
    }

    return {
        setupBraintreeGooglePay,
        onClickGooglePayBtn
    }
}