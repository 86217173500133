import { ReactNode, createContext, useEffect, useMemo, useState } from "react";
import { identityProtectionApis } from "../api/api";
import {useSelector} from "react-redux";
import {
  AlertDismissStatuses,
  IdentityProtectionType,
} from "@dash/gov-plus__front-end__form/src/util/IdentityProtectionHelper";

interface IdentityProtectionContextProps {
  postIdentities: () => Promise<void>;
  missingFields: string[];
  records: any[];
  identities: any;
  alerts: any[];
  emailBreaches: any[];
  verificationIFrameURL: {
    getAuthenticationOnlyResult: string;
    returnURL: string;
    errMsg: string;
  };
  loading: boolean;
  loadingIdentities: boolean;
  loadingRecords: boolean;
  loadingAlerts: boolean;
  loadingEmailBreaches: boolean;
  loadingRecord: boolean;
  loadingDismissAlert: boolean;
  loadingRedAlert: boolean;
  loadingCreditScores: boolean;
  loadingVerificationIFrame: boolean;
  loadingUserBundle: boolean;
  errorIdentities: any;
  errorFindingIdentities: any;
  errorRecords: any;
  errorAlerts: any;
  errorEmailBreaches: any;
  errorRecord: any;
  errorDismissAlert: any;
  errorCreditScores: any;
  errorVerificationIFrame: any;
  errorUserBundle: any;
  errorUserAddress: any;
  getRecords: (recordType: string) => Promise<void>;
  getMyIdentities: () => Promise<void>;
  getAlerts: (type: string) => Promise<void>;
  dismissAlertAction: (alertId: string, status: any) => Promise<void>;
  getEmailBreaches: () => Promise<void>;
  postRecord: (value: { recordType: string; value: string }) => Promise<void>;
  deleteRecord: (monitorId: string) => Promise<void>;
  record: any;
  dismissAlertResult: string;
  dismissAlertResponse: {};
  creditScores: any[];
  getCreditScores: () => Promise<void>;
  verifyUserCreditScores: (value: {
    result: number;
    errorMessage: string;
  }) => Promise<void>;
  userBundle: { missingFields?: string[] };
  patchUserBundle: (value: {
    bundleId: string;
  }) => Promise<void>;
  patchUserAddress: (value: {
    address: {
      address1: string;
      address2?: string;
      city: string;
      state: string;
      zipCode: string;
    };
  }) => Promise<void>;
  missingFieldsModalData: string[];
  setMissingFieldsModalData: (value: any) => Promise<void>;
  updateCustomer: (value: any) => Promise<void>;
  loadingUserUpdating: boolean;
  userUpdatingResult: any;
  errorUserUpdating: any;
}

const initialState: IdentityProtectionContextProps = {
  postIdentities: () => Promise.resolve(),
  missingFields: [],
  records: [],
  identities: [],
  alerts: [],
  emailBreaches: [],
  verificationIFrameURL: {
    getAuthenticationOnlyResult: "",
    returnURL: "",
    errMsg: "",
  },
  loading: false,
  errorAlerts: null,
  errorEmailBreaches: null,
  errorRecords: null,
  errorIdentities: null,
  errorFindingIdentities: null,
  errorRecord: null,
  errorDismissAlert: null,
  errorCreditScores: null,
  errorVerificationIFrame: null,
  errorUserBundle: null,
  errorUserAddress: null,
  errorUserUpdating: null,
  getRecords: () => Promise.resolve(),
  getMyIdentities: () => Promise.resolve(),
  getAlerts: () => Promise.resolve(),
  dismissAlertAction: () => Promise.resolve(),
  getEmailBreaches: () => Promise.resolve(),
  postRecord: () => Promise.resolve(),
  deleteRecord: () => Promise.resolve(),
  record: {},
  dismissAlertResult: "",
  dismissAlertResponse: {},
  creditScores: [],
  getCreditScores: () => Promise.resolve(),
  verifyUserCreditScores: () => Promise.resolve(),
  userBundle: { missingFields: [] },
  patchUserBundle: () => Promise.resolve(),
  patchUserAddress: () => Promise.resolve(),
  updateCustomer: () => Promise.resolve(),
  userUpdatingResult: null,
  loadingUserUpdating: false,
  loadingIdentities: false,
  loadingRecords: false,
  loadingAlerts: false,
  loadingEmailBreaches: false,
  loadingRecord: false,
  loadingDismissAlert: false,
  loadingRedAlert: false,
  loadingCreditScores: false,
  loadingVerificationIFrame: false,
  loadingUserBundle: false,
  missingFieldsModalData: [],
  setMissingFieldsModalData: () => Promise.resolve(),
};

export const IdentityProtectionContext =
  createContext<IdentityProtectionContextProps>(initialState);

export const IdentityProtectionProvider = ({
  children,
}: {
  children: ReactNode;
}) => {

  // @ts-ignore
  const {authToken} = useSelector(({auth}) => auth);

  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState({});
  const [identities, setIdentities] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [emailBreaches, setEmailBreaches] = useState([]);
  const [missingFields, setMissingFields] = useState([]);
  const [dismissAlertResult, setDismissAlertResult] = useState("");
  const [dismissAlertResponse, setDismissAlertResponse] = useState({} as any);
  const [creditScores, setCreditScores] = useState([]);
  const [verificationIFrameURL, setVerificationIFrameURL] = useState({});
  const [userBundle, setUserBundle] = useState({});
  const [userAddress, setUserAddress] = useState("");
  const [loadingRecords, setLoadingRecords] = useState(false);
  const [errorRecords, setErrorRecords] = useState(null);
  const [loadingIdentities, setLoadingIdentities] = useState(false);
  const [errorIdentities, setErrorIdentities] = useState(null);
  const [errorFindingIdentities, setErrorFindingIdentities] = useState(null);
  const [loadingAlerts, setLoadingAlerts] = useState(false);
  const [errorAlerts, setErrorAlerts] = useState(null);
  const [loadingEmailBreaches, setLoadingEmailBreaches] = useState(false);
  const [errorEmailBreaches, setErrorEmailBreaches] = useState(null);
  const [loadingRecord, setLoadingRecord] = useState(false);
  const [errorRecord, setErrorRecord] = useState(null);
  const [loadingDismissAlert, setLoadingDismissAlert] = useState(false);
  const [loadingRedAlert, setloadingRedAlert] = useState(false);
  const [errorDismissAlert, setErrorDismissAlert] = useState(null);
  const [loadingCreditScores, setLoadingCreditScores] = useState(false);
  const [errorCreditScores, setErrorCreditScores] = useState(null);
  const [loadingVerificationIFrame, setLoadingVerificationIFrame] =
    useState(false);
  const [errorVerificationIFrame, setErrorVerificationIFrame] = useState(null);
  const [loadingUserBundle, setLoadingUserBundle] = useState(false);
  const [errorUserBundle, setErrorUserBundle] = useState(null);
  const [loadingUserAddress, setLoadingUserAddress] = useState(false);
  const [errorUserAddress, setErrorUserAddress] = useState(null);
  const [loadingUserUpdating, setLoadingUserUpdating] = useState(false);
  const [userUpdatingResult, setUserUpdatingResult] = useState(null);
  const [errorUserUpdating, setErrorUserUpdating] = useState(null);
  const [missingFieldsModalData, setMissingFieldsModalData] = useState([]);

  const getRecords = async (recordType: string) => {
    setLoadingRecords(true);
    setErrorRecords(null);
    try {
      const { data } = await identityProtectionApis.getRecords(recordType);
      setRecords(data.getBkCcDlMdPpEmTMDetailsResult);
      setLoadingRecords(false);
    } catch (error: any) {
      setErrorRecords(error);
    } finally {
      setLoadingRecords(false);
    }
  };

  const getMyIdentities = async () => {
    setLoadingIdentities(true);
    setErrorIdentities(null);
    try {
      const { data } = await identityProtectionApis.getMyIdentities();
      setIdentities(data);
      setLoadingIdentities(false);
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        setErrorFindingIdentities(error);
      } else {
        setErrorIdentities(error);
      }
    } finally {
      setLoadingIdentities(false);
    }
  };

  const getAlerts = async (type: string) => {
    setLoadingAlerts(true);
    setErrorAlerts(null);
    try {
      const { data } = await identityProtectionApis.getAlerts(type);
      setAlerts(data.getAllAlertsResult);
      setLoadingAlerts(false);
    } catch (error: any) {
      setErrorAlerts(error);
    } finally {
      setLoadingAlerts(false);
    }
  };

  const dismissAlertAction = async (
    alertId: string,
    payload: { status: string },
  ) => {
    if (payload.status === AlertDismissStatuses.RED_ALERT) {
      setloadingRedAlert(true);
    }
    if (payload.status === AlertDismissStatuses.DISMISS) {
      setLoadingDismissAlert(true);
    }
    setErrorDismissAlert(null);
    try {
      const { data } = await identityProtectionApis.dismissAlert(
        alertId,
        payload,
      );
      const updateAlertStatusResult = data.updateAlertStatusResult;
      const parsedData = JSON.parse(updateAlertStatusResult);
      setDismissAlertResponse(parsedData);
      const parsedDataResponse = parsedData.Response.Status;
      setDismissAlertResult(parsedDataResponse);
      if (payload.status === AlertDismissStatuses.RED_ALERT) {
        setloadingRedAlert(false);
      }
      if (payload.status === AlertDismissStatuses.DISMISS) {
        setLoadingDismissAlert(false);
      }
    } catch (error: any) {
      setErrorDismissAlert(error);
    } finally {
      if (payload.status === AlertDismissStatuses.RED_ALERT) {
        setloadingRedAlert(false);
      }
      if (payload.status === AlertDismissStatuses.DISMISS) {
        setLoadingDismissAlert(false);
      }
    }
  };

  const getEmailBreaches = async () => {
    setLoadingEmailBreaches(true);
    setErrorEmailBreaches(null);
    try {
      const { data } = await identityProtectionApis.getEmailBreaches();
      setEmailBreaches(data.getEmailBreachDataResult);
      setLoadingEmailBreaches(false);
    } catch (error: any) {
      setErrorEmailBreaches(error);
    } finally {
      setLoadingEmailBreaches(false);
    }
  };

  const postRecord = async (value: { recordType: string; value: string }) => {
    setLoadingRecord(true);
    setErrorRecord(null);
    try {
      const { data } = await identityProtectionApis.postRecord(value);
      setRecord(data);
      setLoadingRecord(false);
    } catch (error: any) {
      setErrorRecord(error);
    } finally {
      setLoadingRecord(false);
    }
  };

  const deleteRecord = async (monitorId: string) => {
    setLoadingRecord(true);
    setErrorRecord(null);
    try {
      await identityProtectionApis.deleteRecord(monitorId);
      setLoadingRecord(false);
    } catch (error: any) {
      setErrorRecord(error);
    } finally {
      setLoadingRecord(false);
    }
  };

  const postIdentities = async () => {
    setLoadingIdentities(true);
    setErrorIdentities(null);
    try {
      const { data } = await identityProtectionApis.postIdentities();
      setMissingFields(data.missingFields);
      setLoadingIdentities(false);
    } catch (error: any) {
      setErrorIdentities(error);
    } finally {
      setLoadingIdentities(false);
    }
  };

  const getCreditScores = async () => {
    setLoadingCreditScores(true);
    setErrorCreditScores(null);
    try {
      const { data } = await identityProtectionApis.getCreditScores();
      setCreditScores(data.getCreditScoresResult);
      setLoadingCreditScores(false);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        const enfortraErrorMessage = error.response.data.error;
        setErrorVerificationIFrame(enfortraErrorMessage);
      } else {
        setErrorCreditScores(error);
      }
    } finally {
      setLoadingCreditScores(false);
    }
  };

  const verifyUserCreditScores = async (value: {
    result: number;
    errorMessage: string;
  }) => {
    setLoadingVerificationIFrame(true);
    setErrorVerificationIFrame(null);
    try {
      const { data } =
        await identityProtectionApis.verifyUserCreditScores(value);
      setVerificationIFrameURL(data);
      setLoadingVerificationIFrame(false);

      if (!data.getAuthenticationOnlyResult) {
        setErrorVerificationIFrame(
          data.errMsg ||
            "Error verifying user credit scores. Please try again.",
        );
      }
    } catch (error: any) {
      setErrorVerificationIFrame(error);
    } finally {
      setLoadingVerificationIFrame(false);
    }
  };

  const patchUserBundle = async (value: {
    bundleId: string;
  }) => {
    setLoadingUserBundle(true);
    setErrorUserBundle(null);
    try {
      const { data } = await identityProtectionApis.patchUserBundle(value);
      setUserBundle(data);
      setMissingFields(data.missingFields);
      setLoadingUserBundle(false);
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        const { missingFields } = error.response.data;
        setMissingFields(missingFields);
      } else {
        setErrorUserBundle(error);
      }
    } finally {
      setLoadingUserBundle(false);
    }
  };

  const patchUserAddress = async (value: {
    address: {
      address1: string;
      address2: string;
      city: string;
      state: string;
      zipCode: string;
    };
  }) => {
    setLoadingUserAddress(true);
    setErrorUserAddress(null);
    try {
      const { data } = await identityProtectionApis.patchUserAddress(value);
      setUserAddress(data);
      setLoadingUserAddress(false);
    } catch (error: any) {
      setErrorUserAddress(error);
    } finally {
      setLoadingUserAddress(false);
    }
  };

  const updateCustomer = async (value: any) => {
    setLoadingUserUpdating(true);
    setErrorUserUpdating(null);
    try {
      const { data } =
        await identityProtectionApis.addMissingFieldsToUser(value);
      setUserUpdatingResult(data);
      setLoadingUserUpdating(false);
    } catch (error: any) {
      setErrorUserUpdating(error);
    } finally {
      setLoadingUserUpdating(false);
    }
  };

  const value = useMemo(
    () => ({
      records,
      identities,
      alerts,
      emailBreaches,
      verificationIFrameURL,
      loadingAlerts,
      loadingEmailBreaches,
      loadingRecords,
      loadingIdentities,
      loadingRecord,
      loadingDismissAlert,
      loadingRedAlert,
      loadingCreditScores,
      loadingVerificationIFrame,
      loadingUserBundle,
      loadingUserAddress,
      errorAlerts,
      errorEmailBreaches,
      errorRecords,
      errorIdentities,
      errorFindingIdentities,
      errorRecord,
      errorDismissAlert,
      errorCreditScores,
      errorVerificationIFrame,
      errorUserBundle,
      errorUserAddress,
      getRecords,
      getMyIdentities,
      getAlerts,
      dismissAlertAction,
      getEmailBreaches,
      postRecord,
      deleteRecord,
      postIdentities,
      missingFields,
      record,
      dismissAlertResult,
      creditScores,
      getCreditScores,
      verifyUserCreditScores,
      userBundle,
      patchUserBundle,
      userAddress,
      patchUserAddress,
      missingFieldsModalData,
      setMissingFieldsModalData,
      updateCustomer,
      loadingUserUpdating,
      userUpdatingResult,
      errorUserUpdating,
      dismissAlertResponse
    }),
    [records, identities, alerts, emailBreaches, verificationIFrameURL, loadingAlerts, loadingEmailBreaches, loadingRecords, loadingIdentities, loadingRecord, loadingDismissAlert, loadingRedAlert, loadingCreditScores, loadingVerificationIFrame, loadingUserBundle, loadingUserAddress, errorAlerts, errorEmailBreaches, errorRecords, errorIdentities, errorFindingIdentities, errorRecord, errorDismissAlert, errorCreditScores, errorVerificationIFrame, errorUserBundle, errorUserAddress, missingFields, record, dismissAlertResult, creditScores, userBundle, userAddress, missingFieldsModalData, loadingUserUpdating, userUpdatingResult, errorUserUpdating, dismissAlertResponse],
  );


  return (
    // @ts-ignore
    <IdentityProtectionContext.Provider value={value}>
      {children}
    </IdentityProtectionContext.Provider>
  );
};
