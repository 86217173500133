/* eslint-disable react-redux/useSelector-prefer-selectors */
import React, { useEffect, useState } from "react"
import "./CreditMonitoringCard.scss"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { verifyUserCreditScores } from "../../../../../../dashboard-govplus-front/src/appRedux/actions"
import { useHelperSubscription } from "../../../../../../dashboard-govplus-front/src/hooks/useHelperSubscription"
import useIsMobile from "../../../../../../dashboard-govplus-front/src/hooks/useIsMobile"
import { BUNDLE_TYPES, AlertBackgroundTypes, BUNDLE_NAMES } from "../../../../util/IdentityProtectionHelper"
import GSpinner from "../../atoms/Spinner/Spinner"
import GText from "../../atoms/Text/Text"
import GButton from "../../molecules/Button/GButton"
import { COLORS_NEW } from "../../types/Colors"
import { BUTTON_TYPE, SIZE } from "../../types/types"
import GAlertBackgroundMessage from "../AlertBackgroundMessage/AlertBackgroundMessage"
import GCard from "../GCard/GCard"
import { CreditMonitoringCardProps } from "./CreditMonitoringCard.props"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import GTag from "../../molecules/Tag/GTag"
import IconsSvg from "../../atoms/IconsSvg/IconsSvg"

const CreditMonitoringCard: React.FC<CreditMonitoringCardProps> = ({
  isTeaserCreditScoreStatus = false,
  isTeaserSubscriptionStatus = false,
  subscriptionTeaserAction,
  handleClickInfoCard,
  userBundleID,
  creditScoresLoader,
  creditScoresError,
  dynamic,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isMobile = useIsMobile(768)

  const { verifyUserCreditScoresData, verifyUserCreditScoresLoader } = useSelector(
    // @ts-ignore
    (state) => state.identityProtectionData
  )

  const { triggerIdentityProtectionVerificationStarted } = useHelperSubscription()

  const [navigateToIframeStarted, setNavigateToIframeStarted] = useState(
    localStorage.getItem("navigateToIframeStarted") === "true" || false
  )

  // check if component is in teaser mode - regardless of the subscription or isAuthenticated
  const showTeaserBlock = isTeaserSubscriptionStatus || isTeaserCreditScoreStatus

  const handleTeaserCreditScoresClick = async (event: React.MouseEvent) => {
    event.stopPropagation()
    setNavigateToIframeStarted(true)
    localStorage.setItem("navigateToIframeStarted", "true")

    if (isTeaserSubscriptionStatus) {
      subscriptionTeaserAction?.()
    } else if (userBundleID !== BUNDLE_TYPES.ADVANCED) {
      handleClickInfoCard?.(BUNDLE_TYPES.ADVANCED, BUNDLE_NAMES.ADVANCED)
    } else if (userBundleID === BUNDLE_TYPES.ADVANCED) {
      dispatch(verifyUserCreditScores({ result: 1, errorMessage: "" }))
    }
  }

  useEffect(() => {
    if (navigateToIframeStarted && verifyUserCreditScoresData?.getAuthenticationOnlyResult) {
      history.push("/myIdentity/userVerification")
      triggerIdentityProtectionVerificationStarted()
      setNavigateToIframeStarted(false)
      localStorage.removeItem("navigateToIframeStarted")
    }
  }, [history, navigateToIframeStarted, verifyUserCreditScoresData?.getAuthenticationOnlyResult])

  useEffect(() => {
    if (creditScoresError) {
      setNavigateToIframeStarted(false)
      localStorage.removeItem("navigateToIframeStarted")
    }
  }, [creditScoresError])

  return (
    <section>
      <div className={"CreditMonitoringCard"}>
        {dynamic && (
          <div className={"CreditMonitoringCard__Header"}>
            <GText
              text={"Credit monitoring"}
              size={isMobile ? SIZE.PARAGRAPH_BOLD_28 : SIZE.PARAGRAPH_BOLD_32}
              color={COLORS_NEW.BLACK_1000}
              style={{ letterSpacing: "-1px" }}
            />
          </div>
        )}
        <div className={"CreditMonitoringCard__Content"}>
          <GCard
            hasLogo
            hasLine={false}
            title={"Credit monitoring"}
            logo={<IconsSvg type={ICONS_SVG_TYPE.CREDIT_MONITORING} width={65} height={68} />}
            hasAdditionalElement={!showTeaserBlock}
            additionalElement={
              <GTag text={"\u2022 Activated"} colorBackground={COLORS_NEW.GREEN_200} colorText={COLORS_NEW.GREEN_700} />
            }
            hasRightElement={showTeaserBlock}
            rightElement={
              <div className={"CreditMonitoringCard__Content__Actions"}>
                <GButton
                  text={"Activate credit monitoring"}
                  showIcon
                  type={BUTTON_TYPE.PRIMARY}
                  click={handleTeaserCreditScoresClick}
                  textSize={isMobile ? SIZE.PARAGRAPH_BOLD_14 : SIZE.PARAGRAPH_BOLD_16}
                  loading={verifyUserCreditScoresLoader}
                />
              </div>
            }
          >
            <div className={"CreditMonitoringCard__Content"}>
              {creditScoresLoader && (
                <div className={"CreditMonitoringCard__Overlay"}>
                  <div className={"CreditMonitoringCard__Spinner"}>
                    <GSpinner />
                  </div>
                </div>
              )}

              {creditScoresError && (
                <GAlertBackgroundMessage
                  status={AlertBackgroundTypes.ERROR}
                  message={"Error fetching user data. Please try again later."}
                />
              )}
              <div className={"CreditMonitoringCard__Content__Promo"}>
                <div className={"CreditMonitoringCard__Content__Promo__Header"}>
                  <GText
                    text={
                      "Be the first to know if document fraud is harming your personal financial health and credit."
                    }
                    size={SIZE.PARAGRAPH_BOLD_16}
                    color={COLORS_NEW.BLACK_500}
                  />
                </div>
              </div>
            </div>
          </GCard>
        </div>
      </div>
    </section>
  )
}

export default CreditMonitoringCard
