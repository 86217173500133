import React from "react"

export const SvgCreditMonitoring = ({ width = 65, height = 68 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 65 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14149_123829)">
        <mask id="mask0_14149_123829" maskUnits="userSpaceOnUse" x="0" y="4" width="55" height="64">
          <path
            d="M27.5 68C27.5 68 54.5 55.2 54.5 36V13.6L27.5 4L0.5 13.6V36C0.5 55.2 27.5 68 27.5 68Z"
            fill="#13BA68"
          />
          <path
            d="M27.5 68C27.5 68 54.5 55.2 54.5 36V13.6L27.5 4L0.5 13.6V36C0.5 55.2 27.5 68 27.5 68Z"
            stroke="black"
          />
        </mask>
        <g mask="url(#mask0_14149_123829)">
          <path
            d="M28.1954 66.5271C27.9141 66.6747 27.6802 66.7943 27.5 66.885C27.3198 66.7943 27.0859 66.6747 26.8046 66.5271C26.0796 66.1465 25.0401 65.5799 23.7911 64.8397C21.2908 63.3581 17.9621 61.1877 14.6385 58.4304C7.94067 52.8737 1.5 45.1311 1.5 36V14.3058L27.5 5.06133L53.5 14.3058V36C53.5 45.1311 47.0593 52.8737 40.3615 58.4304C37.0379 61.1877 33.7092 63.3581 31.2089 64.8397C29.9599 65.5799 28.9204 66.1465 28.1954 66.5271Z"
            fill="#F7F9FA"
            stroke="#216DE3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <mask id="mask1_14149_123829" maskUnits="userSpaceOnUse" x="2" y="6" width="51" height="60">
          <path
            d="M27.5 65.5C27.5 65.5 52.3906 53.7 52.3906 36V15.35L27.5 6.5L2.60938 15.35V36C2.60938 53.7 27.5 65.5 27.5 65.5Z"
            fill="#13BA68"
          />
          <path
            d="M27.5 65.5C27.5 65.5 52.3906 53.7 52.3906 36V15.35L27.5 6.5L2.60938 15.35V36C2.60938 53.7 27.5 65.5 27.5 65.5Z"
            stroke="black"
          />
        </mask>
        <g mask="url(#mask1_14149_123829)">
          <path d="M27.6994 4.07422L27.6995 63.8398" stroke="#3585FF" strokeDasharray="2.81 2.81" />
          <path d="M63.0308 35.992L-15.2212 35.9922" stroke="#3585FF" strokeDasharray="2.81 2.81" />
          <mask id="mask2_14149_123829" maskUnits="userSpaceOnUse" x="-132" y="14" width="243" height="48">
            <path
              d="M-130.099 57.34C-129.005 58.1382 -124.035 62.1749 -119.579 60.4054C-115.123 58.6358 -115.006 52.3325 -108.33 52.3325C-101.655 52.3325 -101.273 23.8962 -97.446 21.9479C-93.6195 19.9996 -91.1025 33.2039 -86.5617 32.4307C-82.0209 31.6575 -80.1841 14.0819 -75.6773 18.0149C-71.1705 21.9479 -69.4528 39.9384 -64.793 42.0753C-60.1331 44.2122 -58.1646 51.0364 -54.1 52.3325C-50.0354 53.6285 -49.6308 48.1475 -43.0243 48.1475C-36.4178 48.1475 -33.8173 33.1655 -29.2594 39.4706C-24.7016 45.7756 -26.7148 58.5182 -21.2556 56.2763C-16.0943 53.957 -11.5647 56.4702 -10.3712 57.34C-9.27692 58.1382 -4.3071 62.1749 0.148678 60.4054C4.60446 58.6358 4.72183 52.3325 11.3975 52.3325C18.0732 52.3325 18.4553 23.8962 22.2819 21.9479C26.1084 19.9996 28.6254 33.2039 33.1662 32.4307C37.707 31.6575 38.898 8.45018 44.0506 18.0149C49.2031 27.5796 54.5535 17.0459 60.0708 17.0459C65.5881 17.0459 60.6878 24.1099 64.7525 25.406C68.8171 26.702 70.0971 48.1475 76.7036 48.1475C83.3101 48.1475 85.9106 33.1656 90.4685 39.4706C95.0263 45.7756 93.0131 58.5182 98.4723 56.2763C103.932 54.0344 105.133 46.3966 109.357 44.3961"
              stroke="#13BA68"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </mask>
          <g mask="url(#mask2_14149_123829)">
            <rect x="-112.042" y="14.168" width="239.456" height="48.8123" fill="url(#paint0_linear_14149_123829)" />
          </g>
          <path
            d="M21.9346 27.6281C21.9346 28.7323 21.0391 29.6273 19.9346 29.6273C18.83 29.6273 17.9346 28.7323 17.9346 27.6281C17.9346 26.524 18.83 25.6289 19.9346 25.6289C21.0391 25.6289 21.9346 26.524 21.9346 27.6281Z"
            fill="#216DE3"
          />
          <path
            d="M17.9346 46.6281C17.9346 47.7323 17.0391 48.6273 15.9346 48.6273C14.83 48.6273 13.9346 47.7323 13.9346 46.6281C13.9346 45.524 14.83 44.6289 15.9346 44.6289C17.0391 44.6289 17.9346 45.524 17.9346 46.6281Z"
            fill="#216DE3"
          />
          <ellipse cx="37.0352" cy="25.9992" rx="2" ry="1.99922" fill="#216DE3" />
        </g>
      </g>
      <rect x="36.5" width="28" height="28" rx="14" fill="#13BA68" />
      <path
        d="M49.8333 18.6667C52.7789 18.6667 55.1667 16.2789 55.1667 13.3333C55.1667 10.3878 52.7789 8 49.8333 8C46.8878 8 44.5 10.3878 44.5 13.3333C44.5 16.2789 46.8878 18.6667 49.8333 18.6667Z"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.4999 19.9977L53.5999 17.0977"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14149_123829"
          x1="7.68639"
          y1="14.168"
          x2="7.68639"
          y2="62.9803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#03874F" />
          <stop offset="0.5" stopColor="#13BA68" />
          <stop offset="1" stopColor="#FAA300" />
        </linearGradient>
        <clipPath id="clip0_14149_123829">
          <rect x="0.5" y="4" width="54" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
