import React from "react"

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
  fillCircle?: boolean;
}

export const SvgChatCircle= ({
  width = 65,
  height = 65,
  color = "#185EC8",
  backgroundColor = "#13BA68",
}: ISvgCheck) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill={backgroundColor}/>
      <path 
        d="M9.09851 13.6922C9.0964 14.5027 9.28959 15.3194 9.65498 16.0428L8.47488 19.5255L12.0056 18.3646C12.9266 18.7964 13.3487 18.9111 14.3178 18.9115C15.1282 18.9136 15.9277 18.7243 16.6511 18.3589C16.6511 18.3589 17.5922 17.8231 18.0692 17.3284C18.5028 16.8787 18.9845 16.0256 18.9845 16.0256C19.3499 15.3021 19.5392 14.5027 19.5371 13.6922C19.5367 12.7231 19.2665 11.7733 18.7568 10.949C18.2471 10.1248 17.518 9.45879 16.6511 9.02555C15.9277 8.66016 15.1282 8.4708 14.3178 8.47292H14.0108C12.7309 8.54353 11.5221 9.08374 10.6157 9.99011C9.70933 10.8965 9.16912 12.1053 9.09851 13.3852V13.6922Z" 
        stroke={color} 
        strokeWidth="1.22807" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path d="M12.1586 12.4641L15.8428 12.4641" stroke={color} strokeWidth="1.22807" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.3866 14.9202H15.8428" stroke={color} strokeWidth="1.22807" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
      <clipPath id="clip0_16803_194422">
        <rect width="14.7368" height="14.7368" fill={color} transform="matrix(-1 0 0 -1 21.3691 21.3676)"/>
      </clipPath>
      </defs>
    </svg>

  )
}
