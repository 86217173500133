import React, {FC} from "react"
import {SIZE} from "../../types/types"
import "./ProductPrice.scss"
import GText from "../../atoms/Text/Text"
import {COLORS, COLORS_NEW} from "../../types/Colors"

export interface IProductPrice {
    type: ProductPriceType
    content: IProductPriceContent;
}

export interface IProductPriceContent {
    offer: string;
    price: string;
    savePrice: string
    service: string
    savePrice2: string;
    billingCycle: string;
}

export enum ProductPriceType {
    Application = "application", Subscription = "subscription"
}

export type GProductPriceProps = {
    productPriceProps: IProductPrice
}

const customerOfferStyles: React.CSSProperties = {
  padding: "2px 8px",
  borderRadius: 8,
  backgroundColor: COLORS_NEW.GREEN_600,
  width: "100%"
}

const GProductPrice: FC<GProductPriceProps> = ({productPriceProps}) => {
  return (
    <div className={`GProductPrice ${productPriceProps.type}`} >
      <div className="ContentDescription">
        <GText
          text={productPriceProps.type === ProductPriceType.Application ? productPriceProps.content.service : productPriceProps.content.billingCycle}
          size={SIZE.PARAGRAPH_BOLD_18}
          color={COLORS_NEW.BLACK_800}/>
          {
              productPriceProps.content.offer && (
                  <GText text={productPriceProps.content.offer} size={SIZE.PARAGRAPH_BOLD_14} color={COLORS.WHITE} style={customerOfferStyles}/>
              )
          }
      </div>
      <div className="ContentPrices">
        {productPriceProps.type === ProductPriceType.Application && (
          <div className="PriceApplication">
            <div className="SavePrice">
              <GText text={productPriceProps.content.savePrice} color={COLORS_NEW.BLACK_800} size={SIZE.PARAGRAPH_REGULAR_18} />
            </div>
            <GText text={productPriceProps.content.price} color={COLORS_NEW.BLACK_800} size={SIZE.PARAGRAPH_BOLD_18} />
          </div>
        )}
        {productPriceProps.type === ProductPriceType.Subscription && (
          <>
            <div className="SavePrice">
              <GText text={productPriceProps.content.savePrice} color={COLORS_NEW.BLACK_800}
                size={SIZE.PARAGRAPH_REGULAR_18} />
            </div>
            <GText text={productPriceProps.content.savePrice2}  size={SIZE.PARAGRAPH_REGULAR_18} color={COLORS_NEW.BLACK_800} />
            <GText text={productPriceProps.content.price} size={SIZE.PARAGRAPH_REGULAR_14} color={COLORS_NEW.BLACK_800} />
          </>
        )}
      </div>
    </div>
  )
}

export default GProductPrice
