import React from 'react';
import {SvgType} from "../SvgType";

export const SvgIconBox = ({width, height}: SvgType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 13.7861V7.11938C17.9997 6.82711 17.9225 6.54006 17.7763 6.28702C17.63 6.03398 17.4198 5.82385 17.1667 5.67772L11.3333 2.34438C11.08 2.1981 10.7926 2.12109 10.5 2.12109C10.2074 2.12109 9.92003 2.1981 9.66667 2.34438L3.83333 5.67772C3.58022 5.82385 3.36998 6.03398 3.22372 6.28702C3.07745 6.54006 3.0003 6.82711 3 7.11938V13.7861C3.0003 14.0783 3.07745 14.3654 3.22372 14.6184C3.36998 14.8715 3.58022 15.0816 3.83333 15.2277L9.66667 18.561C9.92003 18.7073 10.2074 18.7843 10.5 18.7843C10.7926 18.7843 11.08 18.7073 11.3333 18.561L17.1667 15.2277C17.4198 15.0816 17.63 14.8715 17.7763 14.6184C17.9225 14.3654 17.9997 14.0783 18 13.7861Z"
        stroke="#191919" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.22656 6.25391L10.5016 10.4622L17.7766 6.25391" stroke="#191919" strokeWidth="1.66667"
            strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.5 18.8531V10.4531" stroke="#191919" strokeWidth="1.66667" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>

  );
}