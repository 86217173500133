import React, {FC, useState} from "react";
import "./GCancellationReason.scss";
import GText from "../../atoms/Text/Text";
import {SIZE} from "../../types/types";
import {COLORS_NEW} from "../../types/Colors";
import {Input} from 'antd';
import GButton from "../../molecules/Button/GButton";
import {SubscriptionText} from "../../../../shared/text/Text";
import {useSubscription} from "../../../../../../dashboard-govplus-front/src/hooks/useSubscription";
import GSpinner from "../../atoms/Spinner/Spinner";
import {useNavigation} from "../../../../../../dashboard-govplus-front/src/hooks/useNavigation";
import GNotification, {NOTIFICATION_TYPE} from "../Notification/Notification";
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes";

const {TextArea} = Input;


const GCancellationReason: FC = () => {

    const [reason, setReason] = useState('')
    const [emptyReasonWarning, setEmptyReasonWarning] = useState(false)
    const {unsubscribe, loading} = useSubscription()
    const {navigate} = useNavigation()

    const proceedWithCancellation = () => {
        if (reason) {
            unsubscribe(reason, (success: boolean) => {
                if (success)
                    navigate("/SubscriptionCancelled")
            })
        }
        else {
            setEmptyReasonWarning(true)
        }
    }

    const handleSubmit = (value: string) => {
        setReason(value)
        setEmptyReasonWarning(false)
    }

    return (
        <>
            {loading && <GSpinner />}
            {!loading && <div className={"GCancellationReason"}>
                <div className={"GCancellationReason__Header"}>
                    <div className={"GCancellationReason__Title"}>
                        <GText text={SubscriptionText.CancelReasonTitle} size={SIZE.HEADING_XL}/>
                    </div>

                    <div className={"GCancellationReason__Subtitle1"}>
                        <GText 
                            text={SubscriptionText.CancelReasonSubtitle} 
                            innerHtml={SubscriptionText.CancelReasonSubtitle} 
                            size={SIZE.PARAGRAPH_BOLD_20} 
                            color={COLORS_NEW.BLACK_500}
                            maxWidth="560px"
                        />
                    </div>
                </div>

                <div className={"GCancellationReason__Form"}>
                    <div className={"GCancellationReason__Form__Input"}>
                        <div className={"GCancellationReason__FormTitle"}>
                            <GText text={SubscriptionText.CancellationReason} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_800}/>
                        </div>

                        <div className={"GCancellationReason__TextArea"}>
                            <TextArea
                                className={ reason.length < 255 ? "GCancellationReason__CustomTextArea" : "GCancellationReason__CustomTextArea GCancellationReason__CustomTextArea__CharacterLimitExceeded"}
                                autoSize={{minRows: 4}}
                                value={reason}
                                onChange={e => handleSubmit(e.target.value)}
                                maxLength={255}
                            />
                            <div className={"GCancellationReason__CharacterLimit"}>
                                <GText text={`${reason.length}/255`} size={SIZE.PARAGRAPH_REGULAR_12} color={reason.length === 255 ? COLORS_NEW.RED_600 : ''} />
                            </div>
                        </div>
                    </div>

                    {emptyReasonWarning && <GNotification text={SubscriptionText.PleaseProvideReason} type={NOTIFICATION_TYPE.ERROR}
                                                        icon={ICONS_SVG_TYPE.INFORMATION}/>}
                    <div className={"GCancellationReason__ButtonTextArea"}>
                        <GButton text={SubscriptionText.CancelSubscription} click={proceedWithCancellation}/>
                    </div>
                </div>
            </div>}
        </>

    )

}

export default GCancellationReason