/* eslint-disable indent */
import { ActionTypes } from "./validation.actions"
import { Action } from "../../interfacesAction/action.interface"

export interface State {
  firstMistakeName: string;
}

const initialState: State = {
  firstMistakeName: "",
}

export const validationReducer = (state: State = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.SET_FIRST_MISTAKE_NAME:
      return {
        ...state,
        firstMistakeName: action.payload,
      }
    default:
      return state
  }
}
