import React, {FC} from "react";
import ProductPage from "../../../Components/atomicDesign/templates/ProductPage/ProductPage";
import {InsuredDocumentsText} from "../../../shared/text/Text";
import InsuranceDocumentsList
    from "../../../Components/atomicDesign/templates/InsuranceDocumentsList/InsuranceDocumentsList";
import {IInsuredDocument} from "../../../Components/atomicDesign/types/Models";

export interface MyInsuredDocumentsProps {
    documents: IInsuredDocument[],
    onGetSubscription?: () => void
}

const MyInsuredDocuments: FC<MyInsuredDocumentsProps> = ({documents, onGetSubscription }) => {

    const listComponent = () => {
        return <InsuranceDocumentsList documents={documents} onSubscribe={onGetSubscription}/>
    }

    return (
        <ProductPage
            List={listComponent}
            headerText={InsuredDocumentsText.MyInsuredDocuments}
            subHeaderText={InsuredDocumentsText.ReplaceWithNoCost}
            subHeaderWidth="520px"
        />
    )
}

export default MyInsuredDocuments