import React, {FC, useMemo} from "react";
import {Avatar} from "antd";
import {UserOutlined} from "@ant-design/icons";
import GText from "../../atoms/Text/Text";
import {FamilyMemberRelationships, SIZE} from "../../types/types";
import './FamilyMemberHeader.scss'
import {COLORS} from "../../types/Colors";

interface FamilyMemberHeaderProps {
    firstName?: string,
    middleName?: string,
    lastName?: string,
    relationship?: string,
}

const FamilyMemberHeader: FC<FamilyMemberHeaderProps> = ({
    firstName, middleName, lastName, relationship
}) => {
    const letterSpacingText: React.CSSProperties = {
        letterSpacing: "-0.1px"
    }

    const getNameInitials = useMemo(() => {
        if (firstName && lastName) {
            let initials = firstName.charAt(0) + lastName.charAt(0)
            return initials
        }
        return undefined
    }, [firstName, lastName])

    const avatarStyles = useMemo(() => {
        switch (relationship) {
            case FamilyMemberRelationships.Mother:
            case FamilyMemberRelationships.Father:
                return {
                    color: COLORS.YELLOW_500,
                    backgroundColor: COLORS.YELLOW_50
                }
            default:
                return {
                    color: COLORS.SKY_BLUE_500,
                    backgroundColor: COLORS.SKY_BLUE_50
                }
        }
    }, [relationship])

    const fullName = useMemo(() => {
        return `${firstName || 'Family member'} ${middleName?.charAt(0) || ''}${middleName ? '.' : ''} ${lastName || ''}`
    }, [firstName, lastName, middleName])

    const getTextSize = useMemo(() => {
        let size = SIZE.HEADING_XXS

        if (fullName?.length > 20) {
            size = SIZE.HEADING_XXXS
        }
        return size
    }, [fullName])

    return <div className={"GFamilyMemberHeader"}>
        <Avatar style={firstName ? avatarStyles : {}} icon={!firstName && <UserOutlined />}>
            {getNameInitials}
        </Avatar>
        <div className={"GFamilyMemberHeader__Text"}>
            <GText
                text={fullName}
                size={getTextSize}
                style={letterSpacingText}
            />
            {firstName && <GText text={relationship || ''} size={SIZE.PARAGRAPH_REGULAR_14} style={letterSpacingText} color={COLORS.SLATE_GRAY_500}/>}
        </div>

    </div>
}

export default FamilyMemberHeader