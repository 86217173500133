import React from 'react';
import {SvgType} from "../SvgType";

export const SvgMyIdentity = ({width = 20, height = 24, color = "#6E6E6E"}: SvgType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 22.5866C10 22.5866 19 18.3513 19 11.9984V4.58663L10 1.41016L1 4.58663V11.9984C1 18.3513 10 22.5866 10 22.5866Z"
        stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M15.5 18.7502V17.2165C15.5 16.5602 15.2103 15.9309 14.6945 15.4669C14.1788 15.0029 13.4793 14.7422 12.75 14.7422H7.25C6.52065 14.7422 5.82118 15.0029 5.30546 15.4669C4.78973 15.9309 4.5 16.5602 4.5 17.2165V18.7502"
        stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M10.0014 11.2023C11.5636 11.2023 12.83 9.93632 12.83 8.37461C12.83 6.81289 11.5636 5.54688 10.0014 5.54688C8.43925 5.54688 7.17285 6.81289 7.17285 8.37461C7.17285 9.93632 8.43925 11.2023 10.0014 11.2023Z"
        stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}