/* eslint-disable react-redux/useSelector-prefer-selectors */
import React from "react"
import "./AlertMessagesCard.scss"
import { AlertBackgroundTypes, formatDateToShort, isAlert } from "../../../../util/IdentityProtectionHelper"
import GText from "../../atoms/Text/Text"
import { COLORS_NEW } from "../../types/Colors"
import { SIZE } from "../../types/types"
import GAlertBackgroundMessage from "../AlertBackgroundMessage/AlertBackgroundMessage"
import GCard from "../GCard/GCard"
import GTable from "../Table/Table"
import { AlertMessagesProps } from "./AlertMessagesCard.props"
import GSpinner from "../../atoms/Spinner/Spinner"

const AlertMessagesCard: React.FC<AlertMessagesProps> = ({
  alerts,
  hasReviewLink,
  redirectLink,
  alertsLoader,
  alertsError,
}) => {
  const headerCells = ["Subject", "Date of alert"]

  const transformAlerts = (alerts: { [key: string]: string }[]): { subject: string, date: string }[] => {
    return alerts.map((alert) => ({
      subject: alert.AlertClass,
      date: formatDateToShort(alert.DateTime),
    }))
  }

  const renderAlerts = (alerts: any[]) => {
    return alerts?.length > 0 ? (
      <>
        <div className={"AlertMessagesCard__Text"}>
          <GText
            size={SIZE.PARAGRAPH_BOLD_16}
            color={COLORS_NEW.BLACK_500}
            text={`There are ${alerts.length} alerts that need your attention. Review now to keep your identity safe.`}
          />
        </div>
        <GTable headerCells={headerCells} details={transformAlerts(alerts)} />
      </>
    ) : (
      <GAlertBackgroundMessage
        status={AlertBackgroundTypes.SUCCESS}
        message={"Good news! There is no suspicious activity that needs your attention."}
      />
    )
  }

  const alertsCount = alerts?.filter((alert) => isAlert(alert)).length

  return (
    <section className={alertsLoader ? "AlertMessagesCard__Loading" : "AlertMessagesCard"}>
      <GCard
        title={"Alert messages"}
        alertsCount={alertsCount}
        hasReviewLink={hasReviewLink}
        redirectLink={redirectLink}
      >
        {alertsLoader && (
          <div className={"AlertMessagesCard__Overlay"}>
            <div className={"AlertMessagesCard__Spinner"}>
              <GSpinner />
            </div>
          </div>
        )}
        {!alertsError && renderAlerts(alerts)}

        {alertsError && !alerts?.length && (
          <GAlertBackgroundMessage
            status={AlertBackgroundTypes.ERROR}
            message={"Error fetching user data. Please try again later."}
          />
        )}
      </GCard>
    </section>
  )
}

export default AlertMessagesCard