import { FC } from "react"
import { BasicBlockContainerProps } from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/interfaces/interfaces"
import { renderBasicBlockContainer } from "../../Components/FormComponents/UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import LayoutBaseContainer from "../../Components/FormComponents/UploadPhoto/Layouts/LayoutBaseContainer/LayoutBaseContainer"
import FormIdRepository from "../../persistence/session/FormIdRepository";

const DynamicServiceCardLayout: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text, state, type },
  arrayComponents,
}) => {
  const formId = FormIdRepository.getInstance().getValue();
  const webFlowServicesData = JSON.parse(localStorage.getItem("webFlowServicesData") ?? "{}") ?? {}
  const product = webFlowServicesData?.[formId ?? ""]
  const { text: textDescription } = product ?? {}

  text = textDescription

  return renderBasicBlockContainer({ className, text, childrenItem, arrayComponents, state }, LayoutBaseContainer)
}

export default DynamicServiceCardLayout
