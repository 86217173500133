import React, {useState} from "react";
import GText from "../../atoms/Text/Text";
import GRadio from "../../molecules/Radio/Radio";
import {SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import {GSubscriptionPeriodItemProps} from "./SubscriptionPeriodItem.props";
import {SUBSCRIPTION_PERIOD_YEARLY} from "../../../../shared/constans/payment";
import GLabelIcon from "../../molecules/LabelIcon/LabelIcon";

import './SubscriptionPeriodItem.scss'
import GTag from "../../molecules/Tag/GTag";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const GSubscriptionPeriodItem: React.FC<GSubscriptionPeriodItemProps> = ({
                                                                             id,
                                                                             price,
                                                                             method,
                                                                             saleLabel,
                                                                             salePrice,
                                                                             yearPrice,
                                                                             isSelected,
                                                                             onSelect
                                                                         }) => {

    const triggerOnSelect = () => onSelect(id);
    const [isRadioHighlighted, setIsRadioHighlighted] = useState<boolean>(false);

    const showBestPriceLabel = method === SUBSCRIPTION_PERIOD_YEARLY;
    const breakPoint = useBreakpoint()

    const period = 'year';

    return (
        <div
            key={id}
            role="button"
            tabIndex={0}
            onClick={triggerOnSelect}
            onKeyDown={triggerOnSelect}
            onMouseEnter={() => setIsRadioHighlighted(true)}
            onMouseLeave={() => setIsRadioHighlighted(false)}
            className={`GSubscriptionPeriods-atomic ${showBestPriceLabel ? 'GSubscriptionPeriods-atomic-bestPrice' : 'GSubscriptionPeriods-atomic-normalPrice'} ${isSelected ? 'selected' : ''}`}
        >
            {
                showBestPriceLabel && <span className="best-value-badge">
                                <GLabelIcon>
                                    <GText text={'Best value'}
                                           size={breakPoint.xs ? SIZE.PARAGRAPH_BOLD_14 : SIZE.PARAGRAPH_BOLD_16}
                                           color={COLORS.WHITE}/>
                                </GLabelIcon>
                            </span>
            }

            <div className={`GSubscriptionPeriods-atomic__radio`}>
                <GRadio selected={isSelected} isHighlighted={isRadioHighlighted}/>
            </div>
            <div className="GSubscriptionPeriods-atomic__content">
                <div className="GSubscriptionPeriods-atomic__content-lines">
                    <div className={`GSubscriptionPeriods-atomic__line-1 ${saleLabel ? '' : 'GSubscriptionPeriods-atomic__line-1--no-sale'}`}>
                        <div className={`PeriodInfo`}>
                            <div className={`PeriodInfo__Content`}>
                                <GText text={`Paid ${method}`} style={{textAlign: "left"}} size={breakPoint.xs ? SIZE.PARAGRAPH_BOLD_14 : SIZE.PARAGRAPH_BOLD_18} color={COLORS_NEW.BLACK_800}/>
                                {
                                    saleLabel && (
                                        <GTag text={saleLabel} sizeText={breakPoint.md ? SIZE.PARAGRAPH_BOLD_14 : SIZE.PARAGRAPH_BOLD_11} colorBackground={COLORS_NEW.GREEN_600} colorText={COLORS.WHITE}/>
                                    )
                                }
                            </div>
                            <div>
                                {
                                    salePrice ? (
                                        <div className={`PeriodInfo__Content__Info`}>
                                            <GText 
                                                text={`$${salePrice}${!breakPoint.xs ? '' : `${breakPoint.md ? '/month' : ''}`}`} 
                                                size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_18 : SIZE.PARAGRAPH_REGULAR_14}
                                                color={COLORS_NEW.BLACK_800}
                                                style={{textAlign: "right"}}
                                            />
                                            <GText 
                                                text={`$${price}/month`} 
                                                size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_14}
                                                color={COLORS_NEW.BLACK_800}
                                                style={{textAlign: "right"}}
                                            />
                                        </div>
                                    ) : (
                                        <div className={`PeriodInfo__Content__Info`}>
                                            <GText 
                                                text={`$${price}/month`} 
                                                size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_14} 
                                                color={COLORS_NEW.BLACK_800} 
                                                style={{textDecoration: "none"}}
                                            />
                                        </div>
                                    )
                                }
                                {
                                    yearPrice  && <div className={`PeriodInfo--Description`}>
                                        <GText text={`Pay $${yearPrice} today`} style={{textAlign: "right"}} size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_14 : SIZE.PARAGRAPH_REGULAR_12} color={COLORS_NEW.BLACK_600}/>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* {
                            yearPrice && breakPoint.xs && <div className={`PeriodInfo--Description`}>
                                        <GText text={`$${yearPrice} billed annually`} size={SIZE.PARAGRAPH_REGULAR_12} color={COLORS_NEW.BLACK_600}/>
                            </div>
                        } */}
                    </div>

                </div>
            </div>
        </div>
    )
}
export default GSubscriptionPeriodItem;