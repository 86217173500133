import React from 'react';
import {iconSvgTypes} from '../../constants/imageTypes'
import {svgIconsVariables} from "./svgIconsVariables";

export const IconSvg = ({type, styleForIco, iconColor}) => {
  switch (type) {
    case iconSvgTypes.USER_ICON:
      return (
        <svg className={styleForIco} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.3327 13V11.6667C11.3327 10.9594 11.0517 10.2811 10.5516 9.78105C10.0515 9.28095 9.37326 9 8.66601 9H3.33268C2.62544 9 1.94716 9.28095 1.44706 9.78105C0.946967 10.2811 0.666016 10.9594 0.666016 11.6667V13"
            stroke="#666666" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M6.00065 6.33333C7.47341 6.33333 8.66732 5.13943 8.66732 3.66667C8.66732 2.19391 7.47341 1 6.00065 1C4.52789 1 3.33398 2.19391 3.33398 3.66667C3.33398 5.13943 4.52789 6.33333 6.00065 6.33333Z"
            stroke="#666666" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.BILLING_ICON:
      return (
        <svg className={styleForIco} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.9993 0.666687H1.99935C1.26297 0.666687 0.666016 1.26364 0.666016 2.00002V10C0.666016 10.7364 1.26297 11.3334 1.99935 11.3334H13.9993C14.7357 11.3334 15.3327 10.7364 15.3327 10V2.00002C15.3327 1.26364 14.7357 0.666687 13.9993 0.666687Z"
            stroke="#666666" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M0.666016 4.66666H15.3327" stroke="#666666" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.LOG_OUT_ICON:
      return (
        <svg className={styleForIco} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H5"
            stroke="#666666" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M9.66602 10.3333L12.9993 6.99999L9.66602 3.66666" stroke="#666666" strokeLinecap="round"
                strokeLinejoin="round"/>
          <path d="M13 7H5" stroke="#666666" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.PLUS_ICON:
      return (
        <svg className={styleForIco} width="13" height="13" viewBox="0 0 12 12" fill="none">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M7.81643 4.42615V0.570465H4.60337V4.42615H0.746094V7.63921H4.60337V11.4949H7.81643V7.63921H11.6705V4.42615H7.81643Z"
                fill="#159EDA"/>
        </svg>
      )
    case iconSvgTypes.INFO_ICON:
      return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10 18.3333C14.6024 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39762 14.6024 1.66666 10 1.66666C5.39762 1.66666 1.66666 5.39762 1.66666 10C1.66666 14.6024 5.39762 18.3333 10 18.3333Z"
            stroke={iconColor || "#159EDA"} strokeWidth="1.66667" strokeLinecap="round"
            strokeLinejoin="round"/>
          <path d="M10 13.3333V10" stroke={iconColor || "#159EDA"} strokeWidth="1.66667" strokeLinecap="round"
                strokeLinejoin="round"/>
          <path d="M10 6.66666H10.0083" stroke={iconColor || "#159EDA"} strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>

      )
    case iconSvgTypes.LEFT_SIDE_ARROW_ICON:
      return (
        <svg className={styleForIco} width="21" height="20" viewBox="0 0 21 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M4.50065 10H16.1673" stroke={iconColor || "#DAEBFB"} strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"/>
          <path d="M10.334 4.16667L16.1673 10L10.334 15.8333" stroke={iconColor || "#DAEBFB"}
                strokeWidth="1.66667"
                strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.RIGHT_SIDE_ARROW_ICON:
      return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.1663 8.5H3.83301" stroke="#159EDA" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M8.49968 13.1666L3.83301 8.49998L8.49968 3.83331" stroke="#159EDA" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>

      )
    case iconSvgTypes.PRODUCTS_ICON:
      return (svgIconsVariables.productsIcon)
    case iconSvgTypes.OFFERS_ICON:
      return (
        <svg className={styleForIco} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3333 8V14.6667H2.66666V8" stroke="#F8F8F8" strokeLinecap="round"
                strokeLinejoin="round"/>
          <path d="M14.6667 4.66667H1.33334V8H14.6667V4.66667Z" stroke="#F8F8F8" strokeLinecap="round"
                strokeLinejoin="round"/>
          <path d="M8 14.6667V4.66667" stroke="#F8F8F8" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M8.00001 4.66666H5.00001C4.55798 4.66666 4.13406 4.49107 3.8215 4.17851C3.50894 3.86595 3.33334 3.44202 3.33334 3C3.33334 2.55797 3.50894 2.13405 3.8215 1.82148C4.13406 1.50892 4.55798 1.33333 5.00001 1.33333C7.33334 1.33333 8.00001 4.66666 8.00001 4.66666Z"
            stroke="#F8F8F8" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M8 4.66666H11C11.442 4.66666 11.866 4.49107 12.1785 4.17851C12.4911 3.86595 12.6667 3.44202 12.6667 3C12.6667 2.55797 12.4911 2.13405 12.1785 1.82148C11.866 1.50892 11.442 1.33333 11 1.33333C8.66667 1.33333 8 4.66666 8 4.66666Z"
            stroke="#F8F8F8" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.VISA_ICON:
      return (
        <svg width="32" height="21" viewBox="0 0 32 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V1.5Z"
                fill="#F4F6F7"/>
          <path fillRule="evenodd" clipRule="evenodd"
                d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V5H0V1.5Z"
                fill="#4095DB"/>
          <path fillRule="evenodd" clipRule="evenodd"
                d="M0 16H32V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V16Z"
                fill="#F4B662"/>
          <path fillRule="evenodd" clipRule="evenodd"
                d="M20.1405 7.28462C19.8291 7.14852 19.3394 7 18.7294 7C17.1725 7 16.0766 7.92035 16.0675 9.23809C16.0576 10.2127 16.8495 10.7564 17.4466 11.0804C18.0602 11.4125 18.2664 11.625 18.2639 11.9219C18.2597 12.3761 17.7741 12.5842 17.3214 12.5842C16.6909 12.5842 16.356 12.4818 15.8381 12.2286L15.6355 12.1206L15.4153 13.6386C15.7825 13.8274 16.4632 13.9914 17.1697 14C18.8243 14 19.8995 13.0909 19.9121 11.6837C19.9177 10.9108 19.4981 10.3248 18.5893 9.84108C18.039 9.52672 17.7013 9.31841 17.7052 9.00014C17.7055 8.71823 17.9905 8.41658 18.6073 8.41658C19.1224 8.40737 19.4952 8.53927 19.7858 8.67707L19.9272 8.75468L20.1405 7.28462ZM12.8536 13.8995L13.8388 7.11817H15.4135L14.4284 13.8995H12.8536ZM22.8646 9.59983C22.8646 9.59983 22.3678 11.1054 22.2373 11.4952H23.5446C23.4824 11.1562 23.1802 9.53763 23.1802 9.53763L23.0739 8.95277C23.0319 9.08717 22.9732 9.26533 22.9286 9.40077C22.8889 9.52147 22.8603 9.60823 22.8646 9.59983ZM24.1801 7.12508H22.9631C22.5856 7.12508 22.3031 7.24506 22.1377 7.68721L19.7991 13.9019H21.4531C21.4531 13.9019 21.7229 13.0658 21.7842 12.8824C21.869 12.8824 22.2689 12.8831 22.6929 12.884C23.171 12.8849 23.6796 12.8859 23.8011 12.8859C23.8483 13.1225 23.9929 13.9019 23.9929 13.9019H25.4545L24.1801 7.12508ZM11.5325 7.12388L9.99122 11.7471L9.82612 10.8073L9.27428 7.69142C9.17908 7.26169 8.9024 7.1343 8.56042 7.11928H6.02099L6 7.25978C6.61781 7.4354 7.17026 7.68865 7.65441 8.00364L9.05479 13.8935L10.7214 13.8924L13.2013 7.12388H11.5325Z"
                fill="#0071CE"/>
          <path fillRule="evenodd" clipRule="evenodd"
                d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V1.5Z"
                fill="#E5E5E5" fillOpacity="0.01"/>
        </svg>
      )
    case iconSvgTypes.QUESTION_ICON:
      return (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke={iconColor || "#525252"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.08984 9.00057C9.32495 8.33224 9.789 7.76868 10.3998 7.4097C11.0106 7.05073 11.7287 6.91951 12.427 7.03928C13.1253 7.15906 13.7587 7.52209 14.2149 8.0641C14.6712 8.6061 14.9209 9.29209 14.9198 10.0006C14.9198 12.0006 11.9198 13.0006 11.9198 13.0006" stroke={iconColor || "#525252"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 17H12.01" stroke={iconColor || "#525252"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
      )
    case iconSvgTypes.NOTIFICATION_BELL_ICON:
      return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="20" fill="white"/>
          <path
            d="M25 16.6667C25 15.3406 24.4732 14.0688 23.5355 13.1311C22.5979 12.1935 21.3261 11.6667 20 11.6667C18.6739 11.6667 17.4021 12.1935 16.4645 13.1311C15.5268 14.0688 15 15.3406 15 16.6667C15 22.5 12.5 24.1667 12.5 24.1667H27.5C27.5 24.1667 25 22.5 25 16.6667Z"
            stroke="#666666" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M21.4419 27.5C21.2954 27.7526 21.0851 27.9622 20.8321 28.1079C20.5791 28.2537 20.2922 28.3304 20.0003 28.3304C19.7083 28.3304 19.4214 28.2537 19.1684 28.1079C18.9154 27.9622 18.7051 27.7526 18.5586 27.5"
            stroke="#666666" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>


      )
    case iconSvgTypes.MENU_ARROW_ICON:
      return (
        <svg className={styleForIco} width="10" height="6" viewBox="0 0 10 6" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0.5L5 5.5L10 0.5H0Z" fill="#CCCCCC"/>
        </svg>
      )
    case iconSvgTypes.MENU_USER_ICON:
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
            stroke="#74849B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
            stroke="#74849B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.SUCCESS_ICON:
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.6667 7.38666V8C14.6658 9.43761 14.2003 10.8364 13.3396 11.9879C12.4788 13.1393 11.2689 13.9816 9.89023 14.3893C8.51162 14.7969 7.03817 14.7479 5.68964 14.2497C4.34112 13.7515 3.18976 12.8307 2.4073 11.6247C1.62484 10.4187 1.25319 8.99204 1.34778 7.55754C1.44237 6.12304 1.99813 4.75755 2.93218 3.66471C3.86623 2.57188 5.12852 1.81025 6.53079 1.49343C7.93306 1.1766 9.40017 1.32156 10.7133 1.90666"
            stroke="#0FB968" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M14.6667 2.66667L8 9.34L6 7.34" stroke="#0FB968" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>

      )
    case iconSvgTypes.SUCCESS_ICON_V2:
      return (<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 14C0 6.26801 6.26801 0 14 0V0C21.732 0 28 6.26801 28 14V14C28 21.732 21.732 28 14 28V28C6.26801 28 0 21.732 0 14V14Z"
          fill="#D3F8D7"/>
        <path d="M19.3352 10.3311L12.0001 17.6661L8.66602 14.332" stroke="#0FB968" strokeWidth="1.33"
              strokeLinecap="round" strokeLinejoin="round"/>
      </svg>)
    case iconSvgTypes.UNSUCCESS_ICON:
      return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.00001 15.1667C11.6819 15.1667 14.6667 12.1819 14.6667 8.5C14.6667 4.8181 11.6819 1.83333 8.00001 1.83333C4.31811 1.83333 1.33334 4.8181 1.33334 8.5C1.33334 12.1819 4.31811 15.1667 8.00001 15.1667Z"
            stroke={iconColor || "#E02B3E"} strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M10 6.5L6 10.5" stroke={iconColor || "#E02B3E"} strokeLinecap="round"
                strokeLinejoin="round"/>
          <path d="M6 6.5L10 10.5" stroke={iconColor || "#E02B3E"} strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.CLOCK_ICON:
      return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.5 14.6667C12.1819 14.6667 15.1667 11.6819 15.1667 8C15.1667 4.3181 12.1819 1.33333 8.5 1.33333C4.8181 1.33333 1.83334 4.3181 1.83334 8C1.83334 11.6819 4.8181 14.6667 8.5 14.6667Z"
            stroke="#999999" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M8.5 4V8L11.1667 9.33333" stroke="#999999" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )

    case iconSvgTypes.ICON_IN_PRODUCT_PAGE:
      return (
        <svg width="65" height="68" viewBox="0 0 65 68" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M20.9494 25.0503C21.34 25.4408 21.34 26.074 20.9494 26.4645L19.5352 27.8787C19.1447 28.2692 18.5115 28.2692 18.121 27.8787C17.7305 27.4882 17.7305 26.855 18.121 26.4645L19.5352 25.0503C19.9258 24.6597 20.5589 24.6597 20.9494 25.0503ZM12.4642 25.0503C12.0736 24.6597 11.4405 24.6597 11.0499 25.0503C10.6594 25.4408 10.6594 26.074 11.0499 26.4645L12.4642 27.8787C12.8547 28.2692 13.4879 28.2692 13.8784 27.8787C14.2689 27.4882 14.2689 26.855 13.8784 26.4645L12.4642 25.0503ZM19.5352 32.1213C19.1447 31.7308 18.5115 31.7308 18.121 32.1213C17.7305 32.5119 17.7305 33.145 18.121 33.5356L19.5352 34.9498C19.9258 35.3403 20.5589 35.3403 20.9494 34.9498C21.34 34.5592 21.34 33.9261 20.9494 33.5356L19.5352 32.1213ZM13.8784 33.5356C14.2689 33.145 14.2689 32.5119 13.8784 32.1213C13.4879 31.7308 12.8547 31.7308 12.4642 32.1213L11.0499 33.5356C10.6594 33.9261 10.6594 34.5592 11.0499 34.9498C11.4405 35.3403 12.0736 35.3403 12.4642 34.9498L13.8784 33.5356Z"
                fill="#113469"/>
          <path fillRule="evenodd" clipRule="evenodd"
                d="M49 16C49.5523 16 50 16.4477 50 17V19C50 19.5523 49.5523 20 49 20C48.4477 20 48 19.5523 48 19V17C48 16.4477 48.4477 16 49 16ZM44 21C43.4477 21 43 21.4477 43 22C43 22.5523 43.4477 23 44 23H46C46.5523 23 47 22.5523 47 22C47 21.4477 46.5523 21 46 21H44ZM52 21C51.4477 21 51 21.4477 51 22C51 22.5523 51.4477 23 52 23H54C54.5523 23 55 22.5523 55 22C55 21.4477 54.5523 21 54 21H52ZM50 25C50 24.4477 49.5523 24 49 24C48.4477 24 48 24.4477 48 25V27C48 27.5523 48.4477 28 49 28C49.5523 28 50 27.5523 50 27V25Z"
                fill="#113469"/>
          <path
            d="M38 15.0722C37.4566 13.8936 36.7948 12.8962 35.9493 12.0507C35.1038 11.2052 34.1064 10.5434 32.9278 10C34.1064 9.45657 35.1038 8.79478 35.9493 7.9493C36.7948 7.10382 37.4566 6.1064 38 4.92778C38.5434 6.1064 39.2052 7.10382 40.0507 7.9493C40.8962 8.79478 41.8936 9.45657 43.0722 10C41.8936 10.5434 40.8962 11.2052 40.0507 12.0507C39.2052 12.8962 38.5435 13.8936 38 15.0722Z"
            fill="white" stroke="#113469" strokeWidth="2"/>
          <path
            d="M27.7112 20.666C27.015 21.3522 26.4601 22.1511 26 23.0804C25.54 22.1511 24.9851 21.3522 24.2889 20.666C23.6025 19.9896 22.8054 19.4491 21.8803 19.0001C22.8054 18.5512 23.6025 18.0106 24.2889 17.3342C24.9851 16.6481 25.54 15.8491 26 14.9198C26.4601 15.8491 27.015 16.6481 27.7112 17.3342C28.3975 18.0106 29.1946 18.5512 30.1198 19.0001C29.1946 19.4491 28.3975 19.9896 27.7112 20.666Z"
            fill="white" stroke="#113469" strokeWidth="2"/>
          <path fillRule="evenodd" clipRule="evenodd"
                d="M36.9213 42V25.9999L20.9213 45.9999H32.9213L32.9213 62L48.9213 42L36.9213 42Z"
                fill="white"/>
          <path
            d="M36.9213 25.9999H38.4213V21.7238L35.75 25.0629L36.9213 25.9999ZM36.9213 42H35.4213V43.5L36.9213 43.5L36.9213 42ZM20.9213 45.9999L19.75 45.0629L17.8003 47.4999H20.9213V45.9999ZM32.9213 45.9999L34.4213 45.9999L34.4213 44.4999H32.9213V45.9999ZM32.9213 62L31.4213 62L31.4213 66.2762L34.0926 62.937L32.9213 62ZM48.9213 42L50.0926 42.937L52.0422 40.5L48.9213 40.5L48.9213 42ZM35.4213 25.9999V42H38.4213V25.9999H35.4213ZM22.0926 46.937L38.0926 26.937L35.75 25.0629L19.75 45.0629L22.0926 46.937ZM32.9213 44.4999H20.9213V47.4999H32.9213V44.4999ZM34.4213 62L34.4213 45.9999L31.4213 45.9999L31.4213 62L34.4213 62ZM47.75 41.0629L31.75 61.0629L34.0926 62.937L50.0926 42.937L47.75 41.0629ZM36.9213 43.5L48.9213 43.5L48.9213 40.5L36.9213 40.5L36.9213 43.5Z"
            fill="#113469"/>
        </svg>

      )

    case iconSvgTypes.ACCEPTED:
      return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
          <rect width="50" height="50" rx="25" fill={iconColor || "#0FB968"}/>
          <rect width="50" height="50" rx="25"/>
          <path d="M31.6667 20L22.5 29.1667L18.3333 25" stroke={iconColor ? "#113469" : "white"}
                strokeWidth="1.66667"
                strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.FORGOT_PASSWORD_KEY:
      return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="50" height="50" rx="25" fill="#DAEBFB"/>
          <path
            d="M27.9167 21.25L30.8333 18.3333M32.5 16.6667L30.8333 18.3333L32.5 16.6667ZM24.4917 24.675C24.922 25.0996 25.264 25.605 25.4981 26.1623C25.7323 26.7196 25.8538 27.3177 25.8559 27.9222C25.8579 28.5267 25.7403 29.1256 25.5099 29.6844C25.2796 30.2433 24.9409 30.751 24.5135 31.1785C24.086 31.6059 23.5783 31.9445 23.0194 32.1749C22.4606 32.4053 21.8617 32.5229 21.2572 32.5209C20.6527 32.5188 20.0546 32.3973 19.4973 32.1631C18.94 31.929 18.4346 31.587 18.01 31.1567C17.1751 30.2922 16.7131 29.1345 16.7236 27.9327C16.734 26.731 17.2161 25.5815 18.0658 24.7317C18.9156 23.8819 20.0652 23.3999 21.2669 23.3894C22.4687 23.379 23.6264 23.8409 24.4908 24.6758L24.4917 24.675ZM24.4917 24.675L27.9167 21.25L24.4917 24.675ZM27.9167 21.25L30.4167 23.75L33.3333 20.8333L30.8333 18.3333L27.9167 21.25Z"
            stroke="#113469"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

      )
    case iconSvgTypes.SHIPPED_BOX:
      return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
          <rect width="50" height="50" rx="25" fill={iconColor || "#0FB968"}/>
          <rect width="50" height="50" rx="25"/>
          <path d="M31.6667 20L22.5 29.1667L18.3333 25" stroke={iconColor ? "#113469" : "white"}
                strokeWidth="1.66667"
                strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.NO_SIGNATURE:
      return (
        <svg width="64" height="64" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="50" height="50" rx="25" fill="#E02B3E"/>
          <rect width="50" height="50" rx="25"/>
          <path d="M25 30.8333L30.8333 25L33.3333 27.5L27.5 33.3333L25 30.8333Z" stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M30 25.8333L28.75 19.5833L16.6667 16.6667L19.5833 28.75L25.8333 30L30 25.8333Z"
                stroke="white"
                strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M16.6667 16.6667L22.9883 22.9883" stroke="white" strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"/>
          <path
            d="M24.1667 25.8333C25.0871 25.8333 25.8333 25.0871 25.8333 24.1667C25.8333 23.2462 25.0871 22.5 24.1667 22.5C23.2462 22.5 22.5 23.2462 22.5 24.1667C22.5 25.0871 23.2462 25.8333 24.1667 25.8333Z"
            stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

      )
    case iconSvgTypes.GOV_ICON:
      return (
        svgIconsVariables.govIcon
      )
    case iconSvgTypes.MAP_MARKER:
      return (
        svgIconsVariables.mapMarker
      )
    case iconSvgTypes.NO_DOCUMENTS:
      return (
        svgIconsVariables.noDocument
      )
    case iconSvgTypes.COMPLETED:
      return (
        svgIconsVariables.completed
      )
    case iconSvgTypes.PENDING_NOTARIZE:
      return (
        svgIconsVariables.noDocument
      )
    case iconSvgTypes.SEND_TO_NOTARIZE:
      return (
        svgIconsVariables.noDocument
      )
    case iconSvgTypes.IDENTITY_DOCUMENT:
      return (
        svgIconsVariables.noDocument
      )
    case iconSvgTypes.RELATIONSHIP_DOCUMENT:
      return (
        svgIconsVariables.noDocument
      )
    case iconSvgTypes.SHIPMENT:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.INVALID_ADDRESS:
      return (
        svgIconsVariables.invalidAddress
      )
    case iconSvgTypes.ERROR_MESSAGE_INFO_ICON:
      return (
        svgIconsVariables.errorMessageInfoIcon
      )
    case iconSvgTypes.WARNING_MESSAGE_INFO_ICON:
      return (
        svgIconsVariables.warningMessageInfoIcon
      )
    case iconSvgTypes.SUCCSES_MESSAGE_INFO_ICON:
      return (
        svgIconsVariables.succsesMessageInfoIcon
      )
    case iconSvgTypes.PHONE_ICON:
      return (
        svgIconsVariables.phoneIcon
      )
    case iconSvgTypes.USER_ADD_ICON:
      return (
        svgIconsVariables.userAddIcon
      )
    case iconSvgTypes.PROCESSING_SHIPMENT:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.HEADER_SEPARATOR:
      return (
        svgIconsVariables.headerSeparator
      )
    case iconSvgTypes.DROPDOWN_ARROW_FOR_SELECTOR_INPUT:
      return (
        svgIconsVariables.dropdownArrowForSelectorInput
      )
    case iconSvgTypes.NEW_APPLICATION_LINK:
      return (
        svgIconsVariables.newApplicationLinkIcon
      )
    case iconSvgTypes.OLD_ADDRESS:
      return (
        svgIconsVariables.oldAddress
      )
    case iconSvgTypes.NEW_ADDRESS:
      return (
        svgIconsVariables.newAddress
      )
    case iconSvgTypes.ARROW_BETWEN_OLD_AND_NEW:
      return (
        svgIconsVariables.arrowBetwenOldAndNew
      )
    case iconSvgTypes.NOTARIZATION:
      return (
        svgIconsVariables.notarization
      )
    case iconSvgTypes.CONGRATULATION_ICON_FOR_MODAL:
      return (
        svgIconsVariables.congratulationIconInModal
      )
    case iconSvgTypes.MY_INSURED_DOCUMENTS:
      return (
        svgIconsVariables.myInsuredDocuments
      )
    case iconSvgTypes.REVIEW:
      return (
        svgIconsVariables.review
      )
    case iconSvgTypes.MAIL_ICON:
      return (
        svgIconsVariables.mailIcon
      )
    case iconSvgTypes.DELIVERED_TO_GOV:
      return (
        svgIconsVariables.greenBoxPostal
      )
    case iconSvgTypes.GOVERNMENT_FEES:
      return (
        svgIconsVariables.government_fees
      )
    case iconSvgTypes.READY_TO_PROCESS:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.BOOKING:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.QUESTIONAIRE:
    case iconSvgTypes.QUESTIONAIRE_SSN:
    case iconSvgTypes.QUESTIONAIRE_HAS_SSN:
    case iconSvgTypes.QUESTIONAIRE_HAS_TAX_AND_PAS:
    case iconSvgTypes.QUESTIONAIRE_HAS_VA_AND_PAS_DL:
      return (
        svgIconsVariables.questionaire
      )
    case iconSvgTypes.PENDING_ADDITIONAL_INFORMATION:
      return (
        svgIconsVariables.noDocument
      )
    case iconSvgTypes.NO_GOV_PRODUCTS_ICON:
      return (
        svgIconsVariables.noGovProductsIcon
      )
    case iconSvgTypes.BOOKING_CONFIRMED:
      return (
        svgIconsVariables.booking_confirmed
      )
    case iconSvgTypes.PENDING_APPOINTMENT_SELECTION:
      return (
        svgIconsVariables.pending_appointment_selection
      )
    case iconSvgTypes.DONE_ICON:
      return (
        svgIconsVariables.doneIcon
      )
    case iconSvgTypes.INVALID_NEW_ADDRESS:
      return (
        svgIconsVariables.invalid_newOrOld_address
      )
    case iconSvgTypes.INVALID_OLD_ADDRESS:
      return (
        svgIconsVariables.invalid_newOrOld_address
      )
    case iconSvgTypes.DISCOUNT:
      return (
        svgIconsVariables.discount
      )
    case iconSvgTypes.DISCOUNT_STAR:
      return (
        svgIconsVariables.discount_stars
      )
    case iconSvgTypes.PROCESSING_BOT_NEW_ERROR:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.PROCESSING_ERROR:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.BLOCKED_101_OTHER:
      return (
        svgIconsVariables.contactUs
      )
    case iconSvgTypes.BLOCKED_SOLEPROPRIETOR:
      return (
        svgIconsVariables.contactUs
      )
    case iconSvgTypes.PROCESSING_BOT_RENEWAL_CHECK:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.PROCESSING_BOT_RENEWAL_SUBMIT_APPLICATION:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.APPOINTMENT_SELECTION_RENEWAL:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.COMPLETED_RENEW:
      return (
        svgIconsVariables.completed
      )
    case iconSvgTypes.PROCESSING_NOTIFICATIONS_SERVICES:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.PROCESSING_DUPLICATE:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.PROCESSING_NOTIFICATIONS_AGENCIES:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.COMPLETED_NEW:
      return (
        svgIconsVariables.completed
      )
    case iconSvgTypes.COMPLETED_RENEW_EXPIRED:
      return (
        svgIconsVariables.completed
      )
    case iconSvgTypes.PROCESSING_BOT_ERROR:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.BLOCKED_FOR_SOME_REASON:
      return (
        svgIconsVariables.blocked_for_some_reason
      )
    case iconSvgTypes.PROCESSING_VALIDATION_NOTARIZATION:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.SERVICE_PROVIDERS:
      return (
        svgIconsVariables.service_providers
      )
    case iconSvgTypes.SHARE_LINK_ICON:
      return (
        svgIconsVariables.shareLinkIcon
      )
    case iconSvgTypes.SIGNUP_STAR:
      return (
        svgIconsVariables.signUpStar
      )
    case iconSvgTypes.SUCCESS_SHARING:
      return (
        svgIconsVariables.successSharing
      )
    case iconSvgTypes.COPY_LINK:
      return (
        svgIconsVariables.copyLink
      )
    case iconSvgTypes.ERROR_GROWL_MESSAGE:
      return (
        svgIconsVariables.error_growl_message
      )
    case iconSvgTypes.WARNING_GROWL_MESSAGE:
      return (
        svgIconsVariables.warning_growl_message
      )
    case iconSvgTypes.SUCCESS_GROWL_MESSAGE:
      return (
        svgIconsVariables.success_growl_message
      )
    case iconSvgTypes.INFO_GROWL_MESSAGE:
      return (
        svgIconsVariables.info_growl_message
      )
    case iconSvgTypes.GROWL_CLOSE_ICON:
      return (
        svgIconsVariables.growlCloseIcon
      )
    case iconSvgTypes.NAME_CHANGE_DOCUMENT:
    case iconSvgTypes.CITIZENSHIP_DOCUMENT:
      return (
        <svg width="50" height="50" viewBox="0 0 72 72" fill="none">
          <rect width="72" height="72" rx="36" fill="#E02B3E"/>
          <path
            d="M27 21C26.2044 21 25.4413 21.3161 24.8787 21.8787C24.3161 22.4413 24 23.2044 24 24V48C24 48.7956 24.3161 49.5587 24.8787 50.1213C25.4413 50.6839 26.2044 51 27 51H45C45.7956 51 46.5587 50.6839 47.1213 50.1213C47.6839 49.5587 48 48.7956 48 48V30L39 21H27ZM27 24H37.5L45 31.5V48H27V24Z"
            fill="white"/>
          <path d="M36 26.25V33H42.75L36 26.25Z" fill="white"/>
        </svg>
      )
    case iconSvgTypes.UPLOAD_TAXES:
      return (
        <svg width="50" height="50" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="72" height="72" rx="36" fill="#E02B3E"/>
          <path
            d="M27 21C26.2044 21 25.4413 21.3161 24.8787 21.8787C24.3161 22.4413 24 23.2044 24 24V48C24 48.7956 24.3161 49.5587 24.8787 50.1213C25.4413 50.6839 26.2044 51 27 51H45C45.7956 51 46.5587 50.6839 47.1213 50.1213C47.6839 49.5587 48 48.7956 48 48V30L39 21H27ZM27 24H37.5L45 31.5V48H27V24Z"
            fill="white"/>
          <path d="M36 26.25V33H42.75L36 26.25Z" fill="white"/>
        </svg>
      )
    // pending_passport_details
    case iconSvgTypes.MISSING_INFORMATION:
    case iconSvgTypes.PENDING_ADDITIONAL_INFORMATION_PAS:
    case iconSvgTypes.PENDING_ADDITIONAL_INFORMATION_VA:
      return (
        <svg width="50" height="50" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="72" height="72" rx="36" fill="#E02B3E"/>
          <path
            d="M27 21C26.2044 21 25.4413 21.3161 24.8787 21.8787C24.3161 22.4413 24 23.2044 24 24V48C24 48.7956 24.3161 49.5587 24.8787 50.1213C25.4413 50.6839 26.2044 51 27 51H45C45.7956 51 46.5587 50.6839 47.1213 50.1213C47.6839 49.5587 48 48.7956 48 48V30L39 21H27ZM27 24H37.5L45 31.5V48H27V24Z"
            fill="white"/>
          <path d="M36 26.25V33H42.75L36 26.25Z" fill="white"/>
        </svg>
      )
    case iconSvgTypes.PENDING_PACKAGE_VALIDATION:
      return (
        <svg width="50" height="50" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="72" height="72" rx="36" fill="#CCCCCC"/>
          <path
            d="M36 21C27.72 21 21 27.72 21 36C21 44.28 27.72 51 36 51C44.28 51 51 44.28 51 36C51 27.72 44.28 21 36 21ZM36 48C29.385 48 24 42.615 24 36C24 29.385 29.385 24 36 24C42.615 24 48 29.385 48 36C48 42.615 42.615 48 36 48Z"
            fill="white"/>
          <rect x="34.5" y="27" width="3" height="10.5" fill="white"/>
          <rect x="34.5" y="34.5" width="9" height="3" fill="white"/>
        </svg>
      )
    case iconSvgTypes.NO_PASSPORT_DETAILS:
      return (
        <svg width="50" height="50" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="72" height="72" rx="36" fill="#E02B3E"/>
          <path
            d="M27 21C26.2044 21 25.4413 21.3161 24.8787 21.8787C24.3161 22.4413 24 23.2044 24 24V48C24 48.7956 24.3161 49.5587 24.8787 50.1213C25.4413 50.6839 26.2044 51 27 51H45C45.7956 51 46.5587 50.6839 47.1213 50.1213C47.6839 49.5587 48 48.7956 48 48V30L39 21H27ZM27 24H37.5L45 31.5V48H27V24Z"
            fill="white"/>
          <path d="M36 26.25V33H42.75L36 26.25Z" fill="white"/>
        </svg>
      )
    case iconSvgTypes.UPLOAD_PHOTO:
      return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="50" height="50" rx="25" fill="#E02B3E"/>
          <path
            d="M31.6667 32.5V30.8333C31.6667 29.9493 31.3155 29.1014 30.6903 28.4763C30.0652 27.8512 29.2174 27.5 28.3333 27.5H21.6667C20.7826 27.5 19.9348 27.8512 19.3096 28.4763C18.6845 29.1014 18.3333 29.9493 18.3333 30.8333V32.5"
            stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M25 24.1667C26.841 24.1667 28.3333 22.6743 28.3333 20.8333C28.3333 18.9924 26.841 17.5 25 17.5C23.1591 17.5 21.6667 18.9924 21.6667 20.8333C21.6667 22.6743 23.1591 24.1667 25 24.1667Z"
            stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.NO_QUESTIONNAIRE:
      return (
        svgIconsVariables.questionaire
      )
    case iconSvgTypes.SHIPPED:
      return (
        <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="50" height="50" rx="25" fill="#113469"/>
          <path d="M29.25 23.3333L21.75 19.0083" stroke="white" strokeWidth="1.66667" strokeLinecap="round"
                strokeLinejoin="round"/>
          <path
            d="M33 28.8333V22.1667C32.9997 21.8744 32.9225 21.5873 32.7763 21.3343C32.63 21.0813 32.4198 20.8711 32.1667 20.725L26.3333 17.3917C26.08 17.2454 25.7926 17.1684 25.5 17.1684C25.2074 17.1684 24.92 17.2454 24.6667 17.3917L18.8333 20.725C18.5802 20.8711 18.37 21.0813 18.2237 21.3343C18.0775 21.5873 18.0003 21.8744 18 22.1667V28.8333C18.0003 29.1256 18.0775 29.4127 18.2237 29.6657C18.37 29.9187 18.5802 30.1289 18.8333 30.275L24.6667 33.6083C24.92 33.7546 25.2074 33.8316 25.5 33.8316C25.7926 33.8316 26.08 33.7546 26.3333 33.6083L32.1667 30.275C32.4198 30.1289 32.63 29.9187 32.7763 29.6657C32.9225 29.4127 32.9997 29.1256 33 28.8333Z"
            stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M18.225 21.3L25.5 25.5083L32.775 21.3" stroke="white" strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"/>
          <path d="M25.5 33.9V25.5" stroke="white" strokeWidth="1.66667" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.IN_TRANSIT:
      return (
        <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="1" width="49" height="49" rx="24.5" fill={iconColor || "white"}/>
          <path d="M28.3333 18H15.8333V28.8333H28.3333V18Z" stroke={!iconColor ? "#666666" : "white"}
                strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M28.3333 22.1667H31.6667L34.1667 24.6667V28.8333H28.3333V22.1667Z"
                stroke={!iconColor ? "#666666" : "white"} strokeWidth="1.66667" strokeLinecap="round"
                strokeLinejoin="round"/>
          <path
            d="M19.5833 33C20.7339 33 21.6667 32.0673 21.6667 30.9167C21.6667 29.7661 20.7339 28.8333 19.5833 28.8333C18.4327 28.8333 17.5 29.7661 17.5 30.9167C17.5 32.0673 18.4327 33 19.5833 33Z"
            stroke={!iconColor ? "#666666" : "white"} strokeWidth="1.66667" strokeLinecap="round"
            strokeLinejoin="round"/>
          <path
            d="M30.4167 33C31.5673 33 32.5 32.0673 32.5 30.9167C32.5 29.7661 31.5673 28.8333 30.4167 28.8333C29.2661 28.8333 28.3333 29.7661 28.3333 30.9167C28.3333 32.0673 29.2661 33 30.4167 33Z"
            stroke={!iconColor ? "#666666" : "white"} strokeWidth="1.66667" strokeLinecap="round"
            strokeLinejoin="round"/>
          <rect x="0.5" y="1" width="49" height="49" rx="24.5" stroke="#CCCCCC"/>
        </svg>


      )
    case iconSvgTypes.DELIVERED:
      return (
        <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="49" height="49" rx="24.5" fill={!iconColor ? "white" : iconColor}/>
          <path d="M32.1667 20.5L23 29.6667L18.8333 25.5" stroke={iconColor ? "white" : "#666666"}
                strokeWidth="1.66667"
                strokeLinecap="round" strokeLinejoin="round"/>
          <rect x="1" y="1" width="49" height="49" rx="24.5" stroke="#CCCCCC"/>
        </svg>
      )
    case iconSvgTypes.PROCESSING:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.PROCESSING_VALIDATION:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.PENDING_SHIPMENT:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.DELEVERED_TO_GOV:
      return (
        svgIconsVariables.processing
      )
    case iconSvgTypes.FACEBOOK:
      return (
        svgIconsVariables.facebook
      )
    case iconSvgTypes.TWITTER:
      return (
        svgIconsVariables.twitter
      )
    case iconSvgTypes.LINKEDIN:
      return (
        svgIconsVariables.linkedin
      )
    // packagevalidation
    case iconSvgTypes.PACKAGEVALIDATION:
      return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="50" height="50" rx="25" fill="#159EDA"/>
          <rect width="50" height="50" rx="25" stroke="#159EDA"/>
          <path
            d="M25 33.3333C29.6024 33.3333 33.3333 29.6024 33.3333 25C33.3333 20.3976 29.6024 16.6667 25 16.6667C20.3976 16.6667 16.6667 20.3976 16.6667 25C16.6667 29.6024 20.3976 33.3333 25 33.3333Z"
            stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M25 20V25L28.3333 26.6667" stroke="white" strokeWidth="1.66667" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.SHIPMENTTOCLIENT:
      return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="50" height="50" rx="25" fill="#159EDA"/>
          <rect width="50" height="50" rx="25" stroke="#159EDA"/>
          <path
            d="M25 33.3333C29.6024 33.3333 33.3333 29.6024 33.3333 25C33.3333 20.3976 29.6024 16.6667 25 16.6667C20.3976 16.6667 16.6667 20.3976 16.6667 25C16.6667 29.6024 20.3976 33.3333 25 33.3333Z"
            stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M25 20V25L28.3333 26.6667" stroke="white" strokeWidth="1.66667" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.INPUT_ERROR:
      return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="7" cy="7.5" r="7" fill="#F54053"/>
          <path d="M9 5.5L5 9.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M5 5.5L9 9.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )

    case iconSvgTypes.SIGN_IN_ICON:
      return (
        <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="0.5" width="64" height="64" rx="32" fill="#EBF9FF"/>
          <path
            d="M40 41.5V39.5C40 38.4391 39.5786 37.4217 38.8284 36.6716C38.0783 35.9214 37.0609 35.5 36 35.5H28C26.9391 35.5 25.9217 35.9214 25.1716 36.6716C24.4214 37.4217 24 38.4391 24 39.5V41.5"
            stroke="#2F5082" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M32 31.5C34.2091 31.5 36 29.7091 36 27.5C36 25.2909 34.2091 23.5 32 23.5C29.7909 23.5 28 25.2909 28 27.5C28 29.7091 29.7909 31.5 32 31.5Z"
            stroke="#2F5082" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.SIGN_UP_ICON:
      return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="50" height="50" rx="25" fill="#DAEBFB"/>
          <path
            d="M28.3333 32.5V30.8333C28.3333 29.9493 27.9822 29.1014 27.357 28.4763C26.7319 27.8512 25.8841 27.5 25 27.5H19.1667C18.2826 27.5 17.4348 27.8512 16.8097 28.4763C16.1845 29.1014 15.8333 29.9493 15.8333 30.8333V32.5"
            stroke="#113469" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M22.0833 24.1667C23.9243 24.1667 25.4167 22.6743 25.4167 20.8333C25.4167 18.9924 23.9243 17.5 22.0833 17.5C20.2424 17.5 18.75 18.9924 18.75 20.8333C18.75 22.6743 20.2424 24.1667 22.0833 24.1667Z"
            stroke="#113469" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M31.6667 21.6667V26.6667" stroke="#113469" strokeWidth="1.66667" strokeLinecap="round"
                strokeLinejoin="round"/>
          <path d="M34.1667 24.1667H29.1667" stroke="#113469" strokeWidth="1.66667" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>

      )
    case iconSvgTypes.ENTER_VERIFICATION_CODE_CONFIRM_PHONE:
      return (
        <svg width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="1" width="49" height="49" rx="24.5" fill="#DAEBFB" stroke="#DAEBFB"/>
          <path
            d="M29.1667 17.1667H20.8334C19.9129 17.1667 19.1667 17.9128 19.1667 18.8333V32.1667C19.1667 33.0871 19.9129 33.8333 20.8334 33.8333H29.1667C30.0872 33.8333 30.8334 33.0871 30.8334 32.1667V18.8333C30.8334 17.9128 30.0872 17.1667 29.1667 17.1667Z"
            stroke="#113469" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M25 30.5H25.0083" stroke="#113469" strokeWidth="1.66667" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>
      )
    case iconSvgTypes.ENTER_VERIFICATION_CODE_ADD_PHONE:
      return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="50" height="50" rx="25" fill="#DAEBFB"/>
          <path
            d="M25 33.3333C29.6024 33.3333 33.3334 29.6024 33.3334 25C33.3334 20.3976 29.6024 16.6667 25 16.6667C20.3976 16.6667 16.6667 20.3976 16.6667 25C16.6667 29.6024 20.3976 33.3333 25 33.3333Z"
            stroke="#113469" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M25 28.3333L28.3333 25L25 21.6667" stroke="#113469" strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"/>
          <path d="M21.6667 25H28.3334" stroke="#113469" strokeWidth="1.66667" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>

      )
    case iconSvgTypes.DOWNLOAD:
      return (
        svgIconsVariables.greenBoxPostal
      )
    case iconSvgTypes.TRACKING_TO_CUSTOMER:
      return (
        svgIconsVariables.greenBoxPostal
      )
    case iconSvgTypes.BLOCKED:
      return (
        svgIconsVariables.contactUs
      )
    case iconSvgTypes.CLOSEDLOCK:
      return (
        svgIconsVariables.closedLock
      )
    case iconSvgTypes.OPENEDLOCK:
      return (
        svgIconsVariables.openedLock
      )
    case iconSvgTypes.VALIDATION_ERR:
      return (
        svgIconsVariables.validationError
      )
    case iconSvgTypes.BUTTON_ARROW:
      return (
        svgIconsVariables.arrowForButton
      )
    case iconSvgTypes.BACK_ARROW_LEFT:
      return (
        svgIconsVariables.arrowBackLeft
      )
      case iconSvgTypes.DUPLICATE_APP:
          return (
              svgIconsVariables.duplicateApp
          )
      case iconSvgTypes.CLOSE_ICON:
          return (
              svgIconsVariables.closeIcon
          )
    case iconSvgTypes.INSURED:
      return (
        svgIconsVariables.insured
      )
    case iconSvgTypes.CHECKED_ICON:
      return (
        svgIconsVariables.checkedIcon
      )
    case iconSvgTypes.LITTLE_QUESTION_ICON:
      return (
        svgIconsVariables.littleQuestionIcon
      )
    case iconSvgTypes.NOT_CHECKED_ICON:
      return (
        svgIconsVariables.notCheckedIcon
      )
    case iconSvgTypes.HALF_INSURED:
      return (
        svgIconsVariables.halfInsured
      )
    case iconSvgTypes.PLUS_ICON_BLUE:
      return (
        svgIconsVariables.plusIconBlue
      )
    case iconSvgTypes.ADD_DOCUMENT_ICON_CIRCLE_BORDER:
      return (
          svgIconsVariables.addIconCircleBorder
      )
    default:
      return type
  }
}
