import React from "react"

interface ISvgSignArrowDown {
  width: number;
  height: number;
  color: string;
}

export const SvgSignArrowDown = ({ width = 57, height = 56, color = "#3E3E3E" }: ISvgSignArrowDown) => {
  return (
    <svg width={width} height={height} viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.5 11.667V44.3337" stroke={color} strokeWidth="4.66667" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M44.8346 28L28.5013 44.3333L12.168 28" stroke={color} strokeWidth="4.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
