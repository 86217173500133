import passportLogoThankYouPage from "../../../../assets/image/passportLogoTYP.png"
import itinLogoThankYouPage from "../../../../assets/image/itinLogoTYP.png"
import ssnLogoThankYouPage from "../../../../assets/image/ssnLogoTYP.png"
import einLogoThankYouPage from "../../../../assets/image/einLogoTYP.png"
import coaLogoThankYouPage from "../../../../assets/image/coaLogoTYP.png"
import serLogoThankYouPage from "../../../../assets/image/serLogoTYP.png"
import tsaLogoThankYouPage from "../../../../assets/image/tsaLogoTYP.png"
import SsnMailThankYouPage from "../../../../assets/image/SsnMailThankYouPage.svg"
import checkStatusIcon from "../../../../assets/image/checkStatusIcon.svg"
import subscriptionLogoPage from "../../../../assets/image/subscriptionImage.png"
import subscriptionLogoPage2 from "../../../../assets/image/subscriptionImage2.png"
import paymentLogoPage from "../../../../assets/image/illu-payment-package.png"
import nortonImage from "../../../../assets/image/logo-norton-secure-seal.png"

export const arrayImportedSvgTYP = [
  {
    src: "PassportLogoThankYouPage",
    svg: passportLogoThankYouPage,
  },
  {
    src: "ItinLogoThankYouPage",
    svg: itinLogoThankYouPage,
  },
  {
    src: "SsnLogoThankYouPage",
    svg: ssnLogoThankYouPage,
  },
  {
    src: "EinLogoThankYouPage",
    svg: einLogoThankYouPage,
  },
  {
    src: "CoaLogoThankYouPage",
    svg: coaLogoThankYouPage,
  },
  {
    src: "CertLogoThankYouPage",
    svg: serLogoThankYouPage,
  },
  {
    src: "TSALogoThankYouPage",
    svg: tsaLogoThankYouPage,
  },
  {
    src: "SsnMailThankYouPage",
    svg: SsnMailThankYouPage,
  },
  {
    src: "check_status_icon",
    svg: checkStatusIcon,
  },
  {
    src: "SubscriptionLogoPage",
    svg: subscriptionLogoPage,
  },
  {
    src: "SubscriptionLogoPage2",
    svg: subscriptionLogoPage2,
  },
  {
    src: "paymentLogoPage",
    svg: paymentLogoPage,
  },
  {
    src: "NortonImage",
    svg: nortonImage,
  },
]
