import React from "react"

interface ISvgCheck {
  width: number;
  height: number;
  color: string;
  backgroundColor?: string;
  fillCircle?: boolean;
}

export const SvgRenewalAdd = ({
  width = 65,
  height = 65,
  color = "#113469",
  backgroundColor = "#EEF4FB",
  fillCircle = true,
}: ISvgCheck) => {
  return (
    <svg width={width} height={height} viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.6734 0.85745C30.885 0.624999 33.115 0.624999 35.3266 0.85745C37.5382 1.0899 39.7194 1.55353 41.8344 2.24073C43.9493 2.92792 45.9865 3.83491 47.9123 4.94681C49.8382 6.05872 51.6423 7.36943 53.2949 8.85746C54.9475 10.3455 56.4396 12.0026 57.7467 13.8017C59.0538 15.6008 60.1688 17.532 61.0733 19.5635C61.9778 21.5951 62.6669 23.7159 63.1293 25.8911C63.5916 28.0663 63.8247 30.284 63.8247 32.5078C63.8247 34.7316 63.5916 36.9493 63.1293 39.1245C62.6669 41.2998 61.9778 43.4205 61.0733 45.4521C60.1688 47.4836 59.0538 49.4148 57.7467 51.2139C56.4396 53.013 54.9475 54.6701 53.2949 56.1582C51.6423 57.6462 49.8382 58.9569 47.9123 60.0688C45.9865 61.1807 43.9493 62.0877 41.8344 62.7749C39.7194 63.4621 37.5382 63.9257 35.3266 64.1582C33.115 64.3906 30.885 64.3906 28.6734 64.1582C26.4618 63.9257 24.2806 63.4621 22.1656 62.7749C20.0507 62.0877 18.0135 61.1807 16.0877 60.0688C14.1618 58.9569 12.3577 57.6462 10.7051 56.1582C9.0525 54.6701 7.5604 53.013 6.25328 51.2139C4.94615 49.4148 3.8312 47.4836 2.92669 45.4521C2.02218 43.4205 1.3331 41.2998 0.870745 39.1245C0.408388 36.9493 0.175299 34.7316 0.175299 32.5078C0.175299 30.284 0.408388 28.0663 0.870744 25.8911C1.3331 23.7159 2.02218 21.5951 2.92669 19.5635C3.8312 17.532 4.94615 15.6008 6.25328 13.8017C7.5604 12.0026 9.0525 10.3455 10.7051 8.85745C12.3577 7.36943 14.1618 6.05872 16.0877 4.94681C18.0135 3.83491 20.0507 2.92792 22.1656 2.24073C24.2806 1.55353 26.4618 1.0899 28.6734 0.85745Z"
        fill={backgroundColor}
      />
      <path
        d="M41.9975 24.2344V29.6892H37.543"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.2812 28.7808C24.7423 27.4778 25.5259 26.3128 26.5589 25.3946C27.592 24.4764 28.8408 23.8349 30.1888 23.5299C31.5368 23.2248 32.9401 23.2663 34.2678 23.6503C35.5955 24.0344 36.8042 24.7485 37.7813 25.7261L41.9994 29.6899"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 40.7829V35.3281H26.4545"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 35.3281L26.2182 39.2919C27.1952 40.2695 28.404 40.9836 29.7316 41.3677C31.0593 41.7517 32.4626 41.7932 33.8106 41.4882C35.1587 41.1831 36.4075 40.5416 37.4405 39.6234C38.4735 38.7052 39.2571 37.5402 39.7182 36.2373"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M32 35.5078V29.5078" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M29 32.5078H35" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
