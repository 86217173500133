import BaseCookiesRepository from "./BaseCookiesRepository";

const FBC_VARS_KEY = "_fbc";

class FbcRepository extends BaseCookiesRepository {

    static FBC_DEFAULT_VALUE = "";

    private static _instance: FbcRepository;

    private constructor() {
        super()
    }

    public static getInstance(): FbcRepository {
        if (!FbcRepository._instance) {
            FbcRepository._instance = new FbcRepository();
        }
        return FbcRepository._instance;
    }

    getKeyName(): string {
        return FBC_VARS_KEY;
    }
}

export default FbcRepository;
