import { useCallback } from "react"
import React, { FC } from "react"
import LayoutDndUploadPhoto from "../Layouts/LayoutDndUploadPhoto/LayoutDndUploadPhoto"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useDispatch } from "react-redux"
import { sentPhotoToBack } from "../../../../Redux/store/upload/upload.actions"
import { BasicBlockContainerProps } from "../UploadPhotoService/interfaces/interfaces"
import { renderBasicBlockContainer } from "../UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"
import LayoutBaseContainer from "../Layouts/LayoutBaseContainer/LayoutBaseContainer"
import { delayTimer } from "../../../../Pages/FormPage/FormPage"

const UploadPhotoDndArea: FC<BasicBlockContainerProps> = ({
  item: { className, children: childrenItem, text, state },
  arrayComponents,
}) => {
  const dispatch = useDispatch()
  // for drag and drop upload
  const handleUploadPhoto = useCallback(
    (file: File) => {
      delayTimer(500).then(() => {
        if (file) {
          dispatch(sentPhotoToBack(file))
        }
        return
      })
    },
    [dispatch]
  )

  return (
    <DndProvider
        backend={HTML5Backend}
        key={Math.random()}
        context={window}
    >
      <div className={className}>
        <LayoutDndUploadPhoto onDrop={handleUploadPhoto}>
          {renderBasicBlockContainer({ className, text, childrenItem, arrayComponents, state }, LayoutBaseContainer)}
        </LayoutDndUploadPhoto>
      </div>
    </DndProvider>
  )
}

export default UploadPhotoDndArea
