import AuthUserBearerTokenRepository from "../../persistence/session/AuthUserBearerTokenRepository";
import { env } from "../../env";

const axios = require("axios")
const hostUploadPhoto = env.REACT_APP_HOST_UPLOAD_PHOTO ?? "https://image-processor.govplus.com:3000/"

export const interceptorFunc = () =>
  axios.interceptors.request.use(
    (config: any) => {
      const token = AuthUserBearerTokenRepository.getInstance().getValue()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      config.headers["Content-Type"] = "application/json"
      return config
    },
    (error: string) => Promise.reject(error)
  )

function getHostUploadURL() {
  const lastCharSlash = hostUploadPhoto.endsWith("/")
  return lastCharSlash ? hostUploadPhoto.slice(0, -1) : hostUploadPhoto
}

export default class ServiceDashbord {

  static uploadPhoto = async (photo: File) => {
    const formData = new FormData()
    formData.append("image", photo, photo.name.toLowerCase())
    const response = await axios.post(`${getHostUploadURL()}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return response.data
  }
}
