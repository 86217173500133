import Event from "../Event"
import {EventOptions, EventProps} from "../EventProperties"
import IEvent from "../IEvent";

const EVENT_NAME = "Auto Renewal Completed";

/**
 * Document Renewal Completed
 * @class DocumentRenewalCompleted
 * @extends {Event}
 * @implements {IEvent}
 * @description Document Renewal Completed event
 */
export default class DocumentRenewalCompleted extends Event implements IEvent {
    private readonly _documentSolution: string | undefined;
    /**
     * Creates an instance of DocumentRenewalCompleted.
     * @param documentSolution
     * @memberof DocumentRenewalCompleted
     * @description Document Renewal Completed event
     */
    constructor(
        documentSolution: string | undefined,
        ipAddress: string | undefined
    ) {
        super(EVENT_NAME, ipAddress);
        this._documentSolution = documentSolution;
    }

    /**
     * Get Event Data
     * @returns {EventProps}
     * @memberof DocumentRenewalCompleted
     * @description Document Renewal Completed event
     */
    getEventData(): EventProps {
        return {
            ...this.baseEventData(),
            solution: this._documentSolution,
        }
    }

    /**
     * Get Event Name
     * @returns {string}
     * @memberof DocumentRenewalCompleted
     * @description Get Event Name
     */
    getEventName(): string {
        return this.eventName;
    }

    /**
     * Get page name
     * @returns {string}
     * @memberof DocumentRenewalCompleted
     * @description Get page name
     */
    pageName(): string {
        return "";
    }

    /**
     * Get previous id
     * @returns {string}
     * @memberof DocumentRenewalCompleted
     * @description Get previous id
     */
    getPreviousId(): string {
        return "";
    }

    /**
     * Get user id
     * @returns {string}
     * @memberof DocumentRenewalCompleted
     * @description Get user id
     */
    getUserId(): string {
        return "";
    }

    getEventOptions(): EventOptions | undefined {
        return undefined;
    }
}
