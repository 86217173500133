import React from "react";
import {LabelIconProps} from "./LabelIcon.props";

import './LabelIcon.scss'

const GLabelIcon: React.FC<LabelIconProps> = ({children}) => {
  return (
    <div>
      <div className={'label-icon'}>
        <div className={'label-icon__text'}>{children}</div>
      </div>
    </div>
  )
}

export default GLabelIcon;