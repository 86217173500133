import React from "react"

interface ISvgNewApplication {
  width: number;
  height: number;
  color: string;
}

export const SvgNewApplication = ({ width = 24, height = 25, color }: ISvgNewApplication) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 2.71094H6C5.46957 2.71094 4.96086 2.92165 4.58579 3.29672C4.21071 3.6718 4 4.1805 4 4.71094V20.7109C4 21.2414 4.21071 21.7501 4.58579 22.1252C4.96086 22.5002 5.46957 22.7109 6 22.7109H18C18.5304 22.7109 19.0391 22.5002 19.4142 22.1252C19.7893 21.7501 20 21.2414 20 20.7109V8.71094L14 2.71094Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 2.71094V8.71094H20" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 18.7109V12.7109" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 15.7109H15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
