/* eslint-disable prettier/prettier,indent */
import { getUtm } from "../../../../Redux/store/form/form.reducer"
import { changeFormatEIN } from "./crutsEin/crutsEin"

export const crutSubmit = (submitObj: {} | any) => {
  switch (submitObj["form_id"] && submitObj["form_id"].split("-")[0]) {
    case "ein": {
      return changeFormatEIN(submitObj)
    }
    default:
      return submitObj
  }
}

export const compareSubmit = (submitObj: {} | any) => {
  if (getUtm() && JSON.stringify(getUtm()) === JSON.stringify(submitObj.utm)) {
    delete submitObj.utm
    return { ...submitObj }
  } else if (getUtm() && JSON.stringify(getUtm()) !== JSON.stringify(submitObj.utm)) {
    submitObj.utm = getUtm()
    return { ...submitObj }
  }
  return submitObj
}
