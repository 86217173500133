import React, { FC } from "react"
import { COLORS } from "../../types/Colors"
import { ICONS_SVG_TYPE } from "../../atoms/IconsSvg/IconsSvgTypes"
import { SIZE } from "../../types/types"
import GCheckListItem from "./CheckListItem"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"

export enum CHECK_LIST_TYPE {
  SUCCESS,
  WARNING,
  DANGER,
  CUSTOM,
}

type GCheckListProps = {
  type?: number,
  text: string,
  bubbleWidth: number,
  bubbleHeight: number,
  textColor?: string,
  bubbleColor?: string,
  iconsType?: string,
  iconsWidth?: number,
  iconsHeight?: number,
  iconsColor?: string,
}

/**
 * Example of use  <GCheckList text={"Testing Text"} type={CHECK_LIST_TYPE.SUCCESS}/>
 * @param text
 * @param type
 * @param bubbleWidth
 * @param bubbleHeight
 * @param textColor
 * @param bubbleColor
 * @param iconsType
 * @param iconsWidth
 * @param iconsHeight
 * @param iconsColor
 * @constructor
 */
const GCheckList: FC<GCheckListProps> = ({
  text,
  type = CHECK_LIST_TYPE.SUCCESS,
  bubbleWidth = 28,
  bubbleHeight = 28,
  textColor,
  bubbleColor,
  iconsType,
  iconsWidth,
  iconsHeight,
  iconsColor,
}) => {
  const breakPoint = useBreakpoint()
  const getComponent = () => {
    if (type === CHECK_LIST_TYPE.SUCCESS) {
      return (
        <GCheckListItem
          text={text}
          textSize={SIZE.PARAGRAPH_REGULAR_16}
          textColor={COLORS.SLATE_GRAY_500}
          bubbleColor={COLORS.GREEN_50}
          bubbleWidth={bubbleWidth}
          bubbleHeight={bubbleHeight}
          iconsType={ICONS_SVG_TYPE.CHECK}
          iconsWidth={18}
          iconsHeight={17}
          iconsColor={COLORS.GREEN_500}
        />
      )
    } else if (type === CHECK_LIST_TYPE.WARNING) {
      return (
        <GCheckListItem
          text={text}
          textSize={SIZE.PARAGRAPH_REGULAR_16}
          textColor={COLORS.SLATE_GRAY_500}
          bubbleColor={COLORS.YELLOW_50}
          bubbleWidth={bubbleWidth}
          bubbleHeight={bubbleHeight}
          iconsType={ICONS_SVG_TYPE.CHECK}
          iconsWidth={18}
          iconsHeight={17}
          iconsColor={COLORS.YELLOW_500}
        />
      )
    } else if (type === CHECK_LIST_TYPE.DANGER) {
      return (
        <GCheckListItem
          text={text}
          textSize={SIZE.PARAGRAPH_REGULAR_16}
          textColor={COLORS.SLATE_GRAY_500}
          bubbleColor={COLORS.RED_50}
          bubbleWidth={bubbleWidth}
          bubbleHeight={bubbleHeight}
          iconsType={ICONS_SVG_TYPE.X}
          iconsWidth={18}
          iconsHeight={18}
          iconsColor={COLORS.RED_500}
        />
      )
    } else if (type === CHECK_LIST_TYPE.CUSTOM) {
      return (
        <>
          {bubbleColor && textColor && iconsType && (
            <GCheckListItem
              text={text}
              textSize={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14}
              textColor={textColor}
              bubbleColor={bubbleColor}
              bubbleWidth={bubbleWidth}
              bubbleHeight={bubbleHeight}
              iconsType={iconsType}
              iconsWidth={iconsWidth ? iconsWidth : 18}
              iconsHeight={iconsHeight ? iconsHeight : 18}
              iconsColor={iconsColor ? iconsColor : COLORS.WHITE}
            />
          )}
        </>
      )
    } else {
      return <span>Unsupported</span>
    }
  }
  return <div className="GCheckList">{getComponent()}</div>
}

export default GCheckList
