import React from "react";
import "./PageNotFound.less";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import Header from "../../containers/Header";
import SettingsButton from "../SettingsButton";
import GText from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Text/Text"
import GButton from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Button/GButton"
import GLogo from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Logo/Logo"
import { SIZE } from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import { COLORS_NEW } from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { GeneralText } from "@dash/gov-plus__front-end__form/src/shared/text/Text";

const PageNotFound = () =>{
  const history = useHistory();
  const {authToken} = useSelector(({auth}) => auth);
  const breakpoint = useBreakpoint()

  const backToHome = () =>{
    if (authToken === null) {
      history.push('/signin');
    }else{
      history.push('/');
    }
  }

  return (
    <div className={"pageNotFound_container"}>
      <GLogo width={110} height={28} />
      <div className={"pageNotFound_container_box"}>
        <div className={"header"}>
          <GText text={GeneralText.PageNotFound} size={SIZE.HEADING_LG} />
          <GText 
            text={GeneralText.PageNotFoundSubHeader} 
            size={breakpoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16} 
            color={COLORS_NEW.BLACK_500} 
            maxWidth={"376px"} 
          />
        </div>
        <div className="button">
          <GButton 
            text="GOV+ home page"
            click={backToHome}
          />
        </div>
      </div>

    </div>
  )
};


export default PageNotFound;
