import React from "react"

interface ISvgCheck {
    width: number;
    height: number;
    color?: string;
    backgroundColor?: string;
}

export const SvgRefresh = ({width, height, color = "#216DE3", backgroundColor = "#E2EEFF"}: ISvgCheck) => {
    const viewBox = `0 0 ${width} ${height}`
    const rx = width / 2
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width={width} height={height} rx={rx} fill={backgroundColor}/>
            <path d="M38.5004 20.7263V26.1811H33.0459" stroke={color} strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M18.5 35.2717V29.8169H23.9545" stroke={color} strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M20.7818 25.2722C21.2429 23.9692 22.0265 22.8043 23.0595 21.8861C24.0925 20.9679 25.3413 20.3263 26.6894 20.0213C28.0374 19.7163 29.4407 19.7577 30.7684 20.1418C32.096 20.5258 33.3048 21.24 34.2818 22.2175L38.5 26.1814M18.5 29.8179L22.7182 33.7817C23.6952 34.7593 24.904 35.4734 26.2316 35.8575C27.5593 36.2415 28.9626 36.2829 30.3106 35.9779C31.6587 35.6729 32.9075 35.0314 33.9405 34.1131C34.9735 33.1949 35.7571 32.03 36.2182 30.727"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
