import React, {useEffect} from "react";
import "./index.less";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearMessage} from "../../appRedux/actions";
import GWelcome from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/templates/Welcome/Welcome";
import GLogo from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Logo/Logo";
import { env } from "../../env";

const WelcomePage = () =>{
  const history = useHistory();
  const dispatch = useDispatch();
  const {authToken, alertMessage, userData: { eMail }, isAuthFinished} = useSelector(({auth}) => auth);

  useEffect(()=>{
    dispatch(clearMessage())
    const userCameFromGovPage = sessionStorage.getItem("userCameFromGov")
    if (isAuthFinished){
      if (!authToken) {
        history.push('/signin');
      } else if (authToken && userCameFromGovPage){
        window.location.replace(`${env.REACT_APP_GOV_URL}=${userCameFromGovPage}&fromDashboard=true`);
        sessionStorage.removeItem("userCameFromGov");
      } else if(authToken){
        history.push('/');
      }
    }

  }, [authToken, isAuthFinished])

  useEffect(() => {
    if (alertMessage === 'common.USER_TOKEN_EXPIRED') {
      localStorage.removeItem(`otpToken/${eMail}`)
      localStorage.removeItem('phoneSlice')
      history.push('/signin')
    }
  }, [alertMessage])

  return(
    <div className="gx-app-login-wrap welcomePage">
      <div className={'gx-app-login-logo'}>
        <GLogo width={110} height={28} />
      </div>
      <GWelcome />
    </div>
  )
}



export default WelcomePage;
