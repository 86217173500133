import {apiAxiosInstance} from "./apiHelper";

export const userService = {
  async authenticate(userCredentials) {
    return await apiAxiosInstance.dashboardBackInstance("post", "authenticate", userCredentials);
  },

  async checkUserLink(tokenFromActivationLink) {
    return await apiAxiosInstance.dashboardBackInstance("post", "/users/verifyActivationToken", tokenFromActivationLink);
  },

  async getUserApi () {
    return await apiAxiosInstance.dashboardBackInstance("get", "/getIpData")
  },

  async retargetingLinkGenerator (state) {
    return await apiAxiosInstance.dashboardBackInstance("put", "/usersState/data", state)
  },

  async getDataFromRetargetingLink (retargetingToken) {
    return await apiAxiosInstance.dashboardBackInstance("get", `/usersState/data/${retargetingToken}`)
  },

  async activationEmail(client_token) {
    return await apiAxiosInstance.formsBackInstance("post", "/sendActivationEmail", {client_token});
  },

  async saveUserDataFromModal(fullUserData) {
    return await apiAxiosInstance.autofillMicroserviceInstance("put", "/usersData/data", fullUserData);
  },

  async userPreAuth(userData) {
    return await apiAxiosInstance.dashboardBackInstance("post", "/users/createPreAuth", userData);
  },

  async getUserDataFromModal() {
    return await apiAxiosInstance.autofillMicroserviceInstance("get", "/usersData/data");
  },

  async getProgressBar(client_token) {
    return await apiAxiosInstance.formsBackInstance("post", "/getFormFillPercent", {client_token});
  },

  async checkIsTokenActive(token) {
    return await apiAxiosInstance.dashboardBackInstance("post", "/users/checkIsTokenActive", {token});
  },

  async userFlatternData() {
    return await apiAxiosInstance.dashboardBackInstance("get", "/usersData/data");
  },

  checkResetToken(reset_token) {
    return apiAxiosInstance.dashboardBackInstance("post", "/users/checkResetToken", {reset_token});
  },

  async saveUtmVars(email, utm_vars, fbc, fbp, gclid, msclkid, eventFlowId, impactRadiusId) {
    const params = {
      email,
      utm_vars,
      fbc,
      fbp,
      gclid,
      msclkid,
      event_flow_Id: eventFlowId,
      impact_radius_id: impactRadiusId
    }
    return apiAxiosInstance.utmBackInstance("post", "/saveUtmVars", params);
  },

  async checkUserExist(email) {
    return await apiAxiosInstance.dashboardBackInstance("post", "/users/checkUserExists", {email});
  },

  generateSms(phone, outer_id) {
    return apiAxiosInstance.dashboardBackInstance("post", "/users/generateSMS", {phone, outer_id});
  },

  async userDataFromDash() {
    return await apiAxiosInstance.dashboardBackInstance("get", "/profile");
  },

  async changeUserPassword(current_password, new_password) {
    return await apiAxiosInstance.dashboardBackInstance("post", "/users/changePassword", {
      current_password,
      new_password
    });
  },

  async getFooterData() {
    return await apiAxiosInstance.dashboardBackInstance("get", "/webflow/getItem/63fa70698bac0ad3e24f2cfb/63fa70698bac0a85cf4f2df5");
  },

  async sendResetInstruction(email) {
    return await apiAxiosInstance.dashboardBackInstance("post", "/users/reset", {email});
  },

  async ResetForGotPassword(new_password, reset_token) {
    return await apiAxiosInstance.dashboardBackInstance("post", "/users/changePassword", {new_password, reset_token});
  },

  async forGotPassword(current_password, new_password) {
    return await apiAxiosInstance.dashboardBackInstance("post", "/profile/changePassword", {
      current_password,
      new_password
    });
  },

  async createNewUser(newUserFullData) {
    return await apiAxiosInstance.dashboardBackInstance("post", "/users/create", {...newUserFullData});
  },

  async authUserSMS(smsAuthData) {
    return await apiAxiosInstance.dashboardBackInstance("post", "/users/verifyUserSMS", smsAuthData);
  },

  async userLogOut() {
    return await apiAxiosInstance.dashboardBackInstance("get", "/users/logout");
  },
  async getIntercomUserHash(token) {
    return await apiAxiosInstance.userBackInstance("post", "/getIntercomUserHash", token);
  },
  async getUserBillingInformation(client_token) {
    return await apiAxiosInstance.userBackInstance("post", "/getCustomerPaymentMethodsByClientToken", {client_token})
  },
  async saveUserPaymentMethod(data) {
    return await apiAxiosInstance.userBackInstance("post", "/savePaymentMethod", data)
  },
  async updateDefaultPaymmentMethod(data) {
    return await apiAxiosInstance.userBackInstance("post", "/updateDefaultPaymentMethod", data)
  },
  async getCustomer(client_token) {
    return await apiAxiosInstance.userBackInstance("post", "/getCustomerByClientToken", {client_token})
  },
  async getSubmittedDashUser(client_token) {
    return await apiAxiosInstance.userBackInstance("post", "/getDashUserInfo", {client_token})
  }
}

export const subscriptionService = {
  async getUserSubscriptionStatus() {
    return await apiAxiosInstance.subscriptionBackInstance("get", "subscriptions/my-status")
  },
  async getInsuredDocumentsFromUser() {
    return await apiAxiosInstance.insuranceBackInstance("get", "/documents/insurance")
  },
  async getRenewalDocumentsFromUser() {
    return await apiAxiosInstance.insuranceBackInstance("get", "/documents/automatic-renewal")
  },
  async subscribe(customerBillingID, paymentMethodID, period) {
    return await apiAxiosInstance.subscriptionBackInstance("post", `subscriptions/?customerBillingId=${customerBillingID}&paymentSourceId=${paymentMethodID}&period=${period}`)
  },
  async subscribeGOV(data) {
    return await apiAxiosInstance.subscriptionBackInstanceGOV("post", `/subscriptions/subscriptions`, data)
  },
  async unsubscribe(data) {
    return await apiAxiosInstance.subscriptionInstanceWithDataBody("delete",`subscriptions/`, {...data})
  },
  async insureDocument(document) {
    return await apiAxiosInstance.insuranceBackInstance("post", "/documents/insurance", document)
  },
  async renewalDocument(document) {
    return await apiAxiosInstance.insuranceBackInstance("post", "/documents/automatic-renewal", document)
  },
  async updateSubscriptionPaymentMethod(paymentSourceId) {
    return await apiAxiosInstance.subscriptionBackInstance("patch", "/subscriptions/update-payment-source", {paymentSourceId})
  },
}

export const productsApis = {

  async getAllProductsApi(token) {
    return await apiAxiosInstance.userBackInstance("post", "/getApplications", {client_token:token});
  },

  async saveGovAppointment(token, form_id, application_id, appointment_time) {
    return await apiAxiosInstance.formsBackInstance("post", "/saveGovAppointment", {client_token: token, form_id, application_id, appointment_time});
  },

  async checkUserSelectedAppForDuplicate(form_id, client_token) {
    return await apiAxiosInstance.formsBackInstance("post", "/checkSameApplication", {form_id, client_token});
  },

  async getGovProducts() {
    return await apiAxiosInstance.dashboardBackInstance("get", "/products/getFromWebflow");
  },

  async getSolutionsData() {
    return await apiAxiosInstance.dashboardBackInstance("get", "/webflow/getCollection/63fa70698bac0a02eb4f2cf7");
  },

  async getApplicationCompletion(application_id, product_id, form_id) {
    return await apiAxiosInstance.formsBackInstance("get", "/getApplicationCompletion", {application_id, product_id, form_id})
  }
}

export const billingApis = {
  async getCustomerBilling(token) {
    return await apiAxiosInstance.formsBackInstance("post", "/getCustomerBilling", {token});
  },

  async confirmSubscription(data) {
    return await apiAxiosInstance.formsBackInstance("post", "/customerSubscription", data);
  }
}
export const taxApis = {
  async postTaxPageLink(data) {
    return await apiAxiosInstance.taxBackInstance("post", '/taxes', data);
  }
}
export const offerApis = {
  async getPaymentModel() {
    return await apiAxiosInstance.offerInstance("get", '/getPaymentModel')
  }
}

export const postTrackingApis = {
  async postTrackingData(client_token, application_id, to_customer) {
    return await apiAxiosInstance.trackingBackInstance("post", '/tracking/trackingInformation', {client_token, application_id, to_customer});
  }
}

export const identityProtectionApis = {
  async getRecords(recordType) {
    return await apiAxiosInstance.identityProtectionBackInstance("get", `/identity-protection/records?recordType=${recordType}`);
  },
  async postRecord(data) {
    return await apiAxiosInstance.identityProtectionBackInstance("post", '/identity-protection/records', data);
  },
  async deleteRecord(monitorId) {
    return await apiAxiosInstance.identityProtectionBackInstance("delete", `/identity-protection/records/${monitorId}`);
  },
  async postIdentities() {
    return await apiAxiosInstance.identityProtectionBackInstance("post", '/identity-protection/identities');
  },
  async getMyIdentities() {
    return await apiAxiosInstance.identityProtectionBackInstance("get", '/identity-protection/identities/me');
  },
  async getAlerts(type) {
    return await apiAxiosInstance.identityProtectionBackInstance("get", `/identity-protection/alerts?type=${type}`);
  },
  async dismissAlert(alertId, status) {
    return await apiAxiosInstance.identityProtectionBackInstance("patch", `/identity-protection/alerts/${alertId}`, status);
  },
  async getEmailBreaches() {
    return await apiAxiosInstance.identityProtectionBackInstance("get", '/identity-protection/email-breaches');
  },
  async addMissingFieldsToUser(data) {
    return await apiAxiosInstance.patchUserInstance("post", '/user/updateCustomer', data);
  },
  async getCreditScores() {
    return await apiAxiosInstance.identityProtectionBackInstance("get", '/identity-protection/credit-scores/me');
  },
  async verifyUserCreditScores(data) {
    return await apiAxiosInstance.identityProtectionBackInstance("post", '/identity-protection/identities/me/verify', data);
  },
  async checkVerificationStatus() {
    return await apiAxiosInstance.identityProtectionBackInstance("post", '/identity-protection/identities/me/authenticate');
  },
  async patchUserBundle(data) {
    return await apiAxiosInstance.identityProtectionBackInstance("patch", 'identity-protection/identities/me', data);
  },
  async patchUserAddress(data) {
    return await apiAxiosInstance.identityProtectionBackInstance("patch", '/identity-protection/identities/me', data);
  }
}