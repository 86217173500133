import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {IconSvg} from "../../svgImages/SvgImages";
import './DuplicateApplicationModal.less'
import {useDispatch, useSelector} from "react-redux";
import {
  getProducts, setSelectedFormId,
  showModalsType
} from "../../../appRedux/actions";
import Spinner from "../../spiner/Spinner";
import GText from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/atoms/Text/Text";
import {SIZE} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/types";
import {COLORS_NEW} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";
import GMyApplicationCard from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/MyApplicationCard/MyApplicationCard";
import StringHelper from "@dash/gov-plus__front-end__form/src/util/StringHelper";
import { env } from "../../../env";

const DuplicateApplicationModal = ({productInfo}) => {
  const {allProducts, allProductsLoader, anotherGovProducts, webFlowData} = useSelector(({allProducts}) => allProducts);
  const {authToken} = useSelector(({auth}) => auth);
  const service = JSON.parse(localStorage.getItem("webFlowServicesData"))[allProducts[productInfo?.application_id]?.form_id]?.text;
  const solution = JSON.parse(localStorage.getItem("webFlowServicesData"))[allProducts[productInfo?.application_id]?.form_id]?.title;
  const dispatch = useDispatch();

  const onContactCLick = () => {
    window.open(`${env.REACT_APP_GOV_HOME_PAGE}/contact-us`, "_blank")
  }

  useEffect(()=>{
    if(!Object.keys(allProducts).length || (service === undefined || solution=== undefined)){
      dispatch(getProducts(authToken));
    }
  }, [Object.keys(allProducts).length])

  const onCLoseModal = (event) => {
    event.preventDefault()
    dispatch(showModalsType(''))
    dispatch(setSelectedFormId(''))
  }

  return (
    <div className={'duplicateModal-container'}>
      {allProductsLoader
        ? <Spinner/>
        : <>
          <div className={'duplicateModal-container_close-icon'} onClick={onCLoseModal}><IconSvg type={'close_icon'}/>
          </div>

          <div className={`duplicateModal-container_title`}>
            <GText text={`Duplicate application`} size={SIZE.HEADING_MDLG} color={COLORS_NEW.BLACK_1000} />
          </div>
          <div className={`duplicateModal-container_text`}>
            <GText 
              text={`It looks like you’ve already submitted a ${solution ? StringHelper.capitalizeServiceName(solution) : ''} application recently.`} 
              size={SIZE.PARAGRAPH_BOLD_18} 
              color={COLORS_NEW.BLACK_600} 
            />
          </div>

          <div className={`duplicateModal-container_application-card`}>
            <GMyApplicationCard 
              date={productInfo?.['date_apply']}
              productId={productInfo?.application_id}
              allProducts={allProducts}
              anotherGovProducts={anotherGovProducts}
              webFlowData={webFlowData}
              completedPercentage={allProducts[productInfo?.application_id]?.completion}
            />
          </div>
          
          <span className={'duplicateModal-container_text'}>
              If you need to change some of the information you submitted on your {solution ? StringHelper.capitalizeServiceName(solution) : ''} application, please 
              <span className={'link assistant-button'} onClick={onContactCLick}> contact support </span> and our experts will be happy to help.
          </span>
        </>
      }
    </div>
  )
}

export default DuplicateApplicationModal
