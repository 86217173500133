import {clearStorage} from "../appRedux/actions";
import {USER_IP} from "@dash/gov-plus__front-end__form/src/persistence/session/UserIpRepository";
import SessionStorageHandler from "@dash/gov-plus__front-end__form/src/persistence/SessionStorageHandler";
import axios from "axios";
import {notifyError} from "@dash/gov-plus__front-end__form/src/util/helper";
import ClientTokenRepository from "@dash/gov-plus__front-end__form/src/persistence/cookies/ClientTokenRepository";
import {Path} from "@dash/gov-plus__front-end__form/src/util/PageHelper"
import { env } from "../env";

const UserLogin = {
  logout: async (dispatch, history) => {
    const dashboard_back = env.REACT_APP_GOV_DASHBOARD;
    const isUserHasTheTokenInCookies = ClientTokenRepository.getInstance().getValue();

    try {
      await axios.get(`${dashboard_back}/users/logout`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${isUserHasTheTokenInCookies}`
        }
      })
    } catch (err) {
      notifyError(err)
    }

    dispatch(clearStorage())
    SessionStorageHandler.clearIgnoringKey([USER_IP]);
    for (let key in localStorage) {
      if (key.split('/')[0] !== "otpToken") {
        localStorage.removeItem(key);
      }
    }
    history.push(Path.SIGN_IN);
  }
}

export default UserLogin;