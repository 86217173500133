import { Action } from "../../interfacesAction/action.interface"
import {PaymentError} from "../../../dto/PaymentError";

export const ActionTypes = {
  SET_ACTIVE_INPUT: "[FORM] Set active input",
  SET_INITIAL_HELPER: "[FORM] set initial helper ",

  SET_CHANGED_ADDRESS: "[FORM] set changed address",

  POST_CURRENT_STORE_IN_INTERVAL: "[FORM] Post current store in interval",

  SET_VALUE_CURRENT_FIELD: "[FORM] set value current field",
  REMOVE_VARIABLE_CURRENT_FIELD: "[FORM] remove variable current field",
  SET_INITIAL_FIELDS: "[FORM] set initial fields",
  SUBMIT_FORM: "[FORM] submit form",

  SET_OBSERVERS: "[FORM] set observers",
  REMOVE_OBSERVER: "[FORM] remove observer",
  SET_OBSERVERS_CHAIN: "[FORM] set observers chain",

  SET_PREV_NAME_OPTION: "[FORM] set prev name option",

  SET_VALUE_MODAL: "[MODAL] set value modal",

  SET_SCROLL_Y_VALUE: "[HELPER] set scrollY value",

  SET_CLIENT_ID: "[CLIENT ID] set client id",
  SET_CLIENT_EMAIL: "[EMAIL] set client email",
  SET_APP_ID: "[APP ID] set app id",
  SET_FORM_ID: "FORM ID set form id",
  SET_PDF: "[PDF] set PDF",
  SET_USER_IP: "[USER_IP] set user ip",
  SET_QUESTIONARY: "[QUESTIONARY] set questionary",

  SUBMIT_FORM_WITHOUT_PAYMENT: "[FORM] submit form without payment",
  MAKE_PAYMENT: "[PAYMENT] make payment",
  CONFIRM_SUBSCRIPTION: "[PAYMENT] confirm subscription",
  PAY_GOVERNMENT_FEES: "[PAYMENT] government fees",
  PAY_APPLICATION_FEE: "[PAYMENT] APPLICATION FEE",

  PAY_GOVERNMENT_FEES_FROM_SCRAP: "[PAYMENT] government fees from scrap",

  GET_AUTOCOMPLETE: "[AUTOCOMPLETE] get autocomplete",

  SET_BILLING_ADRESS: "[BILLING ADRESS] set billing address",
  SET_CUSTOMER: "[CLIENT DATA] set customer",
  SET_SERVICES_CUSTOMER: "[CUSTOMER_SERVICES] set services customers",

  SET_SUBMIT_OBJECT: "[SUBMIT_OBJECT] set submit object",
  SET_STATUS_ON_SUBMIT_OBJECT: "[SUBMIT_OBJECT] set status on submit object",
  SET_PREFILLED_OBJECT: "[PREFILLED_OBJECT] set prefilled object",
  SET_NEW_PREFILLED_OBJECT: "[PREFILLED_OBJECT] set new prefilled object",
  SET_TAG_FIELDS: "[PREFILLED_OBJECT] set tag fields",

  CHECK_ADDRESS_VALIDATION: "[ADDRESS] check address validation",

  SET_DISCOUNT: "[DISCOUNT] set discount",

  SET_PRICING: "[PRICING] set pricing values",

  SET_DOCUMENT_LIST: "[DOCUMENTS] set document list",

  SET_PAYMENT_ERROR: "[PAYMENT ERROR] set payment error",

  GET_FAILURE: "[getFailureAction] get failure action",

  STRIPE_LOADED: "[STRIPE_LOADED] disabled payment button action",

  PAYMENT_METHOD: "[PAYMENT_METHOD] set payment method",

  SHOW_STRIPE_FORM: "[SHOW_STRIPE_FORM] show stripe form",

  SET_STATUS_STRIPE: "[SET_STATUS_STRIPE] show stripe form",

  SHOW_ADD_PAYMENT_METHOD_BUTTON: "[SHOW_ADD_PAYMENT_BUTTON] show payment button",

  CLEAR_SUBMIT_BY_EMPTY_PROP: "[SUBMIT_OBJECT] Clear submit by empty prop",
  CLEAR_SUBMIT_IN_START_APP: "[SUBMIT_OBJECT] Clear submit in start app",

  SET_SUBMIT_FORM_ERROR: "[SUBMIT_FORM_ERROR] Set submit form error",
}

export const setActiveInput = (currentInput: {}): Action<{}> => ({
  type: ActionTypes.SET_ACTIVE_INPUT,
  payload: currentInput,
})

export const setInitialHelper = (): Action<{}> => ({
  type: ActionTypes.SET_INITIAL_HELPER,
})

// Filling and submit

export const setChangedAddress = (newAddress: {} | any): Action<{}> => ({
  type: ActionTypes.SET_CHANGED_ADDRESS,
  payload: newAddress,
})

export const setValueCurrentField = (
  nameCurrentField: string,
  valueCurrentField: string | number | any
): Action<{}> => ({
  type: ActionTypes.SET_VALUE_CURRENT_FIELD,
  payload: { [nameCurrentField]: valueCurrentField },
})

export const removeVariableCurrentField = (nameCurrentField: string): Action<{}> => ({
  type: ActionTypes.REMOVE_VARIABLE_CURRENT_FIELD,
  payload: nameCurrentField,
})

export const postCurrentStoreInInterval = (toggle: boolean, submitObject: {} | null): Action<any> => ({
  type: ActionTypes.POST_CURRENT_STORE_IN_INTERVAL,
  payload: { toggle, submitObject },
})

export const setInitialFields = (): Action<{}> => ({
  type: ActionTypes.SET_INITIAL_FIELDS,
})

export const submitForm = (dataObject: {}): Action<{}> => ({
  type: ActionTypes.SUBMIT_FORM,
  payload: dataObject,
})

export const setObservers = (observablesObj: {}, value: string): Action<any> => ({
  type: ActionTypes.SET_OBSERVERS,
  payload: { observablesObj, value },
})

export const removeObserver = (name: string): Action<string> => ({
  type: ActionTypes.REMOVE_OBSERVER,
  payload: name,
})

export const setObserversChain = (observablesObjChain: {}): Action<{}> => ({
  type: ActionTypes.SET_OBSERVERS_CHAIN,
  payload: observablesObjChain,
})

export const setPrevNameOption = (nameOption: string, nameField: string, valueField: string): Action<any> => ({
  type: ActionTypes.SET_PREV_NAME_OPTION,
  payload: { nameOption, nameField, valueField },
})

export const setScrollYValue = (value: number): Action<any> => ({
  type: ActionTypes.SET_SCROLL_Y_VALUE,
  payload: value,
})

//

export const setValueModal = (value: string): Action<string> => ({
  type: ActionTypes.SET_VALUE_MODAL,
  payload: value,
})

export const setClientId = (id: string): Action<string> => ({
  type: ActionTypes.SET_CLIENT_ID,
  payload: id,
})

export const setClientEmail = (email: string): Action<string> => ({
  type: ActionTypes.SET_CLIENT_EMAIL,
  payload: email,
})

export const setAppId = (id: string): Action<string> => ({
  type: ActionTypes.SET_APP_ID,
  payload: id,
})

export const setFormId = (id: string): Action<string> => ({
  type: ActionTypes.SET_FORM_ID,
  payload: id,
})

export const setPDF = (link: string): Action<string> => ({
  type: ActionTypes.SET_PDF,
  payload: link,
})

export const setUserIp = (value: string): Action<string> => ({
  type: ActionTypes.SET_USER_IP,
  payload: value,
})

export const setQuestionary = (array: []): Action<[]> => ({
  type: ActionTypes.SET_QUESTIONARY,
  payload: array,
})

export const submitFormWithOutPayment = (dataObject: {}): Action<{}> => ({
  type: ActionTypes.SUBMIT_FORM_WITHOUT_PAYMENT,
  payload: dataObject,
})

export const makePayment = (dataObject: {}): Action<{}> => ({
  type: ActionTypes.MAKE_PAYMENT,
  payload: dataObject,
})

export const confirmSubscription = (dataObject: {}): Action<{}> => ({
  type: ActionTypes.CONFIRM_SUBSCRIPTION,
  payload: dataObject,
})

export const payApplicationFee = (dataObject: {}): Action<{}> => ({
  type: ActionTypes.PAY_APPLICATION_FEE,
  payload: dataObject,
})

export const payGovernmentFees = (dataObject: {}): Action<{}> => ({
  type: ActionTypes.PAY_GOVERNMENT_FEES,
  payload: dataObject,
})

export const payGovernmentFeesFromScrap = (dataObject: {}): Action<{}> => ({
  type: ActionTypes.PAY_GOVERNMENT_FEES_FROM_SCRAP,
  payload: dataObject,
})

export const getAutocomplete = (value: string, nameCities: string, nameStates: string): Action<{}> => ({
  type: ActionTypes.GET_AUTOCOMPLETE,
  payload: { value, nameCities, nameStates },
})

export const setBillingAddress = (dataObject: {}): Action<{}> => ({
  type: ActionTypes.SET_BILLING_ADRESS,
  payload: dataObject,
})

export const setCustomer = (dataObject: {}): Action<{}> => ({
  type: ActionTypes.SET_CUSTOMER,
  payload: dataObject,
})

export const setServicesCustomer = (dataObject: {}): Action<{}> => ({
  type: ActionTypes.SET_SERVICES_CUSTOMER,
  payload: dataObject,
})

export const setSubmitObject = (object: any): Action<any> => ({
  type: ActionTypes.SET_SUBMIT_OBJECT,
  payload: object,
})

export const setStatusOnSubmitObject = (status: string): Action<any> => ({
  type: ActionTypes.SET_STATUS_ON_SUBMIT_OBJECT,
  payload: status
})

export const setTagPrefilledObject = (object: any): Action<any> => ({
  type: ActionTypes.SET_PREFILLED_OBJECT,
  payload: object,
})

export const setNewPrefilledObject = (object: any): Action<any> => ({
  type: ActionTypes.SET_NEW_PREFILLED_OBJECT,
  payload: object,
})

export const setTagFields = (object: any): Action<any> => ({
  type: ActionTypes.SET_TAG_FIELDS,
  payload: object,
})

export const checkAddressValidation = (object: any): Action<any> => ({
  type: ActionTypes.CHECK_ADDRESS_VALIDATION,
  payload: object,
})

export const setDiscount = (value: string | number): Action<string | number> => ({
  type: ActionTypes.SET_DISCOUNT,
  payload: value,
})

export const setPricing = (dataObject: {}): Action<{}> => ({
  type: ActionTypes.SET_PRICING,
  payload: dataObject,
})

export const setDocumentList = (dataObject: {}[]): Action<{}[]> => ({
  type: ActionTypes.SET_DOCUMENT_LIST,
  payload: dataObject,
})

export const setSubmitFormError = (dataObject: {}): Action<{}> => ({
  type: ActionTypes.SET_SUBMIT_FORM_ERROR,
  payload: dataObject,
})

export const setPaymentError = (dataObject: PaymentError | null): Action<{} | null> => ({
  type: ActionTypes.SET_PAYMENT_ERROR,
  payload: dataObject,
})

export const getFailureAction = (error?: {} | null): Action<any> => ({
  type: ActionTypes.GET_FAILURE,
  payload: error,
})

export const setStripeLoaded = (disabled: boolean): Action<any> => ({
  type: ActionTypes.STRIPE_LOADED,
  payload: disabled,
})

export const setPaymentMethod = (paymentMethod: string): Action<any> => ({
  type: ActionTypes.PAYMENT_METHOD,
  payload: paymentMethod,
})

export const showStripeForm = (show: boolean): Action<any> => ({
  type: ActionTypes.SHOW_STRIPE_FORM,
  payload: show,
})

export const setStatusStripe = (status: string): Action<any> => ({
  type: ActionTypes.SET_STATUS_STRIPE,
  payload: status,
})

export const clearSubmitByEmptyPropStore = (submit: {}): Action<{}> => ({
  type: ActionTypes.CLEAR_SUBMIT_BY_EMPTY_PROP,
  payload: submit,
})

export const clearSubmitInStartAPP = () => ({
  type: ActionTypes.CLEAR_SUBMIT_IN_START_APP,
})

export const showAddPaymentButton = (show: boolean): Action<any> => ({
  type: ActionTypes.SHOW_ADD_PAYMENT_METHOD_BUTTON,
  payload: show,
})
