export const initialClassObject = {
  name: "",
  action: () => {},
}

export const initialEmail = "xxx@xxx.com"

type ActionType = (child: ChildInterface, email: string) => void

interface ClassInterface {
  name: string;
  action: ActionType;
}

interface ChildInterface {
  className: string;
  innerText: string;
}

export const arrayClasses = [
  {
    name: "dynamic-text",
    action: (child: ChildInterface, email: string) => (child.innerText = email),
  },
]

const getDynamicAction = ({ action }: ClassInterface = initialClassObject, child: ChildInterface, email: string) =>
  action(child, email)

export const filterChildByClass = (
  arrayChildren: ChildInterface[] | never[] = [],
  arrayClasses: ClassInterface[],
  email: string
) =>
  arrayChildren?.forEach(
    (child) =>
      child.className &&
      getDynamicAction(
        arrayClasses.find(({ name }: ClassInterface) => child.className === name),
        child,
        email
      )
  )
