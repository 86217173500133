import UtmDto from "../dto/ParamsUriDto/UtmDto";
import GclidRepository from "../persistence/cookies/GclidRepository";
import MsclkidRepository from "../persistence/cookies/MsclkidRepository";
import EverFlowIdRepository from "../persistence/cookies/EverFlowIdRepository";
import ImpactRadiusIdRepository from "../persistence/cookies/ImpactRadiusIdRepository";
import UtmRepository from "../persistence/cookies/UtmRepository";
import FbcRepository from "../persistence/cookies/FbcRepository";
import FbpRepository from "../persistence/cookies/FbpRepository";
import OfferCouponRepository from "../persistence/session/OfferCouponRepository";

export interface IUrlAnalyticQueryParams {
    utmVars: string;
    analytic_fbc: string | null;
    analytic_fbp: string | null;
    analytic_gclid: string | null;
    analytic_msclkid: string | null;
    analytic_ever_flow_id: string | null;
    analytic_impact_radius_id: string | null;
}

export enum UrlQueryParams {
    ApplicationId = "applicationId",
}

export const persistUrlAnalyticQueryParams = (paramsDto: UtmDto) => {
    if (paramsDto.gclid) {
        GclidRepository.getInstance().setValue(paramsDto.gclid ?? GclidRepository.getInstance().getValue());
    }
    if (paramsDto.msclkid) {
        MsclkidRepository.getInstance().setValue(paramsDto.msclkid ?? MsclkidRepository.getInstance().getValue());
    }
    if (paramsDto.everFlowId) {
        EverFlowIdRepository.getInstance().setValue(paramsDto.everFlowId ?? EverFlowIdRepository.getInstance().getValue());
    }
    if (paramsDto.impactRadiusId) {
        ImpactRadiusIdRepository.getInstance().setValue(paramsDto.impactRadiusId ?? ImpactRadiusIdRepository.getInstance().getValue());
    }

    if (paramsDto.offerCouponCode) {
        const currentOfferCoupon = OfferCouponRepository.getInstance().getValue();

        OfferCouponRepository.getInstance().setValue(paramsDto.offerCouponCode, currentOfferCoupon);
    }

    UtmRepository.getInstance().setUtm({
        utm_source: paramsDto.utmSource ?? UtmRepository.getInstance().getUtm().utm_source,
        utm_medium: paramsDto.utmMedium ?? UtmRepository.getInstance().getUtm().utm_medium,
        utm_campaign: paramsDto.utmCampaign ?? UtmRepository.getInstance().getUtm().utm_campaign,
        utm_content: paramsDto.utmContent ?? UtmRepository.getInstance().getUtm().utm_content,
        utm_term: paramsDto.utmTerm ?? UtmRepository.getInstance().getUtm().utm_term
    });
}

export const getUrlAnalyticQueryParams = (): IUrlAnalyticQueryParams => {
    const utmVars = UtmRepository.getInstance().getValue();
    const analytic_fbc = FbcRepository.getInstance().getValue();
    const analytic_fbp = FbpRepository.getInstance().getValue();
    const analytic_gclid = GclidRepository.getInstance().getValue();
    const analytic_msclkid = MsclkidRepository.getInstance().getValue();
    const analytic_ever_flow_id = EverFlowIdRepository.getInstance().getValue();
    const analytic_impact_radius_id = ImpactRadiusIdRepository.getInstance().getValue();
    return {
        utmVars,
        analytic_fbc,
        analytic_fbp,
        analytic_gclid,
        analytic_msclkid,
        analytic_ever_flow_id,
        analytic_impact_radius_id
    }
}