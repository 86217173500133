import React, { FC, useState } from "react"
import "./CreditCardDetailsForm.scss"
import { Form } from "antd"
import GText from "../../../atoms/Text/Text"
import GButton from "../../../molecules/Button/GButton"
import GInputText from "../../../molecules/InputText/InputText"
import GSelect from "../../../molecules/Select/Select"
import { COLORS_NEW } from "../../../types/Colors"
import { SIZE, BUTTON_TYPE } from "../../../types/types"
import InputMask from "react-input-mask"
import useIsMobile from "../../../../../../../dashboard-govplus-front/src/hooks/useIsMobile"
import {
  FormInput,
  cardTypeOptions,
  createMaskRule,
  validateCardNumber,
} from "../../../../../util/IdentityProtectionHelper"

const CreditCardDetailsForm: FC<FormInput> = ({ recordType, handleFormActions }) => {
  const [creditCardForm] = Form.useForm()
  const isMobile = useIsMobile(768)

  const [cardType, setCardType] = useState(cardTypeOptions[0].value)
  const [creditCardError, setCreditCardError] = useState("")

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/-/g, "")
    creditCardForm.setFieldValue("creditCardNumber", value)

    const isAmericanExpressCard = value.toString()[0] === "3"

    if (isAmericanExpressCard && value.length === 15) {
      setCreditCardError("")
    } else if (!isAmericanExpressCard && value.length === 16) {
      setCreditCardError("")
    }
  }

  const getCardTypeFormLabel = () => {
    return (
      <div className={"CreditCardDetailsForm__Item__Label"}>
        <GText text={"Card type"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
      </div>
    )
  }

  const getCardNumberFormLabel = () => {
    return (
      <div className={`CreditCardDetailsForm__Item__Label`}>
        <GText text={"Card number"} size={SIZE.PARAGRAPH_BOLD_16} color={COLORS_NEW.BLACK_900} />
      </div>
    )
  }

  const handleFormSubmit = async () => {
    try {
      const valid = await creditCardForm.validateFields()
      if (valid) {
        const submittedData = {
          recordType,
          value: `${creditCardForm.getFieldValue("creditCardNumber")}|${cardType === "Credit" ? "True" : "False"}`,
        }
        handleFormActions(submittedData)
        setCreditCardError("")
      }
    } catch (error: any) {
      setCreditCardError(error.message)
    }
  }

  const formItemStyle = isMobile ? { maxWidth: "100%", width: "100%" } : { maxWidth: "145px", width: "100%" }

  return (
    <Form
      className={"CreditCardDetailsForm"}
      layout={"vertical"}
      form={creditCardForm}
      initialValues={{ remember: true }}
      name={"basic"}
      onFinish={handleFormSubmit}
    >
      <Form.Item
        style={formItemStyle}
        label={getCardTypeFormLabel()}
        name="creditCardType"
        initialValue={cardTypeOptions[0].value}
        rules={[{ required: false }]}
      >
        <GSelect
          onChange={(value: any) => setCardType(value)}
          options={cardTypeOptions}
          defaultValue={cardTypeOptions[0].value}
          placeHolder={"Select type"}
        />
      </Form.Item>

      <Form.Item
        className={"CreditCardDetailsForm__Item"}
        label={getCardNumberFormLabel()}
        name="creditCardNumber"
        rules={[{ required: true, validator: validateCardNumber }]}
        hasFeedback={false}
        help={creditCardError}
        validateStatus={creditCardError ? "error" : ""}
      >
        <InputMask mask={createMaskRule(16)} maskPlaceholder={null} onChange={handleInputChange}>
          <GInputText inputMode={"numeric"} maskedForPrivacy minLength={15} maxLength={16} />
        </InputMask>
      </Form.Item>

      <div className={"CreditCardDetailsForm__Actions"}>
        <GButton
          type={BUTTON_TYPE.SECONDARY}
          showIcon={false}
          style={{ height: "44px" }}
          text={"Add"}
          click={handleFormSubmit}
        />
      </div>
    </Form>
  )
}

export default CreditCardDetailsForm
