import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getParams} from "../../../assets/helpers/getParams";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import {breadCrumbsHelper} from "./helper/helper";
import {useDispatch, useSelector} from "react-redux";
import GBreadCrumbs from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/BreadCrumbs/BreadCrumbs";
import {ProductsIds} from "@dash/gov-plus__front-end__form/src/util/ProductsIds";

const BreadCrumbsHandler = () => {
  const {anotherGovProducts, allProducts, webFlowData} = useSelector(({allProducts}) => allProducts);
  const history = useHistory();
  const productParams = useParams();
  const [routesArr, setRoutesArr] = useState([]);
  const [isRouteChange, setIsRouteChange] = useState("");

  history.listen((location) => {
    setIsRouteChange(location.pathname)
  })

  useEffect(() => {
    const params = getParams(window.location.search, ["formId", "form_id", "applicationId", "page"]);
    const productDataByAppId = productParams?.id ? webFlowData[allProducts[productParams?.id]?.form_id] : "";
    const productsData =
      !!JSON.parse(localStorage.getItem("webFlowServicesData"))
        ? JSON.parse(localStorage.getItem("webFlowServicesData"))[params?.formId ? params?.formId : params?.form_id]
        : false;
    // special condition for glo-sub
    if (params?.form_id !== ProductsIds.GloFormId && params?.formId !== ProductsIds.GloFormId) {
      if (productsData) {
        setRoutesArr(breadCrumbsHelper.routes(history.location.pathname, productsData, productParams.id, params))
      } else if (productParams.id) {
        setRoutesArr(breadCrumbsHelper.routes(history.location.pathname, productDataByAppId, productParams.id, params))
      } else {
        setRoutesArr(breadCrumbsHelper.routes(history.location.pathname))
      }
    } else {
      setRoutesArr([
        {
          path: '/formsApp',
          breadcrumbName: 'Dashboard',
        },
        {
          breadcrumbName: "Get started",
        }
      ])
    }
  }, [history.location.pathname, anotherGovProducts?.length, productParams.id, isRouteChange, allProducts[productParams.id], webFlowData])


  return (
    <GBreadCrumbs
      routesAttr={routesArr}
    />
  )
}

export default BreadCrumbsHandler;
