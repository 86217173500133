import {
  uploadPhotoInstructionDescription,
  uploadPhotoInstructionList,
  dragAndDropAreaIcons,
  errorsIcons,
  reviewPageIcons,
  mainLayoutIcons,
} from "../../UploadPhotoInstruction/uploadPhotoInstructionService"
import { arrayImportedSvgTYP } from "../../../CommonThankYouPage/CommonTYPServices/CommonTYPServices"

export interface ImportedSvgInterface {
  src: string;
  svg: string;
}

export const getSrc = (src: string, arrayImportedSvg: ImportedSvgInterface[] = []) =>
  arrayImportedSvg.find((svg) => svg.src === src)?.svg ?? src

interface arraysImportedIconsInterface {
  type: string;
  name: ImportedSvgInterface[];
}

export const arraysImportedIcons = [
  {
    type: "passport_photos_instruction__cases_img",
    name: uploadPhotoInstructionList,
  },
  {
    type: "passport_documents_instruction__cases_img",
    name: uploadPhotoInstructionList,
  },
  {
    type: "passport_photos_instruction__description_icon_img",
    name: uploadPhotoInstructionDescription,
  },
  {
    type: "thank_you_page_common__header_logo_product_img",
    name: arrayImportedSvgTYP,
  },
  {
    type: "header_text_container__img",
    name: arrayImportedSvgTYP,
  },
  {
    type: "drag_and_drop_area__icon_img",
    name: dragAndDropAreaIcons,
  },
  {
    type: "drag_and_drop_area__spinner_icon",
    name: dragAndDropAreaIcons,
  },
  {
    type: "success_icon__img_icon",
    name: dragAndDropAreaIcons,
  },
  {
    type: "format_error__icon_img",
    name: errorsIcons,
  },
  {
    type: "drag_and_drop_area__drag_document_icon_img",
    name: dragAndDropAreaIcons,
  },
  {
    type: "drag_and_drop_area__drag_photo_icon_img",
    name: dragAndDropAreaIcons,
  },
  {
    type: "check_status_page__edit_icon",
    name: reviewPageIcons,
  },
  {
    type: "main_page_layout__header_img",
    name: mainLayoutIcons,
  },
  {
    type: "card_prefilled_form__img",
    name: arrayImportedSvgTYP,
  },
  {
    type: "header_glo",
    name: arrayImportedSvgTYP,
  },
  {
    type: "right_image",
    name: arrayImportedSvgTYP,
  },
]

export const getArraysImportedIcons = (type: string, arraysImportedIcons: arraysImportedIconsInterface[] = []) =>
  arraysImportedIcons.find(({ type: typeFromArray }) => type === typeFromArray)?.name ?? []
