import { Action } from "../../interfacesAction/action.interface"

export const ActionTypes = {
  SET_FIRST_MISTAKE_NAME: "[VALIDATION] set validation",

  GET_FAILURE: "[getFailureAction] get failure action",
}

export const setFirstMistakeName = (nameField: string): Action<{}> => ({
  type: ActionTypes.SET_FIRST_MISTAKE_NAME,
  payload: nameField,
})

export const getFailureAction = (error?: {}): Action<{}> => ({
  type: ActionTypes.GET_FAILURE,
  payload: error,
})
