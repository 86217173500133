/* eslint-disable indent */
/* eslint-disable array-callback-return */
import { connect } from "react-redux"
import React from "react"
import { Button } from "antd"
import statusCheckApp from "../../../../../assets/image/status-check_app.jpg"
import statusCheckCompleted from "../../../../../assets/image/status-check_completed.svg"
import statusCheckPending from "../../../../../assets/image/status-check_pending.svg"
import statusCheckProcessed from "../../../../../assets/image/status-check_processed.svg"
import statusCheckRequired from "../../../../../assets/image/status-check_required.svg"
import FormTools from "../../../FormTools/FormTools"

type StatusCheckEinComponentProps = {
  additionalPage: {} | any,
  dispatch: any,
}

const getElement = (item: {} | any, className: string) => {
  switch (item.tag) {
    case "h1":
      return (
        <h1 className={`${className} ${"name-" + item.name} ${"type-" + item.type}`} key={item.name}>
          {item.text}
        </h1>
      )
    case "h3":
      return (
        <h3 className={`${className} ${"name-" + item.name} ${"type-" + item.type}`} key={item.name}>
          {item.text}
        </h3>
      )
    case "p":
      return (
        <p className={`${className} ${"name-" + item.name} ${"type-" + item.type}`} key={item.name}>
          {item.text}
        </p>
      )
    case "button":
      return (
        <Button className={`${className} ${"name-" + item.name} ${"type-" + item.type}`} key={item.name}>
          {item.text}
        </Button>
      )
    default:
      return <div>Влад, ты неправильно прописал тип</div>
  }
}

const checkIncludes = (item: {} | any) => {
  const arrayTag = ["h1", "h3", "p", "button"]

  if (arrayTag.includes(item.tag)) {
    return getElement(item, "additional-page__container-main-text")
  } else {
    return <></>
  }
}

const getStatusCheckImage = (name: string) => {
  switch (name) {
    case "status-check_completed":
      return <img src={statusCheckCompleted} alt="status-check_completed" />
    case "status-check_processed":
      return <img src={statusCheckProcessed} alt="status-check_processed" />
    case "status-check__required":
      return <img src={statusCheckRequired} alt="status-check__required" />
    case "status-check_pending":
      return <img src={statusCheckPending} alt="status-check_pending" />
    default:
      return <div>Влад, ты неправильно прописал тип</div>
  }
}

// eslint-disable-next-line max-len
const StatusCheckEinComponent: React.FunctionComponent<StatusCheckEinComponentProps> = ({
  additionalPage,
  dispatch,
}) => {
  const { header } = additionalPage || []
  const { main } = additionalPage || []

  return (
    <>
      <header className={"additional-page__container-header"}>
        {header.map((item: {} | any) => checkIncludes(item))}
      </header>
      <div className={"additional-page__container-main-img"}>
        <img src={statusCheckApp} alt="statusCheckApp" />
        {getStatusCheckImage(additionalPage.name)}
      </div>
      <main className={"additional-page__container-main"}>
        <div className={"additional-page__container-main-data"}>
          {main.map((item: {} | any) => checkIncludes(item))}
        </div>
      </main>
      <FormTools
        arraySteps={[]}
        indexCurrentStep={1}
        handleDecrementClick={() => {}}
        type={additionalPage.type}
        reCaptcha={null}
        buttonPrev={additionalPage.buttonPrev}
        nameStep={additionalPage.name}
        navigation={additionalPage.navigation}
      />
    </>
  )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(StatusCheckEinComponent)
