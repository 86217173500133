import {call, put, select, takeEvery} from "redux-saga/effects"
import Service from "../../../services/form-service"
import { LoadingState } from "../../../shared/constans/user-from-view-mode.enum"
import { setLoadingState } from "../loading/loading.action"

import {
  ActionTypes,
  setArraySteps,
  setValidationRulers,
  setOptionsForSelect,
  setAdditionalSteps,
  setFormArraySteps,
  setDynamicBlocks,
  setContentModal,
  setCurrentStep,
  setLabelForm,
  setBillingInformation,
  setCurrentBlock,
  setInitialArraySteps,
} from "./step.actions"

import {
  clearSubmitByEmptyPropStore,
  setAppId,
  setPaymentError,
  setPaymentMethod,
  setSubmitObject,
  setTagPrefilledObject,
  setUserIp,
  setValueCurrentField,
  showAddPaymentButton, submitForm,
} from "../form/form.actions"
import { getCashState } from "../cash/cash.action"
import {
  getAllSteps,
  getClientTokenForResponse,
  getParamsFromGetParams,
  getParamsFromSessionStorage,
  saveDynamicEvents,
  delay,
  getRoot,
} from "./service.step.saga"
import { ServiceUser } from "../../../services/requests/userService"
import { getWebFlowByIdKey, getWebFlowSolutions, setPercents } from "../user/user.actions"
import { getPropertiesForEvents } from "../../../services/gtm/backEndEvent"
import { getToolsForForms } from "./service.prefilled.data"
import { getTopScrollPosition } from "../../../Components/FormComponents/FormBlock/FormBlock"
import { getPath } from "../form/form.saga"
import { ERRORS } from "./constants"
import {ProductsIds} from "../../../util/ProductsIds";
import ApplicationIdRepository from "../../../persistence/session/ApplicationIdRepository";
import {StepSagaHelper} from "./StepSagaHelper";
import SavedSubmitFields from "../../../Constants/SavedSubmitFields"

function* setArrayStepsSaga(): any {
  try {
    yield put(setLoadingState(LoadingState.Loading))

    const { paymentGov } = yield getParamsFromSessionStorage()
    yield delay(100)
    const { fromDashboard, enableEditor, fromDashboardSignature } = yield getParamsFromGetParams()
    let { applicationId } = yield getParamsFromGetParams()
    if(!applicationId) {
      applicationId = yield ApplicationIdRepository.getInstance().getValue()
    }
    const oldFormId = yield sessionStorage.getItem("old_form_id")
    const { form_id } = yield getPropertiesForEvents()
    const { client_token } = yield getClientTokenForResponse(fromDashboard)
    yield getTopScrollPosition()
    if (yield applicationId && applicationId !== "undefined" && applicationId !== "null") {
      yield call(StepSagaHelper.SetAppIdSessionValue, applicationId);
    }

    let checkStatusFlag = false
    if (yield applicationId && applicationId !== "undefined" && applicationId !== "null") {
      yield (checkStatusFlag = true)
      yield put(getCashState({ client_token, form_id, fromDashboardSignature }))
    } else {
      let response
      if (yield client_token && form_id && form_id !== "undefined" && form_id !== "null" && form_id !== "forms") {
        response = yield Service.getForm({ client_token, form_id })
      } else {
        throw new Error(ERRORS.MISSING_VALUE)
      }
      if (response?.error) {
        throw new Error(response?.error?.message)
      }

      if (enableEditor) {
        // @ts-ignore
        window.gedit__json = response
      }



      if (!response?.initial_user && form_id === ProductsIds.GloFormId) {
        throw new Error(ERRORS.USER_IS_INITIAL)
      }

      const responsePercents = yield ServiceUser.getFormFillPercents({ form_id, client_token })
      yield getTopScrollPosition()

      yield put(getWebFlowByIdKey())
      yield put(getWebFlowSolutions())

      if (responsePercents.success) {
        yield put(setPercents(responsePercents.success))
      }

      yield saveDynamicEvents(getAllSteps(paymentGov, response))

      if (response.modalContent) {
        yield put(setContentModal(response.modalContent))
      }
      if (response.dynamicBlocks) {
        yield put(setDynamicBlocks(response.dynamicBlocks))
      }
      if (response.billingInformation) {
        yield put(setBillingInformation(response.billingInformation))
        yield put(setPaymentMethod(response.billingInformation.default_payment_methods))
        if (response.billingInformation.payment_methods.length > 0) {
          yield put(showAddPaymentButton(true))
        }
      }
      const arrayFormSteps = yield [...response.steps]
      const arrayPagesName = getAllSteps(paymentGov, response).map((item: any) => item.label)
      const validation = yield response.validation
      const optionsForSelect = yield response.predefinedOptions
      const additionalSteps = yield response.additionalSteps
      // for independent correct information page(now only for ein)
      if (additionalSteps && response.correctInformation) {
        yield (additionalSteps.electronic_signature_page = [additionalSteps.electronic_signature_page[0]].concat(
          response.correctInformation
        ))
      }
      yield put(setPaymentError(null))
      yield put(setArraySteps(getAllSteps(paymentGov, response)))
      yield put(setFormArraySteps(arrayFormSteps))
      yield put(setAdditionalSteps(additionalSteps))
      yield put(setValidationRulers(validation))
      yield put(setOptionsForSelect(optionsForSelect))
      yield put(setValueCurrentField("pages", arrayPagesName))
      yield put(setValueCurrentField("form-name", getAllSteps(paymentGov, response)?.[0]?.["form-label"]))
      yield put(setValueCurrentField("relationship_specify", ""))
      yield put(setValueCurrentField("unique-section-id", response.unique_session_id))
      yield put(setLabelForm(getAllSteps(paymentGov, response)?.[0]?.["form_label"]))
      yield put(setCurrentBlock({}))
      const product = yield sessionStorage.getItem("formNameForEvent") || ""

      // for segment event and entity sub category
      if (form_id === ProductsIds.GloFormId) {
        /* Event Start Application */
      }

      yield delay(300)
      const statusStartAppEvent = yield oldFormId !== form_id
      if (statusStartAppEvent) {
        let subCategory = "";
        if(product === "EIN") {
          subCategory = "Sole Proprietor / Individual"
        }

        sessionStorage.setItem("SubCategory", subCategory)
      }
      if (response?.prefillData?.flattenData) {
        const flattenData = response?.prefillData?.flattenData
        sessionStorage.setItem("tagPrefilledFields", JSON.stringify(flattenData))
        yield put(setTagPrefilledObject(flattenData))
        yield put(setInitialArraySteps([...arrayFormSteps]))
        const { arrayStepsAfterPrefilled, prefilledStore } = yield getToolsForForms({
          arraySteps: arrayFormSteps,
          flattenData,
          prefilledStore: {},
          product,
        })

        /*
        * In the others passport services flows, the form_type was set in the submitObject during the execution
        * of the Questionnaire step, and in New passport there is no such step, so it is necessary to set this field in
        * submitObject for later use in other steps.
        */
        if(arrayFormSteps?.length > 0 && arrayFormSteps[0].form_type === "NEW") {
          prefilledStore.form_type = "NEW"
        }

        const prefillData = response?.prefillData
        if (prefillData.data) {
          Object.keys(prefillData.data).forEach(field => {
            if (SavedSubmitFields.includes(field)) {
              prefilledStore[field] = prefillData.data[field]
            }
          })
        }

        yield put(setFormArraySteps(arrayStepsAfterPrefilled))
        yield put(setArraySteps(arrayStepsAfterPrefilled))
        yield put(setSubmitObject(prefilledStore))

        if(arrayStepsAfterPrefilled?.length === 0) {
          let submitFormPayload = yield select(getSubmitForm);
          submitFormPayload = { ...submitFormPayload, flattenData }
          yield put(clearSubmitByEmptyPropStore(submitFormPayload))
          yield put(submitForm({ newSubmitObject: submitFormPayload, arraySteps: arrayFormSteps, indexCurrentStep: 0 }))
        }
      }
      yield getPath({ nameGetParam: "page", valueGetParam: "form" })
    }
    if (!checkStatusFlag) {
      yield put(setLoadingState(LoadingState.Loaded))
    }
  } catch (e) {
    if (e === "Not Found") {
      yield put(setLoadingState(LoadingState.NotFound))
    } else {
      yield (window.location.href = `${getRoot()}/formsApp`)
    }
    // console.log(e)
  }
}

export const getSubmitForm = (state: any) => state.form.submitObject

function* getStoreFromEditorSaga({ payload: indexCurrentStep }: any) {
  try {
    // @ts-ignore
    const { steps } = window.gedit__json
    yield put(setArraySteps(null))
    yield put(setArraySteps(steps))
    yield put(setFormArraySteps(steps))
    yield put(setCurrentStep(indexCurrentStep))
  } catch (e) {
    // console.log(e)
  }
}

export default function* stepSaga() {
  yield takeEvery(ActionTypes.GET_ARRAY_STEPS, setArrayStepsSaga)
  yield takeEvery(ActionTypes.GET_STORE_FROM_EDITOR, getStoreFromEditorSaga)
}
