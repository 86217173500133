import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import SelectPhoneComponent from "./SelectPhoneComponent"
import { getArrayCountryCode } from "../../../../Redux/store/email/email.action"
import InputPhoneComponent from "./InputPhoneComponent"
import { RootState } from "../../../../App"
import { emailSelector } from "../../UploadPhoto/UploadPhotoService/renderFunctions/renderFunctionsUploadPhoto"

interface NonUSAPhoneInputComponentInterface {
  phoneNonUSA: any;
}

const NonUSAPhoneInputComponent: React.FC<NonUSAPhoneInputComponentInterface> = ({ phoneNonUSA }) => {
  const {
    fields: [select, input],
  } = phoneNonUSA
  const dispatch = useDispatch()

  useEffect(() => {
    // will be request for arrayCountryCodesHere
    dispatch(getArrayCountryCode())
  }, [dispatch])

  // const [options] = useState(arrayCountryCodes)
  const options = useSelector<RootState, { arrayCountryCodes: [] }>(emailSelector).arrayCountryCodes

  return (
    <div className={"phoneNonUSA_wrapper"}>
      <SelectPhoneComponent field={select} options={options} dependsField={input.name} />
      <InputPhoneComponent field={input} />
    </div>
  )
}

export default NonUSAPhoneInputComponent
