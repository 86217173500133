import {Modal} from "antd";
import React, {useEffect} from "react";
import './CustomModal.less'
import {useDispatch, useSelector} from "react-redux";
import {showModalsType} from "../../appRedux/actions";

function CustomModal({nodeModalChildren, showModalStatus}) {
  const {showModalType} = useSelector(({settings}) => settings);
  const dispatch = useDispatch();

  const handleCancel = () => {
    if (showModalType === "UserDataModal") {
      dispatch(showModalsType(""))
    }
  };

  // useEffect(() => {
  //   document.querySelector(".ant-modal-wrap").style.alignItems = showModalType === "CongratsModal" ? "center" : "flex-start"
  // }, [showModalType]);

  return (
    <Modal
      className={"modal"}
      open={showModalStatus}
      closable={showModalType === "UserDataModal"}
      footer={null}
      onCancel={handleCancel}
      wrapClassName="CustomModal"
    >
      {nodeModalChildren}
    </Modal>
  )
}

export default CustomModal
