import { SIZE } from "../../types/types"
import { COLORS, COLORS_NEW } from "../../types/Colors"
import { FC } from "react"
import React from "react"
import GText from "../../atoms/Text/Text"
import readyToFile from "../../../../assets/image/ready-to-file.svg"
import "./GImageDescription.scss"

export interface GImageDescription {
  title: string;
  titleSize?: string;
  titleColor?: string;
  image: string;
  description: string;
  descriptionColor?: string;
  descriptionSize?: string;
}

const GImageDescription: FC<GImageDescription> = ({
  title,
  titleSize = SIZE.HEADING_MDLG,
  titleColor = COLORS_NEW.BLACK_1000,
  image,
  description,
  descriptionColor = COLORS_NEW.BLACK_500,
  descriptionSize = SIZE.PARAGRAPH_BOLD_18,
}) => {
  return (
    <div className="GImageDescription">
      <div className="title">
        <GText text={title} size={titleSize} color={titleColor} />
        <div className="titleDescription">
          <GText text={description} size={descriptionSize} color={descriptionColor} innerHtml={description} />
        </div>
      </div>
      <img src={image} alt={""} />
    </div>
  )
}

export default GImageDescription
