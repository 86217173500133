import OrderCompleted from "./OrderCompleted";
import IEvent from "../IEvent";
import BaseBuilder from "../BaseBuilder";

export default class OrderCompletedBuilder extends BaseBuilder {
    private _gclid: string | undefined;
    private _msclkid: string | undefined;
    private _fbc: string | undefined;
    private _fbp: string | undefined;
    private _orderId: string | undefined;
    private _value: string | undefined;
    private _revenue: string | undefined;
    private _currency: string | undefined;
    private _productId: string | undefined;
    private _service: string | undefined;
    private _solution: string | undefined;
    private _productPrice: string | undefined;
    private _productQuantity: string | undefined;
    private _productCategory: string | undefined;
    private _productName: string | undefined;
    private _utmCampaign: string | undefined;
    private _utmContent: string | undefined;
    private _utmMedium: string | undefined;
    private _utmSource: string | undefined;
    private _utmTerm: string | undefined;
    private _lastUtmCampaign: string | undefined;
    private _lastUtmContent: string | undefined;
    private _lastUtmMedium: string | undefined;
    private _lastUtmSource: string | undefined;
    private _lastUtmTerm: string | undefined;
    private _subdivision: string | undefined;
    private _referrerId: string | undefined;
    private _referrerType: string | undefined;
    private _email: string | undefined;
    private _coupon: string | undefined;
    private _subscriptionId: string | undefined;
    private _subscriptionIdExternal: string | undefined;
    private _subscriptionPlan: string | undefined;
    private _subscriptionPaymentFrequency: string | undefined;
    private _subscriptionPrice: string | undefined;
    private _subscriptionStatus: string | undefined;
    private _subscriptionCreationDate: string | undefined;

    private _eventType: string | undefined;

    setIpAddress(ipAddress: string | undefined) {
        this._ipAddress = ipAddress;
        return this;
    }

    setGclid(value: string | undefined) {
        this._gclid = value;
        return this;
    }

    setMsclkid(value: string | undefined) {
        this._msclkid = value;
        return this;
    }

    setFbc(value: string | undefined) {
        this._fbc = value;
        return this;
    }

    setFbp(value: string | undefined) {
        this._fbp = value;
        return this;
    }

    setOrderId(value: string | undefined) {
        this._orderId = value;
        return this;
    }

    setValue(value: string | undefined) {
        this._value = value;
        return this;
    }

    setRevenue(value: string | undefined) {
        this._revenue = value;
        return this;
    }

    setCurrency(value: string | undefined) {
        this._currency = value;
        return this;
    }

    setProductId(value: string | undefined) {
        this._productId = value;
        return this;
    }

    setService(value: string | undefined) {
        this._service = value;
        return this;
    }

    setSolution(value: string | undefined) {
        this._solution = value;
        return this;
    }

    setProductPrice(value: string | undefined) {
        this._productPrice = value;
        return this;
    }

    setProductQuantity(value: string | undefined) {
        this._productQuantity = value;
        return this;
    }

    setProductCategory(value: string | undefined) {
        this._productCategory = value;
        return this;
    }

    setProductName(value: string | undefined) {
        this._productName = value;
        return this;
    }

    setUtmCampaign(value: string) {
        this._utmCampaign = value;
        return this;
    }

    setUtmContent(value: string) {
        this._utmContent = value;
        return this;
    }

    setUtmMedium(value: string) {
        this._utmMedium = value;
        return this;
    }

    setUtmSource(value: string) {
        this._utmSource = value;
        return this;
    }

    setUtmTerm(value: string) {
        this._utmTerm = value;
        return this;
    }

    setLastUtmCampaign(value: string) {
        this._lastUtmCampaign = value;
        return this;
    }

    setLastUtmContent(value: string) {
        this._lastUtmContent = value;
        return this;
    }

    setLastUtmMedium(value: string) {
        this._lastUtmMedium = value;
        return this;
    }

    setLastUtmSource(value: string) {
        this._lastUtmSource = value;
        return this;
    }

    setLastUtmTerm(value: string) {
        this._lastUtmTerm = value;
        return this;
    }

    setSubdivision(value: string) {
        this._subdivision = value;
        return this;
    }

    setReferrerId(value: string) {
        this._referrerId = value;
        return this;
    }

    setReferrerType(value: string) {
        this._referrerType = value;
        return this;
    }

    setEmail(value: string) {
        this._email = value;
        return this;
    }

    setCoupon(value: string | undefined) {
        this._coupon = value;

        return this;

    }
    setSubscriptionId(value: string | undefined) {
        this._subscriptionId = value;
        return this;
    }
    setSubscriptionIdExternal(value: string | undefined) {
        this._subscriptionIdExternal = value;
        return this;
    }
    setSubscriptionPlan(value: string | undefined) {
        this._subscriptionPlan = value;
        return this;
    }
    setSubscriptionPaymentFrequency(value: string | undefined) {
        this._subscriptionPaymentFrequency = value;
        return this;
    }
    setSubscriptionPrice(value: string | undefined) {
        this._subscriptionPrice = value;
        return this;
    }
    setSubscriptionStatus(value: string | undefined) {
        this._subscriptionStatus = value;
        return this;
    }
    setSubscriptionCreationDate(value: string | undefined) {
        this._subscriptionCreationDate = value;
        return this;
    }

    setEventType(value: string | undefined) {
        this._eventType = value;
        return this;
    }

    build(): IEvent {
        return new OrderCompleted(
            this._ipAddress,
            this._gclid,
            this._msclkid,
            this._fbc,
            this._fbp,
            this._orderId,
            this._value,
            this._revenue,
            this._currency,
            this._productId,
            this._service,
            this._solution,
            this._productPrice,
            this._productQuantity,
            this._productCategory,
            this._productName,
            this._utmMedium,
            this._utmSource,
            this._utmCampaign,
            this._utmContent,
            this._utmTerm,
            this._lastUtmMedium,
            this._lastUtmSource,
            this._lastUtmCampaign,
            this._lastUtmContent,
            this._lastUtmTerm,
            this._subdivision,
            this._referrerId,
            this._referrerType,
            this._email,
            this._coupon,
            this._subscriptionId,
            this._subscriptionIdExternal,
            this._subscriptionPlan,
            this._subscriptionPaymentFrequency,
            this._subscriptionPrice,
            this._subscriptionStatus,
            this._subscriptionCreationDate,
            !this._eventType ? OrderCompleted.TYPE_ORDER_COMPLETED_PAYMENT_PAGE : this._eventType
        );
    }
}
