export const productAndStatusesDataList = {

  PAS: {
    questionnaire: {
      applicationHeaderDescriptionText: "We need a bit more information",
      productStatusInfo: "We have not yet received your responses to the questionnaire. Please click on the link below to provide your answers and finish your application.",
      buttonTextForCheckStatus: "Complete the questionnaire",
    },
    missing_information: {
      applicationHeaderDescriptionText: "We’re missing some info",
      productStatusInfo: "Please click on the link below to finish your passport application.",
      buttonTextForCheckStatus: "Provide details",
    },
    upload_photo: {
      applicationHeaderDescriptionText: "We need your passport photo",
      productStatusInfo: "Please click on the link below to provide your photo and finish your application.",
      buttonTextForCheckStatus: "Provide photo",
    },
    identity_document: {
      applicationHeaderDescriptionText: "Please upload your documents",
      productStatusInfo: "We have not yet received your documents. Please click on the link below to complete this step. We look forward to processing your application. ",
      buttonTextForCheckStatus: "Provide documents",
    },
    citizenship_document: {
      applicationHeaderDescriptionText: "Please upload your documents",
      productStatusInfo: "We have not yet received your documents. Please click on the link below to complete this step. We look forward to processing your application. ",
      buttonTextForCheckStatus: "Provide documents",
    },
    processing: {
      applicationHeaderDescriptionText: "Your application is being processed",
      productStatusInfo: "We will contact you if more information is needed, and you will be notified by email when your application is ready.",
      buttonTextForCheckStatus: "",
    },
    processing_bot_error: {
      applicationHeaderDescriptionText: "Your application is being processed",
      productStatusInfo: "We will contact you if more information is needed, and you will be notified by email when your application is ready.",
      buttonTextForCheckStatus: "",
    },
    signature: {
      applicationHeaderDescriptionText: "Please provide your electronic signature",
      productStatusInfo: "We need your signature to continue processing your application. Please click the link below.",
      buttonTextForCheckStatus: "Provide signature",
    },
    government_fees: {
      applicationHeaderDescriptionText: "Pending government fees",
      productStatusInfo: "You need to pay for the government fees so we can finish your application.",
      buttonTextForCheckStatus: "Pay government fees",
    },
    processing_validation: {
      applicationHeaderDescriptionText: "Your application is being processed",
      productStatusInfo: "Your passport application is being processed. We will contact you when the application is ready or if more information is needed.",
      buttonTextForCheckStatus: "",
    },
    pending_shipment: {
      applicationHeaderDescriptionText: "Your application is being processed",
      productStatusInfo: "We will contact you if more information is needed, and you will be notified by email when your application is ready.",
      buttonTextForCheckStatus: "",
    },
    // completed: {},
  },

  EIN: {
    signature: {
      applicationHeaderDescriptionText: "Please provide your electronic signature",
      productStatusInfo: "We need your signature to continue processing your application. Please click the link below.",
      buttonTextForCheckStatus: "Provide signature",
    },
    processing_validation: {
      applicationHeaderDescriptionText: "Your application is being processed",
      productStatusInfo: "You will be notified by email when your EIN is ready or if more information is required.",
      buttonTextForCheckStatus: "",
    },
    processing: {
      applicationHeaderDescriptionText: "Your application is being processed",
      productStatusInfo: "You will be notified by email when your EIN is ready or if more information is required.",
      buttonTextForCheckStatus: "",
    },
    processing_error: {
      applicationHeaderDescriptionText: "Your application is being processed",
      productStatusInfo: "You will be notified by email when your EIN is ready or if more information is required.",
      buttonTextForCheckStatus: "",
    },
    blocked_wronginformation: {
      applicationHeaderDescriptionText: "Further action is required",
      productStatusInfo: "There's a problem with some of the information you provided. Please double check and correct or complete the highlighted fields.",
      buttonTextForCheckStatus: "Contact Us",
    },
    blocked_101others: {
      applicationHeaderDescriptionText: "Further action is required",
      productStatusInfo: "There's a problem with some of the information you provided. Please double check and correct or complete the highlighted fields.",
      buttonTextForCheckStatus: "Contact Us",
    },
    // processing:{},
    blocked_soleproprietor: {
      applicationHeaderDescriptionText: "Further action is required",
      productStatusInfo: "There's a problem with some of the information you provided. Please double check and correct or complete the highlighted fields.",
      buttonTextForCheckStatus: "Contact Us",
    },
    // completed: {},
    blocked_for_some_reason: {
      applicationHeaderDescriptionText: "Your application is blocked",
      productStatusInfo: "",
      buttonTextForCheckStatus: "Contact Us",
    }
  },

  COA: {
    // processing_notifications_services: {},
    // processing: {},
    // processing_error: {},
    // processing_duplicate: {},
    // processing_notifications_agencies: {},
    // completed: {},
    // processing_validation: {},
    service_providers:{
      applicationHeaderDescriptionText: "Pending service providers",
      productStatusInfo: "You need to select the service providers you want to notify of your address change",
      buttonTextForCheckStatus: "Pick service providers",
    },
    invalid_old_address: {
      applicationHeaderDescriptionText: "Pending address validation",
      productStatusInfo: "We need to validate your address in order to ensure your documents.",
      buttonTextForCheckStatus: "Verify address",
    },
    invalid_new_address: {
      applicationHeaderDescriptionText: "Pending address validation",
      productStatusInfo: "We need to validate your address in order to ensure your documents.",
      buttonTextForCheckStatus: "Verify address",
    },
    government_fees: {
      applicationHeaderDescriptionText: "Pending government fees",
      productStatusInfo: "You need to pay for the government fees so we can finish your application.",
      buttonTextForCheckStatus: "Pay government fees",
    },
    missing_information: {
      applicationHeaderDescriptionText: "We’re missing some information",
      productStatusInfo: "Please click on the link below to complete your application.",
      buttonTextForCheckStatus: "Provide more details",
    },
    signature: {
      applicationHeaderDescriptionText: "Please provide your electronic signature",
      productStatusInfo: "We must receive your signature to continue processing your application. Please click the link below to proceed.",
      buttonTextForCheckStatus: "Provide signature",
    },
  },

  TSA: {
    missing_information: {
      applicationHeaderDescriptionText: "Additional information needed",
      productStatusInfo: "Please click on the link below so that we can move forward with your application.",
      buttonTextForCheckStatus: "Provide additional information",
    },
    questionnaire: {
      applicationHeaderDescriptionText: "Please complete the questionnaire",
      productStatusInfo: "Please click on the link below to provide missing information and finish your application.",
      buttonTextForCheckStatus: "Complete the questionnaire",
    },
    processing_bot_renewal_check: {
      applicationHeaderDescriptionText: "Your TSA Pre-Check application is being processed",
      productStatusInfo: "We will contact you if more information is needed, and you will be notified by email with further instructions.",
      buttonTextForCheckStatus: "",
    },
    government_fees: {
      applicationHeaderDescriptionText: "Pending government fees",
      productStatusInfo: "You need to pay for the government fees so we can finish your application.",
      buttonTextForCheckStatus: "Pay government fees",
    },
    processing_validation: {
      applicationHeaderDescriptionText: "Your TSA Pre-Check application is being processed",
      productStatusInfo: "We will contact you if more information is needed, and you will be notified by email with further instructions.",
      buttonTextForCheckStatus: "",
    },
    processing: {
      applicationHeaderDescriptionText: "Your TSA Pre-Check application is being processed",
      productStatusInfo: "We will contact you if more information is needed, and you will be notified by email with further instructions.",
      buttonTextForCheckStatus: "",
    },
    processing_bot_renewal_submit_application: {
      applicationHeaderDescriptionText: "Your TSA Pre-Check application is being processed",
      productStatusInfo: "We will contact you if more information is needed, and you will be notified by email with further instructions.",
      buttonTextForCheckStatus: "",
    },
    appointment_selection_renewal: {
      applicationHeaderDescriptionText: "Your TSA Pre-Check application is being processed",
      productStatusInfo: "We will contact you if more information is needed, and you will be notified by email with further instructions.",
      buttonTextForCheckStatus: "",
    },
    processing_bot_new_error: {
      applicationHeaderDescriptionText: "Your TSA Pre-Check application is being processed",
      productStatusInfo: "We will contact you if more information is needed, and you will be notified by email with further instructions.",
      buttonTextForCheckStatus: "",
    },
    // appointment_selection: {},
    booking: {
      applicationHeaderDescriptionText: "Your appointment is being booked",
      productStatusInfo: "We are preparing your customized appointment instructions and will notify you when they are ready.",
      buttonTextForCheckStatus: "",
    },
    // completed_new: {},
    // completed_renew: {},
    // completed_renew_expired: {},
    // completed_renew_expired:{}
  },

  SSN: {
    invalid_address: {
      applicationHeaderDescriptionText: "Please double check your address information",
      productStatusInfo: "Your mailing address is invalid, please update it to make sure you can receive your preparation package and social security card.",
      buttonTextForCheckStatus: "Update Address",
    },
    signature: {
      applicationHeaderDescriptionText: "Please provide your electronic signature",
      productStatusInfo: "We must receive your signature to continue processing your application. Please click the link below to proceed.",
      buttonTextForCheckStatus: "Provide signature",
    },
    questionnaire: {
      applicationHeaderDescriptionText: "We are missing some information",
      productStatusInfo: "Please click on the link below to complete the questionnaire and finish your application.",
      buttonTextForCheckStatus: "Complete the questionnaire",
    },
    processing_validation: {
      applicationHeaderDescriptionText: "Your social security card application is being processed",
      productStatusInfo: "You will be notified by email when your Social Security Card Package is ready or if more information is required.",
      buttonTextForCheckStatus: "",
    },
    processing_shipment: {
      applicationHeaderDescriptionText: "Your social security card application is being processed",
      productStatusInfo: "We will contact you if more information is needed, and you will be notified by email when your application is ready.",
      buttonTextForCheckStatus: "",
    },
    // tracking_to_customer: {},
  },

  CER: {
    signature: {
      applicationHeaderDescriptionText: "Please provide your electronic signature",
      productStatusInfo: "We must receive your signature to continue processing your application. Please click the link below.",
      buttonTextForCheckStatus: "Provide signature",
    },
    identity_document: {
      applicationHeaderDescriptionText: "Please upload your documents",
      productStatusInfo: "Click on the link below to complete this step. We look forward to processing your application.",
      buttonTextForCheckStatus: "Provide documents",
    },
    relationship_document: {
      applicationHeaderDescriptionText: "Please upload your documents",
      productStatusInfo: "Click on the link below to complete this step. We look forward to processing your application.",
      buttonTextForCheckStatus: "Provide documents",
    },
    government_fees: {
      applicationHeaderDescriptionText: "Pending government fees",
      productStatusInfo: "You need to pay for the government fees so we can finish your application.",
      buttonTextForCheckStatus: "Pay government fees",
    },
    processing_validation: {
      applicationHeaderDescriptionText: "Your application is being processed",
      productStatusInfo: "Your birth certificate application is being processed. We will contact you when the application is ready or if more information is needed.",
      buttonTextForCheckStatus: "",
    },
    notarization: {
      applicationHeaderDescriptionText: "Pending notarization",
      productStatusInfo: "We are preparing your application for online notarization, we will send you an email when it is ready.",
      buttonTextForCheckStatus: "",
    },
    sent_to_notarize: {
      applicationHeaderDescriptionText: "Please notarize your application",
      productStatusInfo: "Your application must be notarized in order to move forward. Please click on the link below to proceed.",
      buttonTextForCheckStatus: "Notarize",
    },
    processing_validation_notarization:{
      applicationHeaderDescriptionText: "Your application is being processed",
      productStatusInfo: "Your birth certificate application is being processed. We will contact you when the application is ready or if more information is needed.",
      buttonTextForCheckStatus: "",
    },
    shipment: {
      applicationHeaderDescriptionText: "Your application is being processed",
      productStatusInfo: "Your birth certificate application is being processed. We will contact you when the application is ready or if more information is needed.",
      buttonTextForCheckStatus: "",
    },
    // completed: {},
  },
  NAM: {
    questionnaire_ssn: {
      applicationHeaderDescriptionText: "We are missing some information",
      productStatusInfo: "Please click on the link below to complete the questionnaire and finish your application.",
      buttonTextForCheckStatus: "Complete the questionnaire",
    },
    questionnaire_has_ssn: {
      applicationHeaderDescriptionText: "We are missing some information",
      productStatusInfo: "Please click on the link below to complete the questionnaire and finish your application.",
      buttonTextForCheckStatus: "Complete the questionnaire",
    },
    questionnaire_has_tax_and_pas: {
      applicationHeaderDescriptionText: "We are missing some information",
      productStatusInfo: "Please click on the link below to complete the questionnaire and finish your application.",
      buttonTextForCheckStatus: "Complete the questionnaire",
    },
    questionnaire_has_va_and_pas_dl_new_name: {
      applicationHeaderDescriptionText: "We are missing some information",
      productStatusInfo: "Please click on the link below to complete the questionnaire and finish your application.",
      buttonTextForCheckStatus: "Complete the questionnaire",
    },
    invalid_address: {
      applicationHeaderDescriptionText: "Please double check your address information",
      productStatusInfo: "Your mailing address is invalid, please update it to make sure you can receive your preparation package and social security card.",
      buttonTextForCheckStatus: "Update Address",
    },
    additional_information_pas: {
      applicationHeaderDescriptionText: "We’re missing some info",
      productStatusInfo: "Please click on the link below to finish your name change application.",
      buttonTextForCheckStatus: "Provide details",
    },
    additional_information_va: {
      applicationHeaderDescriptionText: "We’re missing some info",
      productStatusInfo: "Please click on the link below to finish your name change application.",
      buttonTextForCheckStatus: "Provide details",
    },
    upload_photo: {
      applicationHeaderDescriptionText: "We need your passport photo",
      productStatusInfo: "Please click on the link below to provide your photo and finish your application.",
      buttonTextForCheckStatus: "Provide photo",
    },
    identity_document: {
      applicationHeaderDescriptionText: "Please upload your documents",
      productStatusInfo: "We have not yet received your documents. Please click on the link below to complete this step. We look forward to processing your application. ",
      buttonTextForCheckStatus: "Provide documents",
    },
    citizenship_document: {
      applicationHeaderDescriptionText: "Please upload your documents",
      productStatusInfo: "We have not yet received your documents. Please click on the link below to complete this step. We look forward to processing your application. ",
      buttonTextForCheckStatus: "Provide documents",
    },
    name_change_documents: {
      applicationHeaderDescriptionText: "Please upload your documents",
      productStatusInfo: "We have not yet received your documents. Please click on the link below to complete this step. We look forward to processing your application. ",
      buttonTextForCheckStatus: "Provide documents",
    },
    signature: {
      applicationHeaderDescriptionText: "Please provide your electronic signature",
      productStatusInfo: "We must receive your signature to continue processing your application. Please click the link below to proceed.",
      buttonTextForCheckStatus: "Provide signature",
    },
    government_fees: {
      applicationHeaderDescriptionText: "Pending government fees",
      productStatusInfo: "You need to pay for the government fees so we can finish your application.",
      buttonTextForCheckStatus: "Pay government fees",
    },
    processing: {
      applicationHeaderDescriptionText: "Your application is being processed",
      productStatusInfo: "You will be notified by email when your Name change Package is ready or if more information is required.",
      buttonTextForCheckStatus: "",
    },
    processing_validation: {
      applicationHeaderDescriptionText: "Your application is being processed",
      productStatusInfo: "You will be notified by email when your Name change Package is ready or if more information is required.",
      buttonTextForCheckStatus: "",
    },
    pending_shipment: {
      applicationHeaderDescriptionText: "Your application is being processed",
      productStatusInfo: "You will be notified by email when your Name change Package is ready or if more information is required.",
      buttonTextForCheckStatus: "",
    },
  }
};
