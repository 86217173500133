import React, { FC, useEffect } from "react"
import BasicBlockContainer from "../MainBlockContainer/BasicBlockContainer"
import { arrayUploadPhotoComponents } from "../UploadPhotoService/datas/arrayUploadPhotoComponents"
import { UploadPhotoContainerComponentProps } from "../UploadPhotoService/interfaces/interfaces"

const UploadPhotoContainerComponent: FC<UploadPhotoContainerComponentProps> = ({ item }) => {
  useEffect(() => {
    if (!sessionStorage.getItem("upload_photo_event")) {
      sessionStorage.setItem("upload_photo_event", "true")
      /* Event Processing Step */
    }
  }, [])
  return <BasicBlockContainer item={item} arrayComponents={arrayUploadPhotoComponents} />
}

export default UploadPhotoContainerComponent
