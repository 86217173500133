import {all, put, takeEvery} from "redux-saga/effects";
import {offersApis} from "../../api/api";
import {GET_OFFERS} from "../../constants/ActionTypes";
import {getOffersSuccsses} from "../actions/Offers";

export function* getOffersSaga() {
  try {} catch (err) {}
}

export default function* rootSaga() {
  yield takeEvery(GET_OFFERS, getOffersSaga)
  yield all([]);
}
