import React from "react";
import {useSelector} from "react-redux";
import SimpleStatusesForProducts from "../../../../simpleStatusesForProducts";
import DeliveredInfoBox from "../../../../../../components/DeliveredInfoBox";

const Completed = ({status, productId}) => {
  const {
    allProducts,
    allProductsLoader,
    anotherGovProducts,
  } = useSelector(({allProducts}) => allProducts);
  const {name, lastName} = useSelector(({auth}) => auth.userData);

  const {
    address:{
      address1, city_name, country_code, state_code, zipcode
    },
    trackingNum,
    trackingUri,
    provider
  } = allProducts[productId]?.add;
  const headerText = "Your application is on its way";
  const productImage = anotherGovProducts?.find(item => item.id === allProducts[productId]?.product_name)?.image;
  const productName = anotherGovProducts?.find(item => item.id === allProducts[productId]?.product_name)?.title;

  return(
      <div>
        <SimpleStatusesForProducts
            iconStatus={status}
            userName={name}
            headerText={headerText}
            description={""}
            buttonText={""}
            productImage={productImage}
            productName={productName}
            productId={productId}
        />

        <DeliveredInfoBox
            trackNumber={trackingNum}
            deliverAddress={`${address1}, ${city_name}, ${state_code}, ${zipcode}, ${country_code}`}
            redirectLink={trackingUri}
            provider={provider}
        />
      </div>
  )
}

export default Completed;
