import Event from "../Event"
import { EventOptions, EventProps } from "../EventProperties"
import IEvent from "../IEvent"

const EVENT_NAME = "Identity Protection Verification Completed"

/**
 * Identity Protection Verification Completed
 * @class IdentityProtectionCreditScoreCompleted
 * @extends {Event}
 * @implements {IEvent}
 * @description Identity Protection Verification Completed event
 */
export default class IdentityProtectionCreditScoreCompleted extends Event implements IEvent {
  /**
   * Creates an instance of IdentityProtectionCreditScoreCompleted.
   * @memberof IdentityProtectionCreditScoreCompleted
   * @description Identity Protection Verification Completed event
   */

  constructor(ipAddress: string | undefined) {
    super(EVENT_NAME, ipAddress)
  }

  getEventData(): EventProps {
    return {
      ...super.baseEventData(),
    }
  }

  getEventName(): string {
    return this.eventName
  }

  pageName(): string {
    return ""
  }

  getPreviousId(): string {
    return ""
  }

  getUserId(): string {
    return ""
  }

  getEventOptions(): EventOptions | undefined {
    return undefined
  }
}
