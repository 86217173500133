import React, { FC } from "react"
import "./Notification.scss"
import GCheckList, { CHECK_LIST_TYPE } from "../../molecules/CheckList/CheckList"
import { COLORS, COLORS_NEW } from "../../types/Colors"

export enum NOTIFICATION_TYPE {
  ERROR = "error",
  WARNING = "warning",
  SUCCESS = "success",
  INFORMATION = "information",
}

type GNotificationProps = {
  text: string,
  type: string,
  icon: string,
}

const GNotification: FC<GNotificationProps> = ({ text, type, icon }) => {
  const getComponent = (bgColor: string, borderColor: string, textColor: string, iconWidth: number = 24, iconHeight: number = 24, iconColor?: string) => {
    const style: React.CSSProperties = {
      backgroundColor: bgColor,
      border: "solid 1px " + borderColor,
    }
    return (
      <div className={`GNotification`} style={style}>
        <GCheckList
          text={text}
          bubbleWidth={iconWidth}
          bubbleHeight={iconHeight}
          iconsType={icon}
          iconsColor={iconColor || textColor}
          type={CHECK_LIST_TYPE.CUSTOM}
          bubbleColor={bgColor}
          iconsHeight={iconWidth}
          iconsWidth={iconHeight}
          textColor={textColor}
        />
      </div>
    )
  }
  const render = () => {
    if (type === NOTIFICATION_TYPE.ERROR) {
      return getComponent(COLORS_NEW.RED_300, COLORS_NEW.RED_300, COLORS_NEW.BLACK_1000, 24, 24, COLORS_NEW.RED_800)
    } else if (type === NOTIFICATION_TYPE.WARNING) {
      return getComponent(COLORS_NEW.YELLOW_300, COLORS_NEW.YELLOW_300, COLORS_NEW.BLACK_1000, 24 , 24 , COLORS_NEW.YELLOW_800)
    } else if (type === NOTIFICATION_TYPE.INFORMATION) {
      return getComponent(COLORS.ROYAL_BLUE_50, COLORS.ROYAL_BLUE_600, COLORS.SLATE_GRAY_600, 24, 24)
    } else {
      return getComponent(COLORS.GREEN_50, COLORS.GREEN_600, COLORS.GREEN_600)
    }
  }
  return <>{render()}</>
}

export default GNotification
