import React from "react"
import { Button } from "antd"
import { connect } from "react-redux"
import { approveAdress, setApproveAddressField } from "../../../../Redux/store/additionalForm/additional.actions"
import { setChangedAddress } from "../../../../Redux/store/form/form.actions"
import "./ChoseAddressComponent.scss"
import GText from "../../../atomicDesign/atoms/Text/Text";
import {SIZE} from "../../../atomicDesign/types/types";
import {COLORS, COLORS_NEW} from "../../../atomicDesign/types/Colors";
import GButton from "../../../atomicDesign/molecules/Button/GButton";
import moment from "moment";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"

type ChoseAddressComponentProps = {
  field: any,
  currentResponse: any,
  appId: string,
  formId: string,
  dispatch: any,
  loadingButtonState: any,
  approveAddressField: { fieldName: string } | undefined,
}

// eslint-disable-next-line max-len
const ChoseAddressComponent: React.FunctionComponent<ChoseAddressComponentProps> = ({
  field,
  currentResponse,
  appId,
  formId,
  dispatch,
  loadingButtonState,
  approveAddressField,
}) => {
  const breakPoint = useBreakpoint()
  const getContentComponent = (field: {} | any, currentResponse: {} | any, setChangedAddressOn: boolean) => {
    let submit = {}
    if (field.name === "mailing_address") {
      submit = { ...currentResponse.verifiedAddress.original, type: "choseAddress" }
    } else if (field.name === "verified_address") {
      submit = {
        ...currentResponse.verifiedAddress.verified,
        type: "choseAddress",
        zipcode: currentResponse.verifiedAddress.verified.zipcode.split("-")[0],
      }
    }
    if (setChangedAddressOn) {
      dispatch(
        setChangedAddress({
          ...submit,
          status: currentResponse.verifiedAddress.status,
        })
      )
    }
    return {
      ...submit,
      status: currentResponse.verifiedAddress.status,
      client_token: appId,
      form_id: formId,
    }
  }

  const handlerCurrentResponse = () => {
    dispatch(setApproveAddressField({ fieldName: field.name }))
    dispatch(approveAdress(getContentComponent(field, currentResponse, true)))
  }


  let contentComponent: {} | any = {}
  if (currentResponse?.verifiedAddress) {
    contentComponent = getContentComponent(field, currentResponse, false)
  }
  const name = `${contentComponent.name}`
  const address1 = `${contentComponent.address1} ${contentComponent.address2 ? contentComponent.address2 : ''}`
  const city = `${contentComponent.city_name} ${contentComponent.state_code} ${contentComponent.zipcode}`

  return (
      <div className={`ChoseAddressComponent`}>
        <div className={`ChoseAddressComponent__Header`}>
          <GText text={field.label} size={breakPoint.md ? SIZE.PARAGRAPH_BOLD_18 : SIZE.PARAGRAPH_BOLD_16} />
        </div>
        <div className={`ChoseAddressComponent__Content`}>
          {(name || field.content.name) && (
              <div className={`ChoseAddressComponent__Content__Name`}>
                <GText text={name || field.content.name} size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14} />
              </div>
          )}

          {(address1 || field.content.address) && (
              <div className={`ChoseAddressComponent__Content__AddressItem`}>
                <GText text={address1 || field.content.address} size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14} color={COLORS_NEW.BLACK_800} />
              </div>
          )}

          {(city || field.content.address) && (
              <div className={`ChoseAddressComponent__Content__AddressItem`}>
                <GText text={city || field.content.address} size={breakPoint.md ? SIZE.PARAGRAPH_REGULAR_16 : SIZE.PARAGRAPH_REGULAR_14} color={COLORS_NEW.BLACK_800} />
              </div>
          )}

          <div className={`ChoseAddressComponent__Content__CtaBtnContainer`}>
            <GButton
                disabled={loadingButtonState === "loading"}
                text={field.content.button}
                textSize={SIZE.PARAGRAPH_BOLD_16}
                style={{height: 44}}
                click={handlerCurrentResponse}
                loading={loadingButtonState === "loading" && approveAddressField && approveAddressField.fieldName === field.name}
                showIcon={false}
            />
          </div>
        </div>
      </div>
  )
}

const mapStateToProps = (state: any) => ({
  currentResponse: state.step.currentResponse,
  appId: state.form.appId,
  formId: state.form.formId,
  loadingButtonState: state.loading.loadingButtonState,
  approveAddressField: state.additional.approveAddressField
})

export default connect(mapStateToProps)(ChoseAddressComponent)
