import React, {FC} from "react";
import GIconsSvgType from "../IconsSvg/IconsSvg";
import GText from "../Text/Text";
import {INPUT_STATUS, SIZE} from "../../types/types";
import "./Badge.scss"
import {COLORS} from "../../types/Colors";

type BadgeProps = {
  iconType?: string,
  iconColor?: string,
  showIcon?: boolean,
  text: string,
  textSize?: string,
  type?: INPUT_STATUS,
  className?: string,
  style?: React.CSSProperties,
  textColor?: string,
  backgroundColor?: string,
}
const GBadge:FC<BadgeProps> = ({
                                 text,
                                 type = INPUT_STATUS.SUCCESS,
                                 iconType,
                                 iconColor = COLORS.WHITE,
                                 showIcon = false,
                                 textSize,
                                 className,
                                 textColor,
                                 backgroundColor,
                                 style,

                               }) => {

  const styles: React.CSSProperties = {
    ...style,
    backgroundColor: backgroundColor || (type === INPUT_STATUS.SUCCESS ?
      COLORS.GREEN_400 : type === INPUT_STATUS.WARNING ?
        COLORS.YELLOW_300 : COLORS.RED_700),
  }

  return <span className={`GBadge ${className}`} style={styles}>
        {showIcon && iconType && <GIconsSvgType
          type={iconType}
          fillCircle={false}
          width={16}
          height={16}
          color={iconColor}
          backgroundColor={type === INPUT_STATUS.SUCCESS ?
            COLORS.GREEN_400 : type === INPUT_STATUS.WARNING ?
              COLORS.YELLOW_300 : COLORS.RED_700}
        />}
    <GText
      text={text}
      size={textSize || SIZE.PARAGRAPH_REGULAR_14}
      color={textColor || COLORS.WHITE}
      weight={"600"}
    />
    </span>
}

export default GBadge