/* eslint-disable react-redux/useSelector-prefer-selectors */
import React, { useCallback, useEffect } from "react"
import "./VerificationSuccess.scss"
import GText from "../../atoms/Text/Text"
import { SIZE } from "../../types/types"
import { COLORS_NEW } from "../../types/Colors"
import GButton from "../../molecules/Button/GButton"
import {
  checkVerificationStatus,
  resetUserRedirectedToThankYouPage,
  userRedirectedToThankYouPage,
} from "../../../../../../dashboard-govplus-front/src/appRedux/actions"
import { useDispatch } from "react-redux"
import { env } from "../../../../env"

const VerificationSuccess = () => {
  const dispatch = useDispatch()

  const onLoad = useCallback(() => {
    // if user redirected to this page, it means that the idp verification is completed
    dispatch(checkVerificationStatus())
    dispatch(userRedirectedToThankYouPage())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    onLoad()

    return () => {
      dispatch(resetUserRedirectedToThankYouPage())
    }
  }, [onLoad, dispatch])

  const handleNavigate = useCallback(() => {
    let url

    if (env.REACT_APP_ENV === "development") {
      url = "https://dashboard.govplus.dev/myIdentity"
    } else if (env.REACT_APP_ENV === "production") {
      url = "https://dashboard.govplus.com/myIdentity"
    } else if (env.REACT_APP_ENV === "staging") {
      url = "https://dashboard.govplus.app/myIdentity"
    }
    if (url) {
      window.open(url, "_blank")
      dispatch(resetUserRedirectedToThankYouPage())
    }
  }, [dispatch])

  return (
    <div className={"VerificationSuccess"}>
      <div className={"VerificationSuccess__Content"}>
        <GText
          text={"Your identity verification was successful"}
          size={SIZE.PARAGRAPH_BOLD_24}
          color={COLORS_NEW.BLACK_1000}
        />
        <GText
          text={"This step protects your privacy and ensures only you can access your credit report."}
          size={SIZE.PARAGRAPH_REGULAR_16}
          color={COLORS_NEW.BLACK_800}
        />
        <GButton text={"Go Back to Credit Score"} showIcon click={handleNavigate} />
      </div>
    </div>
  )
}

export default VerificationSuccess
