import React, {useEffect, useState} from "react"
import "./index.less"
import {Divider, Layout} from "antd"
import {useHistory} from "react-router-dom"
import {
    checkProductForDuplicateClear,
    getNotificationsSuccsses,
    toggleCollapsedSideNav,
    userSignOut
} from "../../appRedux/actions"
import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {useDispatch, useSelector} from "react-redux";
import {IconSvg} from "../../components/svgImages/SvgImages";
import GDropDownMenu
    from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/DropDownMenu/DropDownMenu";
import BreadCrumbsHandler from "./BreadCrumsHandler";
import PopoverMenuLogin from "../../components/PopoverMenuLogin";
import GLogo from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/molecules/Logo/Logo";
import {useUser} from "../../hooks/useUser"
import {COLORS_NEW} from "@dash/gov-plus__front-end__form/src/Components/atomicDesign/types/Colors";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {Path} from "@dash/gov-plus__front-end__form/src/util/PageHelper";

const Topbar = () => {
    const breadpoint = useBreakpoint();
    const {anotherGovProducts, webFlowData} = useSelector(({allProducts}) => allProducts);
    const {navStyle} = useSelector(({settings}) => settings);
    const {navCollapsed} = useSelector(({common}) => common);
    const {outerId} = useSelector(({auth}) => auth);
    const width = useSelector(({common}) => common.width)
    const dispatch = useDispatch();
    const history = useHistory();
    const [profileMenuArr] = useState([
        {linkTo: "/account-settings", icon: "userIcon", menuType: "Account settings"},
        {linkTo: "/billing-settings", icon: "billingIcon", menuType: "Billing settings"},
        {linkTo: "/logout", icon: "logOutIcon", menuType: "Log out"},
    ]);

    const pathname = useSelector((state) => state?.common.pathname);

    const queryParamFormId = useSelector((state) => state?.router?.location?.query?.formId);
    const {userSubmittedData} = useUser();
    const firstNamelUser = userSubmittedData?.firstName;
    const lastNameUser = userSubmittedData?.lastName;
    const emailLocalUser = userSubmittedData?.email;

    useEffect(() => {
        dispatch(getNotificationsSuccsses([
            {noteDescription: "Sit amet lorem 6", noteTime: "1day ago"},
            {noteDescription: "Sit amet lorem 7", noteTime: "2days ago"},
            {noteDescription: "Sit amet lorem 8", noteTime: "3days ago"},
            {noteDescription: "Sit amet lorem 9", noteTime: "4days ago"},
        ]))
    }, [dispatch])

    if (pathname === "/logout") {
        dispatch(userSignOut({
            outer_id: outerId,
            userId: JSON.parse(sessionStorage.getItem("userFlattenData")).userId,
        }))
    }


    const linkChat = () => {
        /*eslint-disable-next-line*/
        if (typeof linkChatToBtn === "function") {
            /*eslint-disable-next-line*/
            linkChatToBtn(".assistant-button");
        }
    };

    const userDataAvailable = firstNamelUser && lastNameUser && emailLocalUser

    const openDrawerMenu = () => {
        dispatch(toggleCollapsedSideNav(!navCollapsed))
    }

    return (
        <Layout.Header className={"HeaderComponent"}>
            {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
                <div className="gx-linebar gx-mr-3">
                    <div className={"mobileAndTabletImage"}>
                        <GLogo url={Path.DOCUMENTS_PAGE}/>
                    </div>
                </div> : null}
            {
                !anotherGovProducts?.length || !!Object.keys(webFlowData)?.length
                    ? <BreadCrumbsHandler/>
                    : null
            }

            <div className={"HeaderComponent_settingsMenu"} style={{gap: !breadpoint.xs ? 24 : 16}}>

                <div className={"assistant-button"} onClick={linkChat} style={{cursor: "pointer"}}>
                    <IconSvg type={"questionIcon"} iconColor={COLORS_NEW.BLACK_700}/>
                </div>

                <div className={"HeaderComponent_settingsMenu_userMenu"}>
                    {(userDataAvailable) ? (
                        <>
                            <GDropDownMenu
                                userSignOut={userSignOut}
                                checkProductsForDuplicate={checkProductForDuplicateClear}
                                userName={`${firstNamelUser} ${lastNameUser}`}
                                userMenuItems={profileMenuArr}
                                email={emailLocalUser}
                                name={firstNamelUser}
                                lastName={lastNameUser}
                                outerId={outerId}
                            />
                        </>
                    ) : (
                        <PopoverMenuLogin/>
                    )}
                </div>

                <div
                    className={"navBarToggleIconContainerTablet navBarToggleIconContainerMobile navBarToggleIconContainerDesktop"}>
                    <div className={`menu-btn ${navCollapsed && "menu-btn_active"}`} onClick={openDrawerMenu}>
                        <span/>
                    </div>
                </div>

            </div>
        </Layout.Header>
    );
};

export default Topbar;
