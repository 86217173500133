import React, {FC, useEffect, useState} from "react";
import "./GBillingSettings.scss";
import GText from "../../atoms/Text/Text";
import {CreditCard, PaymentMethod, SIZE} from "../../types/types";
import {COLORS, COLORS_NEW} from "../../types/Colors";
import {SvgCreditCard} from "../../atoms/IconsSvg/svg/SvgCreditCard";
import GPaymentMethodsForm from "../../templates/PaymentMethodsForm/PaymentMethodsForm";
import {isNewPaymentMethod, NEW_PAYMENT_METHOD_VALUE} from "../../../../util/PaymentMethodsHelper";
import GButton, {BUTTON_TYPE} from "../../molecules/Button/GButton";
import {Divider, Form} from "antd";
import {GBillingSettingsMockStaticTexts} from "./GBillingSettingsMockStaticTexts";
import {useSubscription} from "../../../../../../dashboard-govplus-front/src/hooks/useSubscription";
import {SubscriptionText} from "../../../../shared/text/Text";
import {useHelperSubscription} from "../../../../../../dashboard-govplus-front/src/hooks/useHelperSubscription";

// @ts-ignore
import {useNavigation} from "../../../../../../dashboard-govplus-front/src/hooks/useNavigation";
import {useApplications} from "../../../../../../dashboard-govplus-front/src/hooks/useApplications";
import {useSelector} from "react-redux";
import GSpinner from "../../atoms/Spinner/Spinner";
import GNotification, {NOTIFICATION_TYPE} from "../../organisms/Notification/Notification";
import {ICONS_SVG_TYPE} from "../../atoms/IconsSvg/IconsSvgTypes";
import PaymentSecurityTag from "../../molecules/PaymmentSecurityTag/PaymentSecurityTag";
import moment from "moment";

interface GTransactionHistory {
    date: string;
    name: string;
    price: string;
}

interface GSubscriptionPlan {
    planName: string;
    planPrice: string;
    planDescription: string;
    actionName: string;
}

interface GBillingSettings {
    title: string;
    paymentMethods: PaymentMethod[];
    defaultPaymentMethod?: string;
    savePaymentMethod: (paymentMethod: string, creditCard: CreditCard, callback?: (data: any) => void) => void,
    loading?: boolean,
    ctaAddPaymentMethod?: string;
    ctaAddPaymentMethodDescription?: string;
    transactionBlockHistoryTitle?: string;
    transactionsHistory?: GTransactionHistory[];
    onSelectPaymentMethod?: (paymentMethod: string, userIsSubscribed?: boolean) => void
}

const GBillingSettings: FC<GBillingSettings> = ({
    title = "Billing settings",
    paymentMethods = [],
    savePaymentMethod,
    defaultPaymentMethod= "",
    loading = false,
    ctaAddPaymentMethod= GBillingSettingsMockStaticTexts.DEFAULT_CTA_ADD_PAYMENT_METHOD,
    ctaAddPaymentMethodDescription= GBillingSettingsMockStaticTexts.DEFAULT_CTA_ADD_PAYMENT_METHOD_DESCRIPTION,
    transactionBlockHistoryTitle= GBillingSettingsMockStaticTexts.DEFAULT_TRANSACTION_HISTORY_BLOCK_TITLE,
    transactionsHistory,
    onSelectPaymentMethod
}) => {
    const [isPaymentMethodFormOpen, setIsPaymentMethodFormOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(defaultPaymentMethod || NEW_PAYMENT_METHOD_VALUE);
    const {subscriptionIsActive, subscriptionIsCancelled, subscriptionIsValid, subscription, insuredDocuments, renewedDocuments, checkStatus} = useSubscription()
    const [form] = Form.useForm();
    const {navigate} = useNavigation()
    const {filteredProducts} = useApplications()

    const {triggerSubscriptionCancellationStartedAnalyticEvent} = useHelperSubscription();
    const getCreditCardFormValue = async (): Promise<CreditCard | undefined> => {
        try {
            return await form.validateFields()
        } catch (ex) {
            return undefined
        }
    }
    const handleSubmit = async (): Promise<void> => {
        if (!isNewPaymentMethod(selectedPaymentMethod)) {
            return;
        }
        const creditCard: CreditCard | undefined = await getCreditCardFormValue();
        if (!creditCard) {
            return;
        }

        savePaymentMethod(selectedPaymentMethod, creditCard, (response) => {
            setErrorMessage(response?.error ?? '')
        })
    }

    const cancelSubscription = () => {

        // @ts-ignore
        triggerSubscriptionCancellationStartedAnalyticEvent(subscription.id)

        // @ts-ignore
        if (Object.keys(filteredProducts).length > 0) {
            navigate("/SubscriptionCancelApplications")
        }
        // @ts-ignore
        else if (insuredDocuments.filter(document => document.insured).length > 0) {
            navigate("/SubscriptionCancelInsurance")
        }
        // @ts-ignore
        else if (renewedDocuments.filter(document => document.automaticRenewalDate).length > 0) {
            navigate("/SubscriptionCancelRenewal")
        } else {
            navigate("/SubscriptionCancelReason")
        }
    }

    const handleSelectedPaymentMethod = (paymentMethod : string) => {
        setSelectedPaymentMethod(paymentMethod)
        onSelectPaymentMethod?.(paymentMethod, subscriptionIsActive)
    }

    const letterSpacingText: React.CSSProperties = {
        letterSpacing: "-0.1px",
    }

    const subscriptionRenewalDescription = subscriptionIsActive ?
        SubscriptionText.SubscriptionRenewsOn.replace("{date}",
        moment(subscription?.expirationDate).format("LL")) :
        SubscriptionText.SubscriptionActiveUntil.replace("{date}",
        moment(subscription?.expirationDate).format("LL"))

    useEffect( () => {
        checkStatus()
    },[])

    return (
        <>
            {loading && <GSpinner />}
            {!loading && <div className={"GBillingSettings"}>
                <div className={"GBillingSettings__Header"}>
                    <div className={"GBillingSettings__Header__Title"}>
                        <GText text={title} size={SIZE.HEADING_XL} color={COLORS_NEW.BLACK_1000} />
                    </div>
                </div>
                <div className={"GBillingSettings__Content"}>
                    {subscriptionIsValid() && subscription?.planData && <div className={"GBillingSettings__Content__ManageSubscription BillingSettingsCard"}>
                        <GText text={SubscriptionText.ManageSubscription} size={SIZE.HEADING_MD} color={COLORS_NEW.BLACK_1000} />
                        <div className={"GBillingSettings__Content__ManageSubscription__Content"}>
                            <div className={"GBillingSettings__Content__ManageSubscription__Content__Information"}>
                                <GText text={subscription.planData.planName} size={SIZE.PARAGRAPH_BOLD_20}
                                       color={COLORS_NEW.BLACK_1000}/>
                                <GText text={`${subscription.planData.planPrice} $ / per ${subscription.paymentFrequency.toLowerCase()}`} size={SIZE.PARAGRAPH_BOLD_18}
                                       color={COLORS_NEW.BLACK_500}/>
                                <GText
                                    text={subscriptionRenewalDescription}
                                    size={SIZE.PARAGRAPH_REGULAR_16}
                                    color={COLORS_NEW.BLACK_800}
                                />
                            </div>
                            {!subscriptionIsCancelled && <div className={"GBillingSettings__Content__ManageSubscription__Content__CTA"}>
                                <GButton
                                    text={SubscriptionText.CancelPlan}
                                    textSize={SIZE.PARAGRAPH_BOLD_16}
                                    style={{...letterSpacingText, height: 44}}
                                    type={BUTTON_TYPE.SECONDARY}
                                    showIcon={false}
                                    click={cancelSubscription}
                                />
                            </div>}
                        </div>
                    </div>}
                    <Form form={form} layout={`vertical`}>
                        <div className={"GBillingSettings__Content__PaymentMethod BillingSettingsCard"}>
                            <GText text={"Payment method"} size={SIZE.HEADING_MD} color={COLORS_NEW.BLACK_1000} />
                            <Divider />
                            <GPaymentMethodsForm
                                paymentMethods={paymentMethods}
                                defaultPaymentMethod={defaultPaymentMethod}
                                selectionPaymentMethodChange={handleSelectedPaymentMethod}
                                isPaymentMethodFormOpen={setIsPaymentMethodFormOpen}
                            />
                            {errorMessage && (
                                <GNotification text={errorMessage} type={NOTIFICATION_TYPE.ERROR}
                                               icon={ICONS_SVG_TYPE.INFORMATION}/>
                            )}
                            {isPaymentMethodFormOpen && <div className={"GBillingSettings__Content__PaymentMethod__CTA"}>
                                <GButton 
                                    text={ctaAddPaymentMethod}
                                    textSize={SIZE.PARAGRAPH_BOLD_16}
                                    click={handleSubmit}
                                    showIcon={false}
                                />
                                <GText 
                                    text={ctaAddPaymentMethodDescription}
                                    size={SIZE.PARAGRAPH_REGULAR_14} 
                                    color={COLORS_NEW.BLACK_500}
                                />
                            </div>}
                        </div>
                    </Form>
                    {transactionsHistory && <div className={"GBillingSettings__Content__TransactionHistory BillingSettingsCard"}>
                        <GText text={transactionBlockHistoryTitle} size={SIZE.HEADING_XS} color={COLORS_NEW.BLACK_1000} />
                        {
                            transactionsHistory.map((transactionHistory: GTransactionHistory, index: number) => {
                                return (
                                    <div key={index} className={"GBillingSettings__Content__TransactionHistory__Data"}>
                                        <GText text={transactionHistory.date} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS_NEW.BLACK_800}/>
                                        <GText text={transactionHistory.name} size={SIZE.PARAGRAPH_REGULAR_16}
                                                color={COLORS.SLATE_GRAY_500}/>
                                        <GText text={transactionHistory.price} size={SIZE.PARAGRAPH_REGULAR_16} color={COLORS.GREEN_400}/>
                                    </div>
                                );
                            })
                        }
                    </div>}
                </div>
            </div>}
        </>


    )

}
export default GBillingSettings