import { Action } from "../../interfacesAction/action.interface"

export const ActionTypes = {
  SET_PACKAGE_RESPONSE: "[PACKAGE] set package response",

  GET_FAILURE: "[getFailureAction] get failure action",
}

export const setPackageResponse = (responseObject: {}): Action<{}> => ({
  type: ActionTypes.SET_PACKAGE_RESPONSE,
  payload: responseObject,
})

export const getFailureAction = (error?: {}): Action<{}> => ({
  type: ActionTypes.GET_FAILURE,
  payload: error,
})
