import BaseCookiesRepository from "./BaseCookiesRepository";

const IMPACT_RADIUS_ID_VARS_KEY = "irclickid";

class ImpactRadiusIdRepository extends BaseCookiesRepository {

    static IMPACT_RADIUS_ID_DEFAULT_VALUE = null;

    private static _instance: ImpactRadiusIdRepository;

    private constructor() {
        super()
    }

    public static getInstance(): ImpactRadiusIdRepository {
        if (!ImpactRadiusIdRepository._instance) {
            ImpactRadiusIdRepository._instance = new ImpactRadiusIdRepository();
        }
        return ImpactRadiusIdRepository._instance;
    }

    getKeyName(): string {
        return IMPACT_RADIUS_ID_VARS_KEY;
    }
}

export default ImpactRadiusIdRepository;
